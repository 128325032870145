import { FC, useMemo } from 'react';

import MultiSelect from 'components/multiSelect';

import { DashboardVariableMap, SelectElemConfig } from 'types/dashboardTypes';
import { constructOptions } from 'utils/dropdownUtils';
import { resolveTooltipVariables } from 'utils/variableUtils';
import { DatasetRow } from 'types/datasets';
import { AnalyticsEventTracker } from 'utils/analyticsUtils';
import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import { OnNewValueSelectFunc } from 'types/functionPropTypes';

type Props = {
  analyticsEventTracker?: AnalyticsEventTracker;
  config: SelectElemConfig;
  datasets: Record<string, Partial<{ _rows?: DatasetRow[] }>>;
  onNewValueSelect: OnNewValueSelectFunc;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  disabled?: boolean;
  openElementToLeft: boolean;
  variables: DashboardVariableMap;
};

export const DashboardMultiSelectElement: FC<Props> = ({
  analyticsEventTracker,
  config,
  value,
  datasets,
  disabled,
  openElementToLeft,
  onNewValueSelect,
  variables,
}) => {
  const options = useMemo(
    () => constructOptions(config.valuesConfig, datasets),
    [config.valuesConfig, datasets],
  );

  const selectedOptions = useMemo(() => {
    if (!value || !Array.isArray(value)) return [];

    return options.filter((option) => value.indexOf(option.value) > -1);
  }, [options, value]);

  return (
    <div>
      <MultiSelect
        disableOnNoItems={config.disableOnNoItems}
        disabled={disabled}
        infoTooltipText={resolveTooltipVariables(config, variables)}
        label={config.label}
        openElementToLeft={openElementToLeft}
        options={options}
        placeholder={config.placeholderText}
        selectedItems={selectedOptions}
        updateSelectedValues={(newValues) => {
          onNewValueSelect(newValues, { length: newValues?.length ?? 0 });
          analyticsEventTracker?.(REPORTED_ANALYTIC_ACTION_TYPES.MULTISELECT_SELECTED);
        }}
        useFakeLabel={config.label === ''}
        usePortal={config.usePortal}
      />
    </div>
  );
};
