import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';

import { ErrorResponse } from 'actions/responseTypes';
import { Jobs } from 'components/JobQueue/types';
import { BulkJobEnqueueResponse, JobDefinition } from 'actions/jobQueueActions';
import { createApiRequestConfig } from 'actions/actionUtils';
import { v4 } from 'uuid';

export async function makeThunkRequest<T>(
  requestConfig: AxiosRequestConfig | null,
  defaultErrMsg: string,
  onSuccess?: (response: T) => void,
  onError?: (error: ErrorResponse) => void,
): Promise<T> {
  if (!requestConfig) throw new Error(defaultErrMsg);

  return axios(requestConfig)
    .then(({ data }: AxiosResponse) => {
      if (data.success === 1) {
        onSuccess?.(data);
        return data;
      } else if (data.error_msg) throw new Error(data.error_msg);
      else throw new Error(defaultErrMsg);
    })
    .catch((error: AxiosError) => {
      console.error(error.response);
      onError?.(error.response && { ...error.response.data, status: error.response.status });
      throw new Error(defaultErrMsg);
    });
}

const JobErrorMessage = 'Failed to enqueue job';

// This function is temporary till fido and is made to be used to send
// singular jobs to get enqueued
export async function makeThunkJobRequest(
  job: JobDefinition | null,
  customerToken: string,
): Promise<Record<string, Jobs>> {
  if (!job) throw new Error(JobErrorMessage);

  const jobs = { [v4()]: job };
  const requestConfig = createApiRequestConfig(
    'async_jobs/enqueue_jobs/',
    'POST',
    { jobs },
    true,
    customerToken,
  );

  return axios(requestConfig)
    .then(({ data }: AxiosResponse) => {
      if (data.success === 1) {
        const jobMap = (data as BulkJobEnqueueResponse).enqueued_job_map;
        const { error, job_intention_key } = jobMap[Object.keys(jobMap)[0]];

        if (error || !job_intention_key) throw new Error(error);
        return { [job_intention_key]: { jobType: job.job_type } as Jobs };
      } else if (data.error_msg) throw new Error(data.error_msg);
      throw new Error(JobErrorMessage);
    })
    .catch((error: AxiosError) => {
      console.error(error.response);
      throw new Error(JobErrorMessage);
    });
}
