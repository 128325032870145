import { FC } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/index';

import DropdownSelect from 'shared/DropdownSelect';

import { ColorFilterPlacement, V2TwoDimensionChartInstructions } from 'constants/types';
import { DashboardVariable, DashboardVariableMap } from 'types/dashboardTypes';
import { COLOR_CATEGORY_FILTER_SUFFIX } from 'constants/dashboardConstants';
import { getColorDropdownOptions } from 'utils/colorColUtils';

const useStyles = makeStyles(() => ({
  colorCategoryDropdown: {
    '& .bp3-button': {
      filter: 'none !important',
      align: 'right',
    },
    '& .bp3-transition-container': {
      right: '0px',
      left: 'unset !important',
    },
  },
  belowTitle: () => ({
    width: '100%',
    position: 'relative',
    '& .bp3-button': {
      width: '100%',
    },
    '& .bp3-popover-target': {
      width: '100%',
    },
  }),
}));

export type Props = {
  instructions: V2TwoDimensionChartInstructions | undefined;
  dashboardVariables: DashboardVariableMap;
  dpProvidedId: string;
  setVariable: (value: DashboardVariable, name: string) => void;
};

export const ColorCategoryDropdown: FC<Props> = ({
  instructions,
  dashboardVariables,
  dpProvidedId,
  setVariable,
}) => {
  const classes = useStyles();

  const colorOptions = getColorDropdownOptions(instructions);
  if (colorOptions.length === 0) return null;
  const varName = dpProvidedId + COLOR_CATEGORY_FILTER_SUFFIX;

  const selectedVar = dashboardVariables[varName];
  const selectedOption = selectedVar
    ? colorOptions.find((opt) => opt.id === selectedVar)
    : colorOptions[0];

  const isBelowTitle = instructions?.colorFilterPlacement === ColorFilterPlacement.BELOW_TITLE;
  return (
    <div
      className={cx(classes.colorCategoryDropdown, {
        [classes.belowTitle]: isBelowTitle,
      })}>
      <DropdownSelect
        fillWidth
        minimal
        usePortal
        filterable={false}
        noSelectionText="Select a group by"
        onChange={(newValue) => setVariable(newValue.id, varName)}
        openElementToLeft={!isBelowTitle}
        options={colorOptions}
        selectedItem={selectedOption}
      />
    </div>
  );
};
