import { PayloadAction } from '@reduxjs/toolkit';

import { CustomerReportBuilderMetadata } from 'actions/customerReportBuilderMetadata';
import { AggregationType, FilterClause, SortOrder } from 'constants/types';
import { GlobalStyleConfig } from 'globalStyles/types';
import { PivotAgg } from 'types/dateRangeTypes';
import { FilterOperator } from 'types/filterOperations';
import { defineEmbedPostAction } from './actionUtils';
import { EmbedReportBuilder } from './reportBuilderActions';
import { ReportBuilderCol } from './reportBuilderConfigActions';
import { EmbedReportBuilderVersion } from './reportBuilderVersionActions';
import { ACTION } from './types';

export interface CustomerReportDataInfo {
  datasetId: string;
  columns: string[];
}

// Matching shape of FilterClause for ease of use in backend but making
// some of these required instead
export type CustomerReportFilter = Pick<FilterClause, 'filterValue'> & {
  filterColumn: ReportBuilderCol;
  filterOperation: { id: FilterOperator };
  isPostFilter?: boolean;

  // Value for easy updating filters when they have same column
  id: number;
};

export interface CustomerReportGroupBy {
  column: ReportBuilderCol;
  bucket?: PivotAgg;
}

export interface CustomerReportAgg {
  column: ReportBuilderCol;
  agg: AggregationType;
}

export interface CustomerReportSort {
  column: {
    name: string;
  };
  order: SortOrder;
}

export interface CustomerReportView {
  id: string;
  name: string;
  columnOrder: string[];
  hiddenColumns: string[];
  filters: CustomerReportFilter[];
  // For now, we only allow sorting by a single column, but we could support multiple in the future
  sort?: CustomerReportSort[];
  groupBys?: CustomerReportGroupBy[];
  aggregations?: CustomerReportAgg[];
  columnGroupBys?: CustomerReportGroupBy[];
}

export interface CustomerReportConfig {
  dataInfo?: CustomerReportDataInfo;
  // Should not be an empty list, either undefined or has at least one view
  views?: CustomerReportView[];
}

export interface CustomerReport {
  id: number;
  name: string;
  config: CustomerReportConfig;
  modified: string;
  is_starred: boolean;
}

type FetchCustomerReportsBody = {
  report_builder_embed_id: string;
  environment: string | null;
};

type FetchCustomerReportsData = {
  style_config: GlobalStyleConfig;
  customer: { token: string };
  report_builder_version: EmbedReportBuilderVersion;
  report_builder: EmbedReportBuilder;
  reports: CustomerReport[];
  metadata: CustomerReportBuilderMetadata;
  team: { id: number; name: string };
};

export const {
  actionFn: fetchCustomerReports,
  requestAction: fetchCustomerReportsRequest,
  successAction: fetchCustomerReportsSuccess,
  errorAction: fetchCustomerReportsError,
} = defineEmbedPostAction<FetchCustomerReportsBody, FetchCustomerReportsData>(
  ACTION.FETCH_CUSTOMER_REPORTS,
  'customer_reports',
  'reports',
  'POST',
);

type CreateCustomerReportBody = {
  report_builder_embed_id: string;
  name: string;
  config: CustomerReportConfig | null;
};

export const { actionFn: createCustomerReport, successAction: createCustomerReportSuccess } =
  defineEmbedPostAction<CreateCustomerReportBody, { report: CustomerReport }>(
    ACTION.CREATE_CUSTOMER_REPORT,
    'customer_reports',
    'create_report',
    'POST',
  );

type ReportUpdate = { report_id: number };

export const { actionFn: starCustomerReport, successAction: starCustomerReportSuccess } =
  defineEmbedPostAction<ReportUpdate, ReportUpdate>(
    ACTION.STAR_CUSTOMER_REPORT,
    'customer_reports',
    'star_report',
    'POST',
  );

export const { actionFn: deleteCustomerReport, successAction: deleteCustomerReportSuccess } =
  defineEmbedPostAction<ReportUpdate, ReportUpdate>(
    ACTION.DELETE_CUSTOMER_REPORT,
    'customer_reports',
    'delete_report',
    'POST',
  );

type SaveCustomerReportBody = ReportUpdate & { config: CustomerReportConfig };

export const { actionFn: saveCustomerReport, successAction: saveCustomerReportSuccess } =
  defineEmbedPostAction<SaveCustomerReportBody, { report: CustomerReport }>(
    ACTION.SAVE_CUSTOMER_REPORT,
    'customer_reports',
    'save_report',
    'POST',
  );

type UpdateCustomerReportNameBody = ReportUpdate & { name: string };

export const {
  actionFn: updateCustomerReportName,
  successAction: updateCustomerReportNameSuccess,
} = defineEmbedPostAction<UpdateCustomerReportNameBody, UpdateCustomerReportNameBody>(
  ACTION.UPDATE_CUSTOMER_REPORT_NAME,
  'customer_reports',
  'update_report_name',
  'POST',
);

export type UpdateCustomerReportViewName = {
  viewId: string;
  name: string;
};

export type OrderGroupBysPayload = PayloadAction<{
  groupBys: CustomerReportGroupBy[];
  isColumnGroupBys: boolean;
}>;

export type AddGroupByPayload = PayloadAction<{
  groupBy: CustomerReportGroupBy;
  isColumnGroupBy: boolean;
}>;

export type UpdateGroupByPayload = PayloadAction<{
  id: string;
  groupBy: CustomerReportGroupBy;
  isColumnGroupBy: boolean;
}>;

export type DeleteGroupByPayload = PayloadAction<{
  id: string;
  isColumnGroupBy: boolean;
}>;

export type SetLoadingSchemaInfoPayload = PayloadAction<{
  groupBys: CustomerReportGroupBy[];
  aggs: CustomerReportAgg[];
  columnGroupBys?: CustomerReportGroupBy[];
}>;
