import { PureComponent } from 'react';
import cx from 'classnames';
import { isEqual, isNumber, keyBy } from 'utils/standard';
import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles';
import { Spinner, sprinkles } from 'components/ds';

import NeedsConfigurationPanel from 'pages/dashboardPage/DashboardDatasetView/needsConfigurationPanel';
import HighCharts from './highCharts';
import TrendPctChange from './shared/trendPctChange';
import { InfoIcon } from 'components/InfoIcon';
import { UrlClickThroughButton } from 'components/UrlClickThrough';
import { ChartMenu } from 'components/ChartMenu';
import NumberTrendTextPanel from '../../../shared/charts/numberTrendTextPanel';

import { V2KPITrendInstructions, VisualizeOperationGeneralFormatOptions } from 'constants/types';
import { DashboardVariableMap, OpenDrilldownModalType } from 'types/dashboardTypes';
import { V2_NUMBER_FORMATS } from 'constants/dataConstants';
import { DatasetSchema } from 'types/datasets';
import { formatValue } from './utils';
import { replaceTemplatesWithValues } from 'utils/dataPanelConfigUtils';
import { getCategoricalColors, GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { GlobalStyleConfig } from 'globalStyles/types';
import {
  PeriodComparisonRangeTypes,
  PeriodRangeTypes,
  TrendGroupingOptions,
} from 'types/dateRangeTypes';
import { DateTime } from 'luxon';
import { getTimezoneAwareDate } from 'utils/timezoneUtils';
import {
  areRequiredVariablesSet,
  formatDateRange,
  getComparisonDates,
  getPctChange,
  getPeriodDates,
  instructionsReadyToDisplay,
} from './utils/trendUtils';

declare global {
  interface PointOptionsObject {
    custom: Record<string, boolean | number | string>;
  }
}
type ChartData = {
  name: string;
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  color: string;
}[];

const PERIOD_INDEX = 1;
const COMPARISON_INDEX = 0;
const DATE_INDEX = 0;
const AGG_INDEX = 1;

const titleContainerClass = sprinkles({
  marginBottom: 'sp.5',
  flexItems: 'alignCenter',
  flex: 1,
  overflow: 'hidden',
});

const styles = (theme: Theme) =>
  createStyles({
    chartTitle: {
      fontSize: `14px`,
      fontWeight: 'bold',
      marginRight: theme.spacing(2),
      whiteSpace: 'nowrap',
      maxWidth: '60%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: theme.palette.ds.grey800,
    },
    noDataChartTitle: {
      maxWidth: '100%',
    },
    aggValue: {
      fontSize: 20,
    },
    comparisonAggValue: (props: PassedProps) => ({
      color: props.instructions?.displayFormat?.comparisonColor || theme.palette.ds.grey700,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    }),
    dateRange: {
      fontSize: 12,
      fontWeight: 100,
      color: theme.palette.ds.grey800,
    },
    chartDateRange: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: -2,
      color: theme.palette.ds.grey800,
      fontSize: 12,
    },
    aggValueUnit: {
      fontSize: 16,
    },
    periodAggValue: (props: PassedProps) => ({
      color:
        props.instructions?.displayFormat?.periodColor ||
        props.globalStyleConfig.visualizations.categoricalPalette.hue1,
      fontWeight: 600,
    }),
    noDataText: {
      height: 'calc(100% - 20px)',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      fontWeight: 'unset',
    },
    trendLineLoadingState: {
      height: `calc(100% - 20px) !important`,
      overflow: 'hidden',

      '& .bp3-non-ideal-state': {
        overflow: 'hidden',
      },
    },
    noTrendLineLoadingTitle: {
      whiteSpace: 'nowrap',
      maxWidth: '100%',
      fontWeight: 'bold',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginBottom: theme.spacing(1),
    },
    noTrendLineLoadingState: {
      height: 'initial !important',

      overflow: 'hidden',

      '& .bp3-non-ideal-state': {
        overflow: 'hidden',
      },
    },
  });

type TotalAggregatedValues = {
  periodRange: number;
  comparisonRange: number;
};

type PassedProps = {
  loading?: boolean;
  newDataLoading?: boolean;
  backgroundColor: string;
  previewData: Record<string, string | number>[];
  aggValuesLoading?: boolean;
  aggregatedValues?: TotalAggregatedValues;
  instructions?: V2KPITrendInstructions;
  dataPanelTemplateId: string;
  editableDashboard?: boolean;
  variables: DashboardVariableMap;
  schema: DatasetSchema;
  infoTooltipText?: string;
  globalStyleConfig: GlobalStyleConfig;
  generalOptions?: VisualizeOperationGeneralFormatOptions;
  hideIcons?: boolean;

  openDrilldownModal?: OpenDrilldownModalType;
};

type Props = PassedProps & WithStyles<typeof styles>;

type State = {
  hoveredIndex?: number;
  data?: ChartData;
};

class BaseNumberTrend extends PureComponent<Props, State> {
  state: State = {};

  constructor(props: Props) {
    super(props);

    if (
      !props.loading &&
      instructionsReadyToDisplay(
        props.instructions,
        props.instructions?.aggColumn ? [props.instructions?.aggColumn] : undefined,
      ) &&
      props.schema?.length >= 2
    ) {
      this.state = {
        data: this.processTrendData(),
      };
    }
  }

  getBuiltInVarsMap = () => {
    const { data } = this.state;
    if (!data) return {};
    return {
      current_period: this.getPeriodDateRange(data),
      comparison_period: this.getComparisonDateRange(data),
    };
  };

  componentDidUpdate(prevProps: Props) {
    // Don't check props.loading as it's improperly set right now
    // (it will be set to true despite the previewData being already fetched)
    // and the previewData being different should be enough info to confidently
    // re-process the trend data
    if (
      instructionsReadyToDisplay(
        this.props.instructions,
        this.props.instructions?.aggColumn ? [this.props.instructions?.aggColumn] : undefined,
      ) &&
      this.props.schema?.length >= 2 &&
      (!isEqual(prevProps.previewData, this.props.previewData) ||
        !isEqual(prevProps.instructions, this.props.instructions))
    ) {
      this.setState({
        data: this.processTrendData(),
      });
    }
  }

  render() {
    const {
      instructions,
      previewData,
      loading,
      variables,
      aggValuesLoading,
      aggregatedValues,
      editableDashboard,
    } = this.props;

    const requiredVarNotsSet = !areRequiredVariablesSet(variables, instructions);

    // If the dashboard is editable, then we want to reload the entire chart when changes
    // happen. Either way, if there are no agg values to show, we should be loading the
    // entire chart since this.renderNumberTrendTextPanel() will crash if no values are present
    const isDataLoading = instructions?.hideTrendLines
      ? aggValuesLoading && (editableDashboard || !aggregatedValues)
      : loading;
    const instructionsReady = instructionsReadyToDisplay(
      instructions,
      instructions?.aggColumn ? [instructions?.aggColumn] : undefined,
    );

    if (isDataLoading || !instructionsReady || requiredVarNotsSet) {
      return this.renderLoadingState(requiredVarNotsSet, instructionsReady, loading);
    }

    if (instructions?.hideTrendLines) {
      return this.renderNumberTrendTextPanel();
    }

    if (!previewData || previewData.length === 0) {
      return this.renderNoDataBody();
    }

    return this.renderNumberTrend();
  }

  renderLoadingState = (
    requiredVarNotsSet: boolean,
    instructionsReady: boolean,
    loading?: boolean,
  ) => {
    const { classes, instructions, generalOptions, variables } = this.props;
    const showHeader = !generalOptions?.headerConfig?.isHeaderHidden;
    const title = this.getTitle();
    const configPanel = (className: string) => (
      <NeedsConfigurationPanel
        className={className}
        instructionsNeedConfiguration={!instructionsReady}
        loading={loading}
        requiredVarsNotSet={requiredVarNotsSet}
      />
    );
    if (instructions?.hideTrendLines) {
      return (
        <div className={sprinkles({ parentContainer: 'fill', flexItems: 'centerColumn' })}>
          {showHeader && (
            <div
              className={cx(
                classes.noTrendLineLoadingTitle,
                GLOBAL_STYLE_CLASSNAMES.text.kpiTitle.base,
              )}>
              {replaceTemplatesWithValues(title || '', variables)}
            </div>
          )}
          {configPanel(classes.noTrendLineLoadingState)}
        </div>
      );
    }
    return (
      <div className={sprinkles({ parentContainer: 'fill' })}>
        {showHeader && (
          <div className={titleContainerClass}>
            <div className={cx(classes.chartTitle, GLOBAL_STYLE_CLASSNAMES.text.kpiTitle.base)}>
              {replaceTemplatesWithValues(title || '', variables)}
            </div>
          </div>
        )}
        {configPanel(classes.trendLineLoadingState)}
      </div>
    );
  };

  renderNoDataBody = () => {
    const { classes, generalOptions, variables } = this.props;
    return (
      <div
        className={cx(
          sprinkles({ height: 'fill' }),
          GLOBAL_STYLE_CLASSNAMES.text.body.primaryFont,
        )}>
        {!generalOptions?.headerConfig?.isHeaderHidden && (
          <div className={titleContainerClass}>
            <div
              className={cx(
                classes.chartTitle,
                classes.noDataChartTitle,
                GLOBAL_STYLE_CLASSNAMES.text.kpiTitle.base,
              )}>
              {replaceTemplatesWithValues(this.getTitle(), variables)}
            </div>
          </div>
        )}
        <div
          className={classes.noDataText}
          style={{ fontSize: generalOptions?.noDataState?.noDataFontSize || 36 }}>
          {generalOptions?.noDataState?.noDataText || 'No Data'}
        </div>
      </div>
    );
  };

  renderNumberTrendTextPanel = () => {
    const {
      globalStyleConfig,
      instructions,
      generalOptions,
      aggregatedValues,
      aggValuesLoading,
      infoTooltipText,
      variables,
    } = this.props;

    const usesComparison = this.getComparisonRange() !== PeriodComparisonRangeTypes.NO_COMPARISON;
    const periodAggregatedValue = aggregatedValues?.periodRange || 0;
    const comparisonAggregatedValue =
      (usesComparison ? aggregatedValues?.comparisonRange : undefined) || 0;
    const trendChangeVal = instructions?.displayFormat?.showAbsoluteChange
      ? this.getNumericChange(periodAggregatedValue, comparisonAggregatedValue)
      : getPctChange(periodAggregatedValue, comparisonAggregatedValue);
    const trendChangeValFormat = instructions?.displayFormat?.showAbsoluteChange
      ? V2_NUMBER_FORMATS.NUMBER
      : V2_NUMBER_FORMATS.PERCENT;
    const subtitle = replaceTemplatesWithValues(
      instructions?.textOnlyFormat?.subtitle ?? '',
      this.getBuiltInVarsMap(),
    );

    const number = aggregatedValues?.periodRange || 0;
    const noData = generalOptions?.noDataState?.isZeroNoData
      ? !aggregatedValues || number === 0
      : !aggregatedValues;

    return (
      <NumberTrendTextPanel
        aggValuesLoading={aggValuesLoading}
        displayFormat={instructions?.displayFormat}
        generalOptions={generalOptions}
        globalStyleConfig={globalStyleConfig}
        headerActions={this.renderHeaderActions()}
        infoTooltipText={infoTooltipText}
        noData={noData}
        number={number}
        subtitle={subtitle}
        titleFormat={instructions?.titleFormat}
        trendChangeVal={usesComparison ? trendChangeVal : undefined}
        trendChangeValFormat={trendChangeValFormat}
        trendChangeValLabel={this.getComparisonRange()}
        valueFormat={instructions?.valueFormat}
        variables={variables}
      />
    );
  };

  renderNumberTrend = () => {
    const { data } = this.state;

    if (!data) return <div />;

    return (
      <>
        {this.renderChartAggregatedValues(data)}
        <HighCharts chartOptions={this._spec(data)} className={sprinkles({ display: 'flex' })} />
        {this.renderChartDateRange(data)}
      </>
    );
  };

  renderChartDateRange = (data: ChartData) => {
    const { classes } = this.props;
    const periodData = data[PERIOD_INDEX].data;

    const startDate = formatDateRange(periodData[0][DATE_INDEX], this.getTrendGrouping(), true);
    const endDate = formatDateRange(
      periodData[periodData.length - 1][DATE_INDEX],
      this.getTrendGrouping(),
      true,
      true,
    );

    return (
      <div className={cx(classes.chartDateRange, GLOBAL_STYLE_CLASSNAMES.text.body.primaryFont)}>
        <div>{startDate}</div>
        <div>{endDate}</div>
      </div>
    );
  };

  renderHeader = (
    useComparison: boolean,
    periodAggregatedValue: number,
    comparisonAggregatedValue: number,
  ) => {
    const {
      classes,
      generalOptions,
      instructions,
      infoTooltipText,
      newDataLoading,
      variables,
      hideIcons,
    } = this.props;

    const isHeaderHidden = generalOptions?.headerConfig?.isHeaderHidden;
    const linkFormat = generalOptions?.linkFormat;

    const shouldRenderClickThrough = linkFormat?.link && linkFormat?.url && !hideIcons;

    if (isHeaderHidden && !shouldRenderClickThrough && !generalOptions?.enableRawDataDrilldown)
      return;

    const pctChange = useComparison
      ? getPctChange(periodAggregatedValue, comparisonAggregatedValue)
      : undefined;
    const title = this.getTitle();

    return (
      <div
        className={cx(
          sprinkles({
            display: 'flex',
            alignItems: 'center',
            justifyContent: !isHeaderHidden ? 'space-between' : 'flex-end',
          }),
        )}
        style={{ height: 32 }}>
        {!isHeaderHidden ? (
          <div className={titleContainerClass}>
            {title && (
              <div className={cx(classes.chartTitle, GLOBAL_STYLE_CLASSNAMES.text.kpiTitle.base)}>
                {replaceTemplatesWithValues(title, variables)}
              </div>
            )}
            {infoTooltipText && (
              <InfoIcon
                className={sprinkles({ marginLeft: 'sp0', marginRight: 'sp2' })}
                infoTooltipText={infoTooltipText}
              />
            )}
            {pctChange !== undefined && (
              <TrendPctChange instructions={instructions} pctChange={pctChange} />
            )}

            {newDataLoading && <Spinner className={sprinkles({ marginLeft: 'sp3' })} size="md" />}
          </div>
        ) : null}
        {this.renderHeaderActions()}
      </div>
    );
  };

  renderHeaderActions = () => {
    const { dataPanelTemplateId, generalOptions, hideIcons, openDrilldownModal } = this.props;

    const linkFormat = generalOptions?.linkFormat;

    if (hideIcons) return null;

    return (
      <>
        <UrlClickThroughButton linkFormat={linkFormat} />
        {generalOptions?.enableRawDataDrilldown ? (
          <ChartMenu dataPanelId={dataPanelTemplateId} openDrilldownModal={openDrilldownModal} />
        ) : null}
      </>
    );
  };

  renderChartAggregatedValues = (data: ChartData) => {
    const { classes, instructions } = this.props;
    const useComparison = this.getComparisonRange() !== PeriodComparisonRangeTypes.NO_COMPARISON;
    const periodAggregatedValue = this.getTimeRangeAggregatedValue(data, PERIOD_INDEX);
    const comparisonAggregatedValue = this.getTimeRangeAggregatedValue(data, COMPARISON_INDEX);

    return (
      <div className={GLOBAL_STYLE_CLASSNAMES.text.body.primaryFont}>
        {this.renderHeader(useComparison, periodAggregatedValue, comparisonAggregatedValue)}
        <div
          className={sprinkles({
            flexItems: 'alignCenter',
            justifyContent: 'space-between',
          })}>
          <div className={classes.periodAggValue}>
            <div className={classes.aggValue}>
              {this.getFormattedAggValue(periodAggregatedValue)}
              <span className={classes.aggValueUnit}>{instructions?.valueFormat?.units}</span>
            </div>
            <div className={classes.dateRange}>{this.getPeriodDateRange(data)}</div>
          </div>
          {useComparison && (
            <div className={classes.comparisonAggValue}>
              <div className={classes.aggValue}>
                {this.getFormattedAggValue(comparisonAggregatedValue)}
                <span className={classes.aggValueUnit}>{instructions?.valueFormat?.units}</span>
              </div>
              <div className={classes.dateRange}>{this.getComparisonDateRange(data)}</div>
            </div>
          )}
        </div>
      </div>
    );
  };

  getFormattedAggValue = (value?: number) => {
    const { instructions } = this.props;

    if (!value) return '-';

    const decimalPlaces = instructions?.valueFormat?.decimalPlaces ?? 2;
    const significantDigits = instructions?.valueFormat?.significantDigits ?? 3;
    const formatId = instructions?.valueFormat?.numberFormat?.id || V2_NUMBER_FORMATS.NUMBER.id;

    return formatValue({
      value: value,
      decimalPlaces,
      significantDigits,
      formatId,
      hasCommas: true,
      timeFormatId: instructions?.valueFormat?.timeFormat?.id,
      customTimeFormat: instructions?.valueFormat?.timeCustomerFormat,
    });
  };

  getNumericChange = (base: number, comparison: number) => {
    return base - comparison;
  };

  getTimeRangeAggregatedValue = (data: ChartData, timeRangeIndex: number): number => {
    const { hoveredIndex } = this.state;
    const { aggregatedValues } = this.props;

    const timeRangeData = data[timeRangeIndex].data;

    if (hoveredIndex !== undefined) {
      return timeRangeData[hoveredIndex] ? timeRangeData[hoveredIndex][AGG_INDEX] : 0;
    } else {
      return timeRangeIndex === PERIOD_INDEX
        ? (aggregatedValues?.periodRange as number)
        : (aggregatedValues?.comparisonRange as number);
    }
  };

  getPeriodDateRange = (data: ChartData) => {
    return this.getDateRange(data[PERIOD_INDEX].data);
  };

  getComparisonDateRange = (data: ChartData) => {
    return this.getDateRange(data[COMPARISON_INDEX].data);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getDateRange = (compData: any) => {
    const { hoveredIndex } = this.state;
    if (hoveredIndex !== undefined) {
      if (!compData[hoveredIndex]) return '-';
      const periodStartDate = getTimezoneAwareDate(compData[hoveredIndex][DATE_INDEX]);
      return formatDateRange(periodStartDate, this.getTrendGrouping());
    } else {
      const startDate = formatDateRange(
        getTimezoneAwareDate(compData[0][DATE_INDEX]),
        this.getTrendGrouping(),
        true,
      );
      const endDate = formatDateRange(
        getTimezoneAwareDate(compData[compData.length - 1][DATE_INDEX]),
        this.getTrendGrouping(),
        true,
        true,
      );
      return `${startDate} - ${endDate}`;
    }
  };

  _spec = (data: ChartData): Highcharts.Options | undefined => {
    const { previewData, schema, backgroundColor } = this.props;
    if (schema?.length === 0 || !previewData) return;

    const setHoverIndex = (index?: number) => {
      this.setState({ hoveredIndex: index });
    };

    return {
      chart: {
        type: 'line',
        backgroundColor,
      },
      //@ts-ignore
      series: data,
      title: {
        text: undefined,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        line: {
          marker: {
            enabled: false,
          },
        },
        series: {
          animation: false,
          point: {
            events: {
              mouseOver: function () {
                setHoverIndex(this.index);
              },
              mouseOut: function () {
                setHoverIndex(undefined);
              },
            },
          },
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
      yAxis: {
        gridLineWidth: 0,
        labels: {
          enabled: false,
        },
        title: {
          text: undefined,
        },
      },
      xAxis: {
        crosshair: true,
        gridLineWidth: 0,
        labels: {
          enabled: false,
        },
        lineWidth: 1,
        lineColor: '#EEEEEE',
        minorGridLineWidth: 0,
        minorTickLength: 0,
        tickLength: 0,
      },

      tooltip: {
        enabled: false,
      },
    };
  };

  getPeriodColumnName = () => this.props.schema[0].name;

  getAggColumnName = () => this.props.schema[1].name;

  getPeriodAndComparisonDates = () => {
    const { instructions } = this.props;

    const { periodStartDate, periodEndDate, periodDates } = getPeriodDates(
      this.getPeriodRange(),
      this.getTrendGrouping(),
      this.getCustomStartDate(),
      this.getCustomEndDate(),
      instructions?.periodColumn?.trendDateOffset ?? (0 as number),
    );

    const comparisonDates = getComparisonDates(
      periodStartDate,
      periodEndDate,
      periodDates.length,
      this.getComparisonRange(),
      this.getPeriodRange(),
      this.getTrendGrouping(),
    );

    return { periodDates, comparisonDates };
  };

  processTrendData = () => {
    const { previewData, instructions, globalStyleConfig } = this.props;

    if (!previewData) return undefined;

    const periodColumnName = this.getPeriodColumnName();
    const aggColumnName = this.getAggColumnName();

    const { periodDates, comparisonDates } = this.getPeriodAndComparisonDates();

    previewData.forEach((row) => {
      if (!instructions?.periodColumn?.column.type) return;

      row[periodColumnName] = getTimezoneAwareDate(row[periodColumnName].toString()).toLocaleString(
        DateTime.DATE_SHORT,
      );
    });

    const dataByDate = keyBy(previewData, periodColumnName);
    const series: Record<
      string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { name: string; type: string; data: any; color: string; lineWidth?: number; opacity?: number }
    > = {
      period: {
        type: 'line',
        name: 'Period',
        data: [],
        color:
          instructions?.displayFormat?.periodColor || getCategoricalColors(globalStyleConfig)[0],
      },
      comparison: {
        type: 'line',
        name: 'Comparison',
        data: [],
        color: instructions?.displayFormat?.comparisonColor || '#757575',
        lineWidth: 1,
        opacity: 0.6,
      },
    };

    periodDates.forEach((date) => {
      let aggValue = 0;
      const parsedDate = date.toLocaleString(DateTime.DATE_SHORT);
      if (dataByDate[parsedDate]) {
        aggValue = isNumber(dataByDate[parsedDate][aggColumnName])
          ? (dataByDate[parsedDate][aggColumnName] as number)
          : 0;
      }
      const entry = [date, aggValue];
      series.period.data.push(entry);
    });

    comparisonDates.forEach((date) => {
      let aggValue = 0;
      const parsedDate = date.toLocaleString(DateTime.DATE_SHORT);
      if (dataByDate[parsedDate]) {
        aggValue = isNumber(dataByDate[parsedDate][aggColumnName])
          ? (dataByDate[parsedDate][aggColumnName] as number)
          : 0;
      }
      const entry = [date, aggValue];
      series.comparison.data.push(entry);
    });

    return [series.comparison, series.period];
  };

  getPeriodRange = () =>
    this.props.instructions?.periodColumn?.periodRange || PeriodRangeTypes.LAST_4_WEEKS;

  getComparisonRange = () =>
    this.props.instructions?.periodComparisonRange || PeriodComparisonRangeTypes.PREVIOUS_PERIOD;

  getTrendGrouping = () => this.props.instructions?.trendGrouping || TrendGroupingOptions.WEEKLY;

  getCustomEndDate = () => {
    const { instructions } = this.props;
    return instructions?.periodColumn?.customEndDate
      ? getTimezoneAwareDate(instructions.periodColumn.customEndDate)
      : DateTime.local();
  };

  getCustomStartDate = () => {
    const { instructions } = this.props;
    return instructions?.periodColumn?.customStartDate
      ? getTimezoneAwareDate(instructions.periodColumn.customStartDate)
      : DateTime.local();
  };

  // if the title is undefined, we then use the aggregation name as the title for the KPI
  getTitle = () => {
    const { instructions, generalOptions } = this.props;

    if (generalOptions?.headerConfig?.title !== undefined) return generalOptions.headerConfig.title;

    const aggColumn = instructions?.aggColumn?.column;

    return aggColumn?.friendly_name || aggColumn?.name || '';
  };
}

export const NumberTrend = withStyles(styles)(BaseNumberTrend);
