import { FC, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useImmer } from 'use-immer';

import {
  SortableList,
  SortableListItem,
  SortableListItemDragArea,
} from 'components/SortableList/SortableList';
import { Button, sprinkles, IconButton, Checkbox, Icon } from 'components/ds';
import { EmbedDataGrid } from 'components/embed';
import { EmbedText } from '../EmbedText';
import { LargeModal } from './LargeModal';
import * as styles from './DataSelectionModal.css';

import * as RD from 'remotedata';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { openConfirmChangeDatasetModal } from 'reportBuilderContent/reducers/reportEditingReducer';
import { fetchModalData, updateDataInfoThunk } from 'reportBuilderContent/thunks';
import { getColTypeDisplay } from 'utils/reportBuilderConfigUtils';
import {
  FilterableColumn,
  getFilterableColumns,
  getSortableColumns,
  SortableColumnInfo,
} from 'utils/customerReportUtils';

enum DataView {
  COLUMNS = 'Included data',
  PREVIEW = 'Preview table',
}

export const DataSelectionModal: FC = () => {
  const dispatch = useDispatch();

  const { datasetId, currentConfig, reportBuilderConfig, modalDatasetData } = useSelector(
    (state: ReportBuilderReduxState) => ({
      datasetId: state.reportEditing.currentDatasetId,
      currentConfig: state.reportEditing.currentConfig,
      reportBuilderConfig: state.embeddedReportBuilder.reportBuilderVersion?.config,
      modalDatasetData:
        state.reportEditing.modalDatasetData[state.reportEditing.currentDatasetId ?? 0],
    }),
    shallowEqual,
  );

  const [selectedColumns, setSelectedColumns] = useImmer<string[]>(
    currentConfig?.dataInfo?.datasetId === datasetId ? currentConfig.dataInfo.columns : [],
  );
  const [dataView, setDataView] = useState(DataView.COLUMNS);

  const dataset = reportBuilderConfig?.datasets[datasetId ?? ''];

  const selectableColumns = useMemo(
    () => (dataset ? getFilterableColumns(dataset) : []),
    [dataset],
  );

  const sortableColumns = useMemo(() => {
    const columns = getSortableColumns(dataset, selectedColumns);

    // Reset selected columns in case one is not available anymore
    if (selectedColumns.length !== columns.length) {
      setSelectedColumns(columns.map((c) => c.id));
    }
    return columns;
  }, [selectedColumns, dataset, setSelectedColumns]);

  useEffect(() => {
    if (!datasetId) return;
    dispatch(fetchModalData(datasetId));
  }, [dispatch, datasetId]);

  if (!dataset || !currentConfig) return null;

  const numCols = selectedColumns.length;
  const colText = `${numCols} column${numCols === 1 ? '' : 's'}`;

  const allColumnsSelected = selectedColumns.length === selectableColumns.length;
  const rows = RD.isSuccess(modalDatasetData) ? modalDatasetData.data : null;

  const renderColumn = (col: FilterableColumn) => {
    const isChecked = selectedColumns.includes(col.name);

    return (
      <div className={styles.column} key={col.name}>
        <div className={styles.columnInfo}>
          <Checkbox
            isChecked={isChecked}
            onChange={() =>
              setSelectedColumns((draft) => {
                if (!isChecked) draft.push(col.name);
                else return draft.filter((name) => name !== col.name);
              })
            }
          />
          <EmbedText body="b1" className={styles.colName}>
            {col.display}
          </EmbedText>
          <EmbedText
            body="b2"
            className={sprinkles({ marginLeft: 'auto' })}
            color="contentTertiary">
            {getColTypeDisplay(col.type)}
          </EmbedText>
        </div>
        {col.description ? (
          <div className={sprinkles({ marginTop: 'sp1' })}>
            <EmbedText body="b2" color="contentTertiary">
              {col.description}
            </EmbedText>
          </div>
        ) : null}
      </div>
    );
  };

  const renderColumnData = () => {
    return (
      <div className={styles.columnData}>
        <EmbedText body="b1" color="contentTertiary">
          {`${colText} included`}
        </EmbedText>
        <div className={styles.sortableColumnsContainer}>
          <div className={styles.sortableColumns}>
            <SortableList
              getIdFromElem={(item) => item.id}
              onListUpdated={(newList) => setSelectedColumns(newList.map((col) => col.id))}
              sortableItems={sortableColumns}>
              {sortableColumns.map((column, i) => (
                <SortableListItem
                  borderBottomOnDrag
                  boxShadowOnDrag
                  key={column.id}
                  sortId={column.id}>
                  <SortableColumn
                    column={column}
                    isFirst={i === 0}
                    key={column.id}
                    onRemoveCol={() =>
                      setSelectedColumns(selectedColumns.filter((name) => name !== column.id))
                    }
                  />
                </SortableListItem>
              ))}
            </SortableList>
          </div>
        </div>
      </div>
    );
  };

  const renderDataGrid = () => {
    const schema = sortableColumns.map((col) => ({
      name: col.id,
      type: col.type,
      friendly_name: col.name,
    }));
    if (!rows) return null;

    return <EmbedDataGrid columnConfigs={dataset.columnConfigs} rows={rows} schema={schema} />;
  };

  const renderFooterContent = () => {
    return (
      <>
        <EmbedText body="b1" className={sprinkles({ marginRight: 'sp2' })}>
          {numCols === 0 ? 'No data selected' : colText}
        </EmbedText>
        <Button
          disabled={numCols === 0}
          onClick={() =>
            dispatch(updateDataInfoThunk({ datasetId: dataset.id, columns: selectedColumns }))
          }
          type="primary">
          Done
        </Button>
      </>
    );
  };

  return (
    <LargeModal footerContent={renderFooterContent()} title="Select data">
      <div className={styles.bodyContainer}>
        <div className={styles.columnsCol}>
          <div className={styles.columnsHeader}>
            <div className={styles.columnsHeaderText}>
              <EmbedText heading="h3">{dataset.name}</EmbedText>
              <Button
                onClick={() => dispatch(openConfirmChangeDatasetModal(dataset.id))}
                type="tertiary">
                Change Dataset
              </Button>
            </div>
            <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
              <Checkbox
                isChecked={selectedColumns.length > 0}
                isIndeterminate={!allColumnsSelected}
                onChange={() =>
                  setSelectedColumns(
                    allColumnsSelected ? [] : selectableColumns.map((col) => col.name),
                  )
                }
              />
              <EmbedText color="contentTertiary" heading="subtitle">
                COLUMNS
              </EmbedText>
            </div>
          </div>
          <div className={styles.columnList}>{selectableColumns.map(renderColumn)}</div>
        </div>
        <div className={styles.dataCol}>
          <div className={styles.viewOptions}>
            {Object.values(DataView).map((view) => {
              const isSelected = view === dataView;
              return (
                <div
                  className={isSelected ? styles.selectedView : styles.unselectedView}
                  key={view}
                  onClick={() => setDataView(view)}>
                  {view}
                </div>
              );
            })}
          </div>
          {dataView === DataView.COLUMNS ? renderColumnData() : renderDataGrid()}
        </div>
      </div>
    </LargeModal>
  );
};

type SortableColumnProps = {
  column: SortableColumnInfo;
  isFirst: boolean;
  onRemoveCol: () => void;
};

const SortableColumn: FC<SortableColumnProps> = ({ column, isFirst, onRemoveCol }) => {
  return (
    <div
      className={cx(styles.sortableColContainer, { [styles.sortableColContainerFirst]: isFirst })}>
      <div className={styles.sortableCol}>
        <SortableListItemDragArea className={styles.sortableDragArea}>
          <Icon
            className={sprinkles({ color: 'contentTertiary' })}
            name="vertical-grip"
            size="md"
          />
          <EmbedText body="b1">{column.name}</EmbedText>
          <EmbedText body="b2" color="contentTertiary">
            {getColTypeDisplay(column.type)}
          </EmbedText>
          <div className={styles.columnDescription}>
            <EmbedText body="b2" color="contentTertiary">
              {column.description}
            </EmbedText>
          </div>
        </SortableListItemDragArea>
        <IconButton className={styles.removeCol} name="large-cross" onClick={onRemoveCol} />
      </div>
    </div>
  );
};
