import { FC } from 'react';

import * as styles from '../styles.css';
import { Switch, sprinkles } from 'components/ds';
import { InfoIcon } from 'components/InfoIcon';

import { DASHBOARD_ELEMENT_TYPES, DateElemConfig, DATE_FILTER_TYPE } from 'types/dashboardTypes';
import { getUpdateConfigFunc } from '../utils';
import cx from 'classnames';

type Props = {
  config: DateElemConfig;
  dateFilterType: DATE_FILTER_TYPE;
  isCanvas?: boolean;

  updateDateConfig: (config: DateElemConfig) => void;
};

export const DateEditInteractionsConfig: FC<Props> = ({
  config,
  dateFilterType,
  isCanvas,
  updateDateConfig,
}) => {
  const updateConfig = getUpdateConfigFunc(config, updateDateConfig);

  return (
    <>
      <Switch
        className={styles.configInput}
        label="Enable clearing selection"
        onChange={() => updateConfig((draft) => (draft.disableCancel = !draft.disableCancel))}
        switchOn={!config.disableCancel}
      />
      {dateFilterType === DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER ? (
        <>
          {/* TODO: revisit this when the design system updates are in for info icon */}
          <div
            className={cx(
              sprinkles({
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }),
              styles.configInput,
            )}>
            <div
              className={sprinkles({ display: 'flex', flexDirection: 'row', marginRight: 'sp1' })}>
              <div className={sprinkles({ color: 'gray12', body: 'b3' })}>
                Set end date to end of day
              </div>
              <InfoIcon infoTooltipText="Turning this off may affect your dashboard's caching, if configured" />
            </div>
            <Switch
              onChange={() =>
                updateConfig((draft) => (draft.endDateEndOfDay = !draft.endDateEndOfDay))
              }
              switchOn={config.endDateEndOfDay}
            />
          </div>
          {!isCanvas ? (
            <Switch
              className={styles.configInput}
              label="Use portal for popover"
              onChange={() => updateConfig((draft) => (draft.usePortal = !draft.usePortal))}
              switchOn={config.usePortal}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
};
