import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Button, sprinkles } from 'components/ds';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { ModalViews } from 'pages/ReportBuilder/ModalViews';
import { BuiltInListItem } from 'pages/ReportBuilderEditor/BuiltIns/BuiltInListItem';
import { BuiltInReportView } from 'pages/ReportBuilderEditor/BuiltIns/BuiltInReportView';

import { createBlankView } from 'pages/ReportBuilder/utils/viewUtils';
import { CustomerReport } from 'actions/customerReportActions';
import {
  createBuiltInReport,
  getReportBuilderConfig,
  getSelectedBuiltIn,
  updateBuiltInReportConfig,
} from 'reducers/reportBuilderEditReducer';
import { ReduxState } from 'reducers/rootReducer';
import {
  clearReportBuilderPreview,
  setUpReportBuilderPreview,
} from 'reducers/thunks/reportBuilderEditorThunks';
import { isSuccess } from 'remotedata';
import { clearEmbeddedReportBuilderReducer } from 'reportBuilderContent/reducers/embeddedReportBuilderReducer';
import { clearSelectedReport } from 'reportBuilderContent/reducers/reportEditingReducer';
import { isEqual } from 'utils/standard';
import { EditorLeftColumn } from '../EditorLeftColumn';

import * as styles from './BuiltIns.css';

export const ReportBuilderBuiltIns: FC = () => {
  const dispatch = useDispatch();
  const prevBuiltInId = useRef<string>();

  const { currentConfig, selectedGroupId, customerGroups, configData, selectedBuiltIn } =
    useSelector(
      (state: ReduxState) => ({
        selectedGroupId: state.customers.selectedGroupId,
        customerGroups: state.customers.groups,
        currentConfig: state.reportEditing.currentConfig,
        configData: getReportBuilderConfig(state.reportBuilderEdit),
        selectedBuiltIn: getSelectedBuiltIn(state.reportBuilderEdit),
      }),
      shallowEqual,
    );

  useEffect(() => {
    // Config is stored in 2 places: selectedBuiltIn.config and reportEditing.currentConfig
    // When either of these changes, we want to update the other, but prevent an infinite loop
    // This check ensure currentReport and currentConfig update ONLY IF the ID of the selected built-in changes
    // If the selected built-in's config, name, etc. change, nothing will happen and that's fine
    if (selectedBuiltIn?.id === prevBuiltInId.current) return;
    prevBuiltInId.current = selectedBuiltIn?.id;

    if (!selectedBuiltIn) {
      dispatch(clearReportBuilderPreview());
      return;
    }

    dispatch(
      setUpReportBuilderPreview({
        ...INITIAL_REPORT,
        config: selectedBuiltIn?.config || INITIAL_REPORT.config,
        name: selectedBuiltIn?.name || INITIAL_REPORT.name,
      }),
    );
  }, [dispatch, selectedBuiltIn]);

  useEffect(() => {
    return () => {
      dispatch(clearEmbeddedReportBuilderReducer());
      dispatch(clearSelectedReport());
    };
  }, [dispatch]);

  const isSaved = useMemo(
    () => isEqual(currentConfig, selectedBuiltIn?.config),
    [currentConfig, selectedBuiltIn],
  );

  useEffect(() => {
    if (selectedBuiltIn && currentConfig && !isSaved)
      dispatch(
        updateBuiltInReportConfig({
          builtInId: selectedBuiltIn.id,
          config: currentConfig,
        }),
      );
  }, [currentConfig, dispatch, isSaved, selectedBuiltIn]);

  const customerToken = useMemo(() => {
    if (!isSuccess(customerGroups)) return;
    return customerGroups.data.find((group) => group.id === selectedGroupId)?.token;
  }, [selectedGroupId, customerGroups]);

  const createNewBuiltIn = useCallback(
    () =>
      dispatch(
        createBuiltInReport({
          name: 'New Report',
          description: '',
          config: {
            views: [createBlankView()],
          },
        }),
      ),
    [dispatch],
  );

  if (!customerToken) return <div>No token found or no selected customer</div>;

  return (
    <div className={styles.rootClass}>
      <EditorLeftColumn>
        <div className={styles.leftColClass}>
          <div
            className={sprinkles({
              padding: 'sp2',
              gap: 'sp2',
              flexItems: 'column',
            })}>
            Set up what reports will be built into your report builder and surfaced to your users
            <div className={sprinkles({ heading: 'h3', color: 'contentPrimary' })}>
              Built In Reports
            </div>
            <Button fillWidth icon="plus" onClick={createNewBuiltIn} type="primary">
              New Report
            </Button>
          </div>
          <div className={styles.builtInListItems}>
            {Object.values(configData?.builtInReports || {}).map((builtIn) => (
              <BuiltInListItem
                builtIn={builtIn}
                key={builtIn.id}
                selectedBuiltInId={selectedBuiltIn?.id}
              />
            ))}
          </div>
        </div>
      </EditorLeftColumn>
      <div className={styles.previewContainerClass}>
        {selectedBuiltIn && (
          <div className={styles.existingBuiltInBanner}>
            Editing &quot;{selectedBuiltIn.name}&quot;
          </div>
        )}
        {currentConfig ? (
          <BuiltInReportView
            builtInConfig={selectedBuiltIn}
            currentConfig={currentConfig}
            isSaved={isSaved}
          />
        ) : (
          <div className={sprinkles({ flexItems: 'centerColumn', height: 'fill', width: 'fill' })}>
            <EmbedText body="b1" color="contentPrimary">
              No Built Ins created
            </EmbedText>
            <Button
              className={sprinkles({ marginTop: 'sp1' })}
              onClick={createNewBuiltIn}
              type="tertiary">
              Create your first Built In
            </Button>
          </div>
        )}
      </div>
      <ModalViews reportName={selectedBuiltIn?.name || ''} />
    </div>
  );
};

const INITIAL_REPORT: CustomerReport = {
  id: 0,
  name: '',
  config: {},
  modified: '',
  is_starred: false,
};
