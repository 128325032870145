import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import produce from 'immer';
import { makeStyles, Theme } from '@material-ui/core';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import SettingHeader from '../../DataConfigTab/SettingHeader';
import { Switch } from 'components/ds';

import {
  updateGeneralFormatOptions,
  updateVisualizeOperation,
} from 'actions/dataPanelConfigActions';
import {
  OPERATION_TYPES,
  VisualizeCollapsibleListInstructions,
  VisualizeOperationGeneralFormatOptions,
} from 'constants/types';
import { sharedStyles } from './styles';
import { getColDisplayText } from '../../DataConfigTab/vizConfigs/utils';
import { uniqueId } from 'utils/standard';

const useStyles = makeStyles((theme: Theme) => ({
  helperText: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '15px',
    color: theme.palette.ds.grey800,
    marginBottom: theme.spacing(3),
    padding: `0px ${theme.spacing(3)}px`,
  },
  helperTextPadding: {
    paddingTop: theme.spacing(3),
  },
  downloadBtn: {
    marginTop: theme.spacing(3),
  },
}));

type Props = {
  dptProvidedId: string;
  instructions: VisualizeCollapsibleListInstructions;
  visualizationType: OPERATION_TYPES;
  generalFormatOptions: VisualizeOperationGeneralFormatOptions;
};

export default function CollapsibleListConfig({
  dptProvidedId,
  instructions,
  visualizationType,
  generalFormatOptions,
}: Props) {
  const sharedClasses = sharedStyles();
  const dispatch = useDispatch();
  const classes = useStyles();

  const aggCols = instructions.aggregations;
  const categoryCols = instructions.categories;

  const updateInstructions = (newInstructions: VisualizeCollapsibleListInstructions) => {
    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  const shouldUseJobQueue = useSelector(
    (state: ReduxState) => !!state.currentUser.team?.feature_flags.use_job_queue,
  );

  return (
    <>
      <SettingHeader
        name="Footer"
        rightContent={
          <Switch
            useCustomStyles
            onChange={() => {
              const newInstructions = produce(instructions, (draft) => {
                draft.hideFooter = !draft.hideFooter;
              });
              updateInstructions(newInstructions);
            }}
            switchOn={!instructions.hideFooter}
          />
        }
      />
      {!instructions.hideFooter ? (
        <>
          <Switch
            useCustomStyles
            className={cx(sharedClasses.configInput, classes.downloadBtn)}
            label="Show export button"
            onChange={() => {
              const newOptions = produce(generalFormatOptions, (draft) => {
                if (!draft.export) draft.export = {};
                draft.export.isDownloadButtonHidden = !draft.export.isDownloadButtonHidden;
              });
              dispatch(updateGeneralFormatOptions(newOptions));
            }}
            switchOn={!generalFormatOptions.export?.isDownloadButtonHidden}
          />
          <InputWithBlurSave
            containerClassName={sharedClasses.configInput}
            initialValue={generalFormatOptions.export?.downloadFileName || dptProvidedId}
            label="Download file name"
            onNewValueSubmitted={(newValue) => {
              const newOptions = produce(generalFormatOptions, (draft) => {
                if (!draft.export) draft.export = {};
                draft.export.downloadFileName = newValue;
              });
              dispatch(updateGeneralFormatOptions(newOptions));
            }}
          />

          <Switch
            useCustomStyles
            className={cx(sharedClasses.configInput, classes.downloadBtn)}
            label="Enable download export"
            onChange={() => {
              const newOptions = produce(generalFormatOptions, (draft) => {
                if (!draft.export) draft.export = {};
                draft.export.disableDownloadExport = !draft.export.disableDownloadExport;
              });
              dispatch(updateGeneralFormatOptions(newOptions));
            }}
            switchOn={!generalFormatOptions.export?.disableDownloadExport}
          />
          {shouldUseJobQueue ? (
            <Switch
              useCustomStyles
              className={cx(sharedClasses.configInput, classes.downloadBtn)}
              label="Enable email export"
              onChange={() => {
                const newOptions = produce(generalFormatOptions, (draft) => {
                  if (!draft.export) draft.export = {};
                  draft.export.enableEmailExport = !draft.export.enableEmailExport;
                });
                dispatch(updateGeneralFormatOptions(newOptions));
              }}
              switchOn={generalFormatOptions.export?.enableEmailExport}
            />
          ) : null}
        </>
      ) : null}

      <SettingHeader
        name="Show Categories"
        rightContent={
          <Switch
            useCustomStyles
            onChange={() => {
              const newInstructions = produce(instructions, (draft) => {
                draft.showCategories = !draft.showCategories;
              });
              updateInstructions(newInstructions);
            }}
            switchOn={instructions.showCategories}
          />
        }
      />
      {instructions.showCategories
        ? instructions?.rowColumns?.map((col) => (
            <InputWithBlurSave
              containerClassName={sharedClasses.configInput}
              initialValue={col.column.friendly_name || getColDisplayText(col)}
              key={uniqueId(col.column.name)}
              label={getColDisplayText(col)}
              onNewValueSubmitted={(newValue: string) => {
                const newInstructions = produce(instructions, (draft) => {
                  const changedCol = (draft.rowColumns || []).find(
                    (newCol) => newCol.column.name === col.column.name,
                  );
                  if (changedCol) changedCol.column.friendly_name = newValue;
                });
                updateInstructions(newInstructions);
              }}
            />
          ))
        : null}

      <SettingHeader
        name="Category Column Title"
        rightContent={
          <Switch
            useCustomStyles
            onChange={() => {
              const newInstructions = produce(instructions, (draft) => {
                draft.categoryColumnTitle = {
                  ...draft.categoryColumnTitle,
                  showTitle: !draft.categoryColumnTitle?.showTitle,
                };
              });
              updateInstructions(newInstructions);
            }}
            switchOn={instructions.categoryColumnTitle?.showTitle}
          />
        }
      />
      {instructions?.categoryColumnTitle?.showTitle ? (
        <InputWithBlurSave
          containerClassName={cx(sharedClasses.root, sharedClasses.configInput)}
          initialValue={instructions.categoryColumnTitle.title}
          label="Title"
          onNewValueSubmitted={(newValue: string) => {
            const newInstructions = produce(instructions, (draft) => {
              draft.categoryColumnTitle = { ...draft.categoryColumnTitle, title: newValue };
            });
            updateInstructions(newInstructions);
          }}
        />
      ) : null}
      <SettingHeader name="Column Names" />
      <div className={sharedClasses.root}>
        {categoryCols?.map((col) => (
          <InputWithBlurSave
            containerClassName={sharedClasses.configInput}
            initialValue={col.column.friendly_name || getColDisplayText(col)}
            key={`bar-line-labels-${col.column.name}`}
            label={getColDisplayText(col)}
            onNewValueSubmitted={(newValue: string) => {
              const newInstructions = produce(instructions, (draft) => {
                const changedCol = (draft.categories || []).find(
                  (newCol) => newCol.column.name === col.column.name,
                );
                if (changedCol) changedCol.column.friendly_name = newValue;
              });
              updateInstructions(newInstructions);
            }}
          />
        ))}
        {aggCols?.map((col) => (
          <InputWithBlurSave
            containerClassName={sharedClasses.configInput}
            initialValue={col.column.friendly_name || getColDisplayText(col)}
            key={uniqueId(`bar-line-labels-${col.column.name}`)}
            label={getColDisplayText(col)}
            onNewValueSubmitted={(newValue: string) => {
              const newInstructions = produce(instructions, (draft) => {
                const changedCol = (draft.aggregations || []).find(
                  (newCol) =>
                    newCol.column.name === col.column.name && newCol.agg.id === col.agg.id,
                );
                if (changedCol)
                  changedCol.column.friendly_name = newValue !== '' ? newValue : undefined;
              });
              updateInstructions(newInstructions);
            }}
          />
        ))}
      </div>
    </>
  );
}
