import { useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import cx from 'classnames';
import { sortBy } from 'utils/standard';
import { useQuery } from 'utils/routerUtils';
import { useHistory } from 'react-router-dom';

import { CanvasNav } from '../CanvasNav';
import { DashboardDropdownElement } from 'pages/dashboardPage/dashboardElement/dashboardDropdownElement';
import DashboardDateRangePickerElement from 'pages/dashboardPage/dashboardElement/DashboardDateRangePickerElement';
import { ArrowIcon } from '../icons';
import { Icon } from '@blueprintjs/core';
import FilterConfig from './FilterConfig';
import { DashboardMultiSelectElement } from 'pages/dashboardPage/dashboardElement/dashboardMultiSelectElement';
import DashboardToggleElement from 'pages/dashboardPage/dashboardElement/dashboardToggleElement';
import FilterPreview from './FilterPreview';
import { Button, sprinkles } from 'components/ds';
import { ColumnHeader } from 'components/resource/ColumnHeader';
import { DashboardDatepickerElement } from 'pages/dashboardPage/dashboardElement/dashboardDatepickerElement';

import {
  CanvasVersionConfig,
  createCanvasFilter,
  CanvasFilterType,
} from 'actions/canvasConfigActions';
import {
  DashboardVariable,
  DashboardVariableMap,
  DASHBOARD_ELEMENT_TYPES,
} from 'types/dashboardTypes';
import { sharedStyles } from '../sharedStyles';
import { DASHBOARD_ELEMENT_TYPE_TO_NAME } from 'constants/dashboardConstants';
import {
  canvasFilterHasError,
  getFilterQueryInfo,
  getFilterReferences,
} from 'utils/canvasConfigUtils';
import { fetchCanvasFilterDatasetPreview } from 'actions/datasetActions';
import { DatasetRow } from 'types/datasets';
import { ReduxState } from 'reducers/rootReducer';
import { getSelectedCustomer } from 'reducers/customersReducer';
import { getDefaultValueFromRows } from 'utils/dashboardUtils';

const useStyles = makeStyles((theme: Theme) => ({
  datasetReq: {
    marginBottom: 14,
    display: 'flex',
    height: 20,
    alignItems: 'center',
  },
  dataset: {
    width: 100,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  checkbox: {
    marginRight: theme.spacing(3),
  },
  required: {
    fontSize: 14,
    color: theme.palette.ds.grey800,
    marginLeft: theme.spacing(3),
    display: 'flex',
  },
  elementInputs: {
    padding: `${theme.spacing(6)}px 78px`,
    overflowY: 'auto',
    paddingBottom: 56,
  },
  elementInput: {
    marginBottom: theme.spacing(3),
  },
  filterView: {
    height: 200,
    padding: '40px 78px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    borderBottom: `1px solid ${theme.palette.ds.grey400}`,
  },
  filterViewText: {
    color: theme.palette.ds.grey700,
    fontSize: 14,
  },
  itemAttrs: {
    display: 'flex',
    alignItems: 'center',
  },
  filterIdTitle: {
    color: theme.palette.ds.grey600,
    fontSize: 12,
    fontWeight: 500,
    maxWidth: 200,
    lineHeight: '15px',
    marginTop: 2,
  },
  warningIcon: {
    color: theme.palette.ds.grey700,
  },
}));

type Props = {
  canvasId: number;
  config: CanvasVersionConfig;
  setVariable: (variable: string, value: DashboardVariable) => void;
  variables: DashboardVariableMap;
};

type SelectedFilterInfo = { id: string; isNew: boolean };

export default function FilterEditor({
  config,
  canvasId,
  variables,
  setVariable,
}: Props): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const sharedClasses = sharedStyles();
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();

  const selectedUserGroup = useSelector((state: ReduxState) =>
    getSelectedCustomer(state.customers),
  );

  const [selectedFilterInfo, setSelectedFilterInfo] = useState<SelectedFilterInfo | null>(null);
  // Null here is used as a loading state for the filter dataset rows
  const [filterDatasetRows, setFilterDatasetRows] = useState<Record<string, DatasetRow[] | null>>(
    {},
  );

  const sortedFilters = useMemo(
    () => sortBy(Object.values(config.filters), 'name'),
    [config.filters],
  );

  const selectedFilter = selectedFilterInfo ? config.filters[selectedFilterInfo.id] : undefined;
  const queryInfo = selectedFilter ? getFilterQueryInfo(selectedFilter) : null;

  const selectFilter = useCallback(
    (filterId: string, isNew = false) => {
      if (selectedFilterInfo?.id === filterId) return;

      setSelectedFilterInfo({ id: filterId, isNew });
      history.replace(`/blueprint/${canvasId}/filters?id=${filterId}`);
    },
    [canvasId, history, selectedFilterInfo],
  );

  const referencedFilters = useMemo(
    () => getFilterReferences(Object.values(config.datasets)),
    [config.datasets],
  );

  useEffect(() => {
    if (selectedFilterInfo || sortedFilters.length === 0) return;

    const filterId = query.get('id');
    const filter = config.filters[filterId ?? ''] ?? sortedFilters[0];
    selectFilter(filter.id);
  }, [selectedFilterInfo, config.filters, query, selectFilter, sortedFilters]);

  useEffect(() => {
    if (!queryInfo || !selectedFilter) return;

    const dataset = config.datasets[queryInfo.datasetId];
    const filterRows = filterDatasetRows[queryInfo.datasetId];
    if (filterRows !== undefined || !dataset || !dataset.query) return;

    setFilterDatasetRows({ ...filterDatasetRows, [dataset.id]: null });

    const postData = {
      dataset_id: dataset.id,
      query: dataset.query,
      variables: {},
      parent_schema_id: dataset.parent_schema_id,
      query_limit: 5000,
      customer_id: selectedUserGroup?.id,
      timezone: 'UTC',
    };

    dispatch(
      fetchCanvasFilterDatasetPreview({ postData }, (data) => {
        if (queryInfo.valuesConfig) {
          const { defaultValue } = getDefaultValueFromRows(
            queryInfo.valuesConfig,
            data.dataset_preview._rows,
          );
          setVariable(
            selectedFilter.provided_id,
            selectedFilter.filter_info?.filter_type === DASHBOARD_ELEMENT_TYPES.MULTISELECT
              ? ([defaultValue] as DashboardVariable)
              : defaultValue,
          );
        } else {
          setVariable(selectedFilter.provided_id, undefined);
        }
        setFilterDatasetRows({
          ...filterDatasetRows,
          [dataset.id]: data.dataset_preview._rows,
        });
      }),
    );
  }, [
    selectedFilter,
    queryInfo,
    filterDatasetRows,
    config.datasets,
    selectedUserGroup,
    dispatch,
    setVariable,
  ]);

  const renderFilterList = () => {
    return (
      <div className={sharedClasses.navContainer}>
        <Button
          fillWidth
          className={sprinkles({ marginBottom: 'sp1' })}
          icon="plus"
          onClick={(e) => {
            e.stopPropagation();

            const newId = `canvas-${canvasId}-${uuidv4()}`;
            dispatch(createCanvasFilter({ newId }));
            selectFilter(newId, true);
          }}
          type="primary">
          Create new filter
        </Button>
        {sortedFilters.map((filter) => {
          const isSelected = filter.id === selectedFilterInfo?.id;
          return (
            <div
              className={cx(sharedClasses.item, { [sharedClasses.selectedItem]: isSelected })}
              key={filter.id}
              onClick={(e) => {
                e.stopPropagation();
                selectFilter(filter.id);
              }}>
              <div className={sharedClasses.itemInfo}>
                <div className={sharedClasses.itemTitle}>{filter.name}</div>
                <div className={classes.itemAttrs}>
                  {filter.filter_info ? (
                    <div
                      className={sharedClasses.itemSubTitle}
                      style={{ marginRight: theme.spacing(1) }}>
                      {DASHBOARD_ELEMENT_TYPE_TO_NAME[filter.filter_info.filter_type]}
                    </div>
                  ) : null}
                  <div className={cx(sharedClasses.textOverflow, classes.filterIdTitle)}>
                    {`(${filter.provided_id})`}
                  </div>
                </div>
              </div>

              <div className={sharedClasses.itemStatus}>
                {canvasFilterHasError(filter, config.datasets) ? (
                  <Icon className={sharedClasses.errorIcon} icon="error" iconSize={16} />
                ) : referencedFilters[filter.provided_id] === undefined ? (
                  <Icon className={classes.warningIcon} icon="warning-sign" iconSize={16} />
                ) : null}
                {isSelected ? <ArrowIcon /> : null}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderFilterElement = (filterId: string, filterInfo: CanvasFilterType) => {
    const value = variables[filterId];

    const getDatasetRows = () => {
      if (!queryInfo) return {};
      const filterRows = filterDatasetRows[queryInfo.datasetId];
      if (filterRows) return { [queryInfo.datasetId]: { _rows: filterRows } };
      return {};
    };

    switch (filterInfo.filter_type) {
      case DASHBOARD_ELEMENT_TYPES.DROPDOWN:
        return (
          <DashboardDropdownElement
            openElementToLeft
            config={filterInfo.config}
            datasets={getDatasetRows()}
            onNewValueSelect={(newValue) => setVariable(filterId, newValue)}
            value={value}
            variables={variables}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.MULTISELECT:
        return (
          <DashboardMultiSelectElement
            openElementToLeft
            config={filterInfo.config}
            datasets={getDatasetRows()}
            onNewValueSelect={(newValue) => setVariable(filterId, newValue)}
            value={value}
            variables={variables}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.TOGGLE:
        return (
          <DashboardToggleElement
            config={filterInfo.config}
            datasets={getDatasetRows()}
            onNewValueSelect={(newValue) => setVariable(filterId, newValue)}
            value={value}
            variables={variables}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER:
        return (
          <DashboardDateRangePickerElement
            openElementToLeft
            config={filterInfo.config}
            onNewValueSelect={(newValue) => setVariable(filterId, newValue)}
            timezone="UTC"
            value={value}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.DATEPICKER:
        return (
          <DashboardDatepickerElement
            openElementToLeft
            config={filterInfo.config}
            onNewValueSelect={(newValue) => setVariable(filterId, newValue)}
            timezone="UTC"
            value={value}
          />
        );
    }
  };

  const renderFilter = (providedId: string, filterInfo: CanvasFilterType) => {
    return (
      <>
        <ColumnHeader title="Preview" />
        <div className={classes.filterView}>
          <div style={{ width: '100%' }}>{renderFilterElement(providedId, filterInfo)}</div>
          <div className={classes.filterViewText}>
            Interact with the filter to see how it affects the charts.
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={sharedClasses.root}>
      <CanvasNav config={config}>{renderFilterList()}</CanvasNav>
      <div className={sharedClasses.configMenu}>
        {selectedFilter ? (
          <FilterConfig
            datasets={config.datasets}
            filter={selectedFilter}
            isNewFilter={selectedFilterInfo?.isNew ?? false}
            referencedFilters={referencedFilters}
            resetFilterRows={() => {
              if (!queryInfo) return;
              setFilterDatasetRows((oldRows) => {
                delete oldRows[queryInfo.datasetId];
                return oldRows;
              });
            }}
            setVariable={setVariable}
          />
        ) : (
          <div className={sharedClasses.emptyContainer}>Select a Filter</div>
        )}
      </div>
      <div className={sharedClasses.elementView}>
        {selectedFilter ? (
          selectedFilter.filter_info ? (
            <>
              {renderFilter(selectedFilter.provided_id, selectedFilter.filter_info)}
              <FilterPreview
                datasets={config.datasets}
                filterProvidedId={selectedFilter.provided_id}
                holdDataLoad={queryInfo ? !filterDatasetRows[queryInfo.datasetId] : false}
                referencedFilters={referencedFilters}
                variables={variables}
              />
            </>
          ) : (
            <div className={sharedClasses.emptyContainer}>Set Up Filter</div>
          )
        ) : (
          <div className={sharedClasses.emptyContainer}>Select a Filter</div>
        )}
      </div>
    </div>
  );
}
