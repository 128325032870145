import {
  GeneralFormatOptionsOperationType,
  UpdateInstructionsPayload,
} from 'actions/updateInstructionsActions';
import { OPERATION_TYPES } from 'constants/types';
import { DataPanel } from 'types/exploResource';

export function updateInstructions(dataPanel: DataPanel, update: UpdateInstructionsPayload): void {
  const instructions = dataPanel.visualize_op.instructions;
  if (!instructions) return;

  switch (update.operationType) {
    case OPERATION_TYPES.VISUALIZE_TABLE:
      instructions.VISUALIZE_TABLE = update.instructions;
      break;
    case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_LINE_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_AREA_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_PIE_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_FUNNEL_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2:
      instructions.V2_TWO_DIMENSION_CHART = update.instructions;
      break;
    case OPERATION_TYPES.VISUALIZE_NUMBER_V2:
      instructions.V2_KPI = update.instructions;
      break;
    case GeneralFormatOptionsOperationType:
      dataPanel.visualize_op.generalFormatOptions = update.instructions;
      break;
  }
}
