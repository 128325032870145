import { FC, useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { isIdle, isLoading, isSuccess } from 'remotedata';
import { v4 as uuidv4 } from 'uuid';

import { bulkEnqueueJobs } from 'actions/jobQueueActions';
import {
  createReportBuilderEmailCadence,
  deleteReportBuilderEmailCadence,
  listReportBuilderEmailCadences,
  ReportBuilderEmailCadence,
  updateReportBuilderEmailCadence,
} from 'actions/reportBuilderEmailCadenceActions';
import { ErrorResponse } from 'actions/responseTypes';
import { ACTION } from 'actions/types';

import { Jobs } from 'components/JobQueue/types';
import { ScheduledExportModal } from 'components/resource/ScheduledExportModal/ScheduledExportModal';
import { closeReportModal } from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { EmailBody } from 'types/emailCadence';

type Props = {
  reportName: string;
};

export const ScheduleExportModal: FC<Props> = ({ reportName }) => {
  const dispatch = useDispatch();

  const { selectedReportId, reportBuilder, emailCadences, customerToken } = useSelector(
    (state: ReportBuilderReduxState) => ({
      customerToken: state.embeddedReportBuilder.requestInfo.customerToken,
      reportBuilder: state.embeddedReportBuilder.reportBuilder,
      emailCadences: state.reportEditing.emailCadences,
      selectedReportId: state.reportEditing.selectedReportId,
    }),
    shallowEqual,
  );

  const reportBuilderId = isSuccess(reportBuilder) && reportBuilder.data.id;
  const reportBuilderEmbedId = isSuccess(reportBuilder) && reportBuilder.data.embed_id;
  const isEmailCadencesIdle = isIdle(emailCadences);

  useEffect(() => {
    isEmailCadencesIdle &&
      selectedReportId &&
      reportBuilderEmbedId &&
      dispatch(
        listReportBuilderEmailCadences({
          customerToken,
          postData: {
            customer_report_id: selectedReportId,
            report_builder_embed_id: reportBuilderEmbedId,
          },
        }),
      );
  }, [customerToken, dispatch, isEmailCadencesIdle, reportBuilderEmbedId, selectedReportId]);

  const createExportCadence = useCallback(
    (
      email: EmailBody,
      onSuccess: (data: { email: ReportBuilderEmailCadence }) => void,
      onFailure: (errorMsg: ErrorResponse) => void,
    ) => {
      if (!selectedReportId || !reportBuilderId) return;

      return dispatch(
        createReportBuilderEmailCadence(
          {
            customerToken,
            postData: {
              email,
              report_builder_id: reportBuilderId,
              customer_report_id: selectedReportId,
            },
          },
          onSuccess,
          onFailure,
        ),
      );
    },
    [customerToken, dispatch, reportBuilderId, selectedReportId],
  );

  const updateExportCadence = useCallback(
    (
      emailId: string,
      email: EmailBody,
      onSuccess: (data: { email: ReportBuilderEmailCadence }) => void,
      onFailure: (errorMsg: ErrorResponse) => void,
    ) =>
      dispatch(
        updateReportBuilderEmailCadence(
          {
            customerToken,
            postData: {
              email,
              email_id: emailId,
            },
          },
          onSuccess,
          onFailure,
        ),
      ),
    [customerToken, dispatch],
  );

  const deleteExportCadence = useCallback(
    (emailId: string) =>
      dispatch(deleteReportBuilderEmailCadence({ customerToken, postData: { email_id: emailId } })),
    [customerToken, dispatch],
  );

  const sendTestExport = useCallback(
    (emailId: string, onSuccess: (jobs: Record<string, Jobs>) => void) => {
      const jobs = {
        [uuidv4()]: {
          job_type: ACTION.SEND_TEST_CUSTOMER_REPORT_CADENCE_EMAIL,
          job_args: { report_builder_email_cadence_id: emailId, is_test_email: true },
        },
      };
      dispatch(bulkEnqueueJobs({ jobs, customerToken }, onSuccess));
    },
    [customerToken, dispatch],
  );

  const sendTestDraftExport = useCallback(
    (
      recipients: string[],
      subject: string,
      message: string,
      onSuccess: (jobs: Record<string, Jobs>) => void,
    ) => {
      const jobs = {
        [uuidv4()]: {
          job_type: ACTION.SEND_TEST_CUSTOMER_REPORT_CADENCE_EMAIL_BY_PARAMS,
          job_args: {
            customer_report_id: selectedReportId,
            recipients,
            subject,
            message,
          },
        },
      };

      dispatch(bulkEnqueueJobs({ jobs, customerToken }, onSuccess));
    },
    [customerToken, dispatch, selectedReportId],
  );

  return (
    <ScheduledExportModal
      closeModal={() => dispatch(closeReportModal())}
      createExportCadence={createExportCadence}
      customerToken={customerToken}
      deleteExportCadence={deleteExportCadence}
      emails={isSuccess(emailCadences) ? emailCadences.data : []}
      loading={isLoading(emailCadences)}
      resourceName={reportName}
      sendTestDraftExport={sendTestDraftExport}
      sendTestExport={sendTestExport}
      updateExportCadence={updateExportCadence}
    />
  );
};
