import { defineEmbedPostAction } from './actionUtils';
import { ACTION } from './types';

export interface CustomerReportBuilderMetadata {
  id: string;
  customer: string;
  favorite_built_ins: string[];
  created: string;
  modified: string;
}

interface ToggleFavoriteCustomerReportBuilderMetadataBody {
  report_builder_embed_id: string;
  built_in_id: string;
}

interface ToggleFavoriteCustomerReportBuilderMetadataResponse {
  favorite_built_ins: string[];
}

export const { actionFn: toggleFavoriteBuiltIn, successAction: toggleFavoriteBuiltInSuccess } =
  defineEmbedPostAction<
    ToggleFavoriteCustomerReportBuilderMetadataBody,
    ToggleFavoriteCustomerReportBuilderMetadataResponse
  >(ACTION.TOGGLE_FAVORITE_BUILT_IN, 'customer_reports', 'toggle_favorite_built_in', 'POST');
