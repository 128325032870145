import { useEffect, useContext, useState } from 'react';
import { Popover, Position } from '@blueprintjs/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { IconButton } from 'components/ds';

import { DatasetSchema } from 'types/datasets';
import { UserTransformedSchema } from 'constants/types';
import { TransformationSettingsMenu } from './TransformationSettingsMenu';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import DashboardLayoutContext from 'components/DashboardLayout/DashboardLayoutContext';

const useStyles = makeStyles((theme: Theme) => ({
  popoverContainer: {
    '& .bp3-popover.bp3-minimal': {
      margin: `${theme.spacing(1)}px 0px 0px !important`,
    },
    '& .bp3-popover': {
      boxShadow: 'none',
      border: `1px solid ${theme.palette.ds.grey400}`,
    },
  },
}));

type Props = {
  loading?: boolean;
  schema: DatasetSchema;
  userTransformedSchema?: UserTransformedSchema;
  updateUserTransformedSchema: (newSchema: UserTransformedSchema) => void;
};

export default function TransformationSettingsButton({
  loading,
  schema,
  userTransformedSchema,
  updateUserTransformedSchema,
}: Props) {
  const classes = useStyles();
  const { dashboardLayoutTagId } = useContext(DashboardLayoutContext);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  useEffect(() => {
    if (schema.length && !userTransformedSchema) {
      const initializedUserTransformedSchema = schema.map((column) => ({
        ...column,
        isVisible: true,
      }));
      updateUserTransformedSchema(initializedUserTransformedSchema);
    }
  }, [schema, userTransformedSchema, updateUserTransformedSchema]);

  return (
    <Popover
      minimal
      content={
        userTransformedSchema && (
          <TransformationSettingsMenu
            schema={userTransformedSchema}
            updateSchema={updateUserTransformedSchema}
          />
        )
      }
      isOpen={isPopoverOpen}
      onClose={() => setIsPopoverOpen(false)}
      portalClassName={classes.popoverContainer}
      portalContainer={document.getElementById(dashboardLayoutTagId) ?? undefined}
      position={Position.BOTTOM_RIGHT}>
      <IconButton
        className={GLOBAL_STYLE_CLASSNAMES.text.secondaryColor.color}
        disabled={loading}
        name="gear"
        onClick={() => setIsPopoverOpen(true)}
      />
    </Popover>
  );
}
