import { cloneDeep } from 'utils/standard';
import { useDispatch } from 'react-redux';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES, V2TwoDimensionChartInstructions } from 'constants/types';
import { Switch } from 'components/ds';
import { sharedStyles } from './styles';

type Props = {
  instructions: V2TwoDimensionChartInstructions;
  visualizationType: string;
};

export default function FormatTimeSeriesDataConfig({ instructions, visualizationType }: Props) {
  const classes = sharedStyles();
  const dispatch = useDispatch();

  return (
    <div className={classes.root}>
      <Switch
        useCustomStyles
        className={classes.configInput}
        label="Hide data from latest period"
        onChange={() => {
          const newInstructions = cloneDeep(instructions);

          newInstructions.chartSpecificFormat = {
            ...newInstructions.chartSpecificFormat,
            timeSeriesDataFormat: {
              ...newInstructions?.chartSpecificFormat?.timeSeriesDataFormat,
              hideLatestPeriodData:
                !instructions.chartSpecificFormat?.timeSeriesDataFormat?.hideLatestPeriodData,
            },
          };
          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
        switchOn={instructions.chartSpecificFormat?.timeSeriesDataFormat?.hideLatestPeriodData}
      />
    </div>
  );
}
