import { useEffect, useState, useRef } from 'react';
import produce from 'immer';

import {
  FilterOperationInstructions,
  OPERATION_TYPES,
  VisualizeTableInstructions,
} from 'constants/types';
import { getChangedSchema, removeUserDisabledColumns } from 'utils/dashboardUtils';
import { AdHocOperationInstructions, VisualizeOperation } from 'types/dataPanelTemplate';
import { DatasetSchema, DatasetRow, DatabaseUnsupportedOperations } from 'types/datasets';
import { ReportBuilderStep, REPORT_BUILDER_STEP_ORDER } from './constants';
import NameReportStep from './Steps/NameReportStep';
import StartStep from './Steps/StartStep';
import SelectContentStep from './Steps/SelectContentStep';
import { UserTransformedSchema } from 'constants/types';
import PreviewStep from './Steps/PreviewStep';
import ExportStep from './Steps/ExportStep';
import NeedsConfigurationPanel from '../needsConfigurationPanel';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { sortSchemaByOrderedColumnNames } from 'utils/general';
import { SpreadsheetType } from '../../../../reducers/dashboardLayoutReducer';

type ReportConfiguration = {
  name?: string;
  userTransformedSchema: UserTransformedSchema;
};

type Props = {
  adHocOperationInstructions: AdHocOperationInstructions;
  dataPanelId: string;
  error?: boolean;
  isEmbed: boolean;
  loading?: boolean;
  onAdHocFilterInfoUpdate: (adHocFilterInfo: FilterOperationInstructions) => void;
  onAdHocSortOrPageUpdate: (adHocOperationInstructions: AdHocOperationInstructions) => void;
  onDownloadPanelSpreadsheet: (fileFormat: SpreadsheetType, schema?: UserTransformedSchema) => void;
  onDownloadPanelPdf: (
    adHocOperationInstructions: AdHocOperationInstructions,
    schema?: UserTransformedSchema,
    reportName?: string,
  ) => void;
  rows: Record<string, string | number>[];
  secondaryData: DatasetRow[];
  schema: DatasetSchema;
  sourceDataRowCount?: number;
  unsupportedOperations: DatabaseUnsupportedOperations[] | undefined;
  updateDataTableOperation: (visualizeInstructions: VisualizeTableInstructions) => void;
  visualizeOperation: VisualizeOperation;
  variables: DashboardVariableMap;
};

export default function ReportBuilder({
  adHocOperationInstructions,
  dataPanelId,
  error,
  isEmbed,
  loading,
  onAdHocFilterInfoUpdate,
  onAdHocSortOrPageUpdate,
  onDownloadPanelSpreadsheet,
  onDownloadPanelPdf,
  rows,
  secondaryData,
  schema,
  sourceDataRowCount,
  unsupportedOperations,
  updateDataTableOperation: updateVisualizeOperation,
  visualizeOperation,
  variables,
}: Props) {
  const [currentStep, setCurrentStep] = useState<ReportBuilderStep>(REPORT_BUILDER_STEP_ORDER[0]);
  const [reportConfiguration, setReportConfiguration] = useState<ReportConfiguration>({
    userTransformedSchema: getChangedSchema(
      schema,
      visualizeOperation.instructions.VISUALIZE_TABLE,
    ).map((col) => ({
      ...col,
      isVisible: false,
    })),
  });

  useEffect(
    function checkIfColumnsRemovedFromSchema() {
      const maybeChangedSchema = getChangedSchema(
        schema,
        visualizeOperation.instructions.VISUALIZE_TABLE,
      );
      const currentUserTransformedSchema = reportConfiguration.userTransformedSchema;
      let newUserSchema: UserTransformedSchema = [];

      const isColumnAdded = maybeChangedSchema.length > currentUserTransformedSchema.length;
      const isColumnRemoved = maybeChangedSchema.length < currentUserTransformedSchema.length;

      if (isColumnAdded) {
        newUserSchema = produce(currentUserTransformedSchema, (draft) => {
          maybeChangedSchema.forEach((maybeAddedColumn, index) => {
            const isNewlyAddedColumn = !draft.some((col) => col.name === maybeAddedColumn.name);

            if (isNewlyAddedColumn) {
              draft.splice(index, 0, { ...maybeAddedColumn, isVisible: false });
            }
          });
        });
      } else if (isColumnRemoved) {
        newUserSchema = produce(currentUserTransformedSchema, (draft) => {
          draft.forEach((oldCol, index) => {
            const isColumnDeleted = !maybeChangedSchema.some(
              (newCol) => newCol.name === oldCol.name,
            );

            if (isColumnDeleted) {
              draft.splice(index, 1);
            }
          });
        });
      } else {
        return;
      }

      setReportConfiguration({ ...reportConfiguration, userTransformedSchema: newUserSchema });
    },
    [reportConfiguration, schema, visualizeOperation],
  );

  const prevOrderedColumnNames = useRef<string[]>();
  // Check if schema has been resorted in format tab for default sorting in config
  useEffect(() => {
    const orderedColumnNames = visualizeOperation.instructions.VISUALIZE_TABLE.orderedColumnNames;
    if (
      visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_REPORT_BUILDER &&
      currentStep === ReportBuilderStep.NAME_REPORT &&
      prevOrderedColumnNames.current !== orderedColumnNames
    ) {
      setReportConfiguration({
        ...reportConfiguration,
        userTransformedSchema: sortSchemaByOrderedColumnNames(
          reportConfiguration.userTransformedSchema,
          orderedColumnNames,
        ),
      });
      prevOrderedColumnNames.current = orderedColumnNames;
    }
  }, [
    visualizeOperation.instructions.VISUALIZE_TABLE.orderedColumnNames,
    visualizeOperation.operation_type,
    currentStep,
    prevOrderedColumnNames,
    reportConfiguration,
  ]);

  const pivotInstructions = visualizeOperation.instructions.VISUALIZE_PIVOT_TABLE;

  if (
    visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_PIVOT_REPORT_BUILDER &&
    (!pivotInstructions?.colColumn ||
      !pivotInstructions.rowColumn ||
      !pivotInstructions.aggregation)
  ) {
    return <NeedsConfigurationPanel fullHeight instructionsNeedConfiguration />;
  } else if (loading && !reportConfiguration.userTransformedSchema.length) {
    return <NeedsConfigurationPanel fullHeight loading />;
  }

  switch (currentStep) {
    case ReportBuilderStep.START:
      return <StartStep onNext={() => setCurrentStep(ReportBuilderStep.NAME_REPORT)} />;
    case ReportBuilderStep.NAME_REPORT:
      return (
        <NameReportStep
          name={reportConfiguration.name}
          onNext={() => setCurrentStep(ReportBuilderStep.SELECT_CONTENT)}
          updateReportName={(newName) => {
            const newConfiguration = produce(reportConfiguration, (draft) => {
              draft.name = newName;
            });

            setReportConfiguration(newConfiguration);
          }}
        />
      );
    case ReportBuilderStep.SELECT_CONTENT:
      return (
        <SelectContentStep
          isPivot={
            visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_PIVOT_REPORT_BUILDER
          }
          onBack={() => setCurrentStep(ReportBuilderStep.NAME_REPORT)}
          onNext={() => setCurrentStep(ReportBuilderStep.PREVIEW)}
          setUserTransformedSchema={(newSchema) => {
            const newConfiguration = produce(reportConfiguration, (draft) => {
              draft.userTransformedSchema = newSchema;
            });

            setReportConfiguration(newConfiguration);
          }}
          userTransformedSchema={reportConfiguration.userTransformedSchema}
        />
      );
    case ReportBuilderStep.PREVIEW:
      return (
        <PreviewStep
          adHocOperationInstructions={adHocOperationInstructions}
          dataPanelId={dataPanelId}
          error={error}
          isEmbed={isEmbed}
          loading={loading}
          onAdHocFilterInfoUpdate={onAdHocFilterInfoUpdate}
          onAdHocSortOrPageUpdate={onAdHocSortOrPageUpdate}
          onBack={() => setCurrentStep(ReportBuilderStep.SELECT_CONTENT)}
          onNext={() => setCurrentStep(ReportBuilderStep.EXPORT)}
          reportName={reportConfiguration.name}
          rows={rows}
          schema={removeUserDisabledColumns(reportConfiguration.userTransformedSchema)}
          secondaryData={secondaryData}
          sourceDataRowCount={sourceDataRowCount}
          unsupportedOperations={unsupportedOperations}
          updateDataTableOperation={updateVisualizeOperation}
          variables={variables}
          visualizeOperation={visualizeOperation}
        />
      );
    case ReportBuilderStep.EXPORT:
      return (
        <ExportStep
          dataPanelId={dataPanelId}
          onBack={() => setCurrentStep(ReportBuilderStep.PREVIEW)}
          onDownloadPanelPdf={() => {
            onDownloadPanelPdf(
              adHocOperationInstructions,
              reportConfiguration.userTransformedSchema,
              reportConfiguration.name,
            );
          }}
          onDownloadPanelSpreadsheet={(fileFormat) =>
            onDownloadPanelSpreadsheet(fileFormat, reportConfiguration.userTransformedSchema)
          }
          onNext={() => {
            setCurrentStep(ReportBuilderStep.START);

            setReportConfiguration({
              userTransformedSchema: getChangedSchema(
                schema,
                visualizeOperation.instructions.VISUALIZE_TABLE,
              ).map((col) => ({
                ...col,
                isVisible: false,
              })),
            });
          }}
        />
      );
  }
}
