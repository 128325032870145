import { useState } from 'react';
import cx from 'classnames';

import { Classes, Intent, FormGroup } from '@blueprintjs/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import DropdownSelect from 'shared/DropdownSelect';
import Modal from 'components/core/Modal';
import { ParentSchema } from 'actions/dataSourceActions';
import Button from 'shared/Button';
import InputGroup from 'explo-ds/forms/marketing/inputGroup';

type Props = {
  modalOpen: boolean;
  onClose: () => void;
  onSubmit: (newDashboardTitle: string, parent_schema_id: number) => void;
  parentSchemas: ParentSchema[];
  defaultParentSchema?: ParentSchema;
  errorState?: (textFieldVal: string) => { isErrorState: boolean; errorMsg?: string };
  datasetName?: string;
  title: string;
  buttonTitle: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  formGroupValidation: {
    marginBottom: ({ error }: Record<'error', boolean>) => (error ? 0 : theme.spacing(5)),
  },
  textInputContainer: {
    marginBottom: theme.spacing(2),
  },
  modalBody: {
    marginTop: 0,
  },
}));

export default function DatasetModal({
  errorState,
  parentSchemas,
  defaultParentSchema,
  datasetName,
  onSubmit,
  onClose,
  modalOpen,
  title,
  buttonTitle,
}: Props) {
  const [textFieldVal, setTextFieldVal] = useState(datasetName || '');
  const [parentSchemaVal, setParentSchemaVal] = useState(
    // autopopulate if there already is a schema or if there's only one schema to select
    defaultParentSchema
      ? defaultParentSchema
      : parentSchemas.length === 1
      ? parentSchemas[0]
      : undefined,
  );
  const { isErrorState, errorMsg } = errorState?.(textFieldVal) ?? {};
  const classes = useStyles({ error: !!isErrorState });

  return (
    <Modal modalOpen={modalOpen} onClose={onClose} title={title}>
      <div className={cx(Classes.DIALOG_BODY, classes.modalBody)}>
        <FormGroup
          className={classes.formGroupValidation}
          helperText={errorMsg}
          intent={isErrorState ? Intent.DANGER : Intent.NONE}
          labelFor="text-input">
          <InputGroup
            className={classes.textInputContainer}
            onInputChange={setTextFieldVal}
            placeholder="Dataset Name"
            value={textFieldVal}
          />
          <DropdownSelect
            fillWidth
            minimal
            showIcon
            disabled={defaultParentSchema !== undefined}
            noSelectionText="Select Schema"
            onChange={(item) => {
              const itemId = parseInt(item.id);
              const parentSchema = parentSchemas?.find((schema) => schema.id === itemId);
              setParentSchemaVal(parentSchema);
            }}
            options={parentSchemas.map((parentSchema) => ({
              id: String(parentSchema.id),
              name: parentSchema.name,
            }))}
            // only enable schema selection if we're creating a new dataset
            selectedItem={
              parentSchemaVal && {
                id: String(parentSchemaVal.id),
                name: parentSchemaVal.name,
              }
            }
          />
        </FormGroup>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            disabled={
              isErrorState ||
              !textFieldVal ||
              textFieldVal.trim() === '' ||
              parentSchemaVal === undefined
            }
            onClick={() => {
              if (
                isErrorState ||
                !textFieldVal ||
                textFieldVal.trim() === '' ||
                parentSchemaVal === undefined
              ) {
                return;
              }
              onSubmit(textFieldVal || '', parentSchemaVal?.id || 0);
              setTextFieldVal('');
              setParentSchemaVal(defaultParentSchema);
              onClose();
            }}
            text={buttonTitle}
            type="primary"
          />
        </div>
      </div>
    </Modal>
  );
}
