import { FC, useContext } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { cloneDeep, map } from 'utils/standard';

import InputLabel from 'shared/InputLabel';
import ColorPickerButton from 'shared/ColorPicker';
import Button from 'shared/Button';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import { sprinkles } from 'components/ds';

import { DatasetRow, DatasetColumn } from 'types/datasets';
import { StringDisplayOptions } from 'constants/types';
import { DEFAULT_CATEGORY_COLORS } from 'constants/colorConstants';
import { getCategoricalColors, GlobalStylesContext } from 'globalStyles';

const useStyles = makeStyles((theme: Theme) => ({
  colorPickerBtn: {
    width: 32,
    height: 32,
  },
  addCategoryBtn: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '100%',

    '&.bp3-button': {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
      borderRadius: 4,
      fontSize: 14,
      fontWeight: 500,
      fontFamily: 'inherit',
      backgroundImage: 'none !important',
      justifyContent: 'left',

      backgroundColor: theme.palette.ds.grey300,

      '&:hover': {
        backgroundColor: theme.palette.ds.hovered.grey300,
      },
      '&:active': {
        backgroundColor: theme.palette.ds.pressed.grey300,
      },

      '&.bp3-disabled': {
        backgroundColor: theme.palette.ds.grey400,
        color: theme.palette.ds.grey700,
        '&.bp3-button:hover': {
          backgroundColor: theme.palette.ds.grey400,
        },
      },
      '&.bp3-active': {
        boxShadow: 'none',
      },
    },
  },
}));

const colorPickerClass = sprinkles({ marginRight: 'sp2' });
const categoryRowClass = sprinkles({ flexItems: 'alignCenter', marginBottom: 'sp.5' });

type Props = {
  updateStringOptions: (newFields: StringDisplayOptions) => void;
  column: DatasetColumn;
  displayOptions: StringDisplayOptions | undefined;
  dataPanelData: DatasetRow[];
};

export const CategoryFieldColorAssignment: FC<Props> = ({
  updateStringOptions,
  column,
  displayOptions,
  dataPanelData,
}) => {
  const classes = useStyles();
  const context = useContext(GlobalStylesContext);
  const { categoryColorAssignments, addedCategories } = displayOptions ?? {};

  const columnCategories = new Set(map(dataPanelData, column.name));

  return (
    <>
      <InputLabel className={sprinkles({ marginY: 'sp1' })} text="Color Palette" />
      {Array.from(columnCategories).map((category, index) => {
        return (
          <div className={categoryRowClass} key={`color-category-${category}`}>
            <ColorPickerButton
              btnClassName={classes.colorPickerBtn}
              className={colorPickerClass}
              color={categoryColorAssignments?.[category] || DEFAULT_CATEGORY_COLORS[index % 12]}
              colorPalette={getCategoricalColors(context.globalStyleConfig)}
              onColorChange={(newColor) => {
                const newCategoryColorAssignments = cloneDeep(categoryColorAssignments || {});
                newCategoryColorAssignments[category] = newColor;

                updateStringOptions({ categoryColorAssignments: newCategoryColorAssignments });
              }}
            />
            <div className={sprinkles({ color: 'black' })}>{String(category)}</div>
          </div>
        );
      })}
      {addedCategories?.map(({ name, color }, index) => {
        return (
          <div className={categoryRowClass} key={`color-category-${name}-${index}`}>
            <ColorPickerButton
              btnClassName={classes.colorPickerBtn}
              className={colorPickerClass}
              color={color}
              colorPalette={getCategoricalColors(context.globalStyleConfig)}
              onColorChange={(newColor) => {
                const newAddedCategories = cloneDeep(addedCategories || []);
                newAddedCategories[index].color = newColor;

                updateStringOptions({ addedCategories: newAddedCategories });
              }}
            />
            <InputWithBlurSave
              hideRightIconInteractions
              containerClassName={sprinkles({ width: 'fill' })}
              initialValue={name}
              onNewValueSubmitted={(newName) => {
                const newAddedCategories = cloneDeep(addedCategories || []);
                newAddedCategories[index].name = newName;

                updateStringOptions({ addedCategories: newAddedCategories });
              }}
            />
            <Button
              minimal
              icon="cross"
              onClick={() => {
                const newAddedCategories = cloneDeep(addedCategories || []);

                newAddedCategories.splice(index, 1);

                updateStringOptions({ addedCategories: newAddedCategories });
              }}
            />
          </div>
        );
      })}
      <Button
        className={classes.addCategoryBtn}
        icon="plus"
        onClick={() => {
          const newAddedCategories = cloneDeep(addedCategories || []);
          newAddedCategories.push({
            name: '',
            color: context.globalStyleConfig.visualizations.categoricalPalette.hue1,
          });
          updateStringOptions({ addedCategories: newAddedCategories });
        }}
        text="Add a category"
      />
    </>
  );
};
