import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Button, sprinkles } from 'components/ds';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { BuiltInReportCarousel } from 'pages/ReportBuilder/HomeView/BuiltInReportCarousel';
import { ReportListItem } from 'pages/ReportBuilder/HomeView/ReportListItem';

import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { orderBy } from 'utils/standard';
import { getTimezoneAwareUnix } from 'utils/timezoneUtils';

import * as styles from './AllReportTab.css';

interface Props {
  reportBuilderEmbedId: string;
  createReport: () => void;
  customerToken: string;
}

export const AllReportTab: FC<Props> = ({ createReport, customerToken, reportBuilderEmbedId }) => {
  const reports = useSelector(
    (state: ReportBuilderReduxState) => state.embeddedReportBuilder.reports,
  );

  const filteredAndSortedReports = useMemo(() => {
    return orderBy(reports, (report) => getTimezoneAwareUnix(report.modified), 'desc');
  }, [reports]);

  const renderEmptyState = () => (
    <div className={styles.emptyState}>
      <EmbedText body="b1" color="contentPrimary">
        No reports created
      </EmbedText>
      <Button className={sprinkles({ marginTop: 'sp1' })} onClick={createReport} type="tertiary">
        Create your first report
      </Button>
    </div>
  );

  return (
    <div className={styles.reportTabContainer}>
      <BuiltInReportCarousel reportBuilderEmbedId={reportBuilderEmbedId} />
      <div className={styles.reportListContainer}>
        {filteredAndSortedReports.length === 0
          ? renderEmptyState()
          : filteredAndSortedReports.map((report) => (
              <ReportListItem customerToken={customerToken} key={report.id} report={report} />
            ))}
      </div>
    </div>
  );
};
