import { createReducer, createSelector } from '@reduxjs/toolkit';
import * as RD from 'remotedata';

import {
  addCustomerSuccess,
  deleteCustomersSuccess,
  editCustomersSuccess,
  fetchCustomersError,
  fetchCustomersRequest,
  fetchCustomersSuccess,
} from 'actions/customerActions';
import { syncParentSchemaSuccess } from 'actions/parentSchemaActions';
import { Customer, selectCustomer } from 'actions/teamActions';

const EXPLO_EUG_STORAGE_KEY = 'explo_customer';

interface CustomersReducerState {
  groups: RD.ResponseData<Customer[]>;
  selectedGroupId: number | null;
}

export const getSelectedCustomer = createSelector(
  (state: CustomersReducerState) => state.selectedGroupId,
  (state: CustomersReducerState) => state.groups,
  (selectedGroupId, groups) => {
    if (selectedGroupId === null || !RD.isSuccess(groups)) return null;

    return groups.data.find((group) => group.id === selectedGroupId) ?? null;
  },
);

const customersReducerInitialState: CustomersReducerState = {
  groups: RD.Idle(),
  selectedGroupId: null,
};

const selectFirstGroup = (state: CustomersReducerState) => {
  const firstGroup = RD.isSuccess(state.groups) ? state.groups.data[0] : undefined;
  return firstGroup?.id ?? null;
};

const sortCustomers = (groups: Customer[]) => {
  return groups.sort((groupA: Customer, groupB: Customer) => {
    if (!groupA.name.trim()) return 1;
    if (!groupB.name.trim()) return -1;
    return groupA.name.localeCompare(groupB.name);
  });
};

const receiveCustomers = (state: CustomersReducerState, groups: Customer[]) => {
  const sortedGroups = sortCustomers(groups);
  state.groups = RD.Success(sortedGroups);

  if (state.selectedGroupId === null && sortedGroups.length > 0) {
    const storageGroup = parseInt(localStorage.getItem(EXPLO_EUG_STORAGE_KEY) || '');
    if (!isNaN(storageGroup)) {
      if (sortedGroups.find((group) => group.id === storageGroup)) {
        state.selectedGroupId = storageGroup;
        return;
      }
    }
    state.selectedGroupId = sortedGroups[0].id;
  } else if (
    state.selectedGroupId !== null &&
    !sortedGroups.find((group) => group.id === state.selectedGroupId)
  ) {
    state.selectedGroupId = selectFirstGroup(state);
  }
};

export default createReducer(customersReducerInitialState, (builder) => {
  builder
    .addCase(fetchCustomersRequest, (state) => {
      state.groups = RD.Loading();
    })
    .addCase(fetchCustomersError, (state) => {
      state.groups = RD.Error('Error Loading End User Groups');
    })
    .addCase(fetchCustomersSuccess, (state, { payload }) => {
      receiveCustomers(state, payload.customers);
    })
    .addCase(addCustomerSuccess, (state, { payload }) => {
      state.groups = RD.map(state.groups, (groups) => {
        groups.push(payload.customer);
        return sortCustomers(groups);
      });
    })
    .addCase(deleteCustomersSuccess, (state, { payload }) => {
      state.groups = RD.map(state.groups, (groups) =>
        groups.filter((group) => group.id !== payload.id),
      );
      if (state.selectedGroupId === payload.id) state.selectedGroupId = selectFirstGroup(state);
    })
    .addCase(editCustomersSuccess, (state, { payload }) => {
      RD.update(state.groups, (groups) => {
        const userGroup = groups.find((endUser) => endUser.id === payload.user_group.id);
        if (userGroup) {
          userGroup.name = payload.user_group.name;
          userGroup.parent_schema_datasource_mapping =
            payload.user_group.parent_schema_datasource_mapping;
          userGroup.provided_id = payload.user_group.provided_id;
          userGroup.access_group_id = payload.user_group.access_group_id;
          userGroup.is_demo_group = payload.user_group.is_demo_group;
          userGroup.properties = payload.user_group.properties;
        }
      });
    })
    .addCase(syncParentSchemaSuccess, (state, { payload }) => {
      receiveCustomers(state, payload.customers);
    })
    .addCase(selectCustomer, (state, { payload }) => {
      if (state.selectedGroupId === payload) return;

      state.selectedGroupId = payload;
      localStorage.setItem(EXPLO_EUG_STORAGE_KEY, String(payload));
    })
    .addDefaultCase((state) => state);
});
