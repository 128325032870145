import { useState } from 'react';
import { ReduxState } from 'reducers/rootReducer';
import { connect } from 'react-redux';
import parse from 'url-parse';
import minimatch from 'minimatch';
import { makeStyles, Theme } from '@material-ui/core/styles';
import validator from 'validator';

import Button from 'shared/Button';
import InputWithTag from 'shared/InputWithTag';
import SettingsDomainWhitelistRule from './settingsDomainWhitelistRule';
import CalloutLink from 'shared/CalloutLink';
import { Intent } from 'components/ds';
import { IconName } from 'components/ds/Icon';
import { TextFieldModal } from 'components/modals/textFieldModal';

import { ACTION } from 'actions/types';
import { WhitelistDomain } from 'actions/teamActions';
import { createLoadingSelector } from 'reducers/api/selectors';
import { createWhitelistDomain } from 'actions/whitelistDomainActions';
import { showErrorContactSupportToast } from 'shared/sharedToasts';

const useStyles = makeStyles((theme: Theme) => ({
  subsection: {
    fontSize: 20,
    fontWeight: 600,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rulesContainerHeader: {
    fontWeight: 500,
    fontSize: '16px',
  },
  testContainer: {
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(8),
  },
  testHeader: {
    color: theme.palette.ds.grey900,
  },
  testInput: {},
  documentationBox: {
    marginTop: theme.spacing(4),
  },
  subtitle: {
    color: theme.palette.ds.grey700,
    marginBottom: theme.spacing(3),
  },
}));

type VerificationState =
  | {
      statusIntent?: Intent;
      statusIcon?: IconName;
    }
  | undefined;

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

function SettingsDomainWhitelistSection(props: Props) {
  const { currentUser, createWhitelistDomain, whitelistDomains, whitelistDomainsLoading } = props;
  const classes = useStyles();

  const [isAddWhitelistDomainModalOpen, setAddWhitelistDomainModalOpen] = useState<boolean>(false);
  const [urlVerificationStatus, setUrlVerificationStatus] = useState<VerificationState>(undefined);
  const [testedUrl, setTestedUrl] = useState<string>('');

  const onTestUrlChange = (url: string) => {
    let newStatus: VerificationState = undefined;

    if (url) {
      if (testUrl(url, whitelistDomains)) {
        newStatus = {
          statusIntent: Intent.SUCCESS,
          statusIcon: 'check',
        };
      } else {
        newStatus = {
          statusIntent: Intent.ERROR,
          statusIcon: 'cross',
        };
      }
    }

    setTestedUrl(url);
    setUrlVerificationStatus(newStatus);
  };

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.subsection}>Domain Whitelisting</div>
        <Button
          onClick={() => setAddWhitelistDomainModalOpen(true)}
          text="Add a Rule"
          type="primary"
        />
      </div>
      <div className={classes.subtitle}>
        {'Allow your domains to view your dashboards using either hard-coded urls or * wildcards.'}
      </div>
      <div className={classes.testContainer}>
        <InputWithTag
          className={classes.testInput}
          label="Test a URL"
          onChange={onTestUrlChange}
          placeholder="http://test.example.com"
          statusInfo={urlVerificationStatus}
          value={testedUrl}
        />
      </div>
      <div>
        <div className={classes.rulesContainerHeader}>Rules</div>
        {!whitelistDomainsLoading &&
          whitelistDomains &&
          whitelistDomains.map((whitelistDomain) => (
            <SettingsDomainWhitelistRule
              key={`whitelist-domain-rule=${whitelistDomain.id}`}
              rule={whitelistDomain}
            />
          ))}
      </div>
      <TextFieldModal
        buttonName="Save rule"
        closeModal={() => setAddWhitelistDomainModalOpen(false)}
        errorState={(textFieldVal) => {
          if (
            textFieldVal === undefined ||
            (textFieldVal !== '' &&
              textFieldVal !== '*' &&
              !validator.matches(textFieldVal, /^http(s)?:\/{2}([A-z0-9\-*]+\.)+[A-z*]+$/))
          ) {
            return { isErrorState: true, errorMsg: 'Invalid Rule' };
          }
          return { isErrorState: false };
        }}
        modalOpen={isAddWhitelistDomainModalOpen}
        modalTitle="Add new whitelist rule"
        onSubmit={(rule: string) => {
          if (currentUser.team === null) return;
          createWhitelistDomain(
            { postData: { name: rule, team_id: currentUser.team.id } },
            () => setAddWhitelistDomainModalOpen(false),
            (response) => showErrorContactSupportToast(response.error_msg),
          );
        }}
        textFieldPlaceholder="https://*.domain.com"
      />
      <CalloutLink
        className={classes.documentationBox}
        text="Review the developer documentation to learn more"
        url="https://docs.explo.co/embedding-documentation/whitelisting-your-domain"
      />
    </div>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  currentUser: state.currentUser,
  whitelistDomainsLoading: createLoadingSelector([ACTION.FETCH_WHITELIST_DOMAIN], false)(state),
  whitelistDomains: state.whitelistDomains.whitelist_domains,
});

const testUrl = (url: string, whitelistDomains: WhitelistDomain[] | undefined) => {
  // pass in an empty base url so it doesn't use the browser's location
  const parsedTestUrl = parse(url, {});

  if (!whitelistDomains) return true;

  // duplicates the logic in request_handlers/handlers.py in the backend
  for (const whitelistDomain of whitelistDomains) {
    // short circuit if allow-all
    if (whitelistDomain.name === '*') return true;

    // less important to pass in an empty base url here
    // but doing it for consistency and safety
    const parsedPattern = parse(whitelistDomain.name, {});

    if (parsedPattern.protocol !== parsedTestUrl.protocol) continue;

    let splitTestUrl = parsedTestUrl.host.split('.');
    let splitPattern = parsedPattern.host.split('.');

    // it's valid for either to not include a subdomain,
    // so if xor either has www, remove it so that we can
    // check the other parts of the url
    if (splitPattern[0] === 'www' && splitTestUrl[0] !== 'www') {
      splitPattern = splitPattern.slice(1);
    } else if (splitTestUrl[0] === 'www' && splitPattern[0] !== 'www') {
      splitTestUrl = splitTestUrl.slice(1);
    }

    // now we know that the two should have the same number of nested domains
    // so if they don't we can short-circuit checking against this pattern
    if (splitPattern.length !== splitTestUrl.length) continue;

    let match = true;

    // iterate through the subdomains,
    // checking each part for a match
    for (let i = 0; i < splitPattern.length; i++) {
      if (!minimatch(splitTestUrl[i], splitPattern[i])) match = false;
    }

    // if we've made it to the end of iteration
    // and haven't found a mistmatch, then we've
    // found a matching domain!
    if (match) return true;
  }

  return false;
};

const mapDispatchToProps = { createWhitelistDomain };

export default connect(mapStateToProps, mapDispatchToProps)(SettingsDomainWhitelistSection);
