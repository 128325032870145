import { CustomerReportSort } from 'actions/customerReportActions';
import { MutableRefObject, useMemo, useState } from 'react';
import { TypeColumn } from '@inovua/reactdatagrid-enterprise/types';

import { ColumnHeader } from 'components/embed/EmbedDataGrid/ColumnHeader';

import { DatasetColumn } from 'types/datasets';
import {
  ColumnCategoryColorMapping,
  generateDataGridColumn,
  UseColumnsParams,
} from 'components/ds/DataGrid/columnUtils';

import * as styles from './index.css';

type UseEmbedColumnsParams = UseColumnsParams & {
  onSortColumn?: (sort: CustomerReportSort[]) => void;
  onFilterColumn?: (column: DatasetColumn) => void;

  // Ref for the parent container to create a boundary for Radix tooltips, dropdowns, and popovers
  containerRef?: MutableRefObject<HTMLElement | null>;
};

export const useEmbedColumns = (params: UseEmbedColumnsParams) => {
  const [columnToCategoryToColor, setColumnToCategoryToColor] =
    useState<ColumnCategoryColorMapping>({});

  const gridColumns: TypeColumn[] = useMemo(() => {
    const { columns, schema, rows, ...rest } = params;
    if (columns) return columns;
    if (!schema || !rows) return [];

    return schema.map((columnInfo, i) => {
      const baseColumn = generateDataGridColumn({
        ...rest,
        rows,
        columnInfo,
        columnToCategoryToColor,
        setColumnToCategoryToColor,
        isFirstColumn: i === 0,
      });

      return {
        ...baseColumn,
        headerProps: {
          ...baseColumn.headerProps,
          className: styles.columnHeader,
        },
        renderHeader: (cellProps) => (
          <ColumnHeader
            column={columnInfo}
            containerRef={params.containerRef}
            onFilter={params.onFilterColumn}
            onSort={params.onSortColumn}
            sort={cellProps.computedSortInfo}
          />
        ),
      };
    });
  }, [params, columnToCategoryToColor]);

  return gridColumns;
};
