import { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import validator from 'validator';
import cx from 'classnames';

import { Intent, TagInput } from '@blueprintjs/core';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import TextAreaWithBlurSave from 'pages/dataPanelEditorPage/textAreaWithBlurSave';
import Button from 'shared/Button';
import DropdownSelect from 'shared/DropdownSelect';
import InputLabel from 'shared/InputLabel';
import Poller from 'components/JobQueue/Poller';

import { EmailBody, EmailCadence } from 'types/emailCadence';
import { EMAIL_FREQUENCY } from 'constants/types';
import { getTimezoneOptions } from 'utils/timezoneUtils';
import { getEmailCadence, timeOptions, weekdayOptions, weekOfMonthOptions } from './utils';
import { Jobs } from 'components/JobQueue/types';

const useStyles = makeStyles((theme: Theme) => ({
  sectionTitle: {
    color: theme.palette.ds.grey900,
    fontSize: 14,
    lineHeight: '17px',
    fontWeight: 500,
    marginBottom: theme.spacing(2),
  },
  tagInput: {
    marginBottom: theme.spacing(6),

    '&:not(.bp3-intent-danger)': {
      boxShadow: `inset 0px 0px 0px 1px ${theme.palette.ds.grey300} !important`,
    },
    '&.bp3-active': {
      boxShadow: `inset 0px 0px 0px 1px ${theme.palette.ds.blue} !important`,
    },
    '& .bp3-tag-input-values': {
      marginTop: 4,
    },
    '& .bp3-input-ghost': {
      height: 24,
    },
  },
  recipientError: {
    marginTop: `-${theme.spacing(5)}px`,
    lineHeight: '15px',
    marginBottom: theme.spacing(6),
  },
  emailTag: {
    height: 24,
    backgroundColor: theme.palette.ds.lightBlue,
    color: theme.palette.ds.blue,
    borderRadius: 12,
    padding: `0px 8px`,
    marginBottom: `4px !important`,
  },
  messageTextArea: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6),
  },
  footer: {
    marginTop: theme.spacing(8),
    marginLeft: 'auto',
    '& >:first-child': {
      marginRight: theme.spacing(4),
    },
  },
  cadenceDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  flexOne: {
    flex: 1,
    maxWidth: 220,
  },
  marginRightDropdown: {
    marginRight: theme.spacing(2),
  },
  cadenceText: {
    margin: `0px ${theme.spacing(4)}px`,
    color: theme.palette.ds.black,
    fontWeight: 700,
    fontSize: 12,
    whiteSpace: 'nowrap',
  },
}));

type Props = {
  createExportCadence: (email: EmailBody, onSuccess: () => void, onFailure: () => void) => void;
  customerToken: string;
  editingEmail: EmailCadence | undefined;
  goToManage: () => void;
  resourceName: string;
  sendTestDraftExport: (
    recipients: string[],
    subject: string,
    message: string,
    onSuccess: (jobs: Record<string, Jobs>) => void,
  ) => void;
  updateExportCadence: (
    emailId: string,
    email: EmailBody,
    onSuccess: () => void,
    onFailure: () => void,
  ) => void;
};

export function ExportScheduler({
  createExportCadence,
  editingEmail,
  goToManage,
  customerToken,
  resourceName,
  sendTestDraftExport,
  updateExportCadence,
}: Props): JSX.Element {
  const classes = useStyles();

  const [recipients, setRecipients] = useState<string[]>(editingEmail?.recipients ?? []);
  const [recipientError, setRecipientError] = useState(false);
  const [subject, setSubject] = useState(
    editingEmail?.subject ?? `View the latest from ${resourceName}`,
  );
  const [message, setMessage] = useState(editingEmail?.message ?? '');

  const [cadence, setCadence] = useState(EMAIL_FREQUENCY.DAILY);
  const [dayOfWeek, setDayOfWeek] = useState(editingEmail?.day_of_week ?? 0);
  const [weekOfMonth, setWeekOfMonth] = useState(editingEmail?.week_of_month ?? 0);
  const [time, setTime] = useState(720);
  const [timezone, setTimezone] = useState(
    editingEmail?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
  );

  const [saveLoading, setSaveLoading] = useState(false);
  const [awaitedJobs, setAwaitedJobs] = useState<Record<string, Jobs>>({});

  useEffect(() => {
    if (editingEmail) {
      setTime(editingEmail.hour * 60 + editingEmail.minute);
      setCadence(getEmailCadence(editingEmail));
    } else {
      const date = new Date();
      const hours = date.getHours();
      const day = date.getDay();
      setTime(hours * 60 + (date.getMinutes() >= 30 ? 30 : 0));
      setDayOfWeek(day === 0 ? 6 : day - 1);
    }
  }, [editingEmail]);

  const sharedDropdownProps = {
    fillWidth: true,
    ignoreCustomStyles: true,
    filterable: false,
    containerClassName: classes.flexOne,
    minimal: true,
  };

  const emailReadyToSend = () => {
    if (recipients.length === 0) {
      setRecipientError(true);
      return false;
    }
    if (subject.trim() === '') return false;

    return true;
  };

  const updateOrCreateEmail = () => {
    if (!emailReadyToSend()) return;

    const hour = time / 60;
    const minute = Number.isInteger(hour) ? 0 : 30;

    const emailBody = {
      recipients,
      minute,
      hour: Math.floor(hour),
      week_of_month: cadence === EMAIL_FREQUENCY.MONTHLY ? weekOfMonth : null,
      day_of_week: cadence === EMAIL_FREQUENCY.DAILY ? null : dayOfWeek,
      timezone,
      subject,
      message,
    };

    const onSuccess = () => goToManage();
    const onFailure = () => setSaveLoading(false);

    setSaveLoading(true);

    editingEmail
      ? updateExportCadence(editingEmail.id, emailBody, onSuccess, onFailure)
      : createExportCadence(emailBody, onSuccess, onFailure);
  };

  const sendTestEmail = () => {
    if (!emailReadyToSend()) return;

    sendTestDraftExport(recipients, subject, message, (jobs) => {
      setAwaitedJobs(jobs);
    });
  };

  const renderNonDailyCadence = () => {
    if (cadence === EMAIL_FREQUENCY.DAILY) return null;
    const isWeekly = cadence === EMAIL_FREQUENCY.WEEKLY;
    return (
      <>
        <div className={classes.cadenceText}>{isWeekly ? 'on' : 'on the'}</div>
        {!isWeekly ? (
          <DropdownSelect
            {...sharedDropdownProps}
            containerClassName={cx(classes.flexOne, classes.marginRightDropdown)}
            noSelectionText=""
            onChange={(option) => setWeekOfMonth(parseInt(option.id))}
            options={weekOfMonthOptions}
            selectedItem={weekOfMonthOptions[weekOfMonth]}
          />
        ) : null}
        <DropdownSelect
          {...sharedDropdownProps}
          noSelectionText="Select Day of Week"
          onChange={(option) => setDayOfWeek(parseInt(option.id))}
          options={weekdayOptions}
          selectedItem={weekdayOptions[dayOfWeek]}
        />
      </>
    );
  };

  const renderCadence = () => {
    const timezoneOptions = getTimezoneOptions();
    const strTime = time.toString();
    return (
      <div className={classes.cadenceDiv}>
        <DropdownSelect
          {...sharedDropdownProps}
          noSelectionText="Select Cadence"
          onChange={(option) => setCadence(option.id as EMAIL_FREQUENCY)}
          options={Object.values(EMAIL_FREQUENCY).map((cadenceStr) => ({
            id: cadenceStr,
            name: cadenceStr,
          }))}
          selectedItem={{ id: cadence, name: cadence }}
        />
        {renderNonDailyCadence()}
        <div className={classes.cadenceText}>at</div>
        <DropdownSelect
          {...sharedDropdownProps}
          containerClassName={cx(classes.flexOne, classes.marginRightDropdown)}
          noSelectionText="Select Time"
          onChange={(option) => setTime(parseInt(option.id))}
          options={timeOptions}
          selectedItem={timeOptions.find(({ id }) => id === strTime)}
        />
        <DropdownSelect
          {...sharedDropdownProps}
          noSelectionText="Select Timezone"
          onChange={(option) => setTimezone(option.id)}
          options={timezoneOptions}
          selectedItem={timezoneOptions.find(({ id }) => id === timezone)}
        />
      </div>
    );
  };

  return (
    <>
      <div className={classes.sectionTitle}>Select Recipients</div>
      <TagInput
        addOnBlur
        addOnPaste
        className={classes.tagInput}
        intent={recipientError ? Intent.DANGER : Intent.NONE}
        onAdd={(newEmails) => {
          if (newEmails.some((email) => !validator.isEmail(email))) return;
          setRecipients([...recipients, ...newEmails]);
          setRecipientError(false);
        }}
        onRemove={(value) => setRecipients(recipients.filter((email) => email !== value))}
        placeholder="Add Recipient Email"
        tagProps={{ className: classes.emailTag }}
        values={recipients}
      />
      {recipientError ? (
        <InputLabel
          error
          ignoreCustomStyles
          className={classes.recipientError}
          text="You must insert at least one recipient"
        />
      ) : null}

      <div className={classes.sectionTitle}>Add Content</div>
      <InputWithBlurSave
        hideRightIconInteractions
        ignoreCustomStyles
        errorText={subject.trim() === '' ? 'Subject cannot be empty' : undefined}
        initialValue={subject}
        label="Subject line"
        onNewValueSubmitted={setSubject}
      />
      <TextAreaWithBlurSave
        ignoreCustomStyles
        containerClassName={classes.messageTextArea}
        initialValue={message}
        label="Message (optional)"
        onNewValueSubmitted={setMessage}
        placeholder="Add content to your email..."
      />

      <div className={classes.sectionTitle}>Scheduling</div>
      {renderCadence()}

      <div className={classes.footer}>
        <Button
          loading={Object.keys(awaitedJobs).length > 0}
          onClick={sendTestEmail}
          text="Send Test"
        />
        <Button
          loading={saveLoading}
          onClick={updateOrCreateEmail}
          text={editingEmail ? 'Update' : 'Schedule Email'}
          type="primary"
        />
      </div>

      <Poller
        awaitedJobs={awaitedJobs}
        customerToken={customerToken}
        updateJobResult={(finishedJobIds, onComplete) => {
          if (finishedJobIds.length > 0) setAwaitedJobs({});
          onComplete();
        }}
      />
    </>
  );
}
