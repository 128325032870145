import { MouseEvent as ReactMouseEvent, useState, useEffect, createRef, FC } from 'react';
import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';
import useEventListener from '@use-it/event-listener';

import DashboardDatasetEditor, {
  MENU_HEIGHT,
  INITIAL_DATASET_EDITOR_HEIGHT,
} from 'pages/dashboardPage/dashboardDatasetEditor/dashboardDatasetEditor';
import { DATASET_EDITOR_MODE } from '../editDashboardPage';
import { SIDE_PANE_WIDTH } from 'components/SidePane';

import { Dataset } from 'actions/datasetActions';
import { ParentSchema, TableDataset } from 'actions/dataSourceActions';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import {
  DashboardElement,
  DashboardVariableMap,
  DASHBOARD_ELEMENT_TYPES,
} from 'types/dashboardTypes';

const useStyles = makeStyles((theme: Theme) => ({
  datasetEditorContainer: {
    right: 0,
    backgroundColor: 'transparent',
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: INITIAL_DATASET_EDITOR_HEIGHT,
    zIndex: 5,
  },
  datasetEditorDivider: {
    cursor: 'row-resize',
    alignSelf: 'stretch',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(0.25),
    opacity: 0,
  },
  datasetEditorHidden: {
    height: `0 !important`,
  },
  datasetEditorClosed: {
    height: `${MENU_HEIGHT}px !important`,
  },
  datasetEditorExpanded: {
    height: `90vh !important`,
  },
  datasetEditorExpandedWithLeftPanel: {
    left: `-${SIDE_PANE_WIDTH}px !important`,
  },
  pageOverlay: {
    zIndex: 0,
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
}));

type Props = {
  editorDatasets: Record<string, Dataset>;
  left?: number;
  dashboardId: number;
  inEditMode?: boolean;
  parentSchemas: ParentSchema[];
  schemaTablesMap: { [schemaId: number]: { [datasetId: number]: TableDataset } };
  dashboardElements: DashboardElement[];
  datasetEditorMode: DATASET_EDITOR_MODE;
  dataPanels: DataPanelTemplate[];
  leftPaneIsOpen: boolean;
  selectedDashboardItem?: {
    elementType: string;
    elementId: string;
  };
  resizePreview?: (newHeight: number) => void;
  pageWidth: number | null;
  setDatasetEditorMode: (mode: DATASET_EDITOR_MODE, newHeight?: number) => void;
  dashboardVars: DashboardVariableMap;
  selectedUserGroupId?: number;
};

export const ResizableDatasetEditor: FC<Props> = ({
  editorDatasets,
  dashboardElements,
  datasetEditorMode,
  setDatasetEditorMode,
  dashboardId,
  dataPanels,
  inEditMode,
  parentSchemas,
  schemaTablesMap,
  leftPaneIsOpen,
  resizePreview,
  pageWidth,
  dashboardVars,
  selectedUserGroupId,
  left,
  selectedDashboardItem,
}) => {
  const classes = useStyles();
  const [datasetEditorHeight, setDatasetEditorHeight] = useState<number>(
    INITIAL_DATASET_EDITOR_HEIGHT,
  );
  const [dragging, setDragging] = useState<boolean>(false);
  const [separatorYPosition, setSeparatorYPosition] = useState<number>();

  const resizableDatasetEditor = createRef<HTMLDivElement>();

  const isOpen = datasetEditorMode !== DATASET_EDITOR_MODE.COLLAPSED;
  const isDefaultMode = datasetEditorMode === DATASET_EDITOR_MODE.DEFAULT;
  const isExpandedMode = datasetEditorMode === DATASET_EDITOR_MODE.EXPANDED;

  const onMouseMove = (e: ReactMouseEvent) => {
    if (dragging && separatorYPosition && isDefaultMode) {
      const newHeight = datasetEditorHeight - e.clientY + separatorYPosition;
      if (newHeight >= INITIAL_DATASET_EDITOR_HEIGHT / 2) {
        setSeparatorYPosition(e.clientY);
        setDatasetEditorHeight(newHeight);
      }
    }
  };

  const onMouseUp = () => {
    if (dragging) {
      setDragging(false);
      resizePreview?.(datasetEditorHeight);
    }
  };

  // @ts-ignore
  useEventListener('mousemove', onMouseMove);
  // @ts-ignore
  useEventListener('mouseup', onMouseUp);

  useEffect(() => {
    if (resizableDatasetEditor.current) {
      resizableDatasetEditor.current.style.height = `${datasetEditorHeight}px`;
    }
  }, [resizableDatasetEditor, datasetEditorHeight]);

  const getSourceDatasetIdOrDefault = () => {
    if (!selectedDashboardItem) return undefined;

    if (selectedDashboardItem.elementType === DASHBOARD_ELEMENT_TYPES.DATA_PANEL) {
      const dataPanelTemplate = dataPanels?.find((dp) => dp.id === selectedDashboardItem.elementId);
      return dataPanelTemplate && editorDatasets && editorDatasets[dataPanelTemplate.table_id]?.id;
    } else {
      return undefined;
    }
  };

  return (
    <>
      <div
        className={isExpandedMode ? classes.pageOverlay : undefined}
        onClick={() => setDatasetEditorMode(DATASET_EDITOR_MODE.DEFAULT, datasetEditorHeight)}
      />
      <div
        className={cx(classes.datasetEditorContainer, {
          [classes.datasetEditorHidden]: !inEditMode,
          [classes.datasetEditorClosed]: inEditMode && !isOpen,
          [classes.datasetEditorExpanded]:
            inEditMode && datasetEditorMode === DATASET_EDITOR_MODE.EXPANDED,
          [classes.datasetEditorExpandedWithLeftPanel]:
            inEditMode && datasetEditorMode === DATASET_EDITOR_MODE.EXPANDED && leftPaneIsOpen,
        })}
        ref={resizableDatasetEditor}
        style={{ left: left ?? 0 }}>
        {isDefaultMode && (
          <div
            className={classes.datasetEditorDivider}
            onMouseDown={(e: ReactMouseEvent) => {
              setSeparatorYPosition(e.clientY);
              setDragging(true);
            }}
            onMouseUp={() => {
              resizePreview?.(datasetEditorHeight);
              setDragging(false);
            }}
          />
        )}
        <DashboardDatasetEditor
          dashboardElements={dashboardElements}
          dashboardId={dashboardId}
          dashboardVars={dashboardVars}
          dataPanels={dataPanels}
          datasetEditorMode={datasetEditorMode}
          datasets={editorDatasets}
          isOpen={isOpen}
          pageWidth={pageWidth}
          parentSchemas={parentSchemas}
          schemaTablesMap={schemaTablesMap}
          selectedDataPanelSourceDatasetId={getSourceDatasetIdOrDefault()}
          selectedUserGroupId={selectedUserGroupId}
          toggleEditorVisibility={() => {
            !isOpen
              ? setDatasetEditorMode(DATASET_EDITOR_MODE.DEFAULT, datasetEditorHeight)
              : setDatasetEditorMode(DATASET_EDITOR_MODE.COLLAPSED, MENU_HEIGHT);
          }}
          toggleIsExpanded={() => {
            isExpandedMode
              ? setDatasetEditorMode(DATASET_EDITOR_MODE.DEFAULT, datasetEditorHeight)
              : setDatasetEditorMode(DATASET_EDITOR_MODE.EXPANDED);
          }}
        />
      </div>
    </>
  );
};
