import { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { DateTime } from 'luxon';

import PanelButton from 'shared/PanelButton';
import Button from 'shared/Button';
import { Intent, Tag, sprinkles } from 'components/ds';
import InfoCard from 'shared/InfoCard';

import { ReduxState } from 'reducers/rootReducer';
import { PLAN_TYPES, TRIAL_STATUS } from 'constants/paymentPlanConstants';
import { numDaysLeftInPlan } from 'utils/paymentPlanUtils';
import { openActivationCalendly } from 'utils/calendlyUtils';
import { dateTimeFromISOString } from 'utils/dateUtils';
import { sendPing } from 'actions/pingActions';
import { PingTypes } from 'constants/types';

const useStyles = makeStyles((theme: Theme) => ({
  subsection: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  sectionDescription: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.ds.grey800,
    marginBottom: theme.spacing(6),
  },
  planPanel: {
    height: 170,
  },
  planContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  planTitle: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: theme.spacing(2),
    color: theme.palette.ds.black,
  },
  planRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    columnGap: '24px',
  },
  changePlanButton: {
    width: '100%',
    marginTop: theme.spacing(6),
  },
  trialInfoCard: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
    fontSize: 14,
  },
}));

const orderedPlans = [
  PLAN_TYPES.LAUNCH,
  PLAN_TYPES.GROWTH,
  PLAN_TYPES.GROWTH_V2,
  PLAN_TYPES.PRO,
  PLAN_TYPES.ENTERPRISE,
];

const newPlanText = (trialStatus?: TRIAL_STATUS, newPlan?: PLAN_TYPES, oldPlan?: PLAN_TYPES) => {
  if (trialStatus === TRIAL_STATUS.IN_PROGRESS || !oldPlan || !newPlan)
    return 'Contact sales to activate plan';

  const oldIdx = orderedPlans.findIndex((plan) => plan === oldPlan);
  const newIdx = orderedPlans.findIndex((plan) => plan === newPlan);

  return `Contact sales to ${newIdx > oldIdx ? 'upgrade' : 'downgrade'} plan`;
};

export default function SettingsBillingSection() {
  const classes = useStyles();
  const currentUser = useSelector((state: ReduxState) => state.currentUser);
  const {
    payment_plan: paymentPlan,
    trial_status: trialStatus,
    trial_end_date: trialEndDate,
  } = currentUser.team ?? {};

  const [newPlan, setNewPlan] = useState<PLAN_TYPES>();
  const dispatch = useDispatch();

  const isNewPlanSelected = newPlan && newPlan !== paymentPlan;
  const currentlySelectedPlan = newPlan || paymentPlan;

  const daysLeftInPlan = numDaysLeftInPlan(trialEndDate);
  const isAccountDeactivated =
    (trialStatus === TRIAL_STATUS.IN_PROGRESS && daysLeftInPlan <= 0) ||
    paymentPlan === PLAN_TYPES.DEACTIVATED;

  const renderTrialInfoCard = () => {
    if (trialStatus !== TRIAL_STATUS.IN_PROGRESS) return null;

    const dateEndedText = trialEndDate
      ? dateTimeFromISOString(trialEndDate).toLocaleString(DateTime.DATE_SHORT)
      : '';
    const trialText =
      daysLeftInPlan > 0
        ? `There are ${daysLeftInPlan} days left on your trial. During the trial, your team will be on the Pro plan. Please activate your plan before your trial is up to ensure continued access to Explo.`
        : `Your free trial has ended${
            dateEndedText ? ' on ' + dateEndedText : ''
          }. Please activate your plan to continue using Explo.`;

    return <InfoCard className={classes.trialInfoCard} text={trialText} />;
  };

  const currentSelectionTag = (
    <Tag
      className={sprinkles({ marginTop: 'sp1' })}
      intent={Intent.ACTIVE}
      inverted={currentlySelectedPlan === paymentPlan}>
      {trialStatus === TRIAL_STATUS.IN_PROGRESS ? 'Current Trial' : 'Current Plan'}
    </Tag>
  );

  const newPlanTextValue = newPlanText(trialStatus, newPlan, paymentPlan);

  return (
    <div>
      <div className={classes.subsection}>Billing</div>
      {renderTrialInfoCard()}
      <div className={classes.sectionDescription}>
        Contact your sales representative if you have questions about your plan or pricing.
      </div>
      <div className={classes.planRow}>
        <PanelButton
          disabled={isAccountDeactivated}
          onClick={() => setNewPlan(PLAN_TYPES.LAUNCH)}
          selected={currentlySelectedPlan === PLAN_TYPES.LAUNCH}
          text={
            <div className={classes.planContainer}>
              <div className={classes.planTitle}>Launch</div>
              <div>{paymentPlan === PLAN_TYPES.LAUNCH && currentSelectionTag} </div>
            </div>
          }
        />

        <PanelButton
          className={classes.planPanel}
          disabled={isAccountDeactivated}
          onClick={() => setNewPlan(PLAN_TYPES.GROWTH_V2)}
          selected={
            currentlySelectedPlan === PLAN_TYPES.GROWTH ||
            currentlySelectedPlan === PLAN_TYPES.GROWTH_V2
          }
          text={
            <div className={classes.planContainer}>
              <div className={classes.planTitle}>Growth</div>
              <div>
                {(paymentPlan === PLAN_TYPES.GROWTH_V2 || paymentPlan === PLAN_TYPES.GROWTH) &&
                  currentSelectionTag}{' '}
              </div>
            </div>
          }
        />

        <PanelButton
          disabled={isAccountDeactivated}
          onClick={() => setNewPlan(PLAN_TYPES.PRO)}
          selected={currentlySelectedPlan === PLAN_TYPES.PRO}
          text={
            <div className={classes.planContainer}>
              <div className={classes.planTitle}>Pro</div>
              <div>{paymentPlan === PLAN_TYPES.PRO && currentSelectionTag} </div>
            </div>
          }
        />

        <PanelButton
          disabled={isAccountDeactivated}
          onClick={() => setNewPlan(PLAN_TYPES.ENTERPRISE)}
          selected={currentlySelectedPlan === PLAN_TYPES.ENTERPRISE}
          text={
            <div className={classes.planContainer}>
              <div className={classes.planTitle}>Enterprise</div>
              <div>{paymentPlan === PLAN_TYPES.ENTERPRISE && currentSelectionTag} </div>
            </div>
          }
        />
      </div>
      {(isNewPlanSelected || trialStatus === TRIAL_STATUS.IN_PROGRESS) && (
        <Button
          className={classes.changePlanButton}
          onClick={() => {
            // Before payment invoicing is built in, we'll send those who want to change pans to our sales team's Calendly.
            // We'll remove this after the infra to take in payment info is built.
            openActivationCalendly(currentUser);

            newPlan &&
              dispatch(
                sendPing({
                  postData: {
                    message: `${currentUser.first_name} ${currentUser.last_name} (${currentUser.email}) on team "${currentUser.team?.team_name}" clicked to book a call with sales to "${newPlanTextValue}", from ${paymentPlan} to ${newPlan}`,
                    message_type: PingTypes.PING_PRICING_PLAN_UPDATE,
                  },
                }),
              );
          }}
          text={newPlanTextValue}
          type="primary"
        />
      )}
    </div>
  );
}
