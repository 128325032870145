import { useState } from 'react';
import cx from 'classnames';
import { capitalize } from 'utils/standard';
import produce from 'immer';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { GlobalStyleConfig } from 'globalStyles/types';
import ConfigSectionHeader from 'pages/dataPanelEditorPage/configSectionHeader';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import ColorPicker from './CustomStylesColorPicker';
import LineSelect from 'shared/LineSelect';
import FlexBox, { VerticalAlignment } from 'components/core/FlexBox';
import FlexItem from 'components/core/FlexItem';
import { Switch } from 'components/ds';
import CollapsibleMenu from 'components/CollapsibleMenu';
import CustomizedTag from './CustomizedTag';
import { CustomStylesCornerRadiusCategories } from 'constants/types';

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    width: 55,
  },
  leftInput: {
    marginRight: theme.spacing(3),
  },
  rightInput: {
    marginLeft: theme.spacing(3),
  },
  subHeading: {
    marginTop: theme.spacing(6),
  },
  inputLabel: {
    fontSize: 12,
    marginBottom: theme.spacing(1),
  },
  inlineColorPicker: {
    marginLeft: theme.spacing(3),
  },
  outlineRow: {
    margin: `${theme.spacing(5)}px 0px`,
  },
  switchStyles: {
    '&.bp3-control': {
      marginBottom: 0,
    },
  },
  collapsibleMenu: {
    marginTop: theme.spacing(4),
  },
  subsectionMargin: {
    marginBottom: theme.spacing(2),
  },
}));

type Props = {
  styleConfig: GlobalStyleConfig;
  updateConfig: (newConfig: GlobalStyleConfig) => void;
};

export default function CardsConfigSection(props: Props) {
  const { styleConfig, updateConfig } = props;

  const classes = useStyles();

  return (
    <>
      <ConfigSectionHeader title="Cards" />
      <ConfigSectionHeader compact isSubTitle title="Fill and border" />
      <div className={classes.inputLabel}>Fill</div>
      <ColorPicker
        fill
        color={styleConfig.container.fill}
        onClose={(newColor) => {
          const newConfig = produce(styleConfig, (draft) => {
            draft.container.fill = newColor;
          });
          updateConfig(newConfig);
        }}
      />
      <ToggleRow {...props} configField="outline" />
      <ToggleRow {...props} configField="shadow" />
      <ConfigSectionHeader isSubTitle className={classes.subHeading} title="Padding" />
      <InputRow
        {...props}
        breakpoints={[10, 15, 20, 25, 30]}
        configField="padding"
        labels={['Narrow', 'Moderate', 'Wide']}
        minValue={0}
      />
      <ConfigSectionHeader isSubTitle className={classes.subHeading} title="Line Width" />
      <InputRow
        {...props}
        breakpoints={[1, 2, 3, 4, 5]}
        configField="lineWidth"
        labels={['Thin', 'Moderate', 'Thick']}
        maxValue={5}
        minValue={1}
      />
      <ConfigSectionHeader isSubTitle className={classes.subHeading} title="Corner Radius" />
      <InputRow
        {...props}
        breakpoints={[0, 4, 8, 12, 16]}
        configField="cornerRadius"
        labels={['Sharp', 'Moderate', 'Round']}
        minValue={0}
      />
      <CornerRadiusMenu {...props} />
    </>
  );
}

function ToggleRow({
  styleConfig,
  updateConfig,
  configField,
}: Props & { configField: 'outline' | 'shadow' }) {
  const classes = useStyles();

  return (
    <FlexBox className={classes.outlineRow} verticalAlignment={VerticalAlignment.CENTER}>
      <FlexItem>
        <Switch
          useCustomStyles
          className={classes.switchStyles}
          label={capitalize(configField)}
          onChange={() => {
            const newConfig = produce(styleConfig, (draft) => {
              draft.container[configField].enabled = !styleConfig.container[configField].enabled;
            });
            updateConfig(newConfig);
          }}
          switchOn={styleConfig.container[configField].enabled}
        />
      </FlexItem>
      <FlexItem className={classes.inlineColorPicker}>
        <ColorPicker
          fill
          color={
            styleConfig.container[configField].enabled
              ? styleConfig.container[configField].color
              : undefined
          }
          onClose={(newField) => {
            const newConfig = produce(styleConfig, (draft) => {
              draft.container[configField].color = newField;
            });
            updateConfig(newConfig);
          }}
        />
      </FlexItem>
      <InputWithBlurSave
        hideRightIconInteractions
        containerClassName={cx(classes.input, classes.rightInput)}
        disabled={!styleConfig.container[configField].enabled}
        initialValue={`${
          configField === 'outline'
            ? styleConfig.container.outline.weight
            : styleConfig.container.shadow.size
        }px`}
        onNewValueSubmitted={(newField) => {
          const newValue = Number(newField.replace('px', ''));
          let newConfig = styleConfig;
          if (isNaN(newValue) || newValue < 1) return;
          if (configField === 'outline') {
            newConfig = produce(styleConfig, (draft) => {
              draft.container.outline.weight = newValue;
            });
          } else {
            newConfig = produce(styleConfig, (draft) => {
              draft.container.shadow.size = newValue;
            });
          }
          updateConfig(newConfig);
        }}
      />
    </FlexBox>
  );
}

function InputRow({
  styleConfig,
  updateConfig,
  configField,
  labels,
  breakpoints,
  minValue,
  maxValue,
}: Props & {
  configField: 'padding' | 'cornerRadius' | 'lineWidth';
  labels: string[];
  breakpoints: number[];
  minValue: number;
  maxValue?: number;
}) {
  const classes = useStyles();

  return (
    <FlexBox verticalAlignment={VerticalAlignment.BOTTOM}>
      <InputWithBlurSave
        hideRightIconInteractions
        containerClassName={cx(classes.input, classes.leftInput)}
        initialValue={`${styleConfig.container[configField].default}px`}
        onNewValueSubmitted={(newValueString) => {
          const newValue = Number(newValueString.replace('px', ''));

          if (isNaN(newValue) || newValue < minValue || (maxValue && newValue > maxValue)) return;

          const newConfig = produce(styleConfig, (draft) => {
            draft.container[configField].default = newValue;
          });
          updateConfig(newConfig);
        }}
      />
      <FlexItem>
        <LineSelect
          fill
          breakpoints={breakpoints}
          labels={labels}
          setValue={(newValue) => {
            const newConfig = produce(styleConfig, (draft) => {
              draft.container[configField].default = newValue;
            });
            updateConfig(newConfig);
          }}
          value={styleConfig.container[configField].default}
        />
      </FlexItem>
    </FlexBox>
  );
}

function CornerRadiusMenu({ styleConfig, updateConfig }: Props) {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  return (
    <CollapsibleMenu
      className={classes.collapsibleMenu}
      isExpanded={menuOpen}
      setIsExpanded={setMenuOpen}
      title="Custom corner radiuses">
      {Object.values(CustomStylesCornerRadiusCategories).map((config) => {
        return (
          <div className="" key={config}>
            <FlexBox className={classes.inputLabel} verticalAlignment={VerticalAlignment.CENTER}>
              <div>{config === 'buttons' ? 'Buttons' : 'Input Fields'}</div>
              <CustomizedTag
                hidden={styleConfig.container.cornerRadius[config] === undefined}
                onCancel={() => {
                  const newConfig = produce(styleConfig, (draft) => {
                    draft.container.cornerRadius[config] = undefined;
                  });
                  updateConfig(newConfig);
                }}
              />
            </FlexBox>
            <InputWithBlurSave
              hideRightIconInteractions
              initialValue={`${
                styleConfig.container.cornerRadius[config] ??
                styleConfig.container.cornerRadius.default
              }px`}
              onNewValueSubmitted={(newButtonCornerRadiusString) => {
                const newButtonCornerRadius = Number(newButtonCornerRadiusString.replace('px', ''));

                if (isNaN(newButtonCornerRadius) || newButtonCornerRadius < 0) return;

                const newConfig = produce(styleConfig, (draft) => {
                  draft.container.cornerRadius[config] = newButtonCornerRadius;
                });
                updateConfig(newConfig);
              }}
            />
          </div>
        );
      })}
    </CollapsibleMenu>
  );
}
