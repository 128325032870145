import { FC } from 'react';
import produce from 'immer';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';

import { DateGroupToggleConfig, TimePeriodDropdownElemConfig } from 'types/dashboardTypes';

export type LabelConfigType = DateGroupToggleConfig | TimePeriodDropdownElemConfig;

type Props = {
  config: LabelConfigType;

  updateConfig: (config: LabelConfigType) => void;
};

export const LabelConfig: FC<Props> = ({ config, updateConfig }) => {
  return (
    <InputWithBlurSave
      initialValue={config.label}
      label="Label"
      onNewValueSubmitted={(newValue) => {
        const newConfig = produce(config, (draft) => {
          draft.label = newValue;
        });
        updateConfig(newConfig);
      }}
    />
  );
};
