import axios from 'axios';
import { PAGE_EVENTS, REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import { EmbeddedDashboardType } from 'components/EmbeddedDashboard/types';
import { getEnvironment } from './environmentUtils';

interface PostData {
  event_name: REPORTED_ANALYTIC_ACTION_TYPES;
  user_id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  custom_properties?: any;
}

export const sendAnalyticsEvent = (
  eventType: REPORTED_ANALYTIC_ACTION_TYPES,
  userId: string,
  customerToken: string | undefined,
  analyticsToken: string | null | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  custom_properties?: any,
) => {
  const postData: PostData = {
    event_name: eventType,
    user_id: userId,
    properties,
    custom_properties,
  };

  const isPageEvent = PAGE_EVENTS.has(eventType);

  const url = isPageEvent ? 'page_event' : 'track_event';

  sendAnalyticsEventHelper(url, postData, customerToken, analyticsToken);
};

type DashboardInfo =
  | { dashboard_template_id: number; dashboard_template_name: string }
  | { blueprint_id: number; blueprint_name: string };

export type AnalyticsMetadata = {
  team_id: number;
  team_name: string;
  customer_id: number;
  customer_name: string;
  customer_provided_id: string;
  customer_is_demo: boolean;
} & DashboardInfo;

export type Metadata = Record<string, number | string>;

export type AnalyticsEventTracker = (
  eventName: REPORTED_ANALYTIC_ACTION_TYPES,
  metaData?: Metadata,
) => void;

export const sendAnalyticsEventWrapper = (
  userId: string,
  dashboardType: string,
  embedSource: string,
  customerToken: string | undefined,
  analyticsToken: string | null | undefined,
  eventName: REPORTED_ANALYTIC_ACTION_TYPES,
  analyticsMetadata: AnalyticsMetadata | undefined,
  metadata: Metadata | undefined,
  customProperties?: Metadata,
) => {
  const properties = {
    dashboard_type: dashboardType,
    embed_source: embedSource,
    ...analyticsMetadata,
    ...metadata,
  };

  sendAnalyticsEvent(
    eventName,
    userId,
    customerToken,
    analyticsToken,
    properties,
    customProperties,
  );
};

export const isValidHttpUrl = (s: string) => {
  let url;

  try {
    url = new URL(s);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

const sendAnalyticsEventHelper = (
  url: string,
  postData: PostData,
  customerToken: string | undefined,
  analytics_token: string | null | undefined,
) => {
  // only send off analytics events in production
  if (process.env.REACT_APP_ENVIRONMENT && getEnvironment() === 'production')
    axios({
      url: `${process.env.REACT_APP_API_URL}analytics/${url}/`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Customer-Token': customerToken,
        'Analytics-Token': analytics_token,
      },
      data: postData,
    }).catch(() => {
      // we don't need to do anything with this error, but we need to catch
      // so that the page doesn't break if the request fails
      return;
    });
};

export const getPageViewType = (embedType: EmbeddedDashboardType) => {
  switch (embedType) {
    case 'shared':
      return REPORTED_ANALYTIC_ACTION_TYPES.SHARED_DASHBOARD_PAGE_VIEWED;
    case 'portal':
      return REPORTED_ANALYTIC_ACTION_TYPES.PORTAL_DASHBOARD_PAGE_VIEWED;
    default:
      return REPORTED_ANALYTIC_ACTION_TYPES.DASHBOARD_PAGE_VIEWED;
  }
};

export const getEmbedSource = (embedType: EmbeddedDashboardType) =>
  embedType === 'shared' ? 'share' : embedType;
