import { SizeMe } from 'react-sizeme';
import GridLayout, { Layout } from '@explo-tech/react-grid-layout';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Icon } from '@blueprintjs/core';
import cx from 'classnames';

import Markdown from 'components/markdown';

import { GlobalStyleConfig } from 'globalStyles/types';
import { getSortedGridItems } from 'utils/layoutUtils';
import {
  ApplyFilterElemConfig,
  ContainerElemConfig,
  DashboardElement,
  DASHBOARD_ELEMENT_TYPES,
  TextDashboardElemConfig,
} from 'types/dashboardTypes';
import { OPERATION_TYPES } from 'constants/types';
import * as previews from 'constants/dashboardPreviews';
import { elementHasContainer, isDashboardElement } from 'utils/exploResourceUtils';
import { DataPanel } from 'types/exploResource';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflow: 'hidden',
    pointerEvents: 'none',
  },
  nonContainer: (globalConfig: GlobalStyleConfig) => ({
    height: 200,
    backgroundColor: globalConfig.base.backgroundColor,
  }),
  zoomPreview: {
    zoom: 0.5,
  },
  elemContainer: {
    padding: 1,
  },
  label: (globalConfig: GlobalStyleConfig) => ({
    fontSize: 6,
    lineHeight: '6px',
    color: globalConfig.text.secondaryColor,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  inputElem: {
    height: 12,
    borderRadius: 1,
    backgroundColor: theme.palette.ds.white,
    position: 'relative',
  },
  downArrow: {
    position: 'absolute',
    right: 1,
    top: 2,
  },
  textElem: {
    padding: 2,
    height: '100%',
    overflow: 'hidden',
  },
  toggleElem: {
    backgroundColor: theme.palette.ds.white,
    borderRadius: 1,
    height: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  toggleOpt: (globalConfig: GlobalStyleConfig) => ({
    fontSize: 6,
    width: '100%',
    height: '100%',
    paddingLeft: 4,
    display: 'flex',
    alignItems: 'center',
    borderRight: `1px solid ${globalConfig.container.outline.color}`,
    '&:last-child': {
      borderRight: 'none',
    },
  }),
  switchWrapper: {
    display: 'flex',
    height: 14,
    marginTop: 6,
    alignItems: 'center',
  },
  elemButton: (globalConfig: GlobalStyleConfig) => ({
    height: 12,
    marginTop: 6,
    fontSize: 6,
    backgroundColor:
      globalConfig.base.actionColor.buttonColor ?? globalConfig.base.actionColor.default,
    borderRadius: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }),
  dataPanelContainer: {
    height: '100%',
    width: '100%',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 1,
  },
  dpTitle: {
    fontSize: 8,
    lineHeight: '10px',
    maxHeight: '50%',
    overflow: 'hidden',
    fontWeight: 600,
    minHeight: 10,
    margin: 4,
  },
  dp: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    overflow: 'hidden',
  },

  container: {
    backgroundColor: theme.palette.ds.white,
    padding: 2,
    height: '100%',
    borderRadius: 1,
  },
  imageContainer: {
    backgroundColor: theme.palette.ds.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  imageWrapper: {
    height: '80%',
    width: '80%',
  },
  borderContainer: (globalConfig: GlobalStyleConfig) => ({
    border: `1px solid ${globalConfig.container.outline.color}`,
  }),
}));

type Props = {
  layout: Layout[];
  dataPanels: DataPanel[];
  elements: DashboardElement[];
  globalStyleConfig: GlobalStyleConfig;
  // container info
  containerId?: string;
  cols?: number;
  useZoom?: boolean;
};

export default function DashboardPreview({
  layout,
  dataPanels,
  elements,
  globalStyleConfig,
  containerId,
  cols,
  useZoom,
}: Props): JSX.Element {
  const classes = useStyles(globalStyleConfig);

  const viewDPHeader = (dp: DataPanel) => {
    if (
      dp.visualize_op.generalFormatOptions?.headerConfig &&
      !dp.visualize_op.generalFormatOptions.headerConfig.isHeaderHidden
    ) {
      const textAlign =
        dp.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_V2 ||
        dp.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_PROGRESS_V2
          ? 'center'
          : 'initial';
      return (
        <div className={classes.dpTitle} style={{ textAlign }}>
          {dp.visualize_op.generalFormatOptions.headerConfig.title}
        </div>
      );
    }
    return <></>;
  };

  const viewDataPanel = (dp: DataPanel) => {
    return (
      <div
        className={cx(classes.dataPanelContainer, {
          [classes.borderContainer]: containerId === undefined,
        })}
        key={dp.id}>
        {viewDPHeader(dp)}
        {<div className={classes.dp}>{getIconFromOpType(dp.visualize_op.operation_type)}</div>}
      </div>
    );
  };

  const wrapElem = (label: string, element: JSX.Element) => {
    return (
      <div className={classes.elemContainer}>
        <div className={classes.label}>{label}</div>
        {element}
      </div>
    );
  };
  const viewElement = (elem: DashboardElement) => {
    let elemDiv;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const label = (elem.config as any).label ?? '';
    switch (elem.element_type) {
      case DASHBOARD_ELEMENT_TYPES.TEXT: {
        const elemConfig = elem.config as TextDashboardElemConfig;
        const text = elemConfig.text ?? 'No text currently configured';
        const textSize = elemConfig.textSize || 'SMALL';
        const fontSize = textSize === 'LARGE' ? 9 : textSize === 'MEDIUM' ? 7 : 5;
        elemDiv = (
          <div className={classes.textElem} style={{ fontSize }}>
            <Markdown markdownText={text} />
          </div>
        );
        break;
      }
      case DASHBOARD_ELEMENT_TYPES.SPACER: {
        elemDiv = <div />;
        break;
      }
      case DASHBOARD_ELEMENT_TYPES.DROPDOWN:
      case DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN:
      case DASHBOARD_ELEMENT_TYPES.MULTISELECT:
      case DASHBOARD_ELEMENT_TYPES.TEXT_INPUT: {
        const currIcon =
          elem.element_type === DASHBOARD_ELEMENT_TYPES.TEXT_INPUT ? 'cross' : 'caret-down';
        elemDiv = wrapElem(
          label,
          <div className={cx(classes.inputElem, classes.borderContainer)}>
            <Icon className={classes.downArrow} icon={currIcon} iconSize={6} />
          </div>,
        );
        break;
      }
      case DASHBOARD_ELEMENT_TYPES.TOGGLE:
      case DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH:
        elemDiv = wrapElem(
          label,
          <div className={cx(classes.toggleElem, classes.borderContainer)}>
            <div className={classes.toggleOpt}>A</div>
            <div className={classes.toggleOpt}>B</div>
            <div className={classes.toggleOpt}>C</div>
          </div>,
        );
        break;

      case DASHBOARD_ELEMENT_TYPES.SWITCH:
        elemDiv = (
          <div className={classes.switchWrapper}>
            <div className={classes.label} style={{ marginRight: 4 }}>
              {label}
            </div>
            {previews.SWITCH(
              globalStyleConfig.base.actionColor.buttonColor ??
                globalStyleConfig.base.actionColor.default,
            )}
          </div>
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.APPLY_FILTER_BUTTON:
        elemDiv = (
          <div className={classes.elemContainer}>
            <div className={classes.elemButton}>
              {(elem.config as ApplyFilterElemConfig).buttonText ?? 'Apply Filters'}
            </div>
          </div>
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.EXPORT:
        elemDiv = (
          <div className={classes.elemContainer}>
            <div className={classes.elemButton}>{label}</div>
          </div>
        );
        break;

      case DASHBOARD_ELEMENT_TYPES.DATEPICKER:
      case DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER:
        elemDiv = wrapElem(
          label,
          <div className={cx(classes.inputElem, classes.borderContainer)}></div>,
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.CONTAINER: {
        const containerConfig = elem.config as ContainerElemConfig;
        const layoutElem = layout.find((l) => l.i === elem.id);
        elemDiv = (
          <div className={cx(classes.container, classes.borderContainer)}>
            <DashboardPreview
              cols={layoutElem?.w}
              containerId={elem.id}
              dataPanels={dataPanels}
              elements={elements}
              globalStyleConfig={globalStyleConfig}
              layout={containerConfig.layout}
            />
          </div>
        );
        break;
      }
      case DASHBOARD_ELEMENT_TYPES.IMAGE:
        elemDiv = (
          <div className={cx(classes.imageContainer, classes.borderContainer)}>
            <div className={classes.imageWrapper}>{previews.IMAGE}</div>
          </div>
        );
    }
    return <div key={elem.id}>{elemDiv}</div>;
  };
  const filteredLayout = layout.filter((elem) => elem.y < 10);

  const filteredDps = dataPanels.filter((dp) => {
    if (!elementHasContainer(dp)) return true;
    return containerId ? dp.container_id === containerId : !dp.container_id;
  });
  const filteredElems = elements.filter((elem) =>
    containerId ? elem.container_id === containerId : !elem.container_id,
  );

  const gridItems = getSortedGridItems([...filteredDps, ...filteredElems], filteredLayout);

  const gridLayoutChildren = gridItems.map((item) => {
    if (isDashboardElement(item)) return viewElement(item);
    return viewDataPanel(item);
  });

  return (
    <SizeMe>
      {({ size }) => (
        <div
          className={cx(classes.root, {
            [classes.nonContainer]: containerId === undefined,
            [classes.zoomPreview]: useZoom,
          })}>
          <GridLayout
            cols={cols ?? globalStyleConfig.base.numColumns}
            isDraggable={false}
            isResizable={false}
            layout={layout}
            margin={[5, 5]}
            rowHeight={20}
            useCSSTransforms={false}
            width={size.width ?? undefined}>
            {gridLayoutChildren}
          </GridLayout>
        </div>
      )}
    </SizeMe>
  );
}

function getIconFromOpType(opType: OPERATION_TYPES): JSX.Element | undefined {
  switch (opType) {
    case OPERATION_TYPES.VISUALIZE_TABLE:
    case OPERATION_TYPES.VISUALIZE_REPORT_BUILDER:
    case OPERATION_TYPES.VISUALIZE_PIVOT_TABLE:
    case OPERATION_TYPES.VISUALIZE_PIVOT_REPORT_BUILDER:
    case OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST:
    case OPERATION_TYPES.VISUALIZE_TREND_TABLE:
      return previews.TABLE;
    case OPERATION_TYPES.VISUALIZE_LINE_CHART_V2:
      return previews.LINE_CHART;
    case OPERATION_TYPES.VISUALIZE_AREA_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2:
      return previews.AREA_CHART;
    case OPERATION_TYPES.VISUALIZE_PIE_CHART_V2:
      return previews.PIE_CHART;
    case OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2:
      return previews.VERTICAL_BAR_CHART;
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2:
      return previews.HORIZONTAL_BAR_CHART;
    case OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2:
      return previews.DONUT_CHART;
    case OPERATION_TYPES.VISUALIZE_SPIDER_CHART:
      return previews.SPIDER_CHART;
    case OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2:
      return previews.KPI_TREND;
    case OPERATION_TYPES.VISUALIZE_FUNNEL_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2:
      return previews.FUNNEL_CHART;
    case OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2:
      return previews.BOX_PLOT_CHART;
    case OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2:
      return previews.HEATMAP_CHART;
    case OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2:
      return previews.COMBO_CHART;
    case OPERATION_TYPES.VISUALIZE_MAP_V2:
      return previews.MAP_CHART;
    case OPERATION_TYPES.VISUALIZE_PROGRESS_V2:
      return previews.KPI_PROGRESS;
    // KPI
    case OPERATION_TYPES.VISUALIZE_NUMBER_V2:
      return previews.KPI;

    default:
      return undefined;
  }
}
