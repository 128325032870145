import { cloneDeep } from 'utils/standard';
import { useDispatch } from 'react-redux';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import { Switch } from 'components/ds';
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES, V2KPIChartInstructions } from 'constants/types';
import { sharedStyles } from './styles';

type Props = {
  visualizationType: string;
  instructions: V2KPIChartInstructions;
};

export default function KPITrendConfig({ visualizationType, instructions }: Props) {
  const classes = sharedStyles();
  const dispatch = useDispatch();

  return (
    <div className={classes.root}>
      <InputWithBlurSave
        containerClassName={classes.configInput}
        initialValue={instructions.trendFormat?.label}
        label="Label"
        onNewValueSubmitted={(newValue: string) => {
          const newInstructions = cloneDeep(instructions);
          newInstructions.trendFormat = {
            ...newInstructions.trendFormat,
            label: newValue,
          };
          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
      />
      <Switch
        useCustomStyles
        className={classes.configInput}
        label="Use Number"
        onChange={() => {
          const newInstructions = cloneDeep(instructions);

          newInstructions.trendFormat = {
            ...newInstructions.trendFormat,
            isNumber: !instructions.trendFormat?.isNumber,
          };
          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
        switchOn={instructions.trendFormat?.isNumber}
      />
      <Switch
        useCustomStyles
        className={classes.configInput}
        label="Reverse Trend Colors"
        onChange={() => {
          const newInstructions = cloneDeep(instructions);

          newInstructions.trendFormat = {
            ...newInstructions.trendFormat,
            trendColorsReversed: !instructions.trendFormat?.trendColorsReversed,
          };
          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
        switchOn={instructions.trendFormat?.trendColorsReversed}
      />
    </div>
  );
}
