import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import DropdownSelect from 'shared/DropdownSelect';

import { ReduxState } from 'reducers/rootReducer';
import { EXPLO_DASHBOARD_STORAGE_KEY } from 'reducers/styleConfigReducer';
import { selectDashboard } from 'actions/styleConfigActions';
import { DashboardTemplate } from 'actions/dashboardTemplateActions';

type Props = {
  dashboards: DashboardTemplate[];
};

export default function DashboardSelector({ dashboards }: Props): JSX.Element {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const selectedDashboardId = useSelector(
    (state: ReduxState) => state.styleConfig.selectedDashboardId,
  );

  const [hasParsedUrl, setHasParsedUrl] = useState(false);
  const dashboardIdInStorage = useLocalStorage(EXPLO_DASHBOARD_STORAGE_KEY, null)[0];

  useEffect(() => {
    if (hasParsedUrl) return;
    setHasParsedUrl(true);

    // Get dashboard id from url
    const queryParams = new URLSearchParams(location.search);
    const parsedCustomerId = parseInt(queryParams.get('dashboard') ?? '');

    let dashboardId: number | undefined;
    dashboardId = isNaN(parsedCustomerId) ? undefined : parsedCustomerId;

    // Get dashboard id from local storage
    if (dashboardId === undefined) {
      if (!dashboardIdInStorage) return;
      dashboardId = parseInt(dashboardIdInStorage);
    }

    // Check dashboard still exists
    const dashboard = dashboards.find((dashboard) => dashboard.id === dashboardId);
    if (dashboard) dispatch(selectDashboard(dashboard.id));
  }, [dispatch, dashboards, location.search, hasParsedUrl, dashboardIdInStorage]);

  const dashboardOptions = useMemo(
    () =>
      dashboards.map((dashboard) => {
        return {
          id: dashboard.id.toString(),
          name: dashboard.name,
        };
      }),
    [dashboards],
  );

  const selectedDashboard = selectedDashboardId
    ? dashboardOptions.find((dashboard) => dashboard.id === selectedDashboardId.toString())
    : undefined;

  return (
    <DropdownSelect
      fillWidth
      ignoreCustomStyles
      minimal
      showCancelBtn
      showIcon
      disabled={!dashboards}
      noSelectionText="Select dashboard"
      onCancelClick={() => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.delete('dashboard');
        queryParams.delete('customer');
        history.replace({ search: queryParams.toString() });

        dispatch(selectDashboard(null));
      }}
      onChange={(item) => {
        if (item.id === selectedDashboard?.id) return;
        const dashboardId = parseInt(item.id);
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('dashboard', dashboardId.toString());
        history.replace({ search: queryParams.toString() });

        dispatch(selectDashboard(dashboardId));
      }}
      options={dashboardOptions}
      selectedItem={
        selectedDashboard
          ? { id: String(selectedDashboard.id), name: selectedDashboard.name }
          : undefined
      }
    />
  );
}
