import { ROUTES } from 'constants/routes';
import { Action } from 'reducers/rootReducer';
import { Dispatch } from 'redux';

const sessionExpiredMiddleware = () => (next: Dispatch<Action>) => (action: Action) => {
  const errorDataStatus = action.payload?.errorData?.status;
  if ([440, 401].includes(errorDataStatus)) {
    return (window.location.href = `${ROUTES.LOGIN}#expired`);
  }
  return next(action);
};

export default sessionExpiredMiddleware;
