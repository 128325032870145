import { Fragment } from 'react';
import cx from 'classnames';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Popover, Position, Menu, MenuItem, Tooltip } from '@blueprintjs/core';
import { DateTime } from 'luxon';
import { sprinkles } from 'components/ds';

import Button from 'shared/Button';
import DroppedFormula from './DroppedFormula';
import { sharedStyles } from './sharedDroppedStyles';
import { ColumnOptionsPopover } from './ColumnOptionsPopover';
import { DatasetSchema, DatasetColumn } from 'types/datasets';

import { ConfigurableColumnInfo, AggregationType, Aggregation } from 'constants/types';
import { getColDisplayText, getGroupOptions } from '../utils';
import { DashboardElement } from 'types/dashboardTypes';
import { PivotAgg } from 'types/dateRangeTypes';
import { AGGREGATIONS_TYPES } from 'constants/dataConstants';

const useStyles = makeStyles((theme: Theme) => ({
  groupHeader: {
    '&..bp3-heading': {
      paddingLeft: 0,
      color: theme.palette.ds.grey800,
    },
  },
}));

type Props = {
  column: ConfigurableColumnInfo;
  canSelectSmartGrouping?: boolean;
  draggingClass?: string;
  onColAdded: (newCol: DatasetColumn, oldColName?: string, oldColAggType?: AggregationType) => void;

  onColOptionChanged: (option: { id: string; name: string; formula?: string }) => void;
  onRemoveCol: () => void;
  disableEdits?: boolean;
  dashboardElements?: DashboardElement[];
  includeFirst?: boolean;
  isCollapsibleListAgg?: boolean;
  schema: DatasetSchema;
};

export default function DroppedAggregationColumn({
  column,
  canSelectSmartGrouping,
  draggingClass,
  onColAdded,
  onColOptionChanged,
  onRemoveCol,
  disableEdits,
  dashboardElements,
  includeFirst,
  isCollapsibleListAgg,
  schema,
}: Props) {
  const classes = useStyles();
  const sharedClasses = sharedStyles();

  const groupOptions = getGroupOptions(
    column,
    dashboardElements,
    includeFirst,
    isCollapsibleListAgg,
  );

  const dateTime = DateTime.local();

  if (groupOptions.length === 0) {
    return (
      <div
        className={cx(
          sharedClasses.popoverTargetContainer,
          sprinkles({
            display: 'flex',
            marginBottom: 'sp.5',
            backgroundColor: 'lightBlue',
            paddingRight: 'sp.5',
            borderRadius: 3,
          }),
          draggingClass,
        )}>
        <div className={sharedClasses.name}>{getColDisplayText(column)}</div>
        <ColumnOptionsPopover
          rightDivider
          onColChange={(newCol) => onColAdded(newCol, column.column.name, column.agg)}
          schema={schema}
        />
        <Button
          compact
          minimal
          className={sharedClasses.iconBtn}
          icon="cross"
          onClick={onRemoveCol}
        />
      </div>
    );
  }

  if (column.agg?.id === Aggregation.FORMULA) {
    return (
      <DroppedFormula
        disableEdits={disableEdits}
        draggingClass={draggingClass}
        formula={column.agg.formula}
        onColOptionChanged={onColOptionChanged}
        onRemoveCol={onRemoveCol}
      />
    );
  }

  const menuItem = (option: {
    id: string;
    name: string;
    getExample?: (fmt: DateTime) => string | undefined;
  }) => (
    <MenuItem
      className={sharedClasses.menuItemOption}
      disabled={(option.id === PivotAgg.DATE_SMART && !canSelectSmartGrouping) || disableEdits}
      label={option.getExample?.(dateTime)}
      onClick={() => !disableEdits && onColOptionChanged({ id: option.id, name: option.name })}
      text={option.name}
    />
  );

  const aggName = column?.agg ? AGGREGATIONS_TYPES[column.agg.id].name : undefined;

  return (
    <div
      className={cx(
        sprinkles({
          display: 'flex',
          marginBottom: 'sp.5',
          backgroundColor: 'lightBlue',
          paddingRight: 'sp.5',
          borderRadius: 3,
        }),
        draggingClass,
      )}>
      <div className={cx(sharedClasses.popoverTargetContainer, sprinkles({ flex: 3 }))}>
        <div className={sharedClasses.name}>{column.column.name}</div>
        <ColumnOptionsPopover
          rightDivider
          onColChange={(newCol) => onColAdded(newCol, column.column.name, column.agg)}
          schema={schema}
        />
      </div>
      <div className={cx(sharedClasses.popoverTargetContainer, sprinkles({ flex: 2 }))}>
        <div className={sharedClasses.name}>
          {aggName?.toUpperCase() || column?.bucket?.name.toUpperCase()}
        </div>
        <Popover
          captureDismiss
          fill
          minimal
          content={
            <Menu>
              {groupOptions.map((group, i) => (
                <Fragment key={`dropped-column-groups-${i}`}>
                  {group.title && (
                    <li className="bp3-menu-header">
                      <h6 className={cx('bp3-heading', classes.groupHeader)}>{group.title}</h6>
                    </li>
                  )}
                  {group.options.map((option, i) => (
                    <Fragment key={`dropped-column-group=options-${i}`}>
                      {option.id === PivotAgg.DATE_SMART && !canSelectSmartGrouping ? (
                        <Tooltip
                          content="Date Range Picker component must be configured as a filter in the section below to select Smart Grouping"
                          openOnTargetFocus={false}>
                          {menuItem(option)}
                        </Tooltip>
                      ) : (
                        menuItem(option)
                      )}
                    </Fragment>
                  ))}
                </Fragment>
              ))}
            </Menu>
          }
          position={Position.BOTTOM_RIGHT}>
          <Button
            compact
            minimal
            className={cx(sharedClasses.iconBtn, sharedClasses.rightDivider)}
            icon="caret-down"
          />
        </Popover>
      </div>
      <Button
        compact
        minimal
        className={sharedClasses.iconBtn}
        disabled={disableEdits}
        icon="cross"
        onClick={() => !disableEdits && onRemoveCol()}
      />
    </div>
  );
}
