import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import produce from 'immer';
import { sprinkles } from 'components/ds';
import { vars } from 'components/ds/vars.css';

import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import InputLabel from 'shared/InputLabel';
import { Switch } from 'components/ds';
import ColorPickerButton from 'shared/ColorPicker';

import {
  ColorSettings,
  KPIColorFormat,
  OPERATION_TYPES,
  V2KPIChartInstructions,
} from 'constants/types';
import { getCategoricalColors } from 'globalStyles';
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { ReduxState } from 'reducers/rootReducer';
import { sharedStyles } from './styles';

const labelStyle = sprinkles({ paddingX: 'sp1.5', marginBottom: 'sp1' });

const KPI_COLOR_OPTIONS: { id: ColorSettings; name: string }[] = [
  {
    id: ColorSettings.CONSTANT,
    name: 'Constant',
  },
  {
    id: ColorSettings.CONDITIONAL,
    name: 'Conditional',
  },
];

type Props = {
  instructions: V2KPIChartInstructions;
  visualizationType: OPERATION_TYPES.VISUALIZE_PROGRESS_V2 | OPERATION_TYPES.VISUALIZE_NUMBER_V2;
};

export const KPIColorConfig: FC<Props> = ({ instructions, visualizationType }) => {
  const dispatch = useDispatch();
  const globalStyleConfig = useSelector(
    (state: ReduxState) => state.dashboardStyles.globalStyleConfig,
  );

  const sharedClasses = sharedStyles();

  if (!globalStyleConfig) return null;

  const updateFormat = (colorFormat: KPIColorFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.colorFormat = { ...draft.colorFormat, ...colorFormat };
    });
    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  const colorFormat = instructions.colorFormat;
  const selectedColorType = colorFormat?.colorSettingType;

  return (
    <div className={sharedClasses.root}>
      <ToggleButtonGroup fillWidth className={labelStyle} label="Format Type">
        {KPI_COLOR_OPTIONS.map((colorFormat) => (
          <ToggleButton
            active={colorFormat.id === selectedColorType}
            key={colorFormat.id}
            onClick={() =>
              updateFormat({
                colorSettingType: colorFormat.id === selectedColorType ? undefined : colorFormat.id,
              })
            }
            text={colorFormat.name}
          />
        ))}
      </ToggleButtonGroup>
      {selectedColorType === ColorSettings.CONSTANT ? (
        <>
          <InputLabel className={labelStyle} text="KPI Text Color" />
          <ColorPickerButton
            fillWidth
            className={labelStyle}
            color={colorFormat?.constantColor || globalStyleConfig.text.primaryColor}
            colorPalette={getCategoricalColors(globalStyleConfig)}
            onColorChange={(hex) => updateFormat({ constantColor: hex })}
          />
        </>
      ) : null}
      {selectedColorType === ColorSettings.CONDITIONAL ? (
        <>
          <InputWithBlurSave
            containerClassName={labelStyle}
            helpIcon="code"
            helpText="This field can take in variables"
            initialValue={String(colorFormat?.conditionalTriggerValue ?? '')}
            label="Conditional Trigger Value"
            onNewValueSubmitted={(newValue: string) => {
              updateFormat({
                conditionalTriggerValue: newValue,
              });
            }}
          />
          <div className={sprinkles({ flexItems: 'alignCenter', width: 'fill' })}>
            <div className={sprinkles({ flex: 1 })}>
              <InputLabel className={labelStyle} text="Positive Color" />
              <ColorPickerButton
                fillWidth
                className={labelStyle}
                color={colorFormat?.conditionalPositiveColor || vars.colors.green9}
                colorPalette={getCategoricalColors(globalStyleConfig)}
                onColorChange={(hex) => updateFormat({ conditionalPositiveColor: hex })}
              />
            </div>
            <div className={sprinkles({ flex: 1 })}>
              <InputLabel className={labelStyle} text="Negative Color" />
              <ColorPickerButton
                fillWidth
                className={labelStyle}
                color={colorFormat?.conditionalNegativeColor || vars.colors.red9}
                colorPalette={getCategoricalColors(globalStyleConfig)}
                onColorChange={(hex) => updateFormat({ conditionalNegativeColor: hex })}
              />
            </div>
          </div>
        </>
      ) : null}
      {selectedColorType !== undefined ? (
        <Switch
          className={labelStyle}
          label={
            visualizationType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2
              ? 'Apply Color to Goal & Units'
              : 'Apply Color to Units'
          }
          onChange={() =>
            updateFormat({ applyColorToProgressGoal: !colorFormat?.applyColorToProgressGoal })
          }
          switchOn={colorFormat?.applyColorToProgressGoal}
        />
      ) : null}
    </div>
  );
};
