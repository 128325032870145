import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Icon, IconName } from '@blueprintjs/core';
import { formatLabel } from 'pages/dashboardPage/charts/utils';

import { InfoIcon } from 'components/InfoIcon';
import { STRING } from 'constants/dataConstants';
import { StringFormat } from 'constants/types';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { sprinkles } from 'components/ds';

const useStyles = makeStyles({
  tableColumnHeaderText: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'space-between',
  },
  leftItems: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',

    '&.LEFT_ALIGN': {
      justifyContent: 'flex-start',
    },
    '&.CENTER_ALIGN': {
      justifyContent: 'center',
    },
    '&.RIGHT_ALIGN': {
      justifyContent: 'flex-end',
    },
  },
  hoverIcon: {
    opacity: 0.5,
    visibility: 'hidden',

    '&.hidden': {
      display: 'none',
    },
  },
});

type Props = {
  header: string;
  rightIcon?: IconName;
  isSortable: boolean;
  alignment?: string;
  tooltipText?: string;
  stringFormat?: StringFormat;
};

export default function ColumnHeaderText(props: Props) {
  const { header, rightIcon, isSortable, alignment, tooltipText, stringFormat } = props;
  const classes = useStyles();

  return (
    <div
      className={cx(
        'bp3-table-truncated-text',
        classes.tableColumnHeaderText,
        GLOBAL_STYLE_CLASSNAMES.text.tableColumnHeader.base,
      )}
      title="">
      <div className={cx(classes.leftItems, alignment)}>
        {formatLabel(header, STRING, undefined, undefined, undefined, stringFormat)}
        {tooltipText ? <InfoIcon infoTooltipText={tooltipText} /> : null}
      </div>
      {rightIcon ? (
        <Icon className={sprinkles({ marginLeft: 'sp.5' })} icon={rightIcon} iconSize={12} />
      ) : isSortable ? (
        <Icon
          className={cx(
            classes.hoverIcon,
            'columnSortIcon',
            sprinkles({ marginLeft: 'sp.5' }),
            GLOBAL_STYLE_CLASSNAMES.text.tableColumnHeader.base,
          )}
          icon="arrow-up"
          iconSize={12}
        />
      ) : null}
    </div>
  );
}
