import { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as RD from 'remotedata';

import DropdownSelect from 'shared/DropdownSelect';

import { Customer } from 'actions/teamActions';
import { ReduxState } from 'reducers/rootReducer';
import { fetchCustomers } from 'actions/customerActions';
import { SelectedDropdownInputItem } from 'constants/types';

type Props = {
  disabled?: boolean;
  // Current value for the dropdown
  customer?: Customer;
  // Customers to remove as options
  customersToFilter?: number[];
  // Called when the user selects a new value in the dropdown
  onChange: (customer: Customer) => void;
};

/**
 * Loads a list of end users and lets the user select them in a dropdown menu
 */
export const CustomerSelect: FC<Props> = ({ disabled, customer, onChange, customersToFilter }) => {
  const dispatch = useDispatch();
  const customers = useSelector((state: ReduxState) => state.customers.groups);

  useEffect(() => {
    if (RD.isIdle(customers)) dispatch(fetchCustomers());
  }, [dispatch, customers]);

  const customerOptions = useMemo(() => {
    if (!RD.isSuccess(customers)) return [];

    const filterSet = new Set(customersToFilter);

    const options: SelectedDropdownInputItem[] = [];
    customers.data.forEach((group) => {
      if (filterSet.has(group.id)) return;
      const name = group.name.trim();
      options.push({ id: String(group.id), name: name || String(group.id) });
    });
    return options;
  }, [customers, customersToFilter]);

  const selectedCustomer = useMemo(
    () => customerOptions.find((opt) => opt.id === String(customer?.id)),
    [customerOptions, customer],
  );

  return (
    <DropdownSelect
      fillWidth
      ignoreCustomStyles
      minimal
      showIcon
      disabled={disabled || !RD.isSuccess(customers)}
      noSelectionText="Select customer"
      onChange={(item) => {
        const groupId = parseInt(item.id);
        if (!RD.isSuccess(customers)) return;

        const customer = customers.data.find((group) => group.id === groupId);
        if (customer) onChange(customer);
      }}
      options={customerOptions}
      selectedItem={selectedCustomer}
    />
  );
};
