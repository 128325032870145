import { makeStyles, Theme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { some } from 'utils/standard';
import { Tooltip, Position } from '@blueprintjs/core';
import { AppToaster } from 'toaster';
import produce from 'immer';

import SettingHeader from '../SettingHeader';
import DroppableColumnSection from './droppable/DroppableColumnSection';
import DropdownSelect from 'shared/DropdownSelect';
import Button from 'shared/Button';

import {
  Aggregation,
  AggregationType,
  CategoryChartColumnInfo,
  OPERATION_TYPES,
  V2TwoDimensionChartInstructions,
} from 'constants/types';
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { DatasetSchema, DatasetColumn } from 'types/datasets';
import {
  getNewAggCol,
  resolveCategoryColDropped,
  resolveAggColDropped,
  filterForValidDateGroupElements,
} from './utils';
import { DashboardElement } from 'types/dashboardTypes';
import {
  DATE_PART_INPUT_AGG,
  NUMBER_TYPES,
  VIZ_OPS_WITH_CATEGORY_SELECT_DRILLDOWN,
} from 'constants/dataConstants';
import { DEFAULT_Y_AXIS_FORMAT_INDEX } from 'pages/dashboardPage/charts/utils/multiYAxisUtils';
import { COLOR_CATEGORY_FILTER_SUFFIX } from 'constants/dashboardConstants';
import { showCustomToast } from 'shared/sharedToasts';
import { createColorCol, handleColorColumnAddition } from 'utils/colorColUtils';
import { updateVariableEvent } from 'utils/customEventUtils';
import { findMatchingAggColIdx, findMatchingAgg } from 'utils/aggUtils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  categoryVariableInput: {
    padding: theme.spacing(3),
    paddingTop: 0,
  },
}));

type Props = {
  instructions: V2TwoDimensionChartInstructions;
  canSelectSmartGrouping?: boolean;
  chartType: OPERATION_TYPES;
  categoryName?: string;
  aggName?: string;
  colorName?: string;
  maxAggs?: number;
  supportColor?: boolean;
  loading?: boolean;
  dashboardElements?: DashboardElement[];
  dataPanelProvidedId?: string;
  enableMultipleColorCols?: boolean;
  supportSecondaryGroup?: boolean;
  secondaryGroupName?: string;
  schema: DatasetSchema;
};

export default function TwoDimensionVizConfig({
  aggName = 'Y-Axis',
  canSelectSmartGrouping,
  categoryName = 'X-Axis',
  chartType,
  colorName = 'Group By',
  instructions,
  maxAggs,
  supportColor,
  loading,
  dashboardElements,
  dataPanelProvidedId,
  enableMultipleColorCols,
  supportSecondaryGroup,
  secondaryGroupName = 'Clusters',
  schema,
}: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const aggColumns = instructions.aggColumns || [];

  const updateGrouping = (newGrouping: CategoryChartColumnInfo | undefined) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.groupingColumn = newGrouping;
    });
    dispatch(updateVisualizeOperation(newInstructions, chartType));
  };

  const onAggregationColumnAdded = (
    newCol: DatasetColumn,
    oldColName?: string,
    oldColAggType?: AggregationType,
  ) => {
    const newInstructions = produce(instructions, (draft) => {
      // This is case we are adding a new column
      if (oldColName === undefined) {
        draft.aggColumns = resolveAggColDropped(
          newCol,
          (toastInfo) => AppToaster.show(toastInfo),
          draft.aggColumns || [],
          maxAggs,
          draft.yAxisFormats?.[DEFAULT_Y_AXIS_FORMAT_INDEX].id,
        );
        return;
      }
      // This is case we are replacing an old column
      if (!draft.aggColumns) return;
      const newAggCol = getNewAggCol(
        newCol,
        (toastInfo) => AppToaster.show(toastInfo),
        draft.aggColumns,
        draft.yAxisFormats?.[DEFAULT_Y_AXIS_FORMAT_INDEX].id,
      );
      if (newAggCol === undefined) return;
      const removedColIndex = findMatchingAggColIdx(draft.aggColumns, oldColName, oldColAggType);
      if (removedColIndex === -1) return;
      draft.aggColumns.splice(removedColIndex, 1, newAggCol);
    });
    dispatch(updateVisualizeOperation(newInstructions, chartType));
  };

  const onColorColumnAdded = (newCol: DatasetColumn, oldColName?: string) => {
    const newInstructions = produce(instructions, (draft) => {
      if (some(draft.colorColumnOptions, (opt) => opt.column.name === newCol.name)) {
        showCustomToast(
          'The selected column option is already present for this column. Duplicates are not allowed.',
          { icon: 'warning-sign' },
        );
        return;
      }
      updateVariableEvent({
        varName: dataPanelProvidedId + COLOR_CATEGORY_FILTER_SUFFIX,
        newValue: newCol.name,
      });
      // This is case we are adding a new column
      if (oldColName === undefined) {
        draft.colorColumnOptions = handleColorColumnAddition(
          newCol,
          draft.colorColumnOptions,
          enableMultipleColorCols,
        );
        return;
      }
      // This is case we are replacing an old column
      if (!draft.colorColumnOptions) return;
      const removedColIndex = draft.colorColumnOptions.findIndex(
        (col) => col.column.name === oldColName,
      );
      if (removedColIndex === -1) return;
      draft.colorColumnOptions.splice(removedColIndex, 1, createColorCol(newCol));
    });
    dispatch(updateVisualizeOperation(newInstructions, chartType));
  };

  const isBarChartGroupingColumn =
    VIZ_OPS_WITH_CATEGORY_SELECT_DRILLDOWN.has(chartType) &&
    NUMBER_TYPES.has(instructions.categoryColumn?.column?.type ?? '');

  return (
    <div className={classes.root}>
      <SettingHeader name={categoryName} />
      <DroppableColumnSection
        required
        canSelectSmartGrouping={canSelectSmartGrouping}
        columns={instructions.categoryColumn ? [instructions.categoryColumn] : []}
        dashboardElements={dashboardElements}
        disableEdits={loading}
        isBarChartGroupingColumn={isBarChartGroupingColumn}
        maxCols={1}
        onColAdded={(col) => {
          const newInstructions = produce(instructions, (draft) => {
            draft.categoryColumn = resolveCategoryColDropped(col, draft.categoryColumn);
          });
          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        onColOptionChanged={(option) => {
          const newInstructions = produce(instructions, (draft) => {
            if (draft.categoryColumn) {
              if (isBarChartGroupingColumn) {
                const bucketSize = parseInt(option.id);
                draft.categoryColumn.bucketSize = isNaN(bucketSize) ? undefined : bucketSize;
              } else {
                draft.categoryColumn.bucket = option;
              }
            }
          });
          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        onRemoveCol={() => {
          const newInstructions = produce(instructions, (draft) => {
            draft.categoryColumn = undefined;
          });
          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        schema={schema}
      />
      {instructions.categoryColumn?.bucket?.id === DATE_PART_INPUT_AGG ? (
        <div className={classes.categoryVariableInput}>
          <DropdownSelect
            fillWidth
            minimal
            showIcon
            filterable={false}
            noSelectionText="Select an Input"
            onChange={(newValue) => {
              const newInstructions = produce(instructions, (draft) => {
                if (!draft.categoryColumn) return;

                draft.categoryColumn.bucketElemId = newValue.id;
              });

              dispatch(updateVisualizeOperation(newInstructions, chartType));
            }}
            options={filterForValidDateGroupElements(dashboardElements).map((elem) => ({
              id: elem.name,
              name: elem.name,
            }))}
            selectedItem={
              instructions.categoryColumn.bucketElemId
                ? {
                    id: instructions.categoryColumn.bucketElemId,
                    name: instructions.categoryColumn.bucketElemId,
                  }
                : undefined
            }
          />
        </div>
      ) : null}

      <SettingHeader
        name={aggName}
        rightContent={
          <Tooltip
            usePortal
            content="Click to add a custom formula aggregation"
            position={Position.BOTTOM}>
            <Button
              compact
              minimal
              icon="function"
              onClick={() => {
                const newInstructions = produce(instructions, (draft) => {
                  draft.aggColumns = resolveAggColDropped(
                    { name: 'custom_formula', type: 'string' },
                    (toastInfo) => AppToaster.show(toastInfo),
                    draft.aggColumns,
                    maxAggs,
                    instructions.yAxisFormats?.[DEFAULT_Y_AXIS_FORMAT_INDEX].id,
                  );

                  draft.aggColumns[draft.aggColumns.length - 1].agg = {
                    id: Aggregation.FORMULA,
                    formula: '',
                  };
                });
                dispatch(updateVisualizeOperation(newInstructions, chartType));
              }}
            />
          </Tooltip>
        }
      />
      <DroppableColumnSection
        required
        columns={maxAggs ? aggColumns.slice(0, maxAggs) : aggColumns}
        disableEdits={loading}
        maxCols={maxAggs}
        onColAdded={onAggregationColumnAdded}
        onColOptionChanged={(option, name, aggType) => {
          const newInstructions = produce(instructions, (draft) => {
            const changedCol = findMatchingAgg(draft.aggColumns, name, aggType);
            if (!changedCol) return;

            const agg = option as AggregationType;

            if (findMatchingAgg(draft.aggColumns, name, agg)) {
              showCustomToast(
                'The selected aggregation is already present for this column. Duplicates are not allowed.',
                { icon: 'warning-sign' },
              );

              return;
            }

            changedCol.agg = { id: agg.id, formula: agg.formula };
          });

          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        onRemoveCol={(col, aggType) => {
          const newInstructions = produce(instructions, (draft) => {
            const removedColIndex = findMatchingAggColIdx(draft.aggColumns, col.name, aggType);
            if (removedColIndex >= 0) draft.aggColumns?.splice(removedColIndex, 1);
          });

          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        schema={schema}
      />
      {supportColor ? (
        <>
          <SettingHeader name={colorName} />
          <DroppableColumnSection
            columns={instructions.colorColumnOptions || []}
            disableEdits={loading}
            maxCols={enableMultipleColorCols ? undefined : 1}
            onColAdded={onColorColumnAdded}
            onColOptionChanged={(option, name) => {
              if (!name) return;
              const newInstructions = produce(instructions, (draft) => {
                const col = draft.colorColumnOptions?.find(({ column }) => column.name === name);
                if (col) col.bucket = option;
              });
              dispatch(updateVisualizeOperation(newInstructions, chartType));
            }}
            onRemoveCol={({ name }) => {
              const newInstructions = produce(instructions, (draft) => {
                if (!draft.colorColumnOptions || draft.colorColumnOptions.length === 1) {
                  draft.colorColumnOptions = undefined;
                } else {
                  draft.colorColumnOptions = draft.colorColumnOptions.filter(
                    (col) => col.column.name !== name,
                  );
                }
              });

              updateVariableEvent({
                varName: dataPanelProvidedId + COLOR_CATEGORY_FILTER_SUFFIX,
                newValue: newInstructions.colorColumnOptions?.[0].column.name,
              });
              dispatch(updateVisualizeOperation(newInstructions, chartType));
            }}
            schema={schema}
          />
        </>
      ) : null}
      {supportSecondaryGroup ? (
        <>
          <SettingHeader name={secondaryGroupName} />
          <DroppableColumnSection
            columns={instructions.groupingColumn ? [instructions.groupingColumn] : []}
            disableEdits={loading}
            maxCols={1}
            onColAdded={(col) =>
              updateGrouping(resolveCategoryColDropped(col, instructions.groupingColumn))
            }
            onColOptionChanged={(option) => {
              if (!instructions.groupingColumn) return;
              updateGrouping({ ...instructions.groupingColumn, bucket: option });
            }}
            onRemoveCol={() => updateGrouping(undefined)}
            schema={schema}
          />
        </>
      ) : null}
    </div>
  );
}
