import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { DashboardVariableMap, ImageElemConfig } from 'types/dashboardTypes';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { replaceTemplatesWithValues } from 'utils/dataPanelConfigUtils';
import FlexBox, { VerticalAlignment, HorizontalAlignment } from 'components/core/FlexBox';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',

    overflow: 'hidden',
    position: 'relative',
  },
  empty: {
    fontStyle: 'italic',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: 0,
  },
  imageFitContainer: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

type Props = {
  config: ImageElemConfig;
  variables: DashboardVariableMap;
};

const DashboardImageElement = ({ config, variables }: Props) => {
  const classes = useStyles();

  if (!config.imageUrl)
    return <div className={cx(classes.root, classes.empty)}>Enter an image URL</div>;

  return (
    <FlexBox
      className={classes.root}
      horizontalAlignment={HorizontalAlignment.CENTER}
      verticalAlignment={VerticalAlignment.CENTER}>
      <img
        alt="dashboard custom"
        className={cx(
          { [classes.image]: !config.fitEntireImage },
          { [classes.imageFitContainer]: config.fitEntireImage },
          GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.default.borderRadius,
        )}
        src={replaceTemplatesWithValues(config.imageUrl, variables)}
      />
    </FlexBox>
  );
};

export default DashboardImageElement;
