import { FC } from 'react';
import { SizeMe } from 'react-sizeme';

import DropdownSelect from 'shared/DropdownSelect';

import { SelectElemConfig, DashboardVariable, DashboardVariableMap } from 'types/dashboardTypes';
import { constructOptions } from 'utils/dropdownUtils';
import { resolveTooltipVariables } from 'utils/variableUtils';
import { DatasetRow } from 'types/datasets';
import { AnalyticsEventTracker } from 'utils/analyticsUtils';
import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import { OnNewValueSelectFunc } from 'types/functionPropTypes';

type Props = {
  analyticsEventTracker?: AnalyticsEventTracker;
  config: SelectElemConfig;
  datasets: Record<string, Partial<{ _rows: DatasetRow[] }>>;
  onNewValueSelect: OnNewValueSelectFunc;
  value?: DashboardVariable;
  disabled?: boolean;
  openElementToLeft: boolean;
  variables?: DashboardVariableMap;
};

export const DashboardDropdownElement: FC<Props> = ({
  analyticsEventTracker,
  config,
  value,
  onNewValueSelect,
  datasets,
  disabled,
  openElementToLeft,
  variables,
}) => {
  const options = constructOptions(config.valuesConfig, datasets);
  let selectedOption = options.find((option) => option.value === value);

  // If the selected value is not in the options, it is coming from a default variable.
  // In this case create your own option for this
  if (value && !selectedOption) {
    selectedOption = { id: String(value), name: String(value) };
  }

  const renderDropdownSelect = (maxWidth?: number | null) => {
    return (
      <DropdownSelect
        fillWidth
        minimal
        disableOnNoItems={config.disableOnNoItems}
        disabled={disabled}
        filterPlaceholderText={config.filterPlaceholderText || 'Filter...'}
        filterable={!config.disableSearch}
        label={config.label}
        labelHelpText={resolveTooltipVariables(config, variables)}
        maxWidth={maxWidth ?? undefined}
        noSelectionText={config.placeholderText || ''}
        onCancelClick={() => {
          onNewValueSelect(undefined, { display: undefined });
          analyticsEventTracker?.(REPORTED_ANALYTIC_ACTION_TYPES.DROPDOWN_SELECTED);
        }}
        onChange={(item) => {
          onNewValueSelect(item.value, { display: item.name });
          analyticsEventTracker?.(REPORTED_ANALYTIC_ACTION_TYPES.DROPDOWN_SELECTED);
        }}
        openElementToLeft={openElementToLeft}
        options={options}
        selectedItem={selectedOption}
        showCancelBtn={!config.disableCancel}
        useFakeLabel={config.label === ''}
        usePortal={config.usePortal}
      />
    );
  };

  return (
    /* Don't need the width if not using portal */
    config.usePortal ? (
      <SizeMe>{({ size }) => renderDropdownSelect(size.width)}</SizeMe>
    ) : (
      renderDropdownSelect()
    )
  );
};
