import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FC } from 'react';

import FullPageContentContainer from 'shared/FullPageContentContainer';
import ConnectStepByStep from '../ConnectStepByStep';
import ConnectFAQs from '../ConnectFAQs';
import CalloutLink from 'shared/CalloutLink';

import { ConnectDataSourceStep, EXPLO_EGRESS_IPS } from '../constants';
import { DBConnectionConfig } from '../types';
import { areCredentialsComplete, getPropertyNames } from '../utils';
import { CredentialsSection } from './shared/CredentialsSection';
import { SupportedDataSource, DataSourceConfiguration } from 'actions/dataSourceActions';

const useStyles = makeStyles((theme: Theme) => ({
  helpTooltip: {
    margin: theme.spacing(1.5),
  },
  sectionHeader: {
    fontWeight: 600,
    fontSize: 16,
    marginBottom: theme.spacing(1),
  },
  sshTunnelHeader: {
    marginBottom: theme.spacing(4),
  },
  sectionText: {
    fontSize: 14,
    marginBottom: theme.spacing(4),
  },
  whitelistCallout: {
    marginBottom: theme.spacing(8),
  },
}));

type Props = {
  config: DBConnectionConfig;
  updateConfig: (newconfig: DBConnectionConfig) => void;
  onNextClicked: () => void;
  onBackClicked: () => void;
  testingConnLoading?: boolean;
  headerClassName?: string;
};

const SecurityConfiguration: FC<Props> = ({
  config,
  updateConfig,
  onNextClicked,
  onBackClicked,
  testingConnLoading,
  headerClassName,
}) => {
  if (!config.selectedDataSource)
    return <div>Error: A data source type must be selected before proceeding</div>;

  const dataSource = config.selectedDataSource;
  const configuration = config.dataSourceConfig || {};

  return (
    <FullPageContentContainer
      backBtnConfig={{
        onClick: onBackClicked,
      }}
      bodyContent={
        <SecurityConfigurationBody
          config={config}
          headerClassName={headerClassName}
          updateConfig={updateConfig}
        />
      }
      headerTitle="Security"
      leftSideBarBottomContent={<ConnectFAQs />}
      leftSideBarTopContent={<ConnectStepByStep currentStep={ConnectDataSourceStep.SECURITY} />}
      primaryActionConfig={{
        text: 'Next',
        disabled: !areCredentialsComplete(
          dataSource.configuration_schema.properties,
          configuration,
          true,
        ),
        onClick: onNextClicked,
        loading: testingConnLoading,
      }}
    />
  );
};

type BodyProps = {
  config: DBConnectionConfig;
  updateConfig: (newconfig: DBConnectionConfig) => void;
  selectedDataSource?: SupportedDataSource;
  userViewableCredentials?: DataSourceConfiguration;
  headerClassName?: string;
};

export const SecurityConfigurationBody: FC<BodyProps> = ({
  config,
  updateConfig,
  selectedDataSource,
  userViewableCredentials,
  headerClassName,
}) => {
  const classes = useStyles();

  const dataSource = config.selectedDataSource ?? selectedDataSource;
  if (!dataSource) return null;

  const securityPropertyConfigs = getPropertyNames(dataSource.configuration_schema, true);

  return (
    <div data-testid="connect-datasource-security-config">
      <div className={headerClassName ?? classes.sectionHeader}>Whitelisting</div>
      <div className={classes.sectionText}>
        If your database is behind a firewall, please whitelist Explo’s egress IPs:
        <b> {EXPLO_EGRESS_IPS.join(', ')}.</b>
      </div>
      <CalloutLink
        className={classes.whitelistCallout}
        text="How to whitelist Explo's egress IPs"
        url="https://docs.explo.co/data-sources/connecting-to-your-database-or-data-warehouse#secure-connections"
      />
      {securityPropertyConfigs.length === 0 ? null : (
        <>
          <div className={headerClassName ?? cx(classes.sectionHeader, classes.sshTunnelHeader)}>
            SSH Tunnel
          </div>
          <CredentialsSection
            config={config}
            dataSource={dataSource}
            propertyNames={securityPropertyConfigs}
            updateConfig={updateConfig}
            userViewableCredentials={userViewableCredentials}
          />
        </>
      )}
    </div>
  );
};

export default SecurityConfiguration;
