import { Component } from 'react';
import { connect } from 'react-redux';
import { Settings } from 'luxon';
import { cloneDeep, isEmpty, isEqual, keyBy, map, uniqueId, without } from 'utils/standard';
import cx from 'classnames';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { Layout } from '@explo-tech/react-grid-layout';
// @ts-ignore
import JSURL from 'jsurl';
import { v4 as uuidv4 } from 'uuid';
import produce from 'immer';
import { datadogRum } from '@datadog/browser-rum';

import ElementGridLayout from './ElementGridLayout';
import { DashboardStickyHeader } from './DashboardStickyHeader';
import Poller from 'components/JobQueue/Poller';
import { Jobs } from 'components/JobQueue/types';

import { GlobalStylesContext } from 'globalStyles';
import {
  CategoryChartColumnInfo,
  OPERATION_TYPES,
  REPORTED_ANALYTIC_ACTION_TYPES,
  UserTransformedSchema,
  VISUALIZE_TABLE_OPERATIONS,
} from 'constants/types';
import {
  elemIdFromDropId,
  dataPanelsAdded,
  dashboardElementsAdded,
  datasetsChanged,
  getDashboardElemsWithDefaultQueryValues,
  isValueInTableRows,
  getDatasetIdsForElems,
  extractDatasetIdsFromElems,
  removeUnderscoreFields,
  areRequiredUserInputsSet,
  getDefaultValueForNewElem,
  getSynchronousSecondaryDataInstructions,
  getAsynchronousSecondaryDataInstructions,
  getUrlParamStringFromDashVars,
  getDashboardElemsUsingDatasets,
  prepareDataPanelForFetch,
  elementBlockedOnApplyButton,
  resetDependedElements,
  resetDependentBlockedElements,
  getDefaultValueFromRows,
} from 'utils/dashboardUtils';
import {
  DashboardElement,
  SelectElemConfig,
  DashboardVariable,
  DashboardVariableMap,
  VIEW_MODE,
  PAGE_TYPE,
  ExportElemConfig,
  DrilldownModalConfig,
  DASHBOARD_LAYOUT_CONFIG,
} from 'types/dashboardTypes';
import { AdHocOperationInstructions } from 'types/dataPanelTemplate';
import { FetchDatasetPreview } from 'actions/datasetActions';
import { DatasetRow } from 'types/datasets';
import { Customer } from 'actions/teamActions';
import { FetchDataPanelData, FetchDashboardDatasetPreviewData } from 'actions/responseTypes';
import { DASHBOARD_ELEMENT_TYPES } from 'types/dashboardTypes';
import { setDpLoading, UpdateElementConfigArgs } from 'actions/dashboardV2Actions';
import {
  constructFilterFromDrilldownColumn,
  dataPanelRequiresPrimaryData,
  isDataPanelReadyToCompute,
} from 'utils/dataPanelConfigUtils';
import DashboardLayoutContext from './DashboardLayoutContext';
import {
  FetchDataPanel,
  FetchDataPanelBody,
  FetchDataPanelRowCount,
  FetchDataPanelRowCountBody,
  FetchSecondaryData,
  FetchSecondaryDataBody,
  DownloadDataPanelSpreadsheet,
  DownloadDataPanelSpreadsheetBody,
  updateAdHocOperationInstructions,
  UpdateDrilldownDataPanelActionType,
} from 'actions/dataPanelTemplateAction';
import { getCustomerVariables } from 'utils/customerUtils';
import { getTransformedDataPanelForCsv } from 'utils/csvDownloadUtils';
import { getFilterInfo, getSortInfo } from 'utils/adHocUtils';
import * as variableUtils from 'utils/variableUtils';
import * as exploResourceUtils from 'utils/exploResourceUtils';
import * as extraVarUtils from 'utils/extraVariableUtils';
import { ExploResource, ResourceDataset, DataPanel, RequestDataset } from 'types/exploResource';
import {
  DownloadDashboardImage,
  DownloadDashboardPdf,
  DownloadDataPanelPdf,
} from 'actions/exportActions';
import { ACTION } from 'actions/types';
import { BulkEnqueueFnWithArgs, JobDefinition } from 'actions/jobQueueActions';
import { EXPLO_SVG_LOGO } from 'constants/iconConstants';
import { sortSchemaByOrderedColumnNames } from 'utils/general';
import { clearDashboardLayoutReducer } from 'actions/dashboardLayoutActions';
import { DrilldownModal } from 'pages/dashboardPage/charts/DrilldownModal';
import { DRILLDOWN_DATA_PANEL_ID } from 'reducers/dashboardEditConfigReducer';
import { AnalyticsEventTracker } from 'utils/analyticsUtils';
import {
  DataPanelUpdateEvent,
  DATA_PANEL_LINK_UPDATE,
  DATA_PANEL_UPDATE_EVENT,
  DeleteDashboardVariablesEvent,
  DELETE_DASHBOARD_VARIABLES_EVENT,
  DataPanelLinkUpdateEvent,
  UPDATE_VARIABLE_VALUE_EVENT,
  UpdateVariableEvent,
} from 'utils/customEventUtils';
import { getSelectFilterDatasetId } from 'utils/filterUtils';
import { attachLinkFiltersToDp, DashboardLinks } from 'utils/filterLinking';
import { DashboardPageLayoutConfig } from 'types/dashboardVersionConfig';
import { SpreadsheetType } from 'reducers/dashboardLayoutReducer';

const MINIMUM_MINUTES_FOR_REFRESH = 0.25; // 15 Seconds
export const DASHBOARD_LAYOUT_ID_FOR_PORTALS = uniqueId('explo-dashboard');

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      position: 'relative',
    },
    pdfEditor: {
      overflow: 'visible',
    },
    emailView: {
      height: 'auto',
      backgroundColor: theme.palette.ds.white,
      width: '650px',
      margin: 'auto',
    },
    mobileEditor: {
      height: '100%',
      backgroundColor: theme.palette.ds.white,
      width: '320px',
      margin: 'auto',
    },
    exploBrand: {
      position: 'absolute',
      borderRadius: 3,
      bottom: 12,
      right: 12,
      backgroundColor: theme.palette.ds.white,
      color: theme.palette.ds.grey900,
      padding: '6px 8px 6px 6px',
      opacity: 1,
      display: 'flex',
      fontWeight: 500,
      alignItems: 'center',
      lineHeight: '18px',
      '&:hover': {
        backgroundColor: theme.palette.ds.grey100,
        textDecoration: 'none',
      },
    },
  });

type PassedProps = {
  analyticsEventTracker?: AnalyticsEventTracker;
  bulkEnqueueJobsWrapper?: BulkEnqueueFnWithArgs;
  datasets: Record<string, ResourceDataset>;
  dashboardElements: DashboardElement[];
  dashboardLayout: Layout[];
  dashboardLinks?: DashboardLinks;
  dataPanels: DataPanel[];
  disableInputs?: boolean;
  downloadDashboardImage?: DownloadDashboardImage;
  downloadDashboardPdf?: DownloadDashboardPdf;
  downloadDataPanelSpreadsheet: DownloadDataPanelSpreadsheet;
  downloadDataPanelPdf?: DownloadDataPanelPdf;
  draggingElementType?: string;
  editableDashboard?: boolean;
  endUserVariables?: DashboardVariableMap;
  exploResource: ExploResource;
  fetchDataPanel: FetchDataPanel;
  fetchDataPanelRowCount: FetchDataPanelRowCount;
  fetchSecondaryData: FetchSecondaryData;
  fetchDatasetPreview: FetchDatasetPreview;
  fetchShareData: (password?: string, username?: string, isStrictViewingMode?: boolean) => void;
  globalStylesClassName?: string;
  hoverElementId?: string;
  isCanvas?: boolean;
  isArchitectCustomerDashboard?: boolean;
  isViewOnly: boolean;
  isVisible?: boolean;
  onCloseConfigClicked?: () => void;
  onCreateDataPanel?: (
    newLayout: Layout[],
    visualizationType: OPERATION_TYPES,
    containerId?: string,
  ) => void;
  onCreateNewDashboardElement?: (
    id: string,
    elemType: DASHBOARD_ELEMENT_TYPES,
    newLayout: Layout[],
    containerId?: string,
  ) => void;
  onDashboardItemSelect?: (type: DASHBOARD_ELEMENT_TYPES, id: string) => void;
  onDashboardLayoutStateSelected?: (layout: DASHBOARD_LAYOUT_CONFIG) => void;
  onDeleteSelectedItem?: () => void;
  onVariablesChange?: (newVariables: DashboardVariableMap) => void;
  pageLayoutConfig?: DashboardPageLayoutConfig;
  pageType: PAGE_TYPE;
  refreshMinutes?: number;
  resourceVersionNumber: number | undefined;
  selectedDashboardItemId?: string;
  selectedLayoutConfig?: DASHBOARD_LAYOUT_CONFIG;
  shouldUseJobQueue?: boolean;
  showExploBranding?: boolean;
  supportEmail?: string;
  timezone: string;
  updateDashboardLayout?: (newLayout: Layout[]) => void;
  updateDrilldownDataPanel?: UpdateDrilldownDataPanelActionType;
  updateElementConfig?: (args: UpdateElementConfigArgs) => void;
  updateUrlParams?: boolean;
  userGroup: Customer | undefined;
  customerToken?: string;
  variablesDefaultValues?: DashboardVariableMap;
  viewMode: VIEW_MODE;
  width?: number | null;
};

type Props = PassedProps & typeof mapDispatchToProps & WithStyles<typeof styles>;

type State = {
  intervalId?: number;
  variables: DashboardVariableMap; // var_name -> variable
  blockedVariables: DashboardVariableMap; // element_id -> variable
  dashboardLoaded: boolean;

  awaitedJobs: Record<string, Jobs>;
  drilldownModalConfig?: DrilldownModalConfig;
};

type DatasetRowsById = Record<string, DatasetRow[]>;

class DashboardLayout extends Component<Props, State> {
  state: State = {
    variables: {},
    blockedVariables: {},
    dashboardLoaded: false,

    awaitedJobs: {},
  };

  constructor(props: Props) {
    super(props);

    const {
      dashboardElements,
      dataPanels,
      variablesDefaultValues,
      refreshMinutes,
      userGroup,
      endUserVariables,
      clearDashboardLayoutReducer,
      timezone,
    } = props;

    clearDashboardLayoutReducer();

    // the luxon default timezone is local, set this to UTC just so everything's
    // standard. In reality, dates are handled by the backend so this shouldn't
    // matter
    Settings.defaultZone = 'UTC';

    // Keep state when switching between end user dashboards
    if (endUserVariables) {
      this.state = {
        variables: endUserVariables,
        blockedVariables: {},
        dashboardLoaded: false,

        awaitedJobs: {},
      };
      return;
    }

    const dpDefaultVars = variableUtils.initializeDpColorCategoryDropdownVariables(dataPanels);

    let elemDefaultVars = variableUtils.getDefaultVariablesFromDashElements(
      dashboardElements,
      timezone,
      variablesDefaultValues,
    );

    if (userGroup) {
      elemDefaultVars = {
        ...elemDefaultVars,
        ...getCustomerVariables(userGroup),
      };
    }

    if (variablesDefaultValues) {
      elemDefaultVars = {
        ...elemDefaultVars,
        ...variablesDefaultValues,
      };
    }

    if (dpDefaultVars) {
      elemDefaultVars = {
        ...elemDefaultVars,
        ...dpDefaultVars,
      };
    }

    this.state = {
      variables: elemDefaultVars,
      blockedVariables: {},
      dashboardLoaded: false,

      awaitedJobs: {},
    };

    props.onVariablesChange?.(elemDefaultVars);

    this.initializeDashboardData(dashboardElements);

    if (refreshMinutes && refreshMinutes >= MINIMUM_MINUTES_FOR_REFRESH) {
      this.state.intervalId = setInterval(
        this.fetchDashboardDataWrapper.bind(this),
        // convert minutes to milliseconds
        refreshMinutes * 60 * 1000,
      );
    }
  }

  initializeDashboardData = (dashboardElements: DashboardElement[]) => {
    const dashboardElemsWithDefaultQueryValue =
      getDashboardElemsWithDefaultQueryValues(dashboardElements);

    if (dashboardElemsWithDefaultQueryValue.length > 0) {
      this.fetchDropdownQueryDefaults(
        dashboardElemsWithDefaultQueryValue,
        new Set(),
        true,
        (updatedDatasetIds) => {
          this.fetchDashboardDataWrapper(updatedDatasetIds);
        },
      );
    } else {
      this.fetchDashboardDataWrapper();
    }
  };

  fetchDropdownQueryDefaults = (
    defaultElems: DashboardElement[],
    changedElementNamesSet: Set<string>,
    calledOnMount: boolean,
    onComplete: (updatedDatasetIds?: string[], changedVariableNames?: Set<string>) => void,
  ) => {
    const { shouldUseJobQueue } = this.props;
    // If there are no default elements we don't need to do anything
    if (defaultElems.length === 0) return onComplete?.();

    const datasetIdsForDefaultElems = extractDatasetIdsFromElems(defaultElems);

    const datasetPromiseList: unknown[] = [];

    const requests: JobDefinition[] = [];

    // TODO the whole dashboard blocks on this because of the promise....
    datasetIdsForDefaultElems.forEach((datasetId) => {
      const listenValue = `dataset_${datasetId}_${uuidv4()}`;

      if (shouldUseJobQueue)
        requests.push(
          this.fetchDatasetPreviewAsync(
            datasetId,
            () => window.dispatchEvent(new CustomEvent(listenValue)),
            () => window.dispatchEvent(new CustomEvent(listenValue)),
          ),
        );
      else
        this.fetchDatasetPreviewSync(
          datasetId,
          () => window.dispatchEvent(new CustomEvent(listenValue)),
          () => window.dispatchEvent(new CustomEvent(listenValue)),
        );

      datasetPromiseList.push(
        // ugly but we need to tell the below code that all of these requests are done
        new Promise((resolve) => {
          window.addEventListener(listenValue, function handler() {
            window.removeEventListener(listenValue, handler);
            resolve(1);
          });
        }),
      );
    });

    this.bulkEnqueueJobs(requests);

    Promise.all(datasetPromiseList).then(() => {
      this.onDefaultDropdownQueryFetch(
        defaultElems,
        changedElementNamesSet,
        calledOnMount,
        datasetIdsForDefaultElems,
        onComplete,
      );
    });
  };

  onDefaultDropdownQueryFetch = (
    defaultElems: DashboardElement[],
    changedElementNamesSet: Set<string>,
    calledOnMount: boolean,
    datasetIdsForDefaultElems: Set<string>,
    onComplete: (updatedDatasetIds?: string[], changedVariableNames?: Set<string>) => void,
  ) => {
    const { datasets } = this.props;

    const changedVarNames = this.setDropdownQueryDefaults(
      Object.assign({}, ...Object.keys(datasets).map((id) => ({ [id]: datasets[id]._rows }))),
      defaultElems,
      changedElementNamesSet,
      calledOnMount,
    );

    onComplete(Array.from(datasetIdsForDefaultElems), changedVarNames);
  };

  setDropdownQueryDefaults = (
    datasetRowsById: DatasetRowsById,
    defaultElems: DashboardElement[],
    changedElementNamesSet: Set<string>,
    calledOnMount?: boolean,
  ) => {
    const { variables } = this.state;

    if (defaultElems.length === 0) return;
    const defaultVars: Record<string, DashboardVariable> = {};

    defaultElems.forEach((elem) => {
      /**
       *  If the variable is set onMount (i.e. came from URL or embed variables)
       *  we don't want to override it
       */
      if (calledOnMount && variables[elem.name]) return;

      const elemWasCleared = !calledOnMount && !variables[elem.name];
      if (changedElementNamesSet.has(elem.name) || elemWasCleared) return;

      const config = elem.config as SelectElemConfig;
      const { queryTable, queryValueColumn } = config.valuesConfig;
      if (!queryTable || !queryValueColumn) return;

      // We can return early if this element's dataset doesn't use the variable that was updated
      const dataset = this.props.datasets[queryTable.id];
      if (
        changedElementNamesSet.size > 0 &&
        !variableUtils.isQueryDependentOnVariable(changedElementNamesSet, dataset)
      )
        return;

      /**
       * If the current value of the element is still contained in the new dataset, we don't
       * need to reset it
       */
      const datasetRows = datasetRowsById[queryTable.id];
      if (
        !datasetRows?.length ||
        isValueInTableRows(datasetRows, queryValueColumn.name, variables[elem.name])
      )
        return;

      const { defaultValue, defaultDisplay } = getDefaultValueFromRows(
        config.valuesConfig,
        datasetRows,
      );

      if (elem.element_type === DASHBOARD_ELEMENT_TYPES.MULTISELECT) {
        if (defaultValue === undefined) return;
        defaultVars[elem.name] = [defaultValue] as string[] | number[];
        defaultVars[extraVarUtils.getLengthVarName(elem.name)] = 1;
      } else {
        defaultVars[elem.name] = defaultValue;
        if (defaultDisplay) {
          defaultVars[extraVarUtils.getDisplayVarName(elem.name)] = defaultDisplay;
        }
      }
    });

    const newVariables = {
      ...this.state.variables,
      ...defaultVars,
    };
    this.setState({ variables: newVariables });

    this.props.onVariablesChange?.(newVariables);

    return new Set<string>(Object.keys(defaultVars));
  };

  fetchDropdownUpdatesFromDefaults = (
    defaultElems: DashboardElement[],
    changedElementNamesSet: Set<string>,
    onComplete: (updatedDatasetIds?: string[], updatedElemNames?: Set<string>) => void,
    calledOnMount?: boolean,
  ) => {
    const { datasets, dashboardElements, shouldUseJobQueue } = this.props;
    const { variables } = this.state;
    const datasetIdsForDefaultElems = extractDatasetIdsFromElems(defaultElems);
    const defaultElemNames = new Set(defaultElems.map((elem) => elem.name));

    const datasetsToUpdate = Object.values(datasets).filter((dataset) => {
      if (datasetIdsForDefaultElems.has(dataset.id)) return false;
      if (variableUtils.isQueryDependentOnVariable(changedElementNamesSet, dataset)) return true;
      return variableUtils.isQueryDependentOnVariable(defaultElemNames, dataset);
    });

    const updatedElems = getDashboardElemsUsingDatasets(dashboardElements, datasetsToUpdate).filter(
      (elem) => changedElementNamesSet.has(elem.name) && variables[elem.name] !== undefined,
    );

    if (updatedElems.length === 0) return onComplete?.();

    const datasetPromiseList: unknown[] = [];
    const datasetRowsById: DatasetRowsById = {};

    const requests: JobDefinition[] = [];

    datasetsToUpdate.forEach((dataset) => {
      const listenValue = `dataset_${dataset.id}_${uuidv4()}`;

      if (shouldUseJobQueue)
        requests.push(
          this.fetchDatasetPreviewAsync(
            dataset.id,
            (data) => {
              datasetRowsById[dataset.id] = data.dataset_preview._rows;
              window.dispatchEvent(new CustomEvent(listenValue));
            },
            () => window.dispatchEvent(new CustomEvent(listenValue)),
          ),
        );
      else
        this.fetchDatasetPreviewSync(
          dataset.id,
          (data) => {
            datasetRowsById[dataset.id] = data.dataset_preview._rows;
            window.dispatchEvent(new CustomEvent(listenValue));
          },
          () => window.dispatchEvent(new CustomEvent(listenValue)),
        );

      datasetPromiseList.push(
        // ugly but we need to tell the below code that all of these requests are done
        new Promise((resolve) => {
          window.addEventListener(listenValue, function handler() {
            window.removeEventListener(listenValue, handler);
            resolve(1);
          });
        }),
      );
    });

    this.bulkEnqueueJobs(requests);

    Promise.all(datasetPromiseList).then(() => {
      this.setDropdownUpdatesFromDefaults(
        datasetRowsById,
        updatedElems,
        changedElementNamesSet,
        calledOnMount,
      );

      onComplete(map(datasetsToUpdate, 'id'), new Set(map(updatedElems, 'name')));
    });
  };

  setDropdownUpdatesFromDefaults = (
    datasetRowsById: DatasetRowsById,
    updatedElems: DashboardElement[],
    changedElementNamesSet: Set<string>,
    calledOnMount?: boolean,
  ) => {
    const { variables } = this.state;

    const newVars = cloneDeep(variables);

    if (updatedElems.length === 0) return;

    updatedElems.forEach((elem) => {
      /**
       *  If the variable is set onMount (i.e. came from URL or embed variables)
       *  we don't want to override it
       */
      if (calledOnMount && newVars[elem.name]) return;

      const elemWasCleared = !calledOnMount && !newVars[elem.name];
      if (changedElementNamesSet.has(elem.name) || elemWasCleared) return;

      const config = elem.config as SelectElemConfig;
      const datasetId = getSelectFilterDatasetId(config);

      if (!datasetId) return;
      const queryValueColName = config.valuesConfig.queryValueColumn?.name || '';

      /**
       * If the current value of the element is still contained in the new dataset, we don't
       * need to reset it
       */
      const datasetRows = datasetRowsById[datasetId];

      if (elem.element_type === DASHBOARD_ELEMENT_TYPES.MULTISELECT) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const varValues = newVars[elem.name] as any[];
        newVars[elem.name] = varValues.filter((value: string | number) => {
          return isValueInTableRows(datasetRows || [], queryValueColName, value);
        });
      } else if (isValueInTableRows(datasetRows || [], queryValueColName, newVars[elem.name])) {
        return;
      } else {
        // if the value was not in the new dataset rows, mark it as undefined
        newVars[elem.name] = undefined;
      }
    });

    this.setState({ variables: newVars });
    this.props.onVariablesChange?.(newVars);
  };

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.isVisible !== this.props.isVisible &&
      this.props.refreshMinutes &&
      this.props.refreshMinutes >= MINIMUM_MINUTES_FOR_REFRESH
    ) {
      if (this.props.isVisible) {
        // if the tab is refocused on, re-set the refresh interval
        this.setState({
          intervalId: setInterval(
            this.fetchDashboardDataWrapper.bind(this),
            // convert minutes to milliseconds
            this.props.refreshMinutes * 60 * 1000,
          ),
        });
      } else {
        // stop fetching dashboard data if tab is closed to prevent
        // browser performance issues
        if (this.state.intervalId) clearInterval(this.state.intervalId);
      }
    }

    const userGroupChanged = prevProps.userGroup?.id !== this.props.userGroup?.id;
    if (userGroupChanged || prevProps.resourceVersionNumber !== this.props.resourceVersionNumber) {
      if (userGroupChanged && this.props.userGroup) {
        const variables = cloneDeep(this.state.variables);
        Object.keys(variables).forEach((key) => {
          if (key.startsWith('user_group.')) delete variables[key];
        });
        const newVariables = { ...variables, ...getCustomerVariables(this.props.userGroup) };
        this.setState({ variables: newVariables });
        this.props.onVariablesChange?.(newVariables);
      }

      this.initializeDashboardData(this.props.dashboardElements);
      return;
    }

    if (
      this.props.variablesDefaultValues &&
      !isEqual(prevProps.variablesDefaultValues, this.props.variablesDefaultValues)
    ) {
      const newVariables = {
        ...this.state.variables,
        ...this.props.variablesDefaultValues,
      };
      this.setState({ variables: newVariables });
      this.props.onVariablesChange?.(newVariables);
    }

    const newDataPanels = dataPanelsAdded(prevProps.dataPanels, this.props.dataPanels);

    const requests: JobDefinition[] = [];

    if (newDataPanels.length > 0) {
      if (this.props.shouldUseJobQueue)
        newDataPanels.forEach((dataPanel) =>
          requests.push(...this.fetchDataPanelDataAsync(dataPanel)),
        );
      else newDataPanels.forEach(this.fetchDataPanelDataSync);
    }

    const newDatasetIds = datasetsChanged(
      prevProps.dashboardElements,
      this.props.dashboardElements,
    );

    if (this.props.shouldUseJobQueue) {
      newDatasetIds.forEach((datasetId) => requests.push(this.fetchDatasetPreviewAsync(datasetId)));
    } else newDatasetIds.forEach((datasetId) => this.fetchDatasetPreviewSync(datasetId));

    this.bulkEnqueueJobs(requests);

    const newElemIds = dashboardElementsAdded(
      prevProps.dashboardElements,
      this.props.dashboardElements,
    );
    if (newElemIds.length > 0) {
      const elemsById = keyBy(this.props.dashboardElements, 'id');
      const defaultElemValues: DashboardVariableMap = {};
      newElemIds.forEach((elemId) => {
        const newElem = elemsById[elemId];
        const defaultValueForElem = getDefaultValueForNewElem(newElem);
        if (defaultValueForElem) {
          defaultElemValues[newElem.name] = defaultValueForElem;
        }
      });

      const newVariables = {
        ...this.state.variables,
        ...defaultElemValues,
      };
      this.setState({ variables: newVariables });
      this.props.onVariablesChange?.(newVariables);
    }

    if (prevProps.width !== this.props.width) {
      // Trigger resize event for react-grid-layout if container size changes
      window.dispatchEvent(new Event('resize'));
    }

    if (this.state.dashboardLoaded) return;
    if (
      !this.props.dataPanels.some(
        (dataPanel) =>
          dataPanel._loading === true ||
          dataPanel._loading === undefined ||
          !!dataPanel._outstandingSecondaryDataRequests,
      )
    ) {
      this.setState({ dashboardLoaded: true });
      datadogRum.addTiming('dashboard_loaded');
    }
  }

  prepareDataPanelForFetchWrapper = (dp: DataPanel, isSecondaryDataRequest?: boolean) => {
    return removeUnderscoreFields(
      prepareDataPanelForFetch(
        this.state.variables,
        dp,
        this.props.datasets,
        this.props.pageType !== PAGE_TYPE.EXPLO_APP,
        this.props.dashboardElements,
        isSecondaryDataRequest,
      ),
    );
  };

  attachDatasetToPostData = (
    dataPanel: DataPanel,
  ): { datasetId: string; dataset_id: string } | { dataset: RequestDataset } => {
    const datasetId = exploResourceUtils.getDataPanelDatasetId(dataPanel);
    if (this.props.pageType !== PAGE_TYPE.EXPLO_APP) return { datasetId, dataset_id: datasetId };
    const dataset = this.props.datasets[datasetId] ?? {};

    return { dataset: { query: dataset.query, parent_schema_id: dataset.parent_schema_id } };
  };

  // Ideally we move all the data panel template calls to this too.
  // No need to pass in all dataset to backend, especially cause
  // we aren't stripping out underscore fields before sending
  attachDatasetToPostDataV2 = (datasetId: string) => {
    const { pageType, datasets } = this.props;
    if (pageType !== PAGE_TYPE.EXPLO_APP) {
      return { dataset_id: datasetId };
    }
    const dataset = datasets[datasetId];
    return {
      dataset_id: dataset.id,
      query: dataset.query,
      parent_schema_id: dataset.parent_schema_id,
    };
  };

  onAdHocOperationInstructionsUpdated = (
    dataPanelId: string,
    adHocOperationInstructions: AdHocOperationInstructions,
    skipRowCount?: boolean,
  ) => {
    const { dataPanels, updateAdHocOperationInstructions, shouldUseJobQueue } = this.props;
    const dataPanel = dataPanels.find((dp) => dp.id === dataPanelId);
    if (!dataPanel) return;

    updateAdHocOperationInstructions({ dataPanelId, adHocOperationInstructions });

    if (shouldUseJobQueue) {
      const requests = [
        this.fetchDataPanelTemplateAsync(dataPanel, {
          id: dataPanel.id,
          page_number: adHocOperationInstructions.currentPage,
          sort_info: adHocOperationInstructions.sortInfo,
          filter_info: adHocOperationInstructions.filterInfo,
          ...this.attachDatasetToPostData(dataPanel),
        }),
      ];

      if (!skipRowCount)
        requests.push(
          this.fetchDataPanelRowCountAsync(dataPanel, {
            id: dataPanel.id,
            filter_info: adHocOperationInstructions.filterInfo,
            ...this.attachDatasetToPostData(dataPanel),
          }),
        );

      this.bulkEnqueueJobs(requests);
    } else {
      this.fetchDataPanelTemplateSync(dataPanel, {
        page_number: adHocOperationInstructions.currentPage,
        sort_info: adHocOperationInstructions.sortInfo,
        filter_info: adHocOperationInstructions.filterInfo,
      });

      !skipRowCount &&
        this.fetchDataPanelRowCountSync(dataPanel, {
          filter_info: adHocOperationInstructions.filterInfo,
          ...this.attachDatasetToPostData(dataPanel),
        });
    }
  };

  getViewableSchemaForPdf = (
    dataPanel: DataPanel,
    userTransformedSchema: UserTransformedSchema | undefined,
  ): UserTransformedSchema => {
    const operationType = dataPanel.visualize_op.operation_type;

    const shouldUseTransformedSchema =
      (operationType === OPERATION_TYPES.VISUALIZE_TABLE &&
        dataPanel.visualize_op.instructions.VISUALIZE_TABLE.isSchemaCustomizationEnabled) ||
      operationType === OPERATION_TYPES.VISUALIZE_REPORT_BUILDER;

    if (userTransformedSchema && shouldUseTransformedSchema) return userTransformedSchema;

    const changeSchemaList = dataPanel.visualize_op.instructions.VISUALIZE_TABLE.changeSchemaList;
    const changeSchemaByColName = keyBy(changeSchemaList, 'col');

    const schema =
      dataPanel._schema?.map((col) => ({
        ...col,
        isVisible: changeSchemaByColName[col.name]?.keepCol ?? true,
      })) ?? [];

    return sortSchemaByOrderedColumnNames(
      schema,
      dataPanel.visualize_op.instructions.VISUALIZE_TABLE.orderedColumnNames,
    );
  };

  onDataPanelLinkUpdate = ({ detail }: CustomEvent<DataPanelLinkUpdateEvent>) => {
    const { elementName, dataPanelIds } = detail;

    const hasVar = Object.keys(this.state.variables).find(
      (v) => v === elementName || v.startsWith(`${elementName}.`),
    );
    if (!hasVar) return;

    const dpIdsSet = new Set(dataPanelIds);
    const dps = this.props.dataPanels.filter((dp) => dpIdsSet.has(dp.id));
    if (dps.length === 0) return;

    if (this.props.shouldUseJobQueue) {
      this.bulkEnqueueJobs(dps.flatMap(this.fetchDataPanelDataAsync));
    } else {
      dps.forEach(this.fetchDataPanelDataSync);
    }
  };

  onDataPanelUpdate = ({ detail }: CustomEvent<DataPanelUpdateEvent>) => {
    const dp = this.props.dataPanels.find((dp) => dp.id === detail.dataPanelId);
    if (!dp) return;

    if (this.props.shouldUseJobQueue) {
      let requests: JobDefinition[] | undefined;
      if (detail.shouldRecompute) requests = this.fetchDataPanelDataAsync(dp);
      else if (detail.shouldRecomputeSecondaryData) {
        const secondaryInstructions = getAsynchronousSecondaryDataInstructions(
          dp,
          this.props.datasets[exploResourceUtils.getDataPanelDatasetId(dp)],
        );
        requests = this.fetchDataPanelTemplateSecondaryDataAsync(dp, secondaryInstructions);
      }

      this.bulkEnqueueJobs(requests);
    } else {
      if (detail.shouldRecompute) this.fetchDataPanelDataSync(dp);
      else if (detail.shouldRecomputeSecondaryData) {
        this.fetchDataPanelTemplateSecondaryDataSync(dp);
      }
    }
  };

  onVarRename = (e: CustomEvent<{ renames: [string, string][] }>) => {
    this.setState((currentState) => {
      const newVariables = produce(currentState.variables, (variables) => {
        e.detail.renames.forEach(([oldName, newName]) => {
          if (variables[oldName]) {
            variables[newName] = variables[oldName];
            delete variables[oldName];
          }
        });
      });
      this.props.onVariablesChange?.(newVariables);
      return { variables: newVariables };
    });
  };

  onVarDelete = (e: CustomEvent<DeleteDashboardVariablesEvent>) => {
    const newVariables = produce(this.state.variables, (draft) => {
      e.detail.names.forEach((name) => {
        if (name in draft) delete draft[name];
      });
    });
    this.props.onVariablesChange?.(newVariables);
    this.setState({ variables: newVariables });
  };

  onUpdateVariableValueEvent = (e: CustomEvent<UpdateVariableEvent>) => {
    const { varName, newValue, options } = e.detail;

    if (this.state.variables[varName] === newValue) return;

    const newVariables = produce(this.state.variables, (draft) => {
      draft[varName] = newValue;
      extraVarUtils.applyExtraVariables(draft, varName, options);
    });
    this.props.onVariablesChange?.(newVariables);
    this.setState({ variables: newVariables }, () => this.refreshDashboardData([varName]));
  };

  onTimezoneUpdate = () => this.initializeDashboardData(this.props.dashboardElements);

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
    // @ts-ignore
    window.addEventListener(DATA_PANEL_UPDATE_EVENT, this.onDataPanelUpdate);
    // @ts-ignore
    window.addEventListener('renameDashboardVariables', this.onVarRename);
    // @ts-ignore
    window.addEventListener(DELETE_DASHBOARD_VARIABLES_EVENT, this.onVarDelete);
    // @ts-ignore
    window.addEventListener(UPDATE_VARIABLE_VALUE_EVENT, this.onUpdateVariableValueEvent);
    window.addEventListener('updateTimezone', this.onTimezoneUpdate);
    // @ts-ignore
    window.addEventListener(DATA_PANEL_LINK_UPDATE, this.onDataPanelLinkUpdate);
  }

  componentWillUnmount() {
    const { intervalId } = this.state;
    document.removeEventListener('keydown', this.handleKeyDown);

    if (intervalId) clearInterval(intervalId);

    // @ts-ignore
    window.removeEventListener(DATA_PANEL_UPDATE_EVENT, this.onDataPanelUpdate);
    // @ts-ignore
    window.removeEventListener('renameDashboardVariables', this.onVarRename);
    // @ts-ignore
    window.removeEventListener(DELETE_DASHBOARD_VARIABLES_EVENT, this.onVarDelete);
    // @ts-ignore
    window.removeEventListener(UPDATE_VARIABLE_VALUE_EVENT, this.onUpdateVariableValueEvent);
    window.removeEventListener('updateTimezone', this.onTimezoneUpdate);
    // @ts-ignore
    window.removeEventListener(DATA_PANEL_LINK_UPDATE, this.onDataPanelLinkUpdate);
  }

  handleKeyDown = (event: KeyboardEvent) => {
    const { onCloseConfigClicked } = this.props;
    if (event.key !== 'Escape') return;

    onCloseConfigClicked?.();
  };

  render() {
    const {
      classes,
      onCloseConfigClicked,
      datasets,
      dashboardElements,
      exploResource,
      dataPanels,
      dashboardLayout,
      disableInputs,
      analyticsEventTracker,
      draggingElementType,
      editableDashboard,
      fetchShareData,
      isViewOnly,
      hoverElementId,
      onDashboardItemSelect,
      selectedDashboardItemId,
      supportEmail,
      updateDashboardLayout,
      updateElementConfig,
      viewMode,
      pageLayoutConfig,
      pageType,
      customerToken,
      selectedLayoutConfig,
      shouldUseJobQueue,
      isCanvas,
      isArchitectCustomerDashboard,
      onDashboardLayoutStateSelected,
      updateDrilldownDataPanel,
      userGroup,
      dashboardLinks,
      timezone,
    } = this.props;

    const { variables, blockedVariables, awaitedJobs } = this.state;

    const disableInputsForDashboardLoad =
      exploResourceUtils.getDisableFiltersWhileLoading(exploResource) &&
      dataPanels.some(
        (dataPanel) =>
          !isEmpty(dataPanel) &&
          (dataPanel._loading === true ||
            dataPanel._loading === undefined ||
            !!dataPanel._outstandingSecondaryDataRequests),
      );

    return (
      <DashboardLayoutContext.Provider
        value={{
          dashboardLayoutTagId: DASHBOARD_LAYOUT_ID_FOR_PORTALS,
        }}>
        <Poller
          awaitedJobs={awaitedJobs}
          customerToken={customerToken}
          updateJobResult={(finishedJobIds, onComplete) => {
            if (finishedJobIds.length > 0)
              this.setState((currentState) => {
                const newAwaitedJobs = produce(currentState.awaitedJobs, (draft) =>
                  finishedJobIds.forEach((jobId) => delete draft[jobId]),
                );
                return { awaitedJobs: newAwaitedJobs };
              });

            onComplete();
          }}
        />
        <div
          className={cx(classes.root, {
            [classes.pdfEditor]: viewMode === VIEW_MODE.PDF && !isViewOnly,
            [classes.emailView]: viewMode === VIEW_MODE.EMAIL,
            [classes.mobileEditor]: viewMode === VIEW_MODE.MOBILE && !isViewOnly,
            'explo-dashboard-loaded': this.state.dashboardLoaded,
          })}
          id={DASHBOARD_LAYOUT_ID_FOR_PORTALS}
          onClick={() => onCloseConfigClicked?.()}>
          {viewMode === VIEW_MODE.PDF || viewMode === VIEW_MODE.EMAIL ? null : (
            <DashboardStickyHeader
              applyFilters={this.applyFilters}
              blockedVariables={blockedVariables}
              config={pageLayoutConfig?.stickyHeader}
              dashboardElements={dashboardElements}
              dashboardLayout={dashboardLayout}
              dataPanels={dataPanels}
              datasets={datasets}
              disableInputs={disableInputs}
              disableInputsForDashboardLoad={disableInputsForDashboardLoad}
              downloadDashboardImage={this.getDownloadDashboardScreenshotWrapper('image')}
              downloadDashboardPdf={this.getDownloadDashboardScreenshotWrapper('pdf')}
              editableDashboard={editableDashboard ?? false}
              fetchDataPanelData={
                shouldUseJobQueue ? this.fetchDataPanelDataAsync : this.fetchDataPanelDataSync
              }
              fetchShareData={fetchShareData}
              globalStyleConfig={this.context.globalStyleConfig}
              hoverElementId={hoverElementId}
              isHeaderConfigSelected={selectedLayoutConfig === DASHBOARD_LAYOUT_CONFIG.HEADER}
              isViewOnly={isViewOnly}
              onAdHocOperationInstructionsUpdated={this.onAdHocOperationInstructionsUpdated}
              onDashboardItemSelect={onDashboardItemSelect}
              onDashboardLayoutStateSelected={onDashboardLayoutStateSelected}
              onDownloadDataPanelSpreadsheet={this.downloadDataPanelSpreadsheetWrapper}
              onDownloadPanelPdf={this.downloadDataPanelScreenshotWrapper}
              onDrop={this.onDrop}
              pageType={pageType}
              selectedDashboardItemId={selectedDashboardItemId}
              setVariable={this.setVariable}
              shouldUseJobQueue={shouldUseJobQueue}
              supportEmail={supportEmail}
              timezone={timezone}
              userGroup={userGroup}
              variables={variables}
              viewMode={viewMode}
            />
          )}
          <ElementGridLayout
            analyticsEventTracker={analyticsEventTracker}
            applyFilters={this.applyFilters}
            blockedVariables={blockedVariables}
            dashboardElements={dashboardElements}
            dashboardLayout={dashboardLayout}
            dashboardLinks={dashboardLinks}
            dataPanels={dataPanels}
            datasets={datasets}
            disableInputs={disableInputs}
            disableInputsForDashboardLoad={disableInputsForDashboardLoad}
            downloadDashboardImage={this.getDownloadDashboardScreenshotWrapper('image')}
            downloadDashboardPdf={this.getDownloadDashboardScreenshotWrapper('pdf')}
            draggingElementType={draggingElementType}
            editableDashboard={editableDashboard ?? false}
            fetchDataPanelData={
              shouldUseJobQueue ? this.fetchDataPanelDataAsync : this.fetchDataPanelDataSync
            }
            fetchShareData={fetchShareData}
            globalStyleConfig={this.context.globalStyleConfig}
            hoverElementId={hoverElementId}
            isArchitectCustomerDashboard={isArchitectCustomerDashboard}
            isCanvas={isCanvas}
            isViewOnly={isViewOnly}
            onAdHocOperationInstructionsUpdated={this.onAdHocOperationInstructionsUpdated}
            onDashboardItemSelect={onDashboardItemSelect}
            onDownloadDataPanelSpreadsheet={this.downloadDataPanelSpreadsheetWrapper}
            onDownloadPanelPdf={this.downloadDataPanelScreenshotWrapper}
            onDrop={this.onDrop}
            openDrilldownModal={updateDrilldownDataPanel ? this.openDrilldownModal : undefined}
            pageType={pageType}
            selectedDashboardItemId={selectedDashboardItemId}
            setVariable={this.setVariable}
            shouldUseJobQueue={shouldUseJobQueue}
            supportEmail={supportEmail}
            timezone={timezone}
            updateDashboardLayout={updateDashboardLayout}
            updateElementConfig={updateElementConfig}
            userGroup={userGroup}
            variables={variables}
            viewMode={viewMode}
          />
          {this.renderExploBranding()}
          {this.renderDrilldownModal()}
        </div>
      </DashboardLayoutContext.Provider>
    );
  }

  applyFilters = (elementIds: string[]) => {
    const { dashboardElements } = this.props;
    const elementNamesToRefresh: string[] = [];
    this.setState(
      (currentState) => {
        const elementsById = keyBy(dashboardElements, 'id');

        const newState = produce(currentState, (draft) => {
          elementIds.forEach((elemId) => {
            const element = elementsById[elemId];
            if (elemId in draft.blockedVariables && element) {
              elementNamesToRefresh.push(element.name);
              const blockedValue = draft.blockedVariables[elemId];
              draft.variables[element.name] = blockedValue;
              delete draft.blockedVariables[elemId];
              extraVarUtils
                .getListOfExtraVarsForElement(element.name, element.element_type)
                .forEach((variable) => {
                  if (!(variable in draft.blockedVariables)) return;

                  draft.variables[variable] = draft.blockedVariables[variable];
                  delete draft.blockedVariables[variable];
                });

              if (blockedValue === undefined) {
                resetDependedElements(elemId, dashboardElements, draft.variables);
              }
            }
          });
        });

        this.props.onVariablesChange?.(newState.variables);

        return newState;
      },
      () => this.refreshDashboardData(elementNamesToRefresh),
    );
  };

  setVariable = (
    varName: string,
    value: DashboardVariable,
    elementId?: string,
    options?: extraVarUtils.VariableSelectOptions,
  ) => {
    const { dashboardElements, editableDashboard, updateUrlParams } = this.props;
    const blockedElementId = elementBlockedOnApplyButton(dashboardElements, elementId);

    this.setState(
      (currentState) => {
        const newState = produce(currentState, (draft) => {
          if (blockedElementId) {
            // produce doesn't actually set elementValue as undefined if no value was set for it
            // already and we need the distinction for setting vars as undefined after applied
            if (value === undefined && !(blockedElementId in draft.blockedVariables)) {
              draft.blockedVariables[blockedElementId] = 'temp';
            }

            // remove the blocked element if this is a revert
            if (draft.variables[varName] === value) {
              delete draft.blockedVariables[blockedElementId];
              extraVarUtils.clearExtraVariables(draft.blockedVariables, varName);
            }
            // otherwise set to the new value
            else {
              draft.blockedVariables[blockedElementId] = value;
              extraVarUtils.applyExtraVariables(draft.blockedVariables, varName, options);
            }
            if (value === undefined) {
              resetDependentBlockedElements(
                blockedElementId,
                dashboardElements,
                draft.blockedVariables,
              );
            }
          } else {
            draft.variables[varName] = value;
            extraVarUtils.applyExtraVariables(draft.variables, varName, options);

            if (elementId && value === undefined) {
              resetDependedElements(elementId, dashboardElements, draft.variables);
            }
          }
        });

        if (!blockedElementId) this.props.onVariablesChange?.(newState.variables);

        return newState;
      },
      () => {
        if (!blockedElementId) this.refreshDashboardData([varName]);

        !editableDashboard &&
          updateUrlParams &&
          window?.history?.replaceState &&
          window.history.replaceState(
            null,
            'Explo',
            getUrlParamStringFromDashVars(this.state.variables),
          );
      },
    );
  };

  renderExploBranding = () => {
    const { classes, showExploBranding } = this.props;
    if (showExploBranding) {
      return (
        <a
          className={classes.exploBrand}
          href="https://www.explo.co/"
          rel="noopener noreferrer"
          target="_blank">
          {EXPLO_SVG_LOGO(18)}
          <span style={{ marginLeft: 8 }}>Powered by Explo</span>
        </a>
      );
    }
    return null;
  };

  renderDrilldownModal = () => {
    const { analyticsEventTracker, dataPanels, datasets, globalStylesClassName, pageType } =
      this.props;
    const { drilldownModalConfig } = this.state;

    if (!drilldownModalConfig?.isOpen) return;

    const drilldownDPT = dataPanels.find((dp) => dp.id === DRILLDOWN_DATA_PANEL_ID);

    if (!drilldownDPT) return;

    return (
      <DrilldownModal
        modalOpen
        analyticsEventTracker={analyticsEventTracker}
        closeModal={() => this.setState({ drilldownModalConfig: undefined })}
        datasets={datasets}
        drilldownDPT={cloneDeep(drilldownDPT)}
        globalStylesClassName={globalStylesClassName}
        onAdHocOperationInstructionsUpdated={this.onAdHocOperationInstructionsUpdated}
        onDownloadDataPanelPdf={this.downloadDataPanelScreenshotWrapper}
        onDownloadDataPanelSpreadsheet={this.downloadDataPanelSpreadsheetWrapper}
        pageType={pageType}
        portalId={DASHBOARD_LAYOUT_ID_FOR_PORTALS}
      />
    );
  };

  openDrilldownModal = (
    dataPanelTemplateId: string,
    categoryColumn?: CategoryChartColumnInfo,
    category?: string | number,
    subCategoryColumn?: CategoryChartColumnInfo,
    subCategory?: string | number,
    excludedCategories?: (string | number)[],
  ) => {
    const { dataPanels, updateDrilldownDataPanel } = this.props;
    const dataPanel = dataPanels.find((dp) => dp.id === dataPanelTemplateId);

    if (!dataPanel || !updateDrilldownDataPanel) return;

    this.fetchDrilldownData(
      dataPanel,
      categoryColumn,
      category,
      subCategoryColumn,
      subCategory,
      excludedCategories,
    );

    this.setState({
      drilldownModalConfig: {
        isOpen: true,
        dataPanelTemplateId,
        categoryColumn,
        selectedCategory: category,
        subCategoryColumn,
        selectedSubCategory: subCategory,
        excludedCategories,
      },
    });
  };

  onDrop = (layout: Layout[], layoutItem: Layout, event: Event, containerId?: string) => {
    const { onCreateDataPanel, onCreateNewDashboardElement, exploResource } = this.props;
    if (!onCreateDataPanel || !onCreateNewDashboardElement) return;
    const elemType = elemIdFromDropId(layoutItem.i);

    if (elemType.indexOf('data-panel-') >= 0) {
      const operationType = elemType.split('data-panel-')[1] as OPERATION_TYPES;
      onCreateDataPanel(layout, operationType, containerId);
      return;
    }

    switch (elemType) {
      case DASHBOARD_ELEMENT_TYPES.TEXT:
      case DASHBOARD_ELEMENT_TYPES.SPACER:
      case DASHBOARD_ELEMENT_TYPES.EXPORT:
      case DASHBOARD_ELEMENT_TYPES.IMAGE:
      case DASHBOARD_ELEMENT_TYPES.DROPDOWN:
      case DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN:
      case DASHBOARD_ELEMENT_TYPES.MULTISELECT:
      case DASHBOARD_ELEMENT_TYPES.DATEPICKER:
      case DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER:
      case DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH:
      case DASHBOARD_ELEMENT_TYPES.TOGGLE:
      case DASHBOARD_ELEMENT_TYPES.SWITCH:
      case DASHBOARD_ELEMENT_TYPES.APPLY_FILTER_BUTTON:
      case DASHBOARD_ELEMENT_TYPES.TEXT_INPUT:
      case DASHBOARD_ELEMENT_TYPES.IFRAME:
      case DASHBOARD_ELEMENT_TYPES.CONTAINER: {
        const id = `dash${exploResource.id}-${uuidv4()}`;
        return onCreateNewDashboardElement(id, elemType, layout, containerId);
      }
      default:
        return;
    }
  };

  setDataPanelsLoadingForElemName = (changedElementNamesSet: Set<string>) => {
    const { datasets, dataPanels, dashboardElements, setDpLoading } = this.props;
    const { variables } = this.state;

    if (changedElementNamesSet.size === 0) return [];

    const loadingDpIds = map(
      variableUtils.getDataPanelsDependentOnVariable(
        dataPanels,
        datasets,
        dashboardElements,
        changedElementNamesSet,
        variables,
      ),
      'id',
    );

    setDpLoading({ ids: loadingDpIds, loading: true });

    return loadingDpIds;
  };

  refreshDashboardData = (changedElementsNames: string[]) => {
    const { dashboardElements, datasets } = this.props;
    const loadingDpIds = new Set<string>();
    const changedElementNamesSet = new Set<string>(changedElementsNames);

    let elemsWithDefaults = getDashboardElemsWithDefaultQueryValues(
      dashboardElements.filter((e) => !changedElementNamesSet.has(e.name)), // multiple elements with same name won't work here
    );

    elemsWithDefaults = variableUtils.getElemsReliantOnVariableChange(
      elemsWithDefaults,
      datasets,
      changedElementNamesSet,
    );

    this.setDataPanelsLoadingForElemName(changedElementNamesSet).forEach(
      loadingDpIds.add,
      loadingDpIds,
    );

    this.fetchDropdownQueryDefaults(
      elemsWithDefaults,
      changedElementNamesSet,
      false,
      (updatedDatasetIds, changedVariableNames) => {
        this.setDataPanelsLoadingForElemName(changedVariableNames || new Set()).forEach(
          loadingDpIds.add,
          loadingDpIds,
        );

        this.fetchDropdownUpdatesFromDefaults(
          elemsWithDefaults,
          changedElementNamesSet,
          (datasetsUpdated, updatedElemNames) => {
            this.setDataPanelsLoadingForElemName(updatedElemNames || new Set()).forEach(
              loadingDpIds.add,
              loadingDpIds,
            );

            this.fetchDashboardDataWrapper(datasetsUpdated, changedElementNamesSet, loadingDpIds);
          },
          false,
        );
      },
    );
  };

  fetchDashboardDataWrapper = (
    updatedDatasetIds?: string[],
    changedElementNamesSet?: Set<string>,
    dataPanelsToUpdate?: Set<string>,
  ) => {
    const { dataPanels, datasets, shouldUseJobQueue, dashboardElements } = this.props;
    const { variables } = this.state;
    let dpsToUpdate: DataPanel[];
    changedElementNamesSet = changedElementNamesSet || new Set();

    if (dataPanelsToUpdate) {
      dpsToUpdate = dataPanels.filter((dp) => dataPanelsToUpdate.has(dp.id));
    } else if (changedElementNamesSet.size === 0) {
      dpsToUpdate = dataPanels;
    } else {
      dpsToUpdate = variableUtils.getDataPanelsDependentOnVariable(
        dataPanels,
        datasets,
        dashboardElements,
        changedElementNamesSet,
        variables,
      );
    }

    // Get the dataset previews of the dash datasets needed for the dropdowns that need them
    const uniqueDatasetIds = getDatasetIdsForElems(dashboardElements, datasets, true);
    dpsToUpdate.forEach((dataPanel) => {
      variableUtils
        .getDatasetIdsForDataPanel(dataPanel, datasets)
        .forEach((datasetId) => uniqueDatasetIds.add(datasetId));
    });

    const datasetIdsToUpdate = without(
      variableUtils.getDatasetIdsDependentOnVariable(
        Array.from(uniqueDatasetIds),
        datasets,
        changedElementNamesSet,
      ),
      ...(updatedDatasetIds || []),
    );

    if (shouldUseJobQueue) {
      const requests = dpsToUpdate.flatMap((dp) => this.fetchDataPanelDataAsync(dp));
      datasetIdsToUpdate.forEach((id) => requests.push(this.fetchDatasetPreviewAsync(id)));

      this.bulkEnqueueJobs(requests);
    } else {
      dpsToUpdate.forEach(this.fetchDataPanelDataSync);
      datasetIdsToUpdate.forEach((id) => this.fetchDatasetPreviewSync(id));
    }
  };

  fetchDrilldownData = (
    dataPanel: DataPanel,
    categoryColumn?: CategoryChartColumnInfo,
    category?: string | number,
    subCategoryColumn?: CategoryChartColumnInfo,
    subCategory?: string | number,
    excludedCategories?: (string | number)[],
  ) => {
    const { shouldUseJobQueue, updateDrilldownDataPanel, datasets, dashboardElements } = this.props;

    if (!updateDrilldownDataPanel) return;

    const drilldownDpt = removeUnderscoreFields(cloneDeep(dataPanel));
    drilldownDpt.visualize_op.operation_type = OPERATION_TYPES.VISUALIZE_TABLE;
    if (categoryColumn !== undefined) {
      drilldownDpt._adHocOperationInstructions = {
        filterInfo: {
          filterClauses:
            constructFilterFromDrilldownColumn(
              categoryColumn,
              category,
              subCategoryColumn,
              subCategory,
              excludedCategories,
            ) ?? [],
          matchOnAll: true,
        },
      };
    }
    attachLinkFiltersToDp(drilldownDpt, datasets, dashboardElements, this.state.variables);

    if (!drilldownDpt.visualize_op.generalFormatOptions)
      drilldownDpt.visualize_op.generalFormatOptions = {};
    if (!drilldownDpt.visualize_op.generalFormatOptions.export)
      drilldownDpt.visualize_op.generalFormatOptions.export = {};
    drilldownDpt.visualize_op.generalFormatOptions.export.disablePdfDownload = true;

    updateDrilldownDataPanel({ dataPanel: drilldownDpt });

    if (shouldUseJobQueue) {
      const requests = this.fetchDataPanelDataAsync(drilldownDpt);
      this.bulkEnqueueJobs(requests);
    } else {
      this.fetchDataPanelDataSync(drilldownDpt);
    }
  };

  fetchDataPanelDataSync = (dataPanel: DataPanel) => {
    const { setDpLoading, datasets } = this.props;
    const { variables } = this.state;

    if (
      !isDataPanelReadyToCompute(dataPanel, datasets) ||
      !areRequiredUserInputsSet(variables, dataPanel)
    ) {
      return setDpLoading({ ids: [dataPanel.id], loading: false });
    }

    if (dataPanelRequiresPrimaryData(dataPanel.visualize_op)) {
      this.fetchDataPanelTemplateSync(
        dataPanel,
        { filter_info: getFilterInfo(dataPanel), sort_info: getSortInfo(dataPanel) },
        (response) => {
          this.fetchDataPanelTemplateSecondaryDataSync(
            { ...dataPanel, ...response.data_panel_template },
            true,
          );
        },
      );
    }

    this.fetchDataPanelTemplateSecondaryDataSync(dataPanel);

    if (VISUALIZE_TABLE_OPERATIONS.includes(dataPanel.visualize_op.operation_type)) {
      this.fetchDataPanelRowCountSync(dataPanel, { filter_info: getFilterInfo(dataPanel) });
    }
  };

  fetchDataPanelDataAsync = (dataPanel: DataPanel) => {
    const { setDpLoading, datasets } = this.props;
    const { variables } = this.state;

    if (
      !isDataPanelReadyToCompute(dataPanel, datasets) ||
      !areRequiredUserInputsSet(variables, dataPanel)
    ) {
      setDpLoading({ ids: [dataPanel.id], loading: false });
      return [];
    }

    const requests = [] as JobDefinition[];

    if (dataPanelRequiresPrimaryData(dataPanel.visualize_op)) {
      requests.push(
        this.fetchDataPanelTemplateAsync(
          // Since the dataPanelTemplate is stored in the redux store if a data panel template has been loaded before,
          // we need to deep copy it before any modifications, otherwise underscore fields in the redux store will be wiped too.
          removeUnderscoreFields(cloneDeep(dataPanel)),
          {
            id: dataPanel.id,
            filter_info: getFilterInfo(dataPanel),
            sort_info: getSortInfo(dataPanel),
            ...this.attachDatasetToPostData(dataPanel),
          },
          getSynchronousSecondaryDataInstructions(dataPanel),
        ),
      );
    }

    const secondaryInstructions = getAsynchronousSecondaryDataInstructions(
      dataPanel,
      datasets[exploResourceUtils.getDataPanelDatasetId(dataPanel)],
    );

    if (secondaryInstructions)
      requests.push(
        ...this.fetchDataPanelTemplateSecondaryDataAsync(dataPanel, secondaryInstructions),
      );

    if (VISUALIZE_TABLE_OPERATIONS.includes(dataPanel.visualize_op.operation_type)) {
      requests.push(
        this.fetchDataPanelRowCountAsync(dataPanel, {
          id: dataPanel.id,
          filter_info: getFilterInfo(dataPanel),
          ...this.attachDatasetToPostData(dataPanel),
        }),
      );
    }

    return requests;
  };

  fetchDataPanelTemplateSync = (
    dataPanel: DataPanel,
    passedPostData: Pick<FetchDataPanelBody, 'page_number' | 'sort_info' | 'filter_info'>,
    onSuccess?: ((data: FetchDataPanelData) => void) | undefined,
  ) => {
    const { fetchDataPanel, userGroup, isCanvas } = this.props;
    const { variables } = this.state;

    const postData = {
      ...passedPostData,
      id: dataPanel.id,
      config: this.prepareDataPanelForFetchWrapper(dataPanel),
      variables,
      customer_id: userGroup?.id,
      canvas_request: isCanvas,
      ...this.attachDatasetToPostData(dataPanel),
    };

    fetchDataPanel({ postData }, onSuccess);
  };

  fetchDataPanelTemplateAsync = (
    dataPanel: DataPanel,
    passedPostData: Pick<FetchDataPanelBody, 'id' | 'page_number' | 'sort_info' | 'filter_info'>,
    secondaryInstructions?: DataPanel[],
  ) => {
    const { userGroup, isCanvas } = this.props;
    const { variables } = this.state;

    return {
      job_type: ACTION.FETCH_DATA_PANEL_TEMPLATE,
      job_args: {
        ...passedPostData,
        config: this.prepareDataPanelForFetchWrapper(dataPanel),
        variables,
        customer_id: userGroup?.id,
        canvas_request: isCanvas,
        secondary_instructions: secondaryInstructions,
        ...this.attachDatasetToPostData(dataPanel),
      },
    } as JobDefinition;
  };

  fetchDataPanelTemplateSecondaryDataSync = (dataPanel: DataPanel, afterMainFetch?: boolean) => {
    const { datasets, userGroup, isCanvas, fetchSecondaryData } = this.props;
    const datasetId = exploResourceUtils.getDataPanelDatasetId(dataPanel);
    const dataset = datasets[datasetId];

    const secondaryInstructions = afterMainFetch
      ? getSynchronousSecondaryDataInstructions(dataPanel)
      : getAsynchronousSecondaryDataInstructions(dataPanel, dataset);

    secondaryInstructions.forEach((instructions) => {
      const postData = {
        config: this.prepareDataPanelForFetchWrapper(instructions, true),
        id: dataPanel.id,
        customer_id: userGroup?.id,
        variables: this.state.variables,
        canvas_request: isCanvas,
        ...this.attachDatasetToPostData(dataPanel),
        is_secondary_data_query: true,
      } as FetchSecondaryDataBody;

      fetchSecondaryData({ postData });
    });
  };

  fetchDataPanelTemplateSecondaryDataAsync = (
    dataPanel: DataPanel,
    secondaryInstructions: DataPanel[],
  ) => {
    const { userGroup, isCanvas } = this.props;

    return secondaryInstructions.map((instructions) => {
      return {
        job_type: ACTION.FETCH_SECONDARY_DATA,
        job_args: {
          config: this.prepareDataPanelForFetchWrapper(instructions, true),
          id: dataPanel.id,
          customer_id: userGroup?.id,
          variables: this.state.variables,
          canvas_request: isCanvas,
          ...this.attachDatasetToPostData(dataPanel),
          is_secondary_data_query: true,
        },
      };
    });
  };

  fetchDataPanelRowCountSync = (
    dataPanel: DataPanel,
    passedPostData?: Pick<FetchDataPanelRowCountBody, 'filter_info'>,
  ) => {
    const { fetchDataPanelRowCount, userGroup, isCanvas } = this.props;
    const { variables } = this.state;

    const postData = {
      ...passedPostData,
      id: dataPanel.id,
      config: this.prepareDataPanelForFetchWrapper(dataPanel),
      variables,
      customer_id: userGroup?.id,
      canvas_request: isCanvas,
      ...this.attachDatasetToPostData(dataPanel),
    };

    fetchDataPanelRowCount({ postData });
  };

  fetchDataPanelRowCountAsync = (
    dataPanel: DataPanel,
    passedPostData: Pick<FetchDataPanelRowCountBody, 'id' | 'filter_info'>,
  ) => {
    const { userGroup, isCanvas } = this.props;
    const { variables } = this.state;

    return {
      job_type: ACTION.FETCH_DATA_PANEL_ROW_COUNT,
      job_args: {
        ...passedPostData,
        config: this.prepareDataPanelForFetchWrapper(dataPanel),
        variables,
        customer_id: userGroup?.id,
        canvas_request: isCanvas,
        ...this.attachDatasetToPostData(dataPanel),
      },
    };
  };

  fetchDatasetPreviewSync = (
    datasetId: string,
    onSuccess?: (data: FetchDashboardDatasetPreviewData) => void,
    onError?: () => void,
  ) => {
    const { userGroup, fetchDatasetPreview } = this.props;
    const { variables } = this.state;

    fetchDatasetPreview(
      {
        postData: {
          variables,
          customer_id: userGroup?.id,
          query_limit: 5000,
          ...this.attachDatasetToPostDataV2(datasetId),
        },
      },
      onSuccess,
      onError,
    );
  };

  fetchDatasetPreviewAsync = (
    datasetId: string,
    onSuccess?: (data: FetchDashboardDatasetPreviewData) => void,
    onError?: () => void,
  ) => {
    const { userGroup } = this.props;
    const { variables } = this.state;

    const postData = {
      variables,
      customer_id: userGroup?.id,
      query_limit: 5000,
      ...this.attachDatasetToPostDataV2(datasetId),
      id: datasetId,
    };

    return {
      job_type: ACTION.FETCH_DASHBOARD_DATASET_PREVIEW,
      job_args: postData,
      onSuccess: onSuccess,
      onError: onError,
    };
  };

  downloadDataPanelSpreadsheetWrapper = (
    dataPanel: DataPanel,
    fileFormat: SpreadsheetType,
    email: string | undefined,
    userTransformedSchema?: UserTransformedSchema,
  ) => {
    const { variables } = this.state;
    const {
      analyticsEventTracker,
      downloadDataPanelSpreadsheet,
      userGroup,
      isCanvas,
      shouldUseJobQueue,
      datasets,
    } = this.props;

    const transformedDataPanel = getTransformedDataPanelForCsv(
      dataPanel,
      userTransformedSchema,
      datasets,
    );

    const config = removeUnderscoreFields(cloneDeep(transformedDataPanel));

    const postData: DownloadDataPanelSpreadsheetBody = {
      config,
      id: transformedDataPanel.id,
      variables,
      customer_id: userGroup?.id,
      sort_info: transformedDataPanel._adHocOperationInstructions?.sortInfo,
      filter_info: transformedDataPanel._adHocOperationInstructions?.filterInfo,
      canvas_request: isCanvas,
      file_format: fileFormat,
      email,
      ...this.attachDatasetToPostData(transformedDataPanel),
    };

    analyticsEventTracker?.(REPORTED_ANALYTIC_ACTION_TYPES.CSV_DOWNLOADED);

    if (shouldUseJobQueue && email === undefined) {
      this.bulkEnqueueJobs([{ job_type: ACTION.DOWNLOAD_DATA_PANEL_CSV, job_args: postData }]);
    } else downloadDataPanelSpreadsheet({ postData });
  };

  downloadDataPanelScreenshotWrapper = (
    dataPanel: DataPanel,
    adHocOperationInstructions: AdHocOperationInstructions,
    email: string | undefined,
    userTransformedSchema?: UserTransformedSchema,
    reportName?: string,
  ) => {
    const {
      downloadDataPanelPdf,
      analyticsEventTracker,
      resourceVersionNumber,
      userGroup,
      shouldUseJobQueue,
    } = this.props;
    if (!userGroup || !downloadDataPanelPdf) return;
    const { variables } = this.state;

    const viewableSchema = this.getViewableSchemaForPdf(dataPanel, userTransformedSchema);

    const downloadFileName =
      dataPanel.visualize_op.generalFormatOptions?.export?.downloadFileName ||
      dataPanel.provided_id;

    const postData = {
      user_group_id: userGroup.id,
      data_panel_template_id: dataPanel.id,
      download_file_name: downloadFileName,
      version_number: resourceVersionNumber,
      variable_query: getUrlParamStringFromDashVars({
        ...variables,
        reportName,
        userTransformedSchema: JSURL.stringify(viewableSchema),
        adHocOps: JSURL.stringify(adHocOperationInstructions),
      }),
      export_type: 'pdf',
      email,
    };

    analyticsEventTracker?.(REPORTED_ANALYTIC_ACTION_TYPES.DATA_PANEL_PDF_DOWNLOADED);

    if (shouldUseJobQueue && email === undefined) {
      this.bulkEnqueueJobs([
        { job_type: ACTION.DOWNLOAD_DATA_PANEL_TEMPLATE_PDF, job_args: postData },
      ]);
    } else downloadDataPanelPdf({ postData });
  };

  getDownloadDashboardScreenshotWrapper = (exportType: 'image' | 'pdf') => (email?: string) => {
    const {
      dashboardElements,
      exploResource,
      resourceVersionNumber,
      userGroup,
      shouldUseJobQueue,
      downloadDashboardImage,
      downloadDashboardPdf,
    } = this.props;
    const { variables } = this.state;
    if (!userGroup || !downloadDashboardPdf || !downloadDashboardImage) return;

    const downloadDashboardFileName = this.getDashboardDownloadFileName(dashboardElements);

    const postData = {
      download_file_name: downloadDashboardFileName || String(exploResource.id),
      version_number: resourceVersionNumber,
      variable_query: getUrlParamStringFromDashVars(variables),
      export_type: exportType,
      user_group_id: userGroup.id,
      email,
    };

    // If email is passed in, we know we want to enqueue email job in backend so frontend does not have to keep track
    if (shouldUseJobQueue && email === undefined) {
      this.bulkEnqueueJobs([
        {
          job_type:
            exportType === 'image' ? ACTION.FETCH_IMAGE_EXPORT_URL : ACTION.FETCH_PDF_EXPORT_URL,
          job_args: postData,
        },
      ]);
    } else
      exportType === 'image'
        ? downloadDashboardImage({ postData })
        : downloadDashboardPdf({ postData });
  };

  getDashboardDownloadFileName = (elements: DashboardElement[]) => {
    const exportElement = elements.find(
      (elem) => elem.element_type === DASHBOARD_ELEMENT_TYPES.EXPORT,
    );
    return exportElement
      ? (exportElement.config as ExportElemConfig).downloadDashboardFileName
      : undefined;
  };

  bulkEnqueueJobs = (jobs: JobDefinition[] | undefined) => {
    const { bulkEnqueueJobsWrapper } = this.props;

    if (!bulkEnqueueJobsWrapper || jobs === undefined || jobs.length === 0) return;

    const jobMap = Object.assign({}, ...jobs.map((job) => ({ [uuidv4()]: job })));

    bulkEnqueueJobsWrapper({ jobs: jobMap }, (jobs) => {
      this.setState((currentState) => {
        return {
          awaitedJobs: produce(currentState.awaitedJobs, (draft) => ({
            ...draft,
            ...jobs,
          })),
        };
      });
    });
  };
}

DashboardLayout.contextType = GlobalStylesContext;

const mapDispatchToProps = {
  setDpLoading,
  updateAdHocOperationInstructions,
  clearDashboardLayoutReducer,
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(DashboardLayout));
