import { createAction } from '@reduxjs/toolkit';
import { ACTION } from './types';

export const setLinkedElementHover = createAction<string | undefined>(
  ACTION.SET_LINKED_DASHBOARD_ELEMENT_HOVER,
);

export const setSelectedDrilldownColumn = createAction<{ name: string; type: string } | undefined>(
  ACTION.SET_SELECTED_DRILLDOWN_COLUMN,
);
