import { DragEvent } from 'react';
import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Icon } from '@blueprintjs/core';
import { IconName } from '@blueprintjs/icons';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imgSection: {
    flex: `0 0 60px`,
    width: 75,
    height: 75,
    borderRadius: 4,
    backgroundColor: theme.palette.ds.grey100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    overflow: 'hidden',

    transition: 'transform .25s ease-out, box-shadow .25s ease-out',

    '&:hover': {
      cursor: 'pointer',
      transform: 'translatez(0) scale(1.05)',
      boxShadow: '0px 0px 10px -6px rgba(0,0,0,0.75)',

      '& .infoTitle': {
        color: theme.palette.ds.blue,
      },
    },
  },
  elemName: {
    color: theme.palette.ds.grey900,
    fontSize: 12,
    textAlign: 'center',
  },
}));

type Props = {
  icon: IconName | JSX.Element;
  name: string;
  className?: string;
  onDragStart: () => void;
  onDragEnd: () => void;
};

const DroppableComponentItem = ({ className, name, icon, onDragStart, onDragEnd }: Props) => {
  const classes = useStyles();

  const handleOnDragStart = (e: DragEvent<HTMLDivElement>) => {
    // TODO: Figure out if a shadow dragging element could work
    // https://www.kryogenix.org/code/browser/custom-drag-image.html

    e.dataTransfer.setData('text/plain', '');

    onDragStart();
  };

  return (
    <div className={cx(classes.root, className)}>
      <div
        className="droppable-element"
        draggable={true}
        onDragEnd={onDragEnd}
        onDragStart={handleOnDragStart}>
        <div className={cx(classes.imgSection, 'imgSection')}>
          <Icon icon={icon} />
        </div>
      </div>
      <div className={classes.elemName}>{name}</div>
    </div>
  );
};

export default DroppableComponentItem;
