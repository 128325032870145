import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';
import DropdownSelect from 'shared/DropdownSelect';
import { Switch } from 'components/ds';

import { KPIValueFormat, OPERATION_TYPES, V2KPIChartInstructions } from 'constants/types';
import {
  NUMBER_FORMATS_WITH_DECIMALS,
  TIME_DIFF_FORMATS,
  V2_NUMBER_FORMATS,
} from 'constants/dataConstants';

type Props = {
  configInputClass: string;
  instructions: V2KPIChartInstructions;
  operationType: OPERATION_TYPES.VISUALIZE_NUMBER_V2 | OPERATION_TYPES.VISUALIZE_PROGRESS_V2;
  isCanvas?: boolean;
  updateValueFormat: (generalFormat: KPIValueFormat) => void;
};

export default function SharedKPIValueConfigs({
  configInputClass,
  instructions,
  operationType,
  isCanvas,
  updateValueFormat,
}: Props) {
  const valueFormat = instructions.valueFormat;
  const selectedFormat = valueFormat?.numberFormat || V2_NUMBER_FORMATS.NUMBER;
  const selectedTimeFormat = valueFormat?.timeFormat || TIME_DIFF_FORMATS.STANDARD;

  const timeDiffFormatOptions = isCanvas
    ? // Remove custom options as might be too complex for end users
      [TIME_DIFF_FORMATS.STANDARD, TIME_DIFF_FORMATS.ABBREVATION]
    : Object.values(TIME_DIFF_FORMATS);

  return (
    <>
      <ToggleButtonGroup fillWidth className={configInputClass} label="Format">
        {Object.values(V2_NUMBER_FORMATS).map((numberFormat) => (
          <ToggleButton
            active={numberFormat.id === selectedFormat.id}
            key={numberFormat.id}
            onClick={() => updateValueFormat({ numberFormat })}
            text={numberFormat.abbreviation}
          />
        ))}
      </ToggleButtonGroup>
      {selectedFormat.id === V2_NUMBER_FORMATS.TIME.id ? (
        <>
          <DropdownSelect
            btnMinimal
            fillWidth
            minimal
            containerClassName={configInputClass}
            filterable={false}
            label="Time Format"
            noSelectionText="Standard"
            onChange={(item) => updateValueFormat({ timeFormat: item })}
            options={timeDiffFormatOptions}
            selectedItem={selectedTimeFormat}
          />
          {valueFormat?.timeFormat?.id === TIME_DIFF_FORMATS.CUSTOM.id ? (
            <InputWithBlurSave
              containerClassName={configInputClass}
              initialValue={valueFormat?.timeCustomerFormat}
              label="Custom Time Format"
              onNewValueSubmitted={(newValue) =>
                updateValueFormat({ timeCustomerFormat: newValue })
              }
              placeholder="DD days HH:mm:ss"
            />
          ) : null}
        </>
      ) : null}
      {selectedFormat.id === V2_NUMBER_FORMATS.ABBREVIATED.id ? (
        <InputWithBlurSave
          containerClassName={configInputClass}
          initialValue={String(valueFormat?.significantDigits ?? 3)}
          label="Significant Digits"
          onNewValueSubmitted={(newValue) => {
            const intValue = parseInt(newValue);
            updateValueFormat({ significantDigits: intValue >= 0 ? intValue : 3 });
          }}
        />
      ) : null}
      {NUMBER_FORMATS_WITH_DECIMALS.includes(selectedFormat.id) ? (
        <InputWithBlurSave
          containerClassName={configInputClass}
          initialValue={String(valueFormat?.decimalPlaces ?? 2)}
          label="Decimal Places"
          onNewValueSubmitted={(newValue) => {
            const intValue = parseInt(newValue);
            updateValueFormat({ decimalPlaces: intValue > 0 ? intValue : 0 });
          }}
        />
      ) : null}
      {operationType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2 ||
      (NUMBER_FORMATS_WITH_DECIMALS.includes(selectedFormat.id) &&
        operationType === OPERATION_TYPES.VISUALIZE_NUMBER_V2) ? (
        <InputWithBlurSave
          containerClassName={configInputClass}
          initialValue={String(valueFormat?.multiplyFactor ?? 1)}
          label="Multiplier"
          onNewValueSubmitted={(newValue) => {
            const floatValue = parseFloat(newValue);
            updateValueFormat({
              multiplyFactor: isNaN(floatValue) ? undefined : floatValue,
            });
          }}
        />
      ) : null}
      <InputWithBlurSave
        containerClassName={configInputClass}
        initialValue={valueFormat?.units}
        label="Unit"
        onNewValueSubmitted={(newValue) => updateValueFormat({ units: newValue })}
      />
      <Switch
        useCustomStyles
        className={configInputClass}
        label="Include Unit Padding"
        onChange={() => updateValueFormat({ unitPadding: !valueFormat?.unitPadding })}
        switchOn={valueFormat?.unitPadding}
      />
      {operationType === OPERATION_TYPES.VISUALIZE_NUMBER_V2 ? (
        <ToggleButtonGroup fillWidth className={configInputClass} label="Text Format">
          <ToggleButton
            active={valueFormat?.bold}
            icon="bold"
            onClick={() => updateValueFormat({ bold: !valueFormat?.bold })}
          />
          <ToggleButton
            active={valueFormat?.italic}
            icon="italic"
            onClick={() => updateValueFormat({ italic: !valueFormat?.italic })}
          />
        </ToggleButtonGroup>
      ) : null}
      {!isCanvas ? (
        <InputWithBlurSave
          containerClassName={configInputClass}
          helpIcon="code"
          helpText="This field can take in variables"
          initialValue={valueFormat?.imageUrl}
          label="Image to Display Next to Value"
          onNewValueSubmitted={(newValue) => updateValueFormat({ imageUrl: newValue })}
          placeholder="Image URL"
        />
      ) : null}
    </>
  );
}
