import { sprinkles } from 'components/ds';
import { FC, useEffect, useState } from 'react';

import DropdownSelect from 'shared/DropdownSelect';

import { DashboardElement, DashboardElementConfig } from 'types/dashboardTypes';
import { FilterOperator } from 'types/filterOperations';
import { dpLinkUpdated } from 'utils/customEventUtils';
import { getOperatorOptionsForFilter } from 'utils/filterOperations';
import { getUpdateConfigFunc } from './utils';

type Props = {
  element: DashboardElement;

  updateConfig: (config: DashboardElementConfig) => void;
};

export const OperatorConfig: FC<Props> = ({ element, updateConfig }) => {
  const { config, name, element_type } = element;
  const [operator, setOperator] = useState(config.operator);

  useEffect(() => {
    if (config.operator !== operator) {
      setOperator(config.operator);
      const dataPanelIds: string[] = [];
      Object.values(config.datasetLinks ?? {}).forEach((links) => {
        links.dataPanels?.forEach((dp) => dataPanelIds.push(dp));
      });
      if (dataPanelIds.length > 0) dpLinkUpdated({ dataPanelIds, elementName: name });
    }
  }, [config, name, operator]);

  const update = getUpdateConfigFunc(config, updateConfig);

  const options = getOperatorOptionsForFilter(element_type);

  return (
    <div className={sprinkles({ display: 'flex', alignItems: 'center' })}>
      <div className={sprinkles({ body: 'b3', color: 'contentPrimary', marginRight: 'sp1' })}>
        Field
      </div>
      <DropdownSelect
        fillWidth
        ignoreCustomStyles
        minimal
        showCancelBtn
        containerClassName={sprinkles({ flex: 1 })}
        filterable={false}
        noSelectionText="Select an operator..."
        onCancelClick={() => update((draft) => (draft.operator = undefined))}
        onChange={(item) => update((draft) => (draft.operator = item.id as FilterOperator))}
        options={options}
        selectedItem={operator ? options.find((op) => op.id === operator) : undefined}
      />
      <div className={sprinkles({ body: 'b3', color: 'contentPrimary', marginLeft: 'sp1' })}>
        Value
      </div>
    </div>
  );
};
