import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { Icon, sprinkles } from 'components/ds';
import { BooleanFilterPopover } from './PopoverTypes/BooleanFilterPopover';
import { NumberAndStringFilterPopover } from './PopoverTypes/NumberAndStringFilterPopover';
import { DateFilterPopover } from './PopoverTypes/DateFilterPopover';
import * as Popover from '@radix-ui/react-popover';
import * as styles from './FilterTag.css';

import { FilterableColumn, getFilterClauseValueText } from 'utils/customerReportUtils';
import { CustomerReportFilter } from 'actions/customerReportActions';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { BOOLEAN, DATE_TYPES, NUMBER_TYPES, STRING } from 'constants/dataConstants';
import { isFilterClauseIncomplete } from 'utils/dataPanelConfigUtils';
import { clearOpenFilter } from 'reportBuilderContent/reducers/reportEditingReducer';

type Props = { clause?: CustomerReportFilter; column: FilterableColumn };

export const FilterTag: FC<Props> = ({ clause, column }) => {
  const dispatch = useDispatch();

  const openFilterId = useSelector(
    (state: ReportBuilderReduxState) => state.reportEditing.openFilterId,
  );

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  // Used to hold open popover when the popover has a dropdown open
  const [holdPopoverOpen, setHoldPopoverOpen] = useState(false);

  useEffect(() => {
    setIsPopoverOpen(false);
  }, [clause]);

  const clauseId = clause?.id ?? -1;
  useEffect(() => {
    if (openFilterId === clauseId) setIsPopoverOpen(true);
  }, [openFilterId, clauseId]);

  const renderContent = () => {
    if (NUMBER_TYPES.has(column.type) || column.type === STRING) {
      return (
        <NumberAndStringFilterPopover
          clause={clause}
          column={column}
          holdOpen={setHoldPopoverOpen}
        />
      );
    }
    if (column.type === BOOLEAN) {
      return <BooleanFilterPopover clause={clause} column={column} holdOpen={setHoldPopoverOpen} />;
    }
    if (DATE_TYPES.has(column.type)) {
      return <DateFilterPopover clause={clause} column={column} holdOpen={setHoldPopoverOpen} />;
    }
  };

  const isFilterComplete = clause ? !isFilterClauseIncomplete(clause) : false;

  const renderClause = () => {
    if (!clause) return null;
    return (
      <>
        <div className={styles.divider}></div>
        <EmbedText body="b3" color="active">
          {getFilterClauseValueText(clause)}
        </EmbedText>
      </>
    );
  };

  return (
    <Popover.Root
      onOpenChange={(open) => {
        const stayOpen = open || holdPopoverOpen;
        setIsPopoverOpen(stayOpen);
        if (!stayOpen && openFilterId) dispatch(clearOpenFilter());
      }}
      open={isPopoverOpen}>
      <Popover.Trigger asChild>
        <div className={styles.tag}>
          <Icon
            className={sprinkles({ color: 'contentSecondary' })}
            name={isFilterComplete ? 'filter' : 'regular-filter'}
          />
          <EmbedText body="b3" className={sprinkles({ marginLeft: 'sp1' })} color="contentPrimary">
            {column.display}
          </EmbedText>
          {isFilterComplete ? renderClause() : null}
        </div>
      </Popover.Trigger>
      <Popover.Content align="start" className={styles.popover} sideOffset={8}>
        {renderContent()}
      </Popover.Content>
    </Popover.Root>
  );
};
