import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import Highcharts from 'highcharts';
import HighchartsFunnel from 'highcharts/modules/funnel';
import HighchartsHeatMap from 'highcharts/modules/heatmap';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import HighchartsBoost from 'highcharts/modules/boost';
import ResizeObserver from 'react-resize-observer';
import { datadogRum } from '@datadog/browser-rum';

import { useBoost } from 'pages/dashboardPage/charts/utils/useBoost';

HighchartsHeatMap(Highcharts);
HighchartsFunnel(Highcharts);
HighchartsMore(Highcharts);
HighchartsBoost(Highcharts);

const useStyles = makeStyles(() => ({
  chartContainer: {
    height: '100%',
  },
}));

type Props = {
  chartOptions?: Highcharts.Options;
  className?: string;
};

type ChartSizeType = {
  width?: number;
  height?: number;
};

const HighCharts = ({ chartOptions, className }: Props) => {
  const classes = useStyles();
  const [chartSize, setChartSize] = useState<ChartSizeType>();
  const loadedStart = Date.now();

  useBoost(chartOptions);
  return (
    <div className={cx(classes.chartContainer, className)}>
      <HighchartsReact
        callback={(chart: Highcharts.Chart) => chartCallback(chart, loadedStart)}
        containerProps={{ style: { height: '100%', width: '100%' } }}
        highcharts={Highcharts}
        options={{
          ...chartOptions,
          chart: {
            ...chartOptions?.chart,
            width: chartSize?.width,
            height: chartSize?.height,
          },
          credits: { enabled: false },
        }}
      />
      <ResizeObserver
        onResize={(rect) => setChartSize({ width: rect.width, height: rect.height })}
      />
    </div>
  );
};

const chartCallback = (chart: Highcharts.Chart, loadedStart: number) => {
  datadogRum.addTiming(
    `${chart.userOptions.chart?.type + '_chart' || 'chart'}_loaded`,
    loadedStart,
  );
};

export default HighCharts;
