import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Icon } from '@blueprintjs/core';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      '& .bp3-input': {
        fontSize: 12,
        height: 20,
      },
    },
  },
  pageText: {
    marginRight: 4,
  },
  input: {
    marginRight: 4,
    width: 40,

    [theme.breakpoints.down('xs')]: {
      width: 30,
    },
  },
  maxPageText: {
    whiteSpace: 'nowrap',
  },
}));

type Props = {
  className?: string;
  currentPage: number;
  maxPageNumber: number;
  onNewPage: (newPage: string) => void;
};

export default function TablePager({ currentPage, maxPageNumber, onNewPage }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button
        minimal
        disabled={currentPage === 1}
        icon={
          <Icon className={GLOBAL_STYLE_CLASSNAMES.text.secondaryColor.color} icon="chevron-left" />
        }
        onClick={() => currentPage !== 1 && onNewPage(String(currentPage - 1))}
      />
      <div className={cx(classes.pageText, GLOBAL_STYLE_CLASSNAMES.text.secondaryColor.color)}>
        Page
      </div>
      <InputWithBlurSave
        hideRightIconInteractions
        isCompact
        containerClassName={classes.input}
        initialValue={String(currentPage)}
        onNewValueSubmitted={onNewPage}
      />
      <div className={cx(classes.maxPageText, GLOBAL_STYLE_CLASSNAMES.text.secondaryColor.color)}>
        of <b>{maxPageNumber}</b>
      </div>
      <Button
        minimal
        disabled={currentPage === maxPageNumber}
        icon={
          <Icon
            className={GLOBAL_STYLE_CLASSNAMES.text.secondaryColor.color}
            icon="chevron-right"
          />
        }
        onClick={() => currentPage !== maxPageNumber && onNewPage(String(currentPage + 1))}
      />
    </div>
  );
}
