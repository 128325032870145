import produce from 'immer';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';

import { V2TwoDimensionChartInstructions } from 'constants/types';
import { getColDisplayText } from 'pages/dashboardPage/DataPanelConfigV2/DataConfigTab/vizConfigs/utils';
import { getColorColumns } from 'utils/colorColUtils';
import { findMatchingAgg } from 'utils/aggUtils';

type Props = {
  instructions: V2TwoDimensionChartInstructions;
  configInputClass: string;
  firstColOnly?: boolean;
  helpTextClass: string;

  updateInstructions: (instructions: V2TwoDimensionChartInstructions) => void;
};

export default function SharedDataLabelsConfigs({
  instructions,
  firstColOnly,
  configInputClass,
  helpTextClass,
  updateInstructions,
}: Props) {
  let aggCols = instructions.aggColumns;
  if (firstColOnly) {
    aggCols = aggCols?.slice(0, 1);
  }

  return (
    <>
      <div className={helpTextClass}>
        This affects how data is displayed on the legend and interactive tooltip.
      </div>
      {getColorColumns(instructions).map((col) => (
        <InputWithBlurSave
          containerClassName={configInputClass}
          initialValue={col.column.friendly_name || getColDisplayText(col)}
          key={`data-label-color-${col.column.name}`}
          label={getColDisplayText(col)}
          onNewValueSubmitted={(newValue) => {
            const newInstructions = produce(instructions, (draft) => {
              const changedCol = draft.colorColumnOptions?.find(
                (newCol) => newCol.column.name === col.column.name,
              );
              if (changedCol) changedCol.column.friendly_name = newValue;
            });

            updateInstructions(newInstructions);
          }}
        />
      ))}

      {aggCols?.map((col) => (
        <InputWithBlurSave
          containerClassName={configInputClass}
          initialValue={col.column.friendly_name || getColDisplayText(col)}
          key={`data-label-agg-${col.column.name}`}
          label={getColDisplayText(col)}
          onNewValueSubmitted={(newValue) => {
            const newInstructions = produce(instructions, (draft) => {
              const changedCol = findMatchingAgg(draft.aggColumns, col.column.name, col.agg);

              if (changedCol) changedCol.column.friendly_name = newValue;
            });
            updateInstructions(newInstructions);
          }}
        />
      ))}
    </>
  );
}
