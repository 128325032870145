import { NumberDefinition } from 'constants/types';

import pt from 'localization/pt-br';
import en from 'localization/en-us';
import es from 'localization/es';
import fr from 'localization/fr';
import enEuro from 'localization/en-euro';

const LOCALE_MAPPING: Record<string, NumberDefinition> = {
  'pt-br': pt,
  'en-us': en,
  en: en,
  'fr-fr': fr,
  fr: fr,
  'es-es': es,
  es: es,

  // deliberately keeping this out of SUPPORTED_LOCALES because it's a special case for Tydo and Tajir
  'en-euro': enEuro,
};

export default LOCALE_MAPPING;
