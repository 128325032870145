import { FC } from 'react';
import * as RadixSwitch from '@radix-ui/react-switch';
import cx from 'classnames';

import * as styles from './index.css';
import { sprinkles } from 'components/ds';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

type BaseProps = {
  /**
   * Whether the toggle should use custom styles or not
   */
  useCustomStyles?: boolean;
  /**
   * optional class to wrap entire component
   */
  className?: string;
  /**
   * Optional test ID for selecting component in tests
   */
  'data-testid'?: string;
  /**
   * Flag to disable input from user input
   */
  disabled?: boolean;
  /**
   * optional class to wrap the label element
   */
  labelClassName?: string;
  /**
   * optional label shown above the input in the top left
   */
  label?: string;
  /**
   * If true, label will be on right side of switch
   */
  labelOnRight?: boolean;
  /**
   * When true, indicates that the user must check the switch before the owning form can be submitted
   */
  required?: boolean;
};

type ExternalStateProps = {
  name?: never;
  value?: never;
  defaultOn?: never;
  /**
   * Whether the toggle is enabled or not
   */
  switchOn?: boolean;
  /**
   * Function that runs on change events
   */
  onChange: (newValue: boolean) => void;
};

type InternalStateProps = {
  switchOn?: never;
  onChange?: never;
  /**
   * The name of the switch. Submitted with its owning form as part of a name/value pair.
   */
  name: string;
  /**
   * The value given as data when submitted with a name in a form
   */
  value?: string;
  /**
   * The state of the switch when it is initially rendered. Use when you do not need to control its state.
   */
  defaultOn: boolean;
};

// External is when parent uses state to control toggle, Internal is when using a form wrapped around the toggle
type InteractionProps = ExternalStateProps | InternalStateProps;

export type Props = BaseProps & InteractionProps;

export const Switch: FC<Props> = ({
  switchOn,
  useCustomStyles,
  className,
  'data-testid': testId,
  defaultOn,
  disabled,
  label,
  labelClassName,
  labelOnRight,
  name,
  value = 'on',
  required = false,
  onChange,
}) => {
  const labelText = label ? (
    <label
      className={cx(labelClassName ? labelClassName : styles.label, {
        [GLOBAL_STYLE_CLASSNAMES.ds.switchLabel]: useCustomStyles,
      })}
      htmlFor={name}>
      {label}
    </label>
  ) : undefined;

  const toggle = (
    <RadixSwitch.Root
      checked={switchOn}
      className={cx(styles.switchRoot, sprinkles({ cursor: disabled ? 'not-allowed' : 'pointer' }))}
      data-testid={testId}
      defaultChecked={defaultOn}
      disabled={disabled}
      name={name}
      onCheckedChange={(newValue) => {
        if (defaultOn || !onChange) return;
        onChange(newValue);
      }}
      required={required}
      value={value}>
      <RadixSwitch.Thumb className={styles.switchThumb} />
    </RadixSwitch.Root>
  );

  return (
    <div
      className={cx(
        sprinkles({
          flexItems: 'alignCenter',
          justifyContent: 'space-between',
          gap: 'sp1',
        }),
        className,
        { [GLOBAL_STYLE_CLASSNAMES.ds.switch]: useCustomStyles },
      )}
      style={{ minHeight: 18 }}>
      {labelOnRight ? (
        <>
          {toggle}
          {labelText}
        </>
      ) : (
        <>
          {labelText}
          {toggle}
        </>
      )}
    </div>
  );
};
