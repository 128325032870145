import { Layout } from '@explo-tech/react-grid-layout';
import { ACTION } from './types';
import { DashboardVersion } from 'types/dashboardVersion';
import {
  DashboardPageLayoutConfig,
  DashboardParam,
  DashboardVersionConfig,
} from 'types/dashboardVersionConfig';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { EnvironmentTag } from 'actions/teamActions';
import { OPERATION_TYPES } from 'constants/types';
import {
  DashboardElementConfig,
  DASHBOARD_ELEMENT_TYPES,
  DashboardElement,
  VIEW_MODE,
  DASHBOARD_LAYOUT_CONFIG,
} from 'types/dashboardTypes';
import { defineAPIAction, defineAPIPostAction } from 'actions/actionUtils';
import { createAction } from '@reduxjs/toolkit';
import { UpdateOnboardingStepsResponse } from './onboardingActions';

type CreateDashboardTemplateDataPanelV2Args = {
  id: string;
  newLayout: Layout[];
  datasetId: string;
  vizType: OPERATION_TYPES;
  containerId?: string;
  name?: string;
};

export const createDashboardTemplateDataPanelV2 =
  createAction<CreateDashboardTemplateDataPanelV2Args>(ACTION.CREATE_TEMPLATE_DATA_PANEL_V2);

type DuplicateDashboardItemArgs = {
  dashboardItem: DataPanelTemplate | DashboardElement;
  itemType: DASHBOARD_ELEMENT_TYPES;
  dashId: number;
};

export const duplicateDashboardItem = createAction<DuplicateDashboardItemArgs>(
  ACTION.DUPLICATE_DASHBOARD_ITEM,
);

export const createDashboardDatasetV2 = createAction<{
  name: string;
  dashId: number;
  parentSchemaId: number;
}>(ACTION.CREATE_DATASET_V2);

export const deleteDataPanelV2 = createAction<{ id: string }>(ACTION.DELETE_DATA_PANEL_V2);

export type UpdateElementConfigArgs = {
  elementId: string;
  newElementType?: DASHBOARD_ELEMENT_TYPES;
  config?: DashboardElementConfig;
};

export const updateElementConfig = createAction<UpdateElementConfigArgs>(
  ACTION.UPDATE_ELEMENT_CONFIG,
);

export const updateElementLocation = createAction<{
  elementId: string;
  newLocation: DASHBOARD_LAYOUT_CONFIG;
}>(ACTION.UPDATE_ELEMENT_LOCATION);

export const updateDashboardHeaderElementOrder = createAction<{ newOrder: string[] }>(
  ACTION.UPDATE_DASHBOARD_HEADER_ELEMENT_ORDER,
);

type ToggleFilterLinkArgs = {
  dataPanelId: string;
  elementId: string;
};

export const toggleFilterLink = createAction<ToggleFilterLinkArgs>(ACTION.TOGGLE_FILTER_LINK);

export const deleteDashboardElementV2 = createAction<{
  elementId: string;
  elementType: DASHBOARD_ELEMENT_TYPES;
}>(ACTION.DELETE_DASHBOARD_ELEMENT);

export const renameDataPanelV2 = createAction<{
  id: string;
  providedId?: string;
}>(ACTION.RENAME_DATA_PANEL_TEMPLATE);

export const {
  actionFn: createNewDashboardVersion,
  successAction: createNewDashboardVersionSuccess,
} = defineAPIAction<{ dashboard_version: DashboardVersion }>(
  ACTION.CREATE_NEW_DASHBOARD_VERSION,
  'dashboards',
  'create_new_dashboard_version',
  'POST',
);

type SwitchEditingDashboardVersionArgs = {
  dashboardVersion: DashboardVersion;
};

export const switchCurrentlyEditingDashboardVersion =
  createAction<SwitchEditingDashboardVersionArgs>(
    ACTION.SWITCH_CURRENTLY_EDITING_DASHBOARD_VERSION,
  );

type PublishNewDashboardVersionBody = {
  config: DashboardVersionConfig;
  version_number: number;
  change_comments: string;
};

type PublishNewDashboardVersionResponse = {
  dashboard_version: DashboardVersion;
} & UpdateOnboardingStepsResponse;

export const {
  actionFn: publishNewDashboardVersion,
  successAction: publishNewDashboardVersionSuccess,
} = defineAPIPostAction<PublishNewDashboardVersionBody, PublishNewDashboardVersionResponse>(
  ACTION.PUBLISH_NEW_DASHBOARD_VERSION,
  'dashboards',
  'publish_new_dashboard_version',
  'POST',
);

type RevertToDashboardVersionBody = {
  version_number: number;
};

type RevertToDashboardVersionResponse = {
  dashboard_version: DashboardVersion;
};

export const {
  actionFn: revertToDashboardVersion,
  successAction: revertToDashboardVersionSuccess,
} = defineAPIPostAction<RevertToDashboardVersionBody, RevertToDashboardVersionResponse>(
  ACTION.REVERT_TO_DASHBOARD_VERSION,
  'dashboards',
  'revert_to_dashboard_version',
  'POST',
);

export type FetchDashboardVersionsResponse = {
  versions: DashboardVersion[];
  tags: EnvironmentTag[];
};

export const {
  actionFn: fetchDashboardVersions,
  requestAction: fetchDashboardVersionsRequest,
  successAction: fetchDashboardVersionsSuccess,
} = defineAPIAction<FetchDashboardVersionsResponse>(
  ACTION.FETCH_DASHBOARD_VERSIONS,
  'dashboards',
  'list_versions',
  'GET',
);

export const saveDashboardElementUpdates = createAction<{
  id: string;
  config?: DashboardElementConfig;
  name?: string;
}>(ACTION.SAVE_DASHBOARD_ELEMENT_UPDATES);

type CreateDashboardElementArgs = {
  id: string;
  elementType: DASHBOARD_ELEMENT_TYPES;
  initialConfig: DashboardElementConfig;
  newLayout: Layout[];
  containerId?: string;
};

export const createDashboardElement = createAction<CreateDashboardElementArgs>(
  ACTION.CREATE_DASHBOARD_TEMPLATE_ELEMENT,
);

export const clearDashboardConfigReducer = createAction(ACTION.CLEAR_DASHBOARD_CONFIG_REDUCER);

export const updateDashboardTemplateParams = createAction<{
  newParams: Record<string, DashboardParam>;
}>(ACTION.UPDATE_DASHBOARD_TEMPLATE_PARAMS);

export const {
  actionFn: deleteCurrentDashboardDraft,
  successAction: deleteCurrentDashboardDraftSuccess,
} = defineAPIPostAction<{ version_number: number }, {}>(
  ACTION.DELETE_CURRENT_DASHBOARD_DRAFT,
  'dashboards',
  'delete_draft',
  'POST',
);

export type SetDPLoadingArgs = {
  ids: string[];
  loading: boolean;
};

export const setDpLoading = createAction<SetDPLoadingArgs>(ACTION.SET_DP_LOADING);

type ToggleElemVisibilityData = {
  id: string;
  layoutType: VIEW_MODE;
  isExcluded: boolean;
};

export const toggleElementVisibilityForSecondaryLayout = createAction<ToggleElemVisibilityData>(
  ACTION.TOGGLE_ELEM_VISIBILITY_FOR_SECONDARY_LAYOUT,
);

type UpdateDashboardPageLayoutConfigData = {
  config: DashboardPageLayoutConfig;
  dashboardId: number;
};

export const updateDashboardPageLayoutConfig = createAction<UpdateDashboardPageLayoutConfigData>(
  ACTION.UPDATE_DASHBOARD_PAGE_LAYOUT_CONFIG,
);

// if removeElem is false, it is an addition action
export const updateElementContainerLocation = createAction<{
  elementId: string;
  containerId: string;
  removeElem?: boolean;
  isDataPanel?: boolean;
}>(ACTION.UPDATE_ELEMENT_CONTAINER_LOCATION);
