import { makeStyles } from '@material-ui/core/styles';
import { Popover, Button, Icon } from '@blueprintjs/core';
import { BlockPicker } from 'react-color';

const useStyles = makeStyles(() => ({
  blockPicker: {
    textTransform: 'uppercase',
  },
}));

type Props = {
  className?: string;
  children?: JSX.Element;
  selectedColor: string;
  colors: string[];
  onUpdate: (colorHex: string) => void;
  onClose?: () => void;
};

const ColorPicker = ({ selectedColor, className, colors, onUpdate, onClose, children }: Props) => {
  const classes = useStyles();

  return (
    <Popover minimal className={className} onClose={onClose}>
      {children ?? <Button icon={<Icon color={selectedColor} icon="symbol-square" />} />}
      <BlockPicker
        className={classes.blockPicker}
        color={selectedColor}
        colors={colors}
        onChange={(color) => onUpdate(color.hex)}
        triangle="hide"
        // @ts-ignore
        width={180}
      />
    </Popover>
  );
};

export default ColorPicker;
