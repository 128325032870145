import produce from 'immer';

import { AdHocOperationInstructions, SortInfo } from 'types/dataPanelTemplate';
import {
  FilterOperationInstructions,
  OPERATION_TYPES,
  SortOrder,
  VISUALIZE_TABLE_OPERATIONS,
} from 'constants/types';
import { DataPanel } from 'types/exploResource';
import { DatasetSchema } from 'types/datasets';

export const getSortInfo = (dp: DataPanel): SortInfo | undefined => {
  const operationType = dp.visualize_op.operation_type;
  if (!VISUALIZE_TABLE_OPERATIONS.includes(operationType)) return;

  if (dp._adHocOperationInstructions?.sortInfo) return dp._adHocOperationInstructions.sortInfo;

  // Only grab default sort if its initial request
  if (dp._rows) return undefined;

  let defaultSortCol;
  if (
    operationType === OPERATION_TYPES.VISUALIZE_TABLE ||
    operationType === OPERATION_TYPES.VISUALIZE_REPORT_BUILDER
  ) {
    defaultSortCol = dp.visualize_op.instructions.VISUALIZE_TABLE.defaultSortedColumn;
  } else {
    defaultSortCol = dp.visualize_op.instructions.VISUALIZE_PIVOT_TABLE?.defaultSortedColumn;
  }
  if (!defaultSortCol || !defaultSortCol.column) return undefined;

  return { column_name: defaultSortCol.column, order: defaultSortCol.order ?? SortOrder.ASC };
};

export const getFilterInfo = (dp: DataPanel): FilterOperationInstructions | undefined => {
  const operationType = dp.visualize_op.operation_type;
  if (!VISUALIZE_TABLE_OPERATIONS.includes(operationType)) return;

  if (dp._adHocOperationInstructions?.filterInfo) return dp._adHocOperationInstructions.filterInfo;
};

export const sortTable = (
  colIndex: number,
  schema: DatasetSchema,
  adHocOperationInstructions: AdHocOperationInstructions,
  onAdHocSortOrPageUpdate: (adHocOperationInstructions: AdHocOperationInstructions) => void,
  sortOrder?: SortOrder,
): { column_name: string; order: SortOrder } => {
  const colName = schema[colIndex].name;
  const oldSortInfo = adHocOperationInstructions.sortInfo;

  // we use the opposite of the old sort info, where SortOrder.ASC is the default
  // if there is no sort info
  const derivedSortOrder = oldSortInfo?.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;

  const sortInfo = {
    column_name: colName,
    order: sortOrder ?? derivedSortOrder,
  };

  if (oldSortInfo?.column_name === sortInfo.column_name && oldSortInfo?.order === sortInfo.order)
    return oldSortInfo;

  const newAdHocOperationInstructions = produce(adHocOperationInstructions, (draft) => {
    draft.sortInfo = sortInfo;
  });

  onAdHocSortOrPageUpdate(newAdHocOperationInstructions);

  return sortInfo;
};
