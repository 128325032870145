import { useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import validator from 'validator';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { ReduxState } from 'reducers/rootReducer';
import { createLoadingSelector } from 'reducers/api/selectors';
import { every } from 'utils/standard';

import Button from 'shared/Button';
import DropdownSelect from 'shared/DropdownSelect';
import OnboardingFlowPage from 'components/Onboarding/OnboardingFlowPage';
import InputWithBlurSave from './dataPanelEditorPage/inputWithBlurSave';

import {
  addUserSignupCollectionInfo,
  HubspotField,
  SignupQuestionResponse,
} from 'actions/signupInfoCollectionActions';
import { ACTION } from 'actions/types';
import { pageView } from 'analytics/exploAnalytics';

import {
  QUESTION_ROLE,
  QUESTION_PHONE_NUMBER,
  QUESTION_SQL_FAMILIARITY,
  ROLES,
  SIGNUP_INFO_NEXT,
  SQL_FAMILIARITIES,
} from 'constants/onboardingConstants';
import { addSignupCollectionInfoToHubspot } from 'utils/hubspotUtils';
import { showErrorContactSupportToast } from 'shared/sharedToasts';
import { fetchUserTeam } from 'actions/teamActions';
import { logOutUser, setSignupInfoCollectionStep } from 'actions/authAction';
import { useHistory } from 'react-router';
import { ROUTES } from 'constants/routes';

const useStyles = makeStyles((theme: Theme) => ({
  signupInput: {
    marginTop: 2,
    '& .bp3-popover-target': {
      marginBottom: theme.spacing(3),
    },
    '& .bp3-input': {
      boxShadow: 'inset 0px 0px 0px 1px #e0e0e0',
    },
  },
  nextButton: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  backButton: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  formContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  questionsContainer: {
    flex: 1,
    overflowY: 'auto',
  },
  input: {
    marginBottom: theme.spacing(3),
  },
}));

type SignupInfoCollection = {
  question: string;
  response_options?: string[];
  response: string;
  error_text?: string;
  hubspot_field_name: string;
  setResponse: (value: string) => void;
  isValid: (value: string) => boolean;
};

const TellUsAboutYourselfPage = () => {
  const { currentUser, submitResponsesLoading, teamData, signupInfoCollectionStep } = useSelector(
    (state: ReduxState) => ({
      teamData: state.teamData.data,
      currentUser: state.currentUser,
      signupInfoCollectionStep: state.onboarding.signupInfoCollectionStep,
      submitResponsesLoading: createLoadingSelector(
        [ACTION.ADD_USER_SIGNUP_COLLECTION_INFO, ACTION.ADD_TEAM_SIGNUP_COLLECTION_INFO],
        false,
      )(state),
    }),
    shallowEqual,
  );

  const [role, setRole] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [sqlFamiliarity, setSqlFamiliarity] = useState('');

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const tellUsAboutYourself: SignupInfoCollection[] = [
    {
      question: QUESTION_ROLE,
      response_options: Object.values(ROLES),
      response: role,
      hubspot_field_name: 'role',
      setResponse: setRole,
      isValid: (value) => value.trim() !== '',
    },
    {
      question: QUESTION_SQL_FAMILIARITY,
      response_options: Object.values(SQL_FAMILIARITIES),
      response: sqlFamiliarity,
      hubspot_field_name: 'sql_familiarity',
      setResponse: setSqlFamiliarity,
      isValid: (value) => value.trim() !== '',
    },
    {
      question: QUESTION_PHONE_NUMBER,
      response: phoneNumber,
      error_text:
        phoneNumber === '' || validator.isMobilePhone(phoneNumber)
          ? undefined
          : 'The phone number is incorrectly formatted',
      hubspot_field_name: 'phone',
      setResponse: setPhoneNumber,
      isValid: (value) => value === '' || validator.isMobilePhone(value),
    },
  ];

  useEffect(() => {
    pageView('Tell Us About You');
  }, []);

  useEffect(() => {
    if (!teamData) dispatch(fetchUserTeam());
  }, [teamData, dispatch]);

  const onSuccess = (hubspotData: HubspotField[], formStep: SIGNUP_INFO_NEXT) => {
    dispatch(setSignupInfoCollectionStep(formStep));
    addSignupCollectionInfoToHubspot(hubspotData);
  };

  const onSubmitUserData = (
    formStep: SIGNUP_INFO_NEXT,
    dbData: SignupQuestionResponse[],
    hubspotData: HubspotField[],
  ) => {
    // Adding if the account was created via Google SSO
    hubspotData.push({ name: 'google_signup', value: currentUser.is_sso_account });

    dispatch(
      addUserSignupCollectionInfo(
        { postData: { user_id: currentUser.id, user_question_answer_list: dbData } },
        () => {
          onSuccess(hubspotData, formStep);
          history.push(ROUTES.ONBOARDING);
        },
        () => showErrorContactSupportToast(),
      ),
    );
  };

  const createHubspotAndDbData = (data: SignupInfoCollection[]) => {
    const dbData: SignupQuestionResponse[] = [];
    const hubspotData: HubspotField[] = [];
    data.forEach((qa) => {
      dbData.push({
        question: qa.question,
        response: qa.response,
        isValid: qa.isValid(qa.response),
      });
      hubspotData.push({
        name: qa.hubspot_field_name,
        value: qa.response,
      });
    });
    return { dbData, hubspotData };
  };

  const renderForm = (
    title: string,
    signupData: SignupInfoCollection[],
    formNextStep: SIGNUP_INFO_NEXT,
    onSubmit: (
      formNextStep: SIGNUP_INFO_NEXT,
      dbData: SignupQuestionResponse[],
      hubspotData: HubspotField[],
    ) => void,
  ) => {
    const { dbData, hubspotData } = createHubspotAndDbData(signupData);

    const readyToSubmit = every(dbData, (data) => data.isValid);

    return (
      <OnboardingFlowPage
        helpLinks={[
          { name: 'Log Out', onClick: () => dispatch(logOutUser()) },
          { name: 'Need Support?', url: 'https://docs.explo.co/' },
        ]}
        rightContentTitle={title}
        rightPanelContent={
          <div className={classes.formContainer}>
            <div className={classes.questionsContainer}>
              {signupData.map((formField) => {
                if (!formField.response_options) {
                  return (
                    <div className={classes.input} key={formField.question}>
                      <InputWithBlurSave
                        hideRightIconInteractions
                        className={classes.signupInput}
                        errorText={formField.error_text}
                        initialValue={formField.response}
                        label={formField.question}
                        onNewValueSubmitted={(newValue) => formField.setResponse(newValue)}
                        placeholder=""
                      />
                    </div>
                  );
                } else {
                  return (
                    <DropdownSelect
                      fillWidth
                      minimal
                      usePortal
                      className={classes.signupInput}
                      filterable={false}
                      key={formField.question}
                      label={formField.question}
                      noSelectionText="Select an option"
                      onChange={(newValue) => formField.setResponse(newValue.name)}
                      options={formField.response_options.map((resp) => ({
                        id: resp,
                        name: resp,
                      }))}
                      selectedItem={{ id: formField.response, name: formField.response }}
                    />
                  );
                }
              })}
            </div>
            <Button
              className={classes.nextButton}
              disabled={!readyToSubmit}
              loading={submitResponsesLoading}
              onClick={() => {
                if (readyToSubmit) onSubmit(formNextStep, dbData, hubspotData);
              }}
              text="Next"
              type="primary"
            />
          </div>
        }
      />
    );
  };

  if (signupInfoCollectionStep === SIGNUP_INFO_NEXT.USER) {
    return renderForm(
      'Tell us about yourself',
      tellUsAboutYourself,
      SIGNUP_INFO_NEXT.DONE,
      onSubmitUserData,
    );
  } else {
    return null;
  }
};

export default TellUsAboutYourselfPage;
