import { useState, useEffect, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import parse from 'url-parse';
import { sha256 } from 'js-sha256';
import { GoogleLogin } from 'react-google-login';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FormGroup } from '@blueprintjs/core';
import { ReduxState } from 'reducers/rootReducer';
import cx from 'classnames';
import { useLocalStorage } from 'usehooks-ts';

import OnboardingFlowPage from 'components/Onboarding/OnboardingFlowPage';
import InputWithTag from 'shared/InputWithTag';
import PasswordInput from 'components/PasswordInput';
import InfoCard from 'shared/InfoCard';
import Button from 'shared/Button';

import { logInUser } from 'actions/authAction';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ACTION } from 'actions/types';
import { pageView } from 'analytics/exploAnalytics';
import { fetchSignupEmail, googleOAuthVerification } from 'actions/authAction';
import {
  googleOAuthVerificationOnSuccess,
  googleOAuthVerificationOnFailure,
  googleOAuthVerificationOnScriptFailure,
  pingCustomerOnlineMessage,
  pingUserWithoutTeamMessage,
} from 'utils/landingPageUtils';
import { INPUT_STATUS, INPUT_STATUS_TO_STATUS_TAG_INFO } from 'constants/onboardingConstants';
import { ROUTES } from 'constants/routes';
import { PingTypes } from 'constants/types';
import { sendPing } from 'actions/pingActions';
import { enableSSO } from 'featureGates/featureGates';
import { PLAN_TYPES } from 'constants/paymentPlanConstants';

const useStyles = makeStyles((theme: Theme) => ({
  signupInput: {
    width: '100%',
    marginBottom: theme.spacing(4),
  },
  signupInputLast: {
    marginBottom: 0,
  },
  signInButton: {
    width: '100%',
    height: '40px !important',
    marginBottom: theme.spacing(3),
  },
  forgotPassword: {
    fontSize: 12,
    color: theme.palette.ds.grey900,
    textDecoration: 'underline',
  },
  forgotPasswordContainer: {
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(2),
  },
  googleSignupInput: {
    width: '100%',
    marginTop: theme.spacing(0.5),
    justifyContent: 'center',
    // we have to override google's default styling on the login button
    height: '40px !important',
    boxShadow: 'none !important',
    borderRadius: '4px !important',
    border: `1px solid ${theme.palette.ds.grey400} !important`,
    overflow: 'hidden',
  },
  formContainer: {
    width: '100%',
  },
  errorCard: {
    height: 'fit-content',
    marginBottom: theme.spacing(6),
  },
}));

const LOGIN_ERROR_TO_INPUT_ERRORS: Record<
  string,
  {
    emailError: INPUT_STATUS | undefined;
    errorMessage: string;
  }
> = {
  'Must include "email" and "password".': {
    emailError: INPUT_STATUS.ERROR,
    errorMessage: 'Please enter an email and password to log in.',
  },
  'Enter a valid email address.': {
    emailError: INPUT_STATUS.ERROR,
    errorMessage: 'Please enter a valid email to log in.',
  },
  'Unable to log in with provided credentials.': {
    emailError: INPUT_STATUS.ERROR,
    errorMessage: 'Unable to log in with the provided email and password.',
  },
};

export const EMAIL_LOCAL_STORAGE_KEY = 'verify_email';

export default function SignInPageV2() {
  const [signupEmail, setSignupEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [emailInputStatus, setEmailInputStatus] = useState<INPUT_STATUS | undefined>();
  const [disableSSOForIcognito, setDisableSSOForIncognito] = useState(false);

  // when the user signs in, if that email isn't verified we send them to the check your email page
  // and use local storage to store the email they used to sign in, which that page needs
  const setLocalStorageEmail = useLocalStorage(EMAIL_LOCAL_STORAGE_KEY, '')[1];

  const { loginLoading, ssoLoginLoading } = useSelector(
    (state: ReduxState) => ({
      loginLoading: createLoadingSelector([ACTION.LOGIN_USER], false)(state),
      ssoLoginLoading: createLoadingSelector([ACTION.GOOGLE_OAUTH_VERIFICATION], false)(state),
    }),
    shallowEqual,
  );

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const urlQueryParams = parse(window.location.href, true).query;

  const onSubmit = useCallback(
    (email: string, password: string) => {
      dispatch(
        logInUser(
          email,
          sha256(password),
          () => {
            setSignupEmail('');
            setPassword('');
            history.push('/home');
          },
          (response) => {
            const lockoutError = response.detail;

            if (lockoutError !== '' || Object.keys(response).length > 0) {
              let error;
              if (lockoutError) {
                error = lockoutError;
              } else if (response.email) {
                error = response.email[0];
              } else if (response.non_field_errors) {
                error = response.non_field_errors[0];
              }

              if (error) {
                if (error === 'E-mail is not verified.') {
                  setLocalStorageEmail(email);
                  history.push(ROUTES.CHECK_YOUR_EMAIL);
                }

                const errorStatus = LOGIN_ERROR_TO_INPUT_ERRORS[error];

                setErrorMsg(errorStatus ? errorStatus.errorMessage : error);
                setEmailInputStatus(errorStatus ? errorStatus.emailError : undefined);
              }
            }
          },
        ),
      );
    },
    [history, dispatch, setLocalStorageEmail],
  );

  useEffect(() => {
    if (urlQueryParams.invite_hash) {
      dispatch(
        fetchSignupEmail(
          {
            postData: {
              invite_hash: urlQueryParams.invite_hash,
            },
          },
          (response) => {
            setSignupEmail(response.email);
            setErrorMsg(
              'This invitation has already been used to create an account. Please sign in.',
            );
          },
        ),
      );
    }
    pageView('Login');

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        onSubmit(signupEmail, password);
        return;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [urlQueryParams.invite_hash, history, password, signupEmail, onSubmit, dispatch]);

  const location = useLocation();
  const isSessionExpired = location.hash === '#expired';

  return (
    <OnboardingFlowPage
      helpLinks={[
        { name: 'Sign Up', to: ROUTES.SIGNUP },
        { name: 'Need Support?', url: 'https://docs.explo.co/' },
      ]}
      rightContentSubTitle={
        isSessionExpired ? 'Your session has expired. Please sign in again.' : undefined
      }
      rightContentTitle="Sign in"
      rightPanelContent={
        <div className={classes.formContainer}>
          <InputWithTag
            className={classes.signupInput}
            data-testid="sign-in-email-input"
            label="Work email"
            onChange={setSignupEmail}
            placeholder=""
            statusInfo={
              emailInputStatus ? INPUT_STATUS_TO_STATUS_TAG_INFO[emailInputStatus] : undefined
            }
            value={signupEmail}
          />

          <PasswordInput
            className={cx(classes.signupInput, classes.signupInputLast)}
            data-testid="sign-in-password-input"
            label="Password"
            onChange={setPassword}
            value={password}
          />
          <div className={classes.forgotPasswordContainer}>
            <Link className={classes.forgotPassword} to={ROUTES.FORGOT_PASSWORD}>
              Forgot your password?
            </Link>
          </div>
          {errorMsg && <InfoCard error className={classes.errorCard} text={errorMsg} />}
          <Button
            className={classes.signInButton}
            loading={loginLoading || ssoLoginLoading}
            onClick={() => {
              onSubmit(signupEmail, password);
            }}
            text="Sign In"
            type="primary"
          />
          {enableSSO && (
            <div>
              <FormGroup>
                <GoogleLogin
                  accessType="offline"
                  buttonText="Sign in with Google"
                  className={classes.googleSignupInput}
                  clientId={`${process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}`}
                  disabled={loginLoading || ssoLoginLoading || disableSSOForIcognito}
                  onFailure={(response) =>
                    dispatch(googleOAuthVerificationOnFailure(response?.details))
                  }
                  onScriptLoadFailure={() => {
                    setDisableSSOForIncognito(true);
                    googleOAuthVerificationOnScriptFailure();
                  }}
                  onSuccess={(response) => {
                    dispatch(
                      googleOAuthVerification(
                        {
                          postData: {
                            authorization_code: response.code,
                          },
                        },
                        (response) => {
                          googleOAuthVerificationOnSuccess(
                            response.token,
                            response.is_first_time_sso_login_for_existing_account,
                          );
                          sendPing({
                            postData: {
                              message: pingCustomerOnlineMessage(response.user),
                              message_type:
                                response.user.team?.payment_plan === PLAN_TYPES.LAUNCH
                                  ? PingTypes.PING_ONLINE_LAUNCH
                                  : PingTypes.PING_ONLINE,
                            },
                          });
                          if (!response.user.team) {
                            dispatch(
                              sendPing({
                                postData: {
                                  message: pingUserWithoutTeamMessage(response.user),
                                  message_type: PingTypes.PING_USER_WITHOUT_TEAM,
                                },
                              }),
                            );
                          }
                        },
                        (response) => googleOAuthVerificationOnFailure(response?.error_msg),
                      ),
                    );
                  }}
                  redirectUri={`${process.env.REACT_APP_GOOGLE_OAUTH_REDIRECT_URI}`}
                  responseType="code"
                />
              </FormGroup>
            </div>
          )}
        </div>
      }
    />
  );
}
