import cx from 'classnames';
import { FC, useMemo } from 'react';

import { Button, IconButton, sprinkles } from 'components/ds';
import { SchemaSelect } from 'components/resource/SchemaSelect';
import { SchemaTableViewer } from 'components/resource/SchemaTableViewer';

import { TableDataset } from 'actions/dataSourceActions';

import * as styles from 'components/resource/SchemaViewer.css';

type Props = {
  datasetId?: string;
  saveText: string;
  schemaTablesMap: { [schemaId: number]: { [datasetId: number]: TableDataset } };
  selectedDatasetSchemaId?: number;

  onSelectSchema: (schema: { id: number; name: string }) => void;
  onRevertDraft?: () => void;
  onPreview?: () => void;
  onSave?: () => void;
  onFormat: () => void;
  className?: string;
};

/**
 * Lets the user choose a schema from a dropdown list, and then displays the tables in that schema
 * Also provides buttons for reverting edits, previewing, saving, and formatting the SQL
 */
export const SchemaViewer: FC<Props> = ({
  datasetId,
  saveText,
  schemaTablesMap,
  selectedDatasetSchemaId,
  onRevertDraft,
  onPreview,
  onSave,
  onFormat,
  onSelectSchema,
  className,
}) => {
  const tableDatasets = useMemo(() => {
    return selectedDatasetSchemaId
      ? Object.values(schemaTablesMap[selectedDatasetSchemaId]).sort((a, b) =>
          a.table_name.localeCompare(b.table_name),
        )
      : [];
  }, [selectedDatasetSchemaId, schemaTablesMap]);

  return (
    <div className={cx(styles.schemaViewerActionItemsRoot, className)}>
      <div className={styles.actionItems}>
        <IconButton
          disabled={!onRevertDraft}
          name="trashUndo"
          onClick={onRevertDraft}
          tooltipProps={{ text: 'Revert draft' }}
          type="primary"
        />
        <IconButton
          name="wrench"
          onClick={onFormat}
          tooltipProps={{ text: 'Format SQL (⌘+⇧+F)' }}
          type="primary"
        />
        <Button disabled={!onPreview} onClick={onPreview} type="secondary">
          Preview
        </Button>
        <Button disabled={!onSave} icon="play" onClick={onSave} type="primary">
          {saveText}
        </Button>
      </div>
      <div className={styles.schemaViewerRoot}>
        <SchemaSelect
          className={sprinkles({ borderBottom: 1, borderColor: 'gray7', padding: 'sp1.5' })}
          onSelectSchema={onSelectSchema}
          selectedDatasetId={datasetId}
          selectedDatasetSchemaId={selectedDatasetSchemaId}
        />
        <div className={sprinkles({ padding: 'sp1' })}>
          {tableDatasets.map((table) => (
            <SchemaTableViewer key={`schema-table${table.id}`} table={table} />
          ))}
          {tableDatasets.length === 0 ? (
            <div className={styles.noTableDatasets}>
              <div className={sprinkles({ marginBottom: 'sp1' })}>
                This schema has not been synced. Click below to sync the tables.
              </div>
              <Button to={`/sync-tables/${selectedDatasetSchemaId}`} type="primary">
                Sync Tables
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
