import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/styles';
import { ReduxState } from 'reducers/rootReducer';
import { RouteComponentProps } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import produce from 'immer';

import GettingStarted from './StepPages/GettingStarted';
import SelectDatabase from './StepPages/SelectDatabase';
import EnterCredentials from './StepPages/EnterCredentials';
import SecurityConfiguration from './StepPages/SecurityConfiguration';
import ReviewConfiguration from './StepPages/ReviewConfiguration';
import { Jobs } from 'components/JobQueue/types';

import { ConnectDataSourceStep } from './constants';
import {
  listTeamDataSources,
  testDataSourceConnection,
  connectDataSource,
  fetchSupportedDataSources,
  Quantification,
  ParentSchema,
  TestDataSourceConnectionData,
} from 'actions/dataSourceActions';
import { fetchAllParentSchemas, createParentSchema } from 'actions/parentSchemaActions';
import { DBConnectionConfig } from './types';
import { ROUTES } from 'constants/routes';
import { parseJsonFields } from 'utils/general';

import { trackEvent, EVENTS } from 'analytics/exploAnalytics';
import { fetchAccessGroups } from 'actions/rolePermissionActions';
import { AccessGroup } from 'actions/teamActions';
import { showSuccessToast } from 'shared/sharedToasts';
import { updatePageSpecificChatWidget } from 'utils/hubspotUtils';
import { WIDGET_TYPES } from 'constants/hubspotConstants';
import { updateIsWidgetOpen, updateWidgetType } from 'actions/chatWidgetActions';
import Poller from 'components/JobQueue/Poller';
import { PingTypes } from 'constants/types';
import { sendPing } from 'actions/pingActions';
import { bulkEnqueueJobs, JobDefinition } from 'actions/jobQueueActions';
import { ACTION } from 'actions/types';

const styles = () => ({
  root: {
    height: '100%',
    width: '100%',
  },
});

type MatchParams = {};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps<MatchParams> &
  WithStyles<typeof styles>;

type State = {
  currentStep: ConnectDataSourceStep;
  connectionConfig: DBConnectionConfig;
  selectedSchema?: ParentSchema;
  testingConnNumTables?: number;
  testingConnNumTablesQuantification?: Quantification;
  testingConnWorked?: boolean;
  testingConnLoading?: boolean;
  testingConnError?: string;
  connectLoading?: boolean;
  accessGroups?: AccessGroup[];
  selectedAccessGroupIds: number[];
  isNewSchema?: boolean;
  allParentSchemas?: ParentSchema[];

  awaitedJobs: Record<string, Jobs>;
};

class ConnectDataSourceFlow extends Component<Props, State> {
  state: State = {
    currentStep: ConnectDataSourceStep.GETTING_STARTED,
    connectionConfig: {},
    awaitedJobs: {},
    selectedAccessGroupIds: [],
  };

  constructor(props: Props) {
    super(props);

    props.listTeamDataSources();
    props.fetchSupportedDataSources();
    props.fetchAccessGroups(undefined, ({ access_groups }) => {
      this.setState({ accessGroups: access_groups });
      if (access_groups.length === 1)
        this.setState({ selectedAccessGroupIds: [access_groups[0].id] });
    });
    props.fetchAllParentSchemas({}, (result) => {
      if (result.parent_schemas.length === 0) {
        // Create a schema for the team if there is none. Not passing
        // in a name here means that we'll name it default_{team_name}
        props.createParentSchema({}, (data) => {
          trackEvent(EVENTS.CREATED_DEFAULT_SCHEMA, {
            schema: data.schema,
          });

          this.setState({ allParentSchemas: [data.schema], selectedSchema: data.schema });
        });
      } else {
        this.setState({ allParentSchemas: result.parent_schemas });
      }
    });
  }

  componentDidMount() {
    const { widget } = this.props;
    if (!widget.conversationStarted) {
      updatePageSpecificChatWidget(widget.isOpen);
      this.props.updateWidgetType({ widgetType: WIDGET_TYPES.CONNECT_DATA });
    }
  }

  render() {
    const { classes } = this.props;
    const { awaitedJobs } = this.state;

    return (
      <div className={classes.root}>
        <Poller
          awaitedJobs={awaitedJobs}
          updateJobResult={(finishedJobIds, onComplete) => {
            if (finishedJobIds.length > 0)
              this.setState((currentState) => {
                const newAwaitedJobs = produce(currentState.awaitedJobs, (draft) =>
                  finishedJobIds.forEach((jobId) => delete draft[jobId]),
                );
                return { awaitedJobs: newAwaitedJobs };
              });

            onComplete();
          }}
        />
        {this.renderCurrentStep()}
      </div>
    );
  }

  renderCurrentStep = () => {
    const { dataSources } = this.props;
    const {
      currentStep,
      connectionConfig,
      connectLoading,
      testingConnLoading,
      testingConnWorked,
      testingConnError,
      testingConnNumTables,
      testingConnNumTablesQuantification,
      selectedSchema,
      accessGroups,
      selectedAccessGroupIds,
      allParentSchemas,
    } = this.state;

    switch (currentStep) {
      case ConnectDataSourceStep.GETTING_STARTED:
        return (
          <GettingStarted
            accessGroups={accessGroups}
            config={connectionConfig}
            existingDataSources={dataSources ?? []}
            onNextClicked={() => this.updateStep(ConnectDataSourceStep.SELECT_DB)}
            parentSchemas={allParentSchemas}
            selectedAccessGroupIds={selectedAccessGroupIds}
            selectedSchema={selectedSchema}
            setSelectedAccessGroupIds={(accessGroupIds) =>
              this.setState({
                selectedAccessGroupIds: accessGroupIds,
              })
            }
            setSelectedSchema={this.setSelectedSchema}
            updateConfig={this.updateConfig}
          />
        );
      case ConnectDataSourceStep.SELECT_DB:
        return (
          <SelectDatabase
            config={connectionConfig}
            onBackClicked={() => this.updateStep(ConnectDataSourceStep.GETTING_STARTED)}
            onNextClicked={() => this.updateStep(ConnectDataSourceStep.ENTER_CREDS)}
            updateConfig={this.updateConfig}
          />
        );
      case ConnectDataSourceStep.ENTER_CREDS:
        return (
          <EnterCredentials
            config={connectionConfig}
            onBackClicked={() => this.updateStep(ConnectDataSourceStep.SELECT_DB)}
            onNextClicked={() => this.updateStep(ConnectDataSourceStep.SECURITY)}
            updateConfig={this.updateConfig}
          />
        );
      case ConnectDataSourceStep.SECURITY:
        return (
          <SecurityConfiguration
            config={connectionConfig}
            onBackClicked={() => this.updateStep(ConnectDataSourceStep.ENTER_CREDS)}
            onNextClicked={() => this.testConnectionCredentials()}
            testingConnLoading={testingConnLoading}
            updateConfig={this.updateConfig}
          />
        );
      case ConnectDataSourceStep.REVIEW:
        return (
          <ReviewConfiguration
            config={connectionConfig}
            connectLoading={connectLoading}
            existingDataSources={dataSources ?? []}
            onBackClicked={() => this.updateStep(ConnectDataSourceStep.SECURITY)}
            onNextClicked={() =>
              testingConnWorked ? this.connectDataSource() : this.testConnectionCredentials()
            }
            parentSchemas={allParentSchemas}
            selectedAccessGroupIds={selectedAccessGroupIds}
            selectedSchema={selectedSchema}
            setSelectedAccessGroupIds={(accessGroupIds) =>
              this.setState({ selectedAccessGroupIds: accessGroupIds })
            }
            setSelectedSchema={this.setSelectedSchema}
            testingConnError={testingConnError}
            testingConnLoading={testingConnLoading}
            testingConnNumTables={testingConnNumTables}
            testingConnNumTablesQuantification={testingConnNumTablesQuantification}
            testingConnWorked={testingConnWorked}
            updateConfig={this.updateConfig}
          />
        );
    }
  };

  updateStep = (newStep: ConnectDataSourceStep) => {
    this.setState({ currentStep: newStep });
  };

  updateConfig = (newConfig: DBConnectionConfig) => {
    const { testingConnWorked } = this.state;

    this.setState({ connectionConfig: newConfig });

    if (testingConnWorked !== undefined) this.setState({ testingConnWorked: undefined });
  };

  setSelectedSchema = (schema: ParentSchema, isNew?: boolean) => {
    const { connectionConfig } = this.state;
    const defaultSourceType = isNew
      ? connectionConfig.selectedDataSource
      : this.getDefaultSourceType(schema);

    this.setState({
      selectedSchema: schema,
      connectionConfig: {
        ...connectionConfig,
        selectedDataSource: defaultSourceType,
        selectedDataSourceIsLocked: defaultSourceType && !isNew,
      },
      isNewSchema: isNew,
    });
  };

  getDefaultSourceType = ({ id }: ParentSchema) => {
    const { dataSources, supportedDataSources } = this.props;
    if (!supportedDataSources.dataSources) return;

    const dataSource = (dataSources || []).find((ds) => ds.parent_schema_id === id);
    if (!dataSource) return;

    return supportedDataSources.dataSources.find((ds) => ds.type === dataSource.source_type);
  };

  testConnectionCredentials = () => {
    const { testDataSourceConnection, currentUser, sendPing, shouldUseJobQueue } = this.props;
    const { connectionConfig } = this.state;

    if (!connectionConfig.selectedDataSource) return;

    this.setState({ testingConnLoading: true, testingConnWorked: undefined, testingConnError: '' });

    const { parsedConfig, error } = parseJsonFields(
      connectionConfig.selectedDataSource,
      connectionConfig.dataSourceConfig || {},
    );
    if (error !== undefined) {
      this.setState({
        testingConnLoading: false,
        testingConnWorked: false,
        testingConnError: error?.toString(),
      });
      this.updateStep(ConnectDataSourceStep.REVIEW);
      return;
    }

    if (connectionConfig.name === undefined) {
      this.onTestConnectDataSourceError('Data Source name must be defined.');
      return;
    }

    const postData = {
      name: connectionConfig.name,
      type: connectionConfig.selectedDataSource.type,
      configuration: parsedConfig,
    };

    if (!shouldUseJobQueue)
      testDataSourceConnection(
        { postData },
        (data) => this.onTestConnectDataSourceSuccess(data),
        (response) => this.onTestConnectDataSourceError(response.error_msg),
      );
    else {
      this.bulkEnqueueJobs([
        {
          job_type: ACTION.TEST_DATA_SOURCE_CONNECTION,
          job_args: postData,
          onSuccess: this.onTestConnectDataSourceSuccess,
          onError: this.onTestConnectDataSourceError,
        } as JobDefinition,
      ]);
      sendPing({
        postData: {
          message: `${currentUser.first_name} ${currentUser.last_name} (${currentUser.email}) on team "${currentUser.team?.team_name}" is trying to test a data source connection (name: "${connectionConfig.name}" and type "${connectionConfig.selectedDataSource?.type}") using the job queue`,
          message_type: PingTypes.PING_DATASOURCE_TESTING,
        },
      });
    }
  };

  onTestConnectDataSourceSuccess = (data: TestDataSourceConnectionData) => {
    const { currentUser, sendPing } = this.props;
    const { connectionConfig } = this.state;

    this.setState({
      testingConnLoading: false,
      testingConnWorked: true,
      testingConnNumTables: data.num_tables,
      testingConnNumTablesQuantification: data.quantification,
      testingConnError: '',
    });
    this.updateStep(ConnectDataSourceStep.REVIEW);
    // Ping #bd-ping-testing-datasource that a test connection was successful.
    sendPing({
      postData: {
        message: `${currentUser.first_name} ${currentUser.last_name} (${currentUser.email}) on team "${currentUser.team?.team_name}" successfully tested a data source connection (name: "${connectionConfig.name}" and type "${connectionConfig.selectedDataSource?.type}")`,
        message_type: PingTypes.PING_DATASOURCE_TESTING,
      },
    });
  };

  onTestConnectDataSourceError = (error: string | undefined) => {
    const { currentUser, sendPing } = this.props;
    const { connectionConfig } = this.state;

    this.setState({
      testingConnLoading: false,
      testingConnWorked: false,
      testingConnNumTables: undefined,
      testingConnError:
        error || 'There was an error with your credentials. Please ensure they are correct.',
    });
    this.updateStep(ConnectDataSourceStep.REVIEW);
    // ping #bd-ping-testing-datasource that a test connection failed.
    sendPing({
      postData: {
        message: `<!channel> ${currentUser.first_name} ${currentUser.last_name} (${currentUser.email}) on team "${currentUser.team?.team_name}" tried a test data source connection that ERRORED (name: "${connectionConfig.name}" and type "${connectionConfig.selectedDataSource?.type}")`,
        message_type: PingTypes.PING_DATASOURCE_TESTING,
      },
    });
  };

  connectDataSource = () => {
    const { connectDataSource } = this.props;
    const { connectionConfig, selectedSchema, selectedAccessGroupIds, isNewSchema } = this.state;

    this.setState({
      connectLoading: true,
      testingConnWorked: undefined,
      testingConnError: '',
      testingConnNumTables: undefined,
    });

    if (
      !connectionConfig.selectedDataSource ||
      !selectedSchema ||
      selectedAccessGroupIds.length === 0
    )
      return;

    const { parsedConfig, error } = parseJsonFields(
      connectionConfig.selectedDataSource,
      connectionConfig.dataSourceConfig || {},
    );
    if (error !== undefined) {
      this.setState({
        testingConnLoading: false,
        testingConnWorked: false,
        testingConnError: error?.toString(),
      });
      this.updateStep(ConnectDataSourceStep.REVIEW);
      return;
    }

    if (connectionConfig.name === undefined) {
      this.setState({
        testingConnLoading: false,
        testingConnWorked: false,
        testingConnError: 'Data source name must be defined.',
        connectLoading: false,
      });
      return;
    }

    connectDataSource(
      {
        postData: {
          name: connectionConfig.name,
          provided_id: connectionConfig.providedId,
          type: connectionConfig.selectedDataSource.type,
          configuration: parsedConfig,
          schema: selectedSchema,
          access_group_ids: selectedAccessGroupIds,
          isNewSchema: isNewSchema,
        },
      },
      () => {
        this.props.history.push(ROUTES.DATA_PAGE);
        if (isNewSchema) {
          showSuccessToast(
            'You have successfully connected a data source, but you may want to sync your tables here by clicking the three dots to the right of a schema name.',
          );
        }
      },
      (response) => {
        this.setState({
          testingConnLoading: false,
          testingConnWorked: false,
          testingConnError:
            response?.error_msg ||
            'There was an error with your credentials. Please check your firewall settings and ensure the above information is correct.',
          connectLoading: false,
        });
      },
    );
  };

  bulkEnqueueJobs = (jobs: JobDefinition[] | undefined) => {
    const { bulkEnqueueJobs } = this.props;

    if (jobs === undefined || jobs.length === 0) return;

    const jobMap = Object.assign({}, ...jobs.map((job) => ({ [uuidv4()]: job })));

    bulkEnqueueJobs?.({ jobs: jobMap }, (jobs) =>
      this.setState((currentState) => {
        return {
          awaitedJobs: produce(currentState.awaitedJobs, (draft) => ({
            ...draft,
            ...jobs,
          })),
        };
      }),
    );
  };
}

const mapStateToProps = (state: ReduxState) => ({
  supportedDataSources: state.supportedDataSources,
  dataSources: state.dataSourceList.dataSources,
  widget: state.widget,
  currentUser: state.currentUser,
  shouldUseJobQueue: !!state.currentUser.team?.feature_flags.use_job_queue,
});

const mapDispatchToProps = {
  fetchSupportedDataSources,
  testDataSourceConnection,
  connectDataSource,
  fetchAllParentSchemas,
  createParentSchema,
  listTeamDataSources,
  fetchAccessGroups,
  updateWidgetType,
  updateIsWidgetOpen,
  sendPing,
  bulkEnqueueJobs,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConnectDataSourceFlow)),
);
