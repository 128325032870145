import { FC } from 'react';
import * as RadixTooltip from '@radix-ui/react-tooltip';

type Props = {
  tooltipContent: JSX.Element;
  portalContainerId?: string;
};

// TODO: Replace with EmbedTooltip once it's been designed
export const HomecookedTooltipWrapper: FC<Props> = ({
  children,
  portalContainerId,
  tooltipContent,
}) => {
  const content = <RadixTooltip.Content sideOffset={8}>{tooltipContent}</RadixTooltip.Content>;
  return (
    <RadixTooltip.Provider delayDuration={0}>
      <RadixTooltip.Root>
        <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>
        {portalContainerId ? (
          <RadixTooltip.Portal container={document.getElementById(portalContainerId)}>
            {content}
          </RadixTooltip.Portal>
        ) : (
          content
        )}
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};
