import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/ds';
import { EmbeddedDropdownMenu } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownMenu';

import {
  CustomerReport,
  CustomerReportConfig,
  saveCustomerReport,
} from 'actions/customerReportActions';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { openModalType, ReportModal } from 'reportBuilderContent/reducers/reportEditingReducer';

interface Props {
  currentConfig: CustomerReportConfig;
  report: CustomerReport;
  hasUnsavedChanges: boolean;
  isPreview?: boolean;
}

export const SaveButton: FC<Props> = ({ report, currentConfig, hasUnsavedChanges, isPreview }) => {
  const dispatch = useDispatch();
  const [saveAsClicked, setSaveAsClicked] = useState(false);

  const customerToken = useSelector(
    (state: ReportBuilderReduxState) => state.embeddedReportBuilder.requestInfo.customerToken,
  );

  const menuItems = [
    {
      disabled: isPreview || !hasUnsavedChanges,
      name: 'Save',
      onClick: () =>
        dispatch(
          saveCustomerReport({
            customerToken,
            postData: { report_id: report.id, config: currentConfig },
          }),
        ),
    },
    {
      disabled: isPreview,
      name: 'Save as',
      onClick: () => setSaveAsClicked(true),
    },
  ];

  return (
    <EmbeddedDropdownMenu
      menuOptions={menuItems}
      onOpenChange={(open) => {
        // https://github.com/radix-ui/primitives/issues/1241
        // Issue with opening dialog from dropdown
        // This adds enough delay for it to work as expected
        if (!open && saveAsClicked) {
          setSaveAsClicked(false);
          dispatch(openModalType(ReportModal.SAVE_AS));
        }
      }}>
      <Button type="secondary">Save</Button>
    </EmbeddedDropdownMenu>
  );
};
