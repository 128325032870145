import cx from 'classnames';
import { uniqBy, uniqueId } from 'utils/standard';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, ButtonGroup } from '@blueprintjs/core';

import { Spinner } from 'components/ds';

import { SelectedDropdownInputItem } from 'constants/types';
import InputLabel from 'shared/InputLabel';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    '&.bp3-button': {
      display: 'block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      height: 32,

      '&.disabled': {
        backgroundColor: 'rgba(206, 217, 224, 0.5) !important',

        '&:hover': {
          backgroundColor: 'rgba(206, 217, 224, 0.5) !important',
          color: 'inherit !important',
        },
      },
    },
  },
  emptyText: {
    fontStyle: 'italic',
    fontColor: theme.palette.ds.grey800,
    marginTop: '10px',
  },
}));

type Props = {
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
  infoTooltipText?: string;
  selectedItem?: SelectedDropdownInputItem;
  onChange: (item: SelectedDropdownInputItem | undefined) => void;
  options: SelectedDropdownInputItem[];
  fillWidth?: boolean;
  label?: string;
  loading?: boolean;
  preventUnselect?: boolean;
  useFakeLabel?: boolean;
};

export default function DashboardButtonGroupInput(props: Props) {
  const classes = useStyles();
  const {
    disabled,
    options,
    selectedItem,
    label,
    loading,
    preventUnselect,
    useFakeLabel,
    infoTooltipText,
  } = props;

  let uniqueOptions = uniqBy(options, (option: SelectedDropdownInputItem) => option.name);

  if (uniqueOptions.length > 5) {
    uniqueOptions = uniqueOptions.slice(0, 5);
  }

  if (loading) {
    return (
      <Button fill>
        <Spinner size="md" />
      </Button>
    );
  } else if (uniqueOptions.length === 0) {
    return (
      <Button disabled fill>
        <p className={classes.emptyText}>No button currently configured.</p>
      </Button>
    );
  }

  return (
    <div className={props.containerClassName}>
      {(label || useFakeLabel) && (
        <InputLabel fakeLabel={useFakeLabel} helpText={infoTooltipText} text={label || ''} />
      )}
      <ButtonGroup fill>
        {uniqueOptions.map((option) => {
          return (
            <Button
              fill
              active={option.name === selectedItem?.name}
              className={cx(
                classes.button,
                { disabled },
                GLOBAL_STYLE_CLASSNAMES.container.fill.buttonBackgroundColor,
                GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.toggleSwitchInteraction,
                GLOBAL_STYLE_CLASSNAMES.container.outline.buttonBorder,
                GLOBAL_STYLE_CLASSNAMES.container.shadow.buttonShadow,
                GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.inputFields.defaultBorderRadius,
                GLOBAL_STYLE_CLASSNAMES.text.body.button.primaryFont,
              )}
              disabled={disabled}
              key={uniqueId(`${option.id}_`)}
              onClick={() => {
                if (option.name === selectedItem?.name) {
                  if (!preventUnselect) props.onChange(undefined);
                } else {
                  props.onChange(option);
                }
              }}
              text={option.name}
            />
          );
        })}
      </ButtonGroup>
    </div>
  );
}
