import DropdownSelect from 'shared/DropdownSelect';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import { Switch } from 'components/ds';
import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';

import { STRING_FORMATS, STRING } from 'constants/dataConstants';
import {
  LegendFormat,
  OPERATION_TYPES,
  V2BoxPlotInstructions,
  V2ScatterPlotInstructions,
  V2TwoDimensionChartInstructions,
} from 'constants/types';
import { LegendPosition } from 'constants/types';

type Props = {
  configInputClass: string;
  instructions: V2TwoDimensionChartInstructions | V2ScatterPlotInstructions | V2BoxPlotInstructions;
  operationType: OPERATION_TYPES;
  isCanvas?: boolean;

  updateLegendFormat: (legendFormat: LegendFormat) => void;
};

export default function SharedLegendConfigs({
  configInputClass,
  instructions,
  operationType,
  isCanvas,
  updateLegendFormat,
}: Props) {
  const { legendFormat } = instructions;
  const selectedPosition = legendFormat?.position || LegendPosition.AUTO;

  const selectedStringFormat = legendFormat?.stringFormat?.format || STRING_FORMATS.DEFAULT;

  const showLegendFormatOption = () => {
    if (
      operationType !== OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2 &&
      operationType !== OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2
    ) {
      const legendColumnOptions =
        (instructions as V2TwoDimensionChartInstructions).colorColumnOptions || [];
      return !!legendColumnOptions.find((option) => option.column.type === STRING);
    }
    return false;
  };

  return (
    <>
      <Switch
        useCustomStyles
        className={configInputClass}
        label="Title"
        onChange={() => updateLegendFormat({ showTitle: !legendFormat?.showTitle, title: '' })}
        switchOn={legendFormat?.showTitle}
      />
      {legendFormat?.showTitle && (
        <InputWithBlurSave
          containerClassName={configInputClass}
          initialValue={legendFormat?.title}
          onNewValueSubmitted={(newValue) => updateLegendFormat({ title: newValue })}
        />
      )}
      <ToggleButtonGroup fillWidth className={configInputClass} label="Position">
        {Object.values(LegendPosition).map((position) => (
          <ToggleButton
            active={position === selectedPosition}
            key={position}
            onClick={() => updateLegendFormat({ position })}
            text={position}
          />
        ))}
      </ToggleButtonGroup>
      {!isCanvas && showLegendFormatOption() ? (
        <div>
          <DropdownSelect
            btnMinimal
            fillWidth
            ignoreCustomStyles
            minimal
            containerClassName={configInputClass}
            filterable={false}
            label="String Format"
            noSelectionText="Select a format"
            onChange={(item) =>
              updateLegendFormat({
                stringFormat: { ...legendFormat?.stringFormat, format: item.id as STRING_FORMATS },
              })
            }
            options={Object.values(STRING_FORMATS).map((formatOption) => ({
              id: formatOption,
              name: formatOption,
            }))}
            selectedItem={{ id: selectedStringFormat, name: selectedStringFormat }}
          />
          <Switch
            className={configInputClass}
            label="Remove Underscores"
            onChange={() => {
              updateLegendFormat({
                stringFormat: {
                  ...legendFormat?.stringFormat,
                  replaceUnderscores: !legendFormat?.stringFormat?.replaceUnderscores,
                },
              });
            }}
            switchOn={legendFormat?.stringFormat?.replaceUnderscores}
          />
        </div>
      ) : null}
    </>
  );
}
