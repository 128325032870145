import { FC, useState } from 'react';

import NavTabs from 'components/core/navTabs';
import { sprinkles } from 'components/ds';
import { QueryDebugger } from './QueryDebugger';
import { DataDebugger } from './DataDebugger';
import { VariablesDebugger } from './VariablesDebugger';

import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { DashboardParam } from 'types/dashboardVersionConfig';
import { Dataset } from 'actions/datasetActions';
import { Customer } from 'actions/teamActions';
import { DashboardElement, DashboardVariableMap } from 'types/dashboardTypes';

const DEBUGGER_TABS = {
  QUERY: {
    id: 'QUERY',
    name: 'Query',
  },
  VARIABLES: {
    id: 'VARIABLES',
    name: 'Variables',
  },
  DATA: {
    id: 'DATA',
    name: 'Data',
  },
};

const rootClass = sprinkles({
  parentContainer: 'fill',
  overflow: 'hidden',
  flexItems: 'column',
  borderLeft: 1,
  borderColor: 'gray7',
});

type Props = {
  selectedDataPanel?: DataPanelTemplate;
  variables: DashboardVariableMap;
  dataPanels: DataPanelTemplate[];
  dashboardElements: DashboardElement[];
  selectedUserGroup: Customer | null;
  dashboardDatasets: Record<number, Dataset>;
  dashboardTemplateId: number;
  dashboardParams: Record<string, DashboardParam>;
  timezone: string;
};

export const DashboardDebugger: FC<Props> = ({
  selectedDataPanel,
  variables,
  dashboardElements,
  selectedUserGroup,
  dashboardDatasets,
  dataPanels,
  dashboardTemplateId,
  dashboardParams,
  timezone,
}) => {
  const [selectedTabId, setSelectedTabId] = useState<string>(DEBUGGER_TABS.QUERY.id);

  const renderTab = () => {
    if (selectedTabId === DEBUGGER_TABS.QUERY.id) {
      return <QueryDebugger dataPanel={selectedDataPanel} />;
    }

    if (selectedTabId === DEBUGGER_TABS.DATA.id) {
      return <DataDebugger dataPanel={selectedDataPanel} />;
    }

    return (
      <VariablesDebugger
        dashboardDatasets={dashboardDatasets}
        dashboardElements={dashboardElements}
        dashboardParams={dashboardParams}
        dashboardTemplateId={dashboardTemplateId}
        dataPanels={dataPanels}
        selectedUserGroup={selectedUserGroup}
        timezone={timezone}
        variables={variables}
      />
    );
  };

  return (
    <div className={rootClass}>
      <div className={sprinkles({ borderBottom: 1, borderColor: 'gray7' })} style={{ height: 40 }}>
        <NavTabs
          className={sprinkles({ height: 'fill' })}
          onTabSelect={(tabId) => setSelectedTabId(tabId)}
          selectedTabId={selectedTabId}
          tabs={[DEBUGGER_TABS.QUERY, DEBUGGER_TABS.VARIABLES, DEBUGGER_TABS.DATA]}
        />
      </div>
      <div className={sprinkles({ flex: 1, overflowY: 'auto', width: 'fill' })}>{renderTab()}</div>
    </div>
  );
};
