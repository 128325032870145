import { FC, MouseEvent as ReactMouseEvent, forwardRef, Ref } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import * as styles from './index.css';
import { sprinkles } from 'components/ds';
import { Icon, IconName } from 'components/ds/Icon';
import { Tooltip, Props as TooltipProps } from 'components/ds/Tooltip';

type BaseProps = {
  /**
   * Text to display in button
   */
  children?: string;
  /**
   * Optional class name for style overrides
   */
  className?: string;
  /**
   * Optional test ID for selecting component in tests
   */
  'data-testid'?: string;
  /**
   * React ref to pass for interacting with DOM instance
   */
  parentRef?: Ref<HTMLButtonElement | HTMLAnchorElement>;
  /**
   * Whether the button should fill the width of its container
   */
  fillWidth?: boolean;
  /**
   * Shows an arrow to the right of the content for links
   */
  linkIcon?: boolean;
  /**
   * Optionally show a tooltip on hover
   */
  tooltipProps?: TooltipProps;
  /**
   * Determines style of the button
   */
  type?: 'primary' | 'secondary' | 'tertiary' | 'destructive';
};

type LinkProps = {
  disabled?: never;
  icon?: never;
  loading?: never;
  onClick?: never;
  /**
   * URL to link to. https:// is an anchor, otherwise is relative React Router
   */
  to: string;
};

type ButtonProps = {
  /**
   * If true, the button cannot be clicked.
   */
  disabled?: boolean;
  href?: never;
  /**
   * Icon placed on the left side of the button
   */
  icon?: IconName;
  /**
   * Whether or not the button shows a loading state
   */
  loading?: boolean;
  /**
   * Callback to be fired when clicked
   */
  onClick?: (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void;
  to?: never;
};

type InteractionProps = LinkProps | ButtonProps;

export type Props = BaseProps & InteractionProps;

export const Button: FC<Props> = forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>(
  (
    {
      className,
      children,
      'data-testid': testId,
      disabled,
      fillWidth,
      icon,
      linkIcon,
      loading,
      onClick,
      parentRef,
      to,
      tooltipProps,
      type = 'primary',
      ...props
    },
    ref,
  ) => {
    const elementRef = parentRef ?? ref;
    const iconOnly = icon && !children;
    const sharedProps = {
      ...props,
      className: cx(
        styles.base({
          disabled: disabled || loading,
          fillWidth,
          iconOnly,
          // @ts-ignore Radix passes down button type if used with asChild
          type: type === 'button' ? 'primary' : type,
        }),
        className,
      ),
      'data-testid': testId,
    };

    const childElements = (
      <>
        {icon ? (
          <Icon
            className={!iconOnly ? sprinkles({ paddingRight: 'sp1' }) : undefined}
            name={icon}
            size="md"
          />
        ) : null}
        {children}
        {linkIcon ? (
          <Icon className={sprinkles({ paddingLeft: 'sp1' })} name="arrow-right" size="md" />
        ) : null}
      </>
    );

    let baseElement = null;
    if (to) {
      const isAnchor = /^https?:\/\//.test(to);
      if (isAnchor) {
        baseElement = (
          <a
            {...sharedProps}
            href={to}
            ref={elementRef as Ref<HTMLAnchorElement>}
            rel="noopener noreferrer"
            target="_blank">
            {childElements}
          </a>
        );
      } else {
        baseElement = (
          <Link {...sharedProps} ref={elementRef as Ref<HTMLAnchorElement>} to={to}>
            {childElements}
          </Link>
        );
      }
    } else {
      baseElement = (
        <button
          {...sharedProps}
          aria-label={`${icon} icon`}
          disabled={disabled || loading}
          onClick={onClick}
          ref={elementRef as Ref<HTMLButtonElement>}>
          {loading ? (
            <>
              <div className={sprinkles({ position: 'absolute' })}>
                <Icon spin name="spinner" size="md" />
              </div>
              <div className={sprinkles({ opacity: 0 })}>{childElements}</div>
            </>
          ) : (
            childElements
          )}
        </button>
      );
    }

    if (tooltipProps?.text) {
      return (
        <Tooltip {...tooltipProps}>
          {disabled ? <span tabIndex={0}>{baseElement}</span> : baseElement}
        </Tooltip>
      );
    }

    return baseElement;
  },
);

Button.displayName = 'Button';
