import { FC } from 'react';
import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    padding: theme.spacing(3),
    borderRadius: 4,
    outline: `1px solid ${theme.palette.ds.grey400}`,
    backgroundColor: theme.palette.ds.white,

    '&:hover:not(.disabled)': {
      cursor: 'pointer',

      outline: `2px solid ${theme.palette.ds.blue}`,
      backgroundColor: theme.palette.ds.lightBlue,
    },

    '&:hover.disabled': {
      cursor: 'not-allowed',
    },

    '&.selected': {
      outline: `2px solid ${theme.palette.ds.blue}`,
      backgroundColor: theme.palette.ds.lightBlue,

      '&.disabled': {
        backgroundColor: theme.palette.ds.lightBlue,
        opacity: 1,
      },
    },

    '&.disabled': {
      backgroundColor: theme.palette.ds.grey300,
      opacity: 0.6,
    },
  },
  imageContainer: {
    width: 40,
    height: 40,
    marginBottom: theme.spacing(3),
  },
  image: {
    width: '100%',
  },
  text: {
    fontSize: 14,
    color: theme.palette.ds.grey700,
  },
}));

export type Props = {
  /**
   * optional class to wrap entire component
   */
  className?: string;
  /**
   * image to show in the panel
   */
  imgUrl?: string;
  /**
   * text to show undeneath the image
   */
  text: JSX.Element | string;
  /**
   * if true, shows the button in a selected state
   */
  selected?: boolean;
  /**
   * handler for when the button is clicked
   */
  onClick?: () => void;
  /**
   * if provided, button won't be selectable and will be styled as disabled
   */
  disabled?: boolean;
};

const PanelButton: FC<Props> = ({ className, imgUrl, text, selected, disabled, onClick }) => {
  const classes = useStyles();

  return (
    <div
      className={cx(classes.root, className, { selected, disabled })}
      data-testid={`panel-${text}`}
      onClick={() => !disabled && onClick?.()}>
      {imgUrl ? (
        <div className={classes.imageContainer}>
          <img alt="panel display" className={classes.image} src={imgUrl} />
        </div>
      ) : null}
      <div className={classes.text}>{text}</div>
    </div>
  );
};

export default PanelButton;
