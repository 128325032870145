import { makeStyles, Theme } from '@material-ui/core';

import { ButtonGroup } from '@blueprintjs/core';
import InputLabel from 'shared/InputLabel';
import DropdownSelect from 'shared/DropdownSelect';

import { EmailCadenceTime } from 'constants/types';
import { getTimezoneOptions } from 'utils/timezoneUtils';

const useStyles = makeStyles((theme: Theme) => ({
  timeSelect: {
    display: 'flex',
    gap: `${theme.spacing(2)}px`,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  timeContainer: {
    paddingTop: theme.spacing(3),
  },
  timezoneSelect: {
    flex: '1 1 0',
  },
}));

const HOURS = Array.from(Array(12).keys(), (time) => time + 1);
const MINUTES = Array.from(Array(60).keys());

const TIMEZONES = getTimezoneOptions();

const browser_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

type Props = {
  emailCadenceTime: EmailCadenceTime;
  onSetTime: (time: EmailCadenceTime) => void;
};

export default function EmailTimeSelection({ emailCadenceTime, onSetTime }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.timeContainer}>
      <InputLabel text={'Time'} />
      <div className={classes.timeSelect}>
        <ButtonGroup>
          <DropdownSelect
            minimal
            showIcon
            filterable={false}
            noSelectionText="HH"
            onChange={(item) => onSetTime({ ...emailCadenceTime, hour: Number(item.name) })}
            options={HOURS.map((hour) => ({
              id: String(hour),
              name: String(hour),
            }))}
            selectedItem={
              emailCadenceTime?.hour
                ? {
                    id: String(emailCadenceTime.hour),
                    name: String(emailCadenceTime.hour),
                  }
                : undefined
            }
          />
          <DropdownSelect
            minimal
            showIcon
            filterable={false}
            noSelectionText="MM"
            onChange={(item) => onSetTime({ ...emailCadenceTime, minute: Number(item.id) })}
            options={MINUTES.map((minute) => ({
              id: String(minute),
              name: String(minute).length === 2 ? String(minute) : `0${minute}`,
            }))}
            selectedItem={
              emailCadenceTime?.minute !== undefined
                ? {
                    id: String(emailCadenceTime.minute),
                    name:
                      String(emailCadenceTime.minute).length === 2
                        ? String(emailCadenceTime.minute)
                        : `0${emailCadenceTime.minute}`,
                  }
                : undefined
            }
          />
          <DropdownSelect
            minimal
            showIcon
            filterable={false}
            noSelectionText=""
            onChange={(item) => onSetTime({ ...emailCadenceTime, isPm: item.id === 'PM' })}
            options={[
              { id: 'AM', name: 'AM' },
              { id: 'PM', name: 'PM' },
            ]}
            selectedItem={
              emailCadenceTime?.isPm ? { id: 'PM', name: 'PM' } : { id: 'AM', name: 'AM' }
            }
          />
        </ButtonGroup>
        <DropdownSelect
          fillWidth
          minimal
          showIcon
          containerClassName={classes.timezoneSelect}
          filterable={false}
          noSelectionText=""
          onChange={(item) => onSetTime({ ...emailCadenceTime, timezone: item.id })}
          options={TIMEZONES}
          selectedItem={TIMEZONES.find(
            (timezone) => (emailCadenceTime.timezone ?? browser_timezone) === timezone.id,
          )}
        />
      </div>
    </div>
  );
}
