import { FC, MutableRefObject } from 'react';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import cx from 'classnames';

import * as styles from './index.css';

export type Props = {
  /**
   * Preferred alignment of tooltip against trigger
   */
  align?: 'start' | 'center' | 'end';
  /**
   * Optional class name for style overrides
   */
  className?: string;
  /**
   * Duration from when mouse enters the tooltip trigger until it opens
   */
  delayDuration?: number;
  /**
   * Render tooltip in a portal contained by the ID
   */
  portalContainerId?: string;
  /**
   * Preferred side for tooltip to render
   */
  side?: 'top' | 'right' | 'bottom' | 'left';
  /**
   * Distance in pixels from the trigger
   */
  sideOffset?: number;
  /**
   * Content of the tooltip
   */
  text: string | JSX.Element;
  /**
   * Ref for the parent container to create a boundary for the Radix Tooltip
   */
  containerRef?: MutableRefObject<HTMLElement | null>;
};

export const Tooltip: FC<Props> = ({
  align,
  children,
  className,
  delayDuration,
  portalContainerId,
  side,
  sideOffset,
  text,
  containerRef,
}) => {
  const content = (
    <RadixTooltip.Content
      align={align}
      className={cx(styles.tooltipContainer, className)}
      collisionBoundary={containerRef?.current}
      side={side ?? 'top'}
      sideOffset={sideOffset ?? 8}>
      {text}
    </RadixTooltip.Content>
  );

  return (
    <RadixTooltip.Provider delayDuration={0}>
      <RadixTooltip.Root delayDuration={delayDuration ?? 0}>
        <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>
        {portalContainerId ? (
          <RadixTooltip.Portal container={document.getElementById(portalContainerId)}>
            {content}
          </RadixTooltip.Portal>
        ) : (
          content
        )}
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};
