import { FC, HTMLAttributes, RefAttributes } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';

export enum VerticalAlignment {
  TOP = 'vertical-top',
  CENTER = 'vertical-center',
  BOTTOM = 'vertical-bottom',
}

export enum HorizontalAlignment {
  START = 'horizontal-start',
  CENTER = 'horizontal-center',
  SPACE_BETWEEN = 'horizontal-space-between',
  END = 'horizontal-end',
}

export enum FlexDirection {
  ROW = 'row',
  COLUMN = 'column',
}

const getHorizontalAlignmentProperty = (direction?: FlexDirection) => {
  return direction === FlexDirection.COLUMN ? 'alignItems' : 'justifyContent';
};

const getVerticalAlignmentProperty = (direction?: FlexDirection) => {
  return direction === FlexDirection.COLUMN ? 'justifyContent' : 'alignItems';
};

const useStyles = makeStyles(() => ({
  root: ({ direction, flex }: Pick<Props, 'direction' | 'flex'>) => ({
    display: 'flex',
    flexDirection: direction,
    flex: flex ?? undefined,

    [`&.${HorizontalAlignment.START}`]: {
      [getHorizontalAlignmentProperty(direction)]: 'flex-start',
    },
    [`&.${HorizontalAlignment.CENTER}`]: {
      [getHorizontalAlignmentProperty(direction)]: 'center',
    },
    [`&.${HorizontalAlignment.SPACE_BETWEEN}`]: {
      [getHorizontalAlignmentProperty(direction)]: 'space-between',
    },
    [`&.${HorizontalAlignment.END}`]: {
      [getHorizontalAlignmentProperty(direction)]: 'flex-end',
    },

    [`&.${VerticalAlignment.TOP}`]: {
      [getVerticalAlignmentProperty(direction)]: 'flex-start',
    },
    [`&.${VerticalAlignment.CENTER}`]: {
      [getVerticalAlignmentProperty(direction)]: 'center',
    },
    [`&.${VerticalAlignment.BOTTOM}`]: {
      [getVerticalAlignmentProperty(direction)]: 'flex-end',
    },
  }),
}));

type Props = HTMLAttributes<HTMLDivElement> &
  RefAttributes<HTMLDivElement> & {
    className?: string;
    horizontalAlignment?: HorizontalAlignment;
    id?: string;
    verticalAlignment?: VerticalAlignment;
    direction?: FlexDirection;
    flex?: number;
  };

const FlexBox: FC<Props> = ({
  className,
  children,
  horizontalAlignment,
  id,
  verticalAlignment,
  direction = FlexDirection.ROW,
  flex,
  ...otherProps
}) => {
  const classes = useStyles({ direction, flex });

  return (
    <div
      className={cx(classes.root, horizontalAlignment, verticalAlignment, className)}
      id={id}
      {...otherProps}>
      {children}
    </div>
  );
};

export default FlexBox;
