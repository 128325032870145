import { Customer } from 'actions/teamActions';

export function getCustomerVariables(customer: Customer): Record<string, string> {
  const customerProperties: Record<string, string> = {
    customer_id: customer.provided_id,
    customer_name: customer.name,
    user_group_id: customer.provided_id,
    user_group_name: customer.name,
  };
  if (customer.properties) {
    Object.entries(customer.properties).forEach(([key, val]) => {
      customerProperties[`customer.${key}`] = val;
      customerProperties[`user_group.${key}`] = val;
    });
  }
  return customerProperties;
}
