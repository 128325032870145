import { FC } from 'react';

import { sprinkles, Switch } from 'components/ds';
import * as styles from '../styles.css';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';

import { SwitchElementConfig } from 'types/dashboardTypes';
import { getUpdateConfigFunc } from '../utils';

type Props = {
  config: SwitchElementConfig;

  updateSwitchConfig: (config: SwitchElementConfig) => void;
};

export const SwitchAdjustDisplayConfig: FC<Props> = ({ config, updateSwitchConfig }) => {
  const updateConfig = getUpdateConfigFunc(config, updateSwitchConfig);

  return (
    <>
      <Switch
        useCustomStyles
        className={styles.configInput}
        label="Label"
        onChange={() => updateConfig((draft) => (draft.showSwitchLabel = !draft.showSwitchLabel))}
        switchOn={config.showSwitchLabel}
      />
      {config.showSwitchLabel ? (
        <InputWithBlurSave
          containerClassName={styles.configInput}
          initialValue={config.label}
          onNewValueSubmitted={(newValue) => updateConfig((draft) => (draft.label = newValue))}
        />
      ) : null}
      <Switch
        useCustomStyles
        className={styles.configInput}
        label="Descriptive Tooltip"
        onChange={() => updateConfig((draft) => (draft.showTooltip = !draft.showTooltip))}
        switchOn={config.showTooltip}
      />
      {config.showTooltip ? (
        <InputWithBlurSave
          containerClassName={styles.configInput}
          initialValue={config.infoTooltipText}
          onNewValueSubmitted={(newValue) =>
            updateConfig((draft) => (draft.infoTooltipText = newValue))
          }
        />
      ) : null}
      <Switch
        useCustomStyles
        className={styles.configInput}
        label="Show on-off label"
        onChange={() => updateConfig((draft) => (draft.showStatusLabel = !config.showStatusLabel))}
        switchOn={config.showStatusLabel}
      />
      {config.showStatusLabel ? (
        <div className={sprinkles({ display: 'flex' })}>
          <InputWithBlurSave
            className={sprinkles({ paddingRight: 'sp.5' })}
            initialValue={config.onStatusLabel}
            label="On Label"
            onNewValueSubmitted={(newValue) =>
              updateConfig((draft) => (draft.onStatusLabel = newValue))
            }
          />
          <InputWithBlurSave
            className={sprinkles({ paddingLeft: 'sp.5' })}
            initialValue={config.offStatusLabel}
            label="Off Label"
            onNewValueSubmitted={(newValue) =>
              updateConfig((draft) => (draft.offStatusLabel = newValue))
            }
          />
        </div>
      ) : null}
    </>
  );
};
