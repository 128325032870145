import { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Menu, MenuItem, MenuDivider, Intent } from '@blueprintjs/core';
import { useHistory } from 'react-router-dom';
import { find } from 'utils/standard';
import { Switch, AlertModal, APP_PORTAL_ID } from 'components/ds';

import { TextFieldModal } from 'components/modals/textFieldModal';
import ResourceEmbedModal from './ResourceEmbedModal';
import SetupEmailModal from 'pages/homeAppPage/setupEmailModal';
import DropdownSelect from 'shared/DropdownSelect';

import { ACTION } from 'actions/types';
import { DashboardAttribute, ExploreEmailCadence } from 'actions/teamActions';
import { assignDashboardValue } from 'actions/dashboardAttributesActions';
import { trackEvent, EVENTS } from 'analytics/exploAnalytics';
import { DashboardTimezones, DASHBOARD_TIMEZONES } from 'constants/dashboardConstants';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ReduxState } from 'reducers/rootReducer';
import TagDropdown from 'shared/TagDropdown';

import {
  DashboardTemplate,
  cloneDashboardTemplate,
  deleteDashboardTemplate,
  renameDashboardTemplate,
  updateDashboardDefaultTimezone,
  updateDashboardDisableFiltersWhileLoading,
  updateDashboardSyncCategoryColors,
  updateDashboardCacheConfig,
} from 'actions/dashboardTemplateActions';
import { Canvas, cloneCanvas, deleteCanvas, renameCanvas } from 'actions/canvasActions';
import { DASHBOARD_CACHE_OPTIONS, DEFAULT_CRON } from 'constants/cachingConstants';
import { doesUserHavePermission, doesUserHaveSomePermission } from 'utils/permissionUtils';
import { PERMISSIONED_ACTIONS, PERMISSIONED_ENTITIES } from 'constants/roleConstants';
import {
  cloneReportBuilder,
  deleteReportBuilder,
  renameReportBuilder,
  ReportBuilder,
} from 'actions/reportBuilderActions';
import { ResourcePageType } from 'types/exploResource';
import { isEmbeddingDisabled } from 'utils/paymentPlanUtils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 250,
    maxWidth: 280,
    backgroundColor: 'white',
    borderRadius: 3,
    border: `1px solid ${theme.palette.ds.grey400}`,
  },
  switchLabel: {
    fontSize: 14,
    color: '#182026',
  },
  switchInput: { paddingBottom: theme.spacing(1) },
  settingSection: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  settingTitle: {
    color: theme.palette.ds.grey700,
    fontWeight: 500,
    lineHeight: '15px',
    fontSize: 12,
    textTransform: 'uppercase',
    paddingBottom: theme.spacing(1),
  },
  subSection: {
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:not(:last-child)': { marginBottom: theme.spacing(1) },
  },
  attributeName: {
    marginRight: theme.spacing(2),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  menuItem: {
    '&:hover': {
      '& .bp3-icon': { color: `${theme.palette.ds.blue} !important` },

      color: `${theme.palette.ds.blue} !important`,
      backgroundColor: `${theme.palette.ds.lightBlue} !important`,
    },
  },
  subSectionLabel: { paddingRight: theme.spacing(2) },
}));

type Props = {
  createResourceDisabled?: boolean;
  dashboardAttributes?: DashboardAttribute[];
  emailCadence?: ExploreEmailCadence;
  pageType: ResourcePageType;
  resource: DashboardTemplate | Canvas | ReportBuilder;
  resourcePermissionEntity: PERMISSIONED_ENTITIES;
  showDeveloperSettings?: boolean;

  onDelete?: () => void;
  openConfigurabilityModal?: () => void;
  setLoadingStateForResource?: (isLoading: boolean) => void;
};

export default function ResourceConfigurationMenu({
  createResourceDisabled,
  resource,
  emailCadence,
  dashboardAttributes,
  showDeveloperSettings,
  pageType,
  resourcePermissionEntity,
  onDelete,
  openConfigurabilityModal,
  setLoadingStateForResource,
}: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { updateDefaultTimezoneLoading, permissions, enable_dashboard_timezones, paymentPlan } =
    useSelector(
      (state: ReduxState) => ({
        updateDefaultTimezoneLoading: createLoadingSelector(
          //TODO: Add For Canvas
          [ACTION.UPDATE_DASHBOARD_DEFAULT_TIMEZONE],
          false,
        )(state),
        permissions: state.currentUser.permissions,
        enable_dashboard_timezones:
          state.currentUser.team?.feature_flags.enable_dashboard_timezones,
        paymentPlan: state.teamData.data?.payment_plan,
      }),
      shallowEqual,
    );

  const isExploreProduct = pageType === ResourcePageType.EXPLORE;
  const isArchitectProduct = pageType === ResourcePageType.ARCHITECT;

  const [embedModalOpen, setEmbedModalOpen] = useState(false);
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [deleteDashboardModalOpen, setDeleteDashboardModalOpen] = useState(false);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);

  const selectedTimezoneId = resource.default_timezone || DashboardTimezones.UTC;

  const resourcePermissions = permissions[resourcePermissionEntity];

  const userCanConfigureEmails = doesUserHaveSomePermission(resourcePermissions, [
    PERMISSIONED_ACTIONS.UPDATE,
    PERMISSIONED_ACTIONS.CREATE,
    PERMISSIONED_ACTIONS.DELETE,
  ]);

  const renderDeveloperSettings = () => {
    if (
      !isExploreProduct ||
      !showDeveloperSettings ||
      !doesUserHavePermission(resourcePermissions, PERMISSIONED_ACTIONS.PUBLISH)
    )
      return null;

    const dashboard = resource as DashboardTemplate;
    return (
      <div>
        {enable_dashboard_timezones ? (
          <>
            <MenuDivider />
            <div
              className={classes.settingSection}
              onClick={(e) => {
                e.stopPropagation();
              }}>
              <div className={classes.settingTitle}>Timezone</div>
              <DropdownSelect
                fillWidth
                minimal
                disabled={updateDefaultTimezoneLoading}
                filterable={false}
                noSelectionText="Select a timezone"
                onChange={(selectedInput) => {
                  const newTimezone = selectedInput.id as DashboardTimezones;
                  dispatch(
                    updateDashboardDefaultTimezone(
                      {
                        id: resource.id,
                        postData: { default_timezone: newTimezone },
                      },
                      () => {
                        window.dispatchEvent(new CustomEvent('updateTimezone'));
                      },
                    ),
                  );
                }}
                options={DASHBOARD_TIMEZONES}
                selectedItem={DASHBOARD_TIMEZONES.find(
                  (timezone) => timezone.id === selectedTimezoneId,
                )}
              />
            </div>
          </>
        ) : null}
        <MenuDivider />
        <div className={classes.settingSection}>
          <div className={classes.settingTitle}>Caching</div>
          <Switch
            className={classes.switchInput}
            label="Enable caching"
            labelClassName={classes.switchLabel}
            onChange={() =>
              dispatch(
                updateDashboardCacheConfig({
                  id: resource.id,
                  postData: {
                    is_cache_enabled: !dashboard.is_cache_enabled,
                    // if we're turning on the cache, then set the timeout to be the last timeout,
                    // or 60 minutes if unset
                    cache_cron: !dashboard.is_cache_enabled
                      ? dashboard.cache_cron ?? DEFAULT_CRON
                      : undefined,
                  },
                }),
              )
            }
            switchOn={dashboard.is_cache_enabled}
          />
          {dashboard.is_cache_enabled && (
            <div className={classes.subSection}>
              <div className={classes.subSectionLabel}>Timeout</div>
              <DropdownSelect
                fillWidth
                minimal
                disabled={!dashboard.is_cache_enabled}
                filterable={false}
                noSelectionText="Select an option"
                onChange={(selectedInput) => {
                  dispatch(
                    updateDashboardCacheConfig({
                      id: resource.id,
                      postData: {
                        cache_cron: String(selectedInput.value),
                      },
                    }),
                  );
                }}
                options={DASHBOARD_CACHE_OPTIONS}
                selectedItem={
                  find(
                    DASHBOARD_CACHE_OPTIONS,
                    (option) => option.value === dashboard.cache_cron,
                  ) || {
                    id: '60',
                    value: 60,
                    name: 'Every 1 hour',
                  }
                }
              />
            </div>
          )}
        </div>
        <MenuDivider />
        <div className={classes.settingSection}>
          <div className={classes.settingTitle}>Miscellaneous</div>
          <Switch
            className={classes.subSection}
            label="Disable filters while loading"
            labelClassName={classes.switchLabel}
            onChange={() => {
              dispatch(
                updateDashboardDisableFiltersWhileLoading({
                  id: resource.id,
                  postData: {
                    disable_filters_while_loading: !dashboard.disable_filters_while_loading,
                  },
                }),
              );
            }}
            switchOn={dashboard.disable_filters_while_loading}
          />
          <Switch
            className={classes.subSection}
            label="Sync category colors"
            labelClassName={classes.switchLabel}
            onChange={() => {
              dispatch(
                updateDashboardSyncCategoryColors({
                  id: resource.id,
                  postData: {
                    sync_category_colors: !dashboard.sync_category_colors,
                  },
                }),
              );
            }}
            switchOn={dashboard.sync_category_colors}
          />
        </div>
      </div>
    );
  };

  const renderDashboardAttributes = () => {
    if (
      !isExploreProduct ||
      !dashboardAttributes ||
      dashboardAttributes.length === 0 ||
      !doesUserHavePermission(resourcePermissions, PERMISSIONED_ACTIONS.PUBLISH)
    )
      return null;

    const dashboard = resource as DashboardTemplate;
    return (
      <>
        <MenuDivider />
        <div className={classes.settingSection}>
          <div className={classes.settingTitle}>Attributes</div>
          {dashboardAttributes.map((attr) => {
            const elements = attr.values.map((val) => ({
              id: val.id.toString(),
              value: val.value,
              hexVal: val.color_hex,
            }));
            const selectedValue = dashboard.dashboard_attributes.find(
              (curr) => curr.attribute_id === attr.id,
            );
            const selectedItem = selectedValue
              ? elements.find((elem) => elem.id === selectedValue.value_id.toString())
              : null;
            return (
              <div className={classes.subSection} key={attr.id}>
                <div className={classes.attributeName}>{attr.name}</div>
                <TagDropdown
                  elements={elements}
                  onSelect={(elem) => {
                    dispatch(
                      assignDashboardValue({
                        postData: {
                          template_id: dashboard.id,
                          attribute_id: attr.id,
                          value_id: elem.id,
                        },
                      }),
                    );
                  }}
                  selectedItem={selectedItem ?? null}
                />
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const renderAdminOptions = () => {
    return (
      <>
        {doesUserHavePermission(resourcePermissions, PERMISSIONED_ACTIONS.UPDATE) ? (
          <MenuItem
            className={classes.menuItem}
            icon="edit"
            onClick={() => setRenameModalOpen(true)}
            shouldDismissPopover={false}
            text="Rename"
          />
        ) : null}
        {doesUserHavePermission(resourcePermissions, PERMISSIONED_ACTIONS.CREATE) &&
        !createResourceDisabled ? (
          <MenuItem
            className={classes.menuItem}
            icon="duplicate"
            onClick={() => setDuplicateModalOpen(true)}
            shouldDismissPopover={false}
            text="Duplicate"
          />
        ) : null}
        {doesUserHavePermission(resourcePermissions, PERMISSIONED_ACTIONS.VIEW_EMBED_SNIPPET) &&
        !isEmbeddingDisabled(paymentPlan) ? (
          <MenuItem
            className={classes.menuItem}
            icon="insert"
            onClick={() => {
              window.location.hash = '#embed-clicked';
              setEmbedModalOpen(true);
            }}
            shouldDismissPopover={false}
            text="Embed"
          />
        ) : null}
        {openConfigurabilityModal &&
        doesUserHavePermission(resourcePermissions, PERMISSIONED_ACTIONS.PUBLISH) ? (
          <MenuItem
            shouldDismissPopover
            className={classes.menuItem}
            icon="cog"
            onClick={() => openConfigurabilityModal()}
            text="Manage Configurability"
          />
        ) : null}
        {isExploreProduct && userCanConfigureEmails ? (
          <MenuItem
            className={classes.menuItem}
            icon="envelope"
            onClick={() => setEmailModalOpen(true)}
            shouldDismissPopover={false}
            text={'Configure emails'}
          />
        ) : null}
        {isArchitectProduct ? (
          <MenuItem
            className={classes.menuItem}
            icon="arrow-top-right"
            onClick={() => history.push(`/blueprint/${resource.id}/user-dashboards`)}
            text="View by User"
          />
        ) : null}
      </>
    );
  };

  const onDeleteForAllUsers = () => {
    setLoadingStateForResource?.(true);
    setDeleteDashboardModalOpen(false);

    if (isArchitectProduct) {
      dispatch(
        deleteCanvas({ id: resource.id }, () => {
          setLoadingStateForResource?.(false);
          trackEvent(EVENTS.DELETED_CANVAS, { canvas_id: resource.id });
          onDelete?.();
        }),
      );
      return;
    } else if (isExploreProduct) {
      dispatch(
        deleteDashboardTemplate({ id: resource.id }, () => {
          setLoadingStateForResource?.(false);
          trackEvent(EVENTS.DELETED_DASHBOARD, {
            dashboard_template_id: resource.id,
          });
          onDelete?.();
        }),
      );
    } else {
      dispatch(
        deleteReportBuilder({ id: resource.id }, () => {
          setLoadingStateForResource?.(false);
          onDelete?.();
        }),
      );
    }
  };

  const onDuplicate = () => {
    setLoadingStateForResource?.(true);
    setDuplicateModalOpen(false);

    if (isArchitectProduct) {
      dispatch(
        cloneCanvas({ id: resource.id }, (data) => {
          trackEvent(EVENTS.CREATED_CANVAS, {
            canvas_id: data.new_canvas.id,
            canvas_name: `${resource} Copy`,
          });
          setLoadingStateForResource?.(false);
          history.push(`/blueprint/${data.new_canvas.id}/datasets`);
        }),
      );
    } else if (isExploreProduct) {
      dispatch(
        cloneDashboardTemplate({ id: resource.id }, (data) => {
          trackEvent(EVENTS.CREATED_DASHBOARD, {
            dashboard_template_id: data.new_dashboard_template.id,
            dashboard_name: `${resource} Copy`,
          });
          setLoadingStateForResource?.(false);
          history.push(`/dashboard/${data.new_dashboard_template.id}`);
        }),
      );
    } else {
      dispatch(
        cloneReportBuilder({ id: resource.id }, (data) => {
          setLoadingStateForResource?.(false);
          history.push(`/report-builder/${data.new_report_builder.id}/datasets`);
        }),
      );
    }
  };

  return (
    <>
      <Menu className={classes.root}>
        {renderAdminOptions()}
        {renderDashboardAttributes()}
        {renderDeveloperSettings()}
        {doesUserHavePermission(resourcePermissions, PERMISSIONED_ACTIONS.DELETE) ? (
          <>
            <MenuDivider />
            <MenuItem
              icon="trash"
              intent={Intent.DANGER}
              onClick={() => setDeleteDashboardModalOpen(true)}
              shouldDismissPopover={false}
              text="Delete"
            />
          </>
        ) : null}
      </Menu>
      <div onClick={(e) => e.stopPropagation()}>
        <ResourceEmbedModal
          closeModal={() => setEmbedModalOpen(false)}
          embedId={resource.embed_id}
          modalOpen={embedModalOpen}
          pageType={pageType}
        />
        <TextFieldModal
          buttonName="Save"
          closeModal={() => setRenameModalOpen(false)}
          modalOpen={renameModalOpen}
          modalTitle={`Rename ${
            isArchitectProduct ? 'Blueprint' : isExploreProduct ? 'Dashboard' : 'Report Builder'
          }`}
          onSubmit={(name: string) => {
            setLoadingStateForResource?.(true);
            setRenameModalOpen(false);

            if (isArchitectProduct) {
              dispatch(
                renameCanvas({ id: resource.id, postData: { name } }, () => {
                  setLoadingStateForResource?.(false);
                  trackEvent(EVENTS.RENAMED_CANVAS_NAME, {
                    canvas_id: resource.id,
                    canvas_name: name,
                  });
                }),
              );
            } else if (isExploreProduct) {
              dispatch(
                renameDashboardTemplate({ id: resource.id, postData: { name } }, () => {
                  setLoadingStateForResource?.(false);
                  trackEvent(EVENTS.RENAMED_DASHBOARD_TEMPLATE_NAME, {
                    dashboard_template_id: resource.id,
                    dashboard_template_name: name,
                  });
                }),
              );
            } else {
              dispatch(
                renameReportBuilder({ id: resource.id, postData: { name } }, () => {
                  setLoadingStateForResource?.(false);
                }),
              );
            }
          }}
          portalContainerId={APP_PORTAL_ID}
          resourceName={resource.name}
          textFieldPlaceholder="Dashboard name"
        />
        <AlertModal
          actionButtonProps={{ text: 'Delete for all users', onClick: onDeleteForAllUsers }}
          isOpen={deleteDashboardModalOpen}
          onClose={() => setDeleteDashboardModalOpen(false)}
          portalContainerId={APP_PORTAL_ID}
          title={`Are you sure you want to delete this ${
            isArchitectProduct ? 'blueprint' : isExploreProduct ? 'dashboard' : 'report builder'
          }?`}
        />
        <AlertModal
          actionButtonProps={{ text: 'Duplicate', onClick: onDuplicate, type: 'primary' }}
          isOpen={duplicateModalOpen}
          onClose={() => setDuplicateModalOpen(false)}
          portalContainerId={APP_PORTAL_ID}
          title={`Do you want to duplicate: ${resource.name}?`}
        />
        <SetupEmailModal
          closeModal={() => setEmailModalOpen(false)}
          dashboardTemplateId={resource.id}
          emailCadence={emailCadence}
          modalOpen={emailModalOpen}
          modalTitle={`Email Configuration for ${resource.name}`}
        />
      </div>
    </>
  );
}
