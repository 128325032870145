import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import * as styles from './styles.css';
import { Icon, sprinkles } from 'components/ds';
import { ManageSchemasSideSheet } from './modals/ManageSchemasSideSheet';

const SCHEMA_EXPANDED_STORAGE_PREFIX = 'schema_is_expanded_state_';

type Props = {
  name: string;
  id: number;
  isSearching: boolean;
  userCanEdit?: boolean;
  onDelete?: () => void;
  children: JSX.Element[];
};

export const SchemaDataSourceGroup: FC<Props> = ({
  name,
  id,
  isSearching,
  userCanEdit,
  onDelete,
  children,
}) => {
  const history = useHistory();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSideSheetOpen, setIsSideSheetOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useLocalStorage(SCHEMA_EXPANDED_STORAGE_PREFIX + id, true);

  const shouldExpandGroup = isExpanded || isSearching;

  const renderDropdown = () => {
    if (!userCanEdit && !onDelete) return null;
    return (
      <DropdownMenu.Root onOpenChange={setIsDropdownOpen} open={isDropdownOpen}>
        <DropdownMenu.Trigger asChild>
          <div className={styles.schemaEllipsisMenu}>
            <Icon name="ellipsis-vertical" />
          </div>
        </DropdownMenu.Trigger>

        <DropdownMenu.Content
          align="end"
          className={styles.dropdownMenu}
          side="bottom"
          sideOffset={4}>
          {userCanEdit ? (
            <>
              <DropdownMenu.Item
                className={styles.menuItem}
                onClick={() => {
                  setIsDropdownOpen(false);
                  setIsSideSheetOpen(true);
                }}>
                Edit
              </DropdownMenu.Item>
              <DropdownMenu.Item
                className={styles.menuItem}
                onClick={() => {
                  setIsDropdownOpen(false);
                  history.push(`sync-tables/${id}`);
                }}>
                Sync Tables
              </DropdownMenu.Item>
            </>
          ) : null}

          {onDelete ? (
            <DropdownMenu.Item
              className={styles.menuItem}
              onClick={() => {
                setIsDropdownOpen(false);
                onDelete();
              }}>
              Delete
            </DropdownMenu.Item>
          ) : null}
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    );
  };

  return (
    <div className={styles.schemaGroupContainer}>
      <div className={isExpanded ? styles.schemaHeaderExpanded : styles.schemaHeaderCollapsed}>
        <div className={styles.schemaNameContainer} onClick={() => setIsExpanded(!isExpanded)}>
          <Icon
            className={sprinkles({ color: 'gray11' })}
            name={shouldExpandGroup ? 'chevron-down' : 'chevron-right'}
          />
          <p className={styles.primaryText}>{name}</p>
          <p className={styles.secondaryText}>{id}</p>
        </div>
        {renderDropdown()}
      </div>
      {shouldExpandGroup ? children : null}
      {isSideSheetOpen ? (
        <ManageSchemasSideSheet
          isOpen={isSideSheetOpen}
          schemaId={id}
          schemaName={name}
          setIsOpen={setIsSideSheetOpen}
        />
      ) : null}
    </div>
  );
};
