import { arrayMove } from '@dnd-kit/sortable';

import {
  CustomerReport,
  CustomerReportAgg,
  CustomerReportFilter,
  CustomerReportGroupBy,
  CustomerReportSort,
  CustomerReportView,
} from 'actions/customerReportActions';
import {
  ReportBuilderCol,
  ReportBuilderColConfigs,
  ReportBuilderDataset,
} from 'actions/reportBuilderConfigActions';
import { ColumnConfigs } from 'components/ds/DataGrid/columnUtils';
import {
  AGGREGATIONS_TYPES,
  BOOLEAN,
  DATE_TYPES,
  FLOAT,
  INTEGER_DATA_TYPE,
  NUMBER_TYPES,
} from 'constants/dataConstants';
import {
  Aggregation,
  AggregationType,
  CategoryChartColumnInfo,
  DateDisplayFormat,
  DateDisplayOptions,
  FilterValueRelativeDateType,
} from 'constants/types';
import { ReportSchemaInfo } from 'reportBuilderContent/reducers/reportEditingReducer';
import { DatasetColumn, DatasetSchema } from 'types/datasets';
import { DATE_PART_AGGS, DATE_PIVOT_AGGS, PIVOT_AGG_TYPES, PivotAgg } from 'types/dateRangeTypes';
import {
  FILTER_OPERATOR_TYPES_BY_ID,
  FILTER_OPS_NO_VALUE,
  FILTER_OPS_NUMBER,
  FILTER_OPS_RELATIVE_PICKER,
  FILTER_OPS_STRING,
  FilterOperator,
} from 'types/filterOperations';
import { getAggOptions, getNextAggType } from './aggUtils';
import { isFilterClauseIncomplete } from './dataPanelConfigUtils';

export type SortableColumnInfo = { name: string; id: string; type: string; description?: string };

export const getSortableColumns = (
  dataset: ReportBuilderDataset | undefined,
  columnList: string[] | undefined,
) => {
  if (!dataset?.schema || !columnList?.length) return [];

  const columns: SortableColumnInfo[] = [];

  columnList.forEach((colName) => {
    const schemaCol = dataset.schema?.find((col) => col.name === colName);
    const colConfig = dataset.columnConfigs[colName];
    if (!schemaCol || !colConfig?.isVisible) return;

    columns.push({
      id: schemaCol.name,
      type: schemaCol.type,
      name: colConfig.name,
      description: colConfig.description,
    });
  });

  return columns;
};

export type DraggableColumnInfo = ReportBuilderCol & {
  displayName: string;
  isHidden: boolean;
  description?: string;
};

export const getDraggableColumns = (
  dataset: ReportBuilderDataset | undefined,
  columnOrder: string[] | undefined,
  hiddenColumns: string[] | undefined,
) => {
  if (!dataset?.schema || !columnOrder?.length) return [];

  const columns: DraggableColumnInfo[] = [];

  columnOrder.forEach((colName) => {
    const schemaCol = dataset.schema?.find((col) => col.name === colName);
    const colConfig = dataset.columnConfigs[colName];
    if (!schemaCol || !colConfig?.isVisible) return;

    columns.push({
      ...schemaCol,
      displayName: colConfig.name,
      isHidden: !!hiddenColumns?.find((col) => col === colName),
      description: colConfig.description,
    });
  });

  return columns;
};

export type FilterableColumn = {
  name: string;
  type: string;
  display: string;
  default: boolean;
  description: string | undefined;
  isPostFilter?: boolean;
};

/**
 * Get a list of columns from the original dataset that are visible and can be filtered
 */
export const getFilterableColumns = (dataset: ReportBuilderDataset): FilterableColumn[] => {
  const filterableCols: FilterableColumn[] = [];
  dataset.schema?.forEach((col) => {
    const colConfig = dataset.columnConfigs[col.name];
    if (colConfig?.isVisible) {
      filterableCols.push({
        ...col,
        display: colConfig.name,
        default: colConfig.showDefaultFilter ?? false,
        description: colConfig.description,
      });
    }
  });
  return filterableCols;
};

const getAggFriendlyName = (
  { agg, column }: CustomerReportAgg,
  columnConfigs: ReportBuilderColConfigs,
) => {
  const columnConfig = columnConfigs[column.name];
  const aggName = AGGREGATIONS_TYPES[agg.id].name;
  return `${columnConfig?.name ?? column.name} (${aggName})`;
};

/**
 * Get a list of aggregation columns from the original dataset that are visible and can be filtered
 */
export const getFilterableAggs = (
  aggregations: CustomerReportAgg[],
  dataset: ReportBuilderDataset,
): FilterableColumn[] =>
  aggregations.map((col) => ({
    name: getAggColumnName(col),
    display: getAggFriendlyName(col, dataset.columnConfigs),
    default: false,
    description: '',
    isPostFilter: true,
    type: col.column.type,
  }));

export const updateColumnOrdering = (
  columnList: string[],
  oldName: string | number,
  newName: string | number | undefined,
): string[] => {
  if (!newName || oldName === newName) return columnList;

  // Component returns string | number type but we are only
  // passing strings so we can assume its a string
  const oldIdx = columnList.indexOf(oldName as string);
  const newIdx = columnList.indexOf(newName as string);
  return arrayMove(columnList, oldIdx, newIdx);
};

export const getFilterClauseValueText = (clause: CustomerReportFilter): string => {
  const operator = FILTER_OPERATOR_TYPES_BY_ID[clause.filterOperation.id];
  if (FILTER_OPS_NO_VALUE.has(operator.id)) return operator.name;

  if (FILTER_OPS_NUMBER.has(operator.id)) {
    return `${operator.name} ${String(clause.filterValue)}`;
  }
  if (FILTER_OPS_STRING.has(operator.id)) {
    return `${operator.name} ${clause.filterValue}`;
  }
  if (FILTER_OPS_RELATIVE_PICKER.has(operator.id)) {
    const { number, relativeTimeType } =
      (clause.filterValue as FilterValueRelativeDateType | undefined) ?? {};
    if (!number || !relativeTimeType) return '';

    const timeType = number === 1 ? relativeTimeType.id.slice(0, -1) : relativeTimeType.id;
    return `${operator.name} ${number} ${timeType.toLowerCase()}`;
  }

  return '';
};

export const getFilterDefaultOperation = (
  columnType: string,
  filterOperator?: FilterOperator,
): FilterOperator => {
  if (filterOperator) return filterOperator;

  if (NUMBER_TYPES.has(columnType)) return FilterOperator.NUMBER_EQ;
  if (columnType === BOOLEAN) return FilterOperator.BOOLEAN_IS_TRUE;
  if (DATE_TYPES.has(columnType)) return FilterOperator.DATE_PREVIOUS;
  return FilterOperator.STRING_CONTAINS;
};

export const getExportColInfo = (
  dataset: ReportBuilderDataset,
  view: CustomerReportView,
): { column_names: string[]; columns_to_select: string[] | null } => {
  const schemaInfo: ReportSchemaInfo | null =
    view.groupBys?.length || view.aggregations?.length
      ? {
          groupBys: view.groupBys ?? [],
          aggs: view.aggregations ?? [],
          columnGroupBys: view.columnGroupBys ?? [],
        }
      : null;

  const { schema } = getSchemaAndColConfigs(
    dataset,
    view.columnOrder,
    view.hiddenColumns,
    schemaInfo,
  );

  return {
    column_names: schema.map((col) => col.friendly_name ?? col.name),
    columns_to_select: schemaInfo === null ? schema.map((col) => col.name) : null,
  };
};

type RenderTableConfig = {
  schema: DatasetSchema;
  columnConfigs: ColumnConfigs;
  pivotColumns?: string[];
  groupByColumns?: string[];
};

export const getSchemaAndColConfigs = (
  dataset: ReportBuilderDataset | undefined,
  columnOrder: string[],
  hiddenColumns: string[],
  schemaInfo: ReportSchemaInfo | null,
): RenderTableConfig => {
  if (!dataset) return { schema: [], columnConfigs: {} };
  if (schemaInfo) return getGroupedSchemaAndConfigs(dataset, schemaInfo);

  return {
    columnConfigs: dataset.columnConfigs,
    schema: getCustomerReportSchema(dataset, columnOrder, hiddenColumns),
  };
};

const datePartSet = new Set(DATE_PART_AGGS.map((agg) => agg.id));

export function getAggColumnName({ column, agg }: CustomerReportAgg) {
  return `${column.name}_${agg.id.toLowerCase()}`;
}

export function getGroupByColumnName({ column, bucket }: CustomerReportGroupBy) {
  if (bucket) {
    const pivot = PIVOT_AGG_TYPES[bucket];
    return `${pivot.name.toLowerCase().replaceAll(' ', '_')}_${column.name}`;
  }
  return column.name;
}

const getGroupedSchemaAndConfigs = (
  dataset: ReportBuilderDataset,
  { groupBys, aggs, columnGroupBys }: ReportSchemaInfo,
): RenderTableConfig => {
  const columnConfigs: ColumnConfigs = {};
  const schema: DatasetSchema = [];
  const pivotColumns: string[] = [];
  const groupByColumns: string[] = [];

  groupBys.forEach((groupBy) => {
    const col = getDatasetColFromGroupBy(dataset, columnConfigs, groupBy);
    schema.push(col);
    if (columnGroupBys.length > 0) groupByColumns.push(col.name);
  });
  columnGroupBys.forEach((groupBy) => {
    const col = getDatasetColFromGroupBy(dataset, columnConfigs, groupBy);
    schema.push(col);
    pivotColumns.push(col.name);
  });

  aggs.forEach((aggCol) => {
    const { column, agg } = aggCol;
    // TODO: Formatting for aggs?
    const name = getAggColumnName(aggCol);
    const friendlyName = getAggFriendlyName(aggCol, dataset.columnConfigs);

    let type = column.type;
    if (agg.id === Aggregation.COUNT || agg.id === Aggregation.COUNT_DISTINCT) {
      type = INTEGER_DATA_TYPE;
    } else if (agg.id === Aggregation.AVG) {
      type = FLOAT;
    }

    schema.push({ name, type, friendly_name: friendlyName });
  });

  return {
    schema,
    columnConfigs,
    pivotColumns: pivotColumns.length > 0 ? pivotColumns : undefined,
    groupByColumns: groupByColumns.length > 0 ? groupByColumns : undefined,
  };
};

const getDatasetColFromGroupBy = (
  dataset: ReportBuilderDataset,
  columnConfigs: ColumnConfigs,
  groupBy: CustomerReportGroupBy,
): DatasetColumn => {
  const { column, bucket } = groupBy;
  const columnConfig = dataset.columnConfigs[column.name];
  const name = getGroupByColumnName(groupBy);

  let formatting = columnConfig?.displayFormatting;
  let friendlyName = columnConfig?.name ?? column.name;
  if (bucket) {
    const dateFormatting = formatting as DateDisplayOptions | undefined;
    const pivot = PIVOT_AGG_TYPES[bucket];
    friendlyName = `${friendlyName} (${pivot.name})`;

    if (datePartSet.has(bucket)) {
      formatting = { ...dateFormatting, datePartAgg: bucket };
    } else {
      let customFormat = 'MMM yyyy';
      if (bucket === PivotAgg.DATE_DAY || bucket === PivotAgg.DATE_WEEK)
        customFormat = 'MMM d, yyyy';
      else if (bucket === PivotAgg.DATE_YEAR) customFormat = 'yyyy';

      formatting = { ...dateFormatting, format: DateDisplayFormat.CUSTOM, customFormat };
    }
  }
  columnConfigs[name] = { displayFormatting: formatting };

  return { name, friendly_name: friendlyName, type: column.type };
};

const getCustomerReportSchema = (
  dataset: ReportBuilderDataset | undefined,
  selectedColumns: string[] | undefined,
  hiddenColumns: string[] | undefined,
): DatasetSchema => {
  if (!dataset?.schema) return [];

  const hiddenSet = new Set(hiddenColumns ?? []);

  const schema: DatasetSchema = [];
  selectedColumns?.forEach((col) => {
    if (hiddenSet.has(col)) return;

    const columnConfig = dataset.columnConfigs[col];
    if (!columnConfig?.isVisible) return;

    const schemaCol = dataset.schema?.find((c) => c.name === col);
    if (schemaCol) {
      schema.push({ name: col, friendly_name: columnConfig.name, type: schemaCol.type });
    }
  });
  return schema;
};

export const getReportName = (report: CustomerReport) => {
  return report.name || 'Untitled Report';
};

export const getCurrentView = (
  views: CustomerReportView[] | undefined,
  currentView: string | null,
) => {
  return currentView ? views?.find((view) => view.id === currentView) : undefined;
};

export const getGroupByUniqueId = ({ bucket, column }: CustomerReportGroupBy): string => {
  if (!bucket) return column.name;
  return `${column.name}_${bucket}`;
};

const DATE_AGGS = [...DATE_PIVOT_AGGS, ...DATE_PART_AGGS].filter(
  (agg) => agg.id !== PivotAgg.DATE_SMART,
);

export const getNewGroupBy = (
  col: ReportBuilderCol,
  view: CustomerReportView,
): CustomerReportGroupBy | null => {
  const groupBys = (view.groupBys ?? []).concat(view.columnGroupBys ?? []);
  if (DATE_TYPES.has(col.type)) {
    const usedBuckets = new Set<string>();
    groupBys.forEach(({ column, bucket }) => {
      if (column.name === col.name && bucket) usedBuckets.add(bucket);
    });

    if (!usedBuckets.has(PivotAgg.DATE_MONTH)) return { column: col, bucket: PivotAgg.DATE_MONTH };

    for (let idx = 0; idx < DATE_AGGS.length; idx++) {
      const datePivot = DATE_AGGS[idx].id;
      if (!usedBuckets.has(datePivot)) return { column: col, bucket: datePivot };
    }
  } else {
    const alreadyExists = groupBys.find(({ column }) => column.name === col.name);
    if (!alreadyExists) return { column: col };
  }

  return null;
};

export const getGroupByBucketOptions = (
  { column, bucket }: CustomerReportGroupBy,
  bucketsByCol: Record<string, Set<PivotAgg>>,
): { value: PivotAgg; name: string }[] => {
  if (!DATE_TYPES.has(column.type) || !bucket) return [];

  const bucketsSelected = bucketsByCol[column.name];

  return DATE_AGGS.reduce((acc, agg) => {
    if (!bucketsSelected.has(agg.id)) acc.push({ value: agg.id, name: agg.name });
    return acc;
  }, [] as { value: PivotAgg; name: string }[]);
};

export const getAggUniqueId = (agg: CustomerReportAgg): string => {
  return `${agg.column.name}_${agg.agg.id}`;
};

export const getNewAgg = (
  col: ReportBuilderCol,
  view: CustomerReportView,
): CustomerReportAgg | null => {
  const currAggTypes: AggregationType[] = [];
  view.aggregations?.forEach(({ column, agg }) => {
    if (column.name === col.name) currAggTypes.push(agg);
  });
  const nextAgg = getNextAggType(col, currAggTypes);
  if (nextAgg) return { column: col, agg: nextAgg };

  return null;
};

export const getReportAggOptions = (
  { column }: CustomerReportAgg,
  aggsByCol: Record<string, Set<Aggregation>>,
) => {
  const aggs = aggsByCol[column.name];
  return getAggOptions(column.type).reduce((acc, agg) => {
    if (!aggs.has(agg.id)) acc.push({ value: agg.id, name: agg.name });
    return acc;
  }, [] as { value: Aggregation; name: string }[]);
};

export type ViewRequestParams = {
  sort: CustomerReportSort[];
  filters: CustomerReportFilter[];
  group_bys: CategoryChartColumnInfo[];
  aggs: CustomerReportAgg[];
  is_pivot_request: boolean;
};

export const getViewRequestParams = (view: CustomerReportView): ViewRequestParams => {
  const aggs = view.aggregations ?? [];
  let groupBys = view.groupBys ?? [];

  let is_pivot_request = false;
  const colGroupBys = view.columnGroupBys ?? [];
  if (groupBys.length > 0 && aggs.length > 0 && colGroupBys.length > 0) {
    groupBys = groupBys.concat(colGroupBys);
    is_pivot_request = true;
  }

  return {
    // Only pass complete filter clauses
    filters: view.filters.filter((clause) => !isFilterClauseIncomplete(clause)),
    group_bys: prepareGroupBysForFetch(groupBys),
    aggs,
    sort: view.sort ?? [],
    is_pivot_request,
  };
};

const prepareGroupBysForFetch = (groupBys: CustomerReportGroupBy[]): CategoryChartColumnInfo[] =>
  groupBys.map(({ column, bucket }) => {
    const colInfo: CategoryChartColumnInfo = { column };
    if (bucket) {
      const agg = PIVOT_AGG_TYPES[bucket];
      colInfo.bucket = { id: agg.id, name: agg.name };
    }
    return colInfo;
  });
