import DropdownSelect from 'shared/DropdownSelect';

import { FilterOperator, FILTER_OPERATORS } from 'types/filterOperations';
import { Props as FilterRowProps } from './FilterRow';

type OperatorDropdownProps = { className?: string } & Pick<
  FilterRowProps,
  'selectedOperator' | 'selectedColumn' | 'onOperatorSelect'
>;
export default function OperatorDropdown({
  className,
  selectedColumn,
  selectedOperator,
  onOperatorSelect,
}: OperatorDropdownProps) {
  const relevantOperators = FILTER_OPERATORS.filter((op) =>
    selectedColumn ? op.supported_column_types.has(selectedColumn.type) : false,
  );

  return (
    <span className={className}>
      <DropdownSelect
        fillWidth
        minimal
        usePortal
        filterable={false}
        noSelectionText="Operator"
        onChange={(item) => onOperatorSelect(item.id as FilterOperator)}
        options={relevantOperators.map((op) => ({
          name: op.selectionValue,
          id: op.id,
        }))}
        selectedItem={
          selectedOperator ? relevantOperators.find((op) => op.id === selectedOperator) : undefined
        }
      />
    </span>
  );
}
