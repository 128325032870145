import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import * as RD from 'remotedata';

import * as exportActions from 'actions/exportActions';
import * as embedActions from 'actions/shareActions';
import * as dpActions from 'actions/dataPanelTemplateAction';
import {
  clearDashboardLayoutReducer,
  clearDownloads,
  clearShareLink,
} from 'actions/dashboardLayoutActions';
import {
  fetchShareIdError,
  fetchShareIdRequest,
  fetchShareIdSuccess,
} from 'actions/dashboardTemplateActions';

export type ExportType = 'pdf' | 'image' | 'csv' | 'xlsx';

export type SpreadsheetType = 'csv' | 'xlsx';

export type DownloadInfo = { exportType: ExportType } & DownloadType;

type DownloadType =
  | { type: 'email'; status: RD.ResponseData<undefined> }
  | { type: 'url'; status: RD.ResponseData<string> };

type ShareData = {
  id: string;
  shareLinkUrl: string | null;
};

export type ChartMenuInfo = {
  chartId: string;
  chartY: number;
  chartX: number;
  category: string;
  subCategory?: string;
};

interface DashboardLayoutReducer {
  dashboardExport: DownloadInfo | undefined;
  dpDownloads: Record<string, DownloadInfo | undefined>;
  shareData: RD.ResponseData<ShareData>;

  chartMenu: ChartMenuInfo | null;
}

const initialState: DashboardLayoutReducer = {
  dashboardExport: undefined,
  dpDownloads: {},
  shareData: RD.Idle(),
  chartMenu: null,
};

const receiveDownloadInfo = (exportType: ExportType, url?: string): DownloadInfo => {
  return url
    ? { type: 'url', exportType, status: RD.Success(url) }
    : { type: 'email', exportType, status: RD.Success(undefined) };
};

const setLoadingState = (exportType: ExportType, email: string | undefined): DownloadInfo => {
  return { type: email ? 'email' : 'url', exportType, status: RD.Loading() };
};

const dashboardLayoutSlice = createSlice({
  name: 'dashboardLayout',
  initialState,
  reducers: {
    setChartMenu: (state, { payload }: PayloadAction<ChartMenuInfo | null>) => {
      state.chartMenu = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(clearDashboardLayoutReducer, () => {
        return initialState;
      })
      .addCase(clearShareLink, (state) => {
        state.shareData = RD.Idle();
      })
      .addCase(clearDownloads, (state, { payload }) => {
        if (payload === undefined) {
          state.dashboardExport = undefined;
          return;
        }
        if (payload in state.dpDownloads) delete state.dpDownloads[payload];
      })
      // CSV Downloads
      .addMatcher(
        isAnyOf(
          embedActions.embedDownloadDataPanelSpreadsheetRequest,
          dpActions.downloadDataPanelSpreadsheetRequest,
        ),
        (state, { payload }) => {
          state.dpDownloads[payload.postData.id] = setLoadingState(
            payload.postData.file_format,
            payload.postData.email,
          );
        },
      )
      .addMatcher(
        isAnyOf(
          embedActions.embedDownloadDataPanelSpreadsheetSuccess,
          dpActions.downloadDataPanelSpreadsheetSuccess,
        ),
        (state, { payload }) => {
          state.dpDownloads[payload.postData.id] = receiveDownloadInfo(
            payload.postData.file_format,
            payload.url,
          );
        },
      )
      .addMatcher(
        isAnyOf(
          embedActions.embedDownloadDataPanelSpreadsheetError,
          dpActions.downloadDataPanelSpreadsheetError,
        ),
        (state, { payload }) => {
          const download = state.dpDownloads[payload.postData.id];
          if (!download) return;

          state.dpDownloads[payload.postData.id] = {
            ...download,
            status: RD.Error('Error downloading spreadsheet'),
          };
        },
      )
      // PDF Downloads
      .addMatcher(
        isAnyOf(
          embedActions.embedDownloadDataPanelPdfRequest,
          exportActions.downloadDataPanelPdfRequest,
        ),
        (state, { payload }) => {
          state.dpDownloads[payload.postData.data_panel_template_id] = setLoadingState(
            'pdf',
            payload.postData.email,
          );
        },
      )
      .addMatcher(
        isAnyOf(
          embedActions.embedDownloadDataPanelPdfSuccess,
          exportActions.downloadDataPanelPdfSuccess,
        ),
        (state, { payload }) => {
          state.dpDownloads[payload.postData.data_panel_template_id] = receiveDownloadInfo(
            'pdf',
            payload.url,
          );
        },
      )
      .addMatcher(
        isAnyOf(
          embedActions.embedDownloadDataPanelPdfError,
          exportActions.downloadDataPanelPdfError,
        ),
        (state, { payload }) => {
          const dpId = payload.postData.data_panel_template_id;
          const download = state.dpDownloads[dpId];
          if (!download) return;

          state.dpDownloads[dpId] = { ...download, status: RD.Error('Error downloading pdf') };
        },
      )
      // Dashboard Exports
      .addMatcher(
        isAnyOf(
          embedActions.embedDownloadDashboardImageRequest,
          exportActions.downloadDashboardImageRequest,
        ),
        (state, { payload }) => {
          state.dashboardExport = setLoadingState('image', payload.postData.email);
        },
      )
      .addMatcher(
        isAnyOf(
          embedActions.embedDownloadDashboardImageSuccess,
          exportActions.downloadDashboardImageSuccess,
        ),
        (state, { payload }) => {
          state.dashboardExport = receiveDownloadInfo('image', payload.url);
        },
      )
      .addMatcher(
        isAnyOf(
          embedActions.embedDownloadDashboardImageError,
          exportActions.downloadDashboardImageError,
        ),
        (state) => {
          if (!state.dashboardExport) return;
          state.dashboardExport = {
            ...state.dashboardExport,
            status: RD.Error('Error downloading image'),
          };
        },
      )
      .addMatcher(
        isAnyOf(
          embedActions.embedDownloadDashboardPdfRequest,
          exportActions.downloadDashboardPdfRequest,
        ),
        (state, { payload }) => {
          state.dashboardExport = setLoadingState('pdf', payload.postData.email);
        },
      )
      .addMatcher(
        isAnyOf(
          embedActions.embedDownloadDashboardPdfSuccess,
          exportActions.downloadDashboardPdfSuccess,
        ),
        (state, { payload }) => {
          state.dashboardExport = receiveDownloadInfo('pdf', payload.url);
        },
      )
      .addMatcher(
        isAnyOf(
          embedActions.embedDownloadDashboardPdfError,
          exportActions.downloadDashboardPdfError,
        ),
        (state) => {
          if (!state.dashboardExport) return;
          state.dashboardExport = {
            ...state.dashboardExport,
            status: RD.Error('Error downloading pdf'),
          };
        },
      )
      // Share Data
      .addMatcher(isAnyOf(embedActions.embedFetchShareIdRequest, fetchShareIdRequest), (state) => {
        state.shareData = RD.Loading();
      })
      .addMatcher(
        isAnyOf(embedActions.embedFetchShareIdSuccess, fetchShareIdSuccess),
        (state, { payload }) => {
          state.shareData = RD.Success({
            id: payload.share_id,
            shareLinkUrl: payload.share_link_url,
          });
        },
      )
      .addMatcher(isAnyOf(embedActions.embedFetchShareIdError, fetchShareIdError), (state) => {
        state.shareData = RD.Error('Error loading share link');
      });
  },
});

export const { setChartMenu } = dashboardLayoutSlice.actions;

export const dashboardLayoutReducer = dashboardLayoutSlice.reducer;
