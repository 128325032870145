import { useDispatch } from 'react-redux';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import { Switch } from 'components/ds';
import SettingHeader from '../../DataConfigTab/SettingHeader';

import { updateGeneralFormatOptions } from 'actions/dataPanelConfigActions';
import { LinkFormat, VisualizeOperationGeneralFormatOptions } from 'constants/types';
import { sharedStyles } from './styles';

type Props = {
  inHeader?: boolean;
  generalFormatOptions?: VisualizeOperationGeneralFormatOptions;
};

export default function LinkConfig({ inHeader, generalFormatOptions }: Props) {
  const sharedClasses = sharedStyles();
  const dispatch = useDispatch();
  const linkFormat = generalFormatOptions?.linkFormat;

  const updateGeneralFormat = (updates: LinkFormat) => {
    const newOptions = {
      ...generalFormatOptions,
      linkFormat: { ...generalFormatOptions?.linkFormat, ...updates },
    };
    dispatch(updateGeneralFormatOptions(newOptions));
  };

  return (
    <>
      {inHeader ? (
        <Switch
          useCustomStyles
          className={sharedClasses.configInput}
          label="URL Link"
          onChange={() => updateGeneralFormat({ link: !linkFormat?.link })}
          switchOn={linkFormat?.link}
        />
      ) : (
        <SettingHeader
          name="URL Link"
          rightContent={
            <Switch
              useCustomStyles
              onChange={() => updateGeneralFormat({ link: !linkFormat?.link })}
              switchOn={linkFormat?.link}
            />
          }
        />
      )}
      {linkFormat?.link ? (
        <div className={sharedClasses.root}>
          <InputWithBlurSave
            containerClassName={sharedClasses.configInput}
            helpText="Ensure valid url"
            initialValue={linkFormat?.url}
            label="URL"
            onNewValueSubmitted={(newValue: string) => {
              updateGeneralFormat({ url: newValue });
            }}
          />
          <Switch
            useCustomStyles
            className={sharedClasses.configInput}
            label="Open in New Tab"
            onChange={() => updateGeneralFormat({ openInNewTab: !linkFormat?.openInNewTab })}
            switchOn={linkFormat?.openInNewTab}
          />
        </div>
      ) : null}
    </>
  );
}
