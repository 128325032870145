import cx from 'classnames';
import * as styles from './styles.css';
import { FC, useMemo } from 'react';
import { MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { keyBy, sortBy } from 'utils/standard';

import Button from 'shared/Button';
import { sharedStyles } from './sharedDroppedStyles';

import { DatasetSchema, DatasetColumn } from 'types/datasets';
import { createColOptionsWithIcon } from 'utils/general';

type Props = {
  // If popover has a right divider, then this is a single row dropped column. Otherwise, it is a double row dropped column.
  rightDivider: boolean;
  schema: DatasetSchema;
  onColChange: (newCol: DatasetColumn) => void;
};

export const ColumnOptionsPopover: FC<Props> = ({ rightDivider, schema, onColChange }) => {
  const sharedClasses = sharedStyles();
  const schemaByName = useMemo(() => keyBy(schema, 'name'), [schema]);

  const columnsToSelect = useMemo(() => createColOptionsWithIcon(sortBy(schema, 'name')), [schema]);

  return (
    <Select
      filterable={false}
      itemRenderer={(item) => (
        <MenuItem
          className={sharedClasses.menuItemOption}
          icon={item.icon}
          key={`dropped-column-${item.name}`}
          onClick={() => onColChange?.(schemaByName[item.name])}
          text={item.name}
        />
      )}
      items={columnsToSelect}
      onItemSelect={(item) => onColChange?.(schemaByName[item.name])}
      popoverProps={{
        minimal: true,
        popoverClassName: styles.columnOptionsPopover,
      }}>
      <Button
        compact
        minimal
        className={cx({
          [sharedClasses.iconBtn]: rightDivider,
          [sharedClasses.twoRowsIconBtn]: !rightDivider,
          [sharedClasses.rightDivider]: rightDivider,
        })}
        icon="caret-down"
      />
    </Select>
  );
};
