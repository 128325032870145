import { cloneDeep, entries, pickBy } from 'utils/standard';
import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import {
  clearEmptyPanels,
  handleSecondaryDataError,
  handleSecondaryDataRequest,
  handleSecondaryDataSuccess,
} from './utils';
import { DashboardVersionConfig } from 'types/dashboardVersionConfig';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import {
  EMPTY_FILTER_CONFIG,
  V2_VIZ_INSTRUCTION_TYPE,
  VIZ_OPS_WITH_CATEGORY_SELECT_DRILLDOWN,
} from 'constants/dataConstants';
import {
  OPERATION_TYPES,
  VisualizeTableInstructions,
  V2TwoDimensionChartInstructions,
  V2KPIChartInstructions,
  V2BoxPlotInstructions,
  V2ScatterPlotInstructions,
  V2KPITrendInstructions,
  VisualizePivotTableInstructions,
  GROUPED_STACKED_OPERATION_TYPES,
  VisualizeCollapsibleListInstructions,
  V2TrendTableInstructions,
} from 'constants/types';
import {
  switchCurrentlyEditingDashboardVersion,
  publishNewDashboardVersionSuccess,
  clearDashboardConfigReducer,
  setDpLoading,
  createDashboardTemplateDataPanelV2,
  duplicateDashboardItem,
  deleteDataPanelV2,
  createDashboardElement,
  updateElementConfig,
  deleteDashboardElementV2,
  saveDashboardElementUpdates,
  updateDashboardTemplateParams,
  renameDataPanelV2,
  createDashboardDatasetV2,
  revertToDashboardVersionSuccess,
  toggleElementVisibilityForSecondaryLayout,
  toggleFilterLink,
  updateDashboardPageLayoutConfig,
  updateElementLocation,
  updateDashboardHeaderElementOrder,
  updateElementContainerLocation,
} from 'actions/dashboardV2Actions';
import {
  fetchDataPanelError,
  fetchDataPanelRequest,
  fetchDataPanelRowCountSuccess,
  fetchDataPanelRowCountError,
  fetchDataPanelSuccess,
  fetchSecondaryDataError,
  fetchSecondaryDataRequest,
  fetchSecondaryDataSuccess,
  updateAdHocOperationInstructions,
  updateDrilldownDataPanel,
} from 'actions/dataPanelTemplateAction';
import {
  UpdateVisualizeOperationPayload,
  selectDashboardDataPanelToEdit,
  updateSelectedChart,
  updateVisualizeOperationAction,
  createFilterClause,
  deleteFilterClause,
  selectFilterColumn,
  selectFilterOperator,
  updateFilterValue,
  updateFilterMatch,
  updateFilterValueSource,
  updateFilterValueVariable,
  createSortClause,
  deleteSortClause,
  selectSortColumn,
  selectSortOrder,
  createPivotedOnCol,
  deletePivotedOnCol,
  updatePivotedOnCol,
  createAggregation,
  deleteAggregation,
  updateAggregation,
  updateGeneralFormatOptions,
} from 'actions/dataPanelConfigActions';
import {
  BASE_CONFIG_BY_DASH_ELEM,
  DRAGGING_ITEM_CONFIG_BY_TYPE,
  EMPTY_DATA_PANEL_STATE,
} from 'constants/dashboardConstants';
import {
  isDataPanelConfigReady,
  shouldRecomputeDataForDataPanel,
  getDatapanelConfigReducerState,
} from 'utils/dataPanelConfigUtils';

import {
  EMPTY_FILTER_CLAUSE,
  EMPTY_PIVOTED_ON_COL,
  EMPTY_AGGREGATION,
  EMPTY_SORT_CLAUSE,
} from 'constants/dataPanelEditorConstants';
import {
  updateUserInputFieldsWithNewElemName,
  updateUserInputFieldsWithDeletedElem,
  newOperatorShouldClearSelectedVariable,
  newOperatorDoesntHaveVariableOption,
  getLayoutFromDashboardVersionConfig,
  removeElemFromStickyHeader,
} from 'utils/dashboardUtils';
import * as layoutUtils from 'utils/layoutUtils';
import * as namingUtils from 'utils/naming';
import {
  ContainerElemConfig,
  DashboardElement,
  DASHBOARD_ELEMENT_TYPES,
  DASHBOARD_LAYOUT_CONFIG,
  VIEW_MODE,
} from 'types/dashboardTypes';
import {
  fetchDashboardTemplateRequest,
  fetchDashboardTemplateSuccess,
} from 'actions/dashboardTemplateActions';
import {
  fetchDashboardDatasetPreviewSuccess,
  fetchEditorDatasetPreviewError,
  fetchEditorDatasetPreviewRequest,
  fetchEditorDatasetPreviewSuccess,
  fetchEditorDatasetRowCountSuccess,
  fetchEditorDatasetRowCountError,
  saveDatasetQuery,
  saveDraftDatasetQuery,
  deleteDataset,
  editDatasetName,
  updateDatasetDrilldownColConfig,
  updateDatasetDrilldownColConfigs,
  updateDashboardDatasetSchema,
} from 'actions/datasetActions';
import { FetchDashboardDatasetPreviewData } from 'actions/responseTypes';
import {
  updateDashboardEmailLayout,
  updateDashboardEmailText,
  updateDashboardMobileLayout,
  updateDashboardPdfLayout,
  updateDashboardTemplateLayout,
} from 'actions/layoutActions';
import { VersionInfo } from 'types/exploResource';
import {
  addElementToLayout,
  getElementsById,
  removeElementsFromLayoutById,
} from 'utils/layoutResolverUtil';
import { deleteVariables, saveResourceConfig } from 'utils/customEventUtils';
import { fetchDatasetSuccessDrilldownConfig, initConfig } from 'utils/drilldownDatasetUtils';
import { isValidOperationForFilter } from 'utils/filterOperations';
import { removeDataPanelsFromLinks, removeDatasetFromLinks } from 'utils/filterLinking';
import { saveExploreDraft } from './thunks/resourceSaveThunks';

export const DRILLDOWN_DATA_PANEL_ID = '_drilldown_data_panel';

interface DashboardEditConfigReducerState {
  currentEditingDptId?: string;
  config?: DashboardVersionConfig;
  versionInfo?: VersionInfo;
}

const initialState: DashboardEditConfigReducerState = {};

function actionWithEditingDPT(
  state: DashboardEditConfigReducerState,
  actionFn: (editingDPT: DataPanelTemplate) => void,
) {
  if (!state.config) return;
  const editingDPT =
    state.currentEditingDptId && state.config.data_panels[state.currentEditingDptId];

  if (!editingDPT) return;
  actionFn(editingDPT);
  dashboardUpdated();
}

function updateDataPanel(
  state: DashboardEditConfigReducerState,
  dpId: string,
  updateFunc: (dp: DataPanelTemplate) => void,
): void {
  if (!state.config) return;
  const dataPanel = state.config.data_panels[dpId];
  if (dataPanel) updateFunc(dataPanel);
}

const dashboardEditConfigSlice = createSlice({
  name: 'dashboardEditConfig',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(switchCurrentlyEditingDashboardVersion, (state, { payload }) => {
        const { dashboardVersion } = payload;
        if (dashboardVersion.version_number === state.versionInfo?.version_number) return;
        state.config = dashboardVersion.configuration;
        state.versionInfo = {
          is_draft: dashboardVersion.is_draft,
          version_number: dashboardVersion.version_number,
          edit_version_number: dashboardVersion.edit_version_number,
          change_comments: dashboardVersion.change_comments,
        };
      })
      .addCase(revertToDashboardVersionSuccess, (state, { payload }) => {
        const { dashboard_version } = payload;
        state.config = dashboard_version.configuration;
        state.versionInfo = {
          is_draft: dashboard_version.is_draft,
          version_number: dashboard_version.version_number,
          edit_version_number: dashboard_version.edit_version_number,
          change_comments: dashboard_version.change_comments,
        };
      })
      .addCase(publishNewDashboardVersionSuccess, (state, { payload }) => {
        const { dashboard_version } = payload;
        state.versionInfo = {
          is_draft: dashboard_version.is_draft,
          version_number: dashboard_version.version_number,
          edit_version_number: dashboard_version.edit_version_number,
          change_comments: dashboard_version.change_comments,
        };
      })
      .addCase(saveExploreDraft.fulfilled, (state, { payload }) => {
        if (state.versionInfo) state.versionInfo.edit_version_number = payload.edit_version_number;
      })
      // Data Panel Reducers
      .addCase(clearDashboardConfigReducer, (state) => {
        state.config = undefined;
        state.versionInfo = undefined;
      })
      .addCase(fetchDashboardTemplateRequest, (state) => {
        state.config = undefined;
        state.versionInfo = undefined;
      })
      .addCase(fetchDashboardTemplateSuccess, (state, { payload }) => {
        state.config = payload.dashboard_version.configuration;
        if (state.config) clearEmptyPanels(state.config);

        state.versionInfo = {
          is_draft: payload.dashboard_version.is_draft,
          version_number: payload.dashboard_version.version_number,
          edit_version_number: payload.dashboard_version.edit_version_number,
          change_comments: payload.dashboard_version.change_comments,
        };
      })
      .addCase(fetchDataPanelRequest, (state, { payload }) => {
        updateDataPanel(state, payload.postData.id, (panel) => {
          panel._loading = true;
          if (payload.postData) {
            panel._adHocOperationInstructions = {
              ...panel._adHocOperationInstructions,
              currentPage: payload.postData.page_number || 1,
              sortInfo: payload.postData.sort_info,
              filterInfo: payload.postData.filter_info || { ...EMPTY_FILTER_CONFIG },
            };
          }
        });
      })
      .addCase(fetchDataPanelSuccess, (state, { payload }) => {
        updateDataPanel(state, payload.postData.id, (panel) => {
          panel._loading = false;
          panel._rows = payload.data_panel_template._rows;
          panel._schema = payload.data_panel_template._schema;
          panel._source_type = payload.data_panel_template._source_type;
          panel._unsupported_operations = payload.data_panel_template._unsupported_operations;
          panel._query_information = payload.query_information;
          panel._error = undefined;
        });
      })
      .addCase(fetchDataPanelError, (state, { payload }) => {
        updateDataPanel(state, payload.postData.id, (panel) => {
          panel._loading = false;
          panel._error = payload.error_msg ?? 'There was an error fetching the results';
          panel._query_information = payload.query_information;
        });
      })
      .addCase(fetchSecondaryDataRequest, (state, { payload }) => {
        updateDataPanel(state, payload.postData.id, handleSecondaryDataRequest);
      })
      .addCase(fetchSecondaryDataSuccess, (state, { payload }) => {
        updateDataPanel(state, payload.postData.id, (panel) =>
          handleSecondaryDataSuccess(panel, payload.data_panel_template),
        );
      })
      .addCase(fetchSecondaryDataError, (state, { payload }) => {
        updateDataPanel(state, payload.postData.id, handleSecondaryDataError);
      })
      .addCase(fetchDataPanelRowCountSuccess, (state, { payload }) => {
        updateDataPanel(state, payload.postData.id, (panel) => {
          panel._total_row_count = payload._total_row_count;
        });
      })
      .addCase(fetchDataPanelRowCountError, (state, { payload }) => {
        updateDataPanel(state, payload.postData.id, (panel) => {
          panel._total_row_count = undefined;
        });
      })
      .addCase(setDpLoading, (state, { payload }) => {
        if (!state.config) return;
        payload.ids.forEach((id) => {
          if (state.config?.data_panels[id])
            state.config.data_panels[id]._loading = payload.loading;
        });
      })
      .addCase(createDashboardTemplateDataPanelV2, (state, { payload }) => {
        if (!state.config) return;

        const newDataPanel = EMPTY_DATA_PANEL_STATE(
          payload.id,
          payload.datasetId,
          payload.vizType,
          namingUtils.getDefaultPanelProvidedId(payload.vizType, state.config),
          payload.containerId,
          payload.name,
        );
        state.config.data_panels[newDataPanel.id] = newDataPanel;
        state.currentEditingDptId = newDataPanel.id;

        layoutUtils.addItemToConfigLayouts(state.config, payload, newDataPanel.id);
        dashboardUpdated();
      })
      .addCase(duplicateDashboardItem, (state, { payload }) => {
        if (!state.config) return;
        const { dashboardItem, itemType, dashId } = payload;
        const config = state.config;

        // either the whole dashboard or the container that holds this element
        let layout = config.dashboard_layout;

        if (dashboardItem.container_id) {
          const containerConfig = config.elements[dashboardItem.container_id]
            .config as ContainerElemConfig;

          // if we're in a container, then we want to be editing the container's layout
          layout = containerConfig.layout;
        }

        const elem = layout.find((item) => item.i === dashboardItem.id);
        if (!elem) return;

        const newElementConfig = cloneDeep(dashboardItem);

        const newElemId = layoutUtils.placeDuplicatedElementInLayout({
          newElementLayout: cloneDeep(elem),
          newElementConfig,
          layout: cloneDeep(layout),
          config,
          // place the cloned item at the bottom of the column that contains the parent element
          yStart: elem.y + elem.h - 1,
          dashId,
        });

        if (itemType === DASHBOARD_ELEMENT_TYPES.DATA_PANEL) {
          const dataPanel = dashboardItem as DataPanelTemplate;
          Object.values(config.elements).forEach(({ config }) => {
            const dataPanelsLinked = config.datasetLinks?.[dataPanel.table_id]?.dataPanels;
            if (dataPanelsLinked?.includes(dataPanel.id)) {
              dataPanelsLinked.push(newElemId);
            }
          });
        }

        if (itemType === DASHBOARD_ELEMENT_TYPES.CONTAINER) {
          // for container elements, we have to clone all of the contained elements
          const newContainerConfig = (newElementConfig as DashboardElement)
            .config as ContainerElemConfig;
          // clone this so we can iterate over it
          const oldLayout = cloneDeep(newContainerConfig.layout);
          // but clear the layout so that we can add to it fresh
          newContainerConfig.layout = [];

          oldLayout.forEach((newContainerElementLayout) => {
            // we have to clone deep here so that only the duplicated panel gets the new container_id below
            const newContainerElementConfig = cloneDeep(
              newContainerElementLayout.i in config.data_panels
                ? config.data_panels[newContainerElementLayout.i]
                : config.elements[newContainerElementLayout.i],
            );

            // we have to manually swap the container id over to our new duped container
            newContainerElementConfig.container_id = newElementConfig.id;
            layoutUtils.placeDuplicatedElementInLayout({
              newElementLayout: newContainerElementLayout,
              newElementConfig: newContainerElementConfig,
              layout: newContainerConfig.layout,
              config,
              yStart: newContainerElementLayout.y,
              dashId: dashId,
            });
          });
        }
        dashboardUpdated();
      })
      .addCase(updateAdHocOperationInstructions, (state, { payload }) => {
        updateDataPanel(state, payload.dataPanelId, (panel) => {
          panel._adHocOperationInstructions = payload.adHocOperationInstructions;
        });
      })
      // Dataset Editor Reducers
      .addCase(fetchEditorDatasetPreviewRequest, (state, { payload }) => {
        if (!state.config) return;

        const fetchingDataset = state.config.datasets[payload.postData.dataset_id];
        if (fetchingDataset) {
          fetchingDataset._schema = undefined;
          fetchingDataset._rows = undefined;
          fetchingDataset._total_row_count = undefined;
          fetchingDataset._error = undefined;
          fetchingDataset._loading = true;
          fetchingDataset._query_information = undefined;
        }
      })
      .addCase(fetchEditorDatasetPreviewSuccess, (state, { payload }) => {
        fetchDatasetDataSuccess(state, payload.postData.dataset_id, payload);
      })
      .addCase(fetchEditorDatasetPreviewError, (state, { payload }) => {
        if (!state.config) return;

        const fetchingDataset = state.config.datasets[payload.postData.dataset_id];
        if (fetchingDataset) {
          fetchingDataset._error = payload.error_msg ?? 'Internal Error';
          fetchingDataset._loading = false;
          fetchingDataset._query_information = payload.query_information;
        }
      })
      .addCase(fetchEditorDatasetRowCountSuccess, (state, { payload }) => {
        if (!state.config) return;

        const fetchingDataset = state.config.datasets[payload.postData.dataset_id];
        if (fetchingDataset) fetchingDataset._total_row_count = payload._total_row_count;
      })
      .addCase(fetchEditorDatasetRowCountError, (state, { payload }) => {
        if (!state.config) return;

        const fetchingDataset = state.config.datasets[payload.postData.dataset_id];
        if (fetchingDataset) fetchingDataset._total_row_count = undefined;
      })
      .addCase(saveDatasetQuery, (state, { payload }) => {
        if (!state.config) return;
        state.config.datasets[payload.dataset_id].query = payload.query;
        state.config.datasets[payload.dataset_id].queryDraft = undefined;
        state.config.datasets[payload.dataset_id].schema = payload.schema;
        dashboardUpdated();
      })
      .addCase(saveDraftDatasetQuery, (state, { payload }) => {
        if (!state.config) return;
        state.config.datasets[payload.dataset_id].queryDraft = payload.queryDraft;
        dashboardUpdated();
      })
      .addCase(fetchDashboardDatasetPreviewSuccess, (state, { payload }) => {
        if (payload.postData.dataset_id) {
          fetchDatasetDataSuccess(state, payload.postData.dataset_id, payload);
        }
      })
      .addCase(createDashboardDatasetV2, (state, { payload }) => {
        if (!state.config) return;

        const newDataset = {
          id: `dash${payload.dashId}-${uuidv4()}`,
          table_name: payload.name,
          _is_new: true,
          parent_schema_id: payload.parentSchemaId,
          query: '',
          drilldownColumnConfigs: {},
        };
        state.config.datasets[newDataset.id] = newDataset;
        dashboardUpdated();
      })
      .addCase(updateDashboardDatasetSchema, (state, { payload }) => {
        if (!state.config) return;

        state.config.datasets[payload.datasetId].parent_schema_id = payload.newParentSchemaId;
        dashboardUpdated();
      })
      .addCase(deleteDataset, (state, { payload }) => {
        if (!state.config) return;
        delete state.config.datasets[payload.datasetId];

        removeDatasetFromLinks(payload.datasetId, state.config.elements);
        dashboardUpdated();
      })
      .addCase(editDatasetName, (state, { payload }) => {
        if (!state.config) return;
        state.config.datasets[payload.datasetId].table_name = payload.name;
        dashboardUpdated();
      })
      .addCase(updateDatasetDrilldownColConfig, (state, { payload }) => {
        if (!state.config) return;
        const dataset = state.config.datasets[payload.datasetId];
        if (!dataset.drilldownColumnConfigs) {
          dataset.drilldownColumnConfigs = {};
        }

        if (!dataset.drilldownColumnConfigs[payload.colName]) {
          if (payload.displayName === undefined || payload.index === undefined) return;
          dataset.drilldownColumnConfigs[payload.colName] = initConfig(
            payload.index,
            payload.displayName,
          );
        }

        const colConfig = dataset.drilldownColumnConfigs[payload.colName];

        if (payload.displayName !== undefined) colConfig.displayName = payload.displayName;
        if (payload.index !== undefined) colConfig.index = payload.index;
        if (payload.isIncluded !== undefined) colConfig.isIncluded = payload.isIncluded;
        if (payload.isVisible !== undefined) colConfig.isVisible = payload.isVisible;
        if (payload.displayFormatting !== undefined)
          colConfig.displayFormatting = payload.displayFormatting;

        dashboardUpdated();
      })
      .addCase(updateDatasetDrilldownColConfigs, (state, { payload }) => {
        if (!state.config) return;

        state.config.datasets[payload.datasetId].drilldownColumnConfigs = payload.newConfigs;
        dashboardUpdated();
      })
      // Edit Dashboard Reducers
      .addCase(updateDashboardTemplateLayout, (state, { payload }) => {
        if (!state.config) return;

        state.config.dashboard_layout = cloneDeep(payload);
        dashboardUpdated();
      })
      .addCase(updateDashboardPdfLayout, (state, { payload }) => {
        if (!state.config) return;
        state.config.pdf_layout = cloneDeep(payload);
        dashboardUpdated();
      })
      .addCase(updateDashboardEmailLayout, (state, { payload }) => {
        if (!state.config) return;
        state.config.email_layout = cloneDeep(payload);
        dashboardUpdated();
      })
      .addCase(updateDashboardMobileLayout, (state, { payload }) => {
        if (!state.config) return;
        state.config.mobile_layout = cloneDeep(payload);
        dashboardUpdated();
      })
      .addCase(updateDashboardEmailText, (state, { payload }) => {
        if (!state.config) return;

        if (payload.isHeader) {
          state.config.email_header_html = payload.html;
        } else {
          state.config.email_footer_html = payload.html;
        }
        dashboardUpdated();
      })
      .addCase(deleteDataPanelV2, (state, { payload }) => {
        if (!state.config) return;
        delete state.config.data_panels[payload.id];
        layoutUtils.updateSecondaryLayoutsAfterMainLayoutChange(state.config);

        removeDataPanelsFromLinks([payload.id], state.config.elements);

        dashboardUpdated();
      })
      .addCase(createDashboardElement, (state, { payload }) => {
        if (!state.config) return;
        const newElement = {
          id: payload.id,
          name: namingUtils.getDefaultElementName(payload.elementType, state.config),
          element_type: payload.elementType,
          config: payload.initialConfig,
          container_id: payload.containerId,
        };

        state.config.elements[newElement.id] = newElement;

        layoutUtils.addItemToConfigLayouts(state.config, payload, newElement.id);
        dashboardUpdated();
      })
      .addCase(updateElementConfig, (state, { payload }) => {
        const element = state.config?.elements[payload.elementId];
        if (!element) return;

        if (payload.config) element.config = payload.config;
        if (payload.newElementType) {
          if (
            element.config.operator &&
            !isValidOperationForFilter(element.config.operator, payload.newElementType)
          ) {
            element.config.operator = undefined;
          }

          element.element_type = payload.newElementType;
        }
        dashboardUpdated();
      })
      .addCase(deleteDashboardElementV2, (state, { payload }) => {
        if (!state.config) return;
        const { elementType, elementId } = payload;

        const elementNames: string[] = [];
        const idsToUnlink: string[] = [];
        if (elementType === DASHBOARD_ELEMENT_TYPES.CONTAINER) {
          state.config.elements = pickBy(state.config.elements, (elem) => {
            if (elem.id === elementId || elem.container_id === elementId) {
              elementNames.push(elem.name);
              return false;
            }
            return true;
          });
          state.config.data_panels = pickBy(state.config.data_panels, (panel) => {
            if (panel.container_id === elementId) {
              idsToUnlink.push(panel.id);
              return false;
            }
            return true;
          });
        } else {
          elementNames.push(state.config.elements[elementId].name);
          delete state.config.elements[elementId];

          removeElemFromStickyHeader(state.config.dashboard_page_layout_config, elementId);
        }

        removeDataPanelsFromLinks(idsToUnlink, state.config.elements);

        layoutUtils.updateSecondaryLayoutsAfterMainLayoutChange(state.config);
        updateUserInputFieldsWithDeletedElem(state.config, elementNames);
        dashboardUpdated();
      })
      .addCase(saveDashboardElementUpdates, (state, { payload }) => {
        if (payload.name && state.config) {
          const oldName = state.config.elements[payload.id].name;
          state.config.elements[payload.id].name = cloneDeep(payload.name);
          updateUserInputFieldsWithNewElemName(state.config, oldName, payload.name);
          dashboardUpdated();
        }
      })
      .addCase(updateDashboardTemplateParams, (state, { payload }) => {
        if (!state.config) return;
        const deletedVariables: string[] = [];
        const renamedVariables: [string, string][] = [];
        entries(state.config.params).forEach(([key, val]) => {
          const newVar = payload.newParams[key];
          if (!newVar) deletedVariables.push(val.name);
          else if (newVar.name !== val.name) {
            renamedVariables.push([val.name, newVar.name]);
          }
        });

        state.config.params = cloneDeep(payload.newParams);
        deleteVariables(deletedVariables);

        if (renamedVariables.length > 0) {
          window.dispatchEvent(
            new CustomEvent('renameDashboardVariables', {
              detail: { renames: renamedVariables },
            }),
          );
        }
        dashboardUpdated();
      })
      .addCase(selectDashboardDataPanelToEdit, (state, { payload }) => {
        state.currentEditingDptId = payload.id;
      })
      .addCase(renameDataPanelV2, (state, { payload }) => {
        if (!state.config) return;
        if (payload.providedId)
          state.config.data_panels[payload.id].provided_id = payload.providedId;

        dashboardUpdated();
      })
      .addCase(updateVisualizeOperationAction, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDpt) => {
          updateVisualizeOperation_(editingDpt, payload);
        });
      })
      .addCase(updateSelectedChart, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDpt) => {
          updateSelectedChart_(editingDpt, payload);
        });
      })
      .addCase(createFilterClause, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDPT) => {
          if (payload) {
            const clause = cloneDeep(EMPTY_FILTER_CLAUSE);
            clause.filterColumn = payload;
            editingDPT.filter_op.instructions.filterClauses.push(clause);
          } else {
            editingDPT.filter_op.instructions.filterClauses.push(EMPTY_FILTER_CLAUSE);
          }
        });
      })
      .addCase(deleteFilterClause, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDPT) => {
          editingDPT.filter_op.instructions.filterClauses.splice(payload, 1);
        });
      })
      .addCase(selectFilterColumn, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDPT) => {
          const oldColumn =
            editingDPT.filter_op.instructions.filterClauses[payload.index].filterColumn;
          if (oldColumn && oldColumn.type !== payload.column.type) {
            editingDPT.filter_op.instructions.filterClauses[payload.index].filterOperation =
              undefined;
            editingDPT.filter_op.instructions.filterClauses[payload.index].filterValue = undefined;
          }
          editingDPT.filter_op.instructions.filterClauses[payload.index].filterColumn =
            payload.column;
        });
      })
      .addCase(selectFilterOperator, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDPT) => {
          if (
            newOperatorShouldClearSelectedVariable(
              payload.operator,
              editingDPT.filter_op.instructions.filterClauses[payload.index].filterOperation?.id,
            )
          ) {
            editingDPT.filter_op.instructions.filterClauses[payload.index].filterValueVariableId =
              undefined;
            editingDPT.filter_op.instructions.filterClauses[
              payload.index
            ].filterValueVariableProperty = undefined;
          }

          if (newOperatorDoesntHaveVariableOption(payload.operator)) {
            editingDPT.filter_op.instructions.filterClauses[payload.index].filterValueSource =
              undefined;
          }

          editingDPT.filter_op.instructions.filterClauses[payload.index].filterOperation = {
            id: payload.operator,
          };
        });
      })
      .addCase(updateFilterValue, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDPT) => {
          editingDPT.filter_op.instructions.filterClauses[payload.index].filterValue =
            payload.value;
        });
      })
      .addCase(updateFilterMatch, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDPT) => {
          editingDPT.filter_op.instructions.matchOnAll = payload;
        });
      })
      .addCase(updateFilterValueSource, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDPT) => {
          editingDPT.filter_op.instructions.filterClauses[payload.index].filterValueSource =
            payload.newSource;
        });
      })
      .addCase(updateFilterValueVariable, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDPT) => {
          const filterClause = editingDPT.filter_op.instructions.filterClauses[payload.index];
          if (!filterClause) return;
          filterClause.filterValueVariableId = payload.variableId;
          filterClause.filterValueVariableProperty = payload.property;
        });
      })
      .addCase(createSortClause, (state) => {
        actionWithEditingDPT(state, (editingDPT) => {
          editingDPT.sort_op.instructions.sortColumns.push(EMPTY_SORT_CLAUSE);
        });
      })
      .addCase(deleteSortClause, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDPT) => {
          editingDPT.sort_op.instructions.sortColumns.splice(payload, 1);
        });
      })
      .addCase(selectSortColumn, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDPT) => {
          editingDPT.sort_op.instructions.sortColumns[payload.index].column = payload.column;
        });
      })
      .addCase(selectSortOrder, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDPT) => {
          editingDPT.sort_op.instructions.sortColumns[payload.index].order = payload.order;
        });
      })
      .addCase(createPivotedOnCol, (state) => {
        actionWithEditingDPT(state, (editingDPT) => {
          editingDPT.group_by_op.instructions.pivotedOnCols.push(EMPTY_PIVOTED_ON_COL);
        });
      })
      .addCase(deletePivotedOnCol, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDPT) => {
          editingDPT.group_by_op.instructions.pivotedOnCols.splice(payload, 1);
        });
      })
      .addCase(updatePivotedOnCol, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDPT) => {
          editingDPT.group_by_op.instructions.pivotedOnCols[payload.index] =
            payload.aggregationColumnInfo;
        });
      })
      .addCase(createAggregation, (state) => {
        actionWithEditingDPT(state, (editingDPT) => {
          editingDPT.group_by_op.instructions.aggregations.push(EMPTY_AGGREGATION);
        });
      })
      .addCase(deleteAggregation, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDPT) => {
          editingDPT.group_by_op.instructions.aggregations.splice(payload, 1);
        });
      })
      .addCase(updateAggregation, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDPT) => {
          editingDPT.group_by_op.instructions.aggregations[payload.index] =
            payload.pivotOperationAggregation;
        });
      })
      .addCase(updateGeneralFormatOptions, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDPT) => {
          editingDPT.visualize_op.generalFormatOptions = payload;
        });
      })
      .addCase(updateDrilldownDataPanel, (state, { payload }) => {
        if (!state.config) return;
        payload.dataPanel.id = DRILLDOWN_DATA_PANEL_ID;
        state.config.data_panels[DRILLDOWN_DATA_PANEL_ID] = payload.dataPanel as DataPanelTemplate;
      })
      .addCase(toggleElementVisibilityForSecondaryLayout, (state, { payload }) => {
        if (!state.config) return;

        if (!state.config.layout_exclusions)
          state.config.layout_exclusions = {
            pdfExclusions: {},
            emailExclusions: {},
            mobileExclusions: {},
          };

        const layoutExclusions = state.config.layout_exclusions;
        let exclusionMap;

        switch (payload.layoutType) {
          case VIEW_MODE.PDF:
            exclusionMap = layoutExclusions.pdfExclusions;
            break;
          case VIEW_MODE.MOBILE:
            exclusionMap = layoutExclusions.mobileExclusions;
            break;
          case VIEW_MODE.EMAIL:
            exclusionMap = layoutExclusions.emailExclusions;
            break;
        }

        if (!exclusionMap) return;

        let newLayout = getLayoutFromDashboardVersionConfig(state.config, payload.layoutType);

        if (payload.isExcluded) {
          exclusionMap[payload.id] = payload.isExcluded;

          newLayout = removeElementsFromLayoutById(newLayout, new Set([payload.id]));
        } else {
          delete exclusionMap[payload.id];

          const elementToAdd = getElementsById(
            state.config.dashboard_layout,
            new Set([payload.id]),
          );
          if (elementToAdd.length === 1) {
            newLayout = addElementToLayout(
              newLayout,
              elementToAdd[0],
              payload.layoutType === VIEW_MODE.MOBILE,
            );
          }
        }

        switch (payload.layoutType) {
          case VIEW_MODE.PDF:
            state.config.pdf_layout = newLayout;
            break;
          case VIEW_MODE.MOBILE:
            state.config.mobile_layout = newLayout;
            break;
          case VIEW_MODE.EMAIL:
            state.config.email_layout = newLayout;
            break;
        }
      })
      .addCase(toggleFilterLink, (state, { payload }) => {
        const dp = state.config?.data_panels?.[payload.dataPanelId];
        const elem = state.config?.elements?.[payload.elementId];
        if (!elem || !dp) return;

        const datasetLinks = elem.config.datasetLinks?.[dp.table_id];
        if (!datasetLinks) return;
        if (!datasetLinks.dataPanels) datasetLinks.dataPanels = [];

        const dpIdx = datasetLinks.dataPanels.findIndex((id) => id === dp.id);
        if (dpIdx === -1) {
          datasetLinks.dataPanels.push(dp.id);
        } else {
          datasetLinks.dataPanels.splice(dpIdx, 1);
        }
        dashboardUpdated();
      })
      .addCase(updateDashboardPageLayoutConfig, (state, { payload }) => {
        if (!state.config) return;

        const addInitialExportElem =
          !state.config.dashboard_page_layout_config?.stickyHeader &&
          payload.config.stickyHeader?.enabled;

        state.config.dashboard_page_layout_config = payload.config;

        if (addInitialExportElem && state.config.dashboard_page_layout_config.stickyHeader) {
          const exportId = `dash${payload.dashboardId}-${uuidv4()}`;

          const exportButtonConfig = {
            id: exportId,
            name: namingUtils.getDefaultElementName(DASHBOARD_ELEMENT_TYPES.EXPORT, state.config),
            element_type: DASHBOARD_ELEMENT_TYPES.EXPORT,
            config: { ...BASE_CONFIG_BY_DASH_ELEM[DASHBOARD_ELEMENT_TYPES.EXPORT] },
            elemLocation: DASHBOARD_LAYOUT_CONFIG.HEADER,
          };

          state.config.elements[exportId] = exportButtonConfig;
          state.config.dashboard_page_layout_config.stickyHeader.headerContentOrder = [exportId];
        }

        dashboardUpdated();
      })
      .addCase(updateDashboardHeaderElementOrder, (state, { payload }) => {
        if (!state.config?.dashboard_page_layout_config?.stickyHeader) return;

        state.config.dashboard_page_layout_config.stickyHeader.headerContentOrder =
          payload.newOrder;

        dashboardUpdated();
      })
      .addCase(updateElementLocation, (state, { payload }) => {
        if (!state.config) return;
        const element = state.config?.elements[payload.elementId];
        if (!element) return;

        if (!state.config.dashboard_page_layout_config)
          state.config.dashboard_page_layout_config = {};

        const layoutConfig = state.config.dashboard_page_layout_config;
        if (!layoutConfig.stickyHeader) layoutConfig.stickyHeader = {};
        if (!layoutConfig.stickyHeader.headerContentOrder)
          layoutConfig.stickyHeader.headerContentOrder = [];

        if (
          (!element.elemLocation ||
            element.elemLocation === DASHBOARD_LAYOUT_CONFIG.DASHBOARD_BODY) &&
          payload.newLocation === DASHBOARD_LAYOUT_CONFIG.HEADER
        ) {
          layoutConfig.stickyHeader.headerContentOrder.push(payload.elementId);
          state.config.elements[payload.elementId].container_id = undefined;
        } else if (
          element.elemLocation === DASHBOARD_LAYOUT_CONFIG.HEADER &&
          payload.newLocation === DASHBOARD_LAYOUT_CONFIG.DASHBOARD_BODY
        ) {
          const elemWasRemoved = removeElemFromStickyHeader(layoutConfig, payload.elementId);
          if (elemWasRemoved) {
            const { w, h } = DRAGGING_ITEM_CONFIG_BY_TYPE[element.element_type];
            state.config.dashboard_layout.unshift({
              i: element.id,
              x: 0,
              y: 0,
              w,
              h,
            });

            layoutUtils.addItemToConfigLayouts(
              state.config,
              { newLayout: state.config.dashboard_layout },
              element.id,
            );
          }
        }

        element.elemLocation = payload.newLocation;

        dashboardUpdated();
      })
      .addCase(updateElementContainerLocation, (state, { payload }) => {
        if (!state.config) return;

        const element: { id: string; container_id?: string } = payload.isDataPanel
          ? state.config.data_panels[payload.elementId]
          : state.config.elements[payload.elementId];

        if (payload.removeElem) {
          layoutUtils.moveElementFromContainerToBody(state.config, payload.containerId, element);
        } else {
          layoutUtils.moveElementIntoContainer(state.config, payload.containerId, element);
        }

        dashboardUpdated();
      });
  },
});

export const dashboardEditConfigReducer = dashboardEditConfigSlice.reducer;

function dashboardUpdated() {
  saveResourceConfig();
}

const updateVisualizeOperation_ = (
  editingDPT: DataPanelTemplate,
  payload: UpdateVisualizeOperationPayload,
) => {
  const oldDPT = cloneDeep(editingDPT);

  if (editingDPT && editingDPT.visualize_op.instructions) {
    if (payload.operationType === OPERATION_TYPES.VISUALIZE_TABLE) {
      editingDPT.visualize_op.instructions.VISUALIZE_TABLE =
        payload.visualizeInstructions as VisualizeTableInstructions;
    } else if (
      payload.operationType === OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2 ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2 ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2 ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2 ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2 ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2 ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2 ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2 ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_PIE_CHART_V2 ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2 ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_LINE_CHART_V2 ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_AREA_CHART_V2 ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2 ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2 ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2 ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_FUNNEL_V2 ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2 ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_SPIDER_CHART ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_MAP_V2
    ) {
      editingDPT.visualize_op.instructions.V2_TWO_DIMENSION_CHART =
        payload.visualizeInstructions as V2TwoDimensionChartInstructions;
    } else if (
      payload.operationType === OPERATION_TYPES.VISUALIZE_NUMBER_V2 ||
      payload.operationType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2
    ) {
      editingDPT.visualize_op.instructions.V2_KPI =
        payload.visualizeInstructions as V2KPIChartInstructions;
    } else if (payload.operationType === OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2) {
      editingDPT.visualize_op.instructions.V2_BOX_PLOT =
        payload.visualizeInstructions as V2BoxPlotInstructions;
    } else if (payload.operationType === OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2) {
      editingDPT.visualize_op.instructions.V2_SCATTER_PLOT =
        payload.visualizeInstructions as V2ScatterPlotInstructions;
    } else if (payload.operationType === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2) {
      editingDPT.visualize_op.instructions.V2_KPI_TREND =
        payload.visualizeInstructions as V2KPITrendInstructions;
    } else if (payload.operationType === OPERATION_TYPES.VISUALIZE_TREND_TABLE) {
      editingDPT.visualize_op.instructions.V2_TREND_TABLE =
        payload.visualizeInstructions as V2TrendTableInstructions;
    } else if (payload.operationType === OPERATION_TYPES.VISUALIZE_PIVOT_TABLE) {
      editingDPT.visualize_op.instructions.VISUALIZE_PIVOT_TABLE =
        payload.visualizeInstructions as VisualizePivotTableInstructions;
    } else if (payload.operationType === OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST) {
      editingDPT.visualize_op.instructions.VISUALIZE_COLLAPSIBLE_LIST =
        payload.visualizeInstructions as VisualizeCollapsibleListInstructions;
    }

    if (oldDPT) {
      const oldConfigReducerState = getDatapanelConfigReducerState(oldDPT);
      const editingConfigReducerState = getDatapanelConfigReducerState(editingDPT);

      // If the updated instructions necessitates a recompute, we should update the DPT state accordingly
      // to inidicate that it is loading
      const shouldRecompute = shouldRecomputeDataForDataPanel(
        oldConfigReducerState,
        editingConfigReducerState,
      );

      editingDPT._loading = shouldRecompute;
    }
  }
};

const updateSelectedChart_ = (editingDPT: DataPanelTemplate, payload: OPERATION_TYPES) => {
  if (
    VIZ_OPS_WITH_CATEGORY_SELECT_DRILLDOWN.has(editingDPT.visualize_op.operation_type) &&
    !VIZ_OPS_WITH_CATEGORY_SELECT_DRILLDOWN.has(payload)
  ) {
    if (editingDPT.visualize_op.instructions.V2_TWO_DIMENSION_CHART?.categoryColumn)
      editingDPT.visualize_op.instructions.V2_TWO_DIMENSION_CHART.categoryColumn.bucketSize =
        undefined;
  }

  if (
    V2_VIZ_INSTRUCTION_TYPE[editingDPT.visualize_op.operation_type] !==
    V2_VIZ_INSTRUCTION_TYPE[payload]
  ) {
    editingDPT.visualize_op.operation_type = payload;
    editingDPT._loading = isDataPanelConfigReady(editingDPT.visualize_op);
    editingDPT._rows = undefined;
    editingDPT._schema = undefined;
    editingDPT._error = undefined;
    editingDPT._secondaryData = undefined;
  }

  if (
    GROUPED_STACKED_OPERATION_TYPES.includes(editingDPT.visualize_op.operation_type) &&
    !GROUPED_STACKED_OPERATION_TYPES.includes(payload) &&
    editingDPT.visualize_op.instructions.V2_TWO_DIMENSION_CHART?.groupingColumn
  ) {
    editingDPT.visualize_op.instructions.V2_TWO_DIMENSION_CHART.groupingColumn = undefined;
  }

  editingDPT.visualize_op.operation_type = payload;
};

const fetchDatasetDataSuccess = (
  state: DashboardEditConfigReducerState,
  datasetId: string,
  payload: FetchDashboardDatasetPreviewData,
) => {
  if (!state.config) return;

  const fetchingDataset = state.config.datasets[datasetId];

  if (fetchingDataset) {
    fetchingDataset._schema = payload.dataset_preview.schema;
    fetchingDataset._rows = payload.dataset_preview._rows;
    fetchingDataset._unsupported_operations = payload.dataset_preview._unsupported_operations;
    fetchingDataset._error = undefined;
    fetchingDataset._loading = false;
    fetchingDataset._query_information = payload.query_information;

    fetchingDataset.drilldownColumnConfigs = fetchDatasetSuccessDrilldownConfig(
      fetchingDataset,
      payload.dataset_preview.schema,
    );
  }
};
