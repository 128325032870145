import { FC } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import DashboardLayout from 'components/DashboardLayout/DashboardLayout';

import { CanvasVersionConfig } from 'actions/canvasConfigActions';
import { PAGE_TYPE, VIEW_MODE } from 'types/dashboardTypes';
import { getCanvasDashboardElements } from 'utils/canvasConfigUtils';
import { Canvas } from 'actions/canvasActions';
import {
  downloadDataPanelSpreadsheet,
  fetchDataPanel,
  fetchDataPanelRowCount,
  fetchSecondaryData,
} from 'actions/dataPanelTemplateAction';
import { fetchDashboardDatasetPreview } from 'actions/datasetActions';
import { ReduxState } from 'reducers/rootReducer';
import { getSelectedCustomer } from 'reducers/customersReducer';
import { getArchitectCustomerDashboardElements } from 'utils/architectCustomerDashboardUtils';
import { withWidth } from 'components/HOCs/withWidth';

type Props = {
  canvas: Canvas;
  canvasConfig: CanvasVersionConfig;
  width?: number;
};

const DashboardViewBase: FC<Props> = ({ canvasConfig, canvas, width }) => {
  const dispatch = useDispatch();

  const { selectedDashboard, selectedUserGroup } = useSelector(
    (state: ReduxState) => ({
      selectedDashboard: state.canvasEdit.viewingArchitectCustomerDashboard,
      selectedUserGroup: getSelectedCustomer(state.customers),
    }),
    shallowEqual,
  );

  const layout = selectedDashboard
    ? selectedDashboard.configuration.layout
    : canvasConfig.default_layout;
  const elementsInLayout = new Set(layout.map((elem) => elem.i));
  const dataPanels = selectedDashboard
    ? Object.values(selectedDashboard.configuration.data_panels)
    : Object.values(canvasConfig.templates).filter((template) => elementsInLayout.has(template.id));

  const dashboardElements = selectedDashboard
    ? getArchitectCustomerDashboardElements(selectedDashboard.configuration, canvasConfig)
    : getCanvasDashboardElements(canvasConfig, elementsInLayout);

  const attachResourceId = (data: { postData: { resource_id?: number } }) => {
    data.postData.resource_id = canvas.id;
  };

  return (
    <DashboardLayout
      isCanvas
      isViewOnly
      dashboardElements={dashboardElements}
      dashboardLayout={layout}
      dataPanels={dataPanels}
      datasets={canvasConfig.datasets}
      downloadDataPanelSpreadsheet={(fetchDPCsv) => {
        attachResourceId(fetchDPCsv);
        dispatch(downloadDataPanelSpreadsheet(fetchDPCsv));
      }}
      exploResource={canvas}
      fetchDataPanel={(fetchDP, onSuccess) => {
        attachResourceId(fetchDP);
        dispatch(fetchDataPanel(fetchDP, onSuccess));
      }}
      fetchDataPanelRowCount={(fetchDPRowCount) => {
        attachResourceId(fetchDPRowCount);
        dispatch(fetchDataPanelRowCount(fetchDPRowCount));
      }}
      fetchDatasetPreview={(fetchDSPreview, onSuccess, onError) =>
        dispatch(fetchDashboardDatasetPreview(fetchDSPreview, onSuccess, onError))
      }
      fetchSecondaryData={(fetchSecondary) => {
        attachResourceId(fetchSecondary);
        dispatch(fetchSecondaryData(fetchSecondary));
      }}
      fetchShareData={() => console.warn('Not supported')}
      key={selectedDashboard?.id ?? undefined}
      pageType={PAGE_TYPE.EXPLO_APP}
      resourceVersionNumber={undefined}
      timezone="UTC"
      userGroup={selectedUserGroup ?? undefined}
      viewMode={VIEW_MODE.DEFAULT}
      width={width}
    />
  );
};

export const DashboardView = withWidth(DashboardViewBase);
