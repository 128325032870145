import { useRouteMatch } from 'react-router-dom';
import parse from 'url-parse';

import { EndUserMultiDashboardWrapper } from './architectCustomerDashboardPage/multiDashboardView';

type MatchParams = {
  canvasEmbedId: string;
  customerToken: string;
  environment?: string;
};

export default function IFrameArchitectCustomerDashboardPage(): JSX.Element {
  const { params } = useRouteMatch<MatchParams>();
  const { userId, isViewOnly } = parse(window.location.href, true).query;

  return (
    <EndUserMultiDashboardWrapper
      canvasEmbedId={params.canvasEmbedId}
      customerToken={params.customerToken}
      embedType="iframe"
      environment={params.environment}
      isViewOnly={isViewOnly === 'true'}
      userId={userId !== undefined ? (userId.trim() === '' ? undefined : userId) : undefined}
    />
  );
}
