import { FC, MouseEventHandler } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';

import { BuiltInMenu } from 'pages/ReportBuilderEditor/BuiltIns/BuiltInMenu';
import { BuiltInReportConfig } from 'actions/reportBuilderConfigActions';
import { Icon, sprinkles, Tooltip } from 'components/ds';

import {
  createBuiltInReport,
  deleteBuiltInReport,
  selectBuiltInReport,
} from 'reducers/reportBuilderEditReducer';
import { openModalType, ReportModal } from 'reportBuilderContent/reducers/reportEditingReducer';

import * as styles from 'pages/ReportBuilderEditor/BuiltIns/BuiltInListItem.css';

type Props = {
  selectedBuiltInId?: string;
  builtIn: BuiltInReportConfig;
};

export const BuiltInListItem: FC<Props> = ({ builtIn, selectedBuiltInId }) => {
  const dispatch = useDispatch();

  const onClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (e.defaultPrevented) return;
    dispatch(selectBuiltInReport(builtIn.id));
  };

  const onDelete = () => dispatch(deleteBuiltInReport(builtIn.id));

  const onEdit = () => {
    dispatch(selectBuiltInReport(builtIn.id));
    dispatch(openModalType(ReportModal.EDIT_BUILT_IN));
  };

  const onDuplicate = () => {
    dispatch(
      createBuiltInReport({
        ...builtIn,
        name: `${builtIn.name} Copy`,
      }),
    );
  };

  return (
    <Tooltip
      className={sprinkles({ zIndex: 'base' })}
      delayDuration={2000}
      side="right"
      text={
        <div className={sprinkles({ padding: 'sp1' })}>
          <div className={sprinkles({ heading: 'h4', overflow: 'hidden' })}>{builtIn.name}</div>
          <div className={sprinkles({ body: 'b2', overflow: 'hidden' })}>{builtIn.description}</div>
        </div>
      }>
      <div
        className={cx(
          styles.builtInItem,
          selectedBuiltInId === builtIn.id ? styles.builtInItemActive : styles.builtInItemInactive,
        )}
        onClick={onClick}>
        <div className={styles.itemHeading}>
          <span className={sprinkles({ heading: 'h4', truncateText: 'ellipsis' })}>
            {builtIn.name}
          </span>
          <BuiltInMenu onDelete={onDelete} onDuplicate={onDuplicate} onEdit={onEdit} />
        </div>
        {builtIn.description && (
          <span className={styles.itemDescription}>{builtIn.description}</span>
        )}
        <div className={styles.itemPermissions}>
          <Icon name="user" size="sm" />
          {!builtIn.permissions || builtIn.permissions.allCustomers
            ? 'All customers'
            : builtIn.permissions.customerIds.length === 0
            ? 'No customers'
            : builtIn.permissions.customerIds.length === 1
            ? '1 customer'
            : `${builtIn.permissions.customerIds.length} customers`}
        </div>
      </div>
    </Tooltip>
  );
};
