import { FC } from 'react';
import { useDispatch } from 'react-redux';
import produce from 'immer';

import Button from 'shared/Button';
import { Tooltip, Position } from '@blueprintjs/core';

import SettingHeader from '../SettingHeader';
import DroppableColumnSection from '../vizConfigs/droppable/DroppableColumnSection';
import { AppToaster } from 'toaster';

import {
  OPERATION_TYPES,
  V2TrendTableInstructions,
  AggregationType,
  Aggregation,
} from 'constants/types';
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { DatasetSchema, DatasetColumn } from 'types/datasets';
import { getNewAggCol, resolveAggColDropped } from '../vizConfigs/utils';
import { showCustomToast } from 'shared/sharedToasts';
import { findMatchingAggColIdx, findMatchingAgg } from 'utils/aggUtils';

type Props = {
  instructions: V2TrendTableInstructions;
  chartType: OPERATION_TYPES;
  loading?: boolean;
  schema: DatasetSchema;
};

export const TrendTableAggregations: FC<Props> = ({ instructions, chartType, loading, schema }) => {
  const dispatch = useDispatch();
  const aggColumns = instructions.aggColumns || [];

  const onAggregationColumnAdded = (
    newCol: DatasetColumn,
    oldColName?: string,
    oldColAggType?: AggregationType,
  ) => {
    const newInstructions = produce(instructions, (draft) => {
      // This is case we are adding a new column
      if (oldColName === undefined) {
        draft.aggColumns = resolveAggColDropped(
          newCol,
          (toastInfo) => AppToaster.show(toastInfo),
          draft.aggColumns || [],
        );
        return;
      }
      // This is case we are replacing an old column
      if (!draft.aggColumns) return;
      const newAggCol = getNewAggCol(
        newCol,
        (toastInfo) => AppToaster.show(toastInfo),
        draft.aggColumns,
      );
      if (newAggCol === undefined) return;
      const removedColIndex = findMatchingAggColIdx(draft.aggColumns, oldColName, oldColAggType);
      if (removedColIndex === -1) return;
      draft.aggColumns.splice(removedColIndex, 1, newAggCol);
    });
    dispatch(updateVisualizeOperation(newInstructions, chartType));
  };

  return (
    <>
      <SettingHeader
        name="Aggregation"
        rightContent={
          <Tooltip
            usePortal
            content="Click to add a custom formula aggregation"
            position={Position.BOTTOM}>
            <Button
              compact
              minimal
              icon="function"
              onClick={() => {
                const newInstructions = produce(instructions, (draft) => {
                  draft.aggColumns = resolveAggColDropped(
                    { name: 'custom_formula', type: 'string' },
                    (toastInfo) => AppToaster.show(toastInfo),
                    draft.aggColumns,
                  );

                  draft.aggColumns[draft.aggColumns.length - 1].agg = {
                    id: Aggregation.FORMULA,
                    formula: '',
                  };
                });
                dispatch(updateVisualizeOperation(newInstructions, chartType));
              }}
            />
          </Tooltip>
        }
      />
      <DroppableColumnSection
        required
        columns={aggColumns}
        disableEdits={loading}
        onColAdded={onAggregationColumnAdded}
        onColOptionChanged={(option, name, aggType) => {
          const newInstructions = produce(instructions, (draft) => {
            const changedCol = findMatchingAgg(draft.aggColumns, name, aggType);
            if (!changedCol) return;

            const agg = option as AggregationType;
            if (findMatchingAgg(draft.aggColumns, name, agg)) {
              showCustomToast(
                'The selected aggregation is already present for this column. Duplicates are not allowed.',
                { icon: 'warning-sign' },
              );

              return;
            }

            changedCol.agg = { id: agg.id, formula: agg.formula };
          });

          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        onRemoveCol={(col, aggType) => {
          const newInstructions = produce(instructions, (draft) => {
            const removedColIndex = findMatchingAggColIdx(draft.aggColumns, col.name, aggType);
            if (removedColIndex >= 0) draft.aggColumns?.splice(removedColIndex, 1);
          });

          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        schema={schema}
      />
    </>
  );
};
