import { FC, useState, useMemo } from 'react';
import { IconButton, sprinkles } from 'components/ds';

import { TableDataset } from 'actions/dataSourceActions';

import * as styles from 'components/resource/SchemaTableViewer.css';

type Props = {
  table: TableDataset;
};

/**
 * Displays the columns in a schema table
 */
export const SchemaTableViewer: FC<Props> = ({ table }) => {
  const [isOpen, setIsOpen] = useState(false);

  const schema = useMemo(() => {
    if (isOpen && table.schema) {
      return [...table.schema].sort((a, b) => a.name.localeCompare(b.name));
    }
  }, [isOpen, table.schema]);

  return (
    <div>
      <div
        className={sprinkles({ display: 'flex', flexDirection: 'row', alignItems: 'center' })}
        onClick={() => setIsOpen(!isOpen)}>
        <IconButton name={isOpen ? 'caret-down' : 'caret-right'} />
        <div className={styles.tableHeader} style={{ fontSize: 12 }}>
          {table.table_name}
        </div>
      </div>
      {isOpen && (
        <div>
          {schema?.map((col, index) => (
            <div className={styles.tableColRow} key={`${col.name}-${index}`}>
              <div>{col.name}</div>
              <div>{col.type}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
