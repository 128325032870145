import { createReducer } from '@reduxjs/toolkit';

import { DataTable } from 'actions/datasetActions';
import {
  fetchAllDataSourceTablesSuccess,
  fetchAllDataSourceTablesError,
} from 'actions/dataSourceActions';

interface DataSourcesReducerState {
  allTables: DataTable[];
}

const dataSourceReducerInitialState: DataSourcesReducerState = {
  allTables: [],
};

export default createReducer(dataSourceReducerInitialState, (builder) =>
  builder
    .addCase(fetchAllDataSourceTablesSuccess, (state, { payload }) => {
      state.allTables = payload.schema;
    })
    .addCase(fetchAllDataSourceTablesError, (state) => {
      state.allTables = [];
    })
    .addDefaultCase((state) => state),
);
