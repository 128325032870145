import { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';

import { Dialog, Icon } from '@blueprintjs/core';

import Button from 'shared/Button';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.ds.white,
    padding: theme.spacing(6),
    borderRadius: 8,
    border: `1px solid ${theme.palette.ds.grey400}`,
  },
  header: {
    marginBottom: theme.spacing(6),
    fontWeight: 500,
    fontSize: 24,
    lineHeight: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  backIcon: {
    marginRight: theme.spacing(4),
    color: theme.palette.ds.grey900,
    cursor: 'pointer',
  },
}));

type ModalProps = {
  className: string;
  portalContainerId: string;
  title: string;

  goBack?: () => void;
  onClose: () => void;
};

export const ArchitectCustomerDashboardModal: FC<ModalProps> = (props) => {
  const { className, children, onClose, title, goBack } = props;
  const classes = useStyles();

  return (
    <Dialog
      isOpen
      className={cx(classes.root, className)}
      onClose={onClose}
      portalContainer={document.getElementById(props.portalContainerId) ?? undefined}>
      <div className={classes.header}>
        <div className={classes.titleDiv}>
          {goBack ? (
            <Icon className={classes.backIcon} icon="arrow-left" onClick={goBack} size={24} />
          ) : null}
          {title}
        </div>
        {/* Look into making button look better */}
        <Button minimal icon="cross" onClick={onClose} />
      </div>
      {children}
    </Dialog>
  );
};
