import { cloneDeep } from 'utils/standard';
import { useDispatch, useSelector } from 'react-redux';

import TextAreaWithBlurSave from 'pages/dataPanelEditorPage/textAreaWithBlurSave';
import { Switch } from 'components/ds';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';

import { ReduxState } from 'reducers/rootReducer';
import {
  updateGeneralFormatOptions,
  updateVisualizeOperation,
} from 'actions/dataPanelConfigActions';
import {
  OPERATION_TYPES,
  V2GeneralInstructions,
  V2TwoDimensionChartInstructions,
  VisualizeOperationGeneralFormatOptions,
  V2KPIChartInstructions,
  V2KPITrendInstructions,
  V2ScatterPlotInstructions,
  VisualizeTableInstructions,
  VisualizePivotTableInstructions,
  BaseTableInstructions,
  VisualizeCollapsibleListInstructions,
  V2BoxPlotInstructions,
} from 'constants/types';
import { LINK_CONFIG_IN_HEADER } from 'constants/dashboardConstants';
import SettingHeader from '../../DataConfigTab/SettingHeader';
import LinkConfig from '.././formatSections/LinkConfig';

import { sharedStyles } from './styles';
import { TEXT_SIZE_OFFSET_MAP } from 'globalStyles';
import produce from 'immer';

type Props = {
  options: VisualizeOperationGeneralFormatOptions;
  visualizationType: OPERATION_TYPES;
  instructions:
    | V2TwoDimensionChartInstructions
    | V2KPIChartInstructions
    | V2KPITrendInstructions
    | V2ScatterPlotInstructions
    | V2BoxPlotInstructions
    | VisualizeTableInstructions
    | VisualizePivotTableInstructions
    | VisualizeCollapsibleListInstructions;
};

export default function VisualizationHeaderConfig({
  options,
  visualizationType,
  instructions,
}: Props) {
  const dispatch = useDispatch();
  const classes = sharedStyles();

  const updateGeneralFormat = (updates: VisualizeOperationGeneralFormatOptions) => {
    const newOptions = { ...options, ...updates };
    dispatch(updateGeneralFormatOptions(newOptions));
  };

  const globalStyleConfig = useSelector(
    (state: ReduxState) => state.dashboardStyles.globalStyleConfig,
  );

  const updateGeneralInstructions = (updates: V2GeneralInstructions) => {
    const newInstructions = cloneDeep(instructions);

    newInstructions.generalFormat = {
      ...newInstructions.generalFormat,
      ...updates,
    };

    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  const updateTableInstructions = (updates: BaseTableInstructions) => {
    const newInstructions = {
      ...instructions,
      ...updates,
    };
    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  const defaultFontSize = String(
    globalStyleConfig.text.textSize + TEXT_SIZE_OFFSET_MAP['kpiTitle'],
  );

  return (
    <div>
      <SettingHeader
        name="Header"
        rightContent={
          <Switch
            useCustomStyles
            onChange={() => {
              updateGeneralFormat({
                headerConfig: {
                  ...options.headerConfig,
                  isHeaderHidden: !options.headerConfig?.isHeaderHidden,
                },
              });
            }}
            switchOn={!options.headerConfig?.isHeaderHidden}
          />
        }
      />
      {!options.headerConfig?.isHeaderHidden && (
        <div className={classes.root}>
          <InputWithBlurSave
            containerClassName={classes.configInput}
            helpIcon="code"
            helpText="This field can take in variables"
            initialValue={options.headerConfig?.title}
            label="Title"
            onNewValueSubmitted={(newValue) =>
              updateGeneralFormat({ headerConfig: { ...options.headerConfig, title: newValue } })
            }
          />

          {visualizationType === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2 &&
            (instructions as V2KPITrendInstructions).hideTrendLines && (
              <InputWithBlurSave
                containerClassName={classes.configInput}
                initialValue={
                  (instructions as V2KPITrendInstructions).titleFormat?.fontSize?.toString() ??
                  defaultFontSize
                }
                label="Title Font Size"
                onNewValueSubmitted={(newValue: string) => {
                  const intValue = parseInt(newValue);
                  const newInstructions = produce(instructions, (draft: V2KPITrendInstructions) => {
                    draft.titleFormat = {
                      ...draft.titleFormat,
                      fontSize: intValue > 0 ? intValue : undefined,
                    };
                  });
                  dispatch(updateVisualizeOperation(newInstructions, visualizationType));
                }}
              />
            )}
          {(visualizationType === OPERATION_TYPES.VISUALIZE_TABLE ||
            visualizationType === OPERATION_TYPES.VISUALIZE_PIVOT_TABLE) && (
            <>
              <Switch
                useCustomStyles
                className={classes.configInput}
                label="Filtering"
                onChange={() =>
                  updateTableInstructions({
                    isFilteringDisabled: !(instructions as BaseTableInstructions)
                      .isFilteringDisabled,
                  })
                }
                switchOn={!(instructions as BaseTableInstructions).isFilteringDisabled}
              />
              {visualizationType === OPERATION_TYPES.VISUALIZE_TABLE && (
                <Switch
                  useCustomStyles
                  className={classes.configInput}
                  disabled={
                    (instructions as BaseTableInstructions).shouldVisuallyGroupByFirstColumn
                  }
                  label="Editing columns"
                  onChange={() => {
                    const newInstructions = produce(instructions, (draft) => {
                      (draft as VisualizeTableInstructions).isSchemaCustomizationEnabled = !(
                        draft as VisualizeTableInstructions
                      ).isSchemaCustomizationEnabled;
                    });

                    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
                  }}
                  switchOn={
                    (instructions as VisualizeTableInstructions).isSchemaCustomizationEnabled
                  }
                />
              )}
            </>
          )}
          <Switch
            useCustomStyles
            className={classes.configInput}
            label="Descriptive Tooltip"
            onChange={() => {
              updateGeneralInstructions({ showTooltip: !instructions.generalFormat?.showTooltip });
            }}
            switchOn={instructions.generalFormat?.showTooltip}
          />
          {instructions.generalFormat?.showTooltip && (
            <TextAreaWithBlurSave
              containerClassName={classes.configInput}
              initialValue={instructions.generalFormat?.tooltipText || ''}
              onNewValueSubmitted={(newValue) =>
                updateGeneralInstructions({ tooltipText: newValue })
              }
              placeholder=""
            />
          )}
          {LINK_CONFIG_IN_HEADER(visualizationType) ? (
            <LinkConfig inHeader generalFormatOptions={options || {}} />
          ) : null}
        </div>
      )}
    </div>
  );
}
