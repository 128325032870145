import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as RD from 'remotedata';

import { Spinner, sprinkles } from 'components/ds';
import { ReportBuilder } from './ReportBuilder';

import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { EmbedReportBuilder } from 'actions/reportBuilderActions';
import { fetchCustomerReports } from 'actions/customerReportActions';

type Props = {
  customerToken: string;
  environment: string | undefined;
  reportBuilderEmbedId: string;
};

export const ReportBuilderWrapper: FC<Props> = ({
  customerToken,
  environment,
  reportBuilderEmbedId,
}) => {
  const dispatch = useDispatch();

  const reportBuilder = useSelector(
    (state: ReportBuilderReduxState) => state.embeddedReportBuilder.reportBuilder,
  );

  useEffect(() => {
    dispatch(
      fetchCustomerReports({
        customerToken,
        postData: {
          environment: environment ?? null,
          report_builder_embed_id: reportBuilderEmbedId,
        },
      }),
    );
  }, [dispatch, customerToken, environment, reportBuilderEmbedId]);

  const renderReportBuilder = (reportBuilder: EmbedReportBuilder) => {
    return <ReportBuilder reportBuilder={reportBuilder} />;
  };

  return (
    <RD.RemoteComponent
      Error={(error) => (
        <div
          className={sprinkles({
            parentContainer: 'fill',
            flexItems: 'center',
            heading: 'h1',
            color: 'contentPrimary',
          })}>
          {error}
        </div>
      )}
      Loading={() => <Spinner fillContainer />}
      Success={renderReportBuilder}
      data={reportBuilder}
    />
  );
};
