import { FC, useCallback, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { TypeSortInfo } from '@inovua/reactdatagrid-enterprise/types';
import { isLoading, isSuccess } from 'remotedata';
import { orderBy } from 'utils/standard';

import { ReportBuilderFilters } from './Filters';
import { sprinkles } from 'components/ds';
import { EmbedDataGrid } from 'components/embed';
import { EmbedPivotTable } from 'components/embed/EmbedPivotTable';
import { DataPanel } from 'pages/ReportBuilder/ReportView/DataPanel';

import { DatasetColumn } from 'types/datasets';
import { SortOrder } from 'constants/types';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { CustomerReportDataInfo, CustomerReportView } from 'actions/customerReportActions';
import { fetchReportData, updateSortThunk } from 'reportBuilderContent/thunks';
import {
  createFilter,
  getCurrentViewTableData,
  handleTableColumnOrderChange,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import {
  getFilterableAggs,
  getFilterableColumns,
  getSchemaAndColConfigs,
} from 'utils/customerReportUtils';
import { PaginatorProps } from 'components/ds/DataGrid/paginator';

import * as styles from './ViewContent.css';

type Props = {
  dataInfo: CustomerReportDataInfo;
  view: CustomerReportView;
};

export const ViewContent: FC<Props> = ({ dataInfo, view }) => {
  const dispatch = useDispatch();

  const { versionConfig, currentTableData, currentView } = useSelector(
    (state: ReportBuilderReduxState) => ({
      versionConfig: state.embeddedReportBuilder.reportBuilderVersion?.config,
      currentTableData: getCurrentViewTableData(state.reportEditing),
      currentView: state.reportEditing.currentView,
    }),
    shallowEqual,
  );

  const tableDataReturned =
    currentTableData.rows !== undefined || currentTableData.error !== undefined;

  useEffect(() => {
    // currentView has a hidden purpose in this useEffect:
    // If there are two views with empty rows, then we need to re-trigger fetchReportData
    if (currentView && !tableDataReturned && !currentTableData.isLoading) {
      dispatch(fetchReportData());
    }
  }, [dispatch, currentView, tableDataReturned, currentTableData.isLoading]);

  const dataset = versionConfig?.datasets[dataInfo.datasetId];
  const columnOrder = view?.columnOrder;
  const hiddenColumns = view?.hiddenColumns;
  const schemaInfo = currentTableData.loadedSchemaInfo;

  const { schema, columnConfigs, pivotColumns, groupByColumns } = useMemo(
    () => getSchemaAndColConfigs(dataset, columnOrder, hiddenColumns, schemaInfo),
    [dataset, columnOrder, hiddenColumns, schemaInfo],
  );

  const paginatorProps: PaginatorProps = useMemo(() => {
    const totalRowCount = isSuccess(currentTableData.rowCount)
      ? currentTableData.rowCount.data
      : undefined;

    return {
      totalRowCount,
      currentPage: currentTableData.page,
      loading: isLoading(currentTableData.rowCount),
      goToPage: ({ page }) => dispatch(fetchReportData(page)),
    };
  }, [currentTableData.rowCount, currentTableData.page, dispatch]);

  const onColumnOrderChange = useCallback(
    (newOrder: string[]) => dispatch(handleTableColumnOrderChange(newOrder)),
    [dispatch],
  );

  const sortInfo = useMemo<TypeSortInfo>(() => {
    // If you pass a sortInfo array to React Data Grid, it lets users sort by multiple columns simultaneously
    // If you pass sortInfo as a single object (TypeSingleSortInfo), users can only sort by one column at once
    // For now, we only want to sort by one column at a time
    const firstSort = view.sort?.[0];

    // Convert Explo's sort to React Data Grid's sort: SortInfo --> CustomerReportSort
    return firstSort
      ? {
          name: firstSort.column.name,
          id: firstSort.column.name,
          dir: firstSort.order === SortOrder.ASC ? 1 : -1,
        }
      : null;
  }, [view.sort]);

  const filterableColumns = useMemo(() => {
    if (!dataset) return [];
    const filterableAggs = getFilterableAggs(view.aggregations ?? [], dataset);
    const filterableColumns = getFilterableColumns(dataset);
    return filterableColumns.concat(filterableAggs);
  }, [dataset, view.aggregations]);

  const orderedColumns = useMemo(
    () => orderBy(filterableColumns, 'default', 'desc'),
    [filterableColumns],
  );

  const handleFilterColumn = (column: DatasetColumn) => {
    const filterableColumn = filterableColumns.find((col) => col.name === column.name);
    if (!filterableColumn) return;

    return dispatch(
      createFilter({
        column,
        isPostFilter: filterableColumn.isPostFilter,
      }),
    );
  };

  if (!versionConfig || !dataset || !view) return null;

  const renderGrid = () => {
    const sharedProps = {
      columnConfigs,
      loading: currentTableData.isLoading,
      rows: currentTableData.rows ?? [],
      schema,
    };

    if (pivotColumns && groupByColumns) {
      return (
        <EmbedPivotTable
          {...sharedProps}
          groupByColumns={groupByColumns}
          pivotColumns={pivotColumns}
        />
      );
    }

    return (
      <EmbedDataGrid
        {...sharedProps}
        onColumnOrderChange={schemaInfo ? undefined : onColumnOrderChange}
        onFilterColumn={handleFilterColumn}
        onSortColumn={(sort) => dispatch(updateSortThunk(sort))}
        paginatorProps={paginatorProps}
        sortInfo={sortInfo}
      />
    );
  };

  return (
    <div
      className={sprinkles({
        display: 'flex',
        justifyContent: 'stretch',
        flex: 1,
        overflow: 'hidden',
      })}>
      <div className={styles.reportContainer}>
        <ReportBuilderFilters columns={orderedColumns} filters={view.filters} />
        <div className={sprinkles({ flex: 1 })}>{renderGrid()}</div>
      </div>
      <DataPanel dataInfo={dataInfo} view={view} />
    </div>
  );
};
