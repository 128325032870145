import { FC } from 'react';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import cx from 'classnames';

import { sprinkles } from 'components/ds';

type Props = {
  /**
   * Preferred alignment of tooltip against trigger
   */
  align?: 'start' | 'center' | 'end';
  /**
   * Optional class name for style overrides
   */
  className?: string;
  /**
   * Duration from when mouse enters the tooltip trigger until it opens
   */
  delayDuration?: number;
  /**
   * Preferred side for tooltip to render
   */
  side?: 'top' | 'right' | 'bottom' | 'left';
  /**
   * Distance in pixels from the trigger
   */
  sideOffset?: number;
  /**
   * Content of the tooltip
   */
  text: string | JSX.Element;
};

/**
 * Temp until design system supports light tooltips, which are meant for more content heavy tooltips
 */
export const LightTooltip: FC<Props> = ({
  align,
  children,
  className,
  delayDuration,
  side,
  sideOffset,
  text,
}) => {
  const content = (
    <RadixTooltip.Content
      align={align}
      className={cx(
        sprinkles({
          backgroundColor: 'white',
          borderRadius: 8,
          color: 'contentPrimary',
          padding: 'sp2',
          border: 1,
          borderColor: 'outline',
          boxShadow: 'md',
        }),
        className,
      )}
      side={side ?? 'top'}
      sideOffset={sideOffset ?? 8}>
      {text}
    </RadixTooltip.Content>
  );

  return (
    <RadixTooltip.Provider delayDuration={0}>
      <RadixTooltip.Root delayDuration={delayDuration ?? 0}>
        <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>
        {content}
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};
