import { defineAPIAction, defineAPIPostAction } from 'actions/actionUtils';
import { AccessGroup, ApiToken } from 'actions/teamActions';
import { ACTION } from './types';

export interface Role {
  name: string;
  permissions: Record<Entity, Action[]>;
}

type Entity = string;
type Action = string;

type TokenIdBody = { token_id: number };

type ApiTokenData = { new_token: ApiToken };

export const { actionFn: refreshApiToken, successAction: refreshApiTokenSuccess } =
  defineAPIPostAction<TokenIdBody, ApiTokenData>(
    ACTION.REFRESH_API_TOKEN,
    'role_permissions',
    'refresh_api_token',
    'POST',
  );

export const { actionFn: createApiToken, successAction: createApiTokenSuccess } =
  defineAPIPostAction<{ access_group_id: number }, ApiTokenData>(
    ACTION.CREATE_API_TOKEN,
    'role_permissions',
    'create_api_token',
    'POST',
  );

export const { actionFn: deleteApiToken, successAction: deleteApiTokenSuccess } =
  defineAPIPostAction<TokenIdBody, TokenIdBody>(
    ACTION.DELETE_API_TOKEN,
    'role_permissions',
    'delete_api_token',
    'POST',
  );

type UpdateApiTokenBody = {
  token_id: number;
  api_environment_tag_id?: number;
  is_read_only?: boolean;
};

export const { actionFn: updateApiToken, successAction: updateApiTokenSuccess } =
  defineAPIPostAction<UpdateApiTokenBody, ApiTokenData>(
    ACTION.UPDATE_API_TOKEN,
    'role_permissions',
    'update_api_token',
    'POST',
  );

type AccessGroupsData = {
  access_groups: AccessGroup[];
};

export const { actionFn: fetchAccessGroups, successAction: fetchAccessGroupsSuccess } =
  defineAPIAction<AccessGroupsData>(
    ACTION.FETCH_ACCESS_GROUP_LIST,
    'role_permissions',
    'fetch_access_groups',
    'GET',
  );

type AccessGroupData = {
  new_access_group: AccessGroup;
};

export const { actionFn: createAccessGroup, successAction: createAccessGroupSuccess } =
  defineAPIPostAction<{ name: string }, AccessGroupData>(
    ACTION.CREATE_ACCESS_GROUP,
    'role_permissions',
    'create_access_group',
    'POST',
  );

export const { actionFn: deleteAccessGroup, successAction: deleteAccessGroupSuccess } =
  defineAPIPostAction<{ access_group_id: number }, { access_group_id: number }>(
    ACTION.DELETE_ACCESS_GROUP,
    'role_permissions',
    'delete_access_group',
    'POST',
  );

type UpdateAccessGroupsBody = {
  access_groups: {
    access_group_id: number;
    default_data_source_ids?: number[];
    data_source_ids?: number[];
    name?: string;
  }[];
};

export const { actionFn: updateAccessGroups, successAction: updateAccessGroupsSuccess } =
  defineAPIPostAction<UpdateAccessGroupsBody, AccessGroupsData>(
    ACTION.UPDATE_ACCESS_GROUPS,
    'role_permissions',
    'update_access_groups',
    'POST',
  );

type AssignUserRoleBody = {
  user_id: number;
  role_names: string[];
};

type UserRoleData = {
  user_id: number;
  role_names: string[];
};

export const { actionFn: assignUserRole, successAction: assignUserRoleSuccess } =
  defineAPIPostAction<AssignUserRoleBody, UserRoleData>(
    ACTION.ASSIGN_USER_ROLE,
    'role_permissions',
    'assign_user_role',
    'POST',
  );

type AssignPendingInviteRoleBody = {
  email: string;
  role_names: string[];
};

type PendingInviteRoleData = {
  email: string;
  role_names: string[];
};

export const { actionFn: assignPendingInviteRole, successAction: assignPendingInviteRoleSuccess } =
  defineAPIPostAction<AssignPendingInviteRoleBody, PendingInviteRoleData>(
    ACTION.ASSIGN_PENDING_INVITE_ROLE,
    'role_permissions',
    'assign_pending_invite_role',
    'POST',
  );
