import { FC, useMemo } from 'react';
import cx from 'classnames';
import { TypeColumn } from '@inovua/reactdatagrid-enterprise/types';

import { isNumber, keyBy } from 'utils/standard';
import { Icon, sprinkles } from 'components/ds';
import { format } from 'd3-format';
import * as tableStyles from 'components/ds/DataGrid/index.css';
import * as styles from './styles/trendTableStyles.css';

import NeedsConfigurationPanel from 'pages/dashboardPage/DashboardDatasetView/needsConfigurationPanel';
import { EmbedDataGrid } from 'components/embed';

import { V2TrendTableInstructions, VisualizeOperationGeneralFormatOptions } from 'constants/types';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { STRING } from 'constants/dataConstants';
import { DatasetSchema, DatasetRow } from 'types/datasets';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import {
  PeriodComparisonRangeTypes,
  PeriodRangeTypes,
  TrendGroupingOptions,
} from 'types/dateRangeTypes';
import { DateTime } from 'luxon';
import { getTimezoneAwareDate } from 'utils/timezoneUtils';
import {
  areRequiredVariablesSet,
  formatDateRange,
  getComparisonDates,
  getPctChange,
  getPeriodDates,
  instructionsReadyToDisplay,
} from './utils/trendUtils';

const positiveChangeStyles = {
  color: '#0e6245',
  backgroundColor: '#cbf4c9',
};
const noChangeStyles = {
  color: '#4f566b',
  backgroundColor: '#e3e8ee',
};
const negativeChangeStyles = {
  color: '#E51D00',
  backgroundColor: '#FDEDEB',
};

type RawTimeData = {
  date: DateTime;
  aggValues: Record<string, number>;
};

type Props = {
  loading?: boolean;
  previewData: Record<string, string | number>[];
  instructions?: V2TrendTableInstructions;
  variables: DashboardVariableMap;
  schema: DatasetSchema;
  generalOptions?: VisualizeOperationGeneralFormatOptions;
  secondaryData: DatasetRow[];
};

export const TrendTable: FC<Props> = ({
  generalOptions,
  instructions,
  loading,
  previewData,
  schema,
  secondaryData,
  variables,
}) => {
  const instructionsReady = useMemo(
    () => instructionsReadyToDisplay(instructions, instructions?.aggColumns),
    [instructions],
  );

  const chartData = useMemo(() => {
    if (loading || !instructionsReady || schema?.length < 2 || !instructions) return emptyChartData;
    return processTrendData(previewData, instructions, schema);
  }, [loading, instructions, instructionsReady, previewData, schema]);

  const requiredVarNotsSet = !areRequiredVariablesSet(variables, instructions);

  const { compTableRows, periodTableRows } = chartData;
  const periodRows = useMemo(
    () => [secondaryData[1], ...periodTableRows],
    [secondaryData, periodTableRows],
  );
  const compRows = useMemo(
    () => [secondaryData[0], ...compTableRows],
    [secondaryData, compTableRows],
  );
  const gridSchema = useMemo(
    () => [{ friendly_name: 'Date time', name: 'date', type: STRING }, ...schema.slice(1)],
    [schema],
  );

  const columns = useMemo(() => {
    return renderColumns({ schema: gridSchema, periodRows, compRows });
  }, [gridSchema, periodRows, compRows]);

  if (loading || !instructionsReady || requiredVarNotsSet) {
    return (
      <div className={sprinkles({ parentContainer: 'fill' })}>
        <NeedsConfigurationPanel
          fullHeight
          className={styles.loadingState}
          instructionsNeedConfiguration={!instructionsReady}
          loading={loading}
          requiredVarsNotSet={requiredVarNotsSet}
        />
      </div>
    );
  }

  if (!previewData || previewData.length === 0) {
    return (
      <div
        className={cx(
          sprinkles({ height: 'fill' }),
          GLOBAL_STYLE_CLASSNAMES.text.body.primaryFont,
        )}>
        <div
          className={styles.noDataText}
          style={{ fontSize: generalOptions?.noDataState?.noDataFontSize || 36 }}>
          {generalOptions?.noDataState?.noDataText || 'No Data'}
        </div>
      </div>
    );
  }

  if (!chartData) return null;

  return (
    <EmbedDataGrid
      lockFirstRow
      className={sprinkles({ parentContainer: 'fill' })}
      columns={columns}
      rowHeight={72}
      rows={periodRows}
      schema={gridSchema}
    />
  );
};

const getPeriodColumnName = (schema: DatasetSchema) => schema[0].name;

const getAggColumnNames = (schema: DatasetSchema) => schema.slice(1).map((col) => col.name);

const getPeriodAndComparisonDates = (instructions: V2TrendTableInstructions) => {
  const { periodStartDate, periodEndDate, periodDates } = getPeriodDates(
    getPeriodRange(instructions),
    getTrendGrouping(instructions),
    getCustomStartDate(instructions),
    getCustomEndDate(instructions),
    instructions?.periodColumn?.trendDateOffset ?? (0 as number),
  );

  const comparisonDates = getComparisonDates(
    periodStartDate,
    periodEndDate,
    periodDates.length,
    getComparisonRange(instructions),
    getPeriodRange(instructions),
    getTrendGrouping(instructions),
  );

  return { periodDates, comparisonDates };
};

const getPeriodRange = (instructions: V2TrendTableInstructions) => {
  return instructions?.periodColumn?.periodRange || PeriodRangeTypes.LAST_4_WEEKS;
};

const getComparisonRange = (instructions: V2TrendTableInstructions) => {
  return instructions?.periodComparisonRange || PeriodComparisonRangeTypes.PREVIOUS_PERIOD;
};

const getTrendGrouping = (instructions: V2TrendTableInstructions) => {
  return instructions?.trendGrouping || TrendGroupingOptions.WEEKLY;
};

const getCustomEndDate = (instructions: V2TrendTableInstructions) => {
  return instructions?.periodColumn?.customEndDate
    ? getTimezoneAwareDate(instructions.periodColumn.customEndDate)
    : DateTime.local();
};

const getCustomStartDate = (instructions: V2TrendTableInstructions) => {
  return instructions?.periodColumn?.customStartDate
    ? getTimezoneAwareDate(instructions.periodColumn.customStartDate)
    : DateTime.local();
};

const emptyChartData = {
  periodRawData: [],
  compRawData: [],
  periodTableRows: [],
  compTableRows: [],
};

const processTrendData = (
  previewData: Record<string, string | number>[],
  instructions: V2TrendTableInstructions,
  schema: DatasetSchema,
) => {
  if (!previewData) return emptyChartData;

  const periodColumnName = getPeriodColumnName(schema);
  const aggColumnNames = getAggColumnNames(schema);

  const { periodDates, comparisonDates } = getPeriodAndComparisonDates(instructions);

  previewData.forEach((row) => {
    if (!instructions?.periodColumn?.column.type) return;

    row[periodColumnName] = getTimezoneAwareDate(row[periodColumnName].toString()).toLocaleString(
      DateTime.DATE_SHORT,
    );
  });

  const dataByDate = keyBy(previewData, periodColumnName);

  const periodRawData: RawTimeData[] = [];
  const periodTableRows: DatasetRow[] = [];
  const compRawData: RawTimeData[] = [];
  const compTableRows: DatasetRow[] = [];

  periodDates.forEach((date) => {
    const parsedDate = date.toLocaleString(DateTime.DATE_SHORT);
    const aggValues: Record<string, number> = {};

    if (dataByDate[parsedDate]) {
      aggColumnNames.forEach((aggColumnName) => {
        aggValues[aggColumnName] = isNumber(dataByDate[parsedDate][aggColumnName])
          ? (dataByDate[parsedDate][aggColumnName] as number)
          : 0;
      });
    }

    periodRawData.push({
      date,
      aggValues,
    });

    periodTableRows.push({
      ...aggValues,
      date: formatDateRange(date, getTrendGrouping(instructions)),
    });
  });

  comparisonDates.forEach((date) => {
    const parsedDate = date.toLocaleString(DateTime.DATE_SHORT);
    const aggValues: Record<string, number> = {};

    if (dataByDate[parsedDate]) {
      aggColumnNames.forEach((aggColumnName) => {
        aggValues[aggColumnName] = isNumber(dataByDate[parsedDate][aggColumnName])
          ? (dataByDate[parsedDate][aggColumnName] as number)
          : 0;
      });
    }

    compRawData.push({
      date,
      aggValues,
    });
    compTableRows.push({
      ...aggValues,
      date: formatDateRange(date, getTrendGrouping(instructions)),
    });
  });

  return {
    periodRawData,
    compRawData,
    periodTableRows,
    compTableRows,
  };
};

// TODO: Break out rendered cells into their own components
const renderColumns = ({
  schema,
  periodRows,
  compRows,
}: {
  schema: DatasetSchema;
  periodRows: DatasetRow[];
  compRows: DatasetRow[];
}) => {
  return schema?.map((columnInfo, index) => {
    if (index === 0) {
      return {
        ...columnInfo,
        defaultFlex: 3,
        locked: true,
        minWidth: 200,
        render: ({
          rowIndex,
          value,
          isLockedFirstRow,
        }: {
          rowIndex: number;
          value: string;
          isLockedFirstRow: boolean;
        }) => {
          // if `lockFirstRow` is enabled, then the `rows` passed in to the data grid will not include the first
          // row. So the `rowIndex` will all be 1 less than the index into the `rows` that they should be.
          const rowDataIndex = !isLockedFirstRow ? rowIndex + 1 : rowIndex;
          let periodDate = value;
          let compDate = compRows[rowDataIndex] && compRows[rowDataIndex].date;

          if (rowIndex === 0) {
            periodDate = `${periodRows[1].date} - ${periodRows[periodRows.length - 1].date}`;
            compDate = `${compRows[1].date} - ${compRows[compRows.length - 1].date}`;
          }

          return (
            <div className={sprinkles({ padding: 'sp1' })}>
              <div
                className={cx(
                  sprinkles({
                    marginBottom: 'sp.5',
                    fontWeight: rowDataIndex === 0 ? 700 : 500,
                    paddingY: 'sp.5',
                  }),
                  GLOBAL_STYLE_CLASSNAMES.text.primaryColor.color,
                )}>
                {periodDate}
              </div>
              <div className={GLOBAL_STYLE_CLASSNAMES.text.secondaryColor.color}>{compDate}</div>
            </div>
          );
        },
      } as TypeColumn;
    }

    // Agg columns
    return {
      ...columnInfo,
      defaultFlex: 3,
      minWidth: 200,
      render: ({
        rowIndex,
        value,
        isLockedFirstRow,
      }: {
        rowIndex: number;
        value: string;
        isLockedFirstRow: boolean;
      }) => {
        const iconStyles = sprinkles({ marginRight: 'sp.5' });

        // if `lockFirstRow` is enabled, then the `rows` passed in to the data grid will not include the first
        // row. So the `rowIndex` will all be 1 less than the index into the `rows` that they should be.
        const rowDataIndex = !isLockedFirstRow ? rowIndex + 1 : rowIndex;

        const periodAgg = Number(value);
        const aggColName = columnInfo.name;
        const compAgg = compRows[rowDataIndex] ? (compRows[rowDataIndex][aggColName] as number) : 0;

        const pctChange = getPctChange(periodAgg, compAgg);
        const changeText =
          (periodAgg === 0 && compAgg === 0) || isNaN(pctChange)
            ? '-'
            : format(',.1%')(Math.abs(pctChange));

        let trendStyle = noChangeStyles;
        let trendIcon;
        if (pctChange > 0) {
          trendStyle = positiveChangeStyles;
          trendIcon = <Icon className={iconStyles} name="arrow-up-right" />;
        } else if (pctChange < 0) {
          trendStyle = negativeChangeStyles;
          trendIcon = <Icon className={iconStyles} name="arrow-down-right" />;
        }

        return (
          <div
            className={sprinkles({
              padding: 'sp1',
              flexItems: 'alignCenter',
              justifyContent: 'flex-end',
              width: 'fill',
            })}>
            <div className={sprinkles({ flexItems: 'column', alignItems: 'flex-end' })}>
              <div
                className={cx(
                  sprinkles({
                    marginBottom: 'sp.5',
                    fontWeight: rowDataIndex === 0 ? 700 : 500,
                    paddingY: 'sp.5',
                    flexItems: 'alignCenter',
                  }),
                  GLOBAL_STYLE_CLASSNAMES.text.primaryColor.color,
                )}>
                <div
                  className={cx(
                    sprinkles({ marginRight: 'sp1', flexItems: 'alignCenter' }),
                    tableStyles.cellTrendTag,
                  )}
                  style={trendStyle}>
                  {trendIcon}
                  {changeText}
                </div>
                {periodAgg}
              </div>
              <div className={GLOBAL_STYLE_CLASSNAMES.text.secondaryColor.color}>{compAgg}</div>
            </div>
          </div>
        );
      },
    } as TypeColumn;
  });
};
