import { useDispatch } from 'react-redux';
import produce from 'immer';

import SharedLineChartConfigs from 'components/ChartConfigs/LineChartConfigs';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import {
  ChartSpecificFormat,
  OPERATION_TYPES,
  V2TwoDimensionChartInstructions,
} from 'constants/types';
import { sharedStyles } from './styles';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2TwoDimensionChartInstructions;
  lineWidth: number;
};

export default function LineChartConfig({ visualizationType, instructions, lineWidth }: Props) {
  const classes = sharedStyles();
  const dispatch = useDispatch();

  const updateChartSpecificFormat = (specificUpdates: ChartSpecificFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.chartSpecificFormat = {
        ...draft.chartSpecificFormat,
        ...specificUpdates,
      };
    });
    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  return (
    <div className={classes.root}>
      <SharedLineChartConfigs
        configInputClass={classes.configInput}
        defaultLineWidth={lineWidth}
        instructions={instructions}
        updateChartSpecificFormat={updateChartSpecificFormat}
      />
    </div>
  );
}
