import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';

import Button from 'shared/Button';

import { ApplyFilterElemConfig } from 'types/dashboardTypes';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // Vertically aligns export element with dropdowns
    paddingTop: 20,
  },
  filterButton: {
    width: '100%',
    boxShadow: 'none !important',

    '& .bp3-spinner .bp3-spinner-track': {
      stroke: theme.palette.ds.grey100,
    },

    '&.bp3-button .bp3-button-text': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  blockedTag: {
    backgroundColor: theme.palette.ds.grey700,
    color: theme.palette.ds.grey300,
    borderRadius: 10,
    width: 20,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

type Props = {
  applyFilters: (elementIds: string[]) => void;
  blockedElementIds: string[];
  config: ApplyFilterElemConfig;
};

export default function DashboardApplyFilterElement({
  applyFilters,
  blockedElementIds,
  config,
}: Props) {
  const classes = useStyles();

  const blockedElements = blockedElementIds.filter(
    (blockedElemId) => config.elementIds?.[blockedElemId],
  );

  return (
    <div className={classes.root}>
      <Button
        className={cx(
          classes.filterButton,
          GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.buttonBackgroundColor,
          GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.shareButtonHoverColor,
          GLOBAL_STYLE_CLASSNAMES.text.body.button.primaryFont,
          GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.buttons.buttonCornerRadius,
          GLOBAL_STYLE_CLASSNAMES.container.shadow.buttonShadow,
        )}
        icon={config.hideRefreshIcon ? undefined : 'refresh'}
        onClick={() => {
          if (blockedElements.length === 0) return;
          applyFilters(blockedElements);
        }}
        rightIcon={
          !config.hideNumFiltersIcon && blockedElements.length > 0 ? (
            <div className={classes.blockedTag}>{blockedElements.length}</div>
          ) : undefined
        }
        text={config.buttonText || 'Apply Filters'}
        type="primary"
      />
    </div>
  );
}
