import { CSSProperties, MouseEvent as ReactMouseEvent, ReactNode } from 'react';
import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Button as BlueprintButton,
  IconName,
  Alignment,
  Tooltip,
  IButtonProps,
  AnchorButton,
} from '@blueprintjs/core';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&.bp3-button': {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
      height: 32,
      borderRadius: 4,
      fontSize: 14,
      fontWeight: 500,
      fontFamily: 'inherit',
      backgroundImage: 'none !important',
      '&.bp3-button:focus': {
        outline: 'none',
      },
      backgroundColor: theme.palette.ds.grey200,

      '&:hover': {
        backgroundColor: theme.palette.ds.hovered.grey200,
      },
      '&:active': {
        backgroundColor: theme.palette.ds.pressed.grey200,
      },

      '&.bp3-disabled': {
        backgroundColor: theme.palette.ds.grey400,
        color: theme.palette.ds.grey700,
        '&.bp3-button:hover': {
          backgroundColor: theme.palette.ds.grey400,
        },
      },
      '&.bp3-active': {
        boxShadow: 'none',
      },
    },
    '&.bp3-button:empty': {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px !important`,
    },
  },
  borderless: {
    '&.bp3-button': {
      boxShadow: 'none',
      '&.bp3-button:hover': {
        boxShadow: 'none',
      },
    },
  },
  compact: {
    '&.bp3-button': {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
  },
  primary: {
    '&.bp3-button,.bp3-icon': {
      color: theme.palette.ds.white,
    },
    '&.bp3-button': {
      backgroundColor: theme.palette.ds.blue,
      '&.bp3-button:hover': {
        backgroundColor: theme.palette.ds.hovered.blue,
      },
      '&.bp3-button:active': {
        backgroundColor: theme.palette.ds.pressed.blue,
      },
    },
  },
  secondary: {
    '&.bp3-button,.bp3-icon': {
      color: theme.palette.ds.black,
    },
    '&.bp3-button': {
      backgroundColor: theme.palette.ds.grey300,
      '&.bp3-button:hover': {
        backgroundColor: theme.palette.ds.hovered.grey300,
      },
      '&.bp3-button:active': {
        backgroundColor: theme.palette.ds.pressed.grey300,
      },
    },
  },
  destructive: {
    '&.bp3-button,.bp3-icon': {
      color: theme.palette.ds.white,
    },
    '&.bp3-button': {
      backgroundColor: theme.palette.ds.red,
      '&.bp3-button:hover': {
        backgroundColor: theme.palette.ds.hovered.red,
      },
      '&.bp3-button:active': {
        backgroundColor: theme.palette.ds.pressed.red,
      },
    },
  },
  minimal: {
    '&.bp3-button,.bp3-icon': {
      color: theme.palette.ds.grey900,
    },
    '&.bp3-button': {
      backgroundColor: 'transparent',
      '&.bp3-button:hover': {
        backgroundColor: theme.palette.ds.grey100,
      },
      '&.bp3-button:active': {
        backgroundColor: theme.palette.ds.hovered.grey100,
      },
      '&.bp3-disabled': {
        backgroundColor: 'transparent',
        color: theme.palette.ds.grey600,
        '&.bp3-button:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    '&.bp3-button.bp3-active': {
      backgroundColor: theme.palette.ds.hovered.grey100,
      boxShadow: 'none',
      '&.bp3-button.bp3-active': {
        backgroundColor: theme.palette.ds.pressed.grey100,
      },
    },
  },
  minimalDestructive: {
    '&.bp3-button,.bp3-icon': {
      color: theme.palette.ds.red,
    },
    '&.bp3-button': {
      backgroundColor: 'transparent',
      '&.bp3-button:hover': {
        backgroundColor: theme.palette.ds.hovered.lightRed,
      },
      '&.bp3-button:active': {
        backgroundColor: theme.palette.ds.pressed.lightRed,
      },
      '&.bp3-disabled': {
        backgroundColor: 'transparent',
        color: theme.palette.ds.grey600,
        '&.bp3-button:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
}));

type ButtonType = 'primary' | 'secondary' | 'destructive';

type Props = {
  /**
   * If set to true, the button will display in an active state.
   */
  active?: boolean;
  /**
   * Aligns text to left, center, or right and pushes right icon as needed.
   */
  alignText?: Alignment;
  /**
   * If set to true, the button will have a border.
   */
  bordered?: boolean;
  /**
   * Classname to pass to the button component.
   */
  className?: string;
  /**
   * Reduce interior padding of the button.
   */
  compact?: boolean;
  /**
   * If true, the button cannot be clicked.
   */
  disabled?: boolean;
  /**
   * Whether the button should fill the width of its container.
   */
  fillWidth?: boolean;
  /**
   * If specified, will open this URL in a new tab on click.
   */
  href?: string;
  /**
   * Icon placed on the left side of the button.
   */
  icon?: IconName | JSX.Element;
  /**
   * Id to apply to the button.
   */
  id?: string;
  /**
   * If set to true, uses the default styles
   */
  ignoreCustomStyles?: boolean;
  /**
   * If set to true, with href, links open in new tab
   */
  openLinkInNewTab?: boolean;
  /**
   * If set to true, the button will display a loading icon, along with loadingText if specified, instead of its contents.
   */
  loading?: boolean;
  /**
   * If set to true, the button will use minimal styles (transparent background and different hover/active background colors).
   */
  minimal?: boolean;
  /**
   * Callback to be fired when the button is clicked.
   */
  onClick?: (event: ReactMouseEvent<HTMLElement, MouseEvent>) => void;
  /**
   * Icon placed on the right side of the button.
   */
  rightIcon?: IconName | JSX.Element;
  /**
   * Styles to apply directly to the button.
   */
  style?: CSSProperties;
  /**
   * Text or element to display in the button.
   */
  text?: ReactNode;
  /**
   * Optionally provide text to show up in a tooltip on hover.
   */
  tooltipText?: string;
  /**
   * Determines color of the button.
   */
  type?: ButtonType;
};

export default function Button({
  active,
  alignText,
  bordered,
  className,
  compact,
  disabled,
  fillWidth,
  href,
  icon,
  id,
  ignoreCustomStyles,
  openLinkInNewTab,
  loading,
  minimal,
  onClick,
  rightIcon,
  style,
  text,
  tooltipText,
  type,
  ...props
}: Props) {
  const classes = useStyles();

  const buttonProps: IButtonProps & {
    id: string | undefined;
    style: CSSProperties | undefined;
  } = {
    ...props,
    active,
    alignText,
    className: cx(
      classes.root,
      {
        [classes.borderless]: !bordered,
        [classes.compact]: compact,
        [cx(classes.primary, {
          [GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.buttonBackgroundColor]:
            !ignoreCustomStyles,
        })]: type === 'primary',
        [classes.secondary]: type === 'secondary',
        [classes.destructive]: type === 'destructive',
        [classes.minimal]: minimal,
        [classes.minimalDestructive]: minimal && type === 'destructive',
      },
      className,
    ),
    disabled,
    fill: fillWidth,
    icon,
    id,
    loading,
    onClick,
    rightIcon,
    style,
    text,
  };

  if (href) {
    const aProps = {
      href,
      rel: openLinkInNewTab ? 'noopener noreferrer' : undefined,
      target: openLinkInNewTab ? '_blank' : undefined,
    };

    return (
      <a {...aProps}>
        <BlueprintButton {...buttonProps} />
      </a>
    );
  }

  if (tooltipText) {
    // Tooltips do not work on disabled normal buttons so have to use AnchorButton
    return (
      <Tooltip content={tooltipText}>
        {
          // @ts-ignore - Blueprint props mismatch for AnchorButton
          disabled ? <AnchorButton {...buttonProps} /> : <BlueprintButton {...buttonProps} />
        }
      </Tooltip>
    );
  }

  return <BlueprintButton {...buttonProps} />;
}
