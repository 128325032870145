import { Component } from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { v4 as uuidv4 } from 'uuid';
import produce from 'immer';

import { DashboardDatasetView } from 'pages/dashboardPage/DashboardDatasetView';
import PDFExportTableView from './PDFExportTableView/PDFExportTableView';
import Poller from 'components/JobQueue/Poller';

import { GlobalStylesContext } from 'globalStyles';
import { OPERATION_TYPES, UserTransformedSchema } from 'constants/types';
import {
  removeUnderscoreFields,
  areRequiredUserInputsSet,
  getSynchronousSecondaryDataInstructions,
  getAsynchronousSecondaryDataInstructions,
  prepareDataPanelForFetch,
} from 'utils/dashboardUtils';
import { FetchDashboardDatasetPreviewBody } from 'actions/datasetActions';
import { DashboardElement, DashboardVariableMap, PAGE_TYPE } from 'types/dashboardTypes';
import { DataPanelTemplate, AdHocOperationInstructions } from 'types/dataPanelTemplate';
import { Dataset } from 'actions/datasetActions';
import { isDataPanelConfigReady, replaceTemplatesWithValues } from 'utils/dataPanelConfigUtils';
import { SetDPLoadingArgs } from 'actions/dashboardV2Actions';
import {
  FetchDataPanel,
  FetchDataPanelRowCount,
  FetchSecondaryData,
} from 'actions/dataPanelTemplateAction';
import { getDatasetIdsForDataPanel } from 'utils/variableUtils';
import { BulkEnqueueFnWithArgs, JobDefinition } from 'actions/jobQueueActions';
import { FetchDataPanelData } from 'actions/responseTypes';
import { ACTION } from 'actions/types';
import { Jobs } from 'components/JobQueue/types';
import { AnalyticsEventTracker } from 'utils/analyticsUtils';
import { DateTime } from 'luxon';

const styles = () =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      position: 'relative',
    },
  });

type PassedProps = {
  analyticsEventTracker: AnalyticsEventTracker;
  bulkEnqueueJobsWrapper: BulkEnqueueFnWithArgs;
  dashboardTemplateId: number;
  dataPanelTemplate: DataPanelTemplate;
  dataPanelId: string;
  dashboardDatasets: { [datasetId: string]: Dataset };
  dashboardElements: DashboardElement[];
  dashboardVersionNumber?: number;
  fetchDataPanelRowCount: FetchDataPanelRowCount;
  fetchDataPanel: FetchDataPanel;
  fetchDatasetPreview: (args: FetchDashboardDatasetPreviewBody) => void;
  fetchSecondaryData: FetchSecondaryData;
  setDpLoading: (args: SetDPLoadingArgs) => void;
  variables: DashboardVariableMap;
  userTransformedSchema: UserTransformedSchema;
  adHocOperationInstructions: AdHocOperationInstructions;
  reportName: string;
  customerToken: string;
  shouldUseJobQueue: boolean;
  maxRowsOverride?: number;
};

type State = {
  awaitedJobs: Record<string, Jobs>;
};

type Props = PassedProps & WithStyles<typeof styles>;

/**
 * A standalone layout to render a single chart or data table.
 * Distinct from DashboardLayout which takes in the entire dashboard and renders every
 * element of it.
 */
class ChartLayout extends Component<Props, State> {
  state: State = {
    awaitedJobs: {},
  };

  constructor(props: Props) {
    super(props);

    this.fetchDataPanelWrapper();
  }

  // the max number of rows we pull in for a pdf is usually 200 to avoid long
  // load times and timeouts. Now that we're using the job queue, we can
  // increase this, trying out 1000 for now. The number should strike a balance
  // between reasonable load time and showing as much data as possible, while
  // also avoiding something like a pdf with 1m rows. We could also open this
  // configuraton to the customer
  getMaxNumTableRows = (useJobQueue: boolean) => {
    const { maxRowsOverride } = this.props;

    return maxRowsOverride ? maxRowsOverride : useJobQueue ? 700 : 200;
  };

  fetchDataPanelWrapper = () => {
    const { dashboardDatasets, dataPanelTemplate, variables, setDpLoading } = this.props;

    if (
      !isDataPanelConfigReady(dataPanelTemplate.visualize_op) ||
      !areRequiredUserInputsSet(variables, dataPanelTemplate)
    ) {
      return setDpLoading({ ids: [dataPanelTemplate.id], loading: false });
    }

    const requests = getDatasetIdsForDataPanel(
      dataPanelTemplate,
      dashboardDatasets,
      undefined,
      true,
    ).map((datasetId) => this.fetchDatasetPreviewData(datasetId));

    requests.push(this.fetchDataPanelTemplateData(dataPanelTemplate));

    requests.push(...this.fetchDataPanelTemplateSecondaryData(dataPanelTemplate));

    if (dataPanelTemplate.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_TABLE) {
      requests.push(this.fetchDataPanelTemplateRowCount(dataPanelTemplate));
    }

    this.bulkEnqueueJobs(requests.filter((request) => !!request) as JobDefinition[]);
  };

  fetchDataPanelTemplateData = (
    dataPanelTemplate: DataPanelTemplate,
  ): JobDefinition | undefined => {
    const {
      dashboardTemplateId,
      variables,
      adHocOperationInstructions,
      fetchDataPanel,
      shouldUseJobQueue,
      dashboardDatasets,
      dashboardElements,
    } = this.props;

    const onSuccess = (response: FetchDataPanelData) => {
      this.fetchDataPanelTemplateSecondaryData(
        {
          ...dataPanelTemplate,
          ...response.data_panel_template,
        },
        true,
      );
    };
    const postData = {
      datasetId: dataPanelTemplate.table_id,
      config: removeUnderscoreFields(
        prepareDataPanelForFetch(
          variables,
          dataPanelTemplate,
          dashboardDatasets,
          true,
          dashboardElements,
        ),
      ),
      resource_id: dashboardTemplateId,
      id: dataPanelTemplate.id,
      sort_info: adHocOperationInstructions.sortInfo,
      filter_info: adHocOperationInstructions.filterInfo,

      variables,

      query_limit: this.getMaxNumTableRows(shouldUseJobQueue),
      timezone: DateTime.local().zoneName,
    };

    if (!shouldUseJobQueue) fetchDataPanel({ postData }, onSuccess);
    else
      return {
        job_type: ACTION.FETCH_DATA_PANEL_TEMPLATE,
        job_args: { ...postData, dataset_id: dataPanelTemplate.table_id },
      };
  };

  fetchDatasetPreviewData = (datasetId: string): JobDefinition | undefined => {
    const { variables, fetchDatasetPreview, shouldUseJobQueue } = this.props;

    const postData = {
      dataset_id: datasetId,
      query_limit: this.getMaxNumTableRows(shouldUseJobQueue),
      variables,
      customer_id: undefined,
      timezone: DateTime.local().zoneName,
    };

    if (!shouldUseJobQueue) fetchDatasetPreview(postData);
    else
      return {
        job_type: ACTION.FETCH_DASHBOARD_DATASET_PREVIEW,
        job_args: postData,
      };
  };

  fetchDataPanelTemplateSecondaryData = (
    dataPanelTemplate: DataPanelTemplate,
    afterMainFetch?: boolean,
  ): (JobDefinition | undefined)[] => {
    const {
      dashboardDatasets,
      dashboardTemplateId,
      fetchSecondaryData,
      variables,
      shouldUseJobQueue,
    } = this.props;
    const dataset = dashboardDatasets[dataPanelTemplate.table_id];
    const secondaryInstructions = afterMainFetch
      ? getSynchronousSecondaryDataInstructions(dataPanelTemplate)
      : getAsynchronousSecondaryDataInstructions(dataPanelTemplate, dataset);

    return secondaryInstructions.map((instructions) => {
      const postData = {
        datasetId: dataPanelTemplate.table_id,
        config: removeUnderscoreFields(instructions) as DataPanelTemplate,
        resource_id: dashboardTemplateId,
        id: dataPanelTemplate.id,
        variables,
        timezone: DateTime.local().zoneName,
      };

      if (!shouldUseJobQueue) {
        fetchSecondaryData({ postData: { ...postData, is_secondary_data_query: true } });
        return undefined;
      } else
        return {
          job_type: ACTION.FETCH_SECONDARY_DATA,
          job_args: {
            ...postData,
            dataset_id: dataPanelTemplate.table_id,
            is_secondary_data_query: true,
          },
        };
    });
  };

  fetchDataPanelTemplateRowCount = (
    dataPanelTemplate: DataPanelTemplate,
  ): JobDefinition | undefined => {
    const {
      dashboardTemplateId,
      variables,
      adHocOperationInstructions,
      fetchDataPanelRowCount,
      shouldUseJobQueue,
    } = this.props;

    const postData = {
      datasetId: dataPanelTemplate.table_id,
      id: dataPanelTemplate.id,
      config: dataPanelTemplate,
      resource_id: dashboardTemplateId,
      filter_info: adHocOperationInstructions.filterInfo,
      variables,
      timezone: DateTime.local().zoneName,
    };

    if (!shouldUseJobQueue) {
      fetchDataPanelRowCount({ postData });
      return undefined;
    } else
      return {
        job_type: ACTION.FETCH_DATA_PANEL_ROW_COUNT,
        job_args: { ...postData, dataset_id: dataPanelTemplate.table_id },
      } as JobDefinition;
  };

  bulkEnqueueJobs = (jobs: JobDefinition[] | undefined) => {
    const { bulkEnqueueJobsWrapper } = this.props;

    if (jobs === undefined || jobs.length === 0) return;

    const jobMap = Object.assign({}, ...jobs.map((job) => ({ [uuidv4()]: job })));

    bulkEnqueueJobsWrapper?.({ jobs: jobMap }, (jobs) => {
      this.setState((currentState) => {
        return {
          awaitedJobs: produce(currentState.awaitedJobs, (draft) => ({
            ...draft,
            ...jobs,
          })),
        };
      });
    });
  };

  render() {
    const {
      classes,
      analyticsEventTracker,
      dataPanelTemplate,
      variables,
      userTransformedSchema,
      reportName,
      dashboardDatasets,
      customerToken,
    } = this.props;

    const { awaitedJobs } = this.state;

    const isDashboardLoaded = !(
      dataPanelTemplate._loading === true ||
      dataPanelTemplate._loading === undefined ||
      !!dataPanelTemplate._outstandingSecondaryDataRequests ||
      Object.keys(awaitedJobs).length > 0
    );

    let dashboardContent: JSX.Element;

    if (
      [OPERATION_TYPES.VISUALIZE_TABLE, OPERATION_TYPES.VISUALIZE_REPORT_BUILDER].includes(
        dataPanelTemplate.visualize_op.operation_type,
      ) &&
      isDashboardLoaded
    ) {
      const title =
        reportName ||
        dataPanelTemplate.visualize_op.generalFormatOptions?.headerConfig?.title ||
        '';

      dashboardContent = (
        <PDFExportTableView
          dataPanelTemplate={dataPanelTemplate}
          defaultUserTransformedSchema={userTransformedSchema}
          processTemplateValues={(text: string) =>
            replaceTemplatesWithValues(text, variables, dashboardDatasets)
          }
          reportTitle={title}
        />
      );
    } else {
      dashboardContent = (
        <div className={classes.root}>
          <DashboardDatasetView
            canDownloadDataPanel
            isViewOnly
            analyticsEventTracker={analyticsEventTracker}
            dataPanel={dataPanelTemplate}
            datasets={dashboardDatasets}
            defaultUserTransformedSchema={userTransformedSchema}
            isInContainer={false}
            isSelected={false}
            loading={
              dataPanelTemplate._loading === true ||
              dataPanelTemplate._loading === undefined ||
              !!dataPanelTemplate._outstandingSecondaryDataRequests
            }
            onAdHocOperationInstructionsUpdated={() => console.warn('Not supported in chart view')}
            onDownloadPanelPdf={() => console.warn('Not supported in chart view')}
            onDownloadPanelSpreadsheet={() => console.warn('Not supported in chart view')}
            pageType={PAGE_TYPE.SHARED}
            variables={variables}
          />
        </div>
      );
    }

    return (
      <div className={isDashboardLoaded ? 'explo-dashboard-loaded' : ''}>
        <Poller
          awaitedJobs={awaitedJobs}
          customerToken={customerToken}
          updateJobResult={(finishedJobIds, onComplete) => {
            if (finishedJobIds.length > 0)
              this.setState((currentState) => {
                const newAwaitedJobs = produce(currentState.awaitedJobs, (draft) =>
                  finishedJobIds.forEach((jobId) => delete draft[jobId]),
                );
                return { awaitedJobs: newAwaitedJobs };
              });

            onComplete();
          }}
        />
        {dashboardContent}
      </div>
    );
  }
}

ChartLayout.contextType = GlobalStylesContext;

export default withStyles(styles)(ChartLayout);
