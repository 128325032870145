import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ReduxState } from 'reducers/rootReducer';
import { sha256 } from 'js-sha256';

import OnboardingFlowPage from 'components/Onboarding/OnboardingFlowPage';
import PasswordInput from 'components/PasswordInput';
import Button from 'shared/Button';
import InfoCard from 'shared/InfoCard';

import { ACTION } from 'actions/types';
import { pageView } from 'analytics/exploAnalytics';
import { createLoadingSelector } from 'reducers/api/selectors';
import { passwordResetConfirm } from 'actions/authAction';
import { validatePassword } from 'utils/passwordUtils';
import { showErrorToast, showSuccessToast } from 'shared/sharedToasts';

const useStyles = makeStyles((theme: Theme) => ({
  signupInput: {
    marginTop: 2,
    marginBottom: theme.spacing(4),
  },
  resetButton: {
    width: '100%',
  },
  formContainer: {
    width: '100%',
  },
  errorCard: {
    height: 'fit-content',
    marginBottom: theme.spacing(6),
  },
}));

type MatchParams = {
  resetToken: string;
};

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  RouteComponentProps<MatchParams>;

const ResetPasswordPage = (props: Props) => {
  const { passwordResetConfirm, passwordResetConfirmLoading } = props;
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const classes = useStyles();

  useEffect(() => {
    pageView('Forgot password');
  });

  const onPasswordResetConfirmSubmitted = () => {
    passwordResetConfirm(
      {
        postData: {
          token: props.match.params.resetToken,
          password: sha256(password),
        },
      },
      () => {
        showSuccessToast('Password reset successfully.');
        setPassword('');
        setPassword2('');
        props.history.push('/login');
      },
      () => showErrorToast('Something went wrong. Please contact Explo support if this continues.'),
    );
  };
  const passwordError = password && password2 ? validatePassword(password, password2) : '';

  return (
    <OnboardingFlowPage
      rightContentTitle="Password Reset"
      rightPanelContent={
        <div className={classes.formContainer}>
          <PasswordInput
            className={classes.signupInput}
            data-testid="reset-password"
            label="New password"
            onChange={setPassword}
            value={password}
          />
          <PasswordInput
            className={classes.signupInput}
            data-testid="reset-password-confirm"
            label="Confirm new password"
            onChange={setPassword2}
            value={password2}
          />

          {passwordError ? (
            <InfoCard error className={classes.errorCard} text={passwordError} />
          ) : null}
          <Button
            className={classes.resetButton}
            data-testid="reset-password-submit"
            disabled={!password || !password2 || !!passwordError}
            loading={passwordResetConfirmLoading}
            onClick={() => onPasswordResetConfirmSubmitted()}
            text="Set new password"
            type="primary"
          />
        </div>
      }
    />
  );
};

const mapStateToProps = (state: ReduxState) => ({
  passwordResetConfirmLoading: createLoadingSelector([ACTION.PASSWORD_RESET_CONFIRM], false)(state),
});

const mapDispatchToProps = {
  passwordResetConfirm,
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetPasswordPage));
