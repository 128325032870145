import { useEffect, useState, useRef } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import Button from 'shared/Button';
import DataConfigTab from './DataConfigTab';
import NavTabs from 'components/core/navTabs';
import { FormatConfigTab } from './FormatConfigTab';
import { TextFieldModal } from 'components/modals/textFieldModal';

import { ReduxState } from 'reducers/rootReducer';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ACTION } from 'actions/types';
import { Dataset } from 'actions/datasetActions';
import { DatasetSchema } from 'types/datasets';
import { renameDataPanelV2 } from 'actions/dashboardV2Actions';
import { DashboardParam } from 'types/dashboardVersionConfig';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { DashboardElement } from 'types/dashboardTypes';
import {
  shouldRecomputeDataForDataPanel,
  shouldRecomputeSecondaryDataForDataPanel,
  dataPanelToConfig,
} from 'utils/dataPanelConfigUtils';

import { trackEvent, EVENTS } from 'analytics/exploAnalytics';
import { showSuccessToast } from 'shared/sharedToasts';
import { dpConfigUpdated } from 'utils/customEventUtils';

type ConfigTabInfo = { id: string; name: string };

const CONFIG_TABS: { [id: string]: ConfigTabInfo } = {
  DATA: {
    id: 'DATA',
    name: 'Data',
  },
  FORMAT: {
    id: 'FORMAT',
    name: 'Format',
  },
};

const TABS_HEIGHT = 40;
const HEADER_HEIGHT = 56;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.ds.white,
      borderRight: `1px solid ${theme.palette.ds.grey400}`,
    },
    bodyContainer: {
      backgroundColor: theme.palette.ds.white,
      height: `calc(100% - ${HEADER_HEIGHT}px)`,
    },
    configTabsMenu: {
      flexBasis: 0,
      height: TABS_HEIGHT,
      borderBottom: `1px solid ${theme.palette.ds.grey400}`,
    },
    configTabBtn: {
      width: '100%',
      height: TABS_HEIGHT,
    },
    configMenu: {
      height: `calc(100% - ${TABS_HEIGHT}px)`,
      overflowY: 'auto',
      overflowX: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },
    configurationHeader: {
      height: HEADER_HEIGHT,
      padding: theme.spacing(3),
      borderBottom: `1px solid ${theme.palette.ds.grey400}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    dataTableNameContainer: {
      width: '60%',
    },
    dataTableName: {
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      color: theme.palette.ds.grey700,
      fontSize: 'small',
    },
    headerActions: {
      display: 'flex',
      alignItems: 'center',
    },
    elementIdTag: {
      fontWeight: 'bold',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    pressedButton: {
      backgroundColor: `${theme.palette.ds.pressed.grey100} !important`,
      color: `${theme.palette.ds.grey900} !important`,
    },
    activeButton: {
      '& .bp3-icon': {
        color: `${theme.palette.ds.grey600} !important`,
      },
    },
    buttonWidth: {
      width: 32,
    },
  }),
);

type Props = {
  baseSchema: DatasetSchema;
  dataPanelTemplate: DataPanelTemplate;
  dataPanels: DataPanelTemplate[];
  dataset: Dataset;
  dashboardElements: DashboardElement[];
  duplicateDataPanel: (dataPanelTemplate: DataPanelTemplate) => void;
  dashboardDatasets: Record<string, Dataset>;
  dashboardParams: Record<string, DashboardParam>;
  onDeleteDataPanel: () => void;
  onCloseConfigClicked: () => void;
};

export default function DataPanelConfigV2({
  baseSchema,
  dashboardDatasets,
  dashboardElements,
  dashboardParams,
  dataPanels,
  dataPanelTemplate,
  dataset,
  duplicateDataPanel,
  onDeleteDataPanel,
  onCloseConfigClicked,
}: Props) {
  const [selectedConfigTabId, setSelectedConfigTabId] = useState(CONFIG_TABS.DATA.id);
  const [isDuplicatingItem, setIsDuplicatingItem] = useState(false);
  const [shouldShowDuplicateCompleteToast, setShouldShowDuplicateCompleteToast] = useState(false);
  const [isRenameDataPanelModalOpen, setIsRenameDataPanelModalOpen] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();

  const { isDataPanelRenameSaving, isDataPanelUpdating } = useSelector(
    (state: ReduxState) => ({
      isDataPanelRenameSaving: createLoadingSelector(
        [ACTION.RENAME_DATA_PANEL_TEMPLATE],
        false,
      )(state),
      isDataPanelUpdating: createLoadingSelector([ACTION.FETCH_DATA_PANEL_TEMPLATE], false)(state),
    }),
    shallowEqual,
  );

  const prevConfigRef = useRef(dataPanelTemplate);

  useEffect(() => {
    // Only recompute if the config changes because the data panel changed
    if (prevConfigRef.current.id === dataPanelTemplate.id) {
      const prevConfig = dataPanelToConfig(prevConfigRef.current);
      const newConfig = dataPanelToConfig(dataPanelTemplate);

      const shouldRecompute = shouldRecomputeDataForDataPanel(prevConfig, newConfig);

      // If shouldRecompute is true then this value won't be used
      const shouldRecomputeSecondaryData = shouldRecompute
        ? false
        : shouldRecomputeSecondaryDataForDataPanel(prevConfig, newConfig);

      dpConfigUpdated({
        shouldRecompute,
        shouldRecomputeSecondaryData,
        dataPanelId: dataPanelTemplate.id,
      });
    }

    prevConfigRef.current = dataPanelTemplate;
  }, [dataPanelTemplate]);

  const renderDashboardTitle = () => {
    return (
      <div className={classes.configurationHeader}>
        <div className={classes.dataTableNameContainer}>
          <div className={classes.elementIdTag}>{dataPanelTemplate.provided_id}</div>
          <div className={classes.dataTableName}>{dataset.table_name}</div>
        </div>
        <div className={classes.headerActions}>
          <Button
            minimal
            className={cx(classes.buttonWidth, {
              [classes.activeButton]: !isDataPanelRenameSaving,
            })}
            disabled={isDataPanelRenameSaving}
            icon="edit"
            loading={isDataPanelRenameSaving}
            onClick={() => setIsRenameDataPanelModalOpen(true)}
          />
          <Button
            minimal
            className={cx(classes.buttonWidth, {
              [classes.pressedButton]: shouldShowDuplicateCompleteToast,
              [classes.activeButton]: !(isDuplicatingItem || shouldShowDuplicateCompleteToast),
            })}
            disabled={isDuplicatingItem || shouldShowDuplicateCompleteToast}
            icon={shouldShowDuplicateCompleteToast ? 'tick' : 'duplicate'}
            onClick={() => {
              setIsDuplicatingItem(true);
              duplicateDataPanel(dataPanelTemplate);

              setIsDuplicatingItem(false);
              setShouldShowDuplicateCompleteToast(true);

              showSuccessToast(
                `${dataPanelTemplate.provided_id} has been duplicated below`,
                10,
                () => setShouldShowDuplicateCompleteToast(false),
              );
            }}
          />
          <Button
            minimal
            className={cx(classes.activeButton, classes.buttonWidth)}
            icon="trash"
            onClick={() => onDeleteDataPanel()}
            type="destructive"
          />
        </div>
      </div>
    );
  };

  const renderConfigBody = () => (
    <div className={classes.bodyContainer}>
      {renderTabsMenu()}
      <div className={classes.configMenu}>{renderTabConfigOptions()}</div>
    </div>
  );

  const renderTabsMenu = () => (
    <NavTabs
      className={classes.configTabsMenu}
      onTabSelect={(tabId) => switchConfigTab(tabId)}
      selectedTabId={selectedConfigTabId}
      tabClassName={classes.configTabBtn}
      tabs={[CONFIG_TABS.DATA, CONFIG_TABS.FORMAT]}
    />
  );

  const switchConfigTab = (tabId: string) => {
    if (selectedConfigTabId === tabId) return;
    setSelectedConfigTabId(tabId);
  };

  const renderTabConfigOptions = () => {
    switch (selectedConfigTabId) {
      case CONFIG_TABS.DATA.id:
        return (
          <DataConfigTab
            dashboardElements={dashboardElements}
            dashboardParams={dashboardParams}
            dataPanel={dataPanelTemplate}
            dataPanels={dataPanels}
            datasets={dashboardDatasets}
            loading={isDataPanelUpdating}
            schema={baseSchema}
          />
        );
      case CONFIG_TABS.FORMAT.id:
        return (
          <FormatConfigTab
            dashboardDatasets={dashboardDatasets}
            dataPanelData={dataPanelTemplate._rows || []}
            dataPanelTableId={String(dataPanelTemplate.table_id)}
            dataset={dataset}
            dptProvidedId={dataPanelTemplate.provided_id}
            generalFormatOptions={dataPanelTemplate.visualize_op.generalFormatOptions}
            instructions={dataPanelTemplate.visualize_op.instructions}
            onCloseConfigClicked={onCloseConfigClicked}
            schema={dataPanelTemplate._schema || []}
            visualizationType={dataPanelTemplate.visualize_op.operation_type}
          />
        );
      default:
        return <div></div>;
    }
  };

  const renderRenameDataPanelModal = () => {
    if (!isRenameDataPanelModalOpen) return;

    const existingDataPanelProvidedIds = dataPanels.reduce<string[]>((acc, dpt) => {
      if (dataPanelTemplate.id !== dpt.id) acc.push(dpt.provided_id);
      return acc;
    }, []);

    return (
      <TextFieldModal
        modalOpen
        buttonName="Save"
        closeModal={() => setIsRenameDataPanelModalOpen(false)}
        errorState={(dataPanelId) => {
          const trimmedDataPanelId = dataPanelId?.trim();
          if (!trimmedDataPanelId)
            return {
              isErrorState: true,
              errorMsg: 'ID cannot be empty',
            };
          const dataPanelIdExists = existingDataPanelProvidedIds.indexOf(trimmedDataPanelId) > -1;
          if (dataPanelIdExists)
            return {
              isErrorState: true,
              errorMsg: 'Data Panel IDs ust be unique',
            };

          return { isErrorState: false };
        }}
        modalTitle="Data Panel Name"
        onSubmit={(newId: string) => {
          setIsRenameDataPanelModalOpen(false);
          dispatch(renameDataPanelV2({ id: dataPanelTemplate.id, providedId: newId }));
          trackEvent(EVENTS.RENAMED_DATA_PANEL, { data_panel_template_id: dataPanelTemplate.id });
        }}
        resourceName={dataPanelTemplate.provided_id}
        textFieldPlaceholder="Enter an ID"
      />
    );
  };

  return (
    <div className={classes.root}>
      {renderDashboardTitle()}
      {renderConfigBody()}
      {renderRenameDataPanelModal()}
    </div>
  );
}
