import { FC } from 'react';
import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Icon, IconName } from '@blueprintjs/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.ds.lightBlue,
    borderRadius: 4,
    padding: theme.spacing(2),
    height: '100%',
    fontSize: 12,
    color: theme.palette.ds.blue,

    '&.error': {
      backgroundColor: theme.palette.ds.lightRed,
      color: theme.palette.ds.red,
    },
  },
  topMargin: {
    marginTop: theme.spacing(2),
  },
}));

type Props = {
  /**
   * Classname override
   */
  className?: string;
  error?: boolean;
  noTopMargin?: boolean;
  /**
   * Text to be displayed in the card
   */
  text: string | JSX.Element;
  leftIcon?: IconName;
};

const InfoCard: FC<Props> = ({ className, error, noTopMargin, text, leftIcon }) => {
  const classes = useStyles();

  return (
    <div
      className={cx(classes.root, className, { error, [classes.topMargin]: !noTopMargin })}
      data-testid="error-infocard">
      {leftIcon ? <Icon icon={leftIcon} style={{ marginRight: 8 }} /> : null}
      {text}
    </div>
  );
};

export default InfoCard;
