import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { IconName } from 'components/ds/Icon';
import { BaseListItem } from 'pages/ReportBuilder/HomeView/BaseListItem';

import {
  CustomerReport,
  deleteCustomerReport,
  starCustomerReport,
} from 'actions/customerReportActions';
import { openCustomerReport } from 'reportBuilderContent/reducers/reportEditingReducer';
import { getReportName } from 'utils/customerReportUtils';

interface Props {
  customerToken: string;
  report: CustomerReport;
}

export const ReportListItem: FC<Props> = ({ customerToken, report }) => {
  const dispatch = useDispatch();

  const handleDelete = useCallback(
    () =>
      dispatch(
        deleteCustomerReport({
          customerToken,
          postData: { report_id: report.id },
        }),
      ),
    [customerToken, dispatch, report.id],
  );

  return (
    <BaseListItem
      isStarred={report.is_starred}
      key={report.id}
      menuOptions={[
        {
          borderTop: true,
          icon: 'trash' as IconName,
          name: 'Delete',
          onClick: handleDelete,
        },
      ]}
      modified={report.modified}
      name={getReportName(report)}
      onClick={() => dispatch(openCustomerReport(report))}
      onClickStar={() =>
        dispatch(starCustomerReport({ customerToken, postData: { report_id: report.id } }))
      }
    />
  );
};
