import produce from 'immer';
import { useDispatch } from 'react-redux';

import SharedXAxisConfigs from 'components/ChartConfigs/XAxisConfigs';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import {
  OPERATION_TYPES,
  V2BoxPlotInstructions,
  V2ScatterPlotInstructions,
  V2TwoDimensionChartInstructions,
  XAxisFormat,
} from 'constants/types';
import { sharedStyles } from './styles';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2TwoDimensionChartInstructions | V2BoxPlotInstructions | V2ScatterPlotInstructions;
  isHorizontal?: boolean;
  enableScrolling?: boolean;
};

export default function XAxisConfig({
  visualizationType,
  instructions,
  isHorizontal,
  enableScrolling,
}: Props) {
  const classes = sharedStyles();
  const dispatch = useDispatch();

  const updateXAxisFormat = (xAxisUpdates: XAxisFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.xAxisFormat = {
        ...draft.xAxisFormat,
        ...xAxisUpdates,
      };
    });

    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  return (
    <div className={classes.root}>
      <SharedXAxisConfigs
        configInputClass={classes.configInput}
        enableScrolling={enableScrolling}
        instructions={instructions}
        isHorizontal={isHorizontal}
        operationType={visualizationType}
        updateXAxisFormat={updateXAxisFormat}
      />
    </div>
  );
}
