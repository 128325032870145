import { FC } from 'react';

import { sprinkles } from 'components/ds';

const headerContainerClass = sprinkles({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: 'sp1.5',
  borderBottom: 1,
  borderColor: 'gray7',
});

const titleClass = sprinkles({ body: 'section', textTransform: 'uppercase' });

type Props = {
  title: string;
  children?: JSX.Element | null;
};

export const ColumnHeader: FC<Props> = ({ title, children }) => {
  return (
    <div className={headerContainerClass} style={{ height: 56 }}>
      <div className={titleClass}>{title}</div>
      {children}
    </div>
  );
};
