import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    textAlign: 'right',
  },
  docLinkContainer: {
    marginTop: theme.spacing(1),
  },
  docLink: {
    color: theme.palette.ds.grey900,
  },
}));

type Props = {
  className?: string;
};

const ConnectFAQs = ({ className }: Props) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.docLinkContainer}>
        <a
          className={classes.docLink}
          href="https://docs.explo.co/data-sources/connecting-to-your-database-or-data-warehouse"
          rel="noopener noreferrer"
          target="_blank">
          FAQs
        </a>
      </div>
      <div className={classes.docLinkContainer}>
        <a
          className={classes.docLink}
          href="https://docs.explo.co/security/security-overview"
          rel="noopener noreferrer"
          target="_blank">
          Security Overview
        </a>
      </div>
    </div>
  );
};

export default ConnectFAQs;
