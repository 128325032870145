import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Icon } from '@blueprintjs/core';

import { Spinner } from 'components/ds';

import { TeamMember } from 'actions/userActions';
import { SettingsTeamMemberItem } from './settingsTeamMemberItem';

const useStyles = makeStyles((theme: Theme) => ({
  loadingBody: {
    height: 200,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  searchBarWrapper: {
    position: 'relative',
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(8),
  },
  searchBarInput: {
    border: '1px solid #C1CEE0',
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    paddingLeft: theme.spacing(8),
    borderRadius: '20px',
    height: '40px',
    color: theme.palette.ds.black,
    boxShadow: 'none',
    outline: 'none',
    fontSize: 14,
    width: '100%',
  },
  searchBarIcon: {
    width: 16,
    height: 16,
    position: 'absolute',
    left: 12,
    top: 'calc(50% - 8px)',
  },
}));

const compareUsers = (user1: TeamMember, user2: TeamMember) => {
  if (!user1.first_name || !user1.last_name || !user2.first_name || !user2.last_name) return 0;
  if (user1.first_name.toLowerCase() < user2.first_name.toLowerCase()) return -1;
  else if (
    user1.first_name.toLowerCase() === user2.first_name.toLowerCase() &&
    user1.last_name.toLowerCase() < user2.last_name.toLowerCase()
  )
    return -1;
  else return 1;
};

function SettingsTeamMemberList() {
  const [memberSearchQuery, setMemberSearchQuery] = useState('');
  const classes = useStyles();

  const teamId = useSelector((state: ReduxState) => state.currentUser.team?.id);
  const teamData = useSelector((state: ReduxState) => state.teamData);

  const teamMembers = teamData.data?.team_members ?? [];

  if (!teamId || teamData.loading) {
    return (
      <div className={classes.loadingBody}>
        <Spinner fillContainer size="lg" />
      </div>
    );
  }

  const memberSearchQueryLower = memberSearchQuery.toLowerCase();

  const filteredInvites = memberSearchQueryLower
    ? teamData.invitedUsers.filter((user) =>
        user.email.toLowerCase().includes(memberSearchQueryLower),
      )
    : [...teamData.invitedUsers];

  const filteredTeamMembers =
    memberSearchQueryLower && teamMembers
      ? teamMembers.filter(
          (user) =>
            !user.email ||
            user.first_name?.toLowerCase().includes(memberSearchQueryLower) ||
            user.last_name?.toLowerCase().includes(memberSearchQueryLower) ||
            user.email.toLowerCase().includes(memberSearchQueryLower),
        )
      : [...teamMembers];

  return (
    <div>
      <div className={classes.searchBarWrapper}>
        <Icon className={classes.searchBarIcon} icon={'search'} />
        <input
          className={classes.searchBarInput}
          onChange={(e) => setMemberSearchQuery(e.currentTarget.value)}
          placeholder="Search"
          value={memberSearchQuery}
        />
      </div>
      {filteredInvites.sort().map((user) => (
        <SettingsTeamMemberItem invitedUser={user} key={user.email} />
      ))}
      {filteredTeamMembers.sort(compareUsers).map((user) => (
        <SettingsTeamMemberItem key={user.id} user={user} />
      ))}
    </div>
  );
}

export default SettingsTeamMemberList;
