import { FC } from 'react';
import produce from 'immer';

import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';

import { TextInputElemConfig } from 'types/dashboardTypes';

type Props = {
  config: TextInputElemConfig;

  updateInputConfig: (config: TextInputElemConfig) => void;
};

export const TextInputValuesConfig: FC<Props> = ({ config, updateInputConfig }) => {
  const updateConfig = (limitEntriesToNumbers: boolean) => {
    const newConfig = produce(config, (draft) => {
      draft.limitEntriesToNumbers = limitEntriesToNumbers;
    });
    updateInputConfig(newConfig);
  };

  return (
    <ToggleButtonGroup fillWidth label="Allowable entries">
      <ToggleButton
        active={!config.limitEntriesToNumbers}
        onClick={() => updateConfig(false)}
        text="All characters"
      />
      <ToggleButton
        active={config.limitEntriesToNumbers}
        onClick={() => updateConfig(true)}
        text="Only numbers"
      />
    </ToggleButtonGroup>
  );
};
