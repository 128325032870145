import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import * as styles from './styles.css';
import { PageHeader } from 'components/PageHeader';
import { SchemaDataSourceGroup } from './SchemaDataSourceGroup';
import { DataSourceItem } from './DataSourceItem';
import { SyncTablesModal } from 'pages/DataPage/modals/SyncTablesModal';
import ExploLoadingSpinner from 'components/ExploLoadingSpinner';
import { sprinkles, AlertModal } from 'components/ds';
import { ManageSchemasModal } from './modals/ManageSchemasModal';

import {
  listTeamDataSources,
  deleteDataSource,
  DataSource,
  ParentSchema,
} from 'actions/dataSourceActions';
import { fetchUserTeam } from 'actions/teamActions';
import { fetchUsedParentSchemas, fetchAllSchemaTables } from 'actions/parentSchemaActions';
import { ReduxState } from 'reducers/rootReducer';
import { ACTION } from 'actions/types';
import { createLoadingSelector } from 'reducers/api/selectors';
import { groupBy, isEmpty, sortBy } from 'utils/standard';
import { doesUserHavePermission } from 'utils/permissionUtils';
import { PERMISSIONED_ACTIONS, PERMISSIONED_ENTITIES } from 'constants/roleConstants';
import { ROUTES } from 'constants/routes';
import { showErrorToast, showSuccessToast } from 'shared/sharedToasts';
import { isCreateDataSourceDisabled } from 'utils/paymentPlanUtils';
import { AccessGroup } from 'actions/teamActions';
import { syncParentSchema } from 'actions/parentSchemaActions';
import { TOAST_TIMEOUT } from './constants';

enum ModalStatus {
  DELETE_DATASOURCE = 'delete_datasource',
  DELETE_SCHEMA = 'delete_schema',
  SYNC_TABLES = 'sync_tables',
  MANAGE_SCHEMA = 'manage_schema',
  CLOSED = 'closed',
}

const getAccessGroupNamesforDatasource = (accessGroups: AccessGroup[], dataSourceId: number) => {
  return accessGroups.filter((accessGroup) =>
    accessGroup.default_data_source_ids.includes(dataSourceId),
  );
};

const getAccessGroupsforSchema = (
  accessGroups: AccessGroup[] | undefined,
  schemaDataSources: DataSource[],
) => {
  if (!accessGroups) return [];
  return schemaDataSources.map((dataSource) =>
    getAccessGroupNamesforDatasource(accessGroups, dataSource.id).map(
      (accessGroup) => accessGroup.name,
    ),
  );
};

export const DataPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    dataSources,
    parentSchemas,
    parentSchemaTablesMap,
    pageDataLoading,
    teamData,
    permissions,
  } = useSelector(
    (state: ReduxState) => ({
      dataSources: state.dataSourceList.dataSources,
      parentSchemas: state.parentSchemas.usedParentSchemas || [],
      parentSchemaTablesMap: state.parentSchemas.schemaTablesMap || {},
      teamData: state.teamData.data,
      pageDataLoading: createLoadingSelector(
        [ACTION.LIST_TEAM_DATA_SOURCES, ACTION.FETCH_USED_PARENT_SCHEMAS, ACTION.FETCH_USER_TEAM],
        true,
      )(state),
      permissions: state.currentUser.permissions,
    }),
    shallowEqual,
  );

  const [selectedDataSource, setSelectedDataSource] = useState<DataSource>();
  const [selectedParentSchema, setSelectedParentSchema] = useState<ParentSchema>();
  const [searchString, setSearchString] = useState('');
  const [modalStatus, setModalStatus] = useState(ModalStatus.CLOSED);
  const [dataSourceDeleteLoading, setDataSourceDeleteLoading] = useState(false);

  useEffect(() => {
    dispatch(listTeamDataSources());
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchUsedParentSchemas());
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchAllSchemaTables());
  }, [dispatch]);
  useEffect(() => {
    if (!teamData) dispatch(fetchUserTeam());
  }, [teamData, dispatch]);

  const dataSourcesBySchemaId = useMemo(
    () => groupBy(dataSources ?? [], 'parent_schema_id'),
    [dataSources],
  );

  const dataSourcePermissions = permissions[PERMISSIONED_ENTITIES.DATA_SOURCE];

  const isCreateDataSourceDisabledForPaymentPlan = isCreateDataSourceDisabled(
    dataSources || [],
    teamData?.payment_plan,
  );

  const userCanEditDataSource = doesUserHavePermission(
    dataSourcePermissions,
    PERMISSIONED_ACTIONS.UPDATE,
  );

  const userCanDeleteDataSource = doesUserHavePermission(
    dataSourcePermissions,
    PERMISSIONED_ACTIONS.DELETE,
  );

  const filteredDataSourcesBySchema = useMemo(() => {
    return parentSchemas.map((schema) => {
      let dataSources = dataSourcesBySchemaId[schema.id];
      if (!isEmpty(searchString)) {
        dataSources = dataSources.filter(
          (dataSource) =>
            dataSource.name.toLowerCase().includes(searchString) ||
            (dataSource.provided_id ?? dataSource.id).toString().includes(searchString) ||
            schema.name.toLowerCase().includes(searchString),
        );
      }

      return sortBy(dataSources, 'id') ?? [];
    });
  }, [parentSchemas, dataSourcesBySchemaId, searchString]);

  const renderSchemaGroups = () => {
    return filteredDataSourcesBySchema.map((dataSources, schemaIndex) => {
      if (dataSources.length === 0) return null;
      const parentSchema = parentSchemas[schemaIndex];

      const shouldSyncDataTables =
        isEmpty(parentSchemaTablesMap[parentSchema.id]) && userCanEditDataSource;

      // Need to choose the default Data Source tag labels at the schema level
      const schemaAccessGroups = getAccessGroupsforSchema(teamData?.access_groups, dataSources);
      const schemaHasOneAccessGroup = schemaAccessGroups.flat().length === 1;

      const dataSourceItems = dataSources.map((dataSource, index) => {
        return (
          <DataSourceItem
            accessGroupNames={schemaAccessGroups[index]}
            dataSource={dataSource}
            deleteLoading={dataSourceDeleteLoading && dataSource.id === selectedDataSource?.id}
            key={dataSource.id}
            onDelete={
              userCanDeleteDataSource
                ? () => {
                    setSelectedDataSource(dataSource);
                    setModalStatus(ModalStatus.DELETE_DATASOURCE);
                  }
                : undefined
            }
            onSyncTables={
              shouldSyncDataTables
                ? () => {
                    setSelectedParentSchema(parentSchema);
                    setModalStatus(ModalStatus.SYNC_TABLES);
                  }
                : undefined
            }
            schemaHasOneAccessGroup={schemaHasOneAccessGroup}
            userCanEdit={userCanEditDataSource}
          />
        );
      });

      return (
        <SchemaDataSourceGroup
          id={parentSchema.id}
          isSearching={!isEmpty(searchString)}
          key={parentSchema.id}
          name={parentSchema.name}
          onDelete={
            userCanDeleteDataSource
              ? () => {
                  setSelectedParentSchema(parentSchema);
                  setModalStatus(ModalStatus.DELETE_SCHEMA);
                }
              : undefined
          }
          userCanEdit={userCanEditDataSource}>
          {dataSourceItems}
        </SchemaDataSourceGroup>
      );
    });
  };

  if (pageDataLoading) return <ExploLoadingSpinner />;

  const closeModal = () => setModalStatus(ModalStatus.CLOSED);

  const onDeleteDataSource = () => {
    setDataSourceDeleteLoading(true);
    dispatch(
      deleteDataSource(
        { id: selectedDataSource?.id },
        () => {
          setSelectedDataSource(undefined);
          setDataSourceDeleteLoading(false);

          showSuccessToast('Data source successfully deleted.', TOAST_TIMEOUT);
        },
        (error) => {
          setSelectedDataSource(undefined);
          setDataSourceDeleteLoading(false);

          showErrorToast(error.detail, TOAST_TIMEOUT);
        },
      ),
    );

    setModalStatus(ModalStatus.CLOSED);
  };

  const onDeleteSchema = () => {
    const newSchemas = parentSchemas.filter((s) => s.id !== selectedParentSchema?.id);

    dispatch(
      syncParentSchema(
        { postData: { edited_schemas: newSchemas } },
        () => {
          setSelectedParentSchema(undefined);

          showSuccessToast('Schema successfully deleted.', TOAST_TIMEOUT);
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (error: any) => {
          setSelectedParentSchema(undefined);

          showErrorToast(error._error ?? 'Deletion failed.', TOAST_TIMEOUT);
        },
      ),
    );

    setModalStatus(ModalStatus.CLOSED);
  };

  return (
    <div className={styles.outerContainer}>
      <PageHeader
        className={sprinkles({ backgroundColor: 'white' })}
        pageTitle="Data Sources"
        primaryActionProps={
          doesUserHavePermission(dataSourcePermissions, PERMISSIONED_ACTIONS.CREATE)
            ? {
                disabled: isCreateDataSourceDisabledForPaymentPlan,
                text: 'Connect Data Source',
                tooltipText: isCreateDataSourceDisabledForPaymentPlan
                  ? 'Upgrade your plan to add more data sources.'
                  : undefined,
                to: ROUTES.CONNECT_DATA_SOURCE,
              }
            : undefined
        }
        searchBarPlaceholderText="Search by schema name, data source name, or data source ID"
        searchBarSubmit={(searchStringSupplied) =>
          setSearchString(searchStringSupplied.toLowerCase().trim())
        }
        secondaryActionProps={
          userCanEditDataSource
            ? {
                text: 'Manage Schemas',
                onClick: () => setModalStatus(ModalStatus.MANAGE_SCHEMA),
              }
            : undefined
        }
      />
      <div className={styles.scrollContainer}>
        <div className={styles.dataPageContainer}>
          <div className={styles.contentContainer}>{renderSchemaGroups()}</div>
          {modalStatus === ModalStatus.MANAGE_SCHEMA ? (
            <ManageSchemasModal
              closeModal={() => {
                setSelectedParentSchema(undefined);
                setModalStatus(ModalStatus.CLOSED);
              }}
              modalOpen={modalStatus === ModalStatus.MANAGE_SCHEMA}
              parentSchemas={parentSchemas}
              selectedSchemaId={selectedParentSchema?.id}
            />
          ) : null}
          {modalStatus === ModalStatus.DELETE_DATASOURCE ? (
            <AlertModal
              actionButtonProps={{
                text: `Delete ${selectedDataSource?.name}`,
                onClick: onDeleteDataSource,
              }}
              isOpen={modalStatus === ModalStatus.DELETE_DATASOURCE}
              onClose={closeModal}
              title="Are you sure you want to delete this data source?"
            />
          ) : null}
          {modalStatus === ModalStatus.DELETE_SCHEMA ? (
            <AlertModal
              actionButtonProps={{
                text: `Delete ${selectedParentSchema?.name}`,
                onClick: onDeleteSchema,
              }}
              isOpen={modalStatus === ModalStatus.DELETE_SCHEMA}
              onClose={closeModal}
              title="Are you sure you want to delete this schema?"
            />
          ) : null}
          {modalStatus === ModalStatus.SYNC_TABLES ? (
            <SyncTablesModal
              closeModal={() => {
                setModalStatus(ModalStatus.CLOSED);
                setSelectedParentSchema(undefined);
              }}
              modalOpen={modalStatus === ModalStatus.SYNC_TABLES}
              onSync={() =>
                history.push(ROUTES.SYNC_DATA_TABLES_NO_SCHEMA + `/${selectedParentSchema?.id}`)
              }
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
