import { defineAPIPostAction } from './actionUtils';
import { PingTypes } from 'constants/types';
import { ACTION } from './types';

type SendPingBody = {
  message: string;
  message_type: PingTypes;
};

export const { actionFn: sendPing } = defineAPIPostAction<SendPingBody, {}>(
  ACTION.SEND_PING,
  'ping',
  'send_ping',
  'POST',
);
