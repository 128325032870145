import cx from 'classnames';
import { Popover, Position, Menu, MenuItem, MenuDivider } from '@blueprintjs/core';
import { sprinkles } from 'components/ds';

import Button from 'shared/Button';
import { sharedStyles } from './sharedDroppedStyles';
import { ColumnOptionsPopover } from './ColumnOptionsPopover';
import { DatasetSchema, DatasetColumn } from 'types/datasets';

import { PeriodRangeTypes, PERIOD_RANGE_OPTIONS } from 'types/dateRangeTypes';
import { ConfigurableColumnInfo } from 'constants/types';

type Props = {
  column: ConfigurableColumnInfo;
  draggingClass?: string;
  draggingPopoverClass?: string;
  onColAdded: (newCol: DatasetColumn, oldColName?: string) => void;

  onColOptionChanged: (option: { id: string; name: string }) => void;
  onRemoveCol: () => void;
  disableEdits?: boolean;
  periodRange: PeriodRangeTypes | undefined;
  schema: DatasetSchema;
};

export default function DroppedPeriodColumn({
  column,
  draggingClass,
  draggingPopoverClass,
  onColAdded,
  onColOptionChanged,
  onRemoveCol,
  disableEdits,
  periodRange,
  schema,
}: Props) {
  const sharedClasses = sharedStyles();

  const selectedOption = periodRange && PERIOD_RANGE_OPTIONS[periodRange];

  return (
    <div className={cx(sprinkles({ borderRadius: 3, display: 'flex' }), draggingClass)}>
      <div
        className={cx(
          sharedClasses.popoverTargetContainer,
          sprinkles({ width: 'half' }),
          draggingPopoverClass,
        )}>
        <div className={sharedClasses.name}>{column.column.name}</div>
        <ColumnOptionsPopover
          rightDivider
          onColChange={(newCol) => onColAdded(newCol, column.column.name)}
          schema={schema}
        />
      </div>
      <div
        className={cx(
          sharedClasses.popoverTargetContainer,
          sprinkles({ width: 'half' }),
          draggingPopoverClass,
        )}>
        <div className={sharedClasses.name}>{selectedOption ? selectedOption.id : undefined}</div>
        <Popover
          captureDismiss
          fill
          minimal
          content={
            <Menu>
              {Object.values(PERIOD_RANGE_OPTIONS).map((option, i) => (
                <MenuItem
                  className={sharedClasses.menuItemOption}
                  disabled={disableEdits}
                  key={`col-config-option-${i}`}
                  onClick={() => !disableEdits && onColOptionChanged(option)}
                  text={option.name}
                />
              ))}
              <MenuDivider />
              <MenuItem
                disabled={disableEdits}
                onClick={() => !disableEdits && onRemoveCol()}
                text="Remove"
              />
            </Menu>
          }
          position={Position.BOTTOM_RIGHT}>
          <Button
            compact
            minimal
            className={cx(sharedClasses.iconBtn, sharedClasses.rightDivider)}
            icon="caret-down"
          />
        </Popover>
        <Button
          compact
          minimal
          className={sharedClasses.iconBtn}
          disabled={disableEdits}
          icon="cross"
          onClick={() => !disableEdits && onRemoveCol()}
        />
      </div>
    </div>
  );
}
