import produce from 'immer';

import { Switch } from 'components/ds';

import { AreaChartFormat, V2TwoDimensionChartInstructions } from 'constants/types';

type Props = {
  configInputClass: string;
  instructions: V2TwoDimensionChartInstructions;
  updateInstructions: (instructions: V2TwoDimensionChartInstructions) => void;
};

export default function SharedAreaChartConfig({
  configInputClass,
  instructions,
  updateInstructions,
}: Props) {
  const { chartSpecificFormat } = instructions;
  const { areaChart } = chartSpecificFormat ?? {};
  const reverseGroupOrder = areaChart?.reverseGroupOrder ?? false;

  const updateAreaChartFormat = (areaChartUpdates: AreaChartFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.chartSpecificFormat = {
        ...draft.chartSpecificFormat,
        areaChart: { ...draft.chartSpecificFormat?.areaChart, ...areaChartUpdates },
      };
    });
    updateInstructions(newInstructions);
  };

  return (
    <Switch
      useCustomStyles
      className={configInputClass}
      label="Reverse Group Order"
      onChange={() => updateAreaChartFormat({ reverseGroupOrder: !reverseGroupOrder })}
      switchOn={reverseGroupOrder}
    />
  );
}
