import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';

import OnboardingFlowPage from 'components/Onboarding/OnboardingFlowPage';
import InputWithTag from 'shared/InputWithTag';
import Button from 'shared/Button';
import InfoCard from 'shared/InfoCard';

import { pageView } from 'analytics/exploAnalytics';
import { passwordResetRequest } from 'actions/authAction';
import { showCustomToast } from 'shared/sharedToasts';

const useStyles = makeStyles((theme: Theme) => ({
  signupInput: {
    width: '100%',
    marginBottom: theme.spacing(6),
  },
  signInButton: {
    width: '100%',
  },
  formContainer: {
    width: '100%',
  },
  errorCard: {
    height: 'fit-content',
    marginBottom: theme.spacing(6),
  },
}));

export default function ForgotPasswordPage(): JSX.Element {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [passwordResetLoading, setPasswordResetLoading] = useState(false);

  useEffect(() => {
    pageView('Forgot password');
  }, []);

  const onPasswordResetRequestSubmitted = () => {
    if (email.trim() === '') {
      setErrorMsg('Please enter an email.');
    } else {
      setErrorMsg('');
      setPasswordResetLoading(true);
      dispatch(
        passwordResetRequest(
          { postData: { email } },
          () => {
            showCustomToast('If the email address is valid, an email was sent successfully.', {
              icon: 'endorsed',
            });
            setEmail('');
            setPasswordResetLoading(false);
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (response: any) => {
            if (Object.keys(response).length > 0) {
              let error;
              if (response.email) {
                error = response.email[0];
              } else if (response.non_field_errors) {
                error = response.non_field_errors[0];
              }

              if (error) setErrorMsg(error);
            }
            setPasswordResetLoading(false);
          },
        ),
      );
    }
  };

  return (
    <OnboardingFlowPage
      rightContentSubTitle="We’ll email you a link to set a new password."
      rightContentTitle="Password Reset"
      rightPanelContent={
        <div className={classes.formContainer}>
          <InputWithTag
            className={classes.signupInput}
            data-testid="forgot-password-email"
            label="Work email"
            onChange={setEmail}
            placeholder="michael@dundermifflin.com"
            value={email}
          />
          {errorMsg ? <InfoCard error className={classes.errorCard} text={errorMsg} /> : null}

          <Button
            className={classes.signInButton}
            data-testid="forgot-password-submit"
            loading={passwordResetLoading}
            onClick={() => onPasswordResetRequestSubmitted()}
            text="Send password reset link"
            type="primary"
          />
        </div>
      }
    />
  );
}
