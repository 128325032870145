import { Component } from 'react';
import { sortBy } from 'utils/standard';
import { connect } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import cx from 'classnames';

import { Modal, sprinkles } from 'components/ds';
import { Menu, MenuItem, MenuDivider, Icon, IconName } from '@blueprintjs/core';

import { Dataset } from 'actions/datasetActions';
import { titleCase } from 'utils/graphUtils';

const styles = (theme: Theme) => ({
  modalBodyList: {
    height: '40vh',
    backgroundColor: theme.palette.ds.white,
    overflowY: 'auto' as const,
    border: `1px solid ${theme.palette.ds.grey200}`,
    borderRadius: '4px',
    marginBottom: theme.spacing(6),
  },
  actionTitle: {
    fontSize: 16,
    marginBottom: theme.spacing(5),
    fontWeight: 500,
  },
  selectedOption: {
    backgroundColor: theme.palette.ds.white,
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
  selectedOptionText: {},
  selectedOptionValue: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 60px)',
  },
  selectedOptionValueName: {
    marginLeft: theme.spacing(2),
    width: '90%',
    overflow: 'hidden',
    whitespace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  selectedMenuItem: {
    background: theme.palette.ds.lightBlue,
  },
});

type PassedProps = {
  modalOpen: boolean;
  closeModal: () => void;
  onCreate: (datasetId: string, name: string) => void;
  dashboardTemplateId: number;
};

type State = {
  selectedDataset?: Dataset;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  PassedProps &
  WithStyles<typeof styles>;

class AddDataPanelModal extends Component<Props> {
  state: State = {};

  render() {
    const { classes, modalOpen, closeModal } = this.props;
    const { selectedDataset } = this.state;
    return (
      <Modal
        isOpen={modalOpen}
        onClose={closeModal}
        primaryButtonProps={{
          disabled: !selectedDataset,
          onClick: this.onCreateClicked,
          text: 'Create',
        }}
        secondaryButtonProps={{
          onClick: closeModal,
          text: 'Cancel',
        }}
        size="small"
        title="Add a new data panel">
        <div className={sprinkles({ paddingX: 'sp3', flexItems: 'column', margin: 'sp0' })}>
          <div className={classes.actionTitle}>Select a table</div>
          {this.renderDatasetList()}
        </div>
      </Modal>
    );
  }

  onCreateClicked = () => {
    const { onCreate } = this.props;
    const { selectedDataset } = this.state;
    if (!selectedDataset) return;
    if (!selectedDataset) return;
    onCreate(selectedDataset.id, titleCase(selectedDataset.table_name));
  };

  renderDatasetList = () => {
    const { classes, dashboardDatasets } = this.props;

    const dashboardDatasetsOrdered = sortBy(dashboardDatasets, (dataset) => dataset.table_name);

    return (
      <div className={classes.modalBodyList}>
        <Menu>
          <MenuDivider title="Dashboard Datasets"></MenuDivider>
          {dashboardDatasetsOrdered.length === 0 && (
            <MenuItem disabled text="No datasets. Create one!" />
          )}
          {dashboardDatasetsOrdered.map((dataset) => this.renderDatasetItem(dataset))}
        </Menu>
      </div>
    );
  };

  renderDatasetItem = (dataset: Dataset) => {
    const { selectedDataset } = this.state;
    const { classes } = this.props;

    if ((dataset.schema ?? []).length === 0) return null;

    // Making sure that unnamed datasets would still be displayed on the data panel selection screen
    return (
      <MenuItem
        className={cx({ [classes.selectedMenuItem]: dataset.id === selectedDataset?.id })}
        icon="th"
        key={`dataset-add-data-panel-${dataset.id}`}
        onClick={() => this.setState({ selectedDataset: dataset })}
        text={dataset.table_name || 'Untitled'}
      />
    );
  };

  renderSelectedOption = (selectText: string, selectedName: string, icon: IconName) => {
    const { classes } = this.props;

    return (
      <div className={classes.selectedOption}>
        <div className={classes.selectedOptionText}>{selectText}: </div>
        <div className={classes.selectedOptionValue}>
          <Icon icon={icon} /> <div className={classes.selectedOptionValueName}>{selectedName}</div>
        </div>
      </div>
    );
  };

  renderConfirmPage = () => {
    const { selectedDataset } = this.state;
    if (!selectedDataset) return;
    return <>{this.renderSelectedOption('Dataset', selectedDataset.table_name, 'th')}</>;
  };
}

const mapStateToProps = (state: ReduxState) => ({
  dashboardDatasets: Object.values(state.dashboardEditConfig.config?.datasets || {}),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddDataPanelModal));
