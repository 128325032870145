import { createAction } from '@reduxjs/toolkit';
import { Layout } from '@explo-tech/react-grid-layout';

import { DataPanelData, FilterOperation, VisualizeOperation } from 'types/dataPanelTemplate';
import { ACTION } from './types';
import { CanvasFilter } from './canvasConfigActions';
import { FilterClause, OPERATION_TYPES } from 'constants/types';

export interface ArchitectCustomerDashboardConfig {
  data_panels: Record<string, EndUserDataPanel>;
  filters: string[];
  layout: Layout[];
  custom_components?: CustomComponents;
}

// ID used in layout to ID of component in canvas config
export type CustomComponents = Record<string, string>;

export interface EndUserDataPanel extends DataPanelData {
  id: string;
  provided_id: string;
  dataset_id: string;
  template_id: string | null;

  filter_op?: FilterOperation;

  visualize_op: VisualizeOperation;
}

export const updateEUDLayout = createAction<Layout[]>(ACTION.UPDATE_END_USER_DASHBOARD_LAYOUT);

export const toggleEUDFilter = createAction<{
  filter: CanvasFilter;
  isFilterInDashboard: boolean;
}>(ACTION.TOGGLE_END_USER_DASHBOARD_FILTER);

export const copyEUDTemplate = createAction<{ templateId: string; isCustomComponent: boolean }>(
  ACTION.COPY_END_USER_DASHBOARD_TEMPLATE,
);

export const createEUDataPanel = createAction(ACTION.CREATE_END_USER_DATA_PANEL);

export const updateEUDataPanelDataset = createAction<string>(
  ACTION.UPDATE_END_USER_DATA_PANEL_DATASET,
);

export const updateEUDataPanelVizOp = createAction<VisualizeOperation>(
  ACTION.UPDATE_END_USER_DATA_PANEL_VIZ_OP,
);

export const updateEUDataPanelChartType = createAction<{
  operationType: OPERATION_TYPES;
  isSubType: boolean;
}>(ACTION.UPDATE_END_USER_DATA_PANEL_CHART_TYPE);

export const updateEUDataPanelFilters = createAction<FilterClause[]>(
  ACTION.UPDATE_END_USER_DATA_PANEL_FILTERS,
);

export const deleteEUDItem = createAction(ACTION.DELETE_END_USER_DASHBOARD_ITEM);
