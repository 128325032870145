import { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { ErrorFallback } from 'components/ds';
import { reportError } from 'analytics/datadog';
import BaseDashboardDatasetView, { PassedProps } from './DashboardDatasetView';

export const DashboardDatasetView: FC<PassedProps> = (props) => {
  return (
    <ErrorBoundary
      FallbackComponent={({ error, resetErrorBoundary }) => (
        <ErrorFallback
          error={{ ...error, message: 'Chart failed to load' }}
          resetErrorBoundary={resetErrorBoundary}
        />
      )}
      onError={reportError}>
      <BaseDashboardDatasetView {...props} />
    </ErrorBoundary>
  );
};
