import { createReducer } from '@reduxjs/toolkit';
import {
  AdminAction,
  AdminTeam,
  AdminUser,
  getSuperuserActionSuccess,
  getSuperuserTeamsSuccess,
  getTeamUsersSuccess,
} from 'actions/superuserActions';

interface SuperuserReducerState {
  actions?: AdminAction[];
  teams?: AdminTeam[];
  users?: AdminUser[];
}
const initialState: SuperuserReducerState = {};
export default createReducer(initialState, (builder) => {
  builder
    .addCase(getSuperuserActionSuccess, (state, { payload }) => {
      state.actions = payload.actions;
    })
    .addCase(getSuperuserTeamsSuccess, (state, { payload }) => {
      state.teams = payload.teams;
    })
    .addCase(getTeamUsersSuccess, (state, { payload }) => {
      state.users = payload.users;
    });
});
