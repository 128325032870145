import { useState, FC } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Icon } from '@blueprintjs/core';
import produce from 'immer';

import {
  SortableList,
  SortableListItem,
  SortableListItemDragHandle,
} from 'components/SortableList/SortableList';
import FlexBox, { VerticalAlignment } from 'components/core/FlexBox';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import Button from 'shared/Button';
import SettingHeader from '../../DataConfigTab/SettingHeader';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import {
  FunnelChartFormat,
  OPERATION_TYPES,
  V2TwoDimensionChartInstructions,
} from 'constants/types';
import { sharedStyles } from './styles';

const useStyles = makeStyles((theme: Theme) => ({
  infoBox: {
    background: theme.palette.ds.grey200,
    borderRadius: 4,
    fontWeight: 400,
    fontSize: 14,
    color: theme.palette.ds.grey900,
    padding: theme.spacing(2),
  },
  iconColor: { color: theme.palette.ds.grey800 },
  dragHandle: {
    '&:hover': {
      cursor: 'grab',
    },
    '&:active': {
      cursor: 'grabbing',
    },
  },
  greyBox: {
    background: theme.palette.ds.grey200,
    borderRadius: 4,
    fontWeight: 400,
    fontSize: 14,
    color: theme.palette.ds.grey900,
    margin: `${theme.spacing(2)}px 0px`,
    padding: `${theme.spacing(1)}px 0px`,
  },
  row: {
    padding: `0px ${theme.spacing(1)}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  input: {
    '& .bp3-input': {
      height: 24,
    },
  },
}));

type Props = {
  visualizationType: OPERATION_TYPES.VISUALIZE_FUNNEL_V2;
  instructions: V2TwoDimensionChartInstructions;
};

export default function FunnelChartSortingConfig({ visualizationType, instructions }: Props) {
  const sharedClasses = sharedStyles();
  const dispatch = useDispatch();
  const classes = useStyles();

  const funnelChart = instructions.chartSpecificFormat?.funnelChart;
  const [isAddingStage, setIsAddingStage] = useState(funnelChart?.sortedStages === undefined);
  const stages = funnelChart?.sortedStages ? [...funnelChart.sortedStages] : [];

  const updateFunnelInstructions = (updatedFunnelChart: FunnelChartFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.chartSpecificFormat = {
        ...draft.chartSpecificFormat,
        funnelChart: {
          ...funnelChart,
          ...updatedFunnelChart,
        },
      };
    });

    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  return (
    <>
      <SettingHeader
        actionBtnConfig={{
          actionBtnIcon: 'plus',
          onActionBtnClicked: () => setIsAddingStage(true),
        }}
        name="Sorting"
      />
      <div className={sharedClasses.root}>
        <div className={sharedClasses.configInput}>
          <div className={classes.infoBox}>
            Stages must match the values in your data. Excluded stages will be shown at the end of
            the funnel.
          </div>

          {stages.length !== 0 ? (
            <SortableList
              getIdFromElem={(item) => item}
              onListUpdated={(newList) =>
                updateFunnelInstructions({
                  sortedStages: newList,
                })
              }
              sortableItems={stages}>
              {stages.map((stage, i) => (
                <SortableListItem key={stage} sortId={stage}>
                  <SortableStage
                    deleteStage={() => {
                      stages.splice(i, 1);
                      updateFunnelInstructions({
                        sortedStages: stages.length === 0 ? undefined : stages,
                      });
                    }}
                    key={stage}
                    stage={stage}
                    updateStageName={(value) => {
                      const trimmedValue = value.trim();
                      if (trimmedValue !== '' && !stages.includes(trimmedValue)) {
                        stages.splice(i, 1, trimmedValue);
                        updateFunnelInstructions({ sortedStages: stages });
                      }
                    }}
                  />
                </SortableListItem>
              ))}
            </SortableList>
          ) : null}
          {isAddingStage ? (
            <div className={classes.greyBox}>
              <FlexBox className={classes.row} verticalAlignment={VerticalAlignment.CENTER}>
                <Icon className={classes.iconColor} icon="drag-handle-vertical" />
                <InputWithBlurSave
                  fillWidth
                  hideRightIconInteractions
                  className={classes.input}
                  onNewValueSubmitted={(value: string) => {
                    const trimmedValue = value.trim();
                    if (trimmedValue !== '' && !stages.includes(trimmedValue)) {
                      stages.push(trimmedValue);
                      updateFunnelInstructions({ sortedStages: stages });
                    }
                    setIsAddingStage(false);
                  }}
                  placeholder="Stage"
                />
                <Button
                  minimal
                  icon={<Icon className={classes.iconColor} icon="trash" />}
                  onClick={() => setIsAddingStage(false)}
                  type="secondary"
                />
              </FlexBox>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

type SortableStageProps = {
  deleteStage: () => void;
  stage: string;
  updateStageName: (name: string) => void;
};

const SortableStage: FC<SortableStageProps> = ({ deleteStage, stage, updateStageName }) => {
  const classes = useStyles();

  return (
    <div className={classes.greyBox}>
      <FlexBox className={classes.row} verticalAlignment={VerticalAlignment.CENTER}>
        <SortableListItemDragHandle className={classes.iconColor} />
        <InputWithBlurSave
          fillWidth
          hideRightIconInteractions
          className={classes.input}
          initialValue={stage}
          onNewValueSubmitted={updateStageName}
          placeholder="Stage"
        />
        <Button
          minimal
          icon={<Icon className={classes.iconColor} icon="trash" />}
          onClick={deleteStage}
          type="secondary"
        />
      </FlexBox>
    </div>
  );
};
