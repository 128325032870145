import { useState, useCallback, useEffect } from 'react';

import DropdownSelect from 'shared/DropdownSelect';
import { SelectedDropdownInputItem } from 'constants/types';

type Props = {
  code?: string;
  containerClassName?: string;
  label: string;
  noSelectionText: string;
  onSelect: (item: SelectedDropdownInputItem) => void;
  options: { name: string; id: string }[];
};

export default function SettingsInternationalizationDropdown(props: Props) {
  const { code, containerClassName, label, noSelectionText, onSelect, options } = props;
  const getSelectedItemFromCode = useCallback(
    (code?: string) => (code ? options.find((item) => item.id === code) : undefined),
    [options],
  );

  const [setting, setSetting] = useState<{ name: string; id: string } | undefined>(
    getSelectedItemFromCode(code),
  );

  useEffect(() => {
    setSetting(getSelectedItemFromCode(code));
  }, [code, getSelectedItemFromCode]);

  return (
    <div className={containerClassName}>
      <DropdownSelect
        fillWidth
        minimal
        filterable={false}
        label={label}
        noSelectionText={noSelectionText}
        onChange={onSelect}
        options={options}
        selectedItem={setting ?? undefined}></DropdownSelect>
    </div>
  );
}
