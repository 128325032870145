import { FC, useEffect, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { PreviewBuiltIn } from 'pages/ReportBuilder/BuiltInView/PreviewBuiltIn';
import { ReportBuilderHomeView } from './HomeView';
import { ReportBuilderReportView } from './ReportView';

import { CustomerReport } from 'actions/customerReportActions';
import { ReportBuilderTab } from 'pages/ReportBuilder/HomeView/constants';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { EmbedReportBuilder } from 'actions/reportBuilderActions';
import { getRBStateFromURL } from './utils/nagivationUtils';
import { openCustomerReport } from 'reportBuilderContent/reducers/reportEditingReducer';
import { keyBy } from 'utils/standard';
import {
  getSelectedEmbedBuiltIn,
  setSelectedTab,
} from 'reportBuilderContent/reducers/embeddedReportBuilderReducer';

type Props = { reportBuilder: EmbedReportBuilder };

export const ReportBuilder: FC<Props> = ({ reportBuilder }) => {
  const dispatch = useDispatch();

  const { selectedReportId, currentConfig, reports, selectedBuiltIn } = useSelector(
    (state: ReportBuilderReduxState) => ({
      selectedReportId: state.reportEditing.selectedReportId,
      currentConfig: state.reportEditing.currentConfig,
      reports: state.embeddedReportBuilder.reports,
      selectedBuiltIn: getSelectedEmbedBuiltIn(state.embeddedReportBuilder),
    }),
    shallowEqual,
  );

  // reports should not trigger the URL parameters useEffect below
  const reportsRef = useRef<CustomerReport[]>(reports);
  useEffect(() => {
    reportsRef.current = reports;
  }, [reports]);

  // Set the current tab, report, and view based off URL parameters on initial load
  useEffect(() => {
    const navState = getRBStateFromURL();
    const reportsById = keyBy(reportsRef.current, 'id');
    if (
      navState.homeTab &&
      Object.values(ReportBuilderTab).includes(navState.homeTab as ReportBuilderTab)
    ) {
      dispatch(setSelectedTab({ tab: navState.homeTab as ReportBuilderTab }));
    } else if (navState.reportId && reportsById[navState.reportId]) {
      const report = reportsById[navState.reportId];
      let selectedViewId = navState.viewId;
      if (
        selectedViewId &&
        report.config.views?.find((view) => view.id === selectedViewId) === undefined
      ) {
        selectedViewId = undefined;
      }
      dispatch(openCustomerReport({ ...reportsById[navState.reportId], viewId: selectedViewId }));
    }
  }, [dispatch]);

  const report = selectedReportId
    ? reports.find((report) => report.id === selectedReportId)
    : undefined;

  if (report && currentConfig) {
    return <ReportBuilderReportView currentConfig={currentConfig} report={report} />;
  }

  if (selectedBuiltIn) {
    return <PreviewBuiltIn builtInReport={selectedBuiltIn} />;
  }

  return <ReportBuilderHomeView reportBuilder={reportBuilder} />;
};
