import { FC, useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';
import { uniqueId } from 'utils/standard';
import * as RD from 'remotedata';

import Button from 'shared/Button';
import { DashboardGrid } from './dashboardGrid';
import { ArchitectCustomerDashboard } from './architectCustomerDashboard';
import { Icon } from '@blueprintjs/core';
import { Spinner } from 'components/ds';

import getFingerprintUser from 'analytics/fingerprint';
import { loadFonts } from 'globalStyles/helpers';
import { ArchitectCustomerDashboardReduxState } from 'architectCustomerDashboardContent/reducers/rootReducer';
import {
  fetchArchitectCustomerDashboards,
  openArchitectCustomerDashboard,
  closeArchitectCustomerDashboard,
  createArchitectCustomerDashboard,
} from 'actions/architectCustomerDashboardActions';
import { EmbeddedDashboardType } from 'components/EmbeddedDashboard/types';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { getQueryVariables } from 'components/EmbeddedDashboard/EmbeddedDashboard';
import { GlobalStylesProvider } from 'globalStyles';
import { getArchitectCustomerDashboardName } from 'utils/architectCustomerDashboardUtils';
import { CanvasViewType, EmbedCanvas } from 'actions/canvasActions';
import {
  AnalyticsMetadata,
  Metadata,
  sendAnalyticsEvent,
  sendAnalyticsEventWrapper,
  getEmbedSource,
} from 'utils/analyticsUtils';
import { setUser } from 'analytics/datadog';
import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import { getCurrentDashboardInfo } from 'reducers/architectCustomerDashboardReducer';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  multiRoot: { padding: 10 },
  tabBar: {
    height: 36,
    width: '100%',
    backgroundColor: theme.palette.ds.grey200,
    border: `1px solid ${theme.palette.ds.grey400}`,
    display: 'flex',
  },
  homeButton: {
    width: 36,
    height: '100%',
    backgroundColor: theme.palette.ds.grey300,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: `1px solid ${theme.palette.ds.grey400}`,
  },
  tab: {
    borderRight: `1px solid ${theme.palette.ds.grey400}`,
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  selectedTab: {
    backgroundColor: theme.palette.ds.grey100,
    cursor: 'default',
  },
  tabName: {
    color: theme.palette.ds.grey900,
    fontSize: 12,
    fontWeight: 500,
    marginRight: theme.spacing(2),
  },
  closeTab: {
    color: theme.palette.ds.grey800,
    cursor: 'pointer',
  },
  container: {
    flex: 1,
    width: '100%',
  },
  multiContainer: {
    border: `1px solid ${theme.palette.ds.grey400}`,
    borderTop: 'none',
  },
  allDashboards: {
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.ds.grey100,
    padding: theme.spacing(4),
  },
  header: {
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
  title: {
    fontWeight: 500,
    fontSize: 20,
    color: theme.palette.ds.grey900,
  },
  errorMessage: {
    margin: theme.spacing(10),
    fontSize: 24,
    padding: theme.spacing(10),
    backgroundColor: '#FAE1E1',
    borderRadius: 8,
  },
}));

const UNIQUE_ID_FOR_MOUNTING_PORTALS = uniqueId('explo-eud');

type Props = {
  canvasEmbedId: string;
  customerToken: string;
  userId: string | undefined;
  embedType: EmbeddedDashboardType;

  embeddedVariables?: DashboardVariableMap;
  refreshMinutes?: number;
  updateUrlParams?: boolean;
  environment?: string;
  isViewOnly?: boolean;
  analyticsProperties?: Metadata;
  //TODO: implement locale
  localeCode?: string;
  timezone?: string;
};

export const EndUserMultiDashboardWrapper: FC<Props> = (props) => {
  const globalStyleConfig = useSelector(
    (state: ArchitectCustomerDashboardReduxState) => state.dashboardStyles.globalStyleConfig,
  );
  const team = useSelector(
    (state: ArchitectCustomerDashboardReduxState) => state.architectCustomerDashboard.team,
  );

  const [urlVariables] = useState<DashboardVariableMap>(
    getQueryVariables(props.embedType, props.updateUrlParams),
  );

  useEffect(() => {
    if (team !== null) loadFonts(globalStyleConfig.text, team.id, team.payment_plan);
  }, [globalStyleConfig, team]);

  return (
    <GlobalStylesProvider globalStyleConfig={globalStyleConfig}>
      {(globalStylesClassName) => (
        <MultiDashboardView
          globalStylesClassName={globalStylesClassName}
          urlVariables={urlVariables}
          {...props}
        />
      )}
    </GlobalStylesProvider>
  );
};

type PageProps = {
  globalStylesClassName: string;
  urlVariables: DashboardVariableMap;
} & Props;

const MultiDashboardView: FC<PageProps> = ({
  customerToken,
  userId,
  environment,
  canvasEmbedId,
  isViewOnly,
  globalStylesClassName,
  embedType,
  embeddedVariables,
  urlVariables,
  analyticsProperties,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [visitorId, setVisitorId] = useState('');
  const [analyticsMetadata, setAnalyticsMetadata] = useState<AnalyticsMetadata | undefined>();

  const { dashboards, openDashboardId, tabbedDashboards, canvas, team } = useSelector(
    (state: ArchitectCustomerDashboardReduxState) => ({
      dashboards: state.architectCustomerDashboard.dashboards,
      openDashboardId: state.architectCustomerDashboard.openDashboardId,
      tabbedDashboards: state.architectCustomerDashboard.tabbedDashboards,
      canvas: state.architectCustomerDashboard.canvas,
      team: state.architectCustomerDashboard.team,
    }),
    shallowEqual,
  );

  const currDashboardInfo = useSelector(
    (state: ArchitectCustomerDashboardReduxState) =>
      getCurrentDashboardInfo(state.architectCustomerDashboard),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(
      fetchArchitectCustomerDashboards(
        {
          customerToken,
          postData: {
            canvas_embed_id: canvasEmbedId,
            version_number: null,
            user_id: userId ?? null,
            environment,
          },
        },
        (data) => {
          const metadata: AnalyticsMetadata = {
            team_id: data.team.id,
            team_name: data.team.name,
            customer_id: data.customer.id,
            customer_name: data.customer.name,
            customer_provided_id: data.customer.provided_id,
            customer_is_demo: data.customer.is_demo_group,
            blueprint_id: data.canvas.id,
            blueprint_name: data.canvas.name,
          };

          setAnalyticsMetadata(metadata);

          setUser({
            endUserId: data.customer.id,
            endUserName: data.customer.name,
            teamId: data.team.id,
            teamName: data.team.name,
          });

          const sendPageViewAnalytics = async () => {
            // Get the (browser) visitor identifier:
            const fingerprintUser = await getFingerprintUser();
            setVisitorId(fingerprintUser.visitorId);

            sendAnalyticsEvent(
              REPORTED_ANALYTIC_ACTION_TYPES.END_USER_DASHBOARD_PAGE_VIEWED,
              fingerprintUser.visitorId,
              customerToken,
              data.team.explo_analytics_token,
              { ...metadata, environment, embed_source: embedType },
              analyticsProperties,
            );
          };

          sendPageViewAnalytics();
        },
      ),
    );
  }, [canvasEmbedId, customerToken, userId, dispatch, environment, analyticsProperties, embedType]);

  const analyticsEventTracker = useCallback(
    (eventName: REPORTED_ANALYTIC_ACTION_TYPES, metadata?: Metadata) => {
      const dashboardMetadata = currDashboardInfo
        ? {
            dashboard_id: currDashboardInfo.id ?? 'base_dashboard',
            dashboard_name: currDashboardInfo.name,
          }
        : undefined;

      sendAnalyticsEventWrapper(
        visitorId,
        embedType,
        getEmbedSource(embedType),
        customerToken,
        team?.explo_analytics_token,
        eventName,
        analyticsMetadata,
        { ...dashboardMetadata, ...metadata },
        analyticsProperties,
      );
    },
    [
      visitorId,
      currDashboardInfo,
      embedType,
      analyticsProperties,
      analyticsMetadata,
      team,
      customerToken,
    ],
  );

  const renderTabBar = () => {
    return (
      <div className={classes.tabBar}>
        <div
          className={cx(classes.homeButton, {
            [classes.selectedTab]: openDashboardId === undefined,
          })}
          onClick={() => dispatch(openArchitectCustomerDashboard(undefined))}>
          <Icon color="#616161" icon="grid-view" size={16} />
        </div>
        {tabbedDashboards.map((dashboardId) => {
          const dashboard = dashboards?.find((dash) => dash.id === dashboardId);
          if (dashboard === undefined) return;
          return (
            <div
              className={cx(
                { [classes.selectedTab]: openDashboardId === dashboardId },
                classes.tab,
              )}
              key={dashboardId}
              onClick={() => dispatch(openArchitectCustomerDashboard(dashboardId))}>
              <div className={classes.tabName}>{getArchitectCustomerDashboardName(dashboard)}</div>
              <Icon
                className={classes.closeTab}
                icon="small-cross"
                onClick={(e) => {
                  dispatch(closeArchitectCustomerDashboard(dashboardId));
                  e.stopPropagation();
                }}
                size={16}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const renderDashboardView = (canvas: EmbedCanvas) => {
    const multiDashboardView = canvas.configurability?.viewType !== CanvasViewType.SINGLE;
    return (
      <div
        className={cx(classes.root, globalStylesClassName, {
          [classes.multiRoot]: multiDashboardView,
        })}
        id={UNIQUE_ID_FOR_MOUNTING_PORTALS}>
        {multiDashboardView ? renderTabBar() : null}
        <div className={cx(classes.container, { [classes.multiContainer]: multiDashboardView })}>
          {openDashboardId !== undefined || !multiDashboardView ? (
            <ArchitectCustomerDashboard
              analyticsEventTracker={analyticsEventTracker}
              canvasEmbedId={canvasEmbedId}
              customerToken={customerToken}
              embedType={embedType}
              embeddedVariables={embeddedVariables}
              isViewOnly={isViewOnly}
              key={multiDashboardView ? openDashboardId : undefined}
              portalContainerId={UNIQUE_ID_FOR_MOUNTING_PORTALS}
              urlVariables={urlVariables}
              userId={userId}
            />
          ) : (
            <div className={classes.allDashboards}>
              <div className={classes.header}>
                <div className={classes.title}>All Dashboards</div>
                <Button
                  icon="plus"
                  onClick={() =>
                    dispatch(
                      createArchitectCustomerDashboard(
                        {
                          customerToken,
                          postData: {
                            canvas_embed_id: canvasEmbedId,
                            user_id: userId ?? null,
                            name: 'New Dashboard',
                            config: { data_panels: {}, filters: [], layout: [] },
                          },
                        },
                        (data) =>
                          analyticsEventTracker(
                            REPORTED_ANALYTIC_ACTION_TYPES.END_USER_DASHBOARD_CREATED,
                            {
                              dashboard_id: data.dashboard.id as number,
                              dashboard_name: data.dashboard.name,
                            },
                          ),
                      ),
                    )
                  }
                  text="New Dashboard"
                  type="primary"
                />
              </div>
              <DashboardGrid
                analyticsEventTracker={analyticsEventTracker}
                canvasEmbedId={canvasEmbedId}
                customerToken={customerToken}
                userId={userId ?? null}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <RD.RemoteComponent
      Error={(error) => <div className={classes.errorMessage}>{error}</div>}
      Loading={() => <Spinner fillContainer />}
      Success={renderDashboardView}
      data={canvas}
    />
  );
};
