import { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import theme from 'theme';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import FlexBox, { VerticalAlignment, HorizontalAlignment } from 'components/core/FlexBox';
import { IconName } from '@blueprintjs/icons';

const useStyles = makeStyles(() => ({
  noLabelDash: {
    marginTop: 0,
  },
  inputLeft: {
    marginRight: theme.spacing(2),
    '& input.': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  inputRight: {
    '& input': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      marginLeft: -1,
    },
  },
}));

export type Props = {
  /**
   * optional class to wrap entire range input
   */
  className?: string;
  /**
   * label shown above the start range input
   */
  startLabel?: string;
  /**
   * label shown above the end range input
   */
  endLabel?: string;
  /**
   * Value for start of the range
   */
  startVal?: string;
  /**
   * Value for end of range
   */
  endVal?: string;
  /**
   * Called when either start or end is updated
   */
  onNewRange: (start?: string, end?: string) => void;
  /**
   * If values entered can use variables
   */
  usesVariables?: boolean;
};

const RangeInput: FC<Props> = ({
  className,
  onNewRange,
  startLabel,
  endLabel,
  startVal,
  endVal,
  usesVariables,
}) => {
  const classes = useStyles();

  const variableProps = usesVariables
    ? { helpIcon: 'code' as IconName, helpText: 'This field can take in variables' }
    : {};

  return (
    <FlexBox
      className={className}
      horizontalAlignment={HorizontalAlignment.SPACE_BETWEEN}
      verticalAlignment={VerticalAlignment.CENTER}>
      <InputWithBlurSave
        {...variableProps}
        fillWidth
        containerClassName={classes.inputLeft}
        initialValue={String(startVal ?? '')}
        label={startLabel}
        onNewValueSubmitted={(newStartString: string) => {
          if (newStartString === '') {
            return onNewRange(undefined, endVal);
          }
          onNewRange(newStartString, endVal);
        }}
        placeholder="Min"
        useFakeLabel={!startLabel && !!endLabel}
      />
      <InputWithBlurSave
        {...variableProps}
        fillWidth
        containerClassName={classes.inputRight}
        initialValue={String(endVal ?? '')} // if no label, then don't fake a label on second input
        label={endLabel}
        onNewValueSubmitted={(newEndString: string) => {
          if (newEndString === '') {
            return onNewRange(startVal, undefined);
          }
          onNewRange(startVal, newEndString);
        }}
        placeholder="Max"
        useFakeLabel={!endLabel && !!startLabel}
      />
    </FlexBox>
  );
};

export default RangeInput;
