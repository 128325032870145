import * as types from 'constants/types';
import { OPERATION_TYPES, REGION_TYPES, RegionMetaData, Aggregation } from 'constants/types';
import {
  DECIMAL_ICON,
  TEXT_ICON,
  NUMBER_ICON,
  V2_PIE_CHART_ICON,
  V2_DONUT_CHART_ICON,
  V2_AREA_CHART_ICON,
  V2_LINE_CHART_ICON,
  V2_AREA_100_CHART_ICON,
  V2_VERT_BAR_CHART_ICON,
  V2_VERT_100_BAR_CHART_ICON,
  V2_COMBO_CHART_ICON,
  V2_VERT_GROUPED_BAR_CHART_ICON,
  V2_HORIZ_BAR_CHART_ICON,
  V2_HORIZ_100_BAR_CHART_ICON,
  V2_HORIZ_GROUPED_BAR_CHART_ICON,
  V2_FUNNEL_CHART_ICON,
  V2_BAR_FUNNEL_CHART_ICON,
  V2_HEAT_MAP_CHART_ICON,
  V2_KPI_CHART_ICON,
  V2_BOX_PLOT_CHART_ICON,
  V2_PROGRESS_CHART_ICON,
  V2_NUMBER_TREND_ICON,
  V2_MAP_CHART_ICON,
  REPORT_BUILDER_ICON,
  SCATTER_PLOT_ICON,
  SPIDER_CHART_ICON,
  V2_VERT_GROUPED_STACKED_BAR_CHART_ICON,
  V2_HORIZ_GROUPED_STACKED_BAR_CHART_ICON,
  COLLAPSIBLE_LIST_ICON,
  V2_TREND_TABLE_ICON,
} from 'constants/iconConstants';
import { IconName } from '@blueprintjs/core';
import {
  TextElemHorizAlignments,
  TextElemVerticalAlignments,
  TEXT_ELEM_HORIZ_ALIGNMENTS,
  TEXT_ELEM_VERTICAL_ALIGNMENTS,
} from 'types/dashboardTypes';

export const INTEGER_DATA_TYPE: types.DataType = 'INTEGER';
export const UNSIGNED_INTEGER: types.DataType = 'unsigned integer';
export const FLOAT: types.DataType = 'FLOAT';
export const STRING: types.DataType = 'STRING';
export const BOOLEAN: types.DataType = 'BOOLEAN';
export const DATETIME: types.DataType = 'DATETIME';
export const DATE: types.DataType = 'DATE';
export const TIMESTAMP: types.DataType = 'TIMESTAMP';

export const NUMBER_TYPES = new Set([INTEGER_DATA_TYPE, UNSIGNED_INTEGER, FLOAT]);
export const TIME_COLUMN_TYPES = new Set([DATE, DATETIME, TIMESTAMP]);
export const DEFAULT_GROUPING_TYPES = new Set([STRING, BOOLEAN, DATETIME, DATE, TIMESTAMP]);

export const VIZ_OP_WITH_CSV_DOWNLOAD = new Set([
  OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2,
  OPERATION_TYPES.VISUALIZE_AREA_CHART_V2,
  OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2,
  OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2,
  OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2,
  OPERATION_TYPES.VISUALIZE_FUNNEL_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2,
  OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2,
  OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2,
  OPERATION_TYPES.VISUALIZE_SPIDER_CHART,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_LINE_CHART_V2,
  OPERATION_TYPES.VISUALIZE_PIE_CHART_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_MAP_V2,
]);

export const HORIZONTAL_BAR_VIZ_OPS = new Set([
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2,
]);

export const KPI_VIZ_OPS = new Set([
  OPERATION_TYPES.VISUALIZE_NUMBER_V2,
  OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2,
]);

export const VIZ_OPS_WITH_CATEGORY_SELECT_DRILLDOWN = new Set([
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2,
]);

export const VIZ_OPS_WITH_CUSTOM_MENU = new Set([
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_PIE_CHART_V2,
  OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2,
  OPERATION_TYPES.VISUALIZE_LINE_CHART_V2,
  OPERATION_TYPES.VISUALIZE_AREA_CHART_V2,
  OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2,
  OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2,
  OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2,
]);

export const DATE_TYPES = new Set([TIMESTAMP, DATETIME, DATE]);

export const SELECTABLE_DATA_TYPES: types.SelectableDataTypes = [
  STRING,
  INTEGER_DATA_TYPE,
  FLOAT,
  BOOLEAN,
  TIMESTAMP,
];

export const MAX_ROWS_TO_PREVIEW = 50;

export const SCHEMA_DATA_TYPES_BY_ID: types.SchemaDataTypesById = {
  [INTEGER_DATA_TYPE]: {
    name: 'Integer',
    icon: NUMBER_ICON,
  },
  [UNSIGNED_INTEGER]: {
    name: 'Unsigned Integer',
    icon: NUMBER_ICON,
  },
  [FLOAT]: {
    name: 'Decimal',
    icon: DECIMAL_ICON,
  },
  [STRING]: {
    name: 'Text',
    icon: TEXT_ICON,
  },
  [BOOLEAN]: {
    name: 'Boolean',
    icon: 'tick',
  },
  [DATETIME]: {
    name: 'Date',
    icon: 'calendar',
  },
  [DATE]: {
    name: 'Date',
    icon: 'calendar',
  },
  [TIMESTAMP]: {
    name: 'Timestamp',
    icon: 'calendar',
  },
};

export const DATE_PART_INPUT_AGG = 'DATE_PART_INPUT_AGG';

export const AGGREGATIONS_TYPES: Record<Aggregation, { id: Aggregation; name: string }> = {
  COUNT: {
    id: Aggregation.COUNT,
    name: 'Count',
  },
  COUNT_DISTINCT: {
    id: Aggregation.COUNT_DISTINCT,
    name: 'Count Distinct',
  },
  AVG: {
    id: Aggregation.AVG,
    name: 'Avg',
  },
  SUM: {
    id: Aggregation.SUM,
    name: 'Sum',
  },
  MIN: {
    id: Aggregation.MIN,
    name: 'Min',
  },
  MAX: {
    id: Aggregation.MAX,
    name: 'Max',
  },
  '25_PERCENTILE': {
    id: Aggregation['25_PERCENTILE'],
    name: '25th Percentile',
  },
  MEDIAN: {
    id: Aggregation.MEDIAN,
    name: 'Median',
  },
  '75_PERCENTILE': {
    id: Aggregation['75_PERCENTILE'],
    name: '75th Percentile',
  },
  FORMULA: {
    id: Aggregation.FORMULA,
    name: 'Formula',
  },
  FIRST: {
    id: Aggregation.FIRST,
    name: 'First',
  },
};

export const FORMULA_AGG_TYPE = AGGREGATIONS_TYPES[Aggregation.FORMULA];

export const NON_AGG_TYPE = AGGREGATIONS_TYPES[Aggregation.FIRST];

export const AGGREGATIONS_LIST = Object.values(AGGREGATIONS_TYPES).filter(
  (agg) => agg.id !== Aggregation.FORMULA && agg.id !== Aggregation.FIRST,
);

export const NON_NUMBER_AGGREGATIONS_LIST = [
  AGGREGATIONS_TYPES.COUNT,
  AGGREGATIONS_TYPES.COUNT_DISTINCT,
];

export const COLLAPSIBLE_LIST_AGGREGATION_LIST = [
  AGGREGATIONS_TYPES.COUNT,
  AGGREGATIONS_TYPES.COUNT_DISTINCT,
  AGGREGATIONS_TYPES.SUM,
];

export const OP_TYPE_TO_BP3_ICON: types.OperationIconsDictionary = {
  VISUALIZE_TABLE: 'th',
  FILTER: 'filter',
  SORT: 'sort',
  GROUP_BY: 'filter',
  VISUALIZE_VERTICAL_BAR_V2: V2_VERT_BAR_CHART_ICON,
  VISUALIZE_VERTICAL_100_BAR_V2: V2_VERT_100_BAR_CHART_ICON,
  VISUALIZE_VERTICAL_GROUPED_BAR_V2: V2_VERT_GROUPED_BAR_CHART_ICON,
  VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2: V2_VERT_GROUPED_STACKED_BAR_CHART_ICON,
  VISUALIZE_HORIZONTAL_BAR_V2: V2_HORIZ_BAR_CHART_ICON,
  VISUALIZE_HORIZONTAL_100_BAR_V2: V2_HORIZ_100_BAR_CHART_ICON,
  VISUALIZE_HORIZONTAL_GROUPED_BAR_V2: V2_HORIZ_GROUPED_BAR_CHART_ICON,
  VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2: V2_HORIZ_GROUPED_STACKED_BAR_CHART_ICON,
  VISUALIZE_PIE_CHART_V2: V2_PIE_CHART_ICON,
  VISUALIZE_DONUT_CHART_V2: V2_DONUT_CHART_ICON,
  VISUALIZE_LINE_CHART_V2: V2_LINE_CHART_ICON,
  VISUALIZE_AREA_CHART_V2: V2_AREA_CHART_ICON,
  VISUALIZE_AREA_100_CHART_V2: V2_AREA_100_CHART_ICON,
  VISUALIZE_COMBO_CHART_V2: V2_COMBO_CHART_ICON,
  VISUALIZE_HEAT_MAP_V2: V2_HEAT_MAP_CHART_ICON,
  VISUALIZE_SCATTER_PLOT_V2: SCATTER_PLOT_ICON,
  VISUALIZE_SPIDER_CHART: SPIDER_CHART_ICON,
  VISUALIZE_FUNNEL_V2: V2_FUNNEL_CHART_ICON,
  VISUALIZE_VERTICAL_BAR_FUNNEL_V2: V2_BAR_FUNNEL_CHART_ICON,
  VISUALIZE_NUMBER_V2: V2_KPI_CHART_ICON,
  VISUALIZE_BOX_PLOT_V2: V2_BOX_PLOT_CHART_ICON,
  VISUALIZE_PROGRESS_V2: V2_PROGRESS_CHART_ICON,
  VISUALIZE_NUMBER_TREND_V2: V2_NUMBER_TREND_ICON,
  VISUALIZE_MAP_V2: V2_MAP_CHART_ICON,
  VISUALIZE_REPORT_BUILDER: REPORT_BUILDER_ICON,
  VISUALIZE_PIVOT_REPORT_BUILDER: REPORT_BUILDER_ICON,
  VISUALIZE_PIVOT_TABLE: 'th',
  VISUALIZE_COLLAPSIBLE_LIST: COLLAPSIBLE_LIST_ICON,
  VISUALIZE_TREND_TABLE: V2_TREND_TABLE_ICON,
};

export const VIZ_TO_SECTION: Record<string, string> = {
  [types.OPERATION_TYPES.VISUALIZE_NUMBER_V2]: types.ChartTypeSections.PERFORMANCE_INDICATOR,
  [types.OPERATION_TYPES.VISUALIZE_PROGRESS_V2]: types.ChartTypeSections.PERFORMANCE_INDICATOR,
  [types.OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2]: types.ChartTypeSections.PERFORMANCE_INDICATOR,

  [types.OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2]: types.ChartTypeSections.BAR_CHART,
  [types.OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2]: types.ChartTypeSections.BAR_CHART,
  [types.OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2]: types.ChartTypeSections.BAR_CHART,
  [types.OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2]:
    types.ChartTypeSections.BAR_CHART,
  [types.OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2]: types.ChartTypeSections.BAR_CHART,
  [types.OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2]: types.ChartTypeSections.BAR_CHART,
  [types.OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2]: types.ChartTypeSections.BAR_CHART,
  [types.OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2]:
    types.ChartTypeSections.BAR_CHART,

  [types.OPERATION_TYPES.VISUALIZE_LINE_CHART_V2]: types.ChartTypeSections.LINE_CHART,
  [types.OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2]: types.ChartTypeSections.LINE_CHART,

  [types.OPERATION_TYPES.VISUALIZE_AREA_CHART_V2]: types.ChartTypeSections.AREA_CHART,
  [types.OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2]: types.ChartTypeSections.AREA_CHART,

  [types.OPERATION_TYPES.VISUALIZE_PIE_CHART_V2]: types.ChartTypeSections.PIE_CHART,
  [types.OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2]: types.ChartTypeSections.PIE_CHART,

  [types.OPERATION_TYPES.VISUALIZE_FUNNEL_V2]: types.ChartTypeSections.FUNNEL,
  [types.OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2]: types.ChartTypeSections.FUNNEL,

  [types.OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2]: types.ChartTypeSections.SPECIAL,
  [types.OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2]: types.ChartTypeSections.SPECIAL,
  [types.OPERATION_TYPES.VISUALIZE_SPIDER_CHART]: types.ChartTypeSections.SPECIAL,
  [types.OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2]: types.ChartTypeSections.SPECIAL,
  [types.OPERATION_TYPES.VISUALIZE_MAP_V2]: types.ChartTypeSections.SPECIAL,
  [types.OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST]: types.ChartTypeSections.SPECIAL,
  [types.OPERATION_TYPES.VISUALIZE_TREND_TABLE]: types.ChartTypeSections.SPECIAL,
};

export const VIZ_TO_NAME: Record<string, string> = {
  [types.OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2]: 'Bar Chart',
  [types.OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2]: '100% Bar Chart',
  [types.OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2]: 'Grouped Bar Chart',
  [types.OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2]: 'Grouped Stacked Bar Chart',
  [types.OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2]: 'Horizontal Bar Chart',
  [types.OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2]: '100% Horizontal Bar Chart',
  [types.OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2]: 'Horizontal Grouped Bar Chart',
  [types.OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2]:
    'Horizontal Grouped Stacked Bar Chart',
  [types.OPERATION_TYPES.VISUALIZE_PIE_CHART_V2]: 'Pie Chart',
  [types.OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2]: 'Donut Chart',
  [types.OPERATION_TYPES.VISUALIZE_LINE_CHART_V2]: 'Line Chart',
  [types.OPERATION_TYPES.VISUALIZE_AREA_CHART_V2]: 'Area Chart',
  [types.OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2]: '100% Area Chart',
  [types.OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2]: 'Combo Chart',
  [types.OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2]: 'Heat Map',
  [types.OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2]: 'Scatter Plot',
  [types.OPERATION_TYPES.VISUALIZE_SPIDER_CHART]: 'Spider Chart',
  [types.OPERATION_TYPES.VISUALIZE_FUNNEL_V2]: 'Funnel',
  [types.OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2]: 'Bar Funnel',
  [types.OPERATION_TYPES.VISUALIZE_NUMBER_V2]: 'KPI',
  [types.OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2]: 'Box Plot',
  [types.OPERATION_TYPES.VISUALIZE_PROGRESS_V2]: 'Progress',
  [types.OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2]: 'KPI Trend',
  [types.OPERATION_TYPES.VISUALIZE_MAP_V2]: 'Geographic Map',
  [types.OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST]: 'Collapsible List',
  [types.OPERATION_TYPES.VISUALIZE_TABLE]: 'Table',
  [types.OPERATION_TYPES.VISUALIZE_TREND_TABLE]: 'Trend Table',
};

// If chart types have different types in this map, then when a user switches
// between charts of different types, it reloads the data from the backend.
// The implication is that the way the data needs to be calculated is different
// and so we have to refresh the data with the new chart type/instructions
export const V2_VIZ_INSTRUCTION_TYPE: Record<string, string> = {
  [types.OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2]: 'Two-dimensional',
  [types.OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2]: 'Two-dimensional',
  [types.OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2]: 'Two-dimensional',
  [types.OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2]: 'Grouped Stacked Bar Chart',
  [types.OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2]: 'Two-dimensional',
  [types.OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2]: 'Two-dimensional',
  [types.OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2]: 'Two-dimensional',
  [types.OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2]: 'Grouped Stacked Bar Chart',
  [types.OPERATION_TYPES.VISUALIZE_PIE_CHART_V2]: 'Two-dimensional',
  [types.OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2]: 'Two-dimensional',
  [types.OPERATION_TYPES.VISUALIZE_LINE_CHART_V2]: 'Two-dimensional',
  [types.OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2]: 'Two-dimensional',
  [types.OPERATION_TYPES.VISUALIZE_AREA_CHART_V2]: 'Two-dimensional',
  [types.OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2]: 'Two-dimensional',
  [types.OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2]: 'Two-dimensional',
  [types.OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2]: 'Scatter Plot',
  [types.OPERATION_TYPES.VISUALIZE_SPIDER_CHART]: 'Two-dimensional',
  [types.OPERATION_TYPES.VISUALIZE_FUNNEL_V2]: 'Two-dimensional',
  [types.OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2]: 'Two-dimensional',
  [types.OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2]: 'Box & Whisker Plot',
  [types.OPERATION_TYPES.VISUALIZE_NUMBER_V2]: 'KPI',
  [types.OPERATION_TYPES.VISUALIZE_PROGRESS_V2]: 'KPI',
  [types.OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2]: 'Number Trend',
  [types.OPERATION_TYPES.VISUALIZE_MAP_V2]: 'Two-dimensional',
  [types.OPERATION_TYPES.VISUALIZE_TREND_TABLE]: 'KPI',
};

enum SCROLL_DIRECTION {
  VERTICAL = 'Vertical',
  HORIZONTAL = 'Horizontal',
}
export const BAR_CHART_SCROLL_DIRECTION: Record<string, string> = {
  [types.OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2]: SCROLL_DIRECTION.VERTICAL,
  [types.OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2]: SCROLL_DIRECTION.VERTICAL,
  [types.OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2]: SCROLL_DIRECTION.VERTICAL,
  [types.OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2]: SCROLL_DIRECTION.VERTICAL,
  [types.OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2]: SCROLL_DIRECTION.HORIZONTAL,
  [types.OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2]: SCROLL_DIRECTION.HORIZONTAL,
  [types.OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2]: SCROLL_DIRECTION.HORIZONTAL,
  [types.OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2]: SCROLL_DIRECTION.HORIZONTAL,
};

export const V2_NUMBER_FORMATS = {
  NUMBER: {
    id: 'NUMBER',
    name: 'Number',
    abbreviation: '#',
  },
  ABBREVIATED: {
    id: 'ABBREVIATED',
    name: 'Abbreviated Number',
    abbreviation: 'abbr.',
  },
  CURRENCY: {
    id: 'CURRENCY',
    name: 'Currency ($)',
    abbreviation: '$',
  },
  PERCENT: {
    id: 'PERCENT',
    name: 'Percent (%)',
    abbreviation: '%',
  },
  TIME: {
    id: 'TIME',
    name: 'Time (00:00)',
    abbreviation: '00:00',
  },
};

export const NUMBER_FORMATS_WITH_DECIMALS = [
  V2_NUMBER_FORMATS.NUMBER.id,
  V2_NUMBER_FORMATS.CURRENCY.id,
  V2_NUMBER_FORMATS.PERCENT.id,
];

export enum STRING_FORMATS {
  DEFAULT = 'Default',
  UPPERCASE = 'Uppercase',
  LOWERCASE = 'Lowercase',
  TITLE_CASE = 'Title Case',
  CAMEL_CASE = 'Camel Case',
  SENTENCE_CASE = 'Sentence Case',
}

export const TIME_DIFF_FORMATS = {
  STANDARD: {
    id: 'STANDARD',
    name: 'Standard',
  },
  ABBREVATION: {
    id: 'ABBREVATION',
    name: 'Abbreviated',
  },
  CUSTOM: {
    id: 'CUSTOME',
    name: 'Custom',
  },
};

export const ALIGNMENT_FORMATS: Record<
  string,
  { id: TextElemHorizAlignments; icon: IconName; name: string }
> = {
  LEFT_ALIGN: {
    id: TEXT_ELEM_HORIZ_ALIGNMENTS.LEFT,
    icon: 'alignment-left',
    name: 'left',
  },
  CENTER_ALIGN: {
    id: TEXT_ELEM_HORIZ_ALIGNMENTS.CENTER,
    icon: 'alignment-vertical-center',
    name: 'center',
  },
  RIGHT_ALIGN: {
    id: TEXT_ELEM_HORIZ_ALIGNMENTS.RIGHT,
    icon: 'alignment-right',
    name: 'right',
  },
};

export const ORDERED_ALIGNMENTS = [
  ALIGNMENT_FORMATS.LEFT_ALIGN,
  ALIGNMENT_FORMATS.CENTER_ALIGN,
  ALIGNMENT_FORMATS.RIGHT_ALIGN,
];

export const VERT_ALIGNMENT_FORMATS: Record<
  string,
  { id: TextElemVerticalAlignments; icon: IconName; name: string }
> = {
  TOP: {
    id: TEXT_ELEM_VERTICAL_ALIGNMENTS.TOP,
    icon: 'alignment-top',
    name: 'top',
  },
  CENTER: {
    id: TEXT_ELEM_VERTICAL_ALIGNMENTS.CENTER,
    icon: 'alignment-horizontal-center',
    name: 'center',
  },
  BOTTOM: {
    id: TEXT_ELEM_VERTICAL_ALIGNMENTS.BOTTOM,
    icon: 'alignment-bottom',
    name: 'bottom',
  },
};

export const ORDERED_VERT_ALIGNMENTS = [
  VERT_ALIGNMENT_FORMATS.TOP,
  VERT_ALIGNMENT_FORMATS.CENTER,
  VERT_ALIGNMENT_FORMATS.BOTTOM,
];

export const EMPTY_FILTER_CONFIG: types.FilterOperationInstructions = {
  matchOnAll: true,
  filterClauses: [],
};

export const REGIONS: { [key: string]: RegionMetaData } = {
  [REGION_TYPES.UNITED_STATES]: {
    id: REGION_TYPES.UNITED_STATES,
    text: 'United States',
    center: [51.5, -110.0],
    zoom: 2,
  },
  [REGION_TYPES.WORLD]: {
    id: REGION_TYPES.WORLD,
    text: 'World',
    center: [41.9, -20.7],
    zoom: 1,
  },
};

export const DEFAULT_BOOLEAN_DISPLAY_OPTIONS: Record<
  'trueIcon' | 'falseIcon',
  types.BooleanDisplayFormat
> = {
  falseIcon: types.BooleanDisplayFormat.CROSS,
  trueIcon: types.BooleanDisplayFormat.TICK,
};

export const BOOLEAN_DISPLAY_FORMATS: types.BooleanDisplayFormat[] = [
  types.BooleanDisplayFormat.TICK,
  types.BooleanDisplayFormat.CROSS,
  types.BooleanDisplayFormat.BLANK,
];
