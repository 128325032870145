import { makeStyles, Theme } from '@material-ui/core/styles';
import { Icon } from '@blueprintjs/core';
import cx from 'classnames';

import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

const useStyles = makeStyles((theme: Theme) => ({
  checkboxContainer: ({ small, disabled }: Pick<Props, 'disabled' | 'small'>) => ({
    borderRadius: 3,
    width: theme.spacing(small ? 4 : 5),
    height: theme.spacing(small ? 4 : 5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      cursor: disabled ? 'not-allowed' : 'pointer',
    },
  }),
  checkboxContainerNotChecked: ({ disabled }: Pick<Props, 'disabled'>) => ({
    border: `1px solid ${theme.palette.ds.grey500}`,
    backgroundColor: disabled ? theme.palette.ds.grey400 : theme.palette.ds.grey100,
  }),
  checkboxContainerChecked: ({ disabled }: Pick<Props, 'disabled'>) => ({
    backgroundColor: disabled ? theme.palette.ds.grey600 : theme.palette.ds.blue,
    boxShadow: '0px -1px 1px 0px rgba(16, 22, 26, 0.2) inset',
  }),
}));

interface Props {
  className?: string;
  disabled?: boolean;
  small?: boolean;
  ignoreCustomStyles?: boolean;
  isChecked: boolean;
  preventDefault?: boolean;
  onChange: () => void;
}
export default function CustomCheckbox(props: Props) {
  const { ignoreCustomStyles, isChecked, onChange, disabled, small, preventDefault } = props;
  const classes = useStyles({ disabled, small });
  return (
    <div
      className={cx(
        classes.checkboxContainer,
        {
          [cx(classes.checkboxContainerChecked, {
            [GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.backgroundColor]: !ignoreCustomStyles,
          })]: isChecked,
          [classes.checkboxContainerNotChecked]: !isChecked,
        },
        { [GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.color]: !ignoreCustomStyles },
        props.className,
      )}
      onClick={(e) => {
        if (preventDefault) e.preventDefault();
        if (!disabled) onChange();
      }}>
      {isChecked ? (
        <Icon color="#FFFFFF" icon="small-tick" iconSize={props.small ? 12 : 16} />
      ) : null}
    </div>
  );
}
