import { FC } from 'react';

import { Switch } from 'components/ds';
import DropdownSelect from 'shared/DropdownSelect';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';

import {
  DASHBOARD_ELEMENT_TYPES,
  SelectElemConfig,
  SELECT_FILTER_TYPE,
} from 'types/dashboardTypes';
import { INPUT_TYPE } from 'constants/types';
import { constructManualOptions } from 'utils/dropdownUtils';
import { getUpdateConfigFunc } from '../utils';

type Props = {
  config: SelectElemConfig;
  selectType: SELECT_FILTER_TYPE;

  updateSelectConfig: (config: SelectElemConfig, reRequestRows: boolean) => void;
};

export const SelectDefaultValueConfig: FC<Props> = ({ config, selectType, updateSelectConfig }) => {
  const isToggle = selectType === DASHBOARD_ELEMENT_TYPES.TOGGLE;
  const updateConfig = getUpdateConfigFunc(config, updateSelectConfig);

  if (config.valuesConfig.valuesSource === INPUT_TYPE.QUERY) {
    return (
      <Switch
        label={isToggle ? 'Default toggle to first value' : 'Default dropdown to first value'}
        onChange={() =>
          updateConfig((draft) => {
            draft.valuesConfig.queryDefaultFirstValue = !config.valuesConfig.queryDefaultFirstValue;
          }, true)
        }
        switchOn={config.valuesConfig.queryDefaultFirstValue}
      />
    );
  }

  const defaultValue = config.valuesConfig.manualDefaultValue;
  if (selectType === DASHBOARD_ELEMENT_TYPES.MULTISELECT)
    return (
      <InputWithBlurSave
        ignoreCustomStyles
        initialValue={defaultValue ? String(defaultValue) : ''}
        label="Default Value"
        onNewValueSubmitted={(newValue) =>
          updateConfig((draft) => (draft.valuesConfig.manualDefaultValue = newValue))
        }
      />
    );

  const options = constructManualOptions(config.valuesConfig);
  return (
    <DropdownSelect
      fillWidth
      ignoreCustomStyles
      minimal
      showCancelBtn
      filterable={false}
      noSelectionText="Select a default value"
      onCancelClick={() =>
        updateConfig((draft) => (draft.valuesConfig.manualDefaultValue = undefined))
      }
      onChange={(item) =>
        updateConfig((draft) => (draft.valuesConfig.manualDefaultValue = item.value as string))
      }
      options={options}
      selectedItem={defaultValue ? options.find((opt) => opt.value === defaultValue) : undefined}
    />
  );
};
