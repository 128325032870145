import { createReducer } from '@reduxjs/toolkit';
import { sortBy } from 'utils/standard';

import {
  DataSource,
  deleteDataSourceSuccess,
  listTeamDataSourcesSuccess,
  editDataSourceSuccess,
} from 'actions/dataSourceActions';
import { syncParentSchemaSuccess } from 'actions/parentSchemaActions';

interface DataSourceListReducerState {
  dataSources?: DataSource[];
}

const initialState: DataSourceListReducerState = {
  dataSources: undefined,
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(listTeamDataSourcesSuccess, (state, { payload }) => {
      state.dataSources = sortBy(payload.dataSources, (datasource) => datasource.id);
    })
    .addCase(editDataSourceSuccess, (state, { payload }) => {
      if (!state.dataSources) return;
      const editedDataSource = state.dataSources.find((dataSource) => dataSource.id === payload.id);

      if (!editedDataSource) return;

      if (payload.data_source.provided_id)
        editedDataSource.provided_id = payload.data_source.provided_id;
      if (payload.data_source.name) editedDataSource.name = payload.data_source.name;
      if (payload.data_source.user_viewable_credentials) {
        editedDataSource.user_viewable_credentials = payload.data_source.user_viewable_credentials;
      }
      if (payload.postData.access_group_ids)
        editedDataSource.access_groups = payload.data_source.access_groups;
    })
    .addCase(syncParentSchemaSuccess, (state, { payload }) => {
      state.dataSources = sortBy(payload.data_sources, (datasource) => datasource.id);
    })
    .addCase(deleteDataSourceSuccess, (state, { payload }) => {
      if (state.dataSources) {
        const deletedIndex = state.dataSources.findIndex((ds) => ds.id === payload.id);
        if (deletedIndex >= 0) state.dataSources.splice(deletedIndex, 1);
      }
    })
    .addDefaultCase((state) => state);
});
