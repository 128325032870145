import { FC } from 'react';
import produce from 'immer';

import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';
import { sprinkles } from 'components/ds';

import { DatasetColumn } from 'types/datasets';
import { BooleanDisplayFormat, BooleanDisplayOptions } from 'constants/types';
import { BOOLEAN_DISPLAY_FORMATS } from 'constants/dataConstants';
import { ORDERED_ALIGNMENTS } from 'constants/dataConstants';
import { getCellAlignment } from 'components/dataTable/utils';
import { getCurrentBooleanIcons } from 'utils/formatConfigUtils';

type Props = {
  column: DatasetColumn;
  displayOptions: BooleanDisplayOptions | undefined;

  updateBooleanOptions: (updates: BooleanDisplayOptions) => void;
};

export const BooleanFormatConfig: FC<Props> = ({
  column,
  displayOptions,
  updateBooleanOptions,
}) => {
  const { trueIcon, falseIcon } = getCurrentBooleanIcons(displayOptions);
  const currentAlignment = getCellAlignment(displayOptions, column.type);

  const onButtonClick = (
    displayProperty: 'trueIcon' | 'falseIcon',
    newIcon: BooleanDisplayFormat,
  ) => {
    const currentIcon = displayProperty === 'trueIcon' ? trueIcon : falseIcon;
    if (newIcon === currentIcon) return;
    const oppositeIcon = displayProperty === 'trueIcon' ? falseIcon : trueIcon;
    const oppositeDisplayProperty = displayProperty === 'trueIcon' ? 'falseIcon' : 'trueIcon';

    const newOptions = produce(displayOptions ?? {}, (draft) => {
      draft[displayProperty] = newIcon;

      if (newIcon === oppositeIcon) {
        draft[oppositeDisplayProperty] = getOppositeBooleanIcon(newIcon);
      }
    });

    updateBooleanOptions(newOptions);
  };

  return (
    <>
      <div className={sprinkles({ width: 'fill', marginTop: 'sp1', display: 'flex' })}>
        <div className={sprinkles({ marginRight: 'sp1', flex: 1 })}>
          <ToggleButtonGroup fillWidth label="True">
            {BOOLEAN_DISPLAY_FORMATS.map((format) => (
              <ToggleButton
                active={trueIcon === format}
                icon={format}
                key={`true_${format}`}
                onClick={() => onButtonClick('trueIcon', format)}
              />
            ))}
          </ToggleButtonGroup>
        </div>
        <div className={sprinkles({ flex: 1 })}>
          <ToggleButtonGroup fillWidth label="False">
            {BOOLEAN_DISPLAY_FORMATS.map((format) => (
              <ToggleButton
                active={falseIcon === format}
                icon={format}
                key={`false_${format}`}
                onClick={() => onButtonClick('falseIcon', format)}
              />
            ))}
          </ToggleButtonGroup>
        </div>
      </div>
      <ToggleButtonGroup fillWidth className={sprinkles({ marginTop: 'sp1' })} label="Alignment">
        {ORDERED_ALIGNMENTS.map((alignment) => (
          <ToggleButton
            active={currentAlignment === alignment.id}
            icon={alignment.icon}
            key={`alignment-${alignment.id}-${column.name}`}
            onClick={() => {
              const newOptions = produce(displayOptions ?? {}, (draft) => {
                draft.alignment = alignment.id;
              });

              updateBooleanOptions(newOptions);
            }}
          />
        ))}
      </ToggleButtonGroup>
    </>
  );
};

function getOppositeBooleanIcon(format: BooleanDisplayFormat): BooleanDisplayFormat {
  switch (format) {
    case BooleanDisplayFormat.TICK:
    case BooleanDisplayFormat.CROSS:
      return BooleanDisplayFormat.BLANK;
    case BooleanDisplayFormat.BLANK:
      return BooleanDisplayFormat.TICK;
  }
}
