import { Component, KeyboardEvent } from 'react';
import cx from 'classnames';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Button, IconName, Intent } from '@blueprintjs/core';

import InputGroup from 'explo-ds/forms/marketing/inputGroup';
import InputLabel from 'shared/InputLabel';
import theme from 'theme';

const styles = () => ({
  compactInput: {
    '& .bp3-input': {
      padding: `0 6px !important`,
      height: 25,
      textAlign: 'center',
    },
  },
  fillWidth: {
    width: '100%',
  },
  descriptiveText: {
    paddingTop: theme.spacing(1),
  },
});

type PassedProps = {
  /**
   * If passed in, prevents user input and interaction
   */
  disabled?: boolean;
  /**
   * If passed in, causes the input to fill the width of its container
   */
  fillWidth?: boolean;
  /**
   * If passed in, the input will be shorter and have less padding on the sides within the
   * input.
   */
  isCompact?: boolean;
  /**
   * If passed in, the input will be positioned as if it had a label (with spacing above it)
   * but no words will be visible
   */
  useFakeLabel?: boolean;
  /**
   * If passed in, the cancel button and enter button on the right inside of the input
   * will not appear
   */
  hideRightIconInteractions?: boolean;
  initialValue?: string;
  onNewValueSubmitted: (newValue: string) => void;
  errorText?: string;
  placeholder?: string;
  leftIcon?: IconName | JSX.Element;
  containerClassName?: string;
  className?: string;
  label?: string;
  type?: string;
  helpIcon?: IconName;
  helpText?: string;
  descriptiveText?: JSX.Element | string;
  ignoreCustomStyles?: boolean;
  ignoreKeyPress?: boolean;
};

type Props = WithStyles<typeof styles> & PassedProps;

type State = {
  value?: string;
  inputFocused: boolean;
};

class InputWithBlurSave extends Component<Props, State> {
  inputRef: HTMLInputElement | null = null;

  state: State = {
    value: this.props.initialValue,
    inputFocused: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.initialValue !== this.props.initialValue) {
      this.setState({ value: this.props.initialValue });
      this.inputRef?.blur();
    }
  }

  render = () => {
    const { value } = this.state;
    const {
      className,
      containerClassName,
      disabled,
      initialValue,
      errorText,
      placeholder,
      onNewValueSubmitted,
      isCompact,
      classes,
      label,
      leftIcon,
      fillWidth,
      type,
      useFakeLabel,
      helpText,
      helpIcon,
      descriptiveText,
      ignoreCustomStyles,
      ignoreKeyPress,
    } = this.props;

    return (
      <div className={cx(containerClassName, { [classes.fillWidth]: fillWidth })}>
        {label || useFakeLabel ? (
          <InputLabel
            fakeLabel={useFakeLabel}
            helpIcon={helpIcon}
            helpText={helpText}
            ignoreCustomStyles={ignoreCustomStyles}
            text={label || ''}
          />
        ) : null}
        <InputGroup
          className={cx({ [classes.compactInput]: isCompact }, className)}
          disabled={disabled}
          inputRef={(ref) => (this.inputRef = ref)}
          intent={errorText ? Intent.DANGER : Intent.NONE}
          leftIcon={leftIcon}
          onBlur={() => {
            this.setState({ value: initialValue, inputFocused: false });
            onNewValueSubmitted(value || '');
          }}
          onFocus={() => this.setState({ inputFocused: true })}
          onInputChange={(value) => this.setState({ value })}
          onKeyPress={(event: KeyboardEvent<HTMLInputElement>) => {
            if (ignoreKeyPress) return;

            const code = event.keyCode || event.which;
            if (code === 13) this.inputRef?.blur();
          }}
          placeholder={placeholder}
          rightElement={this.renderRightElement()}
          type={type}
          value={value}
        />
        {errorText ? <InputLabel error text={errorText} /> : null}
        {descriptiveText ? (
          <div className={classes.descriptiveText}>
            {typeof descriptiveText === 'string' ? (
              <InputLabel text={descriptiveText} />
            ) : (
              descriptiveText
            )}
          </div>
        ) : null}
      </div>
    );
  };

  renderRightElement = () => {
    const { inputFocused } = this.state;
    const { onNewValueSubmitted, hideRightIconInteractions, disabled } = this.props;

    if (hideRightIconInteractions || disabled) return;

    const icon = inputFocused ? 'key-enter' : 'cross';
    const onClick = inputFocused ? undefined : () => onNewValueSubmitted('');

    return <Button minimal disabled={inputFocused} icon={icon} onClick={onClick} />;
  };
}

export default withStyles(styles)(InputWithBlurSave);
