import { FC, Suspense, lazy, useMemo } from 'react';
import { TypePivotItem } from '@inovua/reactdatagrid-enterprise/types';

import { Spinner, sprinkles } from 'components/ds';

import { DatasetSchema, DatasetRow } from 'types/datasets';
import { ColumnConfigs } from 'components/ds/DataGrid/columnUtils';
import * as styles from './index.css';
import { loadMask } from '../EmbedDataGrid/index.css';

import '@inovua/reactdatagrid-enterprise/index.css';
import { generatePivotColumns, transformRows } from './pivotUtils';

const ReactDataGrid = lazy(
  () => import(/* webpackChunkName: "reactdatagrid" */ '@inovua/reactdatagrid-enterprise'),
);

type Props = {
  /**
   * Column configs for formatting
   */
  columnConfigs: ColumnConfigs;
  /**
   * Columns to group by
   */
  groupByColumns: string[];
  /**
   * Show the loading state for the DataGrid
   */
  loading: boolean;
  /**
   * Columns to pivot by
   */
  pivotColumns: string[];
  /**
   * Schema for column metadata (e.g. name, type)
   */
  schema: DatasetSchema;
  /**
   * Rows of data to display
   */
  rows: DatasetRow[];
};

export const EmbedPivotTable: FC<Props> = ({
  columnConfigs,
  loading,
  schema,
  rows,
  groupByColumns,
  pivotColumns,
}) => {
  const { columns, groupColumn } = useMemo(
    () => generatePivotColumns({ columnConfigs, schema, pivotColumns, groupByColumns }),
    [columnConfigs, schema, pivotColumns, groupByColumns],
  );

  const transformedRows = useMemo(
    () => transformRows(rows, schema, columnConfigs),
    [rows, schema, columnConfigs],
  );

  const renderLoadMask = ({ visible }: { visible: boolean }) => {
    return visible ? <Spinner fillContainer className={loadMask} /> : null;
  };

  return (
    <div className={sprinkles({ parentContainer: 'fill', flexItems: 'column' })}>
      <Suspense fallback={<Spinner fillContainer />}>
        <ReactDataGrid
          disableGroupByToolbar
          className={sprinkles({ flex: 1 })}
          columnOrder={schema?.map((col) => col.name)}
          columns={columns}
          dataSource={transformedRows}
          groupBy={groupByColumns}
          groupColumn={groupColumn}
          idProperty="id"
          licenseKey={process.env.REACT_APP_DATAGRID_KEY}
          loading={transformedRows.length === 0 && loading}
          // They have 'string' instead of string in this type so this is needed
          pivot={pivotColumns as TypePivotItem[]}
          renderLoadMask={renderLoadMask}
          reorderColumns={false}
          showColumnMenuTool={false}
          sortable={false}
        />
        {transformedRows.length >= 5000 ? (
          <div className={styles.rowLimitContainer}>5,000 row limit reached</div>
        ) : null}
      </Suspense>
    </div>
  );
};
