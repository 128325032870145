import { FC, useState } from 'react';
import cx from 'classnames';
import * as ToggleGroup from '@radix-ui/react-toggle-group';

import * as styles from './index.css';
import { IconButton } from 'components/ds/IconButton';
import { IconName } from 'components/ds/Icon';

type NavigationItem = {
  /**
   * Icon to show for this navigation item.
   */
  iconName: IconName;
  /**
   * value used to select item.
   */
  value: string;
  /**
   * Action to perform when navigation item is clicked.
   */
  onClick: () => void;
  /**
   * Id used for testing.
   */
  testId?: string;
};

export type Props = {
  /**
   * A list of navigation items to display. When clicked, they perform their corresponding action.
   */
  items: NavigationItem[];
  /**
   * Value within the item list that begins as the selected item. Defaults to first item if not present.
   */
  defaultValue?: string;
  /**
   * Optional class name for style overrides.
   */
  className?: string;
  /**
   * Test ID for selecting component in tests.
   */
  'data-testid'?: string;
};

export const NavigationToggle: FC<Props> = ({
  items,
  defaultValue,
  className,
  'data-testid': testId,
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue || items.at(0)?.value);
  if (items.length === 0) return null;

  return (
    <ToggleGroup.Root
      className={cx(styles.baseContainer, className)}
      data-testid={testId}
      onValueChange={(value: string) => {
        if (value) setSelectedValue(value);
      }}
      type="single"
      value={selectedValue}>
      {items.map((item, index) => {
        const { onClick, iconName, testId, value } = item;
        return (
          <ToggleGroup.Item asChild key={`${iconName}-${index}`} value={value}>
            <IconButton
              className={styles.item}
              data-testid={testId}
              name={iconName}
              onClick={onClick}
            />
          </ToggleGroup.Item>
        );
      })}
    </ToggleGroup.Root>
  );
};
