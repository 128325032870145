import { FC, useState, MouseEvent as ReactMouseEvent } from 'react';
import cx from 'classnames';

import { Button, Icon, IconButton, NavigationToggle, sprinkles } from 'components/ds';
import * as styles from './index.css';

export enum ViewType {
  List,
  Card,
}

type PrimaryActionProps = {
  disabled?: boolean;
  id?: string;
  text: string;
  to?: string;
  tooltipText?: string;
  onClick?: (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

type SecondaryActionProps = {
  text: string;
  onClick: (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

type Props = {
  pageTitle: string;
  className?: string;
  currentView?: ViewType;
  primaryActionProps?: PrimaryActionProps;
  secondaryActionProps?: SecondaryActionProps;
  searchBarPlaceholderText: string;
  searchBarSubmit: (searchText: string) => void;
  toggleView?: (view: ViewType) => void;
};

export const PageHeader: FC<Props> = ({
  pageTitle,
  className,
  currentView,
  primaryActionProps,
  secondaryActionProps,
  searchBarPlaceholderText,
  searchBarSubmit,
  toggleView,
}) => {
  const [isSearchBarExpanded, setIsSearchBarExpanded] = useState(false);

  const renderSearchBar = () => {
    if (isSearchBarExpanded) {
      return (
        <div className={styles.openSearchBar}>
          <Icon className={sprinkles({ color: 'active' })} name="search" />
          <input
            autoFocus
            className={styles.input}
            onChange={(event) => {
              searchBarSubmit(event.currentTarget.value);
            }}
            placeholder={searchBarPlaceholderText || 'Search'}
          />
          <IconButton
            className={styles.cross}
            name="cross"
            onClick={() => {
              setIsSearchBarExpanded(false);
              searchBarSubmit('');
            }}
            type="tertiary"
          />
        </div>
      );
    }
    return <IconButton name="search" onClick={() => setIsSearchBarExpanded(true)} type="primary" />;
  };

  return (
    <div
      className={cx(
        className ?? sprinkles({ backgroundColor: 'elevationLow' }),
        styles.headerContainer,
      )}>
      <div className={styles.contentContainer}>
        <div className={styles.nameSearch}>
          <div className={sprinkles({ heading: 'h2', display: 'flex', width: 'fill' })}>
            {pageTitle}
          </div>
          {renderSearchBar()}
        </div>
        <div className={styles.buttonContainer}>
          {toggleView ? (
            <NavigationToggle
              defaultValue={currentView === ViewType.List ? 'list' : 'grid'}
              items={[
                {
                  iconName: 'grid',
                  value: 'grid',
                  onClick: () => toggleView?.(ViewType.Card),
                },
                {
                  iconName: 'list',
                  value: 'list',
                  onClick: () => toggleView?.(ViewType.List),
                },
              ]}
            />
          ) : null}
          {secondaryActionProps ? (
            <Button onClick={secondaryActionProps.onClick} type="secondary">
              {secondaryActionProps.text}
            </Button>
          ) : null}
          {primaryActionProps && primaryActionProps?.onClick ? (
            <Button
              disabled={primaryActionProps.disabled}
              onClick={primaryActionProps.onClick}
              tooltipProps={
                primaryActionProps.tooltipText
                  ? {
                      text: primaryActionProps.tooltipText,
                    }
                  : undefined
              }
              type="primary">
              {primaryActionProps.text}
            </Button>
          ) : primaryActionProps && primaryActionProps.to ? (
            <Button
              to={primaryActionProps.to}
              tooltipProps={
                primaryActionProps.tooltipText
                  ? {
                      text: primaryActionProps.tooltipText,
                    }
                  : undefined
              }
              type="primary">
              {primaryActionProps.text}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};
