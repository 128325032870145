import { EditableText } from 'components/EditableText';
import { FC } from 'react';

import { EmbedText } from './EmbedText';

import * as styles from './ReportName.css';

type Props = {
  onSubmit?: (value: string) => void;
  value: string;
  disabled?: boolean; // If true, prevents the user from renaming
};

export const ReportName: FC<Props> = ({ value, onSubmit, disabled }) => {
  return (
    <EmbedText className={styles.headerContainer} heading="h1">
      <EditableText
        disabled={disabled}
        onRename={(name) => onSubmit?.(name)}
        placeholder="Untitled Report"
        value={value}
      />
    </EmbedText>
  );
};
