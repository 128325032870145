import { defineAPIAction, defineAPIPostAction } from 'actions/actionUtils';
import { ACTION } from './types';
import { WhitelistDomain } from 'actions/teamActions';

type FetchWhitelistDomainsData = {
  whitelist_domains: WhitelistDomain[];
};

export const { actionFn: fetchWhitelistDomains, successAction: fetchWhiteListDomainsSuccess } =
  defineAPIAction<FetchWhitelistDomainsData>(
    ACTION.FETCH_WHITELIST_DOMAIN,
    'whitelist_domains',
    '',
    'GET',
  );

type CreateWhiteListDomainBody = {
  name: string;
  team_id: number;
};

type CreateWhitelistDomainData = {
  whitelist_domain: WhitelistDomain;
};

export const { actionFn: createWhitelistDomain, successAction: createWhitelistDomainSucess } =
  defineAPIPostAction<CreateWhiteListDomainBody, CreateWhitelistDomainData>(
    ACTION.CREATE_WHITELIST_DOMAIN,
    'whitelist_domains',
    '',
    'POST',
  );

export const { actionFn: deleteWhitelistDomain, successAction: deleteWhitelistDomainSuccess } =
  defineAPIAction(ACTION.DELETE_WHITELIST_DOMAIN, 'whitelist_domains', '', 'DELETE');
