import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';

import ValueInput, {
  ValueInputProps,
} from 'pages/dashboardPage/DashboardDatasetView/Header/ValueInput';
import DropdownSelect from 'shared/DropdownSelect';
import FlexBox, { VerticalAlignment } from 'components/core/FlexBox';
import { FilterValueSourceType } from 'constants/types';
import { DashboardElement } from 'types/dashboardTypes';
import { filterForValidFilterElementsBasedOnType } from 'utils/dashboardUtils';
import { DashboardParam } from 'types/dashboardVersionConfig';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { filterDataPanelsForValidDrilldownVarsBasedOnType } from 'utils/drilldownUtils';
import {
  updateFilterValue,
  updateFilterValueSource,
  updateFilterValueVariable,
} from 'actions/dataPanelConfigActions';

const VALUE_SOURCE_TYPE_OPTIONS = {
  [FilterValueSourceType.INPUT]: {
    name: 'Manual',
    id: FilterValueSourceType.INPUT,
  },
  [FilterValueSourceType.VARIABLE]: {
    name: 'Variable',
    id: FilterValueSourceType.VARIABLE,
  },
};

const useStyles = makeStyles(() => ({
  root: {
    '& input': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    gap: 4,
  },
  varOrValDropdown: {
    marginRight: -1,

    '& .bp3-button': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  variableDropdown: {
    '& .bp3-button': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      width: 190,
    },
  },
  inputFilter: {
    width: `194px !important`,
  },
}));

type Props = Omit<ValueInputProps, 'onFilterValueUpdate'> & {
  clauseIdx: number;
  operatorDropdownClass: string;
  filterValueSource?: FilterValueSourceType;
  filterValueVariableId?: string;
  filterValueVariableProperty?: string;
  dashboardElements?: DashboardElement[];
  dashboardParams?: Record<string, DashboardParam>;
  dataPanels?: DataPanelTemplate[];
};

export default function FilterConfigMenu({
  tall,
  clauseIdx,
  operatorDropdownClass,
  selectedColumn,
  selectedOperator,
  filterValue,
  filterValueSource,
  filterValueVariableId,
  filterValueVariableProperty,
  dashboardElements,
  dashboardParams,
  dataPanels,
}: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const drilldownVarOptions = filterDataPanelsForValidDrilldownVarsBasedOnType(
    dataPanels || [],
    selectedOperator,
  );

  const variableOptions = filterForValidFilterElementsBasedOnType(
    dashboardElements,
    dashboardParams,
    selectedOperator,
  ).concat(drilldownVarOptions);

  const selectedSourceType = filterValueSource || FilterValueSourceType.INPUT;
  const selectedVarId = filterValueVariableProperty
    ? `${filterValueVariableId}.${filterValueVariableProperty}`
    : filterValueVariableId;

  return (
    <FlexBox className={classes.root} verticalAlignment={VerticalAlignment.TOP}>
      <DropdownSelect
        fillWidth
        minimal
        usePortal
        containerClassName={cx(classes.varOrValDropdown, operatorDropdownClass)}
        disabled={
          variableOptions.length === 0 && selectedSourceType === FilterValueSourceType.INPUT
        }
        filterable={false}
        noSelectionText="Var"
        onChange={(newValue) =>
          dispatch(
            updateFilterValueSource({
              index: clauseIdx,
              newSource: newValue.id as FilterValueSourceType,
            }),
          )
        }
        options={[
          VALUE_SOURCE_TYPE_OPTIONS[FilterValueSourceType.INPUT],
          VALUE_SOURCE_TYPE_OPTIONS[FilterValueSourceType.VARIABLE],
        ]}
        selectedItem={VALUE_SOURCE_TYPE_OPTIONS[selectedSourceType]}
      />
      {selectedSourceType === FilterValueSourceType.INPUT && (
        <div className={classes.inputFilter}>
          <ValueInput
            filterValue={filterValue}
            onFilterValueUpdate={(value) =>
              dispatch(updateFilterValue({ index: clauseIdx, value }))
            }
            selectedColumn={selectedColumn}
            selectedOperator={selectedOperator}
            tall={tall}
          />
        </div>
      )}
      {selectedSourceType === FilterValueSourceType.VARIABLE && (
        <DropdownSelect
          fillWidth
          minimal
          usePortal
          containerClassName={classes.variableDropdown}
          filterable={false}
          noSelectionText="Select Variable"
          onChange={(newValue) => {
            const parts = newValue.id.split('.', 2);
            dispatch(
              updateFilterValueVariable({
                index: clauseIdx,
                variableId: parts[0],
                property: parts[1],
              }),
            );
          }}
          options={variableOptions}
          selectedItem={
            selectedVarId && variableOptions.findIndex((v) => v.id === selectedVarId) !== -1
              ? { id: selectedVarId, name: selectedVarId }
              : undefined
          }
        />
      )}
    </FlexBox>
  );
}
