import { PureComponent } from 'react';
import Highcharts from 'highcharts';
import ReactDOMServer from 'react-dom/server';
import { cloneDeep, groupBy, keyBy } from 'utils/standard';

import NeedsConfigurationPanel from 'pages/dashboardPage/DashboardDatasetView/needsConfigurationPanel';
import { DrilldownChart } from './shared/drilldownChart';
import BaseTooltip from './tooltips/BaseTooltip';

import {
  getColorColNames,
  xAxisFormat,
  yAxisFormat,
  formatLegend,
  formatValue,
  getLabelStyle,
  isTwoDimVizInstructionsReadyToDisplay,
  areRequiredVariablesSetTwoDimViz,
  formatLabel,
  shouldProcessColAsDate,
} from './utils';
import {
  GradientType,
  HeatMapFormat,
  V2TwoDimensionChartInstructions,
  VisualizeOperationGeneralFormatOptions,
} from 'constants/types';
import { V2_NUMBER_FORMATS } from 'constants/dataConstants';
import { DashboardVariableMap, OpenDrilldownModalType } from 'types/dashboardTypes';
import { DatasetSchema } from 'types/datasets';
import { Point } from 'highcharts';
import { GlobalStyleConfig } from 'globalStyles/types';
import { getTimezoneAwareUnix } from 'utils/timezoneUtils';
import { getColDisplayText } from '../DataPanelConfigV2/DataConfigTab/vizConfigs/utils';
import { getFloats } from 'utils/formatConfigUtils';
import { isSelectedColorDateType, getColorColumn } from 'utils/colorColUtils';
import { ChartMenuInfo } from 'reducers/dashboardLayoutReducer';

type Props = {
  backgroundColor: string;
  loading?: boolean;
  previewData: Record<string, string | number>[];
  instructions?: V2TwoDimensionChartInstructions;
  dataPanelTemplateId: string;
  variables: DashboardVariableMap;
  schema: DatasetSchema;
  selectedColorColName?: string;
  globalStyleConfig: GlobalStyleConfig;
  openDrilldownModal?: OpenDrilldownModalType;
  generalOptions?: VisualizeOperationGeneralFormatOptions;
  setChartMenu: (info: ChartMenuInfo | null) => void;
};

type SeriesOptions = Highcharts.SeriesHeatmapOptions;

class HeatMap extends PureComponent<Props> {
  render() {
    const { generalOptions, instructions, loading, variables, setChartMenu } = this.props;
    const requiredVarNotsSet = !areRequiredVariablesSetTwoDimViz(variables, instructions);
    const instructionsReadyToDisplay = !!(
      isTwoDimVizInstructionsReadyToDisplay(instructions) &&
      instructions?.colorColumnOptions?.length
    );

    if (loading || !instructionsReadyToDisplay || requiredVarNotsSet) {
      return (
        <NeedsConfigurationPanel
          fullHeight
          instructionsNeedConfiguration={!instructionsReadyToDisplay}
          loading={loading}
          requiredVarsNotSet={requiredVarNotsSet}
        />
      );
    }
    return (
      <DrilldownChart
        chartOptions={this._spec()}
        closeChartMenu={() => setChartMenu(null)}
        customMenuOptions={
          generalOptions?.customMenu?.enabled ? generalOptions?.customMenu?.menuOptions : undefined
        }
        dataPanelTemplateId={this.props.dataPanelTemplateId}
        instructions={instructions}
        openDrilldownModal={this.props.openDrilldownModal}
        underlyingDataEnabled={this.getUnderlyingDrilldownEnabled()}
      />
    );
  }

  _spec = (): Highcharts.Options | undefined => {
    const {
      generalOptions,
      previewData,
      schema,
      instructions,
      backgroundColor,
      globalStyleConfig,
      setChartMenu,
      dataPanelTemplateId,
    } = this.props;
    if (!instructions?.aggColumns || schema?.length === 0 || !previewData) return;

    // this is a short term fix en lieu of this bug being fixed by vega:
    // Ref: TU/447fn2df
    this.processDatesData();

    const { xCategories, yCategories, originalXCategories, originalYCategories } =
      this.getAxisCategories();
    const { aggColName } = getColorColNames(schema);
    const { valueFormatId, decimalPlaces } = this.getValueFormat();

    const aggCol = instructions?.aggColumns[0];
    const heatMapFormat = instructions.chartSpecificFormat?.heatMap;
    const gradientColors = heatMapFormat?.gradient;

    const { colorAxisMin, colorAxisMid, colorAxisMax } = this.getColorAxisFloats(
      aggColName,
      heatMapFormat,
    );

    const data = this.transformData();

    const hasClickEvents =
      this.getUnderlyingDrilldownEnabled() || !!generalOptions?.customMenu?.enabled;

    return {
      chart: {
        type: 'heatmap',
        plotBorderWidth: 1,
        backgroundColor,
      },
      series: data,
      title: { text: undefined },
      plotOptions: {
        heatmap: {
          dataLabels: {
            enabled: heatMapFormat?.showCellLabels,
            style: {
              textOutline: 'none',
              ...getLabelStyle(globalStyleConfig, 'primary'),
            },
            formatter: function () {
              const value = this.point.value ?? 0;
              if (heatMapFormat?.hideZeros && value === 0) return;
              return formatValue({
                value,
                decimalPlaces,
                formatId: valueFormatId,
                hasCommas: true,
              });
            },
          },
        },
        series: {
          animation: false,
          cursor: hasClickEvents ? 'pointer' : undefined,
          point: {
            events: {
              click: function (e) {
                if (!hasClickEvents) return;
                const options = e.point.options;
                if (options.x === undefined || options.y === undefined || options.y === null)
                  return;

                setChartMenu({
                  chartId: dataPanelTemplateId,
                  chartX: e.chartX,
                  chartY: e.chartY,
                  category: originalXCategories[options.x],
                  subCategory: originalYCategories[options.y],
                });
              },
            },
          },
        },
      },
      yAxis: {
        endOnTick: false,
        categories: yCategories,
        ...yAxisFormat(globalStyleConfig, instructions?.yAxisFormats?.[0]),
        labels: {
          style: getLabelStyle(globalStyleConfig, 'secondary'),
        },
      },
      xAxis: {
        ...xAxisFormat(globalStyleConfig, instructions?.xAxisFormat),
        categories: xCategories,
        labels: {
          style: getLabelStyle(globalStyleConfig, 'secondary'),
          enabled: !instructions?.xAxisFormat?.hideAxisLabels,
        },
        visible: !instructions?.xAxisFormat?.hideAxisLine,
      },
      colorAxis: {
        stops:
          heatMapFormat?.gradientType === GradientType.DIVERGING
            ? [
                [
                  colorAxisMin,
                  gradientColors?.hue1 || globalStyleConfig.visualizations.divergingPalette.hue1,
                ],
                [
                  colorAxisMid,
                  gradientColors?.hue2 || globalStyleConfig.visualizations.divergingPalette.hue2,
                ],
                [
                  colorAxisMax,
                  gradientColors?.hue3 || globalStyleConfig.visualizations.divergingPalette.hue3,
                ],
              ]
            : [
                [
                  colorAxisMin,
                  gradientColors?.hue2 || globalStyleConfig.visualizations.gradientPalette.hue1,
                ],
                [
                  colorAxisMax,
                  gradientColors?.hue3 || globalStyleConfig.visualizations.gradientPalette.hue2,
                ],
              ],
        startOnTick: false,
        endOnTick: false,
      },
      legend: {
        ...formatLegend(globalStyleConfig, instructions?.legendFormat),
      },
      tooltip: {
        formatter: function () {
          const xName = getPointCategoryName(this.point, 'x');
          const yName = getPointCategoryName(this.point, 'y');

          return ReactDOMServer.renderToStaticMarkup(
            <BaseTooltip
              decimalPlaces={decimalPlaces}
              globalStyleConfig={globalStyleConfig}
              header={`${xName}, ${yName}`}
              points={[
                {
                  color: String(this.point.color),
                  name: aggCol.column.friendly_name || getColDisplayText(aggCol) || aggColName,
                  value: this.point.value || 0,
                },
              ]}
              valueFormat={valueFormatId}
            />,
          );
        },
        padding: 0,
        borderWidth: 0,
        borderRadius: 0,
        backgroundColor: '#FFFFFF00',
        shadow: false,
        useHTML: true,
        outside: true,
      },
    };
  };

  getUnderlyingDrilldownEnabled = () => {
    const { generalOptions, openDrilldownModal } = this.props;
    return !!(generalOptions?.enableRawDataDrilldown && openDrilldownModal);
  };

  getValueFormat = () => {
    const { instructions } = this.props;

    return {
      valueFormatId:
        instructions?.yAxisFormats?.[0]?.numberFormat?.id || V2_NUMBER_FORMATS.NUMBER.id,
      decimalPlaces: instructions?.yAxisFormats?.[0]?.decimalPlaces ?? 2,
    };
  };

  processDatesData = () => {
    const { instructions, previewData, schema } = this.props;
    const categoryColIsDate = shouldProcessColAsDate(instructions?.categoryColumn);
    const colorColIsDate = isSelectedColorDateType(instructions || {});

    if (!previewData || (!categoryColIsDate && !colorColIsDate) || !schema || schema.length === 0)
      return;

    const xAxisColName = schema[0].name;
    const colorColName = getColorColNames(schema).colorColName;

    previewData.forEach((row) => {
      if (!instructions?.categoryColumn?.column.type) return;
      if (categoryColIsDate) row[xAxisColName] = getTimezoneAwareUnix(row[xAxisColName] as string);
      if (colorColIsDate) row[colorColName] = getTimezoneAwareUnix(row[colorColName] as string);
    });
  };

  getAxisCategories = () => {
    const { instructions, previewData, schema, selectedColorColName } = this.props;
    const { xAxisColName, colorColName } = getColorColNames(schema);
    const colorColumn = getColorColumn(instructions, selectedColorColName);

    const originalXCategories = new Set<string>();
    const xCategories: string[] = [];
    const originalYCategories = new Set<string>();
    const yCategories: string[] = [];

    previewData.forEach((row) => {
      const originalCategory = String(row[xAxisColName]);
      if (originalXCategories.has(originalCategory)) return;
      originalXCategories.add(originalCategory);

      const formatted = formatLabel(
        row[xAxisColName],
        instructions?.categoryColumn?.column.type,
        instructions?.categoryColumn?.bucket?.id,
        instructions?.categoryColumn?.bucketSize,
        instructions?.xAxisFormat?.dateFormat,
        instructions?.xAxisFormat?.stringFormat,
      );
      xCategories.push(formatted);
    });

    const sortedArray = cloneDeep(previewData);
    if (sortedArray[0] && typeof sortedArray[0][colorColName] === 'number') {
      sortedArray.sort((a, b) => Number(a[colorColName]) - Number(b[colorColName]));
    }
    sortedArray.forEach((row) => {
      const originalCategory = String(row[colorColName]);
      if (originalYCategories.has(originalCategory)) return;
      originalYCategories.add(originalCategory);

      const formatted = formatLabel(
        row[colorColName],
        colorColumn?.column.type,
        colorColumn?.bucket?.id,
      );
      yCategories.push(formatted);
    });

    return {
      xCategories,
      yCategories,
      originalXCategories: Array.from(originalXCategories),
      originalYCategories: Array.from(originalYCategories),
    };
  };

  transformData = (): SeriesOptions[] => {
    const { instructions, schema } = this.props;

    if (
      !instructions?.aggColumns ||
      instructions.aggColumns.length === 0 ||
      !schema ||
      schema.length === 0
    )
      return [];

    return this.transformColorData(schema);
  };

  transformColorData = (schema: DatasetSchema): SeriesOptions[] => {
    const { previewData, instructions, selectedColorColName } = this.props;
    const { xAxisColName, colorColName, aggColName } = getColorColNames(schema);
    const { xCategories, yCategories } = this.getAxisCategories();
    const colorColumn = getColorColumn(instructions, selectedColorColName);

    const dataByXAxis = groupBy(previewData, (row) =>
      formatLabel(
        row[xAxisColName],
        instructions?.categoryColumn?.column.type,
        instructions?.categoryColumn?.bucket?.id,
        instructions?.categoryColumn?.bucketSize,
        instructions?.xAxisFormat?.dateFormat,
        instructions?.xAxisFormat?.stringFormat,
      ),
    );

    if (!xCategories || !yCategories) return [];
    const data: [number, number, number][] = [];

    xCategories.forEach((xCategory, x) => {
      const dataByYAxis = keyBy(dataByXAxis[xCategory], (row) =>
        formatLabel(row[colorColName], colorColumn?.column.type, colorColumn?.bucket?.id),
      );
      yCategories.forEach((yCategory, y) => {
        if (dataByYAxis[yCategory]) {
          const row = dataByYAxis[yCategory];
          data.push([x, y, row[aggColName] as number]);
        } else {
          data.push([x, y, 0]);
        }
      });
    });

    return [
      { type: 'heatmap', data, nullColor: '#FFFFFF', borderWidth: 1, borderColor: '#cccccc' },
    ];
  };

  getColorAxisFloats = (aggColName: string, heatMapFormat: HeatMapFormat | undefined) => {
    const { previewData } = this.props;

    if (previewData.length === 0) return { colorAxisMin: 0, colorAxisMid: 0.5, colorAxisMax: 1 };
    let min = previewData[0][aggColName] as number;
    let max = min;
    previewData.forEach((row) => {
      const aggCol = row[aggColName] as number;
      min = Math.min(aggCol, min);
      max = Math.max(aggCol, max);
    });

    const colorAxisMin = getFloats(heatMapFormat?.gradientOptions?.minpoint, min, max, 0);
    const colorAxisMid = getFloats(heatMapFormat?.gradientOptions?.midpoint, min, max, 0.5);
    const colorAxisMax = getFloats(heatMapFormat?.gradientOptions?.maxpoint, min, max, 1);
    return { colorAxisMin, colorAxisMid, colorAxisMax };
  };
}

function getPointCategoryName(point: Point, dimension: string) {
  const series = point.series,
    isY = dimension === 'y',
    axis = series[isY ? 'yAxis' : 'xAxis'];
  return axis.categories[point[isY ? 'y' : 'x'] || 0];
}

export { HeatMap };
