import { makeStyles, Theme } from '@material-ui/core/styles';

import { Testimonial } from 'constants/onboardingConstants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  image: {
    borderRadius: '50%',
    width: 100,
    height: 100,
  },
  quote: {
    marginTop: theme.spacing(6),
    fontSize: 16,
    lineHeight: '28px',
    fontWeight: 400,
    textAlign: 'center',
    width: 300,
  },
  bio: {
    marginTop: theme.spacing(6),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  name: {
    fontWeight: 600,
  },
  title: {},
}));

type Props = {
  testimonial: Testimonial;
};

export default function TestimonialLayout(props: Props) {
  const classes = useStyles();
  const { companyName, personName, personTitle, personImage, quote } = props.testimonial;

  return (
    <div className={classes.root}>
      <img alt="testimonial face" className={classes.image} src={personImage} />
      <div className={classes.quote}>&ldquo;{quote}&rdquo;</div>
      <div className={classes.bio}>
        <div className={classes.name}>{personName}</div>
        <div className={classes.title}>
          {personTitle}, {companyName}
        </div>
      </div>
    </div>
  );
}
