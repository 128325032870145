import { makeStyles } from '@material-ui/core';
import LoadingSpinner from 'images/loading_spinner.gif';

const useStyles = makeStyles(() => ({
  loadingBody: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingSpinner: {
    width: 75,
    zIndex: 2,
  },
}));

export default function ExploLoadingSpinner() {
  const classes = useStyles();

  return (
    <div className={classes.loadingBody}>
      <img alt="loading spinner" className={classes.loadingSpinner} src={LoadingSpinner} />
    </div>
  );
}
