import produce from 'immer';
import { useDispatch } from 'react-redux';

import { Switch } from 'components/ds';
import SettingHeader from '../../DataConfigTab/SettingHeader';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import * as types from 'constants/types';
import { DatasetSchema } from 'types/datasets';
import { sharedStyles } from './styles';
import DropdownSelect from 'shared/DropdownSelect';

type Props = {
  visualizationType: types.OPERATION_TYPES;
  instructions: types.VisualizeTableInstructions;
  schema: DatasetSchema;
};

export default function TableDrilldown({ visualizationType, instructions, schema }: Props) {
  const classes = sharedStyles();
  const dispatch = useDispatch();

  const updateDrilldownConfig = (updates: types.TableDrilldownConfig) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.drilldownConfig = {
        ...draft.drilldownConfig,
        ...updates,
      };
    });

    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  const drilldownEnabled = instructions.drilldownConfig?.drilldownEnabled;

  const colDropdown = () => {
    const options = schema.map((col) => ({ id: col.name, name: col.friendly_name || col.name }));

    const drilldownCol = instructions.drilldownConfig?.drilldownColumn;
    const selectedItem = drilldownCol ? options.find((opt) => opt.id === drilldownCol) : undefined;

    return (
      <div className={classes.root}>
        <DropdownSelect
          btnMinimal
          fillWidth
          minimal
          containerClassName={classes.configInput}
          filterable={false}
          label="Selection value"
          noSelectionText="Column"
          onChange={(item) => updateDrilldownConfig({ drilldownColumn: item.id })}
          options={options}
          selectedItem={selectedItem}
        />
      </div>
    );
  };

  return (
    <>
      <SettingHeader
        name="Drilldown"
        rightContent={
          <Switch
            useCustomStyles
            onChange={() => updateDrilldownConfig({ drilldownEnabled: !drilldownEnabled })}
            switchOn={drilldownEnabled}
          />
        }
      />
      {drilldownEnabled && colDropdown()}
    </>
  );
}
