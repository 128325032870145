import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ArchitectCustomerDashboard } from './architectCustomerDashboard';

import { getQueryVariables } from 'components/EmbeddedDashboard/EmbeddedDashboard';
import { EmbeddedDashboardType } from 'components/EmbeddedDashboard/types';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { ArchitectCustomerDashboardReduxState } from 'architectCustomerDashboardContent/reducers/rootReducer';
import { GlobalStylesProvider } from 'globalStyles';
import { loadFonts } from 'globalStyles/helpers';
import {
  fetchArchitectCustomerDashboards,
  openArchitectCustomerDashboard,
} from 'actions/architectCustomerDashboardActions';

type Props = {
  canvasEmbedId: string;
  dashboardId: number;
  customerToken: string;
  userId: string | undefined;

  environment?: string;
};

const embedType: EmbeddedDashboardType = 'shared';

export const SharedArchitectCustomerDashboard: FC<Props> = (props) => {
  const globalStyleConfig = useSelector(
    (state: ArchitectCustomerDashboardReduxState) => state.dashboardStyles.globalStyleConfig,
  );
  const team = useSelector(
    (state: ArchitectCustomerDashboardReduxState) => state.architectCustomerDashboard.team,
  );

  const [urlVariables] = useState<DashboardVariableMap>(getQueryVariables(embedType));

  useEffect(() => {
    if (team !== null) loadFonts(globalStyleConfig.text, team.id, team.payment_plan);
  }, [globalStyleConfig, team]);

  return (
    <GlobalStylesProvider globalStyleConfig={globalStyleConfig}>
      {(globalStylesClassName) => (
        <SharedDashboard
          globalStylesClassName={globalStylesClassName}
          urlVariables={urlVariables}
          {...props}
        />
      )}
    </GlobalStylesProvider>
  );
};

type DashboardProps = Props & { globalStylesClassName: string; urlVariables: DashboardVariableMap };

const SharedDashboard: FC<DashboardProps> = ({
  canvasEmbedId,
  dashboardId,
  environment,
  customerToken,
  userId,
  globalStylesClassName,
  urlVariables,
}) => {
  const dispatch = useDispatch();

  const openDashboardId = useSelector(
    (state: ArchitectCustomerDashboardReduxState) =>
      state.architectCustomerDashboard.openDashboardId,
  );

  useEffect(() => {
    dispatch(openArchitectCustomerDashboard(dashboardId));
  }, [dispatch, dashboardId]);

  useEffect(() => {
    dispatch(
      fetchArchitectCustomerDashboards({
        customerToken: customerToken,
        postData: {
          canvas_embed_id: canvasEmbedId,
          version_number: null,
          user_id: userId ?? null,
          environment,
        },
      }),
    );
  }, [canvasEmbedId, customerToken, userId, environment, dispatch]);

  return (
    <div className={globalStylesClassName}>
      <ArchitectCustomerDashboard
        canvasEmbedId={canvasEmbedId}
        customerToken={customerToken}
        embedType={embedType}
        isViewOnly={true}
        key={openDashboardId}
        // Not important since modals aren't opened in shared env
        portalContainerId={''}
        urlVariables={urlVariables}
        userId={userId}
      />
    </div>
  );
};
