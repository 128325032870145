import { useDispatch } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';

import SharedDataLabelsConfigs from 'components/ChartConfigs/DataLabelsConfigs';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES, V2TwoDimensionChartInstructions } from 'constants/types';
import { sharedStyles } from './styles';

const useStyles = makeStyles((theme: Theme) => ({
  helperText: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '15px',
    color: theme.palette.ds.grey800,
    marginBottom: theme.spacing(3),
    padding: `0px ${theme.spacing(3)}px`,
  },
}));

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2TwoDimensionChartInstructions;
  firstColOnly?: boolean;
};

export default function BarLineDataLabelsConfig({
  visualizationType,
  instructions,
  firstColOnly,
}: Props) {
  const sharedClasses = sharedStyles();
  const classes = useStyles();
  const dispatch = useDispatch();

  const updateInstructions = (newInstructions: V2TwoDimensionChartInstructions) => {
    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  return (
    <div className={sharedClasses.root}>
      <SharedDataLabelsConfigs
        configInputClass={sharedClasses.configInput}
        firstColOnly={firstColOnly}
        helpTextClass={classes.helperText}
        instructions={instructions}
        updateInstructions={updateInstructions}
      />
    </div>
  );
}
