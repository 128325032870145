import { CustomerReportSort } from 'actions/customerReportActions';
import { FC, Suspense, useMemo, lazy, useRef, useCallback } from 'react';
import cx from 'classnames';
import {
  TypeColumn,
  TypeComputedColumn,
  TypeSortInfo,
} from '@inovua/reactdatagrid-enterprise/types';

import { Spinner, sprinkles } from 'components/ds';
import { DataGridPaginator, PaginatorProps } from 'components/ds/DataGrid/paginator';

import { useEmbedColumns } from 'components/embed/EmbedDataGrid/useEmbedColumns';
import { SortOrder } from 'constants/types';
import { DatasetSchema, DatasetRow, DatasetColumn } from 'types/datasets';
import { ColumnConfigs } from 'components/ds/DataGrid/columnUtils';

import * as styles from './index.css';
import '@inovua/reactdatagrid-enterprise/index.css';

const ReactDataGrid = lazy(
  () => import(/* webpackChunkName: "reactdatagrid" */ '@inovua/reactdatagrid-enterprise'),
);

type Props = {
  /**
   * Optional class name for style overrides
   */
  className?: string;
  /**
   * Allows an override of default columns that are generated from schema, rows, and columnConfigs
   */
  columns?: TypeColumn[];
  /**
   * Column configs for formatting
   */
  columnConfigs?: ColumnConfigs;
  /**
   * Show the loading state for the DataGrid
   */
  loading?: boolean;
  /**
   * Locks position of first row of data grid
   */
  lockFirstRow?: boolean;
  /**
   * Schema for column metadata (e.g. name, type)
   */
  schema: DatasetSchema;
  /**
   * Height that all rows should be
   */
  rowHeight?: number;
  /**
   * Rows of data to display
   */
  rows: DatasetRow[];
  /**
   * Props to show/configure DataGridPaginator
   */
  paginatorProps?: PaginatorProps;
  /**
   * Callback when column order changes
   */
  onColumnOrderChange?: (newOrder: string[]) => void;
  /**
   * Callback when a column is sorted
   */
  onSortColumn?: (sort: CustomerReportSort[]) => void;
  /**
   * Callback when a column is filtered
   */
  onFilterColumn?: (column: DatasetColumn) => void;
  /**
   * Manually control data grid sorting
   */
  sortInfo?: TypeSortInfo;
};

export const EmbedDataGrid: FC<Props> = ({
  className,
  columnConfigs,
  columns,
  loading,
  lockFirstRow,
  schema,
  rowHeight,
  rows,
  onColumnOrderChange,
  paginatorProps,
  sortInfo,
  onSortColumn,
  onFilterColumn,
}) => {
  const container = useRef<HTMLDivElement>(null);
  const gridColumns = useEmbedColumns({
    columns,
    columnConfigs,
    rows,
    schema,
    containerRef: container,
    onFilterColumn,
    onSortColumn,
  });

  const lockedRows = useMemo(() => {
    if (!lockFirstRow || rows.length < 1) return;

    const firstRowData = rows[0];
    return [
      {
        position: 'start' as 'start' | 'end',
        render: ({ column }: { column: TypeComputedColumn }) => {
          if (column.computedAbsoluteIndex === undefined || !column.name) return;
          const columnInfo = gridColumns[column.computedAbsoluteIndex];
          const value = firstRowData[column.name];
          return columnInfo.render?.({ isLockedFirstRow: true, rowIndex: 0, value });
        },
      },
    ];
  }, [lockFirstRow, rows, gridColumns]);

  const renderLoadMask = ({ visible }: { visible: boolean }) => {
    return visible ? <Spinner fillContainer className={styles.loadMask} /> : null;
  };

  const handleSortInfoChange = useCallback(
    (sortInfo: TypeSortInfo) => {
      if (!onSortColumn) return;

      // Convert React Data Grid's sort to Explo's sort: SortInfo --> CustomerReportSort
      const sortInfoList = !sortInfo ? [] : Array.isArray(sortInfo) ? sortInfo : [sortInfo];
      const transformedSort = sortInfoList.map((sortInfo) => ({
        column: {
          name: sortInfo.name,
        },
        order: sortInfo.dir === -1 ? SortOrder.DESC : SortOrder.ASC,
      }));
      onSortColumn(transformedSort);
    },
    [onSortColumn],
  );

  return (
    <div className={styles.container}>
      <Suspense fallback={<Spinner fillContainer />}>
        <div className={sprinkles({ flex: 1 })} ref={container}>
          <ReactDataGrid
            className={cx(sprinkles({ height: 'fill' }), className)}
            columnOrder={schema?.map((col) => col.name)}
            columns={gridColumns}
            dataSource={rows.slice(lockFirstRow ? 1 : 0)}
            idProperty="id"
            licenseKey={process.env.REACT_APP_DATAGRID_KEY}
            loading={loading}
            lockedRows={lockedRows}
            onColumnOrderChange={onColumnOrderChange}
            onSortInfoChange={handleSortInfoChange}
            renderLoadMask={renderLoadMask}
            reorderColumns={onColumnOrderChange !== undefined}
            rowHeight={rowHeight}
            showColumnMenuTool={false}
            sortInfo={sortInfo}
            sortable={!!onSortColumn}
          />
        </div>
        {paginatorProps ? (
          <DataGridPaginator {...paginatorProps} currentRowCount={rows.length} />
        ) : null}
      </Suspense>
    </div>
  );
};
