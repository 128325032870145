import { groupBy } from 'utils/standard';
import { useDispatch } from 'react-redux';

import SettingHeader from './DataPanelConfigV2/DataConfigTab/SettingHeader';
import { DashboardElementVisibilityCard } from 'components/DashboardElementVisibilityCard';
import * as styles from './DashboardElementShowHidePanel.css';
import { sprinkles } from 'components/ds';

import { DashboardElement, VIEW_MODE } from 'types/dashboardTypes';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { LayoutExclusionMap, ElementExclusionMap } from 'types/dashboardVersionConfig';
import { VIZ_TO_NAME } from 'constants/dataConstants';
import { getChartIcon } from 'pages/BlueprintPage/icons';
import { toggleElementVisibilityForSecondaryLayout } from 'actions/dashboardV2Actions';

type Props = {
  dataPanels: DataPanelTemplate[];
  dashboardElements: DashboardElement[];
  layoutExclusions?: LayoutExclusionMap;
  viewMode: VIEW_MODE;
};

export function DashboardElementShowHidePanel({
  dataPanels,
  dashboardElements,
  layoutExclusions,
  viewMode,
}: Props): JSX.Element {
  const dispatch = useDispatch();

  const exclusionMap = getExclusionMapFromViewMode(viewMode, layoutExclusions);

  if (exclusionMap === undefined) return <div>Error</div>;

  const groupedDataPanels = groupBy(dataPanels, (panel) => panel.visualize_op.operation_type);

  return (
    <div className={styles.showHidePanelContainer}>
      <SettingHeader name="Elements" />
      <div className={sprinkles({ padding: 'sp1' })}>
        {dashboardElements.map((element) => (
          <DashboardElementVisibilityCard
            isSelected={!exclusionMap[element.id]}
            key={element.id}
            name={element.name}
            onClick={() =>
              dispatch(
                toggleElementVisibilityForSecondaryLayout({
                  id: element.id,
                  layoutType: viewMode,
                  isExcluded: !exclusionMap[element.id],
                }),
              )
            }
          />
        ))}
      </div>
      {Object.values(groupedDataPanels).map((group) => {
        const opType = group[0].visualize_op.operation_type;
        const opName = VIZ_TO_NAME[opType];

        return (
          <div key={opType}>
            <SettingHeader name={opName} />
            <div className={sprinkles({ padding: 'sp1' })}>
              {group.map((dataPanel) => {
                // filter out drilldowns and other future throw away panels
                if (dataPanel.id.startsWith('_')) return;

                return (
                  <DashboardElementVisibilityCard
                    icon={getChartIcon(dataPanel.visualize_op.operation_type)}
                    isSelected={!exclusionMap[dataPanel.id]}
                    key={dataPanel.id}
                    name={
                      dataPanel.visualize_op.generalFormatOptions?.headerConfig?.title || opName
                    }
                    onClick={() =>
                      dispatch(
                        toggleElementVisibilityForSecondaryLayout({
                          id: dataPanel.id,
                          layoutType: viewMode,
                          isExcluded: !exclusionMap[dataPanel.id],
                        }),
                      )
                    }
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

const getExclusionMapFromViewMode = (
  viewMode: VIEW_MODE,
  layoutExclusions?: LayoutExclusionMap,
): ElementExclusionMap | undefined => {
  const emptyExclusionMap: ElementExclusionMap = {};

  switch (viewMode) {
    case VIEW_MODE.PDF:
      return layoutExclusions?.pdfExclusions || emptyExclusionMap;
    case VIEW_MODE.MOBILE:
      return layoutExclusions?.mobileExclusions || emptyExclusionMap;
    case VIEW_MODE.EMAIL:
      return layoutExclusions?.emailExclusions || emptyExclusionMap;
    default:
      return undefined;
  }
};
