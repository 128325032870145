import cx from 'classnames';
import { FC, MouseEventHandler } from 'react';

import { sprinkles, Icon } from 'components/ds';
import { IconName } from 'components/ds/Icon';

import * as styles from './exportStyles.css';

import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

interface ExportOptionItemProps {
  onClick: MouseEventHandler;
  option: string;
  icon: IconName;
  rightArrow?: boolean;
}

export const ExportOptionItem: FC<ExportOptionItemProps> = ({
  onClick,
  option,
  icon,
  rightArrow,
}) => (
  <div className={styles.menuItem} onClick={onClick}>
    <div className={styles.menuItemLabelIcon}>
      <Icon className={sprinkles({ color: 'contentSecondary' })} name={icon} />
      <div className={cx(sprinkles({ body: 'b2' }), GLOBAL_STYLE_CLASSNAMES.text.body.primary)}>
        {option}
      </div>
    </div>
    {rightArrow ? (
      <Icon className={sprinkles({ color: 'contentTertiary' })} name="arrow-right" />
    ) : null}
  </div>
);
