import { useContext } from 'react';
import cx from 'classnames';
import { cloneDeep } from 'utils/standard';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import { Switch } from 'components/ds';
import InputLabel from 'shared/InputLabel';
import ColorPickerButton from 'shared/ColorPicker';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { BoxPlotFormat, OPERATION_TYPES, V2BoxPlotInstructions } from 'constants/types';
import { titleCase } from 'utils/graphUtils';
import { ReduxState } from 'reducers/rootReducer';
import { getCategoricalColors, GlobalStylesContext } from 'globalStyles';
import { sharedStyles } from './styles';
import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';

const useStyles = makeStyles((theme: Theme) => ({
  columnHeading: {
    fontSize: 12,
    fontWeight: 600,
  },
  colorOptions: {
    padding: `0px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  colorPickerContainer: {
    width: '100%',
  },
  medianColor: {
    marginLeft: theme.spacing(3),
  },
  colorPicker: {
    width: '100%',
  },
}));

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2BoxPlotInstructions;
};

export default function BoxPlotConfig({ visualizationType, instructions }: Props) {
  const { calcColumns, boxPlotFormat } = instructions;
  const classes = useStyles();
  const sharedClasses = sharedStyles();
  const dispatch = useDispatch();
  const context = useContext(GlobalStylesContext);
  /**
   * We use useSelector here instead of useContext because the context around content other than the DashboardLayout
   * uses the default theme to avoid using custom styles in Explo app components. But here we want to use the user's configured
   * theme to determine what to display, not the default.
   */
  const globalStyleConfig = useSelector(
    (state: ReduxState) => state.dashboardStyles.globalStyleConfig,
  );

  const updateBoxPlotFormat = (boxPlotFormat: BoxPlotFormat) => {
    const newInstructions = cloneDeep(instructions);
    newInstructions.boxPlotFormat = {
      ...newInstructions.boxPlotFormat,
      ...boxPlotFormat,
    };
    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  return (
    <div className={sharedClasses.root}>
      {calcColumns?.map((col, idx) => (
        <>
          <div className={sharedClasses.configInput}>
            <div className={classes.columnHeading}>
              {titleCase(col.name || `Column ${idx + 1}`)}
            </div>
            <InputWithBlurSave
              initialValue={String(
                boxPlotFormat?.seriesLabelByColumn?.[col.name || ''] || `Series ${idx + 1}`,
              )}
              label="Series label"
              onNewValueSubmitted={(newValue) => {
                updateBoxPlotFormat({
                  seriesLabelByColumn: {
                    ...instructions.boxPlotFormat?.seriesLabelByColumn,
                    ...(col.name && { [col.name]: newValue }),
                  },
                });
              }}
            />
          </div>
          <div className={classes.colorOptions}>
            <div className={classes.colorPickerContainer}>
              <InputLabel text="Fill color" />
              <ColorPickerButton
                className={classes.colorPicker}
                color={
                  instructions.boxPlotFormat?.fillColorByColumn?.[col.name || ''] ||
                  globalStyleConfig.visualizations.gradientPalette.hue2
                }
                colorPalette={getCategoricalColors(context.globalStyleConfig)}
                onColorChange={(newColor) => {
                  updateBoxPlotFormat({
                    fillColorByColumn: {
                      ...instructions.boxPlotFormat?.fillColorByColumn,
                      ...(col.name && { [col.name]: newColor }),
                    },
                  });
                }}
              />
            </div>
            <div className={cx(classes.colorPickerContainer, classes.medianColor)}>
              <InputLabel text="Median color" />
              <ColorPickerButton
                className={classes.colorPicker}
                color={
                  instructions.boxPlotFormat?.medianColorByColumn?.[col.name || ''] ||
                  globalStyleConfig.visualizations.gradientPalette.hue1
                }
                colorPalette={getCategoricalColors(context.globalStyleConfig)}
                onColorChange={(newColor: string) => {
                  updateBoxPlotFormat({
                    medianColorByColumn: {
                      ...instructions.boxPlotFormat?.medianColorByColumn,
                      ...(col.name && { [col.name]: newColor }),
                    },
                  });
                }}
              />
            </div>
          </div>
        </>
      ))}
      <ToggleButtonGroup fillWidth className={sharedClasses.configInput} label="Orientation">
        <ToggleButton
          active={!boxPlotFormat?.isVertical}
          key="horizontal"
          onClick={() => updateBoxPlotFormat({ isVertical: false })}
          text="Horizontal"
        />
        <ToggleButton
          active={boxPlotFormat?.isVertical}
          key="vertical"
          onClick={() => updateBoxPlotFormat({ isVertical: true })}
          text="Vertical"
        />
      </ToggleButtonGroup>
      <Switch
        className={sharedClasses.configInput}
        label="Show whisker"
        onChange={() => {
          updateBoxPlotFormat({ hideWhisker: !boxPlotFormat?.hideWhisker });
        }}
        switchOn={!boxPlotFormat?.hideWhisker}
      />
    </div>
  );
}
