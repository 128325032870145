import { useMemo } from 'react';
import { cloneDeep, keyBy } from 'utils/standard';
import { makeStyles, Theme } from '@material-ui/core/styles';

import DropdownSelect from 'shared/DropdownSelect';
import { Switch } from 'components/ds';

import {
  TableJoinColumnConfig,
  VisualizePivotTableInstructions,
  VisualizeTableInstructions,
} from 'constants/types';
import { Dataset } from 'actions/datasetActions';
import { DatasetColumn } from 'types/datasets';
import { createColOptionsWithIcon, createColOptionWithIconFromCol } from 'utils/general';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  configInput: {
    marginTop: theme.spacing(2),
  },
}));

type Props = {
  column: DatasetColumn;
  instructions: VisualizeTableInstructions | VisualizePivotTableInstructions;
  updateInstructions: (
    newInstructions: VisualizeTableInstructions | VisualizePivotTableInstructions,
  ) => void;
  dashboardDatasets: Record<string, Dataset>;
};

export default function EnrichColumnConfiguration({
  column,
  instructions,
  updateInstructions,
  dashboardDatasets,
}: Props) {
  const classes = useStyles();
  const config = instructions.schemaDisplayOptions?.[column.name];

  const savedDatasets = Object.values(dashboardDatasets).filter((dataset) => !!dataset.schema);
  const selectedDataset = config?.joinTable && dashboardDatasets[config.joinTable.id];

  const schemaByName =
    selectedDataset && selectedDataset.schema ? keyBy(selectedDataset.schema, 'name') : {};
  const selectedJoinColumn = config?.joinColumn && schemaByName[config.joinColumn.name];
  const selectedDisplayColumn =
    config?.joinDisplayColumn && schemaByName[config.joinDisplayColumn.name];

  const updateSchemaDisplayOptions = (updates: TableJoinColumnConfig) => {
    const newInstructions = cloneDeep(instructions);
    if (!newInstructions.schemaDisplayOptions) return;
    newInstructions.schemaDisplayOptions[column.name] = {
      ...newInstructions.schemaDisplayOptions[column.name],
      ...updates,
    };

    updateInstructions(newInstructions);
  };

  const options = useMemo(
    () => createColOptionsWithIcon(selectedDataset?.schema || []),
    [selectedDataset],
  );

  return (
    <div className={classes.root}>
      <Switch
        useCustomStyles
        className={classes.configInput}
        label="Join"
        onChange={() => updateSchemaDisplayOptions({ joinOn: !config?.joinOn })}
        switchOn={config?.joinOn}
      />

      {config?.joinOn && (
        <>
          <DropdownSelect
            fillWidth
            minimal
            showCancelBtn
            containerClassName={classes.configInput}
            filterable={false}
            label="Dataset"
            noSelectionText="Select dataset"
            onCancelClick={() => updateSchemaDisplayOptions({ joinTable: undefined })}
            onChange={(item) =>
              updateSchemaDisplayOptions({ joinTable: { id: item.id, name: item.name } })
            }
            options={savedDatasets.map((dataset: Dataset) => ({
              id: String(dataset.id),
              name: dataset.table_name || 'Untitled',
            }))}
            selectedItem={
              selectedDataset && {
                id: selectedDataset.id,
                name: selectedDataset.table_name || 'Untitled',
              }
            }
          />
          <DropdownSelect
            fillWidth
            minimal
            showCancelBtn
            showIcon
            containerClassName={classes.configInput}
            disabled={selectedDataset === undefined}
            filterable={false}
            label="Join Column"
            noSelectionText="Select a column"
            onCancelClick={() => updateSchemaDisplayOptions({ joinColumn: undefined })}
            onChange={(item) =>
              updateSchemaDisplayOptions({
                joinColumn: { id: item.id, name: item.name, column: schemaByName[item.name] },
              })
            }
            options={options}
            selectedItem={selectedJoinColumn && createColOptionWithIconFromCol(selectedJoinColumn)}
          />
          <DropdownSelect
            fillWidth
            minimal
            showCancelBtn
            showIcon
            containerClassName={classes.configInput}
            disabled={selectedDataset === undefined || selectedJoinColumn === undefined}
            filterable={false}
            label="Display Column"
            noSelectionText="Select a column"
            onCancelClick={() => updateSchemaDisplayOptions({ joinDisplayColumn: undefined })}
            onChange={(item) =>
              updateSchemaDisplayOptions({
                joinDisplayColumn: {
                  id: item.id,
                  name: item.name,
                  column: schemaByName[item.name],
                },
              })
            }
            options={options}
            selectedItem={
              selectedDisplayColumn && createColOptionWithIconFromCol(selectedDisplayColumn)
            }
          />
        </>
      )}
    </div>
  );
}
