import { useState } from 'react';
import produce from 'immer';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { NumericInput } from '@blueprintjs/core';

import ConfigSectionHeader from 'pages/dataPanelEditorPage/configSectionHeader';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import ColorPicker from './CustomStylesColorPicker';
import LineSelect from 'shared/LineSelect';
import FlexBox, { VerticalAlignment } from 'components/core/FlexBox';
import FlexItem from 'components/core/FlexItem';
import CollapsibleMenu from 'components/CollapsibleMenu';
import CustomizedTag from './CustomizedTag';

import { GlobalStyleConfig } from 'globalStyles/types';
import { showErrorToast } from 'shared/sharedToasts';

const useStyles = makeStyles((theme: Theme) => ({
  spacingInput: {
    width: 55,
    marginRight: theme.spacing(3),
  },
  numColumnsSection: {
    marginTop: theme.spacing(6),
  },
  spacingHeading: {
    marginTop: theme.spacing(6),
  },
  backgroundColorSelection: {
    marginRight: theme.spacing(3),
  },
  actionsColorSelection: {
    marginLeft: theme.spacing(3),
  },
  inputLabel: {
    fontSize: 12,
    marginBottom: theme.spacing(1),
  },
  sectionMargin: {
    marginBottom: theme.spacing(4),
  },
  subsectionMargin: {
    marginBottom: theme.spacing(2),
  },
  input: {
    height: 32,
  },
}));

type Props = {
  styleConfig: GlobalStyleConfig;
  updateConfig: (newConfig: GlobalStyleConfig) => void;
};

export default function BaseConfigSection(props: Props) {
  const classes = useStyles();

  return (
    <>
      <ConfigSectionHeader title="Base" />
      <NumColumnsInput {...props} />
      <ConfigSectionHeader compact isSubTitle title="Color" />
      <ColorInputs {...props} />
      <CustomInteractionColorMenu {...props} />
      <ConfigSectionHeader isSubTitle className={classes.spacingHeading} title="Spacing" />
      <SpacingInputs {...props} />
    </>
  );
}

function NumColumnsInput({ styleConfig, updateConfig }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.sectionMargin}>
      <div className={classes.inputLabel}>Number of dashboard columns</div>
      <NumericInput
        allowNumericCharactersOnly
        fill
        className={classes.input}
        min={1}
        onValueChange={(newNumColumns) => {
          if (isNaN(newNumColumns) || newNumColumns < 1) return;
          const newConfig = produce(styleConfig, (draft) => {
            draft.base.numColumns = newNumColumns;
          });
          updateConfig(newConfig);
        }}
        value={styleConfig.base.numColumns}
      />
    </div>
  );
}

function ColorInputs({ styleConfig, updateConfig }: Props) {
  const classes = useStyles();

  return (
    <FlexBox className={classes.sectionMargin}>
      <FlexItem>
        <div className={classes.backgroundColorSelection}>
          <div className={classes.inputLabel}>Background</div>
          <ColorPicker
            fill
            color={styleConfig.base.backgroundColor}
            onClose={(newColor) => {
              const newConfig = produce(styleConfig, (draft) => {
                draft.base.backgroundColor = newColor;
              });
              updateConfig(newConfig);
            }}
          />
        </div>
      </FlexItem>
      <FlexItem>
        <div className={classes.actionsColorSelection}>
          <div className={classes.inputLabel}>Actions</div>
          <ColorPicker
            fill
            color={styleConfig.base.actionColor.default}
            onClose={(newColor) => {
              const newConfig = produce(styleConfig, (draft) => {
                draft.base.actionColor.default = newColor;
              });
              updateConfig(newConfig);
            }}
          />
        </div>
      </FlexItem>
    </FlexBox>
  );
}

function CustomInteractionColorMenu({ styleConfig, updateConfig }: Props) {
  const classes = useStyles();
  const [colorMenuOpen, setColorMenuOpen] = useState(false);

  return (
    <CollapsibleMenu
      isExpanded={colorMenuOpen}
      setIsExpanded={setColorMenuOpen}
      title="Custom interaction colors">
      <div className={classes.subsectionMargin}>
        <FlexBox className={classes.inputLabel} verticalAlignment={VerticalAlignment.CENTER}>
          <div>Button color</div>
          <CustomizedTag
            hidden={!styleConfig.base.actionColor.buttonColor}
            onCancel={() => {
              const newConfig = produce(styleConfig, (draft) => {
                draft.base.actionColor.buttonColor = undefined;
              });
              updateConfig(newConfig);
            }}
          />
        </FlexBox>
        <ColorPicker
          fill
          color={styleConfig.base.actionColor.buttonColor || styleConfig.base.actionColor.default}
          onClose={(newColor) => {
            const newConfig = produce(styleConfig, (draft) => {
              draft.base.actionColor.buttonColor = newColor;
            });
            updateConfig(newConfig);
          }}
        />
      </div>
      <FlexBox className={classes.inputLabel} verticalAlignment={VerticalAlignment.CENTER}>
        <div>Interaction states</div>
        <CustomizedTag
          hidden={!styleConfig.base.actionColor.interactionStateColor}
          onCancel={() => {
            const newConfig = produce(styleConfig, (draft) => {
              draft.base.actionColor.interactionStateColor = undefined;
            });
            updateConfig(newConfig);
          }}
        />
      </FlexBox>
      <ColorPicker
        fill
        alpha={0.1}
        color={
          styleConfig.base.actionColor.interactionStateColor || styleConfig.base.actionColor.default
        }
        onClose={(newColor) => {
          const newConfig = produce(styleConfig, (draft) => {
            draft.base.actionColor.interactionStateColor = newColor;
          });
          updateConfig(newConfig);
        }}
      />
    </CollapsibleMenu>
  );
}

function SpacingInputs({ styleConfig, updateConfig }: Props) {
  const classes = useStyles();

  return (
    <FlexBox className={classes.sectionMargin} verticalAlignment={VerticalAlignment.BOTTOM}>
      <InputWithBlurSave
        hideRightIconInteractions
        containerClassName={classes.spacingInput}
        initialValue={`${styleConfig.base.spacing.default}px`}
        onNewValueSubmitted={(newSpacingString) => {
          const newSpacing = Number(newSpacingString.replace('px', ''));
          if (isNaN(newSpacing) || newSpacing < 0) return;
          if (styleConfig.base.spacing.default !== 48 && newSpacing > 48) {
            /**
             * Setting spacing above 48px messes with the dashboard layout
             */
            showErrorToast('Cannot set spacing above 48px');
          }
          const newConfig = produce(styleConfig, (draft) => {
            draft.base.spacing.default = newSpacing <= 48 ? newSpacing : 48;
          });
          updateConfig(newConfig);
        }}
      />
      <FlexItem>
        <LineSelect
          fill
          breakpoints={[10, 15, 20, 25, 30]}
          labels={['Narrow', 'Moderate', 'Wide']}
          setValue={(newSpacing) => {
            const newConfig = produce(styleConfig, (draft) => {
              draft.base.spacing.default = newSpacing;
            });
            updateConfig(newConfig);
          }}
          value={styleConfig.base.spacing.default}
        />
      </FlexItem>
    </FlexBox>
  );
}
