import cx from 'classnames';
import { FC } from 'react';
import Highcharts from 'highcharts';
import HighCharts from '../highCharts';

import { CustomMenuOptionConfig, V2TwoDimensionChartInstructions } from 'constants/types';
import * as Popover from '@radix-ui/react-popover';
import { Menu, MenuItem } from '@blueprintjs/core';
import { sprinkles } from 'components/ds';

import { DrilldownVariable, OpenDrilldownModalType } from 'types/dashboardTypes';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { getColorColumn } from 'utils/colorColUtils';
import { useSelector } from 'react-redux';
import { JoinedStates } from 'reducers/rootReducer';
import { DASHBOARD_LAYOUT_ID_FOR_PORTALS } from 'components/DashboardLayout/DashboardLayout';
import { DATE } from 'constants/dataConstants';

interface Props {
  instructions: V2TwoDimensionChartInstructions | undefined;
  chartOptions: Highcharts.Options | undefined;
  customMenuOptions: CustomMenuOptionConfig[] | undefined;
  dataPanelTemplateId: string;

  // Used for selecting categories in bar charts
  drilldownVar?: DrilldownVariable;
  setCategorySelect?: (category: string, colorColumn?: string) => void;

  // Used for opening drilldown modals
  underlyingDataEnabled?: boolean;
  openDrilldownModal: OpenDrilldownModalType | undefined;
  selectedColorColName?: string;

  // Used for excluding categories when opening pie chart drilldown
  excludedCategories?: (string | number)[];

  closeChartMenu: () => void;
}

export const DrilldownChart: FC<Props> = ({
  instructions,
  chartOptions,
  customMenuOptions,
  dataPanelTemplateId,
  openDrilldownModal,
  selectedColorColName,
  setCategorySelect,
  excludedCategories,
  underlyingDataEnabled,
  closeChartMenu,
  drilldownVar,
}) => {
  const chartMenuInfo = useSelector((state: JoinedStates) => state.dashboardLayout.chartMenu);

  const hasColorOptions = !!instructions?.colorColumnOptions?.length;

  const onUnderlyingDataClick = (category: string, subCategory?: string) => {
    const categoryColumn = instructions?.categoryColumn;
    if (!categoryColumn || !openDrilldownModal) return;

    const colorColumn = getColorColumn(instructions, selectedColorColName);

    openDrilldownModal(
      dataPanelTemplateId,
      categoryColumn,
      category,
      colorColumn,
      hasColorOptions ? subCategory : undefined,
      category === 'Other' && excludedCategories !== undefined ? excludedCategories : undefined,
    );
  };

  const onCustomMenuOptionClicked = (jsEvent: string, category: string, subCategory?: string) => {
    window.dispatchEvent(new CustomEvent(jsEvent, { detail: { category, color: subCategory } }));
  };

  const renderChartMenu = () => {
    if (chartMenuInfo?.chartId !== dataPanelTemplateId) return null;
    const { chartX, chartY, category, subCategory } = chartMenuInfo;

    const isCategorySelected =
      drilldownVar?.category === category && drilldownVar?.color === subCategory;

    // Block drilldown for other category for pie chart date columns
    const blockOtherDrilldownForDateType =
      category === 'Other' &&
      instructions?.categoryColumn?.column.type === DATE &&
      excludedCategories !== undefined;

    const canViewUnderlyingData = underlyingDataEnabled && !blockOtherDrilldownForDateType;

    if (!canViewUnderlyingData && !setCategorySelect && !customMenuOptions?.length) return null;

    return (
      <Popover.Root onOpenChange={(open) => !open && closeChartMenu()} open={true}>
        <Popover.Anchor asChild>
          <div
            className={sprinkles({ position: 'absolute' })}
            style={{ left: chartMenuInfo.chartX, top: chartMenuInfo.chartY }}
          />
        </Popover.Anchor>
        <Popover.Portal
          container={document.getElementById(DASHBOARD_LAYOUT_ID_FOR_PORTALS) ?? undefined}>
          {/* Added key so it repaints if another part of the chart is clicked */}
          <Popover.Content
            align="start"
            key={`${chartX}-${chartY}`}
            onClick={() => closeChartMenu()}
            side="right"
            style={{ zIndex: 10 }}>
            <Menu
              className={cx(
                sprinkles({ boxShadow: 'md' }),
                GLOBAL_STYLE_CLASSNAMES.text.body.primaryFont,
              )}>
              {canViewUnderlyingData ? (
                <MenuItem
                  onClick={() => onUnderlyingDataClick(category, subCategory)}
                  text="View Underlying Data"
                />
              ) : null}
              {setCategorySelect ? (
                <MenuItem
                  onClick={() =>
                    setCategorySelect(category, hasColorOptions ? subCategory : undefined)
                  }
                  text={isCategorySelected ? 'Remove Filter' : 'Filter Category'}
                />
              ) : null}
              {customMenuOptions?.map((menuOption) =>
                menuOption.name && menuOption.customJSEventName ? (
                  <MenuItem
                    onClick={() =>
                      onCustomMenuOptionClicked(
                        menuOption.customJSEventName as string,
                        category,
                        subCategory,
                      )
                    }
                    text={menuOption.name}
                  />
                ) : null,
              )}
            </Menu>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    );
  };

  return (
    <div className={sprinkles({ position: 'relative', parentContainer: 'fill' })}>
      <HighCharts chartOptions={chartOptions} />
      {renderChartMenu()}
    </div>
  );
};
