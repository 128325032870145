import produce from 'immer';

import FormatSection from './FormatSection';
import SharedLineChartConfigs from 'components/ChartConfigs/LineChartConfigs';
import SharedAreaChartConfig from 'components/ChartConfigs/AreaChartConfigs';

import { sharedStyles } from './sharedStyles';
import {
  ChartSpecificFormat,
  OPERATION_TYPES,
  V2TwoDimensionChartInstructions,
} from 'constants/types';
import { FormatConfigProps } from './types';
import { useSelector } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';

type Props = FormatConfigProps<
  OPERATION_TYPES.VISUALIZE_AREA_CHART_V2,
  V2TwoDimensionChartInstructions
>;

export default function AreaChartSection({
  isArchitectCustomerDashboard,
  instructions,
  updateInstructions,
  operationType,
}: Props): JSX.Element | null {
  const classes = sharedStyles({ isArchitectCustomerDashboard });

  const globalStyleConfig = useSelector(
    (state: ReduxState) => state.dashboardStyles.globalStyleConfig,
  );

  const updateChartSpecificFormat = (specificUpdates: ChartSpecificFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.chartSpecificFormat = {
        ...draft.chartSpecificFormat,
        ...specificUpdates,
      };
    });
    updateInstructions({ instructions: newInstructions, operationType });
  };

  return (
    <FormatSection isArchitectCustomerDashboard={isArchitectCustomerDashboard} title="Area Chart">
      <SharedLineChartConfigs
        isCanvas
        configInputClass={classes.configInput}
        defaultLineWidth={globalStyleConfig.container.lineWidth.default}
        instructions={instructions}
        updateChartSpecificFormat={updateChartSpecificFormat}
      />
      <SharedAreaChartConfig
        configInputClass={classes.configInput}
        instructions={instructions}
        updateInstructions={(instructions: V2TwoDimensionChartInstructions) =>
          instructions.chartSpecificFormat &&
          updateChartSpecificFormat(instructions.chartSpecificFormat)
        }
      />
    </FormatSection>
  );
}
