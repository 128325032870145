import { FC, useState } from 'react';
import { Intent, FormGroup } from '@blueprintjs/core';
import { Modal, sprinkles } from 'components/ds';

import InputGroup from 'explo-ds/forms/marketing/inputGroup';

type Props = {
  buttonName: string;
  closeModal: () => void;
  errorState?: (textFieldVal?: string) => { isErrorState: boolean; errorMsg?: string };
  keepOpenOnSubmit?: boolean;
  loading?: boolean;
  modalOpen: boolean;
  modalTitle: string;
  onSubmit: (text: string) => void;
  portalContainerId?: string;
  resourceName?: string;
  textFieldPlaceholder: string;
};

export const TextFieldModal: FC<Props> = ({
  buttonName,
  closeModal,
  errorState,
  keepOpenOnSubmit,
  portalContainerId,
  loading,
  modalOpen,
  modalTitle,
  onSubmit,
  resourceName,
  textFieldPlaceholder,
}) => {
  const [textFieldVal, setTextFieldVal] = useState(resourceName ?? '');

  const { isErrorState, errorMsg } = errorState
    ? errorState(textFieldVal)
    : { isErrorState: false, errorMsg: undefined };

  const onButtonClick = () => {
    onSubmit(textFieldVal);
    if (!keepOpenOnSubmit) {
      setTextFieldVal('');
      closeModal();
    }
  };

  return (
    <Modal
      isOpen={modalOpen}
      onClose={closeModal}
      portalContainerId={portalContainerId}
      primaryButtonProps={{
        disabled: isErrorState || textFieldVal.trim() === '' || loading,
        loading: loading,
        onClick: onButtonClick,
        text: buttonName,
      }}
      size="small"
      title={modalTitle}>
      <div className={sprinkles({ paddingX: 'sp3', paddingTop: 'sp.5' })} style={{ height: 61 }}>
        <FormGroup
          helperText={errorMsg}
          intent={isErrorState ? Intent.DANGER : Intent.NONE}
          labelFor="text-input">
          <InputGroup
            defaultValue={resourceName}
            intent={isErrorState ? Intent.DANGER : Intent.NONE}
            large={true}
            onInputChange={setTextFieldVal}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && textFieldVal.trim() !== '') {
                onSubmit(textFieldVal);
                if (!keepOpenOnSubmit) {
                  setTextFieldVal('');
                  closeModal();
                }
              }
            }}
            placeholder={textFieldPlaceholder}
          />
        </FormGroup>
      </div>
    </Modal>
  );
};
