import { FC, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { usePopper } from 'react-popper';
import { cloneDeep } from 'utils/standard';
import * as RD from 'remotedata';

import ChartEditor from 'pages/BlueprintPage/ChartEditor';
import Button from 'shared/Button';

import { ArchitectCustomerDashboardReduxState } from 'architectCustomerDashboardContent/reducers/rootReducer';
import { VisualizeOperation } from 'types/dataPanelTemplate';
import { DataPanel } from 'types/exploResource';
import { FilterClause } from 'constants/types';
import { CanvasConfigureType } from 'actions/canvasActions';
import { getCurrentDashboard } from 'reducers/architectCustomerDashboardReducer';
import { deleteEUDItem } from 'actions/architectCustomerDashboardConfigActions';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  },
  editorWrapper: {
    width: 310,
    backgroundColor: theme.palette.ds.white,
    border: `1px solid ${theme.palette.ds.grey400}`,
    borderRadius: 4,
    zIndex: 3,
    maxHeight: '80vh',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  deleteWrapper: {
    width: 310,
    height: 50,
    border: `1px solid ${theme.palette.ds.grey400}`,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.ds.white,
    borderRadius: 4,
    zIndex: 3,
    display: 'flex',
    alignItems: 'center',
  },
}));

type Props = {
  // Both are undefined for dashboard element editing
  panelId?: string;
  fetchDataPanelData?: (dataPanel: DataPanel) => void;
};

export const ArchitectCustomerDashboardEditor: FC<Props> = ({ panelId, fetchDataPanelData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { editingDashboard, canvasVersion, dashboard, canvas } = useSelector(
    (state: ArchitectCustomerDashboardReduxState) => ({
      editingDashboard: state.architectCustomerDashboard.editingDashboard,
      canvasVersion: state.architectCustomerDashboard.canvasVersion,
      dashboard: getCurrentDashboard(state.architectCustomerDashboard),
      canvas: state.architectCustomerDashboard.canvas,
    }),
    shallowEqual,
  );

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const popper = usePopper(referenceElement, popperElement, {
    strategy: 'fixed',
    placement: 'right-start',
    modifiers: [
      {
        name: 'flip',
        options: { fallbackPlacements: ['right-start', 'left-start'] },
      },
      { name: 'preventOverflow', options: { altAxis: true, padding: 20 } },
      { name: 'offset', options: { offset: [0, 20] } },
    ],
  });

  if (!canvasVersion || !editingDashboard) return null;

  const datasets = canvasVersion.configuration.datasets;

  const fetchPanelData = ({
    newViz,
    newFilters,
  }: {
    newViz?: VisualizeOperation;
    newFilters?: FilterClause[];
  }) => {
    if (!fetchDataPanelData || !panelId) return;

    const panel = editingDashboard.configuration.data_panels[panelId];
    if (!panel) return;

    if (newViz) fetchDataPanelData({ ...panel, visualize_op: newViz });
    if (newFilters && panel.filter_op) {
      const filterOp = cloneDeep(panel.filter_op);
      filterOp.instructions.filterClauses = newFilters;
      fetchDataPanelData({ ...panel, filter_op: filterOp });
    }
  };

  let configurability = CanvasConfigureType.EDITABLE;
  if (RD.isSuccess(canvas) && canvas.data.configurability) {
    configurability = canvas.data.configurability.type;
  }

  const justDelete =
    configurability === CanvasConfigureType.STATIC || fetchDataPanelData === undefined || !panelId;

  const viewPopper = () => {
    if (justDelete) {
      return (
        <Button fillWidth icon="trash" onClick={() => dispatch(deleteEUDItem())} text="Remove" />
      );
    }

    const panel = editingDashboard.configuration.data_panels[panelId];
    if (!panel) return null;

    return (
      <ChartEditor
        isArchitectCustomerDashboard
        configurability={configurability}
        datasets={datasets}
        fetchPanelData={fetchPanelData}
        isNew={dashboard?.configuration.data_panels[panelId] === undefined}
        panel={panel}
      />
    );
  };

  return (
    <>
      <div className={classes.root} ref={setReferenceElement}></div>
      <div
        className={justDelete ? classes.deleteWrapper : classes.editorWrapper}
        ref={setPopperElement}
        style={popper.styles.popper}
        {...popper.attributes.popper}>
        {viewPopper()}
      </div>
    </>
  );
};
