import { FC } from 'react';
import { useDroppable } from '@dnd-kit/core';
import { useDispatch } from 'react-redux';

import { DataPanelSubHeader } from './SubHeader';
import {
  SortableListItemDragHandle,
  SortableList,
  SortableListItem,
} from 'components/SortableList/SortableList';
import { IconButton, sprinkles } from 'components/ds';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import {
  EmbeddedDropdownButton,
  EmbeddedDropdownMenu,
} from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownMenu';
import * as styles from './index.css';

import { CustomerReportGroupBy } from 'actions/customerReportActions';
import { COLS_SECTION_ID, ROWS_SECTION_ID } from './constants';
import { getGroupByBucketOptions, getGroupByUniqueId } from 'utils/customerReportUtils';
import { updateGroupByThunk } from 'reportBuilderContent/thunks';
import { PIVOT_AGG_TYPES, PivotAgg } from 'types/dateRangeTypes';
import { orderGroupBys } from 'reportBuilderContent/reducers/reportEditingReducer';

type Props = {
  bucketsByCol: Record<string, Set<PivotAgg>>;
  colNameToDisplay: Record<string, string | undefined>;
  sectionGroupBys: CustomerReportGroupBy[];
  isColumnSection?: boolean;
  isSectionDisabled?: boolean;
};

const ColumnGroupByWarningMessage = 'Requires Rows / Values';

export const GroupBysSection: FC<Props> = ({
  bucketsByCol,
  colNameToDisplay,
  sectionGroupBys,
  isColumnSection = false,
  isSectionDisabled,
}) => {
  const dispatch = useDispatch();

  const sectionId = isColumnSection ? COLS_SECTION_ID : ROWS_SECTION_ID;

  const { setNodeRef, isOver } = useDroppable({ id: sectionId, data: { section: sectionId } });

  const numGroupBys = sectionGroupBys.length;

  const sideText = isColumnSection && isSectionDisabled ? ColumnGroupByWarningMessage : undefined;
  const errorText = isSectionDisabled && numGroupBys > 0 ? ColumnGroupByWarningMessage : undefined;

  return (
    <div
      className={sprinkles({
        position: 'relative',
        paddingBottom: numGroupBys > 0 ? 'sp2' : 'sp0',
      })}
      ref={setNodeRef}>
      <DataPanelSubHeader
        errorText={errorText}
        fieldCount={numGroupBys}
        sideText={sideText}
        title={isColumnSection ? 'Columns' : 'Rows'}
      />
      <SortableList
        getIdFromElem={getGroupByUniqueId}
        onListUpdated={(newOrder) =>
          dispatch(orderGroupBys({ groupBys: newOrder, isColumnGroupBys: isColumnSection }))
        }
        sortableItems={sectionGroupBys}>
        {sectionGroupBys.map((groupBy, idx) => {
          const id = getGroupByUniqueId(groupBy);
          const { column, bucket } = groupBy;
          const bucketOptions = getGroupByBucketOptions(groupBy, bucketsByCol);
          const selectedBucket = bucket ? PIVOT_AGG_TYPES[bucket].name : '';

          return (
            <SortableListItem key={`${column.name}-${idx}`} sortId={id}>
              <div className={styles.groupedDraggableCol}>
                <SortableListItemDragHandle className={sprinkles({ color: 'contentTertiary' })} />
                <EmbedText body="b1" className={styles.columnName}>
                  {colNameToDisplay[column.name] ?? column.name}
                </EmbedText>
                {bucketOptions.length > 0 ? (
                  <EmbeddedDropdownMenu
                    menuOptions={bucketOptions}
                    onClick={(newPivotAgg) =>
                      dispatch(
                        updateGroupByThunk(id, isColumnSection, {
                          ...groupBy,
                          bucket: newPivotAgg as PivotAgg,
                        }),
                      )
                    }>
                    <EmbeddedDropdownButton selectedName={selectedBucket} />
                  </EmbeddedDropdownMenu>
                ) : null}
                <IconButton
                  className={styles.eyeIcon}
                  name="cross"
                  onClick={() => dispatch(updateGroupByThunk(id, isColumnSection))}
                />
              </div>
            </SortableListItem>
          );
        })}
      </SortableList>
      {isOver ? (
        <div
          className={sprinkles({
            parentContainer: 'fillAbsolute',
            border: 2,
            borderColor: 'active',
          })}
        />
      ) : null}
    </div>
  );
};
