import { createAction } from '@reduxjs/toolkit';
import { WIDGET_TYPES } from 'constants/hubspotConstants';
import { ACTION } from './types';

export const updateIsWidgetOpen = createAction<{ isWidgetOpen: boolean | undefined }>(
  ACTION.UPDATE_IS_WIDGET_OPEN,
);

export const updateWidgetType = createAction<{ widgetType: WIDGET_TYPES }>(
  ACTION.UPDATE_WIDGET_TYPE,
);

export const updateConversationStarted = createAction<{ conversationStarted: boolean }>(
  ACTION.UPDATE_WIDGET_CONVERSATION_STARTED,
);
