import { ACTION } from './types';
import { EnvironmentTag } from 'actions/teamActions';
import { defineAPIAction, defineAPIPostAction } from 'actions/actionUtils';

export const { actionFn: createEnvironmentTag, successAction: createEnvironmentTagSuccess } =
  defineAPIAction<{ tag: EnvironmentTag }>(
    ACTION.CREATE_ENVIRONMENT_TAG,
    'dashboards',
    'create_environment_tag',
    'POST',
  );

type UpdateEnvironmentTagBody =
  | { color_hex: string }
  | { name: string }
  | ({ version_id: number } & (
      | { canvas_id: number }
      | { dashboard_id: number }
      | { report_builder_id: number }
    ));

export const { actionFn: updateEnvironmentTag, successAction: updateEnvironmentTagSuccess } =
  defineAPIPostAction<UpdateEnvironmentTagBody & { tag_id: number }, { tag: EnvironmentTag }>(
    ACTION.UPDATE_ENVIRONMENT_TAG,
    'dashboards',
    'update_environment_tag',
    'POST',
  );
