import { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { DashboardVariableMap, IframeElemConfig } from 'types/dashboardTypes';
import { replaceTemplatesWithValues } from 'utils/dataPanelConfigUtils';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',

    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  empty: {
    fontStyle: 'italic',
    width: '100%',
    height: '100%',

    overflow: 'hidden',
  },
}));

type Props = {
  config: IframeElemConfig;
  variables: DashboardVariableMap;
};

export const DashboardIframeElement: FC<Props> = ({ config, variables }) => {
  const classes = useStyles();

  const iframeUrl = config.iframeUrl?.trim();

  if (!iframeUrl) return <div className={classes.empty}>Enter an Iframe URL</div>;

  return (
    <div className={classes.root}>
      <iframe
        src={replaceTemplatesWithValues(iframeUrl, variables)}
        style={{ border: 'none', height: '100%', width: '100%' }}
      />
    </div>
  );
};
