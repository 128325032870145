import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import { Switch } from 'components/ds';
import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';
import RangeInput from 'shared/RangeInput';

import { YAxisFormat } from 'constants/types';
import { V2_NUMBER_FORMATS } from 'constants/dataConstants';

type Props = {
  configInputClass: string;
  isCanvas?: boolean;
  yAxisFormat: YAxisFormat | undefined;

  updateAxisFormat: (yAxisFormat: YAxisFormat) => void;
};

export default function SharedYAxisConfigs({
  configInputClass,
  isCanvas,
  updateAxisFormat,
  yAxisFormat,
}: Props) {
  const selectedFormat = yAxisFormat?.numberFormat || V2_NUMBER_FORMATS.NUMBER;

  return (
    <>
      <Switch
        useCustomStyles
        className={configInputClass}
        label="Axis Title"
        onChange={() => updateAxisFormat({ showTitle: !yAxisFormat?.showTitle })}
        switchOn={yAxisFormat?.showTitle}
      />
      {yAxisFormat?.showTitle ? (
        <InputWithBlurSave
          containerClassName={configInputClass}
          initialValue={yAxisFormat?.title}
          onNewValueSubmitted={(newValue) => updateAxisFormat({ title: newValue })}
        />
      ) : null}

      <ToggleButtonGroup fillWidth className={configInputClass} label="Value Format">
        {[V2_NUMBER_FORMATS.NUMBER, V2_NUMBER_FORMATS.CURRENCY, V2_NUMBER_FORMATS.PERCENT].map(
          (numberFormat) => (
            <ToggleButton
              active={numberFormat.id === selectedFormat.id}
              key={numberFormat.id}
              onClick={() => updateAxisFormat({ numberFormat })}
              text={numberFormat.abbreviation}
            />
          ),
        )}
      </ToggleButtonGroup>
      <Switch
        useCustomStyles
        className={configInputClass}
        label="Show Axis Decimals"
        onChange={() => updateAxisFormat({ showDecimals: !yAxisFormat?.showDecimals })}
        switchOn={yAxisFormat?.showDecimals}
      />
      {yAxisFormat?.showDecimals ? (
        <InputWithBlurSave
          containerClassName={configInputClass}
          helpText="This will only apply to the data tooltip unless 'Show Axis Decimals' is enabled."
          initialValue={String(yAxisFormat?.decimalPlaces ?? 2)}
          label="Decimal Places"
          onNewValueSubmitted={(newValue: string) => {
            const intValue = parseInt(newValue);
            updateAxisFormat({ decimalPlaces: intValue > 0 ? intValue : 0 });
          }}
        />
      ) : null}
      {!isCanvas ? (
        <Switch
          useCustomStyles
          className={configInputClass}
          label="Use Logarithmic Scale"
          onChange={() => {
            let min = yAxisFormat?.min;
            // log scales do not allow minimumValue of <= 0
            if (min !== undefined && min <= 0) min = undefined;

            updateAxisFormat({ useLogScale: !yAxisFormat?.useLogScale, min });
          }}
          switchOn={yAxisFormat?.useLogScale}
        />
      ) : null}
      <RangeInput
        className={configInputClass}
        endLabel="Maximum Value"
        endVal={String(yAxisFormat?.max ?? '')}
        onNewRange={(newStart?: string, newEnd?: string) => {
          const min = parseFloat(newStart ?? '');
          const max = parseFloat(newEnd ?? '');

          const validIfLog = !(yAxisFormat?.useLogScale && min <= 0);

          updateAxisFormat({
            min: min >= 0 && validIfLog ? min : undefined,
            max: max >= 0 ? max : undefined,
          });
        }}
        startLabel="Minimum Value"
        startVal={String(yAxisFormat?.min ?? '')}
      />
      <Switch
        useCustomStyles
        className={configInputClass}
        label="Axis Labels"
        onChange={() => updateAxisFormat({ hideAxisLabels: !yAxisFormat?.hideAxisLabels })}
        switchOn={!yAxisFormat?.hideAxisLabels}
      />
      <Switch
        useCustomStyles
        className={configInputClass}
        label="Grid Lines"
        onChange={() => updateAxisFormat({ hideGridLines: !yAxisFormat?.hideGridLines })}
        switchOn={!yAxisFormat?.hideGridLines}
      />
      {!isCanvas ? (
        <Switch
          useCustomStyles
          className={configInputClass}
          label="Reverse Axis"
          onChange={() => updateAxisFormat({ reverseAxis: !yAxisFormat?.reverseAxis })}
          switchOn={yAxisFormat?.reverseAxis}
        />
      ) : null}
    </>
  );
}
