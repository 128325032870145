import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sortBy } from 'utils/standard';
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from 'utils/routerUtils';
import { useHistory } from 'react-router-dom';

import { CanvasNav } from '../CanvasNav';
import DatasetConfig from './DatasetConfig';
import { DatasetPreviewTable } from 'components/resource/DatasetPreviewTable';
import { AddDatasetButton } from 'components/resource/AddDatasetButton';
import { ColumnHeader } from 'components/resource/ColumnHeader';

import { CanvasVersionConfig } from 'actions/canvasConfigActions';
import { createCanvasDataset } from 'actions/canvasConfigActions';
import {
  fetchEditorDatasetPreview,
  fetchEditorDatasetRowCount,
  saveDatasetQuery,
} from 'actions/datasetActions';
import { sharedStyles } from '../sharedStyles';
import { trackEvent, EVENTS } from 'analytics/exploAnalytics';
import { ReduxState } from 'reducers/rootReducer';
import { getDatasetName } from 'utils/naming';
import { showDuplicateColumnNameToast } from 'shared/sharedToasts';
import { ResourceDataset } from 'types/exploResource';
import { getSelectedCustomer } from 'reducers/customersReducer';
import { DatasetItem } from 'components/resource/DatasetItem';
import { getSchemaForPreviewTable } from 'utils/canvasConfigUtils';

type Props = {
  canvasId: number;
  config: CanvasVersionConfig;
};

type SelectedDatasetInfo = { id: string; isNew: boolean };

export default function DatasetEditor({ canvasId, config }: Props): JSX.Element {
  const sharedClasses = sharedStyles();
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();

  const selectedUserGroup = useSelector((state: ReduxState) =>
    getSelectedCustomer(state.customers),
  );

  const [selectedDatasetInfo, setSelectedDatasetInfo] = useState<SelectedDatasetInfo | null>(null);

  const selectedDataset = selectedDatasetInfo ? config.datasets[selectedDatasetInfo.id] : undefined;

  const sortedDatasets = useMemo(
    () => sortBy(Object.values(config.datasets), (dataset) => getDatasetName(dataset)),
    [config.datasets],
  );

  const switchSelectedDataset = useCallback(
    (newDataset: ResourceDataset) => {
      if (newDataset.query && !newDataset._error) {
        const postData = {
          dataset_id: newDataset.id,
          query: newDataset.query,
          parent_schema_id: newDataset.parent_schema_id,
          variables: {},
          customer_id: selectedUserGroup?.id,
          timezone: 'UTC',
        };

        if (!newDataset._rows) dispatch(fetchEditorDatasetPreview({ postData }));

        if (!newDataset._total_row_count) {
          dispatch(fetchEditorDatasetRowCount({ postData }));
        }
      }

      history.replace(`/blueprint/${canvasId}/datasets?id=${newDataset.id}`);
      setSelectedDatasetInfo({ id: newDataset.id, isNew: false });
    },
    [dispatch, selectedUserGroup, history, canvasId],
  );

  useEffect(() => {
    if (selectedDatasetInfo || sortedDatasets.length === 0) return;

    const id = query.get('id');
    const dataset = config.datasets[id ?? ''] ?? sortedDatasets[0];
    switchSelectedDataset(dataset);
  }, [query, config.datasets, switchSelectedDataset, selectedDatasetInfo, sortedDatasets]);

  const getCurrentQuery = () => {
    return (selectedDataset?.queryDraft ?? selectedDataset?.query ?? '').trim();
  };

  const getPreview = (offset = 0) => {
    const currentQuery = getCurrentQuery();
    if (!selectedDataset || !currentQuery) return;

    const postData = {
      dataset_id: selectedDataset.id,
      query: currentQuery,
      parent_schema_id: selectedDataset.parent_schema_id,
      variables: {},
      customer_id: selectedUserGroup?.id,
      timezone: 'UTC',
    };

    dispatch(
      fetchEditorDatasetPreview({ postData: { ...postData, offset } }, () => {
        dispatch(fetchEditorDatasetRowCount({ postData }));
      }),
    );

    if (offset === 0) {
      trackEvent(EVENTS.RAN_CODE, {
        dataset_id: selectedDataset.id,
        dataset_query: currentQuery,
      });
    }
  };

  const saveQuery = () => {
    const currentQuery = getCurrentQuery();
    if (!selectedDataset || !currentQuery) return;

    const postData = {
      query: currentQuery,
      dataset_id: selectedDataset.id,
      parent_schema_id: selectedDataset.parent_schema_id,
      customer_id: selectedUserGroup?.id,
      variables: {},
      timezone: 'UTC',
    };

    dispatch(
      fetchEditorDatasetPreview({ postData }, (data) => {
        dispatch(saveDatasetQuery({ ...postData, schema: data.dataset_preview.schema }));
        dispatch(fetchEditorDatasetRowCount({ postData }));
        showDuplicateColumnNameToast(data);
      }),
    );

    trackEvent(EVENTS.SAVED_QUERY, {
      dataset_id: selectedDataset.id,
      dataset_query: currentQuery,
    });
  };

  const renderDatasetList = () => {
    return (
      <div className={sharedClasses.navContainer}>
        <AddDatasetButton
          datasets={sortedDatasets}
          onSubmit={(name, parentSchemaId) => {
            const newId = `canvas-${canvasId}-${uuidv4()}`;
            dispatch(createCanvasDataset({ name, newId, parentSchemaId }));

            setSelectedDatasetInfo({ id: newId, isNew: true });
            trackEvent(EVENTS.CREATED_NEW_CANVAS_DATASET, {});
          }}
        />
        {sortedDatasets.map((dataset) => {
          const isSelected = dataset.id === selectedDatasetInfo?.id;
          return (
            <DatasetItem
              dataset={dataset}
              isSelected={isSelected}
              key={dataset.id}
              onClick={() => {
                if (isSelected) return;
                const newDataset = config.datasets[dataset.id];

                switchSelectedDataset(newDataset);
                trackEvent(EVENTS.SELECTED_DATASET, {
                  dataset_id: dataset.id,
                  dataset_name: getDatasetName(newDataset),
                });
              }}
            />
          );
        })}
      </div>
    );
  };

  return (
    <div className={sharedClasses.root}>
      <CanvasNav config={config}>{renderDatasetList()}</CanvasNav>
      <div className={sharedClasses.configMenu}>
        {selectedDataset ? (
          <DatasetConfig
            canvasId={canvasId}
            config={config}
            dataset={selectedDataset}
            getPreview={getPreview}
            isNewDataset={selectedDatasetInfo?.isNew ?? false}
            saveQuery={saveQuery}
          />
        ) : (
          <div className={sharedClasses.emptyContainer}>Select a Dataset</div>
        )}
      </div>
      <div className={sharedClasses.elementView}>
        {selectedDataset ? (
          <>
            <ColumnHeader title="Preview" />

            <DatasetPreviewTable
              currentQuery={getCurrentQuery()}
              error={selectedDataset._error}
              handlePageChange={getPreview}
              isLoading={selectedDataset._loading ?? false}
              rowCount={selectedDataset._total_row_count}
              rows={selectedDataset._rows}
              schema={getSchemaForPreviewTable(
                selectedDataset._schema || selectedDataset.schema,
                selectedDataset,
              )}
              unsupportedOperations={selectedDataset._unsupported_operations}
            />
          </>
        ) : (
          <div className={sharedClasses.emptyContainer}>Select a Dataset</div>
        )}
      </div>
    </div>
  );
}
