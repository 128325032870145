import { FC, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { BaseListItem } from 'pages/ReportBuilder/HomeView/BaseListItem';

import { BuiltInReportConfig } from 'actions/reportBuilderConfigActions';
import { vars } from 'components/ds';
import { toggleFavoriteBuiltIn } from 'actions/customerReportBuilderMetadata';
import { setSelectedBuiltInReport } from 'reportBuilderContent/reducers/embeddedReportBuilderReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';

interface Props {
  reportBuilderEmbedId: string;
  builtIn: BuiltInReportConfig;
}

export const BuiltInListItem: FC<Props> = ({ builtIn, reportBuilderEmbedId }) => {
  const dispatch = useDispatch();

  const { favoriteBuiltIns, customerToken } = useSelector(
    (state: ReportBuilderReduxState) => ({
      favoriteBuiltIns: state.embeddedReportBuilder.favoriteBuiltIns,
      customerToken: state.embeddedReportBuilder.requestInfo.customerToken,
    }),
    shallowEqual,
  );

  const onClickFavorite = useCallback(() => {
    dispatch(
      toggleFavoriteBuiltIn({
        customerToken,
        postData: {
          report_builder_embed_id: reportBuilderEmbedId,
          built_in_id: builtIn.id,
        },
      }),
    );
  }, [builtIn.id, customerToken, dispatch, reportBuilderEmbedId]);

  return (
    <BaseListItem
      description={builtIn.description}
      isStarred={favoriteBuiltIns.includes(builtIn.id)}
      key={builtIn.id}
      menuOptions={[]}
      modified={builtIn.modified}
      name={builtIn.name}
      onClick={() => dispatch(setSelectedBuiltInReport(builtIn.id))}
      onClickStar={onClickFavorite}
      tags={[{ backgroundColor: vars.colors.activeSubdued, children: 'Built In' }]}
    />
  );
};
