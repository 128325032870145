import { defineAPIAction, defineAPIPostAction } from './actionUtils';

import { DashboardTimezones } from 'constants/dashboardConstants';
import { ACTION } from './types';

export interface ReportBuilder extends EmbedReportBuilder {
  team_id: number;
  latest_versions: {
    is_draft: boolean;
    version_number: number;
    modified: string;
  }[];
}

export interface EmbedReportBuilder {
  id: number;
  name: string;
  embed_id: string;
  default_timezone: DashboardTimezones | null;
}

type FetchReportBuildersData = {
  report_builders: ReportBuilder[];
};

export const {
  actionFn: fetchReportBuilders,
  requestAction: fetchReportBuildersRequest,
  errorAction: fetchReportBuildersError,
  successAction: fetchReportBuildersSuccess,
} = defineAPIAction<FetchReportBuildersData>(
  ACTION.FETCH_REPORT_BUILDERS,
  'report_builder',
  'get_report_builders',
  'GET',
);

export const { actionFn: createReportBuilder, successAction: createReportBuilderSuccess } =
  defineAPIPostAction<{ name: string }, { report_builder: ReportBuilder }>(
    ACTION.CREATE_REPORT_BUILDER,
    'report_builder',
    'create_report_builder',
    'POST',
  );

export const { actionFn: cloneReportBuilder, successAction: cloneReportBuilderSuccess } =
  defineAPIAction<{ new_report_builder: ReportBuilder }>(
    ACTION.CLONE_REPORT_BUILDER,
    'report_builder',
    'duplicate',
    'POST',
  );

export const { actionFn: deleteReportBuilder, successAction: deleteReportBuilderSuccess } =
  defineAPIAction(ACTION.DELETE_REPORT_BUILDER, 'report_builder', '', 'DELETE');

export const { actionFn: renameReportBuilder, successAction: renameReportBuilderSuccess } =
  defineAPIPostAction<{ name: string }, { name: string }>(
    ACTION.RENAME_REPORT_BUILDER,
    'report_builder',
    'rename',
    'POST',
  );
