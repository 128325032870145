import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { AlertModal, sprinkles } from 'components/ds';
import Button from 'shared/Button';
import DropdownSelect from 'shared/DropdownSelect';
import { showSuccessToast, showErrorContactSupportToast } from 'shared/sharedToasts';
import cx from 'classnames';

import { TeamMember } from 'actions/userActions';
import {
  DEFAULT_ROLE_OPTIONS,
  PERMISSIONED_ACTIONS,
  PERMISSIONED_ENTITIES,
} from 'constants/roleConstants';
import {
  cancelTeamInvite,
  InvitedUser,
  inviteTeammates,
  removeTeammate,
} from 'actions/teamActions';
import { assignPendingInviteRole, assignUserRole } from 'actions/rolePermissionActions';
import { clearState } from 'actions/authAction';
import { doesUserHavePermission, getTeamMemberRole } from 'utils/permissionUtils';

type StyleProps = {
  userCanAssignRoles: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  member: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
  },
  leftSide: {
    width: '100%',
    display: 'flex',
  },
  rightSide: ({ userCanAssignRoles }: Pick<StyleProps, 'userCanAssignRoles'>) => ({
    width: '100%',
    display: 'flex',
    justifyContent: userCanAssignRoles ? 'space-between' : 'flex-end',
    alignItems: 'center',
  }),
  nameSection: {
    marginLeft: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  memberName: {
    marginLeft: theme.spacing(4),
    fontWeight: 500,
    maxWidth: 200,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  memberEmail: {
    color: theme.palette.ds.grey700,
    fontSize: 12,
    marginTop: theme.spacing(-2),
    marginLeft: theme.spacing(4),
  },
  invitedEmail: {
    color: theme.palette.ds.grey700,
    marginLeft: theme.spacing(4),
  },
  profileIconSmall: {
    borderRadius: '12%',
    color: theme.palette.ds.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    fontSize: 18,
  },
  memberRole: {
    color: theme.palette.ds.grey900,
    display: 'flex',
    justifyContent: 'flex-end',
    width: 50,
    marginRight: theme.spacing(4),
  },
  invitedActions: ({ userCanAssignRoles }: Pick<StyleProps, 'userCanAssignRoles'>) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: userCanAssignRoles ? 200 : 75,
  }),
  resendButton: { color: `${theme.palette.ds.blue} !important` },
  removeButton: { color: `${theme.palette.ds.red} !important` },
}));

type Props = {
  invitedUser?: InvitedUser;
  user?: TeamMember;
};

function SettingsTeamMemberItem({ user, invitedUser }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const currentUser = useSelector((state: ReduxState) => state.currentUser);
  const isCurrentUser = currentUser.id === user?.id;
  const userTeamPermissions = currentUser.permissions[PERMISSIONED_ENTITIES.TEAM];

  const classes = useStyles({
    userCanAssignRoles: doesUserHavePermission(userTeamPermissions, PERMISSIONED_ACTIONS.UPDATE),
  });
  const dispatch = useDispatch();

  const renderProfileIcon = () => {
    return (
      <div className={cx(sprinkles({ backgroundColor: 'blueDark6' }), classes.profileIconSmall)}>
        {user
          ? `${user.first_name.charAt(0).toUpperCase()}${user.last_name.charAt(0).toUpperCase()}`
          : invitedUser
          ? `${invitedUser.email.charAt(0).toUpperCase()}`
          : 'A'}
      </div>
    );
  };

  const renderProfileName = () => {
    if (user) {
      return (
        <div className={classes.nameSection}>
          <p className={classes.memberName}>
            {user.first_name} {user.last_name}
            {isCurrentUser ? ' (You)' : ''}
          </p>
          <p className={classes.memberEmail}>{user.email}</p>
        </div>
      );
    } else if (invitedUser) {
      return (
        <div className={classes.nameSection}>
          <div className={classes.invitedEmail}>{invitedUser.email}</div>
        </div>
      );
    }
  };

  const renderRole = () => {
    const userRole = getTeamMemberRole(user ?? invitedUser);
    if (doesUserHavePermission(userTeamPermissions, PERMISSIONED_ACTIONS.UPDATE)) {
      return (
        <div className={classes.memberRole}>
          <DropdownSelect
            btnMinimal
            minimal
            filterable={false}
            noSelectionText="Select a Role"
            onChange={(option) => {
              if (invitedUser)
                dispatch(
                  assignPendingInviteRole({
                    postData: { email: invitedUser.email, role_names: [option.name] },
                  }),
                );
              if (user)
                dispatch(
                  assignUserRole({ postData: { user_id: user.id, role_names: [option.name] } }),
                );
            }}
            options={DEFAULT_ROLE_OPTIONS}
            selectedItem={DEFAULT_ROLE_OPTIONS.find((option) => option.name === userRole)}
          />
        </div>
      );
    } else return <div className={classes.memberRole}>{userRole}</div>;
  };

  const renderActions = () => {
    return (
      <div className={classes.invitedActions}>
        {invitedUser && doesUserHavePermission(userTeamPermissions, PERMISSIONED_ACTIONS.CREATE) ? (
          <Button
            minimal
            className={classes.resendButton}
            onClick={() => {
              if (!currentUser.team) return;

              dispatch(
                inviteTeammates(
                  {
                    postData: {
                      invites: [{ email: invitedUser.email, role_names: invitedUser.role_names }],
                      team_id: currentUser.team.id,
                      inviter: currentUser.first_name,
                      resend: true,
                    },
                  },
                  () => showSuccessToast(`Invites sent successfully to ${invitedUser.email}`),
                  (response) => showErrorContactSupportToast(response.error_msg),
                ),
              );
            }}
            text="Resend Invite"
          />
        ) : null}
        {!isCurrentUser &&
        doesUserHavePermission(userTeamPermissions, PERMISSIONED_ACTIONS.DELETE) ? (
          <Button
            minimal
            className={classes.removeButton}
            onClick={() => setIsModalOpen(true)}
            text="Remove"
          />
        ) : null}
      </div>
    );
  };

  const renderConfirmationModal = () => {
    const onRemove = () => {
      if (!currentUser.team) return;
      const team_id = currentUser.team.id;

      if (invitedUser)
        dispatch(cancelTeamInvite({ postData: { team_id, email: invitedUser.email } }));

      if (user) {
        dispatch(removeTeammate({ postData: { user_id: user.id, team_id } }));
        if (isCurrentUser) dispatch(clearState());
      }

      setIsModalOpen(false);
    };

    return (
      <AlertModal
        actionButtonProps={{ text: 'Remove', onClick: onRemove }}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={`Are you sure you want to remove ${user ? user.first_name : invitedUser?.email}?`}>
        {isCurrentUser ? 'This will log you out automatically' : null}
      </AlertModal>
    );
  };

  return (
    <div className={classes.member} key={`team-user-member-${user?.id}`}>
      <div className={classes.leftSide}>
        {renderProfileIcon()}
        {renderProfileName()}
      </div>
      <div className={classes.rightSide}>
        {renderRole()}
        {renderActions()}
      </div>
      {renderConfirmationModal()}
    </div>
  );
}

export { SettingsTeamMemberItem };
