import { AxiosRequestConfig } from 'axios';
import { AnyAction, createAsyncThunk, ThunkAction } from '@reduxjs/toolkit';

import { createApiRequestConfig } from 'actions/actionUtils';
import {
  CustomerReportSort,
  CustomerReportAgg,
  CustomerReportDataInfo,
  CustomerReportGroupBy,
  CustomerReportView,
} from 'actions/customerReportActions';
import { ReportBuilderCol } from 'actions/reportBuilderConfigActions';
import { ACTION } from 'actions/types';
import { FilterValueType } from 'constants/types';
import { ReduxState } from 'reducers/rootReducer';
import {
  fetchPreviewModalData,
  fetchPreviewReportData,
} from 'reducers/thunks/reportBuilderEditorThunks';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { DatasetRow } from 'types/datasets';
import { FilterOperator } from 'types/filterOperations';
import {
  getCurrentView,
  getNewAgg,
  getNewGroupBy,
  getViewRequestParams,
  ViewRequestParams,
} from 'utils/customerReportUtils';
import { xor } from 'utils/standard';
import { makeThunkRequest } from 'utils/thunkUtils';
import {
  addAgg,
  addGroupBy,
  closeReportModal,
  createFilter,
  deleteAgg,
  deleteFilter,
  deleteGroupBy,
  saveDataInfo,
  setLoadingSchemaInfo,
  updateAgg,
  updateFilter,
  updateGroupBy,
  updateSort,
} from './reducers/reportEditingReducer';

type Thunk = ThunkAction<void, ReduxState, unknown, AnyAction>;

export const fetchModalData =
  (datasetId: string): Thunk =>
  (dispatch, getState) => {
    const { embeddedReportBuilder, reportEditing } = getState();
    if (datasetId in reportEditing.modalDatasetData) return;

    const { isPreviewPage } = embeddedReportBuilder;

    if (isPreviewPage) dispatch(fetchPreviewModalData(datasetId));
    else dispatch(fetchEmbeddedModalData(datasetId));
  };

export const fetchEmbeddedModalData = createAsyncThunk<
  { rows: DatasetRow[] },
  string,
  { state: ReportBuilderReduxState }
>(ACTION.FETCH_CUSTOMER_DATASET_PREVIEW, async (datasetId, { getState }) => {
  const { requestInfo } = getState().embeddedReportBuilder;

  const requestConfig = createApiRequestConfig(
    'customer_reports/get_dataset_preview/',
    'POST',
    {
      embed_id: requestInfo.embedId,
      version_number: requestInfo.versionNumber,
      dataset_id: datasetId,
    },
    true,
    requestInfo.customerToken,
  );

  return makeThunkRequest(requestConfig, 'Error loading data');
});

type CustomerReportDataBody = {
  embed_id: string;
  version_number: number;
  dataset_id: string;
} & ViewRequestParams;

export const fetchReportData =
  (page?: number): Thunk =>
  (dispatch, getState) => {
    const { isPreviewPage } = getState().embeddedReportBuilder;

    if (isPreviewPage) dispatch(fetchPreviewReportData(page));
    else dispatch(fetchEmbeddedReportData(page));
  };

export const fetchEmbeddedReportData = createAsyncThunk<
  { rows: DatasetRow[] },
  // Which page to fetch. If undefined get row count as well
  number | undefined,
  { state: ReportBuilderReduxState }
>(ACTION.FETCH_CUSTOMER_REPORT_DATA, async (page, { getState, dispatch }) => {
  const { embeddedReportBuilder, reportEditing } = getState();
  const requestInfo = embeddedReportBuilder.requestInfo;
  const { currentConfig, currentView } = reportEditing;

  const viewConfig = getCurrentView(currentConfig?.views, currentView);

  let requestConfig: AxiosRequestConfig | null = null;
  let onSuccessFunc: (() => void) | undefined = undefined;

  if (viewConfig && currentConfig?.dataInfo) {
    const viewParams = getViewRequestParams(viewConfig);

    const dataConfig: CustomerReportDataBody = {
      embed_id: requestInfo.embedId,
      version_number: requestInfo.versionNumber,
      dataset_id: currentConfig.dataInfo.datasetId,
      ...viewParams,
    };

    dispatch(
      setLoadingSchemaInfo({
        groupBys: viewConfig.groupBys ?? [],
        aggs: viewConfig.aggregations ?? [],
        columnGroupBys: viewConfig.columnGroupBys,
      }),
    );

    // If first page data is requested and not a pivot table also get row count
    if (page === undefined && !viewParams.is_pivot_request) {
      onSuccessFunc = () => dispatch(fetchEmbeddedReportRowCount(dataConfig));
    }

    requestConfig = createApiRequestConfig(
      'customer_reports/get_data/',
      'POST',
      { ...dataConfig, columns: currentConfig.dataInfo.columns, page: page ?? 1 },
      true,
      requestInfo.customerToken,
    );
  }

  return makeThunkRequest(requestConfig, 'Error loading data', onSuccessFunc);
});

export const fetchEmbeddedReportRowCount = createAsyncThunk<
  { row_count: number },
  CustomerReportDataBody,
  { state: ReportBuilderReduxState }
>(ACTION.FETCH_CUSTOMER_REPORT_ROW_COUNT, async (requestBody, { getState }) => {
  const { customerToken } = getState().embeddedReportBuilder.requestInfo;

  const requestConfig = createApiRequestConfig(
    'customer_reports/get_row_count/',
    'POST',
    requestBody,
    true,
    customerToken,
  );

  return makeThunkRequest(requestConfig, 'Error loading row count');
});

export const updateDataInfoThunk =
  (newInfo: CustomerReportDataInfo): Thunk =>
  (dispatch, getState) => {
    // Should only access embedded state
    const { currentConfig } = getState().reportEditing;
    if (!currentConfig) return;

    const dataInfo = currentConfig.dataInfo;
    if (
      !dataInfo ||
      dataInfo.datasetId !== newInfo.datasetId ||
      xor(dataInfo.columns, newInfo.columns).length > 0
    ) {
      dispatch(saveDataInfo(newInfo));
      dispatch(fetchReportData());
    } else {
      dispatch(closeReportModal());
    }
  };

type ApplyFilterInfo = {
  filterOperator: FilterOperator;
  value: FilterValueType;
  isPostFilter?: boolean;
} & ({ column: ReportBuilderCol } | { filterId: number });

// TODO: Look into middleware for fetching data on changes instead of
// needing all these thunks

export const applyFilterThunk =
  (filterInfo: ApplyFilterInfo): Thunk =>
  (dispatch) => {
    dispatch('filterId' in filterInfo ? updateFilter(filterInfo) : createFilter(filterInfo));
    dispatch(fetchReportData());
  };

export const deleteFilterThunk =
  (filterId: number): Thunk =>
  (dispatch) => {
    dispatch(deleteFilter(filterId));
    dispatch(fetchReportData());
  };

export const addGroupByThunk =
  (
    col: ReportBuilderCol,
    view: CustomerReportView,
    isColumnGroupBy: boolean,
    fetchData = true,
  ): Thunk =>
  (dispatch) => {
    const groupBy = getNewGroupBy(col, view);
    if (!groupBy) return;

    dispatch(addGroupBy({ groupBy, isColumnGroupBy }));
    if (fetchData) dispatch(fetchReportData());
  };

export const updateGroupByThunk =
  (groupById: string, isColumnGroupBy: boolean, groupBy?: CustomerReportGroupBy): Thunk =>
  (dispatch) => {
    if (groupBy) dispatch(updateGroupBy({ id: groupById, groupBy, isColumnGroupBy }));
    else dispatch(deleteGroupBy({ id: groupById, isColumnGroupBy }));

    dispatch(fetchReportData());
  };

export const addAggThunk =
  (col: ReportBuilderCol, view: CustomerReportView): Thunk =>
  (dispatch) => {
    const agg = getNewAgg(col, view);
    if (!agg) return;

    dispatch(addAgg(agg));
    dispatch(fetchReportData());
  };

export const updateAggThunk =
  (aggId: string, agg?: CustomerReportAgg): Thunk =>
  (dispatch) => {
    if (agg) dispatch(updateAgg({ id: aggId, agg }));
    else dispatch(deleteAgg(aggId));

    // Look into just removing aggs not reloading
    dispatch(fetchReportData());
  };

export const updateSortThunk =
  (sortInfo: CustomerReportSort[]): ThunkAction<void, ReduxState, unknown, AnyAction> =>
  (dispatch) => {
    dispatch(updateSort(sortInfo));
    dispatch(fetchReportData(1));
  };
