import { useContext } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { sprinkles } from 'components/ds';
import cx from 'classnames';

import Button from 'shared/Button';
import TransformationSettingsButton from '../DataTable/TransformationSettingsButton';
import { UrlClickThroughButton } from 'components/UrlClickThrough';
import FilterButton from './FilterButton';
import { PanelName } from './PanelName';

import { AdHocOperationInstructions } from 'types/dataPanelTemplate';
import { FilterOperationInstructions, UserTransformedSchema, LinkFormat } from 'constants/types';
import { DatasetSchema } from 'types/datasets';
import { HEADER_HEIGHT } from 'pages/dashboardPage/DashboardDatasetView/DashboardDatasetView';
import { GlobalStylesContext, GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { GlobalStyleConfig } from 'globalStyles/types';

const useStyles = makeStyles((theme: Theme) => ({
  header: ({ globalStyleConfig }: { globalStyleConfig: GlobalStyleConfig }) => ({
    borderBottom: `1px solid ${globalStyleConfig.container.outline.color}`,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    fontSize: 14,
    height: HEADER_HEIGHT,
    justifyContent: 'space-between',
  }),
  dataTablePadding: ({ globalStyleConfig }: { globalStyleConfig: GlobalStyleConfig }) => ({
    padding: `${theme.spacing(5)}px ${globalStyleConfig.container.padding.default}px`,
    paddingBottom: theme.spacing(5),
  }),
}));

type Props = {
  drilldownDatasetName?: string;
  adHocOperationInstructions: AdHocOperationInstructions;
  elementEndsOnRightSide?: boolean;
  error?: boolean;
  infoTooltipText?: string;
  isSchemaCustomizationEnabled?: boolean;
  isFilteringDisabled?: boolean;
  loading?: boolean;
  onAdHocFilterInfoUpdate: (adHocFilterInfo: FilterOperationInstructions) => void;
  title: string;
  schema: DatasetSchema;
  updateUserTransformedSchema?: (userTransformedSchema: UserTransformedSchema) => void;
  linkFormat?: LinkFormat;
  userTransformedSchema?: UserTransformedSchema;
  onCloseDrilldownModal?: () => void; //If defined, means we are in the drilldown modal header
};

export default function DataTableHeader({
  drilldownDatasetName,
  adHocOperationInstructions,
  elementEndsOnRightSide,
  error,
  infoTooltipText,
  isSchemaCustomizationEnabled,
  isFilteringDisabled,
  loading,
  onAdHocFilterInfoUpdate,
  title,
  schema,
  updateUserTransformedSchema,
  linkFormat,
  userTransformedSchema,
  onCloseDrilldownModal,
}: Props) {
  const { globalStyleConfig } = useContext(GlobalStylesContext);
  const classes = useStyles({ globalStyleConfig });

  return (
    <div
      className={cx(
        classes.header,
        onCloseDrilldownModal
          ? sprinkles({ paddingY: 'sp3', paddingX: 'sp4' })
          : classes.dataTablePadding,
        GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.default.borderRadiusTop,
        GLOBAL_STYLE_CLASSNAMES.container.fill.backgroundColor,
      )}>
      <PanelName
        drilldownDatasetName={drilldownDatasetName}
        infoTooltipText={infoTooltipText}
        loading={loading}
        panelName={title}
      />
      <div className={sprinkles({ display: 'flex', alignItems: 'center' })}>
        {isSchemaCustomizationEnabled && (
          <TransformationSettingsButton
            loading={loading}
            schema={schema}
            updateUserTransformedSchema={(schema) => updateUserTransformedSchema?.(schema)}
            userTransformedSchema={userTransformedSchema}
          />
        )}
        {!isFilteringDisabled && (
          <div className={sprinkles({ marginX: onCloseDrilldownModal ? 'sp1' : undefined })}>
            <FilterButton
              adHocFilterInfo={adHocOperationInstructions?.filterInfo}
              error={!!error}
              loading={loading}
              onAdHocFilterInfoUpdate={onAdHocFilterInfoUpdate}
              openPopoverToRight={
                elementEndsOnRightSide === undefined ? false : !elementEndsOnRightSide
              }
              schema={userTransformedSchema ?? schema}
            />
          </div>
        )}
        {onCloseDrilldownModal ? (
          <Button minimal icon="cross" onClick={onCloseDrilldownModal} />
        ) : (
          <UrlClickThroughButton linkFormat={linkFormat} />
        )}
      </div>
    </div>
  );
}
