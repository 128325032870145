import { makeStyles, Theme } from '@material-ui/core/styles';
import FlexBox from 'components/core/FlexBox';
import FlexItem from 'components/core/FlexItem';
import { ASC_SORT_ICON, DESC_SORT_ICON } from 'constants/iconConstants';
import { DefaultSortColumn, SelectedDropdownInputItem, SortOrder } from 'constants/types';

import DropdownSelect from 'shared/DropdownSelect';
import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';

const useStyles = makeStyles((theme: Theme) => ({
  configInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  sortOrderSection: {
    display: 'flex',
    alignItems: 'flex-end',
    marginLeft: theme.spacing(2),
  },
}));

type Props = {
  updateDefaultSort: (sortColumn: DefaultSortColumn) => void;
  label: string;
  order: SortOrder;
  selectedItem?: SelectedDropdownInputItem;
  sortOptions: SelectedDropdownInputItem[];
};
export default function ColumnSortingConfig({
  label,
  order,
  selectedItem,
  sortOptions,
  updateDefaultSort,
}: Props) {
  const classes = useStyles();
  return (
    <FlexBox className={classes.configInput}>
      <FlexItem>
        <DropdownSelect
          fillWidth
          minimal
          showCancelBtn
          filterable={false}
          label={label}
          noSelectionText="Select column"
          onCancelClick={() => updateDefaultSort({ column: undefined })}
          onChange={(newValue) => updateDefaultSort({ column: newValue.id })}
          options={sortOptions}
          selectedItem={selectedItem}
        />
      </FlexItem>
      <ToggleButtonGroup className={classes.sortOrderSection}>
        {Object.values(SortOrder).map((sort) => {
          return (
            <ToggleButton
              active={sort === order}
              icon={sort === SortOrder.ASC ? ASC_SORT_ICON : DESC_SORT_ICON}
              key={sort}
              onClick={() => updateDefaultSort({ order: sort })}
            />
          );
        })}
      </ToggleButtonGroup>
    </FlexBox>
  );
}
