import { FC, useState } from 'react';

import Button from 'shared/Button';
import { EditableVariableList } from './EditableVariableList';
import RawVariableList from './RawVariableList';
import ManageCustomVariableModal from '../manageCustomVariableModal';
import { sprinkles } from 'components/ds';

import { DashboardElement, DashboardVariableMap } from 'types/dashboardTypes';
import { filterDptsWithDrilldowns } from 'utils/drilldownUtils';
import { DashboardParam } from 'types/dashboardVersionConfig';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { Dataset } from 'actions/datasetActions';
import { Customer } from 'actions/teamActions';
import { FILTER_ELEMENTS } from 'constants/dashboardConstants';
import { isChartUsingMultipleColorCategories } from 'utils/colorColUtils';

type Props = {
  variables: DashboardVariableMap;
  dashboardElements: DashboardElement[];
  dataPanels: DataPanelTemplate[];
  selectedUserGroup: Customer | null;
  dashboardDatasets: Record<number, Dataset>;
  dashboardTemplateId: number;
  dashboardParams: Record<string, DashboardParam>;
  timezone: string;
};

export const VariablesDebugger: FC<Props> = ({
  variables,
  dashboardElements,
  selectedUserGroup,
  dashboardDatasets,
  dataPanels,
  dashboardTemplateId,
  dashboardParams,
  timezone,
}) => {
  const [inEditMode, setInEditMode] = useState(false);
  const [isCustomVariableModalOpen, setIsCustomVariableModalOpen] = useState(false);

  const filteredDashboardElements = dashboardElements.filter((element) =>
    FILTER_ELEMENTS.has(element.element_type),
  );

  return (
    <div className={sprinkles({ height: 'fill', overflow: 'auto' })}>
      <div
        className={sprinkles({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 'sp1.5',
        })}>
        <Button
          className={sprinkles({ flex: 1 })}
          onClick={() => setInEditMode(!inEditMode)}
          text={inEditMode ? 'Preview' : 'Edit'}
        />
        <Button
          className={sprinkles({ marginLeft: 'sp1', flex: 3 })}
          onClick={() => setIsCustomVariableModalOpen(true)}
          text="Manage Custom Variables"
        />
      </div>
      {inEditMode ? (
        <EditableVariableList
          dashboardDatasets={dashboardDatasets}
          dashboardInputElements={filteredDashboardElements}
          dashboardParams={dashboardParams}
          timezone={timezone}
          variables={variables}
        />
      ) : (
        <RawVariableList
          dashboardInputElements={filteredDashboardElements}
          dashboardParams={dashboardParams}
          dataPanelsWithColorCategories={dataPanels.filter((dpt) =>
            isChartUsingMultipleColorCategories(dpt.visualize_op),
          )}
          dataPanelsWithDrilldown={filterDptsWithDrilldowns(dataPanels)}
          selectedUserGroup={selectedUserGroup}
          variables={variables}
        />
      )}
      <ManageCustomVariableModal
        dashboardParams={dashboardParams}
        dashboardTemplateId={dashboardTemplateId}
        isOpen={isCustomVariableModalOpen}
        onClose={() => setIsCustomVariableModalOpen(false)}
      />
    </div>
  );
};
