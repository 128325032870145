import { PureComponent } from 'react';
import { isEqual } from 'utils/standard';
// @ts-ignore
import FunnelGraph from 'funnel-graph-js';
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core/styles';
import ResizeObserver from 'react-resize-observer';
import cx from 'classnames';

import NeedsConfigurationPanel from 'pages/dashboardPage/DashboardDatasetView/needsConfigurationPanel';

import {
  getColorColNames,
  formatLabel,
  getColorPalette,
  isTwoDimVizInstructionsReadyToDisplay,
  areRequiredVariablesSetTwoDimViz,
} from './utils';
import { V2TwoDimensionChartInstructions } from 'constants/types';
import { DATE_TYPES } from 'constants/dataConstants';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { DatasetSchema } from 'types/datasets';
import { GlobalStyleConfig } from 'globalStyles/types';
import { getTimezoneAwareUnix } from 'utils/timezoneUtils';

declare global {
  interface PointOptionsObject {
    custom: Record<string, boolean | number | string>;
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',

      '& .svg-funnel-js__label': {
        width: 0,
        borderColor: `${theme.palette.ds.grey200} !important`,
        padding: `0 ${theme.spacing(2)}px !important`,
        fontFamily: ({ globalStyleConfig }: Props) =>
          globalStyleConfig.text.overrides.smallBody?.font || globalStyleConfig.text.secondaryFont,
      },
      '& .label__title': {
        color: `inherit !important`,
        overflow: ({ instructions }: Props) =>
          instructions?.chartSpecificFormat?.funnelChart?.isVertical ? 'inherit' : 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
      '& .label__value': {
        color: `inherit !important`,
      },
      '& .label__percentage': {
        fontSize: '12px !important',
        color: `inherit !important`,
      },
    },
  });

type Props = {
  backgroundColor: string;
  loading?: boolean;
  previewData: Record<string, string | number>[];
  instructions?: V2TwoDimensionChartInstructions;
  dataPanelTemplateId: string;
  variables: DashboardVariableMap;
  schema: DatasetSchema;
  donut?: boolean;
  globalStyleConfig: GlobalStyleConfig;
} & WithStyles<typeof styles>;

type State = {};

class FunnelChart extends PureComponent<Props, State> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chart: any;
  componentDidMount() {
    const chartConfig = this._spec();
    const aggCol = this.props.instructions?.aggColumns;
    const categoryCol = this.props.instructions?.categoryColumn;

    if (!chartConfig || aggCol === undefined || aggCol.length === 0 || categoryCol === undefined)
      return;
    this.chart = new FunnelGraph(chartConfig);
    this.chart.draw();
  }

  componentDidUpdate(oldProps: Props) {
    const { previewData, instructions } = this.props;

    if (
      !isEqual(instructions, oldProps.instructions) ||
      !isEqual(previewData, oldProps.previewData)
    ) {
      const chartConfig = this._spec();
      if (!chartConfig) return;

      if (!this.chart) {
        this.chart = new FunnelGraph(chartConfig);
        this.chart.draw();
      } else {
        this.chart.update(chartConfig);
      }
    }
  }

  render() {
    const { classes, dataPanelTemplateId, instructions, loading, variables } = this.props;
    const requiredVarNotsSet = !areRequiredVariablesSetTwoDimViz(variables, instructions);
    const instructionsReadyToDisplay = isTwoDimVizInstructionsReadyToDisplay(instructions);

    if (loading || !instructionsReadyToDisplay || requiredVarNotsSet) {
      return (
        <NeedsConfigurationPanel
          fullHeight
          instructionsNeedConfiguration={!instructionsReadyToDisplay}
          loading={loading}
          requiredVarsNotSet={requiredVarNotsSet}
        />
      );
    }
    return (
      <>
        <div className={cx(classes.root, `explo-funnel-${dataPanelTemplateId}`)} />
        <ResizeObserver
          onResize={() => this.chart && this.chart.updateHeight() && this.chart.updateWidth()}
        />
      </>
    );
  }

  _spec = () => {
    const { dataPanelTemplateId, previewData, schema, instructions, globalStyleConfig, loading } =
      this.props;
    if (loading || schema?.length === 0 || !previewData) return;

    // this is a short term fix en lieu of this bug being fixed by vega:
    // Ref: TU/447fn2df
    this.processDatesData();

    const { labels, values } = this.transformData();

    return {
      container: `.explo-funnel-${dataPanelTemplateId}`,
      gradientDirection: instructions?.chartSpecificFormat?.funnelChart?.isVertical
        ? 'vertical'
        : 'horizontal',
      data: {
        labels: instructions?.chartSpecificFormat?.funnelChart?.useStageForLabel
          ? labels
          : undefined,
        colors: getColorPalette(globalStyleConfig, instructions?.colorFormat),
        values,
      },
      displayPercent: !instructions?.chartSpecificFormat?.funnelChart?.hideChartValues,
      direction: instructions?.chartSpecificFormat?.funnelChart?.isVertical
        ? 'vertical'
        : 'horizontal',
    };
  };

  getChartType = () => {
    return 'funnel';
  };

  getXAxisColName = () => {
    const { schema } = this.props;

    return schema[0].name;
  };

  processDatesData = () => {
    const { instructions, previewData, schema } = this.props;

    if (
      !previewData ||
      !DATE_TYPES.has(instructions?.categoryColumn?.column.type || '') ||
      !schema ||
      schema.length === 0
    )
      return;

    const xAxisColName = this.getXAxisColName();

    if (
      instructions?.categoryColumn?.bucket?.id &&
      instructions.categoryColumn.bucket.id.indexOf('DATE_PART') >= 0
    )
      return;

    previewData.forEach((row) => {
      if (!instructions?.categoryColumn?.column.type) return;
      row[xAxisColName] = getTimezoneAwareUnix(row[xAxisColName] as string);
    });
  };

  transformData = () => {
    // This is for when there are multiple bars/lines selected
    const { instructions, schema } = this.props;

    if (
      !instructions?.aggColumns ||
      instructions.aggColumns.length === 0 ||
      !schema ||
      schema.length === 0
    )
      return { labels: [], values: [] };

    if (instructions.colorColumnOptions?.length) return this.transformColorData(schema);

    return this.transformAggColsData(schema);
  };

  getValueFormat = () => {
    const { instructions } = this.props;

    return {
      decimalPlaces: instructions?.yAxisFormats?.[0]?.decimalPlaces ?? 2,
    };
  };

  transformAggColsData = (schema: DatasetSchema) => {
    const { previewData, instructions } = this.props;
    if (!instructions?.aggColumns) return { labels: [], values: [] };

    const xAxisColName = schema[0].name;
    const aggColNames = [schema[1].name];

    const labels: string[] = [];
    const values: number[] = [];

    previewData.forEach((row) => {
      aggColNames.forEach((colName) => {
        labels.push(
          formatLabel(
            row[xAxisColName],
            instructions?.categoryColumn?.column.type,
            instructions?.categoryColumn?.bucket?.id,
            instructions?.categoryColumn?.bucketSize,
            instructions?.xAxisFormat?.dateFormat,
            instructions?.xAxisFormat?.stringFormat,
          ),
        );
        values.push(Math.round((row[colName] as number) * 100) / 100);
      });
    });

    const sortedStages = instructions.chartSpecificFormat?.funnelChart?.sortedStages;
    if (sortedStages) {
      sortedStages.forEach((stage: string, i: number) => {
        // Find index of stage from labels list
        const foundIndex = labels.findIndex((label) => stage === label);
        if (foundIndex !== -1) {
          // Get label and value that needs to be re-sorted
          const label = labels[foundIndex];
          const value = values[foundIndex];
          // Delete from original list from old location
          labels.splice(foundIndex, 1);
          values.splice(foundIndex, 1);
          // Re-insert label and value into new location (corresponding to sorting from config)
          labels.splice(i, 0, label);
          values.splice(i, 0, value);
        } else {
          // Push value of 0 for stages, not in labels list
          labels.splice(i, 0, stage);
          values.splice(i, 0, 0);
        }
      });
    }

    return { labels, values };
  };

  transformColorData = (schema: DatasetSchema) => {
    const { previewData, instructions } = this.props;
    const { xAxisColName, aggColName } = getColorColNames(schema);
    if (!instructions?.aggColumns) return { labels: [], values: [] };

    const seriesData: Record<string, { name: string | number; y: number }> = {};

    previewData.forEach((row) => {
      const category = row[xAxisColName];
      if (seriesData[category]) {
        seriesData[category].y += row[aggColName] as number;
      } else {
        seriesData[category] = {
          name: category,
          y: row[aggColName] as number,
        };
      }
    });

    const data = Object.values(seriesData);
    const labels: string[] = [];
    const values: number[] = [];

    data.forEach((point) => {
      labels.push(
        formatLabel(
          point.name,
          instructions?.categoryColumn?.column.type,
          instructions?.categoryColumn?.bucket?.id,
          instructions?.categoryColumn?.bucketSize,
          instructions?.xAxisFormat?.dateFormat,
          instructions?.xAxisFormat?.stringFormat,
        ),
      );
      values.push(Math.round(point.y * 100) / 100);
    });

    return { labels, values };
  };
}

export default withStyles(styles)(FunnelChart);
