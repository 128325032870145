import { makeStyles } from '@material-ui/core/styles';

import DashboardButtonGroupInput from 'components/dashboardButtonGroupInput';
import { DashboardDropdownElement } from './dashboardDropdownElement';

import {
  DateGroupToggleConfig,
  DashboardVariable,
  DateGroupToggleSelectTypes,
} from 'types/dashboardTypes';
import { getDateGroupSwitchOptions } from 'utils/dashboardUtils';
import { INPUT_TYPE, REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import { AnalyticsEventTracker } from 'utils/analyticsUtils';

const useStyles = makeStyles(() => ({
  root: {
    '& .bp3-button-group .bp3-button, .bp3-button-group .bp3-button.bp3-active, .bp3-button-group .bp3-button:hover':
      {
        zIndex: 0,
      },
  },
}));

type Props = {
  analyticsEventTracker?: AnalyticsEventTracker;
  config: DateGroupToggleConfig;
  disabled?: boolean;
  value?: DashboardVariable;
  onNewValueSelect: (newvalue: DashboardVariable) => void;
  openElementToLeft: boolean;
};

const DashboardDateGroupSwitchElement = ({
  analyticsEventTracker,
  config,
  disabled,
  value,
  onNewValueSelect,
  openElementToLeft,
}: Props) => {
  const classes = useStyles();
  const options = getDateGroupSwitchOptions(config);

  let selectedItem = undefined;
  if (value) {
    selectedItem = options.find((option) => option.id === value);
  }

  if (config.filterType === DateGroupToggleSelectTypes.DROPDOWN) {
    return (
      <DashboardDropdownElement
        config={{
          disableCancel: true,
          disableSearch: true,
          label: config.label || '',
          valuesConfig: {
            valuesSource: INPUT_TYPE.MANUAL,
            manualValues: JSON.stringify(options.map((option) => option.id)),
            manualDisplays: JSON.stringify(options.map((option) => option.name)),
          },
        }}
        datasets={{}}
        disabled={disabled}
        onNewValueSelect={(newValue) => {
          onNewValueSelect(newValue);
          analyticsEventTracker?.(REPORTED_ANALYTIC_ACTION_TYPES.DROPDOWN_SELECTED);
        }}
        openElementToLeft={openElementToLeft}
        value={value}
      />
    );
  }

  return (
    <div className={classes.root}>
      <DashboardButtonGroupInput
        fillWidth
        preventUnselect
        disabled={disabled}
        label={config.label}
        onChange={(item) => {
          onNewValueSelect(item?.id);
          analyticsEventTracker?.(REPORTED_ANALYTIC_ACTION_TYPES.DROPDOWN_SELECTED);
        }}
        options={options}
        selectedItem={selectedItem}
        useFakeLabel={config.label === ''}
      />
    </div>
  );
};

export default DashboardDateGroupSwitchElement;
