import { combineReducers, Reducer } from 'redux';

import { ACTION } from 'actions/types';
import { EmbedReduxState } from 'embeddedContent/reducers/rootReducer';
import { ArchitectCustomerDashboardReduxState } from 'architectCustomerDashboardContent/reducers/rootReducer';

import loadingReducer from 'reducers/api/loadingReducer';
import authReducer from 'reducers/authReducer';
import teamDataReducer from 'reducers/teamDataReducer';
import supportedDataSourcesReducer from 'reducers/supportedDataSourcesReducer';
import dataSourceReducer from 'reducers/dataSourceReducer';
import dataSourceListReducer from 'reducers/dataSourceListReducer';
import dashboardReducer from 'reducers/dashboardReducer';
import datasetsReducer from 'reducers/datasetsReducer';
import customersReducer from 'reducers/customersReducer';
import styleConfigReducer from 'reducers/styleConfigReducer';
import embedDashboardReducer from 'reducers/embedDashboardReducer';
import environmentTagReducer from 'reducers/environmentTagReducer';
import { dashboardEditConfigReducer } from 'reducers/dashboardEditConfigReducer';
import { dashboardVersionsReducer } from 'reducers/dashboardVersionsReducer';
import parentSchemaReducer from 'reducers/parentSchemaReducer';
import whitelistDomainReducer from './whitelistDomainReducer';
import internationalizationReducer from './internationalizationReducer';
import emailCadenceReducer from './emailReducer';
import dashboardAttributesReducer from './dashboardAttributesReducer';
import endUserReducer from './endUserReducer';
import customFontsReducer from './customFontsReducer';
import canvasReducer from './canvasReducer';
import { canvasVersionsReducer } from './canvasVersionsReducer';
import { canvasEditReducer } from './canvasEditReducer';
import onboardingReducer from './onboardingReducer';
import architectCustomerDashboardReducer from './architectCustomerDashboardReducer';
import chatWidgetReducer from './chatWidgetReducer';
import superuserReducer from './superuserReducer';
import { dashboardLayoutReducer } from './dashboardLayoutReducer';
import dashboardStylesReducer from './dashboardStylesReducer';
import cssLinkingReducer from './cssLinkingReducer';
import reportBuilderReducer from './reportBuilderReducer';
import reportBuilderEditReducer from './reportBuilderEditReducer';
import { embeddedReportBuilderReducer } from '../reportBuilderContent/reducers/embeddedReportBuilderReducer';
import { reportEditingReducer } from '../reportBuilderContent/reducers/reportEditingReducer';
import { resourceSavingReducer } from './resourceSavingReducer';

const appReducer = combineReducers({
  loading: loadingReducer,
  currentUser: authReducer,
  teamData: teamDataReducer,
  supportedDataSources: supportedDataSourcesReducer,
  dataSource: dataSourceReducer,
  dataSourceList: dataSourceListReducer,
  dashboard: dashboardReducer,
  datasets: datasetsReducer,
  customers: customersReducer,
  environmentTags: environmentTagReducer,
  styleConfig: styleConfigReducer,
  dashboardEditConfig: dashboardEditConfigReducer,
  dashboardVersions: dashboardVersionsReducer,
  parentSchemas: parentSchemaReducer,
  whitelistDomains: whitelistDomainReducer,
  internationalization: internationalizationReducer,
  emailCadence: emailCadenceReducer,
  dashboardAttributes: dashboardAttributesReducer,
  endUsers: endUserReducer,
  customFonts: customFontsReducer,
  onboarding: onboardingReducer,
  widget: chatWidgetReducer,
  dashboardLayout: dashboardLayoutReducer,
  dashboardStyles: dashboardStylesReducer,
  cssLinks: cssLinkingReducer,
  resourceSaving: resourceSavingReducer,

  // Embedded Dashboard
  embedDashboard: embedDashboardReducer,

  // Canvas
  canvas: canvasReducer,
  canvasVersions: canvasVersionsReducer,
  canvasEdit: canvasEditReducer,
  architectCustomerDashboard: architectCustomerDashboardReducer,

  // Report Builder
  reportBuilder: reportBuilderReducer,
  reportBuilderEdit: reportBuilderEditReducer,
  // Embedded ReportBuilder
  embeddedReportBuilder: embeddedReportBuilderReducer,
  reportEditing: reportEditingReducer,

  // Superuser
  superuser: superuserReducer,
});

const rootReducer: Reducer<ReduxState, Action> = (state, action) => {
  if (action.type === ACTION.CLEAR_STATE) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export type ReduxState = ReturnType<typeof appReducer>;

export type JoinedStates = ReduxState | EmbedReduxState | ArchitectCustomerDashboardReduxState;

export default rootReducer;

export interface Action {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}
