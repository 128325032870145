import { cloneDeep } from 'utils/standard';
import { useDispatch } from 'react-redux';

import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import {
  OPERATION_TYPES,
  SpiderChartFormat,
  SpiderChartShape,
  V2TwoDimensionChartInstructions,
} from 'constants/types';
import { sharedStyles } from './styles';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2TwoDimensionChartInstructions;
  lineWidth: number;
};

export default function SpiderChartConfig({ visualizationType, instructions, lineWidth }: Props) {
  const classes = sharedStyles();
  const dispatch = useDispatch();

  const spiderChartFormat = instructions.chartSpecificFormat?.spiderChart;
  const selectedShape = spiderChartFormat?.spiderChartShape || SpiderChartShape.ROUND;

  const updateSpiderChartInstructions = (spiderChartUpdates: SpiderChartFormat) => {
    const newInstructions = cloneDeep(instructions);

    newInstructions.chartSpecificFormat = {
      ...newInstructions.chartSpecificFormat,
      spiderChart: {
        ...newInstructions.chartSpecificFormat?.spiderChart,
        ...spiderChartUpdates,
      },
    };

    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  return (
    <div className={classes.root}>
      <ToggleButtonGroup fillWidth className={classes.configInput} label="Grid Shape">
        <ToggleButton
          active={SpiderChartShape.ROUND === selectedShape}
          icon="layout-circle"
          key={SpiderChartShape.ROUND}
          onClick={() => {
            updateSpiderChartInstructions({ spiderChartShape: SpiderChartShape.ROUND });
          }}
          text={SpiderChartShape.ROUND}
        />
        <ToggleButton
          active={SpiderChartShape.POLYGON === selectedShape}
          icon="polygon-filter"
          key={SpiderChartShape.POLYGON}
          onClick={() => {
            updateSpiderChartInstructions({ spiderChartShape: SpiderChartShape.POLYGON });
          }}
          text={SpiderChartShape.POLYGON}
        />
      </ToggleButtonGroup>
      <InputWithBlurSave
        containerClassName={classes.configInput}
        initialValue={String(spiderChartFormat?.lineWidth ?? lineWidth)}
        label="Line Width"
        onNewValueSubmitted={(newValue) => {
          const newInt = parseInt(newValue);
          updateSpiderChartInstructions({ lineWidth: newInt > 0 ? newInt : undefined });
        }}
      />
    </div>
  );
}
