import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

const useStyles = makeStyles((theme: Theme) => ({
  titleText: {
    fontWeight: 600,
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
}));

type Props = {
  text: string;
};

export default function StepHeader({ text }: Props) {
  const classes = useStyles();

  return <div className={cx(classes.titleText, GLOBAL_STYLE_CLASSNAMES.text.h2.base)}>{text}</div>;
}
