import { FC, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import { makeStyles, Theme } from '@material-ui/core';
import * as RD from 'remotedata';
import { groupBy, keyBy, sortBy } from 'utils/standard';
import cx from 'classnames';
import { useImmer } from 'use-immer';

import { DashboardView } from './DashboardView';
import InputGroup from 'explo-ds/forms/marketing/inputGroup';
import { Icon } from '@blueprintjs/core';

import {
  Canvas,
  clearViewingArchitectCustomerDashboard,
  fetchAllDashboardsForCanvas,
  selectArchitectCustomerDashboardToView,
} from 'actions/canvasActions';
import { CanvasVersionConfig } from 'actions/canvasConfigActions';
import { ArchitectCustomerDashboard } from 'actions/architectCustomerDashboardActions';
import { selectCustomer } from 'actions/teamActions';
import { getArchitectCustomerDashboardName } from 'utils/architectCustomerDashboardUtils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  dashboardSelectorWrapper: {
    width: 350,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRight: `1px solid ${theme.palette.ds.grey400}`,
  },
  dashboardSearch: {
    height: 56,
    backgroundColor: theme.palette.ds.grey200,
    borderBottom: `1px solid ${theme.palette.ds.grey400}`,
    padding: theme.spacing(3),
  },
  searchInput: { width: '100%' },
  userGroupList: {
    flex: 1,
    overflowY: 'auto',
    width: '100%',
    backgroundColor: theme.palette.ds.white,
    padding: theme.spacing(3),
    color: theme.palette.ds.black,
  },
  emptyUserGroupList: {
    padding: theme.spacing(1),
    fontSize: 14,
    color: theme.palette.ds.grey900,
    lineHeight: '17px',
  },
  userGroup: {
    marginBottom: theme.spacing(4),
  },
  userGroupName: {
    height: '100%',
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  userDashboards: {
    marginLeft: theme.spacing(3),
  },
  userDashboard: {
    marginLeft: theme.spacing(4),
    height: 24,
    fontSize: 12,
    lineHeight: '24px',
    padding: `0px 8px`,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.palette.ds.grey400,
    },

    '&.selected': {
      backgroundColor: theme.palette.ds.blue,
      color: theme.palette.ds.white,
    },
  },
  dashboardViewer: {
    flex: 1,
    height: '100%',
  },
}));

type Props = {
  canvas: Canvas;
  config: CanvasVersionConfig;
  width?: number;
};

type OpenCustomer = { isOpen: boolean; openUsers: Set<string | null> };

export const DashboardViewer: FC<Props> = ({ config, canvas, width }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { architectCustomerDashboards, customers, selectedDashboardId } = useSelector(
    (state: ReduxState) => ({
      architectCustomerDashboards: state.canvasEdit.architectCustomerDashboards,
      customers: state.customers.groups,
      selectedDashboardId: state.canvasEdit.viewingArchitectCustomerDashboard?.id ?? null,
    }),
    shallowEqual,
  );

  const [searchString, setSearchString] = useState('');
  const [openCustomers, setOpenCustomers] = useImmer<Record<number, OpenCustomer | undefined>>({});

  useEffect(() => {
    return function clearViewer() {
      dispatch(clearViewingArchitectCustomerDashboard());
    };
  }, [dispatch]);

  const customersById = useMemo(() => {
    if (!RD.isSuccess(customers)) return null;
    return keyBy(customers.data, (group) => group.id);
  }, [customers]);

  const groupedDashboards = useMemo(() => {
    if (!RD.isSuccess(architectCustomerDashboards) || !customersById) return null;

    const grouped = sortBy(
      groupBy(architectCustomerDashboards.data, (db) => db.group_id),
      (group) => {
        const customer = customersById[group[0].group_id];
        return customer.name;
      },
    );
    return grouped.map((groupDashboards) =>
      sortBy(
        groupBy(groupDashboards, (db) => db.user_id),
        (userDashboards) => userDashboards[0].user_id ?? '',
      ),
    );
  }, [architectCustomerDashboards, customersById]);

  useEffect(() => {
    if (RD.isIdle(architectCustomerDashboards)) {
      dispatch(fetchAllDashboardsForCanvas({ postData: { canvas_id: canvas.id } }));
    }

    // Select first dashboard on load
    if (groupedDashboards && groupedDashboards.length > 0) {
      const group = groupedDashboards[0];
      const groupId = group[0][0].group_id;
      dispatch(selectArchitectCustomerDashboardToView(group[0][0].id));
      dispatch(selectCustomer(groupId));
      setOpenCustomers({
        [groupId]: { isOpen: true, openUsers: new Set([group[0][0].user_id]) },
      });
    }
  }, [
    groupedDashboards,
    architectCustomerDashboards,
    canvas.id,
    dispatch,
    customers,
    setOpenCustomers,
  ]);

  const getIcon = (isOpen: boolean | undefined) => (
    <Icon icon={isOpen ? 'caret-down' : 'caret-right'} iconSize={16} />
  );

  const renderGroupDashboards = (
    groupDashboards: ArchitectCustomerDashboard[][],
    openCustomer: OpenCustomer,
  ) => {
    const groupId = groupDashboards[0][0].group_id;
    return groupDashboards.map((userDashboards) => {
      const userId = userDashboards[0].user_id;
      const userName = userId ?? 'Default';
      const userIsOpen = openCustomer.openUsers.has(userId);
      return (
        <div className={classes.userDashboards} key={`${groupId}-${userName}`}>
          <div
            className={classes.userGroupName}
            onClick={(e) => {
              setOpenCustomers((draft) => {
                const openGroup = draft[groupId];
                if (!openGroup) return;
                if (userIsOpen) openGroup.openUsers.delete(userId);
                else openGroup.openUsers.add(userId);
              });
              e.stopPropagation;
            }}>
            {getIcon(userIsOpen)}
            {userName}
          </div>
          {userIsOpen
            ? userDashboards.map((dashboard) => (
                <div
                  className={cx(classes.userDashboard, {
                    selected: selectedDashboardId === dashboard.id,
                  })}
                  key={dashboard.id}
                  onClick={() => {
                    dispatch(selectArchitectCustomerDashboardToView(dashboard.id));
                    dispatch(selectCustomer(dashboard.group_id));
                  }}>
                  {getArchitectCustomerDashboardName(dashboard)}
                </div>
              ))
            : null}
        </div>
      );
    });
  };

  const renderUserGroupList = () => {
    if (!customersById || !groupedDashboards || groupedDashboards.length === 0) {
      return (
        <div className={classes.emptyUserGroupList}>There are no users with active dashboards.</div>
      );
    }

    const groupDivs: JSX.Element[] = [];
    const searchVal = searchString.trim() !== '' ? searchString.toLowerCase() : null;

    groupedDashboards.forEach((groupDashboards) => {
      const groupId = groupDashboards[0][0].group_id;
      const customer = customersById[groupId];
      if (!customer) return;

      const filteredGroupDashboards =
        searchVal && !customer.name.toLowerCase().includes(searchVal)
          ? groupDashboards.filter((userDashboards) =>
              userDashboards[0].user_id?.toLowerCase().includes(searchVal),
            )
          : groupDashboards;

      if (filteredGroupDashboards.length === 0) return;

      const openCustomer = openCustomers[groupId];
      groupDivs.push(
        <div className={classes.userGroup} key={groupId}>
          <div
            className={classes.userGroupName}
            onClick={() =>
              setOpenCustomers((draft) => {
                const openGroup = draft[groupId];
                if (openGroup) openGroup.isOpen = !openGroup.isOpen;
                else draft[groupId] = { isOpen: true, openUsers: new Set() };
              })
            }>
            {getIcon(openCustomer?.isOpen)}
            {customer.name}
          </div>
          {openCustomer?.isOpen
            ? renderGroupDashboards(filteredGroupDashboards, openCustomer)
            : null}
        </div>,
      );
    });

    if (groupDivs.length > 0) return groupDivs;
    return <div className={classes.emptyUserGroupList}>No dashboards match your search.</div>;
  };

  return (
    <div className={classes.root}>
      <div className={classes.dashboardSelectorWrapper}>
        <div className={classes.dashboardSearch}>
          <InputGroup
            className={classes.searchInput}
            leftIcon="search"
            onInputChange={setSearchString}
            placeholder="Search users or user groups"
            value={searchString}
          />
        </div>
        <div className={classes.userGroupList}>{renderUserGroupList()}</div>
      </div>
      <div className={classes.dashboardViewer}>
        {!groupedDashboards ||
        (groupedDashboards.length > 0 && selectedDashboardId === null) ? null : (
          <DashboardView canvas={canvas} canvasConfig={config} width={width} />
        )}
      </div>
    </div>
  );
};
