import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core';
import { sortBy } from 'utils/standard';
import * as RD from 'remotedata';

import DropdownSelect from 'shared/DropdownSelect';
import { DataGrid } from 'components/ds';
import { PaginatorProps } from 'components/ds/DataGrid/paginator';
import { isPagingDisabled } from 'components/ds/DataGrid/utils';

import { CanvasDataset } from 'actions/canvasConfigActions';
import { SelectedDropdownInputItem } from 'constants/types';
import {
  fetchCanvasFilterDatasetPreview,
  fetchCanvasFilterDatasetRowCount,
} from 'actions/datasetActions';
import { DatasetSchema, DatasetRow } from 'types/datasets';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { ReduxState } from 'reducers/rootReducer';
import { getSelectedCustomer } from 'reducers/customersReducer';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  selectors: {
    padding: theme.spacing(4),
  },
  tableContainer: {
    flex: 1,
    overflowY: 'auto',
    borderTop: `1px solid ${theme.palette.ds.grey300}`,
  },
  error: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.ds.grey700,
    fontSize: 14,
    padding: theme.spacing(4),
    textAlign: 'center',
  },
  controls: {
    height: 40,
    borderTop: `1px solid ${theme.palette.ds.grey500}`,
    backgroundColor: theme.palette.ds.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(3),
  },
}));

type Props = {
  datasets: Record<string, CanvasDataset>;
  filterProvidedId: string;
  holdDataLoad: boolean;
  referencedFilters: Record<string, string[]>;
  variables: DashboardVariableMap;
};

type PreviewData = { rows: DatasetRow[]; schema: DatasetSchema };

export default function FilterPreview({
  datasets,
  filterProvidedId,
  holdDataLoad,
  referencedFilters,
  variables,
}: Props): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();

  const selectedUserGroup = useSelector((state: ReduxState) =>
    getSelectedCustomer(state.customers),
  );

  const [selectedDatasetId, setSelectedDatasetId] = useState<string | null>(null);

  const [previewData, setPreviewData] = useState<RD.ResponseData<PreviewData>>(RD.Idle());
  const [rowCount, setRowCount] = useState<RD.ResponseData<number>>(RD.Idle());

  const [currentPage, setCurrentPage] = useState(1);

  const datasetOptions = useMemo(() => {
    const datasetsUsingFilter = new Set(referencedFilters[filterProvidedId] || []);
    return sortBy(
      Object.values(datasets).reduce<SelectedDropdownInputItem[]>((acc, dataset) => {
        if (datasetsUsingFilter.has(dataset.id)) {
          acc.push({ id: dataset.id, name: dataset.name });
        }
        return acc;
      }, []),
      'name',
    );
  }, [datasets, referencedFilters, filterProvidedId]);

  const selectedDataset = selectedDatasetId ? datasets[selectedDatasetId] : undefined;

  useEffect(() => {
    if (datasetOptions.length === 0) {
      if (selectedDatasetId !== null) setSelectedDatasetId(null);
      return;
    }

    const datasetIds = datasetOptions.map((dataset) => dataset.id);
    if (selectedDatasetId === null || !datasetIds.includes(selectedDatasetId)) {
      setSelectedDatasetId(datasetIds[0]);
    }
  }, [datasetOptions, selectedDatasetId]);

  const requestData = useCallback(
    (offset?: number) => {
      if (!selectedDataset) return;
      const postData = {
        dataset_id: selectedDataset.id,
        query: selectedDataset.query,
        parent_schema_id: selectedDataset.parent_schema_id,
        variables: { [filterProvidedId]: variables[filterProvidedId] },
        customer_id: selectedUserGroup?.id,
        offset,
        timezone: 'UTC',
      };

      setPreviewData(RD.Loading());

      dispatch(
        fetchCanvasFilterDatasetPreview(
          { postData },
          (data) =>
            setPreviewData(
              RD.Success({ rows: data.dataset_preview._rows, schema: data.dataset_preview.schema }),
            ),
          (error) => setPreviewData(RD.Error(error.error_msg ?? 'Error Loading Data')),
        ),
      );
      if (offset === undefined) {
        setRowCount(RD.Loading());
        setCurrentPage(1);
        dispatch(
          fetchCanvasFilterDatasetRowCount(
            { postData },
            (data) => setRowCount(RD.Success(data._total_row_count)),
            () => setRowCount(RD.Error('Error Loading Row Count')),
          ),
        );
      }
    },
    [dispatch, filterProvidedId, selectedDataset, selectedUserGroup, variables],
  );

  useEffect(() => {
    if (
      !selectedDataset ||
      !selectedDataset.query ||
      !selectedDataset.query.includes(filterProvidedId) ||
      holdDataLoad
    ) {
      setPreviewData(RD.Idle());
      return;
    }
    requestData();
  }, [filterProvidedId, holdDataLoad, requestData, selectedDataset]);

  const paginatorProps: PaginatorProps = useMemo(() => {
    return {
      totalRowCount: RD.isSuccess(rowCount) ? rowCount.data : 0,
      currentPage: currentPage,
      loading: false,
      isPagingDisabled: isPagingDisabled(selectedDataset?._unsupported_operations),
      goToPage: ({ page, offset }) => {
        requestData(offset);
        setCurrentPage(page);
      },
    };
  }, [selectedDataset?._unsupported_operations, rowCount, currentPage, requestData]);

  return (
    <div className={classes.root}>
      <div className={classes.selectors}>
        <DropdownSelect
          fillWidth
          ignoreCustomStyles
          minimal
          disabled={datasetOptions.length === 0}
          filterable={false}
          label="Datasets using this filter"
          noSelectionText="No Datasets Referencing Filter"
          onChange={(item) => {
            setSelectedDatasetId(item.id);
          }}
          options={datasetOptions}
          selectedItem={
            selectedDatasetId
              ? datasetOptions.find((opt) => opt.id === selectedDatasetId)
              : undefined
          }
        />
      </div>
      {RD.isIdle(previewData) ? null : (
        <>
          <div className={classes.tableContainer}>
            <RD.RemoteComponent
              Error={(errorMsg) => <div className={classes.error}>{errorMsg}</div>}
              Loading={() => <DataGrid loading />}
              Success={(data) => (
                <DataGrid paginatorProps={paginatorProps} rows={data.rows} schema={data.schema} />
              )}
              data={previewData}
            />
          </div>
        </>
      )}
    </div>
  );
}
