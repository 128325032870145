import { FC } from 'react';
import { isError, isLoading, isSuccess } from 'remotedata';
import copy from 'copy-to-clipboard';
import { Icon, sprinkles } from 'components/ds';

import { DownloadInfo } from 'reducers/dashboardLayoutReducer';
import { WindowState } from 'utils/exportUtils';
import { DEFAULT_SUPPORT_EMAIL } from 'constants/emailConstants';

type Props = {
  windowState: WindowState;
  downloadInfo?: DownloadInfo;
  supportEmail?: string;
};

export const DownloadStatus: FC<Props> = ({ windowState, downloadInfo, supportEmail }: Props) => {
  if (downloadInfo === undefined) return null;

  const isProblem =
    isError(downloadInfo.status) || windowState.downloadBlocked || windowState.popupBlocked;

  const isEmail = downloadInfo.type === 'email';

  if (!isProblem) {
    return (
      <div className={rootClass}>
        <div className={headingClass}>
          {isLoading(downloadInfo.status) ? (
            isEmail ? (
              'Email will be sent shortly'
            ) : (
              'Downloading...'
            )
          ) : (
            <>
              {isEmail ? 'Email will be sent shortly' : 'Download Complete'}
              <Icon className={sprinkles({ color: 'success' })} name="check" />
            </>
          )}
        </div>
      </div>
    );
  }

  const supportEmailText = supportEmail || DEFAULT_SUPPORT_EMAIL;

  if (isError(downloadInfo.status)) {
    return (
      <div className={rootClass}>
        <div className={headingClass}>
          {isEmail ? 'Email failed to send' : 'Download failed'}
          <Icon className={sprinkles({ color: 'error' })} name="cross" />
        </div>
        <div className={sprinkles({ body: 'b2' })}>
          If this continues, please contact{' '}
          <a href={'mailto:' + supportEmailText} rel="noopener noreferrer" target="_blank">
            {supportEmailText}
          </a>
        </div>
      </div>
    );
  }
  if (!isEmail && isSuccess(downloadInfo.status)) {
    const link = downloadInfo.status.data;
    const showLink = windowState.downloadBlocked;
    return (
      <div className={rootClass}>
        <div className={headingClass}>
          Download Complete
          <Icon className={sprinkles({ color: 'success' })} name="check" />
        </div>
        <div className={sprinkles({ body: 'b2' })}>
          If the download did not start automatically,{' '}
          <a
            href={showLink ? undefined : link}
            onClick={() => {
              if (showLink) copy(link || '');
            }}
            rel="noopener noreferrer"
            target="_blank">
            {showLink ? 'click here to copy link.' : 'click here.'}
          </a>
        </div>
      </div>
    );
  }
  return null;
};

const rootClass = sprinkles({ flexItems: 'centerColumn', color: 'gray10' });
const headingClass = sprinkles({ body: 'b2', gap: 'sp1', flexItems: 'alignCenter' });
