import {
  OPERATION_TYPES,
  V2_CHART_VISUALIZATION_OPERATIONS,
  VisualizeOperationInstructions,
} from 'constants/types';

export const convertHexToRGBA = (hexString: string, alpha: number) => {
  const hex = hexString.substr(1, 10);
  let c;
  let rgbaString = 'rgba(';
  for (let i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    rgbaString += `${c},`;
  }
  rgbaString += `${alpha})`;
  return rgbaString;
};

export const titleCase = (s: string | number) => {
  if (!s) return '';

  s = String(s);

  s = s.replace(/_/g, ' ');

  // WIP - camel case to title case
  // if (s.indexOf(' ') === -1) {
  //   s = s.replace(/([A-Z])/g, ' $1');
  // }
  return capitalizeEachWord(s);
};

const capitalizeEachWord = (str: string) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const getInformationTooltipInfo = (
  opType: OPERATION_TYPES,
  vizInstructions: VisualizeOperationInstructions,
) => {
  if (V2_CHART_VISUALIZATION_OPERATIONS.indexOf(opType) >= 0) {
    if (
      opType === OPERATION_TYPES.VISUALIZE_NUMBER_V2 ||
      opType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2
    ) {
      if (vizInstructions.V2_KPI?.generalFormat?.showTooltip) {
        return vizInstructions.V2_KPI.generalFormat?.tooltipText;
      }
    } else if (opType === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2) {
      if (vizInstructions.V2_KPI_TREND?.generalFormat?.showTooltip) {
        return vizInstructions.V2_KPI_TREND.generalFormat.tooltipText;
      }
    } else if (opType === OPERATION_TYPES.VISUALIZE_TREND_TABLE) {
      if (vizInstructions.V2_TREND_TABLE?.generalFormat?.showTooltip) {
        return vizInstructions.V2_TREND_TABLE.generalFormat.tooltipText;
      }
    } else if (opType === OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2) {
      if (vizInstructions.V2_SCATTER_PLOT?.generalFormat?.showTooltip) {
        return vizInstructions.V2_SCATTER_PLOT.generalFormat.tooltipText;
      }
    } else if (opType === OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2) {
      if (vizInstructions.V2_BOX_PLOT?.generalFormat?.showTooltip) {
        return vizInstructions.V2_BOX_PLOT.generalFormat.tooltipText;
      }
    } else {
      if (vizInstructions.V2_TWO_DIMENSION_CHART?.generalFormat?.showTooltip) {
        return vizInstructions.V2_TWO_DIMENSION_CHART.generalFormat?.tooltipText;
      }
    }
  } else if (opType === OPERATION_TYPES.VISUALIZE_TABLE) {
    if (vizInstructions.VISUALIZE_TABLE.generalFormat?.showTooltip) {
      return vizInstructions.VISUALIZE_TABLE.generalFormat?.tooltipText ?? '';
    }
  } else if (opType === OPERATION_TYPES.VISUALIZE_PIVOT_TABLE) {
    if (vizInstructions.VISUALIZE_PIVOT_TABLE?.generalFormat?.showTooltip) {
      return vizInstructions.VISUALIZE_PIVOT_TABLE?.generalFormat?.tooltipText ?? '';
    }
  } else if (opType === OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST) {
    if (vizInstructions.VISUALIZE_COLLAPSIBLE_LIST?.generalFormat?.showTooltip) {
      return vizInstructions.VISUALIZE_COLLAPSIBLE_LIST?.generalFormat?.tooltipText ?? '';
    }
  }
};
