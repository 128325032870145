import { createAction } from '@reduxjs/toolkit';

import { ActionFnWithArgs, defineAPIPostAction } from './actionUtils';
import { ACTION } from 'actions/types';

import { AdHocOperationInstructions, SortInfo } from 'types/dataPanelTemplate';
import {
  FetchDataPanelData,
  FetchDataPanelRowCountData,
  ExportUrlResponse,
} from 'actions/responseTypes';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { FilterOperationInstructions } from 'constants/types';
import { DataPanel, RequestDataset } from 'types/exploResource';
import { SpreadsheetType } from '../reducers/dashboardLayoutReducer';

export type FetchDataPanelBody = {
  id: string;
  config: DataPanel;
  variables: DashboardVariableMap;
  page_number?: number;
  sort_info?: SortInfo;
  filter_info?: FilterOperationInstructions;
  query_limit?: number;
  canvas_request?: boolean;
  timezone?: string;

  // embedded
  datasetId?: string | number;
  versionNumber?: number;
  resource_embed_id?: string;
  // non-embedded
  dataset?: RequestDataset;
  customer_id?: number;
  resource_id?: number;
};

export type FetchDataPanel = ActionFnWithArgs<FetchDataPanelBody, FetchDataPanelData>;

export const {
  actionFn: fetchDataPanel,
  requestAction: fetchDataPanelRequest,
  successAction: fetchDataPanelSuccess,
  errorAction: fetchDataPanelError,
} = defineAPIPostAction<FetchDataPanelBody, FetchDataPanelData>(
  ACTION.FETCH_DATA_PANEL_TEMPLATE,
  'data_panel_templates',
  'get_data',
  'POST',
);
export type FetchSecondaryDataBody = {
  variables: DashboardVariableMap;
  config: DataPanel;
  id: string;
  is_secondary_data_query: true;
  canvas_request?: boolean;
  timezone?: string;

  // embedded
  datasetId?: string | number;
  resource_embed_id?: string;
  // non-embedded
  dataset?: RequestDataset;
  customer_id?: number;
  resource_id?: number;
};

export type FetchSecondaryData = ActionFnWithArgs<FetchSecondaryDataBody, FetchDataPanelData>;

export const {
  actionFn: fetchSecondaryData,
  requestAction: fetchSecondaryDataRequest,
  successAction: fetchSecondaryDataSuccess,
  errorAction: fetchSecondaryDataError,
} = defineAPIPostAction<FetchSecondaryDataBody, FetchDataPanelData>(
  ACTION.FETCH_SECONDARY_DATA,
  'data_panel_templates',
  'get_data',
  'POST',
);

export type FetchDataPanelRowCountBody = {
  id: string;
  config: DataPanel;
  filter_info?: FilterOperationInstructions;
  variables: DashboardVariableMap;
  canvas_request?: boolean;
  timezone?: string;

  // embedded
  datasetId?: string | number;
  resource_embed_id?: string;
  // non-embedded
  dataset?: RequestDataset;
  customer_id?: number;
  resource_id?: number;
};

export type FetchDataPanelRowCount = ActionFnWithArgs<
  FetchDataPanelRowCountBody,
  FetchDataPanelRowCountData
>;

export const {
  actionFn: fetchDataPanelRowCount,
  successAction: fetchDataPanelRowCountSuccess,
  errorAction: fetchDataPanelRowCountError,
} = defineAPIPostAction<FetchDataPanelRowCountBody, FetchDataPanelRowCountData>(
  ACTION.FETCH_DATA_PANEL_ROW_COUNT,
  'data_panel_templates',
  'get_row_count',
  'POST',
);

export type DownloadDataPanelSpreadsheetBody = {
  id: string;
  config: DataPanel;
  variables: DashboardVariableMap;
  sort_info: SortInfo | undefined;
  filter_info: FilterOperationInstructions | undefined;
  canvas_request?: boolean;
  file_format: SpreadsheetType;
  email?: string;
  timezone?: string;

  // embedded
  datasetId?: string | number;
  resource_embed_id?: string;
  // non-embedded
  dataset?: RequestDataset;
  customer_id?: number;
  resource_id?: number;
};

export type DownloadDataPanelSpreadsheet = ActionFnWithArgs<
  DownloadDataPanelSpreadsheetBody,
  ExportUrlResponse
>;

export const {
  actionFn: downloadDataPanelSpreadsheet,
  requestAction: downloadDataPanelSpreadsheetRequest,
  successAction: downloadDataPanelSpreadsheetSuccess,
  errorAction: downloadDataPanelSpreadsheetError,
} = defineAPIPostAction<DownloadDataPanelSpreadsheetBody, ExportUrlResponse>(
  ACTION.DOWNLOAD_DATA_PANEL_CSV,
  'data_panel_templates',
  'download_spreadsheet',
  'POST',
);

type UpdateAdHocOperationInstructionsArgs = {
  dataPanelId: string;
  adHocOperationInstructions: AdHocOperationInstructions;
};

export const updateAdHocOperationInstructions = createAction<UpdateAdHocOperationInstructionsArgs>(
  ACTION.UPDATE_AD_HOC_OPERATION_INSTRUCTIONS,
);

export type UpdateDrilldownDataPanelActionType = (args: { dataPanel: DataPanel }) => void;

export const updateDrilldownDataPanel = createAction<{ dataPanel: DataPanel }>(
  ACTION.UPDATE_DRILLDOWN_DATA_PANEL,
);
