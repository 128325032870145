import { FC } from 'react';

import { ConfigSection } from '../ConfigSection';
import * as sharedStyles from '../index.css';
import { sprinkles } from 'components/ds';

type Props = {
  children: (JSX.Element | null)[];
  isNewDataset: boolean;
  sectionText: string;
  toggleName: string;
};

const toggleNameClass = sprinkles({
  paddingX: 'sp1',
  color: 'gray11',
  display: 'flex',
  justifyContent: 'flex-end',
  body: 'b3',
  marginBottom: 'sp.5',
});

export const DatasetColumnSelection: FC<Props> = ({
  isNewDataset,
  sectionText,
  toggleName,
  children,
}) => {
  return (
    <ConfigSection defaultIsOpen={isNewDataset} title="Select columns">
      <div className={sharedStyles.configSection}>
        <p className={sharedStyles.sectionText}>{sectionText}</p>
        <div className={toggleNameClass}>{toggleName}</div>
        {children}
      </div>
    </ConfigSection>
  );
};
export { DatasetColumnSelectionItem } from './item';
