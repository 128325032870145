import { useDispatch } from 'react-redux';
import produce from 'immer';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { Switch } from 'components/ds';
import SettingHeader from '../../DataConfigTab/SettingHeader';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { HeatMapFormat, OPERATION_TYPES, V2TwoDimensionChartInstructions } from 'constants/types';
import { sharedStyles } from './styles';
import { GlobalStyleConfig } from 'globalStyles/types';
import HeatMapGradientConfiguration from './HeatMapGradientConfiguration';

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    margin: theme.spacing(3),
  },
}));

type Props = {
  globalStyleConfig: GlobalStyleConfig;
  visualizationType: OPERATION_TYPES;
  instructions: V2TwoDimensionChartInstructions;
};

export default function HeatMapConfig({
  globalStyleConfig,
  visualizationType,
  instructions,
}: Props) {
  const classes = sharedStyles();
  const customClasses = useStyles();
  const dispatch = useDispatch();
  const heatMap = instructions.chartSpecificFormat?.heatMap;

  const updateHeatMapInstructions = (updates: HeatMapFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.chartSpecificFormat = {
        ...draft.chartSpecificFormat,
        heatMap: {
          ...draft.chartSpecificFormat?.heatMap,
          ...updates,
        },
      };
    });

    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  return (
    <div className={classes.root}>
      <Switch
        useCustomStyles
        className={classes.configInput}
        label="Show Chart Values"
        onChange={() => updateHeatMapInstructions({ showCellLabels: !heatMap?.showCellLabels })}
        switchOn={heatMap?.showCellLabels}
      />
      {heatMap?.showCellLabels && (
        <Switch
          useCustomStyles
          className={classes.configInput}
          label="Hide Zeros"
          onChange={() => updateHeatMapInstructions({ hideZeros: !heatMap?.hideZeros })}
          switchOn={heatMap?.hideZeros}
        />
      )}
      <SettingHeader name="Gradient" />
      <div className={customClasses.section}>
        <HeatMapGradientConfiguration
          globalStyleConfig={globalStyleConfig}
          instructions={instructions}
          updateHeatMapInstructions={updateHeatMapInstructions}
        />
      </div>
    </div>
  );
}
