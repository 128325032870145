import { FC } from 'react';
import { sprinkles } from 'components/ds';

type Props = {
  action?: JSX.Element;
  description?: React.ReactChild;
  icon?: JSX.Element;
  title: React.ReactNode;
};

export const DatasetEditorNonIdealState: FC<Props> = ({ action, description, icon, title }) => {
  return (
    <div
      className={sprinkles({
        flexItems: 'centerColumn',
        color: 'contentSecondary',
        textAlign: 'center',
        height: 'fill',
        gap: 'sp1.5',
        padding: 'sp3',
      })}>
      {icon ? icon : null}
      <div className={sprinkles({ heading: 'h3' })}>{title}</div>
      {description ? <div className={sprinkles({ body: 'b2' })}>{description}</div> : null}
      {action ? action : null}
    </div>
  );
};
