import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as RD from 'remotedata';

import { Button } from 'components/ds';
import { EmbeddedDropdownMenu } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownMenu';

import {
  clearExport,
  openModalType,
  ReportModal,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { exportReport } from 'reportBuilderContent/thunks/exportThunks';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { IconName } from 'components/ds/Icon';

type Props = { exportDisabled: boolean };

export const ExportButton: FC<Props> = ({ exportDisabled }) => {
  const dispatch = useDispatch();
  const exportUrl = useSelector((state: ReportBuilderReduxState) => state.reportEditing.exportUrl);
  const [scheduleClicked, setScheduleClicked] = useState(false);

  useEffect(() => {
    if (!RD.isSuccess(exportUrl)) return;

    window.open(exportUrl.data);
    dispatch(clearExport());
  }, [dispatch, exportUrl]);

  const isLoading = RD.isLoading(exportUrl);

  const menuOptions = [
    {
      disabled: exportDisabled,
      icon: 'paper-plane' as IconName,
      name: 'Export now',
      onClick: () => dispatch(exportReport()),
    },
    {
      disabled: exportDisabled,
      icon: 'clock' as IconName,
      name: 'Schedule export',
      onClick: () => setScheduleClicked(true),
    },
  ];

  return (
    <EmbeddedDropdownMenu
      disabled={isLoading}
      menuOptions={menuOptions}
      onOpenChange={(open) => {
        // https://github.com/radix-ui/primitives/issues/1241
        if (!open && scheduleClicked) {
          setScheduleClicked(false);
          dispatch(openModalType(ReportModal.SCHEDULE_EXPORT));
        }
      }}>
      <Button icon="file-export" loading={isLoading} type="primary">
        Export
      </Button>
    </EmbeddedDropdownMenu>
  );
};
