import { FC } from 'react';
import { useParams } from 'react-router';

import { sprinkles } from 'components/ds';
import { ViewModeTabs } from 'components/resource/ViewModeTabs';
import { VIEW_MODE } from './types';

const rootClass = sprinkles({
  flexItems: 'column',
  height: 'fill',
  borderRight: 1,
  borderColor: 'gray7',
  backgroundColor: 'white',
});

const tabs = [VIEW_MODE.DATASETS, VIEW_MODE.BUILT_INS, VIEW_MODE.PREVIEW];

export const EditorLeftColumn: FC = ({ children }) => {
  const { reportBuilderId, view } = useParams<{ reportBuilderId: string; view: VIEW_MODE }>();

  return (
    <div className={rootClass} style={{ minWidth: 350, width: 350 }}>
      <ViewModeTabs
        selectedTab={view}
        tabs={process.env.REACT_APP_ENVIRONMENT === 'development' ? [...tabs, VIEW_MODE.DEV] : tabs}
        toUrl={`/report-builder/${reportBuilderId}`}
      />

      <div className={sprinkles({ flex: 1, overflowY: 'auto' })}>{children}</div>
    </div>
  );
};
