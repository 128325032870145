import { makeStyles, Theme } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import TableConfig from './TableConfig';
import TwoDimensionVizConfig from './TwoDimensionVizConfig';
import KPIConfig from './KPIConfig';

import { VisualizeOperation } from 'types/dataPanelTemplate';
import { OPERATION_TYPES } from 'constants/types';
import { CanvasDataset, updateCanvasTemplateVizOp } from 'actions/canvasConfigActions';
import { updateEUDataPanelVizOp } from 'actions/architectCustomerDashboardConfigActions';

const useStyles = makeStyles((theme: Theme) => ({
  container: ({ isArchitectCustomerDashboard }: { isArchitectCustomerDashboard: boolean }) => ({
    padding: isArchitectCustomerDashboard ? theme.spacing(2) : '0px 12px',
  }),
  flexContainer: { flex: 1 },
  inputRow: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  fullWidthInput: { marginBottom: theme.spacing(2) },
}));

type Props = {
  dataset: CanvasDataset;
  isArchitectCustomerDashboard: boolean;
  panelId: string;
  visualizeOperation: VisualizeOperation;

  fetchPanelData: (newViz: VisualizeOperation) => void;
};

export default function ChartConfig({
  dataset,
  visualizeOperation,
  panelId,
  fetchPanelData,
  isArchitectCustomerDashboard,
}: Props): JSX.Element | null {
  const classes = useStyles({ isArchitectCustomerDashboard });
  const dispatch = useDispatch();

  const { operation_type } = visualizeOperation;

  const updateVizOp = (newViz: VisualizeOperation, fetchData = true) => {
    dispatch(
      isArchitectCustomerDashboard
        ? updateEUDataPanelVizOp(newViz)
        : updateCanvasTemplateVizOp({ templateId: panelId, vizOp: newViz }),
    );
    if (fetchData) fetchPanelData(newViz);
  };

  const renderTypeConfig = () => {
    switch (operation_type) {
      case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_LINE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_AREA_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_PIE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_FUNNEL_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2:
        return (
          <TwoDimensionVizConfig
            dataset={dataset}
            operationType={operation_type}
            updateVizOp={updateVizOp}
            usePortal={isArchitectCustomerDashboard}
            visualizeOperation={visualizeOperation}
          />
        );
      case OPERATION_TYPES.VISUALIZE_NUMBER_V2:
        return (
          <KPIConfig
            dataset={dataset}
            updateVizOp={updateVizOp}
            usePortal={isArchitectCustomerDashboard}
            visualizeOperation={visualizeOperation}
          />
        );
      case OPERATION_TYPES.VISUALIZE_TABLE:
        return (
          <TableConfig
            dataset={dataset}
            isArchitectCustomerDashboard={isArchitectCustomerDashboard}
            updateVizOp={(newViz) => updateVizOp(newViz, false)}
            visualizeOperation={visualizeOperation}
          />
        );

      default:
        return null;
    }
  };

  return <div className={classes.container}>{renderTypeConfig()}</div>;
}
