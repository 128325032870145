import { createReducer, createSelector, isAnyOf } from '@reduxjs/toolkit';
import * as RD from 'remotedata';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from 'utils/standard';

import { CanvasViewType, EmbedCanvas } from 'actions/canvasActions';
import { EmbedCanvasVersion } from 'actions/canvasVersionActions';
import { ArchitectCustomerDashboard } from 'actions/architectCustomerDashboardActions';
import { EndUserDataPanel } from 'actions/architectCustomerDashboardConfigActions';
import { Customer } from 'actions/teamActions';
import * as customerActions from 'actions/architectCustomerDashboardActions';
import * as eudConfigActions from 'actions/architectCustomerDashboardConfigActions';
import * as embedActions from 'actions/shareActions';
import * as dptActions from 'actions/dataPanelTemplateAction';
import * as datasetActions from 'actions/datasetActions';
import * as architectEmailCadenceActions from 'actions/architectEmailCadenceActions';
import * as eudUtils from 'utils/architectCustomerDashboardUtils';
import { ArchitectCustomerDashboardConfig } from 'actions/architectCustomerDashboardConfigActions';
import { EMPTY_FILTER_CONFIG } from 'constants/dataConstants';
import { CanvasDataset } from 'actions/canvasConfigActions';
import {
  EMPTY_FILTER_OP_STATE,
  EMPTY_VISUALIZATION_INSTRUCTIONS,
} from 'constants/dashboardConstants';
import { OPERATION_TYPES } from 'constants/types';
import { getNextElementNumber } from 'utils/canvasConfigUtils';
import { updateAdHocOperationInstructions } from 'actions/dataPanelTemplateAction';
import {
  handleSecondaryDataError,
  handleSecondaryDataRequest,
  handleSecondaryDataSuccess,
} from './utils';
import { updateEUDataPanelInstructions } from 'actions/updateInstructionsActions';
import { updateInstructions } from 'utils/dataPanelInstructionUtils';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { removeUnderscoreFields } from 'utils/dashboardUtils';

interface ArchitectCustomerDashboardReducer {
  canvas: RD.ResponseData<EmbedCanvas>;
  canvasVersion: EmbedCanvasVersion | null;
  editingDashboard: ArchitectCustomerDashboard | null;
  customer: Customer | null;
  architectEmailCadences: architectEmailCadenceActions.ArchitectEmailCadence[] | null;
  changesMadeToDashboard: boolean;
  selectedDashboardItemId: string | undefined;
  team: customerActions.EmbedArchitectCustomerDashboardTeam | null;
  exportUrl: RD.ResponseData<string>;
  // Multi View
  dashboards: ArchitectCustomerDashboard[] | null;
  dashboardVariables: Record<string, DashboardVariableMap>;
  openDashboardId: number | null | undefined;
  tabbedDashboards: (number | null)[];
}

const initialState: ArchitectCustomerDashboardReducer = {
  canvas: RD.Idle(),
  canvasVersion: null,
  editingDashboard: null,
  customer: null,
  architectEmailCadences: null,
  changesMadeToDashboard: false,
  selectedDashboardItemId: undefined,
  team: null,
  exportUrl: RD.Idle(),
  dashboards: null,
  dashboardVariables: {},
  openDashboardId: undefined,
  tabbedDashboards: [],
};

export const getCurrentDashboard = createSelector(
  (state: ArchitectCustomerDashboardReducer) => state.dashboards,
  (state: ArchitectCustomerDashboardReducer) => state.openDashboardId,
  (state: ArchitectCustomerDashboardReducer) => state.editingDashboard,
  (dashboards, openDashboardId, editingDashboard) => {
    if (!dashboards || openDashboardId === undefined) return null;
    if (editingDashboard) return editingDashboard;
    return dashboards.find((dashboard) => dashboard.id === openDashboardId) ?? null;
  },
);

export const getCurrentDashboardInfo = createSelector(
  (state: ArchitectCustomerDashboardReducer) => state.dashboards,
  (state: ArchitectCustomerDashboardReducer) => state.openDashboardId,
  (dashboards, openDashboardId) => {
    if (!dashboards || openDashboardId === undefined) return null;
    const dashboard = dashboards.find((dashboard) => dashboard.id === openDashboardId);
    if (dashboard) return { id: dashboard.id, name: dashboard.name };
    return null;
  },
);

function updateDashboardConfig(
  state: ArchitectCustomerDashboardReducer,
  updateFunc: (configuration: ArchitectCustomerDashboardConfig) => void,
): void {
  if (!state.dashboards || state.openDashboardId === undefined) return;
  if (state.editingDashboard !== null) {
    // TODO: How to handle data updates when going between edit and view
    updateFunc(state.editingDashboard.configuration);
    return;
  }
  const dashboard = state.dashboards.find((dashboard) => dashboard.id === state.openDashboardId);
  if (dashboard) updateFunc(dashboard.configuration);
}

function updateDataset(
  state: ArchitectCustomerDashboardReducer,
  datasetId: string,
  updateFunc: (dataset: CanvasDataset) => void,
): void {
  if (state.canvasVersion === null) return;

  const dataset = state.canvasVersion.configuration.datasets[datasetId];
  if (dataset) updateFunc(dataset);
}

function editDashboard(
  state: ArchitectCustomerDashboardReducer,
  updateFunc: (dashboard: ArchitectCustomerDashboard) => void,
): void {
  if (state.editingDashboard === null) return;
  state.changesMadeToDashboard = true;
  updateFunc(state.editingDashboard);
}

// Update panel changes that need to be saved
function updatePanel(
  state: ArchitectCustomerDashboardReducer,
  updateFunc: (dataPanel: EndUserDataPanel) => void,
): void {
  if (!state.editingDashboard || !state.selectedDashboardItemId) return;
  const dataPanel = state.editingDashboard.configuration.data_panels[state.selectedDashboardItemId];
  if (!dataPanel) return;
  state.changesMadeToDashboard = true;
  updateFunc(dataPanel);
}

// Update panel data which doesn't require saving
function updatePanelData(
  state: ArchitectCustomerDashboardReducer,
  panelId: string,
  updateFunc: (dataPanel: EndUserDataPanel) => void,
): void {
  updateDashboardConfig(state, (config) => {
    const panel = config.data_panels[panelId];
    if (panel) updateFunc(panel);
  });
}

export default createReducer(initialState, (builder) => {
  builder
    .addCase(customerActions.fetchArchitectCustomerDashboardsRequest, (state) => {
      state.canvas = RD.Loading();
    })
    .addCase(customerActions.fetchArchitectCustomerDashboardsSuccess, (state, { payload }) => {
      state.canvas = RD.Success(payload.canvas);
      state.canvasVersion = payload.canvas_version;
      state.team = payload.team;
      state.customer = payload.customer;
      state.architectEmailCadences = payload.emails;
      state.dashboards = payload.dashboards;
      const baseDashboard = eudUtils.convertCanvasVersionToArchitectCustomerDashboard(
        payload.customer.id,
        payload.canvas_version.configuration,
        payload.canvas.name,
        payload.canvas_version.modified,
      );
      state.dashboards.push(baseDashboard);

      if (payload.canvas.configurability?.viewType === CanvasViewType.SINGLE) {
        state.openDashboardId = state.dashboards[0].id;
      }
    })
    .addCase(customerActions.fetchArchitectCustomerDashboardsError, (state, { payload }) => {
      state.canvas = RD.Error(payload.errorData?.detail ?? 'Error Loading Dashboards');
    })
    .addCase(customerActions.createArchitectCustomerDashboardSuccess, (state, { payload }) => {
      if (!state.dashboards) return;
      if (state.editingDashboard) {
        const currentDashboard = state.dashboards.find(
          (dashboard) => dashboard.id === state.openDashboardId,
        );
        if (!currentDashboard) return;
        currentDashboard.id = payload.dashboard.id;
        currentDashboard.configuration = state.editingDashboard.configuration;
      } else {
        state.dashboards.push(payload.dashboard);
        state.tabbedDashboards.push(payload.dashboard.id);
      }
      state.openDashboardId = payload.dashboard.id;
      state.editingDashboard = null;
      state.selectedDashboardItemId = undefined;
    })
    .addCase(customerActions.deleteArchitectCustomerDashboardSuccess, (state, { payload }) => {
      if (!state.dashboards) return;
      state.tabbedDashboards = state.tabbedDashboards.filter((id) => id !== payload.dashboard_id);
      state.dashboards = state.dashboards.filter(
        (dashboard) => dashboard.id !== payload.dashboard_id,
      );

      if (
        RD.isSuccess(state.canvas) &&
        state.canvas.data.configurability?.enableResetDashboardChanges &&
        state.canvas.data.configurability.viewType === CanvasViewType.SINGLE
      ) {
        state.openDashboardId = null;
      }

      if (state.dashboards.length === 0 && state.customer && state.canvasVersion) {
        const baseDashboard = eudUtils.convertCanvasVersionToArchitectCustomerDashboard(
          state.customer?.id,
          state.canvasVersion.configuration,
          'Dashboard',
          Date().toString(),
        );

        state.dashboards.push(baseDashboard);
      }

      if (payload.dashboard_id in state.dashboardVariables) {
        delete state.dashboardVariables[payload.dashboard_id];
      }
    })
    .addCase(customerActions.toggleArchitectCustomerDashboardEditing, (state, { payload }) => {
      if (state.openDashboardId === undefined || !state.dashboards) return;

      if (!payload) {
        state.editingDashboard = null;
      } else {
        const dashboard = state.dashboards.find(
          (dashboard) => dashboard.id === state.openDashboardId,
        );
        if (dashboard) state.editingDashboard = cloneDeep(dashboard);
      }
      state.selectedDashboardItemId = undefined;
      state.changesMadeToDashboard = false;
    })
    .addCase(customerActions.saveArchitectCustomerDashboardSuccess, (state, { payload }) => {
      if (!state.editingDashboard || !state.dashboards) return;
      const dashboard = state.dashboards.find((dashboard) => dashboard.id === payload.dashboard_id);
      if (!dashboard) return;
      dashboard.configuration = state.editingDashboard.configuration;
      dashboard.name = state.editingDashboard.name;
      state.editingDashboard = null;
      state.selectedDashboardItemId = undefined;
    })
    .addCase(customerActions.openArchitectCustomerDashboard, (state, { payload }) => {
      if (state.openDashboardId === payload) return;
      state.editingDashboard = null;
      state.openDashboardId = payload;

      if (payload !== undefined && !state.tabbedDashboards.includes(payload))
        state.tabbedDashboards.push(payload);
    })
    .addCase(customerActions.closeArchitectCustomerDashboard, (state, { payload }) => {
      if (state.openDashboardId === payload) {
        state.editingDashboard = null;
        state.openDashboardId = undefined;
      }
      state.tabbedDashboards = state.tabbedDashboards.filter((dashId) => dashId !== payload);

      if ((payload ?? '') in state.dashboardVariables) {
        delete state.dashboardVariables[payload ?? ''];
      }

      const dashboard = state.dashboards?.find((dashboard) => dashboard.id === payload);
      if (dashboard) {
        Object.values(dashboard.configuration.data_panels).map(removeUnderscoreFields);
      }
    })
    .addCase(customerActions.updateArchitectCustomerDashboardVariables, (state, { payload }) => {
      state.dashboardVariables[payload.dashboardId ?? ''] = payload.variables;
    })
    .addCase(customerActions.updateArchitectCustomerDashboardName, (state, { payload }) => {
      if (!state.editingDashboard) return;
      if (payload !== state.editingDashboard.name) {
        state.changesMadeToDashboard = true;
        state.editingDashboard.name = payload;
      }
    })
    .addCase(customerActions.setSelectedDashboardItemId, (state, { payload }) => {
      state.selectedDashboardItemId = payload;
    })
    .addCase(customerActions.setArchitectCustomerDashboardForExample, (state, { payload }) => {
      state.canvasVersion = payload.canvasVersion;
      state.canvas = RD.Success(payload.canvas);
      // Ids below don't matter since this won't be saved
      state.dashboards = [
        eudUtils.convertCanvasVersionToArchitectCustomerDashboard(
          0,
          payload.canvasVersion.configuration,
          payload.canvas.name,
          '',
        ),
      ];
      state.openDashboardId = null;
    })
    .addCase(customerActions.clearArchitectCustomerDashboardExample, () => {
      return initialState;
    })
    .addCase(eudConfigActions.updateEUDLayout, (state, { payload }) => {
      editDashboard(state, (dashboard) => {
        dashboard.configuration.layout = payload;
      });
    })
    .addCase(eudConfigActions.toggleEUDFilter, (state, { payload }) => {
      const { filter, isFilterInDashboard } = payload;
      editDashboard(state, (dashboard) => {
        if (isFilterInDashboard) {
          dashboard.configuration.filters = dashboard.configuration.filters.filter(
            (filterId) => filterId !== filter.id,
          );
          dashboard.configuration.layout = dashboard.configuration.layout.filter(
            (elem) => elem.i !== filter.id,
          );
        } else {
          dashboard.configuration.filters.push(filter.id);
          eudUtils.addElementToLayout(
            dashboard.configuration.layout,
            filter.id,
            filter.filter_info?.filter_type,
          );
        }
      });
    })
    .addCase(eudConfigActions.copyEUDTemplate, (state, { payload }) => {
      if (payload.isCustomComponent) {
        const customComponent =
          state.canvasVersion?.configuration.customComponents?.[payload.templateId];
        if (!customComponent) return;
        editDashboard(state, ({ configuration }) => {
          if (!configuration.custom_components) configuration.custom_components = {};
          const newId = uuidv4();
          configuration.custom_components[newId] = payload.templateId;
          eudUtils.addElementToLayout(
            configuration.layout,
            newId,
            customComponent.info.elementType,
          );
          state.selectedDashboardItemId = newId;
        });
        return;
      }
      const template = state.canvasVersion?.configuration.templates[payload.templateId];
      if (!template) return;
      editDashboard(state, ({ configuration }) => {
        const newId = uuidv4();
        const nextNum = getNextElementNumber('chart_', configuration.data_panels);
        const newPanel = eudUtils.convertTemplateToDataPanel(template, newId, nextNum);
        configuration.data_panels[newId] = newPanel;
        eudUtils.addElementToLayout(configuration.layout, newId);
        state.selectedDashboardItemId = newId;
      });
    })
    .addCase(eudConfigActions.createEUDataPanel, (state) => {
      const dataset = Object.values(state.canvasVersion?.configuration.datasets ?? {})[0];
      if (!dataset) return;
      editDashboard(state, ({ configuration }) => {
        const newId = uuidv4();
        const defaultOpType = OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2;
        const nextNum = getNextElementNumber('chart_', configuration.data_panels);
        const providedId = `chart_${nextNum}`;
        const newPanel: EndUserDataPanel = {
          id: newId,
          provided_id: providedId,
          dataset_id: dataset.id,
          template_id: null,
          visualize_op: EMPTY_VISUALIZATION_INSTRUCTIONS(
            defaultOpType,
            `Chart ${nextNum}`,
            providedId,
          ),
        };
        configuration.data_panels[newId] = newPanel;
        eudUtils.addElementToLayout(configuration.layout, newId);
        state.selectedDashboardItemId = newId;
      });
    })
    .addCase(eudConfigActions.deleteEUDItem, (state) => {
      editDashboard(state, ({ configuration }) => {
        if (!state.selectedDashboardItemId) return;
        if (state.selectedDashboardItemId in configuration.data_panels) {
          delete configuration.data_panels[state.selectedDashboardItemId];
        } else if (
          configuration.custom_components &&
          state.selectedDashboardItemId in configuration.custom_components
        ) {
          delete configuration.custom_components[state.selectedDashboardItemId];
        } else if (configuration.filters.includes(state.selectedDashboardItemId)) {
          configuration.filters = configuration.filters.filter(
            (filterId) => filterId !== state.selectedDashboardItemId,
          );
        }
        configuration.layout = configuration.layout.filter(
          (elem) => elem.i !== state.selectedDashboardItemId,
        );
      });
    })
    .addCase(eudConfigActions.updateEUDataPanelDataset, (state, { payload }) => {
      updatePanel(state, (panel) => {
        panel.dataset_id = payload;
        panel.visualize_op = {
          ...EMPTY_VISUALIZATION_INSTRUCTIONS(
            panel.visualize_op.operation_type,
            '',
            panel.provided_id,
          ),
          generalFormatOptions: panel.visualize_op.generalFormatOptions,
        };
      });
    })
    .addCase(eudConfigActions.updateEUDataPanelVizOp, (state, { payload }) => {
      updatePanel(state, (panel) => (panel.visualize_op = payload));
    })
    .addCase(updateEUDataPanelInstructions, (state, { payload }) => {
      updatePanel(state, (panel) => updateInstructions(panel, payload));
    })
    .addCase(eudConfigActions.updateEUDataPanelChartType, (state, { payload }) => {
      updatePanel(state, (panel) => {
        if (payload.isSubType) {
          panel.visualize_op.operation_type = payload.operationType;
        } else {
          panel.visualize_op = {
            ...EMPTY_VISUALIZATION_INSTRUCTIONS(payload.operationType, '', panel.provided_id),
            generalFormatOptions: panel.visualize_op.generalFormatOptions,
          };
        }
      });
    })
    .addCase(eudConfigActions.updateEUDataPanelFilters, (state, { payload }) => {
      updatePanel(state, (panel) => {
        if (!panel.filter_op) panel.filter_op = EMPTY_FILTER_OP_STATE();
        panel.filter_op.instructions.filterClauses = payload;
      });
    })
    .addCase(updateAdHocOperationInstructions, (state, { payload }) => {
      updatePanelData(state, payload.dataPanelId, (panel) => {
        panel._adHocOperationInstructions = payload.adHocOperationInstructions;
      });
    })
    .addCase(
      architectEmailCadenceActions.createArchitectEmailCadenceSuccess,
      (state, { payload }) => {
        if (!state.architectEmailCadences) return;
        state.architectEmailCadences.push(payload.email);
      },
    )
    .addCase(
      architectEmailCadenceActions.deleteArchitectEmailCadenceSuccess,
      (state, { payload }) => {
        if (!state.architectEmailCadences) return;
        state.architectEmailCadences = state.architectEmailCadences.filter(
          (email) => email.id !== payload.id,
        );
      },
    )
    .addCase(
      architectEmailCadenceActions.updateArchitectEmailCadenceSuccess,
      (state, { payload }) => {
        if (!state.architectEmailCadences) return;
        state.architectEmailCadences = state.architectEmailCadences.filter(
          (email) => email.id !== payload.email.id,
        );
        state.architectEmailCadences.push(payload.email);
      },
    )
    .addCase(customerActions.exportArchitectDashboardRequest, (state) => {
      state.exportUrl = RD.Loading();
    })
    .addCase(customerActions.exportArchitectDashboardSuccess, (state, { payload }) => {
      state.exportUrl = RD.Success(payload.url);
    })
    .addCase(customerActions.exportArchitectDashboardError, (state) => {
      state.exportUrl = RD.Error('Error Exporting Dashboard');
    })
    .addCase(customerActions.clearArchitectExport, (state) => {
      state.exportUrl = RD.Idle();
    })
    .addMatcher(
      isAnyOf(embedActions.embedFetchDataPanelRequest, dptActions.fetchDataPanelRequest),
      (state, { payload }) => {
        updatePanelData(state, payload.postData.id, (panel) => {
          panel._loading = true;
          panel._adHocOperationInstructions = {
            ...panel._adHocOperationInstructions,
            currentPage: payload.postData.page_number || 1,
            sortInfo: payload.postData.sort_info,
            filterInfo: payload.postData.filter_info || { ...EMPTY_FILTER_CONFIG },
          };
        });
      },
    )
    .addMatcher(
      isAnyOf(embedActions.embedFetchDataPanelError, dptActions.fetchDataPanelError),
      (state, { payload }) => {
        updatePanelData(state, payload.postData.id, (panel) => {
          panel._loading = false;
          panel._error = payload.error_msg ?? 'There was an error fetching the results';
        });
      },
    )
    .addMatcher(
      isAnyOf(embedActions.embedFetchDataPanelSuccess, dptActions.fetchDataPanelSuccess),
      (state, { payload }) => {
        updatePanelData(state, payload.postData.id, (panel) => {
          panel._loading = false;
          panel._rows = payload.data_panel_template._rows;
          panel._schema = payload.data_panel_template._schema;
          panel._source_type = payload.data_panel_template._source_type;
          panel._unsupported_operations = payload.data_panel_template._unsupported_operations;
          panel._error = payload.data_panel_template._error;
        });
      },
    )
    .addMatcher(
      isAnyOf(
        embedActions.embedFetchDataPanelRowCountSuccess,
        dptActions.fetchDataPanelRowCountSuccess,
      ),
      (state, { payload }) => {
        updatePanelData(state, payload.postData.id, (panel) => {
          panel._total_row_count = payload._total_row_count;
        });
      },
    )
    .addMatcher(
      isAnyOf(
        embedActions.embedFetchDashboardDatasetPreviewSuccess,
        datasetActions.fetchDashboardDatasetPreviewSuccess,
      ),
      (state, { payload }) => {
        updateDataset(state, payload.postData.dataset_id, (dataset) => {
          dataset.schema = payload.dataset_preview.schema;
          dataset._rows = payload.dataset_preview._rows;
          dataset._unsupported_operations = payload.dataset_preview._unsupported_operations;
          dataset._loading = false;
          dataset._error = undefined;
        });
      },
    )
    .addMatcher(
      isAnyOf(embedActions.embedFetchSecondaryDataRequest, dptActions.fetchSecondaryDataRequest),
      (state, { payload }) => {
        updatePanelData(state, payload.postData.id, (panel) => handleSecondaryDataRequest(panel));
      },
    )
    .addMatcher(
      isAnyOf(embedActions.embedFetchSecondaryDataSuccess, dptActions.fetchSecondaryDataSuccess),
      (state, { payload }) => {
        updatePanelData(state, payload.postData.id, (panel) =>
          handleSecondaryDataSuccess(panel, payload.data_panel_template),
        );
      },
    )
    .addMatcher(
      isAnyOf(embedActions.embedFetchSecondaryDataError, dptActions.fetchSecondaryDataError),
      (state, { payload }) => {
        updatePanelData(state, payload.postData.id, (panel) => handleSecondaryDataError(panel));
      },
    )
    .addDefaultCase((state) => state);
});
