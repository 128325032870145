import { Tooltip } from 'components/ds';
import { Props as TooltipProps } from 'components/ds/Tooltip';
import { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import { useDoubleClick } from 'utils/hookUtils';

import * as styles from 'components/EditableText.css';

interface Props {
  /** Initial value */
  value: string;

  /** Input or empty value placeholder */
  placeholder: string;

  /** Editing is triggered when the user double clicks. If they single click, this is called instead */
  onSingleClick?: () => void;

  /** Called when the user is done editing (blurs or presses enter) */
  onRename: (name: string) => void;

  /** Disable editing */
  disabled?: boolean;

  /** Manually control the renaming state from outside the component */
  isRenaming?: boolean;

  /** Use this to manually update the renaming state if using isRenaming */
  onRenamingChange?: (isRenaming: boolean) => void;

  /** Optional extra content that will be hidden when the input is active. Clicking won't trigger editing */
  children?: ReactNode;

  /**
   * Optional tooltip that appears when the text is hovered
   */
  tooltipProps?: TooltipProps;

  /** Will be rendered after the value string inside the tooltip trigger */
  triggerChildren?: ReactNode;

  className?: string;
}

/**
 * Allows the user to edit text inline. It will automatically resize to the width of the text
 * When using inside a flexbox, you may need to set min-width: 0 on all parent flex items
 */
export const EditableText: FC<Props> = ({
  className,
  value,
  placeholder,
  onSingleClick,
  children,
  onRename,
  isRenaming: isRenamingManual,
  onRenamingChange,
  disabled,
  tooltipProps,
  triggerChildren,
}) => {
  const [isRenaming, setIsRenaming] = useState(!!isRenamingManual);
  const [newValue, setNewValue] = useState(value);
  const [inputWidth, setInputWidth] = useState(0);
  const childrenRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setNewValue(value);
  }, [value]);

  useEffect(() => {
    if (isRenamingManual != null) setIsRenaming(isRenamingManual);
  }, [isRenamingManual]);

  const handleRename = useCallback(() => {
    setIsRenaming(true);
    onRenamingChange?.(true);
  }, [onRenamingChange]);

  const { triggerClick } = useDoubleClick({
    onSingleClick: () => onSingleClick?.(),
    onDoubleClick: handleRename,
  });

  const handleBlur = useCallback(() => {
    setIsRenaming(false);
    onRenamingChange?.(false);

    const trimmed = newValue.trim();
    if (!trimmed) return;
    onRename(trimmed);
  }, [newValue, onRename, onRenamingChange]);

  useEffect(() => {
    const width = childrenRef.current?.offsetWidth;

    // Auto-resize the input to the width of the text
    // Math.ceil to remove flickering when the width is a decimal
    // Subtract 1 because if the text is 12.6px, offsetWidth will be 13px
    if (width) setInputWidth(Math.ceil(width) - 1);
  }, [newValue, value, isRenaming]);

  const renderTrigger = () => (
    // triggerContainer provides a larger clickable area than just the text
    <div className={styles.triggerContainer} onClick={() => !disabled && triggerClick()}>
      <span className={styles.trigger}>
        {newValue || placeholder}
        {triggerChildren}
      </span>
    </div>
  );

  return (
    <div className={cx(styles.editableText, className)}>
      {isRenaming && (
        <input
          autoFocus
          className={styles.input}
          onBlur={handleBlur}
          onChange={(e) => setNewValue(e.currentTarget.value)}
          onKeyUp={(e) => e.key === 'Enter' && handleBlur()}
          placeholder={placeholder}
          style={{ width: inputWidth }}
          value={newValue}
        />
      )}
      <div
        className={cx(styles.childrenContainer, { [styles.childrenHidden]: isRenaming })}
        ref={childrenRef}>
        {tooltipProps ? <Tooltip {...tooltipProps}>{renderTrigger()}</Tooltip> : renderTrigger()}
        {children}
      </div>
    </div>
  );
};
