import { useState } from 'react';
import produce from 'immer';
import cx from 'classnames';
import { capitalize } from 'utils/standard';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { GlobalStyleConfig } from 'globalStyles/types';
import { TEXT_SIZE_OFFSET_MAP } from 'globalStyles';
import ConfigSectionHeader from 'pages/dataPanelEditorPage/configSectionHeader';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import ColorPicker from './CustomStylesColorPicker';
import FlexBox, { VerticalAlignment } from 'components/core/FlexBox';
import FlexItem from 'components/core/FlexItem';
import DropdownSelect from 'shared/DropdownSelect';
import CollapsibleMenu from 'components/CollapsibleMenu';
import LineSelect from 'shared/LineSelect';
import CustomizedTag from './CustomizedTag';
import { SelectedDropdownInputItem } from 'constants/types';
import { getFontFamilyName } from 'globalStyles/helpers';

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    width: 55,
  },
  spacingInput: {
    marginRight: theme.spacing(3),
  },
  spacingHeading: {
    marginTop: theme.spacing(6),
  },
  backgroundColorSelection: {
    marginRight: theme.spacing(3),
  },
  actionsColorSelection: {
    marginLeft: theme.spacing(3),
  },
  inputLabel: {
    fontSize: 12,
    marginBottom: theme.spacing(1),
  },
  secondaryFontRow: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(5),
  },
  collapsibleMenu: {
    marginTop: theme.spacing(4),
  },
  collapsibleMenuColorPicker: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  dropdownInput: {
    minWidth: 0,
  },
}));

type Props = {
  styleConfig: GlobalStyleConfig;
  updateConfig: (newConfig: GlobalStyleConfig) => void;
  fontOptions: SelectedDropdownInputItem[];
};

export default function TextConfigSection(props: Props) {
  return (
    <>
      <ConfigSectionHeader title="Text" />
      <FontRow type="primary" {...props} />
      <FontRow type="secondary" {...props} />
      <ConfigSectionHeader isSubTitle title="Text Size" />
      <TextSizeInputs {...props} />
      <TextOverrideCollapsibleMenu {...props} />
    </>
  );
}

function FontRow({
  styleConfig,
  updateConfig,
  fontOptions,
  type,
}: Props & {
  type: 'primary' | 'secondary';
}) {
  const classes = useStyles();
  const fontType = type === 'primary' ? 'primaryFont' : 'secondaryFont';
  const fontColor = type === 'primary' ? 'primaryColor' : 'secondaryColor';
  const font = styleConfig.text[fontType];

  return (
    <FlexBox className={cx({ [classes.secondaryFontRow]: type === 'secondary' })}>
      <FlexItem className={classes.dropdownInput}>
        <div className={classes.backgroundColorSelection}>
          <div className={classes.inputLabel}>{`${capitalize(type)} Font`}</div>
          <DropdownSelect
            fillWidth
            showCancelBtn
            noSelectionText="Inherit"
            onCancelClick={() => {
              const newConfig = produce(styleConfig, (draft) => {
                draft.text[fontType] = undefined;
              });
              updateConfig(newConfig);
            }}
            onChange={(font) => {
              const newConfig = produce(styleConfig, (draft) => {
                draft.text[fontType] = font.id;
              });
              updateConfig(newConfig);
            }}
            options={fontOptions}
            selectedItem={
              font
                ? {
                    id: font,
                    name: font.split('.')[0],
                  }
                : undefined
            }
          />
        </div>
      </FlexItem>
      <FlexItem>
        <div className={classes.actionsColorSelection}>
          <div className={classes.inputLabel}>Fill</div>
          <ColorPicker
            fill
            color={styleConfig.text[fontColor]}
            onClose={(newColor) => {
              const newConfig = produce(styleConfig, (draft) => {
                draft.text[fontColor] = newColor;
              });
              updateConfig(newConfig);
            }}
          />
        </div>
      </FlexItem>
    </FlexBox>
  );
}

function TextSizeInputs({ styleConfig, updateConfig }: Props) {
  const classes = useStyles();

  return (
    <FlexBox verticalAlignment={VerticalAlignment.BOTTOM}>
      <InputWithBlurSave
        hideRightIconInteractions
        containerClassName={cx(classes.input, classes.spacingInput)}
        initialValue={`${styleConfig.text.textSize}px`}
        onNewValueSubmitted={(newTextSizeString) => {
          const newTextSize = Number(newTextSizeString.replace('px', ''));

          if (isNaN(newTextSize) || newTextSize < 1) return;

          const newConfig = produce(styleConfig, (draft) => {
            draft.text.textSize = newTextSize;
          });
          updateConfig(newConfig);
        }}
      />
      <FlexItem>
        <LineSelect
          fill
          breakpoints={[10, 12, 14, 16, 18]}
          labels={['Small', 'Moderate', 'Large']}
          setValue={(newTextSize) => {
            const newConfig = produce(styleConfig, (draft) => {
              draft.text.textSize = newTextSize;
            });
            updateConfig(newConfig);
          }}
          value={styleConfig.text.textSize}
        />
      </FlexItem>
    </FlexBox>
  );
}

function TextOverrideCollapsibleMenu(props: Props) {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  return (
    <CollapsibleMenu
      className={classes.collapsibleMenu}
      isExpanded={menuOpen}
      setIsExpanded={setMenuOpen}
      title="Custom text">
      <CollapsibleMenuRow isFirst override="h1" text="H1" {...props} />
      <CollapsibleMenuRow override="h2" text="Chart Titles" {...props} />
      <CollapsibleMenuRow override="kpiTitle" text="KPI Titles" {...props} />
      <CollapsibleMenuRow override="kpiValue" text="KPI Values" {...props} />
      <CollapsibleMenuRow override="tableColumnHeader" text="Table Column Headers" {...props} />
      <CollapsibleMenuRow override="body" text="Body" {...props} />
      <CollapsibleMenuRow override="smallHeading" text="Small Heading" {...props} />
      <CollapsibleMenuRow override="smallBody" text="Small Body" {...props} />
    </CollapsibleMenu>
  );
}

type CollapsibleMenuRowProps = {
  override: keyof GlobalStyleConfig['text']['overrides'];
  text: string;
  isFirst?: boolean;
};

function CollapsibleMenuRow({
  fontOptions,
  override,
  text,
  isFirst,
  styleConfig,
  updateConfig,
}: CollapsibleMenuRowProps & Props) {
  const classes = useStyles();
  const font = styleConfig.text.overrides[override]?.font;
  const size =
    styleConfig.text.overrides[override]?.size ||
    styleConfig.text.textSize + TEXT_SIZE_OFFSET_MAP[override];

  return (
    <>
      <FlexBox
        className={cx(classes.inputLabel, { [classes.collapsibleMenu]: !isFirst })}
        verticalAlignment={VerticalAlignment.CENTER}>
        <div>{text}</div>
        <CustomizedTag
          hidden={!styleConfig.text.overrides[override]}
          onCancel={() => {
            const newConfig = produce(styleConfig, (draft) => {
              draft.text.overrides[override] = undefined;
            });
            updateConfig(newConfig);
          }}
        />
      </FlexBox>
      <FlexBox verticalAlignment={VerticalAlignment.BOTTOM}>
        <FlexItem className={classes.dropdownInput}>
          <DropdownSelect
            fillWidth
            showCancelBtn
            noSelectionText="Inherit"
            onCancelClick={() => {
              const newConfig = produce(styleConfig, (draft) => {
                const overrideProperties = draft.text.overrides[override];
                if (overrideProperties) {
                  overrideProperties.font = undefined;
                }
              });
              updateConfig(newConfig);
            }}
            onChange={(font) => {
              const newConfig = produce(styleConfig, (draft) => {
                draft.text.overrides[override] = {
                  ...styleConfig.text.overrides[override],
                  font: font.id,
                };
              });
              updateConfig(newConfig);
            }}
            options={fontOptions}
            selectedItem={
              font
                ? {
                    id: font,
                    name: getFontFamilyName(font),
                  }
                : undefined
            }
          />
        </FlexItem>
        <FlexItem>
          <div className={classes.collapsibleMenuColorPicker}>
            <ColorPicker
              fill
              color={styleConfig.text.overrides[override]?.color || styleConfig.text.primaryColor}
              onClose={(newColor) => {
                const newConfig = produce(styleConfig, (draft) => {
                  draft.text.overrides[override] = {
                    ...styleConfig.text.overrides[override],
                    color: newColor,
                  };
                });
                updateConfig(newConfig);
              }}
            />
          </div>
        </FlexItem>
        <InputWithBlurSave
          hideRightIconInteractions
          containerClassName={cx(classes.input, classes.spacingInput)}
          initialValue={`${size}px`}
          onNewValueSubmitted={(newTextSizeString) => {
            const newTextSize = Number(newTextSizeString.replace('px', ''));

            if (isNaN(newTextSize) || newTextSize < 1) return;

            const newConfig = produce(styleConfig, (draft) => {
              draft.text.overrides[override] = {
                ...styleConfig.text.overrides[override],
                size: newTextSize,
              };
            });
            updateConfig(newConfig);
          }}
        />
      </FlexBox>
    </>
  );
}
