import { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { sortBy } from 'utils/standard';

import DraggableColumn from './DraggableColumn';
import SearchBar from 'shared/SearchBar';

import { DatasetSchema } from 'types/datasets';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: `0 ${theme.spacing(1)}px`,
    minHeight: 200,
    display: 'flex',
    flexDirection: 'column',
  },
  columns: {
    overflowY: 'scroll',
  },
  searchBar: {
    padding: `${theme.spacing(1)}px 0`,
  },
}));

type Props = {
  schema: DatasetSchema;
  loading?: boolean;
};

export default function VisualizationConfigTableColumns({ schema, loading }: Props) {
  const classes = useStyles();

  const [searchQuery, setSearchQuery] = useState('');
  const isSearching = searchQuery.trim() !== '';

  const filteredSchemas = isSearching
    ? schema.filter((col) => col.name.toLowerCase().includes(searchQuery.toLowerCase()))
    : schema;

  return (
    <div className={classes.root}>
      <SearchBar className={classes.searchBar} onChange={setSearchQuery} placeholder="Search..." />
      <div className={classes.columns}>
        {sortBy(filteredSchemas, 'name').map((col) => (
          <DraggableColumn
            disableDrag={loading}
            displayName={
              isSearching ? col.name.replaceAll(searchQuery, `<b>${searchQuery}</b>`) : col.name
            }
            key={col.name}
            name={col.name}
            type={col.type}
          />
        ))}
      </div>
    </div>
  );
}
