import { FC } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Button, sprinkles, IconButton } from 'components/ds';

import * as styles from './index.css';

type BaseProps = {
  /**
   * Specify a container element id to portal the content into.
   */
  portalContainerId?: string;
  /**
   * Determines size of modal
   */
  size: 'small' | 'medium' | 'large' | 'xlarge';
  /**
   * The title of the modal
   */
  title: string;
  /**
   * Event handler when back button is clicked
   */
  onBack?: () => void;
};

type FooterButtonProps = {
  /**
   * The action button text
   */
  text: string;
  /**
   * Determines whether the action button should be loading
   */
  loading?: boolean;
  /**
   * Determines whether the action button should be disabled
   */
  disabled?: boolean;
  /**
   * Event handler called when footer button is clicked.
   */
  onClick: () => void;
};

type FooterProps = {
  primaryButtonProps?: FooterButtonProps;
  secondaryButtonProps?: FooterButtonProps;
  tertiaryButtonProps?: FooterButtonProps;
};

type ExternalStateProps = {
  defaultOpen?: never;
  trigger?: never;
  /**
   * The controlled open state of the dialog. Must be used in conjunction with onOpenChange.
   */
  isOpen: boolean;
  /**
   * Event handler called when the dialog opens.
   */
  onOpen?: () => void;
  /**
   * Event handler called when the dialog closes.
   */
  onClose: () => void;
};

type InternalStateProps = {
  isOpen?: never;
  onOpen?: () => void;
  onClose?: () => void;
  /**
   * The open state of the dialog when it is initially rendered. Use when you do not need to control its open state.
   */
  defaultOpen?: boolean;
  /**
   * Trigger to open and close modal
   */
  trigger: JSX.Element;
};

type InteractionProps = ExternalStateProps | InternalStateProps;

export type Props = BaseProps & InteractionProps & FooterProps;

export const Modal: FC<Props> = ({
  isOpen,
  portalContainerId,
  size,
  title,
  children,
  defaultOpen = false,
  trigger,
  primaryButtonProps,
  secondaryButtonProps,
  tertiaryButtonProps,
  onBack,
  onOpen,
  onClose,
}) => {
  const header = (
    <Dialog.Title className={styles.modalHeader}>
      <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp.5' })}>
        {onBack ? (
          <IconButton data-testid="modal-back-button" name="arrow-left" onClick={onBack} />
        ) : undefined}
        {title}
      </div>
      <Dialog.Close asChild>
        <IconButton data-testid="modal-close-button" name="cross" />
      </Dialog.Close>
    </Dialog.Title>
  );

  const footer = (
    <div className={styles.dialogFooter}>
      {tertiaryButtonProps ? (
        <Dialog.Close asChild>
          <Button data-testid="modal-tertiary-button" type="tertiary" {...tertiaryButtonProps}>
            {tertiaryButtonProps.text}
          </Button>
        </Dialog.Close>
      ) : null}
      {secondaryButtonProps ? (
        <Dialog.Close asChild>
          <Button data-testid="modal-secondary-button" type="secondary" {...secondaryButtonProps}>
            {secondaryButtonProps.text}
          </Button>
        </Dialog.Close>
      ) : null}
      {primaryButtonProps ? (
        <Dialog.Close asChild>
          <Button data-testid="modal-primary-button" type="primary" {...primaryButtonProps}>
            {primaryButtonProps.text}
          </Button>
        </Dialog.Close>
      ) : null}
    </div>
  );

  const content = (
    <div className={sprinkles({ flexItems: 'center' })}>
      <Dialog.Overlay className={styles.dialogOverlay} />
      <Dialog.Content className={styles.dialogContent({ size })}>
        {header}
        <div className={styles.childContent({ size })}>{children}</div>
        {footer}
      </Dialog.Content>
    </div>
  );

  return (
    <Dialog.Root
      defaultOpen={defaultOpen}
      onOpenChange={(open) => {
        if (open && onOpen) onOpen();
        else if (!open && onClose) onClose();
      }}
      open={isOpen}>
      {trigger ? <Dialog.Trigger asChild>{trigger}</Dialog.Trigger> : null}
      {portalContainerId ? (
        <Dialog.Portal container={document.getElementById(portalContainerId)}>
          {content}
        </Dialog.Portal>
      ) : (
        content
      )}
    </Dialog.Root>
  );
};
