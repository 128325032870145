import { makeStyles, Theme } from '@material-ui/core';

import { ConfigSection } from 'components/resource/ConfigSection';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.ds.grey200,
    marginBottom: theme.spacing(3),
    borderRadius: 4,
    border: `1px solid ${theme.palette.ds.grey300}`,
  },
  configSection: ({ isArchitectCustomerDashboard }: { isArchitectCustomerDashboard: boolean }) => ({
    padding: isArchitectCustomerDashboard ? theme.spacing(2) : theme.spacing(3),
    paddingTop: isArchitectCustomerDashboard ? undefined : theme.spacing(1),
    paddingBottom: 0,
  }),
}));

type Props = {
  children: JSX.Element | (JSX.Element | null)[];
  defaultIsOpen?: boolean;
  isArchitectCustomerDashboard: boolean;
  title: string;

  toggleOn?: boolean;
  onToggle?: () => void;
};

export default function FormatSection({
  children,
  isArchitectCustomerDashboard,
  title,
  defaultIsOpen,
  toggleOn,
  onToggle,
}: Props): JSX.Element {
  const classes = useStyles({ isArchitectCustomerDashboard });
  return (
    <div className={isArchitectCustomerDashboard ? undefined : classes.container}>
      <ConfigSection
        defaultIsOpen={defaultIsOpen ?? false}
        isArchitectCustomerDashboard={isArchitectCustomerDashboard}
        onToggle={onToggle}
        title={title}
        toggleOn={toggleOn}>
        <div className={classes.configSection}>{children}</div>
      </ConfigSection>
    </div>
  );
}
