import { Layout } from '@explo-tech/react-grid-layout';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from 'utils/standard';

import {
  CanvasCustomComponent,
  CanvasFilter,
  CanvasTemplate,
  CanvasVersionConfig,
} from 'actions/canvasConfigActions';
import { ArchitectCustomerDashboard } from 'actions/architectCustomerDashboardActions';
import {
  CustomComponents,
  ArchitectCustomerDashboardConfig,
  EndUserDataPanel,
} from 'actions/architectCustomerDashboardConfigActions';
import {
  defaultDataPanelDimensions,
  DRAGGING_ITEM_CONFIG_BY_TYPE,
} from 'constants/dashboardConstants';
import { DashboardElement, DASHBOARD_ELEMENT_TYPES } from 'types/dashboardTypes';
import { Customer } from 'actions/teamActions';
import { removeUnderscoreFields } from './dashboardUtils';
import {
  canvasComponentsToDashboardElements,
  canvasFiltersToDashboardElements,
} from './canvasConfigUtils';
import { DRILLDOWN_DATA_PANEL_ID } from 'reducers/dashboardEditConfigReducer';

export function getArchitectCustomerDashboardElements(
  config: ArchitectCustomerDashboardConfig,
  canvasConfig: CanvasVersionConfig,
): DashboardElement[] {
  const filtersInDashboard: CanvasFilter[] = [];
  const elementsInDashboard: CanvasCustomComponent[] = [];

  config.filters.forEach((filterId) => {
    const filter = canvasConfig.filters[filterId];
    if (filter) filtersInDashboard.push(filter);
  });

  Object.entries(config.custom_components ?? {}).forEach(([layoutId, componentId]) => {
    const component = canvasConfig.customComponents?.[componentId];
    if (component) elementsInDashboard.push({ ...component, id: layoutId });
  });

  return canvasFiltersToDashboardElements(filtersInDashboard).concat(
    canvasComponentsToDashboardElements(elementsInDashboard),
  );
}

export function prepareConfigForCopy(
  configToCopy: ArchitectCustomerDashboardConfig,
): ArchitectCustomerDashboardConfig {
  const newIdMapping: Record<string, string> = {};
  const dataPanels: Record<string, EndUserDataPanel> = {};

  const config = cloneDeep(configToCopy);

  Object.values(config.data_panels).forEach((dataPanel) => {
    if (dataPanel.id === DRILLDOWN_DATA_PANEL_ID) return;

    const newId = uuidv4();
    newIdMapping[dataPanel.id] = newId;

    removeUnderscoreFields(dataPanel);
    dataPanel.id = newId;
    dataPanels[newId] = dataPanel;
  });
  config.data_panels = dataPanels;

  config.layout.forEach((layoutElem) => {
    const newId: string | undefined = newIdMapping[layoutElem.i];
    if (newId !== undefined) layoutElem.i = newId;
  });

  return config;
}

export function convertCanvasVersionToArchitectCustomerDashboard(
  groupId: number,
  config: CanvasVersionConfig,
  name: string,
  modified: string,
): ArchitectCustomerDashboard {
  const elemIdsInLayout = new Set(config.default_layout.map((elem) => elem.i));

  const newIdMapping: Record<string, string> = {};
  const dataPanels: Record<string, EndUserDataPanel> = {};
  let nextNum = 0;
  Object.values(config.templates).forEach((template) => {
    if (!elemIdsInLayout.has(template.id)) return;
    nextNum += 1;

    const newId = uuidv4();
    dataPanels[newId] = convertTemplateToDataPanel(template, newId, nextNum);
    newIdMapping[template.id] = newId;
  });

  const filters: string[] = [];
  Object.values(config.filters).forEach((filter) => {
    if (!elemIdsInLayout.has(filter.id)) return;
    filters.push(filter.id);
  });

  const customComponents: CustomComponents = {};
  Object.values(config.customComponents ?? {}).forEach((comp) => {
    if (!elemIdsInLayout.has(comp.id)) return;

    const newId = uuidv4();
    customComponents[newId] = comp.id;
    newIdMapping[comp.id] = newId;
  });

  const layout = cloneDeep(config.default_layout);
  layout.forEach((layoutElem) => {
    const newId: string | undefined = newIdMapping[layoutElem.i];
    if (newId !== undefined) layoutElem.i = newId;
  });

  const configuration: ArchitectCustomerDashboardConfig = {
    data_panels: dataPanels,
    filters,
    layout,
    custom_components: customComponents,
  };

  return {
    id: null,
    group_id: groupId,
    user_id: null,
    name,
    configuration,
    modified,
  };
}

export const convertTemplateToDataPanel = (
  template: CanvasTemplate,
  id: string,
  nextNum: number,
): EndUserDataPanel => {
  return {
    id,
    provided_id: `chart_${nextNum}`,
    dataset_id: template.dataset_id,
    template_id: template.id,
    visualize_op: template.visualize_op,
    filter_op: template.filter_op,
  };
};

export const addElementToLayout = (
  layout: Layout[],
  elemId: string,
  elemType?: DASHBOARD_ELEMENT_TYPES,
): void => {
  const { w, h } = elemType ? DRAGGING_ITEM_CONFIG_BY_TYPE[elemType] : defaultDataPanelDimensions;
  layout.forEach((elem) => (elem.y += h));
  layout.push({ y: 0, x: 0, w, h, i: elemId });
};

export const doesCustomerHaveInvalidAccessGroup = (
  customer: Customer,
  accessGroupIds: Set<number>,
) => !accessGroupIds.has(customer.access_group_id);

// Pre multi dashboard, dashboard names were empty. Can probably remove in future
export const getArchitectCustomerDashboardName = (dashboard: ArchitectCustomerDashboard): string =>
  dashboard.name || 'Dashboard';
