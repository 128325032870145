import { FC, MouseEvent as ReactMouseEvent, forwardRef } from 'react';
import { Props as TooltipProps } from 'components/ds/Tooltip';

import { Button } from 'components/ds';
import { IconName } from 'components/ds/Icon';

export type Props = {
  /**
   * Optional class name for style overrides
   */
  className?: string;
  /**
   * Icon Buttons don't have children because they just render the icon
   */
  children?: never;
  /**
   * Optional test ID for selecting component in tests
   */
  'data-testid'?: string;
  /**
   * If true, the button cannot be clicked.
   */
  disabled?: boolean;
  /**
   * Name of Icon to use for button
   */
  name: IconName;
  /**
   * Whether or not the button shows a loading state
   */
  loading?: boolean;
  /**
   * Callback to be fired when the button is clicked
   */
  onClick?: (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void;
  /**
   * Optionally show a tooltip on hover
   */
  tooltipProps?: TooltipProps;
  /**
   * Determines style of the icon button
   */
  type?: 'primary' | 'secondary' | 'tertiary' | 'destructive';
};

export const IconButton: FC<Props> = forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>(
  (
    {
      className,
      'data-testid': testId,
      disabled,
      name,
      loading,
      onClick,
      tooltipProps,
      type,
      ...props
    },
    ref,
  ) => {
    // @ts-ignore Radix sends in type button when used as asChild
    const buttonType = type === 'button' ? undefined : type;
    return (
      <Button
        {...props}
        className={className}
        data-testid={testId}
        disabled={disabled}
        icon={name}
        loading={loading}
        onClick={onClick}
        parentRef={ref}
        tooltipProps={tooltipProps}
        type={buttonType ?? 'tertiary'}
      />
    );
  },
);

IconButton.displayName = 'IconButton';
