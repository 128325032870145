import { makeStyles, Theme } from '@material-ui/core/styles';

import {
  DashboardElement,
  DashboardVariable,
  DashboardVariableMap,
  DASHBOARD_ELEMENT_TYPES,
} from 'types/dashboardTypes';
import InputLabel from 'shared/InputLabel';
import { DashboardParam } from 'types/dashboardVersionConfig';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { Customer } from 'actions/teamActions';
import { COLOR_CATEGORY_FILTER_SUFFIX } from 'constants/dashboardConstants';
import { OPERATION_TYPES } from 'constants/types';
import { getListOfExtraVarsForElement } from 'utils/extraVariableUtils';

const useStyles = makeStyles((theme: Theme) => ({
  variablesContainer: {
    fontFamily: 'Source Code Pro',
    overflow: 'auto',
    padding: theme.spacing(3),
  },
  variable: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5),
    borderRadius: 2,

    '&:hover': {
      backgroundColor: theme.palette.ds.lightBlue,
    },
  },
  variableName: {
    fontSize: 12,
  },
  variableValue: {
    fontSize: 12,
    textAlign: 'right',
  },
  sectionContainer: {
    padding: `${theme.spacing(2)}px 0`,
  },
  sectionHeader: {
    color: theme.palette.ds.grey700,
    fontSize: 12,
    textTransform: 'uppercase',
    marginLeft: 6,
    marginBottom: theme.spacing(1),
    fontWeight: 500,
  },
}));

type Props = {
  variables: DashboardVariableMap;
  dashboardInputElements: DashboardElement[];
  dataPanelsWithDrilldown: DataPanelTemplate[];
  dataPanelsWithColorCategories: DataPanelTemplate[];
  selectedUserGroup: Customer | null;
  dashboardParams: Record<string, DashboardParam>;
};

export default function RawVariableList({
  variables,
  dashboardInputElements,
  dataPanelsWithDrilldown,
  dataPanelsWithColorCategories,
  selectedUserGroup,
  dashboardParams,
}: Props) {
  const classes = useStyles();

  const viewVariable = (variableName: string, value: DashboardVariable, toStringify = true) => {
    const val = toStringify ? JSON.stringify(value) : value;
    return (
      <div className={classes.variable} key={variableName}>
        <div className={classes.variableName}>{variableName}</div>
        <div className={classes.variableValue}>{val}</div>
      </div>
    );
  };

  return (
    <div className={classes.variablesContainer}>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeader}>
          <InputLabel
            fakeLabel={false}
            helpText='"user_group" variables are deprecated and have been replaced by "customer" variables'
            text="Customer"
          />
        </div>
        {viewVariable('customer_id', selectedUserGroup?.provided_id)}
        {viewVariable('customer_name', selectedUserGroup?.name)}

        {selectedUserGroup?.properties
          ? Object.entries(selectedUserGroup.properties).map(([key, val]) => {
              return viewVariable(`customer.${key}`, val);
            })
          : null}
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeader}>Dashboard Elements</div>
        {dashboardInputElements.map((element) => {
          const value = variables[element.name];

          if (value && element.element_type === DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER) {
            const valueObj = value as Record<string, DashboardVariable>;
            return (
              <div key={`variable-${element.name}`}>
                {Object.keys(valueObj).map((key) =>
                  viewVariable(`${element.name}.${key}`, valueObj[key]),
                )}
              </div>
            );
          }

          return [
            viewVariable(element.name, value),
            getListOfExtraVarsForElement(element.name, element.element_type).map((extraVar) => {
              const extraValue = variables[extraVar];
              return extraValue !== undefined ? viewVariable(extraVar, extraValue) : null;
            }),
          ];
        })}
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeader}>Data Panels</div>
        {dataPanelsWithColorCategories.map((dpt) => {
          const varName = dpt.provided_id + COLOR_CATEGORY_FILTER_SUFFIX;
          const value = variables[varName];
          return viewVariable(varName, value ? JSON.stringify(value) : '', false);
        })}
        {dataPanelsWithDrilldown.map((dpt) => {
          const dptId = dpt.provided_id;
          const value = variables[dptId];
          if (!value || dpt.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_TABLE) {
            //Why is value empty??
            return viewVariable(dptId, value, false);
          }

          const valueObj = value as Record<string, DashboardVariable>;

          return Object.keys(valueObj).map((key) => viewVariable(`${dptId}.${key}`, valueObj[key]));
        })}
      </div>

      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeader}>Custom Variables</div>

        {Object.values(dashboardParams).map((param) => {
          const value = variables[param.name];
          return viewVariable(param.name, value);
        })}
      </div>
    </div>
  );
}
