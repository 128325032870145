import { FC, useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import cx from 'classnames';
import * as RD from 'remotedata';

import * as styles from '../styles.css';
import { SideSheet, sprinkles } from 'components/ds';
import { GettingStartedBody } from 'pages/ConnectDataSourceFlow/StepPages/GettingStarted';
import PanelButton from 'shared/PanelButton';
import { showErrorToast, showSuccessToast, showWarningToast } from 'shared/sharedToasts';
import { DBConnectionConfig } from 'pages/ConnectDataSourceFlow/types';
import { EnterCredentialsBody } from 'pages/ConnectDataSourceFlow/StepPages/EnterCredentials';
import { SecurityConfigurationBody } from 'pages/ConnectDataSourceFlow/StepPages/SecurityConfiguration';
import { Jobs } from 'components/JobQueue/types';
import Poller from 'components/JobQueue/Poller';
import { AlertModal } from 'components/ds';

import {
  editDataSource,
  DataSource,
  fetchSupportedDataSources,
  testUpdatedDataSourceConnection,
  DataSourceConfiguration,
  TestDataSourceConnectionData,
} from 'actions/dataSourceActions';
import { bulkEnqueueJobs, JobDefinition } from 'actions/jobQueueActions';
import { ACTION } from 'actions/types';
import { sendPing } from 'actions/pingActions';
import { ReduxState } from 'reducers/rootReducer';
import { TOAST_TIMEOUT } from '../constants';
import { PingTypes } from 'constants/types';
import { dataSourceByType } from 'constants/dataSourceConstants';
import { parseJsonFields } from 'utils/general';
import { some } from 'utils/standard';
import { hasValidConfiguration, hasConfigUpdates } from '../utils';

type Props = {
  dataSource: DataSource;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
};

export const ManageDataSourceSideSheet: FC<Props> = ({ dataSource, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();

  const { dataSources, currentUser, parentSchemas, supportedDataSources, team } = useSelector(
    (state: ReduxState) => ({
      parentSchemas: state.parentSchemas.usedParentSchemas,
      dataSources: state.dataSourceList.dataSources,
      currentUser: state.currentUser,
      team: state.teamData.data,
      supportedDataSources: state.supportedDataSources,
    }),
    shallowEqual,
  );

  const [connectionStatus, setConnectionStatus] = useState<
    RD.ResponseData<TestDataSourceConnectionData>
  >(RD.Idle());
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [awaitedJobs, setAwaitedJobs] = useState<Record<string, Jobs>>({});
  const [config, setConfig] = useState<DBConnectionConfig>({});
  const [accessGroupUpdates, setAccessGroupUpdates] = useState<number[]>();
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);

  useEffect(() => {
    if (Object.keys(supportedDataSources).length === 0) {
      dispatch(fetchSupportedDataSources());
    }
  }, [dispatch, supportedDataSources]);

  const supportedDataSource = useMemo(
    () => supportedDataSources.dataSources?.find((ds) => ds.type === dataSource.source_type),
    [supportedDataSources, dataSource],
  );

  const parentSchema = useMemo(
    () => parentSchemas?.find((schema) => schema.id === dataSource.parent_schema_id),
    [parentSchemas, dataSource],
  );
  const newName = config.name !== undefined ? config.name : dataSource.name;

  const updateConfig = (newConfig: DBConnectionConfig) => {
    setConfig(newConfig);
    setConnectionStatus(RD.Idle());
  };

  const bulkEnqueueJobsWrapper = useCallback(
    (jobs: JobDefinition[]) => {
      if (jobs.length === 0) return;

      const jobMap = Object.assign({}, ...jobs.map((job) => ({ [uuidv4()]: job })));

      dispatch(
        bulkEnqueueJobs({ jobs: jobMap }, (jobs) => {
          setAwaitedJobs(jobs);
        }),
      );
    },
    [dispatch],
  );

  const onTestingSuccess = (data: TestDataSourceConnectionData) => {
    setConnectionStatus(RD.Success(data));
    if (data.num_tables > 0) {
      showSuccessToast(
        `Data source successfully connects with ${data.num_tables} tables. Click 'Update' to save these changes.`,
        TOAST_TIMEOUT,
      );
    } else {
      showWarningToast(
        `Data source successfully connects, but it has 0 tables. Click 'Update' to save these changes if this is intended.`,
        TOAST_TIMEOUT,
      );
    }
    // Ping #bd-ping-testing-datasource that a test connection was successful.
    sendPing({
      postData: {
        message: `${currentUser.first_name} ${currentUser.last_name} (${currentUser.email}) on team "${currentUser.team?.team_name}" successfully re-tested an updated data source connection (name: "${newName}" and type "${dataSource.source_type}") with ${data.num_tables} tables`,
        message_type: PingTypes.PING_DATASOURCE_TESTING,
      },
    });
  };

  const onTestingFailure = (errorMessage: string | undefined) => {
    setConnectionStatus(RD.Idle());
    showErrorToast('Testing Failure' + (errorMessage ? `: ${errorMessage}` : ''));
    // ping #bd-ping-testing-datasource that a test connection failed.
    sendPing({
      postData: {
        message: `<!channel> ${currentUser.first_name} ${currentUser.last_name} (${currentUser.email}) on team "${currentUser.team?.team_name}" tried to re-test an updated data source connection that ERRORED (name: "${newName}" and type "${dataSource.source_type}")`,
        message_type: PingTypes.PING_DATASOURCE_TESTING,
      },
    });
  };

  const testConnectionCredentials = (parsedConfig: DataSourceConfiguration) => {
    if (accessGroupUpdates?.length === 0) {
      setConnectionStatus(RD.Idle());
      showErrorToast(`At least one visibility group must be selected.`);
      return;
    }

    if (accessGroupUpdates) {
      const removingDefaultDataSource = some(team?.access_groups, (accessGroup) => {
        const isDefaultDataSource = accessGroup.default_data_source_ids.includes(dataSource.id);
        return isDefaultDataSource && !accessGroupUpdates.includes(accessGroup.id);
      });
      if (removingDefaultDataSource) {
        setConnectionStatus(RD.Idle());
        showErrorToast(`You cannot remove a visibility group from its default data source.`);
        return;
      }
    }

    const postData = {
      name: config.name,
      configuration: parsedConfig,
      id: dataSource.id,
    };

    if (!currentUser.team?.feature_flags.use_job_queue) {
      dispatch(
        testUpdatedDataSourceConnection(
          { postData },
          (data) => onTestingSuccess(data),
          (response) => onTestingFailure(response.error_msg),
        ),
      );
    } else {
      bulkEnqueueJobsWrapper([
        {
          job_type: ACTION.TEST_UPDATED_DATA_SOURCE_CONNECTION,
          job_args: postData,
          onSuccess: (data) => onTestingSuccess(data),
          onError: (errorMessage) => onTestingFailure(errorMessage),
        } as JobDefinition,
      ]);
      sendPing({
        postData: {
          message: `${currentUser.first_name} ${currentUser.last_name} (${currentUser.email}) on team "${currentUser.team?.team_name}" is trying to test an updated data source connection (name: "${dataSource.name}" and type "${dataSource.source_type}") using the job queue`,
          message_type: PingTypes.PING_DATASOURCE_TESTING,
        },
      });
    }
  };

  const onDiscard = () => {
    updateConfig({});
    setConnectionStatus(RD.Idle());
    setAccessGroupUpdates(undefined);
  };

  const hasUpdates = useMemo(() => {
    return hasConfigUpdates(config) || accessGroupUpdates !== undefined;
  }, [config, accessGroupUpdates]);

  const hasValidConfig = useMemo(() => {
    const properties = supportedDataSource?.configuration_schema.properties;
    return properties ? hasValidConfiguration(config, properties) : false;
  }, [config, supportedDataSource]);

  const handleCloseAttempt = () => {
    if (hasUpdates) {
      setShowConfirmationModal(true);
    } else {
      onDiscard();
      setShowConfirmationModal(false);
      setIsOpen(false);
    }
  };

  const onUpdate = () => {
    if (supportedDataSource === undefined) {
      showErrorToast('Data source metadata undefined.', TOAST_TIMEOUT);
      return;
    }

    if (RD.isLoading(connectionStatus)) return;

    const { parsedConfig, error } = parseJsonFields(
      supportedDataSource,
      config?.dataSourceConfig || {},
    );

    if (error !== undefined) {
      setConnectionStatus(RD.Idle());
      showErrorToast('Parsing the credentials failed.', TOAST_TIMEOUT);
      return;
    }

    if (!RD.isSuccess(connectionStatus)) {
      setConnectionStatus(RD.Loading());
      testConnectionCredentials(parsedConfig);
      return;
    }

    const dataSourceUpdatesTemp = {
      name: config.name !== '' ? config.name : undefined,
      provided_id: config.providedId !== '' ? config.providedId : undefined,
      credentials: parsedConfig,
      access_group_ids: accessGroupUpdates,
    };
    setIsUpdateLoading(true);
    dispatch(
      editDataSource(
        { id: dataSource.id, postData: dataSourceUpdatesTemp },
        () => {
          showSuccessToast(`${newName} successfully updated.`, TOAST_TIMEOUT);
          setIsUpdateLoading(false);
          onDiscard();
          setIsOpen(false);
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e: any) => {
          setIsUpdateLoading(false);

          showErrorToast(
            `${newName} failed to update.` + (e?.detail ? ` Error: ${e.detail}` : ''),
            TOAST_TIMEOUT,
          );
        },
      ),
    );
  };

  const renderManageDataSource = () => {
    return (
      <div className={styles.sideSheetContent}>
        <div className={styles.section}>
          <GettingStartedBody
            isEditing
            accessGroups={team?.access_groups}
            config={config}
            existingDataSources={dataSources ?? []}
            headerClassName={styles.sectionHeader}
            placeholderName={dataSource.name}
            placeholderProvidedId={dataSource.provided_id}
            selectedAccessGroupIds={accessGroupUpdates ?? dataSource?.access_groups ?? []}
            setSelectedAccessGroupIds={(newAccessGroupIds) => {
              setAccessGroupUpdates(newAccessGroupIds);
              setConnectionStatus(RD.Idle());
            }}
            updateConfig={updateConfig}
          />
        </div>
        {supportedDataSource ? (
          <>
            <div className={styles.section}>
              <div className={styles.sectionHeader}>Database Type</div>
              <PanelButton
                disabled
                selected
                imgUrl={dataSourceByType[dataSource.source_type].datasourceIconImg}
                text={dataSource.name}
              />
            </div>

            <div className={styles.section}>
              <div className={styles.sectionHeader}>Credentials</div>
              <EnterCredentialsBody
                config={config}
                headerClassName={cx(
                  sprinkles({ marginBottom: 'sp3', marginTop: 'sp7' }),
                  styles.sectionHeader,
                )}
                selectedDataSource={supportedDataSource}
                updateConfig={updateConfig}
                userViewableCredentials={dataSource.user_viewable_credentials}
              />
            </div>
            <div className={styles.section}>
              <div className={styles.sectionHeader}>Security</div>
              <SecurityConfigurationBody
                config={config}
                headerClassName={sprinkles({ heading: 'h4', marginBottom: 'sp2' })}
                selectedDataSource={supportedDataSource}
                updateConfig={updateConfig}
                userViewableCredentials={dataSource.user_viewable_credentials}
              />
            </div>
          </>
        ) : (
          <div>
            Error: A schema type must be selected for this data source before credentials can be
            edited.
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <SideSheet
        breadcrumbs={[parentSchema?.name ?? String(dataSource.parent_schema_id)]}
        className={styles.sidesheet}
        isOpen={isOpen}
        onClickOutside={handleCloseAttempt}
        onCloseClick={handleCloseAttempt}
        primaryButtonProps={{
          onClick: onUpdate,
          disabled: !hasUpdates || !hasValidConfig,
          text: RD.isSuccess(connectionStatus) ? 'Update' : 'Test',
          loading: RD.isLoading(connectionStatus) || isUpdateLoading,
        }}
        secondaryButtonProps={{
          onClick: onDiscard,
          disabled: !hasUpdates,
        }}
        title={dataSource.name}>
        {renderManageDataSource()}
      </SideSheet>

      <AlertModal
        actionButtonProps={{
          text: 'Discard Changes',
          onClick: () => {
            onDiscard();
            setShowConfirmationModal(false);
            setIsOpen(false);
          },
        }}
        cancelButtonProps={{ text: 'Keep Editing' }}
        isOpen={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        title="Do you want to discard your changes?"
      />
      <Poller
        awaitedJobs={awaitedJobs}
        updateJobResult={(finishedJobIds, onComplete) => {
          if (finishedJobIds.length > 0) setAwaitedJobs({});
          onComplete();
        }}
      />
    </>
  );
};
