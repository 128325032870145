import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme, Theme } from '@material-ui/core';
import { Icon } from '@blueprintjs/core';
import { Link } from 'react-router-dom';

import { Button, Intent, Tag, sprinkles, AlertModal } from 'components/ds';
import DropdownSelect from 'shared/DropdownSelect';
import { SelectValuesConfig } from 'components/FilterConfigs/SelectFilter/ValuesConfig';
import { SelectFilterTypeConfig } from 'components/FilterConfigs/SelectFilter/FilterTypeConfig';
import { SelectDefaultValueConfig } from 'components/FilterConfigs/SelectFilter/DefaultValueConfig';
import { SelectAdjustDisplayConfig } from 'components/FilterConfigs/SelectFilter/AdjustDisplayConfig';
import { SelectEditInteractionsConfig } from 'components/FilterConfigs/SelectFilter/EditInteractionsConfig';
import { DateAdjustDisplayConfig } from 'components/FilterConfigs/DateFilter/AdjustDisplayConfig';
import { DateFilterTypeConfig } from 'components/FilterConfigs/DateFilter/FilterTypeConfig';
import { DateValuesConfig } from 'components/FilterConfigs/DateFilter/ValuesConfig';
import { DateDefaultValuesConfig } from 'components/FilterConfigs/DateFilter/DefaultValueConfig';
import { DateEditInteractionsConfig } from 'components/FilterConfigs/DateFilter/EditInteractionsConfig';
import { ColumnHeader } from 'components/resource/ColumnHeader';
import { MetadataInputs } from 'components/resource/MetadataInputs';
import { ConfigSection } from 'components/resource/ConfigSection';

import {
  CanvasFilter,
  setCanvasFilterType,
  deleteCanvasFilter,
  updateCanvasFilter,
  updateCanvasFilterConfig,
  CanvasFilterType,
  CanvasDataset,
  CanvasFilterOptions,
} from 'actions/canvasConfigActions';
import { DashboardVariable, DASHBOARD_ELEMENT_TYPES } from 'types/dashboardTypes';
import { sharedStyles } from '../sharedStyles';
import { SELECT_ELEMENT_SET } from 'constants/dashboardConstants';
import { ReduxState } from 'reducers/rootReducer';
import { canvasFilterHasError } from 'utils/canvasConfigUtils';

const useStyles = makeStyles((theme: Theme) => ({
  selectFilterInput: {
    '& .bp3-button': {
      border: 'none',
    },
  },
  datasetContent: {
    display: 'flex',
    alignItems: 'center',

    '& > p': {
      marginBottom: 0,
      marginRight: theme.spacing(2),
    },
  },
}));

type Props = {
  datasets: Record<string, CanvasDataset>;
  filter: CanvasFilter;
  isNewFilter: boolean;
  referencedFilters: Record<string, string[]>;

  resetFilterRows: () => void;
  setVariable: (variable: string, value: DashboardVariable) => void;
};

export default function FilterConfig({
  filter,
  referencedFilters,
  datasets,
  isNewFilter,
  resetFilterRows,
  setVariable,
}: Props): JSX.Element {
  const classes = useStyles();
  const sharedClasses = sharedStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const currentCanvasId = useSelector((state: ReduxState) => state.canvas.currentCanvasId);

  const [deleteFilterModalOpen, setDeleteFilterModalOpen] = useState(false);

  const { description, id, filter_info: filterInfo, name, provided_id } = filter;

  const updateConfig = (updatedFilterInfo: CanvasFilterType, reQueryRows = false) => {
    dispatch(updateCanvasFilterConfig({ filterId: filter.id, filterInfo: updatedFilterInfo }));
    if (reQueryRows) resetFilterRows();
  };

  const renderFilterType = () => {
    let filterTypeComponent;
    switch (filterInfo?.filter_type) {
      case DASHBOARD_ELEMENT_TYPES.DROPDOWN:
      case DASHBOARD_ELEMENT_TYPES.MULTISELECT:
      case DASHBOARD_ELEMENT_TYPES.TOGGLE:
        filterTypeComponent = (
          <SelectFilterTypeConfig
            elementName={filter.provided_id}
            elementType={filterInfo.filter_type}
            updateType={(newType) => {
              setVariable(filter.provided_id, undefined);
              updateConfig({ ...filterInfo, filter_type: newType });
            }}
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.DATEPICKER:
      case DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER:
        filterTypeComponent = (
          <DateFilterTypeConfig
            config={filterInfo.config}
            dateFilterType={filterInfo.filter_type}
            elementName={filter.provided_id}
            updateDateConfig={(newConfig) => updateConfig({ ...filterInfo, config: newConfig })}
            updateType={(newType) => {
              setVariable(filter.provided_id, undefined);
              updateConfig({ ...filterInfo, filter_type: newType });
            }}
          />
        );
    }
    return filterTypeComponent ? (
      <div className={sprinkles({ marginTop: 'sp1.5' })}>{filterTypeComponent}</div>
    ) : null;
    null;
  };

  const renderFilterValues = () => {
    let valuesComponent;

    switch (filterInfo?.filter_type) {
      case DASHBOARD_ELEMENT_TYPES.DROPDOWN:
      case DASHBOARD_ELEMENT_TYPES.MULTISELECT:
      case DASHBOARD_ELEMENT_TYPES.TOGGLE:
        valuesComponent = (
          <SelectValuesConfig
            config={filterInfo.config}
            datasets={datasets}
            selectType={filterInfo.filter_type}
            updateSelectConfig={(newConfig, reQueryRows) =>
              updateConfig({ ...filterInfo, config: newConfig }, reQueryRows)
            }
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER:
      case DASHBOARD_ELEMENT_TYPES.DATEPICKER:
        valuesComponent = (
          <DateValuesConfig
            config={filterInfo.config}
            dateFilterType={filterInfo.filter_type}
            updateDateConfig={(newConfig) => updateConfig({ ...filterInfo, config: newConfig })}
          />
        );
    }

    return valuesComponent ? (
      <ConfigSection title="Set the filter values">
        <div className={sharedClasses.configSection}>{valuesComponent}</div>
      </ConfigSection>
    ) : null;
  };

  const renderReferencedDatasets = () => {
    const datasetList = Object.values(datasets);
    if (datasetList.length === 0) return;

    const datasetsUsingFilter = new Set(referencedFilters[filter.provided_id] || []);

    return (
      <ConfigSection defaultIsOpen title="Update query with filter">
        <div className={sharedClasses.configSection} style={{ paddingBottom: theme.spacing(4) }}>
          <p className={sharedClasses.sectionText}>
            In order for the filters to affect charts based off of a certain dataset, you&rsquo;ll
            need to reference the filter within your query.
          </p>
          {datasetList.map((dataset) => (
            <Link
              className={sharedClasses.linkedItem}
              key={dataset.id}
              to={`/blueprint/${currentCanvasId}/datasets?id=${dataset.id}`}>
              <div className={classes.datasetContent}>
                <div className={sharedClasses.linkedItemName}>{dataset.name}</div>
              </div>
              <div className={classes.datasetContent}>
                {datasetsUsingFilter.has(dataset.id) ? (
                  <Tag intent={Intent.ACTIVE}>Referenced</Tag>
                ) : null}
                <Icon
                  className={sharedClasses.linkedItemIcon}
                  color={theme.palette.ds.grey800}
                  icon="arrow-top-right"
                />
              </div>
            </Link>
          ))}
        </div>
      </ConfigSection>
    );
  };

  const renderDefaultValue = () => {
    let defaultValueComponent;
    switch (filterInfo?.filter_type) {
      case DASHBOARD_ELEMENT_TYPES.DROPDOWN:
      case DASHBOARD_ELEMENT_TYPES.MULTISELECT:
      case DASHBOARD_ELEMENT_TYPES.TOGGLE:
        defaultValueComponent = (
          <SelectDefaultValueConfig
            config={filterInfo.config}
            selectType={filterInfo.filter_type}
            updateSelectConfig={(newConfig, reRequestRows) =>
              updateConfig({ ...filterInfo, config: newConfig }, reRequestRows)
            }
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.DATEPICKER:
      case DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER:
        defaultValueComponent = (
          <DateDefaultValuesConfig
            config={filterInfo.config}
            dateFilterType={filterInfo.filter_type}
            updateDateConfig={(newConfig) => updateConfig({ ...filterInfo, config: newConfig })}
          />
        );
    }

    return defaultValueComponent ? (
      <ConfigSection defaultIsOpen title="Set the default value">
        <div className={sharedClasses.configSection}>{defaultValueComponent}</div>
      </ConfigSection>
    ) : null;
  };

  const renderAdjustDisplay = () => {
    let adjustComponent;
    switch (filterInfo?.filter_type) {
      case DASHBOARD_ELEMENT_TYPES.DROPDOWN:
      case DASHBOARD_ELEMENT_TYPES.MULTISELECT:
      case DASHBOARD_ELEMENT_TYPES.TOGGLE:
        adjustComponent = (
          <SelectAdjustDisplayConfig
            config={filterInfo.config}
            selectType={filterInfo.filter_type}
            updateSelectConfig={(newConfig) => updateConfig({ ...filterInfo, config: newConfig })}
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.DATEPICKER:
      case DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER:
        adjustComponent = (
          <DateAdjustDisplayConfig
            config={filterInfo.config}
            updateDateConfig={(newConfig) =>
              updateConfig({ ...filterInfo, config: newConfig } as CanvasFilterType)
            }
          />
        );
    }

    return adjustComponent ? (
      <ConfigSection title="Adjust display">
        <div className={sharedClasses.configSection}>{adjustComponent}</div>
      </ConfigSection>
    ) : null;
  };

  const renderEditInteractions = () => {
    let editComponent;
    switch (filterInfo?.filter_type) {
      case DASHBOARD_ELEMENT_TYPES.DROPDOWN:
      case DASHBOARD_ELEMENT_TYPES.MULTISELECT:
      case DASHBOARD_ELEMENT_TYPES.TOGGLE:
        editComponent = (
          <SelectEditInteractionsConfig
            isCanvas
            config={filterInfo.config}
            selectType={filterInfo.filter_type}
            updateSelectConfig={(newConfig) => updateConfig({ ...filterInfo, config: newConfig })}
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.DATEPICKER:
      case DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER:
        editComponent = (
          <DateEditInteractionsConfig
            isCanvas
            config={filterInfo.config}
            dateFilterType={filterInfo.filter_type}
            updateDateConfig={(newConfig) => updateConfig({ ...filterInfo, config: newConfig })}
          />
        );
    }

    return editComponent ? (
      <ConfigSection title="Edit interactions">
        <div className={sharedClasses.configSection}>{editComponent}</div>
      </ConfigSection>
    ) : null;
  };

  const filterOptions = Object.values(CanvasFilterOptions).map((option) => ({
    id: option,
    name: option,
  }));
  const currentFilterType = filterInfo
    ? SELECT_ELEMENT_SET.has(filterInfo.filter_type)
      ? CanvasFilterOptions.SELECT
      : CanvasFilterOptions.DATE
    : undefined;

  return (
    <>
      <ColumnHeader title="Configuration">
        {canvasFilterHasError(filter, datasets) ? (
          <Tag intent={Intent.ERROR}>Dataset used to populate filter does not exist</Tag>
        ) : referencedFilters[filter.provided_id] === undefined ? (
          <Tag intent={Intent.ERROR}>Filter isn’t referenced in any datasets</Tag>
        ) : null}
      </ColumnHeader>

      <div className={sharedClasses.config}>
        <MetadataInputs
          defaultIsOpen={isNewFilter || filterInfo === null}
          handleNewValueSubmitted={(params) =>
            dispatch(updateCanvasFilter({ filterId: id, ...params }))
          }
          initialDescription={description}
          initialId={provided_id}
          initialName={name}
        />

        <ConfigSection
          defaultIsOpen={filterInfo === null || isNewFilter}
          title="Select filter type">
          <div className={sharedClasses.configSection}>
            <DropdownSelect
              btnMinimal
              fillWidth
              ignoreCustomStyles
              minimal
              className={classes.selectFilterInput}
              filterable={false}
              noSelectionText="Select a Filter"
              onChange={(item) => {
                if (currentFilterType === item.id) return;

                const filterType =
                  item.id === CanvasFilterOptions.SELECT
                    ? DASHBOARD_ELEMENT_TYPES.DROPDOWN
                    : DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER;
                setVariable(filter.provided_id, undefined);
                dispatch(setCanvasFilterType({ filterId: id, filterType }));
              }}
              options={filterOptions}
              selectedItem={
                currentFilterType ? { id: currentFilterType, name: currentFilterType } : undefined
              }
            />
            {renderFilterType()}
          </div>
        </ConfigSection>

        {renderFilterValues()}
        {renderReferencedDatasets()}

        {renderDefaultValue()}
        {renderAdjustDisplay()}
        {renderEditInteractions()}

        <div className={sharedClasses.deleteButtonContainer}>
          <Button
            fillWidth
            icon="trash"
            onClick={() => setDeleteFilterModalOpen(true)}
            type="destructive">
            Remove
          </Button>
        </div>
      </div>
      {deleteFilterModalOpen ? (
        // TODO: Add logic checking dataset usage
        <AlertModal
          isOpen
          actionButtonProps={{
            onClick: () => dispatch(deleteCanvasFilter({ filterId: id })),
            text: 'Delete Filter',
          }}
          onClose={() => setDeleteFilterModalOpen(false)}
          title="Are you sure you want to delete this filter?">
          Once you&rsquo;ve deleted it you won&rsquo;t be able to retrieve it.
        </AlertModal>
      ) : null}
    </>
  );
}
