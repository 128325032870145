import { EMPTY_FILTER_OP_STATE, FILTER_LINK_ELEMENTS } from 'constants/dashboardConstants';
import { FilterClause, FilterValueType } from 'constants/types';
import {
  DashboardElement,
  DashboardElementConfig,
  DashboardVariableMap,
} from 'types/dashboardTypes';
import { DataPanel, ResourceDataset } from 'types/exploResource';
import { FilterOperator } from 'types/filterOperations';
import { getDataPanelDatasetId } from './exploResourceUtils';
import { isValidColumnTypeForOperation } from './filterOperations';

export const isFilterLinked = (config: DashboardElementConfig): boolean => {
  if (!config.datasetLinks) return false;

  return !!Object.values(config.datasetLinks).find(
    (dsFilter) => dsFilter.column && dsFilter.dataPanels && dsFilter.dataPanels.length > 0,
  );
};

export const removeDataPanelsFromLinks = (
  dpIds: string[],
  elements: Record<string, DashboardElement>,
) => {
  if (dpIds.length === 0) return;
  const idSet = new Set(dpIds);

  Object.values(elements).forEach((element) => {
    if (!element.config.datasetLinks) return;

    Object.values(element.config.datasetLinks).forEach((datasetLink) => {
      if (!datasetLink.dataPanels) return;

      datasetLink.dataPanels = datasetLink.dataPanels.filter((id) => !idSet.has(id));
    });
  });
};

export const removeDatasetFromLinks = (
  datasetId: string,
  elements: Record<string, DashboardElement>,
) => {
  Object.values(elements).forEach((element) => {
    const datasetLinks = element.config.datasetLinks;
    if (datasetLinks && datasetId in datasetLinks) {
      delete datasetLinks[datasetId];
    }
  });
};

export const getDataPanelLinks = (
  elements: DashboardElement[],
  changedElementNamesSet: Set<string>,
) => {
  const datasetToDps: Record<string, Set<string> | undefined> = {};
  elements.forEach((element) => {
    const { config, name } = element;
    if (!isValidLinkFilter(element) || !changedElementNamesSet.has(name)) return;

    Object.keys(config.datasetLinks ?? {}).map((datasetId) => {
      const linkConfig = config.datasetLinks?.[datasetId];
      if (!linkConfig?.dataPanels || linkConfig.dataPanels.length === 0) return;

      const datasetLinks = datasetToDps[datasetId];
      if (datasetLinks) {
        linkConfig.dataPanels.forEach((id) => datasetLinks.add(id));
      } else {
        datasetToDps[datasetId] = new Set(linkConfig.dataPanels);
      }
    });
  });
  return datasetToDps;
};

export const attachLinkFiltersToDp = (
  dp: DataPanel,
  datasets: Record<string, ResourceDataset>,
  elements: DashboardElement[],
  variables: DashboardVariableMap,
) => {
  const linkFilters = createDataPanelLinkFilterClauses(dp, datasets, elements, variables);
  if (linkFilters.length > 0) {
    if (!dp.filter_op) dp.filter_op = EMPTY_FILTER_OP_STATE();
    dp.filter_op.instructions.filterClauses.push(...linkFilters);
  }
};

const createDataPanelLinkFilterClauses = (
  dp: DataPanel,
  datasets: Record<string, ResourceDataset>,
  elements: DashboardElement[],
  variables: DashboardVariableMap,
): FilterClause[] => {
  const datasetId = getDataPanelDatasetId(dp);
  const dataset = datasets[datasetId];
  if (!dataset) return [];

  return createLinkFilterClauses(dp.id, dataset, elements, variables);
};

const createLinkFilterClauses = (
  dpId: string,
  dataset: ResourceDataset,
  elements: DashboardElement[],
  variables: DashboardVariableMap,
) => {
  return elements.reduce<FilterClause[]>((clauses, { config, name, element_type }) => {
    const variable = variables[name];
    if (
      !(FILTER_LINK_ELEMENTS.has(element_type) && config.operator && config.datasetLinks) ||
      variable === undefined
    )
      return clauses;

    const datasetLinks = config.datasetLinks[dataset.id];
    if (!datasetLinks?.column || !datasetLinks.dataPanels?.includes(dpId)) return clauses;

    const filterColumn = dataset.schema?.find((col) => col.name === datasetLinks.column);
    if (filterColumn && isValidColumnTypeForOperation(config.operator, filterColumn.type)) {
      clauses.push({
        filterColumn,
        filterValue: variable as FilterValueType,
        // Is it not undefined as isValidLinkFilter makes sure of that
        filterOperation: { id: config.operator },
      });
    }

    return clauses;
  }, []);
};

const isValidLinkFilter = ({ element_type, config }: DashboardElement) => {
  return FILTER_LINK_ELEMENTS.has(element_type) && config.operator && config.datasetLinks;
};

export type DataPanelLink = {
  column: string;
  operator: FilterOperator;
  elementName: string;
  elementId: string;
  applied: boolean;
};

export const getPossibleLinksForDataPanel = (
  dp: DataPanel,
  datasets: Record<string, ResourceDataset>,
  elements: DashboardElement[],
) => {
  const datasetId = getDataPanelDatasetId(dp);
  const dataset = datasets[datasetId];
  if (!dataset) return [];

  return elements.reduce<DataPanelLink[]>((acc, elem) => {
    const { config, name } = elem;
    if (isValidLinkFilter(elem) && config.operator) {
      const datasetLinks = config.datasetLinks?.[dataset.id];
      if (!datasetLinks?.column) return acc;
      const filterColumn = dataset.schema?.find((col) => col.name === datasetLinks.column);
      if (filterColumn && isValidColumnTypeForOperation(config.operator, filterColumn.type)) {
        acc.push({
          column: datasetLinks.column,
          operator: config.operator,
          elementName: name,
          elementId: elem.id,
          applied: !!datasetLinks.dataPanels?.includes(dp.id),
        });
      }
    }
    return acc;
  }, []);
};

// DatasetId to set of linked data panels
export type DashboardLinks = Record<string, Set<string> | undefined>;

export const getDashboardLinks = (
  datasets: Record<string, ResourceDataset>,
  dashboardElements: DashboardElement[],
  selectedItem: string,
): DashboardLinks | undefined => {
  const element = dashboardElements.find((elem) => elem.id === selectedItem);
  if (!element) return;

  const { config, element_type } = element;
  if (!FILTER_LINK_ELEMENTS.has(element_type) || !config.operator) return;

  const links: DashboardLinks = {};

  Object.keys(config.datasetLinks ?? {}).forEach((datasetId) => {
    const dataset = datasets[datasetId];
    const datasetLink = config.datasetLinks?.[datasetId];
    if (!dataset || !datasetLink || !datasetLink.column) return;

    const col = dataset.schema?.find((col) => col.name === datasetLink.column);
    if (!col || !isValidColumnTypeForOperation(config.operator, col.type)) return;
    links[datasetId] = new Set(datasetLink.dataPanels ?? []);
  });

  return links;
};
