import { ReactNode, CSSProperties, MouseEvent as ReactMouseEvent } from 'react';
import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { Button, Icon, IButtonProps } from '@blueprintjs/core';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

const BUTTON_HEIGHT = 32;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&.bp3-button': {
      justifyContent: 'space-between',
      backgroundImage: 'none !important',
      boxShadow: 'none',
      border: `1px solid ${theme.palette.ds.grey400}`,
      backgroundColor: theme.palette.ds.white,
      height: BUTTON_HEIGHT,

      '&:hover:not(.disabled)': {
        boxShadow: 'none',
        border: `1px solid ${theme.palette.ds.hovered.grey400}`,
        backgroundColor: theme.palette.ds.white,
      },

      '&:active:not(.disabled)': {
        boxShadow: 'none',
        border: `1px solid ${theme.palette.ds.blue}`,
        backgroundColor: theme.palette.ds.white,
      },
    },
    '& .bp3-button-text': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
      margin: 0,
    },
  },
  buttonContainer: {
    position: 'relative',
  },
  buttonContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonText: {
    alignItems: 'center',
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  buttonTextWithIcon: {
    display: 'flex',
  },
  buttonTextWithCancelBtn: {
    width: 'calc(100% - 20px)',
  },
  buttonIcon: {
    marginRight: theme.spacing(2),
    display: 'flex',
    '& .bp3-icon': {
      margin: `0px !important`,
    },

    '&.noText': {
      marginRight: 0,
    },
  },
  cancelButtonSpacing: {
    width: 20,
  },
  cancelButton: {
    position: 'absolute',
    right: 24,
    top: 6,

    '&.bp3-button': {
      height: 20,
      width: 20,
      minHeight: 0,
      minWidth: 0,
      padding: 0,
      marginRight: 2,
    },
  },
}));

type PassedProps = {
  children?: ReactNode;
  className?: string;
  showCancelBtn?: boolean;
  ignoreCustomStyles?: boolean;
  onCancelClick?: () => void;
  style?: CSSProperties;
  hideText?: boolean;
};

type Props = IButtonProps & PassedProps;

const DropdownButton = (props: Props) => {
  const classes = useStyles(props);
  const {
    disabled,
    icon,
    text,
    onCancelClick,
    showCancelBtn,
    hideText,
    ignoreCustomStyles,
    ...otherProps
  } = props;
  return (
    <div className={classes.buttonContainer}>
      <Button
        disabled={disabled}
        {...otherProps}
        className={cx(
          classes.root,
          {
            disabled,
            [GLOBAL_STYLE_CLASSNAMES.container.outline.dropdownInputBorder]: !ignoreCustomStyles,
            [GLOBAL_STYLE_CLASSNAMES.base.actionColor.interactionStates.dropdownInputBorderHover]:
              !ignoreCustomStyles,
            [GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.dropdownInputBorder]:
              !ignoreCustomStyles,
            [GLOBAL_STYLE_CLASSNAMES.text.body.button.primaryFont]: !ignoreCustomStyles,
          },
          props.className,
        )}>
        <div className={classes.buttonContent}>
          <div
            className={cx(
              classes.buttonText,
              { [classes.buttonTextWithCancelBtn]: showCancelBtn },
              { [classes.buttonTextWithIcon]: icon },
            )}>
            {icon ? (
              <div className={cx(classes.buttonIcon, { noText: hideText })}>
                <Icon icon={icon} />
              </div>
            ) : null}
            {hideText ? undefined : text}
          </div>
          {showCancelBtn ? <div className={classes.cancelButtonSpacing} /> : null}
        </div>
      </Button>
      {showCancelBtn ? (
        <Button
          minimal
          className={classes.cancelButton}
          disabled={disabled}
          icon="cross"
          onClick={(e: ReactMouseEvent<HTMLElement, MouseEvent>) => {
            e.stopPropagation();
            onCancelClick?.();
          }}
        />
      ) : null}
    </div>
  );
};

export default DropdownButton;
