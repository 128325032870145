import { Component } from 'react';
import cx from 'classnames';
import { withStyles, createStyles } from '@material-ui/styles';
import { WithStyles } from '@material-ui/core/index';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import DatePickerInput from 'shared/DatePickerInput';
import DropdownSelect from 'shared/DropdownSelect';
import { dateTimeFromISOString } from 'utils/dateUtils';

import { DashboardVariable } from 'types/dashboardTypes';
import { SelectedDropdownInputItem } from 'constants/types';
import { DateTime } from 'luxon';
import { showWarningToast } from 'shared/sharedToasts';

const styles = () =>
  createStyles({
    valueSelector: {
      width: '100%',
    },
  });

export enum InputType {
  INPUT = 'input',
  DATETIME = 'datetime',
  DROPDOWN = 'dropdown',
}

type PassedProps = {
  className?: string;
  name?: string;
  inputType: InputType;
  dropdownOptions?: SelectedDropdownInputItem[];
  value?: DashboardVariable;
  onValueChange: (newValue?: DashboardVariable) => void;
};

type Props = PassedProps & WithStyles<typeof styles>;

class DashboardVariableEntry extends Component<Props> {
  render = () => {
    const { className } = this.props;

    return <div className={className}>{this.renderVariableInput()}</div>;
  };

  renderVariableInput = () => {
    const { inputType, classes, value, onValueChange, dropdownOptions } = this.props;

    switch (inputType) {
      case InputType.INPUT:
        return (
          <InputWithBlurSave
            hideRightIconInteractions
            containerClassName={cx(classes.valueSelector)}
            initialValue={
              value ? JSON.stringify(value as string | number | string[] | number[]) : ''
            }
            onNewValueSubmitted={(newValue) => {
              if (!newValue) return onValueChange(undefined);
              try {
                newValue = JSON.parse(newValue);
                onValueChange(newValue);
              } catch {
                showWarningToast(
                  'Please enter the variable as valid javascript. Strings should have quotes.',
                  10,
                );
                return;
              }
            }}
            placeholder=""
          />
        );
      case InputType.DATETIME:
        return (
          <DatePickerInput
            showCancelBtn
            withPortal
            className={classes.valueSelector}
            onCancelClick={() => {
              onValueChange(undefined);
            }}
            onNewValueSelect={(newValue) => {
              onValueChange((newValue as DateTime) || undefined);
            }}
            selectedValue={
              typeof value === 'string' ? dateTimeFromISOString(value) : (value as DateTime)
            }
            showTimeSelect={false}
          />
        );
      case InputType.DROPDOWN: {
        let selectedItem;

        if (dropdownOptions && value) {
          selectedItem = dropdownOptions.find((option) => option.id === value);
        }

        return (
          <DropdownSelect
            fillWidth
            minimal
            showCancelBtn
            usePortal
            containerClassName={classes.valueSelector}
            filterable={false}
            noSelectionText=""
            onCancelClick={() => onValueChange(undefined)}
            onChange={(item: SelectedDropdownInputItem) => onValueChange(item.id)}
            options={dropdownOptions || []}
            selectedItem={selectedItem}
          />
        );
      }
    }
  };
}

export default withStyles(styles)(DashboardVariableEntry);
