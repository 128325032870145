import { useState, useContext, CSSProperties } from 'react';
import { uniqueId } from 'utils/standard';
import { Icon, Menu, MenuDivider, MenuItem, PopoverPosition } from '@blueprintjs/core';
import { Select, IItemListRendererProps, IItemRendererProps } from '@blueprintjs/select';
import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';

import DropdownButton from 'explo-ds/buttons/dropdownButton';

import { SelectedDropdownInputItem } from 'constants/types';
import InputLabel from 'shared/InputLabel';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import { withWidth } from 'components/HOCs/withWidth';
import DashboardLayoutContext from 'components/DashboardLayout/DashboardLayoutContext';

const useStyles = makeStyles((theme: Theme) => ({
  fullWidthRoot: {
    width: '100%',
    position: 'relative',

    '& .bp3-popover-target': {
      width: '100%',
    },

    '& .bp3-transition-container': {
      minWidth: '100%',

      '& .bp3-popover': {
        width: '100%',
        minWidth: 120,
        boxShadow: 'none',
        border: `1px solid ${theme.palette.ds.grey300}`,
      },
    },
  },
  fullWidthBtn: {
    width: '100%',
  },
  menuList: {
    maxHeight: 200,
    // blueprint puts a minwidth on the menu items which causes the menu items to
    // overflow the menu containers. Unset the min width so that we can control it
    // more granularly at the menu level
    minWidth: 'unset',
    overflow: 'auto',

    '& li': {
      marginBottom: 0,
    },
  },
  placeholderText: {
    color: '#9DA9B3 !important',
  },
  dropdownMenuItem: {
    '&.bp3-menu-item': {
      display: 'flex',
      alignItems: 'center',
      '&.bp3-active': {
        backgroundColor: theme.palette.ds.blue,
      },
    },
  },

  createItemBtn: {
    margin: 5,
    padding: `5px 7px`,
    borderRadius: 2,
    color: theme.palette.ds.blue,
    backgroundColor: theme.palette.ds.white,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,

    '&:hover': {
      backgroundColor: '#E4E9ED',
      cursor: 'pointer',

      '&.isCreating': {
        backgroundColor: 'initial',
        cursor: 'initial',
      },
    },

    '&:active': {
      backgroundColor: '#D5DADF',

      '&.isCreating': {
        backgroundColor: 'initial',
        cursor: 'initial',
      },
    },
  },
  createItemIcon: {
    color: theme.palette.ds.blue,
    marginRight: theme.spacing(1),
  },
  newItemInputContainer: {
    marginTop: -3,
    marginBottom: -4,

    '& .bp3-input': {
      textAlign: 'left',
    },
  },
  popoverContainer: ({ maxWidth }: { maxWidth?: number }) => ({ width: 'auto', maxWidth }),
  itemDivider: {
    margin: `4px -5px`,
    height: 1,
    backgroundColor: theme.palette.ds.grey400,
  },
  menuTitle: {
    paddingLeft: 0,
  },
}));

export type Props = {
  btnMinimal?: boolean;
  buttonStyle?: CSSProperties;
  className?: string;
  containerClassName?: string;
  createItemPlaceholderText?: string;
  createItemText?: string;
  dataTestid?: string;
  disabled?: boolean;
  fillWidth?: boolean;
  filterable?: boolean;
  filterPlaceholderText?: string;
  hideBtnCaret?: boolean;
  hideSelectedText?: boolean;
  ignoreCustomStyles?: boolean;
  labelHelpIconColor?: string;
  label?: string;
  labelHelpText?: string | JSX.Element;
  maxWidth?: number;
  minimal?: boolean;
  noSelectionText?: string | React.ReactNode;
  onCancelClick?: () => void;
  onChange: (item: SelectedDropdownInputItem) => void;
  onCreateItem?: (name: string) => void;
  options: SelectedDropdownInputItem[];
  selectedItem?: SelectedDropdownInputItem;
  showCancelBtn?: boolean;
  showIcon?: boolean;
  useFakeLabel?: boolean;
  usePortal?: boolean;
  disableOnNoItems?: boolean;
  openElementToLeft?: boolean;
};

const DropdownSelect = (props: Props) => {
  const [isCreatingItem, setIsCreatingItem] = useState<boolean>(false);
  const classes = useStyles({ maxWidth: props.maxWidth });
  const { dashboardLayoutTagId } = useContext(DashboardLayoutContext);

  const simpleFilterPredicate = (query: string, item: SelectedDropdownInputItem) => {
    return item.name.toString().toLowerCase().indexOf(query.toLowerCase()) >= 0;
  };

  const filterable = props.filterable === undefined ? true : props.filterable;

  const isDisabled = props.disabled || (props.disableOnNoItems && props.options.length === 0);

  const itemRenderer = (
    item: SelectedDropdownInputItem,
    { handleClick, query, index }: IItemRendererProps,
  ) => {
    let itemText;
    if (query.length > 0) {
      // replace each instance of the query with a bolded version (wrapped in <b />)
      // so that the item shows the query highlighted in it when searching
      const textPartsWithoutQuery = item.name.split(query);
      itemText = [];
      for (let i = 0; i < textPartsWithoutQuery.length - 1; i++) {
        itemText.push(textPartsWithoutQuery[i]);
        itemText.push(<b key={uniqueId('query_text')}>{query}</b>);
      }
      itemText.push(textPartsWithoutQuery[textPartsWithoutQuery.length - 1]);
    } else if (item.isDivider) {
      return <div className={classes.itemDivider} key={`divider_${index}`}></div>;
    } else if (item.isTitle) {
      return (
        <MenuDivider className={classes.menuTitle} key={`title-${item.name}`} title={item.name} />
      );
    } else {
      itemText = item.name;
    }

    return (
      <MenuItem
        multiline
        active={item.id === props.selectedItem?.id}
        className={cx(classes.dropdownMenuItem, {
          [GLOBAL_STYLE_CLASSNAMES.text.body.dropdownMenuItem]: !props.ignoreCustomStyles,
          [GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.dropdownInputMenuItem]:
            !props.ignoreCustomStyles,
        })}
        data-testid={props.dataTestid ? `${props.dataTestid}-item` : undefined}
        icon={props.showIcon && item.icon}
        key={uniqueId(`${item.id}_`)}
        onClick={handleClick}
        text={itemText}
      />
    );
  };

  const itemListRenderer = ({
    filteredItems,
    renderItem,
    itemsParentRef,
  }: IItemListRendererProps<SelectedDropdownInputItem>) => {
    let itemsList;
    if (filteredItems.length === 0) {
      itemsList = <MenuItem disabled text="No results." />;
    } else if (filteredItems.length > 100 && filterable) {
      itemsList = filteredItems.slice(0, 100).map(renderItem);
      itemsList.push(<MenuItem disabled key="no_results" text="Filter for more results." />);
    } else {
      itemsList = filteredItems.map(renderItem);
    }

    return (
      <div>
        <Menu className={classes.menuList} ulRef={itemsParentRef}>
          {itemsList}
        </Menu>
        {props.onCreateItem && (
          <div
            className={cx(classes.createItemBtn, { isCreating: isCreatingItem })}
            data-testid={props.dataTestid ? `${props.dataTestid}-create` : undefined}
            onClick={() => setIsCreatingItem(true)}>
            <Icon className={classes.createItemIcon} icon="plus" />
            {isCreatingItem ? (
              <InputWithBlurSave
                fillWidth
                hideRightIconInteractions
                ignoreKeyPress
                isCompact
                containerClassName={classes.newItemInputContainer}
                onNewValueSubmitted={(text) => props.onCreateItem?.(text)}
                placeholder={props.createItemPlaceholderText || 'Enter item name'}
              />
            ) : (
              props.createItemText || 'Create Item'
            )}
          </div>
        )}
      </div>
    );
  };

  const usePortal = !props.fillWidth || !!props.usePortal;

  return (
    <div className={props.containerClassName}>
      {(props.label || props.useFakeLabel) && (
        <InputLabel
          fakeLabel={props.useFakeLabel}
          helpText={props.labelHelpText}
          iconColor={props?.labelHelpIconColor}
          ignoreCustomStyles={props.ignoreCustomStyles}
          text={props.label || ''}
        />
      )}
      <Select
        className={cx(
          {
            [classes.fullWidthRoot]: props.fillWidth,
            [GLOBAL_STYLE_CLASSNAMES.container.outline.dropdownFilterInputBorder]:
              !props.ignoreCustomStyles,
            [GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.dropdownFilterInputOutline]:
              !props.ignoreCustomStyles,
            [GLOBAL_STYLE_CLASSNAMES.container.outline.popoverBorder]: !props.ignoreCustomStyles,
            [GLOBAL_STYLE_CLASSNAMES.text.body.dropdownFilterInput]: !props.ignoreCustomStyles,
          },
          props.className,
        )}
        disabled={isDisabled}
        filterable={filterable}
        inputProps={{ placeholder: props.filterPlaceholderText }}
        itemListRenderer={itemListRenderer}
        itemPredicate={simpleFilterPredicate}
        itemRenderer={itemRenderer}
        items={props.options}
        matchTargetWidth={!props.usePortal}
        noResults={<MenuItem disabled={true} text="No results." />}
        onItemSelect={props.onChange}
        popoverProps={{
          boundary: 'window',
          minimal: props.minimal,
          popoverClassName: classes.popoverContainer,
          portalContainer: usePortal
            ? document.getElementById(dashboardLayoutTagId) ?? document.body
            : undefined,
          position: props.openElementToLeft
            ? PopoverPosition.BOTTOM_RIGHT
            : PopoverPosition.BOTTOM_LEFT,
          usePortal,
        }}>
        <DropdownButton
          className={cx(
            { [classes.placeholderText]: !props.selectedItem },
            { [classes.fullWidthBtn]: props.fillWidth },
            {
              [GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.inputFields.defaultBorderRadius]:
                !props.ignoreCustomStyles,
            },
          )}
          data-testid={props.dataTestid ? `${props.dataTestid}-open-dropdown` : undefined}
          disabled={isDisabled}
          hideText={props.hideSelectedText}
          icon={props.showIcon && props.selectedItem ? props.selectedItem.icon : undefined}
          ignoreCustomStyles={props.ignoreCustomStyles}
          minimal={props.btnMinimal}
          onCancelClick={props.onCancelClick}
          rightIcon={props.hideBtnCaret ? undefined : 'caret-down'}
          showCancelBtn={props.selectedItem ? props.showCancelBtn : false}
          style={props.buttonStyle}
          text={props.selectedItem ? props.selectedItem.name : props.noSelectionText}
        />
      </Select>
    </div>
  );
};

export default withWidth(DropdownSelect);
