import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { ResourceListPage } from 'shared/ExploResource/ResourceListPage';

import { ReduxState } from 'reducers/rootReducer';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ACTION } from 'actions/types';
import { fetchDashboardAttributes } from 'actions/dashboardAttributesActions';
import { fetchEmailCadenceList } from 'actions/emailActions';
import { pageView } from 'analytics/exploAnalytics';
import { fetchDashboardTemplateList } from 'actions/dashboardTemplateActions';
import { ResourcePageType } from 'types/exploResource';

export default function HomeAppPage(): JSX.Element {
  const dispatch = useDispatch();

  const {
    currentUser,
    dashboardAttributes,
    dashboardListLoading,
    dashboardTemplateList,
    emailCadenceList,
  } = useSelector(
    (state: ReduxState) => ({
      currentUser: state.currentUser,
      dashboardAttributes: state.dashboardAttributes.dashboard_attributes,
      dashboardTemplateList: state.dashboard.dashboardTemplateList,
      emailCadenceList: state.emailCadence.emailCadenceList,

      dashboardListLoading: createLoadingSelector([ACTION.FETCH_DASHBOARD_TEMPLATE_LIST])(state),
    }),
    shallowEqual,
  );

  // on mount
  useEffect(() => {
    document.title = 'Explo | Home';
    pageView('Home App (Dashboards)');
  }, []);

  const teamId = currentUser.team?.id;

  // load dashboards
  useEffect(() => {
    if (!dashboardTemplateList && teamId) {
      dispatch(fetchDashboardTemplateList({ id: teamId }));
    }
  }, [dashboardTemplateList, teamId, dispatch]);

  // load email cadence list
  useEffect(() => {
    if (!emailCadenceList && teamId) {
      dispatch(fetchEmailCadenceList({ id: teamId }));
    }
  }, [emailCadenceList, teamId, dispatch]);

  // load dashboard attributes
  useEffect(() => {
    if (!dashboardAttributes) dispatch(fetchDashboardAttributes());
  }, [dashboardAttributes, dispatch]);

  return (
    <ResourceListPage
      dashboardAttributes={dashboardAttributes}
      emailCadenceList={emailCadenceList}
      pageType={ResourcePageType.EXPLORE}
      resources={dashboardTemplateList}
      resourcesLoading={dashboardListLoading}
    />
  );
}
