import { createAction } from '@reduxjs/toolkit';

import { defineAPIPostAction, ActionFnWithArgs } from 'actions/actionUtils';
import { ACTION } from 'actions/types';
import { FetchDashboardDatasetPreviewData, QueryDebuggingInformation } from 'actions/responseTypes';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { DisplayOptions } from 'constants/types';
import {
  DatasetColumn,
  DatasetSchema,
  DatasetRow,
  DatabaseUnsupportedOperations,
} from 'types/datasets';

export interface DataTable {
  name: string;
  columns: DatasetColumn[];
}

export interface DrilldownColConfig {
  displayName: string; // defaults to title case of name
  index: number;
  isIncluded: boolean; // True implies will be included in data fetching requests
  isVisible: boolean; //True implies will be seen on load
  displayFormatting?: DisplayOptions;
}

export interface Dataset extends DatasetData {
  id: string;
  // not actually name of table, should switch to name
  table_name: string;
  parent_schema_id: number;
  schema?: DatasetSchema;
  queryDraft?: string;
  query?: string;
  drilldownColumnConfigs?: Record<string, DrilldownColConfig>; // key : col name
  // attached in embedded environments
  query_variables?: string[];

  _schema?: DatasetSchema; // schema cache when preview is clicked but no save is clicked
  _is_new?: boolean;
  _loading?: boolean;
}

export interface DatasetData {
  // attached when dataset data is fetched
  _rows?: DatasetRow[];
  _total_row_count?: number;
  _error?: string;
  _unsupported_operations?: DatabaseUnsupportedOperations[];
  _query_information?: QueryDebuggingInformation;
}

type SaveDatasetQueryArgs = {
  query: string;
  dataset_id: string;
  schema: DatasetSchema;
};

export const saveDatasetQuery = createAction<SaveDatasetQueryArgs>(ACTION.SAVE_DATASET_QUERY);

type SaveDraftDatasetQueryArgs = {
  queryDraft?: string;
  dataset_id: string;
};

export const saveDraftDatasetQuery = createAction<SaveDraftDatasetQueryArgs>(
  ACTION.SAVE_DRAFT_DATASET_QUERY,
);

export const deleteDataset = createAction<{ datasetId: string }>(ACTION.DELETE_DATASET);

type EditDatasetNameArgs = { datasetId: string; name: string };
export const editDatasetName = createAction<EditDatasetNameArgs>(ACTION.EDIT_DATASET_NAME);

export type DrilldownColConfigOptions = {
  displayName?: string;
  index?: number;
  isIncluded?: boolean;
  isVisible?: boolean;
  displayFormatting?: DisplayOptions;
};

type UpdateDatasetDrilldownColConfigArgs = DrilldownColConfigOptions & {
  datasetId: string;
  colName: string;
};

export const updateDatasetDrilldownColConfig = createAction<UpdateDatasetDrilldownColConfigArgs>(
  ACTION.UPDATE_DATASET_DRILLDOWN_COLUMN_CONFIG,
);
type UpdateDatasetDrilldownColConfigsArgs = {
  datasetId: string;
  newConfigs: Record<string, DrilldownColConfig>;
};
export const updateDatasetDrilldownColConfigs = createAction<UpdateDatasetDrilldownColConfigsArgs>(
  ACTION.UPDATE_DATASET_DRILLDOWN_COLUMN_CONFIGS,
);

// Requests For Dashboard Editor Page

export type FetchDashboardDatasetPreviewBody = {
  dataset_id: string;
  query_limit: number;
  variables: DashboardVariableMap;
  customer_id: number | undefined;
  resource_id?: number;
  timezone?: string;

  // non-embedded
  query?: string;
  parent_schema_id?: number;
};

export type FetchDatasetPreview = ActionFnWithArgs<
  FetchDashboardDatasetPreviewBody,
  FetchDashboardDatasetPreviewData
>;

export const {
  actionFn: fetchDashboardDatasetPreview,
  successAction: fetchDashboardDatasetPreviewSuccess,
} = defineAPIPostAction<FetchDashboardDatasetPreviewBody, FetchDashboardDatasetPreviewData>(
  ACTION.FETCH_DASHBOARD_DATASET_PREVIEW,
  'datasets',
  'get_preview',
  'POST',
);

export type FetchEditorDatasetPreviewBody = {
  dataset_id: string;
  query: string;
  parent_schema_id: number;
  resource_id?: number;
  timezone?: string;

  variables: DashboardVariableMap;
  customer_id: number | undefined;
  offset?: number;
};

export const {
  actionFn: fetchEditorDatasetPreview,
  requestAction: fetchEditorDatasetPreviewRequest,
  successAction: fetchEditorDatasetPreviewSuccess,
  errorAction: fetchEditorDatasetPreviewError,
} = defineAPIPostAction<FetchEditorDatasetPreviewBody, FetchDashboardDatasetPreviewData>(
  ACTION.FETCH_EDITOR_DATASET_PREVIEW,
  'datasets',
  'get_preview',
  'POST',
);

export const { actionFn: fetchCanvasFilterDatasetPreview } = defineAPIPostAction<
  FetchEditorDatasetPreviewBody,
  FetchDashboardDatasetPreviewData
>(ACTION.FETCH_CANVAS_FILTER_DATASET_PREVIEW, 'datasets', 'get_preview', 'POST');

export const { actionFn: fetchCanvasFilterDatasetRowCount } = defineAPIPostAction<
  FetchEditorDatasetPreviewBody,
  { _total_row_count: number }
>(ACTION.FETCH_CANVAS_FILTER_DATASET_ROW_COUNT, 'datasets', 'get_row_count', 'POST');

export const {
  actionFn: fetchEditorDatasetRowCount,
  successAction: fetchEditorDatasetRowCountSuccess,
  errorAction: fetchEditorDatasetRowCountError,
} = defineAPIPostAction<FetchEditorDatasetPreviewBody, { _total_row_count: number }>(
  ACTION.FETCH_EDITOR_DATASET_ROW_COUNT,
  'datasets',
  'get_row_count',
  'POST',
);

export const updateDashboardDatasetSchema = createAction<{
  datasetId: string;
  newParentSchemaId: number;
}>(ACTION.UPDATE_DATASET_SCHEMA);

export const updateCanvasDatasetSchema = createAction<{
  datasetId: string;
  newParentSchemaId: number;
}>(ACTION.UPDATE_DATASET_SCHEMA);
