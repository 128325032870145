import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';

import FilterValueInput from 'pages/dataPanelEditorPage/filterValueInput';
import InputGroup from 'explo-ds/forms/marketing/inputGroup';

import { FilterValueType, FilterValueDateType } from 'constants/types';
import { Props as FilterRowProps } from './FilterRow';

const useStyles = makeStyles((theme: Theme) => ({
  valueInput: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    '& .bp3-input:disabled, .bp3-input.bp3-disabled': {
      border: `1px solid ${theme.palette.ds.grey500}`,
    },
    '& .bp3-input': {
      boxShadow: 'none',
      border: `1px solid ${theme.palette.ds.grey500}`,
      height: 32,
    },
    '& .bp3-input-action': {
      paddingRight: 1,
    },
    '& .bp3-input-group .bp3-button': {
      height: 26,
    },
    '& svg': {
      height: 12,
      width: 12,
    },
    '& input': {
      height: 32,
      lineHeight: 'normal',
    },
    '& .bp3-button .bp3-icon:first-child:last-child, .bp3-button .bp3-spinner + .bp3-icon:last-child':
      {
        margin: '0 -3px',
      },
  },
}));

export type ValueInputProps = { tall?: boolean; preventFutureDates?: boolean } & Pick<
  FilterRowProps,
  | 'selectedOperator'
  | 'selectedColumn'
  | 'onFilterValueUpdate'
  | 'filterValue'
  | 'adHocFilterSettings'
>;
export default function ValueInput({
  tall,
  selectedColumn,
  selectedOperator,
  onFilterValueUpdate,
  filterValue,
  adHocFilterSettings,
  preventFutureDates,
}: ValueInputProps) {
  const classes = useStyles({ tall });

  const updateStartDate = (startDate: string | null) => {
    let newVal: FilterValueType;
    if (!filterValue) {
      newVal = {
        startDate: startDate ?? undefined,
      };
    } else {
      newVal = {
        ...(filterValue as FilterValueDateType),
        startDate: startDate ?? undefined,
      };
    }

    onFilterValueUpdate(newVal);
  };

  const updateEndDate = (endDate: string | null) => {
    let newVal: FilterValueType;
    if (!filterValue) {
      newVal = {
        endDate: endDate ?? undefined,
      };
    } else {
      newVal = {
        ...(filterValue as FilterValueDateType),
        endDate: endDate ?? undefined,
      };
    }

    onFilterValueUpdate(newVal);
  };

  if (!selectedColumn || !selectedOperator) {
    return (
      <span className={cx({ [classes.valueInput]: !tall })}>
        <InputGroup disabled className="bp3-fill" placeholder="Value" type="text" />
      </span>
    );
  }

  return (
    <span className={cx({ [classes.valueInput]: !tall })}>
      <FilterValueInput
        fillWidth
        adHocFilterSettings={adHocFilterSettings}
        filterOperator={selectedOperator}
        filterValue={filterValue}
        preventFutureDates={preventFutureDates}
        updateEndDate={updateEndDate}
        updateFilterValue={onFilterValueUpdate}
        updateStartDate={updateStartDate}
      />
    </span>
  );
}
