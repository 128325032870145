import { SELECT_ELEMENT_SET } from 'constants/dashboardConstants';
import { DashboardElement, SelectElemConfig } from 'types/dashboardTypes';
import { DataPanel } from 'types/exploResource';

import { getDataPanelDatasetId } from './exploResourceUtils';
import { getSelectFilterDatasetId } from './filterUtils';

export function getDataPanelsUsingDataset(dataPanels: DataPanel[], datasetId: string) {
  return dataPanels.filter((dp) => datasetId === getDataPanelDatasetId(dp));
}

export function getElementsUsingDataset(
  elements: DashboardElement[],
  datasetId: string,
  elementId?: string,
) {
  return elements.filter((elem) => {
    if (!SELECT_ELEMENT_SET.has(elem.element_type) || elem.id === elementId) return false;

    return getSelectFilterDatasetId(elem.config as SelectElemConfig) === datasetId;
  });
}
