import { FC, MouseEventHandler, forwardRef, MutableRefObject } from 'react';
import cx from 'classnames';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';

import * as styles from './styles.css';
import { Icon, IconName } from 'components/ds/Icon';
import { sprinkles } from 'components/ds';

interface Props {
  disabled?: boolean;
  menuOptions: DropdownOption[];
  open?: boolean;
  // Can be overwritten at option level
  onClick?: (value: string) => void;
  // https://github.com/radix-ui/primitives/issues/1241
  onOpenChange?: (open: boolean) => void;

  // Ref for the parent container to create a boundary for the Radix dropdown menu
  containerRef?: MutableRefObject<HTMLElement | null>;
}

export type DropdownOption = {
  borderTop?: boolean;
  disabled?: boolean;
  icon?: IconName;
  name: string;
  value?: string;
  className?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export const EmbeddedDropdownMenu: FC<Props> = ({
  open,
  children,
  disabled,
  menuOptions,
  onOpenChange,
  onClick,
  containerRef,
}) => {
  return (
    <DropdownMenu.Root onOpenChange={onOpenChange} open={open}>
      <DropdownMenu.Trigger asChild disabled={disabled}>
        {children}
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        align="end"
        className={styles.dropdownContainer}
        collisionBoundary={containerRef?.current}
        sideOffset={8}>
        {menuOptions.map((option) => (
          <DropdownMenu.Item
            className={cx(styles.dropdownOption, {
              [styles.dropdownOptionBorderTop]: option.borderTop,
              [styles.dropdownOptionDisabled]: option.disabled,
            })}
            disabled={option.disabled}
            key={`dropdown-embed-${option.name}`}
            onClick={(e) => {
              if (option.disabled) return;
              if (option.onClick) option.onClick(e);
              else onClick?.(option.value ?? option.name);
            }}>
            {option.icon ? <Icon name={option.icon} /> : undefined}
            <EmbedText body="b1" className={option.className}>
              {option.name}
            </EmbedText>
          </DropdownMenu.Item>
        ))}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

type ButtonProps = {
  selectedName?: string;
  placeholder?: string;
};

export const EmbeddedDropdownButton: FC<ButtonProps> = forwardRef<HTMLDivElement, ButtonProps>(
  ({ selectedName, placeholder, ...props }, ref) => {
    return (
      <div {...props} className={styles.dropdownButton} ref={ref}>
        <span
          className={cx(
            styles.dropdownButtonText,
            selectedName ? undefined : sprinkles({ color: 'contentTertiary' }),
          )}>
          {selectedName ?? placeholder}
        </span>
        <Icon name="caret-down" size="sm" />
      </div>
    );
  },
);

EmbeddedDropdownButton.displayName = 'EmbeddedDropdownButton';
