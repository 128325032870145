import { useContext, useEffect } from 'react';
import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Color from 'color';
import { isLoading, isSuccess } from 'remotedata';

import StepFooter from '../StepFooter';
import StepHeader from '../StepHeader';
import FlexBox, { FlexDirection, HorizontalAlignment } from 'components/core/FlexBox';
import { GlobalStylesContext, GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import Button from 'shared/Button';
import { CSV_ICON, PDF_ICON } from 'constants/iconConstants';
import { UserTransformedSchema } from 'constants/types';
import { JoinedStates } from 'reducers/rootReducer';
import { clearDownloads } from 'actions/dashboardLayoutActions';
import { SpreadsheetType } from 'reducers/dashboardLayoutReducer';

const HEADER_HEIGHT = 65;
const FOOTER_HEIGHT = 58;

const useStyles = makeStyles((theme: Theme) => ({
  fillHeight: {
    height: '100%',
  },
  mainContainer: {
    padding: theme.spacing(4),
    height: `calc(100% - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`,
    overflow: 'auto',

    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  exportOption: {
    backgroundColor: theme.palette.ds.grey100,
    padding: `${theme.spacing(6)}px ${theme.spacing(4)}px`,
    margin: `0px ${theme.spacing(5)}px`,
    textAlign: 'center',

    '&.darkBg': {
      color: theme.palette.ds.white,
      backgroundColor: 'rgba(0, 0, 0, 11)',
    },
  },
  primaryText: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(1),
    fontWeight: 600,
    fontSize: 16,
  },
  secondaryText: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    fontSize: 14,
  },
}));

type Props = {
  dataPanelId: string;
  onBack: () => void;
  onDownloadPanelSpreadsheet: (fileFormat: SpreadsheetType, schema?: UserTransformedSchema) => void;
  onDownloadPanelPdf: (schema?: UserTransformedSchema) => void;
  onNext: () => void;
};

export default function ExportStep({
  dataPanelId,
  onBack,
  onDownloadPanelSpreadsheet,
  onDownloadPanelPdf,
  onNext,
}: Props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { globalStyleConfig } = useContext(GlobalStylesContext);

  const dpDownload = useSelector(
    (state: JoinedStates) => state.dashboardLayout.dpDownloads[dataPanelId],
  );

  useEffect(() => {
    return () => {
      dispatch(clearDownloads(dataPanelId));
    };
  }, [dispatch, dataPanelId]);

  useEffect(() => {
    if (dpDownload?.type === 'url' && isSuccess(dpDownload.status))
      window.open(dpDownload.status.data);
  }, [dpDownload]);

  const containerBgDark = Color(globalStyleConfig.container.fill).isDark();

  const isDownloading = isLoading(dpDownload?.status);

  return (
    <FlexBox
      className={cx(classes.fillHeight, GLOBAL_STYLE_CLASSNAMES.container.fill.backgroundColor)}
      direction={FlexDirection.COLUMN}>
      <StepHeader text="Export" />
      <div className={classes.mainContainer}>
        <ExportOption
          containerBgDark={containerBgDark}
          disabled={isDownloading}
          icon={CSV_ICON(globalStyleConfig.text.secondaryColor, { height: 50, width: 50 })}
          loading={isDownloading && dpDownload?.exportType === 'csv'}
          onDownloadClicked={() => onDownloadPanelSpreadsheet('csv')}
          primaryText="Export as CSV"
          secondaryText="Great for exporting large amounts of data, and allows for easy data re-upload or analysis."
        />
        <ExportOption
          containerBgDark={containerBgDark}
          disabled={isDownloading}
          icon={CSV_ICON(globalStyleConfig.text.secondaryColor, { height: 50, width: 50 })}
          loading={isDownloading && dpDownload?.exportType === 'xlsx'}
          onDownloadClicked={() => onDownloadPanelSpreadsheet('xlsx')}
          primaryText="Export as XLSX"
          secondaryText="Great for exporting large amounts of data, and allows for easy data re-upload or analysis."
        />
        <ExportOption
          containerBgDark={containerBgDark}
          disabled={isDownloading}
          icon={PDF_ICON(globalStyleConfig.text.secondaryColor, { height: 50, width: 50 })}
          loading={isDownloading && dpDownload?.exportType === 'pdf'}
          onDownloadClicked={onDownloadPanelPdf}
          primaryText="Export as PDF"
          secondaryText="Renders a maximum of 200 rows. Great for sharing data in a readable format."
        />
      </div>
      <StepFooter secondary onBack={onBack} onNext={onNext} text="Reset" />
    </FlexBox>
  );
}

type ExportOptionProps = {
  disabled: boolean;
  icon: JSX.Element;
  loading?: boolean;
  onDownloadClicked: () => void;
  primaryText: string;
  secondaryText: string;
  containerBgDark?: boolean;
};

function ExportOption({
  disabled,
  icon,
  loading,
  onDownloadClicked,
  primaryText,
  secondaryText,
  containerBgDark,
}: ExportOptionProps) {
  const classes = useStyles();

  return (
    <FlexBox
      className={cx(classes.exportOption, GLOBAL_STYLE_CLASSNAMES.text.body.primaryFont, {
        darkBg: containerBgDark,
      })}
      direction={FlexDirection.COLUMN}
      horizontalAlignment={HorizontalAlignment.CENTER}>
      {icon}
      <div className={classes.primaryText}>{primaryText}</div>
      <div className={classes.secondaryText}>{secondaryText}</div>
      <Button
        minimal
        className={GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.buttonColor}
        disabled={disabled}
        icon="import"
        loading={loading}
        onClick={onDownloadClicked}
        text="Download"
      />
    </FlexBox>
  );
}
