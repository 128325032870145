import { useEffect, useState, useMemo, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { cloneDeep, sortBy } from 'utils/standard';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'utils/routerUtils';

import ChartEditor from '../ChartEditor';
import TemplateView from './TemplateView';
import { CanvasNav } from '../CanvasNav';
import { ComponentEditor } from '../ComponentEditor';
import { ArrowIcon, CustomComponentIcon } from '../icons';
import { Icon } from '@blueprintjs/core';
import * as styles from './index.css';
import { Button, Intent, Tag, AlertModal, sprinkles } from 'components/ds';
import { ColumnHeader } from 'components/resource/ColumnHeader';
import { MetadataInputs } from 'components/resource/MetadataInputs';

import {
  CanvasCustomComponent,
  CanvasTemplate,
  CanvasVersionConfig,
  createCanvasComponent,
  createCanvasTemplate,
  deleteCanvasComponent,
  deleteCanvasTemplate,
  updateCanvasComponent,
  updateCanvasTemplate,
} from 'actions/canvasConfigActions';
import { sharedStyles } from '../sharedStyles';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { fetchDataPanel, fetchDataPanelRowCount } from 'actions/dataPanelTemplateAction';
import { isDataPanelReadyToCompute } from 'utils/dataPanelConfigUtils';
import { VisualizeOperation } from 'types/dataPanelTemplate';
import { removeUnderscoreFields } from 'utils/dashboardUtils';
import { FilterClause, VISUALIZE_TABLE_OPERATIONS } from 'constants/types';
import { getChartIcon } from '../icons';
import { ReduxState } from 'reducers/rootReducer';
import { getSelectedCustomer } from 'reducers/customersReducer';
import { CanvasConfigureType } from 'actions/canvasActions';
import { getCanvasTemplateError, isCanvasComponent } from 'utils/canvasConfigUtils';

type Props = {
  canvasId: number;
  config: CanvasVersionConfig;
  variables: DashboardVariableMap;
};

type SelectedTemplateInfo =
  | { type: 'chart'; id: string; isNew: boolean }
  | { type: 'custom'; id: string };

export default function TemplateEditor({ config, canvasId, variables }: Props): JSX.Element {
  const sharedClasses = sharedStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();

  const selectedUserGroup = useSelector((state: ReduxState) =>
    getSelectedCustomer(state.customers),
  );

  const sortedTemplates = useMemo(
    () => sortBy(Object.values(config.templates), 'name'),
    [config.templates],
  );

  const sortedComponents = useMemo(
    () => sortBy(Object.values(config.customComponents ?? {}), 'name'),
    [config.customComponents],
  );

  const templateCreationDisabled = useMemo(
    () =>
      Object.values(config.datasets).filter((dataset) => !dataset.isHiddenFromUsers).length === 0,
    [config.datasets],
  );

  const [selectedTemplateInfo, setSelectedTemplateInfo] = useState<SelectedTemplateInfo | null>(
    null,
  );
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const fetchDataForTemplate = useCallback(
    (template: CanvasTemplate, newViz?: VisualizeOperation, newFilters?: FilterClause[]) => {
      if (
        (!newViz && !newFilters && !!template._rows) ||
        !isDataPanelReadyToCompute(
          { ...template, visualize_op: newViz ?? template.visualize_op },
          config.datasets,
        )
      )
        return;

      const cleanedTemplate = removeUnderscoreFields(cloneDeep(template));
      const cleanedDataset = removeUnderscoreFields(
        cloneDeep(config.datasets[template.dataset_id]),
      );
      if (newViz) cleanedTemplate.visualize_op = newViz;
      if (newFilters && cleanedTemplate.filter_op) {
        cleanedTemplate.filter_op.instructions.filterClauses = newFilters;
      }

      const postData = {
        id: template.id,
        config: cleanedTemplate,
        customer_id: selectedUserGroup?.id,
        dataset: cleanedDataset,
        resource_id: canvasId,
        variables,
        canvas_request: true,
        timezone: 'UTC',
      };

      dispatch(fetchDataPanel({ postData }));

      if (VISUALIZE_TABLE_OPERATIONS.includes(cleanedTemplate.visualize_op.operation_type)) {
        dispatch(fetchDataPanelRowCount({ postData }));
      }
    },
    [dispatch, config.datasets, variables, selectedUserGroup, canvasId],
  );

  const selectTemplate = useCallback(
    (templateId: string, type: 'chart' | 'custom' = 'chart', isNew = false) => {
      if (selectedTemplateInfo?.id === templateId) return;

      setSelectedTemplateInfo({ id: templateId, isNew, type });
      history.replace(`/blueprint/${canvasId}/templates?id=${templateId}`);
    },
    [selectedTemplateInfo, history, canvasId],
  );

  useEffect(() => {
    // Select template from url or first one
    if (selectedTemplateInfo) return;

    const templateId = query.get('id') ?? '';

    if (config.customComponents && templateId in config.customComponents) {
      return selectTemplate(templateId, 'custom');
    }

    if (sortedTemplates.length === 0) return;

    const template = config.templates[templateId] ?? sortedTemplates[0];

    selectTemplate(template.id);
    fetchDataForTemplate(template);
  }, [
    sortedTemplates,
    fetchDataForTemplate,
    selectedTemplateInfo,
    query,
    config.templates,
    config.customComponents,
    selectTemplate,
  ]);

  useEffect(() => {
    // Load newly created templates
    if (selectedTemplateInfo?.type !== 'chart' || !selectedTemplateInfo.isNew) return;

    const template = config.templates[selectedTemplateInfo.id];
    if (template && template._error === undefined && !template._loading) {
      fetchDataForTemplate(template);
    }
  }, [selectedTemplateInfo, config.templates, fetchDataForTemplate]);

  const selectedTemplate = selectedTemplateInfo
    ? selectedTemplateInfo.type === 'chart'
      ? config.templates[selectedTemplateInfo.id]
      : config.customComponents?.[selectedTemplateInfo.id]
    : undefined;

  const renderTemplateItem = (item: CanvasTemplate | CanvasCustomComponent) => {
    const isSelected = item.id === selectedTemplateInfo?.id;
    const isComponent = isCanvasComponent(item);
    return (
      <div
        key={item.id}
        onClick={(e) => {
          e.stopPropagation();
          if (isSelected) return;

          selectTemplate(item.id, isComponent ? 'custom' : 'chart');
          if (!isComponent && item._rows === undefined) fetchDataForTemplate(item);
        }}>
        <div
          className={cx(sharedClasses.item, {
            [sharedClasses.selectedItem]: isSelected,
          })}>
          <div className={sharedClasses.itemInfoFlex}>
            <div className={sharedClasses.itemIcon}>
              {isComponent
                ? CustomComponentIcon(36)
                : getChartIcon(item.visualize_op.operation_type, 36)}
            </div>

            <div className={sharedClasses.textContainer}>
              <div className={sharedClasses.itemTitle}>{item.name}</div>
              {item.description ? (
                <div className={sharedClasses.itemSubTitle}>{item.description}</div>
              ) : null}
            </div>
          </div>

          <div className={sharedClasses.itemStatus}>
            {!isComponent && getCanvasTemplateError(item, config.datasets) !== null ? (
              <Icon className={sharedClasses.errorIcon} icon="error" iconSize={16} />
            ) : null}
            {isSelected ? <ArrowIcon /> : null}
          </div>
        </div>
      </div>
    );
  };

  const renderTemplateList = () => {
    return (
      <div className={sharedClasses.navContainer}>
        <Button
          fillWidth
          className={sprinkles({ marginBottom: 'sp1' })}
          disabled={templateCreationDisabled}
          icon="plus"
          onClick={(e) => {
            e.stopPropagation();

            const newId = `canvas-${canvasId}-${uuidv4()}`;
            dispatch(createCanvasTemplate({ newId }));
            selectTemplate(newId, 'chart', true);
          }}
          type="primary">
          Add a chart
        </Button>
        {sortedTemplates.map(renderTemplateItem)}
        <div className={styles.sectionDivider} />
        <Button
          fillWidth
          className={sprinkles({ marginBottom: 'sp1' })}
          icon="plus"
          onClick={(e) => {
            e.stopPropagation();

            const newId = `canvas-${canvasId}-${uuidv4()}`;
            dispatch(createCanvasComponent(newId));
            selectTemplate(newId, 'custom');
          }}
          type="secondary">
          Add an iframe
        </Button>
        {sortedComponents.map(renderTemplateItem)}
      </div>
    );
  };

  const renderTemplate = () => {
    if (!selectedTemplate) {
      return <div className={sharedClasses.emptyContainer}>Select a Template</div>;
    }

    return (
      <>
        <ColumnHeader title="Preview" />
        <div className={sharedClasses.detailView} style={{ height: '100%' }}>
          <div className={sharedClasses.detailElement}>
            <TemplateView
              datasets={config.datasets}
              template={selectedTemplate}
              variables={variables}
            />
          </div>
        </div>
      </>
    );
  };

  const renderConfig = () => {
    if (!selectedTemplateInfo || !selectedTemplate) {
      return <div className={sharedClasses.emptyContainer}>Select a Template</div>;
    }
    const isComponent = isCanvasComponent(selectedTemplate);

    const templateError = isComponent
      ? null
      : getCanvasTemplateError(selectedTemplate, config.datasets);
    const isNew = selectedTemplateInfo.type === 'chart' ? selectedTemplateInfo.isNew : true;

    return (
      <>
        <ColumnHeader title="Configuration">
          {templateError ? <Tag intent={Intent.ERROR}>{templateError}</Tag> : null}
        </ColumnHeader>

        <div className={sharedClasses.config}>
          <MetadataInputs
            defaultIsOpen={isNew}
            handleNewValueSubmitted={(params) => {
              dispatch(
                isComponent
                  ? updateCanvasComponent({ id: selectedTemplate.id, ...params })
                  : updateCanvasTemplate({ templateId: selectedTemplate.id, ...params }),
              );
            }}
            initialDescription={selectedTemplate.description}
            initialName={selectedTemplate.name}
          />
          {!isComponent ? (
            <ChartEditor
              configurability={CanvasConfigureType.EDITABLE}
              datasets={config.datasets}
              fetchPanelData={({ newViz, newFilters }) =>
                fetchDataForTemplate(selectedTemplate, newViz, newFilters)
              }
              isArchitectCustomerDashboard={false}
              isNew={isNew}
              panel={selectedTemplate}
            />
          ) : (
            <ComponentEditor component={selectedTemplate} isArchitectCustomerDashboard={false} />
          )}
          <div className={sharedClasses.deleteButtonContainer}>
            <Button
              fillWidth
              icon="trash"
              onClick={() => setDeleteModalOpen(true)}
              type="destructive">
              Remove
            </Button>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={sharedClasses.root}>
      <CanvasNav config={config}>{renderTemplateList()}</CanvasNav>
      <div className={sharedClasses.configMenu}>{renderConfig()}</div>
      <div className={sharedClasses.elementView}>{renderTemplate()}</div>
      {selectedTemplateInfo && deleteModalOpen ? (
        <AlertModal
          isOpen
          actionButtonProps={{
            onClick: () => {
              dispatch(
                selectedTemplateInfo.type === 'chart'
                  ? deleteCanvasTemplate({ templateId: selectedTemplateInfo.id })
                  : deleteCanvasComponent(selectedTemplateInfo.id),
              );
              setDeleteModalOpen(false);
            },
            text: 'Delete Template',
          }}
          onClose={() => setDeleteModalOpen(false)}
          title="Are you sure you want to delete this template?">
          Once you&rsquo;ve deleted it you won&rsquo;t be able to retrieve it.
        </AlertModal>
      ) : null}
    </div>
  );
}
