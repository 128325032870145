import { FC, useEffect, useState, useMemo } from 'react';

import { Intent, NonIdealState, Icon } from '@blueprintjs/core';

import { DataGrid, sprinkles } from 'components/ds';
import { PaginatorProps } from 'components/ds/DataGrid/paginator';
import { isPagingDisabled } from 'components/ds/DataGrid/utils';
import { DatasetSchema, DatasetRow, DatabaseUnsupportedOperations } from 'types/datasets';
import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';

const datasetErrorClass = sprinkles({
  flexItems: 'center',
  overflow: 'auto',
  height: 'fill',
  backgroundColor: 'white',
});

type Props = {
  currentQuery: string;
  dataset?: ReportBuilderDataset;
  error: string | undefined;
  isLoading: boolean;
  rows: DatasetRow[] | undefined;
  rowCount: number | undefined;
  schema: DatasetSchema | undefined;
  handlePageChange: (offset: number) => void;
  unsupportedOperations?: DatabaseUnsupportedOperations[];
};

export const DatasetPreviewTable: FC<Props> = ({
  currentQuery,
  error,
  dataset,
  isLoading,
  rowCount,
  rows,
  schema,
  unsupportedOperations,
  handlePageChange,
}) => {
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    // Reset page data when query changes
    setPageNumber(1);
  }, [currentQuery]);

  const paginatorProps: PaginatorProps = useMemo(() => {
    return {
      totalRowCount: rowCount,
      currentPage: pageNumber,
      isPagingDisabled: isPagingDisabled(unsupportedOperations),
      loading: isLoading,
      goToPage: ({ page, offset }) => {
        handlePageChange(offset);
        setPageNumber(page);
      },
    };
  }, [rowCount, pageNumber, handlePageChange, unsupportedOperations, isLoading]);

  if (error) {
    return (
      <div className={datasetErrorClass}>
        <NonIdealState
          className={sprinkles({ justifyContent: 'flex-start' })}
          description={error}
          icon={<Icon icon="error" iconSize={50} intent={Intent.DANGER} />}
          title="There was a problem with your query"
        />
      </div>
    );
  }

  if (currentQuery.trim() === '') {
    return (
      <div className={datasetErrorClass}>
        <NonIdealState
          description="The resulting table will show up here once you write some SQL"
          icon="console"
          title="Write a query to get started"
        />
      </div>
    );
  }

  if ((!rows || !schema) && !isLoading) {
    // they have not run the preview for the query
    return (
      <div className={datasetErrorClass}>
        <NonIdealState
          description="This will run your query but not save it to the dataset. To save, press the save button."
          icon="eye-open"
          title="Click the preview button"
        />
      </div>
    );
  }

  return (
    <div className={sprinkles({ flexItems: 'column', flex: 1, overflow: 'hidden' })}>
      <DataGrid
        columnConfigs={dataset?.columnConfigs}
        loading={isLoading}
        paginatorProps={paginatorProps}
        rows={rows}
        schema={schema}
      />
    </div>
  );
};
