import { useState, FC } from 'react';

import FullPageContentContainer from 'shared/FullPageContentContainer';
import ConnectStepByStep from '../ConnectStepByStep';
import ConnectFAQs from '../ConnectFAQs';
import CalloutLink from 'shared/CalloutLink';
import { CredentialsSection } from './shared/CredentialsSection';
import { AlertModal, sprinkles } from 'components/ds';

import { ConnectDataSourceStep, DATABASES } from '../constants';
import { DBConnectionConfig } from '../types';
import { dataSourceByType } from 'constants/dataSourceConstants';
import { areCredentialsComplete, getPropertyNames } from '../utils';
import { SupportedDataSource, DataSourceConfiguration } from 'actions/dataSourceActions';

type Props = {
  config: DBConnectionConfig;
  updateConfig: (newconfig: DBConnectionConfig) => void;
  onNextClicked: () => void;
  onBackClicked: () => void;
};
const DatabasesToPort: { [key: string]: string } = {
  [DATABASES.POSTGRES]: '5432',
  [DATABASES.REDSHIFT]: '5439',
  [DATABASES.MYSQL]: '3306',
  [DATABASES.AZURE]: '1433',
  [DATABASES.SQLSERVER]: '1433',
  [DATABASES.CLICKHOUSE]: '9440',
};

const PortToDatabase: { [key: string]: DATABASES[] } = {
  '5432': [DATABASES.POSTGRES],
  '5439': [DATABASES.REDSHIFT],
  '3306': [DATABASES.MYSQL],
  '1433': [DATABASES.SQLSERVER, DATABASES.AZURE],
  '9440': [DATABASES.CLICKHOUSE],
};

const EnterCredentials: FC<Props> = ({ config, updateConfig, onNextClicked, onBackClicked }) => {
  const [openModal, setOpenModal] = useState(false);
  const dataSource = config.selectedDataSource;

  if (!dataSource) return <div>Oops</div>;

  const configuration = config.dataSourceConfig || {};
  const port = configuration?.port !== undefined ? configuration.port.toString() : '';
  const expectedPort =
    dataSource?.name !== undefined ? DatabasesToPort[dataSource.name] : undefined;

  const getModalBodyText = () => {
    const expectedDatabases = PortToDatabase[port];
    const normallyUsedText =
      port in PortToDatabase
        ? `(which is normally used by ${
            expectedDatabases.length === 2
              ? `${expectedDatabases[0]} or ${expectedDatabases[1]}`
              : expectedDatabases[0]
          })`
        : '';
    return `${dataSource.name} database are usually on port ${expectedPort} but you have selected port ${port} ${normallyUsedText}. If there is an error, please go back and select the correct database type before proceeding.`;
  };

  const onNextClickedCheckPort = () => {
    if (expectedPort === undefined || expectedPort === port) onNextClicked();
    else setOpenModal(true);
  };

  return (
    <>
      <FullPageContentContainer
        backBtnConfig={{
          onClick: onBackClicked,
        }}
        bodyContent={<EnterCredentialsBody config={config} updateConfig={updateConfig} />}
        headerSubtitle="If you don’t have your database credentials, contact your system administrator."
        headerTitle="Enter Credentials"
        leftSideBarBottomContent={<ConnectFAQs />}
        leftSideBarTopContent={
          <ConnectStepByStep currentStep={ConnectDataSourceStep.ENTER_CREDS} />
        }
        primaryActionConfig={{
          text: 'Next',
          disabled: !areCredentialsComplete(
            dataSource.configuration_schema.properties,
            configuration,
            false,
          ),
          onClick: onNextClickedCheckPort,
        }}
      />
      <AlertModal
        actionButtonProps={{ text: 'Connect anyway', onClick: onNextClicked, type: 'primary' }}
        cancelButtonProps={{ text: 'Go back' }}
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        title="Mismatched credentials with database">
        {getModalBodyText()}
      </AlertModal>
    </>
  );
};

type BodyProps = {
  config: DBConnectionConfig;
  updateConfig: (newconfig: DBConnectionConfig) => void;
  selectedDataSource?: SupportedDataSource;
  userViewableCredentials?: DataSourceConfiguration;
  headerClassName?: string;
};

export const EnterCredentialsBody: FC<BodyProps> = ({
  config,
  updateConfig,
  selectedDataSource,
  userViewableCredentials,
  headerClassName,
}) => {
  const dataSource = config.selectedDataSource ?? selectedDataSource;
  if (!dataSource)
    return (
      <div className={sprinkles({ body: 'b1' })}>
        <b>Error:</b> A data source type must be selected before proceeding
      </div>
    );

  const helpCenterUrl = dataSourceByType[dataSource.type].credentialsLink;

  return (
    <div>
      <CredentialsSection
        config={config}
        dataSource={dataSource}
        headerClassName={headerClassName}
        propertyNames={getPropertyNames(dataSource.configuration_schema, false)}
        updateConfig={updateConfig}
        userViewableCredentials={userViewableCredentials}
      />
      {helpCenterUrl && (
        <CalloutLink text={`How to find ${dataSource.name} credentials`} url={helpCenterUrl} />
      )}
    </div>
  );
};

export default EnterCredentials;
