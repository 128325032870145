import { useCallback, useEffect, useState, FC } from 'react';
import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'url-parse';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { SizeMe } from 'react-sizeme';

import {
  ErrorResponse,
  FetchDashboardDatasetPreviewData,
  FetchDataPanelData,
} from 'actions/responseTypes';
import {
  embedDownloadDataPanelSpreadsheet,
  embedDownloadDashboardImage,
  embedDownloadDashboardPdf,
  embedDownloadDataPanelPdf,
  embedFetchDashboard,
  embedFetchDashboardDatasetPreview,
  embedFetchDataPanelRowCount,
  embedFetchDataPanel,
  embedFetchShareId,
  embedFetchSecondaryData,
  embedFetchDashboardVersions,
} from 'actions/shareActions';
import { ACTION } from 'actions/types';
import { pageView } from 'analytics/exploAnalytics';
import DashboardLayout from 'components/DashboardLayout/DashboardLayout';
import { createLoadingSelector } from 'embeddedContent/reducers/api/selectors';
import { EmbedReduxState } from 'embeddedContent/reducers/rootReducer';
import { DashboardVariableMap, PAGE_TYPE, VIEW_MODE } from 'types/dashboardTypes';
import { GlobalStylesProvider } from 'globalStyles';
import { GlobalStyleConfig } from 'globalStyles/types';
import { EmbeddedDashboardType, shouldSendAnalytics, shouldUseUrlParams } from './types';
import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import {
  sendAnalyticsEvent,
  getPageViewType,
  AnalyticsMetadata,
  sendAnalyticsEventWrapper,
  Metadata,
  getEmbedSource,
} from 'utils/analyticsUtils';
import { loadLocale } from 'utils/localizationUtils';
import { getTimezone } from 'utils/timezoneUtils';
import { getLayoutFromDashboardVersionConfig } from 'utils/dashboardUtils';
import { GenericLoadingSpinner } from './GenericLoadingSpinner';
import { loadFonts } from 'globalStyles/helpers';
import UsePageVisibility from 'components/HOCs/usePageVisibility';
import {
  DownloadDataPanelSpreadsheetBody,
  FetchDataPanelBody,
  FetchDataPanelRowCountBody,
  FetchSecondaryDataBody,
  updateDrilldownDataPanel,
} from 'actions/dataPanelTemplateAction';
import { FetchDashboardDatasetPreviewBody } from 'actions/datasetActions';
import { showExploBranding } from 'utils/paymentPlanUtils';
import getFingerprintUser from 'analytics/fingerprint';
import { DownloadDataPanelBody, DownloadDashboardBody } from 'actions/exportActions';
import { BulkEnqueueFnArgs, bulkEnqueueJobs } from 'actions/jobQueueActions';
import { Jobs } from 'components/JobQueue/types';
import { MOBILE_BREAKPOINT_WIDTH } from 'constants/dashboardConstants';
import { setUser, reportError } from 'analytics/datadog';
import { sprinkles } from 'components/ds';

const useStyles = makeStyles((theme: Theme) => ({
  errorMessage: {
    margin: theme.spacing(10),
    fontSize: 24,
    padding: theme.spacing(10),
    backgroundColor: '#FAE1E1',
    borderRadius: 8,
  },
}));

const ErrorFallback: FC<FallbackProps> = ({ error }) => {
  const classes = useStyles();
  return (
    <div className={classes.errorMessage} role="alert">
      {error && error.message
        ? error.message
        : 'There was an error loading the dashboard. Please contact your support team for help.'}
    </div>
  );
};

type Props = {
  dashboardEmbedId: string;
  viewMode: VIEW_MODE;
  embedType: EmbeddedDashboardType;
  customerToken: string;
  customStyles?: GlobalStyleConfig;
  embeddedVariables?: DashboardVariableMap;
  environment?: string;
  versionNumber?: number;
  isProduction?: string;
  isStrict?: boolean;
  refreshMinutes?: number;
  updateUrlParams?: boolean;
  localeCode?: string;
  timezone?: string;
  analyticsProperties?: Metadata;
};

const EmbeddedDashboardWrapper: FC<Props> = ({ customStyles, ...dashboardProps }) => {
  const globalStyleConfig = useSelector(
    (state: EmbedReduxState) => state.dashboardStyles.globalStyleConfig,
  );
  const team = useSelector((state: EmbedReduxState) => state.embedDashboard.team);

  useEffect(() => {
    if (team) {
      loadFonts({ ...globalStyleConfig.text, ...customStyles?.text }, team.id, team.payment_plan);
    }
  }, [globalStyleConfig, customStyles, team]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={reportError}>
      <GlobalStylesProvider globalStyleConfig={{ ...globalStyleConfig, ...customStyles }}>
        {(globalStylesClassName) => (
          <SizeMe>
            {({ size }) => (
              <EmbeddedDashboard
                globalStylesClassName={globalStylesClassName}
                width={size.width}
                {...dashboardProps}
              />
            )}
          </SizeMe>
        )}
      </GlobalStylesProvider>
    </ErrorBoundary>
  );
};

export default EmbeddedDashboardWrapper;

const EmbeddedDashboard: FC<Props & { globalStylesClassName: string; width: number | null }> = (
  props,
) => {
  const {
    viewMode,
    embedType,
    dashboardEmbedId,
    embeddedVariables,
    environment: environmentProp,
    versionNumber,
    isProduction: isProductionProp,
    isStrict,
    refreshMinutes,
    updateUrlParams,
    customerToken,
    localeCode,
    timezone: passedTimezone,
    globalStylesClassName,
    width,
    analyticsProperties,
  } = props;
  const classes = useStyles();

  const [visitorId, setVisitorId] = useState('');
  const [analyticsMetadata, setAnalyticsMetadata] = useState<AnalyticsMetadata | undefined>();
  const [urlVariables] = useState<DashboardVariableMap>(
    getQueryVariables(embedType, updateUrlParams),
  );

  const dispatch = useDispatch();

  const { dashboardTemplate, dashboardVersion, team, userGroup, errorMsg } = useSelector(
    (state: EmbedReduxState) => state.embedDashboard,
  );

  const dashboardLoading = useSelector((state: EmbedReduxState) =>
    createLoadingSelector([ACTION.EMBED_FETCH_DASHBOARD], true)(state),
  );

  const {
    version_number: dashboardVersionNumber,
    configuration: dashboardConfig,
    id: dashboardVersionId,
  } = dashboardVersion ?? {};

  const sendInitialPageView = useCallback(() => {
    switch (embedType) {
      case 'shared':
        if (isStrict) pageView('Shared Dashboard Page - Strict Format');
        else pageView('Shared Dashboard Page');
        break;
      case 'iframe':
        if (isStrict) pageView('Iframe Dashboard - Strict Format');
        else pageView('Iframe Dashboard');
        break;
      default:
        break;
    }
  }, [embedType, isStrict]);

  const fetchDashboardData = useCallback(() => {
    const queryVariables = getQueryVariables(embedType, updateUrlParams);
    const environment = environmentProp || queryVariables['environment'];
    const isProduction = isProductionProp ?? queryVariables['is_production'];

    dispatch(
      embedFetchDashboard(
        {
          customerToken,
          postData: {
            dashboard_embed_id: dashboardEmbedId,
            version_number: versionNumber,
            environment: environment as string | undefined,
          },
        },
        (data) => {
          const metadata: AnalyticsMetadata = {
            team_id: data.team.id,
            team_name: data.team.team_name,
            customer_id: data.customer.id,
            customer_name: data.customer.name,
            customer_provided_id: data.customer.provided_id,
            customer_is_demo: data.customer.is_demo_group,
            dashboard_template_id: data.dashboard_template.id,
            dashboard_template_name: data.dashboard_template.name,
          };

          setUser({
            endUserId: data.customer.id,
            endUserName: data.customer.name,
            teamId: data.team.id,
            teamName: data.team.team_name,
          });

          const sendPageViewAnalytics = async () => {
            if (!shouldSendAnalytics(embedType)) return;
            // Get the (browser) visitor identifier:
            const fingerprintUser = await getFingerprintUser();
            setVisitorId(fingerprintUser.visitorId);

            // Send page view analytics
            sendAnalyticsEvent(
              getPageViewType(embedType),
              fingerprintUser.visitorId,
              customerToken,
              data.team.explo_analytics_token,
              {
                ...metadata,
                is_production: isProduction ? isProduction === 'true' : undefined,
                environment,
                is_strict: isStrict && { isStrict: true },
                embed_source: embedType === 'shared' ? 'share' : embedType,
              },
              analyticsProperties,
            );
          };

          sendPageViewAnalytics();
          setAnalyticsMetadata(metadata);
          loadLocale({
            passedCurrencyCode: getValueOrDefault('currency_code'),
            passedLocaleCode: getValueOrDefault('locale_code', localeCode),
            teamCurrencyCode: data.team.default_currency_code,
            teamLocaleCode: data.team.default_locale_code,
            useBrowserLocale: data.team.use_browser_locale,
          });
        },
      ),
    );
  }, [
    analyticsProperties,
    customerToken,
    dashboardEmbedId,
    dispatch,
    embedType,
    environmentProp,
    isProductionProp,
    isStrict,
    localeCode,
    updateUrlParams,
    versionNumber,
  ]);

  const onLoad = () => {
    sendInitialPageView();
    fetchDashboardData();
  };
  useEffect(onLoad, [
    dashboardEmbedId,
    customerToken,
    embeddedVariables,
    sendInitialPageView,
    fetchDashboardData,
  ]);

  const isVisible = UsePageVisibility();

  const analyticsEventTracker = useCallback(
    (eventName: REPORTED_ANALYTIC_ACTION_TYPES, metadata?: Metadata) => {
      if (!shouldSendAnalytics(embedType)) return;

      sendAnalyticsEventWrapper(
        visitorId,
        embedType,
        getEmbedSource(embedType),
        customerToken,
        team?.explo_analytics_token,
        eventName,
        analyticsMetadata,
        metadata,
        analyticsProperties,
      );
    },
    [visitorId, embedType, analyticsProperties, analyticsMetadata, team, customerToken],
  );

  const fetchShareDataWrapper = useCallback(
    (password?: string, username?: string, isStrictViewingMode?: boolean) => {
      const commonPostData = {
        dashboard_embed_id: dashboardEmbedId,
      };
      const dashboardId = dashboardTemplate?.id;

      dispatch(
        embedFetchDashboardVersions(
          { id: dashboardTemplate?.id, customerToken, postData: commonPostData },
          (data) => {
            const environmentTagId = dashboardId
              ? data.tags.find(
                  (tag) =>
                    tag.dashboard_versions_by_dashboard?.[dashboardId] === dashboardVersionId,
                )?.id
              : undefined;

            dispatch(
              embedFetchShareId({
                customerToken,
                postData: {
                  ...commonPostData,
                  ...(!environmentTagId && { version_number: dashboardVersionNumber }),
                  ...(environmentTagId && { environment_tag_id: environmentTagId }),
                  ...(password && { password: password }),
                  is_strict_viewing_mode: isStrictViewingMode === true,
                },
              }),
            );
          },
        ),
      );
    },
    [
      dispatch,
      dashboardEmbedId,
      customerToken,
      dashboardVersionNumber,
      dashboardVersionId,
      dashboardTemplate,
    ],
  );

  const dashboardTimezone = getTimezone(
    dashboardTemplate?.default_timezone,
    getValueOrDefault('timezone', passedTimezone),
  );

  const fetchDataPanelRowCountWrapper = useCallback(
    (data: { postData: FetchDataPanelRowCountBody }) => {
      if (!dashboardVersionNumber) return;
      return dispatch(
        embedFetchDataPanelRowCount({
          customerToken,
          postData: {
            ...data.postData,
            timezone: dashboardTimezone,
            versionNumber: dashboardVersionNumber,
            resource_embed_id: dashboardEmbedId,
          },
        }),
      );
    },
    [customerToken, dashboardVersionNumber, dispatch, dashboardEmbedId, dashboardTimezone],
  );

  const fetchDataPanelTemplateWrapper = useCallback(
    (data: { postData: FetchDataPanelBody }, onSuccess?: (data: FetchDataPanelData) => void) => {
      if (!dashboardVersionNumber) return;

      return dispatch(
        embedFetchDataPanel(
          {
            customerToken,
            postData: {
              ...data.postData,
              timezone: dashboardTimezone,
              versionNumber: dashboardVersionNumber,
              resource_embed_id: dashboardEmbedId,
            },
          },
          onSuccess,
        ),
      );
    },
    [customerToken, dashboardVersionNumber, dispatch, dashboardEmbedId, dashboardTimezone],
  );

  const fetchDatasetPreviewWrapper = useCallback(
    (
      data: { postData: FetchDashboardDatasetPreviewBody },
      onSuccess?: (data: FetchDashboardDatasetPreviewData) => void,
      onError?: (errorMessage: ErrorResponse) => void,
    ) => {
      if (!dashboardVersionNumber) return;

      return dispatch(
        embedFetchDashboardDatasetPreview(
          {
            customerToken,
            postData: {
              ...data.postData,
              timezone: dashboardTimezone,
              versionNumber: dashboardVersionNumber,
              resource_embed_id: dashboardEmbedId,
            },
          },
          onSuccess,
          onError,
        ),
      );
    },
    [customerToken, dashboardVersionNumber, dispatch, dashboardEmbedId, dashboardTimezone],
  );

  const fetchSecondaryDataWrapper = useCallback(
    (data: { postData: FetchSecondaryDataBody }) => {
      if (!dashboardVersionNumber) return;
      return dispatch(
        embedFetchSecondaryData({
          customerToken,
          postData: {
            ...data.postData,
            timezone: dashboardTimezone,
            versionNumber: dashboardVersionNumber,
            is_secondary_data_query: true,
            resource_embed_id: dashboardEmbedId,
          },
        }),
      );
    },
    [dispatch, dashboardVersionNumber, customerToken, dashboardEmbedId, dashboardTimezone],
  );

  const downloadCsvWrapper = useCallback(
    (data: { postData: DownloadDataPanelSpreadsheetBody }) => {
      if (!dashboardVersionNumber) return;
      dispatch(
        embedDownloadDataPanelSpreadsheet({
          customerToken,
          postData: {
            ...data.postData,
            timezone: dashboardTimezone,
            versionNumber: dashboardVersionNumber,
            resource_embed_id: dashboardEmbedId,
          },
        }),
      );
    },
    [customerToken, dashboardVersionNumber, dispatch, dashboardEmbedId, dashboardTimezone],
  );

  const downloadDptPdfWrapper = useCallback(
    (data: { postData: DownloadDataPanelBody }) => {
      dispatch(
        embedDownloadDataPanelPdf({
          customerToken,
          postData: {
            ...data.postData,
            timezone: dashboardTimezone,
            resource_embed_id: dashboardEmbedId,
          },
        }),
      );
    },
    [dispatch, dashboardEmbedId, customerToken, dashboardTimezone],
  );

  const downloadDashboardImageWrapper = useCallback(
    (data: { postData: DownloadDashboardBody }) => {
      dispatch(
        embedDownloadDashboardImage({
          customerToken,
          postData: {
            ...data.postData,
            timezone: dashboardTimezone,
            resource_embed_id: dashboardEmbedId,
          },
        }),
      );
    },
    [dispatch, dashboardEmbedId, customerToken, dashboardTimezone],
  );

  const downloadDashboardPdfWrapper = useCallback(
    (data: { postData: DownloadDashboardBody }) => {
      dispatch(
        embedDownloadDashboardPdf({
          customerToken,
          postData: {
            ...data.postData,
            timezone: dashboardTimezone,
            resource_embed_id: dashboardEmbedId,
          },
        }),
      );
    },
    [dispatch, dashboardEmbedId, customerToken, dashboardTimezone],
  );

  const bulkEnqueueJobsWrapper = useCallback(
    (
      args: BulkEnqueueFnArgs,
      onSuccess?: (jobs: Record<string, Jobs>) => void,
      onError?: (errorMsg: ErrorResponse) => void,
    ) => {
      if (!dashboardVersionNumber) return;

      Object.values(args.jobs).forEach((jobDefinition) => {
        jobDefinition.job_args['timezone'] = dashboardTimezone;
        jobDefinition.job_args['resource_embed_id'] = dashboardEmbedId;
        jobDefinition.job_args['version_number'] = dashboardVersionNumber;
      });

      return dispatch(bulkEnqueueJobs({ ...args, customerToken }, onSuccess, onError));
    },
    [dashboardVersionNumber, dispatch, customerToken, dashboardEmbedId, dashboardTimezone],
  );

  if (dashboardLoading) {
    return <GenericLoadingSpinner embedType={embedType ?? 'embedded'} />;
  } else if (errorMsg) {
    return (
      <div className={classes.errorMessage} role="alert">
        {errorMsg}
      </div>
    );
  } else if (!dashboardTemplate || !dashboardConfig) {
    throw Error(
      'There was an error loading the dashboard. Please contact your support team for help.',
    );
  }

  const getViewMode = () => {
    if (viewMode === VIEW_MODE.EMAIL || viewMode === VIEW_MODE.PDF) return viewMode;
    if (width && width < MOBILE_BREAKPOINT_WIDTH) {
      return VIEW_MODE.MOBILE;
    }

    return viewMode;
  };

  const calculatedViewMode = getViewMode();
  const shouldFillViewport =
    dashboardConfig.dashboard_page_layout_config?.stickyHeader?.enabled &&
    (embedType === 'iframe' || embedType === 'shared');

  return (
    <div
      className={cx(
        sprinkles({ height: shouldFillViewport ? 'fillViewport' : 'fill' }),
        globalStylesClassName,
      )}
      id={embedType === 'embedded' ? undefined : 'shared-explo-dashboard'}>
      <DashboardLayout
        isViewOnly
        analyticsEventTracker={analyticsEventTracker}
        bulkEnqueueJobsWrapper={bulkEnqueueJobsWrapper}
        customerToken={customerToken}
        dashboardElements={Object.values(dashboardConfig.elements)}
        dashboardLayout={getLayoutFromDashboardVersionConfig(dashboardConfig, calculatedViewMode)}
        dataPanels={Object.values(dashboardConfig.data_panels)}
        datasets={dashboardConfig.datasets}
        disableInputs={isStrict}
        downloadDashboardImage={downloadDashboardImageWrapper}
        downloadDashboardPdf={downloadDashboardPdfWrapper}
        downloadDataPanelPdf={downloadDptPdfWrapper}
        downloadDataPanelSpreadsheet={downloadCsvWrapper}
        editableDashboard={false}
        exploResource={dashboardTemplate}
        fetchDataPanel={fetchDataPanelTemplateWrapper}
        fetchDataPanelRowCount={fetchDataPanelRowCountWrapper}
        fetchDatasetPreview={fetchDatasetPreviewWrapper}
        fetchSecondaryData={fetchSecondaryDataWrapper}
        fetchShareData={fetchShareDataWrapper}
        isVisible={isVisible}
        pageLayoutConfig={dashboardConfig.dashboard_page_layout_config}
        pageType={embedType === 'shared' ? PAGE_TYPE.SHARED : PAGE_TYPE.EMBEDDED}
        refreshMinutes={parseInt(getValueOrDefault('refresh_minutes', refreshMinutes)) || undefined}
        resourceVersionNumber={dashboardVersionNumber}
        shouldUseJobQueue={team?.feature_flags.use_job_queue}
        showExploBranding={showExploBranding(team?.payment_plan)}
        supportEmail={team?.support_email ?? undefined}
        timezone={dashboardTimezone}
        updateDrilldownDataPanel={(args) => dispatch(updateDrilldownDataPanel(args))}
        updateUrlParams={shouldUseUrlParams(embedType, updateUrlParams)}
        userGroup={userGroup}
        variablesDefaultValues={{ ...urlVariables, ...embeddedVariables }}
        viewMode={calculatedViewMode}
        width={width}
      />
    </div>
  );
};

export function getQueryVariables(embedType: EmbeddedDashboardType, updateUrlParams?: boolean) {
  if (!shouldUseUrlParams(embedType, updateUrlParams)) return {};

  const rawVars = parse(window.location.href, true).query;
  const queryVariables: DashboardVariableMap = {};

  if (rawVars) {
    Object.keys(rawVars).forEach((key) => {
      if (['refresh_minutes', 'userTransformedSchema', 'timezone'].includes(key)) return;

      try {
        const val = rawVars[key];
        if (val) queryVariables[key] = JSON.parse(val);
      } catch (e) {
        return;
      }
    });
  }

  return queryVariables;
}

function getValueOrDefault(searchValue: string, value?: string | number) {
  if (value) return value;

  const rawVars = parse(window.location.href, true).query;

  // for some reason the compiler isn't allowing that rawVars[searchValue] can't be null in the return
  if (rawVars && rawVars[searchValue]) return JSON.parse(rawVars[searchValue] ?? '');

  return undefined;
}
