import { useState } from 'react';
import { Classes } from '@blueprintjs/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Modal from 'components/core/Modal';
import Button from 'shared/Button';
import DropdownSelect from 'shared/DropdownSelect';

import { DashboardTemplate } from 'actions/dashboardTemplateActions';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '600px',
  },
  body: {
    margin: `0 ${theme.spacing(5)}px`,
    marginBottom: theme.spacing(5),
  },
  rightButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dropdownContainer: {
    marginTop: theme.spacing(3),
  },
}));

type Props = {
  activateDashboard: (disableDashboardId: number) => void;
  closeModal: () => void;
  dashboardTemplateList: DashboardTemplate[];
  modalOpen: boolean;
  selectedDashboardTemplateId: number;
};

const ActivateDisabledDashboardModal = ({
  activateDashboard,
  closeModal,
  dashboardTemplateList,
  modalOpen,
  selectedDashboardTemplateId,
}: Props) => {
  const classes = useStyles();
  const [selectedDisableDashboardId, setSelectedDisableDashboardId] = useState<number>();

  const selectedDashboard = dashboardTemplateList.find(
    (dashboard) => dashboard.id === selectedDisableDashboardId,
  );

  return (
    <Modal
      className={classes.root}
      modalOpen={modalOpen}
      onClose={closeModal}
      title="Activate Dashboard">
      <div className={classes.body}>
        <div>
          On your current plan, you may only have 3 active dashboards at a time. In order to
          activate this dashboard, please select a dashboard you would like to deactivate.
        </div>
        <DropdownSelect
          fillWidth
          ignoreCustomStyles
          minimal
          containerClassName={classes.dropdownContainer}
          filterable={false}
          noSelectionText="Select a dashboard to deactivate"
          onChange={(item) => {
            setSelectedDisableDashboardId(parseInt(item.id));
          }}
          options={dashboardTemplateList
            .filter(
              (dashboard) => !dashboard.disabled && dashboard.id !== selectedDashboardTemplateId,
            )
            .map((dashboard) => ({
              id: String(dashboard.id),
              name: dashboard.name,
            }))}
          selectedItem={
            selectedDashboard
              ? { id: String(selectedDashboard.id), name: selectedDashboard.name }
              : undefined
          }
        />
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={classes.rightButtonContainer}>
          <Button
            disabled={!selectedDisableDashboardId}
            loading={false}
            onClick={() =>
              selectedDisableDashboardId && activateDashboard(selectedDisableDashboardId)
            }
            text="Activate"
            type="primary"
          />
        </div>
      </div>
    </Modal>
  );
};

export default ActivateDisabledDashboardModal;
