import { cloneDeep, keyBy } from 'utils/standard';

import { OPERATION_TYPES, UserTransformedSchema, SchemaChange } from 'constants/types';
import { DataPanel, ResourceDataset } from 'types/exploResource';
import { getDataPanelDatasetId, isCanvasDataset } from 'utils/exploResourceUtils';

import {
  sortSchemaChangeByOrderedColumnNames,
  sortAggregationsByOrderedColumnNames,
} from './general';

export const getTransformedDataPanelForCsv = (
  dataPanel: DataPanel,
  userTransformedSchema: UserTransformedSchema | undefined,
  datasets: Record<string, ResourceDataset>,
): DataPanel => {
  const operationType = dataPanel.visualize_op.operation_type;
  const shouldUseTransformedSchemaForTable =
    operationType === OPERATION_TYPES.VISUALIZE_TABLE &&
    (dataPanel.visualize_op.instructions.VISUALIZE_TABLE.isSchemaCustomizationEnabled ||
      dataPanel.id === '_drilldown_data_panel');
  const shouldUseTransformedSchema =
    userTransformedSchema &&
    (shouldUseTransformedSchemaForTable ||
      operationType === OPERATION_TYPES.VISUALIZE_REPORT_BUILDER);

  const newDp = cloneDeep(dataPanel);

  if (shouldUseTransformedSchema) {
    // We check the truthiness of userTransformedSchema above
    incorporateUserSchemaOverrides(newDp, userTransformedSchema);
  } else {
    incorporateNewColumns(newDp, datasets);
  }

  return newDp;
};

const incorporateUserSchemaOverrides = (
  dp: DataPanel,
  userTransformedSchema: UserTransformedSchema,
): void => {
  const changeSchemaList = dp.visualize_op.instructions.VISUALIZE_TABLE.changeSchemaList;
  const changeSchemaByColName = keyBy(changeSchemaList, 'col');

  dp.visualize_op.instructions.VISUALIZE_TABLE.changeSchemaList = userTransformedSchema.map(
    (userTransformedCol) => {
      const originalChangeSchema = changeSchemaByColName[userTransformedCol.name];
      return {
        col: userTransformedCol.name,
        newColName: userTransformedCol?.friendly_name || originalChangeSchema.newColName,
        keepCol: userTransformedCol.isVisible,
      };
    },
  );
};

const incorporateNewColumns = (dp: DataPanel, datasets: Record<string, ResourceDataset>): void => {
  const allCols = dp._schema || [];
  const operationType = dp.visualize_op.operation_type;

  if (operationType === OPERATION_TYPES.VISUALIZE_TABLE) {
    const instructions = dp.visualize_op.instructions.VISUALIZE_TABLE;
    const newChangeSchemaList: SchemaChange[] = instructions.changeSchemaList;

    // need to check columnOptions for canvas because schema includes columns with are not visible
    const dataset = datasets[getDataPanelDatasetId(dp)];
    // need to check columnOptions for canvas because schema includes columns with are not visible
    const columnOptions = isCanvasDataset(dataset) ? dataset.columnOptions : undefined;

    // Add in the columns to changeSchemaList that never changed from default
    allCols.forEach(({ name }) => {
      const foundChangeSchemaItem = instructions.changeSchemaList.find(({ col }) => col === name);
      if (
        !foundChangeSchemaItem &&
        (columnOptions === undefined || columnOptions[name]?.isVisible)
      ) {
        // Case in Explore or case in Architect and column is explicitly visible
        newChangeSchemaList.push({ col: name, keepCol: true });
      }
    });

    dp.visualize_op.instructions.VISUALIZE_TABLE.changeSchemaList =
      sortSchemaChangeByOrderedColumnNames(newChangeSchemaList, instructions.orderedColumnNames);
  }

  // Ensures csv download for collapsible list reflects orderedColumnNames
  if (
    operationType === OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST &&
    dp.visualize_op.instructions.VISUALIZE_COLLAPSIBLE_LIST?.aggregations !== undefined
  ) {
    dp.visualize_op.instructions.VISUALIZE_COLLAPSIBLE_LIST.aggregations =
      sortAggregationsByOrderedColumnNames(
        dp.visualize_op.instructions.VISUALIZE_COLLAPSIBLE_LIST.aggregations,
        dp.visualize_op.instructions.VISUALIZE_COLLAPSIBLE_LIST.orderedColumnNames,
      );
  }
};
