import { FC } from 'react';
import cx from 'classnames';
import { sprinkles } from 'components/ds';

import { PanelName } from './PanelName';
import { UrlClickThroughButton } from 'components/UrlClickThrough';
import { ChartMenu } from 'components/ChartMenu';
import { ColorCategoryDropdown } from 'pages/dashboardPage/DashboardDatasetView/Header/ColorCategoryDropdown';

import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { LinkFormat, ColorFilterPlacement } from 'constants/types';

import { Props as ColorCategoryProps } from './ColorCategoryDropdown';
import { ExportProps } from 'components/ChartMenu';
import { OpenDrilldownModalType } from 'types/dashboardTypes';

type Props = {
  dataPanelId: string;
  infoTooltipText?: string;
  includeFullPadding?: boolean;
  isHeaderHidden?: boolean;
  loading: boolean;
  disableMenu: boolean;
  title: string;
  exportProps: ExportProps;
  colorCategoryProps?: ColorCategoryProps;
  linkFormat?: LinkFormat;
  hideIcons: boolean;
  openDrilldownModal?: OpenDrilldownModalType;
};

export const ChartHeader: FC<Props> = ({
  dataPanelId,
  loading,
  disableMenu,
  infoTooltipText,
  isHeaderHidden,
  includeFullPadding,
  title,
  exportProps,
  colorCategoryProps,
  linkFormat,
  hideIcons,
  openDrilldownModal,
}) => {
  const shouldRenderClickThrough = linkFormat && linkFormat.link && linkFormat.url;

  const categoryDropdownBelowTitle =
    !!colorCategoryProps &&
    colorCategoryProps.instructions?.colorFilterPlacement === ColorFilterPlacement.BELOW_TITLE;
  const categoryDropdownRight = !!colorCategoryProps && !categoryDropdownBelowTitle;

  const exportEnabled = !exportProps.disableDownloadExport || exportProps.enableEmailExport;

  const reserveIconSpace =
    !hideIcons &&
    (shouldRenderClickThrough || openDrilldownModal || exportEnabled || categoryDropdownRight);

  const notEmptyHeader = !isHeaderHidden && (infoTooltipText || title);

  const reserveHeaderSpace = reserveIconSpace || notEmptyHeader;

  if (!reserveHeaderSpace && !categoryDropdownBelowTitle) return null;

  const renderHeaderIcons = () => {
    if (hideIcons) return null;

    return (
      <>
        <UrlClickThroughButton reduceOpacity linkFormat={linkFormat} />
        <ChartMenu
          reduceOpacity
          dataPanelId={dataPanelId}
          disabled={disableMenu}
          exportProps={exportProps}
          openDrilldownModal={openDrilldownModal}
        />
      </>
    );
  };

  const renderCategoryDropdown = (shouldRender: boolean) => {
    if (!shouldRender || !colorCategoryProps) return null;

    return (
      <div className={categoryDropdownRight ? sprinkles({ marginLeft: 'sp.5' }) : undefined}>
        <ColorCategoryDropdown {...colorCategoryProps} />
      </div>
    );
  };

  const renderHeaderIconsAndCategoryDropdown = () => {
    if (hideIcons && !categoryDropdownRight) return null;

    return (
      <div
        className={sprinkles({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        })}>
        {renderHeaderIcons()}
        {renderCategoryDropdown(categoryDropdownRight)}
      </div>
    );
  };

  return (
    <div
      className={cx(
        sprinkles({ flexItems: 'column', gap: 'sp1', paddingBottom: 'sp2' }),
        includeFullPadding ? sprinkles({ padding: 'sp2' }) : undefined,
        GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.default.borderRadiusTop,
      )}>
      {reserveHeaderSpace ? (
        <div
          className={sprinkles({
            justifyContent: !isHeaderHidden ? 'space-between' : 'flex-end',
            display: 'flex',
            alignItems: 'center',
          })}
          style={{ height: 32 }}>
          {!isHeaderHidden ? (
            <PanelName infoTooltipText={infoTooltipText} loading={loading} panelName={title} />
          ) : null}

          {renderHeaderIconsAndCategoryDropdown()}
        </div>
      ) : null}

      {renderCategoryDropdown(categoryDropdownBelowTitle)}
    </div>
  );
};
