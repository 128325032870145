import { useMemo } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'utils/standard';
import cx from 'classnames';

import Button from 'shared/Button';
import InputGroup from 'explo-ds/forms/marketing/inputGroup';
import DropdownSelect from 'shared/DropdownSelect';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';

import {
  FilterClause,
  FilterValueRelativeDateType,
  SelectedDropdownInputItem,
} from 'constants/types';
import { CanvasDataset, updateCanvasTemplate } from 'actions/canvasConfigActions';
import {
  DATE_RELATIVE_OPTION,
  DATE_RELATIVE_OPTIONS,
  DATE_RELATIVE_OPTIONS_BY_ID,
  EMPTY_FILTER_CLAUSE,
} from 'constants/dataPanelEditorConstants';
import {
  FilterOperator,
  FILTER_OPERATORS,
  FILTER_OPS_DATE_PICKER,
  FILTER_OPS_DATE_RANGE_PICKER,
  FILTER_OPS_NO_VALUE,
  FILTER_OPS_RELATIVE_PICKER,
} from 'types/filterOperations';
import { updateEUDataPanelFilters } from 'actions/architectCustomerDashboardConfigActions';
import { isFilterClauseIncomplete } from 'utils/dataPanelConfigUtils';
import { newOperatorShouldClearSelectedVariable } from 'utils/dashboardUtils';

type StyleProps = Record<'isArchitectCustomerDashboard', boolean>;

const useStyles = makeStyles((theme: Theme) => ({
  container: ({ isArchitectCustomerDashboard }: StyleProps) => ({
    padding: isArchitectCustomerDashboard ? theme.spacing(2) : '0px 12px',
  }),
  filter: ({ isArchitectCustomerDashboard }: StyleProps) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: isArchitectCustomerDashboard ? 8 : 10,
  }),
  flexOne: ({ isArchitectCustomerDashboard }: StyleProps) => ({
    overflow: isArchitectCustomerDashboard ? 'hidden' : undefined,
    flex: 1,
  }),
  flexTwo: ({ isArchitectCustomerDashboard }: StyleProps) => ({
    overflow: isArchitectCustomerDashboard ? 'hidden' : undefined,
    flex: 2,
  }),
  filterRow: {
    width: '100%',
    display: 'flex',
    height: 32,
    marginBottom: 2,
  },
  twoRowButton: {
    height: '66px !important',
  },
  columnDropdown: {
    '& .bp3-button': {
      borderTopRightRadius: '0px !important',
      borderBottomRightRadius: '0px !important',
    },
  },
  opDropdown: {
    '& .bp3-button': {
      borderTopLeftRadius: '0px !important',
      borderBottomLeftRadius: '0px !important',
      borderLeft: 'none !important',
    },
  },
  fullWidth: { width: '100%' },
  relativeNumInput: { marginRight: 2 },
}));

type Props = {
  dataset: CanvasDataset;
  filters: FilterClause[];
  isArchitectCustomerDashboard: boolean;
  panelId: string;

  fetchPanelData: (newFilters: FilterClause[]) => void;
};

export default function PanelFilters({
  dataset,
  filters,
  isArchitectCustomerDashboard,
  panelId,
  fetchPanelData,
}: Props): JSX.Element {
  const classes = useStyles({ isArchitectCustomerDashboard });
  const dispatch = useDispatch();

  const updateFilters = (updateFunc: (newFilters: FilterClause[]) => void, requestData = false) => {
    const newFilters = cloneDeep(filters);
    updateFunc(newFilters);

    if (requestData) fetchPanelData(newFilters);

    if (isArchitectCustomerDashboard) dispatch(updateEUDataPanelFilters(newFilters));
    else dispatch(updateCanvasTemplate({ templateId: panelId, filters: newFilters }));
  };

  const replaceFilter = (idx: number, filter: FilterClause) => {
    const olderFilterWasReady = !isFilterClauseIncomplete(filters[idx]);
    const filterIsReady = !isFilterClauseIncomplete(filter);
    updateFilters(
      (newFilters) => newFilters.splice(idx, 1, filter),
      filterIsReady || olderFilterWasReady,
    );
  };

  const sharedDropdownProps = {
    fillWidth: true,
    ignoreCustomStyles: !isArchitectCustomerDashboard,
    minimal: true,
    filterable: false,
    usePortal: isArchitectCustomerDashboard,
  };

  const colOptions = useMemo(
    () =>
      dataset.schema?.reduce((acc, col) => {
        const colOption = dataset.columnOptions[col.name];
        if (colOption && colOption.isVisible) {
          acc.push({ id: col.name, name: colOption.name });
        }
        return acc;
      }, [] as SelectedDropdownInputItem[]) ?? [],
    [dataset],
  );

  const getOperatorOptions = ({ filterColumn }: FilterClause) => {
    if (!filterColumn) return [];
    return FILTER_OPERATORS.reduce<SelectedDropdownInputItem[]>((acc, op) => {
      if (
        op.supported_column_types.has(filterColumn.type) &&
        //Remove date options for now
        !(FILTER_OPS_DATE_PICKER.has(op.id) || FILTER_OPS_DATE_RANGE_PICKER.has(op.id))
      ) {
        acc.push({ id: op.id, name: op.selectionValue });
      }
      return acc;
    }, []);
  };

  const renderValueInput = (idx: number, filter: FilterClause) => {
    if (!filter.filterColumn || !filter.filterOperation) {
      return (
        <div className={classes.filterRow}>
          <InputGroup disabled className={classes.fullWidth} placeholder="Value" />
        </div>
      );
    }

    if (FILTER_OPS_NO_VALUE.has(filter.filterOperation.id)) return null;
    if (FILTER_OPS_RELATIVE_PICKER.has(filter.filterOperation.id)) {
      const filterValueRelativeDate = filter.filterValue as FilterValueRelativeDateType | undefined;

      const selectedRelativeTime = filterValueRelativeDate?.relativeTimeType
        ? DATE_RELATIVE_OPTIONS_BY_ID[filterValueRelativeDate.relativeTimeType.id]
        : undefined;

      return (
        <div className={classes.filterRow}>
          <InputWithBlurSave
            containerClassName={cx(classes.relativeNumInput, classes.flexOne)}
            ignoreCustomStyles={!isArchitectCustomerDashboard}
            initialValue={
              filterValueRelativeDate ? String(filterValueRelativeDate.number || '') : ''
            }
            onNewValueSubmitted={(newValue) => {
              const newValNum = parseInt(newValue);

              replaceFilter(idx, {
                ...filter,
                filterValue: {
                  ...filterValueRelativeDate,
                  number: isNaN(newValNum) ? undefined : newValNum,
                },
              });
            }}
            placeholder="Number"
          />
          <DropdownSelect
            {...sharedDropdownProps}
            containerClassName={classes.flexOne}
            noSelectionText="Select time"
            onChange={(item) => {
              if (item.id === filterValueRelativeDate?.relativeTimeType?.id) return;
              const filterValue = {
                ...filterValueRelativeDate,
                relativeTimeType: { id: item.id as DATE_RELATIVE_OPTION },
              };

              replaceFilter(idx, { ...filter, filterValue });
            }}
            options={DATE_RELATIVE_OPTIONS}
            selectedItem={selectedRelativeTime}
          />
        </div>
      );
    }

    return (
      <div className={classes.filterRow}>
        <InputWithBlurSave
          fillWidth
          ignoreCustomStyles={!isArchitectCustomerDashboard}
          initialValue={filter.filterValue as string}
          onNewValueSubmitted={(newValue) =>
            replaceFilter(idx, { ...filter, filterValue: newValue.trim() })
          }
          placeholder="Value"
        />
      </div>
    );
  };

  return (
    <div className={classes.container}>
      {filters.map((filter, idx) => {
        const operatorOptions = getOperatorOptions(filter);
        const twoRows =
          !filter.filterOperation || !FILTER_OPS_NO_VALUE.has(filter.filterOperation.id);

        return (
          <div className={classes.filter} key={`filter-clause-${idx}`}>
            <div className={classes.flexOne}>
              <div className={classes.filterRow}>
                <DropdownSelect
                  {...sharedDropdownProps}
                  containerClassName={cx(classes.flexTwo, classes.columnDropdown)}
                  noSelectionText="Column"
                  onChange={(item) => {
                    const column = dataset.schema?.find((col) => col.name === item.id);
                    if (!column || column.name === filter.filterColumn?.name) return;

                    replaceFilter(idx, {
                      filterValue: undefined,
                      filterOperation: undefined,
                      filterColumn: { name: column.name, type: column.type },
                    });
                  }}
                  options={colOptions}
                  selectedItem={
                    filter.filterColumn
                      ? colOptions.find((opt) => opt.id === filter.filterColumn?.name)
                      : undefined
                  }
                />
                <DropdownSelect
                  {...sharedDropdownProps}
                  containerClassName={cx(classes.flexOne, classes.opDropdown)}
                  disabled={!filter.filterColumn}
                  noSelectionText="Operator"
                  onChange={(item) => {
                    const newFilterOperation = item.id as FilterOperator;
                    if (newFilterOperation === filter.filterOperation?.id) return;

                    const shouldClear = newOperatorShouldClearSelectedVariable(
                      filter.filterOperation?.id,
                      newFilterOperation,
                    );

                    replaceFilter(idx, {
                      ...filter,
                      filterOperation: { id: newFilterOperation },
                      filterValue: shouldClear ? undefined : filter.filterValue,
                    });
                  }}
                  options={operatorOptions}
                  selectedItem={
                    filter.filterOperation
                      ? operatorOptions.find((opt) => opt.id === filter.filterOperation?.id)
                      : undefined
                  }
                />
              </div>
              {renderValueInput(idx, filter)}
            </div>
            <Button
              compact
              className={twoRows ? classes.twoRowButton : undefined}
              icon="cross"
              onClick={() =>
                updateFilters(
                  (newFilters) => newFilters.splice(idx, 1),
                  !isFilterClauseIncomplete(filter),
                )
              }
              style={{ marginLeft: 4 }}
            />
          </div>
        );
      })}
      <Button
        fillWidth
        icon="plus"
        onClick={() => updateFilters((newFilters) => newFilters.push(EMPTY_FILTER_CLAUSE))}
        text="Add a filter"
      />
    </div>
  );
}
