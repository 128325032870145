import { FC } from 'react';
import sqlFormatter from 'sql-formatter';
import sql from 'react-syntax-highlighter/dist/esm/languages/hljs/sql';

import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';

import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { QueryRuntime } from '../DashboardDebugger/QueryRuntime';
import { sprinkles } from 'components/ds';

SyntaxHighlighter.registerLanguage('sql', sql);

type Props = {
  dataPanel: DataPanelTemplate | undefined;
};

export const QueryDebugger: FC<Props> = ({ dataPanel }) => {
  if (!dataPanel) {
    return (
      <>
        <div className={sprinkles({ padding: 'sp1.5' })}>
          Select a data panel on the dashboard to use the debugger
        </div>
      </>
    );
  }

  const formattedQuery = sqlFormatter.format(dataPanel._query_information?._query || '', {
    indent: '  ',
  });

  return (
    <div className={sprinkles({ flexItems: 'column', height: 'fill' })}>
      <QueryRuntime queryInformation={dataPanel._query_information} />
      <div
        className={sprinkles({ paddingX: 'sp1.5', flex: 1, whiteSpace: 'pre' })}
        style={{ fontFamily: 'Source Code Pro' }}>
        <SyntaxHighlighter language="sql">{formattedQuery}</SyntaxHighlighter>
      </div>
    </div>
  );
};
