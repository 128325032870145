import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isIdle, isLoading, isSuccess } from 'remotedata';

import { ResourceListPage } from 'shared/ExploResource/ResourceListPage';

import { ReduxState } from 'reducers/rootReducer';
import { pageView } from 'analytics/exploAnalytics';
import { fetchCanvases } from 'actions/canvasActions';
import { ResourcePageType } from 'types/exploResource';

export default function CanvasesPage(): JSX.Element {
  const dispatch = useDispatch();

  const canvases = useSelector((state: ReduxState) => state.canvas.canvases);

  useEffect(() => {
    document.title = 'Explo | Architect';
    pageView('Home App (Canvases)');
  });

  // load canvases
  useEffect(() => {
    if (isIdle(canvases)) dispatch(fetchCanvases());
  }, [canvases, dispatch]);

  return (
    <ResourceListPage
      pageType={ResourcePageType.ARCHITECT}
      resources={isSuccess(canvases) ? canvases.data : undefined}
      resourcesLoading={isLoading(canvases)}
    />
  );
}
