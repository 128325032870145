import { FC } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Poller from 'components/JobQueue/Poller';

import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { setCompletedJobs } from 'reportBuilderContent/reducers/reportEditingReducer';

export const ReportBuilderPoller: FC = () => {
  const dispatch = useDispatch();

  const { awaitedJobs, customerToken } = useSelector(
    (state: ReportBuilderReduxState) => ({
      awaitedJobs: state.reportEditing.jobs,
      customerToken: state.embeddedReportBuilder.requestInfo.customerToken,
    }),
    shallowEqual,
  );

  return (
    <Poller
      awaitedJobs={awaitedJobs}
      customerToken={customerToken}
      updateJobResult={(finishedJobIds, onComplete) => {
        if (finishedJobIds.length > 0) dispatch(setCompletedJobs(finishedJobIds));
        onComplete();
      }}
    />
  );
};
