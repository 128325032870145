import { useState } from 'react';
import { cloneDeep, every, find, groupBy } from 'utils/standard';
import validator from 'validator';
import cx from 'classnames';
import { Classes, FormGroup } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';

import InputGroup from 'explo-ds/forms/marketing/inputGroup';
import DropdownSelect from 'shared/DropdownSelect';
import Button from 'shared/Button';
import EndUserPortalConfiguration from './customerPortalConfiguration';
import { APP_PORTAL_ID, Intent, Tag } from 'components/ds';
import Modal from 'components/core/Modal';
import Checkbox from 'components/checkbox';
import { InfoIcon } from 'components/InfoIcon';
import { DashboardTemplate } from 'actions/dashboardTemplateActions';
import { EndUser, Customer, AccessGroup } from 'actions/teamActions';
import { ParentSchema, DataSource } from 'actions/dataSourceActions';
import { ReduxState } from 'reducers/rootReducer';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ACTION } from 'actions/types';
import { doesCustomerHaveInvalidAccessGroup } from 'utils/architectCustomerDashboardUtils';
import { showErrorToast } from 'shared/sharedToasts';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '600px',
  },
  label: {
    color: theme.palette.ds.grey900,
    fontSize: 12,
    fontWeight: 400,
    marginTop: theme.spacing(3),
    display: 'flex',
    marginBottom: theme.spacing(1),
    alignItems: 'center',
  },
  notTopMargin: {
    marginTop: theme.spacing(0),
  },
  headerMessage: {
    marginTop: theme.spacing(-5),
  },
  sectionContainer: {
    display: 'flex',
    color: theme.palette.ds.grey600,
    backgroundColor: theme.palette.ds.grey200,
    paddingBottom: `${theme.spacing(3)}px`,
    marginTop: `${theme.spacing(3)}px`,
    borderRadius: theme.spacing(1),
    flexDirection: 'column',
  },
  sectionHeader: {
    fontWeight: 600,
    fontSize: 14,
    color: theme.palette.ds.black,
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
  },
  sectionRowContainer: {
    padding: `0 ${theme.spacing(3)}px`,
    flexBasis: '100%',
  },
  toggleSwitch: {
    paddingRight: theme.spacing(3),
  },
  formContainer: {
    marginBottom: 0,
  },
  formRowContainer: {
    display: 'flex',
    gap: `${theme.spacing(2)}px`,
  },
  providedIdContainer: {
    flexGrow: 1,
  },
  customerTypeContainer: {
    width: theme.spacing(32),
  },
  menuDivider: {
    paddingBottom: theme.spacing(3),
    borderBottom: `solid 1px ${theme.palette.ds.grey400}`,
  },
  tag: {
    marginLeft: theme.spacing(2),
  },
  demoGroupInputContainer: {
    marginTop: theme.spacing(4),
    height: 20,
    display: 'flex',
    alignItems: 'center',
  },
  demoGroupCheckbox: {
    paddingLeft: 2,
    lineHeight: '10px',
    display: 'inline-block',
    '& input:checked ~ .bp3-control-indicator': {
      backgroundColor: `${theme.palette.ds.blue} !important`,
    },
  },
  demoGroupCheckboxLabel: {
    fontSize: 12,
    marginLeft: theme.spacing(2),
    verticalAlign: 'top',
  },
  demoGroupInfoIcon: {
    display: 'inline',
    verticalAlign: 'top',
    marginLeft: theme.spacing(2),
    marginTop: -4,
    '& .bp3-icon': {
      display: 'inline-block',
    },
  },
}));

type Props = {
  closeModal: () => void;
  modalTitle: string;
  buttonName: string;
  onSubmit: (
    name: string,
    provided_id: string,
    mapping: Record<string, string>,
    endUsers: EndUser[],
    accessGroupId: number,
    isDemoGroup: boolean,
    properties: Record<string, string>,
    permissioned_dashboard_id?: number,
  ) => void;
  dataSources: DataSource[];
  parentSchemas: ParentSchema[];
  selectedGroup?: Customer;
  dashboardTemplates: DashboardTemplate[];
  endUsers: EndUser[];
  accessGroups: AccessGroup[];
};

export default function CustomersModal({
  selectedGroup,
  dataSources,
  parentSchemas,
  onSubmit,
  closeModal,
  modalTitle,
  buttonName,
  dashboardTemplates,
  endUsers,
  accessGroups,
}: Props) {
  const customersLoading = useSelector((state: ReduxState) =>
    createLoadingSelector([ACTION.CREATE_CUSTOMER, ACTION.EDIT_CUSTOMER], false)(state),
  );

  const classes = useStyles();

  const accessGroupOptions = accessGroups.map((group) => {
    return { name: group.name, id: group.id.toString() };
  });

  const [editorGroup, setEditorGroup] = useState({
    name: selectedGroup?.name || '',
    providedId: selectedGroup?.provided_id || '',
    mapping: selectedGroup?.parent_schema_datasource_mapping || {},
    properties:
      selectedGroup?.properties && Object.keys(selectedGroup?.properties).length > 0
        ? JSON.stringify(selectedGroup.properties)
        : '',
    accessGroupId:
      selectedGroup?.access_group_id || (accessGroups.length === 1 ? accessGroups[0].id : -1),
    isDemoGroup: selectedGroup?.is_demo_group || false,
  });
  const [editedUsers, setEditedUsers] = useState(endUsers);
  const [selectedDashboardId, setSelectedDashboardId] = useState(
    endUsers.length > 0 ? endUsers[0].permissioned_dashboard_id : undefined,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isCustomerFormReadyToSubmit = Boolean(
    editorGroup.name &&
      editorGroup.providedId &&
      editorGroup.name.trim() &&
      editorGroup.providedId.trim() &&
      editorGroup.accessGroupId !== -1,
  );

  const isEndUserFormReadyToSubmit = Boolean(
    every(editedUsers, (user) => validator.isEmail(user.email)),
  );

  const onSubmitInternal = (customer: {
    name: string;
    providedId: string;
    mapping: Record<string, string>;
    accessGroupId: number;
    isDemoGroup: boolean;
    properties: string;
  }) => {
    if (isCustomerFormReadyToSubmit && isEndUserFormReadyToSubmit && !isSubmitting) {
      let properties;
      try {
        properties = customer.properties ? JSON.parse(customer.properties) : {};
      } catch (e) {
        showErrorToast('Invalid Properties JSON');
        return;
      }
      setIsSubmitting(true);
      onSubmit(
        customer.name,
        customer.providedId,
        customer.mapping,
        editedUsers,
        customer.accessGroupId,
        customer.isDemoGroup,
        properties,
        selectedDashboardId,
      );
    }
  };
  const onCancelClick = (schemaId: number) => {
    const newMapping = cloneDeep(editorGroup.mapping);
    delete newMapping[schemaId];
    setEditorGroup({
      ...editorGroup,
      mapping: newMapping,
    });
  };

  const selectedAccessGroup = accessGroups.find((group) => group.id === editorGroup.accessGroupId);
  const accessGroupDataSources = selectedAccessGroup?.data_source_ids
    ? dataSources.filter((dataSource) =>
        selectedAccessGroup?.data_source_ids.includes(dataSource.id),
      )
    : [];
  const dataSourcesBySchemaId = groupBy(
    accessGroupDataSources,
    (datasource) => datasource.parent_schema_id,
  );

  const defaultDataSourcesBySchemaId: Record<number, DataSource> = {};

  accessGroupDataSources.forEach((dataSource) => {
    if (selectedAccessGroup?.default_data_source_ids.includes(dataSource.id)) {
      defaultDataSourcesBySchemaId[dataSource.parent_schema_id] = dataSource;
    }
  });

  const renderSchemasSection = (schema: ParentSchema) => {
    const dataSourcesForSchema = dataSourcesBySchemaId[schema.id];

    if (!dataSourcesForSchema) return;

    const selectedDataSource =
      schema.id in editorGroup.mapping
        ? find(
            dataSourcesForSchema,
            (dataSource) => String(dataSource.id) === editorGroup.mapping[schema.id],
          )
        : undefined;
    const defaultDataSource = defaultDataSourcesBySchemaId[schema.id];

    return (
      <div className={classes.sectionRowContainer} key={schema.id}>
        <div className={classes.label}>
          {schema.name}
          {selectedDataSource !== undefined && (
            <Tag
              className={classes.tag}
              intent={Intent.ACTIVE}
              onClose={() => onCancelClick(schema.id)}>
              Non-default
            </Tag>
          )}
        </div>
        <DropdownSelect
          fillWidth
          minimal
          showIcon
          noSelectionText="Select Data Source"
          onChange={(item) =>
            setEditorGroup({
              ...editorGroup,
              mapping: { ...editorGroup.mapping, [schema.id]: item.id },
            })
          }
          options={dataSourcesForSchema.map((dataSource) => ({
            id: String(dataSource.id),
            name: dataSource.name,
          }))}
          selectedItem={
            selectedDataSource
              ? {
                  id: String(selectedDataSource.id),
                  name: selectedDataSource.name,
                }
              : defaultDataSource
              ? { id: String(defaultDataSource.id), name: defaultDataSource.name }
              : undefined
          }
        />
      </div>
    );
  };

  const renderAccessGroupsSection = () => {
    if (
      accessGroups.length <= 1 &&
      (!selectedGroup ||
        !doesCustomerHaveInvalidAccessGroup(
          selectedGroup,
          new Set((accessGroups ?? []).map((group) => group.id)),
        ))
    )
      return;

    const selectedItem = accessGroupOptions.find(
      (group) => group.id === editorGroup.accessGroupId.toString(),
    );

    return (
      <>
        <DropdownSelect
          fillWidth
          minimal
          containerClassName={cx(classes.sectionRowContainer, {
            [classes.menuDivider]: selectedItem,
          })}
          filterable={false}
          label="Data Visibility Group"
          noSelectionText="Select an Visibility Group"
          onChange={(item) =>
            setEditorGroup({ ...editorGroup, mapping: {}, accessGroupId: parseInt(item.id) })
          }
          options={accessGroupOptions}
          selectedItem={selectedItem}
        />
      </>
    );
  };

  return (
    // if the caller is rendering CustomersModal, then the modal is open
    <Modal
      modalOpen
      usePortal
      className={classes.root}
      onClose={closeModal}
      portalContainerId={APP_PORTAL_ID}
      title={modalTitle}>
      <div className={Classes.DIALOG_BODY}>
        {/* this div is actually important to styling regardless of whether we display text in it */}
        <div className={classes.headerMessage}>
          {!selectedGroup &&
            'If you’ve connected to our API then new customers will be added automatically.'}
        </div>
        <div>
          <FormGroup className={classes.formContainer} labelFor="text-input">
            <div className={cx(classes.label, { [classes.notTopMargin]: selectedGroup })}>Name</div>
            <InputGroup
              onInputChange={(value) => setEditorGroup({ ...editorGroup, name: value })}
              onKeyPress={(e) => {
                if (
                  e.key === 'Enter' &&
                  isCustomerFormReadyToSubmit &&
                  isEndUserFormReadyToSubmit
                ) {
                  onSubmitInternal(editorGroup);
                }
              }}
              placeholder="Explo Co."
              value={editorGroup.name}
            />
          </FormGroup>
          <FormGroup className={classes.formContainer} labelFor="text-input">
            <div className={classes.label}>Group ID</div>
            <div className={classes.formRowContainer}>
              <InputGroup
                className={classes.providedIdContainer}
                onInputChange={(value) => setEditorGroup({ ...editorGroup, providedId: value })}
                onKeyPress={(e) => {
                  if (
                    e.key === 'Enter' &&
                    isCustomerFormReadyToSubmit &&
                    isEndUserFormReadyToSubmit
                  ) {
                    onSubmitInternal(editorGroup);
                  }
                }}
                placeholder="12a34b56"
                value={editorGroup.providedId}
              />
            </div>
          </FormGroup>
          {selectedGroup ? (
            <FormGroup className={classes.formContainer} labelFor="text-input">
              <div className={classes.label}>
                Token
                <InfoIcon
                  className={classes.demoGroupInfoIcon}
                  infoTooltipText="This token is used when embedding your dashboard for this particular customer."
                />
              </div>
              <div className={classes.formRowContainer}>
                <InputGroup
                  readOnly
                  className={classes.providedIdContainer}
                  value={selectedGroup?.token}
                />
              </div>
            </FormGroup>
          ) : null}
          <FormGroup className={classes.formContainer} labelFor="text-input">
            <div className={classes.label}>
              Properties
              <InfoIcon
                className={classes.demoGroupInfoIcon}
                infoTooltipText="This is a JSON object for properties assigned to this user group."
              />
            </div>
            <div className={classes.formRowContainer}>
              <InputGroup
                className={classes.providedIdContainer}
                onInputChange={(value) => setEditorGroup({ ...editorGroup, properties: value })}
                onKeyPress={(e) => {
                  if (
                    e.key === 'Enter' &&
                    isCustomerFormReadyToSubmit &&
                    isEndUserFormReadyToSubmit
                  ) {
                    onSubmitInternal(editorGroup);
                  }
                }}
                placeholder='{"filterValue": "value"}'
                value={editorGroup?.properties ?? ''}
              />
            </div>
          </FormGroup>
          <FormGroup
            className={classes.formContainer}
            contentClassName={classes.demoGroupInputContainer}
            labelFor="text-input">
            <Checkbox
              small
              className={classes.demoGroupCheckbox}
              isChecked={editorGroup.isDemoGroup}
              onChange={() =>
                setEditorGroup({ ...editorGroup, isDemoGroup: !editorGroup.isDemoGroup })
              }
            />
            <span className={classes.demoGroupCheckboxLabel}>
              This is a demo account, exclude it from billing.
            </span>
            <InfoIcon
              className={classes.demoGroupInfoIcon}
              infoTooltipText="Dashboards for a demo account will be watermarked."
            />
          </FormGroup>
        </div>
        <div className={classes.sectionContainer}>
          <div className={classes.sectionHeader}>Database Assignment</div>
          {renderAccessGroupsSection()}
          {parentSchemas.map((schema) => renderSchemasSection(schema))}
        </div>
        <EndUserPortalConfiguration
          dashboardTemplates={dashboardTemplates}
          endUsers={editedUsers}
          selectedDashboardId={selectedDashboardId}
          updateEndUsers={(newEndUsers) => setEditedUsers(newEndUsers)}
          updateSelectedDashboardId={(id) => setSelectedDashboardId(id)}
        />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            disabled={!isCustomerFormReadyToSubmit || !isEndUserFormReadyToSubmit}
            loading={customersLoading}
            onClick={() => {
              isCustomerFormReadyToSubmit &&
                isEndUserFormReadyToSubmit &&
                onSubmitInternal(editorGroup);
            }}
            text={buttonName}
            type="primary"
          />
        </div>
      </div>
    </Modal>
  );
}
