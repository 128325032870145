import { useDispatch } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Dialog } from '@blueprintjs/core';

import { updateHasSeenWelcomeModal } from 'actions/userActions';
import Button from 'shared/Button';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.ds.white,
    borderRadius: 16,
    position: 'relative',
    padding: 24,
    width: 600,
  },
  welcome: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 20,
    fontWeight: 500,
    height: 24,
    justifyContent: 'center',
    marginBottom: 12,
    marginTop: 4,
    textAlign: 'center',
  },
  help: {
    fontSize: 14,
    fontWeight: 400,
    marginBottom: 24,
    textAlign: 'center',
  },
  intro: {
    color: theme.palette.ds.grey800,
    fontSize: 12,
    fontWeight: 400,
    marginTop: 10,
    textAlign: 'center',
  },
  closeButton: {
    height: 20,
    position: 'absolute',
    right: 18,
    top: 24,
    width: 20,
  },
  getStartedButton: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
  },
  videoContainer: { position: 'relative', paddingBottom: '62.5%' },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));

type Props = {
  message?: string;
};

export default function OnboardingModal({ message }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const closeModal = () => dispatch(updateHasSeenWelcomeModal({ postData: {} }));

  return (
    <Dialog className={classes.root} isOpen={true}>
      <div>
        <div className={classes.welcome}>
          Welcome!
          <span aria-label="party" role="img" style={{ marginLeft: 5 }}>
            🎉
          </span>
        </div>
        <Button minimal className={classes.closeButton} icon="cross" onClick={closeModal} />
      </div>
      {message ? <div className={classes.help}>{message}</div> : null}
      <div className={classes.videoContainer}>
        <iframe
          allowFullScreen
          className={classes.video}
          frameBorder="0"
          src="https://www.loom.com/embed/5bd94e60558b42f0a142ff32ae9e88da?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
          title="Welcome"></iframe>
      </div>
      <div className={classes.intro}>Gary introduces you to explo</div>
      <Button
        className={classes.getStartedButton}
        onClick={closeModal}
        text="Get Started"
        type="primary"
      />
    </Dialog>
  );
}
