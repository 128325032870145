import cx from 'classnames';
import { V2KPITrendInstructions } from 'constants/types';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { format } from 'utils/localizationUtils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: 12,
    fontWeight: 500,
    padding: '2px 4px',
    borderRadius: 2,
  },
  negativeChange: {
    color: theme.palette.ds.red,
    backgroundColor: theme.palette.ds.lightRed,
  },
  positiveChange: {
    color: '#0e6245',
    backgroundColor: '#cbf4c9',
  },
  noChange: {
    color: '#4f566b',
    backgroundColor: '#e3e8ee',
  },
}));

interface Props {
  pctChange: number;
  instructions?: V2KPITrendInstructions;
  className?: string;
}

const getTrendContainerClass = (
  pctChange: number,
  classes: Record<'root' | 'negativeChange' | 'positiveChange' | 'noChange', string>,
  instructions?: V2KPITrendInstructions,
) => {
  const isPositive = pctChange > 0;
  const isNoChange = pctChange === 0;

  if (isNoChange) return classes.noChange;
  if (instructions?.displayFormat?.trendColorsReversed)
    return isPositive ? classes.negativeChange : classes.positiveChange;
  return isPositive ? classes.positiveChange : classes.negativeChange;
};

export default function TrendPctChange({ pctChange, instructions, className }: Props) {
  const classes = useStyles();
  const isPositive = pctChange > 0;
  const isNoChange = pctChange === 0;
  const changeText = format('.1%')(Math.abs(pctChange));

  const containerClass = getTrendContainerClass(pctChange, classes, instructions);

  return (
    <div className={cx(classes.root, className, containerClass)}>
      {isPositive ? '+' : isNoChange ? '' : '-'}
      {changeText}
    </div>
  );
}
