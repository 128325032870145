import { FC } from 'react';
import produce from 'immer';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import { Switch } from 'components/ds';
import DateFormatDescriptiveText from 'pages/dashboardPage/DataPanelConfigV2/FormatConfigTab/DateFormatDescriptiveText';

import { FunnelChartFormat, V2TwoDimensionChartInstructions } from 'constants/types';
import { DATE_TYPES } from 'constants/dataConstants';

type Props = {
  configInputClass: string;
  instructions: V2TwoDimensionChartInstructions;

  updateInstructions: (instructions: V2TwoDimensionChartInstructions) => void;
};

export const SharedFunnelChartConfigs: FC<Props> = ({
  configInputClass,
  instructions,
  updateInstructions,
}) => {
  const { chartSpecificFormat, categoryColumn } = instructions;
  const { funnelChart } = chartSpecificFormat ?? {};
  const hideChartValues = funnelChart?.hideChartValues ?? false;
  const useStageForLabel = funnelChart?.useStageForLabel ?? false;
  const isVertical = funnelChart?.isVertical ?? false;

  const updateFunnelChartFormat = (funnelUpdates: FunnelChartFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.chartSpecificFormat = {
        ...draft.chartSpecificFormat,
        funnelChart: { ...draft.chartSpecificFormat?.funnelChart, ...funnelUpdates },
      };
    });
    updateInstructions(newInstructions);
  };

  return (
    <>
      <Switch
        useCustomStyles
        className={configInputClass}
        label="Vertical Funnel"
        onChange={() => updateFunnelChartFormat({ isVertical: !isVertical })}
        switchOn={isVertical}
      />
      <Switch
        useCustomStyles
        className={configInputClass}
        label="Show Percent Labels"
        onChange={() => updateFunnelChartFormat({ hideChartValues: !hideChartValues })}
        switchOn={!hideChartValues}
      />
      <Switch
        useCustomStyles
        className={configInputClass}
        label="Show Stage Labels"
        onChange={() => updateFunnelChartFormat({ useStageForLabel: !useStageForLabel })}
        switchOn={useStageForLabel}
      />
      {DATE_TYPES.has(categoryColumn?.column.type || '') && (
        <InputWithBlurSave
          containerClassName={configInputClass}
          descriptiveText={<DateFormatDescriptiveText />}
          initialValue={instructions.xAxisFormat?.dateFormat}
          label="Stage Date Format"
          onNewValueSubmitted={(newValue) => {
            const newInstructions = produce(instructions, (draft) => {
              draft.xAxisFormat = { ...draft.xAxisFormat, dateFormat: newValue };
            });
            updateInstructions(newInstructions);
          }}
        />
      )}
    </>
  );
};
