import { FC, forwardRef, Ref } from 'react';
import cx from 'classnames';

import { Icon, sprinkles } from 'components/ds';
import { IconSize } from 'components/ds/Icon';

export type Props = {
  /**
   * Optional class name for style overrides
   */
  className?: string;
  /**
   * Optional test ID for selecting component in tests
   */
  'data-testid'?: string;
  /**
   * Determine whether spinner should fill container or be inline
   */
  fillContainer?: boolean;
  /**
   * Size of the spinner relative to font size
   */
  size?: IconSize;
};

export const Spinner: FC<Props> = forwardRef<SVGSVGElement | HTMLDivElement, Props>(
  ({ className, 'data-testid': testId, fillContainer, size = 'xl', ...props }, ref) => {
    if (fillContainer) {
      return (
        <div
          {...props}
          className={cx(
            sprinkles({ color: 'contentSecondary', parentContainer: 'fill', flexItems: 'center' }),
            className,
          )}
          data-testid={testId}
          ref={ref as Ref<HTMLDivElement>}>
          <Icon spin name="spinner" size={size} />
        </div>
      );
    }

    return (
      <Icon
        {...props}
        spin
        className={cx(sprinkles({ color: 'contentSecondary' }), className)}
        data-testid={testId}
        name="spinner"
        parentRef={ref as Ref<SVGSVGElement>}
        size={size}
      />
    );
  },
);

Spinner.displayName = 'Spinner';
