import { cloneDeep } from 'utils/standard';
import { useDispatch } from 'react-redux';

import DropdownSelect from 'shared/DropdownSelect';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES, V2TwoDimensionChartInstructions, REGION_TYPES } from 'constants/types';
import { sharedStyles } from './styles';

type Props = {
  visualizationType: string;
  instructions: V2TwoDimensionChartInstructions;
};

export default function MapDataConfig({ visualizationType, instructions }: Props) {
  const classes = sharedStyles();
  const dispatch = useDispatch();
  const mapRegionToString = (region: REGION_TYPES) => {
    if (region === REGION_TYPES.WORLD) return 'World';
    else return 'United States';
  };

  return (
    <div className={classes.root}>
      <DropdownSelect
        fillWidth
        minimal
        containerClassName={classes.configInput}
        filterable={false}
        noSelectionText=""
        onChange={(newValue) => {
          const newInstructions = cloneDeep(instructions);
          if (newInstructions.chartSpecificFormat && newInstructions.chartSpecificFormat.mapChart)
            newInstructions.chartSpecificFormat.mapChart.region = newValue.id as REGION_TYPES;
          else
            newInstructions.chartSpecificFormat = {
              mapChart: { region: newValue.id as REGION_TYPES },
            };

          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
        options={[
          { name: mapRegionToString(REGION_TYPES.WORLD), id: REGION_TYPES.WORLD },
          { name: mapRegionToString(REGION_TYPES.UNITED_STATES), id: REGION_TYPES.UNITED_STATES },
        ]}
        selectedItem={{
          name: mapRegionToString(
            instructions.chartSpecificFormat?.mapChart?.region || REGION_TYPES.WORLD,
          ),
          id: instructions.chartSpecificFormat?.mapChart?.region || REGION_TYPES.WORLD,
        }}
      />
    </div>
  );
}
