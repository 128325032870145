import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(4),
  },
  compact: {
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
  },
  subTitle: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

type Props = {
  title: string;
  rightElem?: JSX.Element;
  className?: string;
  isSubTitle?: boolean;
  compact?: boolean;
};

const ConfigSectionHeader = ({ title, rightElem, className, isSubTitle, compact }: Props) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.root, { [classes.compact]: compact }, className)}>
      <div className={isSubTitle ? classes.subTitle : classes.title}>{title}</div>
      <div>{rightElem}</div>
    </div>
  );
};

export default ConfigSectionHeader;
