import produce from 'immer';

import { DatasetSchema, DatasetRow } from 'types/datasets';
import {
  CategoryToColor,
  ColorCategoryTracker,
  ColorFormat,
  COLOR_SYNC_CHART_TYPES,
  OPERATION_TYPES,
} from 'constants/types';
import { GlobalStyleConfig } from 'globalStyles/types';
import { getColorColNames, getColorPalette } from 'pages/dashboardPage/charts/utils';

export function setColorCategoryData(
  colorCategoryTracker: ColorCategoryTracker,
  globalStyleConfig: GlobalStyleConfig,
  operationType: OPERATION_TYPES,
  colorFormat: ColorFormat | undefined,
  previewData: DatasetRow[] | undefined,
  schema: DatasetSchema | undefined,
): void {
  if (!previewData || previewData.length === 0 || !schema || schema.length === 0) return;

  if (!COLOR_SYNC_CHART_TYPES.includes(operationType)) return;

  const colors = getColorPalette(globalStyleConfig, colorFormat) || [];
  if (colors.length === 0) return;

  const { xAxisColName, colorColName } = getColorColNames(schema, operationType);
  const groupName = getColorTrackerCategoryName(xAxisColName, colorColName);

  colorCategoryTracker[groupName] = produce(colorCategoryTracker[groupName] ?? {}, (draft) => {
    setColorsForColorCategoryTracker(previewData, groupName, draft, colors);
  });
}

export const setColorsForColorCategoryTracker = (
  previewData: DatasetRow[],
  groupName: string,
  tracker: CategoryToColor,
  colors: string[],
) => {
  let currentIndex = Object.keys(tracker).length;
  previewData.forEach((row) => {
    const category = String(row[groupName]);
    if (!tracker[category]) {
      tracker[category] = colors[currentIndex % colors.length];
      currentIndex++;
    }
  });
};

export const getColorTrackerCategoryName = (xAxisColName: string, colorColName: string) =>
  // if the xAxis and colorCol are the same,
  // colorColName will suffix '1' from /query_runner/__init__.py `fetch_columns`
  // so use xAxisColName for categoryName
  colorColName === xAxisColName + '1' ? xAxisColName : colorColName;
