import { FC, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  DndContext,
  DraggableSyntheticListeners,
  DragOverlay,
  DraggableAttributes,
} from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Button, Icon, IconButton, sprinkles } from 'components/ds';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { NoDataSelected } from '../NoDataSelected';
import { LightTooltip } from 'pages/ReportBuilder/ReportView/DataPanel/LightTooltip';
import { DataPanelSubHeader } from './SubHeader';
import { GroupBysSection } from './GroupBysSection';
import { AggregationsSection } from './AggregationsSection';
import * as styles from './index.css';

import {
  swapSelectedColumns,
  toggleDataPanelOpen,
  toggleHiddenColumn,
  updateOrderedColumns,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { CustomerReportDataInfo, CustomerReportView } from 'actions/customerReportActions';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { getColTypeDisplay } from 'utils/reportBuilderConfigUtils';
import { DraggableColumnInfo, getDraggableColumns } from 'utils/customerReportUtils';
import {
  AGGS_SECTION_ID,
  COLS_SECTION_ID,
  COL_LIST_SECTION_ID,
  GROUPING_SECTIONS,
  ROWS_SECTION_ID,
} from './constants';
import { addAggThunk, addGroupByThunk } from 'reportBuilderContent/thunks';
import { PivotAgg } from 'types/dateRangeTypes';

type Props = { dataInfo: CustomerReportDataInfo; view: CustomerReportView };

export const DataPanel: FC<Props> = ({ dataInfo, view }) => {
  const dispatch = useDispatch();

  const { versionConfig, isDataPanelOpen } = useSelector(
    (state: ReportBuilderReduxState) => ({
      versionConfig: state.embeddedReportBuilder.reportBuilderVersion?.config,
      isDataPanelOpen: state.reportEditing.isDataPanelOpen,
    }),
    shallowEqual,
  );

  const [draggingColumn, setDraggingColumn] = useState<DraggableColumnInfo | undefined>();
  const [sectionOver, setSectionOver] = useState<string | undefined>();

  const dataset = dataInfo ? versionConfig?.datasets[dataInfo.datasetId] : undefined;

  const colNameToDisplay = useMemo(() => {
    const nameMap: Record<string, string | undefined> = {};
    if (!dataset) return nameMap;

    Object.entries(dataset.columnConfigs).forEach(([colName, colConfig]) => {
      nameMap[colName] = colConfig.name;
    });
    return nameMap;
  }, [dataset]);

  const columnOrder = view.columnOrder;
  const hiddenColumns = view.hiddenColumns;

  const isVisibilityDisabled = !!(view.groupBys?.length || view.aggregations?.length);

  const draggableColumns = useMemo(() => {
    const columns = getDraggableColumns(dataset, columnOrder, hiddenColumns);

    // Remove columns that are not available anymore
    if (columnOrder && columnOrder.length !== columns.length) {
      dispatch(updateOrderedColumns(columns.map((c) => c.name)));
    }
    return columns;
  }, [dispatch, dataset, columnOrder, hiddenColumns]);

  const idColumns = useMemo(
    () => draggableColumns.map((col) => ({ id: col.name })),
    [draggableColumns],
  );

  // Used to make sure there are no duplicate groupings between rows and columns
  const bucketsByCol = useMemo(() => {
    const byCol: Record<string, Set<PivotAgg>> = {};
    const groupBys = (view.groupBys ?? []).concat(view.columnGroupBys ?? []);

    groupBys.forEach(({ column, bucket }) => {
      if (!bucket) return;
      if (column.name in byCol) byCol[column.name].add(bucket);
      else byCol[column.name] = new Set([bucket]);
    });
    return byCol;
  }, [view.groupBys, view.columnGroupBys]);

  if (!versionConfig || !isDataPanelOpen || !dataset) return null;

  const isColGroupBysDisabled = !view.groupBys?.length || !view.aggregations?.length;

  const renderColumns = () => {
    if (draggableColumns.length === 0) return <NoDataSelected />;

    return (
      <SortableContext items={idColumns} strategy={verticalListSortingStrategy}>
        {draggableColumns.map((column) => (
          <DraggableColumn
            column={column}
            disableVisibility={isVisibilityDisabled}
            key={column.name}
            showDragging={sectionOver !== COL_LIST_SECTION_ID}
          />
        ))}
      </SortableContext>
    );
  };

  return (
    <div className={styles.dataPanel}>
      <div className={styles.dataPanelHeader}>
        <Button onClick={() => dispatch(toggleDataPanelOpen())} type="tertiary">
          Hide Data Panel
        </Button>
      </div>
      <DndContext
        modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
        onDragEnd={({ over, active }) => {
          const sectionOver = over?.data.current?.section;
          if (sectionOver === COL_LIST_SECTION_ID) {
            if (over) dispatch(swapSelectedColumns({ oldCol: active.id, newCol: over.id }));
          } else if (GROUPING_SECTIONS.has(sectionOver)) {
            const col = dataset?.schema?.find((col) => col.name === active.id);
            if (!col) return;

            let action;
            if (sectionOver === ROWS_SECTION_ID) action = addGroupByThunk(col, view, false);
            else if (sectionOver === AGGS_SECTION_ID) action = addAggThunk(col, view);
            else if (sectionOver === COLS_SECTION_ID) {
              action = addGroupByThunk(col, view, true, !isColGroupBysDisabled);
            }

            if (action) dispatch(action);
          }
          setDraggingColumn(undefined);
        }}
        onDragOver={(e) => setSectionOver(e.over?.data.current?.section)}
        onDragStart={({ active }) => {
          const col = draggableColumns.find((col) => col.name === active.id);
          if (col) setDraggingColumn(col);
        }}>
        <DataPanelSubHeader
          sideText={isVisibilityDisabled ? 'Not used in table' : undefined}
          title="Report Data"
        />
        <div className={styles.dataPanelBody}>{renderColumns()}</div>
        <GroupBysSection
          bucketsByCol={bucketsByCol}
          colNameToDisplay={colNameToDisplay}
          sectionGroupBys={view.groupBys ?? []}
        />
        <AggregationsSection
          aggregations={view.aggregations ?? []}
          colNameToDisplay={colNameToDisplay}
        />
        <GroupBysSection
          isColumnSection
          bucketsByCol={bucketsByCol}
          colNameToDisplay={colNameToDisplay}
          isSectionDisabled={isColGroupBysDisabled}
          sectionGroupBys={view.columnGroupBys ?? []}
        />
        <DragOverlay dropAnimation={null}>
          {draggingColumn ? (
            <Column isDragging column={draggingColumn} disableVisibility={isVisibilityDisabled} />
          ) : null}
        </DragOverlay>
      </DndContext>
    </div>
  );
};

type DraggableColumnProps = {
  column: DraggableColumnInfo;
  disableVisibility: boolean;
  showDragging?: boolean;
};

const DraggableColumn: FC<DraggableColumnProps> = ({ column, disableVisibility, showDragging }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: column.name,
    data: { section: COL_LIST_SECTION_ID },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging && !showDragging ? 0 : 1,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <LightTooltip
        side="left"
        text={
          <span className={sprinkles({ flexItems: 'column', gap: 'sp1' })}>
            <EmbedText body="b2" className={styles.tooltipTitle}>
              {column.displayName}
            </EmbedText>
            {column.description ? (
              <EmbedText body="b2" className={sprinkles({ margin: 'sp0' })}>
                {column.description}
              </EmbedText>
            ) : null}
          </span>
        }>
        <span>
          <Column
            attributes={attributes}
            column={column}
            disableVisibility={disableVisibility}
            listeners={listeners}
          />
        </span>
      </LightTooltip>
    </div>
  );
};

type ColumnProps = DraggableColumnProps & {
  isDragging?: boolean;
  listeners?: DraggableSyntheticListeners;
  attributes?: DraggableAttributes;
};

const Column: FC<ColumnProps> = ({
  attributes,
  listeners,
  column,
  isDragging,
  disableVisibility,
}) => {
  const dispatch = useDispatch();

  return (
    <div className={isDragging ? styles.draggingCol : styles.draggableCol}>
      <div {...attributes} {...listeners} className={sprinkles({ display: 'flex' })}>
        <Icon
          className={sprinkles({
            color: 'contentTertiary',
            cursor: isDragging ? 'grabbing' : 'grab',
          })}
          name="vertical-grip"
          size="md"
        />
      </div>
      <EmbedText body="b1" className={styles.columnName}>
        {column.displayName}
      </EmbedText>
      <EmbedText body="b2" color="contentTertiary">
        {getColTypeDisplay(column.type)}
      </EmbedText>
      <IconButton
        className={disableVisibility ? styles.disabledEyeIcon : styles.eyeIcon}
        disabled={disableVisibility}
        name={column.isHidden || disableVisibility ? 'eye-closed' : 'eye-open'}
        // For performance, data is not refetched even if you hide the column you're sorting by
        onClick={() => dispatch(toggleHiddenColumn({ col: column.name, hide: !column.isHidden }))}
      />
    </div>
  );
};
