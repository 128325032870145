import { FC, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { orderBy } from 'utils/standard';

import InputLabel from 'shared/InputLabel';
import InputGroup from 'explo-ds/forms/marketing/inputGroup';
import DropdownSelect from 'shared/DropdownSelect';
import ResourcePreview from 'shared/ExploResource/ResourcePreview';
import { Intent, Tag, sprinkles } from 'components/ds';
import { DeleteMenu } from 'components/resource/DeleteMenu';

import { ArchitectCustomerDashboardReduxState } from 'architectCustomerDashboardContent/reducers/rootReducer';
import { getCanvasDashboardElements } from 'utils/canvasConfigUtils';
import { getTimezoneAwareDate, getTimezoneAwareUnix } from 'utils/timezoneUtils';
import {
  createArchitectCustomerDashboard,
  deleteArchitectCustomerDashboard,
  openArchitectCustomerDashboard,
} from 'actions/architectCustomerDashboardActions';
import {
  getArchitectCustomerDashboardName,
  prepareConfigForCopy,
} from 'utils/architectCustomerDashboardUtils';
import { AnalyticsEventTracker } from 'utils/analyticsUtils';
import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';

const useStyles = makeStyles((theme: Theme) => ({
  filterBar: {
    display: 'flex',
    marginBottom: theme.spacing(3),
  },
  searchBar: {
    flex: 1,
    marginRight: theme.spacing(3),
  },
  sortByDropdown: { width: 100 },
  loading: {
    height: 400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 30,
  },
  grid: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(3),
  },
  dashboard: {
    width: 292,
    height: 234,
    backgroundColor: theme.palette.ds.white,
    border: `1px solid ${theme.palette.ds.grey300}`,
    padding: theme.spacing(3),
    borderRadius: 4,
    cursor: 'pointer',

    '&:hover': {
      borderColor: theme.palette.ds.blue,
      backgroundColor: theme.palette.ds.lightBlue,
    },
  },
  preview: {
    height: 154,
    border: `1px solid ${theme.palette.ds.grey400}`,
    borderRadius: 4,
    marginBottom: theme.spacing(3),
    overflow: 'hidden',
  },
  noPreview: {
    backgroundColor: theme.palette.ds.grey300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.ds.grey900,
    height: '100%',
  },
  dashboardControls: {
    height: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontWeight: 500,
  },
  dashboardInfo: {
    overflow: 'hidden',
  },
  dashboardTitle: {
    display: 'flex',
    alignItems: 'center',
    height: 20,
    marginBottom: theme.spacing(1),
  },
  dashboardName: {
    color: theme.palette.ds.black,
    fontSize: 16,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  dashboardEditDate: {
    color: theme.palette.ds.grey600,
    fontSize: 12,
    lineHeight: '15px',
  },
  dashboardMenu: {
    marginLeft: theme.spacing(2),
  },
}));

type Props = {
  analyticsEventTracker: AnalyticsEventTracker;
  canvasEmbedId: string;
  userId: string | null;
  customerToken: string;
};

enum SortOptions {
  NEWEST = 'Newest',
  TITLE = 'Title',
}

const sortOptions = Object.values(SortOptions).map((opt) => ({ id: opt, name: opt }));

export const DashboardGrid: FC<Props> = ({
  analyticsEventTracker,
  canvasEmbedId,
  userId,
  customerToken,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [sort, setSort] = useState(SortOptions.NEWEST);
  const [searchString, setSearchString] = useState('');

  const { dashboards, canvasVersion, tabbedDashboards, globalStyleConfig } = useSelector(
    (state: ArchitectCustomerDashboardReduxState) => ({
      canvasVersion: state.architectCustomerDashboard.canvasVersion,
      dashboards: state.architectCustomerDashboard.dashboards,
      tabbedDashboards: state.architectCustomerDashboard.tabbedDashboards,
      globalStyleConfig: state.dashboardStyles.globalStyleConfig,
    }),
    shallowEqual,
  );

  const dashboardElements = useMemo(() => {
    if (!canvasVersion) return [];
    return getCanvasDashboardElements(canvasVersion.configuration);
  }, [canvasVersion]);

  const filteredDashboards = useMemo(() => {
    if (!dashboards) return null;
    let searchedDashboards = dashboards;
    if (searchString.trim() !== '') {
      const lowerSearch = searchString.toLocaleLowerCase();
      searchedDashboards = dashboards.filter((dash) =>
        dash.name.toLocaleLowerCase().includes(lowerSearch),
      );
    }
    if (sort === SortOptions.TITLE)
      return orderBy(searchedDashboards, getArchitectCustomerDashboardName);

    return orderBy(searchedDashboards, (dash) => getTimezoneAwareUnix(dash.modified), 'desc');
  }, [dashboards, sort, searchString]);

  return (
    <>
      <div className={classes.filterBar}>
        <div className={classes.searchBar}>
          <InputLabel text="Search" />
          <InputGroup
            onInputChange={setSearchString}
            placeholder="Search by Title"
            value={searchString}
          />
        </div>
        <DropdownSelect
          fillWidth
          minimal
          openElementToLeft
          containerClassName={classes.sortByDropdown}
          filterable={false}
          label="Sort by"
          onChange={(opt) => setSort(opt.id as SortOptions)}
          options={sortOptions}
          selectedItem={{ id: sort, name: sort }}
        />
      </div>
      {filteredDashboards === null ? (
        <div className={classes.loading}>Loading Dashboards...</div>
      ) : (
        <div className={classes.grid}>
          {filteredDashboards.map((dashboard) => {
            const modifiedText = `Last edited ${getTimezoneAwareDate(
              dashboard.modified,
            ).toRelative()}`;
            const dashboardName = getArchitectCustomerDashboardName(dashboard);

            const analyticsParams = {
              dashboard_id: dashboard.id ?? 'base_dashboard',
              dashboard_name: dashboardName,
            };

            return (
              <div
                className={classes.dashboard}
                key={dashboard.id}
                onClick={() => {
                  dispatch(openArchitectCustomerDashboard(dashboard.id));

                  // Only fire if its the initial load of dashboard
                  if (tabbedDashboards.includes(dashboard.id)) return;
                  analyticsEventTracker(
                    REPORTED_ANALYTIC_ACTION_TYPES.END_USER_DASHBOARD_LOADED,
                    analyticsParams,
                  );
                }}>
                <div className={classes.preview}>
                  {dashboard.configuration.layout.length === 0 ? (
                    <div className={classes.noPreview}>Dashboard is empty</div>
                  ) : (
                    <ResourcePreview
                      dataPanels={Object.values(dashboard.configuration.data_panels)}
                      elements={dashboardElements}
                      globalStyleConfig={globalStyleConfig}
                      layout={dashboard.configuration.layout}
                    />
                  )}
                </div>
                <div className={classes.dashboardControls}>
                  <div className={classes.dashboardInfo}>
                    <div className={classes.dashboardTitle}>
                      <div className={classes.dashboardName}>{dashboardName}</div>
                      {dashboard.id === null ? (
                        <Tag className={sprinkles({ marginLeft: 'sp1' })} intent={Intent.ACTIVE}>
                          Base
                        </Tag>
                      ) : null}
                    </div>
                    <div className={classes.dashboardEditDate}>{modifiedText}</div>
                  </div>
                  <div className={classes.dashboardMenu} onClick={(e) => e.stopPropagation()}>
                    <DeleteMenu
                      deleteItem={() => {
                        if (dashboard.id === null) return;
                        dispatch(
                          deleteArchitectCustomerDashboard(
                            {
                              customerToken,
                              postData: { user_id: userId, dashboard_id: dashboard.id },
                            },
                            () => {
                              analyticsEventTracker(
                                REPORTED_ANALYTIC_ACTION_TYPES.END_USER_DASHBOARD_DELETED,
                                analyticsParams,
                              );
                            },
                          ),
                        );
                      }}
                      hideDelete={dashboard.id === null}
                      menuItems={[
                        {
                          icon: 'clone',
                          name: 'Duplicate',
                          onClick: () =>
                            dispatch(
                              createArchitectCustomerDashboard(
                                {
                                  customerToken,
                                  postData: {
                                    canvas_embed_id: canvasEmbedId,
                                    config: prepareConfigForCopy(dashboard.configuration),
                                    user_id: userId,
                                    name: `${dashboardName} Copy`,
                                  },
                                },
                                () => {
                                  analyticsEventTracker(
                                    REPORTED_ANALYTIC_ACTION_TYPES.END_USER_DASHBOARD_COPIED,
                                    analyticsParams,
                                  );
                                },
                              ),
                            ),
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
