import { ChangeEvent, useState } from 'react';
import cx from 'classnames';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FormGroup, TextArea } from '@blueprintjs/core';

import Modal from 'components/core/Modal';
import { VersionPanel } from './VersionPanel';
import InputGroup from 'explo-ds/forms/marketing/inputGroup';
import { Button } from 'components/ds';
import { DeleteConfirmationButton } from 'components/core/DeleteConfirmationButton';
import { Spinner, sprinkles } from 'components/ds';

import { APP_PORTAL_ID } from 'components/ds';
import { isSuccess } from 'remotedata';
import {
  deleteCurrentDashboardDraft,
  publishNewDashboardVersion,
  switchCurrentlyEditingDashboardVersion,
} from 'actions/dashboardV2Actions';
import {
  CanvasVersion,
  createNewCanvasVersion,
  deleteCurrentCanvasDraft,
  publishNewCanvasVersion,
  switchCurrentlyEditingCanvasVersion,
} from 'actions/canvasVersionActions';
import { CanvasVersionConfig } from 'actions/canvasConfigActions';
import { ACTION } from 'actions/types';
import { DashboardVersion } from 'types/dashboardVersion';
import { DashboardVersionConfig } from 'types/dashboardVersionConfig';
import { createLoadingSelector } from 'reducers/api/selectors';
import { removeUnsavedDashboardConfigFields } from 'utils/dashboardUtils';
import { showWarningToast } from 'shared/sharedToasts';
import { doesUserHavePermission } from 'utils/permissionUtils';
import { PERMISSIONED_ACTIONS } from 'constants/roleConstants';
import { cleanCanvasConfig } from 'utils/canvasConfigUtils';
import { DEFAULT_SUPPORT_EMAIL } from 'constants/emailConstants';
import { ResourcePageType } from 'types/exploResource';
import { getPermissionEntity } from 'utils/exploResourceUtils';
import {
  deleteCurrentReportBuilderDraft,
  publishNewReportBuilderVersion,
} from 'actions/reportBuilderVersionActions';
import { ReportBuilderConfig } from 'actions/reportBuilderConfigActions';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 700,
  },
  modalBody: {
    padding: `0 ${theme.spacing(6)}px`,
    height: 500,
    overflowY: 'auto',
  },
  draftContainer: {
    backgroundColor: theme.palette.ds.grey200,
    padding: theme.spacing(3),
    borderRadius: 4,
    marginBottom: theme.spacing(3),
  },
  noDraftContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  currentDraftText: {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: theme.spacing(3),
  },
  currentDraftVersionInput: {
    margin: 0,
    marginRight: theme.spacing(3),
    width: 70,
  },
  currentDraftMessageInput: {
    margin: 0,
    marginRight: theme.spacing(3),
    width: 410,

    '&.noDeleteButton': {
      width: 325,
    },
  },
  versionEditBtnContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    paddingTop: 23,
  },
  messageTextArea: {
    resize: 'none',
    width: '100%',
    height: '68px !important',
    fontFamily: 'unset',
  },
}));

type Props = {
  closeModal: () => void;
  pageType: ResourcePageType;
};

export default function VersionControlModal({ closeModal, pageType }: Props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [draftChangeComment, setDraftChangeComment] = useState<string>('');

  const isArchitect = pageType === ResourcePageType.ARCHITECT;
  const isExplore = pageType === ResourcePageType.EXPLORE;

  const {
    currentResourceId,
    resourceConfig,
    deleteLoading,
    publishLoading,
    tags,
    versions,
    viewingResourceVersion,
    permissions,
  } = useSelector((state: ReduxState) => {
    const sharedState = {
      tags: state.environmentTags.tags,
      permissions: state.currentUser.permissions,
    };

    if (isArchitect) {
      return {
        ...sharedState,
        currentResourceId: state.canvas.currentCanvasId,
        resourceConfig: isSuccess(state.canvasEdit.config)
          ? state.canvasEdit.config.data
          : undefined,
        deleteLoading: createLoadingSelector([ACTION.DELETE_CURRENT_CANVAS_DRAFT], false)(state),
        publishLoading: createLoadingSelector([ACTION.PUBLISH_NEW_CANVAS_VERSION], false)(state),
        versions: state.canvasVersions.versions,
        viewingResourceVersion: state.canvasEdit.versionInfo,
      };
    } else if (isExplore) {
      return {
        ...sharedState,
        currentResourceId: state.dashboard.currentDashboardTemplateId,
        resourceConfig: state.dashboardEditConfig.config,
        deleteLoading: createLoadingSelector([ACTION.DELETE_CURRENT_DASHBOARD_DRAFT], false)(state),
        publishLoading: createLoadingSelector([ACTION.PUBLISH_NEW_DASHBOARD_VERSION], false)(state),
        versions: state.dashboardVersions.versions,
        viewingResourceVersion: state.dashboardEditConfig.versionInfo,
      };
    } else {
      return {
        ...sharedState,
        currentResourceId: state.reportBuilder.currentReportBuilder,
        resourceConfig: isSuccess(state.reportBuilderEdit.config)
          ? state.reportBuilderEdit.config.data
          : undefined,
        deleteLoading: createLoadingSelector(
          [ACTION.DELETE_CURRENT_REPORT_BUILDER_DRAFT],
          false,
        )(state),
        publishLoading: createLoadingSelector(
          [ACTION.PUBLISH_NEW_REPORT_BUILDER_VERSION],
          false,
        )(state),
        versions: state.reportBuilderEdit.versions,
        viewingResourceVersion: state.reportBuilderEdit.versionInfo,
      };
    }
  }, shallowEqual);

  const mostRecentVersion = versions[0];
  const previousVersion = versions[1];

  const permissionResource = getPermissionEntity(pageType);

  const handlePublish = () => {
    if (!publishLoading && draftChangeComment) {
      if (currentResourceId === undefined || !resourceConfig) return;

      if (isArchitect) {
        dispatch(
          publishNewCanvasVersion(
            {
              id: currentResourceId,
              postData: {
                config: cleanCanvasConfig(resourceConfig as CanvasVersionConfig),
                version_number: mostRecentVersion.version_number,
                change_comments: draftChangeComment,
              },
            },
            () => {
              dispatch(createNewCanvasVersion({ id: currentResourceId }));
              setDraftChangeComment('');
            },
          ),
        );
      } else if (isExplore) {
        dispatch(
          publishNewDashboardVersion(
            {
              id: currentResourceId,
              postData: {
                config: removeUnsavedDashboardConfigFields(
                  resourceConfig as DashboardVersionConfig,
                ),
                version_number: mostRecentVersion.version_number,
                change_comments: draftChangeComment,
              },
            },
            () => setDraftChangeComment(''),
          ),
        );
      } else {
        dispatch(
          publishNewReportBuilderVersion(
            {
              id: currentResourceId,
              postData: {
                config: resourceConfig as ReportBuilderConfig,
                version_number: mostRecentVersion.version_number,
                change_comments: draftChangeComment,
              },
            },
            () => setDraftChangeComment(''),
          ),
        );
      }
    }
  };

  const handleDelete = () => {
    const args = {
      id: currentResourceId,
      postData: { version_number: mostRecentVersion.version_number },
    };
    const handleError = () => {
      showWarningToast(
        `There was an error deleting the current draft. Please try again and contact ${DEFAULT_SUPPORT_EMAIL} if the problem persists.`,
      );
    };
    if (isArchitect) {
      dispatch(
        deleteCurrentCanvasDraft(
          args,
          () => {
            if (viewingResourceVersion?.version_number === mostRecentVersion.version_number)
              dispatch(
                switchCurrentlyEditingCanvasVersion({
                  canvasVersion: versions[1] as CanvasVersion,
                }),
              );
          },
          handleError,
        ),
      );
    } else if (isExplore) {
      dispatch(
        deleteCurrentDashboardDraft(
          args,
          () => {
            if (viewingResourceVersion?.version_number === mostRecentVersion.version_number)
              dispatch(
                switchCurrentlyEditingDashboardVersion({
                  dashboardVersion: versions[1] as DashboardVersion,
                }),
              );
          },
          handleError,
        ),
      );
    } else {
      dispatch(deleteCurrentReportBuilderDraft(args, undefined, handleError));
    }
  };

  if (!currentResourceId) return null;

  return (
    <Modal
      modalOpen
      usePortal
      className={classes.root}
      onClose={closeModal}
      portalContainerId={APP_PORTAL_ID}
      title="Changelog">
      {versions.length > 0 ? (
        <div className={classes.modalBody}>
          {doesUserHavePermission(permissions[permissionResource], PERMISSIONED_ACTIONS.UPDATE) ? (
            mostRecentVersion.is_draft ? (
              <div className={classes.draftContainer}>
                <div className={classes.currentDraftText}>Current Draft</div>
                <div className={sprinkles({ display: 'flex', alignItems: 'flex-start' })}>
                  <FormGroup className={classes.currentDraftVersionInput} label="Version">
                    <InputGroup
                      disabled
                      readOnly
                      type="text"
                      value={`${mostRecentVersion.version_number}`}
                    />
                  </FormGroup>

                  <FormGroup
                    className={cx(classes.currentDraftMessageInput, {
                      noDeleteButton: mostRecentVersion.version_number === 1,
                    })}
                    label="Description">
                    <TextArea
                      className={classes.messageTextArea}
                      onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                        setDraftChangeComment(e.target.value)
                      }
                      placeholder="Describe what is different"
                      value={draftChangeComment}
                    />
                  </FormGroup>
                  <div className={classes.versionEditBtnContainer}>
                    <Button
                      disabled={!draftChangeComment || publishLoading}
                      loading={publishLoading}
                      onClick={handlePublish}
                      type="primary">
                      Save Version
                    </Button>

                    {previousVersion &&
                    (!('deprecated' in previousVersion) || !previousVersion.deprecated) ? (
                      <DeleteConfirmationButton
                        fillWidth
                        loading={deleteLoading}
                        onDelete={handleDelete}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            ) : (
              <div className={cx(classes.draftContainer, classes.noDraftContainer)}>
                No current draft
              </div>
            )
          ) : null}

          {versions.map((version) => {
            if (version.is_draft) return null;
            return (
              <VersionPanel
                canRevert={version.version_number !== mostRecentVersion.version_number}
                closeModal={closeModal}
                isDraft={mostRecentVersion.is_draft}
                key={`version-modal-version-${version.id}`}
                pageType={pageType}
                resourceId={currentResourceId}
                tags={tags}
                userPermissions={permissions[permissionResource]}
                version={version}
              />
            );
          })}
        </div>
      ) : (
        <Spinner fillContainer size="lg" />
      )}
    </Modal>
  );
}
