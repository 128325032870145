import { useState } from 'react';
import { cloneDeep, some } from 'utils/standard';
import validator from 'validator';
import cx from 'classnames';

import { useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Icon } from '@blueprintjs/core';

import DropdownSelect from 'shared/DropdownSelect';
import { Intent, Tag, Switch } from 'components/ds';
import Button from 'shared/Button';
import InfoCard from 'shared/InfoCard';

import { DashboardTemplate } from 'actions/dashboardTemplateActions';
import { EndUser } from 'actions/teamActions';
import InputGroup from 'explo-ds/forms/marketing/inputGroup';
import { SelectedDropdownInputItem } from 'constants/types';
import { ReduxState } from 'reducers/rootReducer';
import { isEndUserPortalDisabled } from 'utils/paymentPlanUtils';

const useStyles = makeStyles((theme: Theme) => ({
  sectionContainer: {
    display: 'flex',
    color: theme.palette.ds.grey600,
    backgroundColor: theme.palette.ds.grey200,
    marginTop: theme.spacing(3),
    borderRadius: theme.spacing(1),
    flexDirection: 'column',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  sectionHeader: {
    fontWeight: 600,
    fontSize: 14,
    color: theme.palette.ds.black,
    paddingTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(3),
  },
  emailSection: {
    paddingTop: theme.spacing(4),
  },
  subsectionHeader: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.ds.black,
  },
  subsectionSubtitle: {
    color: theme.palette.ds.grey900,
    paddingBottom: theme.spacing(3),
  },
  emailContainer: {
    display: 'flex',
    gap: `${theme.spacing(2)}px`,
    paddingTop: theme.spacing(2),
    alignItems: 'center',

    '& .bp3-input-action': {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      marginRight: 5,
    },
  },
  inputClass: { flexGrow: 2 },
  inputTag: {
    padding: 6,
  },
  addButton: {
    marginBottom: theme.spacing(4),
  },
  dashboardSelect: {
    paddingTop: theme.spacing(3),
  },
  hack: {
    marginTop: theme.spacing(4),
  },
  infoCard: {
    marginBottom: theme.spacing(4),
  },
}));

type Props = {
  dashboardTemplates: DashboardTemplate[];
  endUsers: EndUser[];
  updateEndUsers: (newEndUsers: EndUser[]) => void;
  selectedDashboardId?: number;
  updateSelectedDashboardId: (id: number | undefined) => void;
};

export default function EndUserPortalConfiguration({
  dashboardTemplates,
  endUsers,
  updateEndUsers,
  updateSelectedDashboardId,
  selectedDashboardId,
}: Props) {
  const [showEndUserPortalConfig, setShowEndUserPortalConfig] = useState(
    !!selectedDashboardId || some(endUsers, (endUser) => !!endUser.permissioned_dashboard_id),
  );
  const [newUserId, setNewUserId] = useState(-1);

  const teamPaymentPlan = useSelector((state: ReduxState) => state.currentUser.team?.payment_plan);
  const classes = useStyles();

  let selectedItem: SelectedDropdownInputItem | undefined;
  const dashboardOptions = dashboardTemplates.map((dashboard) => {
    const option = { id: String(dashboard.id), name: dashboard.name };
    if (selectedDashboardId === dashboard.id) selectedItem = option;
    return option;
  });

  return (
    <div className={classes.sectionContainer}>
      <div className={classes.sectionHeader}>
        Customer Portal
        <Switch
          onChange={(newValue: boolean) => {
            // if the section is being closed, delete all users and deselect the dashboard
            if (!newValue) {
              updateEndUsers([]);
              updateSelectedDashboardId(undefined);
            }
            setShowEndUserPortalConfig(newValue);
          }}
          switchOn={showEndUserPortalConfig}
        />
      </div>
      {showEndUserPortalConfig && (
        <>
          {isEndUserPortalDisabled(teamPaymentPlan) ? (
            <InfoCard
              error
              className={classes.infoCard}
              text="Customer Portal will not be accessible on your current plan. You may manage it and add end users to customers, but you will need to upgrade to access the portal."
            />
          ) : null}
          <DropdownSelect
            fillWidth
            minimal
            className={classes.dashboardSelect}
            label="Dashboard"
            noSelectionText="Select a Dashboard"
            onChange={(item) => {
              updateSelectedDashboardId(parseInt(item.id));
            }}
            options={dashboardOptions}
            selectedItem={selectedItem}></DropdownSelect>
          <div className={classes.emailSection}>
            <div className={classes.subsectionHeader}>{"User's Emails"}</div>
            <div className={classes.subsectionSubtitle}>
              {"Removing an email will revoke your user's access."}
            </div>
            {endUsers.map((endUser) => (
              <div className={classes.emailContainer} key={endUser.id}>
                {/* TODO: Should use component similar to InputWithTag */}
                <InputGroup
                  className={classes.inputClass}
                  onInputChange={(value) => {
                    const newUsers = cloneDeep(endUsers);
                    const user = newUsers.find((user) => user.id === endUser.id);
                    if (user) {
                      user.email = value;
                      updateEndUsers(newUsers);
                    }
                  }}
                  placeholder="Enter an email"
                  rightElement={
                    <Tag
                      className={classes.inputTag}
                      intent={validator.isEmail(endUser.email) ? Intent.SUCCESS : Intent.ERROR}
                      leftIcon={validator.isEmail(endUser.email) ? 'check' : 'cross'}
                    />
                  }
                  value={endUser.email}
                />
                <Button
                  icon={<Icon icon="cross" />}
                  onClick={() => {
                    const newUsers = endUsers.filter((user) => user.id !== endUser.id);
                    updateEndUsers(newUsers);
                  }}
                  type="secondary"
                />
              </div>
            ))}
            <Button
              className={cx(classes.addButton, { [classes.hack]: endUsers.length !== 0 })}
              onClick={() => {
                const newUsers = cloneDeep(endUsers);
                newUsers.push({ id: newUserId, email: '' } as EndUser);
                updateEndUsers(newUsers);
                setNewUserId(newUserId - 1);
              }}
              text="Add An Email"
              type="primary"></Button>
          </div>
        </>
      )}
    </div>
  );
}
