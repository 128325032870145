import { FC } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { AllReportTab } from 'pages/ReportBuilder/HomeView/AllReportTab';
import { BuiltInReportTab } from 'pages/ReportBuilder/HomeView/BuiltInReportTab';
import { StarredReportTab } from 'pages/ReportBuilder/HomeView/StarredReportTab';
import { Button, Icon, sprinkles } from 'components/ds';
import { EmbedText } from '../EmbedText';

import { createBlankView } from 'pages/ReportBuilder/utils/viewUtils';
import { createCustomerReport } from 'actions/customerReportActions';
import { EmbedReportBuilder } from 'actions/reportBuilderActions';
import { setSelectedTab } from 'reportBuilderContent/reducers/embeddedReportBuilderReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { ReportBuilderTab } from 'pages/ReportBuilder/HomeView/constants';

import * as styles from './index.css';

interface Props {
  reportBuilder: EmbedReportBuilder;
}

export const ReportBuilderHomeView: FC<Props> = ({ reportBuilder }) => {
  const dispatch = useDispatch();
  const { selectedTab, customerToken, embedView } = useSelector(
    (state: ReportBuilderReduxState) => ({
      customerToken: state.embeddedReportBuilder.requestInfo.customerToken,
      selectedTab: state.embeddedReportBuilder.selectedTab,
      embedView: state.embeddedReportBuilder.embedView,
    }),
    shallowEqual,
  );

  const createReport = () => {
    dispatch(
      createCustomerReport({
        customerToken,
        postData: {
          report_builder_embed_id: reportBuilder.embed_id,
          name: '',
          config: {
            views: [createBlankView()],
          },
        },
      }),
    );
  };

  const renderTabBody = () => {
    switch (selectedTab) {
      case ReportBuilderTab.BUILT_IN_REPORTS:
        return <BuiltInReportTab reportBuilderEmbedId={reportBuilder.embed_id} />;
      case ReportBuilderTab.STARRED:
        return (
          <StarredReportTab
            customerToken={customerToken}
            reportBuilderEmbedId={reportBuilder.embed_id}
          />
        );
      default:
        return (
          <AllReportTab
            createReport={createReport}
            customerToken={customerToken}
            reportBuilderEmbedId={reportBuilder.embed_id}
          />
        );
    }
  };

  const isIframe = embedView === 'iframe';

  return (
    <div
      className={sprinkles({
        width: 'fill',
        flexItems: 'column',
        overflow: 'hidden',
        height: isIframe ? 'fillViewport' : 'fill',
      })}>
      <div className={styles.headerContainer}>
        <div className={styles.headerTitleContainer} style={{ height: 32 }}>
          <EmbedText heading="h1">Reports</EmbedText>
          <Button icon="plus" onClick={createReport} type="primary">
            New Report
          </Button>
        </div>

        <div className={styles.filterContainer} style={{ height: 40 }}>
          <div className={sprinkles({ flexItems: 'alignCenter', height: 'fill' })}>
            {Object.values(ReportBuilderTab).map((tab) => {
              const isSelected = tab === selectedTab;
              return (
                <div
                  className={isSelected ? styles.selectedTab : styles.unselectedTab}
                  key={tab}
                  onClick={() => dispatch(setSelectedTab({ tab }))}>
                  {tab === ReportBuilderTab.STARRED ? <Icon name="star" /> : null}
                  <EmbedText body="b2">{tab}</EmbedText>
                  {isSelected ? <div className={styles.selectedTabNotifier} /> : null}
                </div>
              );
            })}
          </div>
          <div></div>
        </div>
      </div>
      {renderTabBody()}
    </div>
  );
};
