import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import DropdownSelect from 'shared/DropdownSelect';

import { DashboardVariable, TimePeriodDropdownElemConfig } from 'types/dashboardTypes';
import { AnalyticsEventTracker } from 'utils/analyticsUtils';

type Props = {
  analyticsEventTracker?: AnalyticsEventTracker;
  config: TimePeriodDropdownElemConfig;
  onNewValueSelect: (newValue: DashboardVariable) => void;
  value?: number;
  disabled?: boolean;
  openElementToLeft: boolean;
};

const DashboardTimePeriodDropdownElement = ({
  analyticsEventTracker,
  config,
  value,
  onNewValueSelect,
  disabled,
  openElementToLeft,
}: Props) => {
  const options = config.values.map((option) => ({
    id: String(option.value),
    value: option.value,
    name: option.name,
  }));
  let selectedOption = options.find((option) => option.value === value);

  // If the selected value is not in the options, it is coming from a default variable.
  // In this case create your own option for this
  if (!selectedOption && value !== undefined) {
    selectedOption = { id: String(value), value, name: String(value) };
  }

  return (
    <DropdownSelect
      fillWidth
      minimal
      disabled={disabled}
      filterable={!!config.enableSearch}
      label={config.label}
      noSelectionText={config.placeholderText || ''}
      onCancelClick={() => {
        onNewValueSelect(undefined);
        analyticsEventTracker?.(REPORTED_ANALYTIC_ACTION_TYPES.DROPDOWN_SELECTED);
      }}
      onChange={(item) => {
        onNewValueSelect(item.value);
        analyticsEventTracker?.(REPORTED_ANALYTIC_ACTION_TYPES.DROPDOWN_SELECTED);
      }}
      openElementToLeft={openElementToLeft}
      options={options}
      selectedItem={selectedOption}
      showCancelBtn={config.enableCancel}
      useFakeLabel={config.label === ''}
      usePortal={config.usePortal}
    />
  );
};

export default DashboardTimePeriodDropdownElement;
