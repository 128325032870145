import { cloneDeep, get, isEqual, keyBy, reject, some, times, values } from 'utils/standard';
import { DateTime } from 'luxon';
import XRegExp from 'xregexp';

import {
  FilterClause,
  PivotOperationInstructions,
  OPERATION_TYPES,
  VISUALIZATION_OPERATIONS,
  V2_VISUALIZATION_OPERATIONS,
  FilterValueDateType,
  FilterValueRelativeDateType,
  NumberDisplayFormat,
  SortClause,
  AggedChartColumnInfo,
  KPIPeriodColumnInfo,
  NumberDisplayOptions,
  GradientType,
  FilterValueSourceType,
  NumberDisplayDisplayType,
  GROUPED_STACKED_OPERATION_TYPES,
  CategoryChartColumnInfo,
  VisualizePivotTableInstructions,
  SortAxis,
  FilterValueType,
  Aggregation,
} from 'constants/types';
import {
  DataPanelTemplate,
  FilterOperation,
  SortOperation,
  PivotOperation,
  VisualizeOperation,
} from 'types/dataPanelTemplate';
import {
  FILTER_OPS_NO_VALUE,
  FILTER_OPS_DATE_PICKER,
  FILTER_OPS_DATE_RANGE_PICKER,
  FILTER_OPS_RELATIVE_PICKER,
  FILTER_OPS_MULTISELECT,
  FilterOperator,
} from 'types/filterOperations';
import {
  DATE_PART_INPUT_AGG,
  V2_VIZ_INSTRUCTION_TYPE,
  BAR_CHART_SCROLL_DIRECTION,
  STRING,
  NUMBER_TYPES,
  TIME_COLUMN_TYPES,
  BOOLEAN,
} from 'constants/dataConstants';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { PeriodRangeTypes, PivotAgg } from 'types/dateRangeTypes';
import { getDatasetName } from './naming';
import { DataPanel, ResourceDataset } from 'types/exploResource';
import { getDataPanelDatasetId } from './exploResourceUtils';
import { canUseCanvasDataset, canvasDataPanelColumnsError } from './canvasConfigUtils';
import { isAggregationByDate } from './dateUtils';
import { instructionsReadyToDisplay } from 'pages/dashboardPage/charts/utils/trendUtils';

export const variableRegex = /(\{\{(?<variable>.+?)\}\})/g;

export const getDatapanelConfigReducerState = (dpt: DataPanelTemplate) => ({
  filterOperation: dpt.filter_op,
  pivotOperation: dpt.group_by_op,
  visualizeOperation: dpt.visualize_op,
  sortOperation: dpt.sort_op,
});

interface DataPanelConfigReducerState {
  filterOperation: FilterOperation;
  sortOperation: SortOperation;
  pivotOperation: PivotOperation;
  visualizeOperation: VisualizeOperation;
}

export const dataPanelToConfig = (dp: DataPanelTemplate): DataPanelConfigReducerState => {
  return {
    filterOperation: dp.filter_op,
    pivotOperation: dp.group_by_op,
    visualizeOperation: dp.visualize_op,
    sortOperation: dp.sort_op,
  };
};

export const shouldRecomputeDataForDataPanel = (
  prevConfig: DataPanelConfigReducerState,
  newConfig: DataPanelConfigReducerState,
): boolean => {
  const prevConfigClean = cleanConfig(cloneDeep(prevConfig));
  const newConfigClean = cleanConfig(cloneDeep(newConfig));

  if (!isDataPanelConfigReady(newConfigClean.visualizeOperation)) return false;

  if (
    prevConfigClean.filterOperation?.instructions.matchOnAll !==
      newConfigClean.filterOperation?.instructions.matchOnAll ||
    differentFilterOps(
      prevConfigClean.filterOperation?.instructions.filterClauses,
      newConfigClean.filterOperation?.instructions.filterClauses,
    )
  ) {
    return true;
  }

  if (
    differentSortOps(
      prevConfigClean.sortOperation?.instructions.sortColumns,
      newConfigClean.sortOperation?.instructions.sortColumns,
    )
  ) {
    return true;
  }

  if (
    differentPivotOps(
      prevConfigClean.pivotOperation?.instructions,
      newConfigClean.pivotOperation?.instructions,
    )
  ) {
    return true;
  }

  if (
    visualizeOperationNeedsRecompute(
      prevConfigClean.visualizeOperation,
      newConfigClean.visualizeOperation,
    )
  ) {
    return true;
  }

  return false;
};

export const shouldRecomputeSecondaryDataForDataPanel = (
  prevConfig: DataPanelConfigReducerState,
  newConfig: DataPanelConfigReducerState,
) => {
  if (isGradientAddedToTable(prevConfig.visualizeOperation, newConfig.visualizeOperation)) {
    return true;
  }

  if (isGoalQueryAddedToTable(prevConfig.visualizeOperation, newConfig.visualizeOperation)) {
    return true;
  }

  return false;
};

export const aggReady = (aggColumn?: AggedChartColumnInfo) =>
  aggColumn?.agg.id !== Aggregation.FORMULA || aggColumn?.agg.formula;

export const isPivotTableAggReady = (instructions: VisualizePivotTableInstructions | undefined) =>
  instructions?.aggregation?.agg.id === Aggregation.FORMULA
    ? !!instructions?.aggregation?.agg.formula
    : !!instructions?.aggregation;

export const isDataPanelConfigReady = (visualizeOperation: VisualizeOperation): boolean => {
  const { operation_type: operationType, instructions } = visualizeOperation;

  if (V2_VISUALIZATION_OPERATIONS.indexOf(visualizeOperation.operation_type) > -1) {
    if (
      operationType === OPERATION_TYPES.VISUALIZE_TABLE ||
      operationType === OPERATION_TYPES.VISUALIZE_REPORT_BUILDER
    ) {
      return true;
    } else if (
      operationType === OPERATION_TYPES.VISUALIZE_NUMBER_V2 ||
      operationType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2
    ) {
      return !!(
        instructions?.V2_KPI?.aggColumn?.column && aggReady(instructions?.V2_KPI.aggColumn)
      );
    } else if (operationType === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2) {
      const trendConfig = instructions.V2_KPI_TREND;
      return instructionsReadyToDisplay(
        trendConfig,
        trendConfig?.aggColumn ? [trendConfig?.aggColumn] : undefined,
      );
    } else if (operationType === OPERATION_TYPES.VISUALIZE_TREND_TABLE) {
      const trendConfig = instructions.V2_TREND_TABLE;
      return instructionsReadyToDisplay(trendConfig, trendConfig?.aggColumns);
    } else if (operationType === OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2) {
      return (
        !!instructions.V2_BOX_PLOT?.groupingColumn &&
        !!instructions.V2_BOX_PLOT?.calcColumns?.length
      );
    } else if (operationType === OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2) {
      return (
        !!instructions.V2_SCATTER_PLOT?.xAxisColumn && !!instructions.V2_SCATTER_PLOT?.yAxisColumn
      );
    } else if (
      operationType === OPERATION_TYPES.VISUALIZE_PIVOT_TABLE ||
      operationType === OPERATION_TYPES.VISUALIZE_PIVOT_REPORT_BUILDER
    ) {
      const joinedColumns = instructions.VISUALIZE_PIVOT_TABLE?.joinedColumns;
      const isJoinReadyOrUnselected =
        joinedColumns === undefined || (!!joinedColumns.joinColumn && !!joinedColumns.joinTable);

      return (
        isJoinReadyOrUnselected &&
        isPivotTableAggReady(instructions.VISUALIZE_PIVOT_TABLE) &&
        !!instructions.VISUALIZE_PIVOT_TABLE?.colColumn &&
        !!instructions.VISUALIZE_PIVOT_TABLE?.rowColumn
      );
    } else if (GROUPED_STACKED_OPERATION_TYPES.includes(operationType)) {
      return instructions.V2_TWO_DIMENSION_CHART?.groupingColumn !== undefined;
    } else if (operationType === OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST) {
      return (
        !!instructions.VISUALIZE_COLLAPSIBLE_LIST?.rowColumns &&
        !!instructions.VISUALIZE_COLLAPSIBLE_LIST?.aggregations
      );
    } else {
      const chartInstructions = visualizeOperation.instructions.V2_TWO_DIMENSION_CHART;

      if (chartInstructions?.categoryColumn?.bucket?.id === DATE_PART_INPUT_AGG) {
        return !!chartInstructions.categoryColumn.bucketElemId;
      }

      return !!chartInstructions?.aggColumns?.length && !!chartInstructions.categoryColumn;
    }
  }

  return true;
};

export const isDataPanelReadyToCompute = (
  dataPanel: DataPanel,
  datasets: Record<string, ResourceDataset>,
): boolean => {
  if (!isDataPanelConfigReady(dataPanel.visualize_op)) return false;

  const dataset = datasets[getDataPanelDatasetId(dataPanel)];
  if (!dataset) return false;

  if (!canUseCanvasDataset(dataset)) return false;
  if (canvasDataPanelColumnsError(dataPanel, dataset) !== null) return false;

  return true;
};

// If the visualization switches from being a table to visualization, or vise versa
// then recompute the dpt
const visualizeOperationNeedsRecompute = (
  oldVizOp?: VisualizeOperation,
  newVizOp?: VisualizeOperation,
) => {
  if (oldVizOp === undefined) return newVizOp !== undefined;
  if (newVizOp === undefined) return oldVizOp !== undefined;

  const oldVizIsViz = VISUALIZATION_OPERATIONS.indexOf(oldVizOp.operation_type) > -1;
  const newVizIsViz = VISUALIZATION_OPERATIONS.indexOf(newVizOp.operation_type) > -1;
  if (oldVizIsViz !== newVizIsViz) return true;

  if (V2_VISUALIZATION_OPERATIONS.indexOf(newVizOp.operation_type) > -1) {
    if (
      V2_VIZ_INSTRUCTION_TYPE[oldVizOp.operation_type] !==
      V2_VIZ_INSTRUCTION_TYPE[newVizOp.operation_type]
    )
      return true;

    // if scroll is enabled, recompute the data panel when the bart chart type switches axes.
    if (
      oldVizOp.instructions.V2_TWO_DIMENSION_CHART?.xAxisFormat?.enableScroll &&
      BAR_CHART_SCROLL_DIRECTION[oldVizOp.operation_type] !==
        BAR_CHART_SCROLL_DIRECTION[newVizOp.operation_type]
    )
      return true;

    const oldXAxisFormat = oldVizOp.instructions.V2_TWO_DIMENSION_CHART?.xAxisFormat;
    const newXAxisFormat = newVizOp.instructions.V2_TWO_DIMENSION_CHART?.xAxisFormat;
    const isNewSortSet =
      newXAxisFormat?.sortAxis === SortAxis.COLUMN &&
      newXAxisFormat?.sortOption &&
      newXAxisFormat?.sortColumns;

    const isOldSortSet =
      oldXAxisFormat?.sortAxis === SortAxis.COLUMN &&
      oldXAxisFormat?.sortOption &&
      oldXAxisFormat?.sortColumns;

    const tableRequiresResort =
      oldVizOp.instructions.VISUALIZE_TABLE.orderedColumnNames?.[0] !==
        newVizOp.instructions.VISUALIZE_TABLE.orderedColumnNames?.[0] &&
      newVizOp.instructions.VISUALIZE_TABLE.shouldVisuallyGroupByFirstColumn;

    if (tableRequiresResort) {
      return true;
    }

    return !isEqual(
      {
        generalFormatOptions: {
          enableRawDataDrilldown: newVizOp.generalFormatOptions?.enableRawDataDrilldown,
          customMenu: newVizOp.generalFormatOptions?.customMenu,
        },
        VISUALIZE_TABLE: {
          rowsPerPage: newVizOp.instructions.VISUALIZE_TABLE.rowsPerPage,
        },
        V2_TWO_DIMENSION_CHART: {
          aggColumns: removeFriendlyNameFromAggs(
            newVizOp.instructions.V2_TWO_DIMENSION_CHART?.aggColumns,
          ),
          categoryColumn: newVizOp.instructions.V2_TWO_DIMENSION_CHART?.categoryColumn,
          colorColumnOptions: removeFriendlyNameFromColorCols(
            newVizOp.instructions.V2_TWO_DIMENSION_CHART?.colorColumnOptions,
          ),
          groupingOption: newVizOp.instructions.V2_TWO_DIMENSION_CHART?.groupingColumn,
          xAxisFormat: newVizOp.instructions.V2_TWO_DIMENSION_CHART?.xAxisFormat?.enableScroll,
          xAxisSorting: isNewSortSet
            ? {
                sortAxis: newXAxisFormat?.sortAxis,
                sortCol: newXAxisFormat?.sortColumns,
              }
            : undefined,
        },
        V2_KPI: {
          aggColumn: newVizOp.instructions.V2_KPI?.aggColumn,
          trendColumn: newVizOp.instructions.V2_KPI?.trendColumn,
        },
        V2_BOX_PLOT: {
          groupingColumn: newVizOp.instructions.V2_BOX_PLOT?.groupingColumn,
          calcColumns: newVizOp.instructions.V2_BOX_PLOT?.calcColumns,
        },
        V2_SCATTER_PLOT: {
          xAxisColumn: newVizOp.instructions.V2_SCATTER_PLOT?.xAxisColumn,
          yAxisColumn: newVizOp.instructions.V2_SCATTER_PLOT?.yAxisColumn,
          groupingColumn: newVizOp.instructions.V2_SCATTER_PLOT?.groupingColumn,
        },
        V2_KPI_TREND: {
          aggColumn: newVizOp.instructions.V2_KPI_TREND?.aggColumn,
          periodColumn: removeDatesIfNotCompleteOrNotCustom(
            newVizOp.instructions.V2_KPI_TREND?.periodColumn,
          ),
          periodComparisonRange: newVizOp.instructions.V2_KPI_TREND?.periodComparisonRange,
          trendGrouping: newVizOp.instructions.V2_KPI_TREND?.trendGrouping,
          hideTrendLines: newVizOp.instructions.V2_KPI_TREND?.hideTrendLines,
        },
        V2_TREND_TABLE: {
          aggColumns: newVizOp.instructions.V2_TREND_TABLE?.aggColumns,
          periodColumn: removeDatesIfNotCompleteOrNotCustom(
            newVizOp.instructions.V2_TREND_TABLE?.periodColumn,
          ),
          periodComparisonRange: newVizOp.instructions.V2_TREND_TABLE?.periodComparisonRange,
          trendGrouping: newVizOp.instructions.V2_TREND_TABLE?.trendGrouping,
        },
        PIVOT_TABLE: {
          rowColumn: newVizOp.instructions.VISUALIZE_PIVOT_TABLE?.rowColumn,
          colColumn: newVizOp.instructions.VISUALIZE_PIVOT_TABLE?.colColumn,
          aggregation: newVizOp.instructions.VISUALIZE_PIVOT_TABLE?.aggregation,
          joinedColumns: newVizOp.instructions.VISUALIZE_PIVOT_TABLE?.joinedColumns,
          displaySumRow: newVizOp.instructions.VISUALIZE_PIVOT_TABLE?.displaySumRow,
        },
        VISUALIZE_COLLAPSIBLE_LIST: {
          rowColumns: newVizOp.instructions.VISUALIZE_COLLAPSIBLE_LIST?.rowColumns,
          aggregations: newVizOp.instructions.VISUALIZE_COLLAPSIBLE_LIST?.aggregations?.map(
            removeFriendlyNameFromAggColInfo,
          ),
          categories: newVizOp.instructions.VISUALIZE_COLLAPSIBLE_LIST?.categories,
        },
      },
      {
        generalFormatOptions: {
          enableRawDataDrilldown: oldVizOp.generalFormatOptions?.enableRawDataDrilldown,
          customMenu: oldVizOp.generalFormatOptions?.customMenu,
        },
        VISUALIZE_TABLE: {
          rowsPerPage: oldVizOp.instructions.VISUALIZE_TABLE.rowsPerPage,
        },
        V2_TWO_DIMENSION_CHART: {
          aggColumns: removeFriendlyNameFromAggs(
            oldVizOp.instructions.V2_TWO_DIMENSION_CHART?.aggColumns,
          ),
          categoryColumn: oldVizOp.instructions.V2_TWO_DIMENSION_CHART?.categoryColumn,
          colorColumnOptions: removeFriendlyNameFromColorCols(
            oldVizOp.instructions.V2_TWO_DIMENSION_CHART?.colorColumnOptions,
          ),
          groupingOption: oldVizOp.instructions.V2_TWO_DIMENSION_CHART?.groupingColumn,
          xAxisFormat: oldVizOp.instructions.V2_TWO_DIMENSION_CHART?.xAxisFormat?.enableScroll,
          xAxisSorting: isOldSortSet
            ? {
                sortAxis: oldXAxisFormat?.sortAxis,
                sortCol: oldXAxisFormat?.sortColumns,
              }
            : undefined,
        },
        V2_KPI: {
          aggColumn: oldVizOp.instructions.V2_KPI?.aggColumn,
          trendColumn: oldVizOp.instructions.V2_KPI?.trendColumn,
        },
        V2_BOX_PLOT: {
          groupingColumn: oldVizOp.instructions.V2_BOX_PLOT?.groupingColumn,
          calcColumns: oldVizOp.instructions.V2_BOX_PLOT?.calcColumns,
        },
        V2_SCATTER_PLOT: {
          xAxisColumn: oldVizOp.instructions.V2_SCATTER_PLOT?.xAxisColumn,
          yAxisColumn: oldVizOp.instructions.V2_SCATTER_PLOT?.yAxisColumn,
          groupingColumn: oldVizOp.instructions.V2_SCATTER_PLOT?.groupingColumn,
        },
        V2_KPI_TREND: {
          aggColumn: oldVizOp.instructions.V2_KPI_TREND?.aggColumn,
          periodColumn: removeDatesIfNotCompleteOrNotCustom(
            oldVizOp.instructions.V2_KPI_TREND?.periodColumn,
          ),
          periodComparisonRange: oldVizOp.instructions.V2_KPI_TREND?.periodComparisonRange,
          trendGrouping: oldVizOp.instructions.V2_KPI_TREND?.trendGrouping,
          hideTrendLines: oldVizOp.instructions.V2_KPI_TREND?.hideTrendLines,
        },
        V2_TREND_TABLE: {
          aggColumns: oldVizOp.instructions.V2_TREND_TABLE?.aggColumns,
          periodColumn: removeDatesIfNotCompleteOrNotCustom(
            oldVizOp.instructions.V2_TREND_TABLE?.periodColumn,
          ),
          periodComparisonRange: oldVizOp.instructions.V2_TREND_TABLE?.periodComparisonRange,
          trendGrouping: oldVizOp.instructions.V2_TREND_TABLE?.trendGrouping,
        },
        PIVOT_TABLE: {
          rowColumn: oldVizOp.instructions.VISUALIZE_PIVOT_TABLE?.rowColumn,
          colColumn: oldVizOp.instructions.VISUALIZE_PIVOT_TABLE?.colColumn,
          aggregation: oldVizOp.instructions.VISUALIZE_PIVOT_TABLE?.aggregation,
          joinedColumns: oldVizOp.instructions.VISUALIZE_PIVOT_TABLE?.joinedColumns,
          displaySumRow: oldVizOp.instructions.VISUALIZE_PIVOT_TABLE?.displaySumRow,
        },
        VISUALIZE_COLLAPSIBLE_LIST: {
          rowColumns: oldVizOp.instructions.VISUALIZE_COLLAPSIBLE_LIST?.rowColumns,
          aggregations: oldVizOp.instructions.VISUALIZE_COLLAPSIBLE_LIST?.aggregations?.map(
            removeFriendlyNameFromAggColInfo,
          ),
          categories: oldVizOp.instructions.VISUALIZE_COLLAPSIBLE_LIST?.categories,
        },
      },
    );
  }
  return false;
};

const removeFriendlyNameFromAggColInfo = (agg: AggedChartColumnInfo) => {
  return {
    ...agg,
    column: {
      ...agg.column,
      friendly_name: undefined,
    },
  };
};

export const isSecondaryDataRequired = (displayOptions?: NumberDisplayOptions) => {
  const { displayType, displayTypeOptions, format, gradientType, useColumnMaxForGoal } =
    displayOptions || {};

  const hasGradient = [GradientType.DIVERGING, GradientType.LINEAR].includes(
    gradientType ?? GradientType.NONE,
  );
  const hasPercentColumnMaxGoal = format === NumberDisplayFormat.PERCENT && useColumnMaxForGoal;
  const hasProgressBarColumnMaxGoal =
    displayType === NumberDisplayDisplayType.PROGRESS_BAR &&
    displayTypeOptions?.useColumnMaxForProgressBarGoal;

  return hasGradient || hasPercentColumnMaxGoal || hasProgressBarColumnMaxGoal;
};

const isGradientAddedToTable = (oldVizOp?: VisualizeOperation, newVizOp?: VisualizeOperation) => {
  let shouldRefetch = false;
  const oldSchemaDisplayOptions = oldVizOp?.instructions.VISUALIZE_TABLE.schemaDisplayOptions || {};
  const newSchemaDisplayOptions = newVizOp?.instructions.VISUALIZE_TABLE.schemaDisplayOptions || {};

  Object.keys(newSchemaDisplayOptions).forEach((columnName) => {
    /**
     * Casting as union type with undefined because the column may not actually be a number column, so
     * we should use optional chaining when accessing gradientType, otherwise we may get a runtime error.
     * Unioning with undefined forces us to use optional chaining.
     */
    const oldDisplayOptions = oldSchemaDisplayOptions[columnName] as
      | NumberDisplayOptions
      | undefined;
    const newDisplayOptions = newSchemaDisplayOptions[columnName] as
      | NumberDisplayOptions
      | undefined;

    // If secondary data was already required, we already fetched it
    if (isSecondaryDataRequired(oldDisplayOptions)) {
      return;
    }

    const oldSchemaGradientType = oldDisplayOptions?.gradientType;
    const newSchemaGradientType = newDisplayOptions?.gradientType;

    if (
      (oldSchemaGradientType === GradientType.NONE || oldSchemaGradientType === undefined) &&
      [GradientType.DIVERGING, GradientType.LINEAR].includes(
        newSchemaGradientType || GradientType.NONE,
      )
    ) {
      shouldRefetch = true;
    }
  });

  return shouldRefetch;
};

const isGoalQueryAddedToTable = (oldVizOp?: VisualizeOperation, newVizOp?: VisualizeOperation) => {
  let isProgressBarQueryAdded = false;
  let isChangedToFormatWithGoalQuery = false;
  const oldSchemaDisplayOptions = oldVizOp?.instructions.VISUALIZE_TABLE.schemaDisplayOptions || {};
  const newSchemaDisplayOptions = newVizOp?.instructions.VISUALIZE_TABLE.schemaDisplayOptions || {};

  Object.keys(newSchemaDisplayOptions).forEach((columnName) => {
    /**
     * Casting as union type with undefined because the column may not actually be a number column, so
     * we should use optional chaining when accessing gradientType, otherwise we may get a runtime error.
     * Unioning with undefined forces us to use optional chaining.
     */
    const oldDisplayOptions = oldSchemaDisplayOptions[columnName] as
      | NumberDisplayOptions
      | undefined;
    const newDisplayOptions = newSchemaDisplayOptions[columnName] as
      | NumberDisplayOptions
      | undefined;

    // If secondary data was already required, we already fetched it
    if (isSecondaryDataRequired(oldDisplayOptions)) {
      return;
    }

    const {
      displayType: oldSchemaDisplayType,
      displayTypeOptions: oldSchemaDisplayTypeOptions,
      format: oldSchemaFormat,
      useColumnMaxForGoal: oldSchemaUsesColumnMax,
    } = oldDisplayOptions ?? {};
    const {
      displayType: newSchemaDisplayType,
      displayTypeOptions: newSchemaDisplayTypeOptions,
      format: newSchemaFormat,
      useColumnMaxForGoal: newSchemaUsesColumnMax,
    } = newDisplayOptions ?? {};

    if (
      (!oldSchemaUsesColumnMax && newSchemaUsesColumnMax) ||
      (!oldSchemaDisplayTypeOptions?.useColumnMaxForProgressBarGoal &&
        newSchemaDisplayTypeOptions?.useColumnMaxForProgressBarGoal)
    ) {
      isProgressBarQueryAdded = true;
    }

    if (
      oldSchemaFormat !== NumberDisplayFormat.PERCENT &&
      newSchemaFormat === NumberDisplayFormat.PERCENT &&
      newSchemaUsesColumnMax
    ) {
      isChangedToFormatWithGoalQuery = true;
    }

    if (
      oldSchemaDisplayType !== NumberDisplayDisplayType.PROGRESS_BAR &&
      newSchemaDisplayType === NumberDisplayDisplayType.PROGRESS_BAR &&
      newSchemaDisplayTypeOptions?.useColumnMaxForProgressBarGoal
    ) {
      isChangedToFormatWithGoalQuery = true;
    }
  });

  return isProgressBarQueryAdded || isChangedToFormatWithGoalQuery;
};

const removeFriendlyNameFromAggs = (aggCols?: AggedChartColumnInfo[]) => {
  return (aggCols || []).map((aggCol) => ({
    agg: aggCol.agg,
    column: {
      ...aggCol.column,
      friendly_name: undefined,
    },
  }));
};

const removeFriendlyNameFromColorCols = (colorCols?: CategoryChartColumnInfo[]) => {
  return (colorCols || []).map((colorCol) => ({
    bucket: colorCol.bucket,
    column: {
      ...colorCol.column,
      friendly_name: undefined,
    },
  }));
};

const removeDatesIfNotCompleteOrNotCustom = (periodColumn?: KPIPeriodColumnInfo) => {
  if (!periodColumn) return;

  if (
    periodColumn.periodRange === PeriodRangeTypes.CUSTOM_RANGE ||
    periodColumn.periodRange === PeriodRangeTypes.DATE_RANGE_INPUT ||
    periodColumn.periodRange === PeriodRangeTypes.TIME_PERIOD_DROPDOWN
  ) {
    return periodColumn;
  } else {
    return {
      column: periodColumn.column,
      periodRange: periodColumn.periodRange,
      trendDateOffset: periodColumn.trendDateOffset,
    };
  }
};

// Removes configs that do not impact the resulting table (incomplete configs)
const cleanConfig = (config: DataPanelConfigReducerState) => {
  if (config.filterOperation) cleanFilterConfig(config.filterOperation);
  if (config.pivotOperation) cleanPivotConfig(config.pivotOperation);
  return config;
};

const cleanFilterConfig = (filterOperation: FilterOperation) => {
  filterOperation.instructions.filterClauses = reject(
    filterOperation.instructions.filterClauses,
    isFilterClauseIncomplete,
  );
};

export const isFilterClauseIncomplete = (clause: FilterClause) => {
  if (!clause.filterColumn || !clause.filterOperation) return true;

  // If no value is required for operation no need to check anything else
  if (FILTER_OPS_NO_VALUE.has(clause.filterOperation.id)) return false;

  // If the filter is a variable-based filter value, then it will be incomplete
  // if the variable is not yet specified
  if (clause.filterValueSource === FilterValueSourceType.VARIABLE) {
    return clause.filterValueVariableId === undefined;
  }

  if (FILTER_OPS_MULTISELECT.has(clause.filterOperation.id)) {
    if (clause.filterValue === undefined) {
      return true;
    } else {
      try {
        const filterValue = JSON.parse(clause.filterValue as string);
        return !(Array.isArray(filterValue) || filterValue.length > 0);
      } catch {
        return true;
      }
    }
  }

  if (clause.filterValue === undefined || clause.filterValue === null || clause.filterValue === '')
    return true;

  if (FILTER_OPS_DATE_RANGE_PICKER.has(clause.filterOperation.id)) {
    const rangeValue = clause.filterValue as FilterValueDateType;
    return !rangeValue.startDate || !rangeValue.endDate;
  } else if (FILTER_OPS_DATE_PICKER.has(clause.filterOperation.id)) {
    const dateValue = clause.filterValue as FilterValueDateType;
    return !dateValue.startDate;
  } else if (FILTER_OPS_RELATIVE_PICKER.has(clause.filterOperation.id)) {
    const relativeDateValue = clause.filterValue as FilterValueRelativeDateType;
    return !relativeDateValue.number || !relativeDateValue.relativeTimeType;
  }

  return false;
};

const cleanPivotConfig = (pivotOperation: PivotOperation) => {
  pivotOperation.instructions.pivotedOnCols = pivotOperation.instructions.pivotedOnCols.filter(
    (col) => !!col.name,
  );

  pivotOperation.instructions.aggregations = reject(
    pivotOperation.instructions.aggregations,
    (agg) => !agg.aggedOnColumn || !agg.type,
  );
  return pivotOperation;
};

const differentFilterOps = (prevClauses?: FilterClause[], newClauses?: FilterClause[]) => {
  if (prevClauses === undefined) return newClauses !== undefined;
  if (newClauses === undefined) return prevClauses !== undefined;

  return listsAreDifferent(prevClauses, newClauses);
};

const differentSortOps = (prevClauses?: SortClause[], newClauses?: SortClause[]) => {
  if (prevClauses === undefined) return newClauses !== undefined;
  if (newClauses === undefined) return prevClauses !== undefined;

  return listsAreDifferent(prevClauses, newClauses);
};

const differentPivotOps = (
  prevInst?: PivotOperationInstructions,
  newInst?: PivotOperationInstructions,
) => {
  if (prevInst === undefined) return newInst !== undefined;
  if (newInst === undefined) return prevInst !== undefined;

  return (
    listsAreDifferent(prevInst.pivotedOnCols, newInst.pivotedOnCols) ||
    listsAreDifferent(prevInst.aggregations, newInst.aggregations)
  );
};

export const listsAreDifferent = (list1: unknown[], list2: unknown[]) => {
  if (list1.length !== list2.length) return true;

  return some(times(list1.length, (i) => !isEqual(list1[i], list2[i])));
};

export const replaceTemplatesWithValues = (
  s: string,
  variables: DashboardVariableMap,
  datasets?: Record<string, ResourceDataset>,
) => {
  if (s.trim() === '') return s;

  const datasetNameToDataset = keyBy(values(datasets ?? {}), (dataset) => getDatasetName(dataset));

  //@ts-ignore
  XRegExp.forEach(s, variableRegex, (match) => {
    const varName = match[2]?.trim();
    if (varName) {
      let replacement = varName;
      if (varName in variables) {
        const value = variables[varName];
        replacement = value === undefined ? '' : String(value);
      } else if (varName.indexOf('.') > -1) {
        const splitVar = varName.split('.');
        // If there are two parts to the variable, and the first part is a dataset name
        // then we know to replace the value with a dataset value.
        if (splitVar.length === 2 && splitVar[0] in datasetNameToDataset) {
          const [datasetName, columnName] = splitVar;
          const dataset = datasetNameToDataset[datasetName];

          // just use the value in the first row because we're leaving it to the
          // customer to do any aggregation or work in SQL before passing the table
          // to this component
          replacement =
            // explicitly check undefined for the actual value because 0 is falsy,
            // but would be a valid value we want to display, for example
            dataset._rows && dataset._rows[0] && dataset._rows[0][columnName] !== undefined
              ? String(dataset._rows[0][columnName])
              : '';
        } else {
          // If we reached here, then there splitVar can be any length and so we just check the list
          // against `variables` and try to pull out a value if possible. If a value doesn't come
          // then we default to varName
          replacement = get(variables, splitVar, varName);
        }
      }

      s = s.replace(match[0], replacement);
    }
  });
  return s;
};

export const getQueryTablesReferencedByText = (
  text: string,
  datasets: { [datasetId: string]: ResourceDataset },
) => {
  if (text.trim() === '') return [];

  const datasetNameToDataset = keyBy(Object.values(datasets), (dataset) =>
    getDatasetName(dataset, false),
  );

  const queryTables: { id: string; name: string }[] = [];

  //@ts-ignore
  XRegExp.forEach(text, variableRegex, (match) => {
    const varName = match[2]?.trim();
    if (varName) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [datasetName, _, ...other] = varName.split('.');

      // nesting further than table.column_name isn't supported
      if (other.length === 0 && datasetName in datasetNameToDataset) {
        const dataset = datasetNameToDataset[datasetName];

        queryTables.push({ id: dataset.id, name: datasetName });
      }
    }
  });

  return queryTables;
};

export const dataPanelRequiresPrimaryData = (visualizeOperation: VisualizeOperation) => {
  if (visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_TREND_TABLE) return true;
  if (visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2) {
    return !visualizeOperation.instructions.V2_KPI_TREND?.hideTrendLines;
  }

  return true;
};

const convertColumnAndValueIntoFilter = (
  column: CategoryChartColumnInfo,
  category?: string | number,
  excludedCategories?: (string | number)[],
): FilterClause[] | undefined => {
  const col = column.column;

  if (!col.type || !col.name || !category) return [];

  const createFilter = (filterOp: FilterOperator, filterValue?: FilterValueType): FilterClause => {
    return {
      // This is checked above so its not null
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      filterColumn: { name: col.name!, type: col.type! },
      filterValue,
      filterOperation: { id: filterOp },
    };
  };

  if (category === 'Other' && excludedCategories !== undefined) {
    if (col.type !== STRING && !NUMBER_TYPES.has(col.type)) return;
    const filterOp =
      col.type === STRING ? FilterOperator.STRING_IS_NOT_IN : FilterOperator.NUMBER_IS_NOT_IN;
    return [createFilter(filterOp, JSON.stringify(excludedCategories))];
  }

  if (category === 'null') return [createFilter(FilterOperator.IS_EMPTY)];

  if (col.type === STRING) {
    return [createFilter(FilterOperator.STRING_IS, category)];
  } else if (NUMBER_TYPES.has(col.type)) {
    if (column.bucketSize !== undefined) {
      const categoryAsNum = parseInt(String(category));
      const upperBound = categoryAsNum + parseInt(String(column.bucketSize));

      return [
        createFilter(FilterOperator.NUMBER_GTE, categoryAsNum),
        createFilter(FilterOperator.NUMBER_LT, upperBound),
      ];
    } else {
      return [createFilter(FilterOperator.NUMBER_EQ, category)];
    }
  } else if (col.type === BOOLEAN) {
    const boolOp =
      category === 'true' ? FilterOperator.BOOLEAN_IS_TRUE : FilterOperator.BOOLEAN_IS_FALSE;
    return [createFilter(boolOp)];
  } else if (TIME_COLUMN_TYPES.has(col.type)) {
    if (!column.bucket) return;
    if (!isAggregationByDate(column.bucket)) return;

    const startDate = DateTime.fromMillis(parseInt(category as string), { locale: 'UTC' });
    let endDate = startDate;

    switch (column.bucket.id) {
      case PivotAgg.DATE_DAY:
        return [createFilter(FilterOperator.DATE_IS, { startDate: startDate?.toUTC().toISO() })];
      case PivotAgg.DATE_MONTH:
        endDate = startDate.plus({ month: 1 }).minus({ day: 1 });
        break;
      case PivotAgg.DATE_YEAR:
        endDate = startDate.plus({ year: 1 }).minus({ day: 1 });
        break;
      case PivotAgg.DATE_WEEK:
        endDate = startDate.plus({ week: 1 }).minus({ day: 1 });
        break;
      case PivotAgg.DATE_HOUR:
        endDate = startDate.plus({ hour: 1 });
        break;
    }

    return [
      createFilter(FilterOperator.DATE_IS_BETWEEN, {
        startDate: startDate?.toUTC().toISO(),
        endDate: endDate?.toUTC().toISO(),
      }),
    ];
  }
};

export const constructFilterFromDrilldownColumn = (
  categoryColumn: CategoryChartColumnInfo,
  category?: string | number,
  subCategoryColumn?: CategoryChartColumnInfo,
  subCategory?: string | number,
  excludedCategories?: (string | number)[],
): FilterClause[] | undefined => {
  const col = categoryColumn.column;
  if (!col.type || !col.name || !category) return;

  const categoryFilters = convertColumnAndValueIntoFilter(
    categoryColumn,
    category,
    excludedCategories,
  );

  if (!categoryFilters || !subCategoryColumn) return categoryFilters;
  const subCol = subCategoryColumn.column;
  if (!subCol.type || !subCol.name || !subCategory) return categoryFilters;

  const subCategoryFilters = convertColumnAndValueIntoFilter(subCategoryColumn, subCategory);

  return categoryFilters.concat(subCategoryFilters || []);
};
