import { useMemo, useState, FC } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { maxBy, orderBy } from 'utils/standard';
import { useLocalStorage } from 'usehooks-ts';

import { PageHeader, ViewType } from 'components/PageHeader';
import { TextFieldModal } from 'components/modals/textFieldModal';
import EmptyState from 'components/EmptyPageActionCallout';
import ExploLoadingSpinner from 'components/ExploLoadingSpinner';
import DotsDropdown from 'components/dotsDropdown';
import DisabledDashboardMenu from 'pages/homeAppPage/DisabledDashboardMenu';
import { Spinner, sprinkles } from 'components/ds';
import TablePager from 'components/dataTable/tablePager';
import ResourceConfigurationMenu from './ResourceConfigurationMenu';
import * as styles from './styles.css';
import { ResourceItem } from './ResourceItem';

import { ReduxState } from 'reducers/rootReducer';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ACTION } from 'actions/types';
import { trackEvent, EVENTS } from 'analytics/exploAnalytics';
import { DashboardTemplate, createDashboardTemplate } from 'actions/dashboardTemplateActions';
import { getTimezoneAwareUnix } from 'utils/timezoneUtils';
import { DashboardAttribute, ExploreEmailCadence } from 'actions/teamActions';
import { isCreateResourceDisabled } from 'utils/paymentPlanUtils';
import { showErrorToast } from 'shared/sharedToasts';
import { ResourcePageType } from 'types/exploResource';
import { getPermissionEntity } from 'utils/exploResourceUtils';
import { Canvas, createCanvas } from 'actions/canvasActions';
import {
  canUserModifyResource,
  canUserViewResourceConfigurationMenu,
  doesUserHavePermission,
} from 'utils/permissionUtils';
import { PERMISSIONED_ACTIONS } from 'constants/roleConstants';
import { createReportBuilder, ReportBuilder } from 'actions/reportBuilderActions';

const RESOURCES_PER_LIST_PAGE = 40;
const RESOURCES_PER_CARD_PAGE = 24;

const VIEW_TYPE_EXPLORE_KEY = 'explore_page_view_type_key';
const VIEW_TYPE_ARCHITECT_KEY = 'architect_page_view_type_key';
const VIEW_TYPE_REPORT_BUILDER_KEY = 'report_builder_page_view_type_key';

export type Resource = DashboardTemplate | Canvas | ReportBuilder;

type Props = {
  pageType: ResourcePageType;
  resources: Resource[] | undefined;
  resourcesLoading: boolean;

  dashboardAttributes?: DashboardAttribute[];
  emailCadenceList?: ExploreEmailCadence[];
};

const getProductKey = (pageType: ResourcePageType): string => {
  if (pageType === ResourcePageType.EXPLORE) return VIEW_TYPE_EXPLORE_KEY;
  if (pageType === ResourcePageType.ARCHITECT) return VIEW_TYPE_ARCHITECT_KEY;
  return VIEW_TYPE_REPORT_BUILDER_KEY;
};

export const ResourceListPage: FC<Props> = ({
  pageType,
  resources,
  resourcesLoading,
  dashboardAttributes,
  emailCadenceList,
}): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { currentUser, teamPaymentPlan, createResourceLoading } = useSelector(
    (state: ReduxState) => ({
      currentUser: state.currentUser,
      teamPaymentPlan: state.currentUser.team?.payment_plan,

      createResourceLoading: createLoadingSelector(
        [ACTION.CREATE_DASHBOARD_TEMPLATE, ACTION.CLONE_DASHBOARD_TEMPLATE, ACTION.CREATE_CANVAS],
        false,
      )(state),
    }),
    shallowEqual,
  );

  const isExploreProduct = pageType === ResourcePageType.EXPLORE;
  const isArchitectProduct = pageType === ResourcePageType.ARCHITECT;

  const [searchString, setSearchString] = useState('');
  const [currPage, setCurrPage] = useState(0);
  const [createResourceModalOpen, setCreateResourceModalOpen] = useState(false);
  const [resourceUpdating, setResourceUpdating] = useState<number | undefined>();
  const [viewType, setViewType] = useLocalStorage<ViewType>(getProductKey(pageType), ViewType.List);

  const resourcePermissionEntity = getPermissionEntity(pageType);

  const userPermissions = currentUser.permissions[resourcePermissionEntity];

  const userCanCreateResource = doesUserHavePermission(
    userPermissions,
    PERMISSIONED_ACTIONS.CREATE,
  );

  const userCanConfigureResource = canUserViewResourceConfigurationMenu(
    currentUser,
    resourcePermissionEntity,
  );

  const resourcesPerPage =
    viewType === ViewType.List ? RESOURCES_PER_LIST_PAGE : RESOURCES_PER_CARD_PAGE;

  const filteredResources = useMemo(() => {
    if (!resources) return [];

    const sortedResources = orderBy(
      resources,
      ({ latest_versions }) => {
        const lastModified = maxBy(latest_versions, (info) => info.version_number)?.modified;
        return lastModified ? getTimezoneAwareUnix(lastModified) : 0;
      },
      'desc',
    );

    if (searchString.trim() !== '') {
      const lowerSearchString = searchString.toLocaleLowerCase();
      return sortedResources.filter(({ name }) => {
        return name.toLocaleLowerCase().includes(lowerSearchString);
      });
    }
    return sortedResources;
  }, [searchString, resources]);

  const getResourceText = (params?: { plural?: boolean; capitalized?: boolean }) => {
    let str = '';

    switch (pageType) {
      case ResourcePageType.REPORT_BUILDER:
        str = 'report builder';
        break;
      case ResourcePageType.ARCHITECT:
        str = 'dashboard';
        break;
      case ResourcePageType.EXPLORE:
        str = 'dashboard';
        break;
    }

    if (params?.plural) str = str + 's';

    if (params?.capitalized)
      str = str
        .split(' ')
        .map((subStr) => subStr.charAt(0).toUpperCase() + subStr.slice(1))
        .join(' ');

    return str;
  };

  const getPageTitle = () => {
    switch (pageType) {
      case ResourcePageType.REPORT_BUILDER:
        return 'Report Builder';
      case ResourcePageType.ARCHITECT:
        return 'Architect';
      case ResourcePageType.EXPLORE:
        return 'Dashboards';
    }
  };

  if (resourcesLoading) return <ExploLoadingSpinner />;

  if (!resources) {
    return (
      <div className={styles.errorLoadingResources}>
        Error Loading {getResourceText({ plural: true, capitalized: true })}
      </div>
    );
  }

  const createResourceDisabled = isCreateResourceDisabled(
    isExploreProduct,
    resources.length,
    teamPaymentPlan,
  );

  const renderDotsMenu = (
    resource: Resource,
    emailCadence: ExploreEmailCadence | undefined,
    isCard: boolean,
    isDisabled: boolean,
  ) => {
    let panelMenu;

    if (!userCanConfigureResource) return null;

    const containerStyle = isCard ? styles.dotsMenuContainerCard : styles.dotsMenuContainer;

    const iconStyle = isCard ? styles.dotsMenuIconCard : styles.dotsMenuIcon;

    if (resource.id === resourceUpdating)
      return (
        <div className={containerStyle}>
          <Spinner fillContainer className={iconStyle} size="md" />
        </div>
      );

    if (isDisabled) {
      // Only DashboardTemplate can be disabled
      panelMenu = (
        <DisabledDashboardMenu
          dashboardTemplate={resource as DashboardTemplate}
          dashboardTemplateList={resources as DashboardTemplate[]}
          setLoadingStateForDashboard={(isLoading) =>
            setResourceUpdating(isLoading ? resource.id : undefined)
          }
        />
      );
    } else {
      panelMenu = (
        <ResourceConfigurationMenu
          createResourceDisabled={createResourceDisabled}
          dashboardAttributes={dashboardAttributes}
          emailCadence={emailCadence}
          pageType={pageType}
          resource={resource}
          resourcePermissionEntity={resourcePermissionEntity}
          setLoadingStateForResource={(isLoading) =>
            setResourceUpdating(isLoading ? resource.id : undefined)
          }
        />
      );
    }

    return (
      <div className={containerStyle} onClick={(e) => e.preventDefault()}>
        <DotsDropdown iconButtonClassName={iconStyle} panelMenu={panelMenu} />
      </div>
    );
  };

  const viewResource = (resource: Resource) => {
    const emailCadence = isExploreProduct
      ? emailCadenceList?.find((cadence) => cadence.dashboard_template_id === resource.id)
      : undefined;

    const isDisabled = 'disabled' in resource ? resource.disabled ?? false : false;
    const onClickUrl = isArchitectProduct
      ? canUserModifyResource(userPermissions)
        ? `/blueprint/${resource.id}/datasets`
        : `/blueprint/${resource.id}/user-dashboards`
      : isExploreProduct
      ? `/dashboard/${resource.id}`
      : `/report-builder/${resource.id}/datasets`;

    return (
      <ResourceItem
        dotsMenu={renderDotsMenu(resource, emailCadence, viewType === ViewType.Card, isDisabled)}
        hasEmailState={emailCadence !== undefined}
        isCard={viewType === ViewType.Card}
        key={resource.id}
        onClickUrl={onClickUrl}
        resource={resource}
        showPreview={pageType !== ResourcePageType.REPORT_BUILDER}
      />
    );
  };

  const renderList = () => {
    if (resources.length === 0 && userCanCreateResource) {
      return <EmptyState text={`Get started by creating your first ${getResourceText()}`} />;
    }

    const slicedList = filteredResources.slice(
      currPage * resourcesPerPage,
      (currPage + 1) * resourcesPerPage,
    );

    if (viewType === ViewType.Card) {
      return (
        <div className={sprinkles({ flex: 1 })}>
          <div className={styles.cardsGrid}>{slicedList.map(viewResource)}</div>
        </div>
      );
    }

    return (
      <div className={styles.resourceListContainer}>
        <div
          className={sprinkles({
            display: 'flex',
            justifyContent: 'flex-start',
            body: 'section',
            color: 'contentSecondary',
          })}>
          <div className={styles.resourceListHeader}>
            <div className={styles.resourceListColumnGroup}>
              {isArchitectProduct || isExploreProduct ? (
                <div style={{ width: styles.RESOURCE_LIST_PREVIEW_WIDTH }} />
              ) : null}
              <div
                className={sprinkles({ paddingX: 'sp2' })}
                style={{ minWidth: styles.RESOURCE_LIST_NAME_WIDTH }}>
                {getResourceText().toUpperCase() + ` NAME`}
              </div>
            </div>
            <div className={styles.resourceListEndGroup}>
              <div
                className={sprinkles({ paddingX: 'sp1.5' })}
                style={{ width: styles.RESOURCE_LIST_VERSION_WIDTH }}>
                VERSION
              </div>
              <div
                className={sprinkles({ paddingX: 'sp2' })}
                style={{ width: styles.RESOURCE_LIST_EMAIL_WIDTH }}
              />

              {userCanConfigureResource ? (
                <div style={{ width: styles.RESOURCE_LIST_DOTS_MENU_WIDTH }} />
              ) : null}
            </div>
          </div>
        </div>
        {slicedList.map(viewResource)}
      </div>
    );
  };

  const renderPager = () => {
    if (resources.length === 0) return;

    const maxPageNumber = Math.max(Math.ceil(filteredResources.length / resourcesPerPage), 1);

    return (
      <div className={sprinkles({ display: 'block' })}>
        <div className={styles.pagerFooterContainer}>
          <div className={styles.pagerCount}>
            {filteredResources.length} {getResourceText({ plural: filteredResources.length !== 1 })}
          </div>
          <TablePager
            currentPage={currPage + 1}
            maxPageNumber={maxPageNumber}
            onNewPage={(newPage) => {
              const newPageNumber = parseInt(newPage);

              if (
                !newPageNumber ||
                newPageNumber < 1 ||
                newPageNumber > maxPageNumber ||
                currPage + 1 === newPageNumber
              ) {
                return;
              }
              setCurrPage(newPageNumber - 1);
            }}
          />
          <div />
        </div>
      </div>
    );
  };

  const showCreationError = () => {
    showErrorToast(
      `There was an error creating your ${getResourceText()}. Please try again or contact support if the error continues.`,
    );
  };

  const renderCreateResourceModal = () => {
    if (!currentUser.team) return;

    return (
      <TextFieldModal
        buttonName={`Create a ${getResourceText()}`}
        closeModal={() => setCreateResourceModalOpen(false)}
        keepOpenOnSubmit={true}
        loading={createResourceLoading}
        modalOpen={createResourceModalOpen}
        modalTitle={`Create a ${getResourceText()}.`}
        onSubmit={(name) => {
          if (isArchitectProduct) {
            dispatch(
              createCanvas(
                { postData: { name } },
                (data) => {
                  trackEvent(EVENTS.CREATED_CANVAS, {
                    canvas_id: data.canvas.id,
                    canvas_name: name,
                  });
                  setCreateResourceModalOpen(false);
                  history.push(`/blueprint/${data.canvas.id}/datasets`);
                },
                () => showCreationError(),
              ),
            );
          } else if (isExploreProduct) {
            dispatch(
              createDashboardTemplate(
                { id: currentUser.team?.id, postData: { name } },
                (data) => {
                  trackEvent(EVENTS.CREATED_DASHBOARD, {
                    dashboard_template_id: data.new_dashboard_template.id,
                    dashboard_name: name,
                  });
                  setCreateResourceModalOpen(false);
                  history.push(`/dashboard/${data.new_dashboard_template.id}#edit`);
                },
                () => showCreationError(),
              ),
            );
          } else {
            dispatch(
              createReportBuilder(
                { postData: { name } },
                (data) => {
                  setCreateResourceModalOpen(false);
                  history.push(`/report-builder/${data.report_builder.id}/datasets`);
                },
                () => showCreationError(),
              ),
            );
          }
        }}
        textFieldPlaceholder={`Enter ${getResourceText({ plural: true })} name`}
      />
    );
  };

  const primaryActionProps = userCanCreateResource
    ? {
        disabled: createResourceDisabled,
        text: `Create ${getResourceText({ capitalized: true })}`,
        tooltipText: createResourceDisabled
          ? 'Upgrade your plan to create more than 3 dashboards.'
          : undefined,
        onClick: () => setCreateResourceModalOpen(true),
      }
    : undefined;

  return (
    <div className={styles.root}>
      <PageHeader
        className={sprinkles({ backgroundColor: 'white' })}
        currentView={viewType}
        pageTitle={getPageTitle()}
        primaryActionProps={primaryActionProps}
        searchBarPlaceholderText="Search by name"
        searchBarSubmit={(searchStringSupplied) => {
          setSearchString(searchStringSupplied);
          setCurrPage(0);
        }}
        toggleView={
          pageType === ResourcePageType.REPORT_BUILDER
            ? undefined
            : (view: ViewType) => {
                setCurrPage(0);
                setViewType(view);
              }
        }
      />
      <div className={styles.scroll}>
        <div className={styles.content}>
          {renderList()}
          {renderPager()}
          {renderCreateResourceModal()}
        </div>
      </div>
    </div>
  );
};
