import { createAction } from '@reduxjs/toolkit';

import { ACTION } from './types';

export const clearDashboardLayoutReducer = createAction(ACTION.CLEAR_DASHBOARD_LAYOUT_REDUCER);

export const clearShareLink = createAction(ACTION.CLEAR_SHARE_LINK);

export const setSyncCategoryColors = createAction<boolean>(ACTION.SET_SYNC_CATEGORY_COLORS);

export const clearDownloads = createAction<string | undefined>(ACTION.CLEAR_DOWNLOADS);
