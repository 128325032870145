import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import { Props as TooltipProps } from 'components/ds/Tooltip';
import { EditableText } from 'components/EditableText';
import { ViewTabMenu } from 'pages/ReportBuilder/ReportView/Tabs/ViewTabMenu';

import { CustomerReportView } from 'actions/customerReportActions';
import {
  createView,
  deleteView,
  getCanDeleteView,
  setCurrentView,
  updateViewName,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';

import * as styles from 'pages/ReportBuilder/ReportView/Tabs/ViewTabItem.css';

interface Props {
  view: CustomerReportView;
  savedViews: CustomerReportView[]; // Views that have been saved to the DB
}

export const ViewTabItem: FC<Props> = ({ view, savedViews }) => {
  const textRef = useRef<HTMLSpanElement>(null);
  const [isRenaming, setIsRenaming] = useState(false);

  const dispatch = useDispatch();
  const { currentViewId, canDelete } = useSelector(
    (state: ReportBuilderReduxState) => ({
      currentViewId: state.reportEditing.currentView,
      canDelete: getCanDeleteView(state.reportEditing),
    }),
    shallowEqual,
  );

  const isSelected = currentViewId === view.id;
  const isSaved = useMemo(() => {
    const savedView = savedViews.find((savedView) => savedView.id === view.id);
    return savedView && shallowEqual(view, savedView);
  }, [savedViews, view]);

  useEffect(() => {
    // Auto-scroll to the currently selected Tab
    if (isSelected) textRef.current?.scrollIntoView({ behavior: 'auto' });
  }, [isSelected]);

  const tooltipProps = isSaved
    ? undefined
    : ({
        text: 'Unsaved View',
        side: 'bottom',
      } as TooltipProps);

  return (
    <div className={cx(styles.tab, isSelected && styles.tabSelected)}>
      <EditableText
        isRenaming={isRenaming}
        onRename={(name) => dispatch(updateViewName({ viewId: view.id, name: name }))}
        onRenamingChange={setIsRenaming}
        onSingleClick={() => dispatch(setCurrentView(view.id))}
        placeholder="Untitled View"
        tooltipProps={tooltipProps}
        triggerChildren={isSaved ? undefined : ' •'}
        value={view.name}>
        <ViewTabMenu
          canDelete={canDelete}
          onDelete={() => dispatch(deleteView(view.id))}
          onDuplicate={() => dispatch(createView(view))}
          onRename={() => setIsRenaming(true)}
        />
      </EditableText>
    </div>
  );
};
