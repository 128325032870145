import { FC, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Settings } from 'luxon';
import cx from 'classnames';

import { ReportSaveStatus } from 'pages/ReportBuilder/ReportSaveStatus';
import { ReportName } from 'pages/ReportBuilder/ReportName';
import { ModalViews } from 'pages/ReportBuilder/ModalViews';
import { EmbedText } from '../EmbedText';
import { Button, Icon, sprinkles } from 'components/ds';
import { StarStatus } from '../StarStatus';
import { ExportButton } from './ExportButton';
import { SaveButton } from './SaveButton';
import { ViewTabs } from 'pages/ReportBuilder/ReportView/Tabs/ViewTabs';
import { ReportBuilderPoller } from './ReportBuilderPoller';

import {
  CustomerReport,
  CustomerReportConfig,
  updateCustomerReportName,
} from 'actions/customerReportActions';
import {
  clearSelectedReport,
  openDataModal,
  openModalType,
  ReportModal,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { getReportName } from 'utils/customerReportUtils';
import { isEqual } from 'utils/standard';

import * as styles from './index.css';

type Props = {
  currentConfig: CustomerReportConfig;
  report: CustomerReport;

  // Used for previewing in app. No saves will be made
  isPreview?: boolean;
};

export const ReportBuilderReportView: FC<Props> = ({ currentConfig, report, isPreview }) => {
  const dispatch = useDispatch();
  Settings.defaultZone = 'UTC';

  const { customerToken, selectedTab, embedView } = useSelector(
    (state: ReportBuilderReduxState) => ({
      customerToken: state.embeddedReportBuilder.requestInfo.customerToken,
      selectedTab: state.embeddedReportBuilder.selectedTab,
      embedView: state.embeddedReportBuilder.embedView,
    }),
    shallowEqual,
  );

  useEffect(() => {
    return () => {
      dispatch(clearSelectedReport());
    };
  }, [dispatch]);

  const hasUnsavedChanges = useMemo(
    () => !isEqual(currentConfig, report.config),
    [currentConfig, report],
  );

  // If there are unsaved changes, prompt the user to confirm before leaving
  const handleBack = () =>
    !isPreview &&
    (hasUnsavedChanges
      ? dispatch(openModalType(ReportModal.CONFIRM_CLOSE_REPORT))
      : dispatch(clearSelectedReport()));

  return (
    <div
      className={cx(
        styles.container,
        sprinkles({ height: embedView === 'iframe' ? 'fillViewport' : 'fill' }),
      )}>
      <div className={styles.headerContainer}>
        <div className={styles.backContainer}>
          <div className={sprinkles({ cursor: 'pointer' })} onClick={handleBack}>
            <Icon className={styles.backIcon} name="arrow-left" />
            <EmbedText color="brandPrimary" heading="h3">
              {selectedTab}
            </EmbedText>
          </div>
        </div>
        <div className={styles.header}>
          <div className={styles.headerName}>
            <StarStatus isStarred={report.is_starred} reportId={report.id} />
            <ReportName
              onSubmit={(value) => {
                dispatch(
                  updateCustomerReportName({
                    customerToken,
                    postData: { report_id: report.id, name: value },
                  }),
                );
              }}
              value={getReportName(report)}
            />
            <ReportSaveStatus isSaved={!hasUnsavedChanges} />
          </div>
          <div className={styles.headerButtons}>
            <Button icon="pencil" onClick={() => dispatch(openDataModal())} type="secondary">
              Edit Data
            </Button>
            <SaveButton
              currentConfig={currentConfig}
              hasUnsavedChanges={hasUnsavedChanges}
              isPreview={isPreview}
              report={report}
            />
            <ExportButton exportDisabled={!currentConfig.dataInfo} />
          </div>
        </div>
      </div>
      <ViewTabs
        dataInfo={currentConfig.dataInfo}
        savedViews={report.config.views || []}
        views={currentConfig.views || []}
      />
      <ModalViews reportName={report.name} />
      <ReportBuilderPoller />
    </div>
  );
};
