import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import * as styles from './styles.css';

import { IconButton, sprinkles, Icon } from 'components/ds';
import { NumberFormatConfig } from 'components/ColumnFormatConfigs/NumberFormatConfig';
import { BooleanFormatConfig } from 'components/ColumnFormatConfigs/BooleanFormatConfig';
import { DateFormatConfig } from 'components/ColumnFormatConfigs/DateFormatConfig';
import { StringFormatConfig } from 'components/ColumnFormatConfigs/StringFormatConfig';
import { DatasetColumnFormatItem } from 'components/resource/DatasetColumnFormat';
import { DatasetEditorNonIdealState } from './DatasetEditorNonIdealState';

import { BOOLEAN, DATE_TYPES, NUMBER_TYPES, STRING } from 'constants/dataConstants';
import {
  BooleanDisplayOptions,
  DateDisplayOptions,
  DisplayOptions,
  NumberDisplayOptions,
  StringDisplayOptions,
  OPERATION_TYPES,
} from 'constants/types';
import { ReduxState } from 'reducers/rootReducer';
import { DATASET_EDITOR_MODE } from '../editDashboardPage';
import { Dataset, updateDatasetDrilldownColConfig } from 'actions/datasetActions';

type Props = {
  datasetEditorMode: DATASET_EDITOR_MODE;
  dataset: Dataset;
  toggleIsExpanded: () => void;
};

export const DrilldownDatasetColumnsFormatConfig: FC<Props> = ({
  datasetEditorMode,
  dataset,
  toggleIsExpanded,
}) => {
  const dispatch = useDispatch();

  const selectedDrilldownColumn = useSelector(
    (state: ReduxState) => state.cssLinks.selectedDrilldownColumn,
  );

  const isCollapsed = datasetEditorMode !== DATASET_EDITOR_MODE.EXPANDED;
  const showFormattingConfig = !isCollapsed && selectedDrilldownColumn;

  const renderClickColumnToEditFormatting = () => (
    <>
      <Icon name="hand-pointer" />
      <div className={sprinkles({ body: 'b3', textAlign: 'center' })}>
        Click on any column to edit formatting
      </div>
    </>
  );

  const renderClickToExpand = () => (
    <>
      <IconButton name="chevron-up" onClick={toggleIsExpanded} type="secondary" />
      <div
        className={sprinkles({
          body: 'b3',
          textAlign: 'center',
          color: 'active',
          cursor: 'pointer',
        })}
        onClick={toggleIsExpanded}>
        Expand to access formatting tools
      </div>
    </>
  );

  const drilldownConfigs = dataset.drilldownColumnConfigs;
  const selectedDrilldownColumnConfig =
    drilldownConfigs && selectedDrilldownColumn
      ? drilldownConfigs[selectedDrilldownColumn.name]
      : undefined;

  const displayFormatting = selectedDrilldownColumnConfig?.displayFormatting;

  const updateFormatting = (updates: DisplayOptions) => {
    if (!selectedDrilldownColumn || !drilldownConfigs) return;

    const newFormatting = displayFormatting ? { ...displayFormatting, ...updates } : updates;
    const colName = selectedDrilldownColumn.name;

    dispatch(
      updateDatasetDrilldownColConfig({
        datasetId: dataset.id,
        colName,
        displayFormatting: newFormatting,
      }),
    );
  };

  const renderColFormattingType = () => {
    if (!selectedDrilldownColumn || !drilldownConfigs) return null;

    if (selectedDrilldownColumn.type === BOOLEAN) {
      return (
        <BooleanFormatConfig
          column={selectedDrilldownColumn}
          displayOptions={displayFormatting as BooleanDisplayOptions}
          updateBooleanOptions={updateFormatting}
        />
      );
    }
    if (selectedDrilldownColumn.type === STRING) {
      if (!dataset._schema) return null;
      return (
        <StringFormatConfig
          column={selectedDrilldownColumn}
          dataPanelData={dataset._rows ?? []}
          displayOptions={displayFormatting as StringDisplayOptions}
          originalSchema={dataset._schema}
          updateStringOptions={updateFormatting}
        />
      );
    }
    if (DATE_TYPES.has(selectedDrilldownColumn.type)) {
      return (
        <DateFormatConfig
          column={selectedDrilldownColumn}
          displayOptions={displayFormatting as DateDisplayOptions}
          operationType={OPERATION_TYPES.VISUALIZE_TABLE}
          updateDateOptions={updateFormatting}
        />
      );
    }
    if (NUMBER_TYPES.has(selectedDrilldownColumn.type)) {
      return (
        <NumberFormatConfig
          column={selectedDrilldownColumn}
          displayOptions={displayFormatting as NumberDisplayOptions}
          operationType={OPERATION_TYPES.VISUALIZE_TABLE}
          updateNumberOptions={updateFormatting}
        />
      );
    }
    return null;
  };

  const noIncludedColumns =
    drilldownConfigs && !Object.values(drilldownConfigs)?.filter((col) => col.isIncluded).length;

  const formatConfigWidth = isCollapsed
    ? styles.COLLAPSED_FORMATTING_CONFIG_ROOT_WIDTH
    : styles.FORMATTING_CONFIG_ROOT_WIDTH;
  return (
    <div
      className={styles.formattingConfigRoot}
      style={{
        width: formatConfigWidth,
        minWidth: formatConfigWidth,
      }}>
      <div className={sprinkles({ heading: 'h4' })}>Format</div>
      <div
        className={cx(
          styles.formattingTabWrapperClass,
          sprinkles({ justifyContent: showFormattingConfig ? 'flex-start' : 'center' }),
        )}>
        {isCollapsed ? (
          renderClickToExpand()
        ) : noIncludedColumns ? (
          <DatasetEditorNonIdealState
            description="Select some columns to get started."
            title="No visible columns"
          />
        ) : selectedDrilldownColumn ? (
          <DatasetColumnFormatItem
            hideDescriptionConfig
            startOpen
            className={styles.formattingTabClass}
            colName={selectedDrilldownColumnConfig?.displayName || selectedDrilldownColumn.name}
            updateColumnInfo={(updates) => {
              if (!updates.name || !drilldownConfigs) return;

              dispatch(
                updateDatasetDrilldownColConfig({
                  datasetId: dataset.id,
                  colName: selectedDrilldownColumn.name,
                  displayName: updates.name,
                }),
              );
            }}>
            {renderColFormattingType()}
          </DatasetColumnFormatItem>
        ) : (
          renderClickColumnToEditFormatting()
        )}
      </div>
    </div>
  );
};
