import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ColumnHeader } from 'components/resource/ColumnHeader';
import { MetadataInputs } from 'components/resource/MetadataInputs';
import { QuerySection } from 'components/resource/QuerySection';
import {
  DatasetColumnSelection,
  DatasetColumnSelectionItem,
} from 'components/resource/DatasetColumnSelection';
import { Button, sprinkles, AlertModal } from 'components/ds';
import {
  DatasetColumnFormat,
  DatasetColumnFormatItem,
} from 'components/resource/DatasetColumnFormat';
import { ColumnFormatting } from './ColumnFormatting';

import { ReportBuilderColConfig, ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import {
  deleteReportBuilderDataset,
  updateReportBuilderColConfig,
  updateReportBuilderDataset,
} from 'reducers/reportBuilderEditReducer';
import { fetchReportBuilderDataset } from 'reducers/thunks/reportBuilderEditorThunks';

type Props = { dataset: ReportBuilderDataset; isNew: boolean };

export const DatasetConfig: FC<Props> = ({ dataset, isNew }) => {
  const dispatch = useDispatch();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [schemaIdToSelect, setSchemaIdToSelect] = useState<number>();
  const hasShownSaveModal = useRef(false);

  useEffect(() => {
    hasShownSaveModal.current = false;
    setIsDeleteModalOpen(false);
    setIsSaveModalOpen(false);
    setSchemaIdToSelect(undefined);
  }, [dataset.id]);

  const fetchDataset = (save: boolean) => {
    dispatch(fetchReportBuilderDataset({ datasetId: dataset.id, save }));
  };

  const updateConfig = (colName: string, configUpdates: Partial<ReportBuilderColConfig>) => {
    dispatch(
      updateReportBuilderColConfig({
        datasetId: dataset.id,
        colName,
        config: { ...dataset.columnConfigs[colName], ...configUpdates },
      }),
    );
  };

  const renderDeleteModal = () => {
    if (!isDeleteModalOpen) return null;

    // TODO: Get information if it actually affects users
    return (
      <AlertModal
        isOpen
        actionButtonProps={{ onClick: () => dispatch(deleteReportBuilderDataset(dataset.id)) }}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Are you sure you want to delete this dataset?">
        {`This might affect your users' created reports`}
      </AlertModal>
    );
  };

  const renderSaveModal = () => {
    // Show the save modal or select schema modal only once per dataset, so it's not too annoying
    // TODO: Get information if it actually affects users
    if (!isSaveModalOpen || hasShownSaveModal.current) return null;
    hasShownSaveModal.current = true;

    return (
      <AlertModal
        isOpen
        actionButtonProps={{ onClick: () => fetchDataset(true), text: 'Save' }}
        onClose={() => setIsSaveModalOpen(false)}
        title="Are you sure you want to save this query?">
        {`If your users have created reports using this dataset and you change the schema, or add/remove columns, their reports will not be able to load the correct data anymore.`}
      </AlertModal>
    );
  };

  const selectSchema = (schemaId: number) =>
    dispatch(updateReportBuilderDataset({ datasetId: dataset.id, schemaId }));

  const renderSelectSchemaModal = () => {
    // Show the save modal or select schema modal only once per dataset, so it's not too annoying
    if (!schemaIdToSelect || hasShownSaveModal.current) return null;
    hasShownSaveModal.current = true;

    return (
      <AlertModal
        isOpen
        actionButtonProps={{
          onClick: () => selectSchema(schemaIdToSelect),
          text: 'Save',
        }}
        onClose={() => setSchemaIdToSelect(undefined)}
        title="Are you sure you want to change the schema?">
        If your users have created reports using this dataset and you change the schema, or
        add/remove columns, their reports will not be able to load the correct data anymore.
      </AlertModal>
    );
  };

  return (
    <>
      <ColumnHeader title="Configuration" />
      <div className={sprinkles({ flex: 1, width: 'fill', overflowY: 'auto' })}>
        <MetadataInputs
          defaultIsOpen={isNew}
          handleNewValueSubmitted={(params) =>
            dispatch(updateReportBuilderDataset({ datasetId: dataset.id, ...params }))
          }
          initialDescription={dataset.description}
          initialName={dataset.name}
        />
        <QuerySection
          isReportBuilder
          dataset={dataset}
          getPreview={() => fetchDataset(false)}
          onSelectSchema={(schema) =>
            hasShownSaveModal.current ? selectSchema(schema.id) : setSchemaIdToSelect(schema.id)
          }
          saveQuery={() =>
            hasShownSaveModal.current ? fetchDataset(true) : setIsSaveModalOpen(true)
          }
        />
        {dataset.schema?.length ? (
          <>
            <DatasetColumnSelection
              isNewDataset={isNew}
              sectionText="Select which columns will be visible to your end users. Default filters will be filters exposed to the customer, regardless of if the columns are visible to them or not."
              toggleName="Default filter">
              {dataset.schema.map((col) => {
                const columnConfig = dataset.columnConfigs[col.name];
                if (!columnConfig) return null;

                return (
                  <DatasetColumnSelectionItem
                    alwaysShowToggle
                    column={col}
                    isVisible={columnConfig.isVisible}
                    key={col.name}
                    onToggle={(newVal) => updateConfig(col.name, { showDefaultFilter: newVal })}
                    onVisibilityToggle={(newVal) => updateConfig(col.name, { isVisible: newVal })}
                    toggleOn={!!columnConfig.showDefaultFilter}
                  />
                );
              })}
            </DatasetColumnSelection>
            <DatasetColumnFormat sectionText="This will impact how data is shown when used in a report">
              {dataset.schema.map((col) => {
                const columnConfig = dataset.columnConfigs[col.name];
                if (!columnConfig?.isVisible) return null;

                return (
                  <DatasetColumnFormatItem
                    colDescription={columnConfig.description}
                    colName={columnConfig.name}
                    key={col.name}
                    updateColumnInfo={(updates) => dispatch(updateConfig(col.name, updates))}>
                    <ColumnFormatting
                      column={col}
                      dataset={dataset}
                      displayFormatting={columnConfig.displayFormatting}
                    />
                  </DatasetColumnFormatItem>
                );
              })}
            </DatasetColumnFormat>
          </>
        ) : null}
        <div
          className={sprinkles({
            borderTop: 1,
            borderBottom: 1,
            borderColor: 'gray3',
            padding: 'sp2',
          })}>
          <Button
            fillWidth
            icon="trash"
            onClick={() => setIsDeleteModalOpen(true)}
            type="destructive">
            Delete
          </Button>
        </div>
      </div>
      {renderDeleteModal()}
      {renderSaveModal()}
      {renderSelectSchemaModal()}
    </>
  );
};
