import { useEffect, useState, useMemo } from 'react';
import { cloneDeep } from 'utils/standard';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Intent } from '@blueprintjs/core';
import { Prompt } from 'react-router-dom';
import { useScript } from 'usehooks-ts';

import { SIDE_PANE_HEADER_HEIGHT } from 'components/SidePane';
import CustomerSelector from 'components/CustomerSelector';
import DashboardSelector from 'components/DashboardSelector';
import CustomizeStyleConfigPane from './CustomizeStyleConfigPane';
import { sprinkles } from 'components/ds';

import { ReduxState } from 'reducers/rootReducer';
import { getSelectedCustomer } from 'reducers/customersReducer';
import {
  updateGlobalStyles,
  saveGlobalStyles,
  resetGlobalStyles,
  fetchGoogleFonts,
} from 'actions/styleConfigActions';
import { fetchUserTeam } from 'actions/teamActions';
import { fetchDashboardTemplateList } from 'actions/dashboardTemplateActions';
import { loadFonts } from 'globalStyles/helpers';
import { showCustomToast } from 'shared/sharedToasts';
import { doesUserHavePermission } from 'utils/permissionUtils';
import { PERMISSIONED_ACTIONS, PERMISSIONED_ENTITIES } from 'constants/roleConstants';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'explo-dashboard': unknown;
    }
  }
}

const topBarClass = sprinkles({
  flexItems: 'alignCenter',
  paddingX: 'sp2',
  borderBottom: 1,
  borderColor: 'gray7',
});

const EXAMPLE_CUSTOMER_TOKEN = '8898b421f165453944df5e1bb7a792ee5f6c09ce1251a6afbfd10e608e4a4fe5';
const EXAMPLE_EMBED_ID = 'aovARZVAlV';

export function CustomizeStylesPage() {
  const dispatch = useDispatch();
  const [allChangesSaved, setAllChangesSaved] = useState<boolean>(true);

  const {
    globalStyleConfig,
    dashboards,
    googleFonts,
    team,
    selectedUserGroup,
    selectedDashboardId,
    currentUser,
  } = useSelector((state: ReduxState) => {
    const selectedDashboardId = state.styleConfig.selectedDashboardId;
    return {
      dashboards: state.dashboard.dashboardTemplateList,
      globalStyleConfig: state.styleConfig.editing ?? state.dashboardStyles.globalStyleConfig,
      googleFonts: state.styleConfig.googleFonts,
      team: state.teamData.data,
      onboardingSteps: state.onboarding.onboardingSteps,
      selectedUserGroup: selectedDashboardId ? getSelectedCustomer(state.customers) : undefined,
      selectedDashboardId,
      currentUser: state.currentUser,
    };
  }, shallowEqual);

  useScript('https://embed.explo.co/bundle.js', { removeOnUnmount: true });

  useEffect(() => {
    if (!team) dispatch(fetchUserTeam());
  }, [dispatch, team]);

  useEffect(() => dispatch(fetchGoogleFonts()), [dispatch]);

  useEffect(() => {
    if (!dashboards) dispatch(fetchDashboardTemplateList({ id: currentUser.team?.id }));
  }, [dispatch, dashboards, currentUser.team?.id]);

  useEffect(() => {
    if (team) loadFonts(globalStyleConfig.text, team.id, team.payment_plan, true);
  }, [globalStyleConfig.text, team]);

  useEffect(() => {
    const alertUser = (event: BeforeUnloadEvent) => {
      if (allChangesSaved) return;
      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, [allChangesSaved]);

  const stylesToApply = cloneDeep(globalStyleConfig);
  stylesToApply.base.numColumns = 12;

  const selectedDashboard = selectedDashboardId
    ? (dashboards ?? []).find((dashboard) => dashboard.id === selectedDashboardId)
    : undefined;

  const publishedDashboards = useMemo(() => {
    if (!dashboards) return [];

    return dashboards.filter((dashboard) =>
      dashboard.latest_versions.some((version) => !version.is_draft),
    );
  }, [dashboards]);

  const renderDashboardSelectorBar = () => {
    if (
      !doesUserHavePermission(
        currentUser.permissions[PERMISSIONED_ENTITIES.DASHBOARD],
        PERMISSIONED_ACTIONS.READ,
      )
    )
      return null;

    if (publishedDashboards.length === 0)
      return (
        <span>To preview a specific dashboard with custom styles, first publish a dashboard.</span>
      );

    return (
      <>
        <div className={sprinkles({ marginRight: 'sp1' })}>Viewing</div>
        <DashboardSelector dashboards={publishedDashboards} />
        <div className={sprinkles({ marginX: 'sp1' })}>as</div>
        <CustomerSelector
          disabled={!selectedDashboard}
          overwriteSelectedUserGroupToNull={!selectedDashboard}
        />
      </>
    );
  };

  const dashUserGroupToken =
    selectedDashboard?.embed_id && selectedUserGroup?.token
      ? `${selectedDashboard.embed_id}:${selectedUserGroup.token}`
      : `${EXAMPLE_EMBED_ID}:${EXAMPLE_CUSTOMER_TOKEN}`;

  return (
    <div
      className={sprinkles({
        flexItems: 'alignCenter',
        widthConstraints: 'minOnly',
        backgroundColor: 'white',
      })}>
      <Prompt
        message="You have unsaved changes to the styles. If you leave this page your changes will be lost."
        when={!allChangesSaved}
      />
      <CustomizeStyleConfigPane
        googleFonts={googleFonts}
        resetConfig={() => dispatch(resetGlobalStyles())}
        saveConfig={() =>
          dispatch(
            saveGlobalStyles({ postData: { config_v2: globalStyleConfig } }, () => {
              showCustomToast('Styles saved successfully', {
                timeoutInSeconds: 3,
                icon: 'tick',
                intent: Intent.SUCCESS,
              });
              setAllChangesSaved(true);
            }),
          )
        }
        styleConfig={globalStyleConfig}
        updateConfig={(newConfig) => {
          dispatch(updateGlobalStyles({ newConfig }));
          setAllChangesSaved(false);
        }}
      />
      <div
        className={sprinkles({ flex: 1, height: 'fill', overflowY: 'scroll' })}
        key={JSON.stringify(globalStyleConfig)}>
        <div className={topBarClass} style={{ height: SIDE_PANE_HEADER_HEIGHT }}>
          {renderDashboardSelectorBar()}
        </div>
        <explo-dashboard
          custom-styles={JSON.stringify(stylesToApply)}
          dash-customer-token={dashUserGroupToken}
          environment="development"
          isProduction={false}
          updateUrlParams={true}
        />
      </div>
    </div>
  );
}
