import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { IconName } from '@blueprintjs/core';

import Button from 'shared/Button';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    '&.bp3-button,.bp3-icon': {
      color: theme.palette.ds.grey800,
    },
    '&.bp3-button': {
      margin: 0,
      borderRadius: 0,
      backgroundColor: theme.palette.ds.white,
      border: `1px solid ${theme.palette.ds.grey400}`,
      '&:first-child': {
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
      },
      '&:last-child': {
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
      },
      '&.bp3-button:hover': {
        backgroundColor: theme.palette.ds.hovered.white,
        color: theme.palette.ds.hovered.grey800,
        '& .bp3-icon': {
          color: theme.palette.ds.hovered.grey800,
        },
      },
      '&.bp3-button:active': {
        backgroundColor: theme.palette.ds.pressed.white,
        color: theme.palette.ds.pressed.grey800,
        '& .bp3-icon': {
          color: theme.palette.ds.pressed.grey800,
        },
      },
    },
  },
  active: {
    '&.bp3-button,.bp3-icon': {
      color: theme.palette.ds.blue,
    },
    '&.bp3-button': {
      '&.bp3-active': {
        backgroundColor: theme.palette.ds.lightBlue,
        border: `1px solid ${theme.palette.ds.blue}`,
        color: theme.palette.ds.blue,
      },
      '&.bp3-button:hover': {
        backgroundColor: theme.palette.ds.hovered.lightBlue,
        color: theme.palette.ds.hovered.blue,
        '& .bp3-icon': {
          color: theme.palette.ds.hovered.blue,
        },
      },
      '&.bp3-button:active': {
        backgroundColor: theme.palette.ds.pressed.lightBlue,
        color: theme.palette.ds.pressed.blue,
        '& .bp3-icon': {
          color: theme.palette.ds.pressed.blue,
        },
      },
    },
  },
}));

type Props = {
  /**
   * If true, the button will be indicated as selected.
   */
  active?: boolean;
  /**
   * Icon to be included in the button. If text is also present, the icon will appear
   * to the left of the text.
   */
  icon?: IconName | JSX.Element;
  /**
   * Callback function to be executed when the button is clicked.
   */
  onClick?: () => void;
  /**
   * A classname to pass to the container element.
   */
  className?: string;
  /**
   * Text to be included in the button.
   */
  text?: string;
};

export default function ToggleButton(props: Props) {
  const classes = useStyles();

  return (
    <Button
      {...props}
      className={cx(classes.button, { [classes.active]: props.active }, props.className)}
    />
  );
}
