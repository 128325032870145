import { PureComponent } from 'react';
import { orderBy } from 'utils/standard';
import Highcharts from 'highcharts';
import { format } from 'utils/localizationUtils';
import ReactDOMServer from 'react-dom/server';

import NeedsConfigurationPanel from 'pages/dashboardPage/DashboardDatasetView/needsConfigurationPanel';
import { DrilldownChart } from './shared/drilldownChart';
import BaseTooltip from './tooltips/BaseTooltip';

import {
  getColorColNames,
  formatLegend,
  formatLabel,
  getColorPalette,
  getLabelStyle,
  isTwoDimVizInstructionsReadyToDisplay,
  areRequiredVariablesSetTwoDimViz,
  formatValue,
} from './utils';
import { formatTwoDimensionalData } from 'dataFormatters/twoDimensionalDataFormatter';
import {
  ColorCategoryTracker,
  V2TwoDimensionChartInstructions,
  VisualizeOperationGeneralFormatOptions,
} from 'constants/types';
import { BOOLEAN, DATE_TYPES, V2_NUMBER_FORMATS } from 'constants/dataConstants';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { DatasetSchema } from 'types/datasets';
import { getColDisplayText } from 'pages/dashboardPage/DataPanelConfigV2/DataConfigTab/vizConfigs/utils';
import { GlobalStyleConfig } from 'globalStyles/types';
import { getTimezoneAwareUnix } from 'utils/timezoneUtils';
import { OpenDrilldownModalType } from 'types/dashboardTypes';
import {
  ChartShapeBorderDefaultColor,
  chartShapeBorderDefaultWidth,
} from 'constants/dashboardConstants';
import { ChartMenuInfo } from 'reducers/dashboardLayoutReducer';

type Props = {
  backgroundColor: string;
  loading?: boolean;
  previewData: Record<string, string | number>[];
  instructions?: V2TwoDimensionChartInstructions;
  dataPanelTemplateId: string;
  variables: DashboardVariableMap;
  schema: DatasetSchema;
  donut?: boolean;
  globalStyleConfig: GlobalStyleConfig;
  colorCategoryTracker: ColorCategoryTracker;
  openDrilldownModal?: OpenDrilldownModalType;
  generalOptions?: VisualizeOperationGeneralFormatOptions;
  setChartMenu: (info: ChartMenuInfo | null) => void;
};

type SeriesOptions = Highcharts.SeriesPieOptions & {
  data: { name: string | number; y: number }[];
};

type State = {};

class PieChart extends PureComponent<Props, State> {
  excludedCategories: (string | number)[] | undefined;

  constructor(props: Props) {
    super(props);
    this.excludedCategories = undefined;
  }

  render() {
    const { generalOptions, instructions, loading, variables, setChartMenu } = this.props;
    const requiredVarNotsSet = !areRequiredVariablesSetTwoDimViz(variables, instructions);
    const instructionsReadyToDisplay = isTwoDimVizInstructionsReadyToDisplay(instructions);

    if (loading || !instructionsReadyToDisplay || requiredVarNotsSet) {
      return (
        <NeedsConfigurationPanel
          fullHeight
          instructionsNeedConfiguration={!instructionsReadyToDisplay}
          loading={loading}
          requiredVarsNotSet={requiredVarNotsSet}
        />
      );
    }

    return (
      <DrilldownChart
        chartOptions={this._spec()}
        closeChartMenu={() => setChartMenu(null)}
        customMenuOptions={
          generalOptions?.customMenu?.enabled ? generalOptions?.customMenu?.menuOptions : undefined
        }
        dataPanelTemplateId={this.props.dataPanelTemplateId}
        excludedCategories={this.excludedCategories}
        instructions={instructions}
        openDrilldownModal={this.props.openDrilldownModal}
        underlyingDataEnabled={this.getUnderlyingDrilldownEnabled()}
      />
    );
  }

  _spec = (): Highcharts.Options | undefined => {
    const {
      generalOptions,
      previewData,
      schema,
      instructions,
      backgroundColor,
      globalStyleConfig,
      setChartMenu,
      dataPanelTemplateId,
    } = this.props;
    if (schema?.length === 0 || !previewData) return;

    // this is a short term fix en lieu of this bug being fixed by vega:
    // Ref: TU/447fn2df
    this.processDatesData();
    const { decimalPlaces } = this.getValueFormat();
    const pieChart = instructions?.chartSpecificFormat?.pieChart;

    const data = this.transformData();
    const underlyingDrilldownEnabled = this.getUnderlyingDrilldownEnabled();

    const hasClickEvents = underlyingDrilldownEnabled || !!generalOptions?.customMenu?.enabled;

    return {
      chart: {
        type: 'pie',
        backgroundColor,
      },
      //@ts-ignore
      series: data,
      title: { text: undefined },
      colors: getColorPalette(globalStyleConfig, instructions?.colorFormat),
      plotOptions: {
        series: {
          animation: false,
          borderColor:
            instructions?.chartSpecificFormat?.pieChart?.borderColor ??
            ChartShapeBorderDefaultColor,
          borderWidth:
            instructions?.chartSpecificFormat?.pieChart?.borderWidth ??
            chartShapeBorderDefaultWidth,
          cursor: hasClickEvents ? 'pointer' : undefined,
          point: {
            events: {
              click: function (e) {
                if (!hasClickEvents) return;

                setChartMenu({
                  chartId: dataPanelTemplateId,
                  chartX: e.chartX,
                  chartY: e.chartY,
                  category: e.point.name,
                });
              },
            },
          },
        },
        pie: {
          allowPointSelect: true,
          innerSize: this.getInnerSize(),
          cursor: 'pointer',
          showInLegend: true,
          dataLabels: {
            enabled: !pieChart?.hideChartValues || pieChart?.useColorForLabel,
            formatter: function () {
              let label = '';
              if (pieChart?.useColorForLabel) {
                label = formatPieLabel(this.point.name, instructions);
              }
              if (!pieChart?.hideChartValues) {
                const decimalPoints = pieChart?.pctDecimalPlaces ?? 1;
                const valueFormat =
                  instructions?.chartSpecificFormat?.pieChart?.valuesFormat?.id ||
                  V2_NUMBER_FORMATS.PERCENT.id;
                let value;
                if (V2_NUMBER_FORMATS.PERCENT.id === valueFormat) {
                  value = `${format(`.${decimalPoints}f`)(this.point.percentage || 0)}%`;
                } else {
                  value = formatValue({
                    value: this.point.options.y || 0,
                    decimalPlaces: decimalPoints,
                    formatId:
                      instructions?.chartSpecificFormat?.pieChart?.valuesFormat?.id ||
                      V2_NUMBER_FORMATS.PERCENT.id,
                    hasCommas: true,
                  });
                }
                return label ? `${label} (${value})` : value;
              }
              return label;
            },
            style: getLabelStyle(globalStyleConfig, 'primary'),
          },
        },
      },
      legend: {
        ...formatLegend(globalStyleConfig, instructions?.legendFormat),
        labelFormatter: function () {
          return formatPieLabel(this.name, instructions);
        },
      },
      tooltip: {
        formatter: function () {
          return ReactDOMServer.renderToStaticMarkup(
            <BaseTooltip
              decimalPlaces={decimalPlaces}
              globalStyleConfig={globalStyleConfig}
              header={formatPieLabel(this.point.name, instructions)}
              points={[
                {
                  color: String(this.color),
                  name: this.series.name,
                  value: this.point.y,
                },
              ]}
              valueFormat={
                instructions?.yAxisFormats?.[0]?.numberFormat?.id || V2_NUMBER_FORMATS.NUMBER.id
              }
            />,
          );
        },
        padding: 0,
        borderWidth: 0,
        borderRadius: 0,
        backgroundColor: '#FFFFFF00',
        shadow: false,
        useHTML: true,
        outside: true,
      },
    };
  };

  getUnderlyingDrilldownEnabled = () => {
    const { generalOptions, openDrilldownModal } = this.props;
    return !!(generalOptions?.enableRawDataDrilldown && openDrilldownModal);
  };

  getInnerSize = () => {
    if (this.props.donut) return '50%';
  };

  processDatesData = () => {
    const { instructions, previewData, schema } = this.props;

    if (
      !previewData ||
      !DATE_TYPES.has(instructions?.categoryColumn?.column.type || '') ||
      !schema ||
      schema.length === 0
    )
      return;

    const xAxisColName = schema[0].name;

    if (
      instructions?.categoryColumn?.bucket?.id &&
      instructions.categoryColumn.bucket.id.indexOf('DATE_PART') >= 0
    )
      return;

    previewData.forEach((row) => {
      if (!instructions?.categoryColumn?.column.type) return;
      row[xAxisColName] = getTimezoneAwareUnix(row[xAxisColName] as string);
    });
  };

  getValueFormat = () => {
    const { instructions } = this.props;

    return {
      decimalPlaces: instructions?.yAxisFormats?.[0]?.decimalPlaces ?? 2,
    };
  };

  transformData = () => {
    // This is for when there are multiple bars/lines selected
    const { instructions, schema } = this.props;

    if (
      !instructions?.aggColumns ||
      instructions.aggColumns.length === 0 ||
      !schema ||
      schema.length === 0
    )
      return [];

    let series;
    if (instructions.colorColumnOptions) series = this.transformColorData(schema);
    else series = this.transformAggColsData(schema);

    return this.truncateDataMaxCategories(series);
  };

  truncateDataMaxCategories = (series?: SeriesOptions[]) => {
    const { instructions } = this.props;
    if (!series || !series[0]?.data) return [];
    const sortedData = orderBy(series[0].data, 'y', 'desc');

    const maxCategories = instructions?.chartSpecificFormat?.pieChart?.maxCategories;
    if (maxCategories) {
      const topEntries = sortedData.slice(0, maxCategories);
      const otherEntries = sortedData.slice(maxCategories);
      if (otherEntries.length > 0) {
        this.excludedCategories = topEntries.map((info) => info.name);
        const total = otherEntries.reduce((total, entry) => total + entry.y, 0);
        topEntries.push({
          name: 'Other',
          y: total,
        });
      }

      series[0].data = topEntries;
    } else {
      series[0].data = sortedData;
    }

    return series;
  };

  transformAggColsData = (schema: DatasetSchema) => {
    const { previewData, instructions, colorCategoryTracker } = this.props;
    if (!instructions?.aggColumns) return;

    const xAxisColName = schema[0].name;
    const aggCol = instructions?.aggColumns[0];
    const aggColNames = [schema[1].name];
    const series: Record<string, SeriesOptions> = {};

    formatTwoDimensionalData(previewData, instructions).forEach((row) => {
      const color = colorCategoryTracker[xAxisColName]?.[String(row[xAxisColName])];

      aggColNames.forEach((colName) => {
        const entry = {
          name: row[xAxisColName],
          y: row[colName] as number,
          color,
        };
        if (series[colName]) {
          series[colName].data.push(entry);
        } else {
          series[colName] = {
            type: 'pie',
            name: aggCol.column.friendly_name || getColDisplayText(aggCol) || colName,
            data: [entry],
          };
        }
      });
    });

    return Object.values(series);
  };

  transformColorData = (schema: DatasetSchema): SeriesOptions[] | undefined => {
    const { previewData, instructions } = this.props;
    const { xAxisColName, aggColName } = getColorColNames(schema);

    if (!instructions?.aggColumns) return;
    const aggCol = instructions?.aggColumns[0];

    const seriesData: Record<string, { name: string | number; y: number }> = {};

    previewData.forEach((row) => {
      const category = row[xAxisColName];

      if (seriesData[category]) {
        seriesData[category].y += row[aggColName] as number;
      } else {
        seriesData[category] = {
          name: category,
          y: row[aggColName] as number,
        };
      }
    });

    return [
      {
        type: 'pie',
        name: aggCol.column.friendly_name || getColDisplayText(aggCol) || aggColName,
        data: Object.values(seriesData),
      },
    ];
  };
}

function formatPieLabel(
  name: string,
  instructions: V2TwoDimensionChartInstructions | undefined,
): string {
  if (instructions?.categoryColumn?.column.type === BOOLEAN) {
    const trueLabel = instructions?.chartSpecificFormat?.pieChart?.trueLabel || 'true';
    const falseLabel = instructions?.chartSpecificFormat?.pieChart?.falseLabel || 'false';
    name = String(name) === 'true' ? trueLabel : falseLabel;
  }
  return formatLabel(
    name,
    instructions?.categoryColumn?.column.type,
    instructions?.categoryColumn?.bucket?.id,
    instructions?.categoryColumn?.bucketSize,
    instructions?.xAxisFormat?.dateFormat,
    instructions?.xAxisFormat?.stringFormat,
    !!instructions?.chartSpecificFormat?.pieChart?.maxCategories,
  );
}

export { PieChart };
