export enum ACTION {
  // Dashboard
  FETCH_ALL_DATA_SOURCE_TABLES = 'FETCH_ALL_DATA_SOURCE_TABLES',
  SYNC_SOURCE_TABLES = 'SYNC_SOURCE_TABLES',
  FETCH_DATASET_PREVIEW = 'FETCH_DATASET_PREVIEW',
  FETCH_DASHBOARD_TEMPLATE = 'FETCH_DASHBOARD_TEMPLATE',
  FETCH_DASHBOARD_TEMPLATE_LIST = 'FETCH_DASHBOARD_TEMPLATE_LIST',
  CREATE_DASHBOARD_TEMPLATE = 'CREATE_DASHBOARD_TEMPLATE',
  CLONE_DASHBOARD_TEMPLATE = 'CLONE_DASHBOARD_TEMPLATE',
  UPDATE_DASHBOARD_TEMPLATE_PARAMS = 'UPDATE_DASHBOARD_TEMPLATE_PARAMS',
  FETCH_DATA_PANEL_TEMPLATE = 'FETCH_DATA_PANEL_TEMPLATE',
  FETCH_SECONDARY_DATA = 'FETCH_SECONDARY_DATA',
  UPDATE_DASHBOARD_TEMPLATE_LAYOUT = 'UPDATE_DASHBOARD_TEMPLATE_LAYOUT',
  UPDATE_DASHBOARD_PDF_LAYOUT = 'UPDATE_DASHBOARD_PDF_LAYOUT',
  UPDATE_DASHBOARD_EMAIL_LAYOUT = 'UPDATE_DASHBOARD_EMAIL_LAYOUT',
  UPDATE_DASHBOARD_MOBILE_LAYOUT = 'UPDATE_DASHBOARD_MOBILE_LAYOUT',
  UPDATE_DASHBOARD_EMAIL_TEXT = 'UPDATE_DASHBOARD_EMAIL_TEXT',
  FETCH_DATASET_ROW_COUNT = 'FETCH_DATASET_ROW_COUNT',
  CREATE_FILTER_CLAUSE = 'CREATE_FILTER_CLAUSE',
  CREATE_SORT_CLAUSE = 'CREATE_SORT_CLAUSE',
  DELETE_SORT_CLAUSE = 'DELETE_SORT_CLAUSE',
  SELECT_SORT_COLUMN = 'SELECT_SORT_COLUMN',
  SELECT_SORT_ORDER = 'SELECT_SORT_ORDER',
  DELETE_FILTER_CLAUSE = 'DELETE_FILTER_CLAUSE',
  CREATE_PIVOTED_ON_COL = 'CREATE_PIVOTED_ON_COL',
  CREATE_AGGREGATION = 'CREATE_AGGREGATION',
  DELETE_PIVOTED_ON_COL = 'DELETE_PIVOTED_ON_COL',
  UPDATE_PIVOTED_ON_COL = 'UPDATE_PIVOTED_ON_COL',
  DELETE_AGGREGATION = 'DELETE_AGGREGATION',
  UPDATE_AGGREGATION = 'UPDATE_AGGREGATION',
  SELECT_FILTER_COLUMN = 'SELECT_FILTER_COLUMN',
  FETCH_DATA_PANEL_ROW_COUNT = 'FETCH_DATA_PANEL_ROW_COUNT',
  SELECT_FILTER_OPERATOR = 'SELECT_FILTER_OPERATOR',
  UPDATE_FILTER_VALUE = 'UPDATE_FILTER_VALUE',
  UPDATE_FILTER_VALUE_SOURCE = 'UPDATE_FILTER_VALUE_SOURCE',
  UPDATE_FILTER_VALUE_VARIABLE = 'UPDATE_FILTER_VALUE_VARIABLE',
  FETCH_CUSTOMERS = 'FETCH_CUSTOMERS',
  CREATE_CUSTOMER = 'CREATE_CUSTOMER',
  DELETE_CUSTOMER = 'DELETE_CUSTOMER',
  EDIT_CUSTOMER = 'EDIT_CUSTOMER',
  UPDATE_SELECTED_CHART = 'UPDATE_SELECTED_CHART',
  UPDATE_VISUALIZE_OP = 'UPDATE_VISUALIZE_OP',
  DELETE_DASHBOARD_TEMPLATE = 'DELETE_DASHBOARD_TEMPLATE',
  RENAME_DATA_PANEL_TEMPLATE = 'RENAME_DATA_PANEL_TEMPLATE',
  CREATE_DASHBOARD_TEMPLATE_ELEMENT = 'CREATE_DASHBOARD_TEMPLATE_ELEMENT',
  DOWNLOAD_DATA_PANEL_CSV = 'DOWNLOAD_DATA_PANEL_CSV',
  RENAME_DASHBOARD_TEMPLATE = 'RENAME_DASHBOARD_TEMPLATE',
  ACTIVATE_DISABLED_DASHBOARD = 'ACTIVATE_DISABLED_DASHBOARD',
  UPDATE_GLOBAL_STYLES = 'UPDATE_GLOBAL_STYLES',
  SAVE_GLOBAL_STYLES = 'SAVE_GLOBAL_STYLES',
  RESET_GLOBAL_STYLES = 'RESET_GLOBAL_STYLES',
  FETCH_GOOGLE_FONTS = 'FETCH_GOOGLE_FONTS',
  EDIT_DATASET_NAME = 'EDIT_DATASET_NAME',
  DELETE_DATASET = 'DELETE_DATASET',
  FETCH_DASHBOARD_DATASET_PREVIEW = 'FETCH_DASHBOARD_DATASET_PREVIEW',
  FETCH_DASHBOARD_DATASET_ROW_COUNT = 'FETCH_DASHBOARD_DATASET_ROW_COUNT',
  SAVE_DATASET_QUERY = 'SAVE_DATASET_QUERY',
  SAVE_DRAFT_DATASET_QUERY = 'SAVE_DRAFT_DATASET_QUERY',
  UPDATE_ELEMENT_CONFIG = 'UPDATE_ELEMENT_CONFIG',
  UPDATE_ELEMENT_LOCATION = 'UPDATE_ELEMENT_LOCATION',
  UPDATE_DASHBOARD_HEADER_ELEMENT_ORDER = 'UPDATE_DASHBOARD_HEADER_ELEMENT_ORDER',
  TOGGLE_FILTER_LINK = 'TOGGLE_FILTER_LINK',
  DELETE_DASHBOARD_ELEMENT = 'DELETE_DASHBOARD_ELEMENT',
  SAVE_DASHBOARD_ELEMENT_UPDATES = 'SAVE_DASHBOARD_ELEMENT_UPDATES',
  EMBED_FETCH_DASHBOARD = 'EMBED_FETCH_DASHBOARD',
  EMBED_FETCH_DATA_PANEL_TEMPLATE = 'EMBED_FETCH_DATA_PANEL_TEMPLATE',
  EMBED_FETCH_DATA_PANEL_ROW_COUNT = 'EMBED_FETCH_DATA_PANEL_ROW_COUNT',
  EMBED_DOWNLOAD_DATA_PANEL_SPREADSHEET = 'EMBED_DOWNLOAD_DATA_PANEL_SPREADSHEET',
  EMBED_DOWNLOAD_DATA_PANEL_PDF = 'EMBED_DOWNLOAD_DATA_PANEL_PDF',
  EMBED_FETCH_SECONDARY_DATA = 'EMBED_FETCH_SECONDARY_DATA',
  EMBED_FETCH_SHARE_DATA = 'EMBED_FETCH_SHARE_DATA',
  EMBED_FETCH_DASHBOARD_DATASET_PREVIEW = 'EMBED_FETCH_DASHBOARD_DATASET_PREVIEW',
  EMBED_SET_DP_LOADING = 'EMBED_SET_DP_LOADING',
  FETCH_SHARE_ID = 'FETCH_SHARE_ID',
  EMBED_FETCH_SHARE_ID = 'EMBED_FETCH_SHARE_ID',
  FETCH_PDF_EXPORT_URL = 'FETCH_PDF_EXPORT_URL',
  EMBED_FETCH_PDF_EXPORT_URL = 'EMBED_FETCH_PDF_EXPORT_URL',
  FETCH_IMAGE_EXPORT_URL = 'FETCH_IMAGE_EXPORT_URL',
  EMBED_FETCH_IMAGE_EXPORT_URL = 'EMBED_FETCH_IMAGE_EXPORT_URL',
  DOWNLOAD_DASHBOARD_SCREENSHOT = 'DOWNLOAD_DASHBOARD_SCREENSHOT',
  EMBED_DOWNLOAD_DASHBOARD_SCREENSHOT = 'EMBED_DOWNLOAD_DASHBOARD_SCREENSHOT',
  SEND_TRACK_EVENT = 'SEND_TRACK_EVENT',
  SELECT_DASHBOARD_DPT_TO_EDIT = 'SELECT_DASHBOARD_DPT_TO_EDIT',
  UPDATE_FILTER_MATCH = 'UPDATE_FILTER_MATCH',
  PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST',
  PASSWORD_RESET_CONFIRM = 'PASSWORD_RESET_CONFIRM',
  FETCH_EDITOR_DATASET_PREVIEW = 'FETCH_EDITOR_DATASET_PREVIEW',
  FETCH_EDITOR_DATASET_ROW_COUNT = 'FETCH_EDITOR_DATASET_ROW_COUNT',
  SWAP_DATA_SOURCE = 'SWAP_DATA_SOURCE',
  LIST_TEAM_DATA_SOURCES = 'LIST_TEAM_DATA_SOURCES',
  CHANGE_USER_PROFILE_NAME = 'CHANGE_USER_PROFILE_NAME',
  CHANGE_HAS_SEEN_WELCOME_MODAL = 'CHANGE_HAS_SEEN_WELCOME_MODAL',
  INVITE_TEAMMATE = 'INVITE_TEAMMATE',
  CANCEL_TEAM_INVITE = 'CANCEL_TEAM_INVITE',
  FETCH_SIGNUP_EMAIL = 'FETCH_SIGNUP_EMAIL',
  GET_USER_TEAM_INVITE = 'GET_USER_TEAM_INVITE',
  SAVE_SHARE_METADATA = 'SAVE_SHARE_METADATA',
  SAVE_SEND_EMAILS_AS = 'SAVE_SEND_EMAILS_AS',
  SAVE_SHARE_LINK_TITLE = 'SAVE_SHARE_LINK_TITLE',
  CREATE_TEMPLATE_DATA_PANEL_V2 = 'CREATE_TEMPLATE_DATA_PANEL_V2',
  DUPLICATE_DASHBOARD_ITEM = 'DUPLICATE_DASHBOARD_ITEM',
  CREATE_DATASET_V2 = 'CREATE_DATASET_V2',
  UPDATE_DATASET_SCHEMA = 'UPDATE_DATASET_SCHEMA',
  UPDATE_DATASET_DRILLDOWN_COLUMN_CONFIG = 'UPDATE_DATASET_DRILLDOWN_COLUMN_CONFIG',
  UPDATE_DATASET_DRILLDOWN_COLUMN_CONFIGS = 'UPDATE_DATASET_DRILLDOWN_COLUMN_CONFIGS',
  FETCH_DATASETS = 'FETCH_DATASETS',
  DELETE_DATA_PANEL_V2 = 'DELETE_DATA_PANEL_V2',
  CREATE_NEW_DASHBOARD_VERSION = 'CREATE_NEW_DASHBOARD_VERSION',
  SWITCH_CURRENTLY_EDITING_DASHBOARD_VERSION = 'SWITCH_CURRENTLY_EDITING_DASHBOARD_VERSION',
  PUBLISH_NEW_DASHBOARD_VERSION = 'PUBLISH_NEW_DASHBOARD_VERSION',
  REVERT_TO_DASHBOARD_VERSION = 'REVERT_TO_DASHBOARD_VERSION',
  FETCH_DASHBOARD_VERSIONS = 'FETCH_DASHBOARD_VERSIONS',
  SAVE_DASHBOARD_DRAFT = 'SAVE_DASHBOARD_DRAFT',
  UPDATE_ENVIRONMENT_TAG = 'UPDATE_ENVIRONMENT_TAG',
  CREATE_ENVIRONMENT_TAG = 'CREATE_ENVIRONMENT_TAG',
  CLEAR_DASHBOARD_CONFIG_REDUCER = 'CLEAR_DASHBOARD_CONFIG_REDUCER',
  EDIT_DATA_SOURCE = 'EDIT_DATA_SOURCE',
  DELETE_CURRENT_DASHBOARD_DRAFT = 'DELETE_CURRENT_DASHBOARD_DRAFT',
  UPDATE_AD_HOC_OPERATION_INSTRUCTIONS = 'UPDATE_AD_HOC_OPERATION_INSTRUCTIONS',
  EMBED_UPDATE_AD_HOC_OPERATION_INSTRUCTIONS = 'EMBED_UPDATE_AD_HOC_OPERATION_INSTRUCTIONS',
  EMBED_UPDATE_DPT_DATA_TABLE_COLUMN_WIDTHS = 'EMBED_UPDATE_DPT_DATA_TABLE_COLUMN_WIDTHS',

  CREATE_PARENT_SCHEMA = 'CREATE_PARENT_SCHEMA',
  SYNC_PARENT_SCHEMA = 'SYNC_PARENT_SCHEMA',
  FETCH_USED_PARENT_SCHEMAS = 'FETCH_USED_PARENT_SCHEMAS',
  FETCH_ALL_PARENT_SCHEMAS = 'FETCH_ALL_PARENT_SCHEMAS',
  FETCH_ALL_SCHEMA_TABLES_FOR_TEAM = 'FETCH_ALL_SCHEMA_TABLES',
  SET_DP_LOADING = 'SET_DP_LOADING',

  DELETE_DATA_SOURCE = 'DELETE_DATA_SOURCE',

  CREATE_DASHBOARD_EMAIL = 'CREATE_DASHBOARD_EMAIL',
  FETCH_EMAIL_CADENCE_LIST = 'FETCH_EMAIL_CADENCE_LIST',
  FETCH_EMAIL_CADENCE = 'FETCH_EMAIL_CADENCE',
  EDIT_DASHBOARD_EMAIL = 'EDIT_DASHBOARD_EMAIL',
  DELETE_DASHBOARD_EMAIL = 'DELETE_DASHBOARD_EMAIL',
  SEND_TEST_EMAIL = 'SEND_TEST_EMAIL',

  SAVE_ANALYTICS_CONFIG = 'SAVE_ANALYTICS_CONFIG',
  FETCH_USER_TEAM = 'FETCH_USER_TEAM',

  CREATE_WHITELIST_DOMAIN = 'CREATE_WHITELIST_DOMAIN',
  DELETE_WHITELIST_DOMAIN = 'DELETE_WHITELIST_DOMAIN',
  FETCH_WHITELIST_DOMAIN = 'FETCH_WHITELIST_DOMAIN',

  GOOGLE_OAUTH_VERIFICATION = 'GOOGLE_OAUTH_VERIFICATION',
  END_USER_PORTAL_SIGN_IN = 'END_USER_PORTAL_SIGN_IN',
  END_USER_PORTAL_AUTHENTICATION = 'END_USER_PORTAL_AUTHENTICATION',
  FETCH_END_USER_PORTAL_METADATA = 'FETCH_END_USER_PORTAL_METADATA',

  SAVE_DEFAULT_CURRENCY_CODE = 'SAVE_DEFAULT_CURRENCY_CODE',
  SAVE_DEFAULT_LOCALE_CODE = 'SAVE_DEFAULT_LOCALE_CODE',
  SAVE_USE_BROWSER_LOCALE = 'SAVE_USE_BROWSER_LOCALE',

  LOGIN_USER = 'LOGIN_USER',
  REGISTER_USER = 'REGISTER_USER',
  LOGOUT_USER = 'LOGOUT_USER',
  RESEND_EMAIL_VERIFICATION = 'RESEND_EMAIL_VERIFICATION',
  // when a user logs out, we need to dispatch a separate action to clear redux state
  CLEAR_STATE = 'CLEAR_STATE',

  VERIFY_EMAIL = 'VERIFY_EMAIL',

  ADD_USER_TO_TEAM = 'ADD_USER_TO_TEAM',
  CREATE_TEAM = 'CREATE_TEAM',
  REMOVE_TEAMMATE = 'REMOVE_TEAMMATEI',

  ADD_USER_SIGNUP_COLLECTION_INFO = 'ADD_USER_SIGNUP_COLLECTION_INFO',
  ADD_TEAM_SIGNUP_COLLECTION_INFO = 'ADD_TEAM_SIGNUP_COLLECTION_INFO',
  COMPLETE_ONBOARDING_SUB_STEP = 'COMPLETE_ONBOARDING_SUB_STEP',
  SET_TESTIMONIAL_INDEX = 'SET_TESTIMONIAL_INDEX',
  SET_SIGNUP_INFO_COLLECTION_STEP = 'SET_SIGNUP_INFO_COLLECTION_STEP',

  UPDATE_IS_WIDGET_OPEN = 'UPDATE_IS_WIDGET_OPEN',
  UPDATE_WIDGET_TYPE = 'UPDATE_WIDGET_TYPE',
  UPDATE_WIDGET_CONVERSATION_STARTED = 'UPDATE_WIDGET_CONVERSATION_STARTED',

  TEST_DATA_SOURCE_CONNECTION = 'TEST_DATA_SOURCE_CONNECTION',
  TEST_UPDATED_DATA_SOURCE_CONNECTION = 'TEST_UPDATED_DATA_SOURCE_CONNECTION',
  CONNECT_DATA_SOURCE = 'CONNECT_DATA_SOURCE',
  FETCH_SUPPORTED_DATA_SOURCES = 'FETCH_SUPPORTED_DATA_SOURCES',

  UPDATE_VISUALIZE_OPERATION_GENERAL_FORMAT_OPTIONS = 'UPDATE_VISUALIZE_OPERATION_GENERAL_FORMAT_OPTIONS',
  DOWNLOAD_DATA_PANEL_TEMPLATE_PDF = 'DOWNLOAD_DATA_PANEL_TEMPLATE_PDF',
  UPDATE_DRILLDOWN_DATA_PANEL = 'UPDATE_DRILLDOWN_DATA_PANEL',

  UPDATE_DASHBOARD_DEFAULT_TIMEZONE = 'UPDATE_DASHBOARD_DEFAULT_TIMEZONE',
  UPDATE_DISABLE_FILTERS_WHILE_LOADING = 'UPDATE_DISABLE_FILTERS_WHILE_LOADING',
  SYNC_CATEGORY_COLORS = 'SYNC_CATEGORY_COLORS',
  UPDATE_DASHBOARD_CACHE_CONFIG = 'UPDATE_DASHBOARD_CACHE_CONFIG',

  FETCH_DASHBOARD_ATTRIBUTES = 'FETCH_DASHBOARD_ATTRIBUTES',
  CREATE_DASHBOARD_ATTRIBUTE = 'CREATE_DASHBOARD_ATTRIBUTE',
  DELETE_DASHBOARD_ATTRIBUTE = 'DELETE_DASHBOARD_ATTRIBUTE',
  CREATE_ATTRIBUTE_VALUE = 'CREATE_ATTRIBUTE_VALUE',
  DELETE_ATTRIBUTE_VALUE = 'DELETE_ATTRIBUTE_VALUE',
  ASSIGN_DASHBOARD_VALUE = 'ASSIGN_DASHBOARD_VALUE',

  FETCH_END_USER_LIST = 'FETCH_END_USER_LIST',
  CREATE_END_USER = 'CREATE_END_USER',
  DELETE_END_USER = 'DELETE_END_USER',
  EDIT_END_USER = 'EDIT_END_USER',

  UPLOAD_IMAGE = 'UPLOAD_IMAGE',

  CREATE_API_TOKEN = 'CREATE_API_TOKEN',
  REFRESH_API_TOKEN = 'REFRESH_API_TOKEN',
  DELETE_API_TOKEN = 'DELETE_API_TOKEN',
  UPDATE_API_TOKEN = 'UPDATE_API_TOKEN',
  FETCH_ACCESS_GROUP_LIST = 'FETCH_ACCESS_GROUP_LIST',
  CREATE_ACCESS_GROUP = 'CREATE_ACCESS_GROUP',
  DELETE_ACCESS_GROUP = 'DELETE_ACCESS_GROUP',
  ASSIGN_USER_ROLE = 'ASSIGN_USER_ROLE',
  ASSIGN_PENDING_INVITE_ROLE = 'ASSIGN_PENDING_INVITE_ROLE',
  UPDATE_ACCESS_GROUPS = 'UPDATE_ACCESS_GROUPS',

  UPLOAD_CUSTOM_FONT = 'UPLOAD_CUSTOM_FONT',
  DELETE_CUSTOM_FONT = 'DELETE_CUSTOM_FONT',
  FETCH_CUSTOM_FONTS = 'FETCH_CUSTOM_FONTS',

  UPLOAD_SUPPORT_EMAIL = 'UPLOAD_SUPPORT_EMAIL',

  FETCH_CANVAS_LIST = 'FETCH_CANVAS_LIST',
  CREATE_CANVAS = 'CREATE_CANVAS',
  CLONE_CANVAS = 'CLONE_CANVAS',
  DELETE_CANVAS = 'DELETE_CANVAS',
  RENAME_CANVAS = 'RENAME_CANVAS',
  CREATE_CANVAS_DATASET = 'CREATE_CANVAS_DATASET',
  UPDATE_CANVAS_DATASET = 'UPDATE_CANVAS_DATASET',
  TOGGLE_CANVAS_DATASET_VISIBILITY = 'TOGGLE_CANVAS_DATASET_VISIBILITY',
  SAVE_CANVAS_DRAFT = 'SAVE_CANVAS_DRAFT',
  FETCH_CANVAS_VERSIONS = 'FETCH_CANVAS_VERSIONS',
  CREATE_NEW_CANVAS_VERSION = 'CREATE_NEW_CANVAS_VERSION',
  SWITCH_CURRENTLY_EDITING_CANVAS_VERSION = 'SWITCH_CURRENTLY_EDITING_CANVAS_VERSION',
  PUBLISH_NEW_CANVAS_VERSION = 'PUBLISH_NEW_CANVAS_VERSION',
  REVERT_TO_CANVAS_VERSION = 'REVERT_TO_CANVAS_VERSION',
  DELETE_CURRENT_CANVAS_DRAFT = 'DELETE_CURRENT_CANVAS_DRAFT',
  CREATE_CANVAS_FILTER = 'CREATE_CANVAS_FILTER',
  DELETE_CANVAS_FILTER = 'DELETE_CANVAS_FILTER',
  UPDATE_CANVAS_FILTER = 'UPDATE_CANVAS_FILTER',
  UPDATE_REQUIRED_FILTERS = 'UPDATE_REQUIRED_FILTERS',
  SET_CANVAS_FILTER_TYPE = 'SET_CANVAS_FILTER_TYPE',
  UPDATE_CANVAS_FILTER_CONFIG = 'UPDATE_CANVAS_FILTER_CONFIG',
  CREATE_CANVAS_COMPONENT = 'CREATE_CANVAS_COMPONENT',
  DELETE_CANVAS_COMPONENT = 'DELETE_CANVAS_COMPONENT',
  UPDATE_CANVAS_COMPONENT = 'UPDATE_CANVAS_COMPONENT',
  CREATE_CANVAS_TEMPLATE = 'CREATE_CANVAS_TEMPLATE',
  UPDATE_CANVAS_TEMPLATE = 'UPDATE_CANVAS_TEMPLATE',
  DELETE_CANVAS_TEMPLATE = 'DELETE_CANVAS_TEMPLATE',
  UPDATE_CANVAS_TEMPLATE_CHART_TYPE = 'UPDATE_CANVAS_TEMPLATE_CHART_TYPE',
  UPDATE_CANVAS_TEMPLATE_VIZ_OP = 'UPDATE_CANVAS_TEMPLATE_VIZ_OP',
  UPDATE_CANVAS_TEMPLATE_INSTRUCTIONS = 'UPDATE_CANVAS_TEMPLATE_INSTRUCTIONS',
  REMOVE_ELEMENT_FROM_CANVAS_LAYOUT = 'REMOVE_ELEMENT_FROM_CANVAS_LAYOUT',
  ADD_ELEMENT_TO_CANVAS_LAYOUT = 'ADD_ELEMENT_TO_CANVAS_LAYOUT',
  UPDATE_CANVAS_DEFAULT_LAYOUT = 'UPDATE_CANVAS_DEFAULT_LAYOUT',
  FETCH_CANVAS_FILTER_DATASET_PREVIEW = 'FETCH_CANVAS_FILTER_DATASET_PREVIEW',
  FETCH_CANVAS_FILTER_DATASET_ROW_COUNT = 'FETCH_CANVAS_FILTER_DATASET_ROW_COUNT',
  GET_DASHBOARDS_USING_DATASET = 'GET_DASHBOARDS_USING_DATASET',
  UPDATE_CANVAS_CONFIGURABILITY = 'UPDATE_CANVAS_CONFIGURABILITY',

  FETCH_END_USER_DASHBOARDS = 'FETCH_END_USER_DASHBOARDS',
  SELECT_END_USER_DASHBOARD = 'SELECT_END_USER_DASHBOARD',
  CLEAR_CANVAS_EDIT = 'CLEAR_CANVAS_EDIT',
  CLEAR_VIEWING_END_USER_DASHBOARD = 'CLEAR_VIEWING_END_USER_DASHBOARD',

  FETCH_END_USER_DASHBOARD = 'FETCH_END_USER_DASHBOARD',
  CREATE_END_USER_DASHBOARD = 'CREATE_END_USER_DASHBOARD',
  DELETE_END_USER_DASHBOARD = 'DELETE_END_USER_DASHBOARD',
  SAVE_END_USER_DASHBOARD = 'SAVE_END_USER_DASHBOARD',
  OPEN_END_USER_DASHBOARD = 'OPEN_END_USER_DASHBOARD',
  CLOSE_END_USER_DASHBOARD = 'CLOSE_END_USER_DASHBOARD',
  UPDATE_END_USER_DASHBOARD_VARIABLES = 'UPDATE_END_USER_DASHBOARD_VARIABLES',
  UPDATE_END_USER_DASHBOARD_NAME = 'UPDATE_END_USER_DASHBOARD_NAME',
  TOGGLE_END_USER_DASHBOARD_EDITING = 'TOGGLE_END_USER_DASHBOARD_EDITING',
  UPDATE_END_USER_DASHBOARD_LAYOUT = 'UPDATE_END_USER_DASHBOARD_LAYOUT',
  TOGGLE_END_USER_DASHBOARD_FILTER = 'TOGGLE_END_USER_DASHBOARD_FILTER',
  COPY_END_USER_DASHBOARD_TEMPLATE = 'COPY_END_USER_DASHBOARD_TEMPLATE',
  CREATE_END_USER_DATA_PANEL = 'CREATE_END_USER_DATA_PANEL',
  DELETE_END_USER_DASHBOARD_ITEM = 'DELETE_END_USER_DASHBOARD_ITEM',
  SET_SELECTED_END_USER_DASHBOARD_ELEMENT_ID = 'SET_SELECTED_END_USER_DASHBOARD_ELEMENT_ID',
  UPDATE_END_USER_DATA_PANEL_DATASET = 'UPDATE_END_USER_DATA_PANEL_DATASET',
  UPDATE_END_USER_DATA_PANEL_VIZ_OP = 'UPDATE_END_USER_DATA_PANEL_VIZ_OP',
  UPDATE_END_USER_DATA_PANEL_CHART_TYPE = 'UPDATE_END_USER_DATA_PANEL_CHART_TYPE',
  UPDATE_END_USER_DATA_PANEL_INSTRUCTIONS = 'UPDATE_END_USER_DATA_PANEL_INSTRUCTIONS',
  UPDATE_END_USER_DATA_PANEL_FILTERS = 'UPDATE_END_USER_DATA_PANEL_FILTERS',
  CREATE_END_USER_EMAIL = 'CREATE_END_USER_EMAIL',
  DELETE_END_USER_EMAIL = 'DELETE_END_USER_EMAIL',
  UPDATE_END_USER_EMAIL = 'UPDATE_END_USER_EMAIL',
  SEND_TEST_END_USER_EMAIL = 'SEND_TEST_END_USER_EMAIL',
  SEND_TEST_END_USER_EMAIL_BY_PARAMS = 'SEND_TEST_END_USER_EMAIL_BY_PARAMS',
  EXPORT_END_USER_DASHBOARD = 'EXPORT_END_USER_DASHBOARD',
  CLEAR_END_USER_EXPORT = 'CLEAR_END_USER_EXPORT',

  SET_END_USER_DASHBOARD_FOR_EXAMPLE = 'SET_END_USER_DASHBOARD_FOR_EXAMPLE',
  CLEAR_END_USER_DASHBOARD_EXAMPLE = 'CLEAR_END_USER_DASHBOARD_EXAMPLE',

  SEND_PING = 'SEND_PING',
  SELECT_customer = 'SELECT_customer',
  SELECT_DASHBOARD = 'SELECT_DASHBOARD',

  GET_SUPERUSER_ACTIONS = 'GET_SUPERUSER_ACTIONS',
  POST_SUPERUSER_ACTION = 'POST_SUPERUSER_ACTION',
  GET_SUPERUSER_USERS = 'GET_SUPERUSER_USERS',
  GET_SUPERUSER_TEAMS = 'GET_SUPERUSER_TEAMS',

  CLEAR_DASHBOARD_LAYOUT_REDUCER = 'CLEAR_DASHBOARD_LAYOUT_REDUCER',
  CLEAR_SHARE_LINK = 'CLEAR_SHARE_LINK',
  SET_SYNC_CATEGORY_COLORS = 'SET_SYNC_CATEGORY_COLORS',
  TOGGLE_ELEM_VISIBILITY_FOR_SECONDARY_LAYOUT = 'TOGGLE_ELEM_VISIBILITY_FOR_SECONDARY_LAYOUT',
  CLEAR_DOWNLOADS = 'CLEAR_DOWNLOADS',
  UPDATE_DASHBOARD_PAGE_LAYOUT_CONFIG = 'UPDATE_DASHBOARD_PAGE_LAYOUT_CONFIG',
  UPDATE_ELEMENT_CONTAINER_LOCATION = 'UPDATE_ELEMENT_CONTAINER_LOCATION',

  SET_LINKED_DATA_PANEL_HOVER = 'SET_LINKED_DATA_PANEL_HOVER',
  SET_LINKED_DASHBOARD_ELEMENT_HOVER = 'SET_LINKED_DASHBOARD_ELEMENT_HOVER',
  SET_SELECTED_DRILLDOWN_COLUMN = 'SET_SELECTED_DRILLDOWN_COLUMN',

  FETCH_REPORT_BUILDERS = 'FETCH_REPORT_BUILDERS',
  CREATE_REPORT_BUILDER = 'CREATE_REPORT_BUILDER',
  CLONE_REPORT_BUILDER = 'CLONE_REPORT_BUILDER',
  DELETE_REPORT_BUILDER = 'DELETE_REPORT_BUILDER',
  RENAME_REPORT_BUILDER = 'RENAME_REPORT_BUILDER',
  FETCH_REPORT_BUILDER_VERSIONS = 'FETCH_REPORT_BUILDER_VERSIONS',
  PUBLISH_NEW_REPORT_BUILDER_VERSION = 'PUBLISH_NEW_REPORT_BUILDER_VERSION',
  DELETE_CURRENT_REPORT_BUILDER_DRAFT = 'DELETE_CURRENT_REPORT_BUILDER_DRAFT',
  SAVE_REPORT_BUILDER_DRAFT = 'SAVE_REPORT_BUILDER_DRAFT',
  REVERT_TO_REPORT_BUILDER_VERSION = 'REVERT_TO_REPORT_BUILDER_VERSION',
  CLEAR_REPORT_BUILDER_REDUCER = 'CLEAR_REPORT_BUILDER_REDUCER',

  FETCH_REPORT_BUILDER_DATASET_PREVIEW = 'FETCH_REPORT_BUILDER_DATASET_PREVIEW',
  FETCH_REPORT_BUILDER_DATASET_ROW_COUNT = 'FETCH_REPORT_BUILDER_DATASET_ROW_COUNT',
  FETCH_REPORT_BUILDER_PREVIEW_MODAL_DATA = 'FETCH_REPORT_BUILDER_PREVIEW_MODAL_DATA',
  FETCH_REPORT_BUILDER_PREVIEW_REPORT_DATA = 'FETCH_REPORT_BUILDER_PREVIEW_REPORT_DATA',
  FETCH_REPORT_BUILDER_PREVIEW_REPORT_ROW_COUNT = 'FETCH_REPORT_BUILDER_PREVIEW_REPORT_ROW_COUNT',

  FETCH_CUSTOMER_REPORTS = 'FETCH_CUSTOMER_REPORTS',
  CREATE_CUSTOMER_REPORT = 'CREATE_CUSTOMER_REPORT',
  STAR_CUSTOMER_REPORT = 'STAR_CUSTOMER_REPORT',
  DELETE_CUSTOMER_REPORT = 'DELETE_CUSTOMER_REPORT',
  FETCH_CUSTOMER_DATASET_PREVIEW = 'FETCH_CUSTOMER_DATASET_PREVIEW',
  SAVE_CUSTOMER_REPORT = 'SAVE_CUSTOMER_REPORT',
  UPDATE_CUSTOMER_REPORT_NAME = 'UPDATE_CUSTOMER_REPORT_NAME',
  FETCH_CUSTOMER_REPORT_DATA = 'FETCH_CUSTOMER_REPORT_DATA',
  FETCH_CUSTOMER_REPORT_ROW_COUNT = 'FETCH_CUSTOMER_REPORT_ROW_COUNT',
  EXPORT_CUSTOMER_REPORT = 'EXPORT_CUSTOMER_REPORT',
  LIST_CUSTOMER_REPORT_EMAILS = 'LIST_CUSTOMER_REPORT_EMAILS',
  CREATE_CUSTOMER_REPORT_EMAIL = 'CREATE_CUSTOMER_REPORT_EMAIL',
  DELETE_CUSTOMER_REPORT_EMAIL = 'DELETE_CUSTOMER_REPORT_EMAIL',
  UPDATE_CUSTOMER_REPORT_EMAIL = 'UPDATE_CUSTOMER_REPORT_EMAIL',
  SEND_TEST_CUSTOMER_REPORT_CADENCE_EMAIL = 'SEND_TEST_CUSTOMER_REPORT_CADENCE_EMAIL',
  SEND_TEST_CUSTOMER_REPORT_CADENCE_EMAIL_BY_PARAMS = 'SEND_TEST_CUSTOMER_REPORT_CADENCE_EMAIL_BY_PARAMS',

  FETCH_CUSTOMER_REPORT_BUILDER_METADATA = 'FETCH_CUSTOMER_REPORT_BUILDER_METADATA',
  TOGGLE_FAVORITE_BUILT_IN = 'TOGGLE_FAVORITE_BUILT_IN',
}
