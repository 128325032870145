import { useMemo } from 'react';
import cx from 'classnames';
import produce from 'immer';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { times } from 'utils/standard';

import BaseDataTable from 'components/dataTable/baseDataTable';
import LoadingDataTable from 'components/dataTable/loadingDataTable';
import { HEADER_HEIGHT } from 'pages/dashboardPage/DashboardDatasetView/DashboardDatasetView';
import { DatasetEditorNonIdealState } from 'pages/dashboardPage/dashboardDatasetEditor/DatasetEditorNonIdealState';
import DataTableFooter from './DataTableFooter';

import { AdHocOperationInstructions } from 'types/dataPanelTemplate';
import { Dataset } from 'actions/datasetActions';
import { DatasetSchema, DatasetRow, DatabaseUnsupportedOperations } from 'types/datasets';
import { MAX_ROWS_TO_PREVIEW } from 'constants/dataConstants';
import {
  VisualizeTableInstructions,
  REPORTED_ANALYTIC_ACTION_TYPES,
  UserTransformedSchema,
  VisualizeOperationGeneralFormatOptions,
} from 'constants/types';
import {
  getMetricsByColumn,
  getChangedSchema,
  removeUserDisabledColumns,
} from 'utils/dashboardUtils';
import { sortTable } from 'utils/adHocUtils';
import { DashboardVariable, DashboardVariableMap } from 'types/dashboardTypes';
import { ResourceDataset } from 'types/exploResource';
import { AnalyticsEventTracker } from 'utils/analyticsUtils';
import { getDrilldownDisplayOptions } from 'utils/drilldownDatasetUtils';

import { SpreadsheetType } from 'reducers/dashboardLayoutReducer';

const FOOTER_HEIGHT = 34;

const useStyles = makeStyles((theme: Theme) => ({
  dataTable: {
    height: `calc(100% - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px) !important`,

    '&.noHeader': {
      height: `calc(100% - ${FOOTER_HEIGHT}px) !important`,
    },
    '&.noFooter': {
      height: `calc(100% - ${HEADER_HEIGHT}px) !important`,
    },
    '&.noHeader.noFooter': {
      height: `calc(100%) !important`,
    },
  },
  loadingDataTable: {
    height: `calc(100% - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px) !important`,
    borderBottom: `1px solid ${theme.palette.ds.grey500}`,
    '&.noHeader': {
      height: `calc(100% - ${FOOTER_HEIGHT}px) !important`,
    },
    '&.noFooter': {
      height: `calc(100% - ${HEADER_HEIGHT}px) !important`,
    },
    '&.noHeader.noFooter': {
      height: `calc(100%) !important`,
    },
  },
}));

type Props = {
  analyticsEventTracker?: AnalyticsEventTracker;
  adHocOperationInstructions: AdHocOperationInstructions;
  canDownloadDataPanel: boolean;
  dataPanelId: string;
  onDownloadPanelPdf?: (
    adHocOperationInstructions: AdHocOperationInstructions,
    email: string | undefined,
    userTransformedSchema?: UserTransformedSchema,
  ) => void;
  onDownloadPanelSpreadsheet?: (
    fileFormat: SpreadsheetType,
    email?: string,
    userTransformedSchema?: UserTransformedSchema,
  ) => void;
  enableColumnResizing?: boolean;
  error?: boolean;
  isDrilldownModal?: boolean;
  drilldownDataset?: Dataset;
  loading?: boolean;
  onAdHocSortOrPageUpdate: (adHocOperationInstructions: AdHocOperationInstructions) => void;
  previewData: DatasetRow[];
  schema: DatasetSchema;
  secondaryData: DatasetRow[];
  sourceDataRowCount?: number;
  updateDataTableOperation: (visualizeInstructions: VisualizeTableInstructions) => void;
  userTransformedSchema?: UserTransformedSchema;
  datasets?: Record<string, ResourceDataset>;
  generalOptions: VisualizeOperationGeneralFormatOptions | undefined;
  instructions: VisualizeTableInstructions;
  setVariable?: (value: DashboardVariable) => void;
  drilldownVar?: DashboardVariable;
  shouldUseJobQueue?: boolean;
  supportEmail?: string;
  isEmbed: boolean;
  unsupportedOperations: DatabaseUnsupportedOperations[] | undefined;
  variables?: DashboardVariableMap;
};

const getOnColumnSelect =
  ({
    adHocOperationInstructions,
    onAdHocSortOrPageUpdate,
    analyticsEventTracker,
  }: Pick<
    Props,
    'adHocOperationInstructions' | 'onAdHocSortOrPageUpdate' | 'analyticsEventTracker'
  >) =>
  (colIndex: number, headerList: DatasetSchema) => {
    const sortInfo = sortTable(
      colIndex,
      headerList,
      adHocOperationInstructions,
      onAdHocSortOrPageUpdate,
    );

    analyticsEventTracker?.(REPORTED_ANALYTIC_ACTION_TYPES.TABLE_SORTED, {
      column_name: sortInfo.column_name,
      order: sortInfo.order.toString(),
    });
  };

const getOnNewPage =
  ({
    adHocOperationInstructions,
    onAdHocSortOrPageUpdate,
    analyticsEventTracker,
    sourceDataRowCount,
    rowsPerPage,
  }: Pick<
    Props,
    | 'adHocOperationInstructions'
    | 'onAdHocSortOrPageUpdate'
    | 'analyticsEventTracker'
    | 'sourceDataRowCount'
  > & { rowsPerPage?: number }) =>
  (newPage: string) => {
    if (!sourceDataRowCount) return;

    const newPageNumber = Number.parseInt(newPage);
    const maxPageNumber = Math.ceil(sourceDataRowCount / (rowsPerPage || MAX_ROWS_TO_PREVIEW));

    if (
      !newPageNumber ||
      newPageNumber < 1 ||
      newPageNumber > maxPageNumber ||
      adHocOperationInstructions.currentPage === newPageNumber
    ) {
      return;
    }

    const newAdHocOperationInstructions = produce(adHocOperationInstructions, (draft) => {
      draft.currentPage = newPageNumber;
    });

    onAdHocSortOrPageUpdate(newAdHocOperationInstructions);

    analyticsEventTracker?.(REPORTED_ANALYTIC_ACTION_TYPES.TABLED_PAGED, {
      new_page: newPageNumber,
    });
  };

const onTableColumnWidthsEdited =
  ({
    updateDataTableOperation,
    enableColumnResizing,
    instructions,
    schema,
  }: Pick<
    Props,
    'updateDataTableOperation' | 'enableColumnResizing' | 'instructions' | 'schema'
  >) =>
  (index: number, size: number) => {
    if (!enableColumnResizing) return;
    const numHeaders = getChangedSchema(schema, instructions).length;

    const existingWidths = instructions.columnWidths ?? times(numHeaders, () => null);

    const newWidths = produce(existingWidths, (draft) => {
      draft[index] = size;
    });

    const newOperation = produce(instructions, (draft) => {
      draft.columnWidths = newWidths;
    });

    updateDataTableOperation(newOperation);
  };

export default function DataTable({
  adHocOperationInstructions,
  analyticsEventTracker,
  canDownloadDataPanel,
  dataPanelId,
  onDownloadPanelSpreadsheet,
  enableColumnResizing,
  error,
  isDrilldownModal,
  loading,
  onAdHocSortOrPageUpdate,
  onDownloadPanelPdf,
  previewData,
  schema,
  secondaryData,
  sourceDataRowCount,
  updateDataTableOperation,
  userTransformedSchema,
  datasets,
  generalOptions,
  instructions,
  setVariable,
  drilldownVar,
  shouldUseJobQueue,
  supportEmail,
  isEmbed,
  unsupportedOperations,
  drilldownDataset,
  variables,
}: Props) {
  const classes = useStyles();
  const metricsByColumn = useMemo(() => getMetricsByColumn(secondaryData || []), [secondaryData]);

  const tableLoading = loading || !previewData;

  const isSchemaCustomizationEnabled =
    instructions.isSchemaCustomizationEnabled || isDrilldownModal;

  const footer = (
    <DataTableFooter
      adHocOperationInstructions={adHocOperationInstructions}
      canDownloadDataPanel={canDownloadDataPanel}
      dataPanelId={dataPanelId}
      disableDownload={loading || error}
      disableDownloadExport={generalOptions?.export?.disableDownloadExport}
      disablePdfDownload={generalOptions?.export?.disablePdfDownload}
      enableEmailExport={generalOptions?.export?.enableEmailExport}
      isDownloadButtonHidden={generalOptions?.export?.isDownloadButtonHidden}
      isEmbed={isEmbed}
      loading={tableLoading}
      onDownloadPanelPdf={(email) =>
        isSchemaCustomizationEnabled
          ? onDownloadPanelPdf?.(adHocOperationInstructions, email, userTransformedSchema)
          : onDownloadPanelPdf?.(adHocOperationInstructions, email)
      }
      onDownloadPanelSpreadsheet={(fileFormat, email) =>
        isSchemaCustomizationEnabled
          ? onDownloadPanelSpreadsheet?.(fileFormat, email, userTransformedSchema)
          : onDownloadPanelSpreadsheet?.(fileFormat, email)
      }
      onNewPage={getOnNewPage({
        adHocOperationInstructions,
        onAdHocSortOrPageUpdate,
        analyticsEventTracker,
        sourceDataRowCount,
        rowsPerPage: instructions.rowsPerPage,
      })}
      rowsPerPage={instructions.rowsPerPage}
      shouldUseJobQueue={shouldUseJobQueue}
      sourceDataRowCount={sourceDataRowCount}
      supportEmail={supportEmail}
      unsupportedOperations={unsupportedOperations}
    />
  );

  const drilldownDisplayOptions = useMemo(() => {
    const drilldownColumnConfigs = drilldownDataset?.drilldownColumnConfigs;

    if (!drilldownColumnConfigs || tableLoading) return undefined;
    return getDrilldownDisplayOptions(drilldownColumnConfigs);
  }, [tableLoading, drilldownDataset]);

  if (tableLoading) {
    return (
      <>
        <LoadingDataTable
          disableRowHeader
          className={cx(classes.loadingDataTable, {
            noHeader: !isDrilldownModal && generalOptions?.headerConfig?.isHeaderHidden,
            noFooter: instructions.isFooterHidden,
          })}
          maxRows={50}
          rowHeight={instructions.rowHeight}
        />
        {instructions.isFooterHidden ? null : footer}
      </>
    );
  }

  const tableSchema =
    isSchemaCustomizationEnabled && userTransformedSchema
      ? removeUserDisabledColumns(userTransformedSchema)
      : schema;

  let drilldownCol;
  if (instructions.drilldownConfig?.drilldownEnabled) {
    drilldownCol = instructions.drilldownConfig?.drilldownColumn;
  }
  if (isDrilldownModal && tableSchema.length === 0) {
    return <DatasetEditorNonIdealState title="No visible columns" />;
  }

  return (
    <>
      <BaseDataTable
        disableRowHeader
        fill
        isDashboardTable
        noBorderRadius
        truncateEmptyRowSpace
        unrestrictedHeight
        useFriendlyNameForHeader
        changeSchemaList={instructions.changeSchemaList}
        className={cx(classes.dataTable, {
          noHeader: !isDrilldownModal && generalOptions?.headerConfig?.isHeaderHidden,
          noFooter: instructions.isFooterHidden,
        })}
        columnLinesEnabled={instructions.isColumnLinesEnabled}
        columnWidths={instructions.columnWidths}
        datasets={datasets}
        drilldownCol={drilldownCol}
        drilldownVar={drilldownVar}
        enableColumnResizing={enableColumnResizing}
        isColumnHeadersBolded={instructions.isColumnHeadersBolded}
        isFirstColumnBolded={instructions.isFirstColumnBolded}
        isFirstColumnFrozen={instructions.isFirstColumnFrozen}
        isSortable={isDrilldownModal || !instructions.isColumnSortingDisabled}
        loading={tableLoading}
        maxRows={instructions.rowsPerPage || MAX_ROWS_TO_PREVIEW}
        metricsByColumn={metricsByColumn}
        onColumnSelect={getOnColumnSelect({
          adHocOperationInstructions,
          onAdHocSortOrPageUpdate,
          analyticsEventTracker,
        })}
        onColumnWidthChanged={onTableColumnWidthsEdited({
          updateDataTableOperation,
          enableColumnResizing,
          instructions,
          schema,
        })}
        rowHeight={instructions.rowHeight}
        rowLinesDisabled={instructions.isRowLinesDisabled}
        rows={previewData || []}
        schema={tableSchema}
        schemaDisplayOptions={
          isDrilldownModal ? drilldownDisplayOptions : instructions.schemaDisplayOptions
        }
        setVariable={setVariable}
        shouldTruncateText={instructions.shouldTruncateText}
        shouldVisuallyGroupByFirstColumn={instructions.shouldVisuallyGroupByFirstColumn}
        sortInfo={adHocOperationInstructions.sortInfo}
        variables={variables}
      />
      {instructions.isFooterHidden ? null : footer}
    </>
  );
}
