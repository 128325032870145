import { HORIZONTAL_BAR_VIZ_OPS } from 'constants/dataConstants';
import { OPERATION_TYPES } from 'constants/types';
import { PIE_CHART_SET } from 'pages/BlueprintPage/constants';

export function getCategoryColSectionLabel(opType: OPERATION_TYPES): string {
  if (PIE_CHART_SET.has(opType)) return 'Categories';
  if (opType === OPERATION_TYPES.VISUALIZE_FUNNEL_V2) return 'Stage';

  return HORIZONTAL_BAR_VIZ_OPS.has(opType) ? 'Y-Axis' : 'X-Axis';
}

export function getAggColSectionLabel(opType: OPERATION_TYPES): string {
  if (PIE_CHART_SET.has(opType)) return 'Values';
  if (opType === OPERATION_TYPES.VISUALIZE_FUNNEL_V2) return 'Amount';

  return HORIZONTAL_BAR_VIZ_OPS.has(opType) ? 'X-Axis' : 'Y-Axis';
}

export function chartCanHaveGroupBy(opType: OPERATION_TYPES): boolean {
  switch (opType) {
    case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_LINE_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_AREA_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2:
    case OPERATION_TYPES.VISUALIZE_SPIDER_CHART:
      return true;

    default:
      return false;
  }
}
