import DropdownSelect from 'shared/DropdownSelect';
import FlexBox from 'components/core/FlexBox';
import GradientColorPicker from 'components/ColumnFormatConfigs/NumberFormatConfig/GradientColorPicker';
import GoalInputRow from 'components/ColumnFormatConfigs/NumberFormatConfig/GoalInputRow';
import { sprinkles } from 'components/ds';

import {
  GradientType,
  V2TwoDimensionChartInstructions,
  HeatMapFormat,
  SelectedDropdownInputItem,
  GradientPointType,
  GradientOptions,
} from 'constants/types';
import { GlobalStyleConfig } from 'globalStyles/types';

const POINT_CONFIGURATION_FLEX = 3.33;

type Props = {
  globalStyleConfig: GlobalStyleConfig;
  instructions: V2TwoDimensionChartInstructions;
  updateHeatMapInstructions: (updates: HeatMapFormat) => void;
};

export default function HeatMapGradientConfiguration({
  globalStyleConfig,
  instructions,
  updateHeatMapInstructions,
}: Props) {
  const heatMapInstructions = instructions.chartSpecificFormat?.heatMap;

  const gradient = heatMapInstructions?.gradient;
  const gradientType = heatMapInstructions?.gradientType ?? GradientType.LINEAR;
  const defaultDivergingGradient = globalStyleConfig.visualizations.divergingPalette;
  const defaultLinearGradient = globalStyleConfig.visualizations.gradientPalette;
  const { hue1, hue2, hue3 } = {
    hue1: gradient?.hue1 || defaultDivergingGradient.hue1,
    hue2:
      gradient?.hue2 ||
      (gradientType === GradientType.LINEAR
        ? defaultLinearGradient.hue1
        : defaultDivergingGradient.hue2),
    hue3:
      gradient?.hue3 ||
      (gradientType === GradientType.LINEAR
        ? defaultLinearGradient.hue2
        : defaultDivergingGradient.hue3),
  };
  const hues = gradientType === GradientType.DIVERGING ? [hue1, hue2, hue3] : [hue2, hue3];

  const getOnHueChanged = (hue: 'hue1' | 'hue2' | 'hue3') => (newHue: string) => {
    const newGradient = {
      ...gradient,
      [hue]: newHue,
    };
    updateHeatMapInstructions({ gradient: newGradient });
  };

  const gradientOptions = heatMapInstructions?.gradientOptions;
  const { maxpoint, midpoint, minpoint } = gradientOptions ?? {};

  const updateGradientOptions = (updates: GradientOptions) => {
    updateHeatMapInstructions({
      gradientOptions: {
        ...gradientOptions,
        ...updates,
      },
    });
  };

  const gradientTypes = [GradientType.DIVERGING, GradientType.LINEAR];
  const paletteOptions: SelectedDropdownInputItem[] = gradientTypes.map((gradient) => ({
    id: gradient,
    name: gradient,
  }));

  return (
    <div>
      <DropdownSelect
        btnMinimal
        fillWidth
        minimal
        filterable={false}
        label="Gradient Type"
        noSelectionText="Select a Gradient"
        onChange={(item: SelectedDropdownInputItem) => {
          updateHeatMapInstructions({ gradientType: item.id as GradientType });
        }}
        options={paletteOptions}
        selectedItem={{ id: gradientType, name: gradientType }}
      />
      <div className={sprinkles({ marginTop: 'sp1.5' })}>
        <FlexBox>
          <GradientColorPicker
            className={sprinkles({ marginTop: 'sp1' })}
            hues={hues}
            onHuesChanged={
              gradientType === GradientType.DIVERGING
                ? [getOnHueChanged('hue1'), getOnHueChanged('hue2'), getOnHueChanged('hue3')]
                : [getOnHueChanged('hue2'), getOnHueChanged('hue3')]
            }
          />
          <div>
            <GoalInputRow
              exactValue={minpoint?.number}
              flex={POINT_CONFIGURATION_FLEX}
              type="min"
              updateExactValue={(newValue) => {
                updateGradientOptions({
                  minpoint: {
                    ...minpoint,
                    number: newValue,
                  },
                });
              }}
              updateUsesColumnCalculation={(newValue) => {
                updateGradientOptions({
                  minpoint: {
                    ...minpoint,
                    type: newValue ? GradientPointType.COMPUTED : GradientPointType.NUMBER,
                  },
                });
              }}
              usesColumnCalculation={minpoint?.type !== GradientPointType.NUMBER}
            />
            {gradientType === GradientType.DIVERGING && (
              <GoalInputRow
                exactValue={midpoint?.number}
                flex={POINT_CONFIGURATION_FLEX}
                type="mid"
                updateExactValue={(newValue) => {
                  updateGradientOptions({
                    midpoint: {
                      ...midpoint,
                      number: newValue,
                    },
                  });
                }}
                updateUsesColumnCalculation={(newValue) => {
                  updateGradientOptions({
                    midpoint: {
                      ...midpoint,
                      type: newValue ? GradientPointType.COMPUTED : GradientPointType.NUMBER,
                    },
                  });
                }}
                usesColumnCalculation={midpoint?.type !== GradientPointType.NUMBER}
              />
            )}
            <GoalInputRow
              exactValue={maxpoint?.number}
              flex={POINT_CONFIGURATION_FLEX}
              type="max"
              updateExactValue={(newValue) => {
                updateGradientOptions({
                  maxpoint: {
                    ...maxpoint,
                    number: newValue,
                  },
                });
              }}
              updateUsesColumnCalculation={(newValue) => {
                updateGradientOptions({
                  maxpoint: {
                    ...maxpoint,
                    type: newValue ? GradientPointType.COMPUTED : GradientPointType.NUMBER,
                  },
                });
              }}
              usesColumnCalculation={maxpoint?.type !== GradientPointType.NUMBER}
            />
          </div>
        </FlexBox>
      </div>
    </div>
  );
}
