import { Component, ComponentProps } from 'react';
import { uniqueId } from 'utils/standard';
import {
  Theme,
  createStyles,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme,
} from '@material-ui/core/styles';
import { Menu, MenuItem } from '@blueprintjs/core';
import { IconButton, Popover } from 'components/ds';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: 220,
      backgroundColor: 'white',
      border: `1px solid ${theme.palette.ds.blue}`,
      borderRadius: 3,
    },
    actionsMenu: {
      overflow: 'auto',
    },
    operationActionsPopover: {
      boxShadow: 'none',
    },
    portal: {
      '& .bp3-transition-container': {
        zIndex: 0,
      },
    },
  });

type PassedProps = {
  actions?: ComponentProps<typeof MenuItem>[];
  className?: string;
  panelMenu?: JSX.Element;
  iconButtonClassName?: string;
};

type Props = PassedProps & WithStyles<typeof styles> & WithTheme;

class DotsDropdown extends Component<Props> {
  render() {
    const { classes, panelMenu, iconButtonClassName } = this.props;

    return (
      <Popover
        align="end"
        trigger={<IconButton className={iconButtonClassName} name="ellipsis-vertical" />}>
        {panelMenu ?? <div className={classes.root}>{this.renderPanelMenu()}</div>}
      </Popover>
    );
  }

  renderPanelMenu = () => {
    const { classes, actions } = this.props;
    if (!actions) return;

    return (
      <Menu className={classes.actionsMenu}>
        {actions.map((actionConfig: ComponentProps<typeof MenuItem>) => (
          <MenuItem key={uniqueId('flow_action_item')} {...actionConfig} />
        ))}
      </Menu>
    );
  };
}

export default withStyles(styles)(withTheme(DotsDropdown));
