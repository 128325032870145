import { AnyAction, createAsyncThunk, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';

import { createApiRequestConfig } from 'actions/actionUtils';
import { ACTION } from 'actions/types';
import { ReduxState } from 'reducers/rootReducer';
import { ResourcePageType } from 'types/exploResource';
import { removeUnsavedDashboardConfigFields } from 'utils/dashboardUtils';
import { makeThunkRequest } from 'utils/thunkUtils';
import { changesMadeWhileSaving, setSaveError } from 'reducers/resourceSavingReducer';
import { ApiException, ErrorResponse } from 'actions/responseTypes';
import { isSuccess } from 'remotedata';
import { cleanCanvasConfig } from 'utils/canvasConfigUtils';
import { CanvasVersion } from 'actions/canvasVersionActions';
import { ReportBuilderVersion } from 'actions/reportBuilderVersionActions';

type Thunk = ThunkAction<void, ReduxState, unknown, AnyAction>;

export const saveResourceConfig =
  (resourceType: ResourcePageType): Thunk =>
  (dispatch, getState) => {
    const { isSaving, changesWhileSaving } = getState().resourceSaving;
    if (isSaving) dispatch(changesMadeWhileSaving(true));
    else {
      if (changesWhileSaving) dispatch(changesMadeWhileSaving(false));
      if (resourceType === ResourcePageType.EXPLORE) dispatch(saveExploreDraft());
      else if (resourceType === ResourcePageType.ARCHITECT) dispatch(saveArchitectDraft());
      else if (resourceType === ResourcePageType.REPORT_BUILDER) dispatch(saveReportBuilderDraft());
    }
  };

type EditResponse = { edit_version_number: number };

export const saveExploreDraft = createAsyncThunk<EditResponse, undefined, { state: ReduxState }>(
  ACTION.SAVE_DASHBOARD_DRAFT,
  async (_, { getState, dispatch }) => {
    const { dashboardEditConfig, dashboard } = getState();
    const { config, versionInfo } = dashboardEditConfig;
    const dashboardId = dashboard.currentDashboardTemplateId;

    let requestConfig: AxiosRequestConfig | null = null;

    if (config && versionInfo && dashboardId) {
      const postData = {
        config: removeUnsavedDashboardConfigFields(config),
        version_number: versionInfo.version_number,
        edit_version_number: versionInfo.edit_version_number,
      };
      const url = `dashboards/${dashboardId}/save_draft/`;

      requestConfig = createApiRequestConfig(url, 'POST', postData, false);
    }

    return makeThunkRequest(requestConfig, 'Error saving dashboard', undefined, (response) =>
      handleSaveError(dispatch, response),
    );
  },
);

export const saveArchitectDraft = createAsyncThunk<
  EditResponse | { canvas_version: CanvasVersion },
  undefined,
  { state: ReduxState }
>(ACTION.SAVE_CANVAS_DRAFT, async (_, { getState, dispatch }) => {
  const { canvasEdit, canvas } = getState();
  const { config, versionInfo } = canvasEdit;
  const canvasId = canvas.currentCanvasId;

  const errorMessage = 'Error saving blueprint draft';

  if (isSuccess(config) && versionInfo && canvasId) {
    const cleanConfig = cleanCanvasConfig(config.data);
    if (versionInfo.is_draft) {
      const postData = {
        config: cleanConfig,
        version_number: versionInfo.version_number,
        edit_version_number: versionInfo.edit_version_number,
      };
      const url = `canvases/${canvasId}/save_draft/`;
      const requestConfig = createApiRequestConfig(url, 'POST', postData, false);

      return makeThunkRequest(requestConfig, errorMessage, undefined, (response) =>
        handleSaveError(dispatch, response),
      );
    } else {
      const url = `canvases/${canvasId}/create_new_canvas_version/`;
      const requestConfig = createApiRequestConfig(url, 'POST', {}, false);

      return makeThunkRequest(
        requestConfig,
        'Error creating new canvas version',
        undefined,
        (response) => handleSaveError(dispatch, response),
      );
    }
  }
  throw new Error(errorMessage);
});

export const saveReportBuilderDraft = createAsyncThunk<
  EditResponse | { new_version: ReportBuilderVersion },
  undefined,
  { state: ReduxState }
>(ACTION.SAVE_REPORT_BUILDER_DRAFT, async (_, { getState, dispatch }) => {
  const { reportBuilderEdit, reportBuilder } = getState();
  const { config, versionInfo } = reportBuilderEdit;
  const reportBuilderId = reportBuilder.currentReportBuilder;

  let requestConfig: AxiosRequestConfig | null = null;

  if (isSuccess(config) && versionInfo && reportBuilderId) {
    const sharedData = { config: config.data };
    if (versionInfo.is_draft) {
      const postData = {
        ...sharedData,
        version_number: versionInfo.version_number,
        edit_version_number: versionInfo.edit_version_number,
      };
      const url = `report_builder/${reportBuilderId}/save_draft/`;
      requestConfig = createApiRequestConfig(url, 'POST', postData, false);
    } else {
      const url = `report_builder/${reportBuilderId}/create_new_version/`;
      requestConfig = createApiRequestConfig(url, 'POST', sharedData, false);
    }
  }

  return makeThunkRequest(
    requestConfig,
    'Error saving reportBuilder draft',
    undefined,
    (response) => handleSaveError(dispatch, response),
  );
});

const handleSaveError = (
  dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>,
  response: ErrorResponse,
) => {
  if (response.error_code !== ApiException.VERSION_OUT_OF_DATE) return;
  dispatch(setSaveError(response.error_msg ?? ''));
};
