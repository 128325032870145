import produce from 'immer';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { Switch } from 'components/ds';
import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import SettingHeader from '../../DataConfigTab/SettingHeader';

import { updateGeneralFormatOptions } from 'actions/dataPanelConfigActions';
import {
  SECTION_OPTIONS,
  TablePDFFormat,
  VisualizeOperationGeneralFormatOptions,
} from 'constants/types';
import { sharedStyles } from './styles';

const HEADER_SECTION_OPTIONS = [
  { name: '', id: SECTION_OPTIONS.BLANK },
  { name: 'Image', id: SECTION_OPTIONS.IMAGE },
  { name: 'Text', id: SECTION_OPTIONS.TEXT },
];

const useStyles = makeStyles((theme: Theme) => ({
  buttonGrouInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(1),
  },
}));

type Props = {
  generalFormatOptions: VisualizeOperationGeneralFormatOptions;
};

export const TablePDFConfig: FC<Props> = ({ generalFormatOptions }) => {
  const classes = useStyles();
  const sharedClasses = sharedStyles();
  const dispatch = useDispatch();

  const setPDFConfig = (update: (draft: TablePDFFormat) => void) => {
    const newOptions = produce(generalFormatOptions, (draft) => {
      if (!draft.export) draft.export = {};
      if (!draft.export.pdfFormat) draft.export.pdfFormat = {};
      update(draft.export.pdfFormat);
    });
    dispatch(updateGeneralFormatOptions(newOptions));
  };

  const pdfFormat = generalFormatOptions.export?.pdfFormat;
  return (
    <>
      <SettingHeader name="PDF Download" />
      <div className={sharedClasses.root}>
        <Switch
          useCustomStyles
          className={sharedClasses.configInput}
          label="Header"
          onChange={() =>
            setPDFConfig((draft) => {
              draft.headerEnabled = !draft.headerEnabled;
            })
          }
          switchOn={pdfFormat?.headerEnabled}
        />
        {pdfFormat?.headerEnabled && (
          <>
            <ToggleButtonGroup fillWidth className={classes.buttonGrouInput} label="Left">
              {HEADER_SECTION_OPTIONS.map((option) => (
                <ToggleButton
                  active={
                    pdfFormat?.leftOption
                      ? pdfFormat?.leftOption === option.id
                      : option.id === SECTION_OPTIONS.BLANK
                  }
                  key={`left_section_${option.id}`}
                  onClick={() =>
                    setPDFConfig((draft) => {
                      draft.leftOption = option.id;
                    })
                  }
                  text={option.name}
                />
              ))}
            </ToggleButtonGroup>
            {pdfFormat?.leftOption && pdfFormat?.leftOption !== SECTION_OPTIONS.BLANK && (
              <InputWithBlurSave
                containerClassName={sharedClasses.configInput}
                initialValue={pdfFormat?.leftContent || ''}
                onNewValueSubmitted={(newValue: string) => {
                  setPDFConfig((draft) => {
                    draft.leftContent = newValue;
                  });
                }}
              />
            )}

            <ToggleButtonGroup fillWidth className={classes.buttonGrouInput} label="Center">
              {HEADER_SECTION_OPTIONS.map((option) => (
                <ToggleButton
                  active={
                    pdfFormat?.centerOption
                      ? pdfFormat?.centerOption === option.id
                      : option.id === SECTION_OPTIONS.BLANK
                  }
                  key={`center_section_${option.id}`}
                  onClick={() =>
                    setPDFConfig((draft) => {
                      draft.centerOption = option.id;
                    })
                  }
                  text={option.name}
                />
              ))}
            </ToggleButtonGroup>
            {pdfFormat?.centerOption && pdfFormat?.centerOption !== SECTION_OPTIONS.BLANK && (
              <InputWithBlurSave
                containerClassName={sharedClasses.configInput}
                initialValue={pdfFormat?.centerContent || ''}
                onNewValueSubmitted={(newValue: string) => {
                  setPDFConfig((draft) => {
                    draft.centerContent = newValue;
                  });
                }}
              />
            )}

            <ToggleButtonGroup fillWidth className={classes.buttonGrouInput} label="Right">
              {HEADER_SECTION_OPTIONS.map((option) => (
                <ToggleButton
                  active={
                    pdfFormat?.rightOption
                      ? pdfFormat?.rightOption === option.id
                      : option.id === SECTION_OPTIONS.BLANK
                  }
                  key={`right_section_${option.id}`}
                  onClick={() =>
                    setPDFConfig((draft) => {
                      draft.rightOption = option.id;
                    })
                  }
                  text={option.name}
                />
              ))}
            </ToggleButtonGroup>
            {pdfFormat?.rightOption && pdfFormat?.rightOption !== SECTION_OPTIONS.BLANK && (
              <InputWithBlurSave
                containerClassName={sharedClasses.configInput}
                initialValue={pdfFormat?.rightContent || ''}
                onNewValueSubmitted={(newValue: string) => {
                  setPDFConfig((draft) => {
                    draft.rightContent = newValue;
                  });
                }}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};
