import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory } from 'react-router';

import OnboardingFlowPage from 'components/Onboarding/OnboardingFlowPage';
import Button from 'shared/Button';

import { logOutUser, resendEmailVerification } from 'actions/authAction';
import { ReduxState } from 'reducers/rootReducer';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ACTION } from 'actions/types';
import { showSuccessToast } from 'shared/sharedToasts';
import { useLocalStorage } from 'usehooks-ts';
import { EMAIL_LOCAL_STORAGE_KEY } from './SignInPage';
import { ROUTES } from 'constants/routes';

const useStyles = makeStyles((theme: Theme) => ({
  resendButton: {
    width: '100%',
    marginTop: theme.spacing(4),
  },
}));

const CheckYourEmailPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const emailVerificationResendLoading = useSelector((state: ReduxState) =>
    createLoadingSelector([ACTION.RESEND_EMAIL_VERIFICATION], false)(state),
  );

  const [email, setEmail] = useLocalStorage(EMAIL_LOCAL_STORAGE_KEY, '');

  if (email === '') {
    history.push(ROUTES.LOGIN);
  }

  return (
    <OnboardingFlowPage
      helpLinks={[
        {
          name: 'Log Out',
          onClick: () => {
            // we shouldn't actually be logged in on this page, but log out just for safety
            // and then make sure we navigate to the log in page
            dispatch(logOutUser());
            history.push(ROUTES.LOGIN);
          },
        },
        { name: 'Need Support?', url: 'https://docs.explo.co/' },
      ]}
      rightContentTitle={'Verify your email!'}
      rightPanelContent={
        <>
          <div>
            You have received an email from Explo asking you to verify your email. Click the link in
            the email to verify your email and access the platform.
          </div>
          <Button
            className={classes.resendButton}
            data-testid="send-verification-button"
            loading={emailVerificationResendLoading}
            onClick={() =>
              dispatch(
                resendEmailVerification({ postData: { email: email } }, () => {
                  showSuccessToast('New verification email sent!');
                  setEmail('');
                }),
              )
            }
            text="Resend Verification Email"
            type="primary"
          />
        </>
      }
    />
  );
};

export default CheckYourEmailPage;
