import { FC, useState } from 'react';
import cx from 'classnames';
import { Icon, sprinkles } from 'components/ds';
import * as styles from './styles.css';

import { SortableListItemDragHandle } from 'components/SortableList/SortableList';

type Props = {
  name: string;
};

export const SortableExpandableCard: FC<Props> = ({ children, name }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div className={styles.cardRoot}>
      <div
        className={cx(styles.cardHeader, { [styles.cardHeaderExpanded]: isExpanded })}
        onClick={() => setIsExpanded(!isExpanded)}>
        <SortableListItemDragHandle className={styles.dragIcon} />
        <div className={sprinkles({ flex: 1, truncateText: 'ellipsis' })}>
          <span className={styles.cardNameText}>{name}</span>
        </div>
        <Icon name={isExpanded ? 'caret-down' : 'caret-right'} size="md" />
      </div>
      {isExpanded && <div className={styles.cardBodyContainer}>{children}</div>}
    </div>
  );
};
