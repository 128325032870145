import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.ds.white,
    padding: `${theme.spacing(8)}px ${theme.spacing(6)}px`,
    borderRadius: 16,
    minWidth: 500,
  },
  title: {
    fontSize: 24,
    marginBottom: theme.spacing(3),
  },
  description: {
    width: 500,
    marginBottom: theme.spacing(6),
    color: theme.palette.ds.grey600,
  },
}));

type Props = {
  className?: string;
  title: string;
  description?: string;
  children: JSX.Element;
};

const FullPagePanel = ({ className, title, description, children }: Props) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.title}>{title}</div>
      <div className={classes.description}>{description}</div>
      {children}
    </div>
  );
};

export default FullPagePanel;
