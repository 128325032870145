import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import FlexBox, {
  FlexDirection,
  HorizontalAlignment,
  VerticalAlignment,
} from 'components/core/FlexBox';
import Button from 'shared/Button';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  titleText: {
    fontWeight: 600,
  },
  textInput: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(6),
  },
}));

type Props = {
  name?: string;
  onNext: () => void;
  updateReportName: (newName: string) => void;
};

export default function NameReportStep({ name, onNext, updateReportName }: Props) {
  const classes = useStyles();

  return (
    <FlexBox
      className={cx(classes.root, GLOBAL_STYLE_CLASSNAMES.container.fill.backgroundColor)}
      direction={FlexDirection.COLUMN}
      horizontalAlignment={HorizontalAlignment.CENTER}
      verticalAlignment={VerticalAlignment.CENTER}>
      <div className={cx(classes.titleText, GLOBAL_STYLE_CLASSNAMES.text.h2.base)}>
        Name your report
      </div>
      <InputWithBlurSave
        className={classes.textInput}
        initialValue={name}
        onNewValueSubmitted={updateReportName}
        placeholder="Report name"
      />
      <Button onClick={onNext} text="Next" type="primary" />
    </FlexBox>
  );
}
