import { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { withStyles, WithStyles } from '@material-ui/styles';
import { JoinedStates } from 'reducers/rootReducer';
import { ScrollToTop } from 'react-router-scroll-to-top';
import { enableMapSet } from 'immer';

import PrivateRoute from 'components/privateRoute';
import SignUpPage from 'pages/SignUpPage';
import SignInPage from 'pages/SignInPage';
import CheckYourEmailPage from 'pages/checkYourEmailPageV2';
import VerifyEmailPage from 'pages/verifyEmailPageV2';
import AnalyticsPage from 'pages/analyticsPage';
import HomeAppPage from 'pages/homeAppPage/homeAppPage';
import JoinTeamPage from 'pages/JoinTeamPage';
import SharedDashboardPage from 'pages/sharedDashboardPage';
import SharedChartPage from 'pages/SharedChartPage';
import PdfDashboardPage from 'pages/PdfDashboardPage';
import EmailDashboardPage from 'pages/EmailDashboardPage';
import NotionAnalyticsPage from 'pages/notionAnalyticsPage';
import TrialExpiredPage from 'pages/trialExpiredPage';
import OnboardingPage from 'pages/Onboarding/OnboardingPage';
import ConnectDataSourceFlow from 'pages/ConnectDataSourceFlow/ConnectDataSourceFlow';
import SyncDataTablesPage from 'pages/syncDataTablesPage';
import ManageDataTablesPage from 'pages/manageDataTablesPage/manageDataTablesPage';
import SettingsPage from 'pages/settingsPage/settingsPage';
import { DashboardPageWrapper } from 'pages/dashboardPage/dashboardPageWrapper';
import CustomersPage from 'pages/customersPage/customersPage';
import CustomizeStylesPage from 'pages/GlobalCustomStylesPage';
import ForgotPasswordPage from 'pages/ForgotPasswordPage';
import ResetPasswordPage from 'pages/ResetPasswordPage';
import IframeDashboardPage from 'pages/IframeDashboardPage';
import { DataPage } from 'pages/DataPage';
import { PortalBasePage } from 'pages/customerPortal/portalBasePage';
import TellUsAboutYourselfPage from 'pages/TellUsAboutYourselfPage';
import ArchitectPage from 'pages/architectPage';
import BlueprintPage from 'pages/BlueprintPage';
import IFrameArchitectCustomerDashboardPage from 'pages/IframeArchitectCustomerDashboardPage';
import { IframeReportBuilderPage } from 'pages/IframeReportBuilderPage';
import SuperuserPage from 'pages/SuperuserPage';
import { UnsubscribeArchitectEmailPage } from 'pages/UnsubscribeArchitectEmailPage';
import { UnsubscribeReportBuilderEmailPage } from 'pages/UnsubscribeReportBuilderEmailPage';
import { SharedArchitectCustomerDashboardPage } from 'pages/SharedArchitectCustomerDashboardPage';
import { ReportBuilderListPage } from 'pages/reportBuilderListPage';
import { ReportBuilderEditorPage } from 'pages/ReportBuilderEditor';

import { logOutUser } from './actions/authAction';
import { logInUserSuccess } from './actions/userActions';
import { fetchProfile } from './auth/userAuth';
import { NAV_ITEMS } from 'components/Sidebar/constants';
import { fetchGeoJson } from 'utils/geoJsonUtils';
import { DEFAULT_GLOBAL_STYLE_CONFIG, GlobalStylesContext } from 'globalStyles';
import { ONBOARDING_PAGE_TAB_ID } from 'constants/onboardingConstants';

import { ROUTES } from 'constants/routes';

import 'index.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/table/lib/css/table.css';
import 'react-datepicker/dist/react-datepicker.css';
import '@explo-tech/react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import 'funnel-graph-js/dist/css/theme.min.css';
import 'funnel-graph-js/dist/css/main.min.css';
import { GenericLoadingSpinner } from 'components/EmbeddedDashboard';
import ExploLoadingSpinner from 'components/ExploLoadingSpinner';
import {
  redirectIfInvalidUrl,
  pingCustomerOnlineMessage,
  pingUserWithoutTeamMessage,
} from 'utils/landingPageUtils';

import { isArchitectEnabledForPlan, isReportBuilderEnabled } from 'utils/paymentPlanUtils';
import { isExploSuperuser } from 'utils/superuserUtils';
import { PingTypes } from 'constants/types';
import { sendPing } from 'actions/pingActions';
import { canUserModifyResource, doesUserHavePermission } from 'utils/permissionUtils';
import { PERMISSIONED_ACTIONS, PERMISSIONED_ENTITIES } from 'constants/roleConstants';
import { setUser } from 'analytics/datadog';
import { PLAN_TYPES } from 'constants/paymentPlanConstants';

enableMapSet();

const styles = () => ({
  loadingRoot: {
    height: '100vh',
    perspective: '1px',
    overflowY: 'auto' as const,
  },
  loadingPage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
});

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithStyles<typeof styles>;

type State = {
  loading: boolean;
};

const isExternalFacingDashboard = () => {
  const isEmbedded = window.self !== window.top;
  const isShare = window.location.href.indexOf('/share/') > -1;
  const isPortal = window.location.href.indexOf('/portal') > -1;
  return isEmbedded || isShare || isPortal;
};

class Routes extends Component<Props, State> {
  state = {
    loading: true,
  };

  componentDidMount() {
    redirectIfInvalidUrl();

    fetchProfile(
      (user) => {
        this.props.logInUserSuccess(user);

        setUser({
          email: user.email,
          teamId: user.team?.id,
          teamName: user.team?.team_name,
        });

        this.props.sendPing({
          postData: {
            message: pingCustomerOnlineMessage(user),
            message_type:
              user.team?.payment_plan === PLAN_TYPES.LAUNCH
                ? PingTypes.PING_ONLINE_LAUNCH
                : PingTypes.PING_ONLINE,
          },
        });
        if (!user.team) {
          this.props.sendPing({
            postData: {
              message: pingUserWithoutTeamMessage(user),
              message_type: PingTypes.PING_USER_WITHOUT_TEAM,
            },
          });
        }

        this.setState({ loading: false });
      },
      () => {
        this.props.logOutUser();
        this.setState({ loading: false });
      },
    );

    // Fetching GeoJsons
    window.exploAssets = {};
    fetchGeoJson('worldGeoJson.json', 'worldGeoJson');
    fetchGeoJson('unitedStatesGeoJson.json', 'unitedStatesGeoJson');
    fetchGeoJson('worldGeoJsonMapping.json', 'worldGeoJsonMapping');
    fetchGeoJson('unitedStatesGeoJsonMapping.json', 'unitedStatesGeoJsonMapping');
  }

  render() {
    const { classes } = this.props;

    const renderLoadingComponent = () => (
      <div className={classes.loadingRoot}>
        <div className={classes.loadingPage}>
          {isExternalFacingDashboard() ? (
            <GenericLoadingSpinner embedType="embedded" />
          ) : (
            <ExploLoadingSpinner />
          )}
        </div>
      </div>
    );

    return this.state.loading ? (
      renderLoadingComponent()
    ) : (
      <GlobalStylesContext.Provider
        value={{
          globalStyleConfig: DEFAULT_GLOBAL_STYLE_CONFIG,
        }}>
        <Router>
          <ScrollToTop />
          <div className="explo-routes">
            <Switch>
              <Route
                exact
                component={() => <Redirect to={{ pathname: ROUTES.HOME_APP_PAGE }} />}
                path={ROUTES.HOME}
              />
              <Route exact component={VerifyEmailPage} path={ROUTES.VERIFY_EMAIL} />
              <Route
                exact
                component={UnsubscribeArchitectEmailPage}
                path={ROUTES.UNSUBSCRIBE_EMAIL}
              />
              <Route exact component={SignUpPage} path={ROUTES.SIGNUP} />
              <Route exact component={IframeDashboardPage} path={ROUTES.IFRAME} />
              <Route exact component={SharedDashboardPage} path={ROUTES.SHARED_DASHBOARD} />
              <Route exact component={SharedDashboardPage} path={ROUTES.SHARED_DASHBOARD_STRICT} />
              <Route
                exact
                component={IFrameArchitectCustomerDashboardPage}
                path={ROUTES.END_USER_DASHBOARD_IFRAME}
              />
              <Route
                exact
                component={SharedArchitectCustomerDashboardPage}
                path={ROUTES.END_USER_DASHBOARD_SHARED}
              />
              <Route
                exact
                component={IframeReportBuilderPage}
                path={ROUTES.REPORT_BUILDER_IFRAME}
              />
              <Route
                exact
                component={UnsubscribeReportBuilderEmailPage}
                path={ROUTES.UNSUBSCRIBE_REPORT_BUILDER_EMAIL}
              />
              <Route exact component={PortalBasePage} path={ROUTES.END_USER_PORTAL} />
              <Route exact component={SharedChartPage} path={ROUTES.SHARE_CHART} />
              <Route exact component={PdfDashboardPage} path={ROUTES.PDF_DASHBOARD} />
              <Route exact component={EmailDashboardPage} path={ROUTES.EMAIL_DASHBOARD} />
              <Route exact component={NotionAnalyticsPage} path={ROUTES.NOTION_ANALYTICS} />
              <Route exact component={ForgotPasswordPage} path={ROUTES.FORGOT_PASSWORD} />
              <Route exact component={ResetPasswordPage} path={ROUTES.RESET_PASSWORD} />
              <PrivateRoute exact pageComponent={SignInPage} path={ROUTES.LOGIN} />
              <PrivateRoute exact pageComponent={TrialExpiredPage} path={ROUTES.TRIAL_EXPIRED} />
              <PrivateRoute
                exact
                pageComponent={CheckYourEmailPage}
                path={ROUTES.CHECK_YOUR_EMAIL}
              />
              <PrivateRoute exact pageComponent={JoinTeamPage} path={ROUTES.JOIN_TEAM} />
              <PrivateRoute
                exact
                withNavigation
                activeTabId={NAV_ITEMS.DASHBOARDS.id}
                pageComponent={HomeAppPage}
                path={ROUTES.HOME_APP_PAGE}
              />
              <PrivateRoute
                exact
                withNavigation
                activeTabId={NAV_ITEMS.DASHBOARDS.id}
                pageComponent={DashboardPageWrapper}
                path={ROUTES.DASHBOARD_PAGE}
                permissionFn={(user) =>
                  doesUserHavePermission(
                    user.permissions[PERMISSIONED_ENTITIES.DASHBOARD],
                    PERMISSIONED_ACTIONS.READ,
                  )
                }
              />
              <PrivateRoute
                exact
                withNavigation
                activeTabId={NAV_ITEMS.CUSTOMERS.id}
                pageComponent={CustomersPage}
                path={ROUTES.CUSTOMERS_PAGE}
                permissionFn={(user) =>
                  doesUserHavePermission(
                    user.permissions[PERMISSIONED_ENTITIES.CUSTOMER],
                    PERMISSIONED_ACTIONS.READ,
                  )
                }
              />
              {/* TODO permissions */}
              <PrivateRoute
                exact
                withNavigation
                activeTabId={NAV_ITEMS.ANALYTICS.id}
                pageComponent={AnalyticsPage}
                path={ROUTES.ANALYTICS}
              />
              <PrivateRoute
                exact
                pageComponent={TellUsAboutYourselfPage}
                path={ROUTES.TELL_US_ABOUT_YOU}
              />
              <PrivateRoute
                exact
                withNavigation
                activeTabId={ONBOARDING_PAGE_TAB_ID}
                pageComponent={OnboardingPage}
                path={ROUTES.ONBOARDING}
              />
              <PrivateRoute
                exact
                withNavigation
                pageComponent={ConnectDataSourceFlow}
                path={ROUTES.CONNECT_DATA_SOURCE}
                permissionFn={(user) =>
                  doesUserHavePermission(
                    user.permissions[PERMISSIONED_ENTITIES.DATA_SOURCE],
                    PERMISSIONED_ACTIONS.CREATE,
                  )
                }
              />
              <PrivateRoute
                exact
                withNavigation
                activeTabId={NAV_ITEMS.DATA.id}
                pageComponent={SyncDataTablesPage}
                path={ROUTES.SYNC_DATA_TABLES}
                permissionFn={(user) =>
                  doesUserHavePermission(
                    user.permissions[PERMISSIONED_ENTITIES.DATA_SOURCE],
                    PERMISSIONED_ACTIONS.UPDATE,
                  )
                }
              />
              <PrivateRoute
                exact
                withNavigation
                activeTabId={NAV_ITEMS.DATA.id}
                pageComponent={SyncDataTablesPage}
                path={ROUTES.SYNC_DATA_TABLES_NO_SCHEMA}
                permissionFn={(user) =>
                  doesUserHavePermission(
                    user.permissions[PERMISSIONED_ENTITIES.DATA_SOURCE],
                    PERMISSIONED_ACTIONS.UPDATE,
                  )
                }
              />
              <PrivateRoute
                exact
                withNavigation
                activeTabId={NAV_ITEMS.DATA.id}
                pageComponent={DataPage}
                path={ROUTES.DATA_PAGE}
                permissionFn={(user) =>
                  doesUserHavePermission(
                    user.permissions[PERMISSIONED_ENTITIES.DATA_SOURCE],
                    PERMISSIONED_ACTIONS.READ,
                  )
                }
              />
              <PrivateRoute
                exact
                withNavigation
                activeTabId={NAV_ITEMS.DATA.id}
                pageComponent={ManageDataTablesPage}
                path={ROUTES.MANAGE_DATA_TABLES}
                permissionFn={(user) =>
                  doesUserHavePermission(
                    user.permissions[PERMISSIONED_ENTITIES.DATA_SOURCE],
                    PERMISSIONED_ACTIONS.READ,
                  )
                }
              />
              <PrivateRoute
                exact
                withNavigation
                pageComponent={SettingsPage}
                path={ROUTES.SETTINGS_PAGE}
              />
              <PrivateRoute
                exact
                withNavigation
                activeTabId={NAV_ITEMS.STYLES.id}
                pageComponent={CustomizeStylesPage}
                path={ROUTES.GLOBAL_CUSTOM_STYLES_PAGE}
                permissionFn={(user) =>
                  doesUserHavePermission(
                    user.permissions[PERMISSIONED_ENTITIES.TEAM],
                    PERMISSIONED_ACTIONS.UPDATE_CUSTOM_STYLES,
                  )
                }
              />
              {/* TODO permissions */}
              <PrivateRoute
                exact
                withNavigation
                activeTabId={NAV_ITEMS.ARCHITECT.id}
                pageComponent={ArchitectPage}
                path={ROUTES.ARCHITECT}
                permissionFn={(user) => isArchitectEnabledForPlan(user)}
              />
              <PrivateRoute
                withNavigation
                activeTabId={NAV_ITEMS.ARCHITECT.id}
                pageComponent={BlueprintPage}
                path={ROUTES.BLUEPRINT_PAGE}
                permissionFn={(user) => isArchitectEnabledForPlan(user)}
              />
              <PrivateRoute
                exact
                withNavigation
                activeTabId={NAV_ITEMS.REPORT_BUILDER.id}
                pageComponent={ReportBuilderListPage}
                path={ROUTES.REPORT_BUILDER}
                permissionFn={(user) => isReportBuilderEnabled(user)}
              />
              <PrivateRoute
                exact
                withNavigation
                activeTabId={NAV_ITEMS.REPORT_BUILDER.id}
                pageComponent={ReportBuilderEditorPage}
                path={ROUTES.REPORT_BUILDER_EDITOR}
                permissionFn={(user) =>
                  isReportBuilderEnabled(user) &&
                  canUserModifyResource(user.permissions[PERMISSIONED_ENTITIES.REPORT_BUILDER])
                }
              />
              <PrivateRoute
                exact
                withNavigation
                activeTabId={NAV_ITEMS.SUPERUSER.id}
                pageComponent={SuperuserPage}
                path={ROUTES.SUPERUSER}
                permissionFn={(user) => isExploSuperuser(user)}
              />
              <PrivateRoute noMatch pageComponent={HomeAppPage} />
            </Switch>
          </div>
        </Router>
      </GlobalStylesContext.Provider>
    );
  }
}

const mapStateToProps = (state: JoinedStates) => ({
  currentUser: 'currentUser' in state ? state.currentUser : undefined,
});

const mapDispatchToProps = {
  logInUserSuccess,
  logOutUser,
  sendPing,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Routes));
