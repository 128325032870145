import {
  AggedChartColumnInfo,
  V2KPITrendInstructions,
  V2TrendTableInstructions,
} from 'constants/types';
import { DateTime } from 'luxon';
import { DashboardVariableMap } from 'types/dashboardTypes';
import {
  PeriodComparisonRangeTypes,
  PeriodRangeTypes,
  TrendGroupingOptions,
} from 'types/dateRangeTypes';
import { aggReady } from 'utils/dataPanelConfigUtils';
import { subtractMonths, subtractYear } from 'utils/dateUtils';
import { formatTime, TIME_FORMATS } from 'utils/localizationUtils';
import { some } from 'utils/standard';

export const getPctChange = (base: number, comparison: number) => {
  let change = 0;
  if (base === comparison) {
    change = 0;
  } else if (base === 0) {
    change = -1;
  } else if (comparison === 0) {
    change = 1;
  } else {
    change = base / comparison - 1;
  }

  return change;
};

const enumerateDatesByGroup = (
  startDate: DateTime,
  endDate: DateTime,
  datesList: DateTime[],
  trendGrouping: TrendGroupingOptions,
) => {
  // comparing to 1 here because the times aren't always at start of day and diff can go into decimals
  if ((endDate.diff(startDate, 'days').toObject().days ?? 0) < 1) {
    datesList.push(endDate);
    return;
  }

  datesList.push(startDate);

  switch (trendGrouping) {
    case TrendGroupingOptions.HOURLY:
      startDate = startDate.plus({ hour: 1 });
      break;
    case TrendGroupingOptions.DAILY:
      startDate = startDate.plus({ day: 1 });
      break;
    case TrendGroupingOptions.WEEKLY:
      startDate = startDate.plus({ week: 1 });
      break;
    case TrendGroupingOptions.MONTHLY:
      startDate = startDate.plus({ month: 1 });
      break;
    case TrendGroupingOptions.YEARLY:
      startDate = startDate.plus({ year: 1 });
      break;
  }
  enumerateDatesByGroup(startDate, endDate, datesList, trendGrouping);
};

export const getPeriodDates = (
  periodRange: PeriodRangeTypes,
  trendGrouping: TrendGroupingOptions,
  customStartDate: DateTime,
  customEndDate: DateTime,
  periodRangeOffset: number,
) => {
  const periodEndDate = getPeriodEndDate(
    periodRange,
    trendGrouping,
    customEndDate,
    periodRangeOffset,
  );
  const periodStartDate = getPeriodStartDate(
    periodRange,
    trendGrouping,
    customStartDate,
    periodEndDate,
  );

  const periodDates: DateTime[] = [];

  enumerateDatesByGroup(periodStartDate, periodEndDate, periodDates, trendGrouping);

  return {
    periodStartDate,
    periodEndDate,
    periodDates,
  };
};

const getPeriodEndDate = (
  periodRange: PeriodRangeTypes,
  trendGrouping: TrendGroupingOptions,
  customEndDate: DateTime,
  periodRangeOffset: number,
) => {
  let periodEndDate = DateTime.local().minus({ day: periodRangeOffset });

  if (
    periodRange === PeriodRangeTypes.CUSTOM_RANGE ||
    periodRange === PeriodRangeTypes.DATE_RANGE_INPUT
  ) {
    periodEndDate = customEndDate;
  } else if (periodRange === PeriodRangeTypes.TIME_PERIOD_DROPDOWN) {
    periodEndDate = customEndDate;
  }
  return getEntryStartDateFromGrouping(periodEndDate, trendGrouping);
};

const getPeriodStartDate = (
  periodRange: PeriodRangeTypes,
  trendGrouping: TrendGroupingOptions,
  customStartDate: DateTime,
  periodEndDate: DateTime,
) => {
  let periodStartDate = periodEndDate;

  switch (periodRange) {
    case PeriodRangeTypes.CUSTOM_RANGE:
    case PeriodRangeTypes.DATE_RANGE_INPUT:
      periodStartDate = customStartDate;
      break;
    case PeriodRangeTypes.TIME_PERIOD_DROPDOWN:
      periodStartDate = customStartDate;
      break;
    // The start date and end date for TODAY are the same
    case PeriodRangeTypes.TODAY:
      break;
    case PeriodRangeTypes.LAST_7_DAYS:
      periodStartDate = periodEndDate.minus({ days: 6 });
      break;
    case PeriodRangeTypes.LAST_4_WEEKS:
      // When the dates are in days, we need to subtract the full 4 weeks
      if (trendGrouping === TrendGroupingOptions.DAILY) {
        periodStartDate = periodEndDate.minus({ weeks: 4 });
      } else {
        periodStartDate = periodEndDate.minus({ weeks: 3 });
      }
      break;
    case PeriodRangeTypes.LAST_3_MONTHS:
      periodStartDate = subtractMonths(periodEndDate, 2);
      break;
    case PeriodRangeTypes.LAST_12_MONTHS:
      periodStartDate = subtractMonths(periodEndDate, 11);
      break;
    case PeriodRangeTypes.MONTH_TO_DATE:
      periodStartDate = getEntryStartDateFromGrouping(
        periodEndDate.startOf('month'),
        trendGrouping,
      );
      break;
    case PeriodRangeTypes.YEAR_TO_DATE:
      periodStartDate = getEntryStartDateFromGrouping(periodEndDate.startOf('year'), trendGrouping);
      break;
  }

  return getEntryStartDateFromGrouping(periodStartDate, trendGrouping);
};

export const getComparisonDates = (
  startDate: DateTime,
  endDate: DateTime,
  numIntervals: number,
  comparisonRange: PeriodComparisonRangeTypes,
  periodRange: PeriodRangeTypes,
  trendGrouping: TrendGroupingOptions,
) => {
  const comparisonDates: DateTime[] = [];
  let comparisonStartDate;
  let comparisonEndDate;

  switch (comparisonRange) {
    case PeriodComparisonRangeTypes.PREVIOUS_PERIOD:
      comparisonStartDate = shiftDateByGrouping(startDate, -numIntervals, trendGrouping);
      comparisonEndDate = shiftDateByGrouping(startDate, -1, trendGrouping);
      break;
    case PeriodComparisonRangeTypes.PREVIOUS_MONTH:
      switch (periodRange) {
        case PeriodRangeTypes.TODAY:
        case PeriodRangeTypes.CUSTOM_RANGE:
        case PeriodRangeTypes.DATE_RANGE_INPUT:
        case PeriodRangeTypes.TIME_PERIOD_DROPDOWN:
        case PeriodRangeTypes.LAST_7_DAYS:
          comparisonStartDate = getEntryStartDateFromGrouping(
            subtractMonths(startDate, 1),
            trendGrouping,
          );
          comparisonEndDate = getEntryStartDateFromGrouping(
            subtractMonths(endDate, 1),
            trendGrouping,
          );
          break;
        case PeriodRangeTypes.LAST_4_WEEKS:
          comparisonStartDate = shiftDateByGrouping(startDate, -numIntervals, trendGrouping);
          comparisonEndDate = shiftDateByGrouping(endDate, -numIntervals, trendGrouping);
          break;
        case PeriodRangeTypes.LAST_3_MONTHS:
        case PeriodRangeTypes.LAST_12_MONTHS:
          comparisonStartDate = shiftDateByGrouping(
            startDate,
            -numGroupsInMonth(trendGrouping),
            trendGrouping,
          );
          comparisonEndDate = shiftDateByGrouping(
            endDate,
            -numGroupsInMonth(trendGrouping),
            trendGrouping,
          );
          break;
        case PeriodRangeTypes.MONTH_TO_DATE:
          comparisonStartDate = getEntryStartDateFromGrouping(
            subtractMonths(endDate, 1).startOf('month'),
            trendGrouping,
          );
          comparisonEndDate = shiftDateByGrouping(
            comparisonStartDate,
            numIntervals - 1,
            trendGrouping,
          );
          break;
        case PeriodRangeTypes.YEAR_TO_DATE:
          comparisonStartDate = shiftDateByGrouping(
            startDate,
            -numGroupsInMonth(trendGrouping),
            trendGrouping,
          );
          comparisonEndDate = shiftDateByGrouping(
            endDate,
            -numGroupsInMonth(trendGrouping),
            trendGrouping,
          );
          break;
      }
      break;
    case PeriodComparisonRangeTypes.PREVIOUS_YEAR:
      switch (periodRange) {
        case PeriodRangeTypes.TODAY:
        case PeriodRangeTypes.LAST_7_DAYS:
        case PeriodRangeTypes.CUSTOM_RANGE:
        case PeriodRangeTypes.DATE_RANGE_INPUT:
        case PeriodRangeTypes.TIME_PERIOD_DROPDOWN:
          comparisonStartDate = getEntryStartDateFromGrouping(
            subtractYear(startDate),
            trendGrouping,
          );
          comparisonEndDate = getEntryStartDateFromGrouping(subtractYear(endDate), trendGrouping);
          break;
        case PeriodRangeTypes.LAST_4_WEEKS:
        case PeriodRangeTypes.LAST_3_MONTHS:
        case PeriodRangeTypes.LAST_12_MONTHS:
          comparisonStartDate = shiftDateByGrouping(
            startDate,
            -numGroupsInYear(trendGrouping),
            trendGrouping,
          );
          comparisonEndDate = shiftDateByGrouping(
            endDate,
            -numGroupsInYear(trendGrouping),
            trendGrouping,
          );
          break;
        case PeriodRangeTypes.MONTH_TO_DATE:
          comparisonStartDate = getEntryStartDateFromGrouping(
            subtractYear(endDate).startOf('month'),
            trendGrouping,
          );
          comparisonEndDate = shiftDateByGrouping(
            comparisonStartDate,
            numIntervals - 1,
            trendGrouping,
          );
          break;
        case PeriodRangeTypes.YEAR_TO_DATE:
          comparisonStartDate = getEntryStartDateFromGrouping(
            subtractYear(endDate).startOf('year'),
            trendGrouping,
          );
          comparisonEndDate = shiftDateByGrouping(
            comparisonStartDate,
            numIntervals - 1,
            trendGrouping,
          );
          break;
      }
      break;
    case PeriodComparisonRangeTypes.NO_COMPARISON:
      return [];
  }

  enumerateDatesByGroup(comparisonStartDate, comparisonEndDate, comparisonDates, trendGrouping);

  return comparisonDates;
};

const shiftDateByGrouping = (
  date: DateTime,
  shift: number,
  trendGrouping: TrendGroupingOptions,
) => {
  switch (trendGrouping) {
    case TrendGroupingOptions.HOURLY:
      return date.plus({ hours: shift });
    case TrendGroupingOptions.DAILY:
      return date.plus({ days: shift });
    case TrendGroupingOptions.WEEKLY:
      return date.plus({ weeks: shift });
    case TrendGroupingOptions.MONTHLY:
      return date.plus({ months: shift });
    case TrendGroupingOptions.YEARLY:
      return date.plus({ years: shift });
    default:
      return date;
  }
};

const getEntryStartDateFromGrouping = (date: DateTime, trendGrouping: TrendGroupingOptions) => {
  switch (trendGrouping) {
    case TrendGroupingOptions.HOURLY:
    case TrendGroupingOptions.DAILY:
      return date;
    case TrendGroupingOptions.WEEKLY:
      return date.startOf('week');
    case TrendGroupingOptions.MONTHLY:
      return date.startOf('month');
    case TrendGroupingOptions.YEARLY:
      return date.startOf('year');
    default:
      return date;
  }
};

const numGroupsInMonth = (trendGrouping: TrendGroupingOptions) => {
  switch (trendGrouping) {
    case TrendGroupingOptions.HOURLY:
      return 30 * 24;
    case TrendGroupingOptions.DAILY:
      return 30;
    case TrendGroupingOptions.WEEKLY:
      return 4;
    case TrendGroupingOptions.MONTHLY:
      return 1;
    case TrendGroupingOptions.YEARLY:
      return 1;
    default:
      return 1;
  }
};

const numGroupsInYear = (trendGrouping: TrendGroupingOptions) => {
  switch (trendGrouping) {
    case TrendGroupingOptions.HOURLY:
      return 365 * 24;
    case TrendGroupingOptions.DAILY:
      return 365;
    case TrendGroupingOptions.WEEKLY:
      return 52;
    case TrendGroupingOptions.MONTHLY:
      return 12;
    case TrendGroupingOptions.YEARLY:
      return 1;
    default:
      return 1;
  }
};

export const formatDateRange = (
  date: DateTime,
  trendGrouping: TrendGroupingOptions,
  showSingleDate?: boolean,
  isEndDate?: boolean,
  isTrendGrid?: boolean,
) => {
  const localizedDateFormat = isTrendGrid ? TIME_FORMATS['MMMM D, YYYY'] : TIME_FORMATS['MMM D'];
  switch (trendGrouping) {
    case TrendGroupingOptions.HOURLY: {
      const dayFormat = isTrendGrid
        ? TIME_FORMATS['MM/DD/YYYY HH:00 aa']
        : TIME_FORMATS['HH:00 M/D'];
      return formatTime(date, dayFormat);
    }
    case TrendGroupingOptions.DAILY:
      return formatTime(date, localizedDateFormat);
    case TrendGroupingOptions.WEEKLY: {
      const endDate = date.plus({ days: 6 });
      if (showSingleDate) {
        if (isEndDate) {
          return formatTime(endDate, localizedDateFormat);
        } else {
          return formatTime(date, localizedDateFormat);
        }
      } else {
        return `${formatTime(date, localizedDateFormat)} - ${formatTime(
          endDate,
          localizedDateFormat,
        )}`;
      }
    }
    case TrendGroupingOptions.MONTHLY:
      return formatTime(date, TIME_FORMATS['MMM YYYY']);
    case TrendGroupingOptions.YEARLY:
      return formatTime(date, TIME_FORMATS['YYYY']);
  }
};

export const areRequiredVariablesSet = (
  variables: DashboardVariableMap,
  instructions?: V2KPITrendInstructions | V2TrendTableInstructions,
) => {
  if (instructions?.periodColumn?.periodRange === PeriodRangeTypes.DATE_RANGE_INPUT) {
    const rangeVarId = instructions?.periodColumn?.rangeElemId;

    if (!rangeVarId) return true;

    const rangeVariable = variables[rangeVarId] as {
      startDate: DateTime;
      endDate: DateTime;
    };

    return !!(rangeVariable?.startDate && rangeVariable?.endDate);
  } else if (instructions?.periodColumn?.periodRange === PeriodRangeTypes.TIME_PERIOD_DROPDOWN) {
    const timeVarId = instructions?.periodColumn?.timePeriodElemId;

    if (!timeVarId) return true;

    const rangeVariable = variables[timeVarId] as number;

    return !!rangeVariable;
  } else {
    return true;
  }
};

export const instructionsReadyToDisplay = (
  instructions?: V2KPITrendInstructions | V2TrendTableInstructions,
  aggregations?: AggedChartColumnInfo[],
) => {
  const customRangeValid =
    instructions?.periodColumn?.periodRange !== PeriodRangeTypes.CUSTOM_RANGE ||
    (instructions.periodColumn.customEndDate && instructions.periodColumn.customStartDate);
  const rangeInputValue =
    instructions?.periodColumn?.periodRange !== PeriodRangeTypes.DATE_RANGE_INPUT ||
    instructions.periodColumn.rangeElemId;

  const timePeriodValue =
    instructions?.periodColumn?.periodRange !== PeriodRangeTypes.TIME_PERIOD_DROPDOWN ||
    instructions.periodColumn.timePeriodElemId;

  return !!(
    instructions &&
    aggregations &&
    some(aggregations.map(aggReady)) &&
    instructions.periodColumn?.column &&
    customRangeValid &&
    rangeInputValue &&
    timePeriodValue
  );
};
