import { FC } from 'react';

import * as styles from '../styles.css';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import { Switch } from 'components/ds';

import {
  DASHBOARD_ELEMENT_TYPES,
  SelectElemConfig,
  SELECT_FILTER_TYPE,
} from 'types/dashboardTypes';
import { getUpdateConfigFunc } from '../utils';

type Props = {
  config: SelectElemConfig;
  selectType: SELECT_FILTER_TYPE;

  updateSelectConfig: (config: SelectElemConfig) => void;
};

export const SelectAdjustDisplayConfig: FC<Props> = ({
  config,
  selectType,
  updateSelectConfig,
}) => {
  const isToggle = selectType === DASHBOARD_ELEMENT_TYPES.TOGGLE;
  const updateConfig = getUpdateConfigFunc(config, updateSelectConfig);

  return (
    <>
      <InputWithBlurSave
        ignoreCustomStyles
        containerClassName={styles.configInput}
        initialValue={config.label}
        label="Label"
        onNewValueSubmitted={(newValue) => updateConfig((draft) => (draft.label = newValue))}
        placeholder="Enter a label"
      />
      <Switch
        className={styles.configInput}
        label="Descriptive Tooltip"
        onChange={() => updateConfig((draft) => (draft.showTooltip = !draft.showTooltip))}
        switchOn={config.showTooltip}
      />
      {config.showTooltip ? (
        <InputWithBlurSave
          containerClassName={styles.configInput}
          initialValue={config.infoTooltipText}
          onNewValueSubmitted={(newValue: string) =>
            updateConfig((draft) => {
              draft.infoTooltipText = newValue;
            })
          }
        />
      ) : null}

      {!isToggle ? (
        <InputWithBlurSave
          ignoreCustomStyles
          containerClassName={styles.configInput}
          initialValue={config.placeholderText}
          label="Dropdown Placeholder"
          onNewValueSubmitted={(newValue) =>
            updateConfig(
              (draft) => (draft.placeholderText = newValue.trim() === '' ? undefined : newValue),
            )
          }
          placeholder="Enter a placeholder"
        />
      ) : null}
    </>
  );
};
