import { Component } from 'react';
import { isEqual } from 'utils/standard';
import cx from 'classnames';
import { formatTime, TIME_FORMATS } from 'utils/localizationUtils';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@blueprintjs/core';

import DatePickerInput from 'shared/DatePickerInput';
import DropdownSelect from 'shared/DropdownSelect';

import { DashboardVariable, DateRangePickerElemConfig } from 'types/dashboardTypes';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { DEFAULT_DATE_RANGES, DEFAULT_DATE_RANGES_DISPLAY_OVERWRITES } from 'types/dateRangeTypes';
import { getDefaultRangeValues } from 'utils/dateUtils';
import { dateTimeFromISOString } from 'utils/dateUtils';
import { DateTime } from 'luxon';
import { REPORTED_ANALYTIC_ACTION_TYPES, SelectedDropdownInputItem } from 'constants/types';
import { sortBy } from 'utils/standard';
import { AnalyticsEventTracker } from 'utils/analyticsUtils';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    rangeDropdown: {
      flex: '1 1 auto',
      maxWidth: 150,

      '&:not(.full-width) .bp3-button': {
        borderTopRightRadius: `0 !important`,
        borderBottomRightRadius: `0 !important`,
      },

      '&.full-width': {
        maxWidth: 'initial',
      },
    },
    datePickerBtn: {
      height: 32,

      '&.bp3-button': {
        display: 'block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        // To match blueprint input
        backgroundColor: theme.palette.ds.white,
        borderRadius: 3,

        '&:not(.disabled):hover': {
          backgroundColor: theme.palette.ds.white,
        },
      },
    },

    datePicker: {
      flex: '1 1 auto',
      minWidth: 0,
    },
    datePickerBtnWithDropdown: {
      '&.bp3-button': {
        borderTopLeftRadius: '0 !important',
        borderBottomLeftRadius: '0 !important',
        borderLeft: '0 !important',
      },
    },
    datePickerBtnWithCancelBtn: {
      '&.bp3-button': {
        paddingRight: 25,
      },
    },
  });

type PassedProps = {
  analyticsEventTracker?: AnalyticsEventTracker;
  className?: string;
  config: DateRangePickerElemConfig;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  onNewValueSelect: (newValue: DashboardVariable) => void;
  disabled?: boolean;
  withPortal?: boolean;
  noLabel?: boolean;
  noDropdown?: boolean;
  portalId?: string;
  openElementToLeft?: boolean;
  timezone: string;
};

type Props = PassedProps & WithStyles<typeof styles>;

const MONTHS_SHOWN = 2;

type State = {
  currentValue?: { startDate?: string | DateTime; endDate?: string | DateTime };
  isDatePickerOpen: boolean;
  selectedDateRange?: DEFAULT_DATE_RANGES;
};

class DashboardDateRangePickerElement extends Component<Props, State> {
  state: State = {
    currentValue: this.props.value,
    isDatePickerOpen: false,
  };

  componentDidMount() {
    if (!this.props.value) return;
    const { startDate, endDate } = this.props.value;
    if (!startDate || !endDate || this.props.noDropdown) return;

    const startDateParsed = typeof startDate === 'string' ? DateTime.fromISO(startDate) : startDate;
    const endDateParsed = typeof endDate === 'string' ? DateTime.fromISO(endDate) : endDate;

    const preselectedRange = this.getPreselectedRange(startDateParsed, endDateParsed);

    this.setState({ selectedDateRange: preselectedRange });
  }

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.value, this.props.value)) {
      this.setState({
        currentValue: this.props.value,
      });
    }
  }

  getPreselectedRange = (startDate?: DateTime, endDate?: DateTime) => {
    const { config, timezone } = this.props;

    if (!startDate || !endDate) return;

    const endDateTrunc = +endDate.startOf('day');
    return Object.values(DEFAULT_DATE_RANGES).find((range) => {
      if (config.hiddenDefaultRanges?.includes(range)) return false;
      const { startDate: rangeStart, endDate: rangeEnd } = getDefaultRangeValues(
        range,
        config.endDateEndOfDay,
        timezone,
      );

      return +rangeStart === +startDate && +rangeEnd.startOf('day') === endDateTrunc;
    });
  };

  renderDropdown() {
    const { analyticsEventTracker, classes, config, onNewValueSelect, disabled, timezone } =
      this.props;
    const { selectedDateRange } = this.state;

    const options: SelectedDropdownInputItem[] = [];
    const numDefaultRanges = Object.keys(DEFAULT_DATE_RANGES).length;

    const orderedRangeValues = sortBy(DEFAULT_DATE_RANGES, (range) => {
      const index = config.defaultRangesOrder?.indexOf(range);

      if (index === undefined) return undefined;
      if (index === -1) return numDefaultRanges;
      return index;
    });

    orderedRangeValues.forEach((range) => {
      if (!config.hiddenDefaultRanges?.includes(range)) {
        options.push({ id: range, name: DEFAULT_DATE_RANGES_DISPLAY_OVERWRITES[range] ?? range });
      }
    });

    return (
      <DropdownSelect
        fillWidth
        minimal
        showIcon
        containerClassName={cx(classes.rangeDropdown, {
          'full-width': !!config.excludeDatePicker,
        })}
        disabled={disabled}
        filterable={false}
        label={config.label}
        labelHelpText={config.showTooltip ? config.infoTooltipText : undefined}
        noSelectionText="Select a range"
        onCancelClick={() => {
          this.setState({ currentValue: undefined, selectedDateRange: undefined });
          onNewValueSelect(undefined);
          analyticsEventTracker?.(REPORTED_ANALYTIC_ACTION_TYPES.DATEPICKER_SELECTED);
        }}
        onChange={(newValue) => {
          // eslint-disable-next-line prefer-const
          let { startDate: newStart, endDate: newEnd } = getDefaultRangeValues(
            newValue.id as DEFAULT_DATE_RANGES,
            config.endDateEndOfDay,
            timezone,
          );

          if (config.endDateEndOfDay) newEnd = newEnd.endOf('day');

          this.setState({
            currentValue: { startDate: newStart, endDate: newEnd },
            selectedDateRange: newValue.id as DEFAULT_DATE_RANGES,
          });

          onNewValueSelect({ startDate: newStart, endDate: newEnd });
          analyticsEventTracker?.(REPORTED_ANALYTIC_ACTION_TYPES.DATEPICKER_SELECTED);
        }}
        options={options}
        selectedItem={
          selectedDateRange
            ? {
                id: selectedDateRange,
                name:
                  DEFAULT_DATE_RANGES_DISPLAY_OVERWRITES[selectedDateRange] ?? selectedDateRange,
              }
            : undefined
        }
        showCancelBtn={!config.disableCancel && config.excludeDatePicker}
        useFakeLabel={config.label === ''}
        usePortal={config.usePortal}
      />
    );
  }

  render() {
    const {
      classes,
      className,
      config,
      value,
      onNewValueSelect,
      disabled,
      withPortal,
      noDropdown,
      noLabel,
      portalId,
      openElementToLeft,
    } = this.props;

    const startDate = this.state.currentValue?.startDate;
    const endDate = this.state.currentValue?.endDate;

    const startDateParsed =
      typeof startDate === 'string' ? dateTimeFromISOString(startDate) : startDate;
    const endDateParsed = typeof endDate === 'string' ? dateTimeFromISOString(endDate) : endDate;

    const labelProps = noLabel
      ? undefined
      : {
          text: config.label,
          helpText: config.showTooltip ? config.infoTooltipText : undefined,
          fakeLabel: config.includeRangeDropdown,
        };

    return (
      <div className={cx(classes.root, className)}>
        {config.includeRangeDropdown && !noDropdown ? this.renderDropdown() : null}
        {!config.excludeDatePicker ? (
          <DatePickerInput
            selectsRange
            className={classes.datePicker}
            customInput={(onClick, ref) => (
              <Button
                fill
                className={cx(
                  classes.datePickerBtn,
                  { disabled },
                  { [classes.datePickerBtnWithCancelBtn]: !config.disableCancel },
                  { [classes.datePickerBtnWithDropdown]: config.includeRangeDropdown },
                  {
                    [GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.buttonBorderActive]:
                      this.state.isDatePickerOpen,
                  },
                  GLOBAL_STYLE_CLASSNAMES.container.outline.buttonBorder,
                  GLOBAL_STYLE_CLASSNAMES.container.shadow.buttonShadow,
                  GLOBAL_STYLE_CLASSNAMES.text.body.button.primaryFont,
                  GLOBAL_STYLE_CLASSNAMES.base.actionColor.interactionStates.buttonBorderHover,
                  GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.inputFields.defaultBorderRadius,
                )}
                disabled={disabled}
                onClick={onClick}
                ref={ref}
                text={renderInputButtonText(startDateParsed?.toLocal(), endDateParsed?.toLocal())}
              />
            )}
            disabled={disabled}
            endDate={endDateParsed?.toLocal()}
            labelProps={labelProps}
            monthsShown={MONTHS_SHOWN}
            onCalendarClose={() => {
              const readyToCompute = !!(startDateParsed && endDateParsed);

              if (!readyToCompute) this.setState({ currentValue: value });

              this.setState({ isDatePickerOpen: false });
            }}
            onCalendarOpen={() => this.setState({ isDatePickerOpen: true })}
            onCancelClick={() => {
              this.setState({ currentValue: undefined, selectedDateRange: undefined });
              onNewValueSelect(undefined);
            }}
            onNewValueSelect={(date) => {
              // eslint-disable-next-line prefer-const
              let [startDate, endDate] = date as [DateTime, DateTime | undefined];
              if (endDate && config.endDateEndOfDay) endDate = endDate.endOf('day');

              this.setState({
                currentValue: { startDate: startDate, endDate: endDate },
                selectedDateRange: undefined,
              });

              if (startDate && endDate) {
                onNewValueSelect({ startDate: startDate.toUTC(), endDate: endDate.toUTC() });
              }
            }}
            openElementToLeft={openElementToLeft}
            portalId={portalId}
            selectedValue={startDateParsed?.toLocal()}
            showCancelBtn={!config.disableCancel}
            startDate={startDateParsed?.toLocal()}
            withPortal={!portalId ? withPortal : undefined}
          />
        ) : undefined}
      </div>
    );
  }
}

const renderInputButtonText = (startDate?: DateTime, endDate?: DateTime) => {
  // We show the dates in the date picker in the default timezone but store them in the `variables` in
  // UTC. Similar to the `DatePickerInput`, we need to convert to the default timezone when displayed
  const currentDate = DateTime.local();

  const startDateInYear = startDate?.hasSame(currentDate, 'year');
  const startFormat = startDateInYear ? TIME_FORMATS['MMM D'] : TIME_FORMATS['MMM D, YYYY'];

  const endDateInYear = endDate?.hasSame(currentDate, 'year');
  const endFormat = endDateInYear ? TIME_FORMATS['MMM D'] : TIME_FORMATS['MMM D, YYYY'];

  if (startDate && endDate) {
    return `${formatTime(startDate, startFormat)}—${formatTime(endDate, endFormat)}`;
  } else if (startDate && !endDate) {
    return `${formatTime(startDate, startFormat)}—`;
  }

  return '';
};

export default withStyles(styles)(DashboardDateRangePickerElement);
