import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core';
import { Button, Classes, Dialog, Intent } from '@blueprintjs/core';

import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import plaintext from 'react-syntax-highlighter/dist/esm/languages/hljs/plaintext';
import javascript from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import style from 'react-syntax-highlighter/dist/esm/styles/hljs/atom-one-dark';

import { showSuccessToast } from 'shared/sharedToasts';
import { ResourcePageType } from 'types/exploResource';

SyntaxHighlighter.registerLanguage('plaintext', plaintext);
SyntaxHighlighter.registerLanguage('javascript', javascript);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 500,
    width: '60vw',
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  headerText: {
    fontWeight: 'bold',
    fontSize: 18,
    marginBottom: theme.spacing(2),
  },
  bodyText: {
    marginBottom: theme.spacing(4),
  },
  iframeBodyText: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  underCodeBlock: {
    marginTop: theme.spacing(5),
  },
  codeBlock: {
    cursor: 'pointer',
  },
}));

type Props = {
  closeModal: () => void;
  embedId: string;
  pageType: ResourcePageType;
  modalOpen: boolean;
};

export default function ResourceEmbedModal({
  closeModal,
  embedId,
  pageType,
  modalOpen,
}: Props): JSX.Element | null {
  const classes = useStyles();
  if (!modalOpen) return null;

  const embed_asset_url = process.env.REACT_APP_EMBED_ASSET_URL
    ? `${process.env.REACT_APP_EMBED_ASSET_URL}bundle.js`
    : 'https://embed.explo.co/bundle.js';
  const importInstructionsCode = `<script src="${embed_asset_url}"></script>`;

  const copyToClipboard = (apiToken: string) => {
    navigator.clipboard.writeText(apiToken);
    showSuccessToast('Copied To Clipboard');
  };

  const renderEmbedCode = () => {
    const embedCode =
      pageType === ResourcePageType.ARCHITECT
        ? `<explo-dashboard-builder\n  blueprint-token="${embedId}:CUSTOMER_TOKEN"\n  update-url-params={true}\n  is-production={true}\n  environment="production"\n  refresh-minutes={10}\n  variables={JSON.stringify({\n    element1: 'value',\n    element2: 'value2',\n  })}\n/>\n`
        : pageType === ResourcePageType.EXPLORE
        ? `<explo-dashboard\n  dash-customer-token="${embedId}:CUSTOMER_TOKEN"\n  updateUrlParams={true}\n  isProduction={true}\n  environment="production"\n  refresh-minutes={10}\n  variables={JSON.stringify({\n    element1: 'value',\n    element2: 'value2',\n  })}\n/>\n`
        : `<explo-report-builder\n report-builder-token="${embedId}:CUSTOMER_TOKEN"\n environment="production"\n/>\n`;

    return renderCodeBlock(embedCode);
  };

  const renderIframeCode = () => {
    const embedCode =
      pageType === ResourcePageType.REPORT_BUILDER
        ? `<iframe\n  src="https://app.explo.co/report-builder/iframe/${embedId}/<CUSTOMER_TOKEN>/<ENVIRONMENT>"\n  style="width: 100%;border: none;height: 100vh;">\n</iframe> `
        : `<iframe\n  src="https://app.explo.co/${
            pageType === ResourcePageType.ARCHITECT ? 'end-user-dashboard/' : ''
          }iframe/${embedId}/<CUSTOMER_TOKEN>/<ENVIRONMENT>${
            pageType === ResourcePageType.ARCHITECT ? '?userId=Sales' : ''
          }"\n  style="width: 100%;border: none;height: 100vh;">\n</iframe> `;

    return renderCodeBlock(embedCode);
  };

  const renderCodeBlock = (code: string) => (
    <div className={classes.codeBlock} onClick={() => copyToClipboard(code)}>
      <SyntaxHighlighter
        customStyle={{
          borderRadius: '3px',
          padding: '6px 8px',
          margin: '0px',
        }}
        language="javascript"
        style={style}
        wrapLines={false}>
        {code}
      </SyntaxHighlighter>
    </div>
  );

  const renderInlineCode = (code: string) => (
    <SyntaxHighlighter
      customStyle={{
        display: 'inline-flex',
        borderRadius: '3px',
        padding: '2px 4px',
        margin: '0px',
      }}
      language="plaintext"
      style={style}>
      {code}
    </SyntaxHighlighter>
  );

  return (
    <Dialog className={classes.root} isOpen={true} onClose={closeModal} title="Embed Instructions">
      <div className={Classes.DIALOG_BODY}>
        <div className={classes.modalContent}>
          <div className={classes.headerText}>0a. Add Explo Domains to CSP</div>
          <div className={classes.bodyText}>
            If you have a strict Content Security Policy, please whitelist{' '}
            {renderInlineCode('https://*.explo.co')} to your CSP to ensure our embed can work
            properly and make the proper requests. If you&rsquo;re not sure if you have a strict
            CSP, then you likely don&rsquo;t. If you prefer not to wildcard Explo&rsquo;s
            subdomains, these are the necessary domains: {renderInlineCode('https://api.explo.co')},{' '}
            {renderInlineCode('https://data.explo.co')},{' '}
            {renderInlineCode('https://data1.explo.co')}. More may be added in the future, but
            customers will be notified ahead of time.
          </div>
          <div className={classes.headerText}>0b. Have Explo Whitelist Your Domains</div>
          <div className={classes.bodyText}>
            Explo will need to allow your domains to make requests to our servers. Please whitelist
            your domains in the &ldquo;Domain Whitelisting&ldquo; section of the Settings page. For
            development purposes, we have {renderInlineCode('localhost')} on ports 3000, 3001, 3002,
            3006, 3100, 4000, 6700, 8080, 9000, 44339 whitelisted already.
          </div>
          <div className={classes.headerText}>1. Import Explo JS library</div>
          <div className={classes.bodyText}>
            In the {renderInlineCode('<head>')} of your {renderInlineCode('index.html')} include the
            following script imports
          </div>
          {renderCodeBlock(importInstructionsCode)}
          <div className={cx(classes.headerText, classes.underCodeBlock)}>
            2. Embed Component Into Frontend
          </div>
          <div className={classes.bodyText}>
            We use Web Components to power our embedded component, meaning it is compatible with
            every frontend framework (React, Vue, etc) and vanilla javascript. Below are some
            examples on how to embed the component. Check out our embed documentation{' '}
            <a
              href="https://docs.explo.co/embedding-documentation/option-1-web-component"
              rel="noopener noreferrer"
              target="_blank">
              here
            </a>
            .
          </div>
          {renderEmbedCode()}
          <div className={classes.iframeBodyText}>
            An alternative way to embed the dashboard is to use an iframe. Below is an example
            iframe snippet. Check out our iframe documentation{' '}
            <a
              href="https://docs.explo.co/embedding-documentation/option-2-iframe"
              rel="noopener noreferrer"
              target="_blank">
              here
            </a>
            .
          </div>

          {renderIframeCode()}
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button intent={Intent.NONE} onClick={() => closeModal()}>
            Close
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
