import { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import DropdownSelect from 'shared/DropdownSelect';
import GoalInputRow from './GoalInputRow';
import { sprinkles, Switch } from 'components/ds';

import {
  NumberDisplayOptions,
  NumberDisplayDisplayType,
  NumberDisplayTypeOptions,
  OPERATION_TYPES,
} from 'constants/types';
import { DatasetSchema } from 'types/datasets';
import { NUMBER_TYPES } from 'constants/dataConstants';

const useStyles = makeStyles((theme: Theme) => ({
  goalButton: {
    marginTop: theme.spacing(2),
    '& .bp3-button': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      fontWeight: 'normal',
    },
  },
}));

const goalInputClass = sprinkles({ marginTop: 'sp1' });

type Props = {
  updateNumberOptions: (newFields: NumberDisplayOptions) => void;
  displayOptions: NumberDisplayOptions | undefined;
  operationType: OPERATION_TYPES;
  originalSchema: DatasetSchema;
};

export const ProgressBarConfiguration: FC<Props> = ({
  updateNumberOptions,
  displayOptions,
  operationType,
  originalSchema,
}) => {
  const classes = useStyles();
  const isCollapsibleList = operationType === OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST;

  const { displayType, displayTypeOptions } = displayOptions ?? {};

  const numberColumnsOptions = originalSchema
    .filter((col) => NUMBER_TYPES.has(col.type))
    .map((col) => ({
      id: col.name,
      name: col.name,
    }));

  const updateFields = (updates: NumberDisplayTypeOptions) => {
    updateNumberOptions({ displayTypeOptions: { ...displayTypeOptions, ...updates } });
  };

  return (
    <>
      <Switch
        label="Progress bar"
        onChange={() => {
          updateNumberOptions({
            displayType:
              displayType === NumberDisplayDisplayType.PROGRESS_BAR
                ? NumberDisplayDisplayType.VALUE
                : NumberDisplayDisplayType.PROGRESS_BAR,
          });
        }}
        switchOn={displayType === NumberDisplayDisplayType.PROGRESS_BAR}
      />
      {displayType === NumberDisplayDisplayType.PROGRESS_BAR && (
        <>
          <Switch
            className={sprinkles({ paddingTop: 'sp1' })}
            label="Show progress on hover"
            onChange={() => {
              updateNumberOptions({
                disableHoverTooltip: !displayOptions?.disableHoverTooltip,
              });
            }}
            switchOn={!displayOptions?.disableHoverTooltip}
          />
          {isCollapsibleList ? (
            <GoalInputRow
              disableColumnCalculation
              label="Goal Value"
              type="max"
              updateExactValue={(newValue) => updateFields({ progressBarGoal: Number(newValue) })}
            />
          ) : (
            <>
              <ToggleButtonGroup fillWidth className={classes.goalButton}>
                <ToggleButton
                  active={
                    !displayTypeOptions?.useOtherColumnAsMax &&
                    displayTypeOptions?.useColumnMaxForProgressBarGoal
                  }
                  onClick={() =>
                    updateFields({
                      useColumnMaxForProgressBarGoal: true,
                      useOtherColumnAsMax: false,
                    })
                  }
                  text="Column Max"
                />
                <ToggleButton
                  active={
                    !displayTypeOptions?.useOtherColumnAsMax &&
                    !displayTypeOptions?.useColumnMaxForProgressBarGoal
                  }
                  onClick={() =>
                    updateFields({
                      useColumnMaxForProgressBarGoal: false,
                      useOtherColumnAsMax: false,
                    })
                  }
                  text="Exact Value"
                />
                <ToggleButton
                  active={displayTypeOptions?.useOtherColumnAsMax}
                  onClick={() =>
                    updateFields({
                      useColumnMaxForProgressBarGoal: false,
                      useOtherColumnAsMax: true,
                    })
                  }
                  text="Column"
                />
              </ToggleButtonGroup>
              {!displayTypeOptions?.useColumnMaxForProgressBarGoal &&
                !displayTypeOptions?.useOtherColumnAsMax && (
                  <InputWithBlurSave
                    hideRightIconInteractions
                    className={goalInputClass}
                    disabled={displayTypeOptions?.useColumnMaxForProgressBarGoal}
                    initialValue={String(displayTypeOptions?.progressBarGoal ?? '1')}
                    onNewValueSubmitted={(newValue) => {
                      if (isNaN(Number(newValue))) return;

                      updateFields({ progressBarGoal: Number(newValue) });
                    }}
                  />
                )}
              {displayTypeOptions?.useOtherColumnAsMax && (
                <DropdownSelect
                  fillWidth
                  minimal
                  containerClassName={goalInputClass}
                  filterable={false}
                  noSelectionText="Select goal column"
                  onChange={(newValue) => updateFields({ goalColumnName: newValue.id })}
                  options={numberColumnsOptions}
                  selectedItem={
                    displayTypeOptions?.goalColumnName
                      ? numberColumnsOptions.find(
                          (col) => col.name === displayTypeOptions.goalColumnName,
                        )
                      : undefined
                  }
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
