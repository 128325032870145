import { FC } from 'react';
import produce from 'immer';

import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';

import { SwitchElementConfig } from 'types/dashboardTypes';

type Props = {
  config: SwitchElementConfig;

  updateSwitchConfig: (config: SwitchElementConfig) => void;
};

export const SwitchDefaultValueConfig: FC<Props> = ({ config, updateSwitchConfig }) => {
  const updateConfig = (defaultOn: boolean) => {
    const newConfig = produce(config, (draft) => {
      draft.defaultOn = defaultOn;
    });
    updateSwitchConfig(newConfig);
  };

  return (
    <ToggleButtonGroup fillWidth>
      <ToggleButton active={config.defaultOn} onClick={() => updateConfig(true)} text="ON" />
      <ToggleButton active={!config.defaultOn} onClick={() => updateConfig(false)} text="OFF" />
    </ToggleButtonGroup>
  );
};
