import { useState, useEffect, useMemo, FC } from 'react';
import { cloneDeep, some } from 'utils/standard';
import { produce } from 'immer';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Modal, Icon, sprinkles } from 'components/ds';
import { Link } from 'react-router-dom';

import { ManageSchemasSection } from 'pages/DataPage/modals/ManageSchemasSection';

import { ReduxState } from 'reducers/rootReducer';
import { ACTION } from 'actions/types';
import { ParentSchema } from 'actions/dataSourceActions';
import { syncParentSchema } from 'actions/parentSchemaActions';
import { createLoadingSelector } from 'reducers/api/selectors';
import { showWarningToast } from 'shared/sharedToasts';
import { updateAccessGroups } from 'actions/rolePermissionActions';
import { showErrorToast } from 'shared/sharedToasts';
import {
  getAccessGroupDataSourcesForSchemasMap,
  getAccessGroupsToUpdate,
  schemasHaveDuplicateNames,
} from '../utils';
import { DUPLICATE_SCHEMA_NAME_TEXT, VIZ_GROUP_EDITING_ERROR_TEXT } from '../constants';
import * as styles from '../styles.css';
import { ROUTES } from 'constants/routes';

type Props = {
  modalOpen: boolean;
  closeModal: () => void;
  parentSchemas: ParentSchema[];
  selectedSchemaId?: number;
};

export const ManageSchemasModal: FC<Props> = ({
  parentSchemas,
  selectedSchemaId,
  closeModal,
  modalOpen,
}) => {
  const [editorSchema, setEditorSchema] = useState<ParentSchema[]>([]);
  const [accessGroupIdToDefaultDataSourceIdUpdates, setAccessGroupIdToDefaultDataSourceIdUpdates] =
    useState<Record<number, number[]>>({});

  const dispatch = useDispatch();

  useEffect(() => {
    setEditorSchema(cloneDeep(parentSchemas));
  }, [parentSchemas]);

  const { dataSources, team, onSyncSchemasLoading } = useSelector(
    (state: ReduxState) => ({
      dataSources: state.dataSourceList.dataSources,
      team: state.teamData.data,
      onSyncSchemasLoading: createLoadingSelector([ACTION.SYNC_PARENT_SCHEMA], false)(state),
    }),
    shallowEqual,
  );

  const accessGroupDataSourcesForSchemaMap = useMemo(() => {
    return getAccessGroupDataSourcesForSchemasMap(dataSources, parentSchemas, team?.access_groups);
  }, [team, dataSources, parentSchemas]);

  const saveDisabled = some(editorSchema, (schema) => !schema.name);

  const renderSchemaSection = (schema: ParentSchema) => {
    if (selectedSchemaId && schema.id !== selectedSchemaId) return null;
    return (
      <ManageSchemasSection
        accessGroupDataSourcesMap={accessGroupDataSourcesForSchemaMap?.[schema.id]}
        accessGroupIdToDefaultDataSourceIdUpdates={accessGroupIdToDefaultDataSourceIdUpdates}
        key={`schema-${schema.id}`}
        onDelete={() => {
          const newSchemas = editorSchema.filter((s) => s.id !== schema.id);
          setEditorSchema(newSchemas);
        }}
        onSchemaNameChange={(val) => {
          const newEditorSchema = produce(editorSchema, (draft) => {
            const indexToUpdate = draft.findIndex((tempSchema) => schema.name === tempSchema.name);
            if (indexToUpdate !== -1) draft[indexToUpdate].name = val;
          });
          setEditorSchema(newEditorSchema);
        }}
        schema={schema}
        updateAccessGroupDefaultDataSource={(accessGroupId, newDefaultDataSourceIds) =>
          setAccessGroupIdToDefaultDataSourceIdUpdates({
            ...accessGroupIdToDefaultDataSourceIdUpdates,
            [accessGroupId]: newDefaultDataSourceIds,
          })
        }
      />
    );
  };

  const saveSchema = () => {
    if (schemasHaveDuplicateNames(editorSchema)) {
      showWarningToast(DUPLICATE_SCHEMA_NAME_TEXT);
      return;
    }

    const accessGroupsToUpdate = getAccessGroupsToUpdate(accessGroupIdToDefaultDataSourceIdUpdates);

    if (accessGroupsToUpdate) {
      dispatch(
        updateAccessGroups({ postData: { access_groups: accessGroupsToUpdate } }, undefined, () => {
          showErrorToast(VIZ_GROUP_EDITING_ERROR_TEXT);
        }),
      );
    }

    dispatch(
      syncParentSchema(
        { postData: { edited_schemas: editorSchema } },
        () => closeModal(),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e: any) => showWarningToast(e._error),
      ),
    );
  };

  return (
    <Modal
      isOpen={modalOpen}
      onClose={() => {
        if (onSyncSchemasLoading) return;

        setEditorSchema(cloneDeep(parentSchemas));
        setAccessGroupIdToDefaultDataSourceIdUpdates({});
        closeModal();
      }}
      primaryButtonProps={{
        disabled: saveDisabled,
        loading: onSyncSchemasLoading,
        onClick: saveSchema,
        text: 'Save',
      }}
      size="medium"
      title="Manage Schemas">
      <div className={sprinkles({ paddingX: 'sp3', flexItems: 'column' })}>
        <div className={sprinkles({ paddingY: 'sp1' })}>
          Use schemas to group databases that can run the same dataset queries. Databases that you
          use distinctly should be in their own separate schema.
        </div>
        <div className={styles.modalSectionsContainer} style={{ height: '40vh' }}>
          {editorSchema.length > 0 ? (
            editorSchema.map(renderSchemaSection)
          ) : (
            <div className={styles.emptyModal}>
              <Icon
                className={sprinkles({ color: 'yellow7' })}
                name="circle-exclamation"
                size="lg"
              />
              <div className={sprinkles({ body: 'b2' })}>
                No schemas to show. Set up a schema by connecting a data source{' '}
                <Link to={ROUTES.CONNECT_DATA_SOURCE}>here</Link>.
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
