import { DATE, DEFAULT_BOOLEAN_DISPLAY_OPTIONS } from 'constants/dataConstants';
import {
  BooleanDisplayFormat,
  BooleanDisplayOptions,
  DateDisplayFormat,
  DateDisplayOptions,
  GradientPointOptions,
  GradientPointType,
  NumberDisplayFormat,
  NumberDisplayOptions,
  StringDisplayFormat,
  StringDisplayOptions,
  StringUrlDisplayFormat,
} from 'constants/types';
import { DatasetRow } from 'types/datasets';
import { getValidHex } from './general';

export const getFloats = (
  point: GradientPointOptions | undefined,
  min: number,
  max: number,
  defaultFloat: number,
): number => {
  if (max <= min) return defaultFloat;
  // if the point is an exact value, calculate where it falls between max and min
  if (point && point.type === GradientPointType.NUMBER && point.number !== undefined) {
    return (point.number - min) / (max - min);
  }
  return defaultFloat;
};

export const getCurrentDateFormat = (
  dateDisplayOptions: DateDisplayOptions | undefined,
  colType: string,
): DateDisplayFormat => {
  if (dateDisplayOptions?.format) return dateDisplayOptions.format;
  return colType === DATE ? DateDisplayFormat.NORMAL : DateDisplayFormat.DATE_TIMESTAMP;
};

export const getCurrentNumberFormat = (
  numberDisplayOptions: NumberDisplayOptions | undefined,
): NumberDisplayFormat => {
  return numberDisplayOptions?.format ?? NumberDisplayFormat.NORMAL;
};

export const getCurrentStringFormat = (
  stringDisplayOptions: StringDisplayOptions | undefined,
): StringDisplayFormat => {
  return stringDisplayOptions?.format ?? StringDisplayFormat.NORMAL;
};

export const getCurrentBooleanIcons = (
  booleanOptions: BooleanDisplayOptions | undefined,
): { trueIcon: BooleanDisplayFormat; falseIcon: BooleanDisplayFormat } => {
  return {
    trueIcon: booleanOptions?.trueIcon ?? DEFAULT_BOOLEAN_DISPLAY_OPTIONS.trueIcon,
    falseIcon: booleanOptions?.falseIcon ?? DEFAULT_BOOLEAN_DISPLAY_OPTIONS.falseIcon,
  };
};

export const getCurrentUrlFormat = (urlFormat?: StringUrlDisplayFormat): StringUrlDisplayFormat => {
  return urlFormat ?? StringUrlDisplayFormat.LABEL;
};

export const getLinkInfo = (
  { urlFormat, label, urlColumnName, urlLinkColor, openInSameTab }: StringDisplayOptions,
  rows: DatasetRow[],
  rowIndex: number,
): { urlLabel: string | undefined; linkColor: string | undefined; target: string | undefined } => {
  let urlLabel: string | undefined = '';
  const format = getCurrentUrlFormat(urlFormat);
  if (format === StringUrlDisplayFormat.LABEL) {
    urlLabel = label;
  } else if (format === StringUrlDisplayFormat.COLUMN && urlColumnName) {
    urlLabel = String(rows[rowIndex][urlColumnName]);
  }
  return {
    urlLabel,
    target: openInSameTab ? undefined : '_blank',
    linkColor: urlLinkColor ? getValidHex(urlLinkColor) : undefined,
  };
};
