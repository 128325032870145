import { FC } from 'react';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import CustomStylesColorPicker from 'pages/GlobalCustomStylesPage/CustomStylesColorPicker';

import * as styles from './styles.css';
import { ChartShapeBorderFormat } from 'constants/types';
import {
  ChartShapeBorderDefaultColor,
  chartShapeBorderDefaultWidth,
} from 'constants/dashboardConstants';

type Props = {
  chartShapeBorderConfig?: ChartShapeBorderFormat;
  updateConfig: (newConfig: ChartShapeBorderFormat) => void;
};

export const ChartShapeBorderConfig: FC<Props> = ({ chartShapeBorderConfig, updateConfig }) => {
  return (
    <div className={styles.rootClass}>
      <InputWithBlurSave
        containerClassName={styles.configInputClass}
        initialValue={String(chartShapeBorderConfig?.borderWidth ?? chartShapeBorderDefaultWidth)}
        label="Shape border width"
        onNewValueSubmitted={(newValue) => {
          const newNum = Number(newValue);
          if (isNaN(newNum)) return;

          updateConfig({ ...chartShapeBorderConfig, borderWidth: newNum });
        }}
      />
      <div className={styles.configInputClass}>
        <div className={styles.inputLabel}>Shape border color</div>
        <CustomStylesColorPicker
          fill
          color={chartShapeBorderConfig?.borderColor || ChartShapeBorderDefaultColor}
          onClose={(newColor) => updateConfig({ ...chartShapeBorderConfig, borderColor: newColor })}
        />
      </div>
    </div>
  );
};
