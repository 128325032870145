import { useMemo, useState } from 'react';
import { makeStyles, useTheme, Theme } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import produce from 'immer';
import cx from 'classnames';

import { Icon } from '@blueprintjs/core';
import Checkbox from 'components/checkbox';
import { Button, Intent, Tag } from 'components/ds';
import DatasetModal from './DatasetModal';
import { ColumnHeader } from 'components/resource/ColumnHeader';
import { MetadataInputs } from 'components/resource/MetadataInputs';
import { ConfigSection } from 'components/resource/ConfigSection';
import { QuerySection } from 'components/resource/QuerySection';
import {
  DatasetColumnSelection,
  DatasetColumnSelectionItem,
} from 'components/resource/DatasetColumnSelection';
import {
  DatasetColumnFormat,
  DatasetColumnFormatItem,
} from 'components/resource/DatasetColumnFormat';

import { updateCanvasDatasetSchema } from 'actions/datasetActions';
import {
  CanvasColumnOption,
  CanvasDataset,
  CanvasVersionConfig,
  toggleCanvasDatasetVisibility,
  updateCanvasDataset,
  updateRequiredFilters,
} from 'actions/canvasConfigActions';
import { sharedStyles } from '../sharedStyles';
import { getFilterReferences } from 'utils/canvasConfigUtils';
import { DASHBOARD_ELEMENT_TYPE_TO_NAME } from 'constants/dashboardConstants';

const useStyles = makeStyles((theme: Theme) => ({
  schemaSelect: {
    padding: theme.spacing(2),
  },
  requiredText: {
    color: theme.palette.ds.grey800,
    fontSize: 12,
    fontWeight: 400,
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  filterContent: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  filterAttrs: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  filterCheckbox: {
    marginRight: theme.spacing(4),
    flexShrink: 0,
  },
  filterType: {
    color: theme.palette.ds.grey600,
    textTransform: 'capitalize',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  filterId: {
    color: theme.palette.ds.grey600,
  },
  linkedFilter: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
}));

type Props = {
  canvasId: number;
  config: CanvasVersionConfig;
  dataset: CanvasDataset;
  isNewDataset: boolean;

  getPreview: (offset?: number) => void;
  saveQuery: () => void;
};

enum ModalStatus {
  CLOSED = 'Closed',
  DELETE_DATASET = 'Delete',
  HIDE_DATASET = 'Hide',
}

export default function DatasetConfig({
  canvasId,
  getPreview,
  saveQuery,
  dataset,
  config,
  isNewDataset,
}: Props): JSX.Element {
  const sharedClasses = sharedStyles();
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { blueprintId } = useParams<{ blueprintId: string }>();

  const [modalStatus, setModalStatus] = useState(ModalStatus.CLOSED);

  const filtersReferenced = useMemo(() => {
    const filterReferences = getFilterReferences([dataset]);
    return new Set(Object.keys(filterReferences));
  }, [dataset]);

  const renderReferencedFilters = () => {
    const filters = Object.values(config.filters);
    if (filters.length === 0) return;

    return (
      <ConfigSection defaultIsOpen title="Integrate filters">
        <div className={sharedClasses.configSection} style={{ paddingBottom: theme.spacing(4) }}>
          <p className={sharedClasses.sectionText}>
            These values will be the possible options users can select when interacting with the
            filter.
          </p>

          <p className={classes.requiredText}>Required</p>
          {filters.map((filter) => {
            const isReferenced = filtersReferenced.has(filter.provided_id);
            const isRequiredByDataset =
              dataset.requiredFilters.findIndex((id) => id === filter.id) > -1;

            return (
              <div className={classes.filterContainer} key={filter.id}>
                <Link
                  className={cx(sharedClasses.linkedItem, classes.linkedFilter)}
                  to={`/blueprint/${blueprintId}/filters?id=${filter.id}`}>
                  <Checkbox
                    ignoreCustomStyles
                    preventDefault
                    className={classes.filterCheckbox}
                    disabled={!isReferenced}
                    isChecked={isRequiredByDataset}
                    onChange={() =>
                      dispatch(
                        updateRequiredFilters({ filterId: filter.id, datasetId: dataset.id }),
                      )
                    }
                  />
                  <div className={classes.filterContent}>
                    <div
                      className={classes.filterAttrs}
                      style={{ maxWidth: 200, marginRight: theme.spacing(1) }}>
                      <div
                        className={sharedClasses.linkedItemName}
                        style={{ marginBottom: theme.spacing(1), padding: 0 }}>
                        {filter.name}
                      </div>
                      <div className={classes.filterId}>{filter.provided_id}</div>
                    </div>

                    <div className={classes.filterAttrs} style={{ alignItems: 'flex-end' }}>
                      {filter.filter_info ? (
                        <div className={classes.filterType}>
                          {DASHBOARD_ELEMENT_TYPE_TO_NAME[filter.filter_info.filter_type]}
                        </div>
                      ) : null}
                      {isReferenced ? <Tag intent={Intent.ACTIVE}>Referenced</Tag> : null}
                    </div>
                  </div>

                  <Icon
                    className={sharedClasses.linkedItemIcon}
                    color={theme.palette.ds.grey800}
                    icon="arrow-top-right"
                  />
                </Link>
              </div>
            );
          })}
        </div>
      </ConfigSection>
    );
  };

  const updateColumnOptions = (colName: string, updates: Partial<CanvasColumnOption>) => {
    const columnOptions = produce(dataset.columnOptions, (draft) => {
      draft[colName] = { ...draft[colName], ...updates };
    });
    dispatch(updateCanvasDataset({ datasetId: dataset.id, columnOptions }));
  };

  const renderColumnSelection = () => {
    if (!dataset.schema?.length) return null;
    const columnOptions = dataset.columnOptions;
    return (
      <DatasetColumnSelection
        isNewDataset={isNewDataset}
        sectionText="Columns with grouping enabled will be available to augment chart data with group bys."
        toggleName="Grouping">
        {dataset.schema.map((col) => {
          const columnOption = columnOptions[col.name];
          if (!columnOption) return null;

          return (
            <DatasetColumnSelectionItem
              column={col}
              isVisible={columnOption.isVisible}
              key={col.name}
              onToggle={(newVal) => updateColumnOptions(col.name, { canBeGroupedBy: newVal })}
              onVisibilityToggle={(newVal) => updateColumnOptions(col.name, { isVisible: newVal })}
              toggleOn={columnOption.canBeGroupedBy}
            />
          );
        })}
      </DatasetColumnSelection>
    );
  };

  const renderColumnFormatting = () => {
    if (!dataset.schema?.length) return null;

    return (
      <DatasetColumnFormat sectionText="This will impact how data is shown when used in a table or chart">
        {dataset.schema.map((col) => {
          const columnOption = dataset.columnOptions[col.name];
          if (!columnOption || !columnOption.isVisible) return null;

          return (
            <DatasetColumnFormatItem
              colDescription={columnOption.description}
              colName={columnOption.name}
              key={col.name}
              updateColumnInfo={(updates) => updateColumnOptions(col.name, updates)}
            />
          );
        })}
      </DatasetColumnFormat>
    );
  };

  return (
    <>
      <ColumnHeader title="Configuration">
        <Button
          icon={dataset.isHiddenFromUsers ? 'eye-open' : 'eye-closed'}
          onClick={() => {
            if (dataset.isHiddenFromUsers) {
              dispatch(toggleCanvasDatasetVisibility(dataset.id));
              return;
            }
            setModalStatus(ModalStatus.HIDE_DATASET);
          }}
          type={dataset.isHiddenFromUsers ? 'primary' : 'secondary'}>
          {dataset.isHiddenFromUsers ? 'Make visible' : 'Hide'}
        </Button>
      </ColumnHeader>

      <div className={sharedClasses.config}>
        <MetadataInputs
          defaultIsOpen={isNewDataset}
          handleNewValueSubmitted={(params) =>
            dispatch(updateCanvasDataset({ datasetId: dataset.id, ...params }))
          }
          initialDescription={dataset.description}
          initialName={dataset.name}
        />

        <QuerySection
          dataset={dataset}
          getPreview={getPreview}
          onSelectSchema={(schema) =>
            dispatch(
              updateCanvasDatasetSchema({
                datasetId: dataset.id,
                newParentSchemaId: schema.id,
              }),
            )
          }
          saveQuery={saveQuery}
        />
        {renderReferencedFilters()}
        {renderColumnSelection()}
        {renderColumnFormatting()}

        <div className={sharedClasses.deleteButtonContainer}>
          <Button
            fillWidth
            icon="trash"
            onClick={() => setModalStatus(ModalStatus.DELETE_DATASET)}
            type="destructive">
            Remove
          </Button>
        </div>
      </div>
      {modalStatus === ModalStatus.CLOSED ? null : (
        <DatasetModal
          canvasId={canvasId}
          closeModal={() => setModalStatus(ModalStatus.CLOSED)}
          config={config}
          datasetId={dataset.id}
          isDeletion={modalStatus === ModalStatus.DELETE_DATASET}
        />
      )}
    </>
  );
}
