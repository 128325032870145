import { FC, useEffect, useState } from 'react';

import { sprinkles } from 'components/ds';
import { ChartLinkRow } from './ChartLinkRow';
import DropdownSelect from 'shared/DropdownSelect';

import { DashboardElementConfig } from 'types/dashboardTypes';
import { DataPanel, ResourceDataset } from 'types/exploResource';
import { getDataPanelsUsingDataset } from 'utils/datasetUtils';
import { getDatasetName } from 'utils/naming';
import { FILTER_OPERATOR_TYPES_BY_ID } from 'types/filterOperations';
import { SelectedDropdownInputItem } from 'constants/types';
import { getUpdateConfigFunc } from '../utils';
import { dpLinkUpdated } from 'utils/customEventUtils';

type Props = {
  config: DashboardElementConfig;
  dataset: ResourceDataset;
  dataPanels: Record<string, DataPanel>;
  elementName: string;

  updateConfig: (config: DashboardElementConfig) => void;
};

export const DatasetLink: FC<Props> = ({
  config,
  dataPanels,
  dataset,
  elementName,
  updateConfig,
}) => {
  const datasetFilter = config.datasetLinks?.[dataset.id];
  const [column, setColumn] = useState(datasetFilter?.column);

  useEffect(() => {
    const newColumn = datasetFilter?.column;
    if (newColumn !== column) {
      setColumn(newColumn);
      const dataPanelIds = datasetFilter?.dataPanels ?? [];
      if (dataPanelIds.length > 0) dpLinkUpdated({ dataPanelIds, elementName });
    }
  }, [datasetFilter, column, elementName]);

  const update = getUpdateConfigFunc(config, updateConfig);

  const operatorInfo = config.operator ? FILTER_OPERATOR_TYPES_BY_ID[config.operator] : undefined;

  const dpsUsed = getDataPanelsUsingDataset(Object.values(dataPanels), dataset.id);
  if (dpsUsed.length === 0) return null;

  const columnOptions = (dataset.schema ?? []).reduce((acc, col) => {
    if (operatorInfo && operatorInfo.supported_column_types.has(col.type)) {
      acc.push({ id: col.name, name: col.friendly_name || col.name });
    }
    return acc;
  }, [] as SelectedDropdownInputItem[]);

  const selectedColumn = column ? dataset.schema?.find((col) => col.name === column) : undefined;

  const columnNotSupportedByOperator =
    selectedColumn && config.operator
      ? !columnOptions.find((col) => col.id === selectedColumn.name)
      : false;

  return (
    <div className={sprinkles({ marginTop: 'sp1.5' })}>
      <DropdownSelect
        disableOnNoItems
        fillWidth
        ignoreCustomStyles
        minimal
        showCancelBtn
        containerClassName={sprinkles({
          marginBottom: columnNotSupportedByOperator ? 'sp.5' : 'sp1',
        })}
        disabled={config.operator === undefined}
        filterable={columnOptions.length > 5}
        label={getDatasetName(dataset)}
        noSelectionText="Search or select a field"
        onCancelClick={() =>
          update((draft) => {
            if (!draft.datasetLinks || !(dataset.id in draft.datasetLinks)) return;
            draft.datasetLinks[dataset.id].column = undefined;
          })
        }
        onChange={(item) => {
          update((draft) => {
            if (!draft.datasetLinks) draft.datasetLinks = {};
            draft.datasetLinks[dataset.id] = {
              ...draft.datasetLinks[dataset.id],
              column: item.id,
            };
          });
        }}
        options={columnOptions}
        selectedItem={
          selectedColumn
            ? {
                id: selectedColumn.name,
                name: selectedColumn.friendly_name || selectedColumn.name,
              }
            : undefined
        }
      />
      {columnNotSupportedByOperator ? (
        <div className={sprinkles({ body: 'b3', color: 'error', marginBottom: 'sp1' })}>
          Selected column is not supported by selected operator
        </div>
      ) : null}
      {selectedColumn
        ? dpsUsed.map((dp) => (
            <ChartLinkRow
              config={config}
              dataPanel={dp}
              datasetId={dataset.id}
              elementName={elementName}
              key={`${dataset.id}-${dp.id}`}
              updateConfig={updateConfig}
            />
          ))
        : null}
    </div>
  );
};
