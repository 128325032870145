import { sortBy } from 'utils/standard';

import { WhitelistDomain } from 'actions/teamActions';
import { createReducer } from '@reduxjs/toolkit';
import {
  createWhitelistDomainSucess,
  deleteWhitelistDomainSuccess,
  fetchWhiteListDomainsSuccess,
} from 'actions/whitelistDomainActions';

interface WhitelistDomainReducerState {
  whitelist_domains?: WhitelistDomain[];
}

const whitelistDomainReducerInitialState: WhitelistDomainReducerState = {
  whitelist_domains: [],
};

export default createReducer(whitelistDomainReducerInitialState, (builder) => {
  builder
    .addCase(fetchWhiteListDomainsSuccess, (state, { payload }) => {
      state.whitelist_domains = sortBy(
        payload.whitelist_domains,
        (whitelist_domain) => whitelist_domain.id,
      );
    })
    .addCase(createWhitelistDomainSucess, (state, { payload }) => {
      if (!state.whitelist_domains) return;

      state.whitelist_domains.push(payload.whitelist_domain);

      state.whitelist_domains = sortBy(
        state.whitelist_domains,
        (whitelist_domain) => whitelist_domain.id,
      );
    })
    .addCase(deleteWhitelistDomainSuccess, (state, { payload }) => {
      state.whitelist_domains = state.whitelist_domains?.filter(
        (domain) => domain.id !== payload.id,
      );
    })
    .addDefaultCase((state) => state);
});
