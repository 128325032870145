import { FC } from 'react';
import cx from 'classnames';

import { Content, Item } from '@radix-ui/react-dropdown-menu';
import * as styles from './styles.css';

type Props = {
  items: { id: string; disabled?: boolean; name?: string }[];
  selectedItemId?: string;
  largeItems?: boolean;

  onSelect: (id: string) => void;
};

export const DropdownMenuContent: FC<Props> = ({ items, selectedItemId, onSelect, largeItems }) => {
  return (
    <Content align="start" className={styles.dropdown}>
      {items.map((item) => (
        <Item
          className={cx(
            selectedItemId === item.id ? styles.selectedItem : styles.unselectedItem,
            largeItems ? styles.largeMenuItem : styles.normalMenuItem,
          )}
          disabled={item.disabled}
          key={item.id}
          onSelect={() => onSelect(item.id)}>
          {item.name ?? item.id}
        </Item>
      ))}
    </Content>
  );
};
