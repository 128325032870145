import { cloneDeep, compact, isNumber, keyBy, map, orderBy, partition } from 'utils/standard';
import parse from 'url-parse';
import ReactGridLayout from '@explo-tech/react-grid-layout';

import {
  DashboardPageLayoutConfig,
  DashboardParam,
  DashboardVersionConfig,
} from 'types/dashboardVersionConfig';
import { DatasetSchema, DatasetColumn, DatasetRow } from 'types/datasets';
import { COLOR_CATEGORY_FILTER_SUFFIX, SELECT_ELEMENT_SET } from 'constants/dashboardConstants';
import {
  Aggregation,
  FilterValueSourceType,
  GradientOptions,
  GradientPointType,
  GradientShape,
  GradientType,
  NumberDisplayOptions,
  OPERATION_TYPES,
  PivotOperationAggregation,
  UserTransformedSchema,
  VisualizeTableInstructions,
  VisualizePivotTableInstructions,
} from 'constants/types';
import {
  ApplyFilterElemConfig,
  DASHBOARD_ELEMENT_TYPES,
  DashboardElement,
  DashboardElementConfig,
  DashboardVariable,
  DashboardVariableMap,
  DateGroupToggleConfig,
  DropdownValuesConfig,
  MetricsByColumn,
  MultiSelectSortOption,
  NumberColumnMetrics,
  SelectElemConfig,
  TextDashboardElemConfig,
  VIEW_MODE,
  DASHBOARD_LAYOUT_CONFIG,
} from 'types/dashboardTypes';
import { AGGREGATIONS_TYPES, DATE_PART_INPUT_AGG, NUMBER_TYPES } from 'constants/dataConstants';
import { areRequiredVariablesSet } from 'pages/dashboardPage/charts/utils/trendUtils';
import { DEFAULT_GRADIENT } from 'constants/dataPanelEditorConstants';
import { getPercentageRatio, mixColors } from './general';
import { isSecondaryDataRequired } from './dataPanelConfigUtils';
import { titleCase } from 'utils/graphUtils';
import {
  PeriodRangeTypes,
  PIVOT_AGG_TYPES,
  PivotAgg,
  TREND_GROUP_OPTION_TO_PIVOT_AGG,
  TREND_GROUPING_OPTIONS,
  TrendGroupingOptions,
} from 'types/dateRangeTypes';
import { findDrilldownValue, isDrilldownVar } from 'utils/drilldownUtils';
import { dateTimeFromISOString } from './dateUtils';
import { DateTime } from 'luxon';
import { DataPanel, ResourceDataset } from 'types/exploResource';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import {
  getDataPanelDatasetId,
  elementHasContainer,
  isCanvasDataset,
  isDataPanelTemplate,
} from './exploResourceUtils';
import { CanvasColumnOption } from 'actions/canvasConfigActions';
import {
  FILTER_OPERATOR_TYPES_BY_ID,
  FILTER_OPS_DATE_PICKER,
  FILTER_OPS_DATE_RANGE_PICKER,
  FILTER_OPS_MULTISELECT,
  FILTER_OPS_NUMBER,
  FILTER_OPS_RELATIVE_PICKER,
  FILTER_OPS_STRING,
  FilterOperator,
} from 'types/filterOperations';
import { DRILLDOWN_DATA_PANEL_ID } from 'reducers/dashboardEditConfigReducer';
import { getSelectFilterDatasetId } from './filterUtils';
import { getDataPanelsUsingDataset, getElementsUsingDataset } from './datasetUtils';
import { attachLinkFiltersToDp } from './filterLinking';
import { formatSmartBucket } from './smartGroupingUtils';

export const elemIdFromDropId = (dropId: string) => {
  return dropId.split('-element-')[1];
};

export const dataPanelsAdded = (oldDPs: DataPanel[], newDPs: DataPanel[]): DataPanel[] => {
  const oldIds = new Set(map(oldDPs, 'id'));
  return newDPs.filter((dp) => !oldIds.has(dp.id));
};

export const dashboardElementsAdded = (
  oldElems: DashboardElement[],
  newElems: DashboardElement[],
) => {
  const oldIds = new Set(map(oldElems, 'id'));
  const newIDs = map(newElems, 'id');

  return newIDs.filter((id) => !oldIds.has(id));
};

const getDatasetIdsFromElems = (elems: DashboardElement[]) => {
  return elems.reduce<string[]>((acc, elem) => {
    if (SELECT_ELEMENT_SET.has(elem.element_type)) {
      const datasetId = getSelectFilterDatasetId(elem.config as SelectElemConfig);
      if (datasetId) acc.push(datasetId);
    }
    return acc;
  }, []);
};

export const datasetsChanged = (oldElems: DashboardElement[], newElems: DashboardElement[]) => {
  const oldDatasetIds = getDatasetIdsFromElems(oldElems);
  const newDatasetsIds = getDatasetIdsFromElems(newElems);

  const results: string[] = [];
  const oldIdsSet = new Set(oldDatasetIds);
  newDatasetsIds.forEach((newId) => {
    if (!oldIdsSet.has(newId)) {
      results.push(newId);
    }
  });

  return results;
};

export const getDashboardElemsWithDefaultQueryValues = (elems: DashboardElement[]) => {
  return elems.filter((elem) => {
    if (!SELECT_ELEMENT_SET.has(elem.element_type)) return false;

    const config = elem.config as SelectElemConfig;
    const datasetId = getSelectFilterDatasetId(config);
    return datasetId && config.valuesConfig.queryDefaultFirstValue;
  });
};

export const getDashboardElemsUsingDatasets = (
  elems: DashboardElement[],
  datasets: ResourceDataset[],
) => {
  const datasetIds = new Set(datasets.map((dataset) => dataset.id));
  return elems.filter((elem) => {
    if (!SELECT_ELEMENT_SET.has(elem.element_type)) return false;

    const filterDatasetId = getSelectFilterDatasetId(elem.config as SelectElemConfig);
    return filterDatasetId && datasetIds.has(filterDatasetId);
  });
};

export const getDatasetIdsForElems = (
  elems: DashboardElement[],
  datasets: Record<string, ResourceDataset>,
  excludeDefaultValueDatasets?: boolean,
) => {
  const datasetIdsToFetchForFilterElems: string[] = [];
  const datasetIdsToFetchForTextElems: string[] = [];

  elems.forEach((dashboardElement) => {
    if (SELECT_ELEMENT_SET.has(dashboardElement.element_type)) {
      const config = dashboardElement.config as SelectElemConfig;
      const datasetId = getSelectFilterDatasetId(config);
      if (
        datasetId &&
        (!excludeDefaultValueDatasets || !config.valuesConfig.queryDefaultFirstValue) &&
        datasets[datasetId] // if dataset is no longer present, don't try to fetch it
      ) {
        datasetIdsToFetchForFilterElems.push(datasetId);
      }
    }

    if (dashboardElement.element_type === DASHBOARD_ELEMENT_TYPES.TEXT) {
      const textConfig = dashboardElement.config as TextDashboardElemConfig;
      if (textConfig.queryTables) {
        datasetIdsToFetchForTextElems.push(...map(textConfig.queryTables, 'id'));
      }
    }
  });

  if (excludeDefaultValueDatasets) {
    const elemsWithDefault = getDashboardElemsWithDefaultQueryValues(elems || []);
    const datasetsWithDefaults = extractDatasetIdsFromElems(elemsWithDefault);
    const filteredFilterElemDatasets = datasetIdsToFetchForFilterElems.filter(
      (x) => !datasetsWithDefaults.has(x),
    );
    return new Set(filteredFilterElemDatasets.concat(datasetIdsToFetchForTextElems));
  } else {
    return new Set(datasetIdsToFetchForFilterElems.concat(datasetIdsToFetchForTextElems));
  }
};

export const extractDatasetIdsFromElems = (elems: DashboardElement[]) => {
  return new Set(
    compact(
      elems.map((elem) => {
        const config = elem.config as SelectElemConfig;
        return config.valuesConfig.queryTable?.id;
      }),
    ),
  );
};

export const isValueInTableRows = (rows: DatasetRow[], key: string, value?: DashboardVariable) => {
  if (!value) return false;

  return rows.some((row) => row[key] === value);
};

export const getUrlSanitizedDashboardVars = (exportVars: DashboardVariableMap) => {
  const vars: { [key: string]: string } = {};

  Object.keys(exportVars).forEach((key) => {
    if (
      !exportVars[key] ||
      key === 'user_group_id' ||
      key === 'user_group_name' ||
      key.startsWith('user_group.') ||
      key === 'customer_id' ||
      key === 'customer_name' ||
      key.startsWith('customer.')
    )
      return;

    const s = JSON.stringify(exportVars[key]);
    vars[key] = s;
  });

  return vars;
};

export const getUrlParamStringFromDashVars = (exportVars: DashboardVariableMap): string => {
  const sanitizedVars = getUrlSanitizedDashboardVars(exportVars);

  // first convert the variable dictionary into a URL
  let dummyUrl = parse('https://example.com');
  dummyUrl.set('query', sanitizedVars);

  // then read in the generated URL and pull out the unparsed query
  dummyUrl = parse(dummyUrl.toString());
  return (dummyUrl.query as unknown as string) || '?';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function removeUnderscoreFields<K extends Record<string, any>>(obj: K): K {
  Object.keys(obj).forEach((key) => {
    if (key.startsWith('_')) delete obj[key];
  });

  return obj;
}

function addBaseSchemaToTable<T extends DataPanel>(
  dp: T,
  datasets: Record<string, ResourceDataset>,
) {
  const datasetId = getDataPanelDatasetId(dp);
  const dataset = datasets[datasetId] ?? {};

  dp.visualize_op.instructions.VISUALIZE_TABLE.baseSchemaList =
    dataset?.schema ?? dataset?._schema ?? [];

  return dp;
}

export const removeUnsavedDashboardConfigFields = (config: DashboardVersionConfig) => {
  const cleanConfig = cloneDeep(config);
  Object.values(cleanConfig.datasets).map(removeUnderscoreFields);
  Object.values(cleanConfig.elements).map(removeUnderscoreFields);
  Object.values(cleanConfig.data_panels).map(removeUnderscoreFields);

  if (DRILLDOWN_DATA_PANEL_ID in cleanConfig.data_panels) {
    delete cleanConfig.data_panels[DRILLDOWN_DATA_PANEL_ID];
  }

  cleanConfig.dashboard_layout?.forEach((layout) => {
    Object.keys(layout).forEach((layoutKey) => {
      // @ts-ignore
      if (layout[layoutKey] === undefined) delete layout[layoutKey];
    });
  });

  return cleanConfig;
};

export function prepareDataPanel(
  variables: DashboardVariableMap,
  dp: DataPanel,
  datasets: Record<string, ResourceDataset>,
  isEmbed: boolean,
  elements: DashboardElement[],
): DataPanel {
  return prepareDataPanelForFetch(variables, dp, datasets, isEmbed, elements);
}

export function prepareDataPanelForFetch<T extends DataPanel>(
  variables: DashboardVariableMap,
  dp: T,
  datasets: Record<string, ResourceDataset>,
  isEmbed: boolean,
  elements?: DashboardElement[],
  isSecondaryDataRequest?: boolean,
): T {
  let newDp = cloneDeep(dp);
  newDp = processUserInputConfig(variables, newDp, datasets, elements || []);
  if (!isEmbed) {
    newDp = getPivotColumnsQuery(datasets, newDp);
  }
  if (elements) attachLinkFiltersToDp(newDp, datasets, elements, variables);

  if (
    !isSecondaryDataRequest &&
    (dp.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_TABLE ||
      dp.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_REPORT_BUILDER)
  ) {
    newDp = addBaseSchemaToTable(newDp, datasets);
  }

  return newDp;
}

function processUserInputConfig<T extends DataPanel>(
  variables: DashboardVariableMap,
  dp: T,
  datasets: Record<string, ResourceDataset>,
  dashboardElements: DashboardElement[],
): T {
  const filterClauses = dp.filter_op?.instructions.filterClauses;
  filterClauses?.forEach((filterClause) => {
    if (filterClause.filterValueSource === FilterValueSourceType.VARIABLE) {
      const filterVarId = filterClause.filterValueVariableId;
      if (!filterVarId) {
        filterClause.filterValue = undefined;
        return;
      }

      let value = isDrilldownVar(filterVarId)
        ? findDrilldownValue(variables, filterVarId)
        : variables[filterVarId];

      if (filterClause.filterValueVariableProperty) {
        value = value ? value[filterClause.filterValueVariableProperty] : undefined;
      }

      if (value === undefined) {
        // if the variable value is undefined, meaning unset, then it doesn't matter what type
        // the operation or column is, just set the filter value to undefined
        filterClause.filterValue = undefined;
      } else if (filterClause.filterOperation && filterClause.filterColumn) {
        const filterOpId = filterClause.filterOperation.id;
        if (FILTER_OPS_DATE_PICKER.has(filterOpId)) {
          // operations that do date filters on a single date (ie date is after X)
          // have values that take the form of { startDate: x }
          filterClause.filterValue = {
            startDate: value as string,
          };
        } else if (FILTER_OPS_DATE_RANGE_PICKER.has(filterOpId)) {
          const dateRangeValue = value as {
            startDate: string;
            endDate: string;
          };
          filterClause.filterValue = dateRangeValue;
        } else if (FILTER_OPS_RELATIVE_PICKER.has(filterOpId)) {
          // There is no variable element that supports what the relative date picker does
          // so always make the value undefined if it is trying to use a variable for this filter
          filterClause.filterValue = undefined;
        } else if (
          filterOpId === FILTER_OPERATOR_TYPES_BY_ID.STRING_IS_IN.id ||
          filterOpId === FILTER_OPERATOR_TYPES_BY_ID.STRING_IS_NOT_IN.id
        ) {
          filterClause.filterValue = value as string[];
        } else if (
          filterOpId === FILTER_OPERATOR_TYPES_BY_ID.NUMBER_IS_IN.id ||
          filterOpId === FILTER_OPERATOR_TYPES_BY_ID.NUMBER_IS_NOT_IN.id
        ) {
          filterClause.filterValue = value as number[];
        } else {
          if (NUMBER_TYPES.has(filterClause.filterColumn.type)) {
            // if the column being filtered is a number, confirm the value is a number and set it.
            // otherwise set the value to undefined. If the value is not a number, that would result in
            // the SQL code crashing since we'd be trying to filter a number column with a non-number val
            if (isNumber(value) && !isNaN(value)) {
              filterClause.filterValue = value as number;
            } else {
              filterClause.filterValue = undefined;
            }
          } else {
            // similarly, if it is not a number string, ie a char field, then cast the value to a string
            // to prevent type errors on the SQL query when ran
            filterClause.filterValue = String(value);
          }
        }
      }
    }
  });

  const instructions = dp.visualize_op.instructions;
  const twoDimensionInstructions = instructions.V2_TWO_DIMENSION_CHART;

  const colorCategory = variables[dp.provided_id + COLOR_CATEGORY_FILTER_SUFFIX];
  if (twoDimensionInstructions?.colorColumnOptions?.length && colorCategory !== undefined) {
    twoDimensionInstructions.colorColumnOptions.forEach(
      (colOption) => (colOption.selected = colOption.column.name === colorCategory),
    );
  }

  if (twoDimensionInstructions?.categoryColumn?.bucket?.id === DATE_PART_INPUT_AGG) {
    if (twoDimensionInstructions.categoryColumn?.bucketElemId) {
      const selectedGroupInput = variables[twoDimensionInstructions.categoryColumn.bucketElemId];
      twoDimensionInstructions.categoryColumn.bucket =
        TREND_GROUP_OPTION_TO_PIVOT_AGG[selectedGroupInput as TrendGroupingOptions];
    }
  }

  if (twoDimensionInstructions?.categoryColumn?.bucket?.id === PivotAgg.DATE_SMART) {
    formatSmartBucket(
      dp,
      datasets,
      dashboardElements,
      variables,
      filterClauses,
      twoDimensionInstructions.categoryColumn,
    );
  }

  if (
    dp.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2 ||
    dp.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_TREND_TABLE
  ) {
    const config =
      dp.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2
        ? instructions.V2_KPI_TREND
        : instructions.V2_TREND_TABLE;
    if (
      config?.periodColumn?.periodRange === PeriodRangeTypes.DATE_RANGE_INPUT &&
      config.periodColumn.rangeElemId
    ) {
      const rangeVariableValue = variables[config.periodColumn.rangeElemId] as {
        startDate: string;
        endDate: string;
      };

      if (rangeVariableValue) {
        // this should be coming in as a string generated by DateTime.toISO(), but just for safety
        // convert it back to a datetime and back to ISO to make sure the format is correct. fromISO()
        // can take a variety of formats, so this will standardize it
        config.periodColumn.customStartDate = dateTimeFromISOString(
          rangeVariableValue.startDate,
        ).toISO();
        config.periodColumn.customEndDate = dateTimeFromISOString(
          rangeVariableValue.endDate,
        ).toISO();
      } else {
        config.periodColumn.customStartDate = undefined;
        config.periodColumn.customEndDate = undefined;
      }
    } else if (
      config?.periodColumn?.periodRange === PeriodRangeTypes.TIME_PERIOD_DROPDOWN &&
      config.periodColumn.timePeriodElemId
    ) {
      const timePeriodVariableValue = variables[config.periodColumn.timePeriodElemId] as number;

      if (timePeriodVariableValue) {
        config.periodColumn.customStartDate = DateTime.local()
          .minus({ minutes: timePeriodVariableValue })
          .toISO();
        config.periodColumn.customEndDate = DateTime.local().toISO();
      } else {
        config.periodColumn.customStartDate = undefined;
        config.periodColumn.customEndDate = undefined;
      }
    }

    return dp;
  }

  return dp;
}

function getPivotColumnsQuery<T extends DataPanel>(
  datasets: Record<string, ResourceDataset>,
  dp: T,
): T {
  if (dp.visualize_op.instructions.VISUALIZE_PIVOT_TABLE?.joinedColumns?.joinTable?.id) {
    const joinDataset =
      datasets[dp.visualize_op.instructions.VISUALIZE_PIVOT_TABLE.joinedColumns.joinTable.id];
    dp.visualize_op.instructions.VISUALIZE_PIVOT_TABLE.joinedColumns.joinTable.parent_schema_id =
      joinDataset.parent_schema_id;
    dp.visualize_op.instructions.VISUALIZE_PIVOT_TABLE.joinedColumns.joinTable.query =
      joinDataset.query;
  }

  return dp;
}

export const areRequiredUserInputsSet = (variables: DashboardVariableMap, dp: DataPanel) => {
  if (dp.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2) {
    return areRequiredVariablesSet(variables, dp.visualize_op.instructions.V2_KPI_TREND);
  } else if (dp.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_TREND_TABLE) {
    return areRequiredVariablesSet(variables, dp.visualize_op.instructions.V2_TREND_TABLE);
  }

  return true;
};

export const getAsynchronousSecondaryDataInstructions = (
  dataPanel: DataPanel,
  dataset: ResourceDataset,
): DataPanel[] => {
  switch (dataPanel.visualize_op.operation_type) {
    case OPERATION_TYPES.VISUALIZE_TABLE:
    case OPERATION_TYPES.VISUALIZE_REPORT_BUILDER:
      return getSecondaryDataInstructionsForDataTable(dataPanel, dataset);
    case OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2:
    case OPERATION_TYPES.VISUALIZE_TREND_TABLE:
      return [getSecondaryDataForNumberTrendInstructions(dataPanel)];
    default:
      return [];
  }
};

const getSecondaryDataForNumberTrendInstructions = (dataPanel: DataPanel) => {
  const newInstructions = cloneDeep(dataPanel);
  newInstructions.visualize_op.instructions.V2_KPI_TREND = {
    ...newInstructions.visualize_op.instructions.V2_KPI_TREND,
    ...{ trendGrouping: undefined },
  };
  newInstructions.visualize_op.instructions.V2_TREND_TABLE = {
    ...newInstructions.visualize_op.instructions.V2_TREND_TABLE,
    ...{ trendGrouping: undefined },
  };
  return newInstructions;
};

const getSecondaryDataInstructionsForDataTable = (
  dataPanel: DataPanel,
  dataset: ResourceDataset,
): DataPanel[] => {
  const displayOptions = dataPanel.visualize_op.instructions.VISUALIZE_TABLE.schemaDisplayOptions;

  // EndUserDataPanel and CanvasTemplate currently do not use secondary instructions
  if (!displayOptions || !isDataPanelTemplate(dataPanel)) return [];

  const columnsWithDisplayOptions = Object.keys(displayOptions);
  const numberColumnsWithDisplayOptions = columnsWithDisplayOptions.filter((columnName) =>
    dataset.schema?.find((column) => column.name === columnName && NUMBER_TYPES.has(column.type)),
  );
  const columnNames = numberColumnsWithDisplayOptions.filter((column) => {
    const columnDisplayOptions = displayOptions[column] as NumberDisplayOptions;
    return isSecondaryDataRequired(columnDisplayOptions);
  });

  // If no columns match, don't need to get secondary data
  if (columnNames.length === 0) return [];

  const secondaryDataInstructions = columnNames.map((columnName) => ({
    columnName,
    aggregations: [Aggregation.MIN, Aggregation.AVG, Aggregation.MAX],
  }));

  const aggregationDPT = cloneDeep(dataPanel);
  const pivotAggregations = getPivotAggregationsForSecondaryData(
    dataset,
    secondaryDataInstructions,
  );
  aggregationDPT.group_by_op.instructions.aggregations = pivotAggregations;

  return [aggregationDPT];
};

export const getSynchronousSecondaryDataInstructions = (dataPanel: DataPanel): DataPanel[] => {
  switch (dataPanel.visualize_op.operation_type) {
    case OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2:
      return getSynchronousSecondaryDataInstructionsForBoxPlot(dataPanel);
    default:
      return [];
  }
};

const getSynchronousSecondaryDataInstructionsForBoxPlot = (dataPanel: DataPanel): DataPanel[] => {
  const calcColumns = dataPanel.visualize_op.instructions.V2_BOX_PLOT?.calcColumns;

  if (calcColumns === undefined || (calcColumns && calcColumns.length < 2)) return [];

  const additionalCalcColumnsToFetch = calcColumns.slice(1);

  return additionalCalcColumnsToFetch.map((calcColumn) => {
    const dataPanelTemplateForCalcColumn = cloneDeep(dataPanel);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    dataPanelTemplateForCalcColumn.visualize_op.instructions.V2_BOX_PLOT!.calcColumns = [
      calcColumn,
    ];

    return dataPanelTemplateForCalcColumn;
  });
};

const getPivotAggregationsForSecondaryData = (
  dataset: ResourceDataset,
  secondaryDataInstructions: {
    columnName: string;
    aggregations: Aggregation[];
  }[],
) => {
  const aggregations: PivotOperationAggregation[] = [];

  secondaryDataInstructions.forEach((instruction) => {
    const columnInfo = dataset.schema?.find((column) => column.name === instruction.columnName);

    instruction.aggregations.forEach((aggregation) => {
      aggregations.push({
        aggedOnColumn: columnInfo ?? null,
        type: AGGREGATIONS_TYPES[aggregation],
      });
    });
  });

  return aggregations;
};

export const getGradientColor = ({
  gradient,
  gradientType,
  gradientOptions,
  value,
  metrics,
}: {
  value: number;
  metrics: NumberColumnMetrics;
  gradient?: Partial<GradientShape>;
  gradientType?: GradientType;
  gradientOptions?: GradientOptions;
}) => {
  const color1 = gradient?.hue1 || DEFAULT_GRADIENT.hue1;
  const color2 = gradient?.hue2 || DEFAULT_GRADIENT.hue2;
  const color3 = gradient?.hue3 || DEFAULT_GRADIENT.hue3;

  const minpoint =
    (gradientOptions?.minpoint?.type === GradientPointType.NUMBER
      ? gradientOptions?.minpoint?.number
      : metrics.min) ?? 0;
  const midpoint =
    (gradientOptions?.midpoint?.type === GradientPointType.NUMBER
      ? gradientOptions?.midpoint?.number
      : metrics.avg) ?? 0;
  const maxpoint =
    (gradientOptions?.maxpoint?.type === GradientPointType.NUMBER
      ? gradientOptions?.maxpoint?.number
      : metrics.max) ?? 0;

  const boundRatioZeroToOne = (ratio: number) => {
    if (ratio > 1) return 1;
    else if (ratio < 0) return 0;
    return ratio;
  };

  let color = undefined;
  if (gradientType === GradientType.LINEAR) {
    const ratio = getPercentageRatio(minpoint, maxpoint, value);
    color = mixColors(color3, color1, boundRatioZeroToOne(ratio)).rgb().string();
  } else if (gradientType === GradientType.DIVERGING) {
    if (value < (midpoint ?? 0)) {
      const ratio = getPercentageRatio(minpoint, midpoint, value);
      color = mixColors(color2, color1, boundRatioZeroToOne(ratio)).rgb().string();
    } else {
      const ratio = getPercentageRatio(midpoint, maxpoint, value);
      color = mixColors(color3, color2, boundRatioZeroToOne(ratio)).rgb().string();
    }
  }

  return color;
};

export const updateUserInputFieldsWithNewElemName = (
  config: DashboardVersionConfig,
  oldName: string,
  newName: string,
) => {
  Object.values(config.data_panels).forEach((dpt) => {
    if (dpt.visualize_op?.instructions.V2_KPI_TREND?.periodColumn?.rangeElemId === oldName) {
      dpt.visualize_op.instructions.V2_KPI_TREND.periodColumn.rangeElemId = newName;
    } else if (
      dpt.visualize_op?.instructions.V2_TREND_TABLE?.periodColumn?.rangeElemId === oldName
    ) {
      dpt.visualize_op.instructions.V2_TREND_TABLE.periodColumn.rangeElemId = newName;
    }

    dpt.filter_op?.instructions.filterClauses.forEach((filterClause) => {
      if (filterClause.filterValueVariableId === oldName) {
        filterClause.filterValueVariableId = newName;
      }
    });

    if (
      dpt.visualize_op?.instructions.V2_TWO_DIMENSION_CHART?.categoryColumn?.bucketElemId ===
      oldName
    ) {
      dpt.visualize_op.instructions.V2_TWO_DIMENSION_CHART.categoryColumn.bucketElemId = newName;
    }
  });
};

export const updateUserInputFieldsWithDeletedElem = (
  config: DashboardVersionConfig,
  deletedElemIds: string[],
) => {
  if (deletedElemIds.length === 0) return;

  const isDeleted = (elemId: string | undefined): boolean => {
    if (elemId === undefined) return false;
    return deletedElemIds.includes(elemId);
  };
  /* eslint-disable  @typescript-eslint/no-non-null-assertion */
  Object.values(config.data_panels).forEach((dpt) => {
    if (isDeleted(dpt.visualize_op?.instructions.V2_KPI_TREND?.periodColumn?.rangeElemId)) {
      dpt.visualize_op.instructions.V2_KPI_TREND!.periodColumn!.rangeElemId = undefined;
      dpt.visualize_op.instructions.V2_KPI_TREND!.periodColumn!.periodRange =
        PeriodRangeTypes.LAST_4_WEEKS;
    } else if (
      isDeleted(dpt.visualize_op?.instructions.V2_TREND_TABLE?.periodColumn?.rangeElemId)
    ) {
      dpt.visualize_op.instructions.V2_TREND_TABLE!.periodColumn!.rangeElemId = undefined;
      dpt.visualize_op.instructions.V2_TREND_TABLE!.periodColumn!.periodRange =
        PeriodRangeTypes.LAST_4_WEEKS;
    }

    dpt.filter_op?.instructions.filterClauses.forEach((filterClause) => {
      if (isDeleted(filterClause.filterValueVariableId)) {
        filterClause.filterValueVariableId = undefined;
        filterClause.filterValueVariableProperty = undefined;
      }
    });

    if (
      isDeleted(dpt.visualize_op?.instructions.V2_TWO_DIMENSION_CHART?.categoryColumn?.bucketElemId)
    ) {
      dpt.visualize_op.instructions.V2_TWO_DIMENSION_CHART!.categoryColumn!.bucketElemId =
        undefined;
      dpt.visualize_op.instructions.V2_TWO_DIMENSION_CHART!.categoryColumn!.bucket =
        PIVOT_AGG_TYPES.DATE_MONTH;
    }
  });
  /* eslint-enable  @typescript-eslint/no-non-null-assertion */
};

export const getMetricsByColumn = (secondaryData: DatasetRow[]): MetricsByColumn => {
  const metrics = secondaryData[0];
  if (!metrics) return {};

  const metricsByColumn: { [columnName: string]: NumberColumnMetrics } = {};

  Object.keys(metrics).forEach((columnNameAgg) => {
    const stringArr = columnNameAgg.split('_');
    const columnName = stringArr.slice(0, stringArr.length - 1).join('_');
    const agg = stringArr[stringArr.length - 1];

    metricsByColumn[columnName] = {
      ...metricsByColumn[columnName],
      [agg]: metrics[columnNameAgg],
    };
  });

  return metricsByColumn;
};

export const filterForValidFilterElementsBasedOnType = (
  dashboardElements?: DashboardElement[],
  dashboardParams?: Record<string, DashboardParam>,
  filterOperator?: FilterOperator,
) => {
  if (!filterOperator || !dashboardElements || !dashboardParams) return [];
  const params = Object.values(dashboardParams);
  const elemOptions: { id: string; name: string }[] = [];
  let dashElems: DashboardElement[] = [];
  let customVars: DashboardParam[] = [];

  if (FILTER_OPS_DATE_PICKER.has(filterOperator)) {
    dashboardElements.forEach((elem) => {
      if (elem.element_type === DASHBOARD_ELEMENT_TYPES.DATEPICKER) {
        dashElems.push(elem);
      } else if (elem.element_type === DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER) {
        const elemName = elem.name;
        elemOptions.push({ id: `${elemName}.startDate`, name: `${elemName}.startDate` });
        elemOptions.push({ id: `${elemName}.endDate`, name: `${elemName}.endDate` });
      }
    });
    customVars = params.filter((elem) => elem.type === 'TIMESTAMP');
  } else if (FILTER_OPS_DATE_RANGE_PICKER.has(filterOperator)) {
    dashElems = dashboardElements.filter(
      (elem) => elem.element_type === DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER,
    );
  } else if (FILTER_OPS_MULTISELECT.has(filterOperator)) {
    dashElems = dashboardElements.filter(
      (elem) => elem.element_type === DASHBOARD_ELEMENT_TYPES.MULTISELECT,
    );
  } else if (FILTER_OPS_STRING.has(filterOperator)) {
    dashElems = dashboardElements.filter(
      (elem) =>
        elem.element_type === DASHBOARD_ELEMENT_TYPES.DROPDOWN ||
        elem.element_type === DASHBOARD_ELEMENT_TYPES.SWITCH ||
        elem.element_type === DASHBOARD_ELEMENT_TYPES.TOGGLE ||
        elem.element_type === DASHBOARD_ELEMENT_TYPES.TEXT_INPUT,
    );
    customVars = params.filter((elem) => elem.type === 'STRING');
  } else if (FILTER_OPS_NUMBER.has(filterOperator)) {
    dashElems = dashboardElements.filter(
      (elem) =>
        elem.element_type === DASHBOARD_ELEMENT_TYPES.DROPDOWN ||
        elem.element_type === DASHBOARD_ELEMENT_TYPES.TOGGLE,
    );
    customVars = params.filter((elem) => elem.type === 'FLOAT' || elem.type === 'INTEGER');
  }
  const elems = dashElems.map((elem) => ({ id: elem.name, name: elem.name }));
  const custom = customVars.map((elem) => ({ id: elem.name, name: elem.name }));
  return elemOptions.concat(elems.concat(custom));
};

export const newOperatorShouldClearSelectedVariable = (
  newOperator?: FilterOperator,
  oldOperator?: FilterOperator,
) => {
  if (!newOperator || !oldOperator) return true;
  if (FILTER_OPS_DATE_PICKER.has(newOperator) !== FILTER_OPS_DATE_PICKER.has(oldOperator))
    return true;
  if (
    FILTER_OPS_DATE_RANGE_PICKER.has(newOperator) !== FILTER_OPS_DATE_RANGE_PICKER.has(oldOperator)
  )
    return true;
  if (FILTER_OPS_RELATIVE_PICKER.has(newOperator) !== FILTER_OPS_RELATIVE_PICKER.has(oldOperator))
    return true;
  return false;
};

export const newOperatorDoesntHaveVariableOption = (filterOperator?: FilterOperator) => {
  return !filterOperator || FILTER_OPS_RELATIVE_PICKER.has(filterOperator);
};

export const getDateGroupSwitchOptions = (config: DateGroupToggleConfig) => {
  return compact(
    Object.values(TREND_GROUPING_OPTIONS).map((groupingOption) => {
      const configForOption = config.groupingOptionByType?.[groupingOption.id];

      if (configForOption?.exclude) return null;

      return {
        name: configForOption?.name || groupingOption.name,
        id: groupingOption.id,
      };
    }),
  );
};

export const getDefaultValueForNewElem = (elem: DashboardElement) => {
  if (elem.element_type === DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH) {
    return TrendGroupingOptions.MONTHLY;
  } else if (elem.element_type === DASHBOARD_ELEMENT_TYPES.SWITCH) {
    return 'false';
  }
};

export const getUserTransformedSchema = (
  schema: DatasetSchema,
  instructions: VisualizeTableInstructions,
  dataset?: ResourceDataset,
): UserTransformedSchema => {
  const changedSchema = getChangedSchema(schema, instructions, dataset);
  const changeSchemaList = instructions.changeSchemaList;
  const changeSchemaDictionary = keyBy(changeSchemaList, 'col');
  const userTransformedSchema = changedSchema.map((column) => ({
    ...column,
    isVisible: !changeSchemaDictionary[column.name]?.hideCol,
  }));
  return userTransformedSchema;
};

export const getChangedSchema = (
  schema: DatasetSchema,
  instructions: VisualizeTableInstructions | VisualizePivotTableInstructions,
  dataset?: ResourceDataset,
) => {
  //Produce (can't change schema from state)
  const clonedSchema = cloneDeep(schema);

  const changeSchemaList = instructions.changeSchemaList || [];
  const changedSchema: DatasetSchema = [];
  const changeSchemaDictionary = keyBy(changeSchemaList, 'col');
  const columnOptions = dataset && isCanvasDataset(dataset) ? dataset.columnOptions : null;
  clonedSchema.forEach((columnInfo) => {
    const col = changeSchemaDictionary[columnInfo.name];
    const keepCol = col?.keepCol ?? true;
    if (columnOptions) {
      // Want to be stricter with EUD so if it was not in schema when saved
      // will not display column
      const columnOption: CanvasColumnOption | undefined = columnOptions[columnInfo.name];
      if (columnOption?.isVisible && keepCol) {
        columnInfo.friendly_name = columnOption.name;
        changedSchema.push(columnInfo);
      }
    } else if (col) {
      if (keepCol) {
        if (col.newColName !== null && col.newColName !== '') {
          columnInfo.friendly_name = col.newColName;
        } else {
          columnInfo.friendly_name = titleCase(columnInfo.name);
        }
        changedSchema.push(columnInfo);
      }
    } else {
      columnInfo.friendly_name = titleCase(columnInfo.name);
      changedSchema.push(columnInfo);
    }
  });
  return changedSchema;
};

export const getExcludedColumns = (
  schema: DatasetSchema,
  instructions: VisualizeTableInstructions,
) => {
  const changeSchemaList = instructions.changeSchemaList;
  const changeSchemaDictionary = keyBy(changeSchemaList, 'col');
  return schema.filter((columnInfo: DatasetColumn) => {
    const col = changeSchemaDictionary[columnInfo.name];
    if (col) return !col.keepCol;

    return false;
  });
};

export const getHiddenColumns = (
  schema: DatasetSchema,
  instructions: VisualizeTableInstructions,
) => {
  const hiddenSet = new Set<string>();
  const changeSchemaList = instructions.changeSchemaList;
  const changeSchemaDictionary = keyBy(changeSchemaList, 'col');
  schema.forEach((columnInfo) => {
    if (changeSchemaDictionary[columnInfo.name]?.hideCol) {
      hiddenSet.add(columnInfo.name);
    }
  });
  return hiddenSet;
};

export const removeUserDisabledColumns = (schema: UserTransformedSchema) => {
  return schema.filter((column) => column.isVisible);
};

export const getLayoutFromDashboardVersionConfig = (
  config: DashboardVersionConfig,
  viewMode: VIEW_MODE,
) => {
  const { pdf_layout, email_layout, mobile_layout } = config;
  if (viewMode === VIEW_MODE.PDF && pdf_layout) return pdf_layout;
  if (viewMode === VIEW_MODE.EMAIL && email_layout) return email_layout;
  if (viewMode === VIEW_MODE.MOBILE && mobile_layout) return mobile_layout;
  return config.dashboard_layout;
};

export const elementBlockedOnApplyButton = (
  dashboardElements: DashboardElement[],
  elemId?: string,
): string | undefined => {
  if (!elemId) return;

  for (let i = 0; i < dashboardElements.length; i++) {
    const elem = dashboardElements[i];
    if (elem.element_type === DASHBOARD_ELEMENT_TYPES.APPLY_FILTER_BUTTON) {
      const config = elem.config as ApplyFilterElemConfig;
      if (config.elementIds?.[elemId]) return elemId;
    }
  }
};

export const isElemDisabledByDependency = (
  config: DashboardElementConfig,
  variables: DashboardVariableMap,
  dashboardElementsById: Record<string, DashboardElement>,
): boolean => {
  if (!config.dependencyElementIds) return false;

  const dependencyIds = Object.keys(config.dependencyElementIds).filter(
    (elemId) => config.dependencyElementIds?.[elemId],
  );

  if (dependencyIds.length === 0) return false;

  return dependencyIds.some((id) => {
    const element: DashboardElement | undefined = dashboardElementsById[id];
    if (!element) return false;
    return variables[element.name] === undefined;
  });
};

export const resetDependedElements = (
  elementId: string,
  elements: DashboardElement[],
  variables: DashboardVariableMap,
): void => {
  elements.forEach((elem) => {
    const dependencies = elem.config.dependencyElementIds;
    if (dependencies && dependencies[elementId]) {
      if (variables[elem.name] !== undefined) {
        variables[elem.name] = undefined;
        resetDependedElements(elem.id, elements, variables);
      }
    }
  });
};

export const resetDependentBlockedElements = (
  elementId: string,
  elements: DashboardElement[],
  blockedVariables: DashboardVariableMap,
): void => {
  elements.forEach((elem) => {
    const dependencies = elem.config.dependencyElementIds;
    if (dependencies && dependencies[elementId]) {
      const elemNotInBlockedVars = !(elem.id in blockedVariables);
      if (elemNotInBlockedVars || blockedVariables[elem.id] !== undefined) {
        // produce doesn't actually set it as undefined if no value was set for it already
        // and we need the distinction for setting vars as undefined after applied
        if (elemNotInBlockedVars) blockedVariables[elem.id] = 'temp';

        blockedVariables[elem.id] = undefined;
        resetDependentBlockedElements(elem.id, elements, blockedVariables);
      }
    }
  });
};

export const isDatasetInUse = (
  datasetId: string,
  dashboardElements: DashboardElement[],
  dataPanels: DataPanelTemplate[],
) => {
  const elementsInUse = getElementsUsingDataset(dashboardElements, datasetId).map(
    (elem) => elem.name,
  );

  const dataPanelsInUse = getDataPanelsUsingDataset(dataPanels, datasetId).map(
    (dp) => dp.provided_id,
  );

  return { dataPanelsInUse, elementsInUse };
};

export const doesElementStartOnRightHalfOfPage = (
  dashboardLayout: ReactGridLayout.Layout[],
  elementId: string,
  dashboardWidth: number,
  containerXValue?: number,
) => {
  const layoutElem = dashboardLayout.find((elem) => elem.i === elementId);
  if (!layoutElem) return false;

  return layoutElem.x + (containerXValue ?? 0) >= dashboardWidth / 2;
};

export const doesElementEndOnRightHalfOfPage = (
  dashboardLayout: ReactGridLayout.Layout[],
  elementId: string,
  dashboardWidth: number,
  containerXValue?: number,
) => {
  const layoutElem = dashboardLayout.find((elem) => elem.i === elementId);
  if (!layoutElem) return false;

  return layoutElem.x + layoutElem.w + (containerXValue ?? 0) > dashboardWidth / 2;
};

export const getDefaultValueFromRows = (
  { querySortOption, queryDisplayColumn, queryValueColumn }: DropdownValuesConfig,
  rows: DatasetRow[],
): { defaultValue?: string | number; defaultDisplay?: string } => {
  if (!queryValueColumn || rows.length === 0) return {};
  let sortedRows = rows;

  const searchColumn = queryDisplayColumn ? queryDisplayColumn.name : queryValueColumn.name;
  if (querySortOption && querySortOption !== MultiSelectSortOption.DEFAULT) {
    sortedRows = orderBy(
      rows,
      (row) => row[searchColumn],
      querySortOption === MultiSelectSortOption.ASC ? 'asc' : 'desc',
    );
  }

  const defaultValue = sortedRows[0][queryValueColumn.name];
  const defaultDisplay = String(sortedRows[0][searchColumn]);
  return { defaultValue, defaultDisplay };
};

// returns True if the element was found and removed
export const removeElemFromStickyHeader = (
  config: DashboardPageLayoutConfig | undefined,
  elemIdToRemove: string,
) => {
  if (!config?.stickyHeader?.headerContentOrder) return;

  const removedColIndex = config.stickyHeader.headerContentOrder.findIndex(
    (elemId) => elemIdToRemove === elemId,
  );
  if (removedColIndex !== -1) {
    config.stickyHeader.headerContentOrder.splice(removedColIndex, 1);
  }

  return removedColIndex >= 0;
};

export function filterForContainerElements<T extends DashboardElement | DataPanel>(
  elements: T[],
  containerId?: string,
  filterElemsNotOnBody?: boolean,
) {
  const [elemsInContainer, elemsNotInContainer] = partition(elements, (element) => {
    if (filterElemsNotOnBody) {
      const elemConfig = element as DashboardElement;

      // checking if not in the body rather than if is in the header so that in the future
      // when we have multiple non-body interfaces, this still works
      if (
        elemConfig.elemLocation !== undefined &&
        elemConfig.elemLocation !== DASHBOARD_LAYOUT_CONFIG.DASHBOARD_BODY
      )
        return false;
    }

    if (!elementHasContainer(element)) return true;

    return containerId
      ? element.container_id === containerId
      : element.container_id === undefined || element.container_id === null;
  });

  return {
    elemsInContainer,
    elemsNotInContainer,
  };
}
