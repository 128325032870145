import { FC, useState } from 'react';

import { ApplyFilterRow } from './ApplyFilterRow';
import { FilterDropdown } from './FilterDropdown';
import * as styles from './styles.css';

import { CustomerReportFilter } from 'actions/customerReportActions';
import { FilterableColumn, getFilterDefaultOperation } from 'utils/customerReportUtils';
import {
  FilterOperator,
  FILTER_OPERATORS,
  FILTER_OPERATOR_TYPES_BY_ID,
} from 'types/filterOperations';
import { BOOLEAN } from 'constants/dataConstants';

type Props = {
  clause?: CustomerReportFilter;
  column: FilterableColumn;
  holdOpen: (hold: boolean) => void;
};

const booleanOptions = FILTER_OPERATORS.filter((op) => op.supported_column_types.has(BOOLEAN));

export const BooleanFilterPopover: FC<Props> = ({ column, clause, holdOpen }) => {
  const [currOperator, setOperator] = useState(
    getFilterDefaultOperation(column.type, clause?.filterOperation.id),
  );

  const operator = FILTER_OPERATOR_TYPES_BY_ID[currOperator];

  return (
    <div className={styles.booleanContent}>
      <FilterDropdown
        items={booleanOptions}
        onOpenChange={holdOpen}
        onSelect={(id) => setOperator(id as FilterOperator)}
        selectedItem={{ id: operator.id, name: operator.selectionValue }}
      />
      <ApplyFilterRow clause={clause} column={column} operator={operator.id} />
    </div>
  );
};
