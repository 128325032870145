import { FC } from 'react';
import produce from 'immer';
import { useDispatch, useSelector } from 'react-redux';

import { Switch } from 'components/ds';
import SettingHeader from '../../DataConfigTab/SettingHeader';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';

import { ReduxState } from 'reducers/rootReducer';
import { updateGeneralFormatOptions } from 'actions/dataPanelConfigActions';
import {
  OPERATION_TYPES,
  VISUALIZE_TABLE_OPERATIONS,
  VisualizeOperationGeneralFormatOptions,
} from 'constants/types';
import { sharedStyles } from './styles';

type Props = {
  visualizationType: OPERATION_TYPES;
  dptProvidedId: string;
  generalFormatOptions: VisualizeOperationGeneralFormatOptions;
};

export const ExportConfig: FC<Props> = ({
  visualizationType,
  dptProvidedId,
  generalFormatOptions,
}) => {
  const dispatch = useDispatch();
  const classes = sharedStyles();

  const shouldUseJobQueue = useSelector(
    (state: ReduxState) => !!state.currentUser.team?.feature_flags.use_job_queue,
  );

  const setGeneralFormatOptions = (
    update: (draft: VisualizeOperationGeneralFormatOptions) => void,
  ) => {
    const newOptions = produce(generalFormatOptions, update);
    dispatch(updateGeneralFormatOptions(newOptions));
  };

  return (
    <>
      <SettingHeader name="File Export" />
      <div className={classes.root}>
        <Switch
          useCustomStyles
          className={classes.configInput}
          label="Show export button"
          onChange={() =>
            setGeneralFormatOptions((draft) => {
              if (!draft.export) draft.export = {};
              draft.export.isDownloadButtonHidden = !draft.export.isDownloadButtonHidden;
            })
          }
          switchOn={!generalFormatOptions.export?.isDownloadButtonHidden}
        />
        {!generalFormatOptions.export?.isDownloadButtonHidden ? (
          <>
            <InputWithBlurSave
              containerClassName={classes.configInput}
              initialValue={generalFormatOptions.export?.downloadFileName || dptProvidedId}
              label="Download file name"
              onNewValueSubmitted={(newValue) =>
                setGeneralFormatOptions((draft) => {
                  if (!draft.export) draft.export = {};
                  draft.export.downloadFileName = newValue;
                })
              }
            />

            <Switch
              useCustomStyles
              className={classes.configInput}
              label="Enable download export"
              onChange={() =>
                setGeneralFormatOptions((draft) => {
                  if (!draft.export) draft.export = {};
                  draft.export.disableDownloadExport = !draft.export.disableDownloadExport;
                })
              }
              switchOn={!generalFormatOptions.export?.disableDownloadExport}
            />
            {shouldUseJobQueue ? (
              <Switch
                useCustomStyles
                className={classes.configInput}
                label="Enable email export"
                onChange={() =>
                  setGeneralFormatOptions((draft) => {
                    if (!draft.export) draft.export = {};
                    draft.export.enableEmailExport = !draft.export.enableEmailExport;
                  })
                }
                switchOn={!!generalFormatOptions.export?.enableEmailExport}
              />
            ) : null}
            {VISUALIZE_TABLE_OPERATIONS.includes(visualizationType) && (
              <Switch
                useCustomStyles
                className={classes.configInput}
                label="Enable PDF export"
                onChange={() =>
                  setGeneralFormatOptions((draft) => {
                    if (!draft.export) draft.export = {};
                    draft.export.disablePdfDownload = !draft.export.disablePdfDownload;
                  })
                }
                switchOn={!generalFormatOptions.export?.disablePdfDownload}
              />
            )}
          </>
        ) : null}
      </div>
    </>
  );
};
