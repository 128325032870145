import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import { RouteComponentProps } from 'react-router';
import { createLoadingSelector } from 'reducers/api/selectors';
import * as RD from 'remotedata';

import { sprinkles, AlertModal } from 'components/ds';
import EmptyState from 'components/EmptyPageActionCallout';
import { PageHeader } from 'components/PageHeader';
import TablePager from 'components/dataTable/tablePager';
import CustomersModal from 'pages/customersPage/customersModal';
import CustomerGroupListItem from 'pages/customersPage/customerGroupListItem';
import ExploLoadingSpinner from 'components/ExploLoadingSpinner';

import { fetchCustomers, addCustomer, editCustomer, deleteCustomer } from 'actions/customerActions';
import { fetchDashboardTemplateList } from 'actions/dashboardTemplateActions';
import { ACTION } from 'actions/types';
import { AccessGroup, EndUser, Customer, fetchUserTeam } from 'actions/teamActions';
import { listTeamDataSources } from 'actions/dataSourceActions';
import { fetchUsedParentSchemas } from 'actions/parentSchemaActions';
import {
  fetchEndUserList,
  createEndUser,
  deleteEndUser,
  editEndUser,
} from 'actions/endUserActions';
import { fetchAccessGroups } from 'actions/rolePermissionActions';

import { Intent, Tag } from 'components/ds';
import { pageView } from 'analytics/exploAnalytics';
import { showErrorContactSupportToast, showErrorToast } from 'shared/sharedToasts';
import { doesCustomerHaveInvalidAccessGroup } from 'utils/architectCustomerDashboardUtils';
import { partition } from 'utils/standard';
import { doesUserHavePermission } from 'utils/permissionUtils';
import { PERMISSIONED_ACTIONS, PERMISSIONED_ENTITIES } from 'constants/roleConstants';
import { isCreateCustomersDisabled } from 'utils/paymentPlanUtils';
import { PLAN_TYPES } from 'constants/paymentPlanConstants';

const rootStyles = sprinkles({
  flexItems: 'column',
  flex: 1,
});

const scrollStyles = sprinkles({
  parentContainer: 'fill',
  overflowY: 'auto',
});

const contentStyles = sprinkles({
  paddingY: 'sp2',
  paddingX: 'sp3',
  parentContainer: 'fill',
  flexItems: 'column',
  widthConstraints: 'minAndMax',
});

const listContainerStyles = sprinkles({
  marginTop: 'sp2',
  borderBottom: undefined,
  borderRadius: 3,
  flex: 1,
});

const pagerFooterContainerStyles = sprinkles({
  flexItems: 'alignCenter',
  justifyContent: 'space-between',
  paddingTop: 'sp1',
});

type MatchParams = {};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps<MatchParams>;

type State = {
  searchString: string;
  createCustomersModalOpen: boolean;
  editCustomerModalOpen: boolean;
  deleteCustomerConfirmModalOpen: boolean;
  selectedGroup?: Customer;
  groupPage: number;
  accessGroups?: AccessGroup[];
  customerPermissions: string[];
};

const GROUPS_PER_PAGE = 20;

class CustomersPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    props.listTeamDataSources();

    document.title = 'Explo | Customers';

    props.fetchCustomers();
    props.fetchUsedParentSchemas();
    props.fetchDashboardTemplateList({ id: props.currentUser.team?.id });
    props.fetchEndUserList();
    props.fetchUserTeam();
    props.fetchAccessGroups(undefined, ({ access_groups }) => {
      this.setState({ accessGroups: access_groups });
    });

    this.state = {
      searchString: '',
      createCustomersModalOpen: false,
      editCustomerModalOpen: false,
      deleteCustomerConfirmModalOpen: false,
      selectedGroup: undefined,
      groupPage: 0,
      customerPermissions: props.currentUser.permissions[PERMISSIONED_ENTITIES.CUSTOMER],
    };
  }

  componentDidMount() {
    listTeamDataSources();
    pageView('Customers Page');
  }

  render() {
    const {
      parentSchemaLoading,
      dashboardTemplateListLoading,
      endUserListLoading,
      accessGroupListLoading,
      customers,
    } = this.props;

    if (
      RD.isLoading(customers) ||
      parentSchemaLoading ||
      dashboardTemplateListLoading ||
      endUserListLoading ||
      accessGroupListLoading
    ) {
      return <ExploLoadingSpinner />;
    }

    return (
      <div className={rootStyles}>
        {this.renderHeader()}
        <div className={scrollStyles}>
          <div className={contentStyles}>
            {this.renderAddGroupModal()}
            {this.renderEmptyState()}
            {this.renderList()}
            {this.renderPager()}
            {this.renderEditModal()}
            {this.renderDeleteCustomerConfirmModal()}
          </div>
        </div>
      </div>
    );
  }

  renderEmptyState = () => {
    const { customers } = this.props;

    if (!RD.isSuccess(customers) || customers.data.length === 0) {
      return (
        <>
          <EmptyState text="Connect to our API to sync your customer list, or add customers manually here" />
          <div className={listContainerStyles} />
        </>
      );
    }
  };

  renderAddGroupModal = () => {
    const {
      addCustomer,
      dataSources,
      parentSchemas,
      dashboardTemplateList,
      endUserList,
      createEndUser,
    } = this.props;
    const { createCustomersModalOpen, accessGroups } = this.state;
    if (
      !createCustomersModalOpen ||
      !parentSchemas ||
      !dashboardTemplateList ||
      !endUserList ||
      !accessGroups
    )
      return;
    return (
      <CustomersModal
        accessGroups={accessGroups}
        buttonName="Create"
        closeModal={() => this.setState({ createCustomersModalOpen: false })}
        dashboardTemplates={dashboardTemplateList}
        dataSources={dataSources ?? []}
        endUsers={[]}
        modalTitle="Add a Customer"
        onSubmit={(
          name: string,
          id: string,
          mapping: Record<string, string>,
          endUsers: EndUser[],
          accessGroupId: number,
          isDemoGroup: boolean,
          properties: Record<string, string>,
          permissioned_dashboard_id?: number,
        ) => {
          addCustomer(
            {
              postData: {
                name: name,
                id: id,
                mapping: mapping,
                properties: properties,
                access_group_id: accessGroupId,
                is_demo_group: isDemoGroup,
              },
            },
            (response) => {
              const promiseList: unknown[] = [];
              let hasErrors = false;

              endUsers.forEach((user) =>
                promiseList.push(
                  createEndUser(
                    {
                      postData: {
                        email: user.email,
                        customer_id: response.customer.id,
                        permissioned_dashboard_id: permissioned_dashboard_id,
                      },
                    },
                    undefined,
                    () => (hasErrors = true),
                  ),
                ),
              );

              Promise.all(promiseList)
                .then(() => {
                  if (hasErrors)
                    showErrorToast(
                      'Something went wrong and not all of your end users may have saved. Please try again, or contact Explo support if this continues.',
                      7,
                    );
                })
                .finally(() => this.setState({ createCustomersModalOpen: false }));
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (response: any) => {
              this.setState({ createCustomersModalOpen: false });
              showErrorContactSupportToast(response.error_msg);
            },
          );
        }}
        parentSchemas={parentSchemas}
      />
    );
  };

  renderEditModal = () => {
    const { editCustomerModalOpen, selectedGroup, accessGroups } = this.state;
    const {
      dataSources,
      editCustomer,
      parentSchemas,
      dashboardTemplateList,
      endUserList,
      createEndUser,
      editEndUser,
      deleteEndUser,
    } = this.props;
    if (
      !editCustomerModalOpen ||
      !parentSchemas ||
      !dashboardTemplateList ||
      !endUserList ||
      !accessGroups
    )
      return;

    const endUsersForGroup = endUserList.filter((user) => user.group_id === selectedGroup?.id);
    return (
      <CustomersModal
        accessGroups={accessGroups}
        buttonName="Confirm"
        closeModal={() => this.setState({ editCustomerModalOpen: false })}
        dashboardTemplates={dashboardTemplateList}
        dataSources={dataSources ?? []}
        endUsers={endUsersForGroup}
        modalTitle="Edit Customer"
        onSubmit={(
          name: string,
          id: string,
          mapping: Record<string, string>,
          endUsers: EndUser[],
          accessGroupId: number,
          isDemoGroup: boolean,
          properties: Record<string, string>,
          permissioned_dashboard_id?: number,
        ) => {
          const promiseList: unknown[] = [];
          let hasErrors = false;

          const shouldEditCustomer =
            name !== selectedGroup?.name ||
            id !== selectedGroup?.provided_id ||
            mapping !== selectedGroup?.parent_schema_datasource_mapping ||
            accessGroupId !== (selectedGroup?.access_group_id ?? -1) ||
            properties !== selectedGroup?.properties ||
            isDemoGroup !== selectedGroup?.is_demo_group;

          if (shouldEditCustomer) {
            promiseList.push(
              editCustomer(
                {
                  id: selectedGroup?.id,
                  postData: {
                    name: name,
                    mapping: mapping,
                    provided_id: id,
                    access_group_id: accessGroupId,
                    is_demo_group: isDemoGroup,
                    properties: properties,
                  },
                },
                undefined,
                () => (hasErrors = true),
              ),
            );
          }

          endUsers.forEach((user) => {
            if (user.id >= 0) return;
            promiseList.push(
              createEndUser(
                {
                  postData: {
                    email: user.email,
                    customer_id: selectedGroup?.id,
                    permissioned_dashboard_id: permissioned_dashboard_id,
                  },
                },
                undefined,
                () => (hasErrors = true),
              ),
            );
          });

          const deletedUsers = endUsersForGroup.filter(
            (user) => !endUsers.find((eu) => eu.id === user.id),
          );
          deletedUsers.forEach((user) =>
            promiseList.push(deleteEndUser({ id: user.id }, undefined, () => (hasErrors = true))),
          );

          const editedUsers = endUsers.filter(
            (user) =>
              user.id > 0 &&
              !endUsersForGroup.find(
                (eu) =>
                  eu.email === user.email &&
                  eu.permissioned_dashboard_id === permissioned_dashboard_id,
              ),
          );
          editedUsers.forEach((user) =>
            promiseList.push(
              editEndUser(
                {
                  id: user.id,
                  postData: {
                    email: user.email,
                    permissioned_dashboard_id: permissioned_dashboard_id,
                  },
                },
                undefined,
                () => (hasErrors = true),
              ),
            ),
          );

          Promise.all(promiseList)
            .then(() => {
              if (hasErrors)
                showErrorToast(
                  'Something went wrong and not all of your changes may have saved. Please try again, or contact Explo support if this continues.',
                  7,
                );
            })
            .finally(() => this.setState({ editCustomerModalOpen: false }));
        }}
        parentSchemas={parentSchemas}
        selectedGroup={selectedGroup}
      />
    );
  };

  renderDeleteCustomerConfirmModal = () => {
    const { deleteCustomerConfirmModalOpen, selectedGroup } = this.state;
    const { deleteCustomer } = this.props;

    if (!deleteCustomerConfirmModalOpen) return;

    const onDeleteCustomer = () => {
      this.setState({ deleteCustomerConfirmModalOpen: false });
      selectedGroup &&
        deleteCustomer({ id: selectedGroup.id }, () => this.setState({ selectedGroup: undefined }));
    };
    return (
      <AlertModal
        actionButtonProps={{ onClick: onDeleteCustomer }}
        isOpen={deleteCustomerConfirmModalOpen}
        onClose={() => this.setState({ deleteCustomerConfirmModalOpen: false })}
        title="Are you sure you want to delete this customer?"
      />
    );
  };

  renderHeader = () => {
    const { customerPermissions } = this.state;
    const { paymentPlan } = this.props;

    const numCustomers = this.getFilteredCustomers().length;
    const createCustomerDisabledForPaymentPlan = isCreateCustomersDisabled(
      numCustomers,
      paymentPlan,
    );

    return (
      <PageHeader
        pageTitle="Customers"
        primaryActionProps={
          doesUserHavePermission(customerPermissions, PERMISSIONED_ACTIONS.CREATE)
            ? {
                disabled: createCustomerDisabledForPaymentPlan,
                text: 'Create Customer',
                tooltipText: createCustomerDisabledForPaymentPlan
                  ? 'Upgrade your plan to add more customers.'
                  : undefined,
                onClick: () => {
                  this.setState({ createCustomersModalOpen: true });
                },
              }
            : undefined
        }
        searchBarPlaceholderText="Search by Name or Provided ID"
        searchBarSubmit={(searchStringSupplied: string) => {
          this.setState({
            searchString: searchStringSupplied,
            groupPage: 0,
          });
        }}
      />
    );
  };

  renderList = () => {
    const { groupPage, customerPermissions } = this.state;

    const { paymentPlan } = this.props;

    const [invalidGroups, validGroups] = this.getSortedCustomers(this.getFilteredCustomers());

    const invalidGroupIds = new Set(invalidGroups.map((group) => group.id));

    const userCanEditCustomer =
      doesUserHavePermission(customerPermissions, PERMISSIONED_ACTIONS.UPDATE) &&
      paymentPlan !== PLAN_TYPES.LAUNCH;
    const userCanDeleteCustomer =
      doesUserHavePermission(customerPermissions, PERMISSIONED_ACTIONS.DELETE) &&
      paymentPlan !== PLAN_TYPES.LAUNCH;

    return (
      <div className={listContainerStyles}>
        {invalidGroups
          .concat(validGroups)
          .slice(groupPage * GROUPS_PER_PAGE, (groupPage + 1) * GROUPS_PER_PAGE)
          .map((group) => (
            <CustomerGroupListItem
              group={group}
              key={`${group.id}`}
              onDeleteClicked={() =>
                this.setState({ deleteCustomerConfirmModalOpen: true, selectedGroup: group })
              }
              onEditClicked={() =>
                this.setState({ editCustomerModalOpen: true, selectedGroup: group })
              }
              rightTag={
                invalidGroupIds.has(group.id) ? (
                  <Tag intent={Intent.ERROR}>Reassign Visibility Group</Tag>
                ) : undefined
              }
              userCanDeleteCustomer={userCanDeleteCustomer}
              userCanEditCustomer={userCanEditCustomer}
            />
          ))}
      </div>
    );
  };

  renderPager = () => {
    const { groupPage } = this.state;

    const numCustomers = this.getFilteredCustomers().length;
    const maxPageNumber = Math.max(Math.ceil(numCustomers / GROUPS_PER_PAGE), 1);

    return (
      <div className={pagerFooterContainerStyles}>
        <div className={sprinkles({ paddingLeft: 'sp2' })}>{numCustomers} customers</div>
        <TablePager
          currentPage={groupPage + 1}
          maxPageNumber={maxPageNumber}
          onNewPage={(newPage) => {
            const newPageNumber = Number.parseInt(newPage);

            if (
              !newPageNumber ||
              newPageNumber < 1 ||
              newPageNumber > maxPageNumber ||
              groupPage + 1 === newPageNumber
            ) {
              return;
            }

            this.setState({ groupPage: newPageNumber - 1 });
          }}
        />
        <div />
      </div>
    );
  };

  getFilteredCustomers = () => {
    const { customers } = this.props;
    const { searchString } = this.state;

    if (!RD.isSuccess(customers)) return [];
    const trimmedSearchString = searchString.trim().toLowerCase();

    return customers.data.filter(
      (group) =>
        group.provided_id.includes(trimmedSearchString) ||
        group.name.toLowerCase().includes(trimmedSearchString),
    );
  };

  getSortedCustomers = (customers: Customer[]) => {
    const { accessGroups } = this.state;

    const accessGroupIdsSet = new Set((accessGroups ?? []).map((group) => group.id));

    return partition(customers, (group) =>
      doesCustomerHaveInvalidAccessGroup(group, accessGroupIdsSet),
    );
  };
}

const mapStateToProps = (state: ReduxState) => ({
  customers: state.customers.groups,
  dataSources: state.dataSourceList.dataSources,
  parentSchemas: state.parentSchemas.usedParentSchemas,
  parentSchemaLoading: createLoadingSelector([ACTION.FETCH_USED_PARENT_SCHEMAS], false)(state),
  dashboardTemplateListLoading: createLoadingSelector([ACTION.FETCH_DASHBOARD_TEMPLATE_LIST])(
    state,
  ),
  dashboardTemplateList: state.dashboard.dashboardTemplateList,
  endUserListLoading: createLoadingSelector([ACTION.FETCH_END_USER_LIST])(state),
  endUserList: state.endUsers.endUsers,
  currentUser: state.currentUser,
  accessGroupListLoading: createLoadingSelector([ACTION.FETCH_ACCESS_GROUP_LIST])(state),
  paymentPlan: state.teamData.data?.payment_plan,
});

const mapDispatchToProps = {
  fetchCustomers,
  addCustomer,
  listTeamDataSources,
  editCustomer,
  fetchUsedParentSchemas,
  deleteCustomer,
  fetchDashboardTemplateList,
  fetchEndUserList,
  createEndUser,
  deleteEndUser,
  editEndUser,
  fetchAccessGroups,
  fetchUserTeam,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomersPage));
