import { FC } from 'react';
import produce from 'immer';
import { useDispatch } from 'react-redux';

import { ConfigSection } from 'components/resource/ConfigSection';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import { sprinkles } from 'components/ds/sprinkles.css';

import { CanvasCustomComponent, updateCanvasComponent } from 'actions/canvasConfigActions';

type Props = { component: CanvasCustomComponent; isArchitectCustomerDashboard: boolean };

export const ComponentEditor: FC<Props> = ({ isArchitectCustomerDashboard, component }) => {
  const dispatch = useDispatch();

  return (
    <div className={sprinkles({ width: 'fill', marginBottom: 'sp1.5' })}>
      <ConfigSection
        defaultIsOpen
        isArchitectCustomerDashboard={isArchitectCustomerDashboard}
        title="Enter a URL">
        <div
          className={sprinkles(
            isArchitectCustomerDashboard ? { padding: 'sp1' } : { paddingX: 'sp1.5' },
          )}>
          <InputWithBlurSave
            fillWidth
            hideRightIconInteractions
            ignoreCustomStyles={!isArchitectCustomerDashboard}
            initialValue={component.info.config.iframeUrl}
            onNewValueSubmitted={(newValue) => {
              const newInfo = produce(component.info, (draft) => {
                draft.config.iframeUrl = newValue;
              });
              dispatch(updateCanvasComponent({ id: component.id, info: newInfo }));
            }}
            placeholder="Iframe URL"
          />
        </div>
      </ConfigSection>
    </div>
  );
};
