import { cloneDeep } from 'utils/standard';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';

import ColorPickerButton from 'shared/ColorPicker';
import Button from 'shared/Button';

import { ColorPalette, ColorPaletteV2 } from 'constants/types';
import { PALETTE_TO_COLORS } from 'constants/colorConstants';
import { constructCustomPalette } from 'pages/dashboardPage/charts/utils';
import { getCategoricalColors, getDivergingColors, getGradientColors } from 'globalStyles';
import { GlobalStyleConfig } from 'globalStyles/types';

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: 'repeat(auto-fill, 36px)',
    columnGap: '12px',
    rowGap: '8px',
  },
  colorCode: {
    height: '100%',
    width: '100%',
  },
  colorPickerBtn: {
    width: 36,
    height: 36,
  },
  addColorBtn: {
    width: 36,
    height: 36,
  },
}));

type Props = {
  className?: string;
  isCanvas: boolean;
  palette: ColorPalette | ColorPaletteV2;
  customColors?: string;
  globalStyleConfig: GlobalStyleConfig;
  saveCustomColorConfig: (colorPalette: string[]) => void;
};

export default function ColorPaletteDisplay({
  className,
  palette,
  customColors,
  globalStyleConfig,
  isCanvas,
  saveCustomColorConfig,
}: Props) {
  const classes = useStyles();

  let colorList: string[] | undefined;

  const categoricalColors = getCategoricalColors(globalStyleConfig);

  switch (palette) {
    case ColorPalette.CUSTOM:
      colorList = constructCustomPalette(customColors);
      break;
    case ColorPaletteV2.CATEGORICAL:
      colorList = categoricalColors;
      break;
    case ColorPaletteV2.DIVERGING:
      colorList = getDivergingColors(globalStyleConfig);
      break;
    case ColorPaletteV2.GRADIENT:
      colorList = getGradientColors(globalStyleConfig);
      break;
    default:
      colorList = PALETTE_TO_COLORS[palette];
  }

  return (
    <div className={cx(className, classes.root)}>
      {colorList ? (
        <>
          {colorList.map((colorHex, index) => (
            <ColorPickerButton
              btnClassName={classes.colorPickerBtn}
              color={colorHex}
              colorPalette={categoricalColors}
              isCanvas={isCanvas}
              key={`color-palette-display-${index}-${colorHex}`}
              onCancel={() => {
                const palette = cloneDeep(colorList) || [];
                palette.splice(index, 1);
                saveCustomColorConfig(palette);
              }}
              onColorChange={(newColor) => {
                const palette = cloneDeep(colorList) || [];
                palette[index] = newColor;
                saveCustomColorConfig(palette);
              }}
            />
          ))}
          <Button
            className={classes.addColorBtn}
            icon="plus"
            onClick={() => saveCustomColorConfig([...(colorList || []), '#0069ED'])}
          />
        </>
      ) : (
        <Button
          className={classes.addColorBtn}
          icon="plus"
          onClick={() => saveCustomColorConfig(['#0069ED'])}
        />
      )}
    </div>
  );
}
