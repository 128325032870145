import { makeStyles, Theme } from '@material-ui/core';
import produce from 'immer';

import DropdownSelect from 'shared/DropdownSelect';

import { VisualizeOperation } from 'types/dataPanelTemplate';
import {
  Aggregation,
  CategoryChartColumnInfo,
  ColorColumnOption,
  OPERATION_TYPES,
  SelectedDropdownInputItem,
  V2TwoDimensionChartInstructions,
} from 'constants/types';
import { CanvasDataset } from 'actions/canvasConfigActions';
import {
  getGroupOptions,
  resolveAggColDropped,
  resolveCategoryColDropped,
} from 'pages/dashboardPage/DataPanelConfigV2/DataConfigTab/vizConfigs/utils';
import { DATE_TYPES } from 'constants/dataConstants';
import { DATETIME_PIVOT_AGGS, PivotAgg } from 'types/dateRangeTypes';
import { chartCanHaveGroupBy, getAggColSectionLabel, getCategoryColSectionLabel } from './utils';
import { handleColorColumnAddition } from 'utils/colorColUtils';

const useStyles = makeStyles((theme: Theme) => ({
  flexContainer: { flex: 1 },
  inputRow: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
}));

type Props = {
  dataset: CanvasDataset;
  operationType: OPERATION_TYPES;
  usePortal: boolean;
  visualizeOperation: VisualizeOperation;

  updateVizOp: (newViz: VisualizeOperation) => void;
};

export default function TwoDimensionVizConfig({
  dataset,
  operationType,
  usePortal,
  visualizeOperation,
  updateVizOp,
}: Props): JSX.Element {
  const classes = useStyles();

  const updateV2Instructions = (update: Partial<V2TwoDimensionChartInstructions>) => {
    const newViz = produce(visualizeOperation, (draft) => {
      draft.instructions.V2_TWO_DIMENSION_CHART = {
        ...draft.instructions.V2_TWO_DIMENSION_CHART,
        ...update,
      };
    });
    updateVizOp(newViz);
  };

  const { categoryColumn, aggColumns, colorColumnOptions } =
    visualizeOperation.instructions.V2_TWO_DIMENSION_CHART || {};

  const categoryColOptions: SelectedDropdownInputItem[] = [];
  const aggColOptions: SelectedDropdownInputItem[] = [];

  dataset.schema?.forEach((col) => {
    const colOption = dataset.columnOptions[col.name];
    if (!colOption || !colOption.isVisible) return;

    if (colOption.canBeGroupedBy) categoryColOptions.push({ id: col.name, name: colOption.name });
    aggColOptions.push({ id: col.name, name: colOption.name });
  });

  const aggColumn = aggColumns?.[0];
  const colorColumn = colorColumnOptions?.[0];

  const groupOptions = aggColumn ? getGroupOptions(aggColumn) : [];
  const aggGroupOptions: SelectedDropdownInputItem[] =
    groupOptions[0]?.options.map((opt) => ({
      id: opt.id,
      name: opt.name,
    })) ?? [];

  const renderGroupDateOptions = (
    columnInfo: CategoryChartColumnInfo | ColorColumnOption | undefined,
    isCategoryColumn: boolean,
  ) => {
    if (!columnInfo) return null;

    const catOptions = DATE_TYPES.has(columnInfo.column.type ?? '') ? DATETIME_PIVOT_AGGS : [];
    if (catOptions.length === 0) return null;

    const catGroupOptions: SelectedDropdownInputItem[] = [];
    catOptions.forEach((option) => {
      if (option.id === PivotAgg.DATE_SMART) return;
      catGroupOptions.push({ id: option.id, name: option.name });
    });

    return (
      <DropdownSelect
        ignoreCustomStyles
        minimal
        useFakeLabel
        filterable={false}
        noSelectionText=""
        onChange={(item) => {
          if (item.id === columnInfo.bucket?.id) return;
          if (isCategoryColumn) {
            updateV2Instructions({ categoryColumn: { ...columnInfo, bucket: item } });
          } else {
            updateV2Instructions({
              colorColumnOptions: [{ ...(columnInfo as ColorColumnOption), bucket: item }],
            });
          }
        }}
        options={catGroupOptions}
        selectedItem={catGroupOptions.find((opt) => opt.id === columnInfo.bucket?.id)}
        usePortal={usePortal}
      />
    );
  };

  return (
    <>
      <div className={classes.inputRow}>
        <DropdownSelect
          fillWidth
          ignoreCustomStyles
          minimal
          containerClassName={classes.flexContainer}
          filterable={false}
          label={getCategoryColSectionLabel(operationType)}
          noSelectionText="Column"
          onChange={(item) => {
            if (categoryColumn?.column.name === item.id) return;
            const col = dataset.schema?.find((col) => col.name === item.id);
            if (!col) return;
            updateV2Instructions({
              categoryColumn: resolveCategoryColDropped(col, categoryColumn),
            });
          }}
          options={categoryColOptions}
          selectedItem={
            categoryColumn?.column
              ? categoryColOptions.find((col) => col.id === categoryColumn.column.name)
              : undefined
          }
          usePortal={usePortal}
        />
        {renderGroupDateOptions(categoryColumn, true)}
      </div>
      <div className={classes.inputRow}>
        <DropdownSelect
          fillWidth
          ignoreCustomStyles
          minimal
          containerClassName={classes.flexContainer}
          filterable={false}
          label={getAggColSectionLabel(operationType)}
          noSelectionText="Column"
          onChange={(item) => {
            if (aggColumn?.column.name === item.id) return;
            const col = dataset.schema?.find((col) => col.name === item.id);
            if (!col) return;
            updateV2Instructions({ aggColumns: resolveAggColDropped(col) });
          }}
          options={aggColOptions}
          selectedItem={
            aggColumn?.column
              ? aggColOptions.find((col) => col.id === aggColumn.column.name)
              : undefined
          }
          usePortal={usePortal}
        />
        <DropdownSelect
          ignoreCustomStyles
          minimal
          useFakeLabel
          disabled={!aggColumn?.column}
          filterable={false}
          noSelectionText="Aggregation"
          onChange={(item) => {
            if (!aggColumn || aggColumn.agg.id === item.id) return;
            updateV2Instructions({
              aggColumns: [{ ...aggColumn, agg: { id: item.id as Aggregation } }],
            });
          }}
          options={aggGroupOptions}
          selectedItem={aggGroupOptions.find((opt) => opt.id === aggColumn?.agg.id)}
          usePortal={usePortal}
        />
      </div>
      {chartCanHaveGroupBy(operationType) ? (
        <div className={classes.inputRow}>
          <DropdownSelect
            fillWidth
            ignoreCustomStyles
            minimal
            showCancelBtn
            containerClassName={classes.flexContainer}
            filterable={false}
            label="Group By"
            noSelectionText="Column"
            onCancelClick={() => updateV2Instructions({ colorColumnOptions: undefined })}
            onChange={(item) => {
              if (colorColumn?.column.name === item.id) return;
              const col = dataset.schema?.find((col) => col.name === item.id);
              if (!col) return;
              updateV2Instructions({ colorColumnOptions: handleColorColumnAddition(col) });
            }}
            options={categoryColOptions}
            selectedItem={
              colorColumn
                ? categoryColOptions.find((opt) => opt.id === colorColumn.column.name)
                : undefined
            }
            usePortal={usePortal}
          />
          {renderGroupDateOptions(colorColumn, false)}
        </div>
      ) : null}
    </>
  );
}
