import { IconName, Intent, IActionProps, ILinkProps } from '@blueprintjs/core';
import { AppToaster } from 'toaster';

import { FetchDashboardDatasetPreviewData } from 'actions/responseTypes';
import { checkDashboardDatasetDataForDuplicates } from 'utils/queryUtils';
import { DEFAULT_SUPPORT_EMAIL } from 'constants/emailConstants';

export const showErrorContactSupportToast = (response?: string) => {
  AppToaster.show({
    message: response
      ? response
      : `Something went wrong. Please try again or contact ${DEFAULT_SUPPORT_EMAIL} if the error continues.`,
    icon: 'error',
    timeout: 3000,
    intent: Intent.DANGER,
  });
};

export const showErrorToast = (errorMessage: string | JSX.Element, timeoutInSeconds = 3) => {
  AppToaster.show({
    message: errorMessage,
    icon: 'error',
    timeout: timeoutInSeconds * 1000,
    intent: Intent.DANGER,
  });
};

export const showWarningToast = (errorMessage: string, timeoutInSeconds = 5) => {
  AppToaster.show({
    message: errorMessage,
    icon: 'error',
    timeout: timeoutInSeconds * 1000,
    intent: Intent.WARNING,
  });
};

export const showSuccessToast = (message: string, timeoutInSeconds = 3, onDismiss?: () => void) => {
  AppToaster.show({
    message,
    icon: 'endorsed',
    timeout: timeoutInSeconds * 1000,
    intent: Intent.SUCCESS,
    onDismiss,
  });
};

export const showCustomToast = (
  message: string | JSX.Element,
  params?: {
    timeoutInSeconds?: number;
    icon?: IconName;
    intent?: Intent;
    action?: IActionProps & ILinkProps;
  },
) => {
  AppToaster.show({
    message,
    timeout: (params?.timeoutInSeconds ?? 5) * 1000,
    icon: params?.icon,
    intent: params?.intent,
    action: params?.action,
  });
};

export function showDuplicateColumnNameToast(data: FetchDashboardDatasetPreviewData) {
  const potentialDuplicates = checkDashboardDatasetDataForDuplicates(data);
  if (potentialDuplicates.length !== 0) {
    AppToaster.show({
      message: (
        <div>
          The saved query joins tables with duplicate column names ({potentialDuplicates.join(', ')}
          ) and may not work properly with the dashboard.{' '}
          <a
            href="https://docs.explo.co/troubleshooting/common-errors#duplicate-ambiguous-column-names"
            rel="noopener noreferrer"
            target="_blank">
            Click here for more info
          </a>
        </div>
      ),
      icon: 'warning-sign',
      timeout: 10000,
      intent: Intent.WARNING,
    });
  }
}
