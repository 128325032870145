import { useDispatch } from 'react-redux';

import SharedAreaChartConfigs from 'components/ChartConfigs/AreaChartConfigs';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES, V2TwoDimensionChartInstructions } from 'constants/types';
import { sharedStyles } from './styles';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2TwoDimensionChartInstructions;
};

export default function AreaChartConfig({ visualizationType, instructions }: Props) {
  const classes = sharedStyles();
  const dispatch = useDispatch();

  const updateInstructions = (newInstructions: V2TwoDimensionChartInstructions) => {
    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  return (
    <div className={classes.root}>
      <SharedAreaChartConfigs
        configInputClass={classes.configInput}
        instructions={instructions}
        updateInstructions={updateInstructions}
      />
    </div>
  );
}
