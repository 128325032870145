import cx from 'classnames';

import { DashboardVariable, TextInputElemConfig } from 'types/dashboardTypes';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { isValidForm } from 'utils/isValidFormUtils';

type Props = {
  config: TextInputElemConfig;
  disabled?: boolean;
  onNewValueSelect: (newValue: DashboardVariable) => void;
  value: DashboardVariable;
};

export default function DashboardTextInputElement({
  config,
  disabled,
  onNewValueSelect,
  value,
}: Props) {
  return (
    <InputWithBlurSave
      className={cx(
        GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.inputFields.textElementInputBorderRadius,
        GLOBAL_STYLE_CLASSNAMES.container.outline.dropdownFilterInputBorder,
        GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.dropdownFilterInputOutline,
        GLOBAL_STYLE_CLASSNAMES.base.actionColor.interactionStates.textInputBorderHover,
      )}
      disabled={disabled}
      initialValue={
        value && isValidForm(config.limitEntriesToNumbers, String(value)) ? String(value) : ''
      }
      label={config.label}
      leftIcon={config.showSearchIcon ? 'search' : undefined}
      onNewValueSubmitted={(newValue: string) => {
        if (isValidForm(config.limitEntriesToNumbers, newValue))
          onNewValueSelect(newValue ? newValue : undefined);
      }}
      placeholder={config.placeholderText}
      useFakeLabel={!config.label}
    />
  );
}
