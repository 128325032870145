import { FC } from 'react';
import { DateTime } from 'luxon';

import DatePickerInput from 'shared/DatePickerInput';

import { DashboardVariable, DatepickerElemConfig } from 'types/dashboardTypes';
import { DATE_RANGE_TYPES } from 'types/dateRangeTypes';
import { AnalyticsEventTracker } from 'utils/analyticsUtils';
import { getDateMax, getDateMin } from 'utils/dateUtils';
import { dateTimeFromISOString } from 'utils/dateUtils';
import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';

type Props = {
  analyticsEventTracker?: AnalyticsEventTracker;
  config: DatepickerElemConfig;
  value: DashboardVariable;
  onNewValueSelect: (newValue: DateTime | undefined) => void;
  disabled?: boolean;
  portalId?: string;
  openElementToLeft: boolean;
  timezone: string;
};

export const DashboardDatepickerElement: FC<Props> = ({
  analyticsEventTracker,
  config,
  value,
  onNewValueSelect,
  disabled,
  portalId,
  openElementToLeft,
  timezone,
}) => {
  const { relativeDateRange, dateRangeType } = config;
  const helpText = config.showTooltip ? config.infoTooltipText : undefined;

  return (
    <div>
      <DatePickerInput
        disabled={disabled}
        labelProps={{ text: config.label, helpText }}
        maxDate={
          dateRangeType === DATE_RANGE_TYPES.EXACT
            ? config.maxValue
            : getDateMax(relativeDateRange, timezone)
        }
        minDate={
          dateRangeType === DATE_RANGE_TYPES.EXACT
            ? config.minValue
            : getDateMin(relativeDateRange, timezone)
        }
        onCancelClick={() => {
          onNewValueSelect(undefined);
          analyticsEventTracker?.(REPORTED_ANALYTIC_ACTION_TYPES.DATEPICKER_SELECTED);
        }}
        onNewValueSelect={(date) => {
          // this is a date, not a date time, so leaving it in UTC
          onNewValueSelect((date as DateTime).toUTC());
          analyticsEventTracker?.(REPORTED_ANALYTIC_ACTION_TYPES.DATEPICKER_SELECTED);
        }}
        openElementToLeft={openElementToLeft}
        portalId={portalId}
        selectedValue={
          typeof value === 'string' ? dateTimeFromISOString(value) : (value as DateTime)
        }
        showCancelBtn={!config.disableCancel}
        showTimeSelect={!config.hideTimeSelect}
      />
    </div>
  );
};
