import { FC, useEffect, useState } from 'react';

import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { sprinkles } from 'components/ds';
import { FilterDropdown } from './FilterDropdown';
import { ApplyFilterRow } from './ApplyFilterRow';
import * as styles from './styles.css';

import { FilterableColumn, getFilterDefaultOperation } from 'utils/customerReportUtils';
import { CustomerReportFilter } from 'actions/customerReportActions';
import {
  FilterOperator,
  FILTER_OPERATOR_TYPES_BY_ID,
  FILTER_OPS_NO_VALUE,
  FILTER_OPERATORS,
  FILTER_OPS_MULTISELECT,
} from 'types/filterOperations';
import { INTEGER_DATA_TYPE, FLOAT, STRING, UNSIGNED_INTEGER } from 'constants/dataConstants';

type Props = {
  clause: CustomerReportFilter | undefined;
  column: FilterableColumn;

  holdOpen: (hold: boolean) => void;
};

const numberOptions = FILTER_OPERATORS.filter(
  (op) =>
    (op.supported_column_types.has(INTEGER_DATA_TYPE) || op.supported_column_types.has(FLOAT)) &&
    !FILTER_OPS_MULTISELECT.has(op.id),
);

const stringOptions = FILTER_OPERATORS.filter(
  (op) => op.supported_column_types.has(STRING) && !FILTER_OPS_MULTISELECT.has(op.id),
);

export const NumberAndStringFilterPopover: FC<Props> = ({ clause, column, holdOpen }) => {
  const isStringCol = column.type === STRING;
  const isUnsignedIntCol = column.type === UNSIGNED_INTEGER;
  const isIntCol = column.type === INTEGER_DATA_TYPE || isUnsignedIntCol;

  const [currOperator, setOperator] = useState(
    getFilterDefaultOperation(column.type, clause?.filterOperation.id),
  );
  const [valueString, setValueString] = useState(String(clause?.filterValue || ''));
  const [value, setValue] = useState(clause?.filterValue as number | string | undefined);

  useEffect(() => {
    if (valueString === '') return setValue(undefined);
    if (isStringCol) return setValue(valueString);

    const parsedValue = isIntCol ? parseInt(valueString) : parseFloat(valueString);

    // number type=input mostly shouldn't allow numbers, but we have this check just in case
    if (!isNaN(parsedValue)) setValue(parsedValue);
  }, [isIntCol, isStringCol, valueString]);

  const operator = FILTER_OPERATOR_TYPES_BY_ID[currOperator];
  const noValueNeeded = FILTER_OPS_NO_VALUE.has(operator.id);

  return (
    <div className={styles.numberContent}>
      <div className={styles.numberFilterRow}>
        <div className={sprinkles({ flex: 1, overflow: 'hidden' })}>
          <EmbedText body="b3">Operator</EmbedText>
          <FilterDropdown
            items={isStringCol ? stringOptions : numberOptions}
            onOpenChange={holdOpen}
            onSelect={(id) => setOperator(id as FilterOperator)}
            selectedItem={{ id: operator.id, name: operator.selectionValue }}
          />
        </div>

        <div className={sprinkles({ flex: 1 })}>
          <EmbedText body="b3">Value</EmbedText>
          <input
            className={styles.valueInput}
            disabled={noValueNeeded}
            onChange={(e) => setValueString(e.target.value)}
            placeholder={noValueNeeded ? undefined : 'Enter a value'}
            step={isIntCol ? 1 : undefined}
            type={isStringCol ? 'text' : 'number'}
            value={value === undefined || noValueNeeded ? '' : valueString}
          />
        </div>
      </div>
      <ApplyFilterRow clause={clause} column={column} operator={operator.id} value={value} />
    </div>
  );
};
