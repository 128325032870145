import {
  LegendOptions,
  XAxisOptions,
  XAxisTitleOptions,
  YAxisOptions,
  YAxisTitleOptions,
} from 'highcharts';
import { format, formatTime, TIME_FORMATS } from 'utils/localizationUtils';

import { DatasetSchema } from 'types/datasets';
import {
  YAxisFormat,
  XAxisFormat,
  ColorPalette,
  ColorPaletteV2,
  ColorFormat,
  LegendFormat,
  LegendPosition,
  V2TwoDimensionChartInstructions,
  VisualizePivotTableInstructions,
  OPERATION_TYPES,
  GROUPED_STACKED_OPERATION_TYPES,
  VisualizeCollapsibleListInstructions,
  NumberDisplayFormat,
  NumberDisplayOptions,
  CategoryChartColumnInfo,
  StringFormat,
  ColorColumnOption,
  DateDisplayOptions,
  DateDisplayFormat,
} from 'constants/types';
import { LEGEND_CONFIG_BY_POS } from './../constants';
import {
  V2_NUMBER_FORMATS,
  DATE_PART_INPUT_AGG,
  TIME_DIFF_FORMATS,
  STRING_FORMATS,
  STRING,
  DATE_TYPES,
  BOOLEAN,
  NUMBER_TYPES,
} from 'constants/dataConstants';
import { PALETTE_TO_COLORS } from 'constants/colorConstants';
import {
  GLOBAL_STYLE_CLASSNAMES,
  TEXT_SIZE_OFFSET_MAP,
  getCategoricalColors,
  getDivergingColors,
  getGradientColors,
} from 'globalStyles';
import { GlobalStyleConfig } from 'globalStyles/types';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { isPivotTableAggReady, replaceTemplatesWithValues } from 'utils/dataPanelConfigUtils';
import { getFontFamilyName } from 'globalStyles/helpers';
import { PivotAgg, TrendGroupingOptions } from 'types/dateRangeTypes';
import { DateTime, Info } from 'luxon';
import { ResourceDataset } from 'types/exploResource';
import { getTimezoneAwareDate } from 'utils/timezoneUtils';
import { DATE_DISPLAY_FORMAT_TO_DATE_FORMAT } from 'constants/dataPanelEditorConstants';
import { getCurrentDateFormat } from 'utils/formatConfigUtils';

export const getColorColNames = (schema: DatasetSchema, operationType?: OPERATION_TYPES) => {
  let xAxisColName;
  let colorColName;
  let aggColName;
  if (operationType && GROUPED_STACKED_OPERATION_TYPES.includes(operationType)) {
    xAxisColName = schema[1].name;
    colorColName = schema[2].name;
    aggColName = schema[3].name;
  } else {
    xAxisColName = schema[0].name;
    // if there is a color but only 2 columns, that means that the xAxis and color column are the same
    colorColName = schema[2] ? schema[1].name : xAxisColName;
    aggColName = schema[2] ? schema[2].name : schema[1].name;
  }
  return {
    xAxisColName,
    colorColName,
    aggColName,
  };
};

export const shouldProcessColAsDate = (col?: CategoryChartColumnInfo | ColorColumnOption) => {
  if (!col || !DATE_TYPES.has(col.column.type || '')) return false;

  const hasDatePartBucket = col.bucket?.id && col.bucket.id.indexOf('DATE_PART') >= 0;

  return !hasDatePartBucket;
};

export const getLabelStyle = (
  globalStyleConfig: GlobalStyleConfig,
  fallback: 'primary' | 'secondary',
  colorOverride?: string,
) => {
  const fontFamily =
    globalStyleConfig.text.overrides.smallBody?.font ||
    (fallback === 'primary'
      ? globalStyleConfig.text.primaryFont
      : globalStyleConfig.text.secondaryFont);

  return {
    fontSize: `${
      globalStyleConfig.text.overrides.smallBody?.size ||
      globalStyleConfig.text.textSize + TEXT_SIZE_OFFSET_MAP['smallBody']
    }px`,
    color:
      colorOverride ||
      globalStyleConfig.text.overrides.smallBody?.color ||
      (fallback === 'primary'
        ? globalStyleConfig.text.primaryColor
        : globalStyleConfig.text.secondaryColor),
    fontWeight: 'normal',
    ...(fontFamily && { fontFamily: getFontFamilyName(fontFamily) }),
  };
};

export const xAxisFormat = (
  globalStyleConfig: GlobalStyleConfig,
  xAxisFormat?: XAxisFormat,
): XAxisOptions => {
  if (!xAxisFormat) return {};

  let title: XAxisTitleOptions = { text: undefined };
  if (xAxisFormat.showTitle && xAxisFormat.title) {
    title = {
      text: xAxisFormat.title,
      margin: 12,
      style: {
        fontWeight: 'bold',
        color:
          globalStyleConfig?.text.overrides.smallHeading?.color ||
          globalStyleConfig?.text.secondaryColor,
      },
    };
  }
  return {
    title,
    className: GLOBAL_STYLE_CLASSNAMES.text.smallHeading.secondary,
    reversed: xAxisFormat.reverseAxis ?? false,
    tickWidth: xAxisFormat.hideAxisTicks ? 0 : 1,
    opposite: xAxisFormat.flipAxis ?? false,
  };
};

export const yAxisFormat = (
  globalStyleConfig: GlobalStyleConfig,
  yAxisFormat?: YAxisFormat,
  colorOverride?: string,
): YAxisOptions => {
  if (!yAxisFormat) return {};

  let title: YAxisTitleOptions = { text: undefined };
  if (yAxisFormat.showTitle && yAxisFormat.title) {
    title = {
      text: yAxisFormat.title,
      margin: 12,
      style: {
        fontWeight: 'bold',
        color:
          colorOverride ||
          globalStyleConfig?.text.overrides.smallHeading?.color ||
          globalStyleConfig?.text.secondaryColor,
      },
    };
  }
  return {
    title,
    className: GLOBAL_STYLE_CLASSNAMES.text.smallHeading.secondary,
    reversed: yAxisFormat.reverseAxis ?? false,
    type: yAxisFormat.useLogScale ? 'logarithmic' : 'linear',
  };
};
export const formatLegend = (
  globalStyleConfig: GlobalStyleConfig,
  legendFormat?: LegendFormat,
): LegendOptions => {
  let fontFamily =
    globalStyleConfig?.text.overrides.smallBody?.font || globalStyleConfig?.text.secondaryFont;

  const styles = {
    itemStyle: {
      fontSize: `${
        globalStyleConfig.text.overrides.smallBody?.size ||
        globalStyleConfig.text.textSize + TEXT_SIZE_OFFSET_MAP['smallBody']
      }px`,
      color: `${
        globalStyleConfig?.text.overrides.smallBody?.color || globalStyleConfig?.text.secondaryColor
      }`,
      ...(fontFamily && { fontFamily: getFontFamilyName(fontFamily) }),
      fontWeight: 'normal',
    },
  };
  if (!legendFormat) return { ...LEGEND_CONFIG_BY_POS[LegendPosition.AUTO], ...styles };

  fontFamily =
    globalStyleConfig?.text.overrides.smallHeading?.font || globalStyleConfig?.text.secondaryFont;

  return {
    ...LEGEND_CONFIG_BY_POS[legendFormat.position || LegendPosition.AUTO],
    // Note: treating legends as strings as no issues formatting other types
    labelFormatter: function () {
      return formatLabel(
        this.name,
        STRING,
        undefined,
        undefined,
        undefined,
        legendFormat?.stringFormat,
      );
    },
    enabled: !legendFormat.hideLegend,
    title: {
      text: legendFormat.showTitle ? legendFormat.title : undefined,
      style: {
        fontWeight: 'bold',
        fontSize: `${
          globalStyleConfig?.text.overrides.smallHeading?.size ||
          globalStyleConfig?.text.textSize + TEXT_SIZE_OFFSET_MAP['smallHeading']
        }px`,
        color: `${
          globalStyleConfig?.text.overrides.smallHeading?.color ||
          globalStyleConfig?.text.secondaryColor
        }`,
        ...(fontFamily && { fontFamily: getFontFamilyName(fontFamily) }),
      },
    },
    ...styles,
  };
};

const titleString = (s: string) => {
  if (s.length === 0) return s;
  else if (s.length === 1) return s.toUpperCase();

  return s[0].toUpperCase().concat(s.slice(1).toLowerCase());
};

export const formatLabel = (
  value: number | string,
  colType?: string,
  bucket?: string,
  numBucketSize?: number,
  dateFormatOverride?: string,
  stringFormatOverride?: StringFormat,
  maxCategories = false,
) => {
  if (colType === BOOLEAN) return String(value);

  if (value === undefined) return '';
  if (maxCategories && value === 'Other') return value;

  if (colType) {
    if (colType === STRING) {
      let stringValue = String(value);
      if (stringFormatOverride?.replaceUnderscores)
        stringValue = stringReplaceAll(stringValue, '_', ' ');
      switch (stringFormatOverride?.format) {
        case STRING_FORMATS.CAMEL_CASE: {
          const parts = stringValue.trim().split(/\s+/);
          const titledParts = parts.map((part, i) =>
            i === 0 ? part.toLowerCase() : titleString(part),
          );
          return titledParts.join(' ');
        }
        case STRING_FORMATS.SENTENCE_CASE: {
          const parts = stringValue.trim().split(/\s+/);
          const titledParts = parts.map((part, i) =>
            i !== 0 ? part.toLowerCase() : titleString(part),
          );
          return titledParts.join(' ');
        }
        case STRING_FORMATS.LOWERCASE:
          return stringValue.toLowerCase();
        case STRING_FORMATS.UPPERCASE:
          return stringValue.toUpperCase();
        case STRING_FORMATS.TITLE_CASE: {
          const parts = stringValue.trim().split(/\s+/);
          const titledParts = parts.map(titleString);
          return titledParts.join(' ');
        }
        default:
          return stringValue;
      }
    }
    const valueAsNumber = value as number;

    switch (bucket) {
      case PivotAgg.DATE_HOUR:
        return formatTime(
          DateTime.fromMillis(valueAsNumber).toLocal(),
          dateFormatOverride || TIME_FORMATS['HH:00 M/D'],
        );
      case PivotAgg.DATE_DAY:
      case PivotAgg.DATE_WEEK:
        return formatTime(
          DateTime.fromMillis(valueAsNumber).toLocal(),
          dateFormatOverride || TIME_FORMATS['MMM D, YYYY'],
        );
      case PivotAgg.DATE_MONTH:
        return formatTime(
          DateTime.fromMillis(valueAsNumber).toLocal(),
          dateFormatOverride || TIME_FORMATS['MMM YYYY'],
        );
      case PivotAgg.DATE_YEAR:
        return formatTime(
          DateTime.fromMillis(valueAsNumber).toLocal(),
          dateFormatOverride || TIME_FORMATS['YYYY'],
        );
      case PivotAgg.DATE_PART_MONTH:
        return Info.months('long')[valueAsNumber - 1];
      case PivotAgg.DATE_PART_WEEK_DAY: {
        // BE returns Sun 0-6 while Luxon has Mon 0-6
        const day = valueAsNumber - 1;
        return Info.weekdays('long')[day === -1 ? 6 : day];
      }
      case PivotAgg.DATE_PART_HOUR:
        return DateTime.utc()
          .set({ hour: valueAsNumber })
          .toLocal()
          .toLocaleString({ hour: 'numeric' });
    }
    if (NUMBER_TYPES.has(colType) && numBucketSize !== undefined) {
      if (numBucketSize === 1) return String(valueAsNumber);
      // converting these both to strings and then parsing into integers because sometimes the
      // value in the config is stored as a string and will crash if we use it as a number.
      // TODO: identify why this happens and ensure a number is stored
      return `${valueAsNumber}-${
        parseInt(String(valueAsNumber)) + parseInt(String(numBucketSize)) - 1
      }`;
    }
  }

  return String(value);
};

export const getColorPalette = (
  globalStyleConfig: GlobalStyleConfig,
  colorFormat: ColorFormat | undefined,
) => {
  const palette = colorFormat?.selectedPalette;
  const customColors = colorFormat?.customColors;

  switch (palette) {
    case ColorPalette.CUSTOM:
      return constructCustomPalette(customColors);
    case ColorPaletteV2.DIVERGING:
      return getDivergingColors(globalStyleConfig);
    case ColorPaletteV2.GRADIENT:
      return getGradientColors(globalStyleConfig);
    case ColorPaletteV2.CATEGORICAL:
    case undefined:
      return getCategoricalColors(globalStyleConfig);
  }

  return PALETTE_TO_COLORS[palette];
};

type ChartColorZone = { value?: number; color: string };

export const getColorZones = (
  colorFormat: ColorFormat | undefined,
  variables: DashboardVariableMap,
  datasets: Record<string, ResourceDataset>,
): ChartColorZone[] | undefined => {
  if (!colorFormat?.colorZones?.length || !colorFormat.useZones) return;
  const numZones = colorFormat.colorZones.length;
  const chartZones: ChartColorZone[] = [];

  colorFormat.colorZones.forEach(({ zoneThreshold, zoneColor }, i) => {
    // Last zone does not need a threshold
    if (numZones === i + 1) return chartZones.push({ color: zoneColor });

    if (!zoneThreshold) return;

    const value = Number(replaceTemplatesWithValues(zoneThreshold, variables, datasets));
    if (!isNaN(value)) chartZones.push({ value, color: zoneColor });
  });
  return chartZones;
};

export const DEFAULT_PALETTE_COLOR = '#cce1fb';

export const constructCustomPalette = (customColors?: string) => {
  if (!customColors) return [DEFAULT_PALETTE_COLOR];

  return customColors.split(',');
};

export const formatValue = ({
  value,
  decimalPlaces,
  formatId,
  significantDigits,
  multiplier,
  hasCommas,
  timeFormatId,
  customTimeFormat,
}: {
  value: number;
  formatId: string;
  decimalPlaces?: number;
  significantDigits?: number;
  multiplier?: number;
  hasCommas?: boolean;
  timeFormatId?: string;
  customTimeFormat?: string;
}) => {
  value *= multiplier ?? 1;
  const separator = hasCommas ? ',' : '';

  if (formatId === V2_NUMBER_FORMATS.CURRENCY.id) {
    return `${format(`$${separator}.${decimalPlaces ?? 0}f`)(value)}`;
  } else if (formatId === V2_NUMBER_FORMATS.PERCENT.id) {
    return `${format(`${separator}.${decimalPlaces ?? 0}f`)(value * 100)}%`;
  } else if (formatId === V2_NUMBER_FORMATS.TIME.id) {
    return formatTimeFromSeconds(value, timeFormatId, customTimeFormat);
  } else if (formatId === V2_NUMBER_FORMATS.ABBREVIATED.id) {
    return format(`.${significantDigits ?? 3}${Math.abs(value) < 1 ? 'r' : 's'}`)(value);
  } else {
    return `${format(`${separator}.${decimalPlaces ?? 0}f`)(value)}`;
  }
};

export const formatNumberValue = (
  numberDisplayOptions: NumberDisplayOptions,
  value: number,
  goal?: number,
) => {
  switch (numberDisplayOptions.format) {
    case NumberDisplayFormat.CURRENCY:
      return formatValue({
        value,
        decimalPlaces: numberDisplayOptions.decimalPlaces ?? 2,
        formatId: V2_NUMBER_FORMATS.CURRENCY.id,
        hasCommas: numberDisplayOptions.hasCommas,
        multiplier: numberDisplayOptions.multiplier,
      });
    case NumberDisplayFormat.PERCENT:
      return goal
        ? formatValue({
            value: value / goal,
            decimalPlaces: numberDisplayOptions.decimalPlaces ?? 0,
            formatId: V2_NUMBER_FORMATS.PERCENT.id,
            hasCommas: numberDisplayOptions.hasCommas,
            multiplier: numberDisplayOptions.multiplier,
          })
        : formatValue({
            value,
            decimalPlaces: numberDisplayOptions.decimalPlaces ?? 0,
            formatId: V2_NUMBER_FORMATS.PERCENT.id,
            hasCommas: numberDisplayOptions.hasCommas,
            multiplier: numberDisplayOptions.multiplier,
          });
    case NumberDisplayFormat.TIME:
      return formatValue({
        value,
        formatId: V2_NUMBER_FORMATS.TIME.id,
        hasCommas: numberDisplayOptions.hasCommas,
        timeFormatId: numberDisplayOptions.timeFormat?.id,
        customTimeFormat: numberDisplayOptions.timeCustomFormat,
      });
    default:
      return formatValue({
        value,
        decimalPlaces: numberDisplayOptions.decimalPlaces ?? 0,
        formatId: V2_NUMBER_FORMATS.NUMBER.id,
        hasCommas: numberDisplayOptions.hasCommas,
        multiplier: numberDisplayOptions.multiplier,
      });
  }
};

const formatTimeFromSeconds = (time: number, timeFormatId?: string, customTimeFormat?: string) => {
  const days = Math.floor(time / (60 * 60 * 24));
  let remainingSeconds = time % (60 * 60 * 24);
  const hours = Math.floor(remainingSeconds / (60 * 60));
  remainingSeconds = remainingSeconds % (60 * 60);
  const minutes = Math.floor(remainingSeconds / 60);
  const seconds = Math.round(remainingSeconds % 60);

  if (timeFormatId === TIME_DIFF_FORMATS.ABBREVATION.id) {
    let amount = 0;
    let unit = '';
    if (days > 0) {
      amount = days + (hours >= 12 ? 1 : 0);
      unit = 'day';
    } else if (hours > 0) {
      amount = hours + (minutes >= 30 ? 1 : 0);
      unit = 'hour';
    } else if (minutes > 0) {
      amount = minutes + (seconds >= 30 ? 1 : 0);
      unit = 'minute';
    } else {
      amount = seconds;
      unit = 'second';
    }
    return `${amount} ${unit}${amount !== 1 ? 's' : ''}`;
  } else if (timeFormatId === TIME_DIFF_FORMATS.CUSTOM.id && customTimeFormat) {
    const hh = `${hours < 10 ? '0' : ''}${hours}`;
    const mm = `${minutes < 10 ? '0' : ''}${minutes}`;
    const ss = `${seconds < 10 ? '0' : ''}${seconds}`;

    let formatted = stringReplaceAll(customTimeFormat, 'hh', hh);
    formatted = stringReplaceAll(formatted, 'mm', mm);
    formatted = stringReplaceAll(formatted, 'ss', ss);
    formatted = stringReplaceAll(formatted, 'HH', String(hours));
    formatted = stringReplaceAll(formatted, 'MM', String(minutes));
    formatted = stringReplaceAll(formatted, 'SS', String(seconds));
    formatted = stringReplaceAll(formatted, 'DD', String(days));

    return formatted;
  }

  return `${days} day${days !== 1 ? 's' : ''}, ${hours}:${minutes}:${seconds}`;
};

const stringReplaceAll = (s: string, match: string, newVal: string) => {
  const re = new RegExp(match, 'g');

  return s.replace(re, newVal);
};

export const isTwoDimVizInstructionsReadyToDisplay = (
  instructions?: V2TwoDimensionChartInstructions,
  operation_type?: OPERATION_TYPES,
) => {
  if (instructions?.categoryColumn?.bucket?.id === DATE_PART_INPUT_AGG) {
    return !!instructions.categoryColumn.bucketElemId;
  }
  const isGrouped =
    operation_type && GROUPED_STACKED_OPERATION_TYPES.includes(operation_type)
      ? instructions?.groupingColumn !== undefined
      : true;

  return !!(
    instructions &&
    instructions.categoryColumn?.column &&
    instructions.aggColumns &&
    instructions.aggColumns.length > 0 &&
    isGrouped
  );
};

export const areRequiredVariablesSetTwoDimViz = (
  variables: DashboardVariableMap,
  instructions?: V2TwoDimensionChartInstructions,
) => {
  if (instructions?.categoryColumn?.bucket?.id !== DATE_PART_INPUT_AGG) return true;

  const groupingElemId = instructions?.categoryColumn?.bucketElemId;

  if (!groupingElemId) return true;

  const groupingOption = variables[groupingElemId] as TrendGroupingOptions;

  return !!groupingOption;
};

export const isPivotTableReadyToDisplay = (instructions?: VisualizePivotTableInstructions) => {
  if (!instructions) return false;

  const isBaseConfigReady =
    instructions.colColumn && instructions.rowColumn && isPivotTableAggReady(instructions);

  if (!isBaseConfigReady) return false;
  if (instructions.joinedColumns === undefined) return true;

  return instructions.joinedColumns.joinColumn && instructions.joinedColumns.joinTable;
};

export const isCollapsibleListReadyToDisplay = (
  instructions?: VisualizeCollapsibleListInstructions,
) => {
  return !!(instructions && instructions.rowColumns && instructions.aggregations);
};

export const formatDateField = (
  data: string,
  colType: string,
  dateFormatOption: DateDisplayOptions | undefined,
  ignoreInvalidDates?: boolean,
  isDashboardTable?: boolean,
): string => {
  const datePart = dateFormatOption?.datePartAgg;
  // This is used by report builder table
  if (datePart) {
    if (datePart === PivotAgg.DATE_PART_MONTH_DAY) return data;
    const value = Number(data);
    if (isNaN(value)) return '';

    if (datePart === PivotAgg.DATE_PART_MONTH) return Info.months('long')[value - 1];
    const day = value - 1;
    return Info.weekdays('long')[day === -1 ? 6 : day];
  }

  let dateVal = getTimezoneAwareDate(data);
  const currentDateFormat = getCurrentDateFormat(dateFormatOption, colType);
  const dateFormat =
    currentDateFormat === DateDisplayFormat.CUSTOM
      ? dateFormatOption?.customFormat ?? ''
      : DATE_DISPLAY_FORMAT_TO_DATE_FORMAT[currentDateFormat];

  if (!dateVal.isValid) {
    return ignoreInvalidDates ? data : 'Invalid Date';
  }
  // this is a date, not a date time, so leaving it in UTC
  if (!isDashboardTable) dateVal = dateVal.toUTC();
  return formatTime(dateVal, dateFormat);
};
