import { useEffect, useState, KeyboardEvent } from 'react';
import { ReduxState } from 'reducers/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { AlertModal } from 'components/ds';
import Button from 'shared/Button';
import InputGroup from 'explo-ds/forms/marketing/inputGroup';
import { DashboardAttribute, DashboardAttributeValue } from 'actions/teamActions';
import {
  createDashboardAttribute,
  deleteDashboardAttribute,
  createAttributeValue,
  deleteAttributeValue,
  fetchDashboardAttributes,
} from 'actions/dashboardAttributesActions';
import ColorPickerButton from 'shared/ColorPicker';

const useStyles = makeStyles((theme: Theme) => ({
  subsection: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  sectionDescription: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.ds.grey800,
    marginBottom: theme.spacing(6),
  },
  attributesContainer: {
    padding: theme.spacing(3),
    paddingBottom: 0,
    backgroundColor: theme.palette.ds.grey200,
    borderRadius: 4,
    marginBottom: theme.spacing(4),
  },
  createAttribute: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(3),
  },
  createAttributeInput: {
    flex: 1,
    marginRight: theme.spacing(2),
  },

  attributeDiv: { display: 'flex', marginBottom: theme.spacing(3) },
  attributeBox: {
    height: 32,
    borderRadius: 4,
    padding: '0px 12px',
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    backgroundColor: '#fff',
    border: '1px solid #E0E0E0',
    marginRight: theme.spacing(2),
  },
  addValueButton: { marginBottom: theme.spacing(3) },
  anotherValue: { marginLeft: theme.spacing(10) },
  colorPicker: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(2),
    border: '1px solid #E0E0E0',
    borderRadius: 4,
  },
  saveButton: {
    marginLeft: theme.spacing(2),
  },
  colorPickerWrapper: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(2),
  },
  colorPickerButton: {
    height: 32,
    width: 32,
    border: '1px solid #E0E0E0',
    borderRadius: 4,
  },
}));

type NewValue = { attrId: number; value: string; colorHex: string };

type DeleteModal =
  | { type: 'attribute'; attr: DashboardAttribute }
  | { type: 'value'; value: DashboardAttributeValue; attrId: number };

export default function SettingsAttributesSection() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const attributes = useSelector(
    (state: ReduxState) => state.dashboardAttributes.dashboard_attributes,
  );

  useEffect(() => {
    if (!attributes) dispatch(fetchDashboardAttributes());
  }, [attributes, dispatch]);

  const [newAttribute, setNewAttribute] = useState<string | null>(null);
  const [newValue, setNewValue] = useState<NewValue | null>(null);
  const [deleteModal, setDeleteModal] = useState<DeleteModal | null>(null);

  const submitOnEnter = (disabled: boolean, onSubmit: () => void) => {
    if (!disabled) {
      return (event: KeyboardEvent<HTMLInputElement>) => {
        const code = event.keyCode || event.which;
        if (code === 13) {
          onSubmit();
        }
      };
    }
  };

  const createNewAttribute = (attrList: DashboardAttribute[], attrName: string) => {
    const disabled = attrName.trim() === '' || attrList.some((attr) => attr.name === attrName);
    const onSubmit = () => {
      dispatch(
        createDashboardAttribute({ postData: { name: attrName.trim() } }, () =>
          setNewAttribute(null),
        ),
      );
    };
    return (
      <div className={classes.attributesContainer}>
        <div className={classes.createAttribute}>
          <InputGroup
            autoFocus={true}
            className={classes.createAttributeInput}
            onInputChange={setNewAttribute}
            onKeyPress={submitOnEnter(disabled, onSubmit)}
            placeholder="Attribute Name (ex: Status)"
            type="text"
            value={attrName}
          />
          <Button
            compact={true}
            icon="cross"
            onClick={() => setNewAttribute(null)}
            type="secondary"
          />
          <Button
            className={classes.saveButton}
            compact={true}
            disabled={disabled}
            icon="tick"
            onClick={onSubmit}
            type="primary"
          />
        </div>
      </div>
    );
  };

  const createNewValue = (
    attributeId: number,
    values: DashboardAttributeValue[],
    newValue: NewValue,
  ) => {
    const { value, colorHex } = newValue;
    const disabled = value.trim() === '' || values.some((val) => val.value === value);
    const onSubmit = () => {
      dispatch(
        createAttributeValue(
          {
            postData: {
              value: value.trim(),
              color_hex: colorHex,
              attribute_id: attributeId,
            },
          },
          () => setNewValue(null),
        ),
      );
    };
    return (
      <div className={classes.createAttribute}>
        <ColorPickerButton
          btnClassName={classes.colorPickerButton}
          className={classes.colorPickerWrapper}
          color={colorHex}
          colorPalette={[]}
          onColorChange={(newHex) => {
            setNewValue({ ...newValue, colorHex: newHex });
          }}
        />
        <InputGroup
          autoFocus={true}
          className={classes.createAttributeInput}
          onInputChange={(value) => setNewValue({ ...newValue, value })}
          onKeyPress={submitOnEnter(disabled, onSubmit)}
          placeholder="Value"
          type="text"
          value={value}
        />
        <Button compact={true} icon="cross" onClick={() => setNewValue(null)} type="secondary" />
        <Button
          className={classes.saveButton}
          compact={true}
          disabled={disabled}
          icon="tick"
          onClick={onSubmit}
          type="primary"
        />
      </div>
    );
  };

  const viewAttribute = (attribute: DashboardAttribute) => {
    const { name, values, id } = attribute;
    const newValue_ = newValue?.attrId === id ? newValue : null;
    const hasValues = values.length > 0 || newValue_ !== null;
    return (
      <div className={classes.attributesContainer} key={`attr-${name}`}>
        <div className={classes.attributeDiv}>
          <div className={classes.attributeBox}>{name}</div>
          <Button
            icon="trash"
            onClick={() => setDeleteModal({ type: 'attribute', attr: attribute })}
            type="destructive"
          />
        </div>
        {hasValues &&
          values.map((value) => {
            return (
              <div className={classes.attributeDiv} key={`${name}-${value.value}`}>
                <div
                  className={classes.colorPicker}
                  style={{ backgroundColor: value.color_hex }}></div>
                <div className={classes.attributeBox}>{value.value}</div>
                <Button
                  icon="cross"
                  onClick={() => setDeleteModal({ type: 'value', value, attrId: id })}
                  type="destructive"
                />
              </div>
            );
          })}
        {newValue_ && createNewValue(attribute.id, values, newValue_)}
        <Button
          className={cx(classes.addValueButton, hasValues ? classes.anotherValue : '')}
          disabled={newValue_ !== null}
          icon="plus"
          onClick={() => {
            setNewAttribute(null);
            setNewValue({ attrId: id, value: '', colorHex: '#7ACC39' });
          }}
          text={hasValues ? 'Add another value' : 'Add a value'}
          type="secondary"
        />
      </div>
    );
  };

  const removeAttribute = (attribute: DashboardAttribute) => {
    dispatch(deleteDashboardAttribute({ id: attribute.id }));
  };

  const removeValue = (attrId: number, value: DashboardAttributeValue) => {
    dispatch(deleteAttributeValue({ postData: { id: value.id, attrId } }));
  };

  const viewDeleteModal = (modalInfo: DeleteModal) => {
    let title = 'Are you sure you want to delete the ';
    let confirmText = 'Delete ';
    let onConfirm: () => void;
    let bodyText = '';
    if (modalInfo.type === 'attribute') {
      title += `${modalInfo.attr.name} attribute?`;
      confirmText += `${modalInfo.attr.name} attribute`;
      bodyText =
        'If this attribute is deleted then the dashboards will no longer be linked to the values you’ve assigned. These changes will happen immediately.';
      onConfirm = () => {
        removeAttribute(modalInfo.attr);
        setDeleteModal(null);
      };
    } else {
      title += `${modalInfo.value.value} value?`;
      confirmText += `${modalInfo.value.value} value`;
      bodyText = `This value is assigned to your dashboards, if it’s deleted then the dashboards will no longer have any value assigned for ${modalInfo.value.value}.`;
      onConfirm = () => {
        removeValue(modalInfo.attrId, modalInfo.value);
        setDeleteModal(null);
      };
    }
    return (
      <AlertModal
        isOpen
        actionButtonProps={{ text: confirmText, onClick: onConfirm }}
        onClose={() => setDeleteModal(null)}
        title={title}>
        {bodyText}
      </AlertModal>
    );
  };

  if (!attributes) return <></>;

  return (
    <div>
      <div className={classes.subsection}>Attributes</div>
      <div className={classes.sectionDescription}>
        Attributes are customizable tags that you can use to differentiate your dashboards, these
        attributes can then be referenced programatically.
      </div>
      {attributes.length > 0 && <>{attributes.map((attribute) => viewAttribute(attribute))}</>}
      {newAttribute !== null && createNewAttribute(attributes, newAttribute)}
      <Button
        disabled={newAttribute !== null}
        icon="plus"
        onClick={() => {
          setNewValue(null);
          setNewAttribute('');
        }}
        text="Add an attribute"
        type="primary"
      />
      {deleteModal && viewDeleteModal(deleteModal)}
    </div>
  );
}
