import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';
import { Icon, IconName } from '@blueprintjs/core';
import { Link } from 'react-router-dom';

type TabOption = {
  name: string;
  elemId?: string;
  id: string;
  icon?: IconName | JSX.Element;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  tabBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#727A86',
    height: '100%',
    fontWeight: 500,
    fontSize: 14,
    borderBottom: `2px solid #FFFFFF00`, // transparent
    flex: 1,

    '&:hover': {
      color: theme.palette.ds.blue,
      cursor: 'pointer',
    },
  },
  activeTabBtn: {
    color: theme.palette.ds.blue,
    borderBottom: `2px solid ${theme.palette.ds.blue}`,
  },
  tabIcon: {
    marginRight: theme.spacing(3),
  },
  hrefTabContainer: {
    height: '100%',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

type Props = {
  className?: string;
  getHrefFromTabId?: (tabId: string) => string;
  tabClassName?: string;
  tabs: TabOption[];
  tabIconSize?: number;
  selectedTabId?: string;
  onTabSelect?: (tabId: string) => void;
};

const NavTabs = (props: Props) => {
  const {
    className,
    getHrefFromTabId,
    tabClassName,
    selectedTabId,
    tabIconSize,
    tabs,
    onTabSelect,
  } = props;
  const classes = useStyles();

  return (
    <div className={cx(classes.root, className)}>
      {tabs.map((tab) => {
        const tabElem = (
          <div
            className={cx(classes.tabBtn, tabClassName, {
              [classes.activeTabBtn]: selectedTabId === tab.id,
              selected: selectedTabId === tab.id,
            })}
            id={tab.elemId}
            key={`nav-tab-option-${tab.id}`}
            onClick={onTabSelect ? () => onTabSelect(tab.id) : undefined}>
            {tab.icon ? (
              <Icon className={classes.tabIcon} icon={tab.icon} iconSize={tabIconSize} />
            ) : null}
            {tab.name}
          </div>
        );

        if (getHrefFromTabId) {
          return (
            <Link
              className={classes.hrefTabContainer}
              key={`link-tab-option-${tab.id}`}
              to={getHrefFromTabId(tab.id)}>
              {tabElem}
            </Link>
          );
        }

        return tabElem;
      })}
    </div>
  );
};

export default NavTabs;
