import { Layout } from '@explo-tech/react-grid-layout';

import { DashboardElement } from 'types/dashboardTypes';
import { Dataset } from 'actions/datasetActions';
import { DataPanelTemplate } from 'types/dataPanelTemplate';

// If the element ID is present and true in this object, it is excluded
export interface ElementExclusionMap {
  [id: string]: boolean;
}

export interface LayoutExclusionMap {
  pdfExclusions: ElementExclusionMap;
  emailExclusions: ElementExclusionMap;
  mobileExclusions: ElementExclusionMap;
}

export enum DashboardHeaderLocation {
  RIGHT_ALIGNED = 'rightAligned',
  BELOW_HEADER = 'belowHeader',
}

export interface DahsboardStickyHeaderConfig {
  enabled?: boolean;
  headerDisabled?: boolean;
  headerName?: string;
  backgroundColor?: string;
  disableBottomBorder?: boolean;
  disableBottomShadow?: boolean;
  enabledExpandableFilterRow?: boolean;
  enableStretchFilters?: boolean;
  headerTextColor?: string;
  headerTextSize?: number;
  headerContentOrder?: string[];
  filterLocations?: DashboardHeaderLocation;
}

export interface DashboardPageLayoutConfig {
  stickyHeader?: DahsboardStickyHeaderConfig;
}

export interface DashboardVersionConfig {
  dashboard_layout: Layout[];
  pdf_layout?: Layout[];
  email_layout?: Layout[];
  mobile_layout?: Layout[];
  layout_exclusions?: LayoutExclusionMap;
  data_panels: Record<string, DataPanelTemplate>;
  elements: Record<string, DashboardElement>;
  params: Record<string, DashboardParam>;
  datasets: Record<string, Dataset>;
  dashboard_page_layout_config?: DashboardPageLayoutConfig;
  email_header_html?: string;
  email_footer_html?: string;
}

export interface DashboardParam {
  id: string;
  dashboard_template_id?: number;
  name: string;
  type: string;
}
