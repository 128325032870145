import { FC, useContext } from 'react';

import { Icon, Popover, sprinkles } from 'components/ds';
import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import 'react-resizable/css/styles.css';

import Button from 'shared/Button';
import ExportOptions from 'components/ExportOptions/ExportOptions';

import '@explo-tech/react-grid-layout/css/styles.css';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { DashboardVariableMap, ExportElemConfig } from 'types/dashboardTypes';
import DashboardLayoutContext from 'components/DashboardLayout/DashboardLayoutContext';
import { clearShareLink } from 'actions/dashboardLayoutActions';
import { useDownload } from 'utils/exportUtils';
import { AnalyticsEventTracker } from 'utils/analyticsUtils';
import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';

const useStyles = makeStyles((theme: Theme) => ({
  exportButton: {
    width: '100%',
    height: 32,
    boxShadow: 'none !important',

    '& .bp3-spinner .bp3-spinner-track': {
      stroke: theme.palette.ds.grey100,
    },

    '&.bp3-button .bp3-button-text': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}));

type Props = {
  analyticsEventTracker?: AnalyticsEventTracker;
  config: ExportElemConfig;
  exportVars: DashboardVariableMap;
  downloadDashboardImage: (email?: string) => void;
  downloadDashboardPdf: (email?: string) => void;
  fetchShareData?: (password?: string, username?: string, isStrictViewingMode?: boolean) => void;
  isSharedView?: boolean;
  shouldUseJobQueue?: boolean;
  supportEmail?: string;
};

export const DashboardExportElement: FC<Props> = ({
  analyticsEventTracker,
  config,
  downloadDashboardImage,
  downloadDashboardPdf,
  fetchShareData,
  isSharedView,
  exportVars,
  shouldUseJobQueue,
  supportEmail,
}) => {
  const classes = useStyles({ disableShareLink: config.disableShareLink });
  const context = useContext(DashboardLayoutContext);
  const dispatch = useDispatch();

  const { windowState, downloadInfo } = useDownload(undefined);

  const doNotPopover =
    config.disableImageDownload && config.disablePdfDownload && config.disableShareLink;

  const renderPopoverContent = () => {
    if (!config.passwordEnabled && !fetchShareData) {
      return (
        <div className={sprinkles({ padding: 'sp1' })}>
          Unable to share this dashboard. It&rsquo;s likely not customer specific.
        </div>
      );
    }

    const webShareProps = { config, exportVars, fetchShareData, isSharedView };

    return (
      <ExportOptions
        downloadDashboardImage={downloadDashboardImage}
        downloadDashboardPdf={downloadDashboardPdf}
        downloadInfo={downloadInfo}
        shouldUseJobQueue={shouldUseJobQueue}
        supportEmail={supportEmail}
        webShareProps={webShareProps}
        windowState={windowState}
      />
    );
  };

  return (
    <div className={sprinkles({ paddingTop: 'sp2.5' })}>
      <Popover
        onOpenChange={(open) => {
          if (!open) dispatch(clearShareLink());
        }}
        portalContainerId={context.dashboardLayoutTagId}
        trigger={
          <div>
            <Button
              className={cx(
                classes.exportButton,
                GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.buttonBackgroundColor,
                GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.shareButtonHoverColor,
                GLOBAL_STYLE_CLASSNAMES.text.body.button.primaryFont,
                GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.buttons.buttonCornerRadius,
                GLOBAL_STYLE_CLASSNAMES.container.shadow.buttonShadow,
              )}
              disabled={doNotPopover}
              icon={<Icon name="arrow-up-from-line" />}
              onClick={() => {
                analyticsEventTracker?.(REPORTED_ANALYTIC_ACTION_TYPES.SHARE_BUTTON_CLICKED);
              }}
              text={config.label}
              type="primary"
            />
          </div>
        }
        width="large">
        {renderPopoverContent()}
      </Popover>
    </div>
  );
};
