import { ReportBuilderTab } from 'pages/ReportBuilder/HomeView/constants';
import parse from 'url-parse';

export const goToReportView = (reportId: number | null, viewId: string | null) => {
  if (reportId === null) return;
  const queryParam = `?report=${reportId}${viewId ? `&view=${viewId}` : ''}`;
  window.history.replaceState(null, '', queryParam);
};

export const goToHomePageTab = (tabId: ReportBuilderTab) =>
  window.history.replaceState(null, '', `?homeTab=${tabId}`);

const getQueryParams = () => parse(window.location.href, true).query;

export const getRBStateFromURL = () => {
  const queryParams = getQueryParams();
  const state: {
    homeTab?: string | undefined;
    reportId?: number | undefined;
    viewId?: string | undefined;
  } = {};

  if (queryParams['homeTab']) {
    state.homeTab = parsePossibleJSON(queryParams['homeTab']);
  } else if (queryParams['report']) {
    state.reportId = parsePossibleJSON(queryParams['report']);
    if (queryParams['view']) {
      state.viewId = parsePossibleJSON(queryParams['view']);
    }
  }

  return state;
};

const parsePossibleJSON = (s: string) => {
  try {
    return JSON.parse(s);
  } catch (e) {
    return s;
  }
};
