import { makeStyles, Theme } from '@material-ui/core/styles';

import Button from 'shared/Button';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: theme.spacing(12),
    backgroundColor: theme.palette.ds.white,
    display: 'flex',
    justifyContent: 'space-between',
    padding: `0 ${theme.spacing(6)}px`,
    borderBottom: `2px solid ${theme.palette.ds.grey400}`,
  },
  logoBtn: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },

    '&:active': {
      backgroundColor: 'transparent !important',
    },
  },
  logo: {
    height: 30,
  },
  leftNavOptions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rightNavOptions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dashboardNameContainer: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

type Props = {
  dashboardName: string;
  iconUrl?: string;
  onSignOut: () => void;
};

export default function PortalHeader({ dashboardName, iconUrl, onSignOut }: Props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.leftNavOptions}>
        {iconUrl && (
          <Button
            minimal
            className={classes.logoBtn}
            icon={<img alt="logo for explo" className={classes.logo} src={iconUrl} />}></Button>
        )}
        <div className={classes.dashboardNameContainer}>{dashboardName}</div>
      </div>
      <div className={classes.rightNavOptions}>
        <Button onClick={onSignOut} text="Sign out" type="primary" />
      </div>
    </div>
  );
}
