import { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import { useParams } from 'react-router-dom';

import VersionControlModal from 'components/VersionControlModal';
import { SavingInfo } from 'pages/dashboardPage/SavingInfo';
import EditResourceBannerDropdown from 'shared/ExploResource/EditResourceBannerDropdown';
import CustomerSelector from 'components/CustomerSelector';
import ConfigurabilityModal from './ConfigurabilityModal';
import { Button, Link, sprinkles } from 'components/ds';

import { Canvas } from 'actions/canvasActions';
import { VIEW_MODE } from './types';
import { doesUserHavePermission } from 'utils/permissionUtils';
import { PERMISSIONED_ACTIONS, PERMISSIONED_ENTITIES } from 'constants/roleConstants';
import { ResourcePageType } from 'types/exploResource';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    minHeight: 56,
    height: 56,
    borderBottom: `1px solid ${theme.palette.ds.grey400}`,
    backgroundColor: theme.palette.ds.white,
  },
  canvasInfo: {
    backgroundColor: theme.palette.ds.white,
    borderBottom: `1px solid ${theme.palette.ds.grey400}`,
    borderRight: `1px solid ${theme.palette.ds.grey400}`,
  },
  editContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `0px ${theme.spacing(3)}px`,
  },
  containerSide: {
    display: 'flex',
    alignItems: 'center',
  },
  viewingAsText: { marginRight: theme.spacing(3) },
}));

type Props = {
  canvas: Canvas;
};

enum ModalStatus {
  CLOSED = 'Closed',
  VERSION = 'Version',
  CONFIGURE = 'Configure',
}

export default function CanvasHeader({ canvas }: Props): JSX.Element {
  const classes = useStyles();

  const permissions = useSelector((state: ReduxState) => state.currentUser.permissions);
  const versionInfo = useSelector((state: ReduxState) => state.canvasEdit.versionInfo);

  const { view } = useParams<{ view: VIEW_MODE }>();
  const [modalStatus, setModalStatus] = useState(ModalStatus.CLOSED);

  const renderVersionControlModal = () => {
    if (modalStatus !== ModalStatus.VERSION) return null;

    return (
      <VersionControlModal
        closeModal={() => setModalStatus(ModalStatus.CLOSED)}
        pageType={ResourcePageType.ARCHITECT}
      />
    );
  };

  const renderConfigurabilityModal = () => {
    if (modalStatus !== ModalStatus.CONFIGURE) return null;

    return (
      <ConfigurabilityModal
        canvasId={canvas.id}
        closeModal={() => setModalStatus(ModalStatus.CLOSED)}
        configurability={canvas.configurability}
      />
    );
  };

  return (
    <div className={classes.root}>
      <EditResourceBannerDropdown
        className={classes.canvasInfo}
        openConfigurabilityModal={() => setModalStatus(ModalStatus.CONFIGURE)}
        pageType={ResourcePageType.ARCHITECT}
        resource={canvas}
      />
      <div className={classes.editContainer}>
        <div className={classes.containerSide}>
          <div className={classes.viewingAsText}>Viewing as</div>
          <CustomerSelector parseUrl disabled={view === VIEW_MODE.END_USER_DASHBOARDS} />
        </div>
        <div className={classes.containerSide}>
          {versionInfo ? (
            <>
              <SavingInfo
                isDraft={versionInfo.is_draft}
                resourceType={ResourcePageType.ARCHITECT}
                versionNumber={versionInfo.version_number}
              />
              <Button
                className={sprinkles({ marginRight: 'sp1' })}
                onClick={() => setModalStatus(ModalStatus.VERSION)}
                type="secondary">
                Version Control
              </Button>
            </>
          ) : null}
          {view === VIEW_MODE.END_USER_DASHBOARDS ? (
            doesUserHavePermission(
              permissions[PERMISSIONED_ENTITIES.ARCHITECT],
              PERMISSIONED_ACTIONS.UPDATE,
            ) ? (
              <Link to={VIEW_MODE.DATASETS} type="primary">
                Edit Blueprint
              </Link>
            ) : null
          ) : (
            <Link to={`/blueprint/${canvas.id}/${VIEW_MODE.END_USER_DASHBOARDS}`} type="primary">
              View by User
            </Link>
          )}
        </div>
      </div>
      {renderVersionControlModal()}
      {renderConfigurabilityModal()}
    </div>
  );
}
