import { useMemo } from 'react';
import cx from 'classnames';
import produce from 'immer';
import { makeStyles, Theme } from '@material-ui/core/styles';

import BaseDataTable from 'components/dataTable/baseDataTable';
import LoadingDataTable from 'components/dataTable/loadingDataTable';
import DataTableFooter from '../DataTable/DataTableFooter';
import NeedsConfigurationPanel from 'pages/dashboardPage/DashboardDatasetView/needsConfigurationPanel';

import { AdHocOperationInstructions } from 'types/dataPanelTemplate';
import { DatasetSchema, DatasetRow, DatabaseUnsupportedOperations } from 'types/datasets';
import { MAX_ROWS_TO_PREVIEW } from 'constants/dataConstants';
import {
  REPORTED_ANALYTIC_ACTION_TYPES,
  UserTransformedSchema,
  VisualizeOperationGeneralFormatOptions,
  VisualizePivotTableInstructions,
} from 'constants/types';
import { getMetricsByColumn } from 'utils/dashboardUtils';
import { HEADER_HEIGHT } from 'pages/dashboardPage/DashboardDatasetView/DashboardDatasetView';
import { isPivotTableReadyToDisplay } from '../../charts/utils';
import { sortTable } from 'utils/adHocUtils';
import { ResourceDataset } from 'types/exploResource';
import { AnalyticsEventTracker } from 'utils/analyticsUtils';
import { SpreadsheetType } from '../../../../reducers/dashboardLayoutReducer';
import { DashboardVariableMap } from 'types/dashboardTypes';

const FOOTER_HEIGHT = 34;

const useStyles = makeStyles((theme: Theme) => ({
  dataTable: {
    height: `calc(100% - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px) !important`,

    '&.noHeader': {
      height: `calc(100% - ${FOOTER_HEIGHT}px) !important`,
    },
    '&.noFooter': {
      height: `calc(100% - ${HEADER_HEIGHT}px) !important`,
    },
    '&.noHeader.noFooter': {
      height: `calc(100%) !important`,
    },
  },
  loadingDataTable: {
    height: `calc(100% - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px) !important`,
    borderBottom: `1px solid ${theme.palette.ds.grey500}`,
    '&.noHeader': {
      height: `calc(100% - ${FOOTER_HEIGHT}px) !important`,
    },
    '&.noFooter': {
      height: `calc(100% - ${HEADER_HEIGHT}px) !important`,
    },
    '&.noHeader.noFooter': {
      height: `calc(100%) !important`,
    },
  },
}));

type Props = {
  analyticsEventTracker?: AnalyticsEventTracker;
  adHocOperationInstructions: AdHocOperationInstructions;
  canDownloadDataPanel: boolean;
  dataPanelId: string;
  onDownloadPanelPdf?: (
    adHocOperationInstructions: AdHocOperationInstructions,
    email?: string,
    userTransformedSchema?: UserTransformedSchema,
  ) => void;
  onDownloadPanelSpreadsheet?: (
    fileFormat: SpreadsheetType,
    email?: string,
    userTransformedSchema?: UserTransformedSchema,
  ) => void;
  error?: boolean;
  loading?: boolean;
  onAdHocSortOrPageUpdate: (adHocOperationInstructions: AdHocOperationInstructions) => void;
  previewData: Record<string, string | number>[];
  schema: DatasetSchema;
  secondaryData: DatasetRow[];
  sourceDataRowCount?: number;
  userTransformedSchema?: UserTransformedSchema;
  datasets?: Record<string, ResourceDataset>;
  generalOptions: VisualizeOperationGeneralFormatOptions | undefined;
  instructions: VisualizePivotTableInstructions | undefined;
  shouldUseJobQueue?: boolean;
  supportEmail?: string;
  isEmbed: boolean;
  unsupportedOperations: DatabaseUnsupportedOperations[] | undefined;
  variables: DashboardVariableMap;
};

const getOnColumnSelect =
  ({
    adHocOperationInstructions,
    onAdHocSortOrPageUpdate,
    analyticsEventTracker,
  }: Pick<
    Props,
    'adHocOperationInstructions' | 'onAdHocSortOrPageUpdate' | 'analyticsEventTracker'
  >) =>
  (colIndex: number, headerList: DatasetSchema) => {
    const sortInfo = sortTable(
      colIndex,
      headerList,
      adHocOperationInstructions,
      onAdHocSortOrPageUpdate,
    );

    analyticsEventTracker?.(REPORTED_ANALYTIC_ACTION_TYPES.TABLE_SORTED, {
      column_name: sortInfo.column_name,
      order: sortInfo.order.toString(),
    });
  };

const getOnNewPage =
  ({
    adHocOperationInstructions,
    onAdHocSortOrPageUpdate,
    analyticsEventTracker,
    sourceDataRowCount,
  }: Pick<
    Props,
    | 'adHocOperationInstructions'
    | 'onAdHocSortOrPageUpdate'
    | 'analyticsEventTracker'
    | 'sourceDataRowCount'
  >) =>
  (newPage: string) => {
    if (!sourceDataRowCount) return;

    const newPageNumber = Number.parseInt(newPage);
    const maxPageNumber = Math.ceil(sourceDataRowCount / 50);

    if (
      !newPageNumber ||
      newPageNumber < 1 ||
      newPageNumber > maxPageNumber ||
      adHocOperationInstructions.currentPage === newPageNumber
    ) {
      return;
    }

    const newAdHocOperationInstructions = produce(adHocOperationInstructions, (draft) => {
      draft.currentPage = newPageNumber;
    });

    onAdHocSortOrPageUpdate(newAdHocOperationInstructions);

    analyticsEventTracker?.(REPORTED_ANALYTIC_ACTION_TYPES.TABLED_PAGED, {
      new_page: newPageNumber,
    });
  };

export default function PivotTable({
  adHocOperationInstructions,
  analyticsEventTracker,
  canDownloadDataPanel,
  dataPanelId,
  onDownloadPanelSpreadsheet,
  error,
  loading,
  onAdHocSortOrPageUpdate,
  onDownloadPanelPdf,
  previewData,
  schema,
  secondaryData,
  sourceDataRowCount,
  shouldUseJobQueue,
  datasets,
  instructions,
  generalOptions,
  supportEmail,
  isEmbed,
  unsupportedOperations,
  variables,
}: Props) {
  const classes = useStyles();
  const metricsByColumn = useMemo(() => getMetricsByColumn(secondaryData || []), [secondaryData]);

  const tableLoading = loading || !previewData;

  if (!isPivotTableReadyToDisplay(instructions)) {
    return <NeedsConfigurationPanel instructionsNeedConfiguration />;
  }

  const footer = (
    <DataTableFooter
      adHocOperationInstructions={adHocOperationInstructions}
      canDownloadDataPanel={canDownloadDataPanel}
      dataPanelId={dataPanelId}
      disableDownload={loading || error}
      disableDownloadExport={generalOptions?.export?.disableDownloadExport}
      disablePdfDownload={generalOptions?.export?.disablePdfDownload}
      enableEmailExport={generalOptions?.export?.enableEmailExport}
      isDownloadButtonHidden={generalOptions?.export?.isDownloadButtonHidden}
      isEmbed={isEmbed}
      loading={tableLoading}
      onDownloadPanelPdf={(email) => onDownloadPanelPdf?.(adHocOperationInstructions, email)}
      onDownloadPanelSpreadsheet={(fileFormat, email) =>
        onDownloadPanelSpreadsheet?.(fileFormat, email)
      }
      onNewPage={getOnNewPage({
        adHocOperationInstructions,
        onAdHocSortOrPageUpdate,
        analyticsEventTracker,
        sourceDataRowCount,
      })}
      shouldUseJobQueue={shouldUseJobQueue}
      sourceDataRowCount={sourceDataRowCount}
      supportEmail={supportEmail}
      unsupportedOperations={unsupportedOperations}
    />
  );

  if (tableLoading) {
    return (
      <>
        <LoadingDataTable
          disableRowHeader
          className={cx(classes.loadingDataTable, {
            noHeader: generalOptions?.headerConfig?.isHeaderHidden,
            noFooter: instructions?.isFooterHidden,
          })}
          maxRows={50}
          rowHeight={instructions?.rowHeight}
        />
        {!instructions?.isFooterHidden && footer}
      </>
    );
  }

  if (instructions?.displaySumRow) {
    const rowColName = instructions.rowColumn?.column.name;
    if (previewData.length && previewData[0] && rowColName) {
      previewData[0][rowColName] = instructions.sumRowText || 'Totals';
    }
  }

  return (
    <>
      <BaseDataTable
        disableRowHeader
        fill
        ignoreInvalidDates
        isDashboardTable
        noBorderRadius
        truncateEmptyRowSpace
        unrestrictedHeight
        useFriendlyNameForHeader
        changeSchemaList={instructions?.changeSchemaList}
        className={cx(classes.dataTable, {
          noHeader: generalOptions?.headerConfig?.isHeaderHidden,
          noFooter: instructions?.isFooterHidden,
        })}
        columnLinesEnabled={instructions?.isColumnLinesEnabled}
        columnWidths={instructions?.columnWidths}
        datasets={datasets}
        dateFormat={instructions?.dateFormat}
        enableColumnResizing={false}
        firstColumnTitle={instructions?.firstColumnTitle}
        firstRowBackgroundColor={
          instructions?.displaySumRow ? instructions?.sumRowBackgroundColor : undefined
        }
        isColumnHeadersBolded={instructions?.isColumnHeadersBolded}
        isFirstColumnBolded={instructions?.isFirstColumnBolded}
        isFirstColumnFrozen={instructions?.isFirstColumnFrozen}
        isFirstRowBolded={instructions?.displaySumRow && instructions?.sumRowBold}
        isSortable={!instructions?.isColumnSortingDisabled}
        loading={tableLoading}
        maxRows={MAX_ROWS_TO_PREVIEW}
        metricsByColumn={metricsByColumn}
        onColumnSelect={getOnColumnSelect({
          adHocOperationInstructions,
          onAdHocSortOrPageUpdate,
          analyticsEventTracker,
        })}
        rowHeight={instructions?.rowHeight}
        rowLinesDisabled={instructions?.isRowLinesDisabled}
        rows={previewData || []}
        schema={schema}
        schemaDisplayOptions={instructions?.schemaDisplayOptions || {}}
        shouldTruncateText={instructions?.shouldTruncateText}
        shouldVisuallyGroupByFirstColumn={instructions?.shouldVisuallyGroupByFirstColumn}
        sortInfo={adHocOperationInstructions.sortInfo}
        stringFormat={instructions?.stringFormat}
        variables={variables}
      />
      {footer}
    </>
  );
}
