import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import { Switch } from 'components/ds';
import DropdownSelect from 'shared/DropdownSelect';
import RangeInput from 'shared/RangeInput';
import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';
import DateFormatDescriptiveText from 'pages/dashboardPage/DataPanelConfigV2/FormatConfigTab/DateFormatDescriptiveText';

import {
  OPERATION_TYPES,
  V2BoxPlotInstructions,
  V2ScatterPlotInstructions,
  V2TwoDimensionChartInstructions,
  XAxisFormat,
} from 'constants/types';
import {
  DATE_TYPES,
  INTEGER_DATA_TYPE,
  STRING,
  STRING_FORMATS,
  V2_NUMBER_FORMATS,
  VIZ_OPS_WITH_CATEGORY_SELECT_DRILLDOWN,
} from 'constants/dataConstants';

type Instructions =
  | V2TwoDimensionChartInstructions
  | V2BoxPlotInstructions
  | V2ScatterPlotInstructions;

type Props = {
  instructions: Instructions;
  operationType: OPERATION_TYPES;
  configInputClass: string;
  enableScrolling?: boolean;
  isCanvas?: boolean;
  isHorizontal?: boolean;

  updateXAxisFormat: (xAxisFormat: XAxisFormat) => void;
};

export default function SharedXAxisConfigs({
  instructions,
  isHorizontal,
  enableScrolling,
  configInputClass,
  operationType,
  updateXAxisFormat,
  isCanvas,
}: Props) {
  const { xAxisFormat } = instructions;

  const selectedStringFormat = xAxisFormat?.stringFormat?.format || STRING_FORMATS.DEFAULT;
  const selectedNumberFormat = xAxisFormat?.numberFormat || V2_NUMBER_FORMATS.NUMBER;

  const isSpiderChart = operationType === OPERATION_TYPES.VISUALIZE_SPIDER_CHART;
  const isBarFunnel = operationType === OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2;

  const areTotalValueConfigurable =
    isSpiderChart ||
    operationType === OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2 ||
    operationType === OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2 ||
    operationType === OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2 ||
    operationType === OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2 ||
    operationType === OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2 ||
    operationType === OPERATION_TYPES.VISUALIZE_LINE_CHART_V2;
  const areBarValuesConfigurable =
    VIZ_OPS_WITH_CATEGORY_SELECT_DRILLDOWN.has(operationType) ||
    operationType === OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2 ||
    operationType === OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2;

  let xAxisColType: string | undefined;
  const allowMaxCategories = !isCanvas;
  if (operationType === OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2) {
    xAxisColType = (instructions as V2BoxPlotInstructions).groupingColumn?.column.type;
  } else if (operationType === OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2) {
    xAxisColType = (instructions as V2ScatterPlotInstructions).xAxisColumn?.type;
  } else {
    const v2Instructions = instructions as V2TwoDimensionChartInstructions;
    xAxisColType = v2Instructions.categoryColumn?.column.type;
  }

  const isDateColType = DATE_TYPES.has(xAxisColType ?? '');

  return (
    <>
      {isBarFunnel ? null : (
        <>
          <Switch
            useCustomStyles
            className={configInputClass}
            label="Axis Title"
            onChange={() => updateXAxisFormat({ showTitle: !xAxisFormat?.showTitle })}
            switchOn={xAxisFormat?.showTitle}
          />
          {xAxisFormat?.showTitle ? (
            <InputWithBlurSave
              containerClassName={configInputClass}
              initialValue={xAxisFormat?.title}
              onNewValueSubmitted={(newValue) => updateXAxisFormat({ title: newValue })}
            />
          ) : null}
        </>
      )}
      {xAxisColType === INTEGER_DATA_TYPE && !isCanvas && !isBarFunnel ? (
        <>
          <ToggleButtonGroup fillWidth className={configInputClass} label="Value Format">
            {[V2_NUMBER_FORMATS.NUMBER, V2_NUMBER_FORMATS.CURRENCY, V2_NUMBER_FORMATS.PERCENT].map(
              (numberFormat) => (
                <ToggleButton
                  active={numberFormat.id === selectedNumberFormat.id}
                  key={numberFormat.id}
                  onClick={() => updateXAxisFormat({ numberFormat })}
                  text={numberFormat.abbreviation}
                />
              ),
            )}
          </ToggleButtonGroup>
          <Switch
            useCustomStyles
            className={configInputClass}
            label="Show Axis Decimals"
            onChange={() => updateXAxisFormat({ showDecimals: !xAxisFormat?.showDecimals })}
            switchOn={xAxisFormat?.showDecimals}
          />
          {xAxisFormat?.showDecimals ? (
            <InputWithBlurSave
              containerClassName={configInputClass}
              helpText="This will only apply to the data tooltip unless 'Show Axis Decimals' is enabled."
              initialValue={String(xAxisFormat?.decimalPlaces ?? 2)}
              label="Decimal Places"
              onNewValueSubmitted={(newValue: string) => {
                const intValue = parseInt(newValue);
                updateXAxisFormat({ decimalPlaces: intValue > 0 ? intValue : 0 });
              }}
            />
          ) : null}
          <Switch
            useCustomStyles
            className={configInputClass}
            label="Use Logarithmic Scale"
            onChange={() => {
              let min = xAxisFormat?.min;
              // log scales do not allow minimumValue of <= 0
              if (min !== undefined && min <= 0) min = undefined;

              updateXAxisFormat({ useLogScale: !xAxisFormat?.useLogScale, min });
            }}
            switchOn={xAxisFormat?.useLogScale}
          />
          <RangeInput
            className={configInputClass}
            endLabel="Maximum Value"
            endVal={String(xAxisFormat?.max ?? '')}
            onNewRange={(newStart?: string, newEnd?: string) => {
              const min = parseFloat(newStart ?? '');
              const max = parseFloat(newEnd ?? '');

              const validIfLog = !(xAxisFormat?.useLogScale && min <= 0);

              updateXAxisFormat({
                min: min >= 0 && validIfLog ? min : undefined,
                max: max >= 0 ? max : undefined,
              });
            }}
            startLabel="Minimum Value"
            startVal={String(xAxisFormat?.min ?? '')}
          />
        </>
      ) : null}
      {!isSpiderChart && !isBarFunnel ? (
        <ToggleButtonGroup fillWidth className={configInputClass} label="Axis Placement">
          <ToggleButton
            active={!xAxisFormat?.flipAxis}
            key="bottom"
            onClick={() => updateXAxisFormat({ flipAxis: false })}
            text={isHorizontal ? 'Left' : 'Bottom'}
          />
          <ToggleButton
            active={!!xAxisFormat?.flipAxis}
            key="top"
            onClick={() => updateXAxisFormat({ flipAxis: true })}
            text={isHorizontal ? 'Right' : 'Top'}
          />
        </ToggleButtonGroup>
      ) : null}
      {isDateColType && !isCanvas ? (
        <InputWithBlurSave
          containerClassName={configInputClass}
          descriptiveText={<DateFormatDescriptiveText />}
          initialValue={xAxisFormat?.dateFormat}
          label="Date Format"
          onNewValueSubmitted={(newValue) => updateXAxisFormat({ dateFormat: newValue })}
        />
      ) : null}
      {xAxisColType === STRING && !isCanvas ? (
        <>
          <DropdownSelect
            btnMinimal
            fillWidth
            ignoreCustomStyles
            minimal
            containerClassName={configInputClass}
            filterable={false}
            label="String Format"
            noSelectionText="Select a format"
            onChange={(item) => {
              updateXAxisFormat({
                stringFormat: { ...xAxisFormat?.stringFormat, format: item.id as STRING_FORMATS },
              });
            }}
            options={Object.values(STRING_FORMATS).map((formatOption) => ({
              id: formatOption,
              name: formatOption,
            }))}
            selectedItem={{ id: selectedStringFormat, name: selectedStringFormat }}
          />
          <Switch
            useCustomStyles
            className={configInputClass}
            label="Remove Underscores"
            onChange={() => {
              updateXAxisFormat({
                stringFormat: {
                  ...xAxisFormat?.stringFormat,
                  replaceUnderscores: !xAxisFormat?.stringFormat?.replaceUnderscores,
                },
              });
            }}
            switchOn={xAxisFormat?.stringFormat?.replaceUnderscores}
          />
        </>
      ) : null}
      {isBarFunnel ? null : (
        <Switch
          useCustomStyles
          className={configInputClass}
          label="Axis Line"
          onChange={() => updateXAxisFormat({ hideAxisLine: !xAxisFormat?.hideAxisLine })}
          switchOn={!xAxisFormat?.hideAxisLine}
        />
      )}
      {!xAxisFormat?.hideAxisLine && !isBarFunnel ? (
        <>
          {!isSpiderChart ? (
            <Switch
              useCustomStyles
              className={configInputClass}
              label="Axis Ticks"
              onChange={() => updateXAxisFormat({ hideAxisTicks: !xAxisFormat?.hideAxisTicks })}
              switchOn={!xAxisFormat?.hideAxisTicks}
            />
          ) : null}
          <Switch
            useCustomStyles
            className={configInputClass}
            label="Axis Labels"
            onChange={() => updateXAxisFormat({ hideAxisLabels: !xAxisFormat?.hideAxisLabels })}
            switchOn={!xAxisFormat?.hideAxisLabels}
          />
        </>
      ) : null}
      {!isBarFunnel && !isCanvas ? (
        <Switch
          useCustomStyles
          className={configInputClass}
          label="Reverse Axis"
          onChange={() => updateXAxisFormat({ reverseAxis: !xAxisFormat?.reverseAxis })}
          switchOn={xAxisFormat?.reverseAxis}
        />
      ) : null}
      {!isBarFunnel && areTotalValueConfigurable ? (
        <Switch
          useCustomStyles
          className={configInputClass}
          label="Show Total Values"
          onChange={() => updateXAxisFormat({ hideTotalValues: !xAxisFormat?.hideTotalValues })}
          switchOn={!xAxisFormat?.hideTotalValues}
        />
      ) : null}
      {!isBarFunnel && xAxisColType !== INTEGER_DATA_TYPE && allowMaxCategories ? (
        <InputWithBlurSave
          containerClassName={configInputClass}
          initialValue={String(xAxisFormat?.maxCategories ?? '')}
          label="Maximum Categories"
          onNewValueSubmitted={(newValue) => {
            const intValue = parseInt(newValue);
            updateXAxisFormat({ maxCategories: intValue >= 0 ? intValue : undefined });
          }}
        />
      ) : null}
      {areBarValuesConfigurable ? (
        <>
          {isBarFunnel ? null : (
            <Switch
              useCustomStyles
              className={configInputClass}
              label="Show Bar Values"
              onChange={() => updateXAxisFormat({ showBarValues: !xAxisFormat?.showBarValues })}
              switchOn={xAxisFormat?.showBarValues}
            />
          )}
          <InputWithBlurSave
            hideRightIconInteractions
            containerClassName={configInputClass}
            initialValue={String(xAxisFormat?.barCornerRadius || 0)}
            label="Bar Corner Radius"
            onNewValueSubmitted={(newValue: string) => {
              const intValue = parseInt(newValue);
              updateXAxisFormat({ barCornerRadius: intValue >= 0 ? intValue : undefined });
            }}
          />
        </>
      ) : null}
      {!isBarFunnel && enableScrolling ? (
        <Switch
          useCustomStyles
          className={configInputClass}
          label="Enable Scroll Bar"
          onChange={() => updateXAxisFormat({ enableScroll: !xAxisFormat?.enableScroll })}
          switchOn={xAxisFormat?.enableScroll}
        />
      ) : null}
    </>
  );
}
