import { FC } from 'react';

import * as styles from '../styles.css';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import { Switch } from 'components/ds';

import { DateElemConfig } from 'types/dashboardTypes';
import { getUpdateConfigFunc } from '../utils';

type Props = {
  config: DateElemConfig;

  updateDateConfig: (config: DateElemConfig) => void;
};

export const DateAdjustDisplayConfig: FC<Props> = ({ config, updateDateConfig }) => {
  const updateConfig = getUpdateConfigFunc(config, updateDateConfig);

  return (
    <>
      <InputWithBlurSave
        ignoreCustomStyles
        containerClassName={styles.configInput}
        initialValue={config.label}
        label="Label"
        onNewValueSubmitted={(newValue) => updateConfig((draft) => (draft.label = newValue))}
        placeholder="Enter a label"
      />
      <Switch
        className={styles.configInput}
        label="Descriptive Tooltip"
        onChange={() => updateConfig((draft) => (draft.showTooltip = !draft.showTooltip))}
        switchOn={config.showTooltip}
      />
      {config.showTooltip ? (
        <InputWithBlurSave
          containerClassName={styles.configInput}
          initialValue={config.infoTooltipText}
          onNewValueSubmitted={(newValue: string) =>
            updateConfig((draft) => {
              draft.infoTooltipText = newValue;
            })
          }
        />
      ) : null}
    </>
  );
};
