import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

import EmbeddedDashboard from 'components/EmbeddedDashboard/EmbeddedDashboard';
import { VIEW_MODE } from 'types/dashboardTypes';

type MatchParams = {
  dashboardId: string;
  customerToken: string;
  environment?: string;
};

type Props = RouteComponentProps<MatchParams>;
class IFrameDashboardPage extends Component<Props> {
  render() {
    const { match } = this.props;

    return (
      <EmbeddedDashboard
        customerToken={match.params.customerToken}
        dashboardEmbedId={match.params.dashboardId}
        embedType="iframe"
        environment={match.params.environment}
        isStrict={match.url.toUpperCase().endsWith('STRICT')}
        viewMode={VIEW_MODE.DEFAULT}
      />
    );
  }
}

export default withRouter(IFrameDashboardPage);
