import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';

import * as styles from './styles.css';
import { Icon, Spinner, Tag, Menu, MenuActionItem } from 'components/ds';
import { ManageDataSourceSideSheet } from './modals/ManageDataSourceSideSheet';

import { dataSourceByType, DefaultIcon } from 'constants/dataSourceConstants';
import { DataSource } from 'actions/dataSourceActions';
import { ROUTES } from 'constants/routes';

type Props = {
  dataSource: DataSource;
  deleteLoading: boolean;
  accessGroupNames: string[];
  schemaHasOneAccessGroup: boolean;
  userCanEdit?: boolean;
  onDelete?: () => void;
  onSyncTables?: () => void;
};

export const DataSourceItem: FC<Props> = ({
  dataSource,
  deleteLoading,
  accessGroupNames,
  schemaHasOneAccessGroup,
  userCanEdit,
  onDelete,
  onSyncTables,
}) => {
  const history = useHistory();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSidesheetOpen, setIsSideSheetOpen] = useState(false);

  const dataSourceImg = dataSourceByType[dataSource.source_type]?.datasourceIconImg || DefaultIcon;

  const renderDefaultTags = () => {
    if (accessGroupNames.length === 0) return null;
    else {
      return accessGroupNames.map((accessGroupName) => (
        <Tag key={accessGroupName}>
          {schemaHasOneAccessGroup ? 'Default' : accessGroupName + ' default'}
        </Tag>
      ));
    }
  };

  const renderDropdown = () => {
    if (!userCanEdit && !onDelete) return null;
    return (
      <Menu
        align="end"
        isOpen={isDropdownOpen}
        onOpenChange={(open) => {
          if (!deleteLoading) setIsDropdownOpen(open);
        }}
        trigger={
          deleteLoading ? (
            <div className={styles.rightIcon}>
              <Spinner size="md" />
            </div>
          ) : (
            <div className={styles.schemaEllipsisMenu}>
              <Icon name="ellipsis-vertical" />
            </div>
          )
        }
        width="small">
        {userCanEdit ? (
          <MenuActionItem
            onSelect={() => {
              setIsDropdownOpen(false);
              setIsSideSheetOpen(true);
            }}
            text="Edit"
          />
        ) : null}
        {onDelete ? (
          <MenuActionItem
            onSelect={() => {
              setIsDropdownOpen(false);
              onDelete();
            }}
            text="Delete"
          />
        ) : null}
      </Menu>
    );
  };

  return (
    <div className={styles.dataSourceItemContainer}>
      <div
        className={styles.dataSourceItemContentContainer}
        onClick={() => {
          if (onSyncTables) onSyncTables();
          else history.push(`${ROUTES.DATA_PAGE}/${dataSource.id}`);
        }}>
        <div className={styles.dataSourceIconNameContainer}>
          <div className={styles.dataSourceLogoContainer}>
            <img
              alt={`${dataSource.name} logo`}
              className={styles.dataSourceLogo}
              src={dataSourceImg}
            />
          </div>
          <div className={styles.dataSourceNameIdContainer}>
            <p className={styles.primaryText}>{dataSource.name}</p>
            <p className={styles.secondaryText}>
              {dataSource.provided_id ?? String(dataSource.id)}
            </p>
          </div>
        </div>
        <div className={styles.defaultDBTagContainer}>{renderDefaultTags()}</div>
      </div>
      {renderDropdown()}
      {isSidesheetOpen ? (
        <ManageDataSourceSideSheet
          dataSource={dataSource}
          isOpen={isSidesheetOpen}
          setIsOpen={setIsSideSheetOpen}
        />
      ) : null}
    </div>
  );
};
