import { FC, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';

import DashboardLayout from 'components/DashboardLayout/DashboardLayout';
import ArchitectCustomerDashboardHeader from 'pages/architectCustomerDashboardPage/dashboardHeader';
import EmptyDashboard from '../EmptyDashboard';

import { CanvasVersionConfig } from 'actions/canvasConfigActions';
import { Canvas, DEFAULT_CANVAS_CONFIGURABILITY } from 'actions/canvasActions';
import { ReduxState } from 'reducers/rootReducer';
import { getSelectedCustomer } from 'reducers/customersReducer';
import { getCanvasDashboardElements } from 'utils/canvasConfigUtils';
import * as dptActions from 'actions/dataPanelTemplateAction';
import {
  clearArchitectCustomerDashboardExample,
  setArchitectCustomerDashboardForExample,
  setSelectedDashboardItemId,
} from 'actions/architectCustomerDashboardActions';
import { DashboardVariableMap, PAGE_TYPE, VIEW_MODE } from 'types/dashboardTypes';
import { updateEUDLayout } from 'actions/architectCustomerDashboardConfigActions';
import { fetchDashboardDatasetPreview } from 'actions/datasetActions';
import { getCurrentDashboard } from 'reducers/architectCustomerDashboardReducer';

const useStyles = makeStyles((theme: Theme) => ({
  banner: {
    height: 40,
    backgroundColor: theme.palette.ds.pressed.lightRed,
    color: theme.palette.ds.pressed.red,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,
    fontSize: 12,
  },
}));

type Props = {
  canvas: Canvas;
  config: CanvasVersionConfig;
  versionNumber: number;
  width?: number;

  setVariables: (variables: DashboardVariableMap) => void;
};

export const ExampleDashboard: FC<Props> = ({
  canvas,
  config,
  versionNumber,
  setVariables,
  width,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    selectedUserGroup,
    globalStyleConfig,
    exampleDashboard,
    isEditing,
    selectedDashboardItemId,
  } = useSelector(
    (state: ReduxState) => ({
      selectedUserGroup: getSelectedCustomer(state.customers),
      globalStyleConfig: state.dashboardStyles.globalStyleConfig,
      exampleDashboard: getCurrentDashboard(state.architectCustomerDashboard),
      isEditing: state.architectCustomerDashboard.editingDashboard !== null,
      selectedDashboardItemId: state.architectCustomerDashboard.selectedDashboardItemId,
    }),
    shallowEqual,
  );

  useEffect(() => {
    return () => {
      dispatch(clearArchitectCustomerDashboardExample());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!exampleDashboard) {
      dispatch(
        setArchitectCustomerDashboardForExample({
          canvasVersion: { configuration: config, version_number: versionNumber },
          canvas,
        }),
      );
    }
  }, [exampleDashboard, canvas, config, dispatch, versionNumber]);

  const elemIdsInLayout = useMemo(() => {
    return new Set(exampleDashboard?.configuration.layout.map((elem) => elem.i));
  }, [exampleDashboard?.configuration.layout]);

  const dashboardElements = useMemo(() => {
    return getCanvasDashboardElements(config, elemIdsInLayout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.filters, config.customComponents, elemIdsInLayout]);

  if (!exampleDashboard) return null;

  const attachResourceId = (data: { postData: { resource_id?: number } }) => {
    data.postData.resource_id = canvas.id;
  };

  const dataPanels = Object.values(exampleDashboard.configuration.data_panels);

  return (
    <div
      id="layout-editor"
      style={{
        height: '100%',
        width: '100%',
        backgroundColor: globalStyleConfig.base.backgroundColor,
      }}>
      <div className={classes.banner}>
        This is a sandbox. Changes made on this page won&rsquo;t be saved.
      </div>
      <ArchitectCustomerDashboardHeader
        canvasConfig={config}
        canvasConfigurability={canvas.configurability ?? DEFAULT_CANVAS_CONFIGURABILITY}
        canvasEmbedId={''} // EmbedId only used for saving
        canvasName={canvas.name}
        dashboard={exampleDashboard}
        dashboardPadding={globalStyleConfig.base.spacing.default}
        portalContainerId="layout-editor"
        variables={{}}
      />
      {exampleDashboard.configuration.layout.length === 0 ? (
        <EmptyDashboard />
      ) : (
        <DashboardLayout
          isArchitectCustomerDashboard
          isCanvas
          dashboardElements={dashboardElements}
          dashboardLayout={exampleDashboard.configuration.layout}
          dataPanels={dataPanels}
          datasets={config.datasets}
          downloadDataPanelSpreadsheet={(fetchDPCsv) => {
            attachResourceId(fetchDPCsv);
            dispatch(dptActions.downloadDataPanelSpreadsheet(fetchDPCsv));
          }}
          editableDashboard={isEditing}
          exploResource={canvas}
          fetchDataPanel={(fetchDP, onSuccess) => {
            attachResourceId(fetchDP);
            dispatch(dptActions.fetchDataPanel(fetchDP, onSuccess));
          }}
          fetchDataPanelRowCount={(fetchDPRowCount) => {
            attachResourceId(fetchDPRowCount);
            dispatch(dptActions.fetchDataPanelRowCount(fetchDPRowCount));
          }}
          fetchDatasetPreview={(fetchDSPreview, onSuccess, onError) =>
            dispatch(fetchDashboardDatasetPreview(fetchDSPreview, onSuccess, onError))
          }
          fetchSecondaryData={(fetchSecondary) => {
            attachResourceId(fetchSecondary);
            dispatch(dptActions.fetchSecondaryData(fetchSecondary));
          }}
          fetchShareData={() => console.warn('Not supported')}
          isViewOnly={false}
          onCloseConfigClicked={() => dispatch(setSelectedDashboardItemId(undefined))}
          onDashboardItemSelect={(_, id) => dispatch(setSelectedDashboardItemId(id))}
          onVariablesChange={setVariables}
          pageType={PAGE_TYPE.EXPLO_APP}
          resourceVersionNumber={versionNumber}
          selectedDashboardItemId={selectedDashboardItemId}
          timezone="UTC"
          updateDashboardLayout={(newLayout) => dispatch(updateEUDLayout(newLayout))}
          userGroup={selectedUserGroup ?? undefined}
          viewMode={VIEW_MODE.DEFAULT}
          width={width}
        />
      )}
    </div>
  );
};
