import { FC, useContext } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import DashboardLayoutContext from 'components/DashboardLayout/DashboardLayoutContext';

import { Icon, Popover } from 'components/ds';
import Button from 'shared/Button';

import { OpenDrilldownModalType } from 'types/dashboardTypes';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import ExportOptions, {
  DownloadFunc,
  DownloadSpreadsheetFunc,
} from 'components/ExportOptions/ExportOptions';
import { useDownload } from 'utils/exportUtils';

const useStyles = makeStyles(() => ({
  customCompactButton: { '&.bp3-button': { padding: 0 } },
  reducedOpacity: { opacity: 0.75 },
}));

export interface ExportProps {
  disablePdfDownload?: boolean;
  disableDownloadExport?: boolean;
  enableEmailExport?: boolean;
  onDownloadPanelSpreadsheet: DownloadSpreadsheetFunc;
  onDownloadPanelPdf?: DownloadFunc;
  shouldUseJobQueue?: boolean;
  supportEmail?: string;
  error: boolean;
}

interface Props {
  dataPanelId: string;
  disabled?: boolean;
  reduceOpacity?: boolean;
  exportProps?: ExportProps;
  openDrilldownModal?: OpenDrilldownModalType;
}

export const ChartMenu: FC<Props> = ({
  dataPanelId,
  disabled,
  reduceOpacity,
  exportProps,
  openDrilldownModal,
}) => {
  const classes = useStyles();
  const context = useContext(DashboardLayoutContext);
  const { windowState, downloadInfo } = useDownload(dataPanelId);

  // When the drilldown is closed, the menu should also be closed so it's not floating
  const handleOpenDrilldownModal: OpenDrilldownModalType = (
    dptId,
    categoryColumn,
    category,
    subCategoryColumn,
    subCategory,
    excludedCategories,
  ) => {
    if (!openDrilldownModal) return;
    openDrilldownModal(
      dptId,
      categoryColumn,
      category,
      subCategoryColumn,
      subCategory,
      excludedCategories,
    );
  };

  // If export props is not passed then exporting should not be allowed at all
  const exportDisabled = !exportProps || exportProps?.disableDownloadExport;

  if (!openDrilldownModal && exportDisabled && !exportProps?.enableEmailExport) return null;

  const trigger = (
    <Button
      minimal
      className={cx(classes.customCompactButton, {
        [classes.reducedOpacity]: reduceOpacity,
      })}
      disabled={disabled}
      icon={
        <Icon
          className={GLOBAL_STYLE_CLASSNAMES.text.secondaryColor.color}
          name="ellipsis-vertical"
        />
      }
    />
  );

  return (
    <Popover
      align="end"
      portalContainerId={context.dashboardLayoutTagId}
      trigger={<span>{trigger}</span>} //requires span because old button has no forwardRef
      width="medium">
      <ExportOptions
        disableDataPanelPdfDownload // Chart PDF export works, but is buggy
        dataPanelId={dataPanelId}
        disableDownloadExport={exportDisabled}
        downloadInfo={downloadInfo}
        enableEmailExport={exportProps?.enableEmailExport}
        onDownloadPanelPdf={() => console.warn('Not supported in chart view')}
        onDownloadPanelSpreadsheet={exportProps?.onDownloadPanelSpreadsheet}
        openDrilldownModal={openDrilldownModal ? handleOpenDrilldownModal : undefined}
        shouldUseJobQueue={exportProps?.shouldUseJobQueue}
        supportEmail={exportProps?.supportEmail}
        windowState={windowState}
      />
    </Popover>
  );
};
