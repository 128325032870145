import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isIdle, isLoading, isSuccess } from 'remotedata';

import { ResourceListPage } from 'shared/ExploResource/ResourceListPage';

import { pageView } from 'analytics/exploAnalytics';
import { fetchReportBuilders } from 'actions/reportBuilderActions';
import { ReduxState } from 'reducers/rootReducer';
import { ResourcePageType } from 'types/exploResource';

export const ReportBuilderListPage: FC = () => {
  const dispatch = useDispatch();

  const reportBuilders = useSelector((state: ReduxState) => state.reportBuilder.reportBuilders);

  useEffect(() => {
    document.title = 'Explo | Report Builder';
    pageView('Home App (Report Builders)');
  });

  useEffect(() => {
    if (isIdle(reportBuilders)) dispatch(fetchReportBuilders());
  }, [reportBuilders, dispatch]);

  return (
    <ResourceListPage
      pageType={ResourcePageType.REPORT_BUILDER}
      resources={isSuccess(reportBuilders) ? reportBuilders.data : undefined}
      resourcesLoading={isLoading(reportBuilders)}
    />
  );
};
