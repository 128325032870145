import { ContextType, PureComponent } from 'react';
import cx from 'classnames';
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core/styles';
import { format } from 'utils/localizationUtils';
import { Icon } from '@blueprintjs/core';
import { isNumber } from 'utils/standard';

import NeedsConfigurationPanel from 'pages/dashboardPage/DashboardDatasetView/needsConfigurationPanel';
import { InfoIcon } from 'components/InfoIcon';
import { ProgressBar } from 'components/ProgressBar';
import { UrlClickThroughButton } from 'components/UrlClickThrough';
import { ChartMenu } from 'components/ChartMenu';
import { Spinner, sprinkles, Tooltip, vars } from 'components/ds';

import { V2_NUMBER_FORMATS } from 'constants/dataConstants';
import {
  V2KPIChartInstructions,
  OPERATION_TYPES,
  VisualizeOperationGeneralFormatOptions,
  ColorSettings,
} from 'constants/types';
import {
  TITLE_VALUE_ARRANGEMENTS,
  VERTICAL_CONTENT_ALIGNMENTS,
  TEXT_ELEM_HORIZ_ALIGNMENTS,
  OpenDrilldownModalType,
} from 'types/dashboardTypes';
import { DatasetSchema } from 'types/datasets';
import { formatValue } from './utils';
import { getCategoricalColors, GlobalStylesContext, GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { aggReady } from 'utils/dataPanelConfigUtils';

declare global {
  interface PointOptionsObject {
    custom: Record<string, boolean | number | string>;
  }
}

const HEADER_HEIGHT = 48;
const headerStylesClass = sprinkles({ width: 'fill', display: 'flex', justifyContent: 'flex-end' });

const styles = (theme: Theme) =>
  createStyles({
    baseContainer: (props: PassedProps) => ({
      paddingLeft: `${props.defaultContainerPadding / 2}px`,
      paddingRight: `${props.defaultContainerPadding / 2}px`,
    }),
    headerContainer: (props: PassedProps) => ({
      paddingBottom:
        props.instructions?.generalFormat?.title_value_arrangement ===
          TITLE_VALUE_ARRANGEMENTS.FIXED_LEFT ||
        props.instructions?.generalFormat?.title_value_arrangement ===
          TITLE_VALUE_ARRANGEMENTS.FIXED_CENTER
          ? `0px`
          : `${props.defaultContainerPadding / 2}px`,
      paddingTop: `${props.defaultContainerPadding / 2}px`,
    }),
    bodyContainer: (props: PassedProps) => ({
      paddingTop:
        props.instructions?.generalFormat?.title_value_arrangement ===
          TITLE_VALUE_ARRANGEMENTS.FIXED_LEFT ||
        props.instructions?.generalFormat?.title_value_arrangement ===
          TITLE_VALUE_ARRANGEMENTS.FIXED_CENTER
          ? `0px`
          : `${props.defaultContainerPadding / 2}px`,
      paddingBottom: `${props.defaultContainerPadding / 2}px`,
    }),
    kpiHeaderContainer: {
      height: HEADER_HEIGHT,
      zIndex: 1,
      position: 'absolute',
    },
    title: {
      fontWeight: 'bold',
      fontSize: 16,
    },
    subtitle: {
      fontSize: 14,
    },
    bold: {
      fontWeight: 600,
    },
    italic: {
      fontStyle: 'italic',
    },
    trend: {
      color: '#DB3737',
      fontWeight: 600,

      '&.positive': {
        color: '#0F9960',
      },
    },
    valueImage: {
      marginRight: theme.spacing(2),
      height: theme.spacing(9),
      width: theme.spacing(9),
    },
  });

type PassedProps = {
  loading?: boolean;
  previewData: Record<string, string | number>[];
  instructions?: V2KPIChartInstructions;
  dataPanelTemplateId: string;
  schema: DatasetSchema;
  hideChartMenu?: boolean;
  infoTooltipText?: string;
  operationType: OPERATION_TYPES;
  newDataLoading?: boolean;
  defaultContainerPadding: number;
  generalOptions: VisualizeOperationGeneralFormatOptions | undefined;
  processTemplatedValue: (s: string) => string;
  openDrilldownModal?: OpenDrilldownModalType;
};

type Props = PassedProps & WithStyles<typeof styles>;

type State = {};

class BaseSingleNumberChart extends PureComponent<Props, State> {
  static contextType = GlobalStylesContext;
  context!: ContextType<typeof GlobalStylesContext>;

  instructionsReadyToDisplay = (instructions?: V2KPIChartInstructions) => {
    return !!(instructions && instructions.aggColumn?.column && aggReady(instructions.aggColumn));
  };

  hasShownTitle = () => {
    const { generalOptions } = this.props;
    return !generalOptions?.headerConfig?.isHeaderHidden && this.getTitle();
  };

  hasFixedTitle = () => {
    const { instructions } = this.props;
    return (
      instructions?.generalFormat?.title_value_arrangement ===
        TITLE_VALUE_ARRANGEMENTS.FIXED_CENTER ||
      instructions?.generalFormat?.title_value_arrangement === TITLE_VALUE_ARRANGEMENTS.FIXED_LEFT
    );
  };

  hasShownHeader = () => {
    const { instructions } = this.props;
    return (this.hasShownTitle() || instructions?.generalFormat?.subtitle) && this.hasFixedTitle();
  };

  getBodyStyling = () => {
    const { instructions } = this.props;
    return sprinkles({
      height: 'fill',
      flexItems: 'column',
      justifyContent:
        instructions?.generalFormat?.vertical_content_alignment === VERTICAL_CONTENT_ALIGNMENTS.TOP
          ? 'flex-start'
          : instructions?.generalFormat?.vertical_content_alignment ===
            VERTICAL_CONTENT_ALIGNMENTS.BOTTOM
          ? 'flex-end'
          : 'center', //default is center
      textAlign: 'center',
      position: 'relative',
      marginTop: this.hasShownHeader() ? 'sp1' : 'sp0', //default is no padding on top (only used when there is a fixed title)
    });
  };

  getTitleStyling = () => {
    const { instructions } = this.props;
    return sprinkles({
      display: 'flex',
      justifyContent:
        instructions?.generalFormat?.alignment === TEXT_ELEM_HORIZ_ALIGNMENTS.RIGHT
          ? 'flex-end'
          : instructions?.generalFormat?.alignment === TEXT_ELEM_HORIZ_ALIGNMENTS.LEFT
          ? 'flex-start'
          : 'center', // default is center
      alignItems: 'center',
      marginTop:
        instructions?.generalFormat?.title_value_arrangement === TITLE_VALUE_ARRANGEMENTS.BELOW
          ? 'sp.5'
          : 'sp0', // default is no padding on top since title is on top by default
      marginBottom: 'sp.5',
    });
  };

  getFixedTitleStyling = () => {
    const { instructions } = this.props;
    return sprinkles({
      display: 'flex',
      justifyContent:
        instructions?.generalFormat?.title_value_arrangement ===
        TITLE_VALUE_ARRANGEMENTS.FIXED_CENTER
          ? 'center'
          : 'flex-start',
      alignItems: 'center',
    });
  };

  getSubtitleStyling = () => {
    const { instructions } = this.props;
    return sprinkles({
      marginTop:
        !this.hasShownTitle() &&
        instructions?.generalFormat?.title_value_arrangement === TITLE_VALUE_ARRANGEMENTS.BELOW
          ? 'sp.5' // subtitle only has a margin if title is not showing and the value is on top
          : 'sp0',
      display: 'flex',
      justifyContent:
        instructions?.generalFormat?.alignment === TEXT_ELEM_HORIZ_ALIGNMENTS.RIGHT
          ? 'flex-end'
          : instructions?.generalFormat?.alignment === TEXT_ELEM_HORIZ_ALIGNMENTS.LEFT
          ? 'flex-start'
          : 'center', // default is center
      alignItems: 'center',
      marginBottom: 'sp.5',
    });
  };

  getFixedSubTitleStyling = () => {
    const { instructions } = this.props;
    return sprinkles({
      display: 'flex',
      justifyContent:
        instructions?.generalFormat?.title_value_arrangement ===
        TITLE_VALUE_ARRANGEMENTS.FIXED_CENTER
          ? 'center'
          : 'flex-start',
      alignItems: 'center',
      marginTop: !this.hasShownTitle() ? 'sp0' : 'sp.5',
    });
  };

  getValueStyling = () => {
    const { instructions } = this.props;
    return sprinkles({
      display: 'flex',
      justifyContent:
        instructions?.generalFormat?.alignment === TEXT_ELEM_HORIZ_ALIGNMENTS.RIGHT
          ? 'flex-end'
          : instructions?.generalFormat?.alignment === TEXT_ELEM_HORIZ_ALIGNMENTS.LEFT
          ? 'flex-start'
          : 'center', //default is center
      alignItems: 'center',
      marginTop:
        instructions?.generalFormat?.title_value_arrangement === TITLE_VALUE_ARRANGEMENTS.BELOW ||
        this.hasShownHeader()
          ? 'sp0'
          : 'sp.5', //by default assumes the title is present and on top
      marginBottom: 'sp.5',
    });
  };

  render() {
    const {
      classes,
      instructions,
      loading,
      operationType,
      dataPanelTemplateId,
      openDrilldownModal,
      generalOptions,
      hideChartMenu,
    } = this.props;

    const instructionsNeedConfiguration = !this.instructionsReadyToDisplay(instructions);

    if (instructionsNeedConfiguration) {
      return <NeedsConfigurationPanel fullHeight instructionsNeedConfiguration loading={loading} />;
    }

    if (!loading && this.isNoData()) {
      return (
        <div className={cx(this.getBodyStyling(), classes.baseContainer, classes.bodyContainer)}>
          {this.renderTitle()}
          {this.renderSubtitle()}
          {this.renderEmptyValue()}
        </div>
      );
    }

    const drilldownHeader =
      generalOptions?.enableRawDataDrilldown && !hideChartMenu ? (
        <div
          className={cx(
            headerStylesClass,
            sprinkles({
              paddingTop:
                operationType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2 ? undefined : 'sp2',
              paddingRight:
                operationType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2 ? undefined : 'sp2',
            }),
            classes.kpiHeaderContainer,
          )}>
          <ChartMenu dataPanelId={dataPanelTemplateId} openDrilldownModal={openDrilldownModal} />
        </div>
      ) : null;

    if (instructions?.generalFormat?.title_value_arrangement === TITLE_VALUE_ARRANGEMENTS.BELOW) {
      return (
        <>
          {drilldownHeader}
          <div className={cx(this.getBodyStyling(), classes.baseContainer, classes.bodyContainer)}>
            {this.renderNumberValue()}
            {this.renderTitle()}
            {this.renderSubtitle()}
            {operationType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2
              ? this.renderProgressBar()
              : null}
            {this.renderTrend()}
          </div>
        </>
      );
    }

    if (this.hasFixedTitle()) {
      return (
        <>
          {drilldownHeader}
          <div
            className={cx(
              sprinkles({ display: 'block' }),
              classes.baseContainer,
              classes.headerContainer,
            )}>
            {this.renderTitle()}
            {this.renderSubtitle()}
          </div>
          <div className={cx(this.getBodyStyling(), classes.baseContainer, classes.bodyContainer)}>
            {this.renderNumberValue()}
            {operationType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2
              ? this.renderProgressBar()
              : null}
            {this.renderTrend()}
          </div>
        </>
      );
    }

    // in the default case, position the title above the label and make the title non-fixed
    return (
      <>
        {drilldownHeader}
        <div className={cx(this.getBodyStyling(), classes.baseContainer, classes.bodyContainer)}>
          {this.renderTitle()}
          {this.renderSubtitle()}
          {this.renderNumberValue()}
          {operationType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2
            ? this.renderProgressBar()
            : null}
          {this.renderTrend()}
        </div>
      </>
    );
  }

  renderProgressBar = () => {
    const { previewData, schema, instructions } = this.props;
    if (schema?.length === 0 || !previewData) return;

    const value = this.getRawValue() * (instructions?.valueFormat?.multiplyFactor ?? 1);
    const denominator = this.getProgressValue() ?? value;
    const formattedValue = this.getFormattedNumberValue();

    const tooltipText = `${formattedValue} ${this.getUnits(true) ?? `/ ${formattedValue}`}`;

    return (
      <Tooltip align="center" side="bottom" text={tooltipText}>
        <div>
          <ProgressBar
            className={sprinkles({ marginTop: 'sp1.5' })}
            color={
              instructions?.valueFormat?.progressBarColor ||
              getCategoricalColors(this.context.globalStyleConfig)[0]
            }
            value={value / denominator}
          />
        </div>
      </Tooltip>
    );
  };
  renderTitle = () => {
    const {
      classes,
      instructions,
      infoTooltipText,
      newDataLoading,
      generalOptions,
      processTemplatedValue,
    } = this.props;

    if (!generalOptions?.headerConfig?.isHeaderHidden) {
      return (
        <div
          className={cx(
            instructions?.generalFormat?.title_value_arrangement ===
              TITLE_VALUE_ARRANGEMENTS.FIXED_CENTER ||
              instructions?.generalFormat?.title_value_arrangement ===
                TITLE_VALUE_ARRANGEMENTS.FIXED_LEFT
              ? cx(this.getFixedTitleStyling(), classes.title)
              : cx(this.getTitleStyling(), classes.title), //have the floating title styling by default
            GLOBAL_STYLE_CLASSNAMES.text.kpiTitle.base,
          )}>
          {processTemplatedValue(this.getTitle())}
          {infoTooltipText && <InfoIcon infoTooltipText={infoTooltipText} />}
          {newDataLoading && <Spinner className={sprinkles({ marginLeft: 'sp1.5' })} size="md" />}
        </div>
      );
    }
  };

  // if the title is undefined, we then use the aggregation name as the title for the KPI
  getTitle = () => {
    const { instructions, generalOptions } = this.props;

    if (generalOptions?.headerConfig?.title !== undefined) return generalOptions.headerConfig.title;

    const aggColumn = instructions?.aggColumn?.column;

    return aggColumn?.friendly_name || aggColumn?.name || '';
  };

  renderSubtitle = () => {
    const { classes, instructions, processTemplatedValue } = this.props;

    if (instructions?.generalFormat?.subtitle) {
      return (
        <div
          className={cx(
            instructions?.generalFormat?.title_value_arrangement ===
              TITLE_VALUE_ARRANGEMENTS.FIXED_CENTER ||
              instructions?.generalFormat?.title_value_arrangement ===
                TITLE_VALUE_ARRANGEMENTS.FIXED_LEFT
              ? cx(this.getFixedSubTitleStyling(), classes.subtitle)
              : cx(this.getSubtitleStyling(), classes.subtitle),
            GLOBAL_STYLE_CLASSNAMES.text.body.secondary,
          )}>
          {processTemplatedValue(instructions.generalFormat.subtitle)}
        </div>
      );
    }
  };

  renderNumberValue = () => {
    const { classes, instructions, generalOptions, loading, processTemplatedValue } = this.props;

    if (loading) return this.renderLoadingState();

    const value = this.getFormattedNumberOrPctValue();
    const imageUrl = instructions?.valueFormat?.imageUrl;
    const linkFormat = generalOptions?.linkFormat;
    const units = this.getUnits();
    const showUnits = units !== undefined && units !== '' && !this.isKPIProgressPctValue();
    return (
      <div className={this.getValueStyling()}>
        {imageUrl ? (
          <img alt="" className={classes.valueImage} src={processTemplatedValue(imageUrl)} />
        ) : null}
        <span
          className={cx(
            {
              [classes.bold]: instructions?.valueFormat?.bold,
              [classes.italic]: instructions?.valueFormat?.italic,
            },
            GLOBAL_STYLE_CLASSNAMES.text.kpiValue.base,
          )}
          style={this.getValueColorStyle(this.getRawValue())}>
          {value}
        </span>
        {showUnits && (
          <span
            className={cx(GLOBAL_STYLE_CLASSNAMES.text.kpiValue.base, {
              [sprinkles({ marginLeft: 'sp1' })]: instructions?.valueFormat?.unitPadding,
            })}
            style={this.getGoalUnitsColorStyle()}>
            {units}
          </span>
        )}
        <UrlClickThroughButton linkFormat={linkFormat} />
      </div>
    );
  };

  getValueColorStyle = (rawValue: number) => {
    const { instructions, processTemplatedValue } = this.props;
    const colorFormat = instructions?.colorFormat;

    if (colorFormat?.colorSettingType === ColorSettings.CONSTANT && colorFormat.constantColor) {
      return { color: colorFormat.constantColor };
    } else if (
      colorFormat?.colorSettingType === ColorSettings.CONDITIONAL &&
      colorFormat.conditionalTriggerValue
    ) {
      const multipliedValue = rawValue * (instructions?.valueFormat?.multiplyFactor ?? 1);
      const triggerValue = processTemplatedValue(colorFormat.conditionalTriggerValue);

      let triggerValueNum = parseFloat(triggerValue);

      if (isNaN(triggerValueNum)) triggerValueNum = 0;

      if (multipliedValue >= triggerValueNum) {
        return { color: colorFormat.conditionalPositiveColor || vars.colors.green9 };
      } else {
        return { color: colorFormat.conditionalNegativeColor || vars.colors.red9 };
      }
    }
  };

  getGoalUnitsColorStyle = () => {
    const { instructions } = this.props;

    if (instructions?.colorFormat?.applyColorToProgressGoal) {
      return this.getValueColorStyle(this.getRawValue());
    }
  };

  isKPIProgressPctValue = () => {
    const { instructions, operationType } = this.props;

    return (
      operationType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2 &&
      instructions?.valueFormat?.progressShowPct
    );
  };

  renderLoadingState = () => {
    return (
      <div className={this.getValueStyling()}>
        <Spinner size="lg" />
      </div>
    );
  };

  renderEmptyValue = () => {
    const { generalOptions } = this.props;
    const maybeNoDataNumber = parseFloat(generalOptions?.noDataState?.noDataText || '');
    const noDataStyle = { fontSize: generalOptions?.noDataState?.noDataFontSize || 36 };
    const colorStyle = isNaN(maybeNoDataNumber) ? {} : this.getValueColorStyle(maybeNoDataNumber);
    return (
      <div
        className={cx(this.getValueStyling(), GLOBAL_STYLE_CLASSNAMES.text.body.primary)}
        style={{ ...noDataStyle, ...colorStyle }}>
        {generalOptions?.noDataState?.noDataText || 'No Data'}
      </div>
    );
  };

  getUnits = (forceDenominator?: boolean) => {
    const { operationType, instructions } = this.props;

    const valueFormat = instructions?.valueFormat ?? {};

    // If it is a progress bar but the number format is Percent, don't show the denominator
    // because 30% / 100% doesn't make sense.
    if (
      operationType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2 &&
      valueFormat.numberFormat?.id !== V2_NUMBER_FORMATS.PERCENT.id &&
      (forceDenominator || !valueFormat.progressHideGoal)
    ) {
      const denominator =
        instructions?.valueFormat?.progressGoal &&
        formatValue({
          value: this.getProgressValue(),
          decimalPlaces: instructions?.valueFormat?.decimalPlaces ?? 2,
          formatId: instructions?.valueFormat?.numberFormat?.id ?? V2_NUMBER_FORMATS.NUMBER.id,
          significantDigits: instructions?.valueFormat.significantDigits,
          hasCommas: true,
        });

      return denominator
        ? `/ ${denominator} ${instructions?.valueFormat?.units || ''}`
        : instructions?.valueFormat?.units;
    }

    return instructions?.valueFormat?.units;
  };

  getRawValue = () => {
    const { previewData, instructions, schema } = this.props;

    let numberColName = schema[0].name;
    if (instructions?.trendColumn?.column) {
      numberColName = schema[1].name;
    }

    return previewData[0][numberColName] as number;
  };

  isNoData = () => {
    const { previewData, generalOptions } = this.props;
    if (!previewData || previewData.length === 0) return true;

    const value = this.getRawValue();

    if (!isNumber(value)) return true;

    return generalOptions?.noDataState?.isZeroNoData ? value === 0 : false;
  };

  getFormattedNumberValue = () => {
    const { instructions } = this.props;

    const value = this.getRawValue();

    return formatValue({
      value,
      decimalPlaces: instructions?.valueFormat?.decimalPlaces ?? 2,
      formatId: instructions?.valueFormat?.numberFormat?.id ?? V2_NUMBER_FORMATS.NUMBER.id,
      multiplier: instructions?.valueFormat?.multiplyFactor,
      hasCommas: true,
      timeFormatId: instructions?.valueFormat?.timeFormat?.id,
      customTimeFormat: instructions?.valueFormat?.timeCustomerFormat,
      significantDigits: instructions?.valueFormat?.significantDigits,
    });
  };

  getFormattedNumberOrPctValue = () => {
    const { instructions } = this.props;

    if (!this.isKPIProgressPctValue()) return this.getFormattedNumberValue();

    const value = this.getRawValue() * (instructions?.valueFormat?.multiplyFactor ?? 1);
    const denominator = this.getProgressValue() ?? value;

    let ratio = value / denominator;
    if (denominator === 0) {
      ratio = value === 0 ? 0 : 1;
    }

    return formatValue({
      value: ratio,
      decimalPlaces: instructions?.valueFormat?.pctDecimalPlaces ?? 2,
      formatId: V2_NUMBER_FORMATS.PERCENT.id,
    });
  };

  renderTrend = () => {
    const { classes, instructions, loading } = this.props;

    if (loading) return;

    const pctChange = this.getTrend();

    if (pctChange === undefined) return;

    const positiveChange = instructions?.trendFormat?.trendColorsReversed
      ? pctChange < 0
      : pctChange >= 0;

    return (
      <div className={cx(classes.trend, { positive: positiveChange })}>
        <Icon
          color={positiveChange ? '#0F9960' : '#DB3737'}
          icon={pctChange >= 0 ? 'caret-up' : 'caret-down'}
        />
        {instructions?.trendFormat?.isNumber
          ? format(',')(Math.abs(pctChange))
          : `${format('0.2f')(Math.abs(pctChange))}%`}{' '}
        {instructions?.trendFormat?.label}
      </div>
    );
  };

  getTrend = () => {
    const { previewData, instructions, schema } = this.props;
    if (instructions?.trendColumn?.column && schema && schema.length >= 2) {
      const numberColName = schema[1].name;
      if (previewData.length <= 1) return;
      const currVal = previewData[0][numberColName] as number;
      const prevVal = previewData[1][numberColName] as number;

      if (instructions.trendFormat?.isNumber) return currVal - prevVal;

      return (currVal / prevVal - 1) * 100;
    }
  };

  getProgressValue = () => {
    const { instructions, processTemplatedValue } = this.props;

    const valueString = processTemplatedValue(
      instructions?.valueFormat?.progressGoal?.toString() || '100',
    );

    const valueNum = parseFloat(valueString);

    if (isNaN(valueNum)) return 0;

    return valueNum;
  };
}

export const SingleNumberChart = withStyles(styles)(BaseSingleNumberChart);
