import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as RD from 'remotedata';
import { useHistory, useLocation } from 'react-router-dom';

import { CustomerSelect } from 'components/CustomerSelect';

import { ReduxState } from 'reducers/rootReducer';
import { getSelectedCustomer } from 'reducers/customersReducer';
import { selectCustomer } from 'actions/teamActions';

type Props = {
  disabled?: boolean;
  parseUrl?: boolean;
  overwriteSelectedUserGroupToNull?: boolean;
};

/**
 * Updates the selected customer in the redux store when the user selects a customer
 * Also keeps the URL param in sync with the currently selected customers
 * Use CustomerSelect if you want to be able to select a customer, but not change the redux state
 */
export default function CustomerSelector({
  parseUrl,
  disabled,
  overwriteSelectedUserGroupToNull,
}: Props): JSX.Element {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const { customers, selectedUserGroup } = useSelector(
    (state: ReduxState) => ({
      customers: state.customers.groups,
      selectedUserGroup: overwriteSelectedUserGroupToNull
        ? null
        : getSelectedCustomer(state.customers),
    }),
    shallowEqual,
  );

  const [hasParsedUrl, setHasParsedUrl] = useState(false);

  useEffect(() => {
    if (parseUrl && !hasParsedUrl && RD.isSuccess(customers)) {
      const queryParams = new URLSearchParams(location.search);
      const parsedCustomerId = parseInt(queryParams.get('customer') ?? '');

      const customer = !isNaN(parsedCustomerId)
        ? customers.data.find((group) => group.id === parsedCustomerId)
        : undefined;
      if (customer) dispatch(selectCustomer(customer.id));

      setHasParsedUrl(true);
    }
  }, [dispatch, parseUrl, customers, location.search, hasParsedUrl]);

  return (
    <CustomerSelect
      customer={selectedUserGroup ?? undefined}
      disabled={disabled}
      onChange={(customer) => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('customer', customer.id.toString());
        history.replace({ search: queryParams.toString(), hash: location.hash });

        dispatch(selectCustomer(customer.id));
      }}
    />
  );
}
