const CUSTOMER_ID_VARIABLES = ['customer_id', 'user_group_id'];

export const CUSTOMER_ID_VARIABLES_SET = new Set(CUSTOMER_ID_VARIABLES);

export const RESERVED_VARIABLES = new Set([
  'customer',
  'customer_name',
  'user_group',
  'user_group_name',
  ...CUSTOMER_ID_VARIABLES,
]);
