import { FC, useEffect, useState } from 'react';
import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { fetchDashboardAttributes } from 'actions/dashboardAttributesActions';
import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';
import { Button, sprinkles } from 'components/ds';
import { SavingInfo } from './SavingInfo';
import EditResourceBannerDropdown from 'shared/ExploResource/EditResourceBannerDropdown';
import VersionControlModal from 'components/VersionControlModal';
import CustomerSelector from 'components/CustomerSelector';

import { SIDE_PANE_HEADER_HEIGHT } from 'components/SidePane';
import { DashboardVersion } from 'types/dashboardVersion';
import { DashboardTemplate } from 'actions/dashboardTemplateActions';
import { ExploreEmailCadence } from 'actions/teamActions';
import { VIEW_MODE } from 'types/dashboardTypes';
import { ReduxState } from 'reducers/rootReducer';
import {
  EMBED_ICON,
  LEFT_PANE_ICON,
  RIGHT_PANE_ICON,
  PDF_ICON,
  EMAIL_VIEW_ICON,
  MOBILE_ICON,
} from 'constants/iconConstants';
import { ResourcePageType, VersionInfo } from 'types/exploResource';
import { isEmailReportingDisabled } from 'utils/paymentPlanUtils';
import { doesUserHavePermission } from 'utils/permissionUtils';
import { PERMISSIONED_ACTIONS, PERMISSIONED_ENTITIES } from 'constants/roleConstants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: SIDE_PANE_HEADER_HEIGHT,
    borderBottom: `1px solid ${theme.palette.ds.grey400}`,
    paddingRight: theme.spacing(3),
  },
  header: {
    marginLeft: -theme.spacing(5),
    marginRight: theme.spacing(5),
    borderTop: 'none',
    borderBottom: 'none',
  },
  viewModeButton: {
    '&:last-child': {
      borderTopRightRadius: `${theme.spacing(1)}px !important`,
      borderBottomRightRadius: `${theme.spacing(1)}px !important`,
    },
  },
  defaultViewModeButton: {
    '&.bp3-button': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      padding: theme.spacing(2),
    },
  },
  pdfViewModeButton: {
    '&.bp3-button': {
      borderRadius: 0,
      padding: theme.spacing(2),
    },
  },
  emailViewModeButton: {
    '&.bp3-button': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      padding: theme.spacing(2),
    },
  },
  inactiveViewModeButton: {
    color: `${theme.palette.ds.grey600} !important`,
    '&:hover': {
      color: `${theme.palette.ds.hovered.grey600} !important`,
    },

    '&.bp3-button': {
      backgroundColor: theme.palette.ds.grey100,
    },
    '&.bp3-button:hover': {
      borderColor: theme.palette.ds.hovered.grey600,
      backgroundColor: theme.palette.ds.hovered.grey100,
    },
    '&.bp3-button:active': {
      backgroundColor: theme.palette.ds.pressed.grey100,
    },
  },
  activeViewModeButton: {
    color: `${theme.palette.ds.blue} !important`,
    '&.bp3-button': {
      border: `1px solid ${theme.palette.ds.blue}`,
      backgroundColor: theme.palette.ds.lightBlue,
    },
    '&.bp3-button:hover': {
      backgroundColor: theme.palette.ds.hovered.lightBlue,
    },
    '&.bp3-button:active': {
      backgroundColor: theme.palette.ds.pressed.lightBlue,
    },
  },
  isLayoutClosed: {
    color: `${theme.palette.ds.grey600} !important`,

    '&:hover': {
      color: `${theme.palette.ds.hovered.grey600} !important`,
      '&.bp3-button:hover': {
        backgroundColor: theme.palette.ds.grey100,
      },
    },
  },
  isLayoutOpen: {
    color: `${theme.palette.ds.blue} !important`,

    '&:hover': {
      color: `${theme.palette.ds.hovered.blue} !important`,
      '&.bp3-button:hover': {
        backgroundColor: theme.palette.ds.lightBlue,
      },
    },
  },
}));

type Props = {
  dashboardVersionInfo: VersionInfo;
  dashboardTemplate: DashboardTemplate;
  emailCadence?: ExploreEmailCadence;
  viewMode: VIEW_MODE;
  setViewMode: (viewMode: VIEW_MODE) => void;
  inEditMode?: boolean;
  onEditClicked: () => void;
  onPreviewClicked: () => void;
  switchEditModeLoading: boolean;
  onReturnMostCurrentVersionClicked: () => void;
  allDashboardVersions?: DashboardVersion[];
  rightPaneIsOpen: boolean;
  leftPaneIsOpen: boolean;
  toggleLeftPane: () => void;
  toggleRightPane: () => void;
};

export const DashboardEditBanner: FC<Props> = ({
  allDashboardVersions,
  dashboardTemplate,
  dashboardVersionInfo,
  emailCadence,
  inEditMode,
  leftPaneIsOpen,
  onEditClicked,
  onPreviewClicked,
  onReturnMostCurrentVersionClicked,
  rightPaneIsOpen,
  setViewMode,
  switchEditModeLoading,
  toggleLeftPane,
  toggleRightPane,
  viewMode,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { currentDashboardTemplateId, dashboardAttributes, paymentPlan, permissions } = useSelector(
    (state: ReduxState) => ({
      currentDashboardTemplateId: state.dashboard.currentDashboardTemplateId,
      dashboardAttributes: state.dashboardAttributes.dashboard_attributes,
      paymentPlan: state.currentUser.team?.payment_plan,
      permissions: state.currentUser.permissions,
    }),
    shallowEqual,
  );
  const [versionControlModalOpen, setVersionControlModalOpen] = useState(false);

  useEffect(() => {
    if (!dashboardAttributes) dispatch(fetchDashboardAttributes());
  }, [dashboardAttributes, dispatch]);

  const renderViewModeButtonGroup = () => {
    return (
      <ToggleButtonGroup>
        <ToggleButton
          className={cx(classes.defaultViewModeButton, classes.viewModeButton, {
            [classes.activeViewModeButton]: viewMode === VIEW_MODE.DEFAULT,
            [classes.inactiveViewModeButton]: viewMode !== VIEW_MODE.DEFAULT,
          })}
          icon={EMBED_ICON}
          onClick={() => setViewMode(VIEW_MODE.DEFAULT)}
        />
        <ToggleButton
          className={cx(classes.pdfViewModeButton, classes.viewModeButton, {
            [classes.activeViewModeButton]: viewMode === VIEW_MODE.MOBILE,
            [classes.inactiveViewModeButton]: viewMode !== VIEW_MODE.MOBILE,
          })}
          icon={MOBILE_ICON()}
          onClick={() => setViewMode(VIEW_MODE.MOBILE)}
        />

        <ToggleButton
          className={cx(classes.pdfViewModeButton, classes.viewModeButton, {
            [classes.activeViewModeButton]: viewMode === VIEW_MODE.PDF,
            [classes.inactiveViewModeButton]: viewMode !== VIEW_MODE.PDF,
          })}
          icon={PDF_ICON()}
          onClick={() => setViewMode(VIEW_MODE.PDF)}
        />

        {!isEmailReportingDisabled(paymentPlan) && (
          <ToggleButton
            className={cx(classes.emailViewModeButton, classes.viewModeButton, {
              [classes.activeViewModeButton]: viewMode === VIEW_MODE.EMAIL,
              [classes.inactiveViewModeButton]: viewMode !== VIEW_MODE.EMAIL,
            })}
            icon={EMAIL_VIEW_ICON}
            onClick={() => setViewMode(VIEW_MODE.EMAIL)}
          />
        )}
      </ToggleButtonGroup>
    );
  };

  const renderEditorLayoutButtonsGroup = () => {
    if (!inEditMode || viewMode !== VIEW_MODE.DEFAULT) return;

    return (
      <ToggleButtonGroup className={sprinkles({ marginRight: 'sp3' })}>
        <ToggleButton
          className={cx({
            [classes.isLayoutClosed]: !leftPaneIsOpen,
            [classes.isLayoutOpen]: leftPaneIsOpen,
          })}
          icon={LEFT_PANE_ICON}
          onClick={toggleLeftPane}
        />
        <ToggleButton
          className={cx({
            [classes.isLayoutClosed]: !rightPaneIsOpen,
            [classes.isLayoutOpen]: rightPaneIsOpen,
          })}
          icon={RIGHT_PANE_ICON}
          onClick={toggleRightPane}
        />
      </ToggleButtonGroup>
    );
  };

  const renderEditButtons = () => {
    return (
      <div className={sprinkles({ display: 'flex', alignItems: 'center', marginLeft: 'sp1' })}>
        <SavingInfo
          isDraft={dashboardVersionInfo.is_draft}
          resourceType={ResourcePageType.EXPLORE}
          versionNumber={dashboardVersionInfo.version_number}
        />
        <div className={sprinkles({ display: 'flex' })}>
          {inEditMode ? (
            <Button
              disabled={switchEditModeLoading}
              loading={switchEditModeLoading}
              onClick={() => !switchEditModeLoading && onPreviewClicked()}
              type="primary">
              Done Editing
            </Button>
          ) : (
            renderEditDashboardButton()
          )}
        </div>
      </div>
    );
  };

  const renderEditDashboardButton = () => {
    const isMostRecentVersion =
      dashboardVersionInfo.version_number !== allDashboardVersions?.length;

    // There should always be at least 1 dashboard version (the one created when the dashboard is created)
    // so if the length of `allDashboardVersions` is 0, the dashboard versions haven't loaded yet
    if (allDashboardVersions?.length && isMostRecentVersion) {
      return (
        <>
          <Button
            className={sprinkles({ marginRight: 'sp1' })}
            disabled={switchEditModeLoading}
            onClick={() => setVersionControlModalOpen(true)}
            type="secondary">
            Version Control
          </Button>
          <Button
            disabled={switchEditModeLoading}
            loading={switchEditModeLoading}
            onClick={() => onReturnMostCurrentVersionClicked()}
            type="primary">
            Return to Current Draft
          </Button>
        </>
      );
    }

    return (
      <>
        <Button
          className={sprinkles({ marginRight: 'sp1' })}
          disabled={switchEditModeLoading}
          onClick={() => !switchEditModeLoading && setVersionControlModalOpen(true)}
          type="secondary">
          Version Control
        </Button>
        {doesUserHavePermission(
          permissions[PERMISSIONED_ENTITIES.DASHBOARD],
          PERMISSIONED_ACTIONS.UPDATE,
        ) ? (
          <Button
            disabled={switchEditModeLoading || allDashboardVersions?.length === 0}
            loading={switchEditModeLoading}
            onClick={() => !switchEditModeLoading && onEditClicked()}
            type="primary">
            Edit
          </Button>
        ) : null}
      </>
    );
  };

  const renderVersionControlModal = () => {
    if (!versionControlModalOpen || currentDashboardTemplateId === undefined) return;

    return (
      <VersionControlModal
        closeModal={() => setVersionControlModalOpen(false)}
        pageType={ResourcePageType.EXPLORE}
      />
    );
  };

  return (
    <div
      className={cx(
        sprinkles({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'white',
          width: 'fill',
        }),
        classes.root,
      )}>
      <EditResourceBannerDropdown
        showDeveloperSettings
        dashboardAttributes={dashboardAttributes}
        emailCadence={emailCadence}
        pageType={ResourcePageType.EXPLORE}
        resource={dashboardTemplate}
      />
      <div
        className={sprinkles({ display: 'flex', alignItems: 'center' })}
        style={{ marginLeft: 20 }}>
        <div className={sprinkles({ marginRight: 'sp1', width: 'maxContent' })}>Viewing as</div>
        <CustomerSelector parseUrl />
      </div>
      <div className={sprinkles({ display: 'flex', flex: 1, justifyContent: 'center' })}>
        {renderEditorLayoutButtonsGroup()}
        {renderViewModeButtonGroup()}
      </div>
      <div className={sprinkles({ display: 'flex', flex: 1, justifyContent: 'flex-end' })}>
        {renderEditButtons()}
      </div>
      {renderVersionControlModal()}
    </div>
  );
};
