import { makeStyles, Theme } from '@material-ui/core/styles';

// This file has shared styling for dropped columns

export const sharedStyles = makeStyles((theme: Theme) => ({
  popoverTargetContainer: {
    backgroundColor: theme.palette.ds.lightBlue,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .bp3-popover-wrapper.bp3-fill': {
      width: 'auto',
    },
  },
  name: {
    fontWeight: 500,
    marginLeft: theme.spacing(2),
    color: theme.palette.ds.blue,
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  root: {
    display: 'flex',
    height: '100%',
    flex: 1,
    overflow: 'hidden',
  },
  menuItemOption: {
    alignItems: 'center',
    '&:hover': {
      background: theme.palette.ds.blue,
      color: theme.palette.ds.white,
      '& .bp3-menu-item-label': {
        color: theme.palette.ds.white,
      },
    },
  },
  iconBtn: {
    // Must be important to override white background when popover options open
    backgroundColor: `${theme.palette.ds.lightBlue} !important`,
    color: theme.palette.ds.blue,
    '&.bp3-button': {
      width: 24,
      height: 24,
    },
    '&.bp3-button,.bp3-icon': {
      color: theme.palette.ds.blue,
    },
  },
  rightDivider: {
    borderRadius: `0px !important`,
    borderRight: `1px solid ${theme.palette.ds.pressed.lightBlue}`,
  },
  twoRowsIconBtn: {
    // Must be important to override white background when popover options open
    backgroundColor: `${theme.palette.ds.hovered.lightBlue} !important`,
    color: theme.palette.ds.blue,
    '&.bp3-button': {
      width: 24,
      height: 24,
    },
    '&.bp3-button,.bp3-icon': {
      color: theme.palette.ds.blue,
    },
  },
  linkDividerApplied: {
    borderLeft: `1px solid ${theme.palette.ds.pressed.lightBlue}`,
  },
  linkDivider: {
    borderLeft: `1px solid ${theme.palette.ds.pressed.grey400}`,
  },
}));
