import { EmailBody } from 'types/emailCadence';
import { defineEmbedPostAction } from './actionUtils';
import { ACTION } from './types';

export type ReportBuilderEmailCadence = {
  id: string;
  customer_report_id: number;
} & EmailBody;

type CreateReportBuilderEmailCadenceBody = {
  report_builder_id: number;
  customer_report_id: number;
  email: EmailBody;
};

export const {
  actionFn: createReportBuilderEmailCadence,
  successAction: createReportBuilderEmailCadenceSuccess,
} = defineEmbedPostAction<
  CreateReportBuilderEmailCadenceBody,
  { email: ReportBuilderEmailCadence }
>(ACTION.CREATE_CUSTOMER_REPORT_EMAIL, 'customer_reports', 'create_email', 'POST');

export const {
  actionFn: updateReportBuilderEmailCadence,
  successAction: updateReportBuilderEmailCadenceSuccess,
} = defineEmbedPostAction<
  { email_id: string; email: EmailBody },
  { email: ReportBuilderEmailCadence }
>(ACTION.UPDATE_CUSTOMER_REPORT_EMAIL, 'customer_reports', 'update_email', 'POST');

export const {
  actionFn: deleteReportBuilderEmailCadence,
  successAction: deleteReportBuilderEmailCadenceSuccess,
} = defineEmbedPostAction<{ email_id: string }, { id: string }>(
  ACTION.DELETE_CUSTOMER_REPORT_EMAIL,
  'customer_reports',
  'delete_email',
  'POST',
);

export const {
  actionFn: listReportBuilderEmailCadences,
  successAction: listReportBuilderEmailCadencesSuccess,
} = defineEmbedPostAction<
  { report_builder_embed_id: string; customer_report_id: number },
  { emails: ReportBuilderEmailCadence[] }
>(ACTION.LIST_CUSTOMER_REPORT_EMAILS, 'customer_reports', 'emails', 'POST');
