import { FC } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { BaseSaveModal } from 'pages/ReportBuilder/ModalViews/BaseSaveModal';

import { ReportBuilderTab } from 'pages/ReportBuilder/HomeView/constants';
import { createCustomerReport } from 'actions/customerReportActions';
import {
  getSelectedEmbedBuiltIn,
  setSelectedTab,
} from 'reportBuilderContent/reducers/embeddedReportBuilderReducer';
import { closeReportModal } from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';

export const SaveBuiltInAsReportModal: FC = () => {
  const dispatch = useDispatch();

  const { customerToken, embedId, selectedBuiltIn, currentConfig } = useSelector(
    (state: ReportBuilderReduxState) => ({
      currentConfig: state.reportEditing.currentConfig,
      customerToken: state.embeddedReportBuilder.requestInfo.customerToken,
      embedId: state.embeddedReportBuilder.requestInfo.embedId,
      selectedBuiltIn: getSelectedEmbedBuiltIn(state.embeddedReportBuilder),
    }),
    shallowEqual,
  );

  const handleSubmit = (reportName: string) => {
    if (!currentConfig) return;
    dispatch(setSelectedTab({ tab: ReportBuilderTab.ALL_REPORTS }));
    dispatch(closeReportModal());
    dispatch(
      createCustomerReport({
        customerToken,
        postData: {
          report_builder_embed_id: embedId,
          name: reportName,
          config: currentConfig,
        },
      }),
    );
  };

  if (!selectedBuiltIn) return null;

  return <BaseSaveModal allowSameName onSubmit={handleSubmit} reportName={selectedBuiltIn.name} />;
};
