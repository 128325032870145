import { Dispatch, SetStateAction } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import { Theme, makeStyles } from '@material-ui/core/styles';

import ReactQuill, { Quill } from '@explo-tech/react-quill';
import '@explo-tech/react-quill/dist/quill.snow.css';
import Button from 'shared/Button';
import ImageVariableInputToolbar from './ImageVariableInputToolbar';

import selectLocalImage from './ImageUpload';
import { IMAGE_LINK_ICON, IMAGE_UPLOAD_ICON } from 'constants/iconConstants';
import { uploadImage } from 'actions/emailActions';
import { showErrorToast } from 'shared/sharedToasts';

const icons = Quill.import('ui/icons');
icons['image'] = ReactDOMServer.renderToStaticMarkup(IMAGE_UPLOAD_ICON);

const useStyles = makeStyles((theme: Theme) => ({
  toolbarSection: {
    borderRight: `1px solid ${theme.palette.ds.grey400}`,
    '&:last-child': {
      borderRight: `0px`,
    },
    // a lot of the !importants here are overriding default styles
    marginRight: '0px !important',
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  toolbar: {
    padding: '0px !important',
    border: '0 !important',
    display: 'flex',
    height: theme.spacing(10),
    borderRadius: theme.spacing(1),
    boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.12)',

    backgroundColor: theme.palette.ds.white,
  },
  toolbarContainer: {
    position: 'absolute',
    zIndex: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  toolbarHider: {
    visibility: 'hidden',
  },
  headerToolbar: {
    top: '-22px',
  },
  footerToolbar: {
    top: '-42px',
  },
  button: {
    padding: '0px !important',
    width: '24px !important',
    minWidth: '24px !important',
    minHeight: '24px !important',
    backgroundColor: `${theme.palette.ds.white} !important`,
    '& > svg': {
      fill: theme.palette.ds.black,
    },
    '&:hover': {
      '& > svg': {
        fill: theme.palette.ds.blue,
      },
    },
  },
  // clicking the url image upload in the toolbar breaks focus, so we have to hack the outline back in
  focusHack: {
    border: `1px solid ${theme.palette.ds.blue} !important`,
  },
  // eslint-disable-next-line no-useless-computed-key
  ['ql-image']: {
    '& > svg': {
      fill: theme.palette.ds.black,
    },
    '&:hover': {
      '& > svg': {
        fill: theme.palette.ds.blue,
      },
    },
  },
  // overriding some default quill styling
  buttonCustomIcon: {
    padding: '0 !important',
    width: 'auto !important',
    '& > svg': {
      fill: theme.palette.ds.black,
    },
    '&:hover': {
      '& > svg': {
        fill: theme.palette.ds.blue,
      },
    },
  },
}));

type Props = {
  isHeader: boolean;
  quill: ReactQuill | null;
  setShowImageVariableInputToolbar: Dispatch<SetStateAction<boolean>>;
  showImageVariableInputToolbar: boolean;
  toolbarId: string;
};

export default function EmailBuilderToolbar({
  isHeader,
  quill,
  setShowImageVariableInputToolbar,
  showImageVariableInputToolbar,
  toolbarId,
}: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  if (!quill)
    return (
      <div
        className={cx(classes.toolbarContainer, 'quillToolbar', {
          [classes.headerToolbar]: isHeader,
          [classes.footerToolbar]: !isHeader,
          [classes.toolbarHider]: !showImageVariableInputToolbar,
        })}>
        <div className={classes.toolbar} id={toolbarId} />
      </div>
    );

  return (
    <div
      className={cx(classes.toolbarContainer, 'quillToolbar', {
        [classes.headerToolbar]: isHeader,
        [classes.footerToolbar]: !isHeader,
        [classes.toolbarHider]: !showImageVariableInputToolbar,
      })}>
      <div className={classes.toolbar} id={toolbarId}>
        <div className={cx(classes.toolbarSection, 'ql-formats')}>
          <select className="ql-header" defaultValue={0}>
            <option value="1" />
            <option value="2" />
            <option value="3" />
            <option value="0">Paragraph</option>
          </select>
        </div>
        <div className={cx(classes.toolbarSection, 'ql-formats')}>
          <button className="ql-bold" />
          <button className="ql-italic" />
          <button className="ql-underline" />
        </div>
        <div className={cx(classes.toolbarSection, 'ql-formats')}>
          <button className="ql-align" value={[]} />
          <button className="ql-align" value="center" />
          <button className="ql-align" value="right" />
          <button className="ql-align" value="justify" />
        </div>
        <div className={cx(classes.toolbarSection, 'ql-formats')}>
          <button className="ql-list" value="bullet" />
          <button className="ql-list" value="ordered" />
          <button className="ql-link" />
        </div>
        <div className={cx(classes.toolbarSection, 'ql-formats')}>
          <Button
            ignoreCustomStyles
            className={cx('ql-image', classes.buttonCustomIcon, classes.button)}
            onClick={() =>
              selectLocalImage(
                quill,
                (base64: string | ArrayBuffer | null, file: File) =>
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  new Promise((resolve, reject) => {
                    dispatch(
                      uploadImage(
                        { postData: { file: base64, extension: file.name.split('.').pop() } },
                        (response) => resolve(response.url),
                        () =>
                          showErrorToast(
                            'Something went wrong uploading your image. Please try again',
                          ),
                      ),
                    );
                  }),
              )
            }
          />
          <Button
            ignoreCustomStyles
            className={cx(classes.button)}
            icon={IMAGE_LINK_ICON}
            onClick={() => {
              setShowImageVariableInputToolbar(!showImageVariableInputToolbar);
            }}
          />
        </div>
      </div>
      {showImageVariableInputToolbar && (
        <ImageVariableInputToolbar
          closeInput={() => setShowImageVariableInputToolbar(false)}
          quill={quill}
        />
      )}
    </div>
  );
}
