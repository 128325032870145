import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import produce from 'immer';
import { sprinkles } from 'components/ds';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import InputLabel from 'shared/InputLabel';
import { Switch } from 'components/ds';
import ColorPickerButton from 'shared/ColorPicker';

import { KPIValueFormat, OPERATION_TYPES, V2KPIChartInstructions } from 'constants/types';
import { getCategoricalColors } from 'globalStyles';
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { ReduxState } from 'reducers/rootReducer';
import { sharedStyles } from './styles';

type Props = {
  instructions: V2KPIChartInstructions;
  visualizationType: OPERATION_TYPES.VISUALIZE_PROGRESS_V2;
};

export const KPIProgressBarConfig: FC<Props> = ({ instructions, visualizationType }) => {
  const dispatch = useDispatch();
  const globalStyleConfig = useSelector(
    (state: ReduxState) => state.dashboardStyles.globalStyleConfig,
  );

  const labelStyle = sprinkles({ paddingX: 'sp1.5', marginBottom: 'sp1' });
  const sharedClasses = sharedStyles();

  if (!globalStyleConfig) return null;

  const updateFormat = (valueFormat: KPIValueFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.valueFormat = { ...draft.valueFormat, ...valueFormat };
    });
    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  const valueFormat = instructions.valueFormat;

  return (
    <div className={sharedClasses.root}>
      <InputWithBlurSave
        containerClassName={labelStyle}
        helpIcon="code"
        helpText="This field can take in variables"
        initialValue={String(valueFormat?.progressGoal ?? '')}
        label="Progress Goal"
        onNewValueSubmitted={(newValue: string) => {
          updateFormat({
            progressGoal: newValue,
          });
        }}
      />
      <Switch
        className={labelStyle}
        label="Hide Goal"
        onChange={() => updateFormat({ progressHideGoal: !valueFormat?.progressHideGoal })}
        switchOn={valueFormat?.progressHideGoal}
      />

      <Switch
        className={labelStyle}
        label="Show Percent"
        onChange={() => updateFormat({ progressShowPct: !valueFormat?.progressShowPct })}
        switchOn={valueFormat?.progressShowPct}
      />

      {valueFormat?.progressShowPct ? (
        <InputWithBlurSave
          containerClassName={labelStyle}
          initialValue={String(valueFormat?.pctDecimalPlaces ?? '2')}
          label="Percent Decimal Places"
          onNewValueSubmitted={(newValue: string) => {
            const intValue = parseInt(newValue);

            updateFormat({
              pctDecimalPlaces: intValue > 0 ? intValue : 0,
            });
          }}
        />
      ) : null}

      <InputLabel className={labelStyle} text="Progress Bar Color" />
      <ColorPickerButton
        fillWidth
        className={labelStyle}
        color={valueFormat?.progressBarColor || getCategoricalColors(globalStyleConfig)[0]}
        colorPalette={getCategoricalColors(globalStyleConfig)}
        onColorChange={(hex) => updateFormat({ progressBarColor: hex })}
      />
    </div>
  );
};
