import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { PageHeaderConfig } from './elementConfig/PageHeaderConfig/PageHeaderConfig';
import * as styles from './styles.css';

import { DashboardElement, DASHBOARD_LAYOUT_CONFIG } from 'types/dashboardTypes';
import {
  updateElementLocation,
  updateDashboardHeaderElementOrder,
  updateDashboardPageLayoutConfig,
} from 'actions/dashboardV2Actions';
import {
  DahsboardStickyHeaderConfig,
  DashboardPageLayoutConfig,
} from 'types/dashboardVersionConfig';

type Props = {
  dashboardElements: DashboardElement[];
  dashboardId: number;
  onCloseConfig: () => void;
  pageLayoutConfig?: DashboardPageLayoutConfig;
  selectedLayoutConfig: DASHBOARD_LAYOUT_CONFIG;
};

export const DashboardLayoutConfigPanel = ({
  dashboardElements,
  dashboardId,
  onCloseConfig,
  pageLayoutConfig,
  selectedLayoutConfig,
}: Props) => {
  const dispatch = useDispatch();

  const updateHeaderConfig = useCallback(
    (config: DahsboardStickyHeaderConfig) => {
      dispatch(
        updateDashboardPageLayoutConfig({
          dashboardId,
          config: {
            ...pageLayoutConfig,
            stickyHeader: {
              ...pageLayoutConfig?.stickyHeader,
              ...config,
            },
          },
        }),
      );
    },
    [pageLayoutConfig, dashboardId, dispatch],
  );

  const updateElementLocationFn = useCallback(
    (elementId: string, newLocation: DASHBOARD_LAYOUT_CONFIG) => {
      dispatch(updateElementLocation({ elementId, newLocation }));
    },
    [dispatch],
  );

  const updateElemOrder = useCallback(
    (newOrder: string[]) => {
      dispatch(updateDashboardHeaderElementOrder({ newOrder }));
    },
    [dispatch],
  );

  if (selectedLayoutConfig !== DASHBOARD_LAYOUT_CONFIG.HEADER) return null;

  return (
    <div className={styles.configPanelContainer}>
      <PageHeaderConfig
        config={pageLayoutConfig?.stickyHeader}
        dashboardElements={dashboardElements}
        onCloseConfig={onCloseConfig}
        updateConfig={updateHeaderConfig}
        updateElemLocation={updateElementLocationFn}
        updateElemOrder={updateElemOrder}
      />
    </div>
  );
};
