import { FC } from 'react';
import cx from 'classnames';

import { sprinkles } from 'components/ds';
import * as styles from '../styles.css';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';

import { SwitchElementConfig } from 'types/dashboardTypes';
import { getUpdateConfigFunc } from '../utils';

type Props = {
  config: SwitchElementConfig;

  updateSwitchConfig: (config: SwitchElementConfig) => void;
};

export const SwitchValuesConfig: FC<Props> = ({ config, updateSwitchConfig }) => {
  const updateConfig = getUpdateConfigFunc(config, updateSwitchConfig);

  return (
    <div className={cx(sprinkles({ display: 'flex' }), styles.configInput)}>
      <InputWithBlurSave
        className={sprinkles({ paddingRight: 'sp.5' })}
        initialValue={config.onStatusValue}
        label="On Value"
        onNewValueSubmitted={(newValue) =>
          updateConfig((draft) => (draft.onStatusValue = newValue))
        }
        placeholder="true"
      />
      <InputWithBlurSave
        className={sprinkles({ paddingLeft: 'sp.5' })}
        initialValue={config.offStatusValue}
        label="Off Value"
        onNewValueSubmitted={(newValue) =>
          updateConfig((draft) => (draft.offStatusValue = newValue))
        }
        placeholder="false"
      />
    </div>
  );
};
