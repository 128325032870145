import { sortBy } from 'utils/standard';
import { createReducer } from '@reduxjs/toolkit';

import {
  deleteCustomFontSuccess,
  fetchCustomFontsSuccess,
  uploadCustomFontSuccess,
} from 'actions/customFontsActions';

interface CustomFontsReducerState {
  customFonts?: string[];
}

export default createReducer<CustomFontsReducerState>({}, (builder) => {
  builder
    .addCase(fetchCustomFontsSuccess, (state, { payload }) => {
      state.customFonts = sortBy(payload.fonts, (font) => font.toLowerCase());
    })
    .addCase(uploadCustomFontSuccess, (state, { payload }) => {
      if (state.customFonts) {
        state.customFonts.push(payload.postData.fileName);
        state.customFonts = sortBy(state.customFonts, (font) => font.toLowerCase());
        return;
      }
      state.customFonts = [payload.postData.fileName];
    })
    .addCase(deleteCustomFontSuccess, (state, { payload }) => {
      const fileName = payload.postData.fileName;
      state.customFonts = state.customFonts?.filter((font) => font !== fileName);
    })
    .addDefaultCase((state) => state);
});
