import { FC } from 'react';

import * as RadixCheckbox from '@radix-ui/react-checkbox';
import { Icon } from 'components/ds';
import * as styles from './index.css';

export type Props = { isChecked: boolean; isIndeterminate?: boolean; onChange: () => void };

export const Checkbox: FC<Props> = ({ isChecked, isIndeterminate, onChange }) => {
  return (
    <RadixCheckbox.Root checked={isChecked} className={styles.checkbox} onCheckedChange={onChange}>
      <RadixCheckbox.Indicator>
        <Icon name={isIndeterminate ? 'horizontal-rule' : 'check'} />
      </RadixCheckbox.Indicator>
    </RadixCheckbox.Root>
  );
};
