import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Menu, MenuItem, Intent } from '@blueprintjs/core';

import { AlertModal } from 'components/ds';
import ActivateDisabledDashboardModal from './ActivateDisabledDashboardModal';

import { trackEvent, EVENTS } from 'analytics/exploAnalytics';
import {
  activateDisabledDashboard,
  DashboardTemplate,
  deleteDashboardTemplate,
} from 'actions/dashboardTemplateActions';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 220,
    maxWidth: 260,
    backgroundColor: 'white',
    borderRadius: 3,
    border: `1px solid ${theme.palette.ds.grey400}`,
  },
}));

type Props = {
  dashboardTemplate: DashboardTemplate;
  dashboardTemplateList: DashboardTemplate[];
  setLoadingStateForDashboard: (isLoading: boolean) => void;
};

export default function DisabledDashboardMenu({
  dashboardTemplate,
  dashboardTemplateList,
  setLoadingStateForDashboard,
}: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [activateModalOpen, setActivateModalOpen] = useState(false);
  const [deleteDashboardModalOpen, setDeleteDashboardModalOpen] = useState(false);

  const onDeleteDashboard = () => {
    setLoadingStateForDashboard(true);
    setDeleteDashboardModalOpen(false);
    dispatch(
      deleteDashboardTemplate({ id: dashboardTemplate?.id }, () => {
        setLoadingStateForDashboard(false);
        trackEvent(EVENTS.DELETED_DASHBOARD, {
          dashboard_template_id: dashboardTemplate?.id,
        });
      }),
    );
  };
  return (
    <>
      <Menu className={classes.root}>
        <MenuItem
          icon="refresh"
          onClick={() => setActivateModalOpen(true)}
          shouldDismissPopover={false}
          text="Activate"
        />
        <MenuItem
          icon="trash"
          intent={Intent.DANGER}
          onClick={() => setDeleteDashboardModalOpen(true)}
          shouldDismissPopover={false}
          text="Delete"
        />
      </Menu>
      <div onClick={(e) => e.stopPropagation()}>
        <ActivateDisabledDashboardModal
          activateDashboard={(disableDashboardId) =>
            dispatch(
              activateDisabledDashboard({
                id: dashboardTemplate.id,
                postData: { disable_dashboard_id: disableDashboardId },
              }),
            )
          }
          closeModal={() => setActivateModalOpen(false)}
          dashboardTemplateList={dashboardTemplateList}
          modalOpen={activateModalOpen}
          selectedDashboardTemplateId={dashboardTemplate.id}
        />
        <AlertModal
          actionButtonProps={{ text: 'Delete for all users', onClick: onDeleteDashboard }}
          isOpen={deleteDashboardModalOpen}
          onClose={() => setDeleteDashboardModalOpen(false)}
          title="Are you sure you want to delete this dashboard?"
        />
      </div>
    </>
  );
}
