import produce from 'immer';

import FormatSection from './FormatSection';
import SharedXAxisConfigs from 'components/ChartConfigs/XAxisConfigs';

import { sharedStyles } from './sharedStyles';
import { CanvasV2TwoDimensionChartTypes, FormatConfigProps } from './types';
import { V2TwoDimensionChartInstructions, XAxisFormat } from 'constants/types';

type Props = FormatConfigProps<CanvasV2TwoDimensionChartTypes, V2TwoDimensionChartInstructions> & {
  isHorizontal?: boolean;
};

export default function XAxisSection({
  isArchitectCustomerDashboard,
  isHorizontal,
  instructions,
  operationType,
  updateInstructions,
}: Props): JSX.Element {
  const classes = sharedStyles({ isArchitectCustomerDashboard });

  const updateXAxisFormat = (xAxisUpdates: XAxisFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.xAxisFormat = {
        ...draft.xAxisFormat,
        ...xAxisUpdates,
      };
    });

    updateInstructions({ instructions: newInstructions, operationType });
  };

  return (
    <FormatSection
      defaultIsOpen
      isArchitectCustomerDashboard={isArchitectCustomerDashboard}
      title={isHorizontal ? 'Y-Axis' : 'X-Axis'}>
      <SharedXAxisConfigs
        isCanvas
        configInputClass={classes.configInput}
        instructions={instructions}
        isHorizontal={isHorizontal}
        operationType={operationType}
        updateXAxisFormat={updateXAxisFormat}
      />
    </FormatSection>
  );
}
