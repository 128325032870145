import { useEffect, useState } from 'react';

import { BaseModal } from 'pages/ReportBuilder/ModalViews/BaseModal';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { ExportScheduler } from './ExportScheduler';
import { IconButton, sprinkles } from 'components/ds';
import { ScheduleManager } from './ScheduleManager';

import { EmailBody, EmailCadence } from 'types/emailCadence';
import { Jobs } from 'components/JobQueue/types';

enum ModalView {
  SCHEDULE,
  MANAGE,
}

type Props = {
  closeModal: () => void;
  createExportCadence: (email: EmailBody, onSuccess: () => void, onFailure: () => void) => void;
  emails: EmailCadence[];
  customerToken: string;
  resourceName: string;
  sendTestExport: (emailId: string, onSuccess: (jobs: Record<string, Jobs>) => void) => void;
  sendTestDraftExport: (
    recipients: string[],
    subject: string,
    message: string,
    onSuccess: (jobs: Record<string, Jobs>) => void,
  ) => void;
  updateExportCadence: (
    emailId: string,
    email: EmailBody,
    onSuccess: () => void,
    onFailure: () => void,
  ) => void;
  deleteExportCadence: (emailId: string) => void;
  loading?: boolean;
};

export function ScheduledExportModal({
  loading,
  createExportCadence,
  closeModal,
  emails,
  customerToken,
  resourceName,
  sendTestDraftExport,
  sendTestExport,
  updateExportCadence,
  deleteExportCadence,
}: Props): JSX.Element {
  const [view, setView] = useState(emails.length === 0 ? ModalView.SCHEDULE : ModalView.MANAGE);
  const [editingEmailId, setEditingEmailId] = useState<string | null>(null);

  useEffect(() => {
    if (!loading && emails.length > 0) setView(ModalView.MANAGE);
  }, [emails.length, loading]);

  const modalTitle =
    view === ModalView.MANAGE
      ? 'Manage Exports'
      : editingEmailId
      ? 'Edit Export'
      : 'Schedule a New Export';

  const renderBody = () => {
    if (view === ModalView.SCHEDULE) {
      const editingEmail = editingEmailId
        ? emails.find((email) => email.id === editingEmailId)
        : undefined;

      return (
        <ExportScheduler
          createExportCadence={createExportCadence}
          customerToken={customerToken}
          editingEmail={editingEmail}
          goToManage={() => {
            setView(ModalView.MANAGE);
            setEditingEmailId(null);
          }}
          resourceName={resourceName}
          sendTestDraftExport={sendTestDraftExport}
          updateExportCadence={updateExportCadence}
        />
      );
    }
    return (
      <ScheduleManager
        closeModal={closeModal}
        customerToken={customerToken}
        deleteEmail={deleteExportCadence}
        editEmail={(emailId) => {
          setView(ModalView.SCHEDULE);
          setEditingEmailId(emailId);
        }}
        emails={emails}
        loading={loading}
        openScheduler={() => setView(ModalView.SCHEDULE)}
        sendTestExport={sendTestExport}
      />
    );
  };

  const backButton =
    view === ModalView.SCHEDULE && emails.length > 0 ? (
      <IconButton
        className={sprinkles({ cursor: 'pointer', marginRight: 'sp1' })}
        name="arrow-left"
        onClick={() => {
          setView(ModalView.MANAGE);
          setEditingEmailId(null);
        }}
      />
    ) : null;

  return (
    <BaseModal>
      <div className={sprinkles({ flexItems: 'column', padding: 'sp3' })} style={{ width: 764 }}>
        <div
          className={sprinkles({
            flexItems: 'alignCenter',
            justifyContent: 'space-between',
            paddingBottom: 'sp3',
          })}>
          <div className={sprinkles({ flexItems: 'alignCenter' })}>
            {backButton}
            <EmbedText heading="h1">{modalTitle}</EmbedText>
          </div>
          <IconButton
            className={sprinkles({ cursor: 'pointer' })}
            name="cross"
            onClick={() => closeModal()}
          />
        </div>
        {renderBody()}
      </div>
    </BaseModal>
  );
}
