import { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import { Icon, EditableText } from '@blueprintjs/core';
import { sprinkles } from 'components/ds';
import cx from 'classnames';

import { passwordResetRequest } from 'actions/authAction';
import { updateUserName } from 'actions/userActions';
import { ReduxState } from 'reducers/rootReducer';
import { showErrorContactSupportToast, showSuccessToast } from 'shared/sharedToasts';
import { getUserInitials } from 'components/Sidebar/utils';

const useStyles = makeStyles((theme: Theme) => ({
  subsection: {
    fontSize: 20,
    fontWeight: 600,
  },
  profileIcon: {
    position: 'static',
    marginTop: theme.spacing(6),
    display: 'flex',
  },
  changeProfileIcon: {
    marginLeft: theme.spacing(-28),
    display: 'flex',
    opacity: '0',
    alignItems: 'center',
    justifyContent: 'center',
    width: 112,
    height: 112,
    borderRadius: '50%',
    backgroundColor: theme.palette.ds.grey700,
    '&:hover': {
      opacity: '0',
      cursor: 'auto',
    },
  },
  initials: {
    color: theme.palette.ds.white,
    borderRadius: '12%',
    display: 'flex',
    justifyContent: 'center',
    width: 112,
    height: 112,
    alignItems: 'center',
    fontSize: 52,
  },
  label: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.ds.grey900,
    marginTop: theme.spacing(4),
  },
  profileInfo: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(8),
    width: '100%',
  },
  nameBox: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid',
    paddingLeft: theme.spacing(3),
    borderColor: theme.palette.ds.grey500,
    height: '32px',
    borderRadius: '4px',
    minWidth: '160px',
    maxWidth: '450px',
    width: '100%',
    '&:hover': {
      cursor: 'text',
    },
  },
  emailBox: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid',
    paddingLeft: theme.spacing(3),
    borderColor: theme.palette.ds.grey300,
    height: '32px',
    borderRadius: '4px',
    background: theme.palette.ds.grey100,

    '&:hover': {
      cursor: 'not-allowed',
    },
  },
  name: {
    fontSize: 14,
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(1.5),
  },
  resetPasswordButton: {
    marginTop: theme.spacing(7),
    background: theme.palette.ds.grey200,
    fontWeight: 500,
    borderRadius: '4px',
    height: '32px',
    width: '141px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  profile: {
    display: 'flex',
    marginBottom: theme.spacing(20),
  },
  nameBoxes: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  nameBoxWrapper: {
    paddingRight: theme.spacing(5),
    width: '100%',

    '&:last-child': {
      paddingRight: 0,
    },
  },
  languageContainer: {
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(8),
  },
}));

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

function SettingsProfileSection(props: Props) {
  const classes = useStyles();
  const { passwordResetRequest, currentUser, updateUserName } = props;
  const [firstName, setFirstName] = useState(currentUser.first_name);
  const [lastName, setLastName] = useState(currentUser.last_name);
  const onPasswordResetRequestSubmitted = () => {
    if (!currentUser.email) return;
    passwordResetRequest(
      { postData: { email: currentUser.email } },
      () => showSuccessToast('Reset password email sent successfully.'),
      () => showErrorContactSupportToast(),
    );
  };

  return (
    <div>
      <div className={classes.subsection}>Profile</div>
      <div className={classes.profile}>
        <div className={classes.profileIcon}>
          <div className={cx(sprinkles({ backgroundColor: 'blueDark6' }), classes.initials)}>
            {getUserInitials(currentUser)}
          </div>
          <div className={classes.changeProfileIcon}>
            <Icon color="white" icon="upload" />
          </div>
        </div>
        <div className={classes.profileInfo}>
          <div className={classes.nameBoxes}>
            <div className={classes.nameBoxWrapper}>
              <div className={classes.label}>First Name</div>
              <EditableText
                className={classes.nameBox}
                onChange={(value: string) => setFirstName(value)}
                onConfirm={(value: string) => {
                  updateUserName({
                    postData: {
                      first_name: value,
                      last_name: currentUser.last_name,
                      id: currentUser.id,
                    },
                  });
                }}
                placeholder=""
                selectAllOnFocus={true}
                value={firstName}
              />
            </div>
            <div className={classes.nameBoxWrapper}>
              <div className={classes.label}>Last Name</div>
              <EditableText
                className={classes.nameBox}
                onChange={(value: string) => setLastName(value)}
                onConfirm={(value: string) => {
                  updateUserName({
                    postData: {
                      first_name: currentUser.first_name,
                      last_name: value,
                      id: currentUser.id,
                    },
                  });
                }}
                placeholder=""
                selectAllOnFocus={true}
                value={lastName}
              />
            </div>
          </div>
          <div className={classes.label}>Email</div>
          <div className={classes.emailBox}>{currentUser.email}</div>
          <div
            className={classes.resetPasswordButton}
            onClick={() => onPasswordResetRequestSubmitted()}>
            Reset Password
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  passwordResetRequest,
  updateUserName,
};

const mapStateToProps = (state: ReduxState) => ({
  currentUser: state.currentUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsProfileSection);
