import { Layout } from '@explo-tech/react-grid-layout';
import { CanvasDataset } from 'actions/canvasConfigActions';
import { DashboardTemplate, EmbedDashboardTemplate } from 'actions/dashboardTemplateActions';
import { DashboardElement } from 'types/dashboardTypes';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import {
  DataPanel,
  ExploResource,
  LatestVersionInfo,
  ResourceDataset,
  ResourcePageType,
} from 'types/exploResource';
import { getCanvasDashboardElements } from './canvasConfigUtils';
import { PERMISSIONED_ENTITIES } from 'constants/roleConstants';
import { EnvironmentTag } from 'actions/teamActions';
import { Dataset } from 'actions/datasetActions';

export function getDisableFiltersWhileLoading(resource: ExploResource): boolean {
  // Canvases do not have this option for dashboards
  if (!isDashboard(resource)) return false;

  return resource.disable_filters_while_loading;
}

export function getSyncCategoryColors(resource: ExploResource): boolean {
  // All canvases will just default to having this turned on
  if (!isDashboard(resource)) return true;

  return resource.sync_category_colors;
}

function isDashboard(
  resource: ExploResource,
): resource is DashboardTemplate | EmbedDashboardTemplate {
  // If disable filters while loading is added to canvas will need to change
  return 'disable_filters_while_loading' in resource;
}

export function getDataPanelDatasetId(dataPanel: DataPanel): string {
  // Canvas data panels use dataset_id while dashboard uses table_id
  if ('table_id' in dataPanel) return String(dataPanel.table_id);
  return dataPanel.dataset_id;
}

export function elementHasContainer(
  element: DashboardElement | DataPanel,
): element is DataPanelTemplate | DashboardElement {
  // Only CanvasTemplate
  if ('description' in element) return false;
  // Only EndUserDataPanel
  if ('template_id' in element) return false;
  return true;
}

export function isDashboardElement(
  element: DashboardElement | DataPanel,
): element is DashboardElement {
  return 'element_type' in element;
}

export function isCanvasDataset(dataset: ResourceDataset): dataset is CanvasDataset {
  return 'columnOptions' in dataset;
}

export function isExploreDataset(dataset: ResourceDataset): dataset is Dataset {
  return 'table_name' in dataset;
}

export function getPreviewInfoFromLatestVersion({ configuration }: LatestVersionInfo): {
  layout: Layout[];
  dataPanels: DataPanel[];
  elements: DashboardElement[];
} {
  if ('dashboard_layout' in configuration) {
    return {
      layout: configuration.dashboard_layout,
      dataPanels: Object.values(configuration.data_panels),
      elements: Object.values(configuration.elements),
    };
  }
  return {
    layout: configuration.default_layout,
    dataPanels: Object.values(configuration.templates),
    elements: getCanvasDashboardElements(configuration),
  };
}

export function isDataPanelTemplate(dataPanel: DataPanel): dataPanel is DataPanelTemplate {
  return 'group_by_op' in dataPanel;
}

export function getDataPanelTitle({ visualize_op }: DataPanel): string | null {
  const title = visualize_op.generalFormatOptions?.headerConfig?.title ?? '';
  return title.trim() !== '' && !visualize_op.generalFormatOptions?.headerConfig?.isHeaderHidden
    ? title
    : null;
}

export function getPermissionEntity(pageType: ResourcePageType): PERMISSIONED_ENTITIES {
  switch (pageType) {
    case ResourcePageType.ARCHITECT:
      return PERMISSIONED_ENTITIES.ARCHITECT;
    case ResourcePageType.REPORT_BUILDER:
      return PERMISSIONED_ENTITIES.REPORT_BUILDER;
    case ResourcePageType.EXPLORE:
      return PERMISSIONED_ENTITIES.DASHBOARD;
  }
}

export function isVersionTagged(
  pageType: ResourcePageType,
  resourceId: number,
  versionId: number,
  tag: EnvironmentTag,
): boolean {
  switch (pageType) {
    case ResourcePageType.ARCHITECT:
      return tag.canvas_versions_by_canvas?.[resourceId] === versionId;
    case ResourcePageType.EXPLORE:
      return tag.dashboard_versions_by_dashboard?.[resourceId] === versionId;
    case ResourcePageType.REPORT_BUILDER:
      return tag.report_builder_mapping?.[resourceId] === versionId;
  }
}
