import produce from 'immer';
import { useDispatch } from 'react-redux';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import { Switch } from 'components/ds';
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { ScatterPlotFormat, OPERATION_TYPES, V2ScatterPlotInstructions } from 'constants/types';
import { sharedStyles } from './styles';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2ScatterPlotInstructions;
};

export default function ScatterPlotConfig({ visualizationType, instructions }: Props) {
  const { scatterPlotFormat } = instructions;
  const sharedClasses = sharedStyles();
  const dispatch = useDispatch();

  const updateScatterPlotFormat = (scatterPlotFormat: ScatterPlotFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.scatterPlotFormat = {
        ...draft.scatterPlotFormat,
        ...scatterPlotFormat,
      };
    });

    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  return (
    <div className={sharedClasses.root}>
      <InputWithBlurSave
        containerClassName={sharedClasses.configInput}
        initialValue={String(scatterPlotFormat?.radius ?? 4)}
        label="Marker Radius"
        onNewValueSubmitted={(newValue) => {
          const newInt = parseInt(newValue);
          updateScatterPlotFormat({ radius: newInt > 0 ? newInt : undefined });
        }}
      />
      {!instructions.groupingColumn ? (
        <InputWithBlurSave
          containerClassName={sharedClasses.configInput}
          initialValue={scatterPlotFormat?.seriesLabel ?? 'Series 1'}
          label="Label"
          onNewValueSubmitted={(newValue) => {
            updateScatterPlotFormat({ seriesLabel: newValue });
          }}
        />
      ) : undefined}
      <Switch
        className={sharedClasses.configInput}
        label="Use Jitter to Spread Points"
        onChange={() => updateScatterPlotFormat({ useJitter: !scatterPlotFormat?.useJitter })}
        switchOn={scatterPlotFormat?.useJitter}
      />
    </div>
  );
}
