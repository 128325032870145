import cx from 'classnames';
import { cloneDeep } from 'utils/standard';
import { useDispatch } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';

import DropdownSelect from 'shared/DropdownSelect';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';
import { Switch } from 'components/ds';
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { TEXT_ELEM_HORIZ_ALIGNMENTS } from 'types/dashboardTypes';
import { KPIValueFormat, OPERATION_TYPES, V2KPITrendInstructions } from 'constants/types';
import {
  V2_NUMBER_FORMATS,
  TIME_DIFF_FORMATS,
  ORDERED_ALIGNMENTS,
  NUMBER_FORMATS_WITH_DECIMALS,
} from 'constants/dataConstants';
import { sharedStyles } from './styles';

const useStyles = makeStyles((theme: Theme) => ({
  colorPaletteDisplay: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  groupedInput: {
    width: '50%',

    '&:first-child': {
      marginRight: theme.spacing(3),
    },
  },
  groupedInputsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2KPITrendInstructions;
};

export default function KPITrendValueConfig({ visualizationType, instructions }: Props) {
  const classes = useStyles();
  const sharedClasses = sharedStyles();
  const dispatch = useDispatch();

  const selectedFormat = instructions.valueFormat?.numberFormat || V2_NUMBER_FORMATS.NUMBER;
  const selectedTimeFormat = instructions.valueFormat?.timeFormat || TIME_DIFF_FORMATS.STANDARD;
  const selectedAlignment =
    instructions.valueFormat?.horizAlignment || TEXT_ELEM_HORIZ_ALIGNMENTS.CENTER;

  const updateValueFormat = (valueFormat: KPIValueFormat) => {
    const newInstructions = cloneDeep(instructions);

    newInstructions.valueFormat = {
      ...newInstructions.valueFormat,
      ...valueFormat,
    };
    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  return (
    <div className={sharedClasses.root}>
      <ToggleButtonGroup fillWidth className={sharedClasses.configInput} label="Format">
        {Object.values(V2_NUMBER_FORMATS).map((numberFormat) => (
          <ToggleButton
            active={numberFormat.id === selectedFormat.id}
            key={numberFormat.id}
            onClick={() => updateValueFormat({ numberFormat })}
            text={numberFormat.abbreviation}
          />
        ))}
      </ToggleButtonGroup>
      {selectedFormat.id === V2_NUMBER_FORMATS.TIME.id && (
        <>
          <DropdownSelect
            btnMinimal
            fillWidth
            minimal
            containerClassName={sharedClasses.configInput}
            filterable={false}
            label="Time Format"
            noSelectionText="Standard"
            onChange={(item) => updateValueFormat({ timeFormat: item })}
            options={Object.values(TIME_DIFF_FORMATS)}
            selectedItem={selectedTimeFormat}
          />
          {instructions.valueFormat?.timeFormat?.id === TIME_DIFF_FORMATS.CUSTOM.id && (
            <InputWithBlurSave
              containerClassName={sharedClasses.configInput}
              initialValue={instructions.valueFormat?.timeCustomerFormat}
              label="Custom Time Format"
              onNewValueSubmitted={(newValue) =>
                updateValueFormat({ timeCustomerFormat: newValue })
              }
              placeholder="DD days HH:mm:ss"
            />
          )}
        </>
      )}
      {NUMBER_FORMATS_WITH_DECIMALS.includes(selectedFormat.id) && (
        <InputWithBlurSave
          containerClassName={sharedClasses.configInput}
          initialValue={String(instructions.valueFormat?.decimalPlaces ?? 2)}
          label="Decimal Places"
          onNewValueSubmitted={(newValue) => {
            const intValue = parseInt(newValue);
            updateValueFormat({ decimalPlaces: intValue > 0 ? intValue : 0 });
          }}
        />
      )}
      {selectedFormat.id === V2_NUMBER_FORMATS.ABBREVIATED.id && (
        <InputWithBlurSave
          containerClassName={sharedClasses.configInput}
          initialValue={String(instructions.valueFormat?.significantDigits ?? 3)}
          label="Significant Digits"
          onNewValueSubmitted={(newValue) => {
            const intValue = parseInt(newValue);
            updateValueFormat({ significantDigits: intValue >= 0 ? intValue : 3 });
          }}
        />
      )}

      <InputWithBlurSave
        containerClassName={sharedClasses.configInput}
        initialValue={instructions.valueFormat?.units}
        label="Unit"
        onNewValueSubmitted={(newValue) => updateValueFormat({ units: newValue })}
      />
      <Switch
        useCustomStyles
        className={sharedClasses.configInput}
        label="Include Unit Padding"
        onChange={() => updateValueFormat({ unitPadding: !instructions.valueFormat?.unitPadding })}
        switchOn={instructions.valueFormat?.unitPadding}
      />

      {instructions?.hideTrendLines && (
        <>
          <div className={cx(sharedClasses.configInput, classes.groupedInputsContainer)}>
            <ToggleButtonGroup fillWidth className={classes.groupedInput} label="Bold">
              <ToggleButton
                active={!instructions.valueFormat?.bold}
                key="no-bold-selection-kpi"
                onClick={() => updateValueFormat({ bold: false })}
                text=" "
              />
              <ToggleButton
                active={instructions.valueFormat?.bold}
                icon="bold"
                key="bold-selection-kpi"
                onClick={() => updateValueFormat({ bold: true })}
              />
            </ToggleButtonGroup>

            <ToggleButtonGroup fillWidth className={classes.groupedInput} label="Italic">
              <ToggleButton
                active={!instructions.valueFormat?.italic}
                key="no-italic-selection-kpi"
                onClick={() => updateValueFormat({ italic: false })}
                text=" "
              />
              <ToggleButton
                active={instructions.valueFormat?.italic}
                icon="italic"
                key="italic-selection-kpi"
                onClick={() => updateValueFormat({ italic: true })}
              />
            </ToggleButtonGroup>
          </div>
          <ToggleButtonGroup fillWidth className={sharedClasses.configInput}>
            {ORDERED_ALIGNMENTS.map((alignment) => (
              <ToggleButton
                active={alignment.id === selectedAlignment}
                icon={alignment.icon}
                key={alignment.id}
                onClick={() => updateValueFormat({ horizAlignment: alignment.id })}
              />
            ))}
          </ToggleButtonGroup>
        </>
      )}
    </div>
  );
}
