import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { map } from 'utils/standard';
import { sprinkles } from 'components/ds';

import Button from 'shared/Button';
import { TextFieldModal } from 'components/modals/textFieldModal';
import { showSuccessToast } from 'shared/sharedToasts';
import { TextElementConfigPanel } from '../TextElementConfigPanel';
import { ExportElementConfigPanel } from '../ExportElementConfigPanel';
import { IframeElementConfigPanel } from '../IframeElementConfigPanel';
import { ContainerElementConfigPanel } from '../ContainerElementConfigPanel';
import { ImageElementConfigPanel } from '../ImageElementConfigPanel';
import { FilterConfigPanel } from '../FilterConfigPanel';
import { RESERVED_VARIABLES } from 'constants/variables';
import * as styles from './index.css';

import {
  ApplyFilterElemConfig,
  DashboardElement,
  DashboardElementConfig,
  DASHBOARD_ELEMENT_TYPES,
  ExportElemConfig,
  IframeElemConfig,
  ImageElemConfig,
  TextDashboardElemConfig,
} from 'types/dashboardTypes';
import {
  duplicateDashboardItem,
  saveDashboardElementUpdates,
  updateElementConfig,
} from 'actions/dashboardV2Actions';
import { EVENTS, trackEvent } from 'analytics/exploAnalytics';
import { FILTER_ELEMENTS } from 'constants/dashboardConstants';
import { Dataset } from 'actions/datasetActions';
import { ApplyFilterButtonConfigPanel } from '../ApplyFilterButtonConfigPanel';
import { DataPanelTemplate } from 'types/dataPanelTemplate';

type Props = {
  allElements: DashboardElement[];
  dashboardId: number;
  dashboardShareLinkTitle: string | null;
  dataPanels: DataPanelTemplate[];
  datasets: Record<string, Dataset>;
  element: DashboardElement;

  onDeleteElement: () => void;
};

export const ElementConfigPanel: FC<Props> = ({
  allElements,
  element,
  dashboardId,
  dashboardShareLinkTitle,
  dataPanels,
  datasets,
  onDeleteElement,
}) => {
  const dispatch = useDispatch();

  const [isDuplicating, setIsDuplicating] = useState(false);
  const [renameElementModalOpen, setRenameElementModalOpen] = useState(false);

  const updateConfig = useCallback(
    (config: DashboardElementConfig) => {
      dispatch(updateElementConfig({ elementId: element.id, config }));
    },
    [dispatch, element.id],
  );

  const allElementNames = useMemo(() => new Set(map(allElements, 'name')), [allElements]);

  const renderRenameModal = () => {
    if (!renameElementModalOpen) return null;

    return (
      <TextFieldModal
        modalOpen
        buttonName="Save"
        closeModal={() => setRenameElementModalOpen(false)}
        errorState={(val) => {
          if (!val)
            return {
              isErrorState: true,
              errorMsg: 'An element name is required and cannot be empty.',
            };
          if (val !== element.name && allElementNames.has(val))
            return {
              isErrorState: true,
              errorMsg:
                'An element with this name on this dashboard already exists. Please choose a different one.',
            };
          if (RESERVED_VARIABLES.has(val)) {
            return {
              isErrorState: true,
              errorMsg: `The name "${val}" is reserved. Please choose a different name.`,
            };
          }
          return { isErrorState: false };
        }}
        modalTitle="Enter Dashboard Element Name"
        onSubmit={(name) => {
          if (allElementNames.has(name) || name === element.name) return;

          setRenameElementModalOpen(false);

          window.dispatchEvent(
            new CustomEvent('renameDashboardVariables', {
              detail: { renames: [[element.name, name]] },
            }),
          );

          dispatch(saveDashboardElementUpdates({ id: element.id, name }));

          trackEvent(EVENTS.RENAMED_DASHBOARD_ELEMENT, {
            dashboard_element_id: element.id,
            name: name,
          });
        }}
        resourceName={element.name}
        textFieldPlaceholder="Dashboard element name"
      />
    );
  };

  const renderNonFilterConfig = () => {
    switch (element.element_type) {
      case DASHBOARD_ELEMENT_TYPES.TEXT:
        return (
          <TextElementConfigPanel
            config={element.config as TextDashboardElemConfig}
            datasets={datasets}
            updateConfig={updateConfig}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.EXPORT:
        return (
          <ExportElementConfigPanel
            config={element.config as ExportElemConfig}
            dashboardId={dashboardId}
            dashboardShareLinkTitle={dashboardShareLinkTitle}
            updateConfig={updateConfig}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.IFRAME:
        return (
          <IframeElementConfigPanel
            config={element.config as IframeElemConfig}
            updateConfig={updateConfig}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.CONTAINER:
        return (
          <ContainerElementConfigPanel
            containerId={element.id}
            dataPanels={dataPanels}
            elements={allElements}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.IMAGE:
        return (
          <ImageElementConfigPanel
            config={element.config as ImageElemConfig}
            updateConfig={updateConfig}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.APPLY_FILTER_BUTTON:
        return (
          <ApplyFilterButtonConfigPanel
            allElements={allElements}
            config={element.config as ApplyFilterElemConfig}
            elementId={element.id}
            updateConfig={updateConfig}
          />
        );
    }
    return null;
  };

  const renderConfigurationMenu = () => {
    if (FILTER_ELEMENTS.has(element.element_type)) {
      return (
        <div className={styles.filterConfigMenu}>
          <FilterConfigPanel
            allElements={allElements}
            datasets={datasets}
            element={element}
            updateConfig={updateConfig}
          />
        </div>
      );
    }

    return <div className={styles.configurationMenu}>{renderNonFilterConfig()}</div>;
  };

  return (
    <div className={styles.root}>
      <div className={styles.configurationHeader}>
        <div className={sprinkles({ fontWeight: 700, flex: 1, truncateText: 'ellipsis' })}>
          {element.name}
        </div>
        <Button
          minimal
          className={styles.activeButton}
          icon="edit"
          onClick={() => setRenameElementModalOpen(true)}
        />
        <Button
          minimal
          className={styles.activeButton}
          disabled={isDuplicating}
          icon={isDuplicating ? 'tick' : 'duplicate'}
          onClick={() => {
            setIsDuplicating(true);
            dispatch(
              duplicateDashboardItem({
                dashboardItem: element,
                itemType: element.element_type,
                dashId: dashboardId,
              }),
            );

            showSuccessToast(`${element.name} has been duplicated below`, 3, () =>
              setIsDuplicating(false),
            );
          }}
        />
        <Button
          minimal
          className={styles.activeButton}
          icon="trash"
          onClick={onDeleteElement}
          type="destructive"
        />
      </div>
      {renderConfigurationMenu()}
      {renderRenameModal()}
    </div>
  );
};
