import { useMemo } from 'react';

import { Button, sprinkles } from 'components/ds';
import {
  DropdownOption,
  EmbeddedDropdownMenu,
} from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownMenu';

import { SortOrder } from 'constants/types';

import * as styles from 'pages/ReportBuilder/HomeView/SortButton.css';

interface SortOption<T extends string> {
  name: T;
  sortOrder: SortOrder;
}

interface Props<T extends string> {
  sortOptions: SortOption<T>[];
  sortOption: SortOption<T>;
  onChange: (sortOption: SortOption<T>) => void;
}

export const SortButton = <T extends string>({ sortOptions, onChange, sortOption }: Props<T>) => {
  const menuOptions = useMemo(
    (): DropdownOption[] =>
      sortOptions.map((option) => {
        return {
          name: option.name as string,
          icon: getSortOrderIcon(option.sortOrder),
          onClick: () => onChange(option),
        };
      }),
    [sortOptions, onChange],
  );

  return (
    <div
      className={sprinkles({
        flexItems: 'center',
      })}>
      <EmbeddedDropdownMenu menuOptions={menuOptions}>
        <Button
          className={styles.sortButton}
          icon={getSortOrderIcon(sortOption.sortOrder)}
          type="tertiary">
          {sortOption.name as string}
        </Button>
      </EmbeddedDropdownMenu>
    </div>
  );
};

const getSortOrderIcon = (sortOrder: SortOrder) =>
  sortOrder === SortOrder.ASC ? 'arrow-up' : 'arrow-down';
