import { FC } from 'react';
import { Spinner, sprinkles } from 'components/ds';

import { EmbeddedDashboardType } from './types';

type Props = {
  embedType: EmbeddedDashboardType;
};

export const GenericLoadingSpinner: FC<Props> = ({ embedType }) => {
  if (embedType === 'embedded') {
    return <Spinner fillContainer size="xl" />;
  }
  return (
    <div className={sprinkles({ flexItems: 'center', parentContainer: 'fill' })}>
      <Spinner size="xl" />
    </div>
  );
};
