import produce from 'immer';
import { makeStyles, Theme } from '@material-ui/core/styles';

import {
  GlobalStyleConfig,
  GlobalStyleDivergingPaletteConfig,
  GlobalStyleGradientPaletteConfig,
} from 'globalStyles/types';
import ConfigSectionHeader from 'pages/dataPanelEditorPage/configSectionHeader';
import ColorPicker from 'components/ColorPicker';
import ColorPaletteInput from 'components/ColorPaletteInput';

const useStyles = makeStyles((theme: Theme) => ({
  spacingInput: {
    width: 65,
    marginRight: theme.spacing(3),
  },
  spacingHeading: {
    marginTop: theme.spacing(6),
  },
  backgroundColorSelection: {
    marginRight: theme.spacing(3),
  },
  actionsColorSelection: {
    marginLeft: theme.spacing(3),
  },
  inputLabel: {
    fontSize: 12,
    marginBottom: theme.spacing(1),
  },
  secondaryFontRow: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(5),
  },
  paletteLabel: {
    fontSize: 12,
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(1),
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    columnGap: theme.spacing(3),
    // Passing a number to rowGap doesn't work for some reason
    rowGap: `${theme.spacing(2)}px`,
  },
  borderlessColorPicker: {
    '&.bp3-button': {
      border: 'none',
    },
  },
  linearLeftColorPicker: {
    '&.bp3-button': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      border: `1px solid ${theme.palette.ds.grey300}`,
      borderRight: 'none',
    },
  },
  linearCenterColorPicker: {
    '&.bp3-button': {
      borderRadius: 0,
      borderTop: `1px solid ${theme.palette.ds.grey300}`,
      borderBottom: `1px solid ${theme.palette.ds.grey300}`,
    },
  },
  linearRightColorPicker: {
    '&.bp3-button': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      border: `1px solid ${theme.palette.ds.grey300}`,
      borderLeft: 'none',
    },
  },
  coloredDiv: {
    borderTop: `1px solid ${theme.palette.ds.grey300}`,
    borderBottom: `1px solid ${theme.palette.ds.grey300}`,
  },
}));

type Props = {
  styleConfig: GlobalStyleConfig;
  updateConfig: (newConfig: GlobalStyleConfig) => void;
};

export default function VisualizationsConfigSection(props: Props) {
  return (
    <>
      <ConfigSectionHeader title="Visualizations" />
      <CategoricalPalette {...props} />
      <DivergingPalette {...props} />
      <GradientPalette {...props} />
    </>
  );
}

function CategoricalPalette({ styleConfig, updateConfig }: Props) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.paletteLabel}>Categorical palette</div>
      <div className={classes.gridContainer}>
        {Object.keys(styleConfig.visualizations.categoricalPalette).map((hueString) => {
          const hue = hueString as keyof typeof styleConfig.visualizations.categoricalPalette;
          return (
            <ColorPicker
              fill
              color={styleConfig.visualizations.categoricalPalette[hue]}
              key={hue}
              onClose={(newHue) => {
                const newConfig = produce(styleConfig, (draft) => {
                  draft.visualizations.categoricalPalette[hue] = newHue;
                });
                updateConfig(newConfig);
              }}
            />
          );
        })}
      </div>
    </>
  );
}

function DivergingPalette({ styleConfig, updateConfig }: Props) {
  const classes = useStyles();

  const hues: (keyof GlobalStyleDivergingPaletteConfig)[] = ['hue1', 'hue2', 'hue3'];
  const getOnHueChanged = (key: keyof GlobalStyleDivergingPaletteConfig) => {
    return (newHue: string) => {
      const newConfig = produce(styleConfig, (draft) => {
        draft.visualizations.divergingPalette[key] = newHue;
      });
      updateConfig(newConfig);
    };
  };

  return (
    <>
      <div className={classes.paletteLabel}>Diverging palette</div>
      <ColorPaletteInput
        hues={hues.map((hue) => styleConfig.visualizations.divergingPalette[hue])}
        onHuesChanged={hues.map(getOnHueChanged)}
      />
    </>
  );
}

function GradientPalette({ styleConfig, updateConfig }: Props) {
  const classes = useStyles();

  const hues: (keyof GlobalStyleGradientPaletteConfig)[] = ['hue1', 'hue2'];
  const getOnHueChanged = (key: keyof GlobalStyleGradientPaletteConfig) => {
    return (newHue: string) => {
      const newConfig = produce(styleConfig, (draft) => {
        draft.visualizations.gradientPalette[key] = newHue;
      });
      updateConfig(newConfig);
    };
  };

  return (
    <>
      <div className={classes.paletteLabel}>Gradient palette</div>
      <ColorPaletteInput
        hues={hues.map((hue) => styleConfig.visualizations.gradientPalette[hue])}
        onHuesChanged={hues.map(getOnHueChanged)}
      />
    </>
  );
}
