import { createRef, Component, RefObject } from 'react';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import { Theme, withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { RouteComponentProps } from 'react-router';
import { Layout } from '@explo-tech/react-grid-layout';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { sprinkles, AlertModal } from 'components/ds';
import EditDashboardConfig from 'pages/dashboardPage/editDashboardConfig';
import DashboardLayout from 'components/DashboardLayout/DashboardLayout';
import AddDataPanelModal from 'pages/dashboardPage/addDataPanelModal';
import { ElementConfigPanel } from './elementConfig/ElementConfigPanel';
import { DashboardEditBanner } from 'pages/dashboardPage/dashboardEditBanner';
import DataPanelConfigV2 from 'pages/dashboardPage/DataPanelConfigV2';
import { DashboardDebugger } from 'pages/dashboardPage/DashboardDebugger';
import { DashboardElementShowHidePanel } from './DashboardElementShowHidePanel';
import { ResizableDatasetEditor } from 'pages/dashboardPage/dashboardDatasetEditor/resizableDatasetEditor';

import { VersionInfo } from 'types/exploResource';
import {
  DashboardPageLayoutConfig,
  DashboardParam,
  LayoutExclusionMap,
} from 'types/dashboardVersionConfig';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { DashboardTemplate } from 'actions/dashboardTemplateActions';
import { Dataset, FetchDatasetPreview } from 'actions/datasetActions';
import { ExploreEmailCadence, selectCustomer } from 'actions/teamActions';
import { TableDataset, ParentSchema } from 'actions/dataSourceActions';
import {
  UpdateElementConfigArgs,
  duplicateDashboardItem,
  deleteDataPanelV2,
} from 'actions/dashboardV2Actions';
import { OPERATION_TYPES } from 'constants/types';
import {
  updateVisualizeOperation,
  selectDashboardDataPanelToEdit,
} from 'actions/dataPanelConfigActions';
import {
  DownloadDataPanelSpreadsheet,
  FetchDataPanel,
  FetchDataPanelRowCount,
  FetchSecondaryData,
  UpdateDrilldownDataPanelActionType,
} from 'actions/dataPanelTemplateAction';
import { ReduxState } from 'reducers/rootReducer';
import {
  DashboardElement,
  DashboardVariableMap,
  DASHBOARD_ELEMENT_TYPES,
  DASHBOARD_LAYOUT_CONFIG,
  PAGE_TYPE,
  VIEW_MODE,
} from 'types/dashboardTypes';
import { SIDE_PANE_WIDTH, SIDE_PANE_HEADER_HEIGHT } from 'components/SidePane';
import { INITIAL_DATASET_EDITOR_HEIGHT } from 'pages/dashboardPage/dashboardDatasetEditor/dashboardDatasetEditor';
import { datasetEditorNotShown } from 'constants/dataPanelEditorConstants';
import EmailBuilderTextEditor from 'components/EmailBuilder/EmailBuilderTextEditor';
import { isDashboardDatasetSaved } from 'utils/onboarding';
import {
  DownloadDataPanelPdf,
  DownloadDashboardImage,
  DownloadDashboardPdf,
} from 'actions/exportActions';
import { getSelectedCustomer } from 'reducers/customersReducer';
import { BulkEnqueueFnWithArgs } from 'actions/jobQueueActions';
import { setSyncCategoryColors } from 'actions/dashboardLayoutActions';
import { KPI_VIZ_OPS } from 'constants/dataConstants';
import { trackEvent, EVENTS } from 'analytics/exploAnalytics';
import { FILTER_LINK_ELEMENTS } from 'constants/dashboardConstants';
import { getDashboardLinks } from 'utils/filterLinking';
import { getTimezone } from 'utils/timezoneUtils';
import { DashboardLayoutConfigPanel } from './DashboardLayoutConfigPanel';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: `100vh`,
      flex: 1,
      overflow: 'hidden',
    },
    bodyBellowBanner: {
      display: 'flex',
      height: `calc(100vh - ${SIDE_PANE_HEADER_HEIGHT}px)`,
    },
    mainPageBody: {
      width: `100%`,
      height: '100%',
      position: 'relative',

      '&.leftPaneOpen': {
        width: `calc(100% - ${SIDE_PANE_WIDTH}px)`,
      },
      '&.rightPaneOpen': {
        width: `calc(100% - ${SIDE_PANE_WIDTH}px)`,
      },
      '&.leftPaneOpen.rightPaneOpen': {
        width: `calc(100% - ${SIDE_PANE_WIDTH * 2}px)`,
      },
    },
    dashboardPreview: {
      width: `100%`,
      height: `calc(100% - ${INITIAL_DATASET_EDITOR_HEIGHT}px)`,
    },
    fullSizeDashboard: {
      height: '100% !important',
    },
    pdfDashboard: {
      height: `calc(100% - ${SIDE_PANE_HEADER_HEIGHT}px) !important`,
      paddingLeft: '15%',
      paddingRight: '15%',
      overflowY: 'auto',
    },
    mobileDashboard: {
      height: `100% !important`,
    },
    emailDashboard: {
      height: `calc(100% - ${SIDE_PANE_HEADER_HEIGHT}px) !important`,
      paddingLeft: '15%',
      paddingRight: '15%',
      overflowY: 'auto',
    },
    leftSide: {
      width: SIDE_PANE_WIDTH,
      height: `100%`,
      backgroundColor: theme.palette.ds.white,
      position: 'relative',
    },
    rightSide: {
      width: SIDE_PANE_WIDTH,
      height: `100%`,
      backgroundColor: theme.palette.ds.white,
      zIndex: 2,
    },
    savedDatasetFirstPane: {
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(246, 246, 246, 0.72)',
      top: 0,
      left: 0,
      position: 'absolute',
      color: theme.palette.ds.grey900,
      fontSize: 18,
      fontWeight: 500,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(15),
      textAlign: 'center',
    },
    noCustomer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 20,
    },
  });

type PassedProps = {
  bulkEnqueueJobsWrapper: BulkEnqueueFnWithArgs;
  createDashboardElement: (
    id: string,
    elemType: DASHBOARD_ELEMENT_TYPES,
    newLayout: Layout[],
    containerId?: string,
  ) => void;
  createDataPanel: (
    id: string,
    datasetId: string,
    newLayout: Layout[],
    vizType: OPERATION_TYPES,
    onSuccess: () => void,
    containerId?: string,
    name?: string,
  ) => void;
  dashboard: DashboardTemplate;
  dashboardDatasets: Record<number, Dataset>;
  dashboardElements: DashboardElement[];
  dashboardLayout: Layout[];
  dashboardParams: Record<string, DashboardParam>;
  dashboardVersionInfo: VersionInfo;
  dataPanels: DataPanelTemplate[];
  deleteDashboardElement: (elementId: string, elementType: DASHBOARD_ELEMENT_TYPES) => void;
  downloadDataPanelSpreadsheet: DownloadDataPanelSpreadsheet;
  downloadDataPanelPdf: DownloadDataPanelPdf;
  editableDashboard?: boolean;
  editorDatasets: Record<string, Dataset>;
  emailCadence?: ExploreEmailCadence;
  emailHeader: string | undefined;
  emailFooter: string | undefined;
  fetchDataPanelRowCount: FetchDataPanelRowCount;
  fetchDataPanel: FetchDataPanel;
  fetchDatasetPreview: FetchDatasetPreview;
  downloadDashboardPdf: DownloadDashboardPdf;
  downloadDashboardImage: DownloadDashboardImage;
  fetchSecondaryData: FetchSecondaryData;
  fetchShareData: (
    customerId: number,
    password?: string,
    username?: string,
    isStrictViewingMode?: boolean,
  ) => void;
  globalStylesClassName: string;
  layoutExclusions?: LayoutExclusionMap;
  onEditClicked: () => void;
  onLayoutUpdated: (layout: Layout[]) => void;
  onPreviewClicked: () => void;
  onReturnMostCurrentVersionClicked: () => void;
  pageLayoutConfig?: DashboardPageLayoutConfig;
  parentSchemas: ParentSchema[];
  schemaTablesMap: { [schemaId: number]: { [datasetId: number]: TableDataset } };
  setViewMode: (viewMode: VIEW_MODE) => void;
  supportEmail?: string;
  switchEditModeLoading: boolean;
  updateDrilldownDataPanel: UpdateDrilldownDataPanelActionType;
  updateElementConfig: (args: UpdateElementConfigArgs) => void;
  viewMode: VIEW_MODE;
  width?: number;
};

export enum DATASET_EDITOR_MODE {
  EXPANDED = 'EXPANDED',
  DEFAULT = 'DEFAULT',
  COLLAPSED = 'COLLAPSED',
}

type Props = RouteComponentProps &
  WithStyles<typeof styles> &
  PassedProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

type State = {
  addDataPanelModalOpen: boolean;
  addDataPanelNewLayout?: Layout[];
  addDataPanelContainerId?: string;
  isDeleteConfirmationModalOpen?: boolean;
  newDashboardElementType?: string;
  // neither 'selectedDashboardItem' nor 'layoutConfigSelectedState' can live while the other survives
  // aka both cannot be defined at the same time and so the other should be set to undefined when one is used
  selectedDashboardItem?: {
    elementType: DASHBOARD_ELEMENT_TYPES;
    elementId: string;
  };
  layoutConfigSelectedState?: DASHBOARD_LAYOUT_CONFIG;
  confirmSwitchOnSwitch?: () => void;
  addDataPanelVizType?: OPERATION_TYPES;
  datasetEditorMode: DATASET_EDITOR_MODE;
  leftPaneIsOpen: boolean;
  rightPaneIsOpen: boolean;
  dashboardVars: DashboardVariableMap;
};

class EditDashboardPage extends Component<Props, State> {
  dashboardPreview: RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);

    this.dashboardPreview = createRef();
    this.props.setSyncCategoryColors(this.props.dashboard.sync_category_colors);

    this.state = {
      addDataPanelModalOpen: false,
      datasetEditorMode: DATASET_EDITOR_MODE.DEFAULT,
      leftPaneIsOpen: true,
      rightPaneIsOpen: false,
      dashboardVars: {},
    };
  }

  render() {
    const { classes, editableDashboard, viewMode, width } = this.props;
    const { leftPaneIsOpen, rightPaneIsOpen } = this.state;

    const isFullPageDashboard =
      !editableDashboard ||
      viewMode === VIEW_MODE.PDF ||
      viewMode === VIEW_MODE.EMAIL ||
      viewMode === VIEW_MODE.MOBILE;

    return (
      <div className={classes.root}>
        {this.renderDashboardEditBanner()}
        <div className={classes.bodyBellowBanner}>
          {this.renderDashboardConfig(isFullPageDashboard)}
          <div
            className={cx(classes.mainPageBody, {
              leftPaneOpen: leftPaneIsOpen && editableDashboard,
              rightPaneOpen: rightPaneIsOpen && editableDashboard,
            })}>
            {this.renderDashboardPreview()}
            {width && !isFullPageDashboard && !datasetEditorNotShown(this.getDashboardWidth())
              ? this.renderDatasetEditor()
              : null}
          </div>
          {this.renderDashboardDebugger(isFullPageDashboard)}
        </div>
        {this.renderAddDataPanelModal()}
        {this.renderDeleteElementConfirmationModal()}
      </div>
    );
  }

  renderDashboardEditBanner = () => {
    const {
      emailCadence,
      viewMode,
      setViewMode,
      dashboardVersionInfo,
      onEditClicked,
      onPreviewClicked,
      switchEditModeLoading,
      onReturnMostCurrentVersionClicked,
      allDashboardVersions,
      editableDashboard,
      dashboard,
    } = this.props;
    const { leftPaneIsOpen, rightPaneIsOpen } = this.state;

    return (
      <DashboardEditBanner
        allDashboardVersions={allDashboardVersions}
        dashboardTemplate={dashboard}
        dashboardVersionInfo={dashboardVersionInfo}
        emailCadence={emailCadence}
        inEditMode={editableDashboard}
        leftPaneIsOpen={leftPaneIsOpen}
        onEditClicked={onEditClicked}
        onPreviewClicked={onPreviewClicked}
        onReturnMostCurrentVersionClicked={onReturnMostCurrentVersionClicked}
        rightPaneIsOpen={rightPaneIsOpen}
        setViewMode={setViewMode}
        switchEditModeLoading={switchEditModeLoading}
        toggleLeftPane={() => this.setState({ leftPaneIsOpen: !leftPaneIsOpen })}
        toggleRightPane={() => this.setState({ rightPaneIsOpen: !rightPaneIsOpen })}
        viewMode={viewMode}
      />
    );
  };

  fetchShareData = (password?: string, username?: string, isStrictViewingMode?: boolean) => {
    const { fetchShareData, selectedUserGroup } = this.props;
    if (selectedUserGroup) {
      fetchShareData(selectedUserGroup.id, password, username, isStrictViewingMode);
    }
  };

  renderDashboardPreview = () => {
    const {
      classes,
      dataPanels,
      dashboard,
      onLayoutUpdated,
      fetchDataPanel,
      fetchSecondaryData,
      fetchDataPanelRowCount,
      downloadDashboardPdf,
      downloadDashboardImage,
      editableDashboard,
      downloadDataPanelSpreadsheet,
      downloadDataPanelPdf,
      dashboardElements,
      dashboardDatasets,
      fetchDatasetPreview,
      hoverElementId,
      dashboardLayout,
      viewMode,
      dashboardVersionInfo,
      pageLayoutConfig,
      updateElementConfig,
      updateDrilldownDataPanel,
      width,
      emailFooter,
      emailHeader,
      supportEmail,
      selectedUserGroup,
      team,
      bulkEnqueueJobsWrapper,
      globalStylesClassName,
    } = this.props;
    const {
      layoutConfigSelectedState,
      newDashboardElementType,
      selectedDashboardItem,
      datasetEditorMode,
    } = this.state;

    if (selectedUserGroup === null) {
      return (
        <div
          className={classes.noCustomer}
          style={{
            height:
              datasetEditorMode !== DATASET_EDITOR_MODE.COLLAPSED && editableDashboard
                ? 'calc(100% - 300px)'
                : '100%',
          }}>
          Create a Customer to Populate Dashboard Data
        </div>
      );
    }

    const dashboardLinks =
      selectedDashboardItem && FILTER_LINK_ELEMENTS.has(selectedDashboardItem.elementType)
        ? getDashboardLinks(dashboardDatasets, dashboardElements, selectedDashboardItem.elementId)
        : undefined;

    return (
      <div
        className={cx(
          classes.dashboardPreview,
          {
            [classes.fullSizeDashboard]:
              !editableDashboard || (width && datasetEditorNotShown(this.getDashboardWidth())),
            [classes.pdfDashboard]: viewMode === VIEW_MODE.PDF,
            [classes.emailDashboard]: viewMode === VIEW_MODE.EMAIL,
            [classes.mobileDashboard]: viewMode === VIEW_MODE.MOBILE,
          },
          globalStylesClassName,
        )}
        ref={this.dashboardPreview}>
        {viewMode === VIEW_MODE.EMAIL && (
          <EmailBuilderTextEditor isHeader initialValue={emailHeader} />
        )}
        <DashboardLayout
          bulkEnqueueJobsWrapper={bulkEnqueueJobsWrapper}
          dashboardElements={dashboardElements}
          dashboardLayout={dashboardLayout}
          dashboardLinks={dashboardLinks}
          dataPanels={dataPanels}
          datasets={dashboardDatasets}
          downloadDashboardImage={downloadDashboardImage}
          downloadDashboardPdf={downloadDashboardPdf}
          downloadDataPanelPdf={downloadDataPanelPdf}
          downloadDataPanelSpreadsheet={downloadDataPanelSpreadsheet}
          draggingElementType={newDashboardElementType}
          editableDashboard={editableDashboard}
          exploResource={dashboard}
          fetchDataPanel={fetchDataPanel}
          fetchDataPanelRowCount={fetchDataPanelRowCount}
          fetchDatasetPreview={fetchDatasetPreview}
          fetchSecondaryData={fetchSecondaryData}
          fetchShareData={this.fetchShareData}
          hoverElementId={hoverElementId}
          isViewOnly={false}
          onCloseConfigClicked={this.onCloseConfigClicked}
          onCreateDataPanel={(
            newLayout: Layout[],
            visualizationType?: OPERATION_TYPES,
            containerId?: string,
          ) =>
            this.setState({
              addDataPanelModalOpen: true,
              addDataPanelNewLayout: newLayout,
              addDataPanelVizType: visualizationType,
              addDataPanelContainerId: containerId,
            })
          }
          onCreateNewDashboardElement={this.onCreateNewDashboardElement}
          onDashboardItemSelect={this.onDashboardItemSelect}
          onDashboardLayoutStateSelected={this.onDashboardLayoutStateSelected}
          onDeleteSelectedItem={() =>
            selectedDashboardItem && this.setState({ isDeleteConfirmationModalOpen: true })
          }
          onVariablesChange={(variables) => this.setState({ dashboardVars: variables })}
          pageLayoutConfig={pageLayoutConfig}
          pageType={PAGE_TYPE.EXPLO_APP}
          resourceVersionNumber={dashboardVersionInfo.version_number}
          selectedDashboardItemId={editableDashboard ? selectedDashboardItem?.elementId : undefined}
          selectedLayoutConfig={layoutConfigSelectedState}
          shouldUseJobQueue={team?.feature_flags.use_job_queue}
          supportEmail={supportEmail}
          timezone={getTimezone(dashboard.default_timezone)}
          updateDashboardLayout={onLayoutUpdated}
          updateDrilldownDataPanel={updateDrilldownDataPanel}
          updateElementConfig={updateElementConfig}
          userGroup={selectedUserGroup}
          viewMode={viewMode}
          width={width}
        />
        {viewMode === VIEW_MODE.EMAIL && (
          <EmailBuilderTextEditor initialValue={emailFooter} isHeader={false} />
        )}
      </div>
    );
  };

  onCreateNewDashboardElement = (
    id: string,
    elemType: DASHBOARD_ELEMENT_TYPES,
    newLayout: Layout[],
    containerId?: string,
  ) => {
    this.props.createDashboardElement(id, elemType, newLayout, containerId);
    this.setState({
      selectedDashboardItem: { elementType: elemType, elementId: id },
      newDashboardElementType: undefined,
    });
  };

  renderDashboardConfig = (isFullPageDashboard: boolean) => {
    const {
      classes,
      dashboard,
      dashboardDatasets,
      dashboardElements,
      dataPanels,
      editableDashboard,
      layoutExclusions,
      pageLayoutConfig,
      team,
      viewMode,
    } = this.props;
    const { selectedDashboardItem, layoutConfigSelectedState, leftPaneIsOpen } = this.state;

    if (editableDashboard && isFullPageDashboard) {
      return (
        <div className={classes.leftSide}>
          <DashboardElementShowHidePanel
            dashboardElements={dashboardElements}
            dataPanels={dataPanels}
            layoutExclusions={layoutExclusions}
            viewMode={viewMode}
          />
        </div>
      );
    }

    const leftSideClass = cx(
      classes.leftSide,
      sprinkles({ display: isFullPageDashboard || !leftPaneIsOpen ? 'none' : undefined }),
    );

    if (layoutConfigSelectedState) {
      return (
        <div className={leftSideClass}>
          <DashboardLayoutConfigPanel
            dashboardElements={dashboardElements}
            dashboardId={dashboard.id}
            onCloseConfig={this.onCloseConfigClicked}
            pageLayoutConfig={pageLayoutConfig}
            selectedLayoutConfig={layoutConfigSelectedState}
          />
        </div>
      );
    }

    if (selectedDashboardItem)
      return <div className={leftSideClass}>{this.renderElementConfigPanel()}</div>;

    return (
      <div className={leftSideClass}>
        <EditDashboardConfig
          dashboardId={dashboard.id}
          isStickyHeaderEnabled={team?.feature_flags.enable_dashboard_sticky_header}
          onLayoutEditStateSelected={this.onDashboardLayoutStateSelected}
          onNewDashboardElementDrag={(elementType?: string) =>
            this.setState({ newDashboardElementType: elementType })
          }
          pageLayoutConfig={pageLayoutConfig}
        />
        {!isDashboardDatasetSaved(Object.values(dashboardDatasets)) ? (
          <div className={classes.savedDatasetFirstPane}>Run your first query to get started.</div>
        ) : null}
      </div>
    );
  };

  onDashboardLayoutStateSelected = (layoutState: DASHBOARD_LAYOUT_CONFIG) => {
    this.setState({ selectedDashboardItem: undefined, layoutConfigSelectedState: layoutState });
  };

  renderDashboardDebugger = (isFullPageDashboard: boolean) => {
    const {
      classes,
      dashboardElements,
      dataPanels,
      dashboardDatasets,
      dashboard,
      dashboardParams,
      selectedUserGroup,
    } = this.props;
    const { rightPaneIsOpen, dashboardVars } = this.state;

    return (
      <div
        className={cx(
          classes.rightSide,
          sprinkles({ display: isFullPageDashboard || !rightPaneIsOpen ? 'none' : undefined }),
        )}>
        <DashboardDebugger
          dashboardDatasets={dashboardDatasets}
          dashboardElements={dashboardElements}
          dashboardParams={dashboardParams}
          dashboardTemplateId={dashboard.id}
          dataPanels={dataPanels}
          selectedDataPanel={this.getDataPanelIfSelected()}
          selectedUserGroup={selectedUserGroup}
          timezone={getTimezone(dashboard.default_timezone)}
          variables={dashboardVars}
        />
      </div>
    );
  };

  getDataPanelIfSelected = () => {
    const { selectedDashboardItem } = this.state;

    if (!selectedDashboardItem) return;
    const { elementType, elementId } = selectedDashboardItem;

    if (elementType === DASHBOARD_ELEMENT_TYPES.DATA_PANEL) return this.getDataPanel(elementId);
  };

  renderDatasetEditor = () => {
    const {
      editableDashboard,
      editorDatasets,
      dashboard,
      dashboardElements,
      dataPanels,
      parentSchemas,
      schemaTablesMap,
      selectedUserGroup,
    } = this.props;

    const { selectedDashboardItem, dashboardVars, datasetEditorMode } = this.state;

    return (
      <ResizableDatasetEditor
        dashboardElements={dashboardElements}
        dashboardId={dashboard.id}
        dashboardVars={dashboardVars}
        dataPanels={dataPanels}
        datasetEditorMode={datasetEditorMode}
        editorDatasets={editorDatasets}
        inEditMode={editableDashboard}
        leftPaneIsOpen={this.state.leftPaneIsOpen}
        pageWidth={this.getDashboardWidth()}
        parentSchemas={parentSchemas}
        resizePreview={(newPanelHeight: number) => {
          this.dashboardPreview.current &&
            (this.dashboardPreview.current.style.height = `calc(100% - ${newPanelHeight}px)`);
        }}
        schemaTablesMap={schemaTablesMap}
        selectedDashboardItem={selectedDashboardItem}
        selectedUserGroupId={selectedUserGroup?.id}
        setDatasetEditorMode={(mode, newHeight?: number) => {
          this.setState({ datasetEditorMode: mode });
          if (this.dashboardPreview.current) {
            switch (mode) {
              case DATASET_EDITOR_MODE.DEFAULT:
              case DATASET_EDITOR_MODE.COLLAPSED:
                if (newHeight) {
                  this.dashboardPreview.current.style.height = `calc(100% - ${newHeight}px)`;
                }
                break;
              case DATASET_EDITOR_MODE.EXPANDED:
                this.onCloseConfigClicked();
                break;
            }
          }
        }}
      />
    );
  };

  getDashboardWidth = () => {
    const { leftPaneIsOpen, rightPaneIsOpen } = this.state;
    const { width } = this.props;

    const leftPanelAddition = leftPaneIsOpen ? 0 : SIDE_PANE_WIDTH;
    const rightPanelAddition = rightPaneIsOpen ? -SIDE_PANE_WIDTH : 0;

    return (width || 0) + leftPanelAddition + rightPanelAddition;
  };

  renderElementConfigPanel = () => {
    const {
      dataPanels,
      dashboardElements,
      editorDatasets,
      duplicateDashboardItem,
      dashboard,
      dashboardDatasets,
      dashboardParams,
    } = this.props;
    const { selectedDashboardItem } = this.state;
    if (!selectedDashboardItem) return;
    const { elementType, elementId } = selectedDashboardItem;

    if (elementType === DASHBOARD_ELEMENT_TYPES.DATA_PANEL) {
      const dataPanel = this.getDataPanel(elementId);

      if (!dataPanel) return;
      const dataset = editorDatasets[dataPanel.table_id];

      return (
        <DataPanelConfigV2
          baseSchema={dataset?.schema || []}
          dashboardDatasets={dashboardDatasets}
          dashboardElements={dashboardElements}
          dashboardParams={dashboardParams}
          dataPanelTemplate={dataPanel}
          dataPanels={dataPanels}
          dataset={dataset}
          duplicateDataPanel={(dataPanelTemplate) => {
            duplicateDashboardItem({
              dashboardItem: dataPanelTemplate,
              itemType: DASHBOARD_ELEMENT_TYPES.DATA_PANEL,
              dashId: dashboard.id,
            });
          }}
          onCloseConfigClicked={this.onCloseConfigClicked}
          onDeleteDataPanel={() => this.setState({ isDeleteConfirmationModalOpen: true })}
        />
      );
    } else {
      const dashboardElement = this.getDashboardElement(elementId);
      if (!dashboardElement) return;

      return (
        <ElementConfigPanel
          allElements={dashboardElements}
          dashboardId={dashboard.id}
          dashboardShareLinkTitle={dashboard.share_link_title}
          dataPanels={dataPanels}
          datasets={editorDatasets}
          element={dashboardElement}
          onDeleteElement={() => this.setState({ isDeleteConfirmationModalOpen: true })}
        />
      );
    }
  };

  renderAddDataPanelModal = () => {
    const { createDataPanel, dashboard } = this.props;
    const {
      addDataPanelModalOpen,
      addDataPanelNewLayout,
      addDataPanelVizType,
      addDataPanelContainerId,
    } = this.state;

    if (!addDataPanelModalOpen || !addDataPanelVizType) return;

    return (
      <AddDataPanelModal
        closeModal={() => this.setState({ addDataPanelModalOpen: false })}
        dashboardTemplateId={dashboard.id}
        modalOpen={addDataPanelModalOpen}
        onCreate={(datasetId: string, name?: string) => {
          if (!addDataPanelNewLayout) return;

          // we want the title to be undefined for KPIs so that we can make the title
          // dynamically defaul to the aggregation selected by the user
          if (KPI_VIZ_OPS.has(addDataPanelVizType)) {
            name = undefined;
          }

          const id = `dash${dashboard.id}-${uuidv4()}`;
          createDataPanel(
            id,
            datasetId,
            addDataPanelNewLayout,
            addDataPanelVizType,
            () =>
              this.setState({
                addDataPanelModalOpen: false,
                addDataPanelNewLayout: undefined,
                addDataPanelContainerId: undefined,
                selectedDashboardItem: {
                  elementType: DASHBOARD_ELEMENT_TYPES.DATA_PANEL,
                  elementId: id,
                },
              }),
            addDataPanelContainerId,
            name,
          );
        }}
      />
    );
  };

  onDashboardItemSelect = (type: DASHBOARD_ELEMENT_TYPES, id: string) => {
    const { selectDashboardDataPanelToEdit } = this.props;
    const { selectedDashboardItem } = this.state;
    if (
      selectedDashboardItem &&
      selectedDashboardItem.elementId === id &&
      selectedDashboardItem.elementType === type
    )
      return;

    if (type === DASHBOARD_ELEMENT_TYPES.DATA_PANEL) {
      const dataPanel = this.getDataPanel(id);
      if (dataPanel) {
        selectDashboardDataPanelToEdit(dataPanel);
        this.setState({
          selectedDashboardItem: { elementType: type, elementId: id },
          layoutConfigSelectedState: undefined,
        });
      }
    } else {
      const element = this.getDashboardElement(id);
      if (element) {
        this.setState({
          selectedDashboardItem: { elementType: type, elementId: id },
          layoutConfigSelectedState: undefined,
        });
      }
    }
  };

  onCloseConfigClicked = () => {
    const { layoutConfigSelectedState, selectedDashboardItem } = this.state;
    if (selectedDashboardItem) this.setState({ selectedDashboardItem: undefined });
    if (layoutConfigSelectedState) this.setState({ layoutConfigSelectedState: undefined });
  };

  getDataPanel = (id: string): DataPanelTemplate | undefined => {
    return this.props.dataPanels.find((panel) => panel.id === id);
  };

  getDashboardElement = (id: string): DashboardElement | undefined => {
    return this.props.dashboardElements.find((elem) => elem.id === id);
  };

  renderDeleteElementConfirmationModal = () => {
    const { deleteDataPanelV2, deleteDashboardElement } = this.props;
    const { isDeleteConfirmationModalOpen, selectedDashboardItem } = this.state;
    if (!isDeleteConfirmationModalOpen || !selectedDashboardItem) return;

    const isDataPanel = selectedDashboardItem?.elementType === DASHBOARD_ELEMENT_TYPES.DATA_PANEL;
    const modalTitle = `Are you sure you want to delete this ${
      isDataPanel ? 'data panel?' : 'element?'
    }`;

    const onDelete = () => {
      if (!selectedDashboardItem) return;

      if (isDataPanel) {
        deleteDataPanelV2({ id: selectedDashboardItem.elementId });
        trackEvent(EVENTS.DELETED_DATA_PANEL, {
          data_panel_template_id: selectedDashboardItem.elementId,
        });
      } else {
        deleteDashboardElement(
          selectedDashboardItem.elementId,
          selectedDashboardItem.elementType as DASHBOARD_ELEMENT_TYPES,
        );
      }

      this.setState({ isDeleteConfirmationModalOpen: false, selectedDashboardItem: undefined });
    };
    return (
      <AlertModal
        actionButtonProps={{ onClick: onDelete }}
        isOpen={isDeleteConfirmationModalOpen}
        onClose={() => this.setState({ isDeleteConfirmationModalOpen: false })}
        title={modalTitle}
      />
    );
  };
}

const mapStateToProps = (state: ReduxState) => ({
  allDashboardVersions: state.dashboardVersions.versions,
  team: state.currentUser.team,
  selectedUserGroup: getSelectedCustomer(state.customers),
  hoverElementId: state.cssLinks.hoverElementId,
});

const mapDispatchToProps = {
  updateVisualizeOperation,
  deleteDataPanelV2,
  duplicateDashboardItem,
  selectDashboardDataPanelToEdit,
  selectCustomer,
  setSyncCategoryColors,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStyles(styles)(EditDashboardPage)));
