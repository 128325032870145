import { makeStyles } from '@material-ui/core/styles';
import Button from 'shared/Button';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  errorStateContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

type Props = {
  text: string;
};

const ErrorState = ({ text }: Props) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.errorStateContainer}>
      <p>{text}</p>
      <Button
        minimal
        onClick={() => history.push('/home')}
        text="Click here to go back to the homepage."
      />
    </div>
  );
};

export default ErrorState;
