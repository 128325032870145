import { FC, useCallback, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';

import { Button, DataGrid, Icon, sprinkles } from 'components/ds';

import { DATASET_VIEWS } from './dashboardDatasetEditor';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { DatasetColumn } from 'types/datasets';
import { Dataset, FetchEditorDatasetPreviewBody } from 'actions/datasetActions';
import { FetchDashboardDatasetPreviewData } from 'actions/responseTypes';
import { DatasetEditorNonIdealState } from './DatasetEditorNonIdealState';
import { PaginatorProps } from 'components/ds/DataGrid/paginator';
import { ReduxState } from 'reducers/rootReducer';
import { getSelectedCustomer } from 'reducers/customersReducer';
import { isPagingDisabled } from 'components/ds/DataGrid/utils';
import { getSortedDrilldownColumns } from 'utils/drilldownDatasetUtils';
import { setSelectedDrilldownColumn } from 'actions/cssLinkActions';

type Props = {
  dashboardId: number;
  dashboardVars: DashboardVariableMap;
  datasets: Record<string, Dataset>;
  datasetQueries: { [datasetId: string]: string };
  enableFormat?: boolean;
  selectedDatasetId?: string;
  fetchDataset: (
    dataPostData?: FetchEditorDatasetPreviewBody,
    rowCountPostData?: FetchEditorDatasetPreviewBody,
    onSuccess?: (data: FetchDashboardDatasetPreviewData) => void,
  ) => void;
  getTablePreview: () => void;
  openTab: (id: string) => void;
};

/**
 * Component for managing the data preview and format tables for the dataset editor
 *
 * @param {number} dashboardId id for the dashboard API call to change page
 * @param {DashboardVariableMap} dashboardVars Variables for the dashboard API call to change page
 * @param {Record<string, Dataset>} datasets All datasets so we can get the selected dataset by id
 * @param {Record} datasetQueries All dataset queries so we can get the selected query by id
 * @param {boolean} enableFormat Whether or not to enable the format tab options
 * @param {string} selectedDatasetId id of the selected dataset, can be undefined
 * @param {Function} fetchDataset Fetches the dataset from API when changing the page
 * @param {Function} getTablePreview Fetches table preview data from API
 * @param {Function} openTab Switches tabs for the dataset editor
 */
export const DatasetPreview: FC<Props> = ({
  dashboardId,
  dashboardVars,
  datasetQueries,
  datasets,
  enableFormat,
  fetchDataset,
  getTablePreview,
  openTab,
  selectedDatasetId,
}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const dispatch = useDispatch();

  const selectedDataset = selectedDatasetId ? datasets[selectedDatasetId] : null;
  const datasetQuery = selectedDatasetId ? datasetQueries[selectedDatasetId] : null;

  const { selectedUserGroupId, selectedDrilldownColumn } = useSelector(
    (state: ReduxState) => ({
      selectedUserGroupId: getSelectedCustomer(state.customers)?.id,
      selectedDrilldownColumn: state.cssLinks.selectedDrilldownColumn,
    }),
    shallowEqual,
  );

  const handlePageChange = useCallback(
    (pageNumber: number, offset: number) => {
      if (!selectedDataset || !datasetQuery) return;

      const postData = {
        dataset_id: selectedDataset.id,
        parent_schema_id: selectedDataset.parent_schema_id,
        query: datasetQuery,
        variables: { ...dashboardVars },
        customer_id: selectedUserGroupId,
        resource_id: dashboardId,
        timezone: DateTime.local().zoneName,
      };

      fetchDataset({ ...postData, offset }, postData);

      setPageNumber(pageNumber);
    },
    [selectedDataset, datasetQuery, dashboardId, dashboardVars, selectedUserGroupId, fetchDataset],
  );

  const paginatorProps: PaginatorProps | undefined = useMemo(() => {
    if (!selectedDataset) return;

    return {
      totalRowCount: selectedDataset._total_row_count,
      currentPage: pageNumber,
      isPagingDisabled: isPagingDisabled(selectedDataset?._unsupported_operations),
      loading: !!selectedDataset._loading,
      goToPage: ({ page, offset }) => handlePageChange(page, offset),
    };
  }, [selectedDataset, pageNumber, handlePageChange]);

  const renderQueryRedirectText = (text: string) => {
    return (
      <span
        className={sprinkles({ color: 'active', cursor: 'pointer' })}
        onClick={() => openTab(DATASET_VIEWS.QUERY.id)}>
        {text}
      </span>
    );
  };

  if (!selectedDataset) {
    return <DataGrid loading />;
  }

  if (selectedDataset._error) {
    return (
      <DatasetEditorNonIdealState
        action={renderQueryRedirectText('Revise your query')}
        description={selectedDataset._error}
        icon={
          <Icon className={sprinkles({ color: 'error' })} name="circle-exclamation" size="lg" />
        }
        title="There was a problem with your query"
      />
    );
  }

  if (!selectedDataset.query && !datasetQuery) {
    return (
      <DatasetEditorNonIdealState
        action={renderQueryRedirectText('Write a query')}
        description="The resulting table will show up here once you write some SQL"
        icon={<Icon name="rectangle-terminal" size="lg" />}
        title="No query to preview"
      />
    );
  }

  if (!selectedDataset._rows) {
    if (selectedDataset._loading) {
      return <DataGrid loading />;
    } else {
      // they have not run the preview for the query
      return (
        <DatasetEditorNonIdealState
          action={
            <Button
              disabled={!datasetQuery || datasetQuery.length === 0}
              onClick={getTablePreview}
              type="secondary">
              Preview
            </Button>
          }
          description="This will run your query but not save it to the dataset."
          icon={<Icon name="eye-open" size="lg" />}
          title="Click the preview button"
        />
      );
    }
  }

  const schema = enableFormat
    ? getSortedDrilldownColumns(selectedDataset.schema || [], selectedDataset)
    : selectedDataset._schema || selectedDataset.schema || [];

  const columnConfigs = enableFormat ? selectedDataset.drilldownColumnConfigs : undefined;

  const onColumnSelect = (column?: DatasetColumn) => {
    if (!enableFormat) return;
    dispatch(
      setSelectedDrilldownColumn(
        column && column.name !== selectedDrilldownColumn?.name
          ? { name: column.name, type: column.type }
          : undefined,
      ),
    );
  };

  return (
    <DataGrid
      columnConfigs={columnConfigs}
      columnSelectionEnabled={enableFormat}
      loading={selectedDataset._loading}
      onColumnSelect={onColumnSelect}
      paginatorProps={paginatorProps}
      rows={selectedDataset._rows}
      schema={schema}
      selectedColumn={selectedDrilldownColumn?.name}
    />
  );
};
