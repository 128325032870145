import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as RD from 'remotedata';

import { Button, Icon, IconButton, sprinkles, Switch } from 'components/ds';
import { BaseModal } from 'pages/ReportBuilder/ModalViews/BaseModal';
import InputLabel from 'shared/InputLabel';
import { CustomerSelect } from 'components/CustomerSelect';

import { Customer } from 'actions/teamActions';
import { getSelectedBuiltIn, updateBuiltInReportSettings } from 'reducers/reportBuilderEditReducer';
import { ReduxState } from 'reducers/rootReducer';
import { closeReportModal } from 'reportBuilderContent/reducers/reportEditingReducer';

import * as styles from 'pages/ReportBuilder/ModalViews/BaseBuiltInModal.css';

const MAX_DESCRIPTION_LENGTH = 300;

export const EditBuiltInModal: FC = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [allCustomers, setAllCustomers] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | undefined>();
  const [customerIds, setCustomerIds] = useState<number[]>([]);

  const selectedBuiltIn = useSelector((state: ReduxState) =>
    getSelectedBuiltIn(state.reportBuilderEdit),
  );

  useEffect(() => {
    if (!selectedBuiltIn) return;
    setName(selectedBuiltIn.name);
    setDescription(selectedBuiltIn.description);
    setAllCustomers(!selectedBuiltIn.permissions || selectedBuiltIn.permissions.allCustomers);
    setCustomerIds(selectedBuiltIn.permissions?.customerIds ?? []);
  }, [selectedBuiltIn]);

  const descriptionCharsRemaining = MAX_DESCRIPTION_LENGTH - description.length;
  const isDescriptionTooLong = descriptionCharsRemaining < 0;

  const handleClose = () => dispatch(closeReportModal());

  const handleSubmit = useCallback(() => {
    if (isDescriptionTooLong || !selectedBuiltIn) return;

    dispatch(closeReportModal());
    dispatch(
      updateBuiltInReportSettings({
        builtInId: selectedBuiltIn.id,
        name,
        description,
        permissions: {
          allCustomers,
          customerIds,
        },
      }),
    );
  }, [
    isDescriptionTooLong,
    selectedBuiltIn,
    dispatch,
    name,
    description,
    allCustomers,
    customerIds,
  ]);

  const customers = useSelector((state: ReduxState) => state.customers.groups);

  const selectedCustomers = useMemo(() => {
    const customerIdSet = new Set(customerIds);
    if (!RD.isSuccess(customers)) return [];
    return customers.data.filter((customer) => customerIdSet.has(customer.id));
  }, [customers, customerIds]);

  const handleAddCustomer = () => {
    if (!selectedCustomer) return;
    setCustomerIds((prev) => {
      if (prev.find((customerId) => customerId === selectedCustomer.id)) return prev;
      return [...prev, selectedCustomer.id];
    });
    setAllCustomers(false);
    setSelectedCustomer(undefined);
  };

  if (!selectedBuiltIn) return null;

  const descriptionLabel = isDescriptionTooLong
    ? `Description must be less than 300 characters. Current number of characters ${description.length}`
    : `${descriptionCharsRemaining} characters remaining`;

  return (
    <BaseModal>
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <div className={sprinkles({ heading: 'h1' })}>{selectedBuiltIn.name} Settings</div>
          <IconButton
            className={sprinkles({ cursor: 'pointer' })}
            name="cross"
            onClick={handleClose}
          />
        </div>
        <div className={styles.modalContainer}>
          <div className={sprinkles({ heading: 'h3' })}>General</div>
          <div>
            <InputLabel text="Name" />
            <input
              className={styles.reportNameInput}
              onChange={(e) => setName(e.target.value)}
              placeholder="My Built In"
              value={name}
            />
          </div>
          <div>
            <InputLabel text="Description" />
            <textarea
              className={styles.textarea}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="This built in contains the top 10 most popular products by revenue"
              rows={6}
              value={description}
            />
            <InputLabel error={isDescriptionTooLong} text={descriptionLabel} />
          </div>
          <div className={sprinkles({ heading: 'h3' })}>Report Permissions</div>
          <div>
            <Switch
              className={styles.allCustomersSwitch}
              label="Visible to all customers"
              onChange={setAllCustomers}
              switchOn={allCustomers}
            />
          </div>
          <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1', width: 'fill' })}>
            <div className={sprinkles({ flex: 1 })}>
              <CustomerSelect
                customer={selectedCustomer}
                customersToFilter={customerIds}
                onChange={setSelectedCustomer}
              />
            </div>
            <Button disabled={!selectedCustomer} onClick={handleAddCustomer} type="primary">
              Add
            </Button>
          </div>
          <div
            className={styles.customerList}
            style={{ minHeight: allCustomers || selectedCustomers.length === 0 ? 24 : 200 }}>
            {allCustomers ? (
              <div className={styles.emptyCustomerText}>Visible to all customers</div>
            ) : selectedCustomers.length === 0 ? (
              <div className={styles.emptyCustomerText}>Not visible to any customers</div>
            ) : (
              selectedCustomers.map((customer) => (
                <div className={styles.customerListItem} key={customer.id}>
                  <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp.5', body: 'b2' })}>
                    <Icon name="user" size="sm" /> {customer.name}{' '}
                    <span className={sprinkles({ color: 'contentSecondary' })}>
                      ({customer.id})
                    </span>
                  </div>
                  <IconButton
                    name="cross"
                    onClick={() =>
                      setCustomerIds((prev) =>
                        prev.filter((customerId) => customerId !== customer.id),
                      )
                    }
                    type="secondary"
                  />
                </div>
              ))
            )}
          </div>
        </div>
        <div className={styles.footerContainer}>
          <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
            <Button onClick={handleClose} type="tertiary">
              Cancel
            </Button>
            <Button disabled={!name || isDescriptionTooLong} onClick={handleSubmit} type="primary">
              Save
            </Button>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};
