import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import sqlFormatter from 'sql-formatter';

import { SchemaViewer } from 'components/resource/SchemaViewer';
import SqlEditor from 'components/SqlEditor';
import { ConfigSection } from '../ConfigSection';

import { ReduxState } from 'reducers/rootReducer';
import { saveDraftDatasetQuery } from 'actions/datasetActions';
import { CanvasDataset } from 'actions/canvasConfigActions';
import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';

import * as styles from './index.css';
import * as sharedStyles from '../index.css';

type Props = {
  dataset: CanvasDataset | ReportBuilderDataset;
  isReportBuilder?: boolean;

  onSelectSchema: (schema: { id: number; name: string }) => void;
  getPreview: () => void;
  saveQuery: () => void;
};

export const QuerySection: FC<Props> = ({
  dataset,
  getPreview,
  saveQuery,
  isReportBuilder,
  onSelectSchema,
}) => {
  const dispatch = useDispatch();

  const schemaTablesMap = useSelector((state: ReduxState) => state.parentSchemas.schemaTablesMap);

  const [currentQuery, setCurrentQuery] = useState(dataset.queryDraft ?? dataset.query);
  const [currDatasetId, setCurrDatasetId] = useState(dataset.id);

  useEffect(() => {
    // Update currentQuery when dataset is switched
    if (dataset.id === currDatasetId) return;
    setCurrDatasetId(dataset.id);
    setCurrentQuery(dataset.queryDraft ?? dataset.query);
  }, [dataset, currDatasetId]);

  const parentSchemaId = dataset.parent_schema_id;

  const { tableNames, columnNames } = useMemo(() => {
    const columnNames = new Set<string>();
    const tableNames = new Set<string>();
    Object.values(schemaTablesMap[parentSchemaId])?.forEach((table) => {
      tableNames.add(table.table_name);
      if (table.schema) {
        table.schema.forEach((column) => {
          columnNames.add(table.table_name + '.' + column.name);
        });
      }
    });
    return { tableNames: Array.from(tableNames), columnNames: Array.from(columnNames) };
  }, [schemaTablesMap, parentSchemaId]);

  const formatSqlQuery = () => {
    const newQuery = sqlFormatter.format(currentQuery || '', { indent: '    ' });
    setCurrentQuery(newQuery);
    dispatch(saveDraftDatasetQuery({ queryDraft: newQuery, dataset_id: dataset.id }));
  };

  const onRevertDraft = () => {
    dispatch(saveDraftDatasetQuery({ queryDraft: undefined, dataset_id: dataset.id }));
    setCurrentQuery(dataset.query);
  };

  const hasChanges = currentQuery !== dataset.query;

  return (
    <ConfigSection defaultIsOpen title="Write your query">
      <div className={sharedStyles.configSection}>
        {isReportBuilder ? null : (
          <p className={sharedStyles.sectionText}>
            With this dataset you can expose columns to users and populate filters.
          </p>
        )}

        <div className={styles.editorWrapper}>
          <SqlEditor
            columnNames={columnNames}
            onChange={setCurrentQuery}
            onChangeDraft={(newQuery) => {
              dispatch(saveDraftDatasetQuery({ queryDraft: newQuery, dataset_id: dataset.id }));
            }}
            query={currentQuery}
            tableNames={tableNames}
          />
        </div>

        <SchemaViewer
          datasetId={dataset.id}
          onFormat={formatSqlQuery}
          onPreview={currentQuery.trim() ? getPreview : undefined}
          onRevertDraft={onRevertDraft}
          onSave={hasChanges ? saveQuery : getPreview}
          onSelectSchema={onSelectSchema}
          saveText={dataset.queryDraft === dataset?.query ? 'Run' : 'Save & Run'}
          schemaTablesMap={schemaTablesMap}
          selectedDatasetSchemaId={dataset.parent_schema_id}
        />
      </div>
    </ConfigSection>
  );
};
