import { ReactElement, useState, useContext, useRef, useEffect, memo } from 'react';
import { uniqueId } from 'utils/standard';
import cx from 'classnames';
import Color from 'color';
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import {
  Cell,
  Column,
  ColumnHeaderCell,
  SelectionModes,
  Table,
  RenderMode,
  IRegion,
} from '@blueprintjs/table';
import { IProps, IconName, Icon } from '@blueprintjs/core';
import { Regions } from '@blueprintjs/table';
import ResizeObserver from 'react-resize-observer';

import ColumnHeaderText from 'components/dataTable/columnHeaderText';

import { DatasetSchema, DatasetColumn, DatasetRow } from 'types/datasets';
import {
  BooleanDisplayOptions,
  DateDisplayOptions,
  GradientType,
  ImageShapeFormat,
  NumberDisplayDisplayType,
  NumberDisplayOptions,
  SchemaDisplayOptions,
  SortOrder,
  StringDisplayFormat,
  StringDisplayOptions,
  SchemaChange,
  StringFormat,
} from 'constants/types';
import { BOOLEAN, DATE_TYPES, NUMBER_TYPES, STRING } from 'constants/dataConstants';
import { DEFAULT_CATEGORY_COLORS } from 'constants/colorConstants';
import { SortInfo } from 'types/dataPanelTemplate';
import { GlobalStylesContext, GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { GlobalStyleConfig } from 'globalStyles/types';
import { getGradientColor } from 'utils/dashboardUtils';
import { DashboardVariable, DashboardVariableMap, MetricsByColumn } from 'types/dashboardTypes';
import { mixColors } from 'utils/general';
import { formatNumberValue } from 'pages/dashboardPage/charts/utils';
import { convertHexToRGBA } from 'utils/graphUtils';
import { resolveTooltipVariables } from 'utils/variableUtils';
import { TableProgressBar } from 'components/TableProgressBar';
import {
  defaultFormatCellData,
  getColumnWidths,
  getDefaultColumnWidth,
  getCellAlignment,
} from './utils';
import { ResourceDataset } from 'types/exploResource';
import useBaseDataTableStyles, { TABLE_ROW_HEIGHT } from 'styles/useBaseDataTableStyles';
import {
  getCurrentBooleanIcons,
  getCurrentStringFormat,
  getLinkInfo,
} from 'utils/formatConfigUtils';
import { formatDateField } from 'pages/dashboardPage/charts/utils';
import { isJoinConfigReady } from 'utils/variableUtils';

const GENERIC_TABLE_HEIGHT = 277;
const TABLE_CORNER_DIMENSIONS = 30;

const useStyles = makeStyles((theme: Theme) => ({
  tableHeight: {
    height: GENERIC_TABLE_HEIGHT,
  },
  noBorderRadius: {
    borderRadius: 0,
  },
  tableColumnHeaderText: {
    display: 'flex',
    alignItems: 'center',
  },
  tableColumnHeaderTypeIcon: {
    marginRight: theme.spacing(2),
  },
  grayedColumn: {
    opacity: 0.3,
  },
  table: {
    flex: 1,
  },
  tableCell: (styleConfigAndProps: GlobalStyleConfig & Props) => ({
    cursor: 'default !important',
    '&.drilldownCell': {
      cursor: 'pointer !important',
      '&:hover': {
        backgroundColor:
          styleConfigAndProps.base.actionColor.interactionStateColor ||
          styleConfigAndProps.base.actionColor.default,
      },
    },

    '&.selectedCell': {
      cursor: 'pointer !important',
      backgroundColor: convertHexToRGBA(
        styleConfigAndProps.base.actionColor.interactionStateColor ||
          styleConfigAndProps.base.actionColor.default,
        0.15,
      ),
    },
  }),
  columnHeaderCell: (styleConfigAndProps: GlobalStyleConfig & Props) => ({
    height: styleConfigAndProps.rowHeight || TABLE_ROW_HEIGHT,

    '&:hover .columnSortIcon': {
      visibility: 'initial',
    },
  }),
  columnHeaderCellWithTooltip: {
    '& .bp3-table-column-name-text': {
      pointerEvents: 'auto',
    },
  },
  categoryCellData: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: 16,
    display: 'inline-block',
  },
  cellImageContainer: {
    height: '100%',
    width: '100%',
    padding: 5,
  },
  cellImageDisplay: {
    height: '100%',

    '&.circle': {
      clipPath: 'circle()',
    },
  },
}));

type Props = {
  className?: string;
  schema: DatasetSchema;
  loading?: boolean;
  isSortable: boolean;
  maxRows: number;
  rows: DatasetRow[];
  disableRowHeader?: boolean;
  fill?: boolean;
  ignoreInvalidDates?: boolean;
  noBorderRadius?: boolean;
  truncateEmptyRowSpace?: boolean;
  unrestrictedHeight?: boolean;
  useFriendlyNameForHeader?: boolean;
  shouldTruncateText?: boolean;
  sortInfo?: SortInfo;
  metricsByColumn?: MetricsByColumn;
  onColumnSelect?: (colIndex: number, schema: DatasetSchema) => void;
  enableColumnResizing?: boolean;
  columnWidths?: Array<number | null | undefined>;
  onColumnWidthChanged?: (index: number, size: number) => void;
  schemaDisplayOptions?: SchemaDisplayOptions;
  rowHeight?: number;
  rowLinesDisabled?: boolean;
  columnLinesEnabled?: boolean;
  isColumnHeadersBolded?: boolean;
  isFirstColumnBolded?: boolean;
  isFirstColumnFrozen?: boolean;
  isFirstRowBolded?: boolean;
  firstRowBackgroundColor?: string;
  shouldVisuallyGroupByFirstColumn?: boolean;
  drilldownCol?: string;
  datasets?: Record<string, ResourceDataset>;
  setVariable?: (value: DashboardVariable) => void;
  drilldownVar?: DashboardVariable;
  isDashboardTable?: boolean;
  isPDFTable?: boolean;
  changeSchemaList?: SchemaChange[];
  firstColumnTitle?: string;
  dateFormat?: string;
  stringFormat?: StringFormat;
  isExpandedDrilldownFormatting?: boolean;
  selectedColumnIndex?: number;
  onColumnUnselect?: () => void;
  variables?: DashboardVariableMap;
};

const BaseDataTable = memo(function BaseDataTable(props: Props) {
  const {
    schema,
    rows,
    ignoreInvalidDates,
    sortInfo,
    isSortable,
    onColumnWidthChanged,
    shouldTruncateText,
    schemaDisplayOptions,
    metricsByColumn,
    columnLinesEnabled,
    firstRowBackgroundColor,
    datasets,
    drilldownCol,
    setVariable,
    drilldownVar,
    isDashboardTable,
    isPDFTable,
    changeSchemaList,
    firstColumnTitle,
    dateFormat,
    stringFormat,
    isExpandedDrilldownFormatting,
    selectedColumnIndex,
    variables,
  } = props;

  const context = useContext(GlobalStylesContext);
  const classes = useStyles({ ...context.globalStyleConfig, ...props });
  const sharedClasses = useBaseDataTableStyles({ ...context.globalStyleConfig });
  const theme: Theme = useTheme();
  const tableInstance = useRef<Table>(null);
  const [tableWidth, setTableWidth] = useState(-1);
  const [selectedRow, setSelectedRow] = useState<number | undefined>(undefined);
  const [columnToCategoryToColorMap, setColumnToCategoryToColorMap] = useState<
    Record<string, Record<string | number, string>>
  >({});

  const getSchemaChange = (index: number) =>
    changeSchemaList?.find((schemaChange) => schemaChange.col === schema[index].name);

  const addCategoryToColorMap = (
    columnName: string,
    category: string | number,
    assignedColor?: string,
  ) => {
    const numKeys = Object.keys(columnToCategoryToColorMap[columnName] || {}).length;
    const color = assignedColor || DEFAULT_CATEGORY_COLORS[numKeys % 12];
    setColumnToCategoryToColorMap((current) => {
      if (!current[columnName]) current[columnName] = {};
      current[columnName][category] = color;
      return current;
    });

    return color;
  };

  useEffect(() => {
    // after the table renders, resize it for any text that needs to wrap
    !shouldTruncateText && resizeRowHeightToFitContent();
  });

  const joinMapping: Record<string, Record<string, Record<string | number, string | number>>> = {};

  const resizeRowHeightToFitContent = () => {
    tableInstance.current &&
      tableInstance.current.resizeRowsByApproximateHeight((rowIndex: number, colIndex: number) =>
        String(getCellData(rowIndex, colIndex)),
      );
  };

  const getVisualSortingValues = (rowIndex: number, colIndex: number, rows: DatasetRow[]) => {
    let isGroupedRow = false;
    let shouldHideText = false;

    if (props.shouldVisuallyGroupByFirstColumn) {
      const firstColCellData = getCellData(rowIndex, 0);

      if (rowIndex < rows.length - 1) {
        const firstColNextCellData = getCellData(rowIndex + 1, 0);

        isGroupedRow = firstColCellData === firstColNextCellData;
      }

      if (rowIndex > 0 && colIndex === 0) {
        const firstColPrevCellData = getCellData(rowIndex - 1, 0);

        shouldHideText = firstColCellData === firstColPrevCellData;
      }
    }

    return [isGroupedRow, shouldHideText];
  };

  const getCellData = (rowIndex: number, colIndex: number) => {
    const header = schema[colIndex].name;
    const cellData = rows[rowIndex][header];
    return cellData === undefined ? '' : cellData;
  };

  const cellRenderer = (rowIndex: number, colIndex: number) => {
    const cellData = getCellData(rowIndex, colIndex);

    const [isGroupedRow, shouldHideText] = getVisualSortingValues(rowIndex, colIndex, rows);

    const column = schema[colIndex];
    let backgroundColor, color;
    const displayOptions = schemaDisplayOptions?.[column.name];

    if (firstRowBackgroundColor && rowIndex === 0) backgroundColor = firstRowBackgroundColor;

    if (
      metricsByColumn?.[column.name] &&
      displayOptions &&
      (displayOptions as NumberDisplayOptions).gradientType
    ) {
      const value = Number(cellData);
      if (cellData !== null && !isNaN(value)) {
        const { gradient, gradientType, gradientOptions, displayType } =
          displayOptions as NumberDisplayOptions;
        backgroundColor =
          displayType !== NumberDisplayDisplayType.PROGRESS_BAR
            ? getGradientColor({
                value,
                gradient,
                gradientType,
                gradientOptions,
                metrics: metricsByColumn[column.name],
              })
            : undefined;
        color = new Color(backgroundColor).isDark()
          ? theme.palette.ds.white
          : theme.palette.ds.black;
      }
    }

    const stringFormat =
      column.type === STRING
        ? getCurrentStringFormat(displayOptions as StringDisplayOptions)
        : undefined;

    const isBold =
      (props.isFirstColumnBolded && colIndex === 0) || (props.isFirstRowBolded && rowIndex === 0);
    const selectedCell = selectedRow === rowIndex;
    const unselectedCell = selectedRow !== undefined && selectedRow >= 0 && !selectedCell;

    const showFirstColumnLine = props.isFirstColumnFrozen && colIndex === 0;

    return (
      <Cell
        className={cx(
          sharedClasses.tableCell,
          classes.tableCell,
          GLOBAL_STYLE_CLASSNAMES.text.body.primary,
          getCellAlignment(displayOptions, column.type),
          {
            firstCell: colIndex === 0,
            imageCell: stringFormat === StringDisplayFormat.IMAGE,
            drilldownCell: drilldownCol && !selectedCell,
            selectedCell,
            [GLOBAL_STYLE_CLASSNAMES.container.fill.deeperOffsetBackgroundColor]: unselectedCell,
            [GLOBAL_STYLE_CLASSNAMES.container.fill.backgroundColor]:
              !unselectedCell && !selectedCell,
          },
        )}
        style={{
          boxShadow: `inset ${columnLinesEnabled || showFirstColumnLine ? '-1px' : '0'} ${
            props.rowLinesDisabled || isGroupedRow ? '0' : '-1px'
          } 0 ${context.globalStyleConfig.container.outline.color}, inset 0px 0 0`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          ...(backgroundColor && { backgroundColor, color }),
          fontWeight: isBold ? 'bold' : undefined,
        }}
        wrapText={!shouldTruncateText}>
        {formatCellData(cellData, column, rowIndex, shouldHideText)}
      </Cell>
    );
  };

  const formatCellData = (
    cellData: string | number,
    column: DatasetColumn,
    rowIndex: number,
    shouldHideText: boolean,
  ) => {
    if (cellData === null || cellData === undefined || shouldHideText) return '';

    if (!schemaDisplayOptions?.[column.name]) {
      return defaultFormatCellData(
        cellData,
        column,
        isDashboardTable,
        ignoreInvalidDates,
        dateFormat,
      );
    }

    const colConfig = schemaDisplayOptions[column.name];

    let colType = column.type;

    if (
      datasets &&
      isJoinConfigReady(colConfig) &&
      colConfig.joinDisplayColumn &&
      colConfig.joinTable?.id
    ) {
      if (
        !joinMapping[colConfig.joinTable.id] ||
        !joinMapping[colConfig.joinTable.id][colConfig.joinDisplayColumn.name]
      ) {
        const joinDataset = datasets[colConfig.joinTable.id];

        joinMapping[joinDataset.id] = joinMapping[colConfig.joinTable.id] ?? {};
        joinMapping[joinDataset.id][colConfig.joinDisplayColumn.name] =
          joinMapping[joinDataset.id][colConfig.joinDisplayColumn.name] ?? {};

        joinDataset._rows?.forEach((row) => {
          if (!colConfig.joinDisplayColumn || !colConfig.joinColumn) return;
          joinMapping[joinDataset.id][colConfig.joinDisplayColumn.name][
            row[colConfig.joinColumn.name]
          ] = row[colConfig.joinDisplayColumn.name];
        });
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const joinColumnCellData = rows[rowIndex][colConfig.joinColumn!.name];
      cellData =
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        joinMapping[colConfig.joinTable.id][colConfig.joinDisplayColumn!.name][
          joinColumnCellData
        ] ?? '';

      colType = colConfig.joinDisplayColumn.column.type;
    }

    if (DATE_TYPES.has(colType)) {
      return formatDateField(
        cellData as string,
        colType,
        colConfig as DateDisplayOptions,
        ignoreInvalidDates,
        isDashboardTable,
      );
    } else if (NUMBER_TYPES.has(colType)) {
      const value = Number(cellData);
      const numberDisplayOptions = schemaDisplayOptions[column.name] as NumberDisplayOptions;
      const {
        goal,
        useColumnMaxForGoal,
        gradientType,
        gradient,
        gradientOptions,
        displayType,
        displayTypeOptions,
        disableHoverTooltip,
      } = numberDisplayOptions;
      const goalValue = useColumnMaxForGoal ? metricsByColumn?.[column.name]?.max : goal;
      const usesGradient = [GradientType.DIVERGING, GradientType.LINEAR].includes(
        gradientType ?? GradientType.NONE,
      );
      const gradientColor =
        usesGradient && metricsByColumn?.[column.name]
          ? getGradientColor({
              value,
              gradient,
              gradientType,
              gradientOptions,
              metrics: metricsByColumn?.[column.name],
            })
          : undefined;

      const formattedValue = formatNumberValue(numberDisplayOptions, value, goalValue);

      if (displayType === NumberDisplayDisplayType.PROGRESS_BAR) {
        let progressBarGoal = 0;
        if (displayTypeOptions?.useOtherColumnAsMax && displayTypeOptions?.goalColumnName) {
          progressBarGoal = rows[rowIndex][displayTypeOptions.goalColumnName] as number;
        } else {
          progressBarGoal = (
            displayTypeOptions?.useColumnMaxForProgressBarGoal
              ? metricsByColumn?.[column.name]?.max
              : displayTypeOptions?.progressBarGoal
          ) as number;
        }

        const backgroundColor =
          gradientColor && metricsByColumn?.[column.name]
            ? mixColors(gradientColor, theme.palette.ds.white, 0.5).rgb().string()
            : theme.palette.ds.lightBlue;
        const color = gradientColor ?? theme.palette.ds.blue;
        return (
          <TableProgressBar
            backgroundColor={backgroundColor}
            color={color}
            disableTooltip={isPDFTable || disableHoverTooltip}
            formattedValue={formattedValue}
            progressBarGoal={progressBarGoal}
            value={value}
          />
        );
      }

      return formattedValue;
    } else if (colType === STRING) {
      const stringDisplayOptions = colConfig as StringDisplayOptions;
      const { categoryColorAssignments, addedCategories, imageShape } = stringDisplayOptions;
      const stringFormat = getCurrentStringFormat(stringDisplayOptions);
      const cellDataString = String(cellData);

      switch (stringFormat) {
        case StringDisplayFormat.CATEGORY: {
          const categoryToColorMap = columnToCategoryToColorMap?.[column.name] || {};
          const addedColor = addedCategories?.find((cat) => cat.name === cellDataString);

          let backgroundColor: string | undefined;
          if (addedColor) {
            backgroundColor = addedColor.color;
            if (backgroundColor !== categoryToColorMap[cellData]) {
              addCategoryToColorMap(column.name, cellData, backgroundColor);
            }
          } else if (categoryColorAssignments?.[cellData]) {
            backgroundColor = categoryColorAssignments?.[cellData];
            if (backgroundColor !== categoryToColorMap[cellData]) {
              addCategoryToColorMap(column.name, cellData, backgroundColor);
            }
          } else if (categoryToColorMap[cellData]) {
            backgroundColor = categoryToColorMap[cellData];
          } else {
            backgroundColor = addCategoryToColorMap(column.name, cellData);
          }

          return (
            <span className={classes.categoryCellData} style={{ backgroundColor }}>
              {cellDataString}
            </span>
          );
        }
        case StringDisplayFormat.LINK: {
          if (!cellDataString) return null;
          const { urlLabel, linkColor, target } = getLinkInfo(stringDisplayOptions, rows, rowIndex);

          return (
            <a
              href={cellDataString}
              rel="noopener noreferrer"
              style={{ color: linkColor }}
              target={target}>
              {urlLabel}
            </a>
          );
        }
        case StringDisplayFormat.IMAGE:
          return (
            <div className={classes.cellImageContainer}>
              <img
                alt="table cell view"
                className={cx(classes.cellImageDisplay, {
                  circle: imageShape === ImageShapeFormat.CIRCLE,
                })}
                src={cellDataString}
              />
            </div>
          );
      }
    } else if (colType === BOOLEAN) {
      const cellDataString = String(cellData);
      if (!['true', 'false'].includes(cellDataString)) return 'Invalid boolean';

      const { trueIcon, falseIcon } = getCurrentBooleanIcons(colConfig as BooleanDisplayOptions);

      return (
        <Icon
          className={GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.color}
          icon={cellDataString === 'true' ? trueIcon : falseIcon}
        />
      );
    }

    return String(cellData);
  };

  const renderDefaultNameRenderer = (header: string, index?: number) => {
    let rightIcon: IconName | undefined = undefined;
    if (index === undefined) return <div></div>;

    if (sortInfo && schema[index].name === sortInfo.column_name) {
      rightIcon = sortInfo.order === SortOrder.ASC ? 'arrow-up' : 'arrow-down';
    }
    const column = schema[index];
    const schemaChange = getSchemaChange(index);

    return (
      <ColumnHeaderText
        alignment={getCellAlignment(schemaDisplayOptions?.[column.name], column.type)}
        header={header}
        isSortable={isSortable}
        rightIcon={rightIcon}
        stringFormat={stringFormat}
        tooltipText={resolveTooltipVariables(
          {
            showTooltip: !isPDFTable && schemaChange?.showTooltip,
            infoTooltipText: schemaChange?.tooltipText,
          },
          variables,
        )}
      />
    );
  };

  const nameRenderer = (
    columnName: string,
    columnInfo: DatasetColumn,
    index: number,
    renderColumnHeaderTextFunction: (header: string, index?: number) => ReactElement<IProps>,
  ) => {
    const resultFn = () => (
      <ColumnHeaderCell
        className={cx(
          sharedClasses.columnHeaderCell,
          classes.columnHeaderCell,
          GLOBAL_STYLE_CLASSNAMES.container.fill.offsetBackgroundColor,
          getCellAlignment(schemaDisplayOptions?.[columnInfo.name], columnInfo.type),
          {
            bolded: props.isColumnHeadersBolded,
            firstCell: index === 0,
            [classes.columnHeaderCellWithTooltip]: getSchemaChange(index)?.showTooltip,
          },
        )}
        name={columnName}
        nameRenderer={renderColumnHeaderTextFunction}
        style={{
          boxShadow: columnLinesEnabled
            ? `0 1px 0 ${context.globalStyleConfig.container.outline.color}, inset -1px 0 0 ${context.globalStyleConfig.container.outline.color}`
            : `0 1px 0 ${context.globalStyleConfig.container.outline.color}`,
        }}
        // @ts-ignore
        truncated={false}
      />
    );

    return resultFn;
  };

  let handleSelection;
  if (isSortable || drilldownCol) {
    handleSelection = (selectedRegions: IRegion[]) => {
      const region = selectedRegions[0];
      if (!region) return;
      if (isSortable && !region.rows && region.cols && region.cols[0] === region.cols[1]) {
        props.onColumnSelect && props.onColumnSelect(region.cols[0], schema);
      } else if (drilldownCol && region.rows) {
        setSelectedRow(region.rows[0] === selectedRow ? -1 : region.rows[0]);
      }
    };
  } else if (isExpandedDrilldownFormatting && props.onColumnSelect) {
    handleSelection = (selectedRegions: IRegion[]) => {
      const region = selectedRegions[0];
      if (!region || !region.cols) return;
      props.onColumnSelect && props.onColumnSelect(region.cols[0], schema);
    };
  }

  useEffect(() => {
    if (selectedRow === undefined || setVariable === undefined || !drilldownCol) return;

    const rowValue = selectedRow === -1 ? undefined : rows[selectedRow][drilldownCol];
    if (rowValue === drilldownVar) return;
    setVariable(rowValue);
  }, [selectedRow, setVariable, drilldownCol, rows, drilldownVar]);

  const columns =
    schema &&
    schema.map((columnInfo, index) => {
      const columnName =
        index === 0 && firstColumnTitle !== undefined
          ? firstColumnTitle
          : props.useFriendlyNameForHeader
          ? columnInfo.friendly_name
          : columnInfo.name;
      return (
        <Column
          cellRenderer={cellRenderer}
          columnHeaderCellRenderer={nameRenderer(
            columnName || '',
            columnInfo,
            index,
            renderDefaultNameRenderer,
          )}
          key={index}
          name={columnInfo.name}
        />
      );
    });

  const columnWidths = getColumnWidths(schema.length, props.columnWidths);

  return (
    <div
      className={cx(
        sharedClasses.root,
        {
          [classes.noBorderRadius]: props.noBorderRadius,
          [classes.tableHeight]: !props.unrestrictedHeight,
        },
        props.className,
      )}
      onClick={() => props.onColumnUnselect?.()}
      style={
        props.truncateEmptyRowSpace && rows.length < 13
          ? { height: rows.length * TABLE_ROW_HEIGHT + TABLE_CORNER_DIMENSIONS }
          : undefined
      }>
      <Table
        className={cx(
          { [classes.table]: props.fill },
          sharedClasses.tableTheme,
          sharedClasses.tableContext,
          GLOBAL_STYLE_CLASSNAMES.container.fill.backgroundColor,
        )}
        columnWidths={columnWidths}
        defaultColumnWidth={getDefaultColumnWidth(schema.length, tableWidth, columnWidths)}
        defaultRowHeight={props.rowHeight || TABLE_ROW_HEIGHT}
        enableColumnResizing={props.enableColumnResizing}
        enableRowHeader={!props.disableRowHeader}
        getCellClipboardData={(row, col) => {
          const cellData = getCellData(row, col);
          return cellData === null ? '' : String(cellData);
        }}
        key={uniqueId('table')}
        numFrozenColumns={props.isFirstColumnFrozen ? 1 : undefined}
        numRows={rows ? Math.min(rows.length, props.maxRows) : props.maxRows}
        onColumnWidthChanged={(index: number, size: number) => {
          onColumnWidthChanged && onColumnWidthChanged(index, size);
          !shouldTruncateText && resizeRowHeightToFitContent();
        }}
        onSelection={handleSelection}
        ref={tableInstance}
        renderMode={RenderMode.BATCH_ON_UPDATE}
        // Passing empty list removes default styling for selection
        // but allows onSelection to still be called
        selectedRegions={
          selectedColumnIndex !== undefined
            ? [Regions.column(selectedColumnIndex)]
            : drilldownCol
            ? []
            : undefined
        }
        selectionModes={isPDFTable ? SelectionModes.NONE : SelectionModes.COLUMNS_AND_CELLS}>
        {columns}
      </Table>
      <ResizeObserver onResize={(rect) => setTableWidth(rect.width)} />
    </div>
  );
});

export default BaseDataTable;
