import { createSlice } from '@reduxjs/toolkit';

import { CanvasVersion } from 'actions/canvasVersionActions';
import {
  deleteCurrentCanvasDraftSuccess,
  fetchCanvasVersionsRequest,
  fetchCanvasVersionsSuccess,
  publishNewCanvasVersionSuccess,
  revertToCanvasVersionSuccess,
} from 'actions/canvasVersionActions';
import { saveArchitectDraft } from './thunks/resourceSaveThunks';
import { createNewCanvasVersionSuccess } from 'actions/canvasVersionActions';

interface CanvasVersionsReducerState {
  versions: CanvasVersion[];
}

const initialState: CanvasVersionsReducerState = {
  versions: [],
};

const canvasVersionSlice = createSlice({
  name: 'canvasVersions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCanvasVersionsRequest, (state) => {
        state.versions = [];
      })
      .addCase(fetchCanvasVersionsSuccess, (state, { payload }) => {
        state.versions = [...payload.versions].sort((a, b) => b.version_number - a.version_number);
      })
      .addCase(saveArchitectDraft.fulfilled, (state, { payload }) => {
        if ('canvas_version' in payload) {
          state.versions.unshift(payload.canvas_version);
        } else {
          state.versions[0].edit_version_number = payload.edit_version_number;
        }
      })
      .addCase(createNewCanvasVersionSuccess, (state, { payload }) => {
        state.versions.unshift(payload.canvas_version);
      })
      .addCase(revertToCanvasVersionSuccess, (state, { payload }) => {
        const index = state.versions.findIndex(
          (version) => version.version_number === payload.canvas_version.version_number,
        );

        // Remove an active draft if present, since we are pushing a new draft based on the revert
        if (index >= 0) {
          state.versions.splice(index, 1);
        }
        state.versions.unshift(payload.canvas_version);
      })
      .addCase(publishNewCanvasVersionSuccess, (state, { payload }) => {
        const publishedVersion = state.versions.find(({ id }) => id === payload.canvas_version.id);

        if (publishedVersion) {
          publishedVersion.is_draft = payload.canvas_version.is_draft;
          publishedVersion.published_by_id = payload.canvas_version.published_by_id;
          publishedVersion.change_comments = payload.canvas_version.change_comments;
          publishedVersion.version_saved_at = payload.canvas_version.version_saved_at;
        }
      })
      .addCase(deleteCurrentCanvasDraftSuccess, (state, { payload }) => {
        const deletedVersionNumber = payload.postData.version_number;

        const index = state.versions.findIndex(
          (version) => version.version_number === deletedVersionNumber,
        );

        if (index >= 0) state.versions.splice(index, 1);
      });
  },
});

export const canvasVersionsReducer = canvasVersionSlice.reducer;
