import { useMemo, useState } from 'react';
import { TypeColumn } from '@inovua/reactdatagrid-enterprise/types';
import cx from 'classnames';

import { Icon, sprinkles, vars } from 'components/ds';
import { ColumnHeader } from './ColumnHeader';

import { defaultFormatCellData } from 'components/dataTable/utils';
import { DEFAULT_CATEGORY_COLORS } from 'constants/colorConstants';
import { BOOLEAN, DATE_TYPES, NUMBER_TYPES, STRING } from 'constants/dataConstants';
import {
  BooleanDisplayOptions,
  DateDisplayOptions,
  DisplayOptions,
  ImageShapeFormat,
  NumberDisplayOptions,
  StringDisplayFormat,
  StringDisplayOptions,
} from 'constants/types';
import { formatDateField, formatNumberValue } from 'pages/dashboardPage/charts/utils';
import { DatasetColumn, DatasetRow, DatasetSchema } from 'types/datasets';
import {
  getCurrentBooleanIcons,
  getCurrentStringFormat,
  getLinkInfo,
} from 'utils/formatConfigUtils';
import { getCellAlignment, getFlexAlignments } from './utils';

import * as styles from './index.css';

/**
 * Util for generating number cells
 *
 * @param {string} cellData The data to render
 * @param {NumberDisplayOptions} displayOptions The formatting config to apply
 *
 * @returns {string} Formatted number value to render in cell
 */
export const renderNumberCell = (
  cellData: string | number,
  displayOptions: NumberDisplayOptions,
): string => {
  const value = Number(cellData);
  const formattedValue = formatNumberValue(displayOptions, value);

  return formattedValue;
};

export type ColumnCategoryColorMapping = Record<
  string,
  Record<string | number, string | undefined>
>;

/**
 * Util for adding a color to the column category map
 *
 * @param {string} cellData The data to render for the cell
 * @param {ColumnInfo} column The data about the column the cell is in
 * @param {DatasetRow[]} rows The rows for the entire data grid
 * @param {StringDisplayOptions} displayOptions The formatting config to apply
 * @param {ColumnCategoryColorMapping} columnToCategoryToColor Map of columns to categories and categories to colors to render categorical cells
 * @param {Function} setColumnToCategoryToColor Setter for the map of category color mappings
 *
 * @returns {string} Color that was added
 */
const addCategoryToColorMap = (
  columnName: string,
  category: string | number,
  columnToCategoryToColor: ColumnCategoryColorMapping,
  setColumnToCategoryToColor: Function,
  assignedColor?: string,
) => {
  let color = assignedColor;
  if (!color) {
    const numKeys = Object.keys(columnToCategoryToColor[columnName] || {}).length;
    color = DEFAULT_CATEGORY_COLORS[numKeys % 12];
  }

  setColumnToCategoryToColor((current: ColumnCategoryColorMapping) => {
    if (!current[columnName]) current[columnName] = {};
    current[columnName][category] = color;
    return current;
  });

  return color;
};

/**
 * Util for generating string cells
 *
 * @param {string} cellData The data to render for the cell
 * @param {DatasetColumn} column The data about the column the cell is in
 * @param {DatasetRow[]} rows The rows for the entire data grid
 * @param {StringDisplayOptions} displayOptions The formatting config to apply
 * @param {ColumnCategoryColorMapping} columnToCategoryToColor Map of columns to categories and categories to colors to render categorical cells
 * @param {Function} setColumnToCategoryToColor Setter for the map of category color mappings
 *
 * @returns {ReactNode | string} The ReactNode or string to render in the cell
 */
const renderStringCell = (
  cellData: string,
  column: DatasetColumn,
  rows: DatasetRow[],
  rowIndex: number,
  displayOptions: StringDisplayOptions,
  columnToCategoryToColor: ColumnCategoryColorMapping,
  setColumnToCategoryToColor: Function,
) => {
  const { categoryColorAssignments, addedCategories, imageShape } = displayOptions;
  const stringFormat = getCurrentStringFormat(displayOptions);
  const cellDataString = String(cellData);

  switch (stringFormat) {
    case StringDisplayFormat.CATEGORY: {
      let backgroundColor = columnToCategoryToColor[column.name]?.[cellData];
      const assignmentColor = categoryColorAssignments?.[cellData];

      if (!backgroundColor) {
        const addedColor = addedCategories?.find((cat) => cat.name === cellDataString);

        if (addedColor) {
          backgroundColor = addCategoryToColorMap(
            column.name,
            cellData,
            columnToCategoryToColor,
            setColumnToCategoryToColor,
            addedColor.color,
          );
        } else if (assignmentColor) {
          backgroundColor = addCategoryToColorMap(
            column.name,
            cellData,
            columnToCategoryToColor,
            setColumnToCategoryToColor,
            assignmentColor,
          );
        } else {
          backgroundColor = addCategoryToColorMap(
            column.name,
            cellData,
            columnToCategoryToColor,
            setColumnToCategoryToColor,
          );
        }
      }

      if (backgroundColor && assignmentColor && backgroundColor !== assignmentColor) {
        backgroundColor = addCategoryToColorMap(
          column.name,
          cellData,
          columnToCategoryToColor,
          setColumnToCategoryToColor,
          assignmentColor,
        );
      }

      return (
        <div
          className={cx(
            sprinkles({
              display: 'flex',
              justifyContent: getFlexAlignments(displayOptions, column.type),
            }),
          )}>
          {/* TODO: Make an EmbeddedTag/update Tag component */}
          <div className={styles.cellCategory}>
            <div className={styles.categoryTag} style={{ backgroundColor }}></div>
            {cellDataString}
          </div>
        </div>
      );
    }
    case StringDisplayFormat.LINK: {
      if (!cellDataString) return null;
      const { urlLabel, linkColor, target } = getLinkInfo(displayOptions, rows, rowIndex);

      return (
        <a
          href={cellDataString}
          rel="noopener noreferrer"
          style={{ color: linkColor }}
          target={target}>
          {urlLabel}
        </a>
      );
    }
    case StringDisplayFormat.IMAGE:
      return (
        <div className={styles.cellImageContainer}>
          <img
            alt="table cell view"
            className={cx(styles.cellImageDisplay, {
              [styles.circleImage]: imageShape === ImageShapeFormat.CIRCLE,
            })}
            src={cellDataString}
          />
        </div>
      );
  }
  return cellDataString;
};

/**
 * Util for generating boolean cells
 *
 * @param {string} cellData The data to render
 * @param {BooleanDisplayOptions} displayOptions The formatting config to apply
 *
 * @returns {ReactNode} The icon to render in the cell
 */
const renderBooleanCell = (cellData: string, displayOptions: BooleanDisplayOptions) => {
  const cellDataString = String(cellData);
  if (!['true', 'false'].includes(cellDataString)) return 'Invalid boolean';

  const { trueIcon, falseIcon } = getCurrentBooleanIcons(displayOptions);

  return <Icon name={cellDataString === 'true' ? trueIcon : falseIcon} />;
};

export type ColumnConfigs = Record<string, { displayFormatting?: DisplayOptions }>;

type GenerateDataGridColumnParams = Omit<UseColumnsParams, 'columns'> & {
  columnInfo: DatasetColumn;
  rows: DatasetRow[];
  isFirstColumn: boolean;

  /** Map of columns to categories and categories to colors to render categorical cells */
  columnToCategoryToColor: ColumnCategoryColorMapping;
  /** Setter for the map of category color mappings */
  setColumnToCategoryToColor: Function;
};

/**
 * Util for calculating formatted grid columns. Iterates over schema and generates the reactdatagrid column format needed.
 * Render is determined by columnConfigs, otherwise defaults based on columnInfo
 */
export function generateDataGridColumn({
  columnConfigs,
  columnInfo,
  rows,
  columnToCategoryToColor,
  setColumnToCategoryToColor,
  isFirstColumn,
  selectedColumnId,
  onColumnSelect,
}: GenerateDataGridColumnParams): TypeColumn {
  const { name, friendly_name, type } = columnInfo;
  const config = columnConfigs?.[name];

  const selected = selectedColumnId === name;
  const selectedClassName = cx({ [sprinkles({ backgroundColor: 'activeSubdued' })]: selected });

  return {
    name,
    defaultFlex: 1,
    minWidth: 100,
    textAlign: getCellAlignment(config?.displayFormatting, type),
    headerProps: {
      className: selectedClassName,
      style: {
        // React Data Grid header cells have extra padding and 2 container divs so the padding gets applied twice
        paddingLeft: isFirstColumn ? vars.spacing['sp.5'] : undefined,
      },
    },
    style: {
      paddingLeft: isFirstColumn ? vars.spacing.sp2 : undefined,
      backgroundColor: selected ? vars.colors.activeSubdued : undefined,
    },
    renderHeader: (headerProps) => (
      <ColumnHeader
        {...headerProps}
        alignment={getFlexAlignments(config?.displayFormatting, type)}
        friendlyName={friendly_name}
        onClick={onColumnSelect}
      />
    ),
    render: ({ rowIndex, value }: { rowIndex: number; value: string }) => {
      if (value === null || value === undefined) return '';

      const displayOptions = config?.displayFormatting;
      if (!displayOptions) return defaultFormatCellData(value, columnInfo);

      if (DATE_TYPES.has(type)) {
        return formatDateField(value, type, displayOptions as DateDisplayOptions, false, true);
      } else if (NUMBER_TYPES.has(type)) {
        return renderNumberCell(value, displayOptions as NumberDisplayOptions);
      } else if (type === STRING) {
        return renderStringCell(
          value,
          columnInfo,
          rows,
          rowIndex,
          displayOptions as StringDisplayOptions,
          columnToCategoryToColor,
          setColumnToCategoryToColor,
        );
      } else if (type === BOOLEAN) {
        return renderBooleanCell(value, displayOptions as BooleanDisplayOptions);
      }

      return String(value);
    },
  };
}

export type UseColumnsParams = {
  /** If provided, will override generated columns */
  columns?: TypeColumn[];
  /** The formatting config to apply to columns */
  columnConfigs?: ColumnConfigs;
  /** The column names and types to render */
  schema?: DatasetSchema;
  /** The rows of data to render in the column */
  rows?: DatasetRow[] | null;
  /** ID of the selected column, where ID is the name from schema */
  selectedColumnId?: string | number;
  /** Callback that fires when a column is selected */
  onColumnSelect?: (id: string | number) => void;
};

/**
 * Custom hook for calculating formatted grid columns
 * @returns Array of columns to be rendered by reactdatagrid
 */
export const useColumns = (params: UseColumnsParams) => {
  const [columnToCategoryToColor, setColumnToCategoryToColor] =
    useState<ColumnCategoryColorMapping>({});

  const gridColumns = useMemo<TypeColumn[]>(() => {
    const { columns, schema, rows, ...rest } = params;
    if (columns) return columns;
    if (!schema || !rows) return [];

    return schema.map((columnInfo, i) =>
      generateDataGridColumn({
        ...rest,
        rows,
        columnInfo,
        columnToCategoryToColor,
        setColumnToCategoryToColor,
        isFirstColumn: i === 0,
      }),
    );
  }, [params, columnToCategoryToColor]);

  return gridColumns;
};
