import { makeStyles, Theme } from '@material-ui/core/styles';
import { groupBy } from 'utils/standard';
import { useDispatch } from 'react-redux';
import * as styles from './styles.css';
import { IconButton, sprinkles, Switch } from 'components/ds';

import DroppableComponentItem from 'pages/dashboardPage/droppableComponentItem';
import SettingHeader from './DataPanelConfigV2/DataConfigTab/SettingHeader';

import { DROPPABLE_DASHBOARD_ELEMENT_CONFIGS, ELEMENT_GROUPS } from 'constants/dashboardConstants';
import { SIDE_PANE_WIDTH } from 'components/SidePane';
import { useSelector } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import { DASHBOARD_LAYOUT_CONFIG } from 'types/dashboardTypes';
import { updateDashboardPageLayoutConfig } from 'actions/dashboardV2Actions';
import { DashboardPageLayoutConfig } from 'types/dashboardVersionConfig';

const GROUPED_ELEMS = groupBy(DROPPABLE_DASHBOARD_ELEMENT_CONFIGS, 'group');

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: SIDE_PANE_WIDTH,
    height: `100%`,
    backgroundColor: theme.palette.ds.white,
    borderRight: `1px solid ${theme.palette.ds.grey400}`,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  layoutHeader: {
    marginTop: theme.spacing(5),
    padding: `0 ${theme.spacing(6)}px`,
  },
  droppableComponent: {
    marginBottom: theme.spacing(3),
  },
  droppableElementContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    padding: `${theme.spacing(5)}px ${theme.spacing(2)}px`,
    paddingBottom: theme.spacing(2),
  },
  editDashboardLayoutBtn: {
    marginTop: theme.spacing(4),
  },
  editDashboardLayoutSaveBtnContainer: {
    marginTop: theme.spacing(4),
  },
  editLayoutContainer: {
    padding: `0 ${theme.spacing(6)}px`,
  },
  config: {
    padding: `0 ${theme.spacing(6)}px`,
  },
}));

type Props = {
  dashboardId: number;
  isStickyHeaderEnabled?: boolean;
  onLayoutEditStateSelected: (layoutSTate: DASHBOARD_LAYOUT_CONFIG) => void;
  onNewDashboardElementDrag: (elementType?: string) => void;
  pageLayoutConfig?: DashboardPageLayoutConfig;
};

export default function EditDashboardConfig({
  dashboardId,
  isStickyHeaderEnabled,
  onLayoutEditStateSelected,
  onNewDashboardElementDrag,
  pageLayoutConfig,
}: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const showMapComponent = useSelector(
    (state: ReduxState) => !!state.currentUser.team?.feature_flags.show_map_component,
  );

  return (
    <div className={classes.root}>
      {isStickyHeaderEnabled ? (
        <>
          <SettingHeader name="Layout" />
          <div className={sprinkles({ display: 'flex', padding: 'sp1' })}>
            <div className={styles.layoutEditOption}>
              <div className={sprinkles({ flexItems: 'alignCenter' })}>
                <Switch
                  useCustomStyles
                  className={sprinkles({ marginRight: 'sp1' })}
                  onChange={() =>
                    dispatch(
                      updateDashboardPageLayoutConfig({
                        config: {
                          ...pageLayoutConfig,
                          stickyHeader: {
                            ...pageLayoutConfig?.stickyHeader,
                            enabled: !pageLayoutConfig?.stickyHeader?.enabled,
                            enabledExpandableFilterRow:
                              pageLayoutConfig?.stickyHeader?.enabled === undefined
                                ? true
                                : undefined,
                          },
                        },
                        dashboardId,
                      }),
                    )
                  }
                  switchOn={!!pageLayoutConfig?.stickyHeader?.enabled}
                />
                <div className={sprinkles({ fontWeight: 500 })}>Header</div>
              </div>
              <IconButton
                name="pencil"
                onClick={() => onLayoutEditStateSelected(DASHBOARD_LAYOUT_CONFIG.HEADER)}
              />
            </div>
          </div>
        </>
      ) : null}
      {[
        ELEMENT_GROUPS.CHARTS,
        ELEMENT_GROUPS.CONTROLS,
        ELEMENT_GROUPS.EXPORT,
        ELEMENT_GROUPS.DISPLAY,
      ].map((group) => (
        <div key={`elem-drop-group-${group}`}>
          <SettingHeader name={group} />
          <div className={classes.droppableElementContainer}>
            {GROUPED_ELEMS[group].map((config) => {
              if (config.name === 'Map' && !showMapComponent) return null;
              return (
                <DroppableComponentItem
                  className={classes.droppableComponent}
                  icon={config.icon}
                  key={`droppable-dash-elem-${config.id}`}
                  name={config.name}
                  onDragEnd={() => onNewDashboardElementDrag(undefined)}
                  onDragStart={() => onNewDashboardElementDrag(config.id)}
                />
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
}
