import { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core';
import { EmailBody } from 'types/emailCadence';
import { cloneDeep } from 'utils/standard';
import cx from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import * as RD from 'remotedata';

import Button from 'shared/Button';
import Checkbox from 'components/checkbox';
import { ArchitectCustomerDashboardModal } from './modal';
import { TemplateModal } from './templateModal';
import { ScheduledExportModal } from 'components/resource/ScheduledExportModal/ScheduledExportModal';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import { Icon } from '@blueprintjs/core';
import Poller from 'components/JobQueue/Poller';

import { ArchitectCustomerDashboardReduxState } from 'architectCustomerDashboardContent/reducers/rootReducer';
import {
  ArchitectCustomerDashboard,
  createArchitectCustomerDashboard,
  deleteArchitectCustomerDashboard,
  saveArchitectCustomerDashboard,
  toggleArchitectCustomerDashboardEditing,
  updateArchitectCustomerDashboardName,
  clearArchitectExport,
} from 'actions/architectCustomerDashboardActions';
import {
  createArchitectEmailCadence,
  deleteArchitectEmailCadence,
  updateArchitectEmailCadence,
} from 'actions/architectEmailCadenceActions';
import { CanvasVersionConfig } from 'actions/canvasConfigActions';
import {
  createEUDataPanel,
  toggleEUDFilter,
} from 'actions/architectCustomerDashboardConfigActions';
import { getUrlParamStringFromDashVars, removeUnderscoreFields } from 'utils/dashboardUtils';
import { DASHBOARD_ELEMENT_TYPE_TO_NAME } from 'constants/dashboardConstants';
import { CanvasConfigurability, CanvasConfigureType, CanvasViewType } from 'actions/canvasActions';
import {
  getArchitectCustomerDashboardName,
  prepareConfigForCopy,
} from 'utils/architectCustomerDashboardUtils';
import { AnalyticsEventTracker } from 'utils/analyticsUtils';
import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import { DRILLDOWN_DATA_PANEL_ID } from 'reducers/dashboardEditConfigReducer';
import { Jobs } from 'components/JobQueue/types';
import { ACTION } from 'actions/types';
import { bulkEnqueueJobs } from 'actions/jobQueueActions';
import { DashboardVariableMap } from 'types/dashboardTypes';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    height: 56,
    width: '100%',
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dashboardName: {
    fontWeight: 500,
    fontSize: 20,
    color: theme.palette.ds.grey900,
  },
  editDashboardName: {
    display: 'flex',
    alignItems: 'center',
  },
  editName: {
    marginLeft: theme.spacing(2),
    cursor: 'pointer',
    height: 26,
    width: 26,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 2,

    '&:hover': {
      backgroundColor: theme.palette.ds.grey300,
    },
  },
  headerButtons: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(2),
    },
  },
  filterModal: {
    minWidth: 380,
  },
  filters: {
    maxHeight: 300,
    overflowY: 'auto',
  },
  filterModalFilter: {
    display: 'flex',
  },
  filterInfo: {
    fontWeight: 500,
    fontSize: 14,
    color: theme.palette.ds.black,
    lineHeight: '20px',
    marginBottom: 5,
  },
  filterType: {
    marginLeft: 4,
    fontWeight: 400,
    color: theme.palette.ds.grey700,
  },
  filterDescription: {
    fontSize: 12,
    lineHeight: '15px',
    color: theme.palette.ds.grey700,
    marginBottom: theme.spacing(2),
  },
  saveDivider: {
    height: '100%',
    width: 1,
    backgroundColor: '#C4C4C4',
  },
  discardBtnRow: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4),
  },
  discardBtnCancel: {
    marginRight: theme.spacing(2),
  },
}));

enum MODAL_STATUS {
  CLOSED,
  FILTERS,
  TEMPLATES,
  MANAGE_EMAILS,
  DISCARD_CHANGES,
}

type Props = {
  canvasConfig: CanvasVersionConfig;
  canvasConfigurability: CanvasConfigurability;
  canvasEmbedId: string;
  canvasName: string;
  dashboard: ArchitectCustomerDashboard;
  dashboardPadding: number;
  portalContainerId: string;
  variables: DashboardVariableMap;

  analyticsEventTracker?: AnalyticsEventTracker;
  isViewOnly?: boolean;
  customerToken?: string;
  userId?: string;
};

export default function ArchitectCustomerDashboardHeader({
  canvasConfigurability,
  canvasEmbedId,
  canvasConfig,
  canvasName,
  dashboard,
  dashboardPadding,
  portalContainerId,
  variables,
  customerToken,
  userId,
  analyticsEventTracker,
  isViewOnly,
}: Props): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { isEditing, changesMadeToDashboard, emails, exportUrl } = useSelector(
    (state: ArchitectCustomerDashboardReduxState) => ({
      isEditing: state.architectCustomerDashboard.editingDashboard !== null,
      changesMadeToDashboard: state.architectCustomerDashboard.changesMadeToDashboard,
      emails: state.architectCustomerDashboard.architectEmailCadences,
      exportUrl: state.architectCustomerDashboard.exportUrl,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (RD.isSuccess(exportUrl)) {
      window.open(exportUrl.data);
      dispatch(clearArchitectExport());
    }
  }, [dispatch, exportUrl]);

  const filteredEmails = useMemo(() => {
    if (!emails) return [];
    return emails.filter((email) => email.architect_customer_dashboard_id === dashboard.id);
  }, [dashboard.id, emails]);

  const isMultiDashboardView = canvasConfigurability.viewType !== CanvasViewType.SINGLE;
  const dashboardName = isMultiDashboardView
    ? getArchitectCustomerDashboardName(dashboard)
    : canvasName;

  const [isDashboardSaving, setIsDashboardSaving] = useState(false);
  const [awaitedJobs, setAwaitedJobs] = useState<Record<string, Jobs>>({});
  const [isEditingName, setIsEditingName] = useState(false);
  const [modalStatus, setModalStatus] = useState(MODAL_STATUS.CLOSED);

  const updateEmailCadence = useCallback(
    (emailId: string, email: EmailBody, onSuccess: () => void, onFailure: () => void) =>
      dispatch(
        updateArchitectEmailCadence(
          {
            customerToken,
            postData: { email_id: emailId, email },
          },
          onSuccess,
          onFailure,
        ),
      ),
    [customerToken, dispatch],
  );

  const createEmailCadence = useCallback(
    (email: EmailBody, onSuccess: () => void, onFailure: () => void) =>
      dispatch(
        createArchitectEmailCadence(
          {
            customerToken,
            postData: {
              canvas_embed_id: canvasEmbedId,
              user_id: userId ?? null,
              dashboard_id: dashboard.id,
              email,
            },
          },
          onSuccess,
          onFailure,
        ),
      ),
    [canvasEmbedId, customerToken, dashboard, dispatch, userId],
  );

  const sendTestDraftEmail = useCallback(
    (
      recipients: string[],
      subject: string,
      message: string,
      onSuccess: (jobs: Record<string, Jobs>) => void,
    ) => {
      const jobs = {
        [uuidv4()]: {
          job_type: ACTION.SEND_TEST_END_USER_EMAIL_BY_PARAMS,
          job_args: {
            canvas_embed_id: canvasEmbedId,
            user_id: userId ?? null,
            recipients,
            subject,
            message,
            dashboard_id: dashboard.id,
          },
        },
      };

      dispatch(bulkEnqueueJobs({ jobs, customerToken }, onSuccess));
    },
    [canvasEmbedId, customerToken, dashboard, dispatch, userId],
  );

  const sendTestEmail = useCallback(
    (exportId: string, onSuccess: (jobs: Record<string, Jobs>) => void) => {
      const jobs = {
        [uuidv4()]: {
          job_type: ACTION.SEND_TEST_END_USER_EMAIL,
          job_args: { architect_email_cadence_id: exportId, is_test_email: true },
        },
      };
      dispatch(bulkEnqueueJobs({ jobs, customerToken }, onSuccess));
    },
    [customerToken, dispatch],
  );

  const renderHeaderButtons = () => {
    if (isViewOnly) return null;
    if (dashboard.id === null && customerToken && isMultiDashboardView) {
      return (
        <>
          <Button
            loading={isDashboardSaving}
            onClick={() => {
              setIsDashboardSaving(true);
              dispatch(
                createArchitectCustomerDashboard(
                  {
                    customerToken,
                    postData: {
                      canvas_embed_id: canvasEmbedId,
                      config: prepareConfigForCopy(dashboard.configuration),
                      name: `${dashboardName} Copy`,
                      user_id: userId ?? null,
                    },
                  },
                  () => {
                    analyticsEventTracker?.(
                      REPORTED_ANALYTIC_ACTION_TYPES.END_USER_DASHBOARD_COPIED,
                    );
                    setIsDashboardSaving(false);
                  },
                  () => setIsDashboardSaving(false),
                ),
              );
            }}
            text="Make Editable Copy"
            type="primary"
          />
        </>
      );
    }
    if (!isEditing) {
      return (
        <>
          {canvasConfigurability.allowEmails && (dashboard.id !== null || emails === null) ? (
            <Button
              disabled={emails === null}
              onClick={() => setModalStatus(MODAL_STATUS.MANAGE_EMAILS)}
              text={filteredEmails.length === 0 ? 'Schedule an Email' : 'Manage Emails'}
              type="secondary"
            />
          ) : null}
          {customerToken ? (
            <Button
              loading={RD.isLoading(exportUrl)}
              onClick={() => {
                const jobs = {
                  [uuidv4()]: {
                    job_type: ACTION.EXPORT_END_USER_DASHBOARD,
                    job_args: {
                      canvas_embed_id: canvasEmbedId,
                      dashboard_id: dashboard.id,
                      variable_query: getUrlParamStringFromDashVars(variables),
                    },
                  },
                };
                dispatch(bulkEnqueueJobs({ jobs, customerToken }, setAwaitedJobs));
              }}
              text="Export Dashboard"
              type="secondary"
            />
          ) : null}
          {!isMultiDashboardView &&
          canvasConfigurability.enableResetDashboardChanges &&
          dashboard.id !== null ? (
            <Button
              disabled={!customerToken}
              onClick={() => setModalStatus(MODAL_STATUS.DISCARD_CHANGES)}
              text="Discard Changes"
              tooltipText={
                !customerToken ? 'Cannot discard changes while viewing example' : undefined
              }
              type="secondary"
            />
          ) : null}
          <Button
            onClick={() => dispatch(toggleArchitectCustomerDashboardEditing(true))}
            text="Edit"
            type="primary"
          />
        </>
      );
    }
    return (
      <>
        {canvasConfigurability.type === CanvasConfigureType.EDITABLE ? (
          <Button
            disabled={Object.keys(canvasConfig.datasets).length === 0}
            icon="plus"
            onClick={() => {
              dispatch(createEUDataPanel());
              analyticsEventTracker?.(REPORTED_ANALYTIC_ACTION_TYPES.CHART_CREATED);
            }}
            text="New Chart"
            type="primary"
          />
        ) : null}
        <Button
          icon="properties"
          onClick={() => setModalStatus(MODAL_STATUS.FILTERS)}
          text="Filters"
          type="primary"
        />
        <Button
          icon="search"
          onClick={() => setModalStatus(MODAL_STATUS.TEMPLATES)}
          text="Templates"
          type="primary"
        />
        <div className={classes.saveDivider} />
        <Button
          icon="tick"
          loading={isDashboardSaving}
          onClick={() => {
            if (!customerToken || !changesMadeToDashboard) {
              return dispatch(toggleArchitectCustomerDashboardEditing(false));
            }

            setIsDashboardSaving(true);
            const config = cloneDeep(dashboard.configuration);
            Object.values(config.data_panels).map(removeUnderscoreFields);
            if (DRILLDOWN_DATA_PANEL_ID in config.data_panels) {
              delete config.data_panels[DRILLDOWN_DATA_PANEL_ID];
            }

            if (dashboard.id === null) {
              dispatch(
                createArchitectCustomerDashboard(
                  {
                    customerToken,
                    postData: {
                      canvas_embed_id: canvasEmbedId,
                      config,
                      name: `${dashboardName} Copy`,
                      user_id: userId ?? null,
                    },
                  },
                  () => {
                    analyticsEventTracker?.(
                      REPORTED_ANALYTIC_ACTION_TYPES.END_USER_DASHBOARD_COPIED,
                    );
                    setIsDashboardSaving(false);
                  },
                  () => setIsDashboardSaving(false),
                ),
              );
            } else {
              dispatch(
                saveArchitectCustomerDashboard(
                  {
                    customerToken,
                    postData: {
                      canvas_embed_id: canvasEmbedId,
                      config,
                      user_id: userId ?? null,
                      dashboard_id: dashboard.id,
                      dashboard_name: dashboard.name,
                    },
                  },
                  () => {
                    analyticsEventTracker?.(
                      REPORTED_ANALYTIC_ACTION_TYPES.END_USER_DASHBOARD_EDITED,
                    );
                    setIsDashboardSaving(false);
                  },
                  //TODO: Alert user of error state
                  () => setIsDashboardSaving(false),
                ),
              );
            }
          }}
          text="Save"
          tooltipText={!customerToken ? 'Dashboard will not save while viewing example' : undefined}
          type="primary"
        />
        <Button
          onClick={() => dispatch(toggleArchitectCustomerDashboardEditing(false))}
          text="Cancel"
        />
      </>
    );
  };

  const renderTemplateModal = () => {
    if (!isEditing || modalStatus !== MODAL_STATUS.TEMPLATES) return null;
    return (
      <TemplateModal
        analyticsEventTracker={analyticsEventTracker}
        closeModal={() => setModalStatus(MODAL_STATUS.CLOSED)}
        customComponents={canvasConfig.customComponents ?? {}}
        datasets={canvasConfig.datasets}
        portalContainerId={portalContainerId}
        templates={canvasConfig.templates}
      />
    );
  };

  const renderFilterModal = () => {
    if (!isEditing || modalStatus !== MODAL_STATUS.FILTERS) return null;
    const filtersSelected = new Set(dashboard.configuration.filters ?? []);
    return (
      <ArchitectCustomerDashboardModal
        className={classes.filterModal}
        onClose={() => setModalStatus(MODAL_STATUS.CLOSED)}
        portalContainerId={portalContainerId}
        title="Filters">
        <div className={classes.filters}>
          {Object.values(canvasConfig.filters).map((filter) => {
            if (!filter.filter_info) return null;
            const isFilterInDashboard = filtersSelected.has(filter.id);
            return (
              <div className={classes.filterModalFilter} key={filter.id}>
                {/* TODO: Move to Classes once designs finalized */}
                <div style={{ marginRight: 6 }}>
                  <Checkbox
                    isChecked={isFilterInDashboard}
                    onChange={() => dispatch(toggleEUDFilter({ isFilterInDashboard, filter }))}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <div className={classes.filterInfo}>
                    {filter.name}
                    <span className={classes.filterType}>{`(${
                      DASHBOARD_ELEMENT_TYPE_TO_NAME[filter.filter_info.filter_type]
                    })`}</span>
                  </div>
                  <div className={classes.filterDescription}>{filter.description}</div>
                </div>
              </div>
            );
          })}
        </div>
      </ArchitectCustomerDashboardModal>
    );
  };

  const renderExportModal = () => {
    if (modalStatus !== MODAL_STATUS.MANAGE_EMAILS || emails === null || !customerToken)
      return null;

    return (
      <ScheduledExportModal
        closeModal={() => setModalStatus(MODAL_STATUS.CLOSED)}
        createExportCadence={createEmailCadence}
        customerToken={customerToken}
        deleteExportCadence={(emailId) =>
          dispatch(
            deleteArchitectEmailCadence({
              customerToken,
              postData: { email_id: emailId },
            }),
          )
        }
        emails={filteredEmails}
        resourceName={dashboardName}
        sendTestDraftExport={sendTestDraftEmail}
        sendTestExport={sendTestEmail}
        updateExportCadence={updateEmailCadence}
      />
    );
  };

  const renderDiscardChangesModal = () => {
    if (modalStatus !== MODAL_STATUS.DISCARD_CHANGES || !customerToken) return null;

    return (
      <ArchitectCustomerDashboardModal
        className={classes.filterModal}
        onClose={() => setModalStatus(MODAL_STATUS.CLOSED)}
        portalContainerId={portalContainerId}
        title="Discard your changes">
        <div>
          <div>This will revert the dashboard to the base dashboard.</div>
          <div className={classes.discardBtnRow}>
            <Button
              className={classes.discardBtnCancel}
              onClick={() => setModalStatus(MODAL_STATUS.CLOSED)}
              text="Cancel"
              type="secondary"
            />
            <Button
              onClick={() => {
                if (dashboard.id !== null) {
                  dispatch(
                    deleteArchitectCustomerDashboard(
                      {
                        customerToken,
                        postData: { user_id: userId ?? null, dashboard_id: dashboard.id },
                      },
                      () => {
                        analyticsEventTracker?.(
                          REPORTED_ANALYTIC_ACTION_TYPES.END_USER_DASHBOARD_RESET,
                        );
                        setIsDashboardSaving(false);
                      },
                      () => setIsDashboardSaving(false),
                    ),
                  );
                }

                setModalStatus(MODAL_STATUS.CLOSED);
              }}
              text="Confirm"
              type="primary"
            />
          </div>
        </div>
      </ArchitectCustomerDashboardModal>
    );
  };

  const renderDashboardName = () => {
    if (isEditing && isMultiDashboardView) {
      return (
        <div className={cx(classes.editDashboardName, classes.dashboardName)}>
          {isEditingName ? (
            <InputWithBlurSave
              hideRightIconInteractions
              initialValue={dashboardName}
              onNewValueSubmitted={(newName) => {
                if (newName.trim() !== '') dispatch(updateArchitectCustomerDashboardName(newName));
                setIsEditingName(false);
              }}
            />
          ) : (
            <>
              {dashboardName}
              <Icon
                className={classes.editName}
                icon="edit"
                onClick={() => setIsEditingName(true)}
                size={16}
              />
            </>
          )}
        </div>
      );
    }
    return <div className={classes.dashboardName}>{dashboardName}</div>;
  };

  return (
    <div
      className={classes.header}
      id="explo-end-user-dashboard"
      style={{ padding: `20px ${dashboardPadding}px 0px` }}>
      {renderDashboardName()}
      {/* Wrap the modals in the header too so that the Embedded modal anchor <div/> doesn't mess up the flexbox */}
      <div className={classes.headerButtons}>
        {renderHeaderButtons()}
        {renderFilterModal()}
        {renderTemplateModal()}
        {renderExportModal()}
        {renderDiscardChangesModal()}
      </div>
      {/* Poller here used for exporting dashboard images */}
      <Poller
        awaitedJobs={awaitedJobs}
        customerToken={customerToken}
        updateJobResult={(finishedJobIds, onComplete) => {
          if (finishedJobIds.length > 0) setAwaitedJobs({});
          onComplete();
        }}
      />
    </div>
  );
}
