import cx from 'classnames';
import { useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import Button from 'shared/Button';
import { sharedStyles } from './sharedDroppedStyles';

import { FORMULA_AGG_TYPE } from 'constants/dataConstants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 32,
    display: 'flex',
    marginBottom: 4,
    '& .bp3-button': {
      borderRadius: `0 3px 3px 0 !important`,
      borderLeft: `1.5px solid ${theme.palette.ds.grey300} !important`,
      margin: 0,
      marginTop: 1, // So that left border doesn't overlap with top border that highlights blue on hover
      width: 32,
      height: 30,
    },
  },
  input: {
    '& .bp3-input': {
      borderRadius: `3px 0 0 3px`,
    },
  },
  droppedFormula: {
    backgroundColor: theme.palette.ds.lightBlue,
    paddingRight: theme.spacing(1),
    borderRadius: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 32,
    marginBottom: 4,
  },
}));

type Props = {
  disableEdits?: boolean;
  draggingClass?: string;
  formula: string | undefined;
  onColOptionChanged: (option: { id: string; name: string; formula: string }) => void;
  onRemoveCol: () => void;
};

export default function DroppedColumn({
  disableEdits,
  draggingClass,
  formula,
  onColOptionChanged,
  onRemoveCol,
}: Props) {
  const classes = useStyles();
  const sharedClasses = sharedStyles();
  const [isInEditMode, setIsInEditMode] = useState(true);

  if (isInEditMode) {
    return (
      <div className={classes.root}>
        <InputWithBlurSave
          fillWidth
          containerClassName={classes.input}
          disabled={disableEdits}
          initialValue={formula}
          onNewValueSubmitted={(newVal) => {
            setIsInEditMode(false);
            if (newVal === formula) return;
            onColOptionChanged({ ...FORMULA_AGG_TYPE, formula: newVal });
          }}
        />
      </div>
    );
  }
  return (
    <div className={cx(classes.droppedFormula, draggingClass)}>
      <div className={sharedClasses.name}>{formula}</div>
      <Button
        compact
        minimal
        className={cx(sharedClasses.iconBtn, sharedClasses.rightDivider)}
        icon="edit"
        onClick={() => setIsInEditMode(true)}
      />
      <Button
        compact
        minimal
        className={sharedClasses.iconBtn}
        icon="cross"
        onClick={onRemoveCol}
      />
    </div>
  );
}
