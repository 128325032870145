import { FC } from 'react';
import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { ProgressBar as BlueprintProgressBar } from '@blueprintjs/core';

type StyleProps = Pick<Props, 'backgroundColor' | 'color' | 'height'>;

const useStyles = makeStyles((theme: Theme) => ({
  progressBar: (props: StyleProps) => ({
    borderRadius: 4,
    height: props.height ?? 25,
    backgroundColor: props.backgroundColor,

    '&.bp3-progress-bar .bp3-progress-meter': {
      backgroundColor: props.color || theme.palette.ds.blue,
      borderRadius: 4,
    },
  }),
}));

type Props = {
  className?: string;
  value: number;
  color?: string;
  backgroundColor?: string;
  height?: number;
};

export const ProgressBar: FC<Props> = ({ className, value, color, backgroundColor, height }) => {
  const classes = useStyles({ backgroundColor, color, height });
  return (
    <BlueprintProgressBar
      animate={false}
      className={cx(classes.progressBar, className)}
      stripes={false}
      value={value}
    />
  );
};
