import { FC } from 'react';
import { useDispatch } from 'react-redux';
import produce from 'immer';

import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';
import { Switch } from 'components/ds';
import { rootClass, configInputClass } from './styles';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import {
  ColorFilterPlacement,
  OPERATION_TYPES,
  V2TwoDimensionChartInstructions,
} from 'constants/types';
import { updateVariableEvent } from 'utils/customEventUtils';
import { COLOR_CATEGORY_FILTER_SUFFIX } from 'constants/dashboardConstants';

type Props = {
  dataPanelProvidedId: string;
  instructions: V2TwoDimensionChartInstructions;
  visualizationType: OPERATION_TYPES;
};

export const ColorCategoryConfig: FC<Props> = ({
  dataPanelProvidedId,
  instructions,
  visualizationType,
}) => {
  const dispatch = useDispatch();

  const selected = instructions.colorFilterPlacement ?? ColorFilterPlacement.RIGHT_CORNER;
  return (
    <div className={rootClass}>
      <ToggleButtonGroup fillWidth className={configInputClass} label="Placement">
        {Object.values(ColorFilterPlacement).map((position) => (
          <ToggleButton
            active={position === selected}
            key={position}
            onClick={() => {
              const newInstructions = produce(instructions, (draft) => {
                draft.colorFilterPlacement = position;
              });
              dispatch(updateVisualizeOperation(newInstructions, visualizationType));
            }}
            text={position === ColorFilterPlacement.RIGHT_CORNER ? 'Right Corner' : 'Below Title'}
          />
        ))}
      </ToggleButtonGroup>
      <Switch
        className={configInputClass}
        label="Default Group By Off"
        onChange={() => {
          if (instructions.defaultColorGroupingOff && instructions.colorColumnOptions?.length) {
            updateVariableEvent({
              varName: dataPanelProvidedId + COLOR_CATEGORY_FILTER_SUFFIX,
              newValue: instructions.colorColumnOptions[0].column.name,
            });
          }
          const newInstructions = produce(instructions, (draft) => {
            draft.defaultColorGroupingOff = !draft.defaultColorGroupingOff;
          });
          dispatch(updateVisualizeOperation(newInstructions, visualizationType));
        }}
        switchOn={instructions.defaultColorGroupingOff}
      />
    </div>
  );
};
