import { ComponentProps, ReactElement } from 'react';
import { Intent, MenuItem } from '@blueprintjs/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import GroupListItem from 'shared/GroupList/GroupListItem';
import { Props as TagProps } from 'components/ds/Tag';
import { Customer } from 'actions/teamActions';

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    marginTop: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.ds.grey500}`,
    paddingTop: theme.spacing(2),
  },
}));

type Props = {
  group: Customer;
  onEditClicked: () => void;
  onDeleteClicked: () => void;
  rightTag?: ReactElement<TagProps>;
  userCanEditCustomer?: boolean;
  userCanDeleteCustomer?: boolean;
};

function CustomerGroupListItem(props: Props) {
  const {
    group,
    onDeleteClicked,
    onEditClicked,
    rightTag,
    userCanDeleteCustomer,
    userCanEditCustomer,
  } = props;

  const classes = useStyles();

  const itemActions: ComponentProps<typeof MenuItem>[] = [];

  if (userCanEditCustomer)
    itemActions.push({
      text: 'Edit',
      onClick: () => {
        onEditClicked();
      },
      icon: 'edit',
    });

  if (userCanDeleteCustomer)
    itemActions.push({
      text: 'Delete',
      onClick: () => {
        onDeleteClicked();
      },
      icon: 'trash',
      className: classes.deleteButton,
      intent: Intent.DANGER,
    });

  return (
    <GroupListItem
      itemActions={itemActions}
      key={`end-user-group-item-${group.id}`}
      rightTag={rightTag}
      subTitle={group.id.toString()}
      title={group.name}
    />
  );
}

export default CustomerGroupListItem;
