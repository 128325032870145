import { TypeColumn } from '@inovua/reactdatagrid-enterprise/types';
import { keyBy } from 'utils/standard';

import { ColumnConfigs, renderNumberCell } from 'components/ds/DataGrid/columnUtils';
import { getCellAlignment } from 'components/ds/DataGrid/utils';
import { DATE_TYPES, NUMBER_TYPES } from 'constants/dataConstants';
import { DateDisplayOptions, DisplayOptions, NumberDisplayOptions } from 'constants/types';
import { formatDateField } from 'pages/dashboardPage/charts/utils';
import { DatasetColumn, DatasetRow, DatasetSchema } from 'types/datasets';

type HookArgs = {
  columnConfigs: ColumnConfigs;
  schema: DatasetSchema;
  pivotColumns: string[];
  groupByColumns: string[];
};

type PivotColumns = {
  columns: TypeColumn[];
  groupColumn: TypeColumn;
};

export function generatePivotColumns({
  columnConfigs,
  schema,
  pivotColumns,
  groupByColumns,
}: HookArgs): PivotColumns {
  const pivotSet = new Set(pivotColumns);
  const groupBySet = new Set(groupByColumns);
  const groupBySize = groupBySet.size;

  const schemaByName = keyBy(schema, (col) => col.name);

  const columns = schema.map((columnInfo) => {
    const { name, friendly_name, type } = columnInfo;
    const config = columnConfigs[name];

    const groupSummaryReducer =
      pivotSet.has(name) || groupBySet.has(name) ? undefined : firstReducer;

    return {
      name,
      header: friendly_name,
      defaultFlex: 1,
      minWidth: 100,
      textAlign: getCellAlignment(config?.displayFormatting, type),
      groupSummaryReducer,
      render: ({ data, value }: { value: string; data: { depth: number } }) => {
        if (data.depth !== groupBySize) return null;
        return value;
      },
    };
  });

  const headerNames: string[] = [];
  groupByColumns.forEach((colName) => {
    const schemaCol = schemaByName[colName];
    if (schemaCol) headerNames.push(schemaCol.friendly_name ?? schemaCol.name);
  });

  const groupColumn: TypeColumn = {
    header: headerNames.join(' / '),
    defaultFlex: 1,
    minWidth: groupByColumns.length * 100,
  };

  return { columns, groupColumn };
}

const renderValue = (
  value: string | number | undefined,
  columnInfo: DatasetColumn | undefined,
  config?: { displayFormatting?: DisplayOptions },
): string => {
  if (value === null || value === undefined || !columnInfo) return '';

  const { type } = columnInfo;

  const displayOptions = config?.displayFormatting;
  if (!displayOptions) return String(value);

  if (NUMBER_TYPES.has(type)) {
    return renderNumberCell(value, displayOptions as NumberDisplayOptions);
  } else if (DATE_TYPES.has(type)) {
    return formatDateField(
      value as string,
      type,
      displayOptions as DateDisplayOptions,
      false,
      true,
    );
  }

  return String(value);
};

// This reducer doesn't actually do anything since it should haven nothing to reduce on
const firstReducer = {
  initialValue: 0,
  reducer: (_: number, b: number) => b,
};

export const transformRows = (
  rows: DatasetRow[],
  schema: DatasetSchema,
  columnConfigs: ColumnConfigs,
): DatasetRow[] =>
  rows.map((row) => {
    const newRow: DatasetRow = {};
    schema.forEach((columnInfo) => {
      const name = columnInfo.name;
      newRow[name] = renderValue(row[name], columnInfo, columnConfigs[name]);
    });
    return newRow;
  });
