import { makeStyles } from '@material-ui/core/styles';

import DashboardButtonGroupInput from 'components/dashboardButtonGroupInput';

import { SelectElemConfig, DashboardVariable, DashboardVariableMap } from 'types/dashboardTypes';
import { constructOptions } from 'utils/dropdownUtils';
import { resolveTooltipVariables } from 'utils/variableUtils';
import { DatasetRow } from 'types/datasets';
import { AnalyticsEventTracker } from 'utils/analyticsUtils';
import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import { OnNewValueSelectFunc } from 'types/functionPropTypes';

const useStyles = makeStyles(() => ({
  root: {
    '& .bp3-button-group .bp3-button, .bp3-button-group .bp3-button.bp3-active, .bp3-button-group .bp3-button:hover':
      {
        zIndex: 0,
      },
  },
}));

type Props = {
  analyticsEventTracker?: AnalyticsEventTracker;
  config: SelectElemConfig;
  value?: DashboardVariable;
  datasets: Record<string, Partial<{ _rows: DatasetRow[] }>>;
  disabled?: boolean;
  loading?: boolean;
  variables: DashboardVariableMap;
  onNewValueSelect: OnNewValueSelectFunc;
};

const DashboardToggleElement = ({
  analyticsEventTracker,
  config,
  disabled,
  datasets,
  value,
  onNewValueSelect,
  loading,
  variables,
}: Props) => {
  const classes = useStyles();

  const options = constructOptions(config.valuesConfig, datasets);
  let selectedOption = options.find((option) => option.value === value);

  if (value && !selectedOption) {
    selectedOption = { id: String(value), name: String(value) };
  }

  return (
    <div className={classes.root}>
      <DashboardButtonGroupInput
        fillWidth
        disabled={disabled}
        infoTooltipText={resolveTooltipVariables(config, variables)}
        label={config.label}
        loading={loading}
        onChange={(item) => {
          onNewValueSelect(item?.value, { display: item?.name });
          analyticsEventTracker?.(REPORTED_ANALYTIC_ACTION_TYPES.DROPDOWN_SELECTED);
        }}
        options={options}
        preventUnselect={config.disableCancel}
        selectedItem={selectedOption}
        useFakeLabel={config.label === ''}
      />
    </div>
  );
};

export default DashboardToggleElement;
