import { createAction } from '@reduxjs/toolkit';

import { EnvironmentTag } from 'actions/teamActions';
import { VersionInfo } from 'types/exploResource';
import { defineAPIAction, defineAPIPostAction } from './actionUtils';
import { CanvasVersionConfig } from './canvasConfigActions';
import { ACTION } from './types';

export interface CanvasVersion extends VersionInfo {
  id: number;
  // ID of Canvas not CanvasVersion
  canvas_id: number;
  configuration: CanvasVersionConfig;
  created: string;
  modified: string;
  version_saved_at: string | null;
  published_by_id: number | null;
  deprecated: boolean;
}

export interface EmbedCanvasVersion {
  configuration: CanvasVersionConfig;
  version_number: number;
}

type FetchCanvasVersionsResponse = {
  versions: CanvasVersion[];
  tags: EnvironmentTag[];
};

export const {
  actionFn: fetchCanvasVersions,
  requestAction: fetchCanvasVersionsRequest,
  successAction: fetchCanvasVersionsSuccess,
  errorAction: fetchCanvasVersionsError,
} = defineAPIAction<FetchCanvasVersionsResponse>(
  ACTION.FETCH_CANVAS_VERSIONS,
  'canvases',
  'get_canvas_versions',
  'GET',
);

export const { actionFn: createNewCanvasVersion, successAction: createNewCanvasVersionSuccess } =
  defineAPIAction<{ canvas_version: CanvasVersion }>(
    ACTION.CREATE_NEW_CANVAS_VERSION,
    'canvases',
    'create_new_canvas_version',
    'POST',
  );

type SwitchEditingCanvasVersionArgs = {
  canvasVersion: CanvasVersion;
};

export const switchCurrentlyEditingCanvasVersion = createAction<SwitchEditingCanvasVersionArgs>(
  ACTION.SWITCH_CURRENTLY_EDITING_CANVAS_VERSION,
);

type RevertToCanvasVersionBody = {
  version_number: number;
};

type RevertToCanvasVersionResponse = {
  canvas_version: CanvasVersion;
};

export const { actionFn: revertToCanvasVersion, successAction: revertToCanvasVersionSuccess } =
  defineAPIPostAction<RevertToCanvasVersionBody, RevertToCanvasVersionResponse>(
    ACTION.REVERT_TO_CANVAS_VERSION,
    'canvases',
    'revert_to_canvas_version',
    'POST',
  );

type PublishNewCanvasVersionBody = {
  config: CanvasVersionConfig;
  version_number: number;
  change_comments: string;
};

type PublishNewCanvasVersionResponse = {
  canvas_version: CanvasVersion;
};

export const { actionFn: publishNewCanvasVersion, successAction: publishNewCanvasVersionSuccess } =
  defineAPIPostAction<PublishNewCanvasVersionBody, PublishNewCanvasVersionResponse>(
    ACTION.PUBLISH_NEW_CANVAS_VERSION,
    'canvases',
    'publish_new_canvas_version',
    'POST',
  );

export const {
  actionFn: deleteCurrentCanvasDraft,
  successAction: deleteCurrentCanvasDraftSuccess,
} = defineAPIPostAction<{ version_number: number }, {}>(
  ACTION.DELETE_CURRENT_CANVAS_DRAFT,
  'canvases',
  'delete_draft',
  'POST',
);
