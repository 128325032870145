import { useEffect, FC } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { some } from 'utils/standard';
import { Link } from 'react-router-dom';

import { DeleteConfirmationButton } from 'components/core/DeleteConfirmationButton';
import { sprinkles } from 'components/ds';
import * as styles from '../styles.css';
import { AccessGroupDropdown } from './AccessGroupDropdown';

import { ReduxState } from 'reducers/rootReducer';
import { ParentSchema } from 'actions/dataSourceActions';
import { AccessGroup } from 'actions/teamActions';
import { AccessGroupDataSourcesMap } from 'constants/types';
import { ROUTES } from 'constants/routes';
import { fetchDashboardTemplateList } from 'actions/dashboardTemplateActions';

type Props = {
  accessGroupDataSourcesMap?: AccessGroupDataSourcesMap;
  accessGroupIdToDefaultDataSourceIdUpdates: Record<number, number[]>;
  schema: ParentSchema;
  onDelete: () => void;
  onSchemaNameChange: (val: string) => void;
  updateAccessGroupDefaultDataSource: (
    accessGroupId: number,
    newDefaultDataSourceIds: number[],
  ) => void;
};

export const ManageSchemasSection: FC<Props> = ({
  accessGroupDataSourcesMap,
  accessGroupIdToDefaultDataSourceIdUpdates,
  schema,
  onDelete,
  onSchemaNameChange,
  updateAccessGroupDefaultDataSource,
}) => {
  const dispatch = useDispatch();

  const { teamData, dataSources, dashboardTemplates } = useSelector(
    (state: ReduxState) => ({
      teamData: state.teamData.data,
      dataSources: state.dataSourceList.dataSources,
      dashboardTemplates: state.dashboard.dashboardTemplateList,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (!dashboardTemplates) dispatch(fetchDashboardTemplateList({ id: teamData?.id }));
  }, [dispatch, dashboardTemplates, teamData?.id]);

  const accessGroups = teamData?.access_groups;

  if (!accessGroups || !dataSources || !accessGroupDataSourcesMap) return null;

  const dashboardTemplatesTeam = dashboardTemplates?.filter(
    (template) => template.team_id === teamData.id,
  );

  const accessGroupDropdown = (accessGroup: AccessGroup) => {
    return (
      <AccessGroupDropdown
        isInModal
        accessGroup={accessGroup}
        accessGroupDataSourcesMap={accessGroupDataSourcesMap}
        accessGroupIdToDefaultDataSourceIdUpdates={accessGroupIdToDefaultDataSourceIdUpdates}
        dataSources={dataSources}
        updateAccessGroupDefaultDataSource={updateAccessGroupDefaultDataSource}
      />
    );
  };

  const isDisabled = some(dashboardTemplatesTeam, (template) =>
    some(template.latest_versions, (version) =>
      some(version.configuration.datasets, (dataset) => dataset.parent_schema_id === schema.id),
    ),
  );

  return (
    <div className={styles.modalGroup}>
      <div className={styles.section}>
        <div className={sprinkles({ display: 'flex', gap: 'sp2', padding: 'sp2' })}>
          <div className={styles.labelAndInput}>
            <p className={styles.label}>Schema Name</p>
            <div className={styles.modalInputAndDelete}>
              <input
                className={styles.input}
                onChange={(event) => onSchemaNameChange(event.currentTarget.value)}
                placeholder={schema.name}
              />
              <DeleteConfirmationButton
                disabled={isDisabled}
                onDelete={onDelete}
                tooltipText={
                  isDisabled ? 'Cannot delete a schema with dashboard datasets.' : undefined
                }
              />
            </div>
          </div>
          {accessGroups?.length === 1 ? accessGroupDropdown(accessGroups[0]) : null}
        </div>
      </div>

      {accessGroups.length > 1 ? (
        <>
          <div className={styles.divider} />
          <div className={styles.modalVisibilitySelection}>
            <div className={sprinkles({ color: 'contentSecondary', body: 'b3' })}>
              Define which data sources should be available to a visibility group on the{' '}
              <Link to={ROUTES.SETTINGS_ACCESS_GROUPS}>Settings Page</Link>.
            </div>
            {accessGroups.map(accessGroupDropdown)}
          </div>
        </>
      ) : null}
    </div>
  );
};
