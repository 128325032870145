import { useDispatch } from 'react-redux';

import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES, V2TwoDimensionChartInstructions, YAxisFormat } from 'constants/types';
import { V2_NUMBER_FORMATS } from 'constants/dataConstants';
import {
  DEFAULT_Y_AXIS_FORMAT_INDEX,
  updateYAxisFormat,
} from 'pages/dashboardPage/charts/utils/multiYAxisUtils';
import { sharedStyles } from './styles';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2TwoDimensionChartInstructions;
};

export default function InteractiveTooltipConfig({ visualizationType, instructions }: Props) {
  const classes = sharedStyles();
  const dispatch = useDispatch();

  const yAxisFormat = instructions.yAxisFormats?.[DEFAULT_Y_AXIS_FORMAT_INDEX];
  const selectedFormat = yAxisFormat?.numberFormat || V2_NUMBER_FORMATS.NUMBER;

  const updateYAxis = (updatedYAxisFormat: YAxisFormat) => {
    const newYAxisFormat = { ...yAxisFormat, ...updatedYAxisFormat };
    dispatch(
      updateVisualizeOperation(updateYAxisFormat(newYAxisFormat, instructions), visualizationType),
    );
  };

  return (
    <div className={classes.root}>
      <ToggleButtonGroup fillWidth className={classes.configInput} label="Value Format">
        {[V2_NUMBER_FORMATS.NUMBER, V2_NUMBER_FORMATS.CURRENCY, V2_NUMBER_FORMATS.PERCENT].map(
          (numberFormat) => (
            <ToggleButton
              active={numberFormat.id === selectedFormat.id}
              key={numberFormat.id}
              onClick={() => updateYAxis({ numberFormat })}
              text={numberFormat.abbreviation}
            />
          ),
        )}
      </ToggleButtonGroup>

      <InputWithBlurSave
        containerClassName={classes.configInput}
        initialValue={String(yAxisFormat?.decimalPlaces ?? 2)}
        label="Value Decimal Places"
        onNewValueSubmitted={(newValue: string) => {
          const intValue = parseInt(newValue);
          updateYAxis({ decimalPlaces: intValue > 0 ? intValue : 0 });
        }}
      />
    </div>
  );
}
