import { FC } from 'react';

import { IconButton, Switch } from 'components/ds';
import * as styles from './item.css';

type Props = {
  column: { type: string; name: string };
  isVisible: boolean;
  toggleOn: boolean;
  alwaysShowToggle?: boolean;

  onToggle: (newVal: boolean) => void;
  onVisibilityToggle: (newVal: boolean) => void;
};

export const DatasetColumnSelectionItem: FC<Props> = ({
  column,
  isVisible,
  onToggle,
  onVisibilityToggle,
  toggleOn,
  alwaysShowToggle,
}) => {
  return (
    <div className={styles.column}>
      <IconButton
        name={isVisible ? 'eye-open' : 'eye-closed'}
        onClick={() => onVisibilityToggle(!isVisible)}
        type="secondary"
      />

      <div className={isVisible ? styles.visibleColumnInfo : styles.hiddenColumnInfo}>
        <div className={styles.columnName}>{column.name}</div>
        <div className={styles.columnType}>{column.type.toLocaleLowerCase()}</div>
        {isVisible || alwaysShowToggle ? (
          <Switch onChange={() => onToggle(!toggleOn)} switchOn={toggleOn} />
        ) : null}
      </div>
    </div>
  );
};
