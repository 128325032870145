import { useState, FC } from 'react';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import {
  CategoryChartColumnInfo,
  DateDisplayOptions,
  DisplayOptions,
  NumberDisplayDisplayType,
  NumberDisplayOptions,
  Total,
} from 'constants/types';
import { Button } from '@blueprintjs/core';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import cx from 'classnames';
import { formatNumberValue, formatValue } from 'pages/dashboardPage/charts/utils';
import { TableProgressBar } from 'components/TableProgressBar';
import { DATE_TYPES, NUMBER_TYPES, V2_NUMBER_FORMATS, STRING } from 'constants/dataConstants';
import { formatDateField } from 'pages/dashboardPage/charts/utils';

const useStyles = makeStyles((theme: Theme) => ({
  totalColumns: ({ totals, columnWidth }: Pick<Props, 'totals' | 'columnWidth' | 'index'>) => ({
    display: 'flex',
    width: `${columnWidth * totals.length}%`,
    justifyContent: 'space-between',
    padding: `0px ${theme.spacing(3)}px`,
  }),
  listItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  item: ({ index, columnWidth }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: theme.spacing(8),
    width: `${columnWidth}%`,
    margin: `${theme.spacing(2)}px 0px`,
    paddingLeft: `${index * theme.spacing(6) + theme.spacing(1)}px`,
  }),
  column: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: `${theme.palette.ds.grey400} 0px -1px 0px inset, 0px 0px 0px inset`,
  },
  totals: {
    width: '100%',
    textAlign: 'center',
    '&.LEFT_ALIGN': {
      textAlign: 'left',
      paddingLeft: theme.spacing(4),
    },
    '&.CENTER_ALIGN': {
      textAlign: 'center',
    },
    '&.RIGHT_ALIGN': {
      textAlign: 'right',
      paddingRight: theme.spacing(4),
    },
  },
  noChildren: {
    paddingLeft: theme.spacing(4),
  },
  progressBar: {
    height: 12,
  },
  progressBarValue: {
    fontSize: 12,
    marginRight: theme.spacing(1),
  },
  progressBarTooltip: {
    width: '100%',
  },
}));

type Props = {
  category: CategoryChartColumnInfo;
  categoryAggName: string;
  name: string;
  totals: Total[];
  children: JSX.Element[];
  columnWidth: number;
  showCategories?: boolean;
  index: number;
  groupByDisplayOptions?: Record<string, DisplayOptions>;
  numberDisplayOptions?: Record<string, NumberDisplayOptions>;
  ignoreInvalidDates?: boolean;
};

export const DashboardCollapsibleListItem: FC<Props> = ({
  name,
  columnWidth,
  categoryAggName,
  children,
  groupByDisplayOptions,
  numberDisplayOptions,
  ignoreInvalidDates,
  totals,
  showCategories,
  category,
  index,
}) => {
  const [isOpen, toggleItemOpen] = useState(false);
  const theme = useTheme();

  const classes = useStyles({ totals, columnWidth, index });

  const formatNumberField = (
    value: number,
    numberFormatOption: NumberDisplayOptions | undefined,
  ) => {
    if (numberFormatOption) {
      const formattedValue = formatNumberValue(numberFormatOption, value, numberFormatOption.goal);

      if (numberFormatOption.displayType === NumberDisplayDisplayType.PROGRESS_BAR) {
        const progressBarGoal = numberFormatOption.displayTypeOptions?.progressBarGoal;

        return (
          <TableProgressBar
            backgroundColor={theme.palette.ds.lightBlue}
            color={theme.palette.ds.blue}
            disableTooltip={numberFormatOption.disableHoverTooltip}
            formattedValue={formattedValue}
            progressBarGoal={progressBarGoal}
            value={value}
          />
        );
      }

      return formattedValue;
    } else {
      return formatValue({
        value: Number(value),
        decimalPlaces: 0,
        formatId: V2_NUMBER_FORMATS.NUMBER.id,
        hasCommas: false,
      });
    }
  };

  const formatTotalValue = (t: Total) => {
    if (t.category) return t.category;

    return formatNumberField(Number(t.total) || 0, numberDisplayOptions?.[t.name]);
  };

  const formatGroupByField = () => {
    const category_type = category.column.type;

    if (category_type === undefined || category_type === STRING) return name;

    if (DATE_TYPES.has(category_type)) {
      return formatDateField(
        name,
        category_type,
        groupByDisplayOptions?.[categoryAggName] as DateDisplayOptions,
        ignoreInvalidDates,
      );
    }

    if (NUMBER_TYPES.has(category_type)) {
      return formatNumberField(
        Number(name),
        groupByDisplayOptions?.[categoryAggName] as NumberDisplayOptions,
      );
    }

    return name;
  };

  return (
    <div className={classes.listItem}>
      <div className={classes.column}>
        <div className={cx(classes.item, GLOBAL_STYLE_CLASSNAMES.text.body.primary)}>
          {children.length > 0 && (
            <Button
              minimal
              icon={isOpen ? 'chevron-down' : 'chevron-right'}
              onClick={() => toggleItemOpen(!isOpen)}
            />
          )}
          <div
            className={cx(GLOBAL_STYLE_CLASSNAMES.text.body.primary, {
              [classes.noChildren]: children.length === 0,
            })}>
            {showCategories && (
              <span className={GLOBAL_STYLE_CLASSNAMES.text.secondaryColor.color}>
                {(category.column.friendly_name || category.column.name) + ': '}
              </span>
            )}
            {formatGroupByField()}
          </div>
        </div>
        <></>
        <div className={classes.totalColumns}>
          {totals.map((t) => (
            <div
              className={cx(GLOBAL_STYLE_CLASSNAMES.text.body.primary, classes.totals)}
              key={t.name}>
              {formatTotalValue(t)}
            </div>
          ))}
        </div>
      </div>
      <div>{isOpen ? children : null}</div>
    </div>
  );
};
