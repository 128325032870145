import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { embedFetchShareData, embedFetchShareDataWithPassword } from 'actions/shareActions';
import EmbeddedDashboard, { GenericLoadingSpinner } from 'components/EmbeddedDashboard';
import SharePasswordRequiredPage from './sharePasswordRequiredPage';
import { makeStyles } from '@material-ui/styles';
import { VIEW_MODE } from 'types/dashboardTypes';

type MatchParams = {
  shareId: string;
};

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
  },
}));

export default function SharedDashboardPage() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [faviconUrl, setFaviconUrl] = useState<string>('');
  const [siteTitle, setSiteTitle] = useState<string>('');
  const [isStrictViewingMode, setIsStrictViewingMode] = useState<boolean>(false);
  const [customerToken, setCustomerToken] = useState<string>('');
  const [dashboardEmbedId, setDashboardEmbedId] = useState<string>('');
  const [versionNumber, setVersionNumber] = useState<number>();
  const [isPasswordProtected, setIsPasswordProtected] = useState<boolean>(false);
  const [isUsernameProtected, setIsUsernameProtected] = useState<boolean>(false);
  const [passwordWasVerified, setPasswordWasVerified] = useState<boolean>(false);

  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    params: { shareId },
    url,
  } = useRouteMatch<MatchParams>();

  useEffect(() => {
    dispatch(
      embedFetchShareData(
        { id: shareId, customerToken },
        (shareData) => {
          setCustomerToken(shareData.customer_token);
          setDashboardEmbedId(shareData.dashboard_embed_id);
          setVersionNumber(shareData.version_number);
          setIsLoading(false);
          setIsStrictViewingMode(shareData.is_strict_viewing_mode);
          setFaviconUrl(shareData.favicon_url ?? '');
          setSiteTitle(shareData.site_title ?? '');
        },
        (error) => {
          // API returns 405 when we send a GET for a password-protected link
          if (error.status === 405) {
            setIsPasswordProtected(true);
            if (error.detail.indexOf('username and password required') >= 0) {
              setIsUsernameProtected(true);
            }
            setIsLoading(false);
          }
        },
      ),
    );
  }, [dispatch, shareId, customerToken]);

  useEffect(() => {
    const faviconElement = document.getElementById('site-favicon') as HTMLAnchorElement;
    const siteTitleElement = document.getElementById('site-title') as HTMLAnchorElement;

    if (faviconElement && faviconUrl !== '')
      faviconElement.href = `https://www.google.com/s2/favicons?domain=${faviconUrl}`;
    if (siteTitleElement && siteTitle !== '') siteTitleElement.innerHTML = `${siteTitle}`;
  }, [faviconUrl, siteTitle]);

  if (isLoading) {
    return <GenericLoadingSpinner embedType="shared" />;
  }

  return (
    <div className={classes.root}>
      {isPasswordProtected && !passwordWasVerified && (
        <SharePasswordRequiredPage
          fetchShareData={(password: string, onFailure: () => void, username?: string) => {
            dispatch(
              embedFetchShareDataWithPassword(
                {
                  id: shareId,
                  customerToken,
                  postData: { password, username },
                },
                (shareData) => {
                  setCustomerToken(shareData.customer_token);
                  setDashboardEmbedId(shareData.dashboard_embed_id);
                  setVersionNumber(shareData.version_number);
                  setIsStrictViewingMode(shareData.is_strict_viewing_mode);
                  setIsLoading(false);
                  setPasswordWasVerified(true);
                },
                (e) => {
                  if (e.status === 403 || e.status === 405) onFailure();
                },
              ),
            );
          }}
          isUsernameProtected={isUsernameProtected}
        />
      )}
      {(!isPasswordProtected || passwordWasVerified) && (
        <EmbeddedDashboard
          customerToken={customerToken}
          dashboardEmbedId={dashboardEmbedId}
          embedType="shared"
          // Keeping the old way to set strict mode through the URL for backwards compatability
          isStrict={isStrictViewingMode || url.toLocaleUpperCase().endsWith('STRICT')}
          versionNumber={versionNumber}
          viewMode={VIEW_MODE.SHARE}
        />
      )}
    </div>
  );
}
