import { FC } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { BaseSaveModal } from 'pages/ReportBuilder/ModalViews/BaseSaveModal';

import { createCustomerReport } from 'actions/customerReportActions';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';

type Props = { reportName: string };

export const SaveAsModal: FC<Props> = ({ reportName }) => {
  const dispatch = useDispatch();

  const { requestInfo, currentConfig } = useSelector(
    (state: ReportBuilderReduxState) => ({
      requestInfo: state.embeddedReportBuilder.requestInfo,
      currentConfig: state.reportEditing.currentConfig,
    }),
    shallowEqual,
  );

  const handleSubmit = (newReportName: string) =>
    dispatch(
      createCustomerReport({
        customerToken: requestInfo.customerToken,
        postData: {
          report_builder_embed_id: requestInfo.embedId,
          name: newReportName,
          config: currentConfig,
        },
      }),
    );

  if (!currentConfig) return null;

  return <BaseSaveModal onSubmit={handleSubmit} reportName={reportName} />;
};
