import { makeStyles, Theme } from '@material-ui/core';
import produce from 'immer';

import DropdownSelect from 'shared/DropdownSelect';

import { VisualizeOperation } from 'types/dataPanelTemplate';
import { Aggregation, SelectedDropdownInputItem, V2KPIChartInstructions } from 'constants/types';
import { CanvasDataset } from 'actions/canvasConfigActions';
import { getGroupOptions } from 'pages/dashboardPage/DataPanelConfigV2/DataConfigTab/vizConfigs/utils';
import { getDefaultAgg } from 'utils/aggUtils';

const useStyles = makeStyles((theme: Theme) => ({
  flexContainer: { flex: 1 },
  inputRow: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
}));

type Props = {
  dataset: CanvasDataset;
  usePortal: boolean;
  visualizeOperation: VisualizeOperation;

  updateVizOp: (newViz: VisualizeOperation) => void;
};

export default function KPIConfig({
  dataset,
  usePortal,
  visualizeOperation,
  updateVizOp,
}: Props): JSX.Element {
  const classes = useStyles();

  const updateKpiInstructions = (update: Partial<V2KPIChartInstructions>) => {
    const newViz = produce(visualizeOperation, (draft) => {
      draft.instructions.V2_KPI = { ...draft.instructions.V2_KPI, ...update };
    });
    updateVizOp(newViz);
  };

  const { aggColumn } = visualizeOperation.instructions.V2_KPI || {};

  const aggColOptions: SelectedDropdownInputItem[] = [];
  dataset.schema?.forEach((col) => {
    const colOption = dataset.columnOptions[col.name];
    if (!colOption || !colOption.isVisible) return;

    aggColOptions.push({ id: col.name, name: colOption.name });
  });

  const groupOptions = aggColumn ? getGroupOptions(aggColumn) : [];
  const aggGroupOptions: SelectedDropdownInputItem[] =
    groupOptions[0]?.options.map((opt) => ({
      id: opt.id,
      name: opt.name,
    })) ?? [];

  return (
    <>
      <div className={classes.inputRow}>
        <DropdownSelect
          fillWidth
          ignoreCustomStyles
          minimal
          containerClassName={classes.flexContainer}
          filterable={false}
          label="Aggregation"
          noSelectionText="Column"
          onChange={(item) => {
            if (aggColumn?.column.name === item.id) return;
            const col = dataset.schema?.find((col) => col.name === item.id);
            if (!col) return;
            updateKpiInstructions({ aggColumn: { column: col, agg: getDefaultAgg(col.type) } });
          }}
          options={aggColOptions}
          selectedItem={
            aggColumn?.column
              ? aggColOptions.find((col) => col.id === aggColumn.column.name)
              : undefined
          }
          usePortal={usePortal}
        />
        <DropdownSelect
          ignoreCustomStyles
          minimal
          useFakeLabel
          disabled={!aggColumn?.column}
          filterable={false}
          noSelectionText="Aggregation"
          onChange={(item) => {
            if (!aggColumn || aggColumn.agg.id === item.id) return;
            updateKpiInstructions({
              aggColumn: { ...aggColumn, agg: { id: item.id as Aggregation } },
            });
          }}
          options={aggGroupOptions}
          selectedItem={aggGroupOptions.find((opt) => opt.id === aggColumn?.agg.id)}
          usePortal={usePortal}
        />
      </div>
    </>
  );
}
