import produce from 'immer';
import { useDispatch } from 'react-redux';
import { IconName } from '@blueprintjs/icons';

import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';

import { COMBO_CHART_DATA_FORMATS } from 'constants/dashboardConstants';
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import {
  AggedChartColumnInfo,
  OPERATION_TYPES,
  V2TwoDimensionChartInstructions,
} from 'constants/types';
import { getColDisplayText } from 'pages/dashboardPage/DataPanelConfigV2/DataConfigTab/vizConfigs/utils';
import { sharedStyles } from './styles';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2TwoDimensionChartInstructions;
};

export default function ComboChartColumnTypeConfig({ visualizationType, instructions }: Props) {
  const classes = sharedStyles();
  const dispatch = useDispatch();

  let aggCols = instructions.aggColumns;
  if (instructions.colorColumnOptions?.length) aggCols = aggCols?.slice(0, 1);

  const onComboUpdateChartType = (
    col: AggedChartColumnInfo,
    chartType: COMBO_CHART_DATA_FORMATS,
  ) => {
    const newInstructions = produce(instructions, (draft) => {
      const changedCol = draft.aggColumns?.find(
        (newCol) => newCol.column.name === col.column.name && newCol.agg.id === col.agg.id,
      );
      if (changedCol) changedCol.column.dataFormat = chartType;
    });

    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  return (
    <div className={classes.root}>
      {aggCols?.map((col, index) => {
        const selectedType = col.column.dataFormat || COMBO_CHART_DATA_FORMATS.LINE;
        return (
          <ToggleButtonGroup
            fillWidth
            className={classes.configInput}
            key={index}
            label={getColDisplayText(col)}>
            {Object.values(COMBO_CHART_DATA_FORMATS).map((dataFormat) => {
              let icon: IconName;
              let text;
              switch (dataFormat) {
                case COMBO_CHART_DATA_FORMATS.LINE:
                  icon = 'timeline-line-chart';
                  text = 'Line';
                  break;
                case COMBO_CHART_DATA_FORMATS.DOT:
                  icon = 'scatter-plot';
                  text = 'Dot';
                  break;
                case COMBO_CHART_DATA_FORMATS.BAR:
                  icon = 'timeline-bar-chart';
                  text = 'Bar';
                  break;
              }
              return (
                <ToggleButton
                  active={selectedType === dataFormat}
                  icon={icon}
                  key={`dataFormat-${dataFormat}`}
                  onClick={() => onComboUpdateChartType(col, dataFormat)}
                  text={text}
                />
              );
            })}
          </ToggleButtonGroup>
        );
      })}
    </div>
  );
}
