import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import DropdownSelect from 'shared/DropdownSelect';
import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';
import ColorPickerButton from 'shared/ColorPicker';
import InputLabel from 'shared/InputLabel';

import { PlotLineType, GoalLineFormat, OPERATION_TYPES } from 'constants/types';
import { PLOT_LINE_CONFIG } from 'constants/dashboardConstants';
import RangeInput from 'shared/RangeInput';
import FlexBox, { HorizontalAlignment, VerticalAlignment } from 'components/core/FlexBox';
import { sharedStyles } from '../styles';

const colorPickerButtonSize = 32;
const wideColorPickerButtonWidth = 286;

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: theme.palette.ds.grey400,
    marginBottom: theme.spacing(2),
  },
  lineTypeDropdown: {
    width: 186,
  },
  lineWidthInput: {
    width: 55,
  },
  colerPickerWrapper: {
    '& .bp3-popover-wrapper': {
      width: colorPickerButtonSize,
      height: colorPickerButtonSize,
    },
  },
  colorPickerBtn: {
    width: colorPickerButtonSize,
    height: colorPickerButtonSize,
  },
  wideColerPickerWrapper: {
    '& .bp3-popover-wrapper': {
      width: wideColorPickerButtonWidth,
      height: colorPickerButtonSize,
    },
  },
  wideColorPickerBtn: {
    width: wideColorPickerButtonWidth,
    height: colorPickerButtonSize,
  },
}));

type Props = {
  config: GoalLineFormat;
  updateConfig: (newConfig: GoalLineFormat) => void;
  visualizationType: OPERATION_TYPES;
};

export default function GoalLineConfig({ config, updateConfig, visualizationType }: Props) {
  const classes = useStyles();
  const sharedClasses = sharedStyles();

  const selectedLineType = config.lineType || PlotLineType.SOLID;

  return (
    <div className={sharedClasses.root}>
      <FlexBox
        className={cx(classes.colerPickerWrapper, sharedClasses.configInput)}
        horizontalAlignment={HorizontalAlignment.SPACE_BETWEEN}
        verticalAlignment={VerticalAlignment.BOTTOM}>
        <InputWithBlurSave
          fillWidth
          containerClassName={sharedClasses.leftElemInRow}
          initialValue={config.label}
          label="Label"
          onNewValueSubmitted={(newValue) => updateConfig({ ...config, label: newValue })}
        />
        <ColorPickerButton
          btnClassName={classes.colorPickerBtn}
          color={config.labelColor ?? '#FF0000'}
          colorPalette={[]}
          onColorChange={(newColor) => {
            updateConfig({ ...config, labelColor: newColor });
          }}
        />
      </FlexBox>

      <ToggleButtonGroup fillWidth className={sharedClasses.configInput} label="Goal type">
        <ToggleButton
          active={!config.isGoalBand}
          onClick={() => updateConfig({ ...config, isGoalBand: false })}
          text="Goal line"
        />
        <ToggleButton
          active={config.isGoalBand}
          onClick={() => updateConfig({ ...config, isGoalBand: true })}
          text="Goal range"
        />
      </ToggleButtonGroup>
      {visualizationType === OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2 ? (
        <ToggleButtonGroup fillWidth className={sharedClasses.configInput} label="Orientation">
          <ToggleButton
            active={!config.isXAxisGoal}
            onClick={() => updateConfig({ ...config, isXAxisGoal: false })}
            text="Horizontal"
          />
          <ToggleButton
            active={config.isXAxisGoal}
            onClick={() => updateConfig({ ...config, isXAxisGoal: true })}
            text="Vertical"
          />
        </ToggleButtonGroup>
      ) : null}

      {config.isGoalBand ? (
        <RangeInput
          usesVariables
          className={sharedClasses.configInput}
          endLabel="Maximum Value"
          endVal={config.goalValueMax}
          onNewRange={(newStart?: string, newEnd?: string) => {
            const newConfig = {
              ...config,
              goalValue: newStart,
              goalValueMax: newEnd,
            };

            updateConfig(newConfig);
          }}
          startLabel="Minimum Value"
          startVal={String(config.goalValue ?? 100)}
        />
      ) : (
        <InputWithBlurSave
          containerClassName={sharedClasses.configInput}
          helpIcon="code"
          helpText="This field can take in variables"
          initialValue={String(config.goalValue ?? 100)}
          label="Goal Value"
          onNewValueSubmitted={(newValue) => {
            updateConfig({ ...config, goalValue: newValue });
          }}
        />
      )}

      {!config.isGoalBand ? (
        <FlexBox
          className={sharedClasses.configInput}
          horizontalAlignment={HorizontalAlignment.SPACE_BETWEEN}
          verticalAlignment={VerticalAlignment.BOTTOM}>
          <DropdownSelect
            btnMinimal
            fillWidth
            minimal
            containerClassName={classes.lineTypeDropdown}
            disabled={config.isGoalBand}
            filterable={false}
            label="Line Type"
            noSelectionText="Select a Line Type"
            onChange={(item) =>
              updateConfig({
                ...config,
                lineType: item.id as PlotLineType,
              })
            }
            options={Object.values(PLOT_LINE_CONFIG)}
            selectedItem={PLOT_LINE_CONFIG[selectedLineType]}
          />

          <InputWithBlurSave
            hideRightIconInteractions
            containerClassName={classes.lineWidthInput}
            disabled={config.isGoalBand}
            initialValue={`${config.lineWidth || 1}px`}
            label="Width"
            onNewValueSubmitted={(newWidthString) => {
              const newWidth = Number(newWidthString.replace('px', ''));
              if (isNaN(newWidth) || newWidth <= 0) return;
              updateConfig({
                ...config,
                lineWidth: newWidth,
              });
            }}
          />
          <ColorPickerButton
            btnClassName={classes.colorPickerBtn}
            color={config.lineColor ?? '#FF0000'}
            colorPalette={[]}
            onColorChange={(newColor) => {
              updateConfig({ ...config, lineColor: newColor });
            }}
          />
        </FlexBox>
      ) : (
        <div className={cx(sharedClasses.configInput, classes.wideColerPickerWrapper)}>
          <InputLabel text="Color" />
          <ColorPickerButton
            btnClassName={classes.wideColorPickerBtn}
            color={config.lineColor ?? '#FF0000'}
            colorPalette={[]}
            onColorChange={(newColor) => {
              updateConfig({ ...config, lineColor: newColor });
            }}
          />
        </div>
      )}
    </div>
  );
}
