import { AnyAction, createAsyncThunk, ThunkAction } from '@reduxjs/toolkit';
import * as RD from 'remotedata';
import { AxiosRequestConfig } from 'axios';

import { createApiRequestConfig } from 'actions/actionUtils';
import { CustomerReport } from 'actions/customerReportActions';
import { ACTION } from 'actions/types';
import { ReduxState } from 'reducers/rootReducer';
import { makeThunkRequest } from 'utils/thunkUtils';
import {
  clearSelectedReport,
  openCustomerReport,
  setLoadingSchemaInfo,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import {
  clearVersionForPreview,
  setVersionForPreview,
} from 'reportBuilderContent/reducers/embeddedReportBuilderReducer';
import { DatabaseUnsupportedOperations, DatasetRow, DatasetSchema } from 'types/datasets';
import { getCurrentView, getViewRequestParams, ViewRequestParams } from 'utils/customerReportUtils';

type FetchReportBuilderDatasetData = {
  rows: DatasetRow[];
  schema: DatasetSchema;
  unsupported_operations: DatabaseUnsupportedOperations[];
};

type FetchReportBuilderDataBody = {
  query: string;
  parent_schema_id: number;
  customer_id: number;
  page: number;
} & Partial<ViewRequestParams>;

const getPreviewRequest = (
  query: string,
  parentSchemaId: number,
  customerId: number,
  page?: number,
  viewParams?: ViewRequestParams,
): { request: AxiosRequestConfig; postData: FetchReportBuilderDataBody } => {
  const postData: FetchReportBuilderDataBody = {
    query: query,
    parent_schema_id: parentSchemaId,
    customer_id: customerId,
    page: page ?? 1,
    ...viewParams,
  };
  return {
    request: createApiRequestConfig('report_builder/get_dataset_data/', 'POST', postData, false),
    postData,
  };
};

const getRowCountRequestConfig = (postData: FetchReportBuilderDataBody) => {
  return createApiRequestConfig('report_builder/get_dataset_row_count/', 'POST', postData, false);
};

type FetchReportBuilderDatasetBody = {
  datasetId: string;
  save?: boolean;
  switchedToDataset?: boolean;
  page?: number;
};

export const fetchReportBuilderDataset = createAsyncThunk<
  FetchReportBuilderDatasetData,
  FetchReportBuilderDatasetBody,
  { state: ReduxState }
>(
  ACTION.FETCH_REPORT_BUILDER_DATASET_PREVIEW,
  async ({ datasetId, page }, { getState, dispatch }) => {
    const { reportBuilderEdit, customers } = getState();

    const customerId = customers.selectedGroupId;
    const dataset = RD.isSuccess(reportBuilderEdit.config)
      ? reportBuilderEdit.config.data.datasets[datasetId]
      : undefined;

    let requestConfig: AxiosRequestConfig | null = null;
    let onSuccessFunc: (() => void) | undefined = undefined;
    if (dataset && customerId) {
      const query = dataset.queryDraft ?? dataset.query;
      const { request, postData } = getPreviewRequest(
        query,
        dataset.parent_schema_id,
        customerId,
        page,
      );
      requestConfig = request;

      if (page === undefined) {
        onSuccessFunc = () => dispatch(fetchReportBuilderDatasetRowCount({ datasetId, postData }));
      }
    }

    return makeThunkRequest(
      requestConfig,
      'Error loading rows for report builder dataset preview',
      onSuccessFunc,
    );
  },
  {
    condition: ({ switchedToDataset, datasetId }, { getState }) => {
      const { datasetData, config } = getState().reportBuilderEdit;

      // If dataset has already been loaded don't need to call again
      if (switchedToDataset && datasetId in datasetData) return false;

      const dataset = RD.isSuccess(config) ? config.data.datasets[datasetId] : undefined;
      if (!dataset) return false;

      // If no query then no need to make request
      const query = dataset.queryDraft ?? dataset.query;
      return query.trim() !== '';
    },
  },
);

export const fetchReportBuilderDatasetRowCount = createAsyncThunk<
  { row_count: number },
  { datasetId: string; postData: FetchReportBuilderDataBody },
  { state: ReduxState }
>(ACTION.FETCH_REPORT_BUILDER_DATASET_ROW_COUNT, async ({ postData }) => {
  const requestConfig = getRowCountRequestConfig(postData);
  return makeThunkRequest(requestConfig, 'Error loading row count for report builder dataset');
});

// Thunks for preview mode in app

export const setUpReportBuilderPreview =
  (customerReport: CustomerReport): ThunkAction<void, ReduxState, unknown, AnyAction> =>
  (dispatch, getState) => {
    const { versionInfo, config } = getState().reportBuilderEdit;
    if (!versionInfo || !RD.isSuccess(config)) return;

    dispatch(openCustomerReport(customerReport));
    dispatch(
      setVersionForPreview({ config: config.data, version_number: versionInfo.version_number }),
    );
  };

export const clearReportBuilderPreview =
  (): ThunkAction<void, ReduxState, unknown, AnyAction> => (dispatch) => {
    dispatch(clearVersionForPreview());
    dispatch(clearSelectedReport());
  };

export const fetchPreviewModalData = createAsyncThunk<
  FetchReportBuilderDatasetData,
  string,
  { state: ReduxState }
>(ACTION.FETCH_REPORT_BUILDER_PREVIEW_MODAL_DATA, async (datasetId, { getState }) => {
  const { customers, embeddedReportBuilder } = getState();

  const customerId = customers.selectedGroupId;
  const dataset = embeddedReportBuilder.reportBuilderVersion?.config.datasets[datasetId];

  let requestConfig: AxiosRequestConfig | null = null;
  if (dataset && customerId) {
    requestConfig = getPreviewRequest(dataset.query, dataset.parent_schema_id, customerId).request;
  }

  return makeThunkRequest(requestConfig, 'Error loading modal data in preview');
});

export const fetchPreviewReportData = createAsyncThunk<
  FetchReportBuilderDatasetData,
  number | undefined,
  { state: ReduxState }
>(ACTION.FETCH_REPORT_BUILDER_PREVIEW_REPORT_DATA, async (page, { dispatch, getState }) => {
  const { customers, embeddedReportBuilder, reportEditing } = getState();
  const { currentConfig, currentView } = reportEditing;

  const viewConfig = getCurrentView(currentConfig?.views, currentView);

  let onSuccessFunc: (() => void) | undefined;

  const customerId = customers.selectedGroupId;
  const dataset =
    embeddedReportBuilder.reportBuilderVersion?.config.datasets[
      currentConfig?.dataInfo?.datasetId ?? ''
    ];

  let requestConfig: AxiosRequestConfig | null = null;
  if (dataset && customerId && viewConfig) {
    const viewParams = getViewRequestParams(viewConfig);
    const { request, postData } = getPreviewRequest(
      dataset.query,
      dataset.parent_schema_id,
      customerId,
      page,
      viewParams,
    );
    requestConfig = request;

    if (page === undefined && !viewParams.is_pivot_request) {
      onSuccessFunc = () => dispatch(fetchPreviewReportRowCount(postData));
    }

    dispatch(
      setLoadingSchemaInfo({
        groupBys: viewConfig.groupBys ?? [],
        aggs: viewConfig.aggregations ?? [],
        columnGroupBys: viewConfig.columnGroupBys,
      }),
    );
  }

  return makeThunkRequest(requestConfig, 'Error loading report data in preview', onSuccessFunc);
});

export const fetchPreviewReportRowCount = createAsyncThunk<
  { row_count: number },
  FetchReportBuilderDataBody,
  { state: ReduxState }
>(ACTION.FETCH_REPORT_BUILDER_PREVIEW_REPORT_ROW_COUNT, async (postData) => {
  const requestConfig = getRowCountRequestConfig(postData);
  return makeThunkRequest(requestConfig, 'Error loading row count report builder preview');
});
