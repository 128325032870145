import { defineEmbedPostAction, defineEmbedAction } from './actionUtils';
import { ACTION } from 'actions/types';

import {
  FetchDashboardDatasetPreviewData,
  FetchDataPanelData,
  ExportUrlResponse,
  FetchDataPanelRowCountData,
} from 'actions/responseTypes';
import { FetchDashboardVersionsResponse } from './dashboardV2Actions';
import {
  DownloadDataPanelSpreadsheetBody,
  FetchDataPanelBody,
  FetchDataPanelRowCountBody,
  FetchSecondaryDataBody,
} from './dataPanelTemplateAction';
import { Customer, EmbedTeam } from 'actions/teamActions';
import { GlobalStyleConfig } from 'globalStyles/types';
import { EmbedDashboardTemplate, FetchShareIdData } from 'actions/dashboardTemplateActions';
import { DashboardVersion } from 'types/dashboardVersion';
import { FetchDashboardDatasetPreviewBody } from './datasetActions';
import { createAction } from '@reduxjs/toolkit';
import { VisualizeTableInstructions } from 'constants/types';
import { DownloadDashboardBody, DownloadDataPanelBody } from './exportActions';

// Dashboard

type EmbedFetchDashboardBody = {
  dashboard_embed_id: string;
  version_number: number | undefined;
  environment: string | undefined;
};

type EmbedFetchDashboardData = {
  dashboard_version: DashboardVersion;
  style_config_v2: GlobalStyleConfig | null;
  dashboard_template: EmbedDashboardTemplate;
  team: EmbedTeam;
  customer: Customer;
};

export const {
  actionFn: embedFetchDashboard,
  errorAction: embedFetchDashboardError,
  successAction: embedFetchDashboardSuccess,
} = defineEmbedPostAction<EmbedFetchDashboardBody, EmbedFetchDashboardData>(
  ACTION.EMBED_FETCH_DASHBOARD,
  'embed',
  'dashboard',
  'POST',
);

// Dashboard Data

export const {
  actionFn: embedFetchDataPanel,
  requestAction: embedFetchDataPanelRequest,
  errorAction: embedFetchDataPanelError,
  successAction: embedFetchDataPanelSuccess,
} = defineEmbedPostAction<FetchDataPanelBody, FetchDataPanelData>(
  ACTION.EMBED_FETCH_DATA_PANEL_TEMPLATE,
  'embed',
  'get_data',
  'POST',
);

type EmbedFetchDataPanelRowCountBody = FetchDataPanelRowCountBody & {
  versionNumber: number;
};

export const {
  actionFn: embedFetchDataPanelRowCount,
  successAction: embedFetchDataPanelRowCountSuccess,
  errorAction: embedFetchDataPanelRowCountError,
} = defineEmbedPostAction<EmbedFetchDataPanelRowCountBody, FetchDataPanelRowCountData>(
  ACTION.EMBED_FETCH_DATA_PANEL_ROW_COUNT,
  'embed',
  'get_row_count',
  'POST',
);

type EmbedFetchSecondaryDataBody = FetchSecondaryDataBody & { versionNumber: number };

export const {
  actionFn: embedFetchSecondaryData,
  requestAction: embedFetchSecondaryDataRequest,
  successAction: embedFetchSecondaryDataSuccess,
  errorAction: embedFetchSecondaryDataError,
} = defineEmbedPostAction<EmbedFetchSecondaryDataBody, FetchDataPanelData>(
  ACTION.EMBED_FETCH_SECONDARY_DATA,
  'embed',
  'get_data',
  'POST',
);

type EmbedFetchDashboardDatasetPreviewBody = FetchDashboardDatasetPreviewBody & {
  versionNumber: number;
  resource_embed_id: string;
  canvas_request?: boolean;
};

export const {
  actionFn: embedFetchDashboardDatasetPreview,
  successAction: embedFetchDashboardDatasetPreviewSuccess,
} = defineEmbedPostAction<EmbedFetchDashboardDatasetPreviewBody, FetchDashboardDatasetPreviewData>(
  ACTION.EMBED_FETCH_DASHBOARD_DATASET_PREVIEW,
  'embed',
  'get_preview',
  'POST',
);

// Downloads

type EmbedDownloadDataPanelSpreadsheetBody = DownloadDataPanelSpreadsheetBody & {
  versionNumber: number;
};

export const {
  actionFn: embedDownloadDataPanelSpreadsheet,
  requestAction: embedDownloadDataPanelSpreadsheetRequest,
  successAction: embedDownloadDataPanelSpreadsheetSuccess,
  errorAction: embedDownloadDataPanelSpreadsheetError,
} = defineEmbedPostAction<EmbedDownloadDataPanelSpreadsheetBody, ExportUrlResponse>(
  ACTION.EMBED_DOWNLOAD_DATA_PANEL_SPREADSHEET,
  'embed',
  'download_spreadsheet',
  'POST',
);

export const {
  actionFn: embedDownloadDashboardPdf,
  requestAction: embedDownloadDashboardPdfRequest,
  successAction: embedDownloadDashboardPdfSuccess,
  errorAction: embedDownloadDashboardPdfError,
} = defineEmbedPostAction<DownloadDashboardBody, ExportUrlResponse>(
  ACTION.EMBED_FETCH_PDF_EXPORT_URL,
  'embed',
  'export/dashboard/pdf',
  'POST',
);

export const {
  actionFn: embedDownloadDashboardImage,
  requestAction: embedDownloadDashboardImageRequest,
  successAction: embedDownloadDashboardImageSuccess,
  errorAction: embedDownloadDashboardImageError,
} = defineEmbedPostAction<DownloadDashboardBody, ExportUrlResponse>(
  ACTION.EMBED_FETCH_IMAGE_EXPORT_URL,
  'embed',
  'export/dashboard/image',
  'POST',
);

export const {
  actionFn: embedDownloadDataPanelPdf,
  requestAction: embedDownloadDataPanelPdfRequest,
  successAction: embedDownloadDataPanelPdfSuccess,
  errorAction: embedDownloadDataPanelPdfError,
} = defineEmbedPostAction<DownloadDataPanelBody, ExportUrlResponse>(
  ACTION.EMBED_DOWNLOAD_DATA_PANEL_PDF,
  'embed',
  'export/data_panel_template/pdf',
  'POST',
);

export const { actionFn: embedFetchDashboardVersions } = defineEmbedPostAction<
  { dashboard_embed_id: string },
  FetchDashboardVersionsResponse
>(ACTION.FETCH_DASHBOARD_VERSIONS, 'embed', 'list_versions', 'POST');

// Share Links

type EmbedFetchShareData = {
  dashboard_embed_id: string;
  customer_token: string;
  user_group_name: string;
  version_number: number;
  is_strict_viewing_mode: boolean;
  favicon_url?: string;
  site_title?: string;
};

export const { actionFn: embedFetchShareData } = defineEmbedAction<EmbedFetchShareData>(
  ACTION.EMBED_FETCH_SHARE_DATA,
  'share',
  'get_share_data',
  'GET',
);

type EmbedFetchShareDataWithPasswordBody = {
  password: string;
  username: string | undefined;
};

export const { actionFn: embedFetchShareDataWithPassword } = defineEmbedPostAction<
  EmbedFetchShareDataWithPasswordBody,
  EmbedFetchShareData
>(ACTION.EMBED_FETCH_SHARE_DATA, 'share', 'get_share_data', 'POST');

type EmbedFetchShareIdBody = {
  dashboard_embed_id: string;
  version_number?: number;
  environment_tag_id?: number;
  password?: string;
  is_strict_viewing_mode: boolean;
};

export const {
  actionFn: embedFetchShareId,
  requestAction: embedFetchShareIdRequest,
  successAction: embedFetchShareIdSuccess,
  errorAction: embedFetchShareIdError,
} = defineEmbedPostAction<EmbedFetchShareIdBody, FetchShareIdData>(
  ACTION.EMBED_FETCH_SHARE_ID,
  'embed',
  'get_share_id',
  'POST',
);

// Misc

export const embedUpdateDPTDataTableColumnWidths = createAction<{
  visualizeInstructions: VisualizeTableInstructions;
  dataPanelTemplateId: string;
}>(ACTION.EMBED_UPDATE_DPT_DATA_TABLE_COLUMN_WIDTHS);
