import produce from 'immer';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import SettingHeader from '../../DataConfigTab/SettingHeader';
import { Switch } from 'components/ds';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { BaseTableInstructions, OPERATION_TYPES } from 'constants/types';

type Props = {
  instructions: BaseTableInstructions;
  visualizationType: OPERATION_TYPES;
};

export const TableFooterConfig: FC<Props> = ({ instructions, visualizationType }) => {
  const dispatch = useDispatch();

  const setTableInstructions = (
    modifyInstructions: (instructions: BaseTableInstructions) => void,
  ) => {
    const newInstructions = produce(instructions, (draft) => {
      modifyInstructions(draft);
    });

    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  return (
    <>
      <SettingHeader
        name="Footer"
        rightContent={
          <Switch
            useCustomStyles
            onChange={() =>
              setTableInstructions(
                (instructions) => (instructions.isFooterHidden = !instructions.isFooterHidden),
              )
            }
            switchOn={!instructions.isFooterHidden}
          />
        }
      />
    </>
  );
};
