import { makeStyles, Theme } from '@material-ui/core/styles';

// This file has styling for components that are shared
// between multiple pages of the canvas editor.

export const textOverflow = (lines: number) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  '-webkit-line-clamp': lines,
  'line-clamp': lines,
  '-webkit-box-orient': 'vertical',
});

export const sharedStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    flex: 1,
    overflow: 'hidden',
  },
  emptyContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 20,
  },
  navContainer: {
    width: '100%',
    padding: theme.spacing(2),
  },
  item: {
    border: `2px solid ${theme.palette.ds.grey400}`,
    backgroundColor: theme.palette.ds.white,
    borderRadius: 4,
    cursor: 'pointer',
    marginBottom: theme.spacing(2),
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between',

    '&:hover': {
      backgroundColor: theme.palette.ds.grey100,
      borderColor: theme.palette.ds.hovered.grey400,
    },
  },
  itemInfo: {
    flex: 1,
    overflow: 'hidden',
  },
  itemInfoFlex: {
    flex: 1,
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
  },
  itemIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: theme.spacing(2),
  },
  itemStatus: {
    minWidth: 12,
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(2),
      ' &:last-child': {
        marginRight: 0,
      },
    },
  },
  errorIcon: {
    color: theme.palette.ds.red,
  },
  itemTitle: {
    ...textOverflow(2),
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '20px',
  },
  itemSubTitle: {
    ...textOverflow(2),
    color: theme.palette.ds.grey600,
    fontWeight: 500,
    lineHeight: '15px',
    fontSize: 12,
    marginTop: 2,
  },
  selectedItem: {
    backgroundColor: theme.palette.ds.lightBlue,
    border: `2px solid ${theme.palette.ds.blue}`,

    '&:hover': {
      backgroundColor: theme.palette.ds.hovered.lightBlue,
      borderColor: theme.palette.ds.hovered.blue,
    },
  },
  configMenu: {
    backgroundColor: theme.palette.ds.grey100,
    borderRight: `1px solid ${theme.palette.ds.grey400}`,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minWidth: 545,
    overflow: 'hidden',
    width: 545,
  },
  config: {
    flex: 1,
    width: '100%',
    overflowY: 'auto',
  },
  configSubHeader: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 14,
    fontWeight: 600,
    margin: `0 0 ${theme.spacing(3)}px 0`,
  },
  configSection: {
    padding: `0 ${theme.spacing(3)}px`,
  },
  sectionText: {
    fontSize: 14,
    color: theme.palette.ds.grey900,
    lineHeight: '17px',
    marginBottom: theme.spacing(4),
  },
  deleteButtonContainer: {
    borderBottom: `1px solid ${theme.palette.ds.grey300}`,
    borderTop: `1px solid ${theme.palette.ds.grey300}`,
    marginBottom: theme.spacing(8),
    padding: `${theme.spacing(4)}px`,
  },
  elementView: {
    backgroundColor: theme.palette.ds.grey200,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'hidden',
    position: 'relative',
  },
  detailView: {
    width: '100%',
    padding: '24px 78px',
    borderBottom: `1px solid ${theme.palette.ds.grey400}`,
    overflow: 'hidden',
  },
  detailElement: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
  },
  linkedItem: {
    alignItems: 'center',
    backgroundColor: theme.palette.ds.white,
    border: `1px solid ${theme.palette.ds.grey400}`,
    borderRadius: theme.spacing(1),
    color: theme.palette.ds.black,
    display: 'flex',
    fontSize: 14,
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    width: '100%',

    '&:hover': {
      textDecoration: 'none',
      color: 'inherit',
      backgroundColor: theme.palette.ds.lightBlue,
    },
  },
  linkedItemName: {
    ...textOverflow(1),
    fontWeight: 500,
    lineHeight: '20px',
    padding: theme.spacing(1),
  },
  linkedItemIcon: {
    paddingLeft: theme.spacing(2),
  },
  textOverflow: {
    ...textOverflow(1),
  },
}));
