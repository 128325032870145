import { CustomerReportView } from 'actions/customerReportActions';
import { v4 as uuidv4 } from 'uuid';

export function createBlankView(): CustomerReportView {
  return {
    id: uuidv4(),
    name: 'View 1',
    columnOrder: [],
    hiddenColumns: [],
    filters: [],
    sort: [],
  };
}

export function getNextViewName(views: CustomerReportView[] | undefined) {
  let currentNum = views?.length || 0;
  for (const view of views || []) {
    const viewDigits = view.name.match(/\d+$/)?.[0];
    const viewNum = parseInt(viewDigits || '') || 0;
    if (viewNum > currentNum) currentNum = viewNum;
  }
  return `View ${currentNum + 1}`;
}
