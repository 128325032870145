import { FC, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useImmer } from 'use-immer';
import cx from 'classnames';
import * as RD from 'remotedata';

import { LargeModal } from './LargeModal';
import { EmbedText } from '../EmbedText';
import { Button, sprinkles } from 'components/ds';
import { EmbedDataGrid } from 'components/embed';

import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { selectDataset } from 'reportBuilderContent/reducers/reportEditingReducer';
import {
  ReportBuilderCol,
  ReportBuilderColConfig,
  ReportBuilderColConfigs,
  ReportBuilderDataset,
} from 'actions/reportBuilderConfigActions';
import { getColTypeDisplay } from 'utils/reportBuilderConfigUtils';
import { fetchModalData } from 'reportBuilderContent/thunks';
import { DatasetRow } from 'types/datasets';

import * as styles from './DatasetSelectionModal.css';

type CurrentDataInfo = { datasetId?: string; column?: string };

export const DatasetSelectionModal: FC = () => {
  const dispatch = useDispatch();

  const { reportBuilderConfig, datasetData, previousDatasetId } = useSelector(
    (state: ReportBuilderReduxState) => ({
      reportBuilderConfig: state.embeddedReportBuilder.reportBuilderVersion?.config,
      datasetData: state.reportEditing.modalDatasetData,
      previousDatasetId: state.reportEditing.currentDatasetId,
    }),
    shallowEqual,
  );

  const [currDataInfo, setCurrDataInfo] = useImmer<CurrentDataInfo>({
    datasetId: previousDatasetId ?? undefined,
  });

  const currColumn = currDataInfo.column;
  const currDatasetId = currDataInfo.datasetId;

  const populatedDatasets = useMemo(
    () =>
      Object.values(reportBuilderConfig?.datasets ?? {}).filter(
        (dataset) => dataset.schema?.length,
      ),
    [reportBuilderConfig],
  );

  // Auto-select first dataset if none is selected yet
  useEffect(() => {
    if (currDatasetId || !populatedDatasets.length) return;

    const dataset = populatedDatasets[0];
    dispatch(fetchModalData(dataset.id));
    setCurrDataInfo({ datasetId: dataset.id });
  }, [currDatasetId, dispatch, populatedDatasets, setCurrDataInfo]);

  if (!reportBuilderConfig) return null;

  const selectedDataset = currDatasetId ? reportBuilderConfig.datasets[currDatasetId] : undefined;

  const renderDataset = (dataset: ReportBuilderDataset) => {
    const isSelected = dataset.id === currDatasetId;
    return (
      <div
        className={isSelected ? styles.selectedDataset : styles.unselectedDataset}
        key={dataset.id}
        onClick={() => {
          if (isSelected) return;
          dispatch(fetchModalData(dataset.id));
          setCurrDataInfo({ datasetId: dataset.id });
        }}>
        <EmbedText heading="h3">{dataset.name}</EmbedText>
        {dataset.description.trim() === '' ? null : (
          <div className={sprinkles({ marginTop: 'sp1' })}>
            <EmbedText body="b1">{dataset.description}</EmbedText>
          </div>
        )}
      </div>
    );
  };

  const renderBodyTertiaryText = (text: string, body?: 'b1' | 'b2', className?: string) => {
    return (
      <EmbedText body={body ?? 'b1'} className={className} color="contentTertiary">
        {text}
      </EmbedText>
    );
  };

  const renderColumn = (columnConfigs: ReportBuilderColConfigs, col: ReportBuilderCol) => {
    const colConfig = columnConfigs[col.name];
    if (!colConfig || !colConfig.isVisible) return null;

    const isSelected = currColumn === col.name;

    return (
      <div
        className={isSelected ? styles.selectedColumn : styles.unselectedColumn}
        key={col.name}
        onClick={() => {
          if (isSelected) return;
          setCurrDataInfo((draft) => {
            draft.column = col.name;
          });
        }}>
        <div className={styles.colInfo}>
          <EmbedText body="b1" className={styles.colName}>
            {colConfig.name}
          </EmbedText>
          {renderBodyTertiaryText(getColTypeDisplay(col.type), 'b2')}
        </div>
        {colConfig.description ? (
          <div className={sprinkles({ marginTop: 'sp1' })}>
            {renderBodyTertiaryText(colConfig.description, 'b2')}
          </div>
        ) : null}
      </div>
    );
  };

  const renderColData = (
    col: ReportBuilderCol,
    colConfig: ReportBuilderColConfig,
    rows: DatasetRow[],
  ) => {
    return (
      <EmbedDataGrid
        className={sprinkles({ height: 'fill' })}
        columnConfigs={{ [col.name]: colConfig }}
        loading={false}
        rows={rows}
        schema={[{ ...col, friendly_name: colConfig.name }]}
      />
    );
  };

  const renderColPreview = (colName: string, colConfig: ReportBuilderColConfig | undefined) => {
    const col = selectedDataset?.schema?.find((schemaCol) => schemaCol.name === colName);
    if (!colConfig || !currDatasetId || !col) return null;

    const data = datasetData[currDatasetId];
    return (
      <RD.RemoteComponent
        Error={renderBodyTertiaryText}
        Loading={() => renderBodyTertiaryText('Data loading')}
        Success={(rows) => renderColData(col, colConfig, rows)}
        data={data ?? RD.Loading()}
      />
    );
  };

  const renderFooterContent = () => {
    return (
      <Button
        disabled={!currDatasetId}
        onClick={() => (currDatasetId ? dispatch(selectDataset(currDatasetId)) : undefined)}
        type="primary">
        Select dataset
      </Button>
    );
  };

  return (
    <LargeModal footerContent={renderFooterContent()} title="Select dataset">
      <div className={styles.bodyContainer}>
        <div className={styles.datasetCol}>
          <EmbedText heading="h3">Choose a dataset</EmbedText>
          <div className={styles.datasetList}>{populatedDatasets.map(renderDataset)}</div>
        </div>
        <div className={cx(styles.previewCol, sprinkles({ borderX: 1 }))}>
          <EmbedText heading="h3">Preview columns</EmbedText>
          <div className={styles.columnList}>
            {selectedDataset
              ? selectedDataset.schema?.map((col) =>
                  renderColumn(selectedDataset.columnConfigs, col),
                )
              : null}
          </div>
        </div>
        <div className={styles.previewCol}>
          <EmbedText className={sprinkles({ marginBottom: 'sp2' })} heading="h3">
            Preview data
          </EmbedText>
          {currColumn
            ? renderColPreview(currColumn, selectedDataset?.columnConfigs[currColumn])
            : renderBodyTertiaryText('No column selected', 'b1')}
        </div>
      </div>
    </LargeModal>
  );
};
