import { FC, useState } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import InputGroup from 'explo-ds/forms/marketing/inputGroup';
import InputLabel from 'shared/InputLabel';
import Button from '../shared/Button';

const useStyles = makeStyles(() => ({
  root: {
    '& .bp3-input-action': {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      marginRight: 5,
    },
  },
}));

type Props = {
  /**
   * optional class to wrap entire component
   */
  className: string;
  /**
   * optional label shown above the input in the top left
   */
  label: string;
  /**
   * Text to show when no input has been made. Required since this is a controlled component
   */
  value: string;
  /**
   * Function that runs on change events
   */
  onChange: (value: string) => void;
};

const PasswordInput: FC<Props> = ({ className, label, value, onChange, ...props }) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={cx(classes.root, className)}>
      <InputLabel text={label || ''} />
      <InputGroup
        {...props}
        // fs-exclude is required to protect privacy on fullstory
        className="fs-exclude"
        onInputChange={onChange}
        rightElement={
          <Button
            icon={showPassword ? 'eye-open' : 'eye-off'}
            onClick={() => setShowPassword(!showPassword)}
            style={{ background: 'none' }}
          />
        }
        type={showPassword ? 'text' : 'password'}
        value={value}
      />
    </div>
  );
};

export default PasswordInput;
