import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { groupBy } from 'utils/standard';

import { CanvasNav } from '../CanvasNav';
import DashboardLayout from 'components/DashboardLayout/DashboardLayout';
import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';
import { ExampleDashboard } from './ExampleDashboard';
import { DashboardElementVisibilityCard } from 'components/DashboardElementVisibilityCard';

import {
  addElementToCanvasLayout,
  CanvasCustomComponent,
  CanvasTemplate,
  CanvasVersionConfig,
  removeElementFromCanvasLayout,
  updateCanvasDefaultLayout,
} from 'actions/canvasConfigActions';
import { sharedStyles } from '../sharedStyles';
import { getCanvasDashboardElements, isCanvasComponent } from 'utils/canvasConfigUtils';
import { Canvas } from 'actions/canvasActions';
import { DashboardVariableMap, PAGE_TYPE, VIEW_MODE } from 'types/dashboardTypes';
import {
  downloadDataPanelSpreadsheet,
  fetchDataPanel,
  fetchDataPanelRowCount,
  fetchSecondaryData,
} from 'actions/dataPanelTemplateAction';
import { fetchDashboardDatasetPreview } from 'actions/datasetActions';
import { VIZ_TO_NAME } from 'constants/dataConstants';
import { CustomComponentIcon, getChartIcon } from '../icons';
import { ReduxState } from 'reducers/rootReducer';
import { getSelectedCustomer } from 'reducers/customersReducer';
import { DASHBOARD_ELEMENT_TYPE_TO_NAME } from 'constants/dashboardConstants';
import { withWidth } from 'components/HOCs/withWidth';

const useStyles = makeStyles((theme: Theme) => ({
  groupHeader: {
    backgroundColor: theme.palette.ds.grey300,
    borderBottom: `1px solid ${theme.palette.ds.grey400}`,
    borderTop: `1px solid ${theme.palette.ds.grey400}`,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(3),
    fontWeight: 600,
    fontSize: 14,
    color: '#000000',
  },
  groupSection: {
    padding: theme.spacing(2),
  },
  layoutViewToggle: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(3),
  },
  layoutInfo: {
    padding: theme.spacing(4),
    paddingTop: 0,
    fontSize: 12,
    color: theme.palette.ds.black,
    lineHeight: '15px',
  },
  eyeIcon: {
    color: theme.palette.ds.grey800,
  },
}));

enum VIEW_STATE {
  EDIT_LAYOUT = 'Edit Layout',
  VIEW_EXAMPLE = 'View Example',
}

type Props = {
  canvas: Canvas;
  config: CanvasVersionConfig;
  versionNumber: number;
  width?: number;

  setVariables: (variables: DashboardVariableMap) => void;
};

const LayoutEditorBase: FC<Props> = ({ canvas, config, versionNumber, setVariables, width }) => {
  const dispatch = useDispatch();
  const sharedClasses = sharedStyles();
  const classes = useStyles();

  const history = useHistory();
  const { subView } = useParams<{ subView: string | undefined }>();

  const [selectedItemId, setSelectedItemId] = useState<string | undefined>();

  const [viewState, setViewState] = useState(
    subView === 'sandbox' ? VIEW_STATE.VIEW_EXAMPLE : VIEW_STATE.EDIT_LAYOUT,
  );
  const isInEditLayoutView = viewState === VIEW_STATE.EDIT_LAYOUT;

  const selectedUserGroup = useSelector((state: ReduxState) =>
    getSelectedCustomer(state.customers),
  );

  useEffect(() => {
    if (subView === 'sandbox') {
      if (viewState !== VIEW_STATE.VIEW_EXAMPLE) setViewState(VIEW_STATE.VIEW_EXAMPLE);
    } else if (viewState !== VIEW_STATE.EDIT_LAYOUT) {
      setViewState(VIEW_STATE.EDIT_LAYOUT);
    }
  }, [subView, viewState]);

  const renderFilters = () => {
    const filters = Object.values(config.filters).filter((filter) => filter.filter_info !== null);
    if (filters.length === 0) return null;

    return (
      <>
        <div className={classes.groupHeader}>Filters</div>
        <div className={classes.groupSection}>
          {filters.map((filter) => {
            if (filter.filter_info === null) return null;
            const isInDefaultLayout = !!config.default_layout.find((elem) => elem.i === filter.id);

            return (
              <DashboardElementVisibilityCard
                description={`${DASHBOARD_ELEMENT_TYPE_TO_NAME[filter.filter_info.filter_type]}${
                  filter.description ? ' - ' : ''
                }${filter.description}`}
                isSelected={isInDefaultLayout}
                key={filter.id}
                name={filter.name}
                onClick={() =>
                  dispatch(
                    isInDefaultLayout
                      ? removeElementFromCanvasLayout({ elementId: filter.id })
                      : addElementToCanvasLayout({
                          filterType: filter.filter_info?.filter_type,
                          id: filter.id,
                        }),
                  )
                }
              />
            );
          })}
        </div>
      </>
    );
  };

  const renderItem = (item: CanvasTemplate | CanvasCustomComponent) => {
    const isInDefaultLayout = !!config.default_layout.find((elem) => elem.i === item.id);
    const isComponent = isCanvasComponent(item);

    return (
      <DashboardElementVisibilityCard
        description={item.description}
        icon={isComponent ? CustomComponentIcon() : getChartIcon(item.visualize_op.operation_type)}
        isSelected={isInDefaultLayout}
        key={item.id}
        name={item.name}
        onClick={() =>
          dispatch(
            isInDefaultLayout
              ? removeElementFromCanvasLayout({ elementId: item.id })
              : addElementToCanvasLayout({ filterType: undefined, id: item.id }),
          )
        }
      />
    );
  };

  const renderElements = () => {
    const components = Object.values(config.customComponents ?? {}).filter(
      (comp) => !!comp.info.config.iframeUrl,
    );
    if (components.length === 0) return null;
    return (
      <>
        <div className={classes.groupHeader}>Iframes</div>
        <div className={classes.groupSection}>{components.map(renderItem)}</div>
      </>
    );
  };

  const renderTemplates = () => {
    const groupedTemplates = groupBy(
      config.templates,
      (template) => template.visualize_op.operation_type,
    );

    return Object.values(groupedTemplates).map((templateGroup) => {
      const opType = templateGroup[0].visualize_op.operation_type;
      return (
        <div key={opType}>
          <div className={classes.groupHeader}>{VIZ_TO_NAME[opType]}</div>
          <div className={classes.groupSection}>{templateGroup.map(renderItem)}</div>
        </div>
      );
    });
  };

  const elemIdsInLayout = useMemo(() => {
    return new Set(config.default_layout.map((elem) => elem.i));
  }, [config.default_layout]);

  const dashboardElements = useMemo(() => {
    return getCanvasDashboardElements(config, elemIdsInLayout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.filters, config.customComponents, elemIdsInLayout]);

  const dataPanels = useMemo(
    () => Object.values(config.templates).filter((template) => elemIdsInLayout.has(template.id)),
    [config.templates, elemIdsInLayout],
  );

  const attachResourceId = (data: { postData: { resource_id?: number } }) => {
    data.postData.resource_id = canvas.id;
  };

  return (
    <div className={sharedClasses.root}>
      <CanvasNav config={config}>
        <>
          <ToggleButtonGroup fillWidth className={classes.layoutViewToggle}>
            <ToggleButton
              active={isInEditLayoutView}
              onClick={() => history.push(`/blueprint/${canvas.id}/layout`)}
              text="Edit Layout"
            />
            <ToggleButton
              active={!isInEditLayoutView}
              onClick={() => history.push(`/blueprint/${canvas.id}/layout/sandbox`)}
              text="View Example"
            />
          </ToggleButtonGroup>
          <div className={classes.layoutInfo}>
            {isInEditLayoutView
              ? "This layout will apply to new and unedited dashboards. Once a user begins editing their dashboard they will no longer inherit this dashboard's changes."
              : "This will be how your users see the dashboards. Changes made here won't affect your users."}
          </div>
          {isInEditLayoutView ? (
            <>
              {renderFilters()}
              {renderTemplates()}
              {renderElements()}
            </>
          ) : null}
        </>
      </CanvasNav>

      {!isInEditLayoutView ? (
        <ExampleDashboard
          canvas={canvas}
          config={config}
          setVariables={setVariables}
          versionNumber={versionNumber}
          width={width}
        />
      ) : (
        <div style={{ height: '100%', width: '100%' }}>
          <DashboardLayout
            editableDashboard
            isCanvas
            dashboardElements={dashboardElements}
            dashboardLayout={config.default_layout}
            dataPanels={dataPanels}
            datasets={config.datasets}
            downloadDataPanelSpreadsheet={(fetchDPCsv) => {
              attachResourceId(fetchDPCsv);
              dispatch(downloadDataPanelSpreadsheet(fetchDPCsv));
            }}
            exploResource={canvas}
            fetchDataPanel={(fetchDP, onSuccess) => {
              attachResourceId(fetchDP);
              dispatch(fetchDataPanel(fetchDP, onSuccess));
            }}
            fetchDataPanelRowCount={(fetchDPRowCount) => {
              attachResourceId(fetchDPRowCount);
              dispatch(fetchDataPanelRowCount(fetchDPRowCount));
            }}
            fetchDatasetPreview={(fetchDSPreview, onSuccess, onError) =>
              dispatch(fetchDashboardDatasetPreview(fetchDSPreview, onSuccess, onError))
            }
            fetchSecondaryData={(fetchSecondary) => {
              attachResourceId(fetchSecondary);
              dispatch(fetchSecondaryData(fetchSecondary));
            }}
            fetchShareData={() => console.warn('Not supported')}
            isViewOnly={false}
            onCloseConfigClicked={() => setSelectedItemId(undefined)}
            onDashboardItemSelect={(_, id) => setSelectedItemId(id)}
            onVariablesChange={setVariables}
            pageType={PAGE_TYPE.EXPLO_APP}
            resourceVersionNumber={versionNumber}
            selectedDashboardItemId={selectedItemId}
            timezone="UTC"
            updateDashboardLayout={(newLayout) => dispatch(updateCanvasDefaultLayout(newLayout))}
            userGroup={selectedUserGroup ?? undefined}
            viewMode={VIEW_MODE.DEFAULT}
            width={width}
          />
        </div>
      )}
    </div>
  );
};

export const LayoutEditor = withWidth(LayoutEditorBase);
