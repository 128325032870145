import { FC, useCallback, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core';
import * as RD from 'remotedata';

import DashboardLayout from 'components/DashboardLayout/DashboardLayout';
import { Spinner } from 'components/ds';
import ArchitectCustomerDashboardHeader from './dashboardHeader';
import EmptyDashboard from 'pages/BlueprintPage/EmptyDashboard';

import usePageVisibility from 'components/HOCs/usePageVisibility';

import { ArchitectCustomerDashboardReduxState } from 'architectCustomerDashboardContent/reducers/rootReducer';
import {
  setSelectedDashboardItemId,
  updateArchitectCustomerDashboardVariables,
} from 'actions/architectCustomerDashboardActions';
import { updateEUDLayout } from 'actions/architectCustomerDashboardConfigActions';
import { DEFAULT_CANVAS_CONFIGURABILITY, EmbedCanvas } from 'actions/canvasActions';
import { DashboardVariableMap, PAGE_TYPE, VIEW_MODE } from 'types/dashboardTypes';
import {
  DownloadDataPanelSpreadsheetBody,
  FetchDataPanelBody,
  FetchDataPanelRowCountBody,
  FetchSecondaryDataBody,
} from 'actions/dataPanelTemplateAction';
import {
  ErrorResponse,
  FetchDashboardDatasetPreviewData,
  FetchDataPanelData,
} from 'actions/responseTypes';
import {
  embedDownloadDataPanelSpreadsheet,
  embedFetchDashboardDatasetPreview,
  embedFetchDataPanel,
  embedFetchDataPanelRowCount,
  embedFetchSecondaryData,
} from 'actions/shareActions';
import { FetchDashboardDatasetPreviewBody } from 'actions/datasetActions';
import { EmbeddedDashboardType } from 'components/EmbeddedDashboard/types';
import { getCurrentDashboard } from 'reducers/architectCustomerDashboardReducer';
import { getArchitectCustomerDashboardElements } from 'utils/architectCustomerDashboardUtils';
import { AnalyticsEventTracker } from 'utils/analyticsUtils';

const useStyles = makeStyles((theme: Theme) => ({
  dashboardWrapper: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
  },
  errorMessage: {
    margin: theme.spacing(10),
    fontSize: 24,
    padding: theme.spacing(10),
    backgroundColor: '#FAE1E1',
    borderRadius: 8,
  },
}));

type Props = {
  analyticsEventTracker?: AnalyticsEventTracker;
  canvasEmbedId: string;
  customerToken: string;
  userId: string | undefined;
  embedType: EmbeddedDashboardType;
  portalContainerId: string;
  embeddedVariables?: DashboardVariableMap;
  refreshMinutes?: number;
  updateUrlParams?: boolean;
  isViewOnly?: boolean;
  urlVariables?: DashboardVariableMap;
  // TODO: implement locale
  localeCode?: string;
  timezone?: string;
};

export const ArchitectCustomerDashboard: FC<Props> = ({
  analyticsEventTracker,
  canvasEmbedId,
  customerToken,
  userId,
  embeddedVariables,
  refreshMinutes,
  isViewOnly,
  portalContainerId,
  urlVariables,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    globalStyleConfig,
    canvas,
    canvasVersion,
    currentDashboard,
    isEditing,
    selectedDashboardItemId,
    customer,
    dashboardVariables,
  } = useSelector(
    (state: ArchitectCustomerDashboardReduxState) => ({
      globalStyleConfig: state.dashboardStyles.globalStyleConfig,
      canvas: state.architectCustomerDashboard.canvas,
      canvasVersion: state.architectCustomerDashboard.canvasVersion,
      currentDashboard: getCurrentDashboard(state.architectCustomerDashboard),
      isEditing: state.architectCustomerDashboard.editingDashboard !== null,
      selectedDashboardItemId: state.architectCustomerDashboard.selectedDashboardItemId,
      customer: state.architectCustomerDashboard.customer,
      dashboardVariables: state.architectCustomerDashboard.dashboardVariables,
    }),
    shallowEqual,
  );

  const canvasVersionNumber = canvasVersion?.version_number;
  const canvasId = RD.isSuccess(canvas) ? canvas.data.id : null;

  const isVisible = usePageVisibility();

  const fetchDataPanelTemplateWrapper = useCallback(
    (data: { postData: FetchDataPanelBody }, onSuccess?: (data: FetchDataPanelData) => void) => {
      if (canvasVersionNumber === undefined) return;

      return dispatch(
        embedFetchDataPanel(
          {
            customerToken,
            postData: {
              ...data.postData,
              versionNumber: canvasVersionNumber,
              resource_embed_id: canvasEmbedId,
            },
          },
          onSuccess,
        ),
      );
    },
    [customerToken, canvasVersionNumber, dispatch, canvasEmbedId],
  );

  const fetchDataPanelRowCountWrapper = useCallback(
    (data: { postData: FetchDataPanelRowCountBody }) => {
      if (canvasVersionNumber === undefined) return;

      return dispatch(
        embedFetchDataPanelRowCount({
          customerToken,
          postData: {
            ...data.postData,
            versionNumber: canvasVersionNumber,
            resource_embed_id: canvasEmbedId,
          },
        }),
      );
    },
    [customerToken, canvasVersionNumber, dispatch, canvasEmbedId],
  );

  const downloadCsvWrapper = useCallback(
    (data: { postData: DownloadDataPanelSpreadsheetBody }) => {
      if (canvasVersionNumber === undefined) return;

      dispatch(
        embedDownloadDataPanelSpreadsheet({
          customerToken,
          postData: {
            ...data.postData,
            resource_embed_id: canvasEmbedId,
            versionNumber: canvasVersionNumber,
          },
        }),
      );
    },
    [customerToken, canvasVersionNumber, dispatch, canvasEmbedId],
  );

  const fetchDatasetPreviewWrapper = useCallback(
    (
      data: { postData: FetchDashboardDatasetPreviewBody },
      onSuccess?: (data: FetchDashboardDatasetPreviewData) => void,
      onError?: (errorMessage: ErrorResponse) => void,
    ) => {
      if (canvasVersionNumber === undefined || canvasId === null) return;
      return dispatch(
        embedFetchDashboardDatasetPreview(
          {
            customerToken,
            postData: {
              ...data.postData,
              versionNumber: canvasVersionNumber,
              canvas_request: true,
              resource_embed_id: canvasEmbedId,
            },
          },
          onSuccess,
          onError,
        ),
      );
    },
    [customerToken, canvasVersionNumber, canvasId, dispatch, canvasEmbedId],
  );

  const fetchSecondaryDataWrapper = useCallback(
    (data: { postData: FetchSecondaryDataBody }) => {
      if (!canvasVersionNumber) return;
      return dispatch(
        embedFetchSecondaryData({
          customerToken,
          postData: {
            ...data.postData,
            versionNumber: canvasVersionNumber,
            is_secondary_data_query: true,
            resource_embed_id: canvasEmbedId,
          },
        }),
      );
    },
    [dispatch, canvasVersionNumber, customerToken, canvasEmbedId],
  );

  const dashboardElements = useMemo(() => {
    if (!currentDashboard || !canvasVersion) return [];

    return getArchitectCustomerDashboardElements(
      currentDashboard.configuration,
      canvasVersion.configuration,
    );
  }, [currentDashboard, canvasVersion]);

  const renderArchitectCustomerDashboard = (canvas: EmbedCanvas) => {
    if (!canvasVersion || !currentDashboard) {
      return <div className={classes.errorMessage}>Error Loading Dashboard</div>;
    }
    const config = currentDashboard.configuration;

    return (
      <div
        className={classes.dashboardWrapper}
        style={{ backgroundColor: globalStyleConfig.base.backgroundColor }}>
        <ArchitectCustomerDashboardHeader
          analyticsEventTracker={analyticsEventTracker}
          canvasConfig={canvasVersion.configuration}
          canvasConfigurability={canvas.configurability ?? DEFAULT_CANVAS_CONFIGURABILITY}
          canvasEmbedId={canvasEmbedId}
          canvasName={canvas.name}
          customerToken={customerToken}
          dashboard={currentDashboard}
          dashboardPadding={globalStyleConfig.base.spacing.default}
          isViewOnly={isViewOnly}
          portalContainerId={portalContainerId}
          userId={userId}
          variables={dashboardVariables[currentDashboard.id ?? ''] ?? {}}
        />
        {config.layout.length === 0 ? (
          <EmptyDashboard />
        ) : (
          <DashboardLayout
            isArchitectCustomerDashboard
            isCanvas
            analyticsEventTracker={analyticsEventTracker}
            dashboardElements={dashboardElements}
            dashboardLayout={config.layout}
            dataPanels={Object.values(config.data_panels)}
            datasets={canvasVersion.configuration.datasets}
            downloadDataPanelSpreadsheet={downloadCsvWrapper}
            editableDashboard={isEditing}
            endUserVariables={dashboardVariables[currentDashboard.id ?? '']}
            exploResource={canvas}
            fetchDataPanel={fetchDataPanelTemplateWrapper}
            fetchDataPanelRowCount={fetchDataPanelRowCountWrapper}
            fetchDatasetPreview={fetchDatasetPreviewWrapper}
            fetchSecondaryData={fetchSecondaryDataWrapper}
            fetchShareData={() => console.warn('Not supported')}
            isViewOnly={false}
            isVisible={isVisible}
            onCloseConfigClicked={() => dispatch(setSelectedDashboardItemId(undefined))}
            onDashboardItemSelect={(_, id) => dispatch(setSelectedDashboardItemId(id))}
            onVariablesChange={(variables) =>
              dispatch(
                updateArchitectCustomerDashboardVariables({
                  dashboardId: currentDashboard.id,
                  variables,
                }),
              )
            }
            pageType={PAGE_TYPE.END_USER_DASHBOARD}
            refreshMinutes={refreshMinutes}
            resourceVersionNumber={canvasVersionNumber}
            selectedDashboardItemId={selectedDashboardItemId}
            supportEmail={undefined}
            timezone="UTC"
            updateDashboardLayout={(layout) => dispatch(updateEUDLayout(layout))}
            userGroup={customer ?? undefined}
            variablesDefaultValues={{ ...urlVariables, ...embeddedVariables }}
            viewMode={VIEW_MODE.DEFAULT}
          />
        )}
      </div>
    );
  };

  return (
    <RD.RemoteComponent
      Error={(error) => <div className={classes.errorMessage}>{error}</div>}
      Loading={() => <Spinner fillContainer size="lg" />}
      Success={renderArchitectCustomerDashboard}
      data={canvas}
    />
  );
};
