import { useState } from 'react';
import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Icon } from '@blueprintjs/core';

import Button from 'shared/Button';

/**
 * DEPRECATED - Use DeleteConfirmationButton instead
 */

const styles = (theme: Theme) => ({
  cancelBtn: {
    marginRight: theme.spacing(2),
    width: `calc(50% - ${theme.spacing(1)}px)`, // to offset the margin Right
  },
  finalDeleteBtn: {
    width: `calc(50% - ${theme.spacing(1)}px)`,
  },
  deleteBtn: {
    width: 72,
  },
});

const useStyles = makeStyles(styles);

type PassedProps = {
  onDelete: () => void;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
};

export default function DeleteConfirmButton({
  onDelete,
  className,
  loading,
  disabled,
}: PassedProps) {
  const classes = useStyles();
  const [isDeleteToggled, setIsDeleteToggled] = useState<boolean>(false);

  return isDeleteToggled && !loading ? (
    <div className={className}>
      <Button
        className={classes.cancelBtn}
        icon={<Icon icon="cross" />}
        onClick={() => {
          setIsDeleteToggled(false);
        }}
        type="secondary"
      />
      <Button
        className={classes.finalDeleteBtn}
        icon={<Icon icon="trash" />}
        onClick={onDelete}
        type="destructive"
      />
    </div>
  ) : (
    <Button
      className={cx(classes.deleteBtn, className)}
      disabled={disabled || loading}
      loading={loading}
      onClick={() => {
        setIsDeleteToggled(true);
      }}
      text="Delete"
      type="destructive"
    />
  );
}
