import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sprinkles } from 'components/ds';

import { ReduxState } from 'reducers/rootReducer';
import { fetchUserTeam } from 'actions/teamActions';
import { useScript } from 'usehooks-ts';
import { getEnvironment } from 'utils/environmentUtils';

const contentClass = sprinkles({
  flexDirection: 'column',
  widthConstraints: 'minOnly',
});

// dashboard is Overview Dashboard (Embed) from team Explo App
const embedId = 'jQgYwVJAvR';

const AnalyticsPage = () => {
  const dispatch = useDispatch();
  const teamData = useSelector((state: ReduxState) => state.teamData.data);

  useEffect(() => {
    if (!teamData) {
      dispatch(fetchUserTeam());
    }
  }, [teamData, dispatch]);

  useScript('https://embed.explo.co/bundle.js', { removeOnUnmount: true });

  const customerToken =
    getEnvironment() === 'development'
      ? process.env.REACT_APP_ANALYTICS_DEFAULT_CUSTOMER_TOKEN
      : teamData?.internal_analytics_user_group_token;
  // TODO: add error handling
  if (!customerToken) return null;

  return (
    <div className={contentClass}>
      <explo-dashboard
        dash-customer-token={`${embedId}:${customerToken}`}
        isProduction={true}
        refresh-minutes={10}
        updateUrlParams={true}
      />
    </div>
  );
};

export default AnalyticsPage;
