import { FC, useState } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import DropdownSelect from 'shared/DropdownSelect';
import DatePicker from 'react-datepicker';

import {
  FILTER_OPS_NO_VALUE,
  FILTER_OPS_DATE_PICKER,
  FILTER_OPS_DATE_RANGE_PICKER,
  FILTER_OPS_RELATIVE_PICKER,
  FILTER_OPS_MULTISELECT,
  FilterOperator,
  FILTER_OPS_NUMBER,
} from 'types/filterOperations';
import {
  DATE_RELATIVE_OPTION,
  DATE_RELATIVE_OPTIONS,
  DATE_RELATIVE_OPTIONS_BY_ID,
} from 'constants/dataPanelEditorConstants';
import { FilterValueDateType, FilterValueRelativeDateType, FilterValueType } from 'constants/types';
import { getDatePickerDateFromISO, getLuxonDateFromDatePicker } from 'utils/timezoneUtils';
import { getFormatForDatePicker, TIME_FORMATS } from 'utils/localizationUtils';

const filterStyles = makeStyles((theme) => ({
  datePicker: {
    padding: '0 10PX',
    borderWidth: 0,
    borderRadius: 3,
    fontSize: 14,
    height: 32,
    boxShadow: `inset 0 0 0 1px ${theme.palette.ds.grey500} !important`,
    width: '100%',
  },
  dateRange: {
    display: 'flex',
    flexDirection: 'row',
  },
  startDateRangeWrapper: {
    marginRight: -1,
  },
  startDateRange: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  endDateRange: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  datePopover: {
    zIndex: 100,
  },
  dateWedge: {
    width: 16,
  },
  formGroupValidationNoError: {
    marginBottom: 20,
  },
  relativeContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  relativeNumInput: {
    width: '30%',
    marginRight: theme.spacing(2),
  },
  relativeTimeDropdown: {
    width: '70%',
  },
  fillWidth: {
    width: '100%',
  },
}));

interface Props {
  filterOperator: FilterOperator;
  filterValue: FilterValueType;
  fillWidth?: boolean;
  updateFilterValue: (value: FilterValueType) => void;
  updateStartDate: (startDate: string | null) => void;
  updateEndDate: (endDate: string | null) => void;
  adHocFilterSettings?: boolean;
  preventFutureDates?: boolean;
}

const FilterValueInput: FC<Props> = (props) => {
  const { filterValue, filterOperator, fillWidth, adHocFilterSettings, preventFutureDates } = props;
  const styles = filterStyles();

  const [error, setError] = useState('');
  const [inputValue, setInputValue] = useState(
    filterValue === undefined || filterValue === null ? '' : String(filterValue),
  );

  if (FILTER_OPS_NO_VALUE.has(filterOperator)) return null;

  const VIEW_DATE_FORMAT = 'MM/DD/YYYY'; // we might want to support granular hours/minutes in the future
  if (FILTER_OPS_DATE_PICKER.has(filterOperator)) {
    let date: Date | null = null;
    const filterValueDate = filterValue as FilterValueDateType;
    if (filterValue) {
      date = filterValueDate.startDate ? getDatePickerDateFromISO(filterValueDate.startDate) : null;
    }

    return (
      <DatePicker
        disabledKeyboardNavigation
        showMonthDropdown
        showYearDropdown
        className={styles.datePicker}
        dateFormat={getFormatForDatePicker(TIME_FORMATS['MM/DD/YYYY'])}
        maxDate={preventFutureDates ? new Date() : undefined}
        onChange={(date) => {
          date = date as Date;

          props.updateStartDate(date ? getLuxonDateFromDatePicker(date).toISO() : null);
        }}
        placeholderText={VIEW_DATE_FORMAT}
        popperClassName={styles.datePopover}
        popperModifiers={{
          preventOverflow: { enabled: false },
          flip: { boundariesElement: 'scrollParent' },
        }}
        popperPlacement={adHocFilterSettings ? 'top-end' : undefined}
        selected={date || undefined}
        withPortal={false}
      />
    );
  } else if (FILTER_OPS_DATE_RANGE_PICKER.has(filterOperator)) {
    let startDate: Date | null = null;
    let endDate: Date | null = null;
    const filterValueDate = filterValue as FilterValueDateType;
    if (filterValue) {
      startDate = filterValueDate.startDate
        ? getDatePickerDateFromISO(filterValueDate.startDate)
        : null;
      endDate = filterValueDate.endDate ? getDatePickerDateFromISO(filterValueDate.endDate) : null;
    }

    return (
      <div className={styles.dateRange}>
        <DatePicker
          disabledKeyboardNavigation
          selectsStart
          showMonthDropdown
          showYearDropdown
          className={cx(styles.datePicker, styles.startDateRange)}
          dateFormat={getFormatForDatePicker(TIME_FORMATS['MM/DD/YYYY'])}
          endDate={endDate || null}
          maxDate={preventFutureDates ? new Date() : undefined}
          onChange={(newDate) => {
            newDate = newDate as Date;

            props.updateStartDate(newDate ? getLuxonDateFromDatePicker(newDate).toISO() : null);
          }}
          placeholderText={VIEW_DATE_FORMAT}
          popperClassName={styles.datePopover}
          popperModifiers={{
            preventOverflow: { enabled: false },
            flip: { boundariesElement: 'scrollParent' },
          }}
          popperPlacement={adHocFilterSettings ? 'top-end' : undefined}
          selected={startDate || null}
          startDate={startDate || null}
          withPortal={false}
          wrapperClassName={styles.startDateRangeWrapper}
        />
        <DatePicker
          disabledKeyboardNavigation
          selectsEnd
          showMonthDropdown
          showYearDropdown
          className={cx(styles.datePicker, styles.endDateRange)}
          dateFormat={getFormatForDatePicker(TIME_FORMATS['MM/DD/YYYY'])}
          endDate={endDate || null}
          maxDate={preventFutureDates ? new Date() : undefined}
          onChange={(newDate) => {
            newDate = newDate as Date;

            props.updateEndDate(
              newDate ? getLuxonDateFromDatePicker(newDate).endOf('day').toISO() : null,
            );
          }}
          placeholderText={VIEW_DATE_FORMAT}
          popperClassName={styles.datePopover}
          popperModifiers={{
            preventOverflow: { enabled: false },
            flip: { boundariesElement: 'scrollParent' },
          }}
          popperPlacement={adHocFilterSettings ? 'top-end' : undefined}
          selected={endDate || null}
          startDate={startDate || null}
          withPortal={false}
        />
      </div>
    );
  } else if (FILTER_OPS_RELATIVE_PICKER.has(filterOperator)) {
    const filterValueRelativeDate = filterValue as FilterValueRelativeDateType | undefined;
    const { relativeTimeType, number } = filterValueRelativeDate ?? {};
    const selectedRelativeTime = relativeTimeType
      ? DATE_RELATIVE_OPTIONS_BY_ID[relativeTimeType.id]
      : undefined;
    return (
      <div className={styles.relativeContainer}>
        <InputWithBlurSave
          containerClassName={styles.relativeNumInput}
          initialValue={number ? String(number || '') : ''}
          onNewValueSubmitted={(newValue) => {
            const newValNum = Number.parseInt(newValue);
            props.updateFilterValue({ ...filterValueRelativeDate, number: newValNum });
          }}
        />
        <div className={styles.relativeTimeDropdown}>
          <DropdownSelect
            fillWidth
            minimal
            usePortal
            noSelectionText="Select time"
            onChange={(item) =>
              props.updateFilterValue({
                ...filterValueRelativeDate,
                relativeTimeType: { id: item.id as DATE_RELATIVE_OPTION },
              })
            }
            options={DATE_RELATIVE_OPTIONS}
            selectedItem={selectedRelativeTime}
          />
        </div>
      </div>
    );
  }

  return (
    <InputWithBlurSave
      containerClassName={fillWidth ? styles.fillWidth : ''}
      errorText={error}
      initialValue={inputValue}
      onNewValueSubmitted={(newValue) => {
        setInputValue(newValue);
        if (FILTER_OPS_MULTISELECT.has(filterOperator)) {
          try {
            const valuesArr = JSON.parse(newValue);
            if (!Array.isArray(valuesArr) || valuesArr.length === 0) {
              return setError('Must be valid non empty javascript array.');
            }
          } catch {
            return setError('Must be valid non empty javascript array.');
          }
        } else if (FILTER_OPS_NUMBER.has(filterOperator)) {
          const numValue = parseFloat(newValue);
          if (isNaN(numValue) || String(numValue) !== newValue) {
            return setError('Must be a valid number');
          }
        }
        setError('');
        props.updateFilterValue(newValue);
      }}
    />
  );
};

export default FilterValueInput;
