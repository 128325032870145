import { keyBy } from 'utils/standard';
import {
  TableDataset,
  fetchDatasetPreviewSuccess,
  fetchDatasetPreviewRequest,
  fetchDatasetPreviewError,
  fetchDatasetRowCountSuccess,
  fetchDatasetRowCountError,
} from 'actions/dataSourceActions';
import { ErrorResponse } from 'actions/responseTypes';
import { createReducer } from '@reduxjs/toolkit';
import { fetchDatasetsError, fetchDatasetsSuccess } from 'actions/parentSchemaActions';

interface DatasetsReducer {
  datasets: { [datasetId: number]: TableDataset };
  error?: ErrorResponse;
}

const datasetsReducerInitialState: DatasetsReducer = {
  datasets: {},
};

export default createReducer(datasetsReducerInitialState, (builder) => {
  builder
    .addCase(fetchDatasetsSuccess, (state, { payload }) => {
      state.datasets = keyBy(payload.datasets, 'id');
      state.error = undefined;
    })
    .addCase(fetchDatasetsError, (state, { payload }) => {
      state.error = payload.errorData;
    })
    .addCase(fetchDatasetPreviewRequest, (state, { payload }) => {
      const fetchingDataset = state.datasets[payload.postData.dataset_id];
      if (fetchingDataset) {
        fetchingDataset.schema = [];
        fetchingDataset._rows = undefined;
      }
    })
    .addCase(fetchDatasetPreviewSuccess, (state, { payload }) => {
      const fetchedDataset = state.datasets[payload.postData.dataset_id];
      if (fetchedDataset) {
        fetchedDataset._error = undefined;
        fetchedDataset.schema = payload.dataset_preview.schema;
        fetchedDataset._rows = payload.dataset_preview._rows;
        fetchedDataset._unsupported_operations = payload.dataset_preview._unsupported_operations;
      }
    })
    .addCase(fetchDatasetPreviewError, (state, { payload }) => {
      const fetchedErrorDataset = state.datasets[payload.postData.dataset_id];
      if (fetchedErrorDataset && payload.dataset_preview) {
        fetchedErrorDataset._error = payload.error_msg;
      }
    })
    .addCase(fetchDatasetRowCountSuccess, (state, { payload }) => {
      const fetchedRowCountDataset = state.datasets[payload.postData.dataset_id];
      if (fetchedRowCountDataset) {
        fetchedRowCountDataset._total_row_count = payload._total_row_count;
      }
    })
    .addCase(fetchDatasetRowCountError, (state, { payload }) => {
      const fetchedRowCountDataset = state.datasets[payload.postData.dataset_id];
      fetchedRowCountDataset._total_row_count = undefined;
    })
    .addDefaultCase((state) => state);
});
