import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, sprinkles } from 'components/ds';
import { AddFilterButton } from './AddFilterButton';
import { FilterTag } from './FilterTag';

import { CustomerReportFilter } from 'actions/customerReportActions';
import { toggleDataPanelOpen } from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { FilterableColumn } from 'utils/customerReportUtils';
import { groupBy } from 'utils/standard';

type Props = {
  columns: FilterableColumn[];
  filters: CustomerReportFilter[] | undefined;
};

export const ReportBuilderFilters: FC<Props> = ({ columns, filters }) => {
  const dispatch = useDispatch();

  const isDataPanelOpen = useSelector(
    (state: ReportBuilderReduxState) => state.reportEditing.isDataPanelOpen,
  );

  const filterClausesByCol = useMemo(
    () => groupBy(filters, (filter) => filter.filterColumn.name),
    [filters],
  );

  return (
    <div
      className={sprinkles({
        flexItems: 'center',
        paddingX: 'sp4',
        paddingY: 'sp2',
      })}>
      <div
        className={sprinkles({ flex: 1, flexItems: 'alignCenter', flexWrap: 'wrap', gap: 'sp1' })}>
        {columns.map((col) => {
          const clauses = filterClausesByCol[col.name];
          if (!clauses) {
            return col.default ? <FilterTag column={col} key={`default_${col.name}`} /> : null;
          }
          return clauses.map((clause) => (
            <FilterTag clause={clause} column={col} key={clause.id} />
          ));
        })}
      </div>
      <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
        <AddFilterButton columns={columns} />
        {isDataPanelOpen ? null : (
          <Button onClick={() => dispatch(toggleDataPanelOpen())} type="tertiary">
            Show Data Panel
          </Button>
        )}
      </div>
    </div>
  );
};
