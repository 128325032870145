import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';

import { sprinkles } from 'components/ds';
import { sharedStyles } from '../vizConfigs/droppable/sharedDroppedStyles';
import Checkbox from 'components/checkbox';

import { DataPanelLink } from 'utils/filterLinking';
import { FILTER_OPERATOR_TYPES_BY_ID } from 'types/filterOperations';
import { toggleFilterLink } from 'actions/dashboardV2Actions';
import { dpLinkUpdated } from 'utils/customEventUtils';

type Props = { dataPanelId: string; link: DataPanelLink };

export const FilterLink: FC<Props> = ({ dataPanelId, link }) => {
  const dispatch = useDispatch();
  const sharedClasses = sharedStyles();

  const [isApplied, setIsApplied] = useState(link.applied);

  const operatorInfo = FILTER_OPERATOR_TYPES_BY_ID[link.operator];

  useEffect(() => {
    if (link.applied !== isApplied) {
      setIsApplied(link.applied);
      dpLinkUpdated({ dataPanelIds: [dataPanelId], elementName: link.elementName });
    }
  }, [link, dataPanelId, isApplied]);

  return (
    <div
      className={sprinkles({
        display: 'flex',
        alignItems: 'center',
        backgroundColor: isApplied ? 'lightBlue' : 'gray2',
        color: isApplied ? 'blue11' : 'gray12',
        marginBottom: 'sp1',
        borderRadius: 4,
        paddingLeft: 'sp1',
        fontWeight: 500,
      })}
      style={{ height: 32 }}>
      <div className={sprinkles({ flex: 2, marginRight: 'sp.5', truncateText: 'ellipsis' })}>
        {link.column}
      </div>
      <div className={sprinkles({ flex: 1, marginRight: 'sp.5', truncateText: 'ellipsis' })}>
        {operatorInfo.selectionValue}
      </div>
      <div className={sprinkles({ flex: 2, truncateText: 'ellipsis' })}>{link.elementName}</div>
      <div
        className={cx(
          sprinkles({ paddingX: 'sp1', height: 'fill', display: 'flex', alignItems: 'center' }),
          isApplied ? sharedClasses.linkDividerApplied : sharedClasses.linkDivider,
        )}>
        <Checkbox
          isChecked={isApplied}
          onChange={() => dispatch(toggleFilterLink({ dataPanelId, elementId: link.elementId }))}
        />
      </div>
    </div>
  );
};
