import { FC, useMemo } from 'react';
import cx from 'classnames';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { IconButton, sprinkles } from 'components/ds';
import { EmbedText } from '../EmbedText';

import { toggleFavoriteBuiltIn } from 'actions/customerReportBuilderMetadata';
import { BuiltInReportConfig } from 'actions/reportBuilderConfigActions';
import { setSelectedBuiltInReport } from 'reportBuilderContent/reducers/embeddedReportBuilderReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';

import * as styles from 'pages/ReportBuilder/HomeView/BuiltInReportCardClass.css';

type Props = {
  reportBuilderEmbedId: string;
  builtInReport: BuiltInReportConfig;
  className?: string;
};

export const BuiltInReportCard: FC<Props> = ({
  className,
  reportBuilderEmbedId,
  builtInReport,
}) => {
  const dispatch = useDispatch();

  const { favoriteBuiltIns, customerToken } = useSelector(
    (state: ReportBuilderReduxState) => ({
      favoriteBuiltIns: state.embeddedReportBuilder.favoriteBuiltIns,
      customerToken: state.embeddedReportBuilder.requestInfo.customerToken,
    }),
    shallowEqual,
  );

  const onClickFavorite = (builtInId: string) => {
    dispatch(
      toggleFavoriteBuiltIn({
        customerToken,
        postData: {
          report_builder_embed_id: reportBuilderEmbedId,
          built_in_id: builtInId,
        },
      }),
    );
  };

  const onClick = () => dispatch(setSelectedBuiltInReport(builtInReport.id));

  const isFavorited = useMemo(
    () => favoriteBuiltIns.includes(builtInReport.id),
    [favoriteBuiltIns, builtInReport.id],
  );

  return (
    <div
      className={cx(styles.builtInReportCardClass, className)}
      onClick={(e) => !e.defaultPrevented && onClick()}>
      <div
        className={sprinkles({
          flexItems: 'alignCenter',
          justifyContent: 'space-between',
          gap: 'sp.5',
        })}>
        <EmbedText
          className={sprinkles({ truncateText: 'ellipsis', marginX: 'sp.5' })}
          color="contentPrimary"
          heading="h3">
          {builtInReport.name}
        </EmbedText>
        <IconButton
          className={isFavorited ? styles.activeStarIcon : ''}
          name={isFavorited ? 'star' : 'regular-star'}
          onClick={(e) => {
            e.preventDefault();
            onClickFavorite(builtInReport.id);
          }}
        />
      </div>
      <EmbedText
        body="b2"
        className={sprinkles({ marginX: 'sp.5', overflow: 'hidden' })}
        color="contentPrimary">
        {builtInReport.description}
      </EmbedText>
    </div>
  );
};
