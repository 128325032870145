import { FC, useMemo, useState } from 'react';
import cx from 'classnames';

import { DataGrid, sprinkles } from 'components/ds';
import { DEFAULT_ROWS_PER_PAGE, PaginatorProps } from 'components/ds/DataGrid/paginator';

import { DataPanelTemplate } from 'types/dataPanelTemplate';

const rootClass = sprinkles({ parentContainer: 'fill', flexItems: 'column', overflow: 'hidden' });
const spacingClass = sprinkles({ padding: 'sp1.5' });

type Props = {
  dataPanel: DataPanelTemplate | undefined;
};

export const DataDebugger: FC<Props> = ({ dataPanel }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const paginatorProps: PaginatorProps = useMemo(() => {
    return {
      minimal: true,
      loading: dataPanel?._loading ?? false,
      totalRowCount: dataPanel?._rows?.length || 0,
      currentPage: currentPage,
      goToPage: ({ page }) => setCurrentPage(page),
    };
  }, [dataPanel, currentPage]);

  if (!dataPanel) {
    return (
      <div className={cx(rootClass, spacingClass)}>
        Select a data panel on the dashboard to use the debugger
      </div>
    );
  } else if (dataPanel._error) {
    return <div className={cx(rootClass, spacingClass)}>There was a problem fetching the data</div>;
  } else if (!dataPanel._rows) {
    return (
      <div className={cx(rootClass, spacingClass)}>
        Configure this data panel to see the underlying data
      </div>
    );
  }

  return (
    <div className={rootClass}>
      <DataGrid
        loading={dataPanel._loading}
        paginatorProps={paginatorProps}
        rows={dataPanel._rows.slice(DEFAULT_ROWS_PER_PAGE * (currentPage - 1))}
        schema={dataPanel._schema || []}
      />
    </div>
  );
};
