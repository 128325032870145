import { FC, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { BuiltInListItem } from 'pages/ReportBuilder/HomeView/BuiltInListItem';
import { ReportListItem } from 'pages/ReportBuilder/HomeView/ReportListItem';

import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { orderBy } from 'utils/standard';
import { getTimezoneAwareUnix } from 'utils/timezoneUtils';

import * as styles from 'pages/ReportBuilder/HomeView/AllReportTab.css';

interface Props {
  customerToken: string;
  reportBuilderEmbedId: string;
}

export const StarredReportTab: FC<Props> = ({ customerToken, reportBuilderEmbedId }) => {
  const { reports, builtInReports, favoriteBuiltIns } = useSelector(
    (state: ReportBuilderReduxState) => ({
      reports: state.embeddedReportBuilder.reports,
      builtInReports: state.embeddedReportBuilder.reportBuilderVersion?.config.builtInReports,
      favoriteBuiltIns: state.embeddedReportBuilder.favoriteBuiltIns,
    }),
    shallowEqual,
  );

  const builtInReportList = useMemo(
    () =>
      Object.values(builtInReports || {}).filter((builtIn) =>
        favoriteBuiltIns.includes(builtIn.id),
      ),
    [builtInReports, favoriteBuiltIns],
  );

  const filteredAndSortedReports = useMemo(() => {
    const filteredReports = reports.filter((report) => report.is_starred);
    return orderBy(filteredReports, (report) => getTimezoneAwareUnix(report.modified), 'desc');
  }, [reports]);

  return (
    <div className={styles.reportListContainer}>
      {!builtInReportList.length && !filteredAndSortedReports.length && (
        <div className={styles.emptyState}>
          <EmbedText body="b1" color="contentPrimary">
            No starred reports or built ins
          </EmbedText>
        </div>
      )}
      {builtInReportList.map((builtIn) => (
        <BuiltInListItem
          builtIn={builtIn}
          key={builtIn.id}
          reportBuilderEmbedId={reportBuilderEmbedId}
        />
      ))}
      {filteredAndSortedReports.map((report) => (
        <ReportListItem customerToken={customerToken} key={report.id} report={report} />
      ))}
    </div>
  );
};
