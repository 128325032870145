import * as RD from 'remotedata';

import { createSelector, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import * as customerActions from 'actions/customerReportActions';
import {
  CustomerReportSort,
  CustomerReport,
  CustomerReportAgg,
  CustomerReportConfig,
  CustomerReportDataInfo,
  CustomerReportGroupBy,
  CustomerReportView,
  UpdateCustomerReportViewName,
} from 'actions/customerReportActions';
import { ReportBuilderCol } from 'actions/reportBuilderConfigActions';
import {
  createReportBuilderEmailCadenceSuccess,
  deleteReportBuilderEmailCadenceSuccess,
  listReportBuilderEmailCadencesSuccess,
  ReportBuilderEmailCadence,
  updateReportBuilderEmailCadenceSuccess,
} from 'actions/reportBuilderEmailCadenceActions';
import { Jobs } from 'components/JobQueue/types';
import { FilterValueType } from 'constants/types';
import * as navUtils from 'pages/ReportBuilder/utils/nagivationUtils';
import { getNextViewName } from 'pages/ReportBuilder/utils/viewUtils';
import {
  fetchPreviewModalData,
  fetchPreviewReportData,
  fetchPreviewReportRowCount,
} from 'reducers/thunks/reportBuilderEditorThunks';
import {
  exportCustomerReportError,
  exportCustomerReportSuccess,
  exportReport,
} from 'reportBuilderContent/thunks/exportThunks';
import {
  fetchEmbeddedModalData,
  fetchEmbeddedReportData,
  fetchEmbeddedReportRowCount,
} from 'reportBuilderContent/thunks';
import { DatasetRow } from 'types/datasets';
import { FilterOperator } from 'types/filterOperations';
import {
  getAggColumnName,
  getAggUniqueId,
  getFilterDefaultOperation,
  getGroupByColumnName,
  getGroupByUniqueId,
  updateColumnOrdering,
} from 'utils/customerReportUtils';
import { isFilterClauseIncomplete } from 'utils/dataPanelConfigUtils';
import { maxBy } from 'utils/standard';
import { v4 as uuidv4 } from 'uuid';

export enum ReportModal {
  CONFIRM_CHANGE_DATASET = 'CONFIRM_CHANGE_DATASET',
  DATASET_SELECTION = 'DATASET_SELECTION',
  DATA_SELECTION = 'DATA_SELECTION',
  EXPORT = 'EXPORT',
  SAVE_AS = 'SAVE_AS',
  SCHEDULE_EXPORT = 'SCHEDULE_EXPORT',
  CLOSED = 'CLOSED',
  EDIT_BUILT_IN = 'EDIT_BUILT_IN',
  SAVE_BUILT_IN_AS_REPORT = 'SAVE_BUILT_IN_AS_REPORT',
  CONFIRM_CLOSE_REPORT = 'CONFIRM_CLOSE_REPORT',
}

export type ReportSchemaInfo = {
  groupBys: CustomerReportGroupBy[];
  aggs: CustomerReportAgg[];
  columnGroupBys: CustomerReportGroupBy[];
};

interface TableData {
  rows?: DatasetRow[];
  rowCount: RD.ResponseData<number>;
  error?: string;
  isLoading: boolean;
  loadingRequestId?: string;
  rowCountRequestId?: string;
  page: number;
  // requestId to Schema Info
  loadingSchemaInfo: ReportSchemaInfo | null;
  loadedSchemaInfo: ReportSchemaInfo | null;
}

interface ReportEditingState {
  selectedReportId: number | null;
  currentConfig: CustomerReportConfig | null;
  tableData: Record<string, TableData>; // viewId to TableData
  openModal: ReportModal;
  modalDatasetData: Record<string, RD.ResponseData<DatasetRow[]> | undefined>;
  exportUrl: RD.ResponseData<string>;
  emailCadences: RD.ResponseData<ReportBuilderEmailCadence[]>;

  currentView: string | null;
  currentDatasetId: string | null;
  openFilterId: number | null;
  isDataPanelOpen: boolean;
  // Right now only used for exporting to csv
  jobs: Record<string, Jobs>;
}

const initialState: ReportEditingState = {
  selectedReportId: null,
  currentConfig: null,
  tableData: {},
  openModal: ReportModal.CLOSED,
  modalDatasetData: {},
  exportUrl: RD.Idle(),
  emailCadences: RD.Idle(),

  currentView: null,
  currentDatasetId: null,
  openFilterId: null,
  isDataPanelOpen: true,
  jobs: {},
};

type UpdateFilterPayload = {
  filterOperator: FilterOperator;
  value: FilterValueType;
  filterId: number;
};

type CreateFilterPayload = {
  filterOperator?: FilterOperator;
  value?: FilterValueType;
  column: ReportBuilderCol;
  /** If true, this filter should be applied last in the DB query rather than at the start (before grouping) */
  isPostFilter?: boolean;
};

const INITIAL_TABLE_DATA: TableData = {
  isLoading: false,
  rowCount: RD.Idle(),
  page: 1,
  loadingSchemaInfo: null,
  loadedSchemaInfo: null,
};

function createBlankView(state: ReportEditingState): CustomerReportView {
  const name = getNextViewName(state.currentConfig?.views);

  return {
    id: uuidv4(),
    name,
    columnOrder: state.currentConfig?.dataInfo?.columns ?? [],
    hiddenColumns: [],
    filters: [],
    sort: [],
  };
}

const createCurrentViewIfNone = (state: ReportEditingState): void => {
  if (!state.currentConfig || state.currentConfig.views !== undefined || state.currentView) return;

  const view = createBlankView(state);
  state.currentConfig.views = [view];
  state.currentView = view.id;

  navUtils.goToReportView(state.selectedReportId, state.currentView);
};

const updateCurrentView = (
  state: ReportEditingState,
  updateView: (view: customerActions.CustomerReportView) => void,
) => {
  const view = state.currentConfig?.views?.find((view) => view.id === state.currentView);
  if (view) updateView(view);
};

function updateCurrentTableData(state: ReportEditingState, updateData: (data: TableData) => void) {
  const viewId = state.currentView;
  if (!viewId) return;

  const currentTableData = state.tableData[viewId];
  if (!currentTableData) state.tableData[viewId] = { ...INITIAL_TABLE_DATA };
  updateData(state.tableData[viewId]);
}

/**
 * When dataset columns, column visibility, group bys, or aggregations change,
 * update group bys, aggregations, sort, and filters to ensure we're only operating on columns that exist
 */
function updateColumns(view: CustomerReportView) {
  // 1. Group bys and aggregations must exist in the original dataset
  const datasetColumns = new Set(view.columnOrder);
  if (view.groupBys) {
    const filteredGroupBys = view.groupBys.filter(({ column }) => datasetColumns.has(column.name));
    if (filteredGroupBys.length !== view.groupBys.length) view.groupBys = filteredGroupBys;
  }

  if (view.aggregations) {
    const filteredAggs = view.aggregations.filter(({ column }) => datasetColumns.has(column.name));
    if (filteredAggs.length !== view.aggregations.length) view.aggregations = filteredAggs;
  }

  // 2. Sort and filters can exist in the dataset, group bys, or aggregations
  // This should be after group bys and aggregations are filtered
  const groupByNames = (view.groupBys ?? [])
    .concat(view.columnGroupBys ?? [])
    .map(getGroupByColumnName);
  const aggNames = (view.aggregations ?? []).map(getAggColumnName);
  const allColumns = view.columnOrder.concat(groupByNames).concat(aggNames);
  const visibleSet = new Set(allColumns);
  const hiddenSet = new Set(view.hiddenColumns);

  if (view.sort) {
    const filteredSort = view.sort.filter(
      ({ column }) => visibleSet.has(column.name) && !hiddenSet?.has(column.name),
    );
    if (filteredSort.length !== view.sort.length) view.sort = filteredSort;
  }

  const filteredFilters = view.filters.filter(({ filterColumn }) =>
    visibleSet.has(filterColumn.name),
  );
  if (filteredFilters.length !== view.filters.length) view.filters = filteredFilters;
}

// If a view is going to be pivoted should clear sorting as sorting is not supported in pivots
function clearSortIfPivot(view: CustomerReportView) {
  if (view.aggregations?.length && view.groupBys?.length && view.columnGroupBys?.length)
    view.sort = [];
}

const reportEditingSlice = createSlice({
  name: 'reportEditing',
  initialState,
  reducers: {
    clearSelectedReport: () => {
      return { ...initialState };
    },
    openCustomerReport: (
      state,
      { payload }: PayloadAction<CustomerReport & { viewId?: string }>,
    ) => {
      state.selectedReportId = payload.id;
      state.currentConfig = payload.config;

      state.currentView = (payload.viewId || payload.config.views?.[0]?.id) ?? null;
      navUtils.goToReportView(payload.id, state.currentView);

      // We don't want to open it immediately in preview mode
      if (payload.id !== 0 && !payload.config.dataInfo) {
        state.openModal = ReportModal.DATASET_SELECTION;
      }
    },
    closeReportModal: (state) => {
      state.openModal = ReportModal.CLOSED;
      state.modalDatasetData = {};
      state.currentDatasetId = null;
    },
    openConfirmChangeDatasetModal: (state, { payload }: PayloadAction<string | undefined>) => {
      state.openModal = state.currentConfig?.dataInfo
        ? ReportModal.CONFIRM_CHANGE_DATASET
        : ReportModal.DATASET_SELECTION;
      state.currentDatasetId = payload ?? null;
    },
    openDatasetModal: (state, { payload }: PayloadAction<string | undefined>) => {
      state.openModal = ReportModal.DATASET_SELECTION;
      state.currentDatasetId = payload ?? null;
    },
    openDataModal: (state) => {
      if (state.currentConfig?.dataInfo) {
        state.openModal = ReportModal.DATA_SELECTION;
        state.currentDatasetId = state.currentConfig.dataInfo.datasetId;
      } else state.openModal = ReportModal.DATASET_SELECTION;
    },
    toggleDataPanelOpen: (state) => {
      state.isDataPanelOpen = !state.isDataPanelOpen;
    },
    selectDataset: (state, { payload }: PayloadAction<string>) => {
      state.currentDatasetId = payload;
      state.openModal = ReportModal.DATA_SELECTION;
    },
    updateOrderedColumns: (state, { payload }: PayloadAction<string[]>) => {
      updateCurrentView(state, (view) => (view.columnOrder = payload));
    },
    swapSelectedColumns: (
      state,
      { payload }: PayloadAction<{ oldCol: string | number; newCol?: string | number }>,
    ) => {
      updateCurrentView(state, (view) => {
        view.columnOrder = updateColumnOrdering(view.columnOrder, payload.oldCol, payload.newCol);
      });
    },
    toggleHiddenColumn: (state, { payload }: PayloadAction<{ col: string; hide: boolean }>) => {
      updateCurrentView(state, (view) => {
        if (payload.hide) {
          view.hiddenColumns.push(payload.col);
          // Don't sort by the column if it's hidden
          updateColumns(view);
        } else {
          view.hiddenColumns = view.hiddenColumns.filter((col) => col !== payload.col);
        }
      });
    },
    saveDataInfo: (state, { payload }: PayloadAction<CustomerReportDataInfo>) => {
      if (!state.currentConfig) return;

      const newDataset = state.currentConfig.dataInfo?.datasetId !== payload.datasetId;

      state.tableData = {};
      state.currentConfig.views?.forEach((view) => {
        if (newDataset) {
          view.hiddenColumns = [];
          view.columnOrder = payload.columns;
          view.filters = [];
        } else {
          const newOrder = view.columnOrder.filter((col) => payload.columns.includes(col));
          payload.columns.forEach((newCol) => {
            if (!newOrder.includes(newCol)) newOrder.push(newCol);
          });
          view.columnOrder = newOrder;
        }
        updateColumns(view);
      });

      state.currentConfig.dataInfo = payload;
      state.openModal = ReportModal.CLOSED;

      createCurrentViewIfNone(state);
    },
    createFilter: (state, { payload }: PayloadAction<CreateFilterPayload>) => {
      updateCurrentView(state, (view) => {
        const incompleteFilter = view.filters.find(
          (filter) =>
            isFilterClauseIncomplete(filter) && filter.filterColumn.name === payload.column.name,
        );

        if (incompleteFilter) {
          state.openFilterId = incompleteFilter.id;
          return;
        }

        const filterOperator = getFilterDefaultOperation(
          payload.column.type,
          payload.filterOperator,
        );

        const maxId = maxBy(view.filters, 'id')?.id ?? 0;
        view.filters.push({
          id: maxId + 1,
          isPostFilter: payload.isPostFilter,
          filterColumn: payload.column,
          filterOperation: { id: filterOperator },
          filterValue: payload.value,
        });

        if (!payload.filterOperator) state.openFilterId = maxId + 1;
      });
    },
    clearOpenFilter: (state) => {
      updateCurrentView(state, (view) => {
        if (state.openFilterId === null) return;

        const emptyFilterIdx = view.filters.findIndex(
          (filter) => filter.id === state.openFilterId && isFilterClauseIncomplete(filter),
        );
        if (emptyFilterIdx < 0) return;
        view.filters.splice(emptyFilterIdx, 1);
      });
      state.openFilterId = null;
    },
    deleteFilter: (state, { payload }: PayloadAction<number>) => {
      updateCurrentView(state, (view) => {
        view.filters = view.filters.filter((filter) => filter.id !== payload);
      });
      state.openFilterId = null;
    },
    updateFilter: (state, { payload }: PayloadAction<UpdateFilterPayload>) => {
      updateCurrentView(state, (view) => {
        view.filters = view.filters.map((filter) => {
          if (filter.id !== payload.filterId) return filter;
          return {
            ...filter,
            filterOperation: { id: payload.filterOperator },
            filterValue: payload.value,
          };
        });
      });
    },
    handleTableColumnOrderChange: (state, { payload }: PayloadAction<string[]>) => {
      updateCurrentView(state, (view) => {
        const newOrder = payload;
        view.columnOrder.forEach((col, idx) => {
          if (!newOrder.includes(col)) newOrder.splice(idx, 0, col);
        });
        view.columnOrder = newOrder;
      });
    },
    addGroupBy: (state, { payload }: customerActions.AddGroupByPayload) => {
      updateCurrentView(state, (view) => {
        if (payload.isColumnGroupBy) {
          if (!view.columnGroupBys) view.columnGroupBys = [];
          view.columnGroupBys.push(payload.groupBy);
        } else {
          if (!view.groupBys) view.groupBys = [];
          view.groupBys.push(payload.groupBy);
          clearSortIfPivot(view);
        }
      });
    },
    updateGroupBy: (state, { payload }: customerActions.UpdateGroupByPayload) => {
      const update = (groupBys?: CustomerReportGroupBy[]) =>
        groupBys?.map((groupBy) =>
          getGroupByUniqueId(groupBy) === payload.id ? payload.groupBy : groupBy,
        );

      updateCurrentView(state, (view) => {
        if (payload.isColumnGroupBy) {
          view.columnGroupBys = update(view.columnGroupBys);
        } else {
          view.groupBys = update(view.groupBys);
          updateColumns(view);
        }
      });
    },
    deleteGroupBy: (state, { payload }: customerActions.DeleteGroupByPayload) => {
      const filter = (groupBys?: CustomerReportGroupBy[]) =>
        groupBys?.filter((groupBy) => getGroupByUniqueId(groupBy) !== payload.id);

      updateCurrentView(state, (view) => {
        if (payload.isColumnGroupBy) {
          view.columnGroupBys = filter(view.columnGroupBys);
        } else {
          view.groupBys = filter(view.groupBys);
          updateColumns(view);
        }
      });
    },
    orderGroupBys: (state, { payload }: customerActions.OrderGroupBysPayload) => {
      updateCurrentView(state, (view) => {
        if (payload.isColumnGroupBys) view.columnGroupBys = payload.groupBys;
        else view.groupBys = payload.groupBys;
      });
      updateCurrentTableData(state, ({ loadedSchemaInfo }) => {
        if (!loadedSchemaInfo) return;
        if (payload.isColumnGroupBys) loadedSchemaInfo.columnGroupBys = payload.groupBys;
        else loadedSchemaInfo.groupBys = payload.groupBys;
      });
    },
    addAgg: (state, { payload }: PayloadAction<CustomerReportAgg>) => {
      updateCurrentView(state, (view) => {
        if (!view.aggregations) view.aggregations = [];
        view.aggregations.push(payload);
        clearSortIfPivot(view);
      });
    },
    updateAgg: (state, { payload }: PayloadAction<{ id: string; agg: CustomerReportAgg }>) => {
      updateCurrentView(state, (view) => {
        view.aggregations = view.aggregations?.map((agg) =>
          getAggUniqueId(agg) === payload.id ? payload.agg : agg,
        );
        updateColumns(view);
      });
    },
    deleteAgg: (state, { payload: aggId }: PayloadAction<string>) => {
      updateCurrentView(state, (view) => {
        view.aggregations = view.aggregations?.filter((agg) => getAggUniqueId(agg) !== aggId);
        updateColumns(view);
      });
    },
    orderAggs: (state, { payload }: PayloadAction<CustomerReportAgg[]>) => {
      updateCurrentView(state, (view) => {
        view.aggregations = payload;
      });
      updateCurrentTableData(state, (data) => {
        if (data.loadedSchemaInfo) data.loadedSchemaInfo.aggs = payload;
      });
    },
    updateSort: (state, { payload: sortInfo }: PayloadAction<CustomerReportSort[]>) => {
      updateCurrentView(state, (view) => {
        view.sort = sortInfo;
      });
    },
    openModalType: (state, { payload }: PayloadAction<ReportModal>) => {
      state.openModal = payload;
    },
    /**
     * If a view is provided, duplicates it into a new view. If not, creates a blank view.
     */
    createView: (state, { payload: view }: PayloadAction<CustomerReportView | undefined>) => {
      const currentConfig = state.currentConfig;
      if (!currentConfig) return;

      const viewData = view
        ? { ...view, id: uuidv4(), name: `${view.name} Copy` }
        : createBlankView(state);
      state.currentView = viewData.id;
      if (!currentConfig.views) currentConfig.views = [viewData];
      else currentConfig.views.push(viewData);

      navUtils.goToReportView(state.selectedReportId, state.currentView);
    },
    deleteView: (state, { payload: viewId }: PayloadAction<string>) => {
      const views = state.currentConfig?.views;
      if (!views) return;

      const index = views.findIndex((view) => view.id === viewId);
      if (index < 0) return;
      views.splice(index, 1);

      if (state.currentView === viewId) {
        const nextViewId = views[Math.max(index - 1, 0)]?.id || null;
        state.currentView = nextViewId;

        navUtils.goToReportView(state.selectedReportId, state.currentView);
      }
    },
    updateViewName: (state, { payload }: PayloadAction<UpdateCustomerReportViewName>) => {
      const views = state.currentConfig?.views;
      if (!views) return;

      const index = views.findIndex((v) => v.id === payload.viewId);
      if (index < 0) return;

      views[index].name = payload.name;
    },
    setCurrentView: (state, { payload: viewId }: PayloadAction<string>) => {
      state.currentView = viewId;

      navUtils.goToReportView(state.selectedReportId, state.currentView);
    },
    clearExport: (state) => {
      state.exportUrl = RD.Idle();
    },
    setLoadingSchemaInfo: (state, { payload }: customerActions.SetLoadingSchemaInfoPayload) => {
      const emptyGroupBys = payload.groupBys.length === 0;
      const emptyAggs = payload.aggs.length === 0;
      if (emptyAggs && emptyGroupBys) return;

      updateCurrentTableData(state, (data) => {
        data.loadingSchemaInfo = {
          groupBys: payload.groupBys,
          aggs: payload.aggs,
          columnGroupBys: emptyGroupBys || emptyAggs ? [] : payload.columnGroupBys ?? [],
        };
      });
    },
    setCompletedJobs: (state, { payload }: PayloadAction<string[]>) => {
      payload.forEach((jobId) => {
        if (jobId in state.jobs) delete state.jobs[jobId];
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(customerActions.createCustomerReportSuccess, (state, { payload }) => {
        state.selectedReportId = payload.report.id;
        state.currentConfig = payload.report.config;

        const viewId = state.currentConfig.views?.[0]?.id ?? null;
        state.openModal = ReportModal.CLOSED;
        state.currentView = viewId;
        if (!payload.report.config.dataInfo) state.openModal = ReportModal.DATASET_SELECTION;

        navUtils.goToReportView(state.selectedReportId, state.currentView);
      })
      .addCase(customerActions.saveCustomerReportSuccess, (state, { payload }) => {
        if (state.selectedReportId !== payload.report.id) return;
        state.currentConfig = payload.report.config;
      })
      .addCase(exportReport.pending, (state) => {
        state.exportUrl = RD.Loading();
      })
      .addCase(exportReport.fulfilled, (state, { payload }) => {
        state.jobs = { ...state.jobs, ...payload };
      })
      .addCase(exportCustomerReportSuccess, (state, { payload }) => {
        state.exportUrl = RD.Success(payload.export_url);
      })
      .addCase(listReportBuilderEmailCadencesSuccess, (state, { payload }) => {
        state.emailCadences = RD.Success(payload.emails);
      })
      .addCase(createReportBuilderEmailCadenceSuccess, (state, { payload }) => {
        if (!RD.isSuccess(state.emailCadences)) return;
        state.emailCadences.data.push(payload.email);
      })
      .addCase(deleteReportBuilderEmailCadenceSuccess, (state, { payload }) => {
        if (!RD.isSuccess(state.emailCadences)) return;
        state.emailCadences.data = state.emailCadences.data.filter(
          (email) => email.id !== payload.id,
        );
      })
      .addCase(updateReportBuilderEmailCadenceSuccess, (state, { payload }) => {
        if (!RD.isSuccess(state.emailCadences)) return;
        const index = state.emailCadences.data.findIndex((email) => email.id === payload.email.id);
        state.emailCadences.data[index] = payload.email;
      })
      .addMatcher(isAnyOf(exportReport.rejected, exportCustomerReportError), (state) => {
        state.exportUrl = RD.Error('Error exporting report');
      })
      .addMatcher(
        isAnyOf(fetchEmbeddedModalData.pending, fetchPreviewModalData.pending),
        (state, { meta }) => {
          state.modalDatasetData[meta.arg] = RD.Loading();
        },
      )
      .addMatcher(
        isAnyOf(fetchEmbeddedModalData.rejected, fetchPreviewModalData.rejected),
        (state, { meta }) => {
          state.modalDatasetData[meta.arg] = RD.Error('Error loading data ');
        },
      )
      .addMatcher(
        isAnyOf(fetchEmbeddedModalData.fulfilled, fetchPreviewModalData.fulfilled),
        (state, { meta, payload }) => {
          state.modalDatasetData[meta.arg] = RD.Success(payload.rows);
        },
      )
      .addMatcher(
        isAnyOf(fetchEmbeddedReportData.pending, fetchPreviewReportData.pending),
        (state, { meta }) =>
          updateCurrentTableData(state, (data) => {
            data.isLoading = true;
            data.loadingRequestId = meta.requestId;
            data.page = meta.arg ?? 1;
            if (meta.arg === undefined) data.rowCount = RD.Loading();
          }),
      )
      .addMatcher(
        isAnyOf(fetchEmbeddedReportData.rejected, fetchPreviewReportData.rejected),
        (state, { meta }) =>
          updateCurrentTableData(state, (data) => {
            if (data.loadingRequestId !== meta.requestId) return;

            data.error = 'Error loading data';
            data.isLoading = false;
            data.loadingSchemaInfo = null;
          }),
      )
      .addMatcher(
        isAnyOf(fetchEmbeddedReportData.fulfilled, fetchPreviewReportData.fulfilled),
        (state, { payload, meta }) =>
          updateCurrentTableData(state, (data) => {
            if (data.loadingRequestId !== meta.requestId) return;

            data.rows = payload.rows;
            data.isLoading = false;
            data.loadedSchemaInfo = data.loadingSchemaInfo;
            data.loadingSchemaInfo = null;
          }),
      )
      .addMatcher(
        isAnyOf(fetchEmbeddedReportRowCount.pending, fetchPreviewReportRowCount.pending),
        (state, { meta }) =>
          updateCurrentTableData(state, (data) => {
            data.rowCount = RD.Loading();
            data.rowCountRequestId = meta.requestId;
          }),
      )
      .addMatcher(
        isAnyOf(fetchEmbeddedReportRowCount.rejected, fetchPreviewReportRowCount.rejected),
        (state, { meta }) =>
          updateCurrentTableData(state, (data) => {
            if (data.rowCountRequestId !== meta.requestId) return;
            data.rowCount = RD.Error('Error loading row count');
          }),
      )
      .addMatcher(
        isAnyOf(fetchEmbeddedReportRowCount.fulfilled, fetchPreviewReportRowCount.fulfilled),
        (state, { payload, meta }) =>
          updateCurrentTableData(state, (data) => {
            if (data.rowCountRequestId !== meta.requestId) return;
            data.rowCount = RD.Success(payload.row_count);
          }),
      );
  },
});

export const getCanDeleteView = createSelector(
  (state: ReportEditingState) => state.currentConfig?.views?.length,
  (viewsLength) => (viewsLength || 0) > 1,
);

export const getCurrentViewTableData = createSelector(
  (state: ReportEditingState) => state.currentView,
  (state: ReportEditingState) => state.tableData,
  (currentView, tableData) => (currentView && tableData[currentView]) || INITIAL_TABLE_DATA,
);

export const reportEditingReducer = reportEditingSlice.reducer;

export const {
  openCustomerReport,
  clearSelectedReport,
  closeReportModal,
  selectDataset,
  openDatasetModal,
  openConfirmChangeDatasetModal,
  saveDataInfo,
  openDataModal,
  updateOrderedColumns,
  swapSelectedColumns,
  toggleHiddenColumn,
  createFilter,
  deleteFilter,
  updateFilter,
  clearOpenFilter,
  createView,
  updateViewName,
  deleteView,
  setCurrentView,
  toggleDataPanelOpen,
  handleTableColumnOrderChange,
  openModalType,
  clearExport,
  addGroupBy,
  updateGroupBy,
  deleteGroupBy,
  addAgg,
  updateAgg,
  setLoadingSchemaInfo,
  orderGroupBys,
  orderAggs,
  deleteAgg,
  updateSort,
  setCompletedJobs,
} = reportEditingSlice.actions;
