import { Component } from 'react';
import cx from 'classnames';
import { SCHEMA_VIEWER_WIDTH } from './styles.css';
import * as sharedStyles from './styles.css';
import { find, map, sortBy, uniq } from 'utils/standard';
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { Menu } from '@blueprintjs/core';
import sqlFormatter from 'sql-formatter';
import { produce } from 'immer';
import { v4 as uuidv4 } from 'uuid';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import sql from 'react-syntax-highlighter/dist/esm/languages/hljs/sql';

import {
  IconButton,
  Icon,
  Spinner,
  sprinkles,
  vars,
  APP_PORTAL_ID,
  Modal,
  AlertModal,
} from 'components/ds';
import DatasetMenuItem from 'pages/manageDataTablesPage/datasetMenuItem';
import DatasetModal from 'components/DatasetModal';
import { DrilldownDatasetColumns } from './DrilldownDatasetColumns';
import { DatasetEditorNonIdealState } from './DatasetEditorNonIdealState';
import SQLEditor from 'components/SqlEditor';
import NavTabs from 'components/core/navTabs';
import DropdownSelect from 'shared/DropdownSelect';
import { SchemaViewer } from 'components/resource/SchemaViewer';
import { DrilldownDatasetColumnsFormatConfig } from './DrilldownDatasetColumnsFormatConfig';
import InfoCard from 'shared/InfoCard';

import { createDashboardDatasetV2 } from 'actions/dashboardV2Actions';
import { setSelectedDrilldownColumn } from 'actions/cssLinkActions';
import {
  fetchEditorDatasetPreview,
  fetchEditorDatasetRowCount,
  FetchEditorDatasetPreviewBody,
  saveDatasetQuery,
  saveDraftDatasetQuery,
  deleteDataset,
  editDatasetName,
  Dataset,
  updateDashboardDatasetSchema,
} from 'actions/datasetActions';
import { TableDataset, ParentSchema } from 'actions/dataSourceActions';
import { trackEvent, EVENTS } from 'analytics/exploAnalytics';
import { DashboardElement, DashboardVariableMap } from 'types/dashboardTypes';
import { showDuplicateColumnNameToast } from 'shared/sharedToasts';
import {
  datasetEditorShowsDatasetsAsDropdown,
  datasetEditorShowsDatasetsAsList,
} from 'constants/dataPanelEditorConstants';
import { getDatasetName } from 'utils/naming';
import { STEPS, SUB_STEPS } from 'constants/onboardingConstants';
import { shouldCompleteOnboardingStep } from 'utils/onboarding';
import { completeOnboardingSubStep } from 'actions/onboardingActions';
import { isDatasetInUse } from 'utils/dashboardUtils';
import { ReduxState } from 'reducers/rootReducer';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { FetchDashboardDatasetPreviewData } from 'actions/responseTypes';
import Poller from 'components/JobQueue/Poller';
import { ACTION } from 'actions/types';
import { bulkEnqueueJobs, JobDefinition } from 'actions/jobQueueActions';
import { Jobs } from 'components/JobQueue/types';
import { DateTime } from 'luxon';
import { QueryRuntime } from '../DashboardDebugger/QueryRuntime';
import { DATASET_EDITOR_MODE } from '../editDashboardPage';
import {
  SELECT_DATASET_FOR_DRILLDOWN_FORMATTING,
  DrilldownDatasetId,
} from 'utils/customEventUtils';
import { isQueryDependentOnVariable } from 'utils/variableUtils';
import { CUSTOMER_ID_VARIABLES_SET } from 'constants/variables';
import { DatasetPreview } from './DatasetPreview';

SyntaxHighlighter.registerLanguage('sql', sql);

const DATASET_LIST_WIDTH = 300;
export const MENU_HEIGHT = 56;
export const INITIAL_DATASET_EDITOR_HEIGHT = 300;
const DATASET_PREVIEW_CONTROLS_BAR_HEIGHT = 40;

export const DATASET_VIEWS = {
  QUERY: {
    id: 'QUERY',
    name: 'Query',
  },
  PREVIEW: {
    id: 'PREVIEW',
    name: 'Preview',
  },
  FORMATTING: {
    id: 'FORMAT',
    name: 'Format',
  },
  DEBUGGER: {
    id: 'DEBUGGER',
    name: 'Debugger',
  },
};

const styles = (theme: Theme) =>
  createStyles({
    compactButton: {
      '&.bp3-button': {
        padding: `${vars.spacing['sp.5']}px ${vars.spacing['sp1']}px`,
      },
    },
    editor: {
      height: '100%',
      width: `calc(100% - ${DATASET_LIST_WIDTH}px)`,
    },
    editorTopBar: {
      backgroundColor: vars.colors.white,
      borderBottom: `1px solid ${vars.colors.gray7}`,
      height: MENU_HEIGHT,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: vars.spacing['sp1.5'],
      paddingLeft: 0,
      overflow: 'hidden',
    },
    datasetDropdown: {
      width: `calc(100% - 80px)`,
    },
    editorDatasetViewTabs: {
      height: MENU_HEIGHT,
    },
    editorContainer: {
      height: `calc(100% - ${MENU_HEIGHT}px)`,
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      position: 'relative',
    },
    datasetPreviewTable: {
      height: `calc(100% - ${DATASET_PREVIEW_CONTROLS_BAR_HEIGHT}px)`,
    },
    datasetListContainer: {
      height: `calc(100% - ${MENU_HEIGHT}px)`,
      overflowY: 'auto',
    },
    schemaViewer: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    modalFooter: {
      display: 'flex',
      alignItems: 'center',
      margin: 0,
      padding: `0 ${theme.spacing(5)}px`,
    },
    warningHeader: {
      fontSize: 18,
      display: 'flex',
      alignItems: 'center',
      marginTop: `${theme.spacing(2)}px`,
    },
    queryDebugger: {
      margin: vars.spacing['sp1.5'],
      padding: vars.spacing['sp1'],
      overflow: 'auto',
      width: `calc(100% - ${vars.spacing['sp3']}px)`,
      backgroundColor: vars.colors.gray3,
      borderRadius: 4,

      '& pre': {
        backgroundColor: 'initial !important',
        marginTop: 0,
      },
    },
    queryDebuggerInfo: {
      margin: vars.spacing['sp1.5'],
      marginBottom: vars.spacing['sp1.5'],
      height: 'fit-content',
    },
    schemaViewerActionItemsRoot: {
      width: SCHEMA_VIEWER_WIDTH,
      minWidth: SCHEMA_VIEWER_WIDTH,
    },
  });

type PassedProps = {
  dashboardId: number;
  isOpen: boolean;
  datasetEditorMode: DATASET_EDITOR_MODE;
  toggleEditorVisibility: () => void;
  toggleIsExpanded: () => void;
  datasets: Record<string, Dataset>;
  dashboardElements: DashboardElement[];
  dataPanels: DataPanelTemplate[];
  selectedDataPanelSourceDatasetId?: string;
  parentSchemas: ParentSchema[];
  schemaTablesMap: { [schemaId: number]: { [datasetId: number]: TableDataset } };
  pageWidth: number | null;
  dashboardVars: DashboardVariableMap;
  selectedUserGroupId?: number;
};

type Props = PassedProps &
  WithStyles<typeof styles> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  RouteComponentProps;

type State = {
  selectedDatasetId?: string;
  datasetQueries: { [datasetId: string]: string };
  actionLoadingDatasetId?: string;
  createDatasetModalOpen: boolean;
  editDatasetId: string | null;
  deleteDatasetId: string | null;
  datasetEditorView: string;
  awaitedJobs: Record<string, Jobs>;
};

class DashboardDatasetEditor extends Component<Props, State> {
  state: State = {
    datasetEditorView: DATASET_VIEWS.QUERY.id,
    datasetQueries: {},
    createDatasetModalOpen: false,
    editDatasetId: null,
    deleteDatasetId: null,
    awaitedJobs: {},
  };

  selectDatasetForDrilldownFormatting = (e: CustomEvent<DrilldownDatasetId>) => {
    if (e.detail.id !== this.state.selectedDatasetId) {
      this.setState({
        selectedDatasetId: e.detail.id,
        datasetEditorView: DATASET_VIEWS.FORMATTING.id,
      });
      this.switchSelectedDataset(this.props.datasets[e.detail.id]);
    }
    this.props.toggleIsExpanded();
    this.getFormattingPreview(e.detail.id);
  };

  componentDidMount() {
    if (
      this.state.selectedDatasetId === undefined &&
      Object.values(this.props.datasets).length > 0
    ) {
      this.switchSelectedDataset(this.getSortedDatasets()[0]);
    }
    document.addEventListener('keydown', this.handleKeyDown);
    // @ts-ignore
    window.addEventListener(
      SELECT_DATASET_FOR_DRILLDOWN_FORMATTING,
      this.selectDatasetForDrilldownFormatting,
    );
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
    // @ts-ignore
    window.removeEventListener(
      SELECT_DATASET_FOR_DRILLDOWN_FORMATTING,
      this.selectDatasetForDrilldownFormatting,
    );
  }

  currentQuery = () => {
    const { datasetQueries, selectedDatasetId } = this.state;
    if (!selectedDatasetId) return;

    return datasetQueries[selectedDatasetId];
  };

  handleKeyDown = (event: KeyboardEvent) => {
    const dataset = this.getSelectedDataset();
    if (!dataset) return;

    if (event.metaKey && event.shiftKey && event.key === 'f') {
      this.formatSqlQuery();
    } else if (event.metaKey && event.key === 'Enter') {
      this.getTablePreview();
    } else if (event.metaKey && event.shiftKey && event.key === 's') {
      if (this.currentQuery() === dataset.query) return;
      this.saveDashboardDataset();
    }
  };

  componentDidUpdate(prevProps: Props) {
    const newDatasets = Object.keys(this.props.datasets);
    const oldDatasets = Object.keys(prevProps.datasets);
    if (
      this.state.selectedDatasetId === undefined &&
      Object.values(this.props.datasets).length > 0
    ) {
      this.switchSelectedDataset(this.getSortedDatasets()[0]);
    } else if (newDatasets.length > oldDatasets.length) {
      const diff = newDatasets.filter((x) => !oldDatasets.includes(x));
      if (diff.length === 1) {
        this.switchSelectedDataset(this.props.datasets[diff[0]]);
      }
    }
  }

  render() {
    const { awaitedJobs } = this.state;
    return (
      <div
        className={sprinkles({
          height: 'fill',
          borderColor: 'gray7',
          backgroundColor: 'white',
          borderTop: 1,
        })}>
        <Poller
          awaitedJobs={awaitedJobs}
          updateJobResult={(finishedJobIds, onComplete) => {
            if (finishedJobIds.length > 0)
              this.setState((currentState) => {
                const newAwaitedJobs = produce(currentState.awaitedJobs, (draft) =>
                  finishedJobIds.forEach((jobId) => delete draft[jobId]),
                );
                return { awaitedJobs: newAwaitedJobs };
              });

            onComplete();
          }}
        />
        {this.renderEditorBody()}
        {this.renderCreateDatasetModal()}
        {this.renderEditDatasetModal()}
        {this.renderDeleteDatasetModal()}
      </div>
    );
  }

  switchSelectedDataset = (newDataset: Dataset) => {
    const { dashboardVars, selectedUserGroupId, dashboardId, setSelectedDrilldownColumn } =
      this.props;

    setSelectedDrilldownColumn();

    if (newDataset.query && newDataset._error === null) {
      const postData = {
        dataset_id: newDataset.id,
        query: newDataset.query,
        parent_schema_id: newDataset.parent_schema_id,
        variables: { ...dashboardVars },
        customer_id: selectedUserGroupId,
        resource_id: dashboardId,
        timezone: DateTime.local().zoneName,
      };

      this.fetchDatasetWrapper(
        !newDataset._rows ? postData : undefined,
        !newDataset._total_row_count ? postData : undefined,
      );
    }

    const stateUpdate = {
      selectedDatasetId: newDataset.id,
      datasetQueries: {
        [newDataset.id]: newDataset.queryDraft || newDataset.query || '',
        ...this.state.datasetQueries,
      },
    };
    this.setState(stateUpdate);
  };

  renderCollapseButton = () => {
    const { toggleEditorVisibility, isOpen } = this.props;

    return (
      <IconButton
        name={isOpen ? 'chevron-down' : 'chevron-up'}
        onClick={() => toggleEditorVisibility()}
        tooltipProps={{ text: isOpen ? 'Hide Dataset Panel' : 'Show Dataset Panel' }}
      />
    );
  };

  renderExpandButton = () => {
    const { classes, datasetEditorMode, toggleIsExpanded } = this.props;

    return (
      <IconButton
        className={cx(sharedStyles.actionBtnClass, classes.compactButton)}
        name="expand"
        onClick={toggleIsExpanded}
        tooltipProps={{
          text: datasetEditorMode === DATASET_EDITOR_MODE.EXPANDED ? 'Inline' : 'Full screen',
        }}
      />
    );
  };

  renderEditorBody = () => {
    return (
      <div className={sprinkles({ height: 'fill', flexItems: 'alignCenter' })}>
        {this.showDatasetsAsList() && this.renderDatasetList()}
        {this.renderDatasetEditor()}
      </div>
    );
  };

  renderSelectDatasetTopBar = () => {
    const { classes, datasets, isOpen } = this.props;
    const { selectedDatasetId } = this.state;
    const dropdownInputs = map(datasets, (dataset) => ({
      name: getDatasetName(dataset),
      id: dataset.id,
    }));

    return (
      <div
        className={sprinkles({
          backgroundColor: 'white',
          flexItems: 'alignCenter',
          justifyContent: 'space-between',
          borderBottom: 1,
          borderColor: 'gray7',
          paddingX: 'sp1.5',
        })}
        style={{ height: MENU_HEIGHT }}>
        <div className={classes.datasetDropdown}>
          <DropdownSelect
            fillWidth
            minimal
            disabled={!isOpen}
            noSelectionText="Select a dataset"
            onChange={(selectedInput) => {
              const newDataset = find(datasets, (dataset) => dataset.id === selectedInput.id);
              newDataset && this.switchSelectedDataset(newDataset);
              trackEvent(EVENTS.SELECTED_DATASET, {
                dataset_id: newDataset?.id,
                dataset_name: newDataset,
              });
            }}
            options={dropdownInputs}
            selectedItem={dropdownInputs.find(
              (dropdownInput) => dropdownInput.id === selectedDatasetId,
            )}
          />
        </div>
        <div className={sprinkles({ display: 'flex' })}>
          <IconButton
            className={sharedStyles.actionBtnClass}
            disabled={!isOpen}
            name="plus"
            onClick={() => {
              this.setState({ createDatasetModalOpen: true });
            }}
          />
          {this.renderCollapseButton()}
        </div>
      </div>
    );
  };

  renderDatasetList = () => {
    const { classes, isOpen } = this.props;
    return (
      <div
        className={sprinkles({
          height: 'fill',
          borderRight: 1,
          borderColor: 'gray7',
          backgroundColor: 'white',
        })}
        style={{ width: DATASET_LIST_WIDTH }}>
        {this.renderDatasetListHeader()}
        {isOpen ? (
          <Menu className={classes.datasetListContainer}>
            {this.getSortedDatasets().map((dataset) => this.renderDatasetItem(dataset))}
          </Menu>
        ) : null}
      </div>
    );
  };

  renderDatasetListHeader = () => {
    const { isOpen } = this.props;
    return (
      <div
        className={sprinkles({
          fontWeight: 700,
          flexItems: 'alignCenter',
          justifyContent: 'space-between',
          padding: 'sp1.5',
          borderBottom: 1,
          borderColor: 'gray7',
        })}
        style={{ height: MENU_HEIGHT }}>
        <div>Datasets</div>
        <div>
          <IconButton
            disabled={!isOpen}
            name="plus"
            onClick={() => this.setState({ createDatasetModalOpen: true })}
          />
        </div>
      </div>
    );
  };

  renderDatasetItem = (dataset: Dataset) => {
    const { selectedDatasetId, actionLoadingDatasetId, datasetQueries, datasetEditorView } =
      this.state;
    const { selectedDataPanelSourceDatasetId, datasets, showUnfilteredDatasetFlag } = this.props;
    const datasetName = getDatasetName(dataset);
    const query = datasetQueries[dataset.id];

    const noCustomerFilter = showUnfilteredDatasetFlag
      ? !isQueryDependentOnVariable(CUSTOMER_ID_VARIABLES_SET, dataset)
      : false;

    return (
      <DatasetMenuItem
        active={selectedDatasetId === dataset.id}
        draft={query != null && query !== datasets[dataset.id].query}
        error={dataset._error != null}
        key={`dataset-navbar-item-${dataset.id}`}
        loading={actionLoadingDatasetId === dataset.id}
        name={datasetName}
        new={dataset._is_new}
        noCustomerFilter={noCustomerFilter}
        onClick={() => {
          this.switchSelectedDataset(dataset);
          if (datasetEditorView === DATASET_VIEWS.FORMATTING.id) {
            this.getFormattingPreview(dataset.id);
          }
          trackEvent(EVENTS.SELECTED_DATASET, {
            dataset_id: dataset.id,
            dataset_name: datasetName,
          });
        }}
        onDeleteClicked={() => this.setState({ deleteDatasetId: dataset.id })}
        onEditClicked={() => this.setState({ editDatasetId: dataset.id })}
        used={dataset.id === selectedDataPanelSourceDatasetId}
      />
    );
  };

  renderCreateDatasetModal = () => {
    const { dashboardId, parentSchemas } = this.props;

    return (
      <DatasetModal
        buttonTitle="Create"
        defaultParentSchema={parentSchemas.length === 1 ? parentSchemas[0] : undefined}
        errorState={this.errorWithDatasetNameEntry}
        modalOpen={this.state.createDatasetModalOpen}
        onClose={() => this.setState({ createDatasetModalOpen: false })}
        onSubmit={(name, parent_schema_id) => {
          this.props.createDashboardDatasetV2({
            name,
            dashId: dashboardId,
            parentSchemaId: parent_schema_id,
          });
          trackEvent(EVENTS.CREATED_NEW_DATASET, {});
        }}
        parentSchemas={parentSchemas}
        title="Create Dataset Option"
      />
    );
  };

  errorWithDatasetNameEntry = (newVal?: string) => {
    const { datasets } = this.props;
    const { editDatasetId } = this.state;
    const currentDatasetNames = Object.values(datasets).map((dataset) =>
      getDatasetName(dataset, false),
    );

    const editingDatasetName = editDatasetId
      ? getDatasetName(datasets[editDatasetId], false)
      : undefined;

    if (newVal && currentDatasetNames.includes(newVal) && editingDatasetName !== newVal) {
      return {
        isErrorState: true,
        errorMsg:
          'There is already a dataset with this name. Please choose another name for the dataset.',
      };
    }
    return { isErrorState: false };
  };

  renderEditDatasetModal = () => {
    const { editDatasetName, parentSchemas, datasets } = this.props;
    const { editDatasetId } = this.state;

    const editDataset = editDatasetId ? datasets[editDatasetId] : undefined;
    if (!editDataset) return;

    const defaultParentSchema = parentSchemas?.find(
      (schema) => schema.id === editDataset.parent_schema_id,
    );

    return (
      <DatasetModal
        buttonTitle="Save"
        datasetName={getDatasetName(editDataset)}
        defaultParentSchema={defaultParentSchema}
        errorState={this.errorWithDatasetNameEntry}
        modalOpen={true}
        onClose={() => this.setState({ editDatasetId: null })}
        onSubmit={(name: string, parent_schema_id: number) => {
          this.setState({ actionLoadingDatasetId: editDataset.id });

          editDatasetName({
            datasetId: editDataset.id,
            name,
          });

          this.setState({ actionLoadingDatasetId: undefined });

          trackEvent(EVENTS.EDITED_DATASET_NAME, {
            dataset_id: editDataset.id,
            dataset_name: name,
            parentSchemaId: parent_schema_id,
          });
        }}
        parentSchemas={parentSchemas}
        title="Edit Dataset"
      />
    );
  };

  renderDeleteDatasetModal = () => {
    const { deleteDataset, datasets, dataPanels, dashboardElements } = this.props;
    const { deleteDatasetId } = this.state;

    const datasetToDelete = deleteDatasetId ? datasets[deleteDatasetId] : undefined;

    if (!datasetToDelete) return;

    const { dataPanelsInUse, elementsInUse } = isDatasetInUse(
      datasetToDelete.id,
      dashboardElements,
      dataPanels,
    );

    if (dataPanelsInUse.length > 0 || elementsInUse.length > 0) {
      return this.renderDeleteDatasetInUse(dataPanelsInUse, elementsInUse);
    }

    const onDeleteDataset = () => {
      this.setState({ actionLoadingDatasetId: datasetToDelete.id });

      deleteDataset({ datasetId: datasetToDelete.id });

      this.setState({ actionLoadingDatasetId: undefined, deleteDatasetId: null });

      const newSelectedDataset = this.getSortedDatasets()[0];
      if (newSelectedDataset) this.switchSelectedDataset(newSelectedDataset);

      trackEvent(EVENTS.DELETED_DATASET, { dataset_id: datasetToDelete.id });
    };

    return (
      <AlertModal
        isOpen
        actionButtonProps={{
          text: `Delete ${getDatasetName(datasetToDelete)}`,
          onClick: onDeleteDataset,
        }}
        onClose={() => this.setState({ deleteDatasetId: null })}
        title="Are you sure you want to delete this dataset?"
      />
    );
  };

  renderDeleteDatasetInUse = (dataPanelsInUse: string[], elementsInUse: string[]) => {
    const { classes } = this.props;

    const onClose = () => this.setState({ deleteDatasetId: null });
    return (
      <Modal
        isOpen
        onClose={onClose}
        portalContainerId={APP_PORTAL_ID}
        secondaryButtonProps={{ text: 'Cancel', onClick: onClose }}
        size="small"
        title="This dataset cannot currently be deleted">
        <div className={sprinkles({ paddingX: 'sp3' })}>
          <div>
            This dataset is in use and cannot be deleted until the following elements are deleted.
          </div>
          {dataPanelsInUse.length > 0 ? (
            <>
              <div className={classes.warningHeader}>
                <Icon className={sharedStyles.warningIconClass} name="circle-exclamation" />
                <div>
                  The following <b>charts</b> are using this dataset:
                </div>
              </div>
              <ul>
                {dataPanelsInUse.map((dataPanelName) => (
                  <li key={`data-panel-delete-list-${dataPanelName}`}>{dataPanelName}</li>
                ))}
              </ul>
            </>
          ) : null}
          {elementsInUse.length > 0 ? (
            <>
              <div className={classes.warningHeader}>
                <Icon className={sharedStyles.warningIconClass} name="circle-exclamation" />
                <div>
                  The following <b>dashboard elements</b> are using this dataset:
                </div>
              </div>
              <ul>
                {elementsInUse.map((elementName) => (
                  <li key={`elem-delete-list-${elementName}`}>{elementName}</li>
                ))}
              </ul>
            </>
          ) : null}
        </div>
      </Modal>
    );
  };

  renderSchemaViewer = () => {
    const { schemaTablesMap, datasets, saveDraftDatasetQuery, classes } = this.props;
    const { selectedDatasetId, datasetEditorView, datasetQueries } = this.state;

    if (datasetEditorView !== DATASET_VIEWS.QUERY.id || !selectedDatasetId) return;
    const datasetQuery = this.currentQuery();
    const dataset = datasets[selectedDatasetId];

    const onRevertDraft = () => {
      if (!dataset) return;
      saveDraftDatasetQuery({ queryDraft: undefined, dataset_id: dataset.id });
      this.setState({
        datasetQueries: { ...datasetQueries, [selectedDatasetId]: dataset.query || '' },
      });
    };

    return (
      <SchemaViewer
        className={classes.schemaViewerActionItemsRoot}
        datasetId={selectedDatasetId}
        onFormat={this.formatSqlQuery}
        onPreview={!datasetQuery || datasetQuery.length === 0 ? undefined : this.getTablePreview}
        onRevertDraft={dataset.queryDraft !== undefined ? onRevertDraft : undefined}
        onSave={!datasetQuery || datasetQuery.length === 0 ? undefined : this.saveDashboardDataset}
        onSelectSchema={(schema) =>
          this.props.updateDashboardDatasetSchema({
            datasetId: selectedDatasetId,
            newParentSchemaId: schema.id,
          })
        }
        saveText={datasetQuery === dataset?.query ? 'Run' : 'Save & Run'}
        schemaTablesMap={schemaTablesMap}
        selectedDatasetSchemaId={this.getSelectedDataset()?.parent_schema_id}
      />
    );
  };

  renderDatasetEditor = () => {
    const { classes, pageWidth } = this.props;
    const { selectedDatasetId } = this.state;
    const dataset = this.getSelectedDataset();

    if (!selectedDatasetId || !dataset) {
      return;
    }

    return (
      <div
        className={
          this.showDatasetsAsList() ? classes.editor : sprinkles({ parentContainer: 'fill' })
        }>
        {pageWidth &&
          datasetEditorShowsDatasetsAsDropdown(pageWidth) &&
          this.renderSelectDatasetTopBar()}
        {this.renderDatasetEditorMenu()}
        <div className={classes.editorContainer}>
          <div className={sprinkles({ parentContainer: 'fill' })}>
            {this.renderDatasetEditorBody()}
          </div>
        </div>
      </div>
    );
  };

  renderDatasetEditorMenu = () => {
    const { classes, datasets } = this.props;
    const { selectedDatasetId } = this.state;

    if (!selectedDatasetId || !datasets[selectedDatasetId]) {
      return;
    }

    return (
      <div className={classes.editorTopBar}>
        {this.renderToggleEditorViewActions()}
        <div className={sprinkles({ flexItems: 'alignCenter' })}>
          {this.showDatasetsAsList() ? (
            <>
              {this.renderExpandButton()} {this.renderCollapseButton()}
            </>
          ) : null}
        </div>
      </div>
    );
  };

  getUnderlyingData = (dataset: Dataset, datasetQuery: string) => {
    const { dashboardVars, selectedUserGroupId, dashboardId } = this.props;

    const postData = {
      dataset_id: dataset.id,
      query: datasetQuery,
      parent_schema_id: dataset.parent_schema_id,
      variables: { ...dashboardVars },
      customer_id: selectedUserGroupId,
      resource_id: dashboardId,
      timezone: DateTime.local().zoneName,
    };

    this.fetchDatasetWrapper(postData, postData);

    trackEvent(EVENTS.RAN_CODE, {
      dataset_id: dataset.id,
      dataset_query: datasetQuery,
    });
  };

  getTablePreview = () => {
    const datasetQuery = this.currentQuery();
    const dataset = this.getSelectedDataset();

    if (!dataset || !datasetQuery) return;

    this.getUnderlyingData(dataset, datasetQuery);
    this.openTab(DATASET_VIEWS.PREVIEW.id);
  };

  getFormattingPreview = (datasetId: string) => {
    const dataset = this.props.datasets[datasetId];
    // Need || dataset.query bc when opening different dataset, the datasetQueries does not have the initial query loaded yet
    const datasetQuery = this.state.datasetQueries[datasetId] || dataset.query;

    if (!dataset || !datasetQuery || dataset._error || dataset._rows) return;

    this.getUnderlyingData(dataset, datasetQuery);
  };

  saveDashboardDataset = () => {
    const {
      saveDatasetQuery,
      completeOnboardingSubStep,
      dashboardVars,
      selectedUserGroupId,
      onboardingSteps,
      dashboardId,
    } = this.props;

    const datasetQuery = this.currentQuery();
    const dataset = this.getSelectedDataset();
    if (!dataset || !datasetQuery) return;

    const postData = {
      query: datasetQuery,
      dataset_id: dataset.id,
      parent_schema_id: dataset.parent_schema_id,
      customer_id: selectedUserGroupId,
      variables: { ...dashboardVars },
      resource_id: dashboardId,
      timezone: DateTime.local().zoneName,
    };

    this.openTab(DATASET_VIEWS.PREVIEW.id);

    this.fetchDatasetWrapper(postData, postData, (data) => {
      saveDatasetQuery({ ...postData, schema: data.dataset_preview.schema });

      if (
        shouldCompleteOnboardingStep(
          onboardingSteps || {},
          STEPS.CREATE_DASHBOARD,
          SUB_STEPS.CREATE_DATASET,
        )
      ) {
        completeOnboardingSubStep({
          postData: { sub_step_name: SUB_STEPS.CREATE_DATASET },
        });
      }
      showDuplicateColumnNameToast(data);
    });

    trackEvent(EVENTS.SAVED_QUERY, {
      dataset_id: dataset.id,
      dataset_query: datasetQuery,
    });
  };

  getSelectedDataset = () => {
    const { datasets } = this.props;
    const { selectedDatasetId } = this.state;

    if (!selectedDatasetId || !datasets[selectedDatasetId]) {
      return;
    }
    return datasets[selectedDatasetId];
  };

  formatSqlQuery = () => {
    const { datasetQueries, selectedDatasetId } = this.state;
    const datasetQuery = this.currentQuery();
    if (!selectedDatasetId || !datasetQuery) return;

    this.setState({
      datasetQueries: {
        ...datasetQueries,
        [selectedDatasetId]: sqlFormatter.format(datasetQuery || '', {
          indent: '    ',
        }),
      },
    });
  };

  openTab = (tabId: string) => {
    this.setState({ datasetEditorView: tabId });
  };

  renderToggleEditorViewActions = () => {
    const { classes, isOpen } = this.props;
    const { datasetEditorView } = this.state;
    if (!isOpen) return <div></div>;

    return (
      <NavTabs
        className={classes.editorDatasetViewTabs}
        onTabSelect={(tabId) => {
          this.openTab(tabId);
          if (tabId === DATASET_VIEWS.FORMATTING.id && this.state.selectedDatasetId) {
            this.getFormattingPreview(this.state.selectedDatasetId);
          }
        }}
        selectedTabId={datasetEditorView}
        tabClassName={sprinkles({ paddingY: 'sp0', paddingX: 'sp1.5' })}
        tabs={[
          DATASET_VIEWS.QUERY,
          DATASET_VIEWS.PREVIEW,
          DATASET_VIEWS.FORMATTING,
          DATASET_VIEWS.DEBUGGER,
        ]}
      />
    );
  };

  renderDatasetEditorBody = () => {
    const { datasetEditorView } = this.state;

    switch (datasetEditorView) {
      case DATASET_VIEWS.QUERY.id:
        return this.renderDatasetQueryEditor();
      case DATASET_VIEWS.PREVIEW.id:
        return this.renderDatasetPreview();
      case DATASET_VIEWS.FORMATTING.id:
        return this.renderDatasetFormattingBody();
      case DATASET_VIEWS.DEBUGGER.id:
        return this.renderGeneratedQueryBody();
    }
  };

  renderDatasetQueryEditor = () => {
    const { schemaTablesMap, datasets, saveDraftDatasetQuery } = this.props;
    const dataset = this.getSelectedDataset();
    const { datasetQueries, selectedDatasetId } = this.state;
    const datasetQuery = this.currentQuery();
    const tableDatasets = selectedDatasetId
      ? Object.values(schemaTablesMap[datasets[selectedDatasetId].parent_schema_id])
      : [];
    const columnNames: string[] = [];
    const tableNames: string[] = [];
    tableDatasets.forEach((table) => {
      tableNames.push(table.table_name);
      if (table.schema) {
        table.schema.forEach((column) => {
          columnNames.push(table.table_name + '.' + column.name);
        });
      }
    });

    return (
      <div className={sprinkles({ display: 'flex', parentContainer: 'fill' })}>
        <div className={sharedStyles.formattedDataTableClass}>
          <SQLEditor
            columnNames={uniq(columnNames)}
            onChange={(newQuery) => {
              if (!selectedDatasetId) return;
              this.setState({
                datasetQueries: {
                  ...datasetQueries,
                  [selectedDatasetId]: newQuery,
                },
              });
            }}
            onChangeDraft={(newQuery) => {
              if (dataset) {
                saveDraftDatasetQuery({
                  queryDraft: newQuery === dataset?.query ? undefined : newQuery,
                  dataset_id: dataset.id,
                });
              }
            }}
            query={datasetQuery || ''}
            tableNames={tableNames}
          />
        </div>
        {this.renderSchemaViewer()}
      </div>
    );
  };

  renderQueryRedirectText = (text: string) => {
    return (
      <span
        className={sprinkles({ color: 'active', cursor: 'pointer' })}
        onClick={() => this.openTab(DATASET_VIEWS.QUERY.id)}>
        {text}
      </span>
    );
  };

  renderDatasetFormattingBody = () => {
    const { datasetEditorMode, toggleIsExpanded } = this.props;
    const dataset = this.getSelectedDataset();
    if (!dataset) return;

    const drilldownColumnConfigsValues = Object.values(dataset.drilldownColumnConfigs || []);

    const isLoading = drilldownColumnConfigsValues.length === 0;
    const numIncludedDrilldownColumnConfigs = drilldownColumnConfigsValues.reduce(
      (acc, config) => acc + (config.isVisible ? 1 : 0),
      0,
    );
    const noIncludedColumns = numIncludedDrilldownColumnConfigs === 0 && !isLoading;
    const isExpanded = datasetEditorMode === DATASET_EDITOR_MODE.EXPANDED;

    if (dataset._error || !dataset.schema?.length) {
      return (
        <div className={sprinkles({ display: 'flex', parentContainer: 'fill' })}>
          <div className={sharedStyles.formattedDataTableClass}>
            {dataset._error ? (
              this.renderDatasetTableError(dataset)
            ) : (
              <DatasetEditorNonIdealState
                action={this.renderQueryRedirectText('Write a query')}
                description="Formatting tools will appear after your SQL query is saved."
                icon={<Icon name="rectangle-terminal" size="lg" />}
                title="No data to format"
              />
            )}
          </div>
          {!isExpanded ? (
            <DrilldownDatasetColumnsFormatConfig
              dataset={dataset}
              datasetEditorMode={datasetEditorMode}
              toggleIsExpanded={toggleIsExpanded}
            />
          ) : null}
        </div>
      );
    }

    return (
      <div className={sprinkles({ display: 'flex', parentContainer: 'fill' })}>
        {datasetEditorMode === DATASET_EDITOR_MODE.EXPANDED ? (
          <DrilldownDatasetColumns dataset={dataset} />
        ) : null}
        <div className={sharedStyles.formattedDataTableClass}>
          {noIncludedColumns ? this.renderNoVisibleColumns() : this.renderDatasetPreview()}
        </div>
        <DrilldownDatasetColumnsFormatConfig
          dataset={dataset}
          datasetEditorMode={datasetEditorMode}
          toggleIsExpanded={toggleIsExpanded}
        />
      </div>
    );
  };

  renderDatasetPreview = () => {
    const { dashboardId, dashboardVars, datasets } = this.props;
    const { datasetEditorView, datasetQueries, selectedDatasetId } = this.state;

    const enableFormat = datasetEditorView === DATASET_VIEWS.FORMATTING.id;

    return (
      <DatasetPreview
        dashboardId={dashboardId}
        dashboardVars={dashboardVars}
        datasetQueries={datasetQueries}
        datasets={datasets}
        enableFormat={enableFormat}
        fetchDataset={this.fetchDatasetWrapper}
        getTablePreview={this.getTablePreview}
        openTab={this.openTab}
        selectedDatasetId={selectedDatasetId}
      />
    );
  };

  renderDatasetTableError = (dataset: Dataset) => {
    return (
      <DatasetEditorNonIdealState
        action={this.renderQueryRedirectText('Revise your query')}
        description={dataset._error}
        icon={
          <Icon className={sprinkles({ color: 'error' })} name="circle-exclamation" size="lg" />
        }
        title="There was a problem with your query"
      />
    );
  };

  renderNoVisibleColumns = () => {
    const { datasetEditorMode } = this.props;
    return (
      <DatasetEditorNonIdealState
        description={
          datasetEditorMode === DATASET_EDITOR_MODE.EXPANDED
            ? 'Select some columns to get started.'
            : 'Open expanded view to add columns.'
        }
        title="No visible columns"
      />
    );
  };

  renderGeneratedQueryBody = () => {
    const { datasets, classes } = this.props;
    const { selectedDatasetId } = this.state;

    if (!selectedDatasetId || !datasets[selectedDatasetId]) {
      return <Spinner fillContainer />;
    }

    const selectedDataset = datasets[selectedDatasetId];

    if (!selectedDataset._query_information) {
      return (
        <DatasetEditorNonIdealState
          action={this.renderQueryRedirectText('Write a query')}
          description="This panel will show the query constructed and ran based on variables in the dataset SQL"
          icon={<Icon name="rectangle-terminal" size="lg" />}
          title="No query to debug"
        />
      );
    }

    const formattedQuery = sqlFormatter.format(selectedDataset._query_information._query || '', {
      indent: '  ',
    });

    return (
      <div className={sprinkles({ height: 'fill', overflow: 'auto' })}>
        <InfoCard
          className={classes.queryDebuggerInfo}
          text="This is the query that Explo generated and ran. All available variables have been inserted into the query."
        />
        <QueryRuntime queryInformation={selectedDataset._query_information} />
        <div className={classes.queryDebugger}>
          <SyntaxHighlighter language="sql">{formattedQuery}</SyntaxHighlighter>
        </div>
      </div>
    );
  };

  getSortedDatasets = () => {
    return sortBy(Object.values(this.props.datasets), (dataset) => getDatasetName(dataset));
  };

  showDatasetsAsList = () => {
    const { pageWidth } = this.props;

    return pageWidth && datasetEditorShowsDatasetsAsList(pageWidth);
  };

  fetchDatasetWrapper = (
    dataPostData?: FetchEditorDatasetPreviewBody,
    rowCountPostData?: FetchEditorDatasetPreviewBody,
    onSuccess?: (data: FetchDashboardDatasetPreviewData) => void,
  ) => {
    const { fetchEditorDatasetPreview, fetchEditorDatasetRowCount, shouldUseJobQueue } = this.props;

    if (!shouldUseJobQueue) {
      if (dataPostData)
        fetchEditorDatasetPreview({ postData: dataPostData }, (data) => onSuccess?.(data));
      if (rowCountPostData) fetchEditorDatasetRowCount({ postData: rowCountPostData });
    } else {
      const requests: JobDefinition[] = [];

      if (dataPostData)
        requests.push({
          job_type: ACTION.FETCH_EDITOR_DATASET_PREVIEW,
          job_args: dataPostData,
          onSuccess,
        });
      if (rowCountPostData)
        requests.push({
          job_type: ACTION.FETCH_EDITOR_DATASET_ROW_COUNT,
          job_args: rowCountPostData,
          onSuccess,
        } as JobDefinition);

      this.bulkEnqueueJobs(requests);
    }
  };

  bulkEnqueueJobs = (jobs: JobDefinition[] | undefined) => {
    const { bulkEnqueueJobs } = this.props;

    if (jobs === undefined || jobs.length === 0) return;

    const jobMap = Object.assign({}, ...jobs.map((job) => ({ [uuidv4()]: job })));

    bulkEnqueueJobs?.(
      {
        jobs: jobMap,
      },
      (jobs) => {
        this.setState((currentState) => {
          return {
            awaitedJobs: produce(currentState.awaitedJobs, (draft) => ({
              ...draft,
              ...jobs,
            })),
          };
        });
      },
    );
  };
}

const mapStateToProps = (state: ReduxState) => ({
  onboardingSteps: state.onboarding.onboardingSteps,
  shouldUseJobQueue: !!state.currentUser.team?.feature_flags.use_job_queue,
  showUnfilteredDatasetFlag: !!state.currentUser.team?.feature_flags.show_unfiltered_dataset_flag,
});

const mapDispatchToProps = {
  createDashboardDatasetV2,
  editDatasetName,
  deleteDataset,
  fetchEditorDatasetPreview,
  fetchEditorDatasetRowCount,
  saveDatasetQuery,
  saveDraftDatasetQuery,
  completeOnboardingSubStep,
  bulkEnqueueJobs,
  setSelectedDrilldownColumn,
  updateDashboardDatasetSchema,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DashboardDatasetEditor)),
);
