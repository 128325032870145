import { Component, ContextType } from 'react';
import cx from 'classnames';
import { Spinner, sprinkles } from 'components/ds';
import produce from 'immer';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { cloneDeep, isEmpty, isEqual, omit, uniqueId } from 'utils/standard';
import { Icon, Intent, NonIdealState } from '@blueprintjs/core';

import DataTable from 'pages/dashboardPage/DashboardDatasetView/DataTable';
import ChoroplethMap from 'pages/dashboardPage/choroplethMap';
import { ChartHeader } from 'pages/dashboardPage/DashboardDatasetView/Header/ChartHeader';
import { HeatMap } from 'pages/dashboardPage/charts/heatMap';
import FunnelChartV2 from 'pages/dashboardPage/charts/funnelChart';
import { BarChart } from 'pages/dashboardPage/charts/barChart';
import { PieChart } from 'pages/dashboardPage/charts/pieChart';
import { LineChart } from 'pages/dashboardPage/charts/lineChart';
import { TrendTable } from 'pages/dashboardPage/charts/TrendTable';
import { SingleNumberChart } from 'pages/dashboardPage/charts/singleNumberChart';
import { BarFunnel } from 'pages/dashboardPage/charts/BarFunnel';
import BoxPlotV2 from 'pages/dashboardPage/charts/boxPlot';
import { NumberTrend } from 'pages/dashboardPage/charts/numberTrend';
import ScatterPlot from 'pages/dashboardPage/charts/ScatterPlot';
import SpiderChart from 'pages/dashboardPage/charts/SpiderChart';
import { ArchitectCustomerDashboardEditor } from 'pages/architectCustomerDashboardPage/architectCustomerDashboardEditor';
import DashboardCollapsibleList from './CollapsibleList/DashboardCollapsibleList';
import ReportBuilder from './ReportBuilder';
import DataTableHeader from './Header/DataTableHeader';
import PivotTable from './PivotTable/PivotTable';
import { Props as ColorCategoryProps } from './Header/ColorCategoryDropdown';
import NeedsConfigurationPanel from 'pages/dashboardPage/DashboardDatasetView/needsConfigurationPanel';
import Button from 'shared/Button';
import { DemoWatermark } from 'components/DemoWatermark';

import { isDataPanelConfigReady, replaceTemplatesWithValues } from 'utils/dataPanelConfigUtils';
import { resolveTooltipVariables } from 'utils/variableUtils';
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { Dataset } from 'actions/datasetActions';
import { embedUpdateDPTDataTableColumnWidths } from 'actions/shareActions';
import { ExportProps } from 'components/ChartMenu';
import { COLOR_CATEGORY_FILTER_SUFFIX } from 'constants/dashboardConstants';
import { OP_TYPE_TO_BP3_ICON, VIZ_OP_WITH_CSV_DOWNLOAD } from 'constants/dataConstants';
import {
  FilterOperationInstructions,
  NO_HEADER_OPERATION_TYPES,
  OPERATION_TYPES,
  REGION_TYPES,
  REPORTED_ANALYTIC_ACTION_TYPES,
  UserTransformedSchema,
  VISUALIZE_TABLE_OPERATIONS,
} from 'constants/types';
import { GLOBAL_STYLE_CLASSNAMES, GlobalStylesContext } from 'globalStyles';
import { SpreadsheetType, setChartMenu } from 'reducers/dashboardLayoutReducer';
import { JoinedStates } from 'reducers/rootReducer';
import { isSuccess } from 'remotedata';
import {
  DashboardElement,
  DashboardVariable,
  DashboardVariableMap,
  OpenDrilldownModalType,
  PAGE_TYPE,
  VIEW_MODE,
} from 'types/dashboardTypes';
import { AdHocOperationInstructions } from 'types/dataPanelTemplate';
import { DatasetSchema } from 'types/datasets';
import { DataPanel, ResourceDataset } from 'types/exploResource';
import { AnalyticsEventTracker } from 'utils/analyticsUtils';
import {
  canUseCanvasDataset,
  canvasDataPanelColumnsError,
  missingRequiredFilter,
} from 'utils/canvasConfigUtils';
import { isChartUsingMultipleColorCategories } from 'utils/colorColUtils';
import { getChangedSchema, getUserTransformedSchema } from 'utils/dashboardUtils';
import {
  getSortedDrilldownColumns,
  getSortedUserTransformedDrilldownColumns,
} from 'utils/drilldownDatasetUtils';
import {
  getDataPanelDatasetId,
  isDataPanelTemplate,
  isExploreDataset,
} from 'utils/exploResourceUtils';
import { sortSchemaByOrderedColumnNames } from 'utils/general';
import { getInformationTooltipInfo } from 'utils/graphUtils';
import { getDatasetName } from 'utils/naming';
import {
  ACCESS_GROUP_NO_DEFAULT_DATASOURCES_WARNING_WITH_LINK,
  IS_ACCESS_GROUP_ERROR,
  parseErrorMessage,
} from 'utils/queryUtils';
import { CAN_USE_MULTI_Y_AXIS_OPS } from '../charts/utils/multiYAxisUtils';

export const HEADER_HEIGHT = 63;

const chartContainerClass = sprinkles({
  parentContainer: 'fill',
  overflow: 'hidden',
  flexItems: 'column',
  flex: 1,
  position: 'relative',
});

const styles = (theme: Theme) =>
  createStyles({
    kpiContainer: {
      padding: `4px !important`,
    },
    noDataContainer: {
      height: `calc(100% - ${HEADER_HEIGHT}px - 20px)`, //20px to account for header padding
      padding: theme.spacing(2),
    },
    emptyIcon: {
      display: 'block',
      height: 30,
    },
    noDataText: {
      fontSize: 18,
    },
    errorContainer: {
      height: `calc(100% - ${HEADER_HEIGHT}px)`,
      padding: theme.spacing(2),
    },
    errorContainerNoHeader: {
      height: `100%`,
      padding: theme.spacing(2),
    },
    errorCallout: {
      height: '100%',
      overflow: 'auto',

      '& .bp3-non-ideal-state-visual': {
        height: 50,
        display: 'flex',
        alignItems: 'center',
      },
    },
    noDataCallout: ({ dataPanel }: PassedProps) => ({
      height: '100%',
      overflow: 'auto',

      '& .bp3-non-ideal-state-visual': {
        height: 50,
      },

      '& .bp3-heading': {
        fontSize: dataPanel.visualize_op.generalFormatOptions?.noDataState?.noDataFontSize || 20,
        fontWeight: 'unset',
      },
    }),
    kpiLinkATag: {
      '&:hover': {
        textDecoration: 'none',
      },
    },
  });

export type PassedProps = {
  analyticsEventTracker?: AnalyticsEventTracker;
  canDownloadDataPanel: boolean;
  dataPanel: DataPanel;
  displayDemoWatermark?: boolean;
  editableDashboard?: boolean;
  elementEndsOnRightSide?: boolean;
  isArchitectCustomerDashboard?: boolean;
  isInContainer?: boolean;
  isSelected?: boolean;
  isUpdatingPosition?: boolean;
  isViewOnly: boolean;
  loading: boolean;
  pageType: PAGE_TYPE;
  openDrilldownModal?: OpenDrilldownModalType;
  isDrilldownModal?: boolean;
  variables: DashboardVariableMap;
  defaultUserTransformedSchema?: UserTransformedSchema;
  datasets: Record<string, ResourceDataset>;
  dashboardElements?: DashboardElement[];
  shouldUseJobQueue?: boolean;
  supportEmail?: string;
  ignoreRequiredFilters?: boolean;
  viewMode?: VIEW_MODE;
  drilldownDatasetName?: string;

  onCloseDrilldownModal?: () => void;
  fetchDataPanelData?: (dataPanel: DataPanel) => void;
  onAdHocOperationInstructionsUpdated: (
    dptId: string,
    adHocInstructions: AdHocOperationInstructions,
    skipRowCount?: boolean,
  ) => void;
  onDownloadPanelSpreadsheet: (
    fileFormat: SpreadsheetType,
    email: string | undefined,
    userTransformedSchema?: UserTransformedSchema,
  ) => void;
  onDownloadPanelPdf: (
    adHocOperationInstructions: AdHocOperationInstructions,
    email: string | undefined,
    userTransformedSchema?: UserTransformedSchema,
    reportName?: string,
  ) => void;
  setVariable?: (value: DashboardVariable, name?: string) => void;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  PassedProps &
  WithStyles<typeof styles>;

/**
 * TODO: We should create a DataTable component that is entirely self contained (its own loading and error logic)
 * to maintain this state
 */
type State = {
  userTransformedSchema: UserTransformedSchema;
  endUserTransformedSchema?: UserTransformedSchema;
};

/**
 * TODO: We should investigate bringing loading state logic out from individual charts so that changes to this logic
 * don't need to be copied to every single chart.
 */
class DashboardDatasetView extends Component<Props, State> {
  static contextType = GlobalStylesContext;
  context!: ContextType<typeof GlobalStylesContext>;

  constructor(props: Props) {
    super(props);

    this.state = {
      userTransformedSchema:
        props.defaultUserTransformedSchema ||
        getUserTransformedSchema(
          props.dataPanel._schema ?? [],
          props.dataPanel.visualize_op.instructions.VISUALIZE_TABLE,
          props.datasets[getDataPanelDatasetId(props.dataPanel)],
        ),
      endUserTransformedSchema: undefined,
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { dataPanel, datasets } = this.props;
    const prevVisualizeTableInstructions =
      prevProps.dataPanel.visualize_op.instructions.VISUALIZE_TABLE;

    // When switching from bar to table this is causing an infinte loop.
    // Frankly not even sure if we need this at all but dont want to risk breaking
    // things, so just removing for EUC for now.
    if (!this.state.userTransformedSchema.length && isDataPanelTemplate(dataPanel)) {
      return this.setState({
        userTransformedSchema: getUserTransformedSchema(
          prevProps.dataPanel._schema ?? [],
          prevVisualizeTableInstructions,
        ),
      });
    }

    if (dataPanel.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_TABLE) {
      const visualizeTableInstructions = dataPanel.visualize_op.instructions.VISUALIZE_TABLE;
      const changeSchemaList = visualizeTableInstructions.changeSchemaList;
      const prevChangeSchemaList = prevVisualizeTableInstructions.changeSchemaList;
      const userTransformedSchema = getUserTransformedSchema(
        dataPanel._schema ?? [],
        visualizeTableInstructions,
        datasets[getDataPanelDatasetId(dataPanel)],
      );

      // Update userTransformedSchema if changedSchema or changeSchemaList are updated
      if (this.state.userTransformedSchema.length !== userTransformedSchema.length) {
        return this.setState({ userTransformedSchema });
      }

      // This comparison is more expensive, so it runs after comparing lengths
      const didChangeSchemaListChange =
        changeSchemaList.length !== prevChangeSchemaList.length ||
        !isEqual(changeSchemaList, prevChangeSchemaList);
      if (didChangeSchemaListChange) {
        this.setState({
          // Reset end user settings so that the updates are visible
          endUserTransformedSchema: undefined,
          userTransformedSchema,
        });
      }
    }
  }

  render() {
    const { classes, dataPanel, isDrilldownModal, isInContainer } = this.props;

    const operationType = dataPanel.visualize_op.operation_type;

    const shouldRenderBorder =
      !isDrilldownModal &&
      (!isInContainer ||
        operationType === OPERATION_TYPES.VISUALIZE_TABLE ||
        operationType === OPERATION_TYPES.VISUALIZE_PIVOT_TABLE);

    return (
      <div
        className={cx(
          sprinkles({
            position: 'relative',
            height: 'fill',
            overflow: 'hidden',
            flexItems: 'column',
          }),
          GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.default.borderRadius,
          {
            [GLOBAL_STYLE_CLASSNAMES.container.fill.backgroundColor]:
              !VISUALIZE_TABLE_OPERATIONS.includes(operationType),
            [GLOBAL_STYLE_CLASSNAMES.container.padding.default.padding]:
              !VISUALIZE_TABLE_OPERATIONS.includes(operationType) &&
              operationType !== OPERATION_TYPES.VISUALIZE_TREND_TABLE &&
              !isInContainer,
            [GLOBAL_STYLE_CLASSNAMES.container.outline.border]: shouldRenderBorder,
            [GLOBAL_STYLE_CLASSNAMES.container.shadow.dropShadow]: !isInContainer,
            [classes.kpiContainer]: this.isKpiNumberOp(),
          },
        )}>
        {this.renderArchitectCustomerDashboardEditor()}
        {this.renderHeader()}
        {this.renderChartOrTable()}
      </div>
    );
  }

  renderArchitectCustomerDashboardEditor = () => {
    const {
      dataPanel,
      isSelected,
      isArchitectCustomerDashboard,
      fetchDataPanelData,
      isUpdatingPosition,
    } = this.props;
    if (!isSelected || isUpdatingPosition || !isArchitectCustomerDashboard || !fetchDataPanelData)
      return null;

    return (
      <ArchitectCustomerDashboardEditor
        fetchDataPanelData={fetchDataPanelData}
        panelId={dataPanel.id}
      />
    );
  };

  isKpiNumberOp = () => {
    const { operation_type, instructions } = this.props.dataPanel.visualize_op;
    return (
      operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_V2 ||
      (operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2 &&
        instructions.V2_KPI_TREND?.hideTrendLines)
    );
  };

  shouldShowFullChartLoading = () => {
    const { dataPanel, loading, editableDashboard } = this.props;

    // On initial load, we unconditionally show the no display state for a chart
    const showFullChartInitialLoadState = !dataPanel._rows || dataPanel._rows.length === 0;
    // On subsequent reloads (because of config changes), we have more conditional behavior.
    // This is because in non-editable dashboards, we want to show stale data while the new
    // data is being fetch. In editable dashboards, we can't show stale data in the interim
    // because the config changes immediately and so the old data often will not be compatible
    // with the new config
    // NOTE: the !showFullChartInitialLoadState is redundant, but more correctly matches the name
    // of the variable, in case we ever end up breaking behavior out in these two cases
    const showFullChartReloadState = !showFullChartInitialLoadState && loading && editableDashboard;
    return showFullChartInitialLoadState || showFullChartReloadState;
  };

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    // these functions are defined as closures and not bound functions, meaning that they are
    // redefined every render. Thus, we must omit them to prevent unnecessary renders when the
    // underlying data for the chart hasn't changed. Long term, we should make DashboardLayout
    // a functional component to access the benefits of the useCallback() hook
    const relevantDataNext = omit(nextProps, 'onDownloadPanelSpreadsheet', 'analyticsEventTracker');
    const relevantDataThis = omit(
      this.props,
      'onDownloadPanelSpreadsheet',
      'analyticsEventTracker',
    );
    return (
      !isEqual(relevantDataNext, relevantDataThis) ||
      !isEqual(this.state.userTransformedSchema, nextState.userTransformedSchema) ||
      !isEqual(this.state.endUserTransformedSchema, nextState.endUserTransformedSchema)
    );
  }

  renderChartOrTable = () => {
    const {
      analyticsEventTracker,
      loading,
      updateVisualizeOperation,
      isSelected = false,
      isDrilldownModal,
      dataPanel,
      canDownloadDataPanel,
      onDownloadPanelSpreadsheet,
      onDownloadPanelPdf,
      variables,
      isViewOnly,
      setVariable,
      canvasFilters,
      dashboardElements,
      datasets,
      embedUpdateDPTDataTableColumnWidths,
      openDrilldownModal,
      editableDashboard,
      pageType,
      colorCategoryTracker,
      shouldUseJobQueue,
      supportEmail,
      ignoreRequiredFilters,
      setChartMenu,
    } = this.props;

    const { operation_type, instructions, generalFormatOptions } = dataPanel.visualize_op;

    const backgroundColor = this.context.globalStyleConfig.container.fill;

    if (dataPanel._error) return this.renderErrorState();

    const dataset = datasets[getDataPanelDatasetId(dataPanel)];
    if (!dataset || !canUseCanvasDataset(dataset)) {
      return <NeedsConfigurationPanel datasetDoesNotExist />;
    }

    if (!ignoreRequiredFilters) {
      const missingFilterError = missingRequiredFilter(
        dataset,
        dashboardElements || [],
        canvasFilters || {},
        variables,
      );
      if (missingFilterError) {
        return <NeedsConfigurationPanel missingFilterError={missingFilterError} />;
      }
    }

    const canvasColumnError = canvasDataPanelColumnsError(dataPanel, dataset);
    if (canvasColumnError) {
      return <NeedsConfigurationPanel columnErrorText={canvasColumnError} />;
    }

    const isNumberTrendAgg =
      operation_type !== OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2 ||
      !instructions.V2_KPI_TREND?.hideTrendLines;

    if (
      !loading &&
      dataPanel._rows &&
      dataPanel._rows.length === 0 &&
      operation_type !== OPERATION_TYPES.VISUALIZE_PROGRESS_V2 &&
      isNumberTrendAgg
    ) {
      return this.renderNoDataBody();
    }

    const showFullChartLoading = this.shouldShowFullChartLoading();

    const selectedColorColName = variables[
      dataPanel.provided_id + COLOR_CATEGORY_FILTER_SUFFIX
    ] as string;

    const schema = dataPanel._schema ?? [];
    const secondaryData = dataPanel._secondaryData ?? [];

    if (
      operation_type === OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2 ||
      operation_type === OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2 ||
      operation_type === OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2 ||
      operation_type === OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2 ||
      operation_type === OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2 ||
      operation_type === OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2 ||
      operation_type === OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2 ||
      operation_type === OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2
    ) {
      return (
        <div className={chartContainerClass}>
          {this.renderDemoWatermark()}
          <BarChart
            backgroundColor={backgroundColor}
            canUseMultiYAxis={CAN_USE_MULTI_Y_AXIS_OPS.has(operation_type)}
            colorCategoryTracker={colorCategoryTracker}
            dataPanelProvidedId={dataPanel.provided_id}
            dataPanelTemplateId={dataPanel.id}
            datasets={datasets}
            generalOptions={generalFormatOptions}
            globalStyleConfig={this.context.globalStyleConfig}
            grouped={
              operation_type === OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2 ||
              operation_type === OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2
            }
            horizontal={
              operation_type === OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2 ||
              operation_type === OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2 ||
              operation_type === OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2 ||
              operation_type === OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2
            }
            instructions={instructions.V2_TWO_DIMENSION_CHART}
            loading={showFullChartLoading}
            normalize={
              operation_type === OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2 ||
              operation_type === OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2
            }
            openDrilldownModal={openDrilldownModal}
            operationType={operation_type}
            previewData={dataPanel._rows || []}
            schema={schema}
            selectedColorColName={selectedColorColName}
            setChartMenu={setChartMenu}
            setVariable={setVariable}
            variables={variables}
          />
        </div>
      );
    } else if (
      operation_type === OPERATION_TYPES.VISUALIZE_PIE_CHART_V2 ||
      operation_type === OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2
    ) {
      return (
        <div className={chartContainerClass}>
          {this.renderDemoWatermark()}
          <PieChart
            backgroundColor={backgroundColor}
            colorCategoryTracker={colorCategoryTracker}
            dataPanelTemplateId={dataPanel.id}
            donut={operation_type === OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2}
            generalOptions={generalFormatOptions}
            globalStyleConfig={this.context.globalStyleConfig}
            instructions={instructions.V2_TWO_DIMENSION_CHART}
            loading={showFullChartLoading}
            openDrilldownModal={openDrilldownModal}
            previewData={dataPanel._rows || []}
            schema={schema}
            setChartMenu={setChartMenu}
            variables={variables}
          />
        </div>
      );
    } else if (
      operation_type === OPERATION_TYPES.VISUALIZE_LINE_CHART_V2 ||
      operation_type === OPERATION_TYPES.VISUALIZE_AREA_CHART_V2 ||
      operation_type === OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2
    ) {
      return (
        <div className={chartContainerClass}>
          {this.renderDemoWatermark()}
          <LineChart
            area={
              operation_type === OPERATION_TYPES.VISUALIZE_AREA_CHART_V2 ||
              operation_type === OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2
            }
            backgroundColor={backgroundColor}
            canUseMultiYAxis={CAN_USE_MULTI_Y_AXIS_OPS.has(operation_type)}
            colorCategoryTracker={colorCategoryTracker}
            dataPanelTemplateId={dataPanel.id}
            datasets={datasets}
            generalOptions={generalFormatOptions}
            globalStyleConfig={this.context.globalStyleConfig}
            instructions={instructions.V2_TWO_DIMENSION_CHART}
            loading={showFullChartLoading}
            normalize={operation_type === OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2}
            openDrilldownModal={openDrilldownModal}
            previewData={dataPanel._rows || []}
            schema={schema}
            selectedColorColName={selectedColorColName}
            setChartMenu={setChartMenu}
            variables={variables}
          />
        </div>
      );
    } else if (operation_type === OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2) {
      return (
        <div className={chartContainerClass}>
          {this.renderDemoWatermark()}
          <BarChart
            grouped
            isComboChart
            backgroundColor={backgroundColor}
            canUseMultiYAxis={CAN_USE_MULTI_Y_AXIS_OPS.has(operation_type)}
            dataPanelProvidedId={dataPanel.provided_id}
            dataPanelTemplateId={dataPanel.id}
            datasets={datasets}
            globalStyleConfig={this.context.globalStyleConfig}
            instructions={instructions.V2_TWO_DIMENSION_CHART}
            loading={showFullChartLoading}
            previewData={dataPanel._rows || []}
            schema={schema}
            setChartMenu={setChartMenu}
            variables={variables}
          />
        </div>
      );
    } else if (operation_type === OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2) {
      return (
        <div className={chartContainerClass}>
          {this.renderDemoWatermark()}
          <HeatMap
            backgroundColor={backgroundColor}
            dataPanelTemplateId={dataPanel.id}
            generalOptions={generalFormatOptions}
            globalStyleConfig={this.context.globalStyleConfig}
            instructions={instructions.V2_TWO_DIMENSION_CHART}
            loading={showFullChartLoading}
            openDrilldownModal={openDrilldownModal}
            previewData={dataPanel._rows || []}
            schema={schema}
            selectedColorColName={selectedColorColName}
            setChartMenu={setChartMenu}
            variables={variables}
          />
        </div>
      );
    } else if (operation_type === OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2) {
      return (
        <div className={chartContainerClass}>
          {this.renderDemoWatermark()}
          <ScatterPlot
            backgroundColor={backgroundColor}
            datasets={datasets}
            globalStyleConfig={this.context.globalStyleConfig}
            instructions={instructions.V2_SCATTER_PLOT}
            loading={showFullChartLoading}
            previewData={dataPanel._rows || []}
            schema={schema}
            variables={variables}
          />
        </div>
      );
    } else if (operation_type === OPERATION_TYPES.VISUALIZE_SPIDER_CHART) {
      return (
        <div className={chartContainerClass}>
          {this.renderDemoWatermark()}
          <SpiderChart
            backgroundColor={backgroundColor}
            colorCategoryTracker={colorCategoryTracker}
            dataPanelTemplateId={dataPanel.id}
            datasets={datasets}
            globalStyleConfig={this.context.globalStyleConfig}
            instructions={instructions.V2_TWO_DIMENSION_CHART}
            loading={showFullChartLoading}
            previewData={dataPanel._rows || []}
            schema={schema}
            variables={variables}
          />
        </div>
      );
    } else if (operation_type === OPERATION_TYPES.VISUALIZE_FUNNEL_V2) {
      return (
        <div className={chartContainerClass}>
          {this.renderDemoWatermark()}
          {
            // @ts-ignore - FunnelChartV2 expects a classes prop for some reason
            <FunnelChartV2
              backgroundColor={backgroundColor}
              dataPanelTemplateId={dataPanel.id}
              globalStyleConfig={this.context.globalStyleConfig}
              instructions={instructions.V2_TWO_DIMENSION_CHART}
              // Need to use a key so that the Funnel rerenders on chart reload because
              // a limitation of the funnel library is that it doesn't reload smartly unless the
              // entire component reloads
              key={uniqueId('funnel-chart')}
              loading={showFullChartLoading}
              previewData={cloneDeep(dataPanel._rows || [])}
              schema={schema}
              variables={variables}
            />
          }
        </div>
      );
    } else if (operation_type === OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2) {
      return (
        <div className={chartContainerClass}>
          {this.renderDemoWatermark()}
          <BarFunnel
            dataPanelTemplateId={dataPanel.id}
            globalStyleConfig={this.context.globalStyleConfig}
            instructions={instructions.V2_TWO_DIMENSION_CHART}
            loading={showFullChartLoading}
            previewData={dataPanel._rows || []}
            schema={schema}
            variables={variables}
          />
        </div>
      );
    } else if (operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_V2) {
      return (
        <div className={chartContainerClass}>
          {this.renderDemoWatermark()}
          <SingleNumberChart
            dataPanelTemplateId={dataPanel.id}
            defaultContainerPadding={this.context.globalStyleConfig.container.padding.default}
            generalOptions={generalFormatOptions}
            hideChartMenu={this.hideIcons()}
            infoTooltipText={getInformationTooltipInfo(operation_type, instructions)}
            instructions={instructions.V2_KPI}
            loading={showFullChartLoading}
            // only show the header loading state if we're not showing the full chart loading state
            newDataLoading={loading && !showFullChartLoading}
            openDrilldownModal={openDrilldownModal}
            operationType={operation_type}
            previewData={dataPanel._rows || []}
            processTemplatedValue={(s) => replaceTemplatesWithValues(s, variables, datasets)}
            schema={schema}
          />
        </div>
      );
    } else if (operation_type === OPERATION_TYPES.VISUALIZE_PROGRESS_V2) {
      return (
        <div className={chartContainerClass}>
          {this.renderDemoWatermark()}
          <SingleNumberChart
            dataPanelTemplateId={dataPanel.id}
            defaultContainerPadding={this.context.globalStyleConfig.container.padding.default}
            generalOptions={generalFormatOptions}
            hideChartMenu={this.hideIcons()}
            infoTooltipText={getInformationTooltipInfo(operation_type, instructions)}
            instructions={instructions.V2_KPI}
            loading={showFullChartLoading}
            // only show the header loading state if we're not showing the full chart loading state
            newDataLoading={loading}
            openDrilldownModal={openDrilldownModal}
            operationType={operation_type}
            previewData={dataPanel._rows || []}
            processTemplatedValue={(s) => replaceTemplatesWithValues(s, variables, datasets)}
            schema={schema}
          />
        </div>
      );
    } else if (operation_type === OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2) {
      return (
        <div className={chartContainerClass}>
          {this.renderDemoWatermark()}
          <BoxPlotV2
            backgroundColor={backgroundColor}
            dataPanelTemplateId={dataPanel.id}
            datasets={datasets}
            globalStyleConfig={this.context.globalStyleConfig}
            instructions={instructions.V2_BOX_PLOT}
            loading={showFullChartLoading}
            previewData={dataPanel._rows || []}
            schema={schema}
            secondaryData={secondaryData}
            variables={variables}
          />
        </div>
      );
    } else if (operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2) {
      return (
        <div className={chartContainerClass}>
          {this.renderDemoWatermark()}
          <NumberTrend
            aggValuesLoading={
              dataPanel._outstandingSecondaryDataRequests === undefined ||
              !!dataPanel._outstandingSecondaryDataRequests
            }
            aggregatedValues={
              !isEmpty(secondaryData)
                ? {
                    comparisonRange: secondaryData[0]
                      ? (secondaryData[0][Object.keys(secondaryData[0])[0] as string] as number) ??
                        0
                      : 0,
                    periodRange: secondaryData[1]
                      ? (secondaryData[1][Object.keys(secondaryData[1])[0] as string] as number) ??
                        0
                      : 0,
                  }
                : undefined
            }
            backgroundColor={backgroundColor}
            dataPanelTemplateId={dataPanel.id}
            editableDashboard={editableDashboard}
            generalOptions={generalFormatOptions}
            globalStyleConfig={this.context.globalStyleConfig}
            hideIcons={this.hideIcons()}
            infoTooltipText={getInformationTooltipInfo(operation_type, instructions)}
            instructions={instructions.V2_KPI_TREND}
            loading={showFullChartLoading}
            // only show the header loading state if we're not showing the full chart loading state
            newDataLoading={loading && !showFullChartLoading}
            openDrilldownModal={openDrilldownModal}
            previewData={dataPanel._rows || []}
            schema={schema}
            variables={variables}
          />
        </div>
      );
    } else if (operation_type === OPERATION_TYPES.VISUALIZE_TREND_TABLE) {
      return (
        <div className={chartContainerClass}>
          {this.renderDemoWatermark()}
          <TrendTable
            generalOptions={generalFormatOptions}
            instructions={instructions.V2_TREND_TABLE}
            loading={showFullChartLoading}
            previewData={dataPanel._rows || []}
            schema={schema}
            secondaryData={secondaryData}
            variables={variables}
          />
        </div>
      );
    } else if (operation_type === OPERATION_TYPES.VISUALIZE_MAP_V2) {
      if (
        showFullChartLoading ||
        !instructions.V2_TWO_DIMENSION_CHART ||
        !instructions.V2_TWO_DIMENSION_CHART.aggColumns ||
        !instructions.V2_TWO_DIMENSION_CHART.categoryColumn ||
        !instructions.V2_TWO_DIMENSION_CHART.aggColumns.length
      )
        return <NeedsConfigurationPanel loading={showFullChartLoading} />;
      return (
        <>
          {this.renderDemoWatermark()}
          <ChoroplethMap
            data={dataPanel._rows || []}
            densityColumn={instructions.V2_TWO_DIMENSION_CHART.aggColumns[0].column}
            globalStyleConfig={this.context.globalStyleConfig}
            region={
              instructions.V2_TWO_DIMENSION_CHART.chartSpecificFormat?.mapChart?.region ||
              REGION_TYPES.WORLD
            }
            regionColumn={instructions.V2_TWO_DIMENSION_CHART.categoryColumn.column}
            schema={schema}
          />
        </>
      );
    } else if (
      operation_type === OPERATION_TYPES.VISUALIZE_REPORT_BUILDER ||
      operation_type === OPERATION_TYPES.VISUALIZE_PIVOT_REPORT_BUILDER
    ) {
      return (
        <ReportBuilder
          adHocOperationInstructions={dataPanel._adHocOperationInstructions || {}}
          dataPanelId={dataPanel.id}
          error={!!dataPanel._error}
          isEmbed={pageType !== PAGE_TYPE.EXPLO_APP}
          loading={showFullChartLoading}
          onAdHocFilterInfoUpdate={this.onAdHocFilterInfoUpdate}
          onAdHocSortOrPageUpdate={this.onAdHocSortOrPageUpdate}
          onDownloadPanelPdf={(adHocOperationInstructions, schema, reportName) =>
            onDownloadPanelPdf(adHocOperationInstructions, undefined, schema, reportName)
          }
          onDownloadPanelSpreadsheet={(fileFormat, userTransformedSchema) =>
            onDownloadPanelSpreadsheet(fileFormat, undefined, userTransformedSchema)
          }
          rows={dataPanel._rows || []}
          schema={schema}
          secondaryData={secondaryData}
          sourceDataRowCount={dataPanel._total_row_count}
          unsupportedOperations={dataPanel._unsupported_operations}
          updateDataTableOperation={(newVisualizeOperation) => {
            pageType === PAGE_TYPE.EXPLO_APP
              ? updateVisualizeOperation(newVisualizeOperation, OPERATION_TYPES.VISUALIZE_TABLE)
              : embedUpdateDPTDataTableColumnWidths({
                  dataPanelTemplateId: dataPanel.id,
                  visualizeInstructions: newVisualizeOperation,
                });
          }}
          variables={variables}
          visualizeOperation={dataPanel.visualize_op}
        />
      );
    } else if (operation_type === OPERATION_TYPES.VISUALIZE_PIVOT_TABLE) {
      const dataset = datasets[getDataPanelDatasetId(dataPanel)];
      const pivotTableInstructions = instructions.VISUALIZE_PIVOT_TABLE;

      const sortedSchema = pivotTableInstructions
        ? this.getSortedSchema(
            getChangedSchema(schema, pivotTableInstructions, dataset),
            pivotTableInstructions.orderedColumnNames,
          )
        : schema;

      const sortedUserTransformedSchema = pivotTableInstructions
        ? this.getSortedUserTransformedSchema(schema, pivotTableInstructions.orderedColumnNames)
        : this.state.userTransformedSchema;

      return (
        <PivotTable
          adHocOperationInstructions={dataPanel._adHocOperationInstructions || {}}
          analyticsEventTracker={analyticsEventTracker}
          canDownloadDataPanel={canDownloadDataPanel}
          dataPanelId={dataPanel.id}
          datasets={datasets}
          error={!!dataPanel._error}
          generalOptions={generalFormatOptions}
          instructions={pivotTableInstructions}
          isEmbed={pageType !== PAGE_TYPE.EXPLO_APP}
          loading={showFullChartLoading}
          onAdHocSortOrPageUpdate={this.onAdHocSortOrPageUpdate}
          onDownloadPanelPdf={onDownloadPanelPdf}
          onDownloadPanelSpreadsheet={onDownloadPanelSpreadsheet}
          previewData={dataPanel._rows || []}
          schema={sortedSchema}
          secondaryData={secondaryData}
          shouldUseJobQueue={shouldUseJobQueue}
          sourceDataRowCount={dataPanel._total_row_count}
          supportEmail={supportEmail}
          unsupportedOperations={dataPanel._unsupported_operations}
          userTransformedSchema={sortedUserTransformedSchema}
          variables={variables}
        />
      );
    } else if (operation_type === OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST) {
      const numRowCols = instructions.VISUALIZE_COLLAPSIBLE_LIST?.rowColumns?.length || 0;

      const sortedSchema = schema
        .slice(0, numRowCols)
        .concat(
          sortSchemaByOrderedColumnNames(
            schema.slice(numRowCols),
            instructions.VISUALIZE_COLLAPSIBLE_LIST?.orderedColumnNames,
          ),
        );

      return (
        <DashboardCollapsibleList
          dataPanelId={dataPanel.id}
          disableDownload={loading || !!dataPanel._error}
          generalOptions={generalFormatOptions}
          instructions={instructions.VISUALIZE_COLLAPSIBLE_LIST || {}}
          loading={showFullChartLoading}
          onDownloadPanelSpreadsheet={onDownloadPanelSpreadsheet}
          previewData={dataPanel._rows || []}
          schema={sortedSchema}
          shouldUseJobQueue={shouldUseJobQueue}
          supportEmail={supportEmail}
        />
      );
    } else {
      const sortedSchema = this.getSortedSchema(
        getChangedSchema(schema, instructions.VISUALIZE_TABLE, dataset),
        instructions.VISUALIZE_TABLE.orderedColumnNames,
      );

      const sortedUserTransformedSchema = this.getSortedUserTransformedSchema(
        schema,
        instructions.VISUALIZE_TABLE.orderedColumnNames,
      );

      return (
        <DataTable
          adHocOperationInstructions={dataPanel._adHocOperationInstructions || {}}
          analyticsEventTracker={analyticsEventTracker}
          canDownloadDataPanel={canDownloadDataPanel}
          dataPanelId={dataPanel.id}
          datasets={datasets}
          drilldownDataset={isDrilldownModal ? (dataset as Dataset) : undefined}
          drilldownVar={variables[dataPanel.provided_id]}
          enableColumnResizing={isSelected || isViewOnly}
          error={!!dataPanel._error}
          generalOptions={generalFormatOptions}
          instructions={instructions.VISUALIZE_TABLE}
          isDrilldownModal={isDrilldownModal}
          isEmbed={pageType !== PAGE_TYPE.EXPLO_APP}
          loading={showFullChartLoading}
          onAdHocSortOrPageUpdate={this.onAdHocSortOrPageUpdate}
          onDownloadPanelPdf={onDownloadPanelPdf}
          onDownloadPanelSpreadsheet={onDownloadPanelSpreadsheet}
          previewData={dataPanel._rows || []}
          schema={sortedSchema}
          secondaryData={secondaryData}
          setVariable={setVariable}
          shouldUseJobQueue={shouldUseJobQueue}
          sourceDataRowCount={dataPanel._total_row_count}
          supportEmail={supportEmail}
          unsupportedOperations={dataPanel._unsupported_operations}
          updateDataTableOperation={(newVisualizeOperation) => {
            pageType === PAGE_TYPE.EXPLO_APP
              ? updateVisualizeOperation(newVisualizeOperation, OPERATION_TYPES.VISUALIZE_TABLE)
              : embedUpdateDPTDataTableColumnWidths({
                  dataPanelTemplateId: dataPanel.id,
                  visualizeInstructions: newVisualizeOperation,
                });
          }}
          userTransformedSchema={this.state.endUserTransformedSchema ?? sortedUserTransformedSchema}
          variables={variables}
        />
      );
    }
  };

  canDownloadCSV = () => {
    const { dataPanel, canDownloadDataPanel } = this.props;
    return Boolean(
      canDownloadDataPanel &&
        VIZ_OP_WITH_CSV_DOWNLOAD.has(dataPanel.visualize_op.operation_type) &&
        dataPanel._rows?.length &&
        isDataPanelConfigReady(dataPanel.visualize_op),
    );
  };

  hideIcons = () =>
    this.props.viewMode === VIEW_MODE.PDF || this.props.viewMode === VIEW_MODE.EMAIL;

  updateEndUserTransformedSchema = (endUserTransformedSchema: UserTransformedSchema) => {
    this.setState({ endUserTransformedSchema });
  };

  getExploreDataset = () => {
    const { datasets, dataPanel } = this.props;
    const dataset = datasets[getDataPanelDatasetId(dataPanel)];
    if (isExploreDataset(dataset)) return dataset;
  };

  getSortedSchema = (schema: DatasetSchema, orderedSchema?: string[]) => {
    const { isDrilldownModal } = this.props;
    const dataset = this.getExploreDataset();

    return isDrilldownModal && dataset && dataset.drilldownColumnConfigs
      ? getSortedDrilldownColumns(schema, dataset)
      : sortSchemaByOrderedColumnNames(schema, orderedSchema);
  };

  getSortedUserTransformedSchema = (schema: DatasetSchema, orderedSchema?: string[]) => {
    const { isDrilldownModal } = this.props;
    const dataset = this.getExploreDataset();

    return isDrilldownModal && dataset && dataset.drilldownColumnConfigs
      ? getSortedUserTransformedDrilldownColumns(schema, dataset)
      : sortSchemaByOrderedColumnNames(this.state.userTransformedSchema, orderedSchema);
  };

  renderHeader = () => {
    const {
      dataPanel,
      datasets,
      elementEndsOnRightSide,
      loading,
      variables,
      isDrilldownModal,
      onDownloadPanelSpreadsheet,
      onDownloadPanelPdf,
      setVariable,
      openDrilldownModal,
      onCloseDrilldownModal,
      shouldUseJobQueue,
      supportEmail,
    } = this.props;
    const { operation_type, instructions, generalFormatOptions } = dataPanel.visualize_op;

    if (NO_HEADER_OPERATION_TYPES.includes(operation_type)) return null;

    // only show the header loading state if we're not showing the full chart loading state
    const showLoadingState = loading && !this.shouldShowFullChartLoading();

    const infoTooltipText = getInformationTooltipInfo(operation_type, instructions);
    const replacedVarsTooltipText = resolveTooltipVariables(
      { showTooltip: !!infoTooltipText, infoTooltipText },
      variables,
    );

    const title = generalFormatOptions?.headerConfig?.title;

    if (
      operation_type === OPERATION_TYPES.VISUALIZE_TABLE ||
      operation_type === OPERATION_TYPES.VISUALIZE_PIVOT_TABLE ||
      operation_type === OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST
    ) {
      if (
        dataPanel.visualize_op.generalFormatOptions?.headerConfig?.isHeaderHidden &&
        !isDrilldownModal
      )
        return;

      const isVisualizeTableOp = operation_type === OPERATION_TYPES.VISUALIZE_TABLE;

      const sortedSchema = this.getSortedSchema(
        dataPanel._schema ?? [],
        instructions.VISUALIZE_TABLE.orderedColumnNames,
      );

      const sortedUserTransformedSchema = this.getSortedUserTransformedSchema(
        dataPanel._schema ?? [],
        instructions.VISUALIZE_TABLE.orderedColumnNames,
      );

      return (
        <DataTableHeader
          adHocOperationInstructions={dataPanel._adHocOperationInstructions ?? {}}
          drilldownDatasetName={
            isDrilldownModal
              ? getDatasetName(datasets[getDataPanelDatasetId(dataPanel)])
              : undefined
          }
          elementEndsOnRightSide={elementEndsOnRightSide}
          error={!!dataPanel._error}
          infoTooltipText={replacedVarsTooltipText}
          isFilteringDisabled={
            isVisualizeTableOp
              ? instructions.VISUALIZE_TABLE.isFilteringDisabled
              : instructions.VISUALIZE_PIVOT_TABLE?.isFilteringDisabled
          }
          isSchemaCustomizationEnabled={
            isVisualizeTableOp
              ? instructions.VISUALIZE_TABLE.isSchemaCustomizationEnabled || isDrilldownModal
              : false
          }
          linkFormat={generalFormatOptions?.linkFormat}
          loading={showLoadingState}
          onAdHocFilterInfoUpdate={this.onAdHocFilterInfoUpdate}
          onCloseDrilldownModal={onCloseDrilldownModal}
          schema={sortedSchema}
          title={title ? replaceTemplatesWithValues(title, variables, datasets) : ''}
          updateUserTransformedSchema={this.updateEndUserTransformedSchema}
          userTransformedSchema={this.state.endUserTransformedSchema ?? sortedUserTransformedSchema}
        />
      );
    }

    const exportOptions = generalFormatOptions?.export;
    const isDownloadDisabled = exportOptions?.isDownloadButtonHidden || !this.canDownloadCSV();
    const exportProps: ExportProps = {
      supportEmail,
      disableDownloadExport: exportOptions?.disableDownloadExport || isDownloadDisabled,
      enableEmailExport: exportOptions?.enableEmailExport && !isDownloadDisabled,
      disablePdfDownload: exportOptions?.disablePdfDownload,
      error: !!dataPanel._error,
      shouldUseJobQueue,
      onDownloadPanelSpreadsheet: (fileFormat, email) => {
        onDownloadPanelSpreadsheet(fileFormat, email, this.state.userTransformedSchema);
      },
      onDownloadPanelPdf: (email) => {
        onDownloadPanelPdf(
          dataPanel._adHocOperationInstructions || {},
          email,
          this.state.userTransformedSchema,
        );
      },
    };

    const shouldRenderColorCategoryDropdown = isChartUsingMultipleColorCategories(
      dataPanel.visualize_op,
    );

    const colorCategoryProps: ColorCategoryProps | undefined =
      shouldRenderColorCategoryDropdown && setVariable
        ? {
            instructions: instructions.V2_TWO_DIMENSION_CHART,
            dashboardVariables: variables,
            dpProvidedId: dataPanel.provided_id,
            setVariable,
          }
        : undefined;

    return (
      <ChartHeader
        colorCategoryProps={colorCategoryProps}
        dataPanelId={dataPanel.id}
        disableMenu={loading}
        exportProps={exportProps}
        hideIcons={this.hideIcons()}
        includeFullPadding={operation_type === OPERATION_TYPES.VISUALIZE_TREND_TABLE}
        infoTooltipText={replacedVarsTooltipText}
        isHeaderHidden={generalFormatOptions?.headerConfig?.isHeaderHidden}
        linkFormat={generalFormatOptions?.linkFormat}
        loading={showLoadingState}
        openDrilldownModal={
          generalFormatOptions?.enableRawDataDrilldown ? openDrilldownModal : undefined
        }
        title={title ? replaceTemplatesWithValues(title, variables, datasets) : ''}
      />
    );
  };

  onAdHocSortOrPageUpdate = (adHocOperationInstructions: AdHocOperationInstructions) => {
    const { onAdHocOperationInstructionsUpdated, dataPanel } = this.props;

    onAdHocOperationInstructionsUpdated(dataPanel.id, adHocOperationInstructions, true);
  };

  onAdHocFilterInfoUpdate = (adHocFilterInfo: FilterOperationInstructions) => {
    const { onAdHocOperationInstructionsUpdated, dataPanel, analyticsEventTracker } = this.props;
    const newAdHocOperationInstructions = produce(
      dataPanel._adHocOperationInstructions ?? {},
      (draft) => {
        draft.filterInfo = adHocFilterInfo;
        draft.currentPage = 1;
      },
    );

    onAdHocOperationInstructionsUpdated(dataPanel.id, newAdHocOperationInstructions);
    analyticsEventTracker?.(REPORTED_ANALYTIC_ACTION_TYPES.TABLE_FILTERED);
  };

  renderNoDataBody = () => {
    const { classes, variables, dataPanel, datasets, loading } = this.props;

    const { operation_type, instructions, generalFormatOptions } = dataPanel.visualize_op;

    const schema = dataPanel._schema ?? [];

    if (
      operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_V2 ||
      operation_type === OPERATION_TYPES.VISUALIZE_PROGRESS_V2
    ) {
      return (
        <div className={sprinkles({ height: 'fill', padding: 'sp2' })}>
          {this.renderDemoWatermark()}
          <SingleNumberChart
            dataPanelTemplateId={dataPanel.id}
            defaultContainerPadding={this.context.globalStyleConfig.container.padding.default}
            generalOptions={generalFormatOptions}
            infoTooltipText={getInformationTooltipInfo(operation_type, instructions)}
            instructions={instructions.V2_KPI}
            loading={loading}
            operationType={operation_type}
            previewData={[]}
            processTemplatedValue={(s) => replaceTemplatesWithValues(s, variables, datasets)}
            schema={schema}
          />
        </div>
      );
    } else if (operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2) {
      const secondaryData = dataPanel._secondaryData ?? [];
      return (
        <>
          {this.renderDemoWatermark()}
          <NumberTrend
            aggValuesLoading={
              dataPanel._outstandingSecondaryDataRequests === undefined ||
              !!dataPanel._outstandingSecondaryDataRequests
            }
            aggregatedValues={
              !isEmpty(secondaryData)
                ? {
                    comparisonRange: secondaryData[0]
                      ? (secondaryData[0][Object.keys(secondaryData[0])[0] as string] as number) ??
                        0
                      : 0,
                    periodRange: secondaryData[1]
                      ? (secondaryData[1][Object.keys(secondaryData[1])[0] as string] as number) ??
                        0
                      : 0,
                  }
                : undefined
            }
            backgroundColor={this.context.globalStyleConfig.container.fill}
            dataPanelTemplateId={dataPanel.id}
            generalOptions={generalFormatOptions}
            globalStyleConfig={this.context.globalStyleConfig}
            infoTooltipText={getInformationTooltipInfo(operation_type, instructions)}
            instructions={instructions.V2_KPI_TREND}
            loading={loading}
            previewData={dataPanel._rows || []}
            schema={schema}
            variables={variables}
          />
        </>
      );
    }

    return (
      <div className={cx(classes.noDataContainer, GLOBAL_STYLE_CLASSNAMES.text.body.primary)}>
        <NonIdealState
          className={classes.noDataCallout}
          icon={
            loading ? (
              <Spinner size="lg" />
            ) : generalFormatOptions?.noDataState?.hideChartIcon ? undefined : (
              <Icon
                className={classes.emptyIcon}
                icon={OP_TYPE_TO_BP3_ICON[operation_type]}
                iconSize={30}
              />
            )
          }
          title={generalFormatOptions?.noDataState?.noDataText || 'No Data'}
        />
      </div>
    );
  };

  renderErrorState = () => {
    const { classes, loading, dataPanel } = this.props;
    const errMsg =
      typeof dataPanel._error === 'string' && IS_ACCESS_GROUP_ERROR(dataPanel._error)
        ? ACCESS_GROUP_NO_DEFAULT_DATASOURCES_WARNING_WITH_LINK(dataPanel._error)
        : 'There was an error fetching the results';

    const errorContainerClass = NO_HEADER_OPERATION_TYPES.includes(
      dataPanel.visualize_op.operation_type,
    )
      ? classes.errorContainerNoHeader
      : classes.errorContainer;

    return (
      <div
        className={cx(
          errorContainerClass,
          'data-panel-error',
          GLOBAL_STYLE_CLASSNAMES.container.fill.backgroundColor,
        )}
        id={dataPanel.id}>
        <NonIdealState
          action={
            <Button
              minimal
              icon="document-open"
              onClick={() => alert(dataPanel._error)}
              text="View full error"
            />
          }
          className={classes.errorCallout}
          description={parseErrorMessage(dataPanel._error)}
          icon={
            loading ? (
              <Spinner size="lg" />
            ) : (
              <Icon icon="error" iconSize={30} intent={Intent.DANGER} />
            )
          }
          title={errMsg}
        />
      </div>
    );
  };

  renderDemoWatermark = () => {
    const { displayDemoWatermark } = this.props;
    return displayDemoWatermark ? <DemoWatermark /> : null;
  };
}

const mapStateToProps = (state: JoinedStates, ownProps: PassedProps) => {
  let canvasFilters = undefined;
  if (ownProps.isArchitectCustomerDashboard) {
    if ('architectCustomerDashboard' in state) {
      canvasFilters = state.architectCustomerDashboard.canvasVersion?.configuration.filters;
    }
  } else if ('canvasEdit' in state && isSuccess(state.canvasEdit.config)) {
    canvasFilters = state.canvasEdit.config.data.filters;
  }

  return {
    colorCategoryTracker: state.dashboardStyles.colorCategoryTracker,
    canvasFilters,
  };
};

const mapDispatchToProps = {
  updateVisualizeOperation,
  embedUpdateDPTDataTableColumnWidths,
  setChartMenu,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(DashboardDatasetView));
