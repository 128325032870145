import Redux from 'redux';
import { batch } from 'react-redux';
import { ACTION } from 'actions/types';
import { BulkJobEnqueueResponse, JobDefinition, JobQueueResult } from 'actions/jobQueueActions';
import { Jobs } from 'components/JobQueue/types';
import {
  fetchDataPanelSuccess,
  fetchDataPanelError,
  fetchDataPanelRowCountSuccess,
  fetchSecondaryDataSuccess,
  fetchSecondaryDataError,
  downloadDataPanelSpreadsheetSuccess,
  downloadDataPanelSpreadsheetError,
  fetchDataPanelRequest,
  fetchSecondaryDataRequest,
  fetchDataPanelRowCountError,
  downloadDataPanelSpreadsheetRequest,
} from 'actions/dataPanelTemplateAction';
import {
  fetchDashboardDatasetPreviewSuccess,
  fetchEditorDatasetPreviewSuccess,
  fetchEditorDatasetPreviewError,
  fetchEditorDatasetRowCountSuccess,
  fetchEditorDatasetPreviewRequest,
  fetchEditorDatasetRowCountError,
} from 'actions/datasetActions';
import {
  fetchDatasetPreviewSuccess,
  fetchDatasetPreviewError,
  fetchDatasetRowCountSuccess,
  fetchAllDataSourceTablesSuccess,
  fetchAllDataSourceTablesError,
  syncSourceTablesSuccess,
  fetchDatasetPreviewRequest,
  fetchDatasetRowCountError,
  syncSourceTablesRequest,
  fetchAllDataSourceTablesRequest,
} from 'actions/dataSourceActions';
import {
  downloadDashboardImageError,
  downloadDashboardImageRequest,
  downloadDashboardImageSuccess,
  downloadDashboardPdfError,
  downloadDashboardPdfRequest,
  downloadDashboardPdfSuccess,
  downloadDataPanelPdfError,
  downloadDataPanelPdfRequest,
  downloadDataPanelPdfSuccess,
} from 'actions/exportActions';
import {
  embedFetchDashboardDatasetPreviewSuccess,
  embedFetchDataPanelSuccess,
  embedFetchDataPanelError,
  embedFetchDataPanelRowCountSuccess,
  embedFetchSecondaryDataSuccess,
  embedFetchSecondaryDataError,
  embedDownloadDataPanelSpreadsheetSuccess,
  embedDownloadDataPanelSpreadsheetError,
  embedDownloadDashboardImageSuccess,
  embedDownloadDashboardPdfSuccess,
  embedFetchDataPanelRequest,
  embedFetchSecondaryDataRequest,
  embedFetchDataPanelRowCountError,
  embedDownloadDataPanelSpreadsheetRequest,
  embedDownloadDashboardImageRequest,
  embedDownloadDashboardImageError,
  embedDownloadDashboardPdfRequest,
  embedDownloadDashboardPdfError,
  embedDownloadDataPanelPdfRequest,
  embedDownloadDataPanelPdfSuccess,
  embedDownloadDataPanelPdfError,
} from 'actions/shareActions';
import {
  exportArchitectDashboardError,
  exportArchitectDashboardRequest,
  exportArchitectDashboardSuccess,
} from 'actions/architectCustomerDashboardActions';
import {
  exportCustomerReportError,
  exportCustomerReportSuccess,
} from 'reportBuilderContent/thunks/exportThunks';

/**
 * Returns the action for the given job type. For dashboard actions, this will either be the embed or non-embed
 * version of the action, depending on whether the context is embedded. For other actions, this is a no-op that
 * returns the job type
 */
export const getDispatchActions = (jobType: ACTION, isEmbed = false) => {
  switch (jobType) {
    case ACTION.FETCH_DATA_PANEL_TEMPLATE:
      return !isEmbed
        ? {
            requestFn: fetchDataPanelRequest,
            successFn: fetchDataPanelSuccess,
            errorFn: fetchDataPanelError,
          }
        : {
            requestFn: embedFetchDataPanelRequest,
            successFn: embedFetchDataPanelSuccess,
            errorFn: embedFetchDataPanelError,
          };
    case ACTION.FETCH_SECONDARY_DATA:
      return !isEmbed
        ? {
            requestFn: fetchSecondaryDataRequest,
            successFn: fetchSecondaryDataSuccess,
            errorFn: fetchSecondaryDataError,
          }
        : {
            requestFn: embedFetchSecondaryDataRequest,
            successFn: embedFetchSecondaryDataSuccess,
            errorFn: embedFetchSecondaryDataError,
          };
    case ACTION.FETCH_DATA_PANEL_ROW_COUNT:
      return !isEmbed
        ? {
            requestFn: null,
            successFn: fetchDataPanelRowCountSuccess,
            errorFn: fetchDataPanelRowCountError,
          }
        : {
            requestFn: null,
            successFn: embedFetchDataPanelRowCountSuccess,
            errorFn: embedFetchDataPanelRowCountError,
          };
    case ACTION.DOWNLOAD_DATA_PANEL_TEMPLATE_PDF:
      return !isEmbed
        ? {
            requestFn: downloadDataPanelPdfRequest,
            successFn: downloadDataPanelPdfSuccess,
            errorFn: downloadDataPanelPdfError,
          }
        : {
            requestFn: embedDownloadDataPanelPdfRequest,
            successFn: embedDownloadDataPanelPdfSuccess,
            errorFn: embedDownloadDataPanelPdfError,
          };
    case ACTION.FETCH_DASHBOARD_DATASET_PREVIEW:
      return !isEmbed
        ? {
            requestFn: null,
            successFn: fetchDashboardDatasetPreviewSuccess,
            errorFn: null,
          }
        : {
            requestFn: null,
            successFn: embedFetchDashboardDatasetPreviewSuccess,
            errorFn: null,
          };
    case ACTION.FETCH_IMAGE_EXPORT_URL:
      return !isEmbed
        ? {
            requestFn: downloadDashboardImageRequest,
            successFn: downloadDashboardImageSuccess,
            errorFn: downloadDashboardImageError,
          }
        : {
            requestFn: embedDownloadDashboardImageRequest,
            successFn: embedDownloadDashboardImageSuccess,
            errorFn: embedDownloadDashboardImageError,
          };
    case ACTION.FETCH_PDF_EXPORT_URL:
      return !isEmbed
        ? {
            requestFn: downloadDashboardPdfRequest,
            successFn: downloadDashboardPdfSuccess,
            errorFn: downloadDashboardPdfError,
          }
        : {
            requestFn: embedDownloadDashboardPdfRequest,
            successFn: embedDownloadDashboardPdfSuccess,
            errorFn: embedDownloadDashboardPdfError,
          };
    case ACTION.DOWNLOAD_DATA_PANEL_CSV:
      return !isEmbed
        ? {
            requestFn: downloadDataPanelSpreadsheetRequest,
            successFn: downloadDataPanelSpreadsheetSuccess,
            errorFn: downloadDataPanelSpreadsheetError,
          }
        : {
            requestFn: embedDownloadDataPanelSpreadsheetRequest,
            successFn: embedDownloadDataPanelSpreadsheetSuccess,
            errorFn: embedDownloadDataPanelSpreadsheetError,
          };
    case ACTION.FETCH_EDITOR_DATASET_PREVIEW:
      return {
        requestFn: fetchEditorDatasetPreviewRequest,
        successFn: fetchEditorDatasetPreviewSuccess,
        errorFn: fetchEditorDatasetPreviewError,
      };
    case ACTION.FETCH_EDITOR_DATASET_ROW_COUNT:
      return {
        requestFn: null,
        successFn: fetchEditorDatasetRowCountSuccess,
        errorFn: fetchEditorDatasetRowCountError,
      };
    case ACTION.FETCH_DATASET_PREVIEW:
      return {
        requestFn: fetchDatasetPreviewRequest,
        successFn: fetchDatasetPreviewSuccess,
        errorFn: fetchDatasetPreviewError,
      };
    case ACTION.FETCH_DATASET_ROW_COUNT:
      return {
        requestFn: null,
        successFn: fetchDatasetRowCountSuccess,
        errorFn: fetchDatasetRowCountError,
      };
    case ACTION.SYNC_SOURCE_TABLES:
      return {
        requestFn: syncSourceTablesRequest,
        successFn: syncSourceTablesSuccess,
        errorFn: null,
      };
    case ACTION.FETCH_ALL_DATA_SOURCE_TABLES:
      return {
        requestFn: fetchAllDataSourceTablesRequest,
        successFn: fetchAllDataSourceTablesSuccess,
        errorFn: fetchAllDataSourceTablesError,
      };
    case ACTION.EXPORT_END_USER_DASHBOARD:
      return {
        requestFn: exportArchitectDashboardRequest,
        successFn: exportArchitectDashboardSuccess,
        errorFn: exportArchitectDashboardError,
      };
    case ACTION.EXPORT_CUSTOMER_REPORT:
      return {
        requestFn: null,
        successFn: exportCustomerReportSuccess,
        errorFn: exportCustomerReportError,
      };
    default:
      return {
        requestFn: null,
        successFn: null,
        errorFn: null,
      };
  }
};

/**
 * Takes in a response from the bulk enqueue endpoint and returns a dictionary of
 * the backend's job id mapped to the job object
 */
export const handleBulkEnqueueResponse = (
  { enqueued_job_map: enqueuedJobMap }: BulkJobEnqueueResponse,
  jobMap: Record<string, JobDefinition>,
  dispatch: Redux.Dispatch,
  isEmbed: boolean,
) => {
  const newAwaitedJobs: Record<string, Jobs> = {};
  Object.keys(enqueuedJobMap).forEach((jobId) => {
    const { job_intention_key: jobIntentionKey, error } = enqueuedJobMap[jobId];

    const { onSuccess, onError, job_type: jobType, job_args: jobArgs } = jobMap[jobId];

    if (error) {
      handleEnqueueError({ jobType, jobArgs, onError }, error, isEmbed, dispatch);
    } else if (jobIntentionKey) {
      switch (jobType) {
        case ACTION.FETCH_DATA_PANEL_TEMPLATE:
        case ACTION.FETCH_SECONDARY_DATA:
        case ACTION.FETCH_DATA_PANEL_ROW_COUNT:
        case ACTION.DOWNLOAD_DATA_PANEL_CSV: {
          newAwaitedJobs[jobIntentionKey] = {
            jobType,
            dataPanelId: jobArgs.id,
          };
          break;
        }
        case ACTION.DOWNLOAD_DATA_PANEL_TEMPLATE_PDF: {
          newAwaitedJobs[jobIntentionKey] = {
            jobType,
            dataPanelId: jobArgs.data_panel_template_id,
          };
          break;
        }
        case ACTION.FETCH_DASHBOARD_DATASET_PREVIEW: {
          newAwaitedJobs[jobIntentionKey] = {
            jobType,
            datasetId: jobArgs.dataset_id,
            onSuccess,
          };
          break;
        }

        case ACTION.FETCH_EDITOR_DATASET_PREVIEW: {
          newAwaitedJobs[jobIntentionKey] = {
            jobType,
            datasetId: jobArgs.dataset_id,
            onSuccess,
          };
          break;
        }
        case ACTION.FETCH_EDITOR_DATASET_ROW_COUNT: {
          newAwaitedJobs[jobIntentionKey] = {
            jobType,
            datasetId: jobArgs.dataset_id,
          };
          break;
        }
        case ACTION.FETCH_DATASET_PREVIEW: {
          newAwaitedJobs[jobIntentionKey] = {
            jobType,
            datasetId: jobArgs.dataset_id,
          };
          break;
        }
        case ACTION.FETCH_DATASET_ROW_COUNT: {
          newAwaitedJobs[jobIntentionKey] = {
            jobType,
            datasetId: jobArgs.dataset_id,
          };
          break;
        }
        case ACTION.SEND_TEST_EMAIL: {
          newAwaitedJobs[jobIntentionKey] = {
            jobType,
            onSuccess: onSuccess as () => void,
            onError,
          };
          break;
        }
        case ACTION.SYNC_SOURCE_TABLES: {
          newAwaitedJobs[jobIntentionKey] = {
            jobType,
            onSuccess: onSuccess as () => void,
          };
          break;
        }
        case ACTION.FETCH_ALL_DATA_SOURCE_TABLES: {
          newAwaitedJobs[jobIntentionKey] = {
            jobType,
            onError,
          };
          break;
        }
        case ACTION.TEST_DATA_SOURCE_CONNECTION: {
          newAwaitedJobs[jobIntentionKey] = {
            jobType,
            onSuccess,
            onError,
          };
          break;
        }
        case ACTION.TEST_UPDATED_DATA_SOURCE_CONNECTION: {
          newAwaitedJobs[jobIntentionKey] = {
            jobType,
            onSuccess,
            onError,
          };
          break;
        }
        case ACTION.SEND_TEST_CUSTOMER_REPORT_CADENCE_EMAIL:
        case ACTION.SEND_TEST_CUSTOMER_REPORT_CADENCE_EMAIL_BY_PARAMS:
        case ACTION.FETCH_IMAGE_EXPORT_URL:
        case ACTION.FETCH_PDF_EXPORT_URL:
        case ACTION.SEND_TEST_END_USER_EMAIL:
        case ACTION.SEND_TEST_END_USER_EMAIL_BY_PARAMS:
        case ACTION.EXPORT_END_USER_DASHBOARD: {
          newAwaitedJobs[jobIntentionKey] = { jobType };
          break;
        }
      }
    }
  });

  return newAwaitedJobs;
};

/**
 * Dispatches the error action, if exists, for the given job type and fires off that job's onError, if exists
 */
export const handleEnqueueError = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  job: { jobType: ACTION; jobArgs: any; onError?: ((errorMessage: string) => void) | (() => void) },
  errorMessage: string,
  isEmbed: boolean,
  dispatch: Redux.Dispatch,
) => {
  const { errorFn } = getDispatchActions(job.jobType, isEmbed);

  let errorArgs = undefined;

  switch (job.jobType) {
    case ACTION.FETCH_DATA_PANEL_TEMPLATE:
    case ACTION.FETCH_SECONDARY_DATA:
    case ACTION.DOWNLOAD_DATA_PANEL_CSV:
      errorArgs = {
        error_msg: errorMessage,

        // postData has more values normally, but the reducer only uses id
        // and the compiler is finicky
        // @ts-ignore
        postData: { id: job.jobArgs.id },
      };
      break;
    case ACTION.FETCH_EDITOR_DATASET_PREVIEW:
    case ACTION.FETCH_DATASET_PREVIEW:
      errorArgs = {
        error_msg: errorMessage,
        // postData has more values normally, but the reducer only uses id
        // and the compiler is finicky
        // @ts-ignore
        postData: { dataset_id: job.jobArgs.dataset_id },
      };
      break;
    case ACTION.FETCH_ALL_DATA_SOURCE_TABLES:
    case ACTION.EXPORT_END_USER_DASHBOARD:
      errorArgs = {};
      break;
    default:
      break;
  }

  // @ts-ignore
  if (errorFn && errorArgs !== undefined) dispatch(errorFn(errorArgs));
  job.onError?.(errorMessage);
};

/**
 * Takes in a response from the get job queue results endpoint and fires off any
 * success and error redux actions and job success and error functions as necessary.
 * Returns a list of the finished job ids.
 */
export const handleJobQueueResults = (
  finishedJobs: Record<string, JobQueueResult>,
  awaitedJobs: Record<string, Jobs>,
  dispatch: Redux.Dispatch,
  isEmbed: boolean,
) => {
  const finishedJobIds = Object.keys(finishedJobs);

  batch(() => {
    finishedJobIds.forEach((jobId) => {
      const { success, error } = finishedJobs[jobId];
      const job = awaitedJobs[jobId];

      const { successFn, errorFn } = getDispatchActions(job.jobType, isEmbed);

      switch (job.jobType) {
        case ACTION.FETCH_DASHBOARD_DATASET_PREVIEW: {
          if (success) {
            if (successFn) {
              dispatch(successFn({ ...success, postData: { dataset_id: job.datasetId } }));
            }
            job.onSuccess?.(success);
          }
          if (error) job.onError?.();
          break;
        }
        case ACTION.FETCH_DATA_PANEL_TEMPLATE: {
          if (success && successFn) {
            dispatch(successFn({ ...success, postData: { id: job.dataPanelId } }));
          }
          if (error && errorFn)
            dispatch(
              errorFn({
                error_msg: error,
                // postData has more values normally, but the reducer only uses id
                // and the compiler is finicky
                // @ts-ignore
                postData: { id: job.dataPanelId },
                query_information: finishedJobs[jobId]?.query_information,
              }),
            );

          break;
        }
        case ACTION.FETCH_DATA_PANEL_ROW_COUNT: {
          if (success && successFn) {
            dispatch(successFn({ ...success, postData: { id: job.dataPanelId } }));
          }
          break;
        }
        case ACTION.FETCH_SECONDARY_DATA: {
          if (success && successFn) {
            dispatch(successFn({ ...success, postData: { id: job.dataPanelId } }));
          }
          if (error && errorFn)
            dispatch(
              errorFn({
                error_msg: error,
                // postData has more values normally, but the reducer only uses id
                // and the compiler is finicky
                // @ts-ignore
                postData: { id: job.dataPanelId },
              }),
            );

          break;
        }
        case ACTION.DOWNLOAD_DATA_PANEL_CSV: {
          if (success && successFn) {
            dispatch(successFn({ ...success, postData: { id: job.dataPanelId } }));
          }

          if (error && errorFn) {
            dispatch(
              errorFn({
                error_msg: error,
                // postData has more values normally, but the reducer only uses id
                // and the compiler is finicky
                // @ts-ignore
                postData: { id: job.dataPanelId },
              }),
            );
          }

          break;
        }
        case ACTION.DOWNLOAD_DATA_PANEL_TEMPLATE_PDF: {
          if (success && successFn)
            dispatch(
              successFn({ ...success, postData: { data_panel_template_id: job.dataPanelId } }),
            );

          if (error && errorFn) {
            dispatch(
              errorFn({
                error_msg: error,
                // @ts-ignore
                postData: { data_panel_template_id: job.dataPanelId },
              }),
            );
          }

          break;
        }
        case ACTION.FETCH_EDITOR_DATASET_PREVIEW: {
          if (success) {
            if (successFn) {
              dispatch(successFn({ ...success, postData: { dataset_id: job.datasetId } }));
            }
            job.onSuccess?.(success);
          }
          if (error && errorFn)
            dispatch(
              errorFn({
                error_msg: error,
                query_information: finishedJobs[jobId]?.query_information,
                // postData has more values normally, but the reducer only uses id
                // and the compiler is finicky
                // @ts-ignore
                postData: { dataset_id: job.datasetId },
              }),
            );
          break;
        }
        case ACTION.FETCH_EDITOR_DATASET_ROW_COUNT: {
          if (success && successFn) {
            dispatch(successFn({ ...success, postData: { dataset_id: job.datasetId } }));
          }
          break;
        }
        case ACTION.FETCH_DATASET_PREVIEW: {
          if (success && successFn) {
            dispatch(successFn({ ...success, postData: { dataset_id: job.datasetId } }));
          }
          if (error && errorFn)
            dispatch(
              errorFn({
                error_msg: error,
                // these are numbers, but have to be typed as strings
                // @ts-ignore
                postData: { dataset_id: job.datasetId },
              }),
            );
          break;
        }
        case ACTION.FETCH_DATASET_ROW_COUNT: {
          if (success && successFn) {
            dispatch(successFn({ ...success, postData: { dataset_id: job.datasetId } }));
          }
          break;
        }
        case ACTION.SYNC_SOURCE_TABLES: {
          if (success && successFn) {
            dispatch(successFn(success));
            job.onSuccess?.();
          }
          break;
        }
        case ACTION.TEST_DATA_SOURCE_CONNECTION:
        case ACTION.TEST_UPDATED_DATA_SOURCE_CONNECTION:
        case ACTION.SEND_TEST_EMAIL: {
          if (success) job.onSuccess?.(success);
          if (error) job.onError?.(error);
          break;
        }
        default:
          if (success && successFn) dispatch(successFn(success));
          // @ts-ignore
          if (error && errorFn) dispatch(errorFn());
      }
    });
  });

  return finishedJobIds;
};
