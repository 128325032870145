import { defineAPIPostAction } from './actionUtils';
import { createAction } from '@reduxjs/toolkit';
import { ACTION } from './types';
import { OnboardingStepData } from './userActions';

export type UpdateOnboardingStepsResponse = {
  onboarding_steps: Record<number, OnboardingStepData>;
};

type CompleteOnboardingSubStepBody = {
  sub_step_name: string;
};

export const {
  actionFn: completeOnboardingSubStep,
  successAction: completeOnboardingSubStepSuccess,
} = defineAPIPostAction<CompleteOnboardingSubStepBody, UpdateOnboardingStepsResponse>(
  ACTION.COMPLETE_ONBOARDING_SUB_STEP,
  'onboarding',
  'complete_sub_step',
  'POST',
);

export const setTestimonialIndex = createAction<number>(ACTION.SET_TESTIMONIAL_INDEX);
