import { FC } from 'react';
import cx from 'classnames';

import { IconButton, Spinner, sprinkles } from 'components/ds';
import * as styles from './paginator.css';

export type PaginatorProps = {
  totalRowCount: number | undefined;
  currentPage: number;
  goToPage: ({ page, offset }: { page: number; offset: number }) => void;
  rowsPerPage?: number;
  isPagingDisabled?: boolean;
  minimal?: boolean;
  loading: boolean;
};

type Props = { currentRowCount: number } & PaginatorProps;

export const DEFAULT_ROWS_PER_PAGE = 50;

export const DataGridPaginator: FC<Props> = ({
  loading,
  totalRowCount,
  currentRowCount,
  currentPage,
  goToPage,
  rowsPerPage = DEFAULT_ROWS_PER_PAGE,
  isPagingDisabled,
  minimal,
}) => {
  const currentFirstRow = currentPage * rowsPerPage - (rowsPerPage - 1);
  const rowCount = totalRowCount ?? 0;
  const maxPage = Math.ceil((rowCount ?? 0) / rowsPerPage);
  const offset = currentPage * rowsPerPage;

  const minimalRowCountText = `${currentFirstRow}-${
    currentFirstRow + currentRowCount - 1
  } of ${rowCount}`;
  const fullRowCountText = `Showing ${minimalRowCountText} row${rowCount > 1 ? 's' : ''}`;

  const rowCountText = minimal ? minimalRowCountText : fullRowCountText;

  return (
    <div
      className={cx(styles.paginatorContainer, sprinkles({ paddingX: minimal ? 'sp1' : 'sp3' }))}>
      <div className={styles.paginatorRowCount}>
        {loading ? (
          <>
            <Spinner className={sprinkles({ marginRight: 'sp1' })} size="md" />
            Loading
          </>
        ) : currentRowCount === 0 ? (
          'Showing 0 rows'
        ) : (
          rowCountText
        )}
      </div>
      {!isPagingDisabled ? (
        <div className={styles.pageSelector}>
          <IconButton
            disabled={currentPage === 1}
            name="angle-left"
            onClick={() => goToPage({ page: currentPage - 1, offset })}
          />
          {/* Add row input */}
          <span className={sprinkles({ marginX: 'sp1' })}>{`Page ${
            maxPage === 0 ? 0 : currentPage
          } of ${maxPage}`}</span>
          <IconButton
            disabled={currentPage >= maxPage}
            name="angle-right"
            onClick={() => goToPage({ page: currentPage + 1, offset })}
          />
        </div>
      ) : (
        <div>This data source does not support pagination.</div>
      )}
    </div>
  );
};
