import { createReducer } from '@reduxjs/toolkit';
import {
  createEndUserSuccess,
  deleteEndUserSuccess,
  editEndUserSuccess,
  fetchEndUserListSuccess,
} from 'actions/endUserActions';
import { EndUser } from 'actions/teamActions';

interface EndUserReducerState {
  endUsers: EndUser[];
}

const endUserReducerInitialState: EndUserReducerState = {
  endUsers: [],
};

export default createReducer(endUserReducerInitialState, (builder) => {
  builder
    .addCase(fetchEndUserListSuccess, (state, { payload }) => {
      state.endUsers = payload.end_users;
    })
    .addCase(createEndUserSuccess, (state, { payload }) => {
      state.endUsers.push(payload.new_end_user);
    })
    .addCase(deleteEndUserSuccess, (state, { payload }) => {
      state.endUsers = state.endUsers.filter((endUser) => endUser.id !== payload.id);
    })
    .addCase(editEndUserSuccess, (state, { payload }) => {
      const user = state.endUsers.find((endUser) => endUser.id === payload.id);
      if (user) {
        user.email = payload.postData.email;
        user.permissioned_dashboard_id = payload.postData.permissioned_dashboard_id as number;
      }
    })
    .addDefaultCase((state) => state);
});
