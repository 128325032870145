import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { BOOLEAN, NUMBER_TYPES, TIME_COLUMN_TYPES } from 'constants/dataConstants';
import { DatasetSchema } from 'types/datasets';

export const getReportBuilderSchema = (
  schema: DatasetSchema | undefined,
  dataset: ReportBuilderDataset,
  forPreview?: boolean,
): DatasetSchema => {
  if (!schema) return [];

  const changedSchema: DatasetSchema = [];
  schema.forEach((col) => {
    const columnConfig = dataset.columnConfigs[col.name];

    if (!columnConfig) {
      // Show columns not in column option in case query hasn't been saved yet
      if (forPreview) changedSchema.push({ ...col });
    } else if (columnConfig.isVisible) {
      changedSchema.push({ ...col, friendly_name: columnConfig.name });
    }
  });
  return changedSchema;
};

export const getColTypeDisplay = (type: string) => {
  if (NUMBER_TYPES.has(type)) return 'number';
  if (TIME_COLUMN_TYPES.has(type)) return 'date';
  if (type === BOOLEAN) return 'boolean';
  return 'string';
};
