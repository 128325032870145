import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { ReportBuilderWrapper } from 'pages/ReportBuilder';
import { setEmbedView } from 'reportBuilderContent/reducers/embeddedReportBuilderReducer';

type MatchParams = {
  reportBuilderId: string;
  customerToken: string;
  environment?: string;
};

export const IframeReportBuilderPage: FC = () => {
  const dispatch = useDispatch();

  const { params } = useRouteMatch<MatchParams>();

  useEffect(() => {
    dispatch(setEmbedView('iframe'));
  }, [dispatch]);

  return (
    <ReportBuilderWrapper
      customerToken={params.customerToken}
      environment={params.environment}
      reportBuilderEmbedId={params.reportBuilderId}
    />
  );
};
