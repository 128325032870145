import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';

import ColorPicker from 'components/ColorPicker';
import { mixColors } from 'utils/general';

const useStyles = makeStyles((theme: Theme) => ({
  coloredDiv: {
    borderLeft: `1px solid ${theme.palette.ds.grey300}`,
    borderRight: `1px solid ${theme.palette.ds.grey300}`,
    height: 4,
    width: 32,
  },
  colorPicker: {
    '& .bp3-popover-target': {
      display: 'block',
      height: 34,
      width: 32,
    },
  },
  colorPickerButton: {
    '&.bp3-button': {
      border: 'none',
    },
  },
  linearTopColorPicker: {
    '&.bp3-button': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      border: `1px solid ${theme.palette.ds.grey300}`,
      borderBottom: 'none',
      height: 35,
    },
  },
  linearCenterColorPicker: {
    '&.bp3-button': {
      borderRadius: 0,
      borderLeft: `1px solid ${theme.palette.ds.grey300}`,
      borderRight: `1px solid ${theme.palette.ds.grey300}`,
      height: 35,
    },
  },
  linearBottomColorPicker: {
    '&.bp3-button': {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      border: `1px solid ${theme.palette.ds.grey300}`,
      borderTop: 'none',
      height: 35,
    },
  },
}));

type Props = {
  className?: string;
  /**
   * A list of either 2 or 3 hues
   */
  hues: string[];
  /**
   * A list of either 2 or 3 callbacks fired on hue change
   */
  onHuesChanged: ((newHue: string) => void)[];
};

export default function GradientColorPicker({ className, hues, onHuesChanged }: Props) {
  const classes = useStyles();

  const color1 = hues[0];
  const color2 = hues[1];

  if (hues.length === 2) {
    return (
      <div className={className}>
        <ColorPicker
          fill
          buttonClassName={cx(classes.colorPickerButton, classes.linearTopColorPicker)}
          className={classes.colorPicker}
          color={hues[0]}
          label=""
          onClose={onHuesChanged[0]}
        />
        <div
          className={classes.coloredDiv}
          style={{ backgroundColor: mixColors(color1, color2, 0.66).rgb().toString() }}
        />
        <div
          className={classes.coloredDiv}
          style={{ backgroundColor: mixColors(color1, color2, 0.33).rgb().toString() }}
        />
        <ColorPicker
          fill
          buttonClassName={cx(classes.colorPickerButton, classes.linearBottomColorPicker)}
          className={classes.colorPicker}
          color={hues[1]}
          label=""
          onClose={onHuesChanged[1]}
        />
      </div>
    );
  }

  const color3 = hues[2];

  return (
    <div className={className}>
      <ColorPicker
        fill
        buttonClassName={cx(classes.colorPickerButton, classes.linearTopColorPicker)}
        className={classes.colorPicker}
        color={hues[0]}
        label=""
        onClose={onHuesChanged[0]}
      />
      <div
        className={classes.coloredDiv}
        style={{ backgroundColor: mixColors(color1, color2, 0.66).rgb().toString() }}
      />
      <div
        className={classes.coloredDiv}
        style={{ backgroundColor: mixColors(color1, color2, 0.33).rgb().toString() }}
      />
      <ColorPicker
        fill
        buttonClassName={cx(classes.colorPickerButton, classes.linearCenterColorPicker)}
        className={classes.colorPicker}
        color={hues[1]}
        label=""
        onClose={onHuesChanged[1]}
      />
      <div
        className={classes.coloredDiv}
        style={{ backgroundColor: mixColors(color2, color3, 0.66).rgb().toString() }}
      />
      <div
        className={classes.coloredDiv}
        style={{ backgroundColor: mixColors(color2, color3, 0.33).rgb().toString() }}
      />
      <ColorPicker
        fill
        buttonClassName={cx(classes.colorPickerButton, classes.linearBottomColorPicker)}
        className={classes.colorPicker}
        color={hues[2]}
        label=""
        onClose={onHuesChanged[2]}
      />
    </div>
  );
}
