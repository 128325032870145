import { useState } from 'react';
import cx from 'classnames';
import * as styles from './index.css';
import * as configStyles from 'components/FilterConfigs/styles.css';
import { partition } from 'utils/standard';

import { SortableList, SortableListItem } from 'components/SortableList/SortableList';
import { Switch } from 'components/ds';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import ColorPicker from 'pages/GlobalCustomStylesPage/CustomStylesColorPicker';
import InputLabel from 'shared/InputLabel';
import NavTabs from 'components/core/navTabs';
import { DraggableHeaderElemOption } from './DraggableHeaderElemOption';
import { HeaderElemOptionSimple } from './HeaderElemOptionSimple';
import { Button, sprinkles } from 'components/ds';

import { DahsboardStickyHeaderConfig, DashboardHeaderLocation } from 'types/dashboardVersionConfig';
import SettingHeader from 'pages/dashboardPage/DataPanelConfigV2/DataConfigTab/SettingHeader';
import { DashboardElement, DASHBOARD_LAYOUT_CONFIG } from 'types/dashboardTypes';
import {
  DASHBOARD_ELEMENT_TYPE_TO_NAME,
  ELEM_TYPE_TO_ICON,
  STICKY_ELEMENTS,
} from 'constants/dashboardConstants';

enum HeaderTab {
  SETUP = 'Setup',
  STYLES = 'Styles',
}

const Tabs = Object.values(HeaderTab).map((tab) => ({ id: tab, name: tab }));

type Props = {
  config?: DahsboardStickyHeaderConfig;
  dashboardElements: DashboardElement[];
  onCloseConfig: () => void;
  updateConfig: (config: DahsboardStickyHeaderConfig) => void;
  updateElemLocation: (elementId: string, newLocation: DASHBOARD_LAYOUT_CONFIG) => void;
  updateElemOrder: (newOrder: string[]) => void;
};

export const PageHeaderConfig = ({
  config,
  dashboardElements,
  onCloseConfig,
  updateConfig,
  updateElemLocation,
  updateElemOrder,
}: Props) => {
  const [tabId, setTabId] = useState(HeaderTab.SETUP);

  const [headerElems, bodyElems] = partition(
    dashboardElements.filter((elem) => STICKY_ELEMENTS.has(elem.element_type)),
    (elem) => elem.elemLocation === DASHBOARD_LAYOUT_CONFIG.HEADER,
  );
  const isBelowHeader = config?.filterLocations === DashboardHeaderLocation.BELOW_HEADER;

  const disabledStateCTA = () => (
    <div
      className={sprinkles({
        flexItems: 'center',
        flexDirection: 'column',
        parentContainer: 'fill',
      })}>
      <div className={sprinkles({ heading: 'h2', textAlign: 'center', marginBottom: 'sp2' })}>
        Add a header to the top of your dashboard
      </div>
      <div className={sprinkles({ marginBottom: 'sp1' })}>
        <Button onClick={() => updateConfig({ enabled: true })} type="primary">
          Turn on Dashboard Header
        </Button>
      </div>

      <div>
        <Button onClick={() => onCloseConfig()} type="tertiary">
          Cancel
        </Button>
      </div>
    </div>
  );

  const sortedSelectedColumns = headerElems.sort((elem1, elem2) =>
    config?.headerContentOrder
      ? config.headerContentOrder.indexOf(elem1.id) - config.headerContentOrder.indexOf(elem2.id)
      : 0,
  );

  const SetupTab = () => (
    <>
      <SettingHeader
        name="Title"
        rightContent={
          <Switch
            useCustomStyles
            onChange={() => updateConfig({ headerDisabled: !config?.headerDisabled })}
            switchOn={!config?.headerDisabled}
          />
        }
      />
      {config?.headerDisabled ? null : (
        <div className={sprinkles({ padding: 'sp1.5' })}>
          <InputWithBlurSave
            hideRightIconInteractions
            initialValue={config?.headerName || 'Dashboard'}
            onNewValueSubmitted={(newValue) => updateConfig({ headerName: newValue })}
          />
        </div>
      )}
      <SettingHeader name="Included in Header" />
      <div className={sprinkles({ padding: 'sp1.5' })}>
        <SortableList
          getIdFromElem={(item) => item.id}
          onListUpdated={(newList) => updateElemOrder(newList.map((elem) => elem.id))}
          sortableItems={sortedSelectedColumns}>
          {sortedSelectedColumns.map((elem) => (
            <SortableListItem key={elem.id} sortId={elem.id}>
              <DraggableHeaderElemOption
                actionIcon="cross"
                elemId={elem.id}
                elemType={elem.element_type}
                key={`filter-header-${elem.id}`}
                name={elem.name}
                onActionClicked={() =>
                  updateElemLocation(elem.id, DASHBOARD_LAYOUT_CONFIG.DASHBOARD_BODY)
                }
              />
            </SortableListItem>
          ))}
        </SortableList>
      </div>
      <SettingHeader name="Excluded from Header" />
      <div className={sprinkles({ padding: 'sp1.5' })}>
        {bodyElems.map((elem) => (
          <HeaderElemOptionSimple
            actionIcon="plus"
            elemId={elem.id}
            icon={ELEM_TYPE_TO_ICON[elem.element_type]}
            key={`filter-header-${elem.id}`}
            name={elem.name}
            onActionClicked={() => updateElemLocation(elem.id, DASHBOARD_LAYOUT_CONFIG.HEADER)}
            subName={DASHBOARD_ELEMENT_TYPE_TO_NAME[elem.element_type]}
          />
        ))}
      </div>
    </>
  );

  const StyleTab = () => (
    <>
      <SettingHeader name="Container" />
      <div className={sprinkles({ padding: 'sp1.5' })}>
        <InputLabel text="Background Color" />
        <ColorPicker
          fill
          color={config?.backgroundColor || '#FFFFFF'}
          onClose={(newColor) => updateConfig({ backgroundColor: newColor })}
        />
        <Switch
          useCustomStyles
          className={sprinkles({ marginTop: 'sp1.5' })}
          label="Bottom Border"
          onChange={() => updateConfig({ disableBottomBorder: !config?.disableBottomBorder })}
          switchOn={!config?.disableBottomBorder}
        />
        <Switch
          useCustomStyles
          className={sprinkles({ marginTop: 'sp1.5' })}
          label="Bottom Shadow"
          onChange={() => updateConfig({ disableBottomShadow: !config?.disableBottomShadow })}
          switchOn={!config?.disableBottomShadow}
        />
      </div>
      <SettingHeader name="Header Text" />
      <div className={sprinkles({ padding: 'sp1.5' })}>
        <InputLabel text="Color" />
        <ColorPicker
          fill
          color={config?.headerTextColor || '#182026'}
          onClose={(newColor) => updateConfig({ headerTextColor: newColor })}
        />
        <InputWithBlurSave
          hideRightIconInteractions
          containerClassName={sprinkles({ marginTop: 'sp1.5' })}
          initialValue={`${config?.headerTextSize || 24}px`}
          label="Font Size"
          onNewValueSubmitted={(newTextSizeString) => {
            const newTextSize = Number(newTextSizeString.replace('px', ''));

            if (isNaN(newTextSize) || newTextSize < 1) return;

            updateConfig({ headerTextSize: newTextSize });
          }}
        />
      </div>
      <SettingHeader name="Filters" />
      <div className={sprinkles({ padding: 'sp1.5' })}>
        <Switch
          useCustomStyles
          className={sprinkles({ marginBottom: 'sp1.5' })}
          label="Use Expandable Filter Row"
          onChange={() =>
            updateConfig({ enabledExpandableFilterRow: !config?.enabledExpandableFilterRow })
          }
          switchOn={config?.enabledExpandableFilterRow}
        />
        <div className={sprinkles({ body: 'b2', marginBottom: 'sp1', color: 'gray12' })}>
          Location
        </div>

        <div className={configStyles.optionRow}>
          <div
            className={cx(configStyles.option, {
              [configStyles.selectedOption]: isBelowHeader,
            })}
            onClick={() => updateConfig({ filterLocations: DashboardHeaderLocation.BELOW_HEADER })}>
            {config?.headerDisabled || config?.enabledExpandableFilterRow
              ? 'Left Aligned'
              : 'Below Header'}
          </div>
          <div
            className={cx(configStyles.option, {
              [configStyles.selectedOption]: !config?.filterLocations || !isBelowHeader,
            })}
            onClick={() =>
              updateConfig({ filterLocations: DashboardHeaderLocation.RIGHT_ALIGNED })
            }>
            Right Aligned
          </div>
        </div>
        <Switch
          useCustomStyles
          className={sprinkles({ marginTop: 'sp1.5' })}
          label="Stretch Filters to Available Space"
          onChange={() => updateConfig({ enableStretchFilters: !config?.enableStretchFilters })}
          switchOn={config?.enableStretchFilters}
        />
      </div>
    </>
  );

  const enabledState = () => (
    <>
      <NavTabs
        className={styles.headerTabContainer}
        onTabSelect={(tabId) => setTabId(tabId as HeaderTab)}
        selectedTabId={tabId}
        tabs={Tabs}
      />
      <div className={styles.headerConfigContainer}>
        {tabId === HeaderTab.SETUP ? SetupTab() : StyleTab()}
      </div>
    </>
  );

  return (
    <>
      <SettingHeader
        name="Dashboard Header"
        rightContent={
          <Switch
            useCustomStyles
            onChange={() => updateConfig({ enabled: !config?.enabled })}
            switchOn={!!config?.enabled}
          />
        }
      />
      {config?.enabled ? enabledState() : disabledStateCTA()}
    </>
  );
};
