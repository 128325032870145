import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { ReduxState } from 'reducers/rootReducer';
import { fetchCustomFonts } from 'actions/customFontsActions';
import SidePane from 'components/SidePane';
import { GlobalStyleConfig } from 'globalStyles/types';
import Button from 'shared/Button';
import BaseConfigSection from './BaseConfigSection';
import CardsConfigSection from './CardsConfigSection';
import TextConfigSection from './TextConfigSection';
import VisualizationsConfigSection from './VisualizationsConfigSection';
import FlexBox from 'components/core/FlexBox';
import { WIDE_SIDE_PANE_WIDTH } from 'components/SidePane';
import { SelectedDropdownInputItem } from 'constants/types';
import { getFontFamilyName } from 'globalStyles/helpers';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ACTION } from 'actions/types';
import { isCustomFontsDisabledForPlan } from 'utils/paymentPlanUtils';

const useStyles = makeStyles((theme: Theme) => ({
  paneContent: {
    padding: `0 ${theme.spacing(6)}px`,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(10),
    overflowY: 'auto',
  },
  sectionSpacing: {
    marginTop: theme.spacing(10),
  },
  buttonSection: {
    borderTop: `1px solid ${theme.palette.ds.grey200}`,
    padding: `0 ${theme.spacing(6)}px`,
    backgroundColor: theme.palette.ds.white,
    width: WIDE_SIDE_PANE_WIDTH,
    borderBottomLeftRadius: 8,
  },
  button: {
    margin: `${theme.spacing(4)}px 0`,
    flex: 1,
  },
  cancelButton: {
    marginRight: theme.spacing(4),
  },
}));

type Props = {
  styleConfig: GlobalStyleConfig;
  updateConfig: (newConfig: GlobalStyleConfig) => void;
  saveConfig: () => void;
  resetConfig: () => void;
  googleFonts?: string[];
};

export default function CustomizeStyleConfigPane({
  styleConfig,
  updateConfig,
  saveConfig,
  resetConfig,
  googleFonts,
}: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { customFonts, stylesLoading, teamPlan } = useSelector(
    (state: ReduxState) => ({
      customFonts: state.customFonts.customFonts,
      stylesLoading: createLoadingSelector([ACTION.SAVE_GLOBAL_STYLES], false)(state),
      teamPlan: state.teamData.data?.payment_plan,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (!customFonts) dispatch(fetchCustomFonts());
  }, [customFonts, dispatch]);

  let customFontOptions: SelectedDropdownInputItem[] = [];
  if (customFonts && !isCustomFontsDisabledForPlan(teamPlan)) {
    customFontOptions = customFonts.map((font) => ({
      id: font,
      name: getFontFamilyName(font),
    }));
  }
  if (customFontOptions.length && googleFonts) {
    customFontOptions.push({ id: '', name: '', isDivider: true });
  }
  const googleOptions = googleFonts?.map((font) => ({ id: font, name: font })) ?? [];
  const fontOptions = customFontOptions.concat(googleOptions);

  return (
    <SidePane wide title="Styling">
      <div className={classes.paneContent}>
        <BaseConfigSection styleConfig={styleConfig} updateConfig={updateConfig} />
        <div className={classes.sectionSpacing}>
          <CardsConfigSection styleConfig={styleConfig} updateConfig={updateConfig} />
        </div>
        <div className={classes.sectionSpacing}>
          <TextConfigSection
            fontOptions={fontOptions}
            styleConfig={styleConfig}
            updateConfig={updateConfig}
          />
        </div>
        <div className={classes.sectionSpacing}>
          <VisualizationsConfigSection styleConfig={styleConfig} updateConfig={updateConfig} />
        </div>
        <div className={classes.sectionSpacing}></div>
      </div>
      <FlexBox className={classes.buttonSection}>
        <Button
          className={cx(classes.button, classes.cancelButton)}
          onClick={resetConfig}
          text="Reset"
          type="secondary"
        />
        <Button
          className={classes.button}
          loading={stylesLoading}
          onClick={saveConfig}
          text="Save"
          type="primary"
        />
      </FlexBox>
    </SidePane>
  );
}
