import { FC, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import cx from 'classnames';

import { sprinkles } from 'components/ds';
import { isIdle, isSuccess } from 'remotedata';
import { ReduxState } from 'reducers/rootReducer';
import CanvasHeader from './CanvasHeader';
import ExploLoadingSpinner from 'components/ExploLoadingSpinner';
import FilterEditor from './FilterEditor';
import TemplateEditor from './TemplateEditor';
import { LayoutEditor } from './LayoutEditor';
import DatasetEditor from './DatasetEditor';
import { DashboardViewer } from './DashboardViewer';

import { fetchCanvasVersions } from 'actions/canvasVersionActions';
import { Canvas, clearCanvasEdit, fetchCanvases } from 'actions/canvasActions';
import { getCurrentCanvas } from 'reducers/canvasReducer';
import { CanvasVersionConfig } from 'actions/canvasConfigActions';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { GlobalStylesProvider } from 'globalStyles';
import { VIEW_MODE } from './types';
import { loadFonts } from 'globalStyles/helpers';
import { getDefaultVariables } from 'utils/canvasConfigUtils';
import { canUserModifyResource } from 'utils/permissionUtils';
import { PERMISSIONED_ENTITIES } from 'constants/roleConstants';
import { useLoadEditMetadata } from 'utils/hookUtils';

const containerStyles = sprinkles({
  height: 'fill',
  position: 'relative',
  overflow: 'hidden',
  widthConstraints: 'minOnly',
  flexItems: 'column',
});

const rootStyles = sprinkles({ display: 'flex', overflowX: 'auto', parentContainer: 'fill' });

const pageStyles = sprinkles({ display: 'flex', flex: 1, height: 'fill', overflow: 'hidden' });

type Props = { globalStylesClassName: string };

const BlueprintPage: FC<Props> = ({ globalStylesClassName }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { blueprintId, view } = useParams<{ blueprintId: string; view: VIEW_MODE }>();
  const [variables, setVariables] = useState<DashboardVariableMap>({});
  const [defaultVariablesLoaded, setDefaultVariablesLoaded] = useState(false);

  const metadataLoading = useLoadEditMetadata();

  const { currentCanvas, canvasesIdle, canvasConfig, versionNumber, permissions } = useSelector(
    (state: ReduxState) => ({
      currentCanvas: getCurrentCanvas(state.canvas),
      canvasesIdle: isIdle(state.canvas.canvases),
      canvasConfig: state.canvasEdit.config,
      versionNumber: state.canvasEdit.versionInfo?.version_number,
      permissions: state.currentUser.permissions,
    }),
    shallowEqual,
  );

  const userCanModifyCanvas = canUserModifyResource(permissions[PERMISSIONED_ENTITIES.ARCHITECT]);

  if (!userCanModifyCanvas && view !== VIEW_MODE.END_USER_DASHBOARDS) {
    history.replace(`/blueprint/${blueprintId}/user-dashboards`);
  }

  useEffect(() => {
    if (!defaultVariablesLoaded && isSuccess(canvasConfig)) {
      setDefaultVariablesLoaded(true);
      setVariables(getDefaultVariables(Object.values(canvasConfig.data.filters)));
    }
  }, [canvasConfig, defaultVariablesLoaded]);

  useEffect(() => {
    return function clearEdit() {
      dispatch(clearCanvasEdit());
    };
  }, [dispatch]);

  useEffect(() => {
    const id = parseInt(blueprintId);

    if (!isNaN(id)) dispatch(fetchCanvasVersions({ id }));
  }, [dispatch, blueprintId]);

  useEffect(() => {
    if (canvasesIdle) dispatch(fetchCanvases());
  }, [dispatch, canvasesIdle]);

  const renderPage = (canvas: Canvas, version: number, config: CanvasVersionConfig) => {
    switch (view) {
      case VIEW_MODE.TEMPLATES:
        return <TemplateEditor canvasId={canvas.id} config={config} variables={variables} />;
      case VIEW_MODE.FILTERS:
        return (
          <FilterEditor
            canvasId={canvas.id}
            config={config}
            setVariable={(variable, value) => setVariables({ ...variables, [variable]: value })}
            variables={variables}
          />
        );
      case VIEW_MODE.LAYOUT:
        return (
          <LayoutEditor
            canvas={canvas}
            config={config}
            setVariables={(variables) => setVariables(variables)}
            versionNumber={version}
          />
        );
      case VIEW_MODE.END_USER_DASHBOARDS:
        return <DashboardViewer canvas={canvas} config={config} />;
      default:
        return <DatasetEditor canvasId={canvas.id} config={config} />;
    }
  };

  const renderCurrentCanvas = () => {
    if (!currentCanvas || !isSuccess(canvasConfig) || metadataLoading || !versionNumber)
      return <ExploLoadingSpinner />;

    return (
      <div className={containerStyles}>
        <CanvasHeader canvas={currentCanvas} />
        <div className={pageStyles}>
          {renderPage(currentCanvas, versionNumber, canvasConfig.data)}
        </div>
      </div>
    );
  };

  return <div className={cx(rootStyles, globalStylesClassName)}>{renderCurrentCanvas()}</div>;
};

export default function BlueprintPageWrapper(): JSX.Element {
  const globalStyleConfig = useSelector(
    (state: ReduxState) => state.dashboardStyles.globalStyleConfig,
  );
  const team = useSelector((state: ReduxState) => state.currentUser?.team);

  useEffect(() => {
    if (team) loadFonts(globalStyleConfig.text, team.id, team.payment_plan);
  }, [globalStyleConfig, team]);

  return (
    <GlobalStylesProvider globalStyleConfig={globalStyleConfig}>
      {(globalStylesClassName) => <BlueprintPage globalStylesClassName={globalStylesClassName} />}
    </GlobalStylesProvider>
  );
}
