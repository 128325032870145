import { FC } from 'react';
import { FallbackProps } from 'react-error-boundary';

import { Button, Icon, sprinkles } from 'components/ds';
import { DEFAULT_SUPPORT_EMAIL } from 'constants/emailConstants';

export const PageError: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <div
      className={sprinkles({
        flexItems: 'centerColumn',
        gap: 'sp2',
        textAlign: 'center',
        width: 'fill',
      })}>
      <Icon
        className={sprinkles({ color: 'contentSecondary' })}
        name="circle-exclamation-reg"
        size="lg"
      />
      <h1 className={sprinkles({ color: 'contentPrimary', heading: 'h3', margin: 'sp0' })}>
        Oops! Something went wrong.
      </h1>
      {error.message ? (
        <p className={sprinkles({ color: 'contentPrimary', body: 'b1', margin: 'sp0' })}>
          {error.message}
        </p>
      ) : null}
      <p className={sprinkles({ color: 'contentPrimary', body: 'b1', margin: 'sp0' })}>
        Please contact {DEFAULT_SUPPORT_EMAIL} if the problem persists.
      </p>
      <Button
        data-testid="page-error-reload"
        icon="arrows-rotate"
        onClick={() => {
          resetErrorBoundary();
          window.location.reload();
        }}
        type="tertiary">
        Reload
      </Button>
    </div>
  );
};
