import { useState, FC, ChangeEvent } from 'react';
import { InputGroup } from '@blueprintjs/core';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { sprinkles } from 'components/ds';

const useStyles = makeStyles({
  searchBarWrapper: {
    position: 'relative',
  },
  searchBar: {
    '& .bp3-input': {
      height: 32,
    },
  },
});

export type Props = {
  className?: string;
  softEdges?: boolean;
  /**
   * Change event handler
   */
  placeholder?: string;
  onChange: (searchText: string) => void;
};

const SearchBar: FC<Props> = ({ className, softEdges, placeholder, onChange }) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState<string>('');

  return (
    <div className={cx(classes.searchBarWrapper, className)}>
      <InputGroup
        className={cx(classes.searchBar, { [sprinkles({ borderRadius: 8 })]: softEdges })}
        leftIcon="search"
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setSearchText(event.currentTarget.value);
          onChange(event.currentTarget.value);
        }}
        placeholder={placeholder || 'Search'}
        round={!softEdges}
        value={searchText}
      />
    </div>
  );
};

export default SearchBar;
