import { keyBy, sortBy } from 'utils/standard';
import { createReducer } from '@reduxjs/toolkit';

import {
  fetchAllSchemaTablesSuccess,
  fetchUsedParentSchemasSuccess,
  syncParentSchemaSuccess,
} from 'actions/parentSchemaActions';
import { TableDataset, ParentSchema } from 'actions/dataSourceActions';

interface ParentSchemaReducerState {
  usedParentSchemas?: ParentSchema[];
  schemaTablesMap: { [schemaId: number]: { [datasetId: number]: TableDataset } };
}

const parentSchemaReducerInitialState: ParentSchemaReducerState = {
  schemaTablesMap: {},
};

export default createReducer(parentSchemaReducerInitialState, (builder) => {
  builder
    .addCase(fetchUsedParentSchemasSuccess, (state, { payload }) => {
      state.usedParentSchemas = sortBy(payload.parent_schemas, (schema) => schema.id);
    })
    .addCase(fetchAllSchemaTablesSuccess, (state, { payload }) => {
      state.schemaTablesMap = {};
      for (const key in payload.schema_tables) {
        state.schemaTablesMap[key] = keyBy(payload.schema_tables[key], 'id');
      }
    })
    .addCase(syncParentSchemaSuccess, (state, { payload }) => {
      state.usedParentSchemas = sortBy(payload.parent_schemas, (schema) => schema.id);
    })
    .addDefaultCase((state) => state);
});
