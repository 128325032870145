import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { embedFetchShareData } from 'actions/shareActions';
import EmbeddedDashboard, { GenericLoadingSpinner } from 'components/EmbeddedDashboard';
import { VIEW_MODE } from 'types/dashboardTypes';

type MatchParams = {
  shareId: string;
};

export default function EmailDashboardPage() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [customerToken, setCustomerToken] = useState<string>('');
  const [dashboardEmbedId, setDashboardEmbedId] = useState<string>('');
  const [versionNumber, setVersionNumber] = useState<number>();
  const dispatch = useDispatch();
  const {
    params: { shareId },
  } = useRouteMatch<MatchParams>();

  useEffect(() => {
    dispatch(
      embedFetchShareData({ id: shareId }, (shareData) => {
        setCustomerToken(shareData.customer_token);
        setDashboardEmbedId(shareData.dashboard_embed_id);
        setVersionNumber(shareData.version_number);
        setIsLoading(false);
      }),
    );
  }, [dispatch, shareId]);

  if (isLoading) {
    return <GenericLoadingSpinner embedType="email" />;
  }

  return (
    <EmbeddedDashboard
      customerToken={customerToken}
      dashboardEmbedId={dashboardEmbedId}
      embedType={'email'}
      versionNumber={versionNumber}
      viewMode={VIEW_MODE.EMAIL}
    />
  );
}
