import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { BaseModal } from './BaseModal';
import { EmbedText } from '../EmbedText';
import { Button, IconButton, sprinkles } from 'components/ds';

import { closeReportModal } from 'reportBuilderContent/reducers/reportEditingReducer';

import * as styles from './styles.css';

type Props = {
  reportName: string;
  onSubmit: (reportName: string) => void;
  allowSameName?: boolean;
};

export const BaseSaveModal: FC<Props> = ({ reportName, onSubmit, allowSameName }) => {
  const dispatch = useDispatch();
  const [newReportName, setNewReportName] = useState(reportName);

  useEffect(() => {
    if (reportName) setNewReportName(reportName);
  }, [reportName]);

  return (
    <BaseModal>
      <div className={styles.saveAsContainer}>
        <div className={styles.saveAsHeader}>
          <EmbedText heading="h2">Save as new report</EmbedText>
          <IconButton
            className={sprinkles({ cursor: 'pointer' })}
            name="cross"
            onClick={() => dispatch(closeReportModal())}
          />
        </div>
        <div className={sprinkles({ flexItems: 'column', gap: 'sp.5' })}>
          <EmbedText body="b3" color="contentPrimary">
            Report name
          </EmbedText>
          <input
            className={styles.reportNameInput}
            onChange={(e) => setNewReportName(e.target.value)}
            placeholder="Untitled Report"
            value={newReportName}
          />
        </div>
        <div className={styles.saveAsFooter}>
          <Button
            disabled={
              newReportName.trim() === '' || (!allowSameName && reportName === newReportName)
            }
            onClick={() => onSubmit(newReportName)}
            type="primary">
            Save a new report
          </Button>
        </div>
      </div>
    </BaseModal>
  );
};
