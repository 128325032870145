import { Dataset } from 'actions/datasetActions';
import { OnboardingStepData } from 'actions/userActions';

export const isDashboardDatasetSaved = (datasets: Dataset[]) => {
  const savedDataset = datasets.find((dataset) => dataset.schema && dataset.query);
  return savedDataset !== undefined;
};

export const isOnboardingComplete = (steps: OnboardingStepData[]) => {
  return steps.filter((step) => (step ? step.complete : false)).length === steps.length;
};

export const shouldCompleteOnboardingStep = (
  onboardingSteps: Record<number, OnboardingStepData>,
  stepName: string,
  subStepName: string,
) => {
  const steps = Object.values(onboardingSteps);
  const createDashboardStep = steps.find((step) => step?.slug === stepName);
  return (
    createDashboardStep &&
    !createDashboardStep.sub_steps.find(
      (subStep) => subStep.slug === subStepName && subStep.complete,
    )
  );
};
