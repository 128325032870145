import { makeStyles, Theme } from '@material-ui/core';
import { cloneDeep } from 'utils/standard';

import { DashboardDatasetView } from 'pages/dashboardPage/DashboardDatasetView';
import { CanvasDataset, CanvasCustomComponent, CanvasTemplate } from 'actions/canvasConfigActions';
import { DashboardVariableMap, PAGE_TYPE } from 'types/dashboardTypes';
import { isCanvasComponent } from 'utils/canvasConfigUtils';
import { DashboardIframeElement } from 'pages/dashboardPage/dashboardElement/DashboardIframeElement';

const useStyles = makeStyles((theme: Theme) => ({
  dataPanelWrapper: {
    backgroundColor: theme.palette.ds.white,
    height: '100%',
    width: '100%',
  },
}));

type Props = {
  datasets: Record<string, CanvasDataset>;
  template: CanvasTemplate | CanvasCustomComponent;
  variables: DashboardVariableMap;
};

export default function TemplateView({ template, variables, datasets }: Props): JSX.Element {
  const classes = useStyles();

  if (isCanvasComponent(template)) {
    return (
      <div className={classes.dataPanelWrapper}>
        <DashboardIframeElement config={template.info.config} variables={variables} />
      </div>
    );
  }

  return (
    <div className={classes.dataPanelWrapper}>
      <DashboardDatasetView
        editableDashboard
        ignoreRequiredFilters
        isViewOnly
        canDownloadDataPanel={false}
        // When using dates rows are being set so we have to deep clone, long term probably
        // want a better solution than this
        dataPanel={cloneDeep(template)}
        datasets={datasets}
        loading={template._loading === undefined ? true : template._loading}
        onAdHocOperationInstructionsUpdated={() => console.warn('Not supported')}
        onDownloadPanelPdf={() => console.warn('Not supported')}
        onDownloadPanelSpreadsheet={() => console.warn('Not supported')}
        pageType={PAGE_TYPE.SHARED}
        variables={variables}
      />
    </div>
  );
}
