import { FC } from 'react';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import { showErrorToast } from 'shared/sharedToasts';
import { sprinkles } from 'components/ds';
import * as sharedStyles from './index.css';
import { ConfigSection } from './ConfigSection';

const inputClass = sprinkles({ marginBottom: 'sp1.5' });

type ValueParams = {
  name?: string;
  description?: string;
  provided_id?: string;
};

type Props = {
  defaultIsOpen: boolean;
  initialId?: string;
  initialDescription: string;
  initialName: string;

  handleNewValueSubmitted: (value: ValueParams) => void;
};

export const MetadataInputs: FC<Props> = ({
  defaultIsOpen,
  initialId,
  initialDescription,
  initialName,

  handleNewValueSubmitted,
}) => {
  const sharedProps = {
    fillWidth: true,
    hideRightIconInteractions: true,
    className: inputClass,
    ignoreCustomStyles: true,
  };

  return (
    <ConfigSection defaultIsOpen={defaultIsOpen} title="Name + Description">
      <div className={sharedStyles.configSection}>
        <div className={sprinkles({ display: 'flex', gap: 'sp1.5' })}>
          <InputWithBlurSave
            {...sharedProps}
            helpText="This is the name your customers will see in the template selection modal"
            initialValue={initialName}
            label="Name"
            onNewValueSubmitted={(newName) => {
              const trimmedName = newName.trim();
              if (trimmedName === '') return showErrorToast('Name cannot be empty.');
              handleNewValueSubmitted({ name: trimmedName });
            }}
          />
          {initialId ? (
            <InputWithBlurSave
              {...sharedProps}
              helpText="This is the variable that you reference in your dataset queries"
              initialValue={initialId}
              label="Provided ID"
              onNewValueSubmitted={(newId) => {
                const trimmedId = newId.trim();
                if (trimmedId === '') return showErrorToast('Provided ID cannot be empty.');
                handleNewValueSubmitted({ provided_id: trimmedId });
              }}
            />
          ) : null}
        </div>

        <InputWithBlurSave
          {...sharedProps}
          helpText="This is the description your customers will see"
          initialValue={initialDescription}
          label="Description"
          onNewValueSubmitted={(newDescription) =>
            handleNewValueSubmitted({ description: newDescription })
          }
        />
      </div>
    </ConfigSection>
  );
};
