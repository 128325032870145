import { forwardRef, MouseEvent as ReactMouseEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import DatePicker from 'react-datepicker';
import { Button } from '@blueprintjs/core';

import InputLabel, { Props as LabelProps } from 'shared/InputLabel';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

import { getFormatForDatePicker, TIME_FORMATS } from 'utils/localizationUtils';
import { DateTime } from 'luxon';
import { getDatePickerDateFromISO, getLuxonDateFromDatePicker } from 'utils/timezoneUtils';
import { dateTimeFromISOString } from 'utils/dateUtils';

const useStyles = makeStyles({
  root: {
    position: 'relative',

    '& .react-datepicker-wrapper': {
      height: 32,
      width: '100%',
    },
  },
  popoverContainer: {
    zIndex: 3,

    '& .react-datepicker': {
      display: 'flex',
    },
  },
  datePicker: {
    textOverflow: 'ellipsis',
    padding: '0 10PX',
    borderWidth: 0,
    fontSize: 14,
    height: 32,
    width: '100%',
    borderRadius: 3,
    boxShadow: 'inset 0px 0px 0px 1px #c1cee0',

    '&.disabled': {
      backgroundColor: 'rgba(206, 217, 224, 0.5)',
    },
  },
  cancelButton: {
    '&.bp3-button': {
      position: 'absolute',
      bottom: 5,
      right: 3,
      height: 20,
      width: 20,
      minHeight: 0,
      minWidth: 0,
      padding: 0,
      marginRight: 2,
    },
  },
  datePickerContainer: {
    height: 32,
    position: 'relative',
  },
  datePickerBtnWithCancelBtn: {
    paddingRight: 25,
  },
});

export type Props = {
  className?: string;
  selectedValue?: DateTime;
  startDate?: DateTime;
  endDate?: DateTime;
  minDate?: DateTime;
  maxDate?: DateTime;
  onNewValueSelect: (date: DateTime | [DateTime, DateTime | undefined] | null) => void;
  showCancelBtn?: boolean;
  showTimeSelect?: boolean;
  onCancelClick?: () => void;
  disabled?: boolean;
  withPortal?: boolean;
  monthsShown?: number;
  selectsRange?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customInput?: (onClick: any, ref: any) => JSX.Element;
  onCalendarClose?: () => void;
  onCalendarOpen?: () => void;
  placeholder?: string;
  /**
   * If passed in, the input will be positioned as if it had a label (with spacing above it)
   * but no words will be visible
   */
  portalId?: string;
  openElementToLeft?: boolean;
  labelProps?: LabelProps;
};

/*
  Note: The react-datepicker takes in user inputs as local time, we convert to UTC
  to store the date, but the convert it back to local time to display so that it looks
  the same as they selected in the UI
*/

const DatePickerInput = (props: Props) => {
  const {
    className,
    labelProps,
    selectedValue,
    onNewValueSelect,
    showCancelBtn,
    onCancelClick,
    showTimeSelect,
    disabled,
    withPortal,
    endDate,
    selectsRange,
    monthsShown,
    customInput,
    onCalendarClose,
    onCalendarOpen,
    placeholder,
    portalId,
  } = props;
  const classes = useStyles(props);

  const CustomInputElement =
    // eslint-disable-next-line
    customInput && forwardRef(({ onClick }: any, ref) => customInput(onClick, ref));
  const maxDate =
    props.maxDate && typeof props.maxDate === 'string'
      ? dateTimeFromISOString(props.maxDate)
      : props.maxDate;
  const minDate =
    props.minDate && typeof props.minDate === 'string'
      ? dateTimeFromISOString(props.minDate)
      : props.minDate;

  return (
    <div className={cx(classes.root, className)}>
      {labelProps ? <InputLabel {...labelProps} /> : null}
      <div className={classes.datePickerContainer}>
        <DatePicker
          disabledKeyboardNavigation
          className={cx(
            classes.datePicker,
            { [classes.datePickerBtnWithCancelBtn]: showCancelBtn, disabled },
            GLOBAL_STYLE_CLASSNAMES.container.outline.boxShadow,
            GLOBAL_STYLE_CLASSNAMES.container.shadow.dropShadow,
            GLOBAL_STYLE_CLASSNAMES.base.actionColor.interactionStates.datePickerInputBorderHover,
            GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.datePickerInputBorderActive,
            GLOBAL_STYLE_CLASSNAMES.text.body.primary,
            GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.inputFields.defaultBorderRadius,
          )}
          customInput={CustomInputElement ? <CustomInputElement /> : null}
          dateFormat={
            showTimeSelect
              ? getFormatForDatePicker(TIME_FORMATS['MM/DD/YYYY h:mm aa'])
              : getFormatForDatePicker(TIME_FORMATS['MM/DD/YYYY'])
          }
          disabled={disabled}
          endDate={endDate ? getDatePickerDateFromISO(endDate.toISO()) : null}
          maxDate={maxDate?.toJSDate()}
          minDate={minDate?.toJSDate()}
          monthsShown={monthsShown}
          onCalendarClose={onCalendarClose}
          onCalendarOpen={onCalendarOpen}
          onChange={(input) => {
            if (selectsRange) {
              const [datejs1, datejs2] = input as [Date, Date];

              let date1 = getLuxonDateFromDatePicker(datejs1);
              let date2 = datejs2 ? getLuxonDateFromDatePicker(datejs2) : undefined;

              if (!showTimeSelect) {
                date1 = date1 ? date1.startOf('day') : date1;
                date2 = date2 ? date2.startOf('day') : date2;
              }

              onNewValueSelect([date1, date2]);
            } else {
              let date = getLuxonDateFromDatePicker(input as Date);
              // The user may have selected a time, but then toggled off
              // show time select. In this case, we have to clear the
              // time originally set by the user, and default to 0 local time
              if (date && !showTimeSelect) {
                date = date.startOf('day');
              }

              onNewValueSelect(date);
            }
          }}
          placeholderText={placeholder}
          popperClassName={cx(
            classes.popoverContainer,
            GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.datePickerDaySelected,
            GLOBAL_STYLE_CLASSNAMES.base.backgroundColor.datePickerHeaderBackgroundColor,
            GLOBAL_STYLE_CLASSNAMES.container.fill.datePickerMonthContainerFill,
            GLOBAL_STYLE_CLASSNAMES.container.fill.datePickerTimeContainerFill,
            GLOBAL_STYLE_CLASSNAMES.text.smallHeading.datePickerHeader,
            GLOBAL_STYLE_CLASSNAMES.text.secondaryColor.datePickerNavigationPreviousColor,
            GLOBAL_STYLE_CLASSNAMES.text.secondaryColor.datePickerNavigationNextColor,
            GLOBAL_STYLE_CLASSNAMES.text.smallBody.datePickerBody,
            GLOBAL_STYLE_CLASSNAMES.text.secondaryColor.datePickerYearDropdownIconColor,
            GLOBAL_STYLE_CLASSNAMES.text.secondaryColor.datePickerMonthDropdownIconColor,
          )}
          popperModifiers={{
            // This needs to be disabled otherwise the popper in containers won't work properly
            preventOverflow: {
              enabled: portalId === undefined,
            },
          }}
          popperPlacement={props.openElementToLeft ? 'bottom-end' : 'bottom-start'}
          portalId={portalId}
          selected={selectedValue ? getDatePickerDateFromISO(selectedValue.toISO()) : null}
          selectsRange={selectsRange}
          shouldCloseOnSelect={false}
          showMonthDropdown={!monthsShown || monthsShown < 2}
          showTimeSelect={showTimeSelect}
          showYearDropdown={!monthsShown || monthsShown < 2}
          startDate={selectedValue ? getDatePickerDateFromISO(selectedValue.toISO()) : null}
          withPortal={withPortal}
        />
        {selectedValue && showCancelBtn && (
          <Button
            minimal
            className={classes.cancelButton}
            disabled={disabled}
            icon="cross"
            onClick={(e: ReactMouseEvent<HTMLElement, MouseEvent>) => {
              e.stopPropagation();
              onCancelClick && onCancelClick();
            }}
          />
        )}
      </div>
    </div>
  );
};

export default DatePickerInput;
