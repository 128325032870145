import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { VIZ_OPS_WITH_CATEGORY_SELECT_DRILLDOWN } from 'constants/dataConstants';
import {
  FilterOperator,
  FILTER_OPS_DATE_PICKER,
  FILTER_OPS_NUMBER,
  FILTER_OPS_STRING,
} from 'types/filterOperations';
import { OPERATION_TYPES, SelectedDropdownInputItem } from 'constants/types';
import { DashboardVariableMap } from 'types/dashboardTypes';

export const findDrilldownValue = (
  variables: DashboardVariableMap,
  varId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any | undefined => {
  const value = (id: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return variables[id] as { category: any; color?: any };
  };

  if (varId.includes('.color')) {
    const id = varId.replace('.color', '');
    return value(id)?.color;
  } else {
    const id = varId.replace('.category', '');
    return value(id)?.category;
  }
};

export const isDrilldownVar = (filterVarId: string): boolean => {
  return filterVarId.includes('.category') || filterVarId.includes('.color');
};

export const filterDptsWithDrilldowns = (
  dataPanelTemplates: DataPanelTemplate[],
): DataPanelTemplate[] => {
  return dataPanelTemplates.filter((dpt) => {
    if (VIZ_OPS_WITH_CATEGORY_SELECT_DRILLDOWN.has(dpt.visualize_op.operation_type)) {
      return dpt.visualize_op.instructions.V2_TWO_DIMENSION_CHART?.drilldown?.categorySelectEnabled;
    } else if (dpt.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_TABLE) {
      return (
        dpt.visualize_op.instructions.VISUALIZE_TABLE.drilldownConfig?.drilldownEnabled &&
        dpt.visualize_op.instructions.VISUALIZE_TABLE.drilldownConfig?.drilldownColumn
      );
    }

    return false;
  });
};

export const filterDataPanelsForValidDrilldownVarsBasedOnType = (
  dataPanels: DataPanelTemplate[],
  filterOperator?: FilterOperator,
): SelectedDropdownInputItem[] => {
  let categoryDrilldowns: DataPanelTemplate[] = [];
  if (!filterOperator) return [];

  if (FILTER_OPS_DATE_PICKER.has(filterOperator)) {
    categoryDrilldowns = dataPanels.filter(
      (dpt) =>
        dpt.visualize_op.instructions.V2_TWO_DIMENSION_CHART?.categoryColumn?.column.type ===
        'DATE',
    );
  } else if (FILTER_OPS_STRING.has(filterOperator)) {
    categoryDrilldowns = dataPanels.filter(
      (dpt) =>
        dpt.visualize_op.instructions.V2_TWO_DIMENSION_CHART?.categoryColumn?.column.type ===
        'STRING',
    );
  } else if (FILTER_OPS_NUMBER.has(filterOperator)) {
    categoryDrilldowns = dataPanels.filter(
      (dpt) =>
        dpt.visualize_op.instructions.V2_TWO_DIMENSION_CHART?.categoryColumn?.column.type ===
          'INTEGER' ||
        dpt.visualize_op.instructions.V2_TWO_DIMENSION_CHART?.categoryColumn?.column.type ===
          'FLOAT',
    );
  }

  // for now, since color & table columns can have multiple different types, we just allow them to choose a DPT
  // with color if there are any color columns - the user has to make sure the types match up on their own
  const tableAndColorDrilldowns = dataPanels.reduce<SelectedDropdownInputItem[]>((acc, dp) => {
    if (dp.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_TABLE) {
      const drilldownConfig = dp.visualize_op.instructions.VISUALIZE_TABLE.drilldownConfig;
      if (drilldownConfig?.drilldownEnabled && drilldownConfig.drilldownColumn) {
        acc.push({ id: dp.provided_id, name: dp.provided_id });
      }
    } else if (dp.visualize_op.instructions.V2_TWO_DIMENSION_CHART?.colorColumnOptions?.length) {
      const drilldownVar = dp.provided_id + '.color';
      acc.push({ id: drilldownVar, name: drilldownVar });
    }
    return acc;
  }, []);

  const newCatDrilldowns = categoryDrilldowns.map((dataPanel) => {
    const drilldownVar = dataPanel.provided_id + '.category';
    return {
      id: drilldownVar,
      name: drilldownVar,
    };
  });
  return tableAndColorDrilldowns.concat(newCatDrilldowns);
};
