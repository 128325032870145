import { Fragment, useState, useEffect, useCallback } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import produce from 'immer';
import { v4 as uuidv4 } from 'uuid';

import { sortBy } from 'utils/standard';
import cx from 'classnames';
import { Icon } from '@blueprintjs/core';
import { Switch } from 'components/ds';
import { ACTION } from 'actions/types';

import FullPagePanel from 'components/pages/fullPagePanel';
import ExploLoadingSpinner from 'components/ExploLoadingSpinner';
import Button from 'shared/Button';
import CalloutLink from 'shared/CalloutLink';
import { NON_SUCCESS_ICON } from 'constants/iconConstants';

import { DataTable } from 'actions/datasetActions';
import { fetchAllDataSourceTables, syncSourceTables } from 'actions/dataSourceActions';
import { fetchUsedParentSchemas } from 'actions/parentSchemaActions';
import { createLoadingSelector } from 'reducers/api/selectors';
import { WIDGET_TYPES } from 'constants/hubspotConstants';
import { updateWidgetType } from 'actions/chatWidgetActions';

import { updatePageSpecificChatWidget } from 'utils/hubspotUtils';
import Poller from 'components/JobQueue/Poller';
import { bulkEnqueueJobs, JobDefinition } from 'actions/jobQueueActions';
import { Jobs } from 'components/JobQueue/types';
import { isJobQueueEnabledForEnvironment } from 'utils/environmentUtils';
import { sprinkles } from 'components/ds';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    padding: `60px 0`,
    overflowY: 'scroll',
  },
  tableList: {
    display: 'grid',
    gridTemplateColumns: '.8fr .2fr',
    rowGap: `${theme.spacing(3)}px`,
  },
  tableListHeaderContainer: {
    marginBottom: theme.spacing(5),
  },
  tableListHeader: {
    fontSize: 14,
    fontWeight: 500,
  },
  tableListTables: {
    maxHeight: '40vh',
    overflowY: 'auto',
  },
  tableListSyncCol: {
    textAlign: 'end',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: `${theme.spacing(2)}px`,
    marginTop: theme.spacing(9),
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(4),
    gap: `${theme.spacing(3)}px`,
  },
  errorCard: {
    background: theme.palette.ds.lightRed,
  },
  warningCard: {
    background: theme.palette.ds.grey100,
  },
  errorText: {
    color: theme.palette.ds.red,
  },
  warningText: {
    color: theme.palette.ds.grey900,
  },
  documentationBox: {
    marginTop: theme.spacing(2),
  },
}));

type MatchParams = {
  passedSchemaId: string;
};

export default function SyncDataTablesPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    params: { passedSchemaId },
  } = useRouteMatch<MatchParams>();

  const {
    allTables,
    fetchAllTablesLoading,
    fetchParentSchemaLoading,
    syncSourceTablesLoading,
    widget,
  } = useSelector(
    (state: ReduxState) => ({
      allTables: state.dataSource.allTables,
      fetchAllTablesLoading: createLoadingSelector(
        [ACTION.FETCH_ALL_DATA_SOURCE_TABLES],
        true,
      )(state),
      fetchParentSchemaLoading: createLoadingSelector(
        [ACTION.FETCH_USED_PARENT_SCHEMAS],
        false,
      )(state),
      syncSourceTablesLoading: createLoadingSelector([ACTION.SYNC_SOURCE_TABLES], false)(state),
      widget: state.widget,
    }),
    shallowEqual,
  );

  // the useEffect here should only run once
  const [effectHasRun, setEffectHasRun] = useState(false);
  const [tablesToIgnore, setTablesToIgnore] = useState<string[]>([]);
  const [schemaId, setSchemaId] = useState(passedSchemaId);
  const [awaitedJobs, setAwaitedJobs] = useState<Record<string, Jobs>>({});
  const [tableError, setTableError] = useState<string | undefined>(undefined);

  const bulkEnqueueJobsWrapper = useCallback(
    (jobs: JobDefinition[] | undefined) => {
      if (jobs === undefined || jobs.length === 0) return;

      const jobMap = Object.assign({}, ...jobs.map((job) => ({ [uuidv4()]: job })));

      dispatch(
        bulkEnqueueJobs?.({ jobs: jobMap }, (jobs) => {
          setAwaitedJobs(
            produce(awaitedJobs, (draft) => {
              return {
                ...draft,
                ...jobs,
              };
            }),
          );
        }),
      );
    },
    [dispatch, awaitedJobs],
  );

  const syncSourceTablesWrapper = () => {
    const postData = { ignore_table_names: tablesToIgnore };

    if (!isJobQueueEnabledForEnvironment())
      dispatch(syncSourceTables({ id: schemaId, postData }, () => history.goBack()));
    else
      bulkEnqueueJobsWrapper([
        {
          job_type: ACTION.SYNC_SOURCE_TABLES,
          job_args: { id: schemaId, ...postData },
          onSuccess: () => history.goBack(),
        },
      ]);
  };

  const fetchAllDataSourceTablesWrapper = useCallback(
    (schemaId: string) => {
      if (!isJobQueueEnabledForEnvironment())
        dispatch(
          fetchAllDataSourceTables({ id: schemaId }, undefined, (response) => {
            setTableError(response.error_msg);
          }),
        );
      else
        bulkEnqueueJobsWrapper([
          {
            job_type: ACTION.FETCH_ALL_DATA_SOURCE_TABLES,
            job_args: { id: schemaId },
            onError: setTableError,
          },
        ]);
    },
    [dispatch, bulkEnqueueJobsWrapper],
  );

  // this functions as componentDidMount
  useEffect(() => {
    if (effectHasRun) return;

    // if the conversation hasn't started, open the specific chat widget
    if (!widget.conversationStarted) {
      updatePageSpecificChatWidget(widget.isOpen);
      dispatch(updateWidgetType({ widgetType: WIDGET_TYPES.SYNC_TABLES }));
    }

    if (!passedSchemaId) {
      dispatch(
        fetchUsedParentSchemas({}, (result) => {
          const schemaId = result.parent_schemas[0].id;
          fetchAllDataSourceTablesWrapper(schemaId.toString());
          setSchemaId(String(schemaId));
        }),
      );
    } else {
      fetchAllDataSourceTablesWrapper(passedSchemaId);
    }

    setEffectHasRun(true);
  }, [
    dispatch,
    passedSchemaId,
    effectHasRun,
    widget,
    bulkEnqueueJobsWrapper,
    fetchAllDataSourceTablesWrapper,
  ]);

  const areAllSelected = tablesToIgnore.length === 0;

  const toggleAllSelected = () =>
    setTablesToIgnore(areAllSelected ? allTables.map((table: DataTable) => table.name) : []);

  const renderTableList = () => (
    <>
      <div className={cx(classes.tableList, classes.tableListHeaderContainer)}>
        <div className={classes.tableListHeader}>Table Name</div>
        <div
          className={cx(
            classes.tableListSyncCol,
            sprinkles({ fontWeight: 500, color: 'blue9', cursor: 'pointer' }),
          )}
          onClick={() => toggleAllSelected()}>
          {areAllSelected ? 'Unselect all' : 'Select all'}
        </div>
      </div>
      <div className={cx(classes.tableList, classes.tableListTables)}>
        {sortBy(allTables || [], (table) => table.name).map((table: DataTable) => {
          const isChecked = !tablesToIgnore.includes(table.name);
          return (
            <Fragment key={`table-name-${table.name}`}>
              <div className={sprinkles({ fontWeight: 400, color: 'gray11' })}>{table.name} </div>
              <div className={sprinkles({ display: 'flex', justifyContent: 'flex-end' })}>
                <Switch
                  onChange={() => {
                    if (isChecked) {
                      setTablesToIgnore([...tablesToIgnore, table.name]);
                    } else {
                      setTablesToIgnore(
                        tablesToIgnore.filter((tableName) => tableName !== table.name),
                      );
                    }
                  }}
                  switchOn={isChecked}
                />
              </div>
            </Fragment>
          );
        })}
      </div>
    </>
  );

  const renderNonSuccessState = (isError: boolean) => {
    // just sanity checking that the error isn't empty and displaying a generic error
    // message if it is
    const errorMessage =
      tableError !== ''
        ? tableError
        : 'Something went wrong and we were unable to connect to the data source.';

    return (
      <>
        <div
          className={cx(classes.cardContainer, isError ? classes.errorCard : classes.warningCard)}>
          <Icon icon={NON_SUCCESS_ICON(isError)} />
          <div className={isError ? classes.errorText : classes.warningText}>
            {isError
              ? errorMessage
              : 'We connected to your data source, but 0 tables were returned.'}
          </div>
        </div>
        <CalloutLink
          className={classes.documentationBox}
          text="Troubleshoot database connection issues"
          url="https://docs.explo.co/troubleshooting/database-connection-errors"
        />
      </>
    );
  };

  const renderSuccessActions = () => (
    <div className={classes.actionsContainer}>
      <Button
        fillWidth
        disabled={tablesToIgnore.length === allTables?.length}
        loading={syncSourceTablesLoading}
        onClick={syncSourceTablesWrapper}
        text="Continue"
        type="primary"
      />
    </div>
  );

  const renderNonSuccessActions = (isError: boolean) => (
    <div className={classes.actionsContainer}>
      <Button
        fillWidth
        onClick={() => fetchAllDataSourceTablesWrapper(schemaId)}
        text="Retry"
        type="primary"
      />
      <Button
        fillWidth
        onClick={isError ? history.goBack : syncSourceTablesWrapper}
        text={isError ? 'Skip for now' : 'Continue'}
        type="secondary"
      />
    </div>
  );

  const renderSnycPanel = () => {
    return (
      <div className={classes.root}>
        <FullPagePanel
          description="The tables selected will have their schemas (column names and types) saved to enhance the query writing experience. It is not required to sync tables to use Explo, but it is recommended."
          title="Select Tables to Sync">
          {renderPanelContent()}
        </FullPagePanel>
      </div>
    );
  };

  const renderPanelContent = () => {
    if (allTables.length > 0)
      return (
        <>
          {renderTableList()}
          {renderSuccessActions()}
        </>
      );
    else if (tableError === undefined) {
      return (
        <>
          {renderNonSuccessState(false)}
          {renderNonSuccessActions(false)}
        </>
      );
    } else
      return (
        <>
          {renderNonSuccessState(true)}
          {renderNonSuccessActions(true)}
        </>
      );
  };

  return (
    <>
      <Poller
        awaitedJobs={awaitedJobs}
        updateJobResult={(finishedJobIds, onComplete) => {
          if (finishedJobIds.length > 0)
            setAwaitedJobs((currentAwaitedJobs) => {
              const newAwaitedJobs = produce(currentAwaitedJobs, (draft) =>
                finishedJobIds.forEach((jobId) => delete draft[jobId]),
              );
              return newAwaitedJobs;
            });

          onComplete();
        }}
      />
      {fetchAllTablesLoading || fetchParentSchemaLoading ? (
        <ExploLoadingSpinner />
      ) : (
        renderSnycPanel()
      )}
    </>
  );
}
