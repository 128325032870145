import { createAction } from '@reduxjs/toolkit';

import { defineAPIAction, defineAPIPostAction } from './actionUtils';
import { ACTION } from './types';

import { DashboardTimezones } from 'constants/dashboardConstants';
import { LatestVersionInfo } from 'types/exploResource';
import { ArchitectCustomerDashboard } from './architectCustomerDashboardActions';

export interface Canvas extends EmbedCanvas {
  team_id: number;
  embed_id: string;
  latest_versions: LatestVersionInfo[];
}

export interface EmbedCanvas {
  id: number;
  name: string;
  default_timezone: DashboardTimezones | null;
  configurability: null | CanvasConfigurability;
}

export enum CanvasConfigureType {
  EDITABLE = 'editable',
  STYLING = 'styling',
  STATIC = 'static',
}

export enum CanvasViewType {
  MULTI = 'multi',
  SINGLE = 'single',
}

export interface CanvasConfigurability {
  type: CanvasConfigureType;
  allowEmails?: boolean;
  viewType?: CanvasViewType;
  enableResetDashboardChanges?: boolean;
}

export const DEFAULT_CANVAS_CONFIGURABILITY: CanvasConfigurability = {
  type: CanvasConfigureType.EDITABLE,
  allowEmails: false,
  viewType: CanvasViewType.MULTI,
};

type FetchCanvasListData = {
  canvas_list: Canvas[];
};

export const {
  actionFn: fetchCanvases,
  requestAction: fetchCanvasesRequest,
  errorAction: fetchCanvasesError,
  successAction: fetchCanvasesSuccess,
} = defineAPIAction<FetchCanvasListData>(
  ACTION.FETCH_CANVAS_LIST,
  'canvases',
  'get_canvas_list',
  'GET',
);

export const { actionFn: createCanvas, successAction: createCanvasSuccess } = defineAPIPostAction<
  { name: string },
  { canvas: Canvas }
>(ACTION.CREATE_CANVAS, 'canvases', 'create_canvas', 'POST');

export const { actionFn: cloneCanvas, successAction: cloneCanvasSuccess } = defineAPIAction<{
  new_canvas: Canvas;
}>(ACTION.CLONE_CANVAS, 'canvases', 'duplicate', 'POST');

export const { actionFn: deleteCanvas, successAction: deleteCanvasSuccess } = defineAPIAction(
  ACTION.DELETE_CANVAS,
  'canvases',
  '',
  'DELETE',
);

export const { actionFn: renameCanvas, successAction: renameCanvasSuccess } = defineAPIPostAction<
  { name: string },
  { name: string }
>(ACTION.RENAME_CANVAS, 'canvases', 'rename_canvas', 'POST');

export const {
  actionFn: updateCanvasConfigurability,
  successAction: updateCanvasConfigurabilitySuccess,
} = defineAPIPostAction<
  { canvas_id: number; configurability: CanvasConfigurability },
  { configurability: CanvasConfigurability }
>(ACTION.UPDATE_CANVAS_CONFIGURABILITY, 'canvases', 'update_configurability', 'POST');

export const {
  actionFn: fetchAllDashboardsForCanvas,
  requestAction: fetchAllDashboardsForCanvasRequest,
  errorAction: fetchAllDashboardsForCanvasError,
  successAction: fetchAllDashboardsForCanvasSuccess,
} = defineAPIPostAction<{ canvas_id: number }, { dashboards: ArchitectCustomerDashboard[] }>(
  ACTION.FETCH_END_USER_DASHBOARDS,
  'canvases',
  'get_dashboards',
  'POST',
);

export const { actionFn: getDashboardsUsingDataset } = defineAPIPostAction<
  { canvas_id: number; dataset_id: string },
  { dashboard_count: number }
>(ACTION.GET_DASHBOARDS_USING_DATASET, 'canvases', 'get_dashboards_using_dataset', 'POST');

export const selectArchitectCustomerDashboardToView = createAction<number | null>(
  ACTION.SELECT_END_USER_DASHBOARD,
);

export const clearCanvasEdit = createAction(ACTION.CLEAR_CANVAS_EDIT);

export const clearViewingArchitectCustomerDashboard = createAction(
  ACTION.CLEAR_VIEWING_END_USER_DASHBOARD,
);
