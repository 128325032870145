import { FC } from 'react';

import InfoCard from 'shared/InfoCard';
import { DatasetLink } from './DatasetLink';

import { DashboardElementConfig } from 'types/dashboardTypes';
import { DataPanel, ResourceDataset } from 'types/exploResource';

type Props = {
  config: DashboardElementConfig;
  datasets: Record<string, ResourceDataset>;
  dataPanels: Record<string, DataPanel>;
  elementName: string;

  updateConfig: (config: DashboardElementConfig) => void;
};

export const ChartLinkConfig: FC<Props> = ({
  config,
  dataPanels,
  datasets,
  elementName,
  updateConfig,
}) => {
  return (
    <>
      <InfoCard
        noTopMargin
        text="To link a chart, start by selecting a column from the dataset it uses."
      />
      {Object.values(datasets).map((dataset) => (
        <DatasetLink
          config={config}
          dataPanels={dataPanels}
          dataset={dataset}
          elementName={elementName}
          key={`${elementName}-${dataset.id}`}
          updateConfig={updateConfig}
        />
      ))}
    </>
  );
};
