import { isEmpty } from 'utils/standard';
import { DashboardVersionConfig } from 'types/dashboardVersionConfig';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { OPERATION_TYPES } from 'constants/types';
import { DataPanel } from 'types/exploResource';
import { dataPanelRequiresPrimaryData } from 'utils/dataPanelConfigUtils';

function resolveSecondaryData(dataPanel: DataPanel, secondaryDataResponse: DataPanel) {
  // For BoxPlots backed by a Redshift or MySQL DB, we need to fetch batches of metrics for different calc columns
  // one at a time. So we don't want to overwrite secondaryData, but append to it instead.
  if (
    (secondaryDataResponse._source_type === 'redshift' ||
      secondaryDataResponse._source_type === 'mysql') &&
    dataPanel.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2
  ) {
    return [...(dataPanel._secondaryData ?? []), ...(secondaryDataResponse._rows ?? [])];
  }

  return secondaryDataResponse._rows;
}

export function handleSecondaryDataRequest(dataPanel: DataPanel): void {
  dataPanel._outstandingSecondaryDataRequests =
    (dataPanel._outstandingSecondaryDataRequests ?? 0) + 1;
}

export function handleSecondaryDataSuccess(dataPanel: DataPanel, response: DataPanel): void {
  dataPanel._secondaryData = resolveSecondaryData(dataPanel, response);
  if (dataPanel._outstandingSecondaryDataRequests !== undefined) {
    dataPanel._outstandingSecondaryDataRequests = Math.max(
      dataPanel._outstandingSecondaryDataRequests - 1,
      0,
    );
  }
  // Some charts do not send off primary data requests causing _loading to never be updated
  // which causes issues with some of our loading states
  if (!dataPanelRequiresPrimaryData(dataPanel.visualize_op)) dataPanel._loading = false;
}

export function handleSecondaryDataError(dataPanel: DataPanel): void {
  if (dataPanel._outstandingSecondaryDataRequests !== undefined) {
    dataPanel._outstandingSecondaryDataRequests = Math.max(
      dataPanel._outstandingSecondaryDataRequests - 1,
      0,
    );
  }
  dataPanel._error = 'There was an error fetching the results';

  // Some charts do not send off primary data requests causing _loading to never be updated
  // which causes issues with some of our loading states
  if (!dataPanelRequiresPrimaryData(dataPanel.visualize_op)) dataPanel._loading = false;
}

export function clearEmptyPanels(configuration: DashboardVersionConfig): void {
  const currentPanels = configuration.data_panels;
  // PD-995: For some reason we're getting empty data panel templates sometimes, mitigate this by
  // checking for emptyness until we can figure out why
  configuration.data_panels = Object.keys(currentPanels).reduce<Record<string, DataPanelTemplate>>(
    (acc, dpId) => {
      if (isEmpty(currentPanels[dpId])) return acc;
      acc[dpId] = currentPanels[dpId];

      return acc;
    },
    {},
  );
}
