import { ComponentProps, MouseEvent as ReactMouseEvent, ReactElement } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { MenuItem, Icon, IconName } from '@blueprintjs/core';
import cx from 'classnames';

import DotsDropdown from '../../components/dotsDropdown';
import { Spinner } from 'components/ds';
import { Props as TagProps } from 'components/ds/Tag';
import FlexBox, { VerticalAlignment, HorizontalAlignment } from 'components/core/FlexBox';

const useStyles = makeStyles((theme: Theme) => ({
  dataSourceItem: {
    width: '100%',
    backgroundColor: theme.palette.ds.white,
    padding: `${theme.spacing(4)}px 0px ${theme.spacing(4)}px ${theme.spacing(4)}px`,
    height: 40,
    position: 'relative',
    border: `1px solid ${theme.palette.ds.grey400}`,
    borderBottom: 'none',

    '&.clickable': {
      cursor: 'pointer',
    },

    '&:last-child': {
      borderBottom: `1px solid ${theme.palette.ds.grey400}`,
    },
  },

  itemHeading: {
    marginBottom: 0,
    marginRight: theme.spacing(2),
  },
  actionsMenu: {
    marginRight: theme.spacing(1),

    '&:hover': {
      cursor: 'pointer',
    },
  },
  icon: {
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(4),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  subTitle: {
    marginRight: theme.spacing(3),
    color: theme.palette.ds.grey600,
  },
  logo: {
    width: theme.spacing(4),
  },
  icons: {
    color: theme.palette.ds.grey800,
  },
  headerTitle: {
    color: `${theme.palette.ds.grey800} !important`,
    textTransform: 'uppercase',
  },
  logoContainer: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    borderRadius: '50%',
    backgroundColor: theme.palette.ds.grey200,
    marginRight: theme.spacing(4),
  },
}));

interface Props {
  className?: string;
  /**
   * Text shown at the left of component
   */
  title: string;
  /**
   * Optional lighter text to add context
   */
  subTitle?: string;
  /**
   * Whether or not to show a loading spinner instead of actions dropdown
   */
  loading?: boolean;
  /**
   * Actions that the user can select related to the component
   */
  itemActions?: ComponentProps<typeof MenuItem>[];
  /**
   * Function triggered by clicking the component
   */
  onClick?: () => void;
  /**
   * Function triggered by clicking the icon
   */
  onClickIcon?: () => void;
  /**
   * Image shown at left in component
   */
  logoSrc?: string;
  /**
   * Icon shown at right in component
   */
  icon?: IconName;
  /**
   * Whether this item is the header of the list
   */
  isHeader?: boolean;
  /**
   * Props for the optional tag on the left side of the component
   */
  leftTag?: ReactElement<TagProps>;
  /**
   * Props for the optional tag on the right side of the component
   */
  rightTag?: ReactElement<TagProps>;
}

const GroupListItem = (props: Props) => {
  const classes = useStyles(props);
  const {
    className,
    title,
    itemActions,
    loading,
    onClick,
    subTitle,
    logoSrc,
    onClickIcon,
    icon,
    leftTag,
    rightTag,
    isHeader,
  } = props;

  const renderItemsDropdown = () => {
    if (!itemActions || itemActions.length === 0) return null;

    return <DotsDropdown actions={itemActions} className={classes.actionsMenu} />;
  };

  return (
    <FlexBox
      className={cx(classes.dataSourceItem, className, { clickable: onClick })}
      horizontalAlignment={HorizontalAlignment.SPACE_BETWEEN}
      onClick={onClick}
      verticalAlignment={VerticalAlignment.CENTER}>
      <FlexBox verticalAlignment={VerticalAlignment.CENTER}>
        {logoSrc && (
          <FlexBox
            className={classes.logoContainer}
            horizontalAlignment={HorizontalAlignment.CENTER}
            verticalAlignment={VerticalAlignment.CENTER}>
            <img alt="logo" className={classes.logo} src={logoSrc} />
          </FlexBox>
        )}
        <FlexBox
          className={cx(classes.itemHeading, { [classes.headerTitle]: isHeader })}
          verticalAlignment={VerticalAlignment.CENTER}>
          {title}
        </FlexBox>
        <div className={classes.subTitle}>{subTitle}</div>
        {leftTag}
      </FlexBox>
      <FlexBox
        className={classes.icons}
        onClick={(e: ReactMouseEvent<HTMLElement, MouseEvent>) => e.stopPropagation()}
        verticalAlignment={VerticalAlignment.CENTER}>
        {rightTag}
        {loading ? <Spinner size="md" /> : renderItemsDropdown()}
        {icon && <Icon className={classes.icon} icon={icon} onClick={onClickIcon} />}
      </FlexBox>
    </FlexBox>
  );
};

export default GroupListItem;
