import { cloneDeep } from 'utils/standard';
import { useDispatch } from 'react-redux';

import { Switch } from 'components/ds';
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES, V2TwoDimensionChartInstructions } from 'constants/types';
import { sharedStyles } from './styles';

type Props = {
  visualizationType: string;
  instructions: V2TwoDimensionChartInstructions;
};

export default function TooltipConfig({ visualizationType, instructions }: Props) {
  const classes = sharedStyles();
  const dispatch = useDispatch();

  return (
    <div className={classes.root}>
      <Switch
        useCustomStyles
        className={classes.configInput}
        label="Show percentage values"
        onChange={() => {
          const newInstructions = cloneDeep(instructions);

          newInstructions.tooltipFormat = {
            ...newInstructions.tooltipFormat,
            showPct: !newInstructions.tooltipFormat?.showPct,
          };
          dispatch(updateVisualizeOperation(newInstructions, visualizationType as OPERATION_TYPES));
        }}
        switchOn={instructions.tooltipFormat?.showPct}
      />
    </div>
  );
}
