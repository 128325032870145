import { cloneDeep } from 'utils/standard';
import { useDispatch } from 'react-redux';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { TEXT_ELEM_HORIZ_ALIGNMENTS } from 'types/dashboardTypes';
import { KPITitleFormat, OPERATION_TYPES, V2KPITrendInstructions } from 'constants/types';
import { ORDERED_ALIGNMENTS } from 'constants/dataConstants';
import { sharedStyles } from './styles';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2KPITrendInstructions;
};

export default function KPITrendTitleConfig({ visualizationType, instructions }: Props) {
  const classes = sharedStyles();
  const dispatch = useDispatch();

  const selectedAlignment =
    instructions.titleFormat?.horizAlignment || TEXT_ELEM_HORIZ_ALIGNMENTS.CENTER;

  const updateTitleFormat = (titleFormat: KPITitleFormat) => {
    const newInstructions = cloneDeep(instructions);
    newInstructions.titleFormat = {
      ...newInstructions.titleFormat,
      ...titleFormat,
    };
    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  return (
    <div className={classes.root}>
      <InputWithBlurSave
        containerClassName={classes.configInput}
        helpIcon="code"
        helpText="This field can take in variables"
        initialValue={instructions.textOnlyFormat?.subtitle}
        label="Subtitle"
        onNewValueSubmitted={(newValue: string) => {
          const newInstructions = cloneDeep(instructions);

          newInstructions.textOnlyFormat = {
            ...newInstructions.textOnlyFormat,
            subtitle: newValue,
          };
          dispatch(updateVisualizeOperation(newInstructions, visualizationType));
        }}
      />
      <ToggleButtonGroup fillWidth className={classes.configInput}>
        {ORDERED_ALIGNMENTS.map((alignment) => (
          <ToggleButton
            active={alignment.id === selectedAlignment}
            icon={alignment.icon}
            key={alignment.id}
            onClick={() => updateTitleFormat({ horizAlignment: alignment.id })}
          />
        ))}
      </ToggleButtonGroup>
    </div>
  );
}
