import { Component, createRef, RefObject, MouseEvent } from 'react';
import cx from 'classnames';
import { keyBy, times } from 'utils/standard';
import {
  Theme,
  withTheme,
  WithTheme,
  withStyles,
  WithStyles,
  createStyles,
} from '@material-ui/core/styles';
import { Icon } from '@blueprintjs/core';
import GridLayout, {
  Responsive as BaseResponsiveGridLayout,
  WidthProvider,
  Layout,
} from '@explo-tech/react-grid-layout';

import { DashboardDatasetView } from 'pages/dashboardPage/DashboardDatasetView';
import DashboardElementView from 'pages/dashboardPage/dashboardElement/dashboardElementView';
import { DataPanelLinkOverlay } from './DataPanelLinkOverlay';

import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import {
  droppingElementId,
  getDraggingConfig,
  MOBILE_BREAKPOINT_WIDTH,
  DASHBOARD_ROW_HEIGHT,
  PDF_PAGE_HEIGHT,
  PDF_PAGE_BREAK_HEIGHT,
  PDF_EDITOR_PAGE_BORDER_WIDTH,
  PDF_EDITOR_MARGIN_SIZE,
  ROWS_PER_PDF_PAGE,
} from 'constants/dashboardConstants';
import {
  doesElementEndOnRightHalfOfPage,
  doesElementStartOnRightHalfOfPage,
  filterForContainerElements,
  prepareDataPanel,
} from 'utils/dashboardUtils';
import * as layoutUtils from 'utils/layoutUtils';
import {
  ContainerElemConfig,
  DashboardElement,
  DashboardVariableMap,
  PAGE_TYPE,
  VIEW_MODE,
} from 'types/dashboardTypes';
import { AdHocOperationInstructions } from 'types/dataPanelTemplate';
import { DASHBOARD_ELEMENT_TYPES } from 'types/dashboardTypes';
import { withWidth } from 'components/HOCs/withWidth';
import { UpdateElementConfigArgs } from 'actions/dashboardV2Actions';
import { Customer } from 'actions/teamActions';
import { GlobalStyleConfig } from 'globalStyles/types';
import { UserTransformedSchema } from 'constants/types';
import DashboardLayoutContext from './DashboardLayoutContext';
import { DataPanel, ResourceDataset } from 'types/exploResource';
import * as resourceUtils from 'utils/exploResourceUtils';
import { sendVariableUpdatedEvent } from 'utils/customEventUtils';
import { OpenDrilldownModalType } from 'types/dashboardTypes';
import { getLayoutHeightInRows } from 'utils/layoutResolverUtil';
import { AnalyticsEventTracker } from 'utils/analyticsUtils';
import { DashboardLinks } from 'utils/filterLinking';
import { SpreadsheetType } from '../../reducers/dashboardLayoutReducer';
import { SetVariableFunc } from 'types/functionPropTypes';

const MARGIN = 12;

const ResponsiveGridLayout = WidthProvider(BaseResponsiveGridLayout);

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      overflowY: 'auto',
      flexDirection: 'column',
      position: 'relative',
    },
    pdfEditor: {
      marginTop: theme.spacing(8),
      /**
       * We want to force the background to be transparent in the PDF view because the "background"
       * is created by the renderPdfEditorPages function
       */
      backgroundColor: 'transparent !important',
      overflowY: 'visible',
    },
    emailView: {
      margin: 'auto',
      width: '650px',
      borderRadius: theme.spacing(1),
      border: `1px solid ${theme.palette.ds.grey400}`,
    },
    containerRoot: {
      borderRadius: 8,
      /**
       * The container takes up an extra row, which minus the 1px border on each side means there will be
       * ROW_HEIGHT - 2 - MARGIN px of extra space on the bottom. By adding this top padding, the content will be
       * vertically centered when the container height is minimized.
       */
      paddingTop: (DASHBOARD_ROW_HEIGHT - 2 - MARGIN) / 2,
      overflowY: 'hidden',
    },
    editableDashboardLayout: {
      minHeight: `5000px !important`,
    },
    containerDashboardLayout: {
      minHeight: `100% !important`,
    },
    fullHeightLayout: {
      height: '100% !important',
    },
    dataTable: { height: '100%' },
    dashboardPanel: {
      position: 'relative',
    },
    hoverElement: (props: PassedProps) => ({
      '&:hover': {
        zIndex: props.isCanvas ? 2 : 3,
        outline: `2px solid ${theme.palette.ds.hovered.lightBlue}`,
        outlineOffset: 5,

        '& >.resizingHandle': { visibility: 'visible' },

        '& >.dashboardElementIdTag': {
          visibility: 'initial',
        },
      },
    }),

    dashboardElementPanel: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
    },
    editableDashboardElement: {
      position: 'relative',
      borderRadius: 3,
    },
    dashboardPanelMenu: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    dotBg: {
      backgroundImage: `radial-gradient(circle at 1px 1px, ${theme.palette.ds.grey600} 1px, transparent 0)`,
      backgroundSize: 'calc(10% - 0.1px) 50px',
      height: 'calc(5000px - 48px)',
      width: 'calc(100% - 48px)',
      position: 'absolute',
    },
    selectedItem: {
      outline: `2px solid ${theme.palette.ds.blue}`,
      outlineOffset: 5,
      borderRadius: 3,
      zIndex: 2,

      '&:hover': {
        outline: `2px solid ${theme.palette.ds.hovered.blue} !important`,

        '& >.dashboardElementIdTag': {
          backgroundColor: `${theme.palette.ds.hovered.blue} !important`,
        },
      },

      '& >.resizingHandle': {
        borderRightColor: theme.palette.ds.blue,
        visibility: 'visible',
      },

      '& >.dashboardElementIdTag': {
        visibility: 'initial',
        backgroundColor: `${theme.palette.ds.blue} !important`,
        color: `${theme.palette.ds.white} !important`,

        '& >.dragIcon': {
          color: theme.palette.ds.white,
        },
      },
    },
    emptyContainerState: {
      width: '100% !important',
      height: '100% !important',
      transform: 'none !important',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.ds.grey600,
    },
    resizingHandle: {
      borderRight: `12px solid ${theme.palette.ds.hovered.lightBlue}`,
      borderTop: '12px solid transparent',
      position: 'absolute',
      right: -7,
      bottom: -7,
      visibility: 'hidden',
      cursor: 'nwse-resize',
    },
    dashboardElementIdTag: {
      visibility: 'hidden',
      position: 'absolute',
      fontSize: 11,
      top: -29,
      left: 0,
      height: 24,
      backgroundColor: theme.palette.ds.hovered.lightBlue,
      borderTopRightRadius: 4,
      borderTopLeftRadius: 4,
      padding: 4,
      display: 'flex',
      alignItems: 'center',
      zIndex: 2,
      whiteSpace: 'nowrap',
      cursor: 'grab',
    },
    elementIdTagName: {
      maxWidth: 120,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      marginLeft: 4,
    },
    invisibleDivForHover: {
      position: 'absolute',
      top: -5,
      height: 5,
      left: 0,
      right: 0,
      backgroundColor: 'transparent',
    },
    dragIcon: {
      color: theme.palette.ds.black,
    },
  });

export type PassedProps = {
  analyticsEventTracker?: AnalyticsEventTracker;
  blockedVariables: DashboardVariableMap;
  columns?: number;
  containerId?: string;
  containerLayout?: Layout;
  disableInputsForDashboardLoad: boolean;
  datasets: Record<string, ResourceDataset>;
  dashboardElements: DashboardElement[];
  dataPanels: DataPanel[];
  dashboardLayout: Layout[];
  dashboardLinks?: DashboardLinks;
  disableInputs?: boolean;
  draggingElementType?: string;
  editableDashboard: boolean;
  isCanvas?: boolean;
  isArchitectCustomerDashboard?: boolean;
  fetchDataPanelData: (dataPanel: DataPanel) => void;
  downloadDashboardImage: (email?: string) => void;
  downloadDashboardPdf: (email?: string) => void;
  fetchShareData?: (password?: string, username?: string, isStrictViewingMode?: boolean) => void;
  globalStyleConfig: GlobalStyleConfig;
  hoverElementId?: string;
  isViewOnly: boolean;
  onDrop: (newLayout: Layout[], layoutItem: Layout, event: Event, containerId?: string) => void;
  onDashboardItemSelect?: (type: DASHBOARD_ELEMENT_TYPES, id: string) => void;
  onDownloadPanelPdf: (
    dataPanel: DataPanel,
    adHocOperationInstructions: AdHocOperationInstructions,
    email: string | undefined,
    userTransformedSchema?: UserTransformedSchema,
    reportName?: string,
  ) => void;
  onDownloadDataPanelSpreadsheet: (
    dataPanel: DataPanel,
    fileFormat: SpreadsheetType,
    email: string | undefined,
    schema?: UserTransformedSchema,
  ) => void;
  openDrilldownModal?: OpenDrilldownModalType;
  pageType: PAGE_TYPE;
  applyFilters: (elementIds: string[]) => void;
  selectedDashboardItemId?: string;
  setVariable: SetVariableFunc;
  shouldUseJobQueue?: boolean;
  supportEmail?: string;
  timezone: string;
  updateDashboardLayout?: (newLayout: Layout[]) => void;
  updateElementConfig?: (args: UpdateElementConfigArgs) => void;
  userGroup: Customer | undefined;
  width?: number;
  onAdHocOperationInstructionsUpdated: (
    dataPanelId: string,
    adHocOperationInstructions: AdHocOperationInstructions,
    skipRowCount?: boolean,
  ) => void;
  variables: DashboardVariableMap;
  viewMode: VIEW_MODE;
};

type Props = PassedProps & WithStyles<typeof styles> & WithTheme;

type State = {
  isDragging: boolean;
  isResizing: boolean;
  initialLayoutChange: boolean;
};

class ElementGridLayout extends Component<Props, State> {
  gridLayout: RefObject<GridLayout>;

  state: State = {
    isDragging: false,
    isResizing: false,
    initialLayoutChange: true,
  };

  constructor(props: Props) {
    super(props);

    this.gridLayout = createRef();
  }

  isMobileView() {
    const { isViewOnly, containerId, width, viewMode } = this.props;

    // This is only used to update containers in mobile views,
    // so not necessary once already in a container
    if (!isViewOnly || containerId || viewMode === VIEW_MODE.EMAIL) return false;

    return (width ?? 0) <= MOBILE_BREAKPOINT_WIDTH;
  }

  render() {
    const { classes, containerId, isViewOnly, viewMode } = this.props;

    const isPdfEditor = !isViewOnly && viewMode === VIEW_MODE.PDF && !containerId;
    const isEmailView = viewMode === VIEW_MODE.EMAIL && !containerId;

    return (
      <div
        className={cx(classes.root, {
          [classes.containerRoot]: containerId,
          [classes.pdfEditor]: isPdfEditor,
          [classes.emailView]: isEmailView,
          [GLOBAL_STYLE_CLASSNAMES.base.backgroundColor.backgroundColor]: !containerId,
          [cx(
            GLOBAL_STYLE_CLASSNAMES.container.fill.backgroundColor,
            GLOBAL_STYLE_CLASSNAMES.container.outline.border,
            GLOBAL_STYLE_CLASSNAMES.container.shadow.dropShadow,
            GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.default.borderRadius,
            GLOBAL_STYLE_CLASSNAMES.container.padding.default.padding,
          )]: containerId,
        })}
        id="scrollableLayout">
        {isPdfEditor && this.renderPdfEditorPages()}
        {this.renderGridLayout()}
      </div>
    );
  }

  renderPdfEditorPages = () => {
    const { dashboardLayout, theme } = this.props;
    const numberOfPages = Math.ceil(getLayoutHeightInRows(dashboardLayout) / ROWS_PER_PDF_PAGE);

    return times(numberOfPages, (pageNumber) => {
      return (
        <div
          key={pageNumber}
          style={{
            position: 'absolute',
            top: pageNumber * PDF_PAGE_HEIGHT + PDF_PAGE_BREAK_HEIGHT / 2,
            height: PDF_PAGE_HEIGHT - PDF_PAGE_BREAK_HEIGHT - PDF_EDITOR_PAGE_BORDER_WIDTH,
            width: '100%',
            backgroundColor: theme.palette.ds.white,
            border: `${PDF_EDITOR_PAGE_BORDER_WIDTH}px solid ${theme.palette.ds.grey400}`,
          }}
        />
      );
    });
  };

  renderGridLayout = () => {
    const {
      classes,
      columns,
      containerId,
      dataPanels,
      dashboardElements,
      draggingElementType,
      editableDashboard,
      isViewOnly,
      dashboardLayout,
      containerLayout,
      width,
      globalStyleConfig,
      onDashboardItemSelect,
      viewMode,
      pageType,
    } = this.props;
    const elemsById = keyBy(dashboardElements, 'id');

    let layout = layoutUtils.processLayout({
      layout: dashboardLayout,
      dashboardElements,
      dataPanels,
      viewMode,
    });
    if (this.isMobileView()) {
      layout = layoutUtils.formatContainerElementHeightForMobile(layout, dashboardElements);
    }

    // We don't want to filter on container id here because we care about every
    // data panel in the dashboard

    const gridLayoutChildren = layoutUtils
      .getSortedGridItems(
        [
          ...filterForContainerElements(dataPanels, containerId).elemsInContainer,
          ...filterForContainerElements(dashboardElements, containerId, true).elemsInContainer,
        ],
        layout,
      )
      .map((gridItem) => {
        if (resourceUtils.isDashboardElement(gridItem)) {
          return this.getDashboardElement(gridItem, elemsById);
        }

        return this.renderDataPanel(gridItem);
      });

    const droppingElement = this.renderDroppingElement();
    if (droppingElement) gridLayoutChildren.push(droppingElement);

    const isEmptyContainer = containerId && !gridLayoutChildren.length;

    if (
      viewMode === VIEW_MODE.DEFAULT &&
      editableDashboard &&
      isEmptyContainer &&
      !draggingElementType
    ) {
      gridLayoutChildren.push(
        <div className={classes.emptyContainerState} key="containerPlaceholder">
          Drag new elements into the container
        </div>,
      );
    }

    const marginSize =
      viewMode === VIEW_MODE.PDF ? PDF_EDITOR_MARGIN_SIZE : globalStyleConfig.base.spacing.default;

    const numRows = containerLayout?.h ?? 1;

    const sharedLayoutProps = {
      className: cx('layout', {
        [classes.editableDashboardLayout]: editableDashboard && viewMode !== VIEW_MODE.EMAIL,
        [classes.containerDashboardLayout]: containerId,
      }),
      draggableCancel: 'input,textarea,.bp3-popover-wrapper',
      isDraggable: !isEmptyContainer && editableDashboard,
      isResizable: !isEmptyContainer && editableDashboard,
      // we don't allow nested containers
      isDroppable: containerId
        ? editableDashboard && draggingElementType !== DASHBOARD_ELEMENT_TYPES.CONTAINER
        : editableDashboard,
      onDrop: editableDashboard ? this.onDrop : undefined,
      compactType: this.dashboardIsEmpty() || viewMode === VIEW_MODE.PDF ? null : undefined,
      droppingItem: getDraggingConfig(draggingElementType),
      // TODO: look more into weird movement in end user dashboard
      useCSSTransforms: !!editableDashboard && pageType !== PAGE_TYPE.END_USER_DASHBOARD,
      margin: [marginSize, marginSize] as [number, number],
      style:
        viewMode === VIEW_MODE.PDF
          ? {
              marginTop: -(PDF_EDITOR_MARGIN_SIZE / 2),
              marginBottom: -(PDF_EDITOR_MARGIN_SIZE / 2),
            }
          : undefined,
    };

    const cols = viewMode === VIEW_MODE.EMAIL ? 6 : columns || globalStyleConfig.base.numColumns;

    if (isViewOnly && !containerId) {
      return (
        <ResponsiveGridLayout
          cols={{ lg: cols, md: cols, sm: cols, xs: cols, xxs: cols }}
          layouts={{ lg: layout, md: layout, sm: layout, xs: layout, xxs: layout }}
          rowHeight={DASHBOARD_ROW_HEIGHT}
          {...sharedLayoutProps}>
          {gridLayoutChildren}
        </ResponsiveGridLayout>
      );
    }

    /**
     * This width will only be used for container layouts.
     * The height that we get from react-sizeme in DashboardContainerElement includes the padding and border widths of DashboardLayout
     */
    const containerWidth =
      width &&
      width -
        globalStyleConfig.container.padding.default * 2 -
        2 * (globalStyleConfig.container.outline.weight || 0);

    const selectItemOnStart = (i: string) => {
      if (editableDashboard && onDashboardItemSelect) {
        const dashboardElement = dashboardElements.find(({ id }) => id === i);
        onDashboardItemSelect(
          dashboardElement ? dashboardElement.element_type : DASHBOARD_ELEMENT_TYPES.DATA_PANEL,
          i,
        );
      }
    };

    return (
      <GridLayout
        cols={cols}
        draggableHandle=".draggableHandle"
        layout={layout}
        onDragStart={(_layout, _oldItem, newItem, _placeholder, e) => {
          selectItemOnStart(newItem.i);
          this.setState({ isDragging: true });
          e.stopPropagation();
        }}
        onDragStop={() => this.setState({ isDragging: false })}
        onLayoutChange={(newLayout) => this.handleLayoutChanged(newLayout, layout)}
        onResizeStart={(_layout, item) => {
          selectItemOnStart(item.i);
          this.setState({ isResizing: true });
        }}
        onResizeStop={(newLayout) => {
          this.handleLayoutChanged(newLayout, layout, true);
          this.setState({ isResizing: false });
        }}
        ref={this.gridLayout}
        resizeHandle={<div className={cx('resizingHandle', classes.resizingHandle)} />}
        rowHeight={
          containerId
            ? (DASHBOARD_ROW_HEIGHT * numRows -
                globalStyleConfig.base.spacing.default -
                2 * globalStyleConfig.container.padding.default) /
              (numRows - 1)
            : DASHBOARD_ROW_HEIGHT
        }
        /**
         * In order to account for internal container padding, we need to dynamically calculate
         * the rowHeight so the vertical spacing will be symmetric
         */
        width={containerId && containerWidth ? containerWidth : width || 0}
        {...sharedLayoutProps}>
        {gridLayoutChildren}
      </GridLayout>
    );
  };

  dashboardIsEmpty = () => {
    return this.props.dataPanels.length === 0;
  };

  onDrop = (layout: Layout[], layoutItem: Layout, event: Event) => {
    if (layoutItem === undefined) {
      return;
    }
    event.stopPropagation();
    const { containerId, onDrop } = this.props;
    onDrop(layout, layoutItem, event, containerId);
  };

  viewIdTag = (name: string) => {
    const { editableDashboard, classes, isCanvas } = this.props;
    if (editableDashboard) {
      return (
        <>
          <div
            className={cx(
              classes.dashboardElementIdTag,
              'dashboardElementIdTag',
              'draggableHandle',
            )}
            onMouseUp={() => this.setState({ isDragging: false })}>
            <Icon className={cx(classes.dragIcon, 'dragIcon')} icon="layout-grid" iconSize={10} />

            {isCanvas ? (
              <Icon
                className={cx(classes.dragIcon, 'dragIcon')}
                icon="layout-grid"
                iconSize={10}
                style={{ marginLeft: 1 }}
              />
            ) : (
              <div className={classes.elementIdTagName}>{name}</div>
            )}
          </div>
          <div className={classes.invisibleDivForHover} />
        </>
      );
    }
    return <></>;
  };

  renderDataPanel = (dataPanel: DataPanel) => {
    const {
      classes,
      containerLayout,
      dashboardLayout,
      dashboardElements,
      editableDashboard,
      globalStyleConfig,
      selectedDashboardItemId,
      analyticsEventTracker,
      containerId,
      hoverElementId,
      isViewOnly,
      onAdHocOperationInstructionsUpdated,
      onDownloadDataPanelSpreadsheet,
      onDownloadPanelPdf,
      openDrilldownModal,
      variables,
      setVariable,
      datasets,
      onDashboardItemSelect,
      pageType,
      shouldUseJobQueue,
      supportEmail,
      fetchDataPanelData,
      userGroup,
      isArchitectCustomerDashboard,
      dashboardLinks,
      viewMode,
    } = this.props;

    dataPanel = prepareDataPanel(
      variables,
      dataPanel,
      datasets,
      pageType !== PAGE_TYPE.EXPLO_APP,
      dashboardElements,
    );

    const isSelected = selectedDashboardItemId === dataPanel.id;

    const elementEndsOnRightSide = doesElementEndOnRightHalfOfPage(
      dashboardLayout,
      dataPanel.id,
      globalStyleConfig.base.numColumns,
      containerLayout?.x,
    );

    return (
      <div
        className={cx(classes.dashboardPanel, {
          [classes.selectedItem]: isSelected || hoverElementId === dataPanel.id,
          [classes.editableDashboardElement]: editableDashboard,
          [classes.hoverElement]: editableDashboard && !isSelected && !this.state.isDragging,
        })}
        key={dataPanel.id}
        onClick={(e) => {
          if (editableDashboard && !isSelected) {
            onDashboardItemSelect?.(DASHBOARD_ELEMENT_TYPES.DATA_PANEL, dataPanel.id);
          }
          e.stopPropagation();
        }}>
        {this.viewIdTag(dataPanel.provided_id)}
        <DashboardDatasetView
          canDownloadDataPanel
          analyticsEventTracker={analyticsEventTracker}
          dashboardElements={dashboardElements}
          dataPanel={dataPanel}
          datasets={datasets}
          displayDemoWatermark={userGroup?.is_demo_group}
          editableDashboard={editableDashboard}
          elementEndsOnRightSide={elementEndsOnRightSide}
          fetchDataPanelData={fetchDataPanelData}
          isArchitectCustomerDashboard={isArchitectCustomerDashboard}
          isInContainer={!!containerId}
          isSelected={isSelected}
          isUpdatingPosition={this.state.isResizing || this.state.isDragging}
          isViewOnly={isViewOnly}
          loading={
            dataPanel._loading === true ||
            dataPanel._loading === undefined ||
            !!dataPanel._outstandingSecondaryDataRequests
          }
          onAdHocOperationInstructionsUpdated={onAdHocOperationInstructionsUpdated}
          onDownloadPanelPdf={(
            adHocOperationInstructions,
            email,
            userTransformedSchema,
            reportName,
          ) =>
            onDownloadPanelPdf(
              dataPanel,
              adHocOperationInstructions,
              email,
              userTransformedSchema,
              reportName,
            )
          }
          onDownloadPanelSpreadsheet={(fileFormat, email, userTransformedSchema) =>
            onDownloadDataPanelSpreadsheet(dataPanel, fileFormat, email, userTransformedSchema)
          }
          openDrilldownModal={openDrilldownModal}
          pageType={pageType}
          setVariable={(newValue, name) => setVariable(name || dataPanel.provided_id, newValue)}
          shouldUseJobQueue={shouldUseJobQueue}
          supportEmail={supportEmail}
          variables={variables}
          viewMode={viewMode}
        />
        {dashboardLinks && selectedDashboardItemId ? (
          <DataPanelLinkOverlay
            dashboardLinks={dashboardLinks}
            dataPanel={dataPanel}
            datasets={datasets}
            elementId={selectedDashboardItemId}
          />
        ) : null}
      </div>
    );
  };

  getDashboardElement = (elem: DashboardElement, elemsById: Record<string, DashboardElement>) => {
    const {
      analyticsEventTracker,
      blockedVariables,
      classes,
      containerId,
      dashboardLayout,
      disableInputs,
      editableDashboard,
      hoverElementId,
      selectedDashboardItemId,
      applyFilters,
      setVariable,
      variables,
      onDashboardItemSelect,
      viewMode,
      containerLayout,
      globalStyleConfig,
      isArchitectCustomerDashboard,
      disableInputsForDashboardLoad,
      timezone,
    } = this.props;

    const isSelected = selectedDashboardItemId === elem.id;
    const elementStartsOnRightSide = doesElementStartOnRightHalfOfPage(
      dashboardLayout,
      elem.id,
      globalStyleConfig.base.numColumns,
      containerLayout?.x,
    );

    return (
      <div
        className={cx(classes.dashboardElementPanel, {
          [classes.selectedItem]: isSelected || hoverElementId === elem.id,
          [classes.editableDashboardElement]: editableDashboard,
          [classes.hoverElement]: editableDashboard && !isSelected && !this.state.isDragging,
        })}
        key={elem.id}
        onClick={(e: MouseEvent<HTMLElement>) => {
          if (editableDashboard && !isSelected && onDashboardItemSelect) {
            onDashboardItemSelect(elem.element_type, elem.id);
          }
          e.stopPropagation();
        }}>
        {this.viewIdTag(elem.name)}
        <DashboardLayoutContext.Consumer>
          {(context) => (
            <DashboardElementView
              analyticsEventTracker={analyticsEventTracker}
              applyFilters={applyFilters}
              blockedElementIds={Object.keys(blockedVariables)}
              dashboardElement={elem}
              dashboardElementsById={elemsById}
              dashboardLayoutProps={{
                ...this.props,
                // We don't want to pass styles, and allows us to use easy syntax above
                ...{ classes: undefined },
              }}
              disableInputs={(disableInputsForDashboardLoad || disableInputs) ?? false}
              editableDashboard={editableDashboard}
              elementStartsOnRightSide={elementStartsOnRightSide}
              isArchitectCustomerDashboard={isArchitectCustomerDashboard}
              isDragging={this.state.isDragging}
              isMobileView={this.isMobileView()}
              isResizing={this.state.isResizing}
              isSelected={isSelected}
              onNewValueSelect={(newValue, options) => {
                sendVariableUpdatedEvent(elem.name, newValue);
                setVariable(elem.name, newValue, elem.id, options);
              }}
              portalId={containerId ? context.dashboardLayoutTagId : undefined}
              timezone={timezone}
              value={elem.id in blockedVariables ? blockedVariables[elem.id] : variables[elem.name]}
              variables={variables}
              viewMode={viewMode}
            />
          )}
        </DashboardLayoutContext.Consumer>
      </div>
    );
  };

  renderDroppingElement = () => {
    const { dashboardLayout, draggingElementType } = this.props;
    if (!dashboardLayout || !draggingElementType) return;

    const elementId = droppingElementId(draggingElementType);
    const droppingElement = dashboardLayout.find((layoutItem) => layoutItem.i === elementId);
    if (droppingElement) return <div key={droppingElement.i} />;
  };

  handleLayoutChanged = (layout: Layout[], oldLayout: Layout[], ignoreResize = false) => {
    const {
      containerId,
      containerLayout,
      updateDashboardLayout,
      updateElementConfig,
      draggingElementType,
      dataPanels,
      dashboardElements,
      viewMode,
      editableDashboard,
    } = this.props;

    //LayoutChange gets called on load and dont want to update until after initial load
    if (this.state.initialLayoutChange) {
      this.setState({ initialLayoutChange: false });
      return;
    }
    //We now call handleLayoutChange on onResizeStop because sometimes on quick resizing handleLayoutChange
    //is not called. But since sometimes handleLayoutChange is called once resizing is done
    //then we ignore that call with the ignoreResize logic
    if (draggingElementType || !editableDashboard || (this.state.isResizing && !ignoreResize))
      return;

    if (!containerId) {
      updateDashboardLayout && updateDashboardLayout(layout);
      return;
    } else if (layout.length === 1 && layout[0].i === 'containerPlaceholder') {
      //When container is dropped in, layoutchanged gets called and the placeholder
      //is inserted in layout which is not wanted
      return;
    }

    // If main layout is invalid, revert to previous layout (doesn't include layouts in containers)
    if (!layoutUtils.validateLayout(layout, containerLayout?.h ?? 0) && !containerId) {
      /**
       * When an the layout is changed, react-grid-layout changes the layout in it's state which
       * it uses to determine how to display the items. Passing the `layout` prop won't override this
       * state, so we need to use the ref to control it
       */
      this.gridLayout.current?.setState({ layout: oldLayout });
      updateElementConfig?.({ elementId: containerId, config: { layout: oldLayout } });
      return;
    }

    const gridLayoutChildren = (
      filterForContainerElements(dataPanels, containerId).elemsInContainer as (
        | DashboardElement
        | DataPanel
      )[]
    ).concat(filterForContainerElements(dashboardElements, containerId, true).elemsInContainer);

    if (gridLayoutChildren.length === 0 && !draggingElementType) {
      this.gridLayout?.current?.setState({
        layout: [],
      });
    }

    const containerElement = dashboardElements.find((element) => element.id === containerId);
    const containerConfig = containerElement?.config as ContainerElemConfig;

    const newConfig = { ...containerConfig };

    switch (viewMode) {
      case VIEW_MODE.PDF:
        newConfig.pdfLayout = layout;
        break;
      case VIEW_MODE.EMAIL:
        newConfig.emailLayout = layout;
        break;
      case VIEW_MODE.MOBILE:
        newConfig.mobileLayout = layout;
        break;
      default: {
        newConfig.layout = layout;
      }
    }

    updateElementConfig?.({
      elementId: containerId,
      config: newConfig,
    });
  };
}

export default withWidth(withStyles(styles)(withTheme(ElementGridLayout)));
