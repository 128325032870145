import { MouseEvent as ReactMouseEvent, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReduxState } from 'reducers/rootReducer';
import { ControlGroup, FormGroup } from '@blueprintjs/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';

import OnboardingFlowPage from 'components/Onboarding/OnboardingFlowPage';
import InputGroup from 'explo-ds/forms/marketing/inputGroup';
import Button from 'shared/Button';
import { Divider } from '@material-ui/core';

import { createTeam, addUserToTeam, getUserTeamInvite, logOutUser } from 'actions/authAction';
import { logInUserSuccess } from 'actions/userActions';
import { isUUID } from 'utils/general';
import { createLoadingSelector } from 'reducers/api/selectors';
import { pageView } from 'analytics/exploAnalytics';
import { ROUTES } from 'constants/routes';
import { ACTION } from 'actions/types';
import { showErrorToast } from 'shared/sharedToasts';
import { fetchProfile } from 'auth/userAuth';

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    width: '100%',
  },
  formInput: {
    width: '100%',
  },
  teamInfo: {
    backgroundColor: theme.palette.ds.grey200,
    padding: theme.spacing(2),
    borderRadius: 4,
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  formField: {
    borderRadius: `3px !important`,
    marginRight: `${theme.spacing(2)}px !important`,
    width: '80%',
    boxShadow: 'inset 0px 0px 0px 1px #eeeeee',
  },
  numMembers: {
    color: theme.palette.ds.blue,
  },
  createTeamButton: {
    marginTop: theme.spacing(6),
  },
  startCreateTeamButton: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  dividerText: {
    color: theme.palette.ds.grey700,
    textAlign: 'center',
  },
  dividerContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: `${theme.spacing(2)}px`,
  },
  divider: {
    flexGrow: 1,
  },
  trialTitle: {
    fontWeight: 500,
    fontSize: 20,
    color: theme.palette.ds.grey900,
    marginTop: theme.spacing(6),
  },
  trialContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

enum PAGE_ACTION {
  JOIN_TEAM,
  CREATE_TEAM,
}

const JoinTeamPage = () => {
  const classes = useStyles();
  const { currentUser, addUserToTeamLoading, createTeamLoading } = useSelector(
    (state: ReduxState) => ({
      currentUser: state.currentUser,
      addUserToTeamLoading: createLoadingSelector([ACTION.ADD_USER_TO_TEAM], false)(state),
      createTeamLoading: createLoadingSelector([ACTION.CREATE_TEAM], false)(state),
    }),
    shallowEqual,
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const [teamName, setTeamName] = useState('');
  const [inviteCode, setInviteCode] = useState('');
  const [inviteHash, setInviteHash] = useState('');
  const [fetchedTeamName, setFetchedTeamName] = useState('');
  const [numTeamUsers, setNumTeamUsers] = useState(0);
  const [pageAction, setPageAction] = useState(PAGE_ACTION.JOIN_TEAM);

  useEffect(() => {
    pageView('Join Team');

    dispatch(
      getUserTeamInvite({}, (response) => {
        if (response.team_name) {
          setInviteHash(response.invite_hash);
          setFetchedTeamName(response.team_name);
          setNumTeamUsers(response.num_users);
        }
      }),
    );
  }, [dispatch]);

  const onSuccess = () => {
    fetchProfile(
      (user) => {
        dispatch(logInUserSuccess(user));
        history.push(ROUTES.TELL_US_ABOUT_YOU);
      },
      () => dispatch(logOutUser()),
    );
  };

  const onCreateTeam = (event: ReactMouseEvent<HTMLElement, MouseEvent>) => {
    dispatch(createTeam(teamName, onSuccess, (errorMsg) => showErrorToast(errorMsg, 10)));
    event.preventDefault();
  };

  const onJoinTeam = (event: ReactMouseEvent<HTMLElement, MouseEvent>) => {
    if (currentUser.id && (isUUID(inviteCode) || inviteHash)) {
      dispatch(
        addUserToTeam(
          currentUser.id,
          inviteCode,
          onSuccess,
          (errorMsg) => {
            showErrorToast(
              errorMsg && errorMsg.status === 403
                ? `You are already logged in with ${currentUser.email}. 
                   If this is the wrong account, please logout with the link in the top right.`
                : 'Incorrect invite code. Please ensure there are no spaces or tabs.',
              10,
            );
          },
          inviteHash,
        ),
      );
    } else {
      showErrorToast('Invalid Invite Code', 10);
    }

    event.preventDefault();
  };

  const CreateTeamBody = (
    <div className="flex-box center-horizontal">
      <FormGroup className={cx(classes.formInput)} label="Team name" labelFor="team_name">
        <InputGroup
          data-testid="sign-up-team-input"
          id="team_name"
          name="team_name"
          onInputChange={setTeamName}
          placeholder="Team name"
          value={teamName}
        />
        <Button
          fillWidth
          className={classes.createTeamButton}
          data-testid="sign-up-team-submit"
          disabled={teamName === '' || addUserToTeamLoading}
          loading={createTeamLoading}
          onClick={onCreateTeam}
          text="Create a Team"
          type="primary"
        />
      </FormGroup>
    </div>
  );

  const RequestTrialBody = (
    <div className={classes.trialContainer}>
      <div className={classes.trialTitle}>Start your 7 day free trial</div>
      <Button
        fillWidth
        className={classes.createTeamButton}
        data-testid="sign-up-demo-redirect"
        href="//www.explo.co/request-a-trial"
        text="Request free trial"
        type="primary"
      />
    </div>
  );

  const CreateTeamButton = (
    <div>
      <Button
        className={classes.startCreateTeamButton}
        data-testid="sign-up-create-team"
        disabled={addUserToTeamLoading}
        loading={addUserToTeamLoading}
        onClick={() => setPageAction(PAGE_ACTION.CREATE_TEAM)}
        text="Create a New Team"
      />
    </div>
  );

  const JoinTeamBody = (
    <>
      {fetchedTeamName ? (
        <div className="flex-box center-horizontal">
          <FormGroup className={classes.formInput} labelFor="invite_code">
            <ControlGroup fill={true} vertical={false}>
              <div className={cx(classes.teamInfo, classes.formField)}>
                <div>{fetchedTeamName}</div>
                <div className={classes.numMembers}>
                  {numTeamUsers} {numTeamUsers === 1 ? 'member' : 'members'}
                </div>
              </div>
              <Button
                loading={addUserToTeamLoading}
                onClick={onJoinTeam}
                text="Join"
                type="primary"
              />
            </ControlGroup>
          </FormGroup>
        </div>
      ) : null}
      <div className="flex-box center-horizontal">
        <FormGroup className={classes.formInput} label="Invite Code" labelFor="invite_code">
          <ControlGroup fill={true} vertical={false}>
            <InputGroup
              className={classes.formField}
              id="invite_code"
              name="invite_code"
              onInputChange={setInviteCode}
              placeholder="000-000-000-000"
              value={inviteCode}
            />
            <Button
              loading={addUserToTeamLoading}
              onClick={onJoinTeam}
              text="Join"
              type="primary"
            />
          </ControlGroup>
        </FormGroup>
      </div>
      <div className={classes.dividerContainer}>
        <Divider className={classes.divider} />
        <div className={classes.dividerText}>or</div>
        <Divider className={classes.divider} />
      </div>
      {currentUser.can_create_team ? CreateTeamButton : RequestTrialBody}
    </>
  );

  return (
    <OnboardingFlowPage
      helpLinks={[
        { name: 'Log Out', onClick: () => dispatch(logOutUser()) },
        { name: 'Need Support?', url: 'https://docs.explo.co/' },
      ]}
      rightContentTitle={
        pageAction === PAGE_ACTION.CREATE_TEAM
          ? 'Create a new team and begin your 7 day trial!'
          : 'Join an existing team'
      }
      rightPanelContent={
        <div className={classes.formContainer}>
          {pageAction === PAGE_ACTION.CREATE_TEAM ? CreateTeamBody : JoinTeamBody}
        </div>
      }
    />
  );
};

// @ts-ignore
export default JoinTeamPage;
