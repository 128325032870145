import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { EmbedText } from '../EmbedText';
import { Button, Icon, sprinkles } from 'components/ds';

import { openDatasetModal } from 'reportBuilderContent/reducers/reportEditingReducer';

export const NoDataSelected: FC = () => {
  const dispatch = useDispatch();

  return (
    <div className={sprinkles({ parentContainer: 'fill', flexItems: 'centerColumn' })}>
      <Icon
        className={sprinkles({ marginBottom: 'sp2', color: 'contentTertiary' })}
        name="empty-set"
        size="lg"
      />
      <EmbedText body="b1" color="contentTertiary">
        No data selected
      </EmbedText>
      <Button onClick={() => dispatch(openDatasetModal())} type="tertiary">
        Select data
      </Button>
    </div>
  );
};
