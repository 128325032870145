import { ACTION } from 'actions/types';
import { ActionFnWithArgs, defineAPIPostAction } from 'actions/actionUtils';
import { ExportUrlResponse } from './responseTypes';

export type DownloadDashboardBody = {
  version_number: number | undefined;
  download_file_name: string;
  variable_query: string;
  export_type: 'image' | 'pdf';
  email?: string;
  timezone?: string;

  // non-embed
  user_group_id?: number;
  resource_id?: number;

  //embed
  resource_embed_id?: string;
};

export type DownloadDashboardPdf = ActionFnWithArgs<DownloadDashboardBody, ExportUrlResponse>;

export const {
  actionFn: downloadDashboardPdf,
  requestAction: downloadDashboardPdfRequest,
  successAction: downloadDashboardPdfSuccess,
  errorAction: downloadDashboardPdfError,
} = defineAPIPostAction<DownloadDashboardBody, ExportUrlResponse>(
  ACTION.FETCH_PDF_EXPORT_URL,
  'export',
  'dashboard/pdf',
  'POST',
);

export type DownloadDashboardImage = ActionFnWithArgs<DownloadDashboardBody, ExportUrlResponse>;

export const {
  actionFn: downloadDashboardImage,
  requestAction: downloadDashboardImageRequest,
  successAction: downloadDashboardImageSuccess,
  errorAction: downloadDashboardImageError,
} = defineAPIPostAction<DownloadDashboardBody, ExportUrlResponse>(
  ACTION.FETCH_IMAGE_EXPORT_URL,
  'export',
  'dashboard/image',
  'POST',
);

export type DownloadDataPanelBody = {
  data_panel_template_id: string;
  download_file_name: string;
  version_number: number | undefined;
  variable_query: string;
  email?: string;
  timezone?: string;

  // for non-embed
  user_group_id?: number;
  resource_id?: number;

  // for embed
  resource_embed_id?: string;
};

export type DownloadDataPanelPdf = ActionFnWithArgs<DownloadDataPanelBody, ExportUrlResponse>;

export const {
  actionFn: downloadDataPanelPdf,
  requestAction: downloadDataPanelPdfRequest,
  successAction: downloadDataPanelPdfSuccess,
  errorAction: downloadDataPanelPdfError,
} = defineAPIPostAction<DownloadDataPanelBody, ExportUrlResponse>(
  ACTION.DOWNLOAD_DATA_PANEL_TEMPLATE_PDF,
  'export',
  'data_panel_template/pdf',
  'POST',
);
