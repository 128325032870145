import { FC } from 'react';
import produce from 'immer';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import { Switch } from 'components/ds';
import { sprinkles } from 'components/ds';

import { ImageElemConfig } from 'types/dashboardTypes';

const configInputClass = sprinkles({ marginTop: 'sp1.5' });

type Props = {
  config: ImageElemConfig;
  updateConfig: (newConfig: ImageElemConfig) => void;
};

export const ImageElementConfigPanel: FC<Props> = ({ config, updateConfig }) => {
  const updateImageConfig = (update: (config: ImageElemConfig) => void) => {
    updateConfig(
      produce(config, (draft) => {
        update(draft);
      }),
    );
  };

  return (
    <div>
      <InputWithBlurSave
        containerClassName={configInputClass}
        helpIcon="code"
        helpText="This field can take in variables"
        initialValue={config.imageUrl}
        label="Image URL"
        onNewValueSubmitted={(newValue) =>
          updateImageConfig((draft) => (draft.imageUrl = newValue))
        }
      />
      <Switch
        useCustomStyles
        className={configInputClass}
        label="Fit entire image in container"
        onChange={() =>
          updateImageConfig((draft) => (draft.fitEntireImage = !draft.fitEntireImage))
        }
        switchOn={config.fitEntireImage}
      />
    </div>
  );
};
