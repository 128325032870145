import { createAction } from '@reduxjs/toolkit';

import { ACTION } from './types';

import { GlobalStyleConfig } from 'globalStyles/types';
import { EmbedCanvas } from './canvasActions';
import { CanvasVersion, EmbedCanvasVersion } from './canvasVersionActions';
import { ArchitectCustomerDashboardConfig } from './architectCustomerDashboardConfigActions';
import { Customer } from './teamActions';
import { PLAN_TYPES } from 'constants/paymentPlanConstants';
import { ArchitectEmailCadence } from './architectEmailCadenceActions';
import { DashboardVariableMap } from 'types/dashboardTypes';
import {
  createRequestAction,
  createErrorAction,
  createSuccessAction,
  defineEmbedPostAction,
} from 'actions/actionUtils';

export interface ArchitectCustomerDashboard {
  id: number | null;
  group_id: number;
  user_id: string | null;
  name: string;
  configuration: ArchitectCustomerDashboardConfig;
  modified: string;
}

export const updateArchitectCustomerDashboardVariables = createAction<{
  dashboardId: number | null;
  variables: DashboardVariableMap;
}>(ACTION.UPDATE_END_USER_DASHBOARD_VARIABLES);

export const updateArchitectCustomerDashboardName = createAction<string>(
  ACTION.UPDATE_END_USER_DASHBOARD_NAME,
);

export const openArchitectCustomerDashboard = createAction<number | null | undefined>(
  ACTION.OPEN_END_USER_DASHBOARD,
);

export const closeArchitectCustomerDashboard = createAction<number | null>(
  ACTION.CLOSE_END_USER_DASHBOARD,
);

export const toggleArchitectCustomerDashboardEditing = createAction<boolean>(
  ACTION.TOGGLE_END_USER_DASHBOARD_EDITING,
);

export const setSelectedDashboardItemId = createAction<undefined | string>(
  ACTION.SET_SELECTED_END_USER_DASHBOARD_ELEMENT_ID,
);

export const setArchitectCustomerDashboardForExample = createAction<{
  canvasVersion: EmbedCanvasVersion;
  canvas: EmbedCanvas;
}>(ACTION.SET_END_USER_DASHBOARD_FOR_EXAMPLE);

export const clearArchitectCustomerDashboardExample = createAction(
  ACTION.CLEAR_END_USER_DASHBOARD_EXAMPLE,
);

type FetchArchitectCustomerDashboardsBody = {
  canvas_embed_id: string;
  version_number: number | null;
  user_id: string | null;
  environment: string | undefined;
};

export type EmbedArchitectCustomerDashboardTeam = {
  id: number;
  name: string;
  payment_plan: PLAN_TYPES;
  explo_analytics_token: string;
};

type FetchArchitectCustomerDashboardsData = {
  canvas: EmbedCanvas;
  canvas_version: CanvasVersion;
  style_config: GlobalStyleConfig | null;
  customer: Customer;
  dashboards: ArchitectCustomerDashboard[];
  emails: ArchitectEmailCadence[];
  team: EmbedArchitectCustomerDashboardTeam;
};

export const {
  actionFn: fetchArchitectCustomerDashboards,
  requestAction: fetchArchitectCustomerDashboardsRequest,
  errorAction: fetchArchitectCustomerDashboardsError,
  successAction: fetchArchitectCustomerDashboardsSuccess,
} = defineEmbedPostAction<
  FetchArchitectCustomerDashboardsBody,
  FetchArchitectCustomerDashboardsData
>(ACTION.FETCH_END_USER_DASHBOARD, 'architect_customer_dashboard', 'dashboards', 'POST');

type CreateArchitectCustomerDashboardBody = {
  canvas_embed_id: string;
  user_id: string | null;
  config: ArchitectCustomerDashboardConfig;
  name: string;
};

export const {
  actionFn: createArchitectCustomerDashboard,
  successAction: createArchitectCustomerDashboardSuccess,
} = defineEmbedPostAction<
  CreateArchitectCustomerDashboardBody,
  { dashboard: ArchitectCustomerDashboard }
>(ACTION.CREATE_END_USER_DASHBOARD, 'architect_customer_dashboard', 'create_dashboard', 'POST');

type SaveArchitectCustomerDashboardBody = {
  canvas_embed_id: string;
  user_id: string | null;
  dashboard_id: number;
  config: ArchitectCustomerDashboardConfig;
  dashboard_name: string;
};

export const {
  actionFn: saveArchitectCustomerDashboard,
  successAction: saveArchitectCustomerDashboardSuccess,
} = defineEmbedPostAction<SaveArchitectCustomerDashboardBody, { dashboard_id: number }>(
  ACTION.SAVE_END_USER_DASHBOARD,
  'architect_customer_dashboard',
  'save_dashboard',
  'POST',
);

type DeleteArchitectCustomerDashboardBody = {
  user_id: string | null;
  dashboard_id: number;
};

export const {
  actionFn: deleteArchitectCustomerDashboard,
  successAction: deleteArchitectCustomerDashboardSuccess,
} = defineEmbedPostAction<DeleteArchitectCustomerDashboardBody, { dashboard_id: number }>(
  ACTION.DELETE_END_USER_DASHBOARD,
  'architect_customer_dashboard',
  'delete_dashboard',
  'POST',
);

export const exportArchitectDashboardRequest = createRequestAction(
  ACTION.EXPORT_END_USER_DASHBOARD,
);
export const exportArchitectDashboardError = createErrorAction(ACTION.EXPORT_END_USER_DASHBOARD);
export const exportArchitectDashboardSuccess = createSuccessAction<{ url: string }>(
  ACTION.EXPORT_END_USER_DASHBOARD,
);

export const clearArchitectExport = createAction(ACTION.CLEAR_END_USER_EXPORT);
