import { getTimeZones } from '@vvo/tzdb';
import { DateTime, Info } from 'luxon';
import { groupBy } from 'utils/standard';
import { dateTimeFromISOString } from './dateUtils';

import { DashboardTimezones } from 'constants/dashboardConstants';
import { SelectedDropdownInputItem } from 'constants/types';

export const getTimezone = (
  dashboardDefaultTimezone: DashboardTimezones | undefined,
  passedTimezone?: string,
) => {
  // Zones are a luxon representation of a timezone that we create from parsing in a user-passed
  // timezone override
  if (passedTimezone !== undefined) {
    const normalized = Info.normalizeZone(passedTimezone);

    if (normalized.isValid) return normalized.name;
    console.warn(`Passed timezone ${passedTimezone} is not valid, using default...`);
  }

  if (dashboardDefaultTimezone === DashboardTimezones.USER_LOCAL_TIME) {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  return 'UTC';
};

export const getTimezoneAwareUnix = (utcTimestamp: string) =>
  dateTimeFromISOString(utcTimestamp).toUnixInteger() * 1000;

export const getTimezoneAwareDate = (utcTimestamp: string) => {
  return dateTimeFromISOString(utcTimestamp);
};

export const getLuxonDateFromDatePicker = (date: Date) =>
  /**
   * Returns the Date object created by react-datepicker converted into a Luxon DateTime
   */
  DateTime.local(
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds(),
  );

export const getDatePickerDateFromISO = (isoDate: string) =>
  /**
   * Returns the date and time passed in in the browser's timezone, but without shifting the hour.
   * This is because react-datepicker treats the date as one in the current timezone, but we
   * treat dates as UTC. Shifting the time to the current timezone here means that when a user
   * selects 4:00 1/1 (UTC) in the date picker, it gets passed to the date picker as 4:00 1/1 (EST)
   */
  dateTimeFromISOString(isoDate)
    .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone, { keepLocalTime: true })
    .toJSDate();

export const getTimezoneOptions = (): SelectedDropdownInputItem[] => {
  const raw_timezones = getTimeZones({ includeUtc: true });
  const timezones_by_alternative_name = groupBy(
    raw_timezones,
    (timezone) => timezone.alternativeName,
  );
  return raw_timezones
    .sort((a, b) => a.rawOffsetInMinutes - b.rawOffsetInMinutes)
    .map((timezone) => {
      // timezone.name is always something like Country/More Specific/(optional) More Specific.
      // We just want the last, most specific part
      const region = timezone.name.split('/').pop()?.replace('_', ' ');
      // if there are multiple timezones with this alternative name (like Eastern Time),
      // then we want to also show the more specific region for this option
      const name =
        `(GMT${timezone.rawFormat.split(' ')[0]}) ${timezone.alternativeName}` +
        (timezones_by_alternative_name[timezone.alternativeName].length > 1 ? ` - ${region}` : '');
      return {
        name: name,
        id: timezone.name,
      };
    });
};

// Since our dashboard assumes dates are in UTC we convert all relative
// and range dates back to UTC but keeping the local time.
export const zoneToUtc = (date: DateTime): DateTime => {
  return date.setZone('UTC', { keepLocalTime: true });
};
