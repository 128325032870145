import { FC } from 'react';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { makeStyles } from '@material-ui/styles';
import cx from 'classnames';

import { Icon } from 'components/ds';
import Button from 'shared/Button';

import { LinkFormat } from 'constants/types';

const useStyles = makeStyles(() => ({
  customCompact: { '&.bp3-button': { padding: 0 } },
  reducedOpacity: { opacity: 0.75 },
}));

type Props = {
  linkFormat?: LinkFormat;
  reduceOpacity?: boolean;
};

export const UrlClickThroughButton: FC<Props> = ({ linkFormat, reduceOpacity }) => {
  const classes = useStyles();

  if (!linkFormat?.link || !linkFormat?.url) return null;

  return (
    <Button
      minimal
      className={cx(classes.customCompact, {
        [classes.reducedOpacity]: reduceOpacity,
      })}
      href={linkFormat.url}
      icon={
        <Icon className={GLOBAL_STYLE_CLASSNAMES.text.secondaryColor.color} name="arrow-up-right" />
      }
      openLinkInNewTab={linkFormat?.openInNewTab}
    />
  );
};
