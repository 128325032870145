import { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';

import {
  sidebarReportPath,
  sidebarReportFilledPath,
  sidebarDashboardsPath,
  sidebarDashboardsFilledPath,
  sidebarArchitectPath,
  sidebarArchitectFilledPath,
} from './paths';

const generateIcon = (name: string, width: number, height: number, svgPath: string) => {
  const aliases: string[] = [];
  const unicode = '';

  return {
    prefix: 'ex' as IconPrefix,
    iconName: name as IconName,
    icon: [width, height, aliases, unicode, svgPath],
  } as IconDefinition;
};

export const exSidebarReport = generateIcon('sidebar-report', 20, 20, sidebarReportPath);
export const exSidebarReportFilled = generateIcon(
  'sidebar-report-filled',
  20,
  20,
  sidebarReportFilledPath,
);

export const exSidebarDashboards = generateIcon(
  'sidebar-dashboards',
  20,
  20,
  sidebarDashboardsPath,
);

export const exSidebarDashboardsFilled = generateIcon(
  'sidebar-dashboards-filled',
  20,
  20,
  sidebarDashboardsFilledPath,
);

export const exSidebarArchitect = generateIcon('sidebar-architect', 20, 20, sidebarArchitectPath);

export const exSidebarArchitectFilled = generateIcon(
  'sidebar-architect-filled',
  20,
  20,
  sidebarArchitectFilledPath,
);

// TODO: Remove once BP not needed
export const exBlank = generateIcon('blank', 0, 0, '');
