import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as customerReportActions from 'actions/customerReportActions';
import { toggleFavoriteBuiltInSuccess } from 'actions/customerReportBuilderMetadata';

import { EmbedReportBuilder } from 'actions/reportBuilderActions';
import { EmbedReportBuilderVersion } from 'actions/reportBuilderVersionActions';

import { GlobalStyleConfig } from 'globalStyles/types';
import { ReportBuilderTab } from 'pages/ReportBuilder/HomeView/constants';
import * as navUtils from 'pages/ReportBuilder/utils/nagivationUtils';
import * as RD from 'remotedata';
import { clearSelectedReport } from './reportEditingReducer';

type ReportBuilderEmbedView = 'iframe' | 'component';

interface EmbeddedReportBuilderReducerState {
  embedView: ReportBuilderEmbedView;
  reportBuilder: RD.ResponseData<EmbedReportBuilder>;
  reportBuilderVersion: EmbedReportBuilderVersion | null;
  reports: customerReportActions.CustomerReport[];
  styleConfig: GlobalStyleConfig | null;
  selectedBuiltInId: string | undefined;
  team: { id: number; name: string } | null;
  requestInfo: { customerToken: string; embedId: string; versionNumber: number };
  isPreviewPage: boolean;
  selectedTab: ReportBuilderTab;
  favoriteBuiltIns: string[]; // List of Built-In Report Ids
}

const initialState: EmbeddedReportBuilderReducerState = {
  embedView: 'component',
  reportBuilder: RD.Idle(),
  reportBuilderVersion: null,
  reports: [],
  selectedBuiltInId: undefined,
  selectedTab: ReportBuilderTab.ALL_REPORTS,
  styleConfig: null,
  team: null,
  requestInfo: { customerToken: '', embedId: '', versionNumber: -1 },
  isPreviewPage: false,
  favoriteBuiltIns: [],
};

const embeddedReportBuilderSlice = createSlice({
  name: 'embeddedReportBuilder',
  initialState,
  reducers: {
    clearEmbeddedReportBuilderReducer: () => {
      return initialState;
    },
    setEmbedView: (state, { payload }: PayloadAction<ReportBuilderEmbedView>) => {
      state.embedView = payload;
    },
    setVersionForPreview: (state, { payload }: PayloadAction<EmbedReportBuilderVersion>) => {
      state.reportBuilderVersion = payload;
      state.isPreviewPage = true;
    },
    clearVersionForPreview: (state) => {
      state.reportBuilderVersion = null;
    },
    setSelectedTab: (state, { payload }: PayloadAction<{ tab: ReportBuilderTab }>) => {
      state.selectedTab = payload.tab;
      navUtils.goToHomePageTab(payload.tab);
    },
    setSelectedBuiltInReport: (state, { payload }: PayloadAction<string | undefined>) => {
      state.selectedBuiltInId = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(customerReportActions.fetchCustomerReportsRequest, (state) => {
        state.reportBuilder = RD.Loading();
      })
      .addCase(customerReportActions.fetchCustomerReportsSuccess, (state, { payload }) => {
        state.reportBuilder = RD.Success(payload.report_builder);
        state.reportBuilderVersion = payload.report_builder_version;
        state.reports = payload.reports;
        state.styleConfig = payload.style_config;
        state.team = payload.team;
        state.favoriteBuiltIns = payload.metadata.favorite_built_ins;
        state.requestInfo = {
          customerToken: payload.customer.token,
          embedId: payload.report_builder.embed_id,
          versionNumber: payload.report_builder_version.version_number,
        };
      })
      .addCase(customerReportActions.fetchCustomerReportsError, (state) => {
        state.reportBuilder = RD.Error('Error Loading Report Builder');
      })
      .addCase(customerReportActions.createCustomerReportSuccess, (state, { payload }) => {
        state.selectedBuiltInId = undefined;
        state.reports.push(payload.report);
      })
      .addCase(customerReportActions.starCustomerReportSuccess, (state, { payload }) => {
        state.reports.forEach((report) => {
          if (report.id === payload.report_id) report.is_starred = !report.is_starred;
        });
      })
      .addCase(customerReportActions.deleteCustomerReportSuccess, (state, { payload }) => {
        state.reports = state.reports.filter((report) => report.id !== payload.report_id);
      })
      .addCase(customerReportActions.saveCustomerReportSuccess, (state, { payload }) => {
        state.reports = state.reports.filter((report) => report.id !== payload.report.id);
        state.reports.push(payload.report);
      })
      .addCase(customerReportActions.updateCustomerReportNameSuccess, (state, { payload }) => {
        state.reports.forEach((report) => {
          if (report.id === payload.report_id) report.name = payload.name;
        });
      })
      .addCase(clearSelectedReport, (state) => {
        navUtils.goToHomePageTab(state.selectedTab);
      })
      .addCase(toggleFavoriteBuiltInSuccess, (state, { payload }) => {
        state.favoriteBuiltIns = payload.favorite_built_ins;
      });
  },
});

export const {
  setSelectedTab,
  setSelectedBuiltInReport,
  setVersionForPreview,
  clearVersionForPreview,
  clearEmbeddedReportBuilderReducer,
  setEmbedView,
} = embeddedReportBuilderSlice.actions;

export const embeddedReportBuilderReducer = embeddedReportBuilderSlice.reducer;

export const getSelectedEmbedBuiltIn = createSelector(
  (state: EmbeddedReportBuilderReducerState) => state.reportBuilderVersion,
  (state: EmbeddedReportBuilderReducerState) => state.selectedBuiltInId,
  (reportBuilder, selectedBuiltInId) => {
    if (!reportBuilder || !selectedBuiltInId) return;
    return reportBuilder.config.builtInReports?.[selectedBuiltInId];
  },
);
