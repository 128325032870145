import { FC, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import { Button, Intent, sprinkles, Tag, Tooltip } from 'components/ds';
import { ModalViews } from 'pages/ReportBuilder/ModalViews';
import { ReportName } from 'pages/ReportBuilder/ReportName';
import { ReportSaveStatus } from 'pages/ReportBuilder/ReportSaveStatus';
import { NoDataSelected } from 'pages/ReportBuilder/ReportView/NoDataSelected';
import { ViewTabs } from 'pages/ReportBuilder/ReportView/Tabs/ViewTabs';

import { BuiltInReportConfig } from 'actions/reportBuilderConfigActions';
import { setSelectedBuiltInReport } from 'reportBuilderContent/reducers/embeddedReportBuilderReducer';
import {
  openCustomerReport,
  openDataModal,
  openModalType,
  ReportModal,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { isEqual } from 'utils/standard';

import * as styles from './PreviewBuiltIn.css';

interface Props {
  builtInReport: BuiltInReportConfig;
}

export const PreviewBuiltIn: FC<Props> = ({ builtInReport }) => {
  const dispatch = useDispatch();

  const { currentConfig, selectedTab, embedView } = useSelector(
    (state: ReportBuilderReduxState) => ({
      currentConfig: state.reportEditing.currentConfig,
      selectedTab: state.embeddedReportBuilder.selectedTab,
      embedView: state.embeddedReportBuilder.embedView,
    }),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(
      openCustomerReport({
        id: 0,
        modified: '',
        is_starred: false,
        config: builtInReport.config,
        name: builtInReport.name,
      }),
    );
  }, [dispatch, builtInReport.name, builtInReport.config]);

  const isSaved = useMemo(
    () => isEqual(currentConfig, builtInReport.config),
    [currentConfig, builtInReport],
  );

  const createReport = () => dispatch(openModalType(ReportModal.SAVE_BUILT_IN_AS_REPORT));

  const handleBack = () =>
    isSaved
      ? dispatch(setSelectedBuiltInReport())
      : dispatch(openModalType(ReportModal.CONFIRM_CLOSE_REPORT));

  return (
    <div
      className={cx(
        styles.rootClass,
        sprinkles({ height: embedView === 'iframe' ? 'fillViewport' : 'fill' }),
      )}>
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <div className={styles.backContainer}>
            <Button icon="arrow-left" onClick={handleBack} type="tertiary">
              {selectedTab}
            </Button>
          </div>
          <div className={styles.header}>
            <div className={styles.headerText}>
              <ReportName disabled value={builtInReport.name} />
              <Tag className={sprinkles({ whiteSpace: 'nowrap' })} intent={Intent.ACTIVE}>
                Built In Report
              </Tag>
              <ReportSaveStatus isSaved={isSaved} />
            </div>
            <div className={styles.headerButtons}>
              <Button icon="pencil" onClick={() => dispatch(openDataModal())} type="secondary">
                Edit Data
              </Button>
              <Tooltip text="Save to a new Report">
                <Button onClick={createReport} type="secondary">
                  Save As
                </Button>
              </Tooltip>
            </div>
          </div>
        </div>
        {!currentConfig?.dataInfo ? (
          <NoDataSelected />
        ) : (
          <ViewTabs
            dataInfo={currentConfig.dataInfo}
            savedViews={builtInReport.config.views || []}
            views={currentConfig.views || []}
          />
        )}
      </div>
      <ModalViews reportName={builtInReport.name} />
    </div>
  );
};
