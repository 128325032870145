import { ReactNode, useEffect, useState } from 'react';
import cx from 'classnames';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import { Icon } from '@blueprintjs/core';
import { OnboardingStepData } from 'actions/userActions';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  stepContainer: {
    backgroundColor: theme.palette.ds.white,
    border: '1px solid',
    borderColor: theme.palette.ds.grey400,
    borderRadius: 8,
    marginBottom: theme.spacing(2),
    overflow: 'hidden',
    width: '100%',
  },
  stepHeader: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(4),
  },
  stepTitle: {
    fontSize: 20,
    fontWeight: 600,
  },
  checkmark: {
    borderRadius: '100%',
    padding: 4,
    backgroundColor: theme.palette.ds.grey400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(4),

    '&.completed': {
      backgroundColor: theme.palette.ds.green,
    },
  },
  progressContainer: {
    width: '100%',
    height: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.ds.grey400}`,
    borderBottom: `1px solid ${theme.palette.ds.grey400}`,
    position: 'relative',
  },
  progressBg: {
    backgroundColor: theme.palette.ds.grey200,
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  progressFill: {
    backgroundColor: theme.palette.ds.green,
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
  },
  stepBody: {
    alignItems: 'flex-start',
    backgroundColor: 'white',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(8),
  },
}));

type StepProps = {
  children: ReactNode;
  step: OnboardingStepData;
  stepExpanded: boolean;
  title: string;
};

export default function OnboardingStep(props: StepProps) {
  const { children, title, step, stepExpanded } = props;
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState(stepExpanded || location.hash.includes(step.slug));

  const progressFill =
    (step.sub_steps.filter((subStep) => subStep.complete).length / step.sub_steps.length) * 100;
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    if (location.hash) setIsExpanded(location.hash.includes(step.slug));
  }, [location, step.slug]);

  return (
    <div className={classes.stepContainer}>
      <div className={classes.stepHeader} onClick={() => setIsExpanded(!isExpanded)}>
        <div className={cx(classes.checkmark, { completed: step.complete })}>
          <Icon color={theme.palette.ds.white} icon="tick" iconSize={24} />
        </div>
        <h2 className={classes.stepTitle}>{title}</h2>
      </div>
      <div className={classes.progressContainer}>
        <div className={classes.progressBg} />
        <div className={classes.progressFill} style={{ width: `${progressFill}%` }} />
      </div>

      {isExpanded ? <div className={classes.stepBody}>{children}</div> : null}
    </div>
  );
}
