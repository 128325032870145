import { DateTime } from 'luxon';

import { CategoryChartColumnInfo, FilterClause, FilterValueSourceType } from 'constants/types';
import { FILTER_OPS_DATE_RANGE_PICKER, FilterOperator } from 'types/filterOperations';
import { DataPanelLink, getPossibleLinksForDataPanel } from './filterLinking';
import { DataPanel, ResourceDataset } from 'types/exploResource';
import { DashboardElement, DashboardVariableMap } from 'types/dashboardTypes';
import { PIVOT_AGG_TYPES } from 'types/dateRangeTypes';
import { DATE } from 'constants/dataConstants';

export const formatSmartBucket = (
  dp: DataPanel,
  datasets: Record<string, ResourceDataset>,
  dashboardElements: DashboardElement[],
  variables: DashboardVariableMap,
  filterClauses: FilterClause[] | undefined,
  column: CategoryChartColumnInfo,
): void => {
  const dateRangeLinks = getPossibleLinksForDataPanel(dp, datasets, dashboardElements).filter(
    (link) => link.applied && link.operator === FilterOperator.DATE_IS_BETWEEN,
  );

  const dates = getDateRangePickerTimeSpan(filterClauses, dateRangeLinks, variables);
  replaceSmartGrouping(column, dates);
};

type Dates = { startDate: DateTime; endDate: DateTime };

const getDateRangePickerTimeSpan = (
  filterClauses: FilterClause[] | undefined,
  dateRangeLinks: DataPanelLink[],
  variables: DashboardVariableMap,
): Dates | undefined => {
  // Finds intersecting time span between date range picker
  let startDate: DateTime | undefined = undefined;
  let endDate: DateTime | undefined = undefined;
  const filterVars: string[] = [];

  filterClauses?.forEach((filter) => {
    const filterOpId = filter.filterOperation?.id;
    const filterVarId = filter.filterValueVariableId;
    if (
      filter.filterValue !== undefined &&
      filterVarId &&
      filterOpId &&
      FILTER_OPS_DATE_RANGE_PICKER.has(filterOpId) &&
      filter.filterValueSource === FilterValueSourceType.VARIABLE
    ) {
      filterVars.push(filterVarId);
    }
  });

  dateRangeLinks.forEach((link) => filterVars.push(link.elementName));

  filterVars.forEach((filterVarId) => {
    const dateRangeValue = variables[filterVarId] as { startDate: DateTime; endDate: DateTime };
    if (dateRangeValue !== undefined) {
      if (startDate === undefined || dateRangeValue.startDate > startDate) {
        startDate = dateRangeValue.startDate;
      }
      if (endDate === undefined || dateRangeValue.endDate < endDate) {
        endDate = dateRangeValue.endDate;
      }
    }
  });

  return startDate && endDate ? { startDate, endDate } : undefined;
};

const oneDay = 24 * 60 * 60;
const threeDays = oneDay * 3;
const twoWeeks = oneDay * 14;
const twoMonths = oneDay * 60;
const twoYears = oneDay * 365 * 2;

const replaceSmartGrouping = (column: CategoryChartColumnInfo, dates: Dates | undefined) => {
  if (dates === undefined) {
    column.bucket = PIVOT_AGG_TYPES.DATE_MONTH;
    return;
  }

  const timeDiff = (dates.endDate.valueOf() - dates.startDate.valueOf()) / 1000;

  let bucket = PIVOT_AGG_TYPES.DATE_YEAR;
  let startTime: DateTime | undefined;
  let endTime: DateTime | undefined;

  if (timeDiff < threeDays && column.column.type !== DATE) bucket = PIVOT_AGG_TYPES.DATE_HOUR;
  else if (timeDiff < twoWeeks) {
    bucket = PIVOT_AGG_TYPES.DATE_DAY;
    startTime = dates.startDate;
    endTime = dates.endDate.startOf('day');
  } else if (timeDiff < twoMonths) {
    bucket = PIVOT_AGG_TYPES.DATE_WEEK;
    startTime = dates.startDate.startOf('week');
    endTime = dates.endDate.startOf('week');
  } else if (timeDiff < twoYears) {
    bucket = PIVOT_AGG_TYPES.DATE_MONTH;
    startTime = dates.startDate.startOf('month');
    endTime = dates.endDate.startOf('month');
  }

  if (startTime && endTime) {
    column.smartBucketInfo = {
      startTime: startTime.startOf('day').toMillis(),
      endTime: endTime.startOf('day').toMillis(),
    };
  }
  column.bucket = { id: bucket.id, name: bucket.name };
};
