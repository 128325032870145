import { makeStyles, Theme } from '@material-ui/core/styles';
import { FC, useContext } from 'react';

import { EXPORT_SVG_ICON } from 'constants/iconConstants';
import Button from 'shared/Button';
import ExportOptions, {
  DownloadFunc,
  DownloadSpreadsheetFunc,
} from 'components/ExportOptions/ExportOptions';

import { GlobalStylesContext } from 'globalStyles';
import { Popover } from 'components/ds';
import { useDownload } from 'utils/exportUtils';

const useStyles = makeStyles((theme: Theme) => ({
  exportButton: {
    marginLeft: theme.spacing(3),
    boxShadow: 'none !important',
    '& .bp3-spinner .bp3-spinner-track': {
      stroke: theme.palette.ds.grey100,
    },
    '& .bp3-button': {
      borderRadius: '8px !important',
    },
  },
}));

type Props = {
  dataPanelId: string;
  disabled?: boolean;
  disablePdfDownload?: boolean;
  disableDownloadExport?: boolean;
  enableEmailExport?: boolean;
  onDownloadPanelSpreadsheet: DownloadSpreadsheetFunc;
  onDownloadPanelPdf?: DownloadFunc;
  shouldUseJobQueue?: boolean;
  supportEmail?: string;
};

export const DownloadChartButton: FC<Props> = ({
  dataPanelId,
  disabled,
  disablePdfDownload,
  disableDownloadExport,
  enableEmailExport,
  onDownloadPanelSpreadsheet,
  onDownloadPanelPdf,
  shouldUseJobQueue,
  supportEmail,
}) => {
  const classes = useStyles();

  const { globalStyleConfig } = useContext(GlobalStylesContext);
  const { windowState, downloadInfo } = useDownload(dataPanelId);

  if (disableDownloadExport && !enableEmailExport) return null;

  return (
    <Popover
      align="end"
      side="top"
      trigger={
        <span>
          <Button
            minimal
            className={classes.exportButton}
            disabled={disabled}
            icon={EXPORT_SVG_ICON(globalStyleConfig.text.secondaryColor)}
          />
        </span>
      }
      width="medium">
      <ExportOptions
        dataPanelId={dataPanelId}
        disableDataPanelPdfDownload={disablePdfDownload}
        disableDownloadExport={disableDownloadExport}
        downloadInfo={downloadInfo}
        enableEmailExport={enableEmailExport}
        onDownloadPanelPdf={onDownloadPanelPdf}
        onDownloadPanelSpreadsheet={onDownloadPanelSpreadsheet}
        shouldUseJobQueue={shouldUseJobQueue}
        supportEmail={supportEmail}
        windowState={windowState}
      />
    </Popover>
  );
};
