import { FC, useContext } from 'react';
import { times } from 'utils/standard';
import { makeStyles, Theme } from '@material-ui/core/styles';

import BaseDataTable from 'components/dataTable/baseDataTable';
import PDFLayout from './PDFLayout';
import PDFTableFooter from './PDFTableFooter';
import PDFHeader from './PDFHeader';

import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { UserTransformedSchema } from 'constants/types';
import { getMetricsByColumn, removeUserDisabledColumns } from 'utils/dashboardUtils';
import { GlobalStyleConfig } from 'globalStyles/types';
import { GlobalStylesContext, GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

const ROWS_PER_PAGE_NORMAL = 20;
const ROWS_PER_PAGE_PDF = 17;

const useStyles = makeStyles((theme: Theme) => ({
  pageContent: {
    padding: theme.spacing(5),
  },
  tableName: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingLeft: 0,
    fontSize: 20,
    fontWeight: 600,
  },
  table: (styleConfig: GlobalStyleConfig) => ({
    border: `1px solid ${styleConfig.container.outline.color}`,
  }),
}));

type Props = {
  dataPanelTemplate: DataPanelTemplate;
  defaultUserTransformedSchema: UserTransformedSchema;
  processTemplateValues: (text: string) => string;
  reportTitle: string;
};

const PDFExportTableView: FC<Props> = ({
  dataPanelTemplate,
  defaultUserTransformedSchema,
  processTemplateValues,
  reportTitle,
}) => {
  const context = useContext(GlobalStylesContext);
  const classes = useStyles(context.globalStyleConfig);

  const visualizeOperation = dataPanelTemplate.visualize_op;
  let numRows = ROWS_PER_PAGE_NORMAL;
  if (visualizeOperation.generalFormatOptions?.export?.pdfFormat?.headerEnabled) {
    numRows = ROWS_PER_PAGE_PDF;
  }

  const tableName = processTemplateValues(reportTitle);
  const rows = dataPanelTemplate._rows || [];
  const numPages = Math.ceil(rows.length / numRows);

  return (
    <PDFLayout className={GLOBAL_STYLE_CLASSNAMES.base.backgroundColor.backgroundColor}>
      {times(numPages, (i) => {
        const startRow = i * numRows;
        const currRows = rows.slice(i * numRows, (i + 1) * numRows);

        return (
          <div className={classes.pageContent} key={`${tableName}-${i}`}>
            {visualizeOperation.generalFormatOptions?.export?.pdfFormat?.headerEnabled && (
              <PDFHeader
                pdfConfig={visualizeOperation.generalFormatOptions?.export?.pdfFormat || {}}
                processTemplateValues={processTemplateValues}
              />
            )}
            <div className={classes.tableName}>{tableName}</div>
            <BaseDataTable
              disableRowHeader
              fill
              isDashboardTable
              isPDFTable
              isSortable
              noBorderRadius
              shouldTruncateText
              truncateEmptyRowSpace
              unrestrictedHeight
              useFriendlyNameForHeader
              className={classes.table}
              columnWidths={visualizeOperation.instructions.VISUALIZE_TABLE.columnWidths}
              loading={false}
              maxRows={numRows}
              metricsByColumn={getMetricsByColumn(dataPanelTemplate._secondaryData || [])}
              rows={currRows}
              schema={removeUserDisabledColumns(defaultUserTransformedSchema)}
              schemaDisplayOptions={
                visualizeOperation.instructions.VISUALIZE_TABLE.schemaDisplayOptions
              }
            />
            <PDFTableFooter
              numCurrentRows={currRows.length}
              numPages={numPages}
              pageNumber={i + 1}
              startRow={startRow + 1}
              totalRows={rows.length}
            />
          </div>
        );
      })}
    </PDFLayout>
  );
};

export default PDFExportTableView;
