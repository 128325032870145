import { FC } from 'react';

import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';
import DropdownSelect from 'shared/DropdownSelect';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import DateFormatDescriptiveText from '../../pages/dashboardPage/DataPanelConfigV2/FormatConfigTab/DateFormatDescriptiveText';
import { sprinkles } from 'components/ds';

import { DatasetColumn } from 'types/datasets';
import {
  DateDisplayFormat,
  DateDisplayFormatToLuxon,
  DateDisplayOptions,
  OPERATION_TYPES,
} from 'constants/types';
import { ORDERED_ALIGNMENTS } from 'constants/dataConstants';
import { getCellAlignment } from 'components/dataTable/utils';
import { getCurrentDateFormat } from 'utils/formatConfigUtils';

const inputClass = sprinkles({ marginTop: 'sp1' });

type Props = {
  column: DatasetColumn;
  displayOptions: DateDisplayOptions | undefined;
  operationType:
    | OPERATION_TYPES.VISUALIZE_TABLE
    | OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST
    | 'REPORT_BUILDER';

  updateDateOptions: (newFields: DateDisplayOptions) => void;
};

export const DateFormatConfig: FC<Props> = ({
  updateDateOptions,
  column,
  displayOptions,
  operationType,
}) => {
  const currentFormat = getCurrentDateFormat(displayOptions, column.type);

  const currentAlignment = getCellAlignment(displayOptions, column.type);
  return (
    <>
      <DropdownSelect
        fillWidth
        minimal
        containerClassName={inputClass}
        label="Format"
        onChange={(item) => {
          if (currentFormat === item.id) return;
          updateDateOptions({ format: item.id as DateDisplayFormat });
        }}
        options={Object.values(DateDisplayFormat).map((format) => ({
          id: format,
          name: DateDisplayFormatToLuxon[format],
        }))}
        selectedItem={{ id: currentFormat, name: DateDisplayFormatToLuxon[currentFormat] }}
      />
      {currentFormat === DateDisplayFormat.CUSTOM ? (
        <InputWithBlurSave
          fillWidth
          containerClassName={inputClass}
          descriptiveText={<DateFormatDescriptiveText />}
          initialValue={displayOptions?.customFormat}
          label="Custom format"
          onNewValueSubmitted={(newFormat) => updateDateOptions({ customFormat: newFormat })}
        />
      ) : null}
      {
        // TODO: Implement alignment for collapsible lists
        operationType !== OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST ? (
          <ToggleButtonGroup fillWidth className={inputClass} label="Alignment">
            {ORDERED_ALIGNMENTS.map((alignment) => (
              <ToggleButton
                active={currentAlignment === alignment.id}
                icon={alignment.icon}
                key={`alignment-${alignment.id}-${column.name}`}
                onClick={() => updateDateOptions({ alignment: alignment.id })}
              />
            ))}
          </ToggleButtonGroup>
        ) : null
      }
    </>
  );
};
