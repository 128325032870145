import { Carousel } from 'components/Carousel';
import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReportBuilderTab } from 'pages/ReportBuilder/HomeView/constants';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { BuiltInReportCard } from 'pages/ReportBuilder/HomeView/BuiltInReportCard';
import { Button, sprinkles } from 'components/ds';

import { setSelectedTab } from 'reportBuilderContent/reducers/embeddedReportBuilderReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';

import * as styles from './BuiltInReportCarousel.css';

const NUM_VISIBLE = 4;

interface Props {
  reportBuilderEmbedId: string;
}

export const BuiltInReportCarousel: FC<Props> = ({ reportBuilderEmbedId }) => {
  const dispatch = useDispatch();

  const builtInReports = useSelector(
    (state: ReportBuilderReduxState) =>
      state.embeddedReportBuilder.reportBuilderVersion?.config.builtInReports,
  );

  const sortedBuiltIns = useMemo(() => {
    const builtIns = Object.values(builtInReports || {});
    return builtIns.slice(0, NUM_VISIBLE);
  }, [builtInReports]);

  if (!sortedBuiltIns.length) return null;

  return (
    <div
      className={sprinkles({
        paddingY: 'sp2',
        backgroundColor: 'elevationMid',
      })}>
      <div
        className={sprinkles({
          flexItems: 'alignCenter',
          justifyContent: 'space-between',
          paddingX: 'sp8',
        })}>
        <EmbedText
          className={sprinkles({ truncateText: 'ellipsis', marginX: 'sp.5' })}
          color="contentPrimary"
          heading="h3">
          Get started with these Built In Reports:
        </EmbedText>
        <Button
          linkIcon
          onClick={() => dispatch(setSelectedTab({ tab: ReportBuilderTab.BUILT_IN_REPORTS }))}
          type="tertiary">
          See all Built In Reports
        </Button>
      </div>
      <Carousel
        className={styles.builtInReportCarousel}
        itemsClassName={styles.builtInReportList}
        scrollAmount={300}>
        {sortedBuiltIns.map((builtIn) => (
          <BuiltInReportCard
            builtInReport={builtIn}
            className={styles.builtInReportCard}
            key={builtIn.id}
            reportBuilderEmbedId={reportBuilderEmbedId}
          />
        ))}
      </Carousel>
    </div>
  );
};
