import { useCallback, useContext, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Icon } from 'components/ds';
import { Popover, Position } from '@blueprintjs/core';

import Button from 'shared/Button';
import FilterDropdownMenu from './FilterDropdownMenu';

import DashboardLayoutContext from 'components/DashboardLayout/DashboardLayoutContext';
import { DatasetSchema, DatasetColumn } from 'types/datasets';
import { EMPTY_FILTER_CLAUSE } from 'constants/dataPanelEditorConstants';
import { FilterClause, FilterOperationInstructions, FilterValueType } from 'constants/types';
import { isFilterClauseIncomplete, listsAreDifferent } from 'utils/dataPanelConfigUtils';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { FilterOperator } from 'types/filterOperations';

const useStyles = makeStyles((theme: Theme) => ({
  popoverContainer: {
    '& .bp3-popover.bp3-minimal': {
      margin: `${theme.spacing(1)}px 0px 0px !important`,
    },

    '& .bp3-popover': {
      boxShadow: 'none',
    },
  },
  selectorButton: {
    boxShadow: 'none !important',
    height: 32,

    '& .bp3-spinner .bp3-spinner-track': {
      stroke: theme.palette.ds.grey100,
    },
    '&.bp3-button': {
      '&:hover': {
        backgroundColor: theme.palette.ds.grey200,
      },
      '&:active': {
        backgroundColor: theme.palette.ds.grey200,
      },
    },
  },
}));

type Props = {
  adHocFilterInfo?: FilterOperationInstructions;
  error?: boolean;
  loading?: boolean;
  onAdHocFilterInfoUpdate: (adHocFilterInfo: FilterOperationInstructions) => void;
  openPopoverToRight: boolean;
  schema: DatasetSchema;
};

export default function FilterButton({
  adHocFilterInfo,
  error,
  loading,
  onAdHocFilterInfoUpdate,
  openPopoverToRight,
  schema,
}: Props) {
  const { dashboardLayoutTagId } = useContext(DashboardLayoutContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [filterConfigs, setFilterConfigs] = useState<FilterClause[]>(
    adHocFilterInfo?.filterClauses?.length
      ? adHocFilterInfo.filterClauses
      : [{ ...EMPTY_FILTER_CLAUSE }],
  );
  const classes = useStyles();

  const getValidFilters = useCallback(() => {
    return filterConfigs.filter((filter) => !isFilterClauseIncomplete(filter));
  }, [filterConfigs]);

  useEffect(() => {
    const validFilters = getValidFilters();
    if (adHocFilterInfo && listsAreDifferent(adHocFilterInfo.filterClauses, validFilters)) {
      adHocFilterInfo.filterClauses = validFilters;
      onAdHocFilterInfoUpdate(adHocFilterInfo);
    }
  }, [filterConfigs, onAdHocFilterInfoUpdate, adHocFilterInfo, getValidFilters]);

  const onColumnSelect = (column: DatasetColumn, idx: number) => {
    setFilterConfigs((prevState) => {
      const newState = [...prevState];
      if (column.type !== prevState[idx].filterColumn?.type) {
        newState[idx] = { ...EMPTY_FILTER_CLAUSE, filterColumn: column };
      } else {
        newState[idx] = { ...newState[idx], filterColumn: column };
      }
      return newState;
    });
  };
  const onOperatorSelect = (operator: FilterOperator, idx: number) => {
    setFilterConfigs((prevState) => {
      const newState = [...prevState];
      newState[idx] = { ...newState[idx], filterOperation: { id: operator } };
      return newState;
    });
  };
  const onFilterValueUpdate = (value: FilterValueType, idx: number) => {
    setFilterConfigs((prevState) => {
      const newState = [...prevState];
      newState[idx] = { ...newState[idx], filterValue: value };
      return newState;
    });
  };
  const addFilter = () => {
    setFilterConfigs((prevState) => [...prevState, { ...EMPTY_FILTER_CLAUSE }]);
  };
  const deleteFilter = (idx: number) => {
    setFilterConfigs((prevState) => {
      const newState = [...prevState];
      newState.splice(idx, 1);
      return newState;
    });
  };

  const validFilters = getValidFilters();

  return (
    <Popover
      minimal
      content={
        <FilterDropdownMenu
          addFilter={addFilter}
          deleteFilter={deleteFilter}
          filterConfigs={filterConfigs}
          onColumnSelect={onColumnSelect}
          onFilterValueUpdate={onFilterValueUpdate}
          onOperatorSelect={onOperatorSelect}
          schema={schema}
        />
      }
      disabled={!schema}
      isOpen={isDropdownOpen}
      modifiers={{
        preventOverflow: {
          enabled: false,
        },
      }}
      onClose={(event) => {
        const e = event as typeof event & { target: { className: string[] } };
        if (e?.type === 'mousedown' && !e?.target.className.includes('react-datepicker')) {
          setIsDropdownOpen(false);
        }
      }}
      portalClassName={classes.popoverContainer}
      portalContainer={document.getElementById(dashboardLayoutTagId) ?? undefined}
      position={openPopoverToRight ? Position.BOTTOM_LEFT : Position.BOTTOM_RIGHT}>
      <Button
        compact
        minimal
        active={isDropdownOpen || validFilters.length > 0}
        className={classes.selectorButton}
        disabled={loading || !schema || (error && validFilters.length === 0)}
        icon={<Icon className={GLOBAL_STYLE_CLASSNAMES.text.secondaryColor.color} name="filter" />}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        text={validFilters.length || ''}
      />
    </Popover>
  );
}
