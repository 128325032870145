import { ReduxState } from 'reducers/rootReducer';
import { connect } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';

import CalloutLink from 'shared/CalloutLink';
import SettingsInternationalizationDropdown from './SettingsInternationalizationDropdown';

import { SelectedDropdownInputItem } from 'constants/types';
import {
  saveDefaultLocaleCode,
  saveDefaultCurrencyCode,
  saveUseBrowserLocale,
} from 'actions/teamActions';
import { Checkbox } from '@blueprintjs/core';
import { SUPPORTED_CURRENCIES, SUPPORTED_LOCALES } from 'utils/localizationUtils';
import { showErrorContactSupportToast } from 'shared/sharedToasts';

const useStyles = makeStyles((theme: Theme) => ({
  subsection: {
    fontSize: 20,
    fontWeight: 600,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  checkboxContainer: {},
  languageContainer: {
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(6),
  },
  subtitle: {
    color: theme.palette.ds.grey700,
    marginBottom: theme.spacing(3),
  },
  documentationBox: {
    marginTop: theme.spacing(8),
  },
}));

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const languageOptions = SUPPORTED_LOCALES.map((locale) => {
  return {
    name: locale.name,
    id: locale.localeCode,
  };
});

const currencyOptions = SUPPORTED_CURRENCIES.map((currency) => {
  return {
    name: currency.name,
    id: currency.currencyCode,
  };
});

function SettingsInternationalizationSection(props: Props) {
  const { team, saveDefaultLocaleCode, saveUseBrowserLocale, saveDefaultCurrencyCode } = props;
  const classes = useStyles();

  const onDefaultLanguageSelect = (item: SelectedDropdownInputItem) => {
    saveDefaultLocaleCode({ postData: { locale_code: item.id } }, undefined, (response) =>
      showErrorContactSupportToast(response.error_msg),
    );
  };

  const onDefaultCurrencySelect = (item: SelectedDropdownInputItem) => {
    saveDefaultCurrencyCode({ postData: { currency_code: item.id } }, undefined, (response) =>
      showErrorContactSupportToast(response.error_msg),
    );
  };

  const onUserBrowserLocaleClick = () => {
    if (!team) return;
    saveUseBrowserLocale(
      { postData: { setting: !team.use_browser_locale } },
      undefined,
      (response) => showErrorContactSupportToast(response.error_msg),
    );
  };

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.subsection}>Internationalization</div>
      </div>
      <div className={classes.subtitle}>
        {
          "This affects the formatting of numbers, currencies, and dates on your customer's dashboards"
        }
      </div>
      <SettingsInternationalizationDropdown
        code={team?.default_locale_code}
        containerClassName={classes.languageContainer}
        label="Default Language"
        noSelectionText="Select a Language"
        onSelect={onDefaultLanguageSelect}
        options={languageOptions}
      />
      <Checkbox
        checked={team?.use_browser_locale}
        className={classes.checkboxContainer}
        label="Use Browser Language (when possible)"
        onChange={onUserBrowserLocaleClick}
      />
      <SettingsInternationalizationDropdown
        code={team?.default_currency_code}
        containerClassName={classes.languageContainer}
        label="Default Currency"
        noSelectionText="Select a Currency"
        onSelect={onDefaultCurrencySelect}
        options={currencyOptions}
      />
      <CalloutLink
        className={classes.documentationBox}
        text="Learn more about internationalization and how it affects your dashboards"
        url="https://docs.explo.co/dashboard-features/internationalization"
      />
    </div>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  team: state.teamData.data,
});

const mapDispatchToProps = { saveDefaultLocaleCode, saveUseBrowserLocale, saveDefaultCurrencyCode };

export default connect(mapStateToProps, mapDispatchToProps)(SettingsInternationalizationSection);
