import { FC } from 'react';

import FormatSection from './FormatSection';
import { SharedFunnelChartConfigs } from 'components/ChartConfigs/FunnelChartConfigs';

import { sharedStyles } from './sharedStyles';
import { OPERATION_TYPES, V2TwoDimensionChartInstructions } from 'constants/types';
import { FormatConfigProps } from './types';

type Props = FormatConfigProps<
  OPERATION_TYPES.VISUALIZE_FUNNEL_V2,
  V2TwoDimensionChartInstructions
>;

export const FunnelChartSection: FC<Props> = ({
  isArchitectCustomerDashboard,
  instructions,
  updateInstructions,
  operationType,
}) => {
  const classes = sharedStyles({ isArchitectCustomerDashboard });

  return (
    <FormatSection isArchitectCustomerDashboard={isArchitectCustomerDashboard} title="Funnel Chart">
      <SharedFunnelChartConfigs
        configInputClass={classes.configInput}
        instructions={instructions}
        updateInstructions={(newInstructions) =>
          updateInstructions({ instructions: newInstructions, operationType })
        }
      />
    </FormatSection>
  );
};
