import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Icon, sprinkles } from 'components/ds';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { starCustomerReport } from 'actions/customerReportActions';

type Props = { isStarred: boolean; reportId: number };

export const StarStatus: FC<Props> = ({ isStarred, reportId }) => {
  const dispatch = useDispatch();

  const customerToken = useSelector(
    (state: ReportBuilderReduxState) => state.embeddedReportBuilder.requestInfo.customerToken,
  );

  return (
    <Icon
      className={sprinkles({
        cursor: 'pointer',
        color: isStarred ? 'active' : 'contentTertiary',
      })}
      name={isStarred ? 'star' : 'regular-star'}
      onClick={(e) => {
        e.stopPropagation();
        dispatch(starCustomerReport({ customerToken, postData: { report_id: reportId } }));
      }}
    />
  );
};
