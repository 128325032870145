import { FunctionComponent } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';
import { Dialog } from '@blueprintjs/core';

import FlexBox, { VerticalAlignment, HorizontalAlignment } from './FlexBox';
import Button from 'shared/Button';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.ds.white,
    borderRadius: 16,
    position: 'relative',
  },
  header: {
    padding: theme.spacing(5),
    fontWeight: 600,
    fontSize: 20,
  },
}));

type ModalProps = {
  className?: string;
  modalOpen?: boolean;
  onClose: () => void;
  portalContainerId?: string;
  title?: string;
  usePortal?: boolean;
};

const Modal: FunctionComponent<ModalProps> = (props) => {
  const { className, children, modalOpen, onClose, portalContainerId, title, usePortal } = props;
  const classes = useStyles();

  return (
    <Dialog
      className={cx(classes.root, className)}
      isOpen={modalOpen}
      onClose={onClose}
      portalContainer={
        usePortal && portalContainerId
          ? document.getElementById(portalContainerId) ?? undefined
          : undefined
      }
      usePortal={usePortal}>
      {title ? (
        <FlexBox
          className={classes.header}
          horizontalAlignment={HorizontalAlignment.SPACE_BETWEEN}
          verticalAlignment={VerticalAlignment.CENTER}>
          <div>{title}</div>
          <Button minimal icon="cross" onClick={onClose} />
        </FlexBox>
      ) : null}
      {children}
    </Dialog>
  );
};

export default Modal;
