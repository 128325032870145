import { Layout } from '@explo-tech/react-grid-layout';
import { CategoryChartColumnInfo, INPUT_TYPE } from 'constants/types';

import { DateTime } from 'luxon';
import {
  DATE_RANGE_TYPES,
  DEFAULT_DATE_RANGES,
  DEFAULT_DATE_TYPES,
  RELATIVE_DATE_OPTIONS,
  RELATIVE_DATE_RANGES,
  TrendGroupingOptions,
} from './dateRangeTypes';
import { FilterOperator } from './filterOperations';

export enum TEXT_ELEM_HORIZ_ALIGNMENTS {
  LEFT = 'LEFT_ALIGN',
  CENTER = 'CENTER_ALIGN',
  RIGHT = 'RIGHT_ALIGN',
}

export enum VERTICAL_CONTENT_ALIGNMENTS {
  TOP = 'TOP',
  CENTER = 'CENTER',
  BOTTOM = 'BOTTOM',
}

export enum TEXT_ELEM_VERTICAL_ALIGNMENTS {
  TOP = 'TOP',
  CENTER = 'CENTER',
  BOTTOM = 'BOTTOM',
}

export enum TITLE_VALUE_ARRANGEMENTS {
  ABOVE = 'TITLE_ABOVE',
  BELOW = 'TITLE_BELOW',
  FIXED_LEFT = 'TITLE_FIXED_TOP_LEFT',
  FIXED_CENTER = 'TITLE_FIXED_TOP_CENTER',
}

export enum TEXT_ELEM_SIZES {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export enum DASHBOARD_ELEMENT_TYPES {
  DATA_PANEL = 'DATA_PANEL',

  TEXT = 'TEXT',
  SPACER = 'SPACER',
  DROPDOWN = 'DROPDOWN',
  TIME_PERIOD_DROPDOWN = 'TIME_PERIOD_DROPDOWN',
  MULTISELECT = 'MULTISELECT',
  DATEPICKER = 'DATEPICKER',
  DATE_RANGE_PICKER = 'DATE_RANGE_PICKER',
  EXPORT = 'EXPORT',
  CONTAINER = 'CONTAINER',
  TOGGLE = 'TOGGLE',
  DATE_GROUP_SWITCH = 'DATE_GROUP_SWITCH',
  IMAGE = 'IMAGE',
  SWITCH = 'SWITCH',
  APPLY_FILTER_BUTTON = 'APPLY_FILTER_BUTTON',
  TEXT_INPUT = 'TEXT_INPUT',
  IFRAME = 'IFRAME',
}

export enum DASHBOARD_LAYOUT_CONFIG {
  DASHBOARD_BODY = 'DASHBOARD_BODY',
  HEADER = 'HEADER',
}

export type SELECT_FILTER_TYPE =
  | DASHBOARD_ELEMENT_TYPES.DROPDOWN
  | DASHBOARD_ELEMENT_TYPES.MULTISELECT
  | DASHBOARD_ELEMENT_TYPES.TOGGLE;

export type DATE_FILTER_TYPE =
  | DASHBOARD_ELEMENT_TYPES.DATEPICKER
  | DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER;

export type DashboardVariable =
  | string
  | number
  | string[]
  | number[]
  | undefined
  | DrilldownVariable
  | DateTime
  | { startDate: DateTime; endDate: DateTime }
  | { startDate: string; endDate: string };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DrilldownVariable = { category: any; color?: any };

export type DashboardVariableMap = Record<string, DashboardVariable>;

export type TextElemHorizAlignments =
  | TEXT_ELEM_HORIZ_ALIGNMENTS.CENTER
  | TEXT_ELEM_HORIZ_ALIGNMENTS.LEFT
  | TEXT_ELEM_HORIZ_ALIGNMENTS.RIGHT;

export type VerticalContentAlignments =
  | VERTICAL_CONTENT_ALIGNMENTS.TOP
  | VERTICAL_CONTENT_ALIGNMENTS.CENTER
  | VERTICAL_CONTENT_ALIGNMENTS.BOTTOM;

export type TextElemVerticalAlignments =
  | TEXT_ELEM_VERTICAL_ALIGNMENTS.CENTER
  | TEXT_ELEM_VERTICAL_ALIGNMENTS.TOP
  | TEXT_ELEM_VERTICAL_ALIGNMENTS.BOTTOM;

export type TitleValueArrangements =
  | TITLE_VALUE_ARRANGEMENTS.ABOVE
  | TITLE_VALUE_ARRANGEMENTS.BELOW
  | TITLE_VALUE_ARRANGEMENTS.FIXED_LEFT
  | TITLE_VALUE_ARRANGEMENTS.FIXED_CENTER;

export type TextDashboardElemConfig = {
  text?: string;
  renderMarkdown?: boolean;
  renderHTML?: boolean;
  alignmentHoriz: TextElemHorizAlignments;
  alignmentVertical: TextElemVerticalAlignments;
  textColor: string;
  textSize?: TEXT_ELEM_SIZES;
  queryTables?: { id: string; name: string }[];
};

export type DropdownValuesConfig = {
  valuesSource: INPUT_TYPE;
  queryTable?: { id: string; name: string };
  queryValueColumn?: { id: string; name: string };
  queryDisplayColumn?: { id: string; name: string };
  queryDefaultFirstValue?: boolean;
  querySortOption?: MultiSelectSortOption;
  manualValues: string;
  manualDisplays: string;
  manualDefaultValue?: string | number;
};

export type SelectElemConfig = {
  disableCancel?: boolean;
  disableOnNoItems?: boolean;
  disableSearch?: boolean;
  filterPlaceholderText?: string;
  label: string;
  placeholderText?: string;
  usePortal?: boolean;
  valuesConfig: DropdownValuesConfig;
  showTooltip?: boolean;
  infoTooltipText?: string;
};

export type TimePeriodDropdownElemConfig = {
  label: string;
  placeholderText?: string;
  enableSearch?: boolean;
  enableCancel?: boolean;
  values: { value: number; name: string }[];
  defaultValue?: number;
  usePortal?: boolean;
};

type SharedDateConfig = {
  label: string;
  disableCancel?: boolean;
  showTooltip?: boolean;
  infoTooltipText?: string;
};

export type DatepickerElemConfig = {
  hideTimeSelect?: boolean;
  defaultType?: DEFAULT_DATE_TYPES;
  dateRangeType?: DATE_RANGE_TYPES;
  defaultValue?: string | DateTime;
  relativeDefaultValue?: RELATIVE_DATE_OPTIONS;
  relativeDateRange?: RELATIVE_DATE_RANGES;
  minValue?: DateTime;
  maxValue?: DateTime;
} & SharedDateConfig;

export type DateRangePickerElemConfig = {
  defaultDateRange?: DEFAULT_DATE_RANGES;
  includeRangeDropdown?: boolean;
  excludeDatePicker?: boolean;
  usePortal?: boolean;
  endDateEndOfDay?: boolean;
  hiddenDefaultRanges?: DEFAULT_DATE_RANGES[];
  defaultRangesOrder?: DEFAULT_DATE_RANGES[];
} & SharedDateConfig;

export type DateElemConfig = DatepickerElemConfig & DateRangePickerElemConfig;

export type SwitchElementConfig = {
  infoTooltipText?: string;
  label: string;
  offStatusLabel?: string;
  offStatusValue?: string;
  onStatusLabel?: string;
  onStatusValue?: string;
  showStatusLabel: boolean;
  showSwitchLabel?: boolean;
  showTooltip?: boolean;
  defaultOn?: boolean;
};

export type ExportElemConfig = {
  label: string;
  popoverTitle?: string;
  popoverSubtitle?: string;
  passwordEnabled: boolean;
  usernameEnabled: boolean;
  siteTitle?: string;
  faviconUrl?: string;
  disableShareLink?: boolean;
  disableImageDownload?: boolean;
  disablePdfDownload?: boolean;
  downloadDashboardFileName?: string;
};

export type ContainerElemConfig = {
  layout: Layout[];
  pdfLayout?: Layout[];
  emailLayout?: Layout[];
  mobileLayout?: Layout[];
};

export type ImageElemConfig = {
  imageUrl?: string;
  fitEntireImage?: boolean;
};

export enum DateGroupToggleSelectTypes {
  DROPDOWN = 'Dropdown',
  TOGGLE = 'Toggle',
}

export type DateGroupToggleConfig = {
  label?: string;
  groupingOptionByType?: Record<string, { name?: string; exclude?: boolean }>;
  defaultGroupingOption: TrendGroupingOptions;
  filterType?: DateGroupToggleSelectTypes;
};

export type ApplyFilterElemConfig = {
  buttonText?: string;

  elementIds?: Record<string, boolean>;
  hideRefreshIcon?: boolean;
  hideNumFiltersIcon?: boolean;
};

export type TextInputElemConfig = {
  label?: string;
  placeholderText?: string;
  showSearchIcon?: boolean;
  defaultValue?: string;
  limitEntriesToNumbers?: boolean;
};

export type IframeElemConfig = {
  iframeUrl?: string;
};

type ElementDependencyConfig = {
  dependencyElementIds?: Record<string, boolean>;
  ignoreDependencies?: boolean;
};

type FilterLinkingConfig = {
  operator?: FilterOperator;
  datasetLinks?: Record<string, DatasetLinkConfig>;
};

type DatasetLinkConfig = {
  column?: string;
  dataPanels?: string[];
};

export type DashboardElementConfig = (
  | TextDashboardElemConfig
  | SelectElemConfig
  | DateElemConfig
  | ExportElemConfig
  | SwitchElementConfig
  | ContainerElemConfig
  | ImageElemConfig
  | DateGroupToggleConfig
  | TimePeriodDropdownElemConfig
  | ApplyFilterElemConfig
  | TextInputElemConfig
  | IframeElemConfig
) &
  ElementDependencyConfig &
  FilterLinkingConfig;

export interface DashboardElement {
  id: string;
  name: string;
  element_type: DASHBOARD_ELEMENT_TYPES;
  container_id?: string;
  config: DashboardElementConfig;
  elemLocation?: DASHBOARD_LAYOUT_CONFIG; // defaults to DASHBOARD_LAYOUT_CONFIG.DASHBOARD_BODY
}

export type NumberColumnMetrics = {
  min?: number;
  avg?: number;
  max?: number;
};
export interface MetricsByColumn {
  [columnName: string]: NumberColumnMetrics;
}

export enum VIEW_MODE {
  DEFAULT = 'default',
  SHARE = 'share',
  PDF = 'pdf',
  MOBILE = 'mobile',
  EMAIL = 'email',
}

export enum PAGE_TYPE {
  EXPLO_APP = 'explo app',
  EMBEDDED = 'embedded',
  SHARED = 'shared',
  END_USER_DASHBOARD = 'end user dashboard',
}

export enum MultiSelectSortOption {
  DEFAULT = 'Default',
  ASC = 'Ascending',
  DESC = 'Descending',
}

export type OpenDrilldownModalType = (
  dptId: string,
  categoryColumn?: CategoryChartColumnInfo,
  category?: string | number,
  subCategoryColumn?: CategoryChartColumnInfo,
  subCategory?: string | number,
  excludedCategories?: (string | number)[],
) => void;

export type DrilldownModalConfig = {
  isOpen?: boolean;
  categoryColumn?: CategoryChartColumnInfo;
  dataPanelTemplateId?: string;
  selectedCategory?: number | string;
  subCategoryColumn?: CategoryChartColumnInfo;
  selectedSubCategory?: number | string;
  excludedCategories?: (string | number)[];
};
