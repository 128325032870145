import { VIZ_TO_NAME } from 'constants/dataConstants';
import { OPERATION_TYPES, SelectedDropdownInputItem } from 'constants/types';
import { getChartIcon } from './icons';

const BAR_CHARTS = [
  OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2,
];

const PIE_CHARTS = [
  OPERATION_TYPES.VISUALIZE_PIE_CHART_V2,
  OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2,
];

export const BAR_CHART_SET = new Set(BAR_CHARTS);
export const PIE_CHART_SET = new Set(PIE_CHARTS);

function getDropdownOptions(charts: OPERATION_TYPES[]): SelectedDropdownInputItem[] {
  return charts.map((chart) => ({
    id: chart,
    name: VIZ_TO_NAME[chart],
    icon: getChartIcon(chart, 24) ?? undefined,
  }));
}

export function getSubChartTypeOptions(
  operationType: OPERATION_TYPES,
): SelectedDropdownInputItem[] {
  if (BAR_CHART_SET.has(operationType)) return getDropdownOptions(BAR_CHARTS);
  if (PIE_CHART_SET.has(operationType)) return getDropdownOptions(PIE_CHARTS);

  return [];
}
