import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core';
import cx from 'classnames';

import { deleteWhitelistDomain } from 'actions/whitelistDomainActions';
import { WhitelistDomain } from 'actions/teamActions';
import { showErrorContactSupportToast } from 'shared/sharedToasts';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: `0 ${theme.spacing(8)}px 125px ${theme.spacing(8)}px`,
  },
  ruleContainer: {
    '&:hover': {
      background: theme.palette.ds.grey200,
    },
    '&:hover .rule-action': {
      color: `${theme.palette.ds.red}`,
      cursor: 'pointer',
    },
    borderRadius: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing(1)}px ${theme.spacing(2.5)}px`,
    margin: `${theme.spacing(2)}px -${theme.spacing(2.5)}px 0px -${theme.spacing(2)}px`,
  },
  ruleText: {},
  ruleAction: {
    color: theme.palette.ds.grey700,
  },
}));

type Props = {
  rule: WhitelistDomain;
};

export default function SettingsDomainWhitelistRule({ rule }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [deleteClicked, setDeleteClicked] = useState(false);

  return (
    <div className={classes.ruleContainer}>
      <div className={classes.ruleText}>{rule.name}</div>
      <div
        className={cx(classes.ruleAction, 'rule-action')}
        onClick={() => {
          if (!deleteClicked) {
            dispatch(
              deleteWhitelistDomain({ id: rule.id }, undefined, (response) => {
                showErrorContactSupportToast(response.error_msg);
                setDeleteClicked(false);
              }),
            );
          }
          setDeleteClicked(true);
        }}>
        Remove
      </div>
    </div>
  );
}
