import { useState } from 'react';
import { Card, Intent } from '@blueprintjs/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';
import FlexBox, {
  FlexDirection,
  HorizontalAlignment,
  VerticalAlignment,
} from 'components/core/FlexBox';

import Button from 'shared/Button';
import InputGroup from 'explo-ds/forms/marketing/inputGroup';
import InputLabel from 'shared/InputLabel';

const useStyles = makeStyles((theme: Theme) => ({
  mainLabel: {
    fontWeight: 500,
    fontSize: 16,
    color: theme.palette.ds.black,
    marginBottom: theme.spacing(7),
  },
  card: {
    height: 238,
    width: 400,
    backgroundColor: theme.palette.ds.white,
  },
  root: { width: '100%', height: '100vh', backgroundColor: theme.palette.ds.grey100 },
  inputs: {
    height: 32,
    width: 320,
  },
  inputsContainer: {
    width: '100%',
    height: '100%',
  },
  usernameInput: {
    marginBottom: theme.spacing(2),
  },
  passwordInput: {
    marginBottom: theme.spacing(7),
  },
}));

type PassedProps = {
  fetchShareData: (password: string, onFailure: () => void, username?: string) => void;
  isUsernameProtected?: boolean;
};

type Props = PassedProps;

export default function SharePasswordRequiredPage(props: Props) {
  const [inputPassword, setInputPassword] = useState<string>('');
  const [inputUsername, setInputUsername] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [passwordVerificationFailed, setPasswordVerificationFailed] = useState<boolean>(false);

  const classes = useStyles();
  const { fetchShareData, isUsernameProtected } = props;

  const errorText = passwordVerificationFailed
    ? `Incorrect password ${isUsernameProtected ? 'or username' : ''}, please try again.`
    : undefined;

  return (
    <>
      <FlexBox
        className={classes.root}
        direction={FlexDirection.COLUMN}
        horizontalAlignment={HorizontalAlignment.CENTER}
        verticalAlignment={VerticalAlignment.CENTER}>
        <Card className={classes.card}>
          <FlexBox
            className={classes.inputsContainer}
            direction={FlexDirection.COLUMN}
            horizontalAlignment={HorizontalAlignment.CENTER}
            verticalAlignment={VerticalAlignment.CENTER}>
            <div className={classes.mainLabel}>This dashboard is password protected.</div>
            {isUsernameProtected && (
              <InputGroup
                className={cx(classes.inputs, classes.usernameInput)}
                intent={errorText ? Intent.DANGER : Intent.NONE}
                onInputChange={(newValue: string) => setInputUsername(newValue)}
                placeholder="Enter username here..."
                value={inputUsername}
              />
            )}
            <InputGroup
              className={cx(classes.inputs, classes.usernameInput)}
              intent={errorText ? Intent.DANGER : Intent.NONE}
              onInputChange={(newValue: string) => setInputPassword(newValue)}
              placeholder="Enter password here..."
              type="password"
              value={inputPassword}
            />
            {errorText ? <InputLabel error text={errorText} /> : null}
            <Button
              className={classes.inputs}
              loading={loading}
              onClick={() => {
                setLoading(true);
                fetchShareData(
                  inputPassword,
                  () => {
                    setPasswordVerificationFailed(true);
                    setLoading(false);
                  },
                  inputUsername,
                );
              }}
              text="Enter"
              type="primary"
            />
          </FlexBox>
        </Card>
      </FlexBox>
    </>
  );
}
