import { useDispatch } from 'react-redux';
import produce from 'immer';

import Button from 'shared/Button';
import { Tooltip, Position } from '@blueprintjs/core';

import SettingHeader from './SettingHeader';
import DroppableColumnSection from './vizConfigs/droppable/DroppableColumnSection';

import {
  AggedChartColumnInfo,
  V2KPITrendInstructions,
  V2KPIChartInstructions,
  OPERATION_TYPES,
  Aggregation,
} from 'constants/types';
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { DatasetSchema } from 'types/datasets';
import { getDefaultAgg } from 'utils/aggUtils';

type Props = {
  instructions: V2KPITrendInstructions | V2KPIChartInstructions;
  chartType: OPERATION_TYPES;
  loading?: boolean;
  schema: DatasetSchema;
};

export default function KPIAggregation({ instructions, chartType, loading, schema }: Props) {
  const dispatch = useDispatch();

  const aggIsCustomFormula = instructions.aggColumn?.agg.id === Aggregation.FORMULA;

  const updateAggCol = (
    updateFunc: (curr: AggedChartColumnInfo | undefined) => AggedChartColumnInfo | undefined,
  ) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.aggColumn = updateFunc(draft.aggColumn);
    });
    dispatch(updateVisualizeOperation(newInstructions, chartType));
  };

  return (
    <>
      <SettingHeader
        name="Aggregation"
        rightContent={
          <Tooltip
            usePortal
            content={
              aggIsCustomFormula ? 'Aggregate fields on the table' : 'Custom aggregation formula'
            }
            position={Position.BOTTOM}>
            <Button
              compact
              minimal
              icon={aggIsCustomFormula ? 'th' : 'function'}
              onClick={() => {
                updateAggCol(() => {
                  return aggIsCustomFormula
                    ? undefined
                    : { column: {}, agg: { id: Aggregation.FORMULA } };
                });
              }}
            />
          </Tooltip>
        }
      />
      <DroppableColumnSection
        required
        columns={instructions.aggColumn ? [instructions.aggColumn] : []}
        disableEdits={loading}
        maxCols={1}
        onColAdded={(col) => updateAggCol(() => ({ column: col, agg: getDefaultAgg(col.type) }))}
        onColOptionChanged={(option) => {
          if (aggIsCustomFormula) {
            updateAggCol(() => ({
              column: { name: 'custom_formula' },
              agg: { id: Aggregation.FORMULA, formula: option?.formula },
            }));
          } else {
            updateAggCol((aggCol) => {
              if (aggCol) aggCol.agg = { id: option.id as Aggregation };
              return aggCol;
            });
          }
        }}
        onRemoveCol={() => updateAggCol(() => undefined)}
        schema={schema}
      />
    </>
  );
}
