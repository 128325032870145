import { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { DateTime } from 'luxon';

import DropdownSelect from 'shared/DropdownSelect';
import DatePickerInput from 'shared/DatePickerInput';
import MultiSelect from 'components/multiSelect';
import DashboardDateRangePickerElement from '../dashboardElement/DashboardDateRangePickerElement';
import CustomerSelector from 'components/CustomerSelector';

import {
  DashboardElement,
  DashboardVariableMap,
  DASHBOARD_ELEMENT_TYPES,
  DatepickerElemConfig,
  SelectElemConfig,
  DateGroupToggleConfig,
  DashboardVariable,
  TimePeriodDropdownElemConfig,
  SwitchElementConfig,
  DateRangePickerElemConfig,
} from 'types/dashboardTypes';
import { DashboardParam } from 'types/dashboardVersionConfig';
import { Dataset } from 'actions/datasetActions';
import { getDateGroupSwitchOptions } from 'utils/dashboardUtils';
import DashboardVariableEntry, {
  InputType,
} from '../dashboardDatasetEditor/dashboardVariableEntry';
import { DATE_TYPES } from 'constants/dataConstants';
import { SelectedDropdownInputItem } from 'constants/types';
import { getDateMax, getDateMin } from 'utils/dateUtils';
import { dateTimeFromISOString } from 'utils/dateUtils';
import { DATE_RANGE_TYPES } from 'types/dateRangeTypes';
import { constructOptions } from 'utils/dropdownUtils';
import { updateVariableEvent } from 'utils/customEventUtils';
import { VariableSelectOptions } from 'utils/extraVariableUtils';

const useStyles = makeStyles((theme: Theme) => ({
  variablesContainer: {
    fontFamily: 'Source Code Pro',
    padding: theme.spacing(3),
    paddingBottom: 300,
  },
  variable: {
    padding: theme.spacing(1.5),
  },
  variableName: {
    fontSize: 12,
    fontFamily: 'Source Code Pro',
  },
  variableInput: {
    marginTop: theme.spacing(2),
  },
  datePickerBtn: {
    '&.bp3-button': {
      display: 'block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      // To match blueprint input
      backgroundColor: theme.palette.ds.white,
      borderRadius: 3,
      '&:hover': {
        backgroundColor: theme.palette.ds.white,
      },
    },
  },
  sectionContainer: {
    padding: `${theme.spacing(2)}px 0`,
  },
  sectionHeader: {
    color: theme.palette.ds.grey700,
    fontSize: 12,
    textTransform: 'uppercase',
    marginLeft: 6,
    marginBottom: theme.spacing(1),
    fontWeight: 500,
  },
}));

type Props = {
  variables: DashboardVariableMap;
  dashboardInputElements: DashboardElement[];
  dashboardDatasets: Record<number, Dataset>;
  dashboardParams: Record<string, DashboardParam>;
  timezone: string;
};

export const EditableVariableList: FC<Props> = ({
  variables,
  dashboardInputElements,
  dashboardDatasets,
  dashboardParams,
  timezone,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.variablesContainer}>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeader}>Customer</div>
        <div className={classes.variable}>
          <div className={classes.variableName}>customer_id</div>
          <div className={classes.variableInput}>
            <CustomerSelector />
          </div>
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeader}>Dashboard Elements</div>
        {dashboardInputElements.map((element) => {
          const value = variables[element.name];

          const selectElementValue = (
            newValue: DashboardVariable,
            options?: VariableSelectOptions,
          ) => {
            updateVariableEvent({ varName: element.name, newValue, options });
          };

          switch (element.element_type) {
            case DASHBOARD_ELEMENT_TYPES.DATEPICKER: {
              const datepickerConfig = element.config as DatepickerElemConfig;
              const { relativeDateRange } = datepickerConfig;
              return (
                <div className={classes.variable} key={`variable-${element.name}-editable`}>
                  <div className={classes.variableName}>{element.name}</div>

                  <DatePickerInput
                    withPortal
                    className={classes.variableInput}
                    maxDate={
                      datepickerConfig.dateRangeType === DATE_RANGE_TYPES.EXACT
                        ? datepickerConfig.maxValue
                        : getDateMax(relativeDateRange, timezone)
                    }
                    minDate={
                      datepickerConfig.dateRangeType === DATE_RANGE_TYPES.EXACT
                        ? datepickerConfig.minValue
                        : getDateMin(datepickerConfig.relativeDateRange, timezone)
                    }
                    onCancelClick={() => selectElementValue(undefined)}
                    onNewValueSelect={(value) => selectElementValue(value as DateTime)}
                    selectedValue={
                      typeof value === 'string' ? dateTimeFromISOString(value) : (value as DateTime)
                    }
                    showCancelBtn={!datepickerConfig.disableCancel}
                    showTimeSelect={!datepickerConfig.hideTimeSelect}
                  />
                </div>
              );
            }
            case DASHBOARD_ELEMENT_TYPES.SWITCH: {
              const switchConfig = element.config as SwitchElementConfig;
              const onLabel = switchConfig.onStatusLabel || 'true';
              const offLabel = switchConfig.offStatusLabel || 'false';
              const onOption = {
                name: onLabel,
                id: onLabel,
                value: onLabel,
              } as SelectedDropdownInputItem;
              const offOption = {
                name: offLabel,
                id: offLabel,
                value: offLabel,
              } as SelectedDropdownInputItem;
              const switchOptions = [onOption, offOption];

              return (
                <div className={classes.variable} key={`variable-${element.name}-editable`}>
                  <div className={classes.variableName}>{element.name}</div>
                  <DropdownSelect
                    fillWidth
                    minimal
                    containerClassName={classes.variableInput}
                    filterable={false}
                    noSelectionText=""
                    onCancelClick={() => selectElementValue(undefined)}
                    onChange={(item) => selectElementValue(item.value)}
                    options={switchOptions}
                    selectedItem={switchOptions.find((option) => option.value === value)}
                  />
                </div>
              );
            }

            case DASHBOARD_ELEMENT_TYPES.DROPDOWN: {
              const dropdownConfig = element.config as SelectElemConfig;
              const dropdownOptions = constructOptions(
                dropdownConfig.valuesConfig,
                dashboardDatasets,
              );
              const selectedDropdownOption = dropdownOptions.find(
                (option) => option.value === value,
              );

              return (
                <div className={classes.variable} key={`variable-${element.name}-editable`}>
                  <div className={classes.variableName}>{element.name}</div>
                  <DropdownSelect
                    fillWidth
                    filterable
                    minimal
                    containerClassName={classes.variableInput}
                    noSelectionText={dropdownConfig.placeholderText || ''}
                    onCancelClick={() => selectElementValue(undefined, { display: undefined })}
                    onChange={(item) => selectElementValue(item.value, { display: item.name })}
                    options={dropdownOptions}
                    selectedItem={selectedDropdownOption}
                    showCancelBtn={!dropdownConfig.disableCancel}
                  />
                </div>
              );
            }
            case DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN: {
              const dropdownConfig = element.config as TimePeriodDropdownElemConfig;
              const dropdownOptions = dropdownConfig.values.map((option) => ({
                id: String(option.value),
                value: option.value,
                name: option.name,
              }));
              const selectedDropdownOption = dropdownOptions.find(
                (option) => option.value === value,
              );

              return (
                <div className={classes.variable} key={`variable-${element.name}-editable`}>
                  <div className={classes.variableName}>{element.name}</div>
                  <DropdownSelect
                    fillWidth
                    filterable
                    minimal
                    containerClassName={classes.variableInput}
                    noSelectionText={dropdownConfig.placeholderText || ''}
                    onCancelClick={() => selectElementValue(undefined)}
                    onChange={(item) => selectElementValue(item.value)}
                    options={dropdownOptions}
                    selectedItem={selectedDropdownOption}
                    showCancelBtn={dropdownConfig.enableCancel}
                  />
                </div>
              );
            }
            case DASHBOARD_ELEMENT_TYPES.MULTISELECT: {
              const multiselectConfig = element.config as SelectElemConfig;
              const multiselectOptions = constructOptions(
                multiselectConfig.valuesConfig,
                dashboardDatasets,
              );
              const selectedMultiselectOption = multiselectOptions.filter(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (option) => !!value && (value as any).indexOf(option.value) > -1,
              );

              return (
                <div className={classes.variable} key={`variable-${element.name}-editable`}>
                  <div className={classes.variableName}>{element.name}</div>
                  <MultiSelect
                    containerClassName={classes.variableInput}
                    options={multiselectOptions}
                    placeholder={multiselectConfig.placeholderText}
                    selectedItems={selectedMultiselectOption}
                    updateSelectedValues={(newValues) =>
                      selectElementValue(newValues, { length: newValues?.length ?? 0 })
                    }
                  />
                </div>
              );
            }
            case DASHBOARD_ELEMENT_TYPES.TOGGLE: {
              const switchConfig = element.config as SelectElemConfig;
              const switchOptions = constructOptions(
                switchConfig.valuesConfig,
                dashboardDatasets,
              ).slice(0, 5);
              const selectedSwitchOption = switchOptions.find((option) => option.value === value);

              return (
                <div className={classes.variable} key={`variable-${element.name}-editable`}>
                  <div className={classes.variableName}>{element.name}</div>

                  <DropdownSelect
                    fillWidth
                    filterable
                    minimal
                    showCancelBtn
                    containerClassName={classes.variableInput}
                    noSelectionText={switchConfig.placeholderText || ''}
                    onCancelClick={() => selectElementValue(undefined, { display: undefined })}
                    onChange={(item) => selectElementValue(item.value, { display: item.name })}
                    options={switchOptions}
                    selectedItem={selectedSwitchOption}
                  />
                </div>
              );
            }

            case DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH: {
              const dateSwitchConfig = element.config as DateGroupToggleConfig;
              const dateSwitchOptions = getDateGroupSwitchOptions(dateSwitchConfig);
              const selectedDateSwitchOption = dateSwitchOptions.find(
                (option) => option.id === value,
              );
              return (
                <div className={classes.variable} key={`variable-${element.name}-editable`}>
                  <div className={classes.variableName}>{element.name}</div>

                  <DropdownSelect
                    fillWidth
                    filterable
                    minimal
                    containerClassName={classes.variableInput}
                    noSelectionText={''}
                    onChange={(item) => selectElementValue(item.id)}
                    options={dateSwitchOptions}
                    selectedItem={selectedDateSwitchOption}
                  />
                </div>
              );
            }
            case DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER: {
              const dateRangePickerConfig = element.config as DateRangePickerElemConfig;

              return (
                <div className={classes.variable} key={`variable-${element.name}-editable`}>
                  <div className={classes.variableName}>{element.name}</div>
                  <DashboardDateRangePickerElement
                    noDropdown
                    noLabel
                    withPortal
                    className={classes.variableInput}
                    config={dateRangePickerConfig}
                    onNewValueSelect={(newValue) => selectElementValue(newValue)}
                    timezone={timezone}
                    value={value}
                  />
                </div>
              );
            }
          }

          return undefined;
        })}
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeader}>Custom Variables</div>

        {Object.values(dashboardParams).map((param) => {
          const inputType = DATE_TYPES.has(param.type) ? InputType.DATETIME : InputType.INPUT;
          return (
            <div className={classes.variable} key={`custom-variable-preview-${param.name}`}>
              <div className={classes.variableName}>{param.name}</div>
              <DashboardVariableEntry
                className={classes.variableInput}
                inputType={inputType}
                key={`custom-variables-entry-${param.name}`}
                name={param.name}
                onValueChange={(newValue) => updateVariableEvent({ varName: param.name, newValue })}
                value={variables[param.name]}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
