import { useMemo, FC } from 'react';
import { useParams } from 'react-router-dom';

import { ViewModeTabs } from 'components/resource/ViewModeTabs';
import { sprinkles } from 'components/ds';

import { VIEW_MODE } from './types';
import { CanvasVersionConfig } from 'actions/canvasConfigActions';
import { canvasFilterHasError, getCanvasTemplateError } from 'utils/canvasConfigUtils';

const rootClass = sprinkles({
  flexItems: 'column',
  height: 'fill',
  borderRight: 1,
  borderColor: 'gray7',
  backgroundColor: 'white',
});

type Props = {
  children: JSX.Element;
  config: CanvasVersionConfig;
};

const tabs = [VIEW_MODE.DATASETS, VIEW_MODE.TEMPLATES, VIEW_MODE.FILTERS, VIEW_MODE.LAYOUT];

export const CanvasNav: FC<Props> = ({ children, config }: Props) => {
  const { blueprintId, view } = useParams<{ blueprintId: string; view: VIEW_MODE }>();

  const { draftTabs, errorTabs } = useMemo(() => {
    const errorTabs = new Set<string>();
    const draftTabs = new Set<string>();
    tabs.forEach((tab) => {
      if (tabHasError(tab, config)) errorTabs.add(tab);
      else if (tabHasDraft(tab, config)) draftTabs.add(tab);
    });
    return { errorTabs, draftTabs };
  }, [config]);

  return (
    <div className={rootClass} style={{ minWidth: 350, width: 350 }}>
      <ViewModeTabs
        draftTabs={draftTabs}
        errorTabs={errorTabs}
        selectedTab={view}
        tabs={tabs}
        toUrl={`/blueprint/${blueprintId}`}
      />

      <div className={sprinkles({ flex: 1, height: 'fill', overflowY: 'auto' })}>{children}</div>
    </div>
  );
};

function tabHasError(viewMode: VIEW_MODE, config: CanvasVersionConfig): boolean {
  if (viewMode === VIEW_MODE.TEMPLATES) {
    return !!Object.values(config.templates).find(
      (template) => getCanvasTemplateError(template, config.datasets) !== null,
    );
  }
  if (viewMode === VIEW_MODE.FILTERS) {
    return !!Object.values(config.filters).find((filter) =>
      canvasFilterHasError(filter, config.datasets),
    );
  }
  return false;
}

function tabHasDraft(viewMode: VIEW_MODE, config: CanvasVersionConfig): boolean {
  if (viewMode === VIEW_MODE.DATASETS) {
    return !!Object.values(config.datasets).find((dataset) => dataset.queryDraft !== undefined);
  }
  return false;
}
