import { FC } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as styles from './index.css';
import cx from 'classnames';
import { Switch, Icon, sprinkles } from 'components/ds';
import { IconName } from 'components/ds/Icon';
import { Props as SwitchProps } from 'components/ds/Switch';

type ClassNameType = {
  /**
   * Style override
   */
  className?: string;
};

type BaseProps = ClassNameType & {
  /**
   * width of menu content
   */
  width?: 'small' | 'large';
  /**
   * Specify a container element id to portal the content into.
   */
  portalContainerId?: string;
  /**
   * The preferred side of the anchor to render against when open. Will be reversed when collisions occur and avoidCollisions is enabled.
   */
  side?: 'top' | 'right' | 'bottom' | 'left';
  /**
   * The preferred alignment against the anchor. May change when collisions occur.
   */
  align?: 'start' | 'center' | 'end';
  /**
   * The distance in pixels from the trigger.
   */
  sideOffset?: number;
  /**
   * The offset from the start or end align options
   */
  alignOffset?: number;
};

type ExternalStateProps = {
  defaultOpen?: never;
  trigger?: never;
  /**
   * The controlled open state of the menu. Must be used in conjunction with onOpenChange.
   */
  isOpen: boolean;
  /**
   * Event handler called when the open state of the menu changes.
   */
  onOpenChange: (open: boolean) => void;
};

type InternalStateProps = {
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  /**
   * The open state of the menu when it is initially rendered. Use when you do not need to control its open state.
   */
  defaultOpen?: boolean;
  /**
   * Trigger to open and close menu
   */
  trigger: JSX.Element;
};

type InteractionProps = ExternalStateProps | InternalStateProps;

export type Props = BaseProps & InteractionProps;

export const Menu: FC<Props> = ({
  className,
  width,
  isOpen,
  align,
  side,
  sideOffset = 4,
  alignOffset,
  portalContainerId,
  children,
  defaultOpen = false,
  trigger,
  onOpenChange,
}) => {
  const content = (
    <DropdownMenu.Content
      align={align}
      alignOffset={alignOffset}
      className={cx(styles.menuContent({ width }), className)}
      side={side}
      sideOffset={sideOffset}>
      {children}
    </DropdownMenu.Content>
  );

  return (
    <DropdownMenu.Root defaultOpen={defaultOpen} onOpenChange={onOpenChange} open={isOpen}>
      {trigger ? <DropdownMenu.Trigger asChild>{trigger}</DropdownMenu.Trigger> : null}
      {portalContainerId ? (
        <DropdownMenu.Portal container={document.getElementById(portalContainerId)}>
          {content}
        </DropdownMenu.Portal>
      ) : (
        content
      )}
    </DropdownMenu.Root>
  );
};

type MenuSubProps = Omit<Props, 'align' | 'side'>;

export const MenuSub: FC<MenuSubProps> = ({
  className,
  width,
  isOpen,
  sideOffset = 4,
  alignOffset,
  portalContainerId,
  children,
  defaultOpen = false,
  trigger,
  onOpenChange,
}) => {
  const content = (
    <DropdownMenu.SubContent
      alignOffset={alignOffset}
      className={cx(styles.menuContent({ width }), className)}
      sideOffset={sideOffset}>
      {children}
    </DropdownMenu.SubContent>
  );

  return (
    <DropdownMenu.Sub defaultOpen={defaultOpen} onOpenChange={onOpenChange} open={isOpen}>
      {trigger ? (
        <DropdownMenu.SubTrigger asChild className={styles.menuItem}>
          {trigger}
        </DropdownMenu.SubTrigger>
      ) : null}
      {portalContainerId ? (
        <DropdownMenu.Portal container={document.getElementById(portalContainerId)}>
          {content}
        </DropdownMenu.Portal>
      ) : (
        content
      )}
    </DropdownMenu.Sub>
  );
};

type MenuItemProps = {
  /**
   * When true, menu item has destructive consequences and styling.
   */
  isDestructive?: boolean;
  /**
   * When true, prevents the user from interacting with the item.
   */
  disabled?: boolean;
  /**
   * action text
   */
  text: string;
  /**
   * Event handler called when the user selects an item (via mouse or keyboard).
   * Calling event.preventDefault in this handler will prevent the dropdown menu
   * from closing when selecting that item.
   */
  onSelect?: (e: Event) => void;
};

type MenuActionItemProps = MenuItemProps & {
  /**
   * Option if want left hand icon
   */
  iconName?: IconName;
  /**
   * Optional test ID for selecting component in tests
   */
  'data-testid'?: string;
};

export const MenuActionItem: FC<MenuActionItemProps> = ({
  isDestructive,
  disabled,
  iconName,
  'data-testid': testId,
  onSelect,
  text,
}) => (
  <DropdownMenu.Item
    className={isDestructive ? styles.destructiveMenuItem : styles.menuItem}
    data-testId={testId}
    disabled={disabled}
    onSelect={onSelect}>
    <div className={styles.menuItemLabelIconStyles}>
      {iconName ? (
        <Icon
          className={sprinkles({ color: isDestructive ? 'errorBold' : 'contentSecondary' })}
          name={iconName}
        />
      ) : null}
      <div className={sprinkles({ color: isDestructive ? 'errorBold' : 'contentPrimary' })}>
        {text}
      </div>
    </div>
  </DropdownMenu.Item>
);

type MenuSwitchItemProps = Omit<MenuItemProps, 'onSelect' | 'isDestructive'> & {
  /**
   * switch props
   */
  switchProps: SwitchProps;
};

export const MenuSwitchItem: FC<MenuSwitchItemProps> = ({ disabled, switchProps, text }) => (
  <DropdownMenu.Item
    className={styles.menuItem}
    disabled={disabled}
    onSelect={(e) => e.preventDefault()}>
    <Switch
      className={sprinkles({ width: 'fill' })}
      label={text}
      labelClassName={sprinkles({ color: 'contentPrimary', body: 'b2' })}
      {...switchProps}
    />
  </DropdownMenu.Item>
);

type LabelProps = ClassNameType & {
  /**
   * Label Text
   */
  text: string;
};

export const MenuLabel: FC<LabelProps> = ({ className, text }) => (
  <DropdownMenu.Label className={cx(styles.menuLabel, className)}>{text}</DropdownMenu.Label>
);

export const MenuSeparator: FC<ClassNameType> = ({ className }) => (
  <DropdownMenu.Separator className={cx(styles.menuSeparator, className)} />
);
