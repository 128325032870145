import { FC, useState } from 'react';
import { ReduxState } from 'reducers/rootReducer';
import { useDispatch, useSelector } from 'react-redux';

import {
  APP_PORTAL_ID,
  Intent,
  Tag,
  AlertModal,
  Menu,
  MenuActionItem,
  MenuSub,
  Icon,
  IconButton,
  sprinkles,
} from 'components/ds';
import PublishToDropdown from './PublishToDropdown';

import { createLoadingSelector } from 'reducers/api/selectors';
import { ACTION } from 'actions/types';
import { DashboardVersion } from 'types/dashboardVersion';
import { EnvironmentTag } from 'actions/teamActions';
import {
  revertToDashboardVersion,
  switchCurrentlyEditingDashboardVersion,
} from 'actions/dashboardV2Actions';
import { CanvasVersion, revertToCanvasVersion } from 'actions/canvasVersionActions';
import { getTimezoneAwareDate } from 'utils/timezoneUtils';
import { doesUserHavePermission } from 'utils/permissionUtils';
import { PERMISSIONED_ACTIONS } from 'constants/roleConstants';
import {
  ReportBuilderVersion,
  revertToReportBuilderVersion,
} from 'actions/reportBuilderVersionActions';
import { ResourcePageType } from 'types/exploResource';
import { isVersionTagged } from 'utils/exploResourceUtils';
import * as styles from './index.css';

type Props = {
  canRevert: boolean;
  closeModal: () => void;
  resourceId: number;
  isDraft: boolean;
  pageType: ResourcePageType;
  tags: EnvironmentTag[];
  version: DashboardVersion | CanvasVersion | ReportBuilderVersion;
  userPermissions: string[];
};

export const VersionPanel: FC<Props> = ({
  canRevert,
  closeModal,
  resourceId,
  isDraft,
  pageType,
  tags,
  version,
  userPermissions,
}) => {
  const dispatch = useDispatch();
  const [isRevertModalOpen, setIsRevertModalOpen] = useState(false);

  const isArchitect = pageType === ResourcePageType.ARCHITECT;
  const isExplore = pageType === ResourcePageType.EXPLORE;

  const revertAction = isArchitect
    ? ACTION.REVERT_TO_CANVAS_VERSION
    : isExplore
    ? ACTION.REVERT_TO_DASHBOARD_VERSION
    : ACTION.REVERT_TO_REPORT_BUILDER_VERSION;

  const revertLoading = useSelector((state: ReduxState) =>
    createLoadingSelector([revertAction], false)(state),
  );

  const isDeprecated = 'deprecated' in version ? version.deprecated : false;

  const userCanEditResource = doesUserHavePermission(userPermissions, PERMISSIONED_ACTIONS.UPDATE);
  const userCanPublishResource = doesUserHavePermission(
    userPermissions,
    PERMISSIONED_ACTIONS.PUBLISH,
  );

  const handlePreview = () => {
    if (isExplore) {
      dispatch(
        switchCurrentlyEditingDashboardVersion({ dashboardVersion: version as DashboardVersion }),
      );
    }
    closeModal();
  };

  const handleRevert = () => {
    const args = {
      id: resourceId,
      postData: {
        version_number: version.version_number,
      },
    };
    const onSuccess = () => setIsRevertModalOpen(false);
    if (isArchitect) {
      dispatch(revertToCanvasVersion(args, onSuccess));
    } else if (isExplore) {
      dispatch(revertToDashboardVersion(args, onSuccess));
    } else {
      dispatch(revertToReportBuilderVersion(args, onSuccess));
    }
  };

  const renderRevertModal = () => (
    <AlertModal
      actionButtonProps={{
        onClick: handleRevert,
        loading: revertLoading,
        text: `Yes, revert to Version ${version.version_number}`,
      }}
      cancelButtonProps={{ onClick: () => setIsRevertModalOpen(false), text: 'Cancel' }}
      isOpen={isRevertModalOpen}
      onClose={() => setIsRevertModalOpen(false)}
      portalContainerId={APP_PORTAL_ID}
      title={
        isDraft
          ? `Reverting to ${version.version_number} will discard your active draft. Do you want to continue?`
          : `Are you sure you want to revert to Version ${version.version_number}?`
      }
    />
  );

  return (
    <div className={styles.versionPanelRoot}>
      <div className={sprinkles({ width: 'fill' })}>
        <div className={styles.versionPanelNameAndTags}>
          <div className={sprinkles({ whiteSpace: 'nowrap', marginRight: 'sp1' })}>
            Version {version.version_number}
          </div>
          <Tag className={sprinkles({ marginRight: 'sp1' })}>
            {`${getTimezoneAwareDate(version.version_saved_at || version.created)
              // set the locale to english for this specific instance because
              // we don't want the app builder itself to be translated yet
              .setLocale('en-us')
              .toRelative()}`}
          </Tag>
          {isDeprecated ? (
            <Tag
              inverted
              className={sprinkles({ marginRight: 'sp1' })}
              intent={Intent.ERROR}
              key={`deprecated-${version.id}`}>
              Deprecated
            </Tag>
          ) : null}
          {tags.map((tag) =>
            isVersionTagged(pageType, resourceId, version.id, tag) ? (
              <Tag
                inverted
                backgroundColor={tag.color_hex}
                className={sprinkles({ marginRight: 'sp1' })}
                key={`attached-tag-${tag.id}`}>
                {tag.name}
              </Tag>
            ) : null,
          )}
        </div>
        <div className={styles.changeComment}>{version.change_comments}</div>
      </div>
      {!isDeprecated ? (
        <div className={sprinkles({ flexItems: 'alignCenter' })}>
          <Menu align="end" trigger={<IconButton name="ellipsis-vertical" />} width="small">
            {isExplore || isArchitect ? (
              <MenuActionItem onSelect={handlePreview} text="Preview" />
            ) : null}
            {canRevert && userCanEditResource ? (
              <MenuActionItem onSelect={() => setIsRevertModalOpen(true)} text="Revert" />
            ) : null}
            {userCanPublishResource ? (
              <MenuSub
                trigger={
                  <div>
                    <>Publish To</>
                    <Icon name="caret-right" />
                  </div>
                }
                width="small">
                <PublishToDropdown
                  pageType={pageType}
                  resourceId={resourceId}
                  tags={tags}
                  versionId={version.id}
                />
              </MenuSub>
            ) : null}
          </Menu>
          {renderRevertModal()}
        </div>
      ) : null}
    </div>
  );
};
