import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Button from 'shared/Button';
import { showErrorToast, showSuccessToast } from 'shared/sharedToasts';
import DropdownSelect from 'shared/DropdownSelect';
import InputWithBlurSave from './dataPanelEditorPage/inputWithBlurSave';

import { ReduxState } from 'reducers/rootReducer';
import {
  getSuperuserActions,
  getSuperuserTeams,
  getTeamUsers,
  postSuperuserAction,
  AdminAction,
  AdminUser,
  AdminTeam,
} from 'actions/superuserActions';
import { SelectedDropdownInputItem } from 'constants/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '100vh',
    width: '100%',
    backgroundColor: theme.palette.ds.grey100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    paddingTop: theme.spacing(10),
  },
  form: {
    marginTop: theme.spacing(5),
  },
  dropdown: {
    margin: theme.spacing(3),
  },
  container: { width: '50%', textAlign: 'center' },
}));

type AdminPostBody = {
  action_id?: string;
  team_id?: string;
  params?: Record<string, string>;
};

const AdminPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { currentUser, superuser } = useSelector(
    (state: ReduxState) => ({
      currentUser: state.currentUser,
      superuser: state.superuser,
    }),
    shallowEqual,
  );

  const [selectedTeamId, setSelectedTeamId] = useState<AdminTeam>();
  const [selectedAction, setSelectedAction] = useState<AdminAction>();
  const [selectedUser, setSelectedUser] = useState<AdminUser>();
  const [postParams, setPostParams] = useState<AdminPostBody>();

  useEffect(() => {
    if (!superuser.actions) {
      dispatch(getSuperuserActions());
    }
    if (!superuser.teams) {
      dispatch(getSuperuserTeams());
    }
  }, [superuser, dispatch]);

  useEffect(() => {
    if (selectedTeamId) {
      dispatch(
        getTeamUsers({
          postData: { team_id: selectedTeamId.id },
        }),
      );
    }
  }, [dispatch, selectedTeamId]);

  const teamOptions: SelectedDropdownInputItem[] = (superuser.teams ?? []).map((team) => ({
    name: team.team_name,
    id: team.id,
  }));

  const actionOptions: SelectedDropdownInputItem[] = (superuser.actions ?? []).map((action) => ({
    name: action.id,
    id: action.id,
  }));

  const userOptions: SelectedDropdownInputItem[] = (superuser.users ?? []).map((user) => ({
    name: user.email,
    id: user.email,
  }));

  const doesActionRequireUsers = (action: AdminAction | undefined) =>
    (action?.params ?? []).filter((p) => p.type === 'user').length > 0;

  const doesActionRequireTeam = (action: AdminAction | undefined) => action?.requires_team;

  const renderTeamActionUserDropdown = () => {
    return (
      <div className={classes.container}>
        <h2>Hello {currentUser.first_name}! Welcome to Mission Control </h2>
        <DropdownSelect
          fillWidth
          filterable
          minimal
          containerClassName={classes.dropdown}
          label="Select an Action"
          noSelectionText="Select an Action"
          onChange={(item) => {
            const newAction = superuser.actions?.find((a) => a.id === item.id);
            if (newAction) {
              setSelectedAction(newAction);
              setPostParams({ ...postParams, action_id: newAction.id });
            }
          }}
          options={actionOptions}
          selectedItem={
            selectedAction ? { id: selectedAction.id, name: selectedAction.id } : undefined
          }
        />
        {doesActionRequireTeam(selectedAction) ? (
          <DropdownSelect
            fillWidth
            filterable
            minimal
            containerClassName={classes.dropdown}
            label="Select a Team"
            noSelectionText="Select a Team"
            onChange={(item) => {
              setSelectedTeamId({ id: item.id, team_name: item.name });
              setPostParams({ ...postParams, team_id: item.id });
            }}
            options={teamOptions}
            selectedItem={
              selectedTeamId ? { id: selectedTeamId.id, name: selectedTeamId.team_name } : undefined
            }
          />
        ) : undefined}
        {doesActionRequireUsers(selectedAction) ? (
          <DropdownSelect
            fillWidth
            filterable
            minimal
            containerClassName={classes.dropdown}
            label="Select a User"
            noSelectionText="Select a User"
            onChange={(item) => {
              const newUser: AdminUser | undefined = superuser.users?.find(
                (a) => a.email === item.id,
              );
              if (newUser) {
                setSelectedUser(newUser);
                setPostParams({ ...postParams, params: { name: 'user', value: newUser.email } });
              }
            }}
            options={userOptions}
            selectedItem={
              selectedUser ? { id: selectedUser.email, name: selectedUser.email } : undefined
            }
          />
        ) : undefined}
      </div>
    );
  };

  const renderAdminParamForm = () => {
    if (!selectedAction) return;

    return (
      <div className={classes.container}>
        {selectedAction.params.map((param, index) => {
          if (param.options) {
            const options = param.options.map((opt) => ({ id: opt, name: opt }));
            return (
              <DropdownSelect
                fillWidth
                minimal
                containerClassName={classes.dropdown}
                key={`admin-form-${index}`}
                label={param.id}
                noSelectionText="Select an option"
                onChange={(item) => {
                  if (postParams) {
                    setPostParams({
                      ...postParams,
                      params: { ...postParams.params, [param.id]: item.id },
                    });
                  }
                }}
                options={options}
                selectedItem={
                  postParams?.params?.[param.id]
                    ? {
                        id: postParams.params[param.id],
                        name: postParams.params[param.id],
                      }
                    : undefined
                }
              />
            );
          } else if (param.type === 'number') {
            return (
              <InputWithBlurSave
                containerClassName={classes.dropdown}
                key={`admin-form-${index}`}
                label={param.id}
                onNewValueSubmitted={(newValue) => {
                  if (!Number(newValue)) {
                    showErrorToast('Input must be a non zero number');
                  } else if (postParams)
                    setPostParams({
                      ...postParams,
                      params: { ...postParams.params, [param.id]: newValue },
                    });
                }}
              />
            );
          } else if (param.type === 'string') {
            return (
              <InputWithBlurSave
                containerClassName={classes.dropdown}
                key={`admin-form-${index}`}
                label={param.id}
                onNewValueSubmitted={(newValue) => {
                  if (postParams) {
                    setPostParams({
                      ...postParams,
                      params: { ...postParams.params, [param.id]: newValue },
                    });
                  }
                }}
              />
            );
          } else return null;
        })}
        <Button
          fillWidth
          disabled={
            !postParams?.action_id || (doesActionRequireTeam(selectedAction) && !postParams.team_id)
          }
          onClick={() => {
            dispatch(
              postSuperuserAction(
                {
                  postData: {
                    team_id: postParams?.team_id || '',
                    action_id: postParams?.action_id || '',
                    params: postParams?.params || {},
                  },
                },
                () => showSuccessToast(`Successfully updated team!`),
                (error) =>
                  showErrorToast(
                    `Something failed with this error: ${error.error_msg} Please try again.`,
                  ),
              ),
            );
          }}
          text="Submit"
        />
      </div>
    );
  };

  return (
    <div className={classes.root}>
      {renderTeamActionUserDropdown()}
      {renderAdminParamForm()}
    </div>
  );
};

export default AdminPage;
