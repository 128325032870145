import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import produce from 'immer';

import { sprinkles } from 'components/ds';
import Checkbox from 'components/checkbox';

import { DataPanel } from 'types/exploResource';
import { DashboardElementConfig } from 'types/dashboardTypes';
import { VIZ_TO_NAME } from 'constants/dataConstants';
import { dpLinkUpdated } from 'utils/customEventUtils';
import { getDataPanelTitle } from 'utils/exploResourceUtils';
import { ReduxState } from 'reducers/rootReducer';
import { setLinkedElementHover } from 'actions/cssLinkActions';

type Props = {
  config: DashboardElementConfig;
  dataPanel: DataPanel;
  datasetId: string;
  elementName: string;

  updateConfig: (config: DashboardElementConfig) => void;
};

export const ChartLinkRow: FC<Props> = ({
  config,
  dataPanel,
  datasetId,
  elementName,
  updateConfig,
}) => {
  const dispatch = useDispatch();

  const [isLinked, setIsLinked] = useState(
    !!config.datasetLinks?.[datasetId]?.dataPanels?.includes(dataPanel.id),
  );
  const hoveredDPId = useSelector((state: ReduxState) => state.cssLinks.hoverElementId);

  useEffect(() => {
    const newIsLinked = !!config.datasetLinks?.[datasetId]?.dataPanels?.includes(dataPanel.id);
    if (newIsLinked !== isLinked) {
      setIsLinked(newIsLinked);
      dpLinkUpdated({ dataPanelIds: [dataPanel.id], elementName });
    }
  }, [config, datasetId, dataPanel.id, elementName, isLinked]);

  return (
    <div
      className={sprinkles({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 'sp.5',
        paddingX: 'sp.5',
        backgroundColor: hoveredDPId === dataPanel.id ? 'lightBlue' : undefined,
        borderRadius: 2,
      })}
      key={dataPanel.id}
      onMouseEnter={() => dispatch(setLinkedElementHover(dataPanel.id))}
      onMouseLeave={() => dispatch(setLinkedElementHover(undefined))}
      style={{ height: 28 }}>
      <div className={sprinkles({ display: 'flex', flex: 1, overflow: 'hidden' })}>
        <Checkbox
          ignoreCustomStyles
          isChecked={isLinked}
          onChange={() => {
            const newConfig = produce(config, (draft) => {
              const datasetFilters = draft.datasetLinks?.[datasetId];
              if (!datasetFilters) return;

              if (!datasetFilters.dataPanels) datasetFilters.dataPanels = [];
              if (isLinked) {
                datasetFilters.dataPanels = datasetFilters.dataPanels.filter(
                  (currId) => currId !== dataPanel.id,
                );
              } else {
                datasetFilters.dataPanels.push(dataPanel.id);
              }
            });
            updateConfig(newConfig);
          }}
        />
        <div
          className={sprinkles({
            marginLeft: 'sp1',
            flex: 1,
            color: 'contentPrimary',
            truncateText: 'ellipsis',
          })}>
          {getDataPanelTitle(dataPanel) ?? dataPanel.provided_id}
        </div>
      </div>
      <div className={sprinkles({ marginLeft: 'sp.5', color: 'contentSecondary' })}>
        {VIZ_TO_NAME[dataPanel.visualize_op.operation_type]}
      </div>
    </div>
  );
};
