import { makeStyles, Theme } from '@material-ui/core/styles';
import { sprinkles } from 'components/ds';

export const sharedStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: `${theme.spacing(3)}px 0px`,
  },
  configInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(2),
  },
  leftElemInRow: {
    marginRight: theme.spacing(2),
  },
}));

export const rootClass = sprinkles({ marginY: 'sp1.5' });
export const configInputClass = sprinkles({ paddingX: 'sp1.5', marginBottom: 'sp1' });
