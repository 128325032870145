import { sprinkles } from 'components/ds';

import { Button } from 'components/ds';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import { SortableExpandableCard } from 'components/ChartConfigs/SortableExpandableCard';
import { SortableList, SortableListItem } from 'components/SortableList/SortableList';

import { ManualSortCategory } from 'constants/types';

type Props = {
  orderConfig?: ManualSortCategory[];
  updateOrder: (newOrder: ManualSortCategory[]) => void;
  visibleCategories: Set<string>;
};

export function SortableCategoriesConfig({ orderConfig, updateOrder, visibleCategories }: Props) {
  const newConfig = [...(orderConfig || [])];
  const presetCategories = new Set(newConfig.map((order) => order.category));

  visibleCategories.forEach(
    (category) =>
      !presetCategories.has(category) && newConfig.push({ category, displayName: category }),
  );

  return (
    <div className={sprinkles({ width: 'fill' })}>
      <SortableList
        getIdFromElem={(item) => item.category}
        onListUpdated={(newList) => updateOrder(newList)}
        sortableItems={newConfig}>
        {newConfig.map((order, i) => (
          <SortableListItem key={order.category} sortId={order.category}>
            <SortableExpandableCard key={`manual-sort-${order.category}`} name={order.category}>
              <div className={sprinkles({ padding: 'sp1' })}>
                <InputWithBlurSave
                  fillWidth
                  hideRightIconInteractions
                  containerClassName={sprinkles({ marginBottom: 'sp1.5' })}
                  initialValue={order.category}
                  label="Category"
                  onNewValueSubmitted={(newValue) => {
                    newConfig[i] = {
                      ...order,
                      category: newValue,
                    };
                    updateOrder(newConfig);
                  }}
                  placeholder="Category"
                />
                <InputWithBlurSave
                  fillWidth
                  hideRightIconInteractions
                  containerClassName={sprinkles({ marginBottom: 'sp1.5' })}
                  initialValue={order.displayName}
                  label="Display Name"
                  onNewValueSubmitted={(newValue) => {
                    newConfig[i] = {
                      ...order,
                      displayName: newValue,
                    };
                    updateOrder(newConfig);
                  }}
                  placeholder="Display Name"
                />
                <Button
                  fillWidth
                  icon="trash"
                  onClick={() => {
                    updateOrder(newConfig.filter((_, index) => index !== i));
                  }}
                  type="destructive">
                  Delete
                </Button>
              </div>
            </SortableExpandableCard>
          </SortableListItem>
        ))}
      </SortableList>
    </div>
  );
}
