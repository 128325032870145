import { createReducer, createSelector } from '@reduxjs/toolkit';
import * as RD from 'remotedata';

import * as actions from 'actions/reportBuilderActions';
import { ReportBuilder } from 'actions/reportBuilderActions';
import { fetchReportBuilderVersionsSuccess } from 'actions/reportBuilderVersionActions';

interface ReportBuilderReducerState {
  currentReportBuilder: number | null;
  reportBuilders: RD.ResponseData<ReportBuilder[]>;
}

const reportBuilderReducerInitialState: ReportBuilderReducerState = {
  currentReportBuilder: null,
  reportBuilders: RD.Idle(),
};

export const getCurrentReportBuilder = createSelector(
  (state: ReportBuilderReducerState) => state.currentReportBuilder,
  (state: ReportBuilderReducerState) => state.reportBuilders,

  (reportBuilderId, reportBuilders) => {
    if (reportBuilderId === null || !RD.isSuccess(reportBuilders)) return null;

    return (
      reportBuilders.data.find((reportBuilder) => reportBuilder.id === reportBuilderId) ?? null
    );
  },
);

const updateReportBuilders = (
  state: ReportBuilderReducerState,
  reportBuilderId: number,
  updateFunc: (reportBuilder: ReportBuilder) => void,
): void => {
  if (!RD.isSuccess(state.reportBuilders)) return;

  const reportBuilder = state.reportBuilders.data.find((elem) => elem.id === reportBuilderId);

  if (reportBuilder) updateFunc(reportBuilder);
};

export default createReducer(reportBuilderReducerInitialState, (builder) =>
  builder
    .addCase(actions.fetchReportBuildersRequest, (state) => {
      state.reportBuilders = RD.Loading();
    })
    .addCase(actions.fetchReportBuildersError, (state) => {
      state.reportBuilders = RD.Error('Error Loading Report Builders');
    })
    .addCase(actions.fetchReportBuildersSuccess, (state, { payload }) => {
      state.reportBuilders = RD.Success(payload.report_builders);
    })
    .addCase(actions.createReportBuilderSuccess, (state, { payload }) => {
      if (!RD.isSuccess(state.reportBuilders)) return;

      state.reportBuilders.data.push(payload.report_builder);
      state.currentReportBuilder = payload.report_builder.id;
    })
    .addCase(actions.cloneReportBuilderSuccess, (state, { payload }) => {
      if (!RD.isSuccess(state.reportBuilders)) return;

      state.reportBuilders.data.push(payload.new_report_builder);
      state.currentReportBuilder = payload.new_report_builder.id;
    })
    .addCase(actions.deleteReportBuilderSuccess, (state, { payload }) => {
      if (!RD.isSuccess(state.reportBuilders)) return;
      state.reportBuilders.data = state.reportBuilders.data.filter((rb) => rb.id !== payload.id);
    })
    .addCase(actions.renameReportBuilderSuccess, (state, { payload }) => {
      updateReportBuilders(state, payload.id as number, (reportBuilder) => {
        reportBuilder.name = payload.name;
      });
    })
    .addCase(fetchReportBuilderVersionsSuccess, (state, { payload }) => {
      if (typeof payload.id === 'number') state.currentReportBuilder = payload.id;
    })
    .addDefaultCase((state) => state),
);
