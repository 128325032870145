import cx from 'classnames';
import produce from 'immer';
import { makeStyles } from '@material-ui/core';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import { Switch } from 'components/ds';
import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';

import {
  ChartSpecificFormat,
  LineChartFormat,
  LineElasticity,
  V2TwoDimensionChartInstructions,
} from 'constants/types';

const useStyles = makeStyles(() => ({
  lineInfoRow: {
    display: 'flex',
  },
  lineInfoElement: {
    flex: 1,
  },
}));

type Props = {
  configInputClass: string;
  instructions: V2TwoDimensionChartInstructions;
  defaultLineWidth: number;
  isCanvas?: boolean;

  updateChartSpecificFormat: (chartSpecificFormat: ChartSpecificFormat) => void;
};

export default function SharedLineChartConfigs({
  configInputClass,
  instructions,
  isCanvas,
  defaultLineWidth,
  updateChartSpecificFormat,
}: Props) {
  const classes = useStyles();
  const { chartSpecificFormat } = instructions;
  const { lineChart, timeSeriesDataFormat } = chartSpecificFormat ?? {};

  const selectedElasticity = lineChart?.elasticity || LineElasticity.CURVED;

  const updateLineChartInstructions = (lineChartFormatUpdates: LineChartFormat) => {
    const newChartSpecificFormat = produce(chartSpecificFormat ?? {}, (draft) => {
      draft.lineChart = { ...draft.lineChart, ...lineChartFormatUpdates };
    });
    updateChartSpecificFormat(newChartSpecificFormat);
  };

  return (
    <>
      <div className={cx(configInputClass, classes.lineInfoRow)}>
        <div className={classes.lineInfoElement} style={{ marginRight: 8 }}>
          <ToggleButtonGroup fillWidth label="Line Elasticity">
            {Object.values(LineElasticity).map((elasticity) => (
              <ToggleButton
                active={selectedElasticity === elasticity}
                key={elasticity}
                onClick={() => updateLineChartInstructions({ elasticity })}
                text={elasticity}
              />
            ))}
          </ToggleButtonGroup>
        </div>
        <div className={classes.lineInfoElement}>
          <InputWithBlurSave
            initialValue={String(lineChart?.lineWidth ?? defaultLineWidth)}
            label="Line Width"
            onNewValueSubmitted={(newValue) => {
              const newInt = parseInt(newValue);
              updateLineChartInstructions({ lineWidth: newInt >= 0 ? newInt : undefined });
            }}
          />
        </div>
      </div>
      <Switch
        useCustomStyles
        className={configInputClass}
        label="Show Markers"
        onChange={() => updateLineChartInstructions({ hideMarkers: !lineChart?.hideMarkers })}
        switchOn={!lineChart?.hideMarkers}
      />
      {!isCanvas ? (
        <Switch
          className={configInputClass}
          label="Fill in missing dates with 0s"
          onChange={() => {
            const newChartSpecificFormat = produce(chartSpecificFormat ?? {}, (draft) => {
              draft.timeSeriesDataFormat = {
                ...draft.timeSeriesDataFormat,
                zeroMissingDates: !timeSeriesDataFormat?.zeroMissingDates,
              };
            });
            updateChartSpecificFormat(newChartSpecificFormat);
          }}
          switchOn={timeSeriesDataFormat?.zeroMissingDates}
        />
      ) : null}
      {!isCanvas ? (
        <Switch
          className={configInputClass}
          label="Fill in missing values with 0s"
          onChange={() => {
            const newChartSpecificFormat = produce(chartSpecificFormat ?? {}, (draft) => {
              draft.timeSeriesDataFormat = {
                ...draft.timeSeriesDataFormat,
                zeroMissingValues: !timeSeriesDataFormat?.zeroMissingValues,
              };
            });
            updateChartSpecificFormat(newChartSpecificFormat);
          }}
          switchOn={timeSeriesDataFormat?.zeroMissingValues}
        />
      ) : null}
    </>
  );
}
