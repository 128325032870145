import { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import exploLogoWatermark from 'images/app/exploLogoWatermark.png';

const useStyles = makeStyles((theme: Theme) => ({
  watermarkText: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
    background: theme.palette.ds.grey900,
    borderRadius: theme.spacing(1),
    color: theme.palette.ds.white,
    fontSize: 12,
    fontFamily: "'Inter', sans-serif",
    textAlign: 'right',
    width: 110,
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 20,
  },
  watermarkLogo: {
    position: 'absolute',
    left: 'calc(50% - 50px)',
    top: 'calc(50% - 50px)',
    width: 100,
    height: 100,
    zIndex: 20,
  },
}));

export const DemoWatermark: FC = () => {
  const classes = useStyles();
  const watermarkText = 'This is a demo account.';
  return (
    <>
      <img className={classes.watermarkLogo} src={exploLogoWatermark} />
      <div className={classes.watermarkText}>{watermarkText}</div>
    </>
  );
};
