import { FC } from 'react';

import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import { CategoryFieldColorAssignment } from './CategoryFieldColorAssignment';
import DropdownSelect from 'shared/DropdownSelect';
import { Switch, sprinkles } from 'components/ds';
import InfoCard from 'shared/InfoCard';

import { DatasetSchema, DatasetColumn, DatasetRow } from 'types/datasets';
import {
  StringDisplayFormat,
  StringDisplayOptions,
  ImageShapeFormat,
  StringUrlDisplayFormat,
} from 'constants/types';
import { ORDERED_ALIGNMENTS } from 'constants/dataConstants';
import { getCellAlignment } from 'components/dataTable/utils';
import { getCurrentStringFormat, getCurrentUrlFormat } from 'utils/formatConfigUtils';
import { isValidHex } from 'utils/general';

const inputClass = sprinkles({ marginTop: 'sp1' });

type Props = {
  column: DatasetColumn;
  dataPanelData: DatasetRow[];
  displayOptions: StringDisplayOptions | undefined;
  originalSchema: DatasetSchema;

  updateStringOptions: (newFields: StringDisplayOptions) => void;
};

export const StringFormatConfig: FC<Props> = ({
  updateStringOptions,
  column,
  displayOptions,
  dataPanelData,
  originalSchema,
}) => {
  const { label, urlFormat, urlColumnName, imageShape, openInSameTab, urlLinkColor } =
    displayOptions ?? {};

  const currentFormat = getCurrentStringFormat(displayOptions);

  const currentAlignment = getCellAlignment(displayOptions, column.type);

  const selectedUrlFormat = getCurrentUrlFormat(urlFormat);
  const urlColumnOptions = originalSchema.map((col) => ({
    id: col.name,
    name: col.name,
  }));

  return (
    <>
      <ToggleButtonGroup fillWidth className={inputClass} label="Format">
        {Object.values(StringDisplayFormat).map((format) => (
          <ToggleButton
            active={format === currentFormat}
            key={format}
            onClick={() => {
              if (format === currentFormat) return;
              updateStringOptions({ format });
            }}
            text={format}
          />
        ))}
      </ToggleButtonGroup>
      {currentFormat === StringDisplayFormat.LINK && (
        <>
          <InfoCard text="The linked URL must be prefixed with http:// or https:// to redirect to a new domain. If not, then the link will navigate within the domain that this dashboard is embedded in." />
          <ToggleButtonGroup fillWidth className={inputClass}>
            {Object.values(StringUrlDisplayFormat).map((urlDisplayFormat) => (
              <ToggleButton
                active={selectedUrlFormat === urlDisplayFormat}
                key={`url-display-format-${urlDisplayFormat}-${column.name}`}
                onClick={() => updateStringOptions({ urlFormat: urlDisplayFormat })}
                text={urlDisplayFormat}
              />
            ))}
          </ToggleButtonGroup>
          {selectedUrlFormat === StringUrlDisplayFormat.LABEL && (
            <InputWithBlurSave
              containerClassName={inputClass}
              initialValue={label}
              onNewValueSubmitted={(newLabel) => updateStringOptions({ label: newLabel })}
              placeholder="Enter Label"
            />
          )}
          {selectedUrlFormat === StringUrlDisplayFormat.COLUMN && (
            <DropdownSelect
              fillWidth
              minimal
              containerClassName={inputClass}
              filterable={false}
              noSelectionText="Select Column"
              onChange={(newValue) => updateStringOptions({ urlColumnName: newValue.id })}
              options={urlColumnOptions}
              selectedItem={
                urlColumnName
                  ? urlColumnOptions.find((col) => col.name === urlColumnName)
                  : undefined
              }
            />
          )}
          <Switch
            className={inputClass}
            label="Open in new tab"
            onChange={() => updateStringOptions({ openInSameTab: !openInSameTab })}
            switchOn={!openInSameTab}
          />
          <InputWithBlurSave
            containerClassName={inputClass}
            helpText="Enter a valid hex code"
            initialValue={urlLinkColor ?? ''}
            label="Link Color"
            onNewValueSubmitted={(linkHex) =>
              updateStringOptions({ urlLinkColor: isValidHex(linkHex) ? linkHex : undefined })
            }
          />
        </>
      )}
      {currentFormat === StringDisplayFormat.CATEGORY && (
        <CategoryFieldColorAssignment
          column={column}
          dataPanelData={dataPanelData}
          displayOptions={displayOptions}
          updateStringOptions={updateStringOptions}
        />
      )}
      {currentFormat === StringDisplayFormat.IMAGE && (
        <ToggleButtonGroup fillWidth className={inputClass} label="Format">
          <ToggleButton
            active={!imageShape || ImageShapeFormat.RECTANGLE === imageShape}
            icon="square"
            key={ImageShapeFormat.RECTANGLE}
            onClick={() => updateStringOptions({ imageShape: ImageShapeFormat.RECTANGLE })}
          />
          <ToggleButton
            active={ImageShapeFormat.CIRCLE === imageShape}
            icon="circle"
            key={ImageShapeFormat.CIRCLE}
            onClick={() => updateStringOptions({ imageShape: ImageShapeFormat.CIRCLE })}
          />
        </ToggleButtonGroup>
      )}
      <ToggleButtonGroup fillWidth className={inputClass} label="Alignment">
        {ORDERED_ALIGNMENTS.map((alignment) => (
          <ToggleButton
            active={currentAlignment === alignment.id}
            icon={alignment.icon}
            key={`alignment-${alignment.id}-${column.name}`}
            onClick={() => updateStringOptions({ alignment: alignment.id })}
          />
        ))}
      </ToggleButtonGroup>
    </>
  );
};
