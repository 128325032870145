import { FC, useEffect } from 'react';
import validator from 'validator';
import cx from 'classnames';
import { Classes } from '@blueprintjs/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useImmer } from 'use-immer';

import Button from 'shared/Button';
import DropdownSelect from 'shared/DropdownSelect';
import Modal from 'components/core/Modal';
import { Intent } from 'components/ds';

import { DEFAULT_ROLE_OPTIONS } from 'constants/roleConstants';
import { InvitedUser } from 'actions/teamActions';
import InputWithTag from 'shared/InputWithTag';
import { getTeamMemberRole } from 'utils/permissionUtils';

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  inviteRow: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(3),

    '&:not(:last-child)': { marginBottom: theme.spacing(3) },
  },
  inviteEmail: { flexGrow: 4 },
  inviteRole: { flex: '5 1 0' },
  addButton: {
    color: `${theme.palette.ds.grey900} !important`,
    fill: `${theme.palette.ds.grey900} !important`,
  },
}));

type Props = {
  closeModal: () => void;
  inviteTeammateLoading: boolean;
  modalOpen: boolean;
  onSubmit: (invites: InvitedUser[]) => void;
  resourceName?: string;
};

const createBlankInvite = () => {
  return { email: '', role_names: [] };
};

const InviteTeammatesModal: FC<Props> = (props) => {
  const { inviteTeammateLoading, modalOpen } = props;

  const classes = useStyles();
  const [invites, updateInvites] = useImmer<InvitedUser[]>([createBlankInvite()]);

  // initialize as true because the initial state is empty
  let areAllInvitesValidOrEmpty = true;
  let isSomeInviteValid = false;

  invites.forEach((invite) => {
    const isRowValid = validator.isEmail(invite.email) && invite.role_names.length !== 0;
    const isRowEmpty = invite.email.trim() === '';

    areAllInvitesValidOrEmpty = areAllInvitesValidOrEmpty && (isRowValid || isRowEmpty);

    isSomeInviteValid = isSomeInviteValid || isRowValid;
  });

  const isReadyToSubmit = areAllInvitesValidOrEmpty && isSomeInviteValid;

  useEffect(() => {
    if (!modalOpen) updateInvites([createBlankInvite()]);
  }, [modalOpen, updateInvites]);

  const renderInviteRow = (invite: InvitedUser, index: number) => (
    <div className={classes.inviteRow} key={`invited-user-row-${index}`}>
      <InputWithTag
        hideLabel
        className={classes.inviteEmail}
        onChange={(value) =>
          updateInvites((draft) => {
            draft.splice(index, 1, { ...invite, email: value });
          })
        }
        placeholder="name@domain.com"
        statusInfo={
          invite.email !== ''
            ? !validator.isEmail(invite.email)
              ? { statusIntent: Intent.ERROR, statusText: 'Invalid Email Address' }
              : { statusIntent: Intent.SUCCESS, statusIcon: 'check' }
            : undefined
        }
        value={invite.email}
      />
      <DropdownSelect
        btnMinimal
        fillWidth
        minimal
        containerClassName={classes.inviteRole}
        filterable={false}
        noSelectionText="Select a Role"
        onChange={(option) =>
          updateInvites((draft) => {
            draft.splice(index, 1, { ...invite, role_names: [option.name] });
          })
        }
        options={DEFAULT_ROLE_OPTIONS}
        selectedItem={
          invite.role_names.length === 0
            ? undefined
            : DEFAULT_ROLE_OPTIONS.find((option) => option.name === getTeamMemberRole(invite))
        }
      />
    </div>
  );

  return (
    <Modal modalOpen={modalOpen} onClose={() => props.closeModal()} title="Invite Team Members">
      <div className={Classes.DIALOG_BODY}>{invites.map(renderInviteRow)}</div>
      <div className={cx(Classes.DIALOG_FOOTER, classes.footer)}>
        <Button
          minimal
          className={classes.addButton}
          icon="plus"
          onClick={() => updateInvites((draft) => draft.concat([createBlankInvite()]))}
          text="Add More"
          type="secondary"
        />
        <Button
          disabled={!isReadyToSubmit}
          loading={inviteTeammateLoading}
          onClick={() => {
            if (isReadyToSubmit) props.onSubmit(invites);
          }}
          text="Send Invites"
          type="primary"
        />
      </div>
    </Modal>
  );
};

export { InviteTeammatesModal };
