import { useContext } from 'react';
import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { Spinner, sprinkles } from 'components/ds';
import TablePager from 'components/dataTable/tablePager';
import { DownloadChartButton } from './DownloadChartButton';
import { InfoIcon } from 'components/InfoIcon';

import { GLOBAL_STYLE_CLASSNAMES, GlobalStylesContext } from 'globalStyles';
import { GlobalStyleConfig } from 'globalStyles/types';

import { AdHocOperationInstructions } from 'types/dataPanelTemplate';
import { MAX_ROWS_TO_PREVIEW } from 'constants/dataConstants';
import { getPaginationDisabledText, isTablePagingEnabled } from 'components/dataTable/utils';
import { DatabaseUnsupportedOperations } from 'types/datasets';
import { DownloadFunc, DownloadSpreadsheetFunc } from 'components/ExportOptions/ExportOptions';

const useStyles = makeStyles((theme: Theme) => ({
  tableFooter: ({ globalStyleConfig }: { globalStyleConfig: GlobalStyleConfig }) => ({
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    paddingLeft: globalStyleConfig.container.padding.default,
    height: 34,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    overflow: 'hidden',

    [theme.breakpoints.down('xs')]: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
  }),
  rowCount: {
    fontSize: 14,
    fontWeight: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    whiteSpace: 'nowrap',

    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
}));

type Props = {
  adHocOperationInstructions: AdHocOperationInstructions;
  canDownloadDataPanel: boolean;
  dataPanelId: string;
  disableDownload?: boolean;
  onDownloadPanelSpreadsheet: DownloadSpreadsheetFunc;
  onDownloadPanelPdf: DownloadFunc;
  onNewPage: (newPage: string) => void;
  loading?: boolean;
  sourceDataRowCount?: number;
  isDownloadButtonHidden?: boolean;
  disablePdfDownload?: boolean;
  disableDownloadExport?: boolean;
  enableEmailExport?: boolean;
  rowsPerPage?: number;
  shouldUseJobQueue?: boolean;
  supportEmail?: string;
  isEmbed: boolean;
  unsupportedOperations: DatabaseUnsupportedOperations[] | undefined;
};

export default function DataTableFooter({
  adHocOperationInstructions,
  canDownloadDataPanel,
  onNewPage,
  sourceDataRowCount,
  loading,
  onDownloadPanelSpreadsheet,
  onDownloadPanelPdf,
  disableDownload,
  disablePdfDownload,
  disableDownloadExport,
  enableEmailExport,
  isDownloadButtonHidden,
  rowsPerPage,
  shouldUseJobQueue,
  supportEmail,
  dataPanelId,
  isEmbed,
  unsupportedOperations,
}: Props) {
  const { globalStyleConfig } = useContext(GlobalStylesContext);
  const classes = useStyles({ globalStyleConfig });

  if (loading || sourceDataRowCount === undefined) {
    return (
      <div
        className={cx(
          classes.tableFooter,
          GLOBAL_STYLE_CLASSNAMES.container.fill.offsetBackgroundColor,
        )}>
        <Spinner className={sprinkles({ marginRight: 'sp.5' })} size="md" />
      </div>
    );
  }

  const numRows = rowsPerPage || MAX_ROWS_TO_PREVIEW;

  const enablePagination = isTablePagingEnabled(unsupportedOperations);

  return (
    <div
      className={cx(
        classes.tableFooter,
        GLOBAL_STYLE_CLASSNAMES.container.fill.offsetBackgroundColor,
        GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.default.borderRadiusBottom,
      )}>
      <div className={cx(classes.rowCount, GLOBAL_STYLE_CLASSNAMES.text.body.secondary)}>
        {enablePagination ? (
          `${sourceDataRowCount.toLocaleString()} Results`
        ) : (
          <div className={sprinkles({ display: 'flex', alignItems: 'center' })}>
            {`Showing ${Math.min(
              numRows,
              sourceDataRowCount,
            )} of ${sourceDataRowCount.toLocaleString()} results`}
            <InfoIcon infoTooltipText={getPaginationDisabledText(isEmbed)} />
          </div>
        )}
      </div>
      {enablePagination ? (
        <TablePager
          currentPage={adHocOperationInstructions.currentPage || 1}
          maxPageNumber={Math.ceil(sourceDataRowCount / numRows)}
          onNewPage={onNewPage}
        />
      ) : null}
      <div className={sprinkles({ display: 'flex', alignItems: 'center' })}>
        {canDownloadDataPanel && dataPanelId && !isDownloadButtonHidden ? (
          <DownloadChartButton
            dataPanelId={dataPanelId}
            disableDownloadExport={disableDownloadExport}
            disablePdfDownload={disablePdfDownload}
            disabled={disableDownload}
            enableEmailExport={enableEmailExport}
            onDownloadPanelPdf={onDownloadPanelPdf}
            onDownloadPanelSpreadsheet={onDownloadPanelSpreadsheet}
            shouldUseJobQueue={shouldUseJobQueue}
            supportEmail={supportEmail}
          />
        ) : null}
      </div>
    </div>
  );
}
