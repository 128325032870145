import { FC } from 'react';
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import Color from 'color';
import { Icon } from '@blueprintjs/core';
import { IItemRendererProps, Select } from '@blueprintjs/select';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 110,
  },
  dropdownButton: {
    width: 110,
    borderRadius: 4,
    height: 28,
    padding: '4px 8px',
    border: '1px solid #E0E0E0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
  menu: {
    '& .bp3-menu': {
      width: 110,
      minWidth: 110,
      padding: 4,
      paddingBottom: 2,
    },
  },
  menuItem: {
    height: 28,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 4,
    marginBottom: 2,
    '&:hover': {
      backgroundColor: theme.palette.ds.lightBlue,
    },
    '&:active': {
      backgroundColor: theme.palette.ds.hovered.lightBlue,
    },
  },
  tag: {
    height: 20,
    borderRadius: 2,
    lineHeight: '20px',
    fontSize: 12,
    fontWeight: 400,
    padding: '0px 4px',
    color: theme.palette.ds.grey900,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

type Props = {
  elements: DropdownElement[];
  onSelect: (elem: DropdownElement) => void;
  selectedItem: DropdownElement | null;
};

type DropdownElement = {
  id: string;
  value: string;
  hexVal?: string;
};

const TagDropdown: FC<Props> = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const itemRenderer = (item: DropdownElement, { handleClick }: IItemRendererProps) => {
    const color = Color(item.hexVal ?? '#FFF');

    return (
      <li className={classes.menuItem} key={item.id} onClick={handleClick}>
        <div
          className={classes.tag}
          style={{
            backgroundColor: item.id === '' ? 'transparent' : color.hex(),
            color: color.isDark() ? theme.palette.ds.white : theme.palette.ds.grey900,
          }}>
          {item.value}
        </div>
      </li>
    );
  };

  const viewSelected = () => {
    if (props.selectedItem) {
      const color = Color(props.selectedItem.hexVal ?? '#FFF');
      return (
        <div
          className={classes.tag}
          style={{
            backgroundColor: color.hex(),
            color: color.isDark() ? theme.palette.ds.white : theme.palette.ds.grey900,
          }}>
          {props.selectedItem.value}
        </div>
      );
    }
    return <div className={classes.tag}>No Tag</div>;
  };

  return (
    <Select
      className={classes.root}
      disabled={props.elements.length === 0}
      filterable={false}
      itemRenderer={itemRenderer}
      items={[...props.elements, { id: '', value: 'No Tag' }]}
      onItemSelect={(item) => {
        if (item.id !== props.selectedItem?.id) {
          props.onSelect(item);
        }
      }}
      popoverProps={{ minimal: true, className: classes.menu, usePortal: false }}>
      <div className={classes.dropdownButton}>
        {viewSelected()}
        <Icon color={theme.palette.ds.grey400} icon="caret-down" />
      </div>
    </Select>
  );
};

export default TagDropdown;
