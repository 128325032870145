import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { logInUserSuccess } from 'actions/userActions';
import { clearDashboardLayoutReducer, setSyncCategoryColors } from 'actions/dashboardLayoutActions';
import { updateDashboardSyncCategoryColorsSuccess } from 'actions/dashboardTemplateActions';
import { fetchDataPanelRequest, fetchDataPanelSuccess } from 'actions/dataPanelTemplateAction';
import { fetchArchitectCustomerDashboardsSuccess } from 'actions/architectCustomerDashboardActions';
import {
  embedFetchDashboardSuccess,
  embedFetchDataPanelRequest,
  embedFetchDataPanelSuccess,
} from 'actions/shareActions';
import { saveGlobalStylesSuccess } from 'actions/styleConfigActions';
import {
  ColorCategoryTracker,
  ColorFormat,
  COLOR_SYNC_CHART_TYPES,
  OPERATION_TYPES,
} from 'constants/types';
import { DEFAULT_GLOBAL_STYLE_CONFIG, getBaseGlobalStyles } from 'globalStyles';
import { GlobalStyleConfig } from 'globalStyles/types';
import { setColorCategoryData } from 'utils/colorCategorySyncUtils';
import { getSyncCategoryColors } from 'utils/exploResourceUtils';

type DataPanelColorInfo = { operationType: OPERATION_TYPES; colorFormat?: ColorFormat };

interface DashboardStylesReducer {
  globalStyleConfig: GlobalStyleConfig;
  colorCategoryTracker: ColorCategoryTracker;
  syncCategoryColors: boolean;

  // Store relevant info during request
  // Have here since jobs don't store full post data
  dataPanelIdToColorInfo: Record<string, DataPanelColorInfo | undefined>;
}

const initialState: DashboardStylesReducer = {
  globalStyleConfig: DEFAULT_GLOBAL_STYLE_CONFIG,
  colorCategoryTracker: {},
  syncCategoryColors: false,
  dataPanelIdToColorInfo: {},
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(clearDashboardLayoutReducer, (state) => {
      state.colorCategoryTracker = {};
      state.dataPanelIdToColorInfo = {};
    })
    .addCase(embedFetchDashboardSuccess, (state, { payload }) => {
      state.globalStyleConfig = getBaseGlobalStyles(payload.style_config_v2);
      state.syncCategoryColors = getSyncCategoryColors(payload.dashboard_template);
    })
    .addCase(fetchArchitectCustomerDashboardsSuccess, (state, { payload }) => {
      state.globalStyleConfig = getBaseGlobalStyles(payload.style_config);
      state.syncCategoryColors = getSyncCategoryColors(payload.canvas);
    })
    .addCase(updateDashboardSyncCategoryColorsSuccess, (state, { payload }) => {
      state.syncCategoryColors = payload.postData.sync_category_colors;
    })
    .addCase(setSyncCategoryColors, (state, { payload }) => {
      state.syncCategoryColors = payload;
    })
    .addCase(logInUserSuccess, (state, { payload }) => {
      if (!payload.team) return;
      state.globalStyleConfig = getBaseGlobalStyles(payload.team.style_config_v2);
    })
    .addCase(saveGlobalStylesSuccess, (state, { payload }) => {
      state.globalStyleConfig = payload.config_v2;
    })
    .addMatcher(
      isAnyOf(embedFetchDataPanelRequest, fetchDataPanelRequest),
      (state, { payload }) => {
        if (!state.syncCategoryColors) return;
        const visualizeOp = payload.postData.config.visualize_op;
        if (!COLOR_SYNC_CHART_TYPES.includes(visualizeOp.operation_type)) return;

        state.dataPanelIdToColorInfo[payload.postData.id] = {
          operationType: visualizeOp.operation_type,
          colorFormat: visualizeOp.instructions.V2_TWO_DIMENSION_CHART?.colorFormat,
        };
      },
    )
    .addMatcher(
      isAnyOf(embedFetchDataPanelSuccess, fetchDataPanelSuccess),
      (state, { payload }) => {
        if (!state.syncCategoryColors) return;

        const colorInfo = state.dataPanelIdToColorInfo[payload.postData.id];
        if (!colorInfo) return;
        setColorCategoryData(
          state.colorCategoryTracker,
          state.globalStyleConfig,
          colorInfo.operationType,
          colorInfo.colorFormat,
          payload.data_panel_template._rows,
          payload.data_panel_template._schema,
        );
        delete state.dataPanelIdToColorInfo[payload.postData.id];
      },
    )
    .addDefaultCase((state) => state);
});
