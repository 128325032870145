import { FC } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';

import { Button, sprinkles } from 'components/ds';

import { CustomerReportFilter } from 'actions/customerReportActions';
import { FilterValueType } from 'constants/types';
import { FilterOperator } from 'types/filterOperations';
import { FilterableColumn } from 'utils/customerReportUtils';
import { isFilterClauseIncomplete } from 'utils/dataPanelConfigUtils';
import { applyFilterThunk, deleteFilterThunk } from 'reportBuilderContent/thunks';

type Props = {
  clause: CustomerReportFilter | undefined;
  column: FilterableColumn;
  operator: FilterOperator;
  value?: FilterValueType;
  className?: string;
};

const buttonRowClass = sprinkles({
  flexItems: 'alignCenter',
  justifyContent: 'flex-end',
  marginTop: 'sp2',
});

export const ApplyFilterRow: FC<Props> = ({ clause, column, operator, value, className }) => {
  const dispatch = useDispatch();

  const col = { name: column.name, type: column.type };
  const newClause = { filterColumn: col, filterOperation: { id: operator }, filterValue: value };

  return (
    <div className={cx(className, buttonRowClass)}>
      {clause && !isFilterClauseIncomplete(clause) ? (
        <Button
          className={sprinkles({ marginRight: 'sp2' })}
          onClick={() => dispatch(deleteFilterThunk(clause.id))}
          type="tertiary">
          Delete
        </Button>
      ) : null}
      <Button
        disabled={
          isFilterClauseIncomplete(newClause) ||
          (operator === clause?.filterOperation.id && value === clause?.filterValue)
        }
        onClick={() => {
          const applyInfo = clause ? { filterId: clause.id } : { column: col };
          dispatch(
            applyFilterThunk({
              ...applyInfo,
              value,
              filterOperator: operator,
              isPostFilter: column.isPostFilter,
            }),
          );
        }}
        type="primary">
        Apply
      </Button>
    </div>
  );
};
