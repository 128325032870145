import { FC, useMemo, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { Icon } from '@blueprintjs/core';
import { keyBy } from 'utils/standard';
import cx from 'classnames';
import produce from 'immer';

import {
  SortableList,
  SortableListItem,
  SortableListItemDragHandle,
} from 'components/SortableList/SortableList';
import Checkbox from 'components/checkbox';

import { VisualizeOperation } from 'types/dataPanelTemplate';
import { CanvasDataset } from 'actions/canvasConfigActions';
import { sortSchemaByOrderedColumnNames } from 'utils/general';
import { SchemaChange } from 'constants/types';
import { DatasetColumn } from 'types/datasets';

const useStyles = makeStyles((theme: Theme) => ({
  column: {
    display: 'flex',
    height: 28,
    alignItems: 'center',
    padding: 4,
    fontSize: 14,

    '&:hover': {
      backgroundColor: theme.palette.ds.lightBlue,
      borderRadius: theme.spacing(0.5),
    },

    '&:hover .columnText': {
      color: theme.palette.ds.blue,
    },
  },
  columName: {
    color: theme.palette.ds.grey800,
    marginLeft: 12,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  columnType: {
    color: theme.palette.ds.grey600,
    marginLeft: 'auto',
    textTransform: 'capitalize',
  },
  dragIconColor: { color: theme.palette.ds.grey700, marginRight: theme.spacing(1) },
  draggedOver: {
    borderBottom: `3px solid ${theme.palette.ds.grey400}`,
    borderRadius: theme.spacing(0.5),
  },
  selectAll: {
    cursor: 'pointer',
    color: theme.palette.ds.grey800,
    fontSize: 10,
    fontWeight: 500,
    marginBottom: theme.spacing(1),

    '&:hover': {
      textDecoration: 'underline',
    },
  },
  viewAllColumns: {
    padding: `0px ${theme.spacing(2)}px`,
    height: 28,
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.ds.grey100,
    },
  },
  viewAllChevron: {
    marginRight: theme.spacing(2),
    color: theme.palette.ds.grey800,
  },
  viewAllText: {
    color: theme.palette.ds.grey800,
    fontSize: 14,
  },
  dragHandle: {
    '&:hover': {
      cursor: 'grab',
    },
    '&:active': {
      cursor: 'grabbing',
    },
  },
}));

type Props = {
  dataset: CanvasDataset;
  isArchitectCustomerDashboard: boolean;
  visualizeOperation: VisualizeOperation;

  updateVizOp: (newViz: VisualizeOperation) => void;
};

export default function TableConfig({
  dataset,
  isArchitectCustomerDashboard,
  visualizeOperation,
  updateVizOp,
}: Props): JSX.Element {
  const classes = useStyles();

  const [viewAll, setViewAll] = useState(!isArchitectCustomerDashboard);

  const instructions = visualizeOperation.instructions.VISUALIZE_TABLE;

  const orderedSchema = useMemo(
    () =>
      sortSchemaByOrderedColumnNames(dataset.schema || [], instructions.orderedColumnNames).filter(
        (col) => dataset.columnOptions[col.name]?.isVisible,
      ),
    [dataset.schema, dataset.columnOptions, instructions.orderedColumnNames],
  );

  const changeSchemaByCol = useMemo(
    () => keyBy(instructions.changeSchemaList, 'col'),
    [instructions.changeSchemaList],
  );

  const allVisible = orderedSchema.every((col) => {
    const changeSchema = changeSchemaByCol[col.name];
    return changeSchema ? changeSchema.keepCol : true;
  });

  const updateVisibility = (
    changeSchemaList: SchemaChange[],
    col: DatasetColumn,
    visibility: boolean,
  ) => {
    const schemaChangeIdx = changeSchemaList.findIndex((change) => change.col === col.name);
    if (schemaChangeIdx >= 0) {
      changeSchemaList[schemaChangeIdx].keepCol = visibility;
    } else {
      changeSchemaList.push({ col: col.name, keepCol: visibility });
    }
  };

  const updateAllCols = () => {
    const newViz = produce(visualizeOperation, (draft) => {
      const changeSchemaList = draft.instructions.VISUALIZE_TABLE.changeSchemaList;
      orderedSchema.forEach((col) => updateVisibility(changeSchemaList, col, !allVisible));
    });
    updateVizOp(newViz);
  };

  return (
    <>
      <div className={classes.selectAll} onClick={updateAllCols}>
        {allVisible ? 'Unselect all' : 'Select all'}
      </div>
      <SortableList
        getIdFromElem={(col) => col.name}
        onListUpdated={(newList) => {
          const newViz = produce(visualizeOperation, (draft) => {
            draft.instructions.VISUALIZE_TABLE.orderedColumnNames = newList.map((col) => col.name);
          });
          updateVizOp(newViz);
        }}
        sortableItems={orderedSchema}>
        {(viewAll ? orderedSchema : orderedSchema.slice(0, 6)).map((col) => {
          const columnOption = dataset.columnOptions[col.name];

          const changeSchema = changeSchemaByCol[col.name];
          const isVisible = changeSchema ? changeSchema.keepCol : true;

          return (
            <SortableListItem key={`table-config-${col.name}`} sortId={col.name}>
              <SortableColumn
                colName={columnOption.name}
                colType={col.type.toLowerCase()}
                isVisible={isVisible}
                key={`table-config-${col.name}`}
                updateVisibility={() => {
                  const newViz = produce(visualizeOperation, (draft) => {
                    updateVisibility(
                      draft.instructions.VISUALIZE_TABLE.changeSchemaList,
                      col,
                      !isVisible,
                    );
                  });
                  updateVizOp(newViz);
                }}
              />
            </SortableListItem>
          );
        })}
      </SortableList>
      {!viewAll && orderedSchema.length > 6 ? (
        <div className={classes.viewAllColumns} onClick={() => setViewAll(true)}>
          <Icon className={classes.viewAllChevron} icon="chevron-down" />
          <span className={classes.viewAllText}>{`See all ${orderedSchema.length} columns`}</span>
        </div>
      ) : null}
    </>
  );
}

type SortableColumnProps = {
  colName: string;
  colType: string;
  isVisible: boolean;
  updateVisibility: () => void;
};

const SortableColumn: FC<SortableColumnProps> = ({
  colName,
  colType,
  isVisible,
  updateVisibility,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.column}>
      <SortableListItemDragHandle className={classes.dragIconColor} />
      <Checkbox ignoreCustomStyles isChecked={isVisible} onChange={updateVisibility} />
      <div className={cx(classes.columName, 'columnText')} title={colName}>
        {colName}
      </div>
      <div className={cx(classes.columnType, 'columnText')}>{colType}</div>
    </div>
  );
};
