import { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  pdfPage: {
    height: 792,
    width: '100%',
    overflow: 'hidden',
  },
}));

type Props = {
  className?: string;
  children: JSX.Element[];
};

const PDFLayout: FC<Props> = ({ className, children }) => {
  const classes = useStyles();

  return (
    <div className={className}>
      {children.map((child, index) => (
        <div className={classes.pdfPage} key={`pdf-page-${index}`}>
          {child}
        </div>
      ))}
    </div>
  );
};

export default PDFLayout;
