import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';

import { KPIGeneralFormat, V2KPIChartInstructions } from 'constants/types';
import {
  KPI_HORIZ_CONTENT_ALIGNMENT_FORMATS,
  KPI_VERT_CONTENT_ALIGNMENT_FORMATS,
  KPI_ORDERED_HORIZ_CONTENT_ALIGNMENTS,
  KPI_ORDERED_VERT_CONTENT_ALIGNMENTS,
  KPI_TITLE_VALUE_ARRANGEMENTS_FORMATS,
  KPI_ORDERED_TITLE_VALUE_ARRANGEMENTS,
} from 'constants/kpiAlignmentConstants';
import { sprinkles } from 'components/ds';

type Props = {
  configInputClass: string;
  instructions: V2KPIChartInstructions;

  updateGeneralFormat: (generalFormat: KPIGeneralFormat) => void;
};

export default function SharedKPIGeneralConfigs({
  configInputClass,
  instructions,
  updateGeneralFormat,
}: Props) {
  const selectedHorizAlignment =
    instructions.generalFormat?.alignment || KPI_HORIZ_CONTENT_ALIGNMENT_FORMATS.CENTER_ALIGN.id;

  const selectedVertAlignment =
    instructions.generalFormat?.vertical_content_alignment ||
    KPI_VERT_CONTENT_ALIGNMENT_FORMATS.CENTER.id;

  const selectedTitleArrangement =
    instructions?.generalFormat?.title_value_arrangement ||
    KPI_TITLE_VALUE_ARRANGEMENTS_FORMATS.ABOVE.id;

  return (
    <>
      <InputWithBlurSave
        containerClassName={configInputClass}
        helpIcon="code"
        helpText="This field can take in variables"
        initialValue={instructions.generalFormat?.subtitle}
        label="Description"
        onNewValueSubmitted={(newValue) => updateGeneralFormat({ subtitle: newValue })}
      />
      <ToggleButtonGroup fillWidth className={configInputClass}>
        {KPI_ORDERED_TITLE_VALUE_ARRANGEMENTS.map((alignment) => (
          <ToggleButton
            active={selectedTitleArrangement === alignment.id}
            className={sprinkles({ color: 'gray11' })}
            icon={alignment.icon}
            key={alignment.id}
            onClick={() => updateGeneralFormat({ title_value_arrangement: alignment.id })}
          />
        ))}
      </ToggleButtonGroup>
      <div className={sprinkles({ justifyContent: 'center', display: 'flex' })}>
        <ToggleButtonGroup
          fillWidth
          className={sprinkles({
            width: 'fill',
            paddingRight: 'sp.5',
            paddingLeft: 'sp1.5',
            marginBottom: 'sp1',
          })}>
          {KPI_ORDERED_VERT_CONTENT_ALIGNMENTS.map((alignment) => (
            <ToggleButton
              active={selectedVertAlignment === alignment.id}
              className={sprinkles({ color: 'gray11' })}
              icon={alignment.icon}
              key={alignment.id}
              onClick={() => updateGeneralFormat({ vertical_content_alignment: alignment.id })}
            />
          ))}
        </ToggleButtonGroup>
        <ToggleButtonGroup
          fillWidth
          className={sprinkles({
            width: 'fill',
            paddingRight: 'sp1.5',
            paddingLeft: 'sp.5',
            marginBottom: 'sp1',
          })}>
          {KPI_ORDERED_HORIZ_CONTENT_ALIGNMENTS.map((alignment) => (
            <ToggleButton
              active={selectedHorizAlignment === alignment.id}
              className={sprinkles({ color: 'gray11' })}
              icon={alignment.icon}
              key={alignment.id}
              onClick={() => updateGeneralFormat({ alignment: alignment.id })}
            />
          ))}
        </ToggleButtonGroup>
      </div>
    </>
  );
}
