import { FC } from 'react';
import produce from 'immer';

import DropdownSelect from 'shared/DropdownSelect';

import { TimePeriodDropdownElemConfig } from 'types/dashboardTypes';

type Props = {
  config: TimePeriodDropdownElemConfig;

  updateDropdownConfig: (config: TimePeriodDropdownElemConfig) => void;
};

export const TimePeriodDefaultValuesConfig: FC<Props> = ({ config, updateDropdownConfig }) => {
  const options = config.values.map((option) => ({
    id: String(option.value),
    value: option.value,
    name: option.name,
  }));

  const selectedDefault = config.defaultValue
    ? options.find((val) => val.value === config.defaultValue)
    : undefined;

  return (
    <DropdownSelect
      fillWidth
      minimal
      showIcon
      filterable={false}
      label="Default value"
      noSelectionText="Select default option"
      onChange={(newValue) => {
        const newConfig = produce(config, (draft) => {
          draft.defaultValue = newValue.value as number;
        });
        updateDropdownConfig(newConfig);
      }}
      options={options}
      selectedItem={selectedDefault}
    />
  );
};
