import { FC, useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NoDataSelected } from 'pages/ReportBuilder/ReportView/NoDataSelected';
import { IconButton } from 'components/ds';
import { ViewTabItem } from 'pages/ReportBuilder/ReportView/Tabs/ViewTabItem';
import { ViewContent } from 'pages/ReportBuilder/ReportView/ViewContent';

import { CustomerReportDataInfo, CustomerReportView } from 'actions/customerReportActions';
import { createView } from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import * as styles from 'pages/ReportBuilder/ReportView/Tabs/ViewTabs.css';

const SCROLL_AMOUNT = 100;
const TOOLTIP_DELAY = 1000;

interface Props {
  views: CustomerReportView[]; // Views that may have been modified on the frontend
  savedViews: CustomerReportView[]; // Views that have been saved to the DB
  dataInfo?: CustomerReportDataInfo;
}

export const ViewTabs: FC<Props> = ({ dataInfo, views, savedViews }) => {
  const dispatch = useDispatch();
  const tabsListRef = useRef<HTMLDivElement>(null);
  const [canScroll, setCanScroll] = useState(false);
  const currentViewId = useSelector(
    (state: ReportBuilderReduxState) => state.reportEditing.currentView,
  );
  const currentView = views.find((view) => currentViewId === view.id);

  // After the view list is re-rendered, toggle the scroll buttons if needed
  useLayoutEffect(() => {
    setCanScroll(
      views.length > 0 &&
        !!tabsListRef.current &&
        tabsListRef.current.scrollWidth > tabsListRef.current.offsetWidth,
    );
  }, [views]);

  const handleScrollLeft = useCallback(() => {
    tabsListRef.current?.scrollBy(-SCROLL_AMOUNT, 0);
  }, []);

  const handleScrollRight = useCallback(() => {
    tabsListRef.current?.scrollBy(SCROLL_AMOUNT, 0);
  }, []);

  return (
    <div className={styles.tabsRoot}>
      <div aria-label="Views" className={styles.tabsListContainer}>
        <IconButton
          name="plus"
          onClick={() => dispatch(createView())}
          tooltipProps={{ align: 'start', side: 'bottom', text: 'Create View' }}
        />
        <div className={styles.viewTabsContainer} ref={tabsListRef}>
          <div className={styles.viewTabs}>
            {views.map((view) => (
              <ViewTabItem key={view.id} savedViews={savedViews} view={view} />
            ))}
          </div>
        </div>
        <IconButton
          disabled={!canScroll}
          name="angle-left"
          onClick={handleScrollLeft}
          tooltipProps={{
            align: 'end',
            side: 'bottom',
            text: 'Scroll left',
            delayDuration: TOOLTIP_DELAY,
          }}
        />
        <IconButton
          disabled={!canScroll}
          name="angle-right"
          onClick={handleScrollRight}
          tooltipProps={{
            align: 'end',
            side: 'bottom',
            text: 'Scroll right',
            delayDuration: TOOLTIP_DELAY,
          }}
        />
      </div>
      {currentView && dataInfo ? (
        <ViewContent dataInfo={dataInfo} view={currentView} />
      ) : (
        <NoDataSelected />
      )}
    </div>
  );
};
