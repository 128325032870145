import cx from 'classnames';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { sprinkles } from 'components/ds';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import { Icon } from '@blueprintjs/core';

const useStyles = makeStyles(() => ({
  colorOption: {
    width: 20,
    height: 20,

    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

type Props = {
  className?: string;
  name: string;
  color: string;
  updateName: (newName: string) => void;
  updateColor: (ewColor: string) => void;
};

export default function TagEditPopover({ name, color, updateName, updateColor }: Props) {
  const classes = useStyles();
  const theme = useTheme();

  const ENV_TAG_COLORS = [
    theme.palette.envTagColors.blue,
    theme.palette.envTagColors.green,
    theme.palette.envTagColors.yellow,
    theme.palette.envTagColors.red,
    theme.palette.envTagColors.purple,
    theme.palette.envTagColors.lightPurple,
  ];

  return (
    <div className={sprinkles({ padding: 'sp1' })}>
      <InputWithBlurSave
        hideRightIconInteractions
        isCompact
        containerClassName={sprinkles({ width: 'fill', marginBottom: 'sp1' })}
        initialValue={name}
        onNewValueSubmitted={(newValue: string) => {
          if (!newValue) return;

          updateName(newValue.toLowerCase().replace(/[\W\s+]+/g, '_'));
        }}
      />
      <div
        className={sprinkles({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        })}>
        {ENV_TAG_COLORS.map((colorHex, i) => (
          <div
            className={cx(
              sprinkles({
                flexItems: 'center',
                borderRadius: 4,
                marginRight: i + 1 < ENV_TAG_COLORS.length ? 'sp.5' : 'sp0',
              }),
              classes.colorOption,
            )}
            key={`env-tag-color-${colorHex}`}
            onClick={() => updateColor(colorHex)}
            style={{ backgroundColor: colorHex }}>
            {colorHex === color && <Icon color={theme.palette.ds.white} icon="small-tick" />}
          </div>
        ))}
      </div>
    </div>
  );
}
