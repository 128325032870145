import { createAction } from '@reduxjs/toolkit';

import {
  OPERATION_TYPES,
  V2KPIChartInstructions,
  V2TwoDimensionChartInstructions,
  VisualizeOperationGeneralFormatOptions,
  VisualizeTableInstructions,
} from 'constants/types';
import { ACTION } from './types';

export const updateCanvasTemplateInstructions = createAction<{
  templateId: string;
  update: UpdateInstructionsPayload;
}>(ACTION.UPDATE_CANVAS_TEMPLATE_INSTRUCTIONS);

export const updateEUDataPanelInstructions = createAction<UpdateInstructionsPayload>(
  ACTION.UPDATE_END_USER_DATA_PANEL_INSTRUCTIONS,
);

export const GeneralFormatOptionsOperationType = 'GENERAL_FORMAT_OPTIONS';
// TODO: Will need to put full set of types here
export type UpdateInstructionsPayload =
  | { instructions: VisualizeTableInstructions; operationType: OPERATION_TYPES.VISUALIZE_TABLE }
  | {
      instructions: V2TwoDimensionChartInstructions;
      operationType:
        | OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2
        | OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2
        | OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2
        | OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2
        | OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2
        | OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2
        | OPERATION_TYPES.VISUALIZE_LINE_CHART_V2
        | OPERATION_TYPES.VISUALIZE_AREA_CHART_V2
        | OPERATION_TYPES.VISUALIZE_PIE_CHART_V2
        | OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2
        | OPERATION_TYPES.VISUALIZE_FUNNEL_V2
        | OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2;
    }
  | { instructions: V2KPIChartInstructions; operationType: OPERATION_TYPES.VISUALIZE_NUMBER_V2 }
  | {
      instructions: VisualizeOperationGeneralFormatOptions;
      operationType: typeof GeneralFormatOptionsOperationType;
    };
