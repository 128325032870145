import { FC, useState } from 'react';

import { Icon } from 'components/ds';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import * as styles from './item.css';
import cx from 'classnames';

type Props = {
  colName: string;
  className?: string;
  startOpen?: boolean;
  hideDescriptionConfig?: boolean;
  colDescription?: string;
  children?: JSX.Element | null;

  updateColumnInfo: (updates: { name?: string; description?: string }) => void;
};

export const DatasetColumnFormatItem: FC<Props> = ({
  children,
  className,
  colDescription,
  colName,
  startOpen,
  hideDescriptionConfig,
  updateColumnInfo,
}) => {
  const [isOpen, setIsOpen] = useState(startOpen ?? false);

  const sharedInputProps = {
    fillWidth: true,
    hideRightIconInteractions: true,
    containerClassName: styles.formatInput,
    ignoreCustomStyles: true,
  };

  return (
    <div className={cx(styles.columnFormat, className)}>
      <div className={styles.columnFormatHeader} onClick={() => setIsOpen(!isOpen)}>
        <Icon name={isOpen ? 'chevron-down' : 'chevron-right'} size="md" />
        <div className={styles.columnFormatName}>{colName}</div>
        {colDescription ? (
          <div className={styles.columnFormatDescription}>{colDescription}</div>
        ) : null}
      </div>
      {isOpen ? (
        <div className={styles.columnFormatBody}>
          <InputWithBlurSave
            {...sharedInputProps}
            initialValue={colName}
            label="Name"
            onNewValueSubmitted={(newName) => updateColumnInfo({ name: newName })}
          />
          {!hideDescriptionConfig ? (
            <InputWithBlurSave
              {...sharedInputProps}
              initialValue={colDescription}
              label="Description"
              onNewValueSubmitted={(newDescription) =>
                updateColumnInfo({ description: newDescription })
              }
            />
          ) : null}
          {children}
        </div>
      ) : null}
    </div>
  );
};
