import { createReducer } from '@reduxjs/toolkit';

import { EnvironmentTag } from 'actions/teamActions';
import { fetchCanvasVersionsSuccess } from 'actions/canvasVersionActions';
import { fetchDashboardVersionsSuccess } from 'actions/dashboardV2Actions';
import {
  createEnvironmentTagSuccess,
  updateEnvironmentTagSuccess,
} from 'actions/environmentTagActions';
import { fetchReportBuilderVersionsSuccess } from 'actions/reportBuilderVersionActions';

interface EnvironmentTagReducerState {
  tags: EnvironmentTag[];
}

const initialState: EnvironmentTagReducerState = {
  tags: [],
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(fetchCanvasVersionsSuccess, (state, { payload }) => {
      state.tags = payload.tags;
    })
    .addCase(fetchDashboardVersionsSuccess, (state, { payload }) => {
      state.tags = payload.tags;
    })
    .addCase(fetchReportBuilderVersionsSuccess, (state, { payload }) => {
      state.tags = payload.tags;
    })
    .addCase(updateEnvironmentTagSuccess, (state, { payload }) => {
      const tagIndex = state.tags.findIndex(({ id }) => id === payload.tag.id);
      if (tagIndex !== -1) state.tags[tagIndex] = payload.tag;
    })
    .addCase(createEnvironmentTagSuccess, (state, { payload }) => {
      state.tags.push(payload.tag);
    })
    .addDefaultCase((state) => state),
);
