import produce from 'immer';

import FormatSection from './FormatSection';
import SharedKPIValueConfigs from 'components/ChartConfigs/KPIValueConfigs';

import { sharedStyles } from './sharedStyles';
import { FormatConfigProps } from './types';
import { KPIValueFormat, OPERATION_TYPES, V2KPIChartInstructions } from 'constants/types';

type Props = FormatConfigProps<OPERATION_TYPES.VISUALIZE_NUMBER_V2, V2KPIChartInstructions>;

export default function KPIValueSection({
  isArchitectCustomerDashboard,
  instructions,
  operationType,
  updateInstructions,
}: Props): JSX.Element {
  const classes = sharedStyles({ isArchitectCustomerDashboard });

  const updateFormat = (valueFormat: KPIValueFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.valueFormat = { ...draft.valueFormat, ...valueFormat };
    });
    updateInstructions({ instructions: newInstructions, operationType });
  };

  return (
    <FormatSection
      defaultIsOpen
      isArchitectCustomerDashboard={isArchitectCustomerDashboard}
      title="Value">
      <SharedKPIValueConfigs
        isCanvas
        configInputClass={classes.configInput}
        instructions={instructions}
        operationType={operationType}
        updateValueFormat={updateFormat}
      />
    </FormatSection>
  );
}
