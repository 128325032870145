import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { closeReportModal } from 'reportBuilderContent/reducers/reportEditingReducer';
import { EmbedText } from '../EmbedText';
import { IconButton, sprinkles } from 'components/ds';
import { BaseModal } from './BaseModal';

import * as styles from './styles.css';

type Props = { footerContent: JSX.Element; title: string };

export const LargeModal: FC<Props> = ({ children, title, footerContent }) => {
  const dispatch = useDispatch();

  return (
    <BaseModal>
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <EmbedText heading="h1">{title}</EmbedText>
          <IconButton
            className={sprinkles({ cursor: 'pointer' })}
            name="cross"
            onClick={() => dispatch(closeReportModal())}
          />
        </div>
        {children}
        <div className={styles.footerContainer}>{footerContent}</div>
      </div>
    </BaseModal>
  );
};
