import produce from 'immer';
import { useDispatch, useSelector } from 'react-redux';

import SettingHeader from '../../DataConfigTab/SettingHeader';
import { SharedColorConfigs } from 'components/ChartConfigs/ColorConfigs';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import {
  ColorFormat,
  OPERATION_TYPES,
  V2ScatterPlotInstructions,
  V2TwoDimensionChartInstructions,
} from 'constants/types';
import { ReduxState } from 'reducers/rootReducer';
import { sharedStyles } from './styles';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2TwoDimensionChartInstructions | V2ScatterPlotInstructions;
  includeZones?: boolean;
};

export default function ColorConfig({ visualizationType, includeZones, instructions }: Props) {
  const sharedClasses = sharedStyles();
  const dispatch = useDispatch();
  /**
   * We use useSelector here instead of useContext because the context around content other than the DashboardLayout
   * uses the default theme to avoid using custom styles in Explo app components. But here we want to use the user's configured
   * theme to determine what to display, not the default.
   */
  const globalStyleConfig = useSelector(
    (state: ReduxState) => state.dashboardStyles.globalStyleConfig,
  );

  const updateColorFormat = (colorUpdates: ColorFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.colorFormat = { ...draft.colorFormat, ...colorUpdates };
    });
    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  return (
    <>
      <SettingHeader name="Colors" />
      <div className={sharedClasses.root}>
        <SharedColorConfigs
          configInputClass={sharedClasses.configInput}
          globalStyleConfig={globalStyleConfig}
          instructions={instructions}
          updateColorFormat={updateColorFormat}
          zonesAllowed={includeZones}
        />
      </div>
    </>
  );
}
