import { useState, FC } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';

import Button from 'shared/Button';
import VersionControlModal from 'components/VersionControlModal';
import { SavingInfo } from 'pages/dashboardPage/SavingInfo';
import EditResourceBannerDropdown from 'shared/ExploResource/EditResourceBannerDropdown';
import CustomerSelector from 'components/CustomerSelector';
import { sprinkles } from 'components/ds';

import { ReportBuilder } from 'actions/reportBuilderActions';
import { ResourcePageType } from 'types/exploResource';

type Props = {
  reportBuilder: ReportBuilder;
};

const rootClass = sprinkles({
  width: 'fill',
  display: 'flex',
  backgroundColor: 'white',
  borderBottom: 1,
  borderColor: 'gray7',
});

const editContainerClass = sprinkles({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingX: 'sp1.5',
});

const resourceBannerClass = sprinkles({ borderBottom: 1, borderRight: 1, borderColor: 'gray7' });

export const Header: FC<Props> = ({ reportBuilder }) => {
  const versionInfo = useSelector((state: ReduxState) => state.reportBuilderEdit.versionInfo);

  const [isVersionModalOpen, setIsVersionModalOpen] = useState(false);

  const renderVersionControlModal = () => {
    if (!isVersionModalOpen) return null;

    return (
      <VersionControlModal
        closeModal={() => setIsVersionModalOpen(false)}
        pageType={ResourcePageType.REPORT_BUILDER}
      />
    );
  };

  return (
    <div className={rootClass} style={{ minHeight: 56, height: 56 }}>
      <EditResourceBannerDropdown
        className={resourceBannerClass}
        pageType={ResourcePageType.REPORT_BUILDER}
        resource={reportBuilder}
      />
      <div className={editContainerClass}>
        <div className={sprinkles({ display: 'flex', alignItems: 'center' })}>
          <div className={sprinkles({ marginRight: 'sp1' })}>Viewing as</div>
          <CustomerSelector parseUrl />
        </div>
        <div className={sprinkles({ display: 'flex', alignItems: 'center' })}>
          {versionInfo ? (
            <>
              <SavingInfo
                isDraft={versionInfo.is_draft}
                resourceType={ResourcePageType.REPORT_BUILDER}
                versionNumber={versionInfo.version_number}
              />
              <Button
                onClick={() => setIsVersionModalOpen(true)}
                text="Version Control"
                type="secondary"
              />
            </>
          ) : null}
        </div>
      </div>
      {renderVersionControlModal()}
    </div>
  );
};
