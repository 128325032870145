import { FC, useContext, createContext, useMemo } from 'react';
import cx from 'classnames';
import * as styles from './styles.css';
import produce from 'immer';
import { DndContext, DraggableAttributes } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { CSS } from '@dnd-kit/utilities';

import { Icon, sprinkles, vars } from 'components/ds';
import { shiftItemsInList } from 'utils/general';

type SortableListProps<T> = {
  children: JSX.Element[];
  getIdFromElem: (item: T) => string;
  onListUpdated: (newList: T[], oldLocationId: string, newLocationId: string) => void;
  sortableItems: T[];
};

export function SortableList<T>({
  children,
  getIdFromElem,
  sortableItems,
  onListUpdated,
}: SortableListProps<T>) {
  const sortableIds = useMemo(
    () => sortableItems.map((item) => ({ id: getIdFromElem(item) })),
    [sortableItems, getIdFromElem],
  );

  return (
    <DndContext
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      onDragEnd={({ active, over }) => {
        if (!over) return;
        const reorderedItems = produce(sortableItems, (draft) => {
          shiftItemsInList(
            draft,
            sortableIds.findIndex((item) => item.id === (active.id as string)),
            sortableIds.findIndex((item) => item.id === (over.id as string)),
          );
        });
        onListUpdated(reorderedItems, active.id as string, over.id as string);
      }}>
      <SortableContext items={sortableIds} strategy={verticalListSortingStrategy}>
        {children}
      </SortableContext>
    </DndContext>
  );
}

export const SortableListItemContext = createContext<{
  attributes?: DraggableAttributes;
  listeners?: SyntheticListenerMap;
}>({});

type SortableListItemProps = {
  borderBottomOnDrag?: boolean;
  boxShadowOnDrag?: boolean;
  sortId: string;
};

export const SortableListItem: FC<SortableListItemProps> = ({
  borderBottomOnDrag,
  boxShadowOnDrag,
  sortId,
  children,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: sortId,
  });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 2 : undefined,
    position: isDragging ? 'relative' : undefined,
    boxShadow: boxShadowOnDrag && isDragging ? '2px 2px 8px rgba(0, 0, 0, 0.12)' : undefined,
    borderBottomColor: borderBottomOnDrag && isDragging ? vars.colors.outline : '',
  };

  return (
    <div ref={setNodeRef} style={style}>
      <SortableListItemContext.Provider value={{ attributes, listeners }}>
        {children}
      </SortableListItemContext.Provider>
    </div>
  );
};

export const SortableListItemDragHandle: FC<{ className?: string }> = ({ className }) => {
  const { attributes, listeners } = useContext(SortableListItemContext);
  return (
    <div {...attributes} {...listeners} className={sprinkles({ display: 'flex' })}>
      <Icon className={cx(styles.dragHandle, className)} name="vertical-grip" size="md" />
    </div>
  );
};

export const SortableListItemDragArea: FC<{ className?: string }> = ({ className, children }) => {
  const { attributes, listeners } = useContext(SortableListItemContext);
  return (
    <div {...attributes} {...listeners} className={cx(styles.dragHandle, className)}>
      {children}
    </div>
  );
};
