import { cloneDeep } from 'utils/standard';
import { Dispatch } from 'react';
import { createAction } from '@reduxjs/toolkit';

import { ACTION } from 'actions/types';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { DatasetColumn } from 'types/datasets';
import {
  AggregationColumnInfo,
  PivotOperationAggregation,
  OPERATION_TYPES,
  VisualizeTableInstructions,
  V2TwoDimensionChartInstructions,
  V2KPIChartInstructions,
  V2KPITrendInstructions,
  FilterValueType,
  SortOrder,
  V2BoxPlotInstructions,
  V2ScatterPlotInstructions,
  FilterValueSourceType,
  VisualizeOperationGeneralFormatOptions,
  VisualizeCollapsibleListInstructions,
} from 'constants/types';
import { FilterOperator } from 'types/filterOperations';

export const selectDashboardDataPanelToEdit = createAction<DataPanelTemplate>(
  ACTION.SELECT_DASHBOARD_DPT_TO_EDIT,
);

// SORTING

export const createSortClause = createAction(ACTION.CREATE_SORT_CLAUSE);

export const deleteSortClause = createAction<number>(ACTION.DELETE_SORT_CLAUSE);

export const selectSortColumn = createAction<{ index: number; column: DatasetColumn }>(
  ACTION.SELECT_SORT_COLUMN,
);

export const selectSortOrder = createAction<{ index: number; order: SortOrder }>(
  ACTION.SELECT_SORT_ORDER,
);

// FILTER TAB

export const createFilterClause = createAction<DatasetColumn | undefined>(
  ACTION.CREATE_FILTER_CLAUSE,
);

export const deleteFilterClause = createAction<number>(ACTION.DELETE_FILTER_CLAUSE);

export const selectFilterColumn = createAction<{ index: number; column: DatasetColumn }>(
  ACTION.SELECT_FILTER_COLUMN,
);

export const selectFilterOperator = createAction<{
  index: number;
  operator: FilterOperator;
}>(ACTION.SELECT_FILTER_OPERATOR);

export const updateFilterValue = createAction<{ index: number; value: FilterValueType }>(
  ACTION.UPDATE_FILTER_VALUE,
);

export const updateFilterMatch = createAction<boolean>(ACTION.UPDATE_FILTER_MATCH);

export const updateFilterValueSource = createAction<{
  index: number;
  newSource: FilterValueSourceType;
}>(ACTION.UPDATE_FILTER_VALUE_SOURCE);

export const updateFilterValueVariable = createAction<{
  index: number;
  variableId: string;
  property?: string;
}>(ACTION.UPDATE_FILTER_VALUE_VARIABLE);

// SUMMARIZE TAB

// groupings

export const createPivotedOnCol = createAction(ACTION.CREATE_PIVOTED_ON_COL);

export const deletePivotedOnCol = createAction<number>(ACTION.DELETE_PIVOTED_ON_COL);

export const updatePivotedOnCol = createAction<{
  index: number;
  aggregationColumnInfo: AggregationColumnInfo;
}>(ACTION.UPDATE_PIVOTED_ON_COL);

// calculations

export const createAggregation = createAction(ACTION.CREATE_AGGREGATION);

export const deleteAggregation = createAction<number>(ACTION.DELETE_AGGREGATION);

export const updateAggregation = createAction<{
  index: number;
  pivotOperationAggregation: PivotOperationAggregation;
}>(ACTION.UPDATE_AGGREGATION);

// VISUALIZE TAB

export const updateSelectedChart = createAction<OPERATION_TYPES>(ACTION.UPDATE_SELECTED_CHART);

// Visualize Table Section

export type CombinedVisualizeInstructions =
  | VisualizeTableInstructions
  | V2TwoDimensionChartInstructions
  | V2KPIChartInstructions
  | V2BoxPlotInstructions
  | V2ScatterPlotInstructions
  | V2KPITrendInstructions
  | VisualizeCollapsibleListInstructions;

export type UpdateVisualizeOperationPayload = {
  visualizeInstructions: CombinedVisualizeInstructions;
  operationType: OPERATION_TYPES;
};

//Helper function to not have to rewrite all the places its used
export const updateVisualizeOperation = (
  visualizeInstructions: CombinedVisualizeInstructions,
  operationType: OPERATION_TYPES,
) => {
  return updateVisualizeOperationAction({ visualizeInstructions, operationType });
};

export const updateVisualizeOperationAction = createAction<{
  visualizeInstructions: CombinedVisualizeInstructions;
  operationType: OPERATION_TYPES;
}>(ACTION.UPDATE_VISUALIZE_OP);

export const updateGeneralFormatOptions = createAction<VisualizeOperationGeneralFormatOptions>(
  ACTION.UPDATE_VISUALIZE_OPERATION_GENERAL_FORMAT_OPTIONS,
);

export function updateClonedVisualizationInstructions<T extends CombinedVisualizeInstructions>(
  oldInstructions: T,
  operationType: OPERATION_TYPES,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>,
  modifyInstructions: (newInstructions: T) => void,
) {
  const newInstructions = cloneDeep(oldInstructions);
  modifyInstructions(newInstructions);
  dispatch(updateVisualizeOperation(newInstructions, operationType));
}
