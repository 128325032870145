import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { CollapsibleListItemConfig } from './CollapsibleListItemConfig';
import { uniqueId } from 'utils/standard';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { DatasetSchema } from 'types/datasets';
import {
  OPERATION_TYPES,
  VisualizeTableInstructions,
  VisualizeCollapsibleListInstructions,
} from 'constants/types';

type Props = {
  instructions: VisualizeCollapsibleListInstructions;
  schema: DatasetSchema;
  originalGroupByCols: DatasetSchema;
};

export const CollapsibleListRowsConfig: FC<Props> = ({
  originalGroupByCols,
  instructions,
  schema,
}) => {
  const dispatch = useDispatch();

  const updateInstructions = (instructions: VisualizeCollapsibleListInstructions) => {
    dispatch(updateVisualizeOperation(instructions, OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST));
  };

  return (
    <>
      {originalGroupByCols.map((row) => {
        return (
          <CollapsibleListItemConfig
            column={row}
            instructions={instructions as VisualizeTableInstructions}
            isRowColumn={true}
            key={uniqueId(row.name)}
            schema={schema}
            updateInstructions={updateInstructions}
          />
        );
      })}
    </>
  );
};
