import { MouseEvent, MutableRefObject, useCallback, useMemo, useRef } from 'react';
import { TypeSortInfo } from '@inovua/reactdatagrid-enterprise/types';

import { Tooltip, Icon, IconButton } from 'components/ds';

import { sprinkles } from 'components/ds/sprinkles.css';
import { CustomerReportSort } from 'actions/customerReportActions';
import {
  DropdownOption,
  EmbeddedDropdownMenu,
} from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownMenu';
import { SortOrder } from 'constants/types';
import { DatasetColumn } from 'types/datasets';

interface ColumnHeaderProps {
  column: DatasetColumn;

  /** Sort info from React Data Grid */
  sort?: TypeSortInfo;
  onSort?: (sort: CustomerReportSort[]) => void;
  onFilter?: (column: DatasetColumn) => void;

  /** Ref for the parent container to create a boundary for Radix tooltips, dropdowns, and popovers */
  containerRef?: MutableRefObject<HTMLElement | null>;
}

export const ColumnHeader = ({
  sort,
  column,
  onSort,
  onFilter,
  containerRef,
}: ColumnHeaderProps) => {
  const shouldFilter = useRef(false);

  // Sort will only have at most one item corresponding to the current column
  const singleSort = Array.isArray(sort) ? sort[0] : sort;

  const menuOptions = useMemo<DropdownOption[]>(() => {
    const sortOptions: DropdownOption[] = onSort
      ? [
          {
            name: 'Sort in ascending order',
            icon: 'arrow-up-wide-short',
            onClick: () => onSort([{ column, order: SortOrder.ASC }]),
            disabled: singleSort?.dir === 1,
          },
          {
            name: 'Sort in descending order',
            icon: 'arrow-down-wide-short',
            onClick: () => onSort([{ column, order: SortOrder.DESC }]),
            disabled: singleSort?.dir === -1,
          },
        ]
      : [];

    const filterOption: DropdownOption[] = onFilter
      ? [
          {
            borderTop: true,
            name: 'Filter by column',
            icon: 'filter',
            onClick: () => {
              shouldFilter.current = true;
            },
          },
        ]
      : [];

    return [...sortOptions, ...filterOption];
  }, [column, onFilter, onSort, singleSort?.dir]);

  const handleToggleSort = useCallback(
    (e: MouseEvent) => {
      if (!onSort) return;
      e.stopPropagation();

      // Unsorted --> ASC --> DESC --> Unsorted...
      onSort(
        singleSort?.dir === 1
          ? [{ column, order: SortOrder.DESC }]
          : singleSort?.dir === -1
          ? []
          : [{ column, order: SortOrder.ASC }],
      );
    },
    [column, onSort, singleSort?.dir],
  );

  return (
    <div className={containerStyle} onClick={handleToggleSort} style={{ display: 'flex' }}>
      <div className={textStyle}>
        <Tooltip
          containerRef={containerRef}
          side="bottom"
          text={column.friendly_name || column.name}>
          <div className={sprinkles({ truncateText: 'ellipsis' })}>
            {column.friendly_name || column.name}
          </div>
        </Tooltip>
        {singleSort && (
          <Icon name={singleSort.dir === 1 ? 'arrow-up-wide-short' : 'arrow-down-wide-short'} />
        )}
      </div>
      {/* Wrapper div to prevent dropdown clicks from triggering sorting */}
      {menuOptions.length > 0 && (
        <div className={sprinkles({ flexItems: 'center' })} onClick={(e) => e.stopPropagation()}>
          <EmbeddedDropdownMenu
            containerRef={containerRef}
            menuOptions={menuOptions}
            onOpenChange={(open) => {
              // Radix bug: popovers, right after opening, will "blur" and close if there's another popover/dropdown that's closing
              // Wait until this dropdown closes before opening the filter popover
              if (!open && shouldFilter.current) {
                shouldFilter.current = false;
                onFilter?.(column);
              }
            }}>
            <span>
              <IconButton name="ellipsis-vertical" />
            </span>
          </EmbeddedDropdownMenu>
        </div>
      )}
    </div>
  );
};

const containerStyle = sprinkles({
  flexItems: 'alignCenter',
  justifyContent: 'space-between',
  width: 'fill',
  overflow: 'hidden',
  color: 'contentSecondary',
  paddingLeft: 'sp1',

  // Less right padding and gap because the ellipsis icon has its own padding
  paddingRight: 'sp.25',
  gap: 'sp.25',
});

const textStyle = sprinkles({
  flexItems: 'alignCenter',
  overflow: 'hidden',
  gap: 'sp1',
});
