import { Component } from 'react';
import { sortBy } from 'utils/standard';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import { ReduxState } from 'reducers/rootReducer';
import { RouteComponentProps } from 'react-router';
import produce from 'immer';
import { Menu, MenuDivider, Intent, NonIdealState, Icon, Divider } from '@blueprintjs/core';
import { v4 as uuidv4 } from 'uuid';

import DatasetMenuItem from 'pages/manageDataTablesPage/datasetMenuItem';
import DropdownSelect from 'shared/DropdownSelect';
import ErrorState from 'components/ErrorState';
import Poller from 'components/JobQueue/Poller';
import Button from 'shared/Button';
import { DataGrid, Spinner, sprinkles } from 'components/ds';

import { createLoadingSelector } from 'reducers/api/selectors';
import { fetchDatasets, fetchUsedParentSchemas } from 'actions/parentSchemaActions';
import {
  FetchDatasetPreviewBody,
  FetchDatasetRowCountBody,
  listTeamDataSources,
  TableDataset,
  fetchDatasetPreview,
  fetchDatasetRowCount,
} from 'actions/dataSourceActions';
import { bulkEnqueueJobs, JobDefinition } from 'actions/jobQueueActions';
import { ACTION } from 'actions/types';
import { Jobs } from 'components/JobQueue/types';
import { PaginatorProps } from 'components/ds/DataGrid/paginator';
import { isPagingDisabled } from 'components/ds/DataGrid/utils';

import { pageView, trackEvent, EVENTS } from 'analytics/exploAnalytics';
import { parseErrorMessage } from 'utils/queryUtils';
import { ROUTES } from 'constants/routes';
import { showCustomToast } from 'shared/sharedToasts';

const SIDEBAR_WIDTH = 250;

const styles = (theme: Theme) => ({
  sidebar: {
    height: '100%',
    width: SIDEBAR_WIDTH,
    backgroundColor: theme.palette.ds.white,
    overflowY: 'auto' as const,
    borderRight: `1px solid ${theme.palette.ds.grey500}`,
  },
  menuDivider: {
    fontWeight: 'bold' as const,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 5,
  },
  datasetViewer: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
  },
  datasetError: {
    backgroundColor: theme.palette.ds.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuItemTextContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dataSetSpinner: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  divider: {
    margin: '5px 0px',
    color: theme.palette.ds.grey300,
  },
  schemaDropdown: {
    padding: '0px 7px',
  },
  schemaTitle: {
    padding: '7px 0px',
  },
  errorCallout: {
    height: '100%',
    overflow: 'auto',

    '& .bp3-non-ideal-state-visual': {
      height: 50,
    },
    '& .bp3-icon-error': {
      paddingBottom: theme.spacing(3),
    },
  },
  unsyncedTables: {
    fontWeight: 500,
    fontSize: `12px !important`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 100px',
    textAlign: 'center' as const,
    height: '100%',
    width: `calc(100vw - ${SIDEBAR_WIDTH}px)`,
  },
  syncTablesButton: {
    padding: `8px`,
    fontWeight: 500,
  },
});

type MatchParams = {
  dataSourceId: string;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps<MatchParams> &
  WithStyles<typeof styles>;

type State = {
  selectedDatasetId: number | null;
  currentPreviewPage: number;
  selectedSchemaId?: number;
  dataSourceId: string;
  errorMessage?: string;

  awaitedJobs: Record<string, Jobs>;
};

class ManageDataTablesPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const dataSourceId = props.match.params.dataSourceId;

    this.state = {
      selectedDatasetId: null,
      currentPreviewPage: 1,
      dataSourceId: dataSourceId,
      awaitedJobs: {},
    };
    document.title = 'Explo | Manage Data';

    this.props.listTeamDataSources(
      {},
      (results) => {
        const selectedDataSource = results.dataSources.find(
          (ds) => ds.id === parseInt(this.state.dataSourceId),
        );

        this.setState({
          selectedSchemaId: selectedDataSource?.parent_schema_id,
        });

        this.props.fetchDatasets({ id: selectedDataSource?.parent_schema_id }, () => {
          this.fetchDatasetRows(this.state.dataSourceId);
        });
      },
      (error) => this.setState({ errorMessage: error.error_msg }),
    );
    this.props.fetchUsedParentSchemas();
  }

  componentDidMount() {
    pageView('Manage Data Tables');
  }

  render() {
    const { classes, datasetLoadingError, loading, datasets } = this.props;
    const { awaitedJobs, errorMessage } = this.state;

    if (datasetLoadingError) return <ErrorState text={datasetLoadingError.detail} />;
    if (errorMessage) return <ErrorState text={errorMessage} />;

    const datasetsIsEmpty = Object.keys(datasets).length === 0;
    return (
      <div
        className={sprinkles({
          parentContainer: 'fill',
          flexItems: 'alignCenter',
          justifyContent: 'flex-start',
          overflow: 'auto',
        })}>
        <Poller
          awaitedJobs={awaitedJobs}
          updateJobResult={(finishedJobIds, onComplete) => {
            if (finishedJobIds.length > 0)
              this.setState((currentState) => {
                const newAwaitedJobs = produce(currentState.awaitedJobs, (draft) =>
                  finishedJobIds.forEach((jobId) => delete draft[jobId]),
                );
                return { awaitedJobs: newAwaitedJobs };
              });

            onComplete();
          }}
        />
        <div className={classes.sidebar}>{this.renderDatasetsSidebar()}</div>
        <div className={classes.datasetViewer}>
          {datasetsIsEmpty && !loading && this.renderSyncTablesMessage()}
          {!datasetsIsEmpty && this.renderDatasetViewer()}
        </div>
      </div>
    );
  }

  fetchDatasetRows = (dataSourceId: string) => {
    const { datasets } = this.props;
    const { selectedDatasetId } = this.state;

    const selectedDataset =
      selectedDatasetId !== null
        ? datasets[selectedDatasetId]
        : sortBy(Object.values(datasets), (dataset) => dataset.table_name)[0];

    if (selectedDataset) {
      this.setState({
        selectedDatasetId: selectedDataset.id,
        dataSourceId: dataSourceId,
      });

      this.fetchDatasetPreviewWrapper({
        data_source_id: dataSourceId,
        dataset_id: selectedDataset.id,
      });

      this.fetchDatasetRowCountWrapper({
        data_source_id: dataSourceId,
        dataset_id: selectedDataset.id,
      });
    }
  };

  renderDatasetsSidebar = () => {
    const { classes, loading, datasets } = this.props;
    const { selectedSchemaId, dataSourceId } = this.state;
    if (loading || !selectedSchemaId || !dataSourceId) {
      return (
        <div className={classes.dataSetSpinner}>
          <Spinner size="lg" />
        </div>
      );
    }

    if (Object.keys(datasets).length === 0) {
      showCustomToast(
        <div>
          This schema has no source tables.
          <br />
          <br />
          Consider sync source tables for updated schema at the following link:
          <ul>
            <li>
              <a
                href={`/sync-tables/${selectedSchemaId}`}
                rel="noopener noreferrer"
                target="_blank">
                {`https://app.explo.co/sync-tables/${selectedSchemaId}`}
              </a>
            </li>
          </ul>
        </div>,
        { timeoutInSeconds: 10, icon: 'info-sign', intent: Intent.SUCCESS },
      );
    }

    return (
      <>
        {this.renderSchemaHeader()}
        <Divider className={classes.divider} />
        {this.renderDatasetList()}
      </>
    );
  };

  renderSchemaHeader = () => {
    const { classes, parentSchemas, dataSources, history } = this.props;
    const { selectedSchemaId, dataSourceId } = this.state;

    const schemaName =
      parentSchemas?.find((schema) => schema.id === (selectedSchemaId ?? -1))?.name ?? '';
    const schemaDataSources = dataSources
      ? dataSources.filter((ds) => ds.parent_schema_id === selectedSchemaId)
      : [];
    const selectedDataSource = schemaDataSources.find((ds) => String(ds.id) === dataSourceId);
    return (
      <Menu>
        <MenuDivider className={classes.schemaTitle} title={schemaName.toUpperCase()}></MenuDivider>
        <DropdownSelect
          fillWidth
          minimal
          showIcon
          containerClassName={classes.schemaDropdown}
          noSelectionText="Select DataSource"
          onChange={(item) => {
            history.replace(`/datasources/${item.id}`);
            this.fetchDatasetRows(item.id);
          }}
          options={schemaDataSources.map((dataSource) => ({
            id: String(dataSource.id),
            name: dataSource.name,
          }))}
          selectedItem={{
            id: dataSourceId,
            name: selectedDataSource?.name ?? dataSourceId,
          }}
        />
      </Menu>
    );
  };

  renderDatasetList = () => {
    const { datasets } = this.props;

    const sourceDatasetsOrdered = sortBy(Object.values(datasets), (dataset) => dataset.table_name);
    return (
      <div>
        <Menu>
          <MenuDivider title="Source Datasets"></MenuDivider>
          {sourceDatasetsOrdered.map((dataset) => this.renderDatasetItem(dataset))}
        </Menu>
      </div>
    );
  };

  renderDatasetItem = (dataset: TableDataset) => {
    const tableName =
      !dataset.table_name || dataset.table_name.length === 0 ? 'Untitled' : dataset.table_name;
    return (
      <DatasetMenuItem
        active={this.state.selectedDatasetId === dataset.id}
        key={`dataset-navbar-item-${dataset.id}`}
        name={tableName}
        onClick={() => {
          this.switchSelectedDataset(dataset);
          trackEvent(EVENTS.SELECTED_DATASET, {
            dataset_id: dataset.id,
            dataset_name: tableName,
          });
        }}
      />
    );
  };

  switchSelectedDataset = (newDataset: TableDataset) => {
    const { dataSourceId } = this.state;

    this.fetchDatasetPreviewWrapper({
      data_source_id: dataSourceId,
      dataset_id: newDataset.id,
    });

    this.fetchDatasetRowCountWrapper({
      data_source_id: dataSourceId,
      dataset_id: newDataset.id,
    });

    const stateUpdate = {
      selectedDatasetId: newDataset.id,
      currentPreviewPage: 1,
    };
    this.setState(stateUpdate);
  };

  renderDatasetViewer = () => {
    const { datasets } = this.props;
    const { selectedDatasetId } = this.state;

    if (selectedDatasetId === null || !datasets[selectedDatasetId]) {
      return <DataGrid loading />;
    }
    const selectedDataset = datasets[selectedDatasetId];

    if (selectedDataset._error) return this.renderDatasetTableError(selectedDataset);

    return this.renderDatasetPreview(selectedDataset);
  };

  renderSyncTablesMessage = () => {
    const { classes } = this.props;
    return (
      <div className={classes.unsyncedTables}>
        <Link to={ROUTES.SYNC_DATA_TABLES_NO_SCHEMA + `/${this.state.selectedSchemaId}`}>
          <h2>No source datasets were found, you may need to first sync your tables.</h2>{' '}
        </Link>
      </div>
    );
  };

  renderDatasetTableError = (dataset: TableDataset) => {
    const { classes } = this.props;
    return (
      <div className={classes.datasetError}>
        <NonIdealState
          action={
            <Button
              minimal
              icon="document-open"
              onClick={() => alert(dataset._error)}
              text="View full error"
            />
          }
          className={classes.errorCallout}
          description={parseErrorMessage(dataset._error)}
          icon={<Icon icon="error" iconSize={50} intent={Intent.DANGER} />}
          title="There was an error fetching the results"></NonIdealState>
      </div>
    );
  };

  renderDatasetPreview = (dataset: TableDataset) => {
    const { loading } = this.props;
    const { currentPreviewPage, dataSourceId } = this.state;

    const isTableLoading = loading || !dataset._rows;

    const paginatorProps: PaginatorProps = {
      totalRowCount: dataset._total_row_count,
      currentPage: currentPreviewPage,
      loading: isTableLoading,
      isPagingDisabled: isPagingDisabled(dataset._unsupported_operations),
      goToPage: ({ page, offset }) => {
        this.fetchDatasetPreviewWrapper({
          data_source_id: dataSourceId,
          dataset_id: dataset.id,
          offset,
        });
        this.setState({ currentPreviewPage: page });
      },
    };

    return (
      <DataGrid
        loading={isTableLoading}
        paginatorProps={paginatorProps}
        rows={dataset._rows}
        schema={dataset.schema || []}
      />
    );
  };

  fetchDatasetPreviewWrapper = (postData: FetchDatasetPreviewBody) => {
    const { fetchDatasetPreview, shouldUseJobQueue } = this.props;
    const { selectedDatasetId } = this.state;

    if (!shouldUseJobQueue) fetchDatasetPreview({ postData });
    else
      this.bulkEnqueueJobs([
        {
          job_type: ACTION.FETCH_DATASET_PREVIEW,
          job_args: { ...postData, id: selectedDatasetId },
        } as JobDefinition,
      ]);
  };

  fetchDatasetRowCountWrapper = (postData: FetchDatasetRowCountBody) => {
    const { fetchDatasetRowCount, shouldUseJobQueue } = this.props;
    const { selectedDatasetId } = this.state;

    if (!shouldUseJobQueue) fetchDatasetRowCount({ postData });
    else
      this.bulkEnqueueJobs([
        {
          job_type: ACTION.FETCH_DATASET_ROW_COUNT,
          job_args: { ...postData, id: selectedDatasetId },
        } as JobDefinition,
      ]);
  };

  bulkEnqueueJobs = (jobs: JobDefinition[] | undefined) => {
    const { bulkEnqueueJobs } = this.props;

    if (jobs === undefined || jobs.length === 0) return;

    const jobMap = Object.assign({}, ...jobs.map((job) => ({ [uuidv4()]: job })));

    bulkEnqueueJobs?.({ jobs: jobMap }, (jobs) => {
      this.setState((currentState) => {
        return {
          awaitedJobs: produce(currentState.awaitedJobs, (draft) => ({
            ...draft,
            ...jobs,
          })),
        };
      });
    });
  };
}

const mapStateToProps = (state: ReduxState) => ({
  // We need to default loading to true so that we don't encounter false -> true -> false
  // loading sequences that make UI elements that depend on loading state jumpy.
  loading: createLoadingSelector(
    [ACTION.FETCH_DATASETS, ACTION.FETCH_USED_PARENT_SCHEMAS],
    true,
  )(state),
  datasets: state.datasets.datasets,
  parentSchemas: state.parentSchemas.usedParentSchemas,
  dataSources: state.dataSourceList.dataSources,
  datasetLoadingError: state.datasets.error,
  shouldUseJobQueue: !!state.currentUser.team?.feature_flags.use_job_queue,
});

const mapDispatchToProps = {
  fetchDatasets,
  fetchDatasetPreview,
  fetchDatasetRowCount,
  fetchUsedParentSchemas,
  listTeamDataSources,
  bulkEnqueueJobs,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ManageDataTablesPage)),
);
