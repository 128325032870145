import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EmbedText } from '../EmbedText';
import { Button, IconButton, sprinkles } from 'components/ds';
import { BaseModal } from 'pages/ReportBuilder/ModalViews/BaseModal';

import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import {
  closeReportModal,
  openDatasetModal,
} from 'reportBuilderContent/reducers/reportEditingReducer';

export const ConfirmChangeDatasetModal: FC = () => {
  const dispatch = useDispatch();

  const currentDatasetId = useSelector(
    (state: ReportBuilderReduxState) => state.reportEditing.currentDatasetId,
  );

  if (!currentDatasetId) return null;

  return (
    <BaseModal>
      <div className={sprinkles({ flexItems: 'column', padding: 'sp3' })} style={{ maxWidth: 480 }}>
        <div
          className={sprinkles({
            flexItems: 'alignCenter',
            justifyContent: 'space-between',
            paddingBottom: 'sp3',
          })}>
          <EmbedText heading="h1">Clear current report builder</EmbedText>
          <IconButton
            className={sprinkles({ cursor: 'pointer' })}
            name="cross"
            onClick={() => dispatch(closeReportModal())}
          />
        </div>
        <EmbedText body="b1">
          You are attempting to select a new dataset. This will clear your existing report.
        </EmbedText>
        <div
          className={sprinkles({
            flexItems: 'alignCenter',
            justifyContent: 'flex-end',
            paddingTop: 'sp3',
            gap: 'sp2',
          })}>
          <Button onClick={() => dispatch(closeReportModal())} type="secondary">
            Cancel
          </Button>
          <Button onClick={() => dispatch(openDatasetModal(currentDatasetId))} type="destructive">
            Select new dataset
          </Button>
        </div>
      </div>
    </BaseModal>
  );
};
