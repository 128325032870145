import cx from 'classnames';
import { useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import { sprinkles } from 'components/ds';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import { sharedStyles } from './sharedDroppedStyles';
import Button from 'shared/Button';
import { showErrorToast } from 'shared/sharedToasts';
import { ColumnOptionsPopover } from './ColumnOptionsPopover';

import { CategoryChartColumnInfo } from 'constants/types';
import { DatasetSchema, DatasetColumn } from 'types/datasets';

type StyleProps = {
  isEditingBucket: boolean;
  isExpanded: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: ({ isEditingBucket, isExpanded }: StyleProps) => ({
    backgroundColor:
      isEditingBucket && isExpanded ? theme.palette.ds.grey200 : theme.palette.ds.lightBlue,
    padding: isExpanded ? theme.spacing(1) : 0,
    borderRadius: 3,
  }),
  greyText: { color: `${theme.palette.ds.grey900} !important` },
  columnsPopoverTargetContainer: ({ isExpanded }: StyleProps) => ({
    backgroundColor: isExpanded ? theme.palette.ds.hovered.lightBlue : theme.palette.ds.lightBlue,
    width: isExpanded ? '100%' : 200,
  }),
  // Includes styles for bucket by vs all values and bucket value input
  bucketDropdownInput: ({ isEditingBucket, isExpanded }: StyleProps) => ({
    backgroundColor: isExpanded
      ? isEditingBucket
        ? theme.palette.ds.white
        : theme.palette.ds.hovered.lightBlue
      : theme.palette.ds.lightBlue,
    color: isEditingBucket && isExpanded ? theme.palette.ds.grey900 : theme.palette.ds.lightBlue,
    width: isExpanded ? '80%' : '50%',
    '& .bp3-popover-wrapper.bp3-fill': {
      width: 'auto',
    },
  }),
  // Bucket input value container width
  bucketValueInput: {
    width: '50% !important',
  },
  bucketIconBtn: ({ isEditingBucket, isExpanded }: StyleProps) => ({
    '&.bp3-button': {
      backgroundColor: isExpanded
        ? isEditingBucket
          ? `${theme.palette.ds.white} !important`
          : `${theme.palette.ds.hovered.lightBlue} !important`
        : `${theme.palette.ds.lightBlue} !important`,
    },
    '& .bp3-icon': {
      color: isEditingBucket && isExpanded ? theme.palette.ds.grey900 : theme.palette.ds.blue,
    },
    borderRadius: !isExpanded ? `0px !important` : 'none',
    borderRight: !isExpanded
      ? `1px solid ${theme.palette.ds.pressed.lightBlue} !important`
      : 'none',
  }),
  crossIcon: ({ isEditingBucket, isExpanded }: StyleProps) => ({
    '&.bp3-button': {
      backgroundColor:
        isExpanded && isEditingBucket
          ? `${theme.palette.ds.grey200} !important`
          : `${theme.palette.ds.lightBlue} !important`,
    },
    '& .bp3-icon': {
      color: theme.palette.ds.blue,
    },
    borderRadius: !isExpanded ? `0px !important` : 'none',
  }),
}));

type Props = {
  column: CategoryChartColumnInfo;
  draggingClass?: string;
  onColAdded: (newCol: DatasetColumn, oldColName?: string) => void;
  onColOptionChanged: (option: { id: string; name: string }) => void;
  onRemoveCol: () => void;
  schema: DatasetSchema;
};

export default function DroppedBucketColumn({
  column,
  draggingClass,
  onColAdded,
  onColOptionChanged,
  onRemoveCol,
  schema,
}: Props) {
  const [isExpanded, setIsExpanded] = useState(column.bucketSize !== undefined);
  const [isEditingBucket, setIsEditingBucket] = useState(column.bucketSize === undefined);
  const classes = useStyles({ isEditingBucket, isExpanded });
  const sharedClasses = sharedStyles();

  const bucketOptionsPopover = (
    <div
      className={cx(
        sprinkles({ display: 'flex', alignItems: 'center' }),
        classes.bucketDropdownInput,
      )}>
      <div
        className={cx(sharedClasses.name, {
          [classes.greyText]: isExpanded && isEditingBucket,
        })}>
        {isExpanded ? 'Bucket values by...' : 'All values'}
      </div>
      <Popover
        captureDismiss
        fill
        minimal
        content={
          <Menu>
            <MenuItem
              className={sharedClasses.menuItemOption}
              onClick={() => {
                setIsExpanded(false);
                onColOptionChanged({ id: '', name: '' });
                setIsEditingBucket(false);
              }}
              text="All values"
            />
            <MenuItem
              className={sharedClasses.menuItemOption}
              onClick={() => {
                setIsExpanded(true);
                setIsEditingBucket(true);
              }}
              text="Bucket values by..."
            />
          </Menu>
        }
        position={Position.BOTTOM_RIGHT}>
        <Button compact minimal className={classes.bucketIconBtn} icon="caret-down" />
      </Popover>
    </div>
  );

  return (
    <div
      className={cx(
        classes.root,
        sprinkles({
          display: 'flex',
          alignItems: 'center',
          marginBottom: 'sp.5',
        }),
        draggingClass,
      )}>
      <div className={sprinkles({ flex: 1 })}>
        <div className={sprinkles({ parentContainer: 'fill', display: 'flex' })}>
          <div
            className={cx(
              sprinkles({ display: 'flex', alignItems: 'center' }),
              classes.columnsPopoverTargetContainer,
            )}>
            <div className={sharedClasses.name}> {column.column.name}</div>
            <ColumnOptionsPopover
              onColChange={(newCol) => onColAdded(newCol, column.column.name)}
              rightDivider={!isExpanded}
              schema={schema}
            />
          </div>
          {isExpanded ? null : bucketOptionsPopover}
        </div>
        {isExpanded ? (
          <div className={sprinkles({ display: 'flex', marginTop: 'sp.5' })}>
            {bucketOptionsPopover}
            {!isEditingBucket ? (
              <div
                className={cx(
                  classes.bucketDropdownInput,
                  classes.bucketValueInput,
                  sprinkles({ display: 'flex', alignItems: 'center', marginLeft: 'sp.5' }),
                )}
                onClick={() => setIsEditingBucket(true)}>
                <div className={cx(sharedClasses.name, sprinkles({ width: 'fill' }))}>
                  {column.bucketSize?.toString()}
                </div>
              </div>
            ) : (
              <InputWithBlurSave
                hideRightIconInteractions
                initialValue={column.bucketSize?.toString() || ''}
                onNewValueSubmitted={(value: string) => {
                  const valAsNumber = Number(value);

                  if (value !== '' && (isNaN(valAsNumber) || valAsNumber <= 0))
                    showErrorToast('Bucket size must be a number greater than 0');
                  else {
                    onColOptionChanged({ id: value, name: value });
                    if (value !== '') {
                      setIsEditingBucket(false);
                    }
                  }
                }}
              />
            )}
          </div>
        ) : null}
      </div>
      <Button
        compact
        minimal
        className={cx(classes.crossIcon, {
          [sprinkles({ marginLeft: 'sp.5' })]: isExpanded,
        })}
        icon="cross"
        onClick={onRemoveCol}
      />
    </div>
  );
}
