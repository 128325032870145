import { FetchDashboardDatasetPreviewData } from 'actions/responseTypes';

export const checkDashboardDatasetDataForDuplicates = (
  datasetPreview: FetchDashboardDatasetPreviewData,
) => {
  const potentialDuplicates: string[] = [];
  const columns = datasetPreview.dataset_preview.schema;

  // matches any string that ends with a number
  const regex = /([A-z]*)\d+$/;

  columns.forEach((column) => {
    const match = column.name.match(regex);
    if (match && columns.some((c) => c.name === match[1])) {
      potentialDuplicates.push(match[1]);
    }
  });

  return potentialDuplicates;
};

const INVALID_DATE_TIME_PARSE_INFO = (
  <div>
    The query generated by this panel is attempted to do time operations on a date field. Please
    ensure that the field you are operating on has a time part before doing hour, minute, or second
    operations
    <br />
    <a
      href="https://docs.explo.co/troubleshooting/common-errors#error-parsing-date-time"
      rel="noopener noreferrer"
      target="_blank">
      Click here for more info.
    </a>
  </div>
);

const AMBIGUOUS_COLUMN_REFERENCE = (
  <div>
    The query generated by this panel references an ambiguous column name. This is likely because
    the dataset used by this panel contains columns with duplicate names.
    <br />
    <a
      href="https://docs.explo.co/troubleshooting/common-errors#error-parsing-date-time"
      rel="noopener noreferrer"
      target="_blank">
      Click here for more info.
    </a>
  </div>
);

const TABLE_DOES_NOT_EXIST = (
  <div>
    The data source you are viewing is incompatible with its schema. This is likely because you have
    added a data source to a schema, but the data source does not contain all tables defined by the
    default data source of the schema.
    <br />
    <a
      href="https://docs.explo.co/troubleshooting/common-errors#table-does-not-exist"
      rel="noopener noreferrer"
      target="_blank">
      Click here for more info.
    </a>
  </div>
);

const MYSQL_OPERATION_NOT_SUPPORTED = (
  <div>
    This data panel is using an unsupported operation for MySQL. This is likely because you are
    using a MEDIAN filter or because the data panel is a box plot. Please reach out to Explo if you
    need this functionality supported for your MySQL dashboards.
  </div>
);

const BIGQUERY_RESERVED_KEYWORD = (
  <div>
    This data panel is using the &quot;time&quot; as the name of one of its columns. This is a
    reserved keyword in BigQuery and is causing issues loading this dashboard. Please use a
    different column name to view this data panel.
  </div>
);

export const IS_ACCESS_GROUP_ERROR = (error: string) => {
  return (
    error.startsWith('The following data visibility group') &&
    error.endsWith(
      'does not have default data sources selected and will result in errors loading data.',
    )
  );
};
export const ACCESS_GROUP_NO_DEFAULT_DATASOURCES_WARNING_WITH_LINK = (error: string) => (
  <>
    {error} Read more <a href="https://docs.explo.co/managing-permissions/access-groups">here</a>
  </>
);

const errorRegexes = [
  {
    regx: /column reference.*is ambiguous/i,
    description: AMBIGUOUS_COLUMN_REFERENCE,
  },
  {
    regx: /invalid value.*for.*date\/time/i,
    description: INVALID_DATE_TIME_PARSE_INFO,
  },
  {
    regx: /datepart.*not supported/i,
    description: INVALID_DATE_TIME_PARSE_INFO,
  },
  // Postgres
  {
    regx: /relation.*does not exist/i,
    description: TABLE_DOES_NOT_EXIST,
  },
  // MySql
  {
    regx: /table.*doesn't exist/i,
    description: TABLE_DOES_NOT_EXIST,
  },
  // BigQuery
  {
    regx: /table name.*missing dataset/i,
    description: TABLE_DOES_NOT_EXIST,
  },
  // Rockset
  {
    regx: /collection.*does not exist/i,
    description: TABLE_DOES_NOT_EXIST,
  },
  // MySQL partition/median operation
  {
    regx: /MySQL.*WITHIN GROUP/i,
    description: MYSQL_OPERATION_NOT_SUPPORTED,
  },
  // BigQuery reserved keyword
  {
    regx: /400 Unrecognized name: time/i,
    description: BIGQUERY_RESERVED_KEYWORD,
  },
];

export const parseErrorMessage = (errorMessage: string | undefined) =>
  errorRegexes.find((er) => errorMessage?.match(er.regx))?.description;
