import { useCallback, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import parse from 'url-parse';
// @ts-ignore
import JSURL from 'jsurl';

import { GenericLoadingSpinner } from 'components/EmbeddedDashboard';
import ChartLayout from 'components/ChartLayout/ChartLayout';

import {
  ErrorResponse,
  FetchDashboardDatasetPreviewData,
  FetchDataPanelData,
} from 'actions/responseTypes';
import {
  embedFetchDashboard,
  embedFetchDataPanelRowCount,
  embedFetchDataPanel,
  embedFetchSecondaryData,
  embedFetchDashboardDatasetPreview,
} from 'actions/shareActions';
import { FetchDashboardDatasetPreviewBody } from 'actions/datasetActions';
import { ACTION } from 'actions/types';
import { AdHocOperationInstructions } from 'types/dataPanelTemplate';
import { pageView } from 'analytics/exploAnalytics';
import { UserTransformedSchema } from 'constants/types';
import { createLoadingSelector } from 'embeddedContent/reducers/api/selectors';
import { EmbedReduxState } from 'embeddedContent/reducers/rootReducer';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { GlobalStylesProvider } from 'globalStyles';
import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import {
  AnalyticsMetadata,
  getEmbedSource,
  Metadata,
  sendAnalyticsEvent,
  sendAnalyticsEventWrapper,
} from 'utils/analyticsUtils';
import { getQueryVariables } from 'components/EmbeddedDashboard/EmbeddedDashboard';
import { loadLocale } from 'utils/localizationUtils';
import { getCustomerVariables } from 'utils/customerUtils';
import { loadFonts } from 'globalStyles/helpers';
import {
  FetchDataPanelBody,
  FetchDataPanelRowCountBody,
  FetchSecondaryDataBody,
} from 'actions/dataPanelTemplateAction';
import getFingerprintUser from 'analytics/fingerprint';
import { BulkEnqueueFnArgs, bulkEnqueueJobs } from 'actions/jobQueueActions';
import { Jobs } from 'components/JobQueue/types';
import { setDpLoading } from 'actions/dashboardV2Actions';

const useStyles = makeStyles((theme: Theme) => ({
  spinner: {
    width: '100%',
    height: '100%',
    margin: theme.spacing(2),
  },
  errorMessage: {
    margin: theme.spacing(10),
    fontSize: 24,
    padding: theme.spacing(10),
    backgroundColor: '#FAE1E1',
    borderRadius: 8,
  },
}));

type Props = {
  dashboardEmbedId: string;
  customerToken: string;
  dataPanelId: string;
  versionNumber?: number;
};

export default function EmbeddedChart(props: Props) {
  const { dashboardEmbedId, versionNumber, customerToken, dataPanelId } = props;
  const classes = useStyles();

  const [visitorId, setVisitorId] = useState('');
  const [analyticsMetadata, setAnalyticsMetadata] = useState<AnalyticsMetadata | undefined>();
  const [urlVariables] = useState<DashboardVariableMap>(getQueryVariables('shared'));
  const [userTransformedSchema] = useState<UserTransformedSchema>(getUserTransformedSchema());
  const [adHocOps] = useState<AdHocOperationInstructions>(getAdHocOps());
  const [reportName] = useState<string>(getReportName());

  const dispatch = useDispatch();

  const {
    dashboardTemplate,
    dashboardVersion,
    errorMsg: embedError,
    team,
    userGroup,
  } = useSelector((state: EmbedReduxState) => state.embedDashboard);
  const { version_number: dashboardVersionNumber, configuration: dashboardConfig } =
    dashboardVersion ?? {};

  const { dashboardLoading, globalStyleConfig } = useSelector(
    (state: EmbedReduxState) => ({
      dashboardLoading: createLoadingSelector([ACTION.EMBED_FETCH_DASHBOARD], true)(state),
      globalStyleConfig: state.dashboardStyles.globalStyleConfig,
    }),
    shallowEqual,
  );

  const onLoad = () => {
    pageView('Shared Chart');

    const queryVariables = getQueryVariables('shared');
    const environment = queryVariables['environment'];
    const isProduction = queryVariables['is_production'];

    dispatch(
      embedFetchDashboard(
        {
          customerToken,
          postData: {
            dashboard_embed_id: dashboardEmbedId,
            version_number: versionNumber,
            environment: environment as string | undefined,
          },
        },
        (data) => {
          const metadata = {
            team_id: data.team.id,
            team_name: data.team.team_name,
            customer_id: data.customer.id,
            customer_name: data.customer.name,
            customer_provided_id: data.customer.provided_id,
            customer_is_demo: data.customer.is_demo_group,
            dashboard_template_id: data.dashboard_template.id,
            dashboard_template_name: data.dashboard_template.name,
          };

          const sendPageViewAnalytics = async () => {
            // Get the (browser) visitor identifier:
            const fingerprintUser = await getFingerprintUser();

            // Send page view analytics
            sendAnalyticsEvent(
              REPORTED_ANALYTIC_ACTION_TYPES.SHARED_CHART_VIEWS,
              fingerprintUser.visitorId,
              customerToken,
              data.team.explo_analytics_token,
              {
                ...metadata,
                is_production: isProduction ? isProduction === 'true' : undefined,
                environment,
              },
            );

            setVisitorId(fingerprintUser.visitorId);
          };

          sendPageViewAnalytics();

          setAnalyticsMetadata(metadata);

          loadLocale({
            teamCurrencyCode: data.team.default_currency_code,
            teamLocaleCode: data.team.default_locale_code,
            useBrowserLocale: data.team.use_browser_locale,
          });
        },
      ),
    );
  };
  useEffect(onLoad, [dispatch, versionNumber, dashboardEmbedId, customerToken]);

  useEffect(() => {
    if (team) loadFonts(globalStyleConfig.text, team.id, team.payment_plan);
  }, [globalStyleConfig.text, team]);

  const analyticsEventTracker = useCallback(
    (eventName: REPORTED_ANALYTIC_ACTION_TYPES, metadata?: Metadata) => {
      sendAnalyticsEventWrapper(
        visitorId,
        'chart',
        getEmbedSource('shared'),
        customerToken,
        team?.explo_analytics_token,
        eventName,
        analyticsMetadata,
        metadata,
      );
    },
    [visitorId, analyticsMetadata, team, customerToken],
  );

  const fetchDataPanelTemplateRowCountWrapper = useCallback(
    (data: { postData: FetchDataPanelRowCountBody }) => {
      if (!dashboardVersionNumber) return;

      return dispatch(
        embedFetchDataPanelRowCount({
          customerToken,
          postData: {
            ...data.postData,
            versionNumber: dashboardVersionNumber,
            resource_embed_id: dashboardEmbedId,
          },
        }),
      );
    },
    [customerToken, dashboardVersionNumber, dispatch, dashboardEmbedId],
  );

  const fetchDataPanelTemplateWrapper = useCallback(
    (data: { postData: FetchDataPanelBody }, onSuccess?: (data: FetchDataPanelData) => void) => {
      if (dashboardVersionNumber === undefined) return;

      return dispatch(
        embedFetchDataPanel(
          {
            customerToken,
            postData: {
              ...data.postData,
              versionNumber: dashboardVersionNumber,
              resource_embed_id: dashboardEmbedId,
            },
          },
          onSuccess,
        ),
      );
    },
    [customerToken, dashboardVersionNumber, dispatch, dashboardEmbedId],
  );

  const fetchSecondaryDataWrapper = useCallback(
    (data: { postData: FetchSecondaryDataBody }) => {
      if (!dashboardVersionNumber) return;

      return dispatch(
        embedFetchSecondaryData({
          customerToken,
          postData: {
            ...data.postData,
            versionNumber: dashboardVersionNumber,
            is_secondary_data_query: true,
            resource_embed_id: dashboardEmbedId,
          },
        }),
      );
    },
    [dispatch, dashboardVersionNumber, customerToken, dashboardEmbedId],
  );

  const fetchDatasetPreview = useCallback(
    (
      data: FetchDashboardDatasetPreviewBody,
      onSuccess?: (data: FetchDashboardDatasetPreviewData) => void,
      onError?: () => void,
    ) => {
      if (!dashboardVersionNumber || !dashboardTemplate) return;

      return dispatch(
        embedFetchDashboardDatasetPreview(
          {
            customerToken,
            postData: {
              ...data,
              versionNumber: dashboardVersionNumber,
              resource_embed_id: dashboardEmbedId,
            },
          },
          onSuccess,
          onError,
        ),
      );
    },
    [dispatch, dashboardVersionNumber, dashboardTemplate, customerToken, dashboardEmbedId],
  );

  const bulkEnqueueJobsWrapper = useCallback(
    (
      args: BulkEnqueueFnArgs,
      onSuccess?: (jobs: Record<string, Jobs>) => void,
      onError?: (errorMsg: ErrorResponse) => void,
    ) => {
      if (!dashboardVersionNumber) return;

      Object.values(args.jobs).forEach((jobDefinition) => {
        jobDefinition.job_args['resource_embed_id'] = dashboardEmbedId;
        jobDefinition.job_args['version_number'] = dashboardVersionNumber;
      });

      return dispatch(bulkEnqueueJobs({ ...args, customerToken }, onSuccess, onError));
    },
    [dashboardVersionNumber, dispatch, customerToken, dashboardEmbedId],
  );

  if (dashboardLoading) {
    return <GenericLoadingSpinner embedType="embedded" />;
  } else if (embedError) {
    return <div className={classes.errorMessage}>{embedError}</div>;
  } else if (!dashboardTemplate || !dashboardConfig || !userGroup) {
    return (
      <div className={classes.errorMessage}>
        There was an error loading the dashboard. Please contact your support team for help.
      </div>
    );
  }

  return (
    <GlobalStylesProvider globalStyleConfig={globalStyleConfig}>
      {(globalStylesClassName) => (
        <div className={globalStylesClassName} id="shared-explo-dashboard">
          {dashboardTemplate && dashboardConfig && (
            <ChartLayout
              adHocOperationInstructions={adHocOps}
              analyticsEventTracker={analyticsEventTracker}
              bulkEnqueueJobsWrapper={bulkEnqueueJobsWrapper}
              customerToken={customerToken}
              dashboardDatasets={dashboardConfig.datasets}
              dashboardElements={Object.values(dashboardConfig.elements)}
              dashboardTemplateId={dashboardTemplate.id}
              dashboardVersionNumber={dashboardVersionNumber}
              dataPanelId={dataPanelId}
              dataPanelTemplate={dashboardConfig.data_panels[dataPanelId]}
              fetchDataPanel={fetchDataPanelTemplateWrapper}
              fetchDataPanelRowCount={fetchDataPanelTemplateRowCountWrapper}
              fetchDatasetPreview={fetchDatasetPreview}
              fetchSecondaryData={fetchSecondaryDataWrapper}
              maxRowsOverride={team?.feature_flags.pdf_max_rows}
              reportName={reportName}
              setDpLoading={(...args) => dispatch(setDpLoading(...args))}
              shouldUseJobQueue={!!team?.feature_flags.use_job_queue}
              userTransformedSchema={userTransformedSchema}
              variables={{
                ...urlVariables,
                ...getCustomerVariables(userGroup),
              }}
            />
          )}
        </div>
      )}
    </GlobalStylesProvider>
  );
}

function getReportName() {
  const rawVars = parse(window.location.href, true).query;
  if (!rawVars?.reportName) return '';

  const reportNameWithQuotesRemoved = rawVars.reportName.substring(
    1,
    rawVars.reportName.length - 1,
  );

  return reportNameWithQuotesRemoved;
}

function getUserTransformedSchema() {
  const rawVars = parse(window.location.href, true).query;
  if (!rawVars?.userTransformedSchema) return [];

  return JSURL.parse(
    rawVars.userTransformedSchema.substring(1, rawVars.userTransformedSchema.length),
  ) as UserTransformedSchema;
}

function getAdHocOps() {
  const rawVars = parse(window.location.href, true).query;
  if (!rawVars?.adHocOps) return {};

  return JSURL.parse(
    rawVars.adHocOps.substring(1, rawVars.adHocOps.length),
  ) as AdHocOperationInstructions;
}
