import { FC } from 'react';
import * as RadixPopover from '@radix-ui/react-popover';
import * as styles from './index.css';
import { Icon, Button, sprinkles } from 'components/ds';
import cx from 'classnames';

type BaseProps = {
  /**
   * Style override
   */
  className?: string;
  /**
   * Specify a container element id to portal the content into.
   */
  portalContainerId?: string;
  /**
   * Width variant for popovers, if undefined hugs content or
   * class override for custom width
   */
  width?: 'small' | 'medium' | 'large';
  /**
   * The preferred side of the anchor to render against when open. Will be
   * reversed when collisions occur and avoidCollisions is enabled.
   */
  side?: 'top' | 'right' | 'bottom' | 'left';
  /**
   * The preferred alignment against the anchor. May change when collisions occur.
   */
  align?: 'start' | 'center' | 'end';
  /**
   * The title of the popover
   */
  title?: string;
  /**
   * The distance in pixels from the anchor.
   */
  sideOffset?: number;
  /**
   * Offset from the start or end alignment options
   */
  alignOffset?: number;
  /**
   * Whether to render a close button in the title
   */
  showCloseButton?: boolean;
  /**
   * Event handler when back button is clicked
   */
  onBack?: () => void;
};

type FooterButtonProps = {
  /**
   * The action button text
   */
  text: string;
  /**
   * Determines whether the action button should be loading
   */
  loading?: boolean;
  /**
   * Determines whether the action button should be disabled
   */
  disabled?: boolean;
  /**
   * Event handler called when the open state of the dialog changes.
   */
  onClick: () => void;
};

type FooterProps = {
  primaryButtonProps?: FooterButtonProps;
  secondaryButtonProps?: FooterButtonProps & { type?: 'secondary' | 'tertiary' };
};

type ExternalStateProps = {
  defaultOpen?: never;
  trigger?: never;
  /**
   * The controlled open state of the popover. Must be used in conjunction with onOpenChange.
   */
  isOpen: boolean;
  /**
   * Event handler called when the open state of the popover changes.
   */
  onOpenChange: (open: boolean) => void;
};

type InternalStateProps = {
  isOpen?: never;
  onOpenChange?: (open: boolean) => void;
  /**
   * The open state of the popover when it is initially rendered. Use when you do not need to control its open state.
   */
  defaultOpen?: boolean;
  /**
   * Trigger to open and close popover
   */
  trigger: JSX.Element;
};

type InteractionProps = ExternalStateProps | InternalStateProps;

export type Props = BaseProps & InteractionProps & FooterProps;

export const Popover: FC<Props> = ({
  className,
  isOpen,
  width,
  portalContainerId,
  children,
  defaultOpen = false,
  side = 'bottom',
  sideOffset = 4,
  alignOffset,
  align = 'center',
  title,
  showCloseButton,
  primaryButtonProps,
  secondaryButtonProps,
  onBack,
  onOpenChange,
  trigger,
}) => {
  const renderHeader = title || onBack || showCloseButton;
  const header = (
    <div className={styles.popoverTitle}>
      <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1.5' })}>
        {onBack ? (
          <Icon data-testid="popover-back-button" name="arrow-left" onClick={onBack} />
        ) : null}
        {title}
      </div>
      <RadixPopover.Close asChild>
        <Icon data-testid="popover-close-button" name="cross" />
      </RadixPopover.Close>
    </div>
  );

  const footer = (
    <div className={styles.popoverFooter}>
      {secondaryButtonProps ? (
        <Button
          fillWidth
          data-testid="popover-secondary-button"
          type={secondaryButtonProps.type || 'tertiary'}
          {...secondaryButtonProps}>
          {secondaryButtonProps.text}
        </Button>
      ) : null}
      {primaryButtonProps ? (
        <Button
          fillWidth
          data-testid="popover-primary-button"
          type="primary"
          {...primaryButtonProps}>
          {primaryButtonProps.text}
        </Button>
      ) : null}
    </div>
  );

  const content = (
    <RadixPopover.Content
      align={align}
      alignOffset={alignOffset}
      className={cx(styles.popoverContent({ width }), className)}
      side={side}
      sideOffset={sideOffset}>
      {renderHeader ? header : null}
      {children}
      {primaryButtonProps ? footer : null}
    </RadixPopover.Content>
  );

  return (
    <RadixPopover.Root defaultOpen={defaultOpen} onOpenChange={onOpenChange} open={isOpen}>
      {trigger ? <RadixPopover.Trigger asChild>{trigger}</RadixPopover.Trigger> : null}
      {portalContainerId ? (
        <RadixPopover.Portal container={document.getElementById(portalContainerId)}>
          {content}
        </RadixPopover.Portal>
      ) : (
        content
      )}
    </RadixPopover.Root>
  );
};
