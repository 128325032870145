import { Component } from 'react';
import { sortBy } from 'utils/standard';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Theme, withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { ReduxState } from 'reducers/rootReducer';
import { RouteComponentProps } from 'react-router';
import { Layout } from '@explo-tech/react-grid-layout';
import * as RD from 'remotedata';

import ErrorState from 'components/ErrorState';
import EditDashboardPage from 'pages/dashboardPage/editDashboardPage';
import ExploLoadingSpinner from 'components/ExploLoadingSpinner';
import { SIDE_PANE_HEADER_HEIGHT } from 'components/SidePane';
import { withWidth, WithWidthProps } from 'components/HOCs/withWidth';
import {
  updateDashboardTemplateLayout,
  updateDashboardPdfLayout,
  updateDashboardEmailLayout,
  updateDashboardMobileLayout,
} from 'actions/layoutActions';
import {
  createDashboardTemplateDataPanelV2,
  updateElementConfig,
  deleteDashboardElementV2,
  createNewDashboardVersion,
  switchCurrentlyEditingDashboardVersion,
  createDashboardElement,
  fetchDashboardVersions,
  clearDashboardConfigReducer,
  saveDashboardElementUpdates,
} from 'actions/dashboardV2Actions';
import { listTeamDataSources } from 'actions/dataSourceActions';
import {
  fetchDashboardDatasetPreview,
  FetchDashboardDatasetPreviewBody,
} from 'actions/datasetActions';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ACTION } from 'actions/types';
import { ExploreEmailCadence, fetchUserTeam } from 'actions/teamActions';
import { OPERATION_TYPES } from 'constants/types';
import {
  downloadDataPanelSpreadsheet,
  DownloadDataPanelSpreadsheetBody,
  fetchDataPanel,
  FetchDataPanelBody,
  fetchDataPanelRowCount,
  FetchDataPanelRowCountBody,
  fetchSecondaryData,
  updateDrilldownDataPanel,
  FetchSecondaryDataBody,
} from 'actions/dataPanelTemplateAction';
import { fetchUsedParentSchemas, fetchAllSchemaTables } from 'actions/parentSchemaActions';
import { BASE_CONFIG_BY_DASH_ELEM } from 'constants/dashboardConstants';
import { pageView, trackEvent, EVENTS } from 'analytics/exploAnalytics';
import { DASHBOARD_ELEMENT_TYPES, VIEW_MODE } from 'types/dashboardTypes';
import { fetchEmailCadence } from 'actions/emailActions';
import {
  fetchDashboardTemplate,
  fetchShareId,
  fetchDashboardTemplateList,
} from 'actions/dashboardTemplateActions';
import { STEPS, SUB_STEPS } from 'constants/onboardingConstants';
import { completeOnboardingSubStep } from 'actions/onboardingActions';
import { shouldCompleteOnboardingStep } from 'utils/onboarding';
import {
  downloadDataPanelPdf,
  downloadDashboardImage,
  downloadDashboardPdf,
  DownloadDataPanelBody,
  DownloadDashboardBody,
} from 'actions/exportActions';
import { BulkEnqueueFnArgs, bulkEnqueueJobs } from 'actions/jobQueueActions';
import {
  ErrorResponse,
  FetchDashboardDatasetPreviewData,
  FetchDataPanelData,
} from 'actions/responseTypes';
import { Jobs } from 'components/JobQueue/types';
import { getSelectedCustomer } from 'reducers/customersReducer';
import { getLayoutFromDashboardVersionConfig } from 'utils/dashboardUtils';
import { getTimezone } from 'utils/timezoneUtils';

const styles = (theme: Theme) =>
  createStyles({
    headerContainer: {
      height: SIDE_PANE_HEADER_HEIGHT,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      borderBottom: `1px solid ${theme.palette.ds.grey200}`,
      padding: `0 ${theme.spacing(6)}px`,
    },
    backBtn: {
      marginRight: theme.spacing(2),
      marginLeft: -10,
      marginTop: -3,
    },
    dashboardName: {
      fontSize: 20,
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    headerInfoContainer: {
      width: '90%',
    },
  });

type PassedProps = { globalStylesClassName: string };

type MatchParams = {
  dashboardTemplateId: string;
  edit?: string;
};

type Props = PassedProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps<MatchParams> &
  WithStyles<typeof styles> &
  WithWidthProps;

type State = {
  inEditMode?: boolean;
  viewMode: VIEW_MODE;
};

class DashboardPage extends Component<Props, State> {
  state: State = {
    viewMode: VIEW_MODE.DEFAULT,
  };

  constructor(props: Props) {
    super(props);

    const {
      fetchDashboardTemplate,
      match,
      listTeamDataSources,
      fetchAllSchemaTables,
      fetchDashboardVersions,
      fetchUsedParentSchemas,
      dashboardTemplateList,
      fetchDashboardTemplateList,
      currentUser,
      fetchUserTeam,
      teamData,
    } = this.props;

    const hash = window.location.hash;

    fetchDashboardTemplate({ id: match.params.dashboardTemplateId }, (data) => {
      document.title = `Explo | ${data.dashboard_template_name}`;

      if (hash === '#edit' && data.dashboard_version.is_draft) {
        this.setState({ inEditMode: true, viewMode: VIEW_MODE.DEFAULT });
      } else if (hash === '#edit_pdf' && data.dashboard_version.is_draft) {
        this.setState({ inEditMode: true, viewMode: VIEW_MODE.PDF });
      } else if (hash === '#edit_email' && data.dashboard_version.is_draft) {
        this.setState({ inEditMode: true, viewMode: VIEW_MODE.EMAIL });
      } else if (hash === '#edit_mobile' && data.dashboard_version.is_draft) {
        this.setState({ inEditMode: true, viewMode: VIEW_MODE.MOBILE });
      } else {
        window.location.hash = '';
      }

      if (hash.startsWith('#edit')) this.updateEditModeUrlHash();
    });
    fetchDashboardVersions({ id: match.params.dashboardTemplateId });
    if (!dashboardTemplateList) fetchDashboardTemplateList({ id: currentUser.team?.id });

    listTeamDataSources();
    fetchAllSchemaTables();
    fetchUsedParentSchemas();
    fetchEmailCadence({ id: match.params.dashboardTemplateId });
    if (!teamData) fetchUserTeam();
  }

  componentDidMount() {
    pageView('Dashboard');
  }

  componentWillUnmount() {
    this.props.clearDashboardConfigReducer();
  }

  setViewMode = (viewMode: VIEW_MODE) => {
    const { inEditMode } = this.state;
    this.setState({ viewMode });
    if (inEditMode) {
      this.updateEditModeUrlHash(viewMode);
    }
  };

  updateEditModeUrlHash = (newViewMode?: VIEW_MODE) => {
    const viewMode = newViewMode ?? this.state.viewMode;

    switch (viewMode) {
      case VIEW_MODE.PDF:
        window.location.hash = '#edit_pdf';
        break;
      case VIEW_MODE.EMAIL:
        window.location.hash = '#edit_email';
        break;
      case VIEW_MODE.MOBILE:
        window.location.hash = '#edit_mobile';
        break;
      default:
        window.location.hash = '#edit';
    }
  };

  render() {
    const {
      dashboardTemplateLoading,
      dashboardLoadingError,
      updateDashboardTemplateLayout,
      updateDashboardPdfLayout,
      updateDashboardEmailLayout,
      updateDashboardMobileLayout,
      updateElementConfig,
      customersLoading,
      dashboardConfig,
      dashboardVersionInfo,
      switchEditModeLoading,
      parentSchemaLoading,
      parentSchemas,
      schemaTablesMap,
      schemaMapLoading,
      emailCadenceList,
      teamData,
      width,
      globalStylesClassName,
      updateDrilldownDataPanel,
    } = this.props;
    const { inEditMode, viewMode } = this.state;

    if (dashboardLoadingError) return <ErrorState text={dashboardLoadingError.detail} />;

    const currentDashboardTemplate = this.getCurrentDashboardTemplate();

    if (
      dashboardTemplateLoading ||
      customersLoading ||
      !currentDashboardTemplate ||
      !dashboardConfig ||
      !dashboardVersionInfo ||
      parentSchemaLoading ||
      !parentSchemas ||
      schemaMapLoading ||
      !schemaTablesMap
    )
      return <ExploLoadingSpinner />;

    const emailCadence = emailCadenceList?.find(
      (cadence: ExploreEmailCadence) =>
        cadence.dashboard_template_id === currentDashboardTemplate.id,
    );

    return (
      <EditDashboardPage
        bulkEnqueueJobsWrapper={this.bulkEnqueueJobsWrapper}
        createDashboardElement={this.createDashboardElement}
        createDataPanel={this.createDataPanel}
        dashboard={currentDashboardTemplate}
        dashboardDatasets={dashboardConfig.datasets}
        dashboardElements={Object.values(dashboardConfig?.elements)}
        dashboardLayout={getLayoutFromDashboardVersionConfig(dashboardConfig, viewMode)}
        dashboardParams={dashboardConfig.params}
        dashboardVersionInfo={dashboardVersionInfo}
        dataPanels={Object.values(dashboardConfig?.data_panels)}
        deleteDashboardElement={this.deleteDashboardElement}
        downloadDashboardImage={this.downloadDashboardImageWrapper}
        downloadDashboardPdf={this.downloadDashboardPdfWrapper}
        downloadDataPanelPdf={this.downloadDataPanelPdfWrapper}
        downloadDataPanelSpreadsheet={this.downloadDataPanelSpreadsheetWrapper}
        editableDashboard={inEditMode}
        editorDatasets={dashboardConfig.datasets}
        emailCadence={emailCadence}
        emailFooter={dashboardConfig.email_footer_html}
        emailHeader={dashboardConfig.email_header_html}
        fetchDataPanel={this.fetchDataPanelDataWrapper}
        fetchDataPanelRowCount={this.fetchDataPanelRowCountWrapper}
        fetchDatasetPreview={this.fetchDatasetPreviewWrapper}
        fetchSecondaryData={this.fetchSecondaryDataWrapper}
        fetchShareData={this.fetchShareDataWrapper}
        globalStylesClassName={globalStylesClassName}
        layoutExclusions={dashboardConfig.layout_exclusions}
        onEditClicked={this.onEditClicked}
        onLayoutUpdated={
          viewMode === VIEW_MODE.MOBILE
            ? updateDashboardMobileLayout
            : viewMode === VIEW_MODE.PDF
            ? updateDashboardPdfLayout
            : viewMode === VIEW_MODE.EMAIL
            ? updateDashboardEmailLayout
            : updateDashboardTemplateLayout
        }
        onPreviewClicked={this.onPreviewClicked}
        onReturnMostCurrentVersionClicked={this.onReturnMostCurrentVersionClicked}
        pageLayoutConfig={dashboardConfig.dashboard_page_layout_config}
        parentSchemas={parentSchemas}
        schemaTablesMap={schemaTablesMap}
        setViewMode={this.setViewMode}
        supportEmail={teamData?.support_email ?? undefined}
        switchEditModeLoading={switchEditModeLoading}
        updateDrilldownDataPanel={updateDrilldownDataPanel}
        updateElementConfig={updateElementConfig}
        viewMode={viewMode}
        width={width}
      />
    );
  }

  onReturnMostCurrentVersionClicked = () => {
    const {
      switchCurrentlyEditingDashboardVersion,
      dashboardVersionInfo,
      currentDashboardTemplateId,
      allDashboardVersions,
    } = this.props;
    if (!dashboardVersionInfo || currentDashboardTemplateId === undefined) return;

    const sortedVersions = sortBy(allDashboardVersions, (version) => -version.version_number);

    if (sortedVersions.length > 0) {
      switchCurrentlyEditingDashboardVersion({ dashboardVersion: sortedVersions[0] });
    }
    window.location.hash = '';
  };

  onEditClicked = () => {
    const {
      createNewDashboardVersion,
      switchCurrentlyEditingDashboardVersion,
      dashboardVersionInfo,
      currentDashboardTemplateId,
      allDashboardVersions,
    } = this.props;
    if (!dashboardVersionInfo || currentDashboardTemplateId === undefined) return;

    if (!dashboardVersionInfo.is_draft) {
      const sortedVersions = sortBy(allDashboardVersions, (version) => -version.version_number);

      // If sortedVersions is empty, the dashboard versions have not loaded yet, which means they
      // couldn't have switched to a different version
      if (sortedVersions.length > 0 && sortedVersions[0].is_draft) {
        switchCurrentlyEditingDashboardVersion({ dashboardVersion: sortedVersions[0] });
      } else {
        createNewDashboardVersion({ id: currentDashboardTemplateId }, (response) => {
          switchCurrentlyEditingDashboardVersion({ dashboardVersion: response.dashboard_version });
        });
      }
    }

    this.setState({ inEditMode: true });
    this.updateEditModeUrlHash();
  };

  onPreviewClicked = () => {
    this.setState({ inEditMode: false });
    window.location.hash = '';
  };

  fetchShareDataWrapper = (
    customerId: number,
    password?: string,
    username?: string,
    isStrictViewingMode?: boolean,
  ) => {
    const {
      fetchShareId,
      match,
      dashboardVersionInfo,
      fetchDashboardVersions,
      currentDashboardTemplateId,
    } = this.props;

    if (currentDashboardTemplateId === undefined) return;

    const commonPostData = {
      dashboard_template_id: parseInt(match.params.dashboardTemplateId),
      user_group_id: customerId,
    };

    fetchDashboardVersions({ id: currentDashboardTemplateId }, (data) => {
      const currentDashboardVersion = data.versions.find(
        (version) => version.version_number === dashboardVersionInfo?.version_number,
      )?.id;
      const environmentTagId = data.tags.find(
        (tag) =>
          tag.dashboard_versions_by_dashboard?.[currentDashboardTemplateId] ===
          currentDashboardVersion,
      )?.id;
      fetchShareId({
        postData: {
          ...commonPostData,
          ...(!environmentTagId && { version_number: dashboardVersionInfo?.version_number }),
          ...(environmentTagId && { environment_tag_id: environmentTagId }),
          ...(password && { password }),
          ...(username && { username }),
          ...{
            is_strict_viewing_mode: isStrictViewingMode === true,
          },
        },
      });
    });
  };

  createDataPanel = (
    id: string,
    datasetId: string,
    newLayout: Layout[],
    vizType: OPERATION_TYPES,
    onSuccess: () => void,
    containerId?: string,
    name?: string,
  ) => {
    const { currentDashboardTemplateId, createDashboardTemplateDataPanelV2 } = this.props;
    if (currentDashboardTemplateId === undefined) return;

    createDashboardTemplateDataPanelV2({
      id,
      name,
      newLayout,
      datasetId,
      vizType,
      containerId,
    });
    this.completeOnboardingStep(SUB_STEPS.CREATE_CHART);
    trackEvent(EVENTS.ADDED_DATA_PANEL, {
      dashboard_template_id: currentDashboardTemplateId,
      selected_dataset_id: datasetId,
    });

    onSuccess();
  };

  createDashboardElement = (
    id: string,
    elemType: DASHBOARD_ELEMENT_TYPES,
    newLayout: Layout[],
    containerId?: string,
  ) => {
    const { createDashboardElement, currentDashboardTemplateId } = this.props;

    if (currentDashboardTemplateId !== undefined) {
      createDashboardElement({
        id,
        elementType: elemType,
        initialConfig: { ...BASE_CONFIG_BY_DASH_ELEM[elemType] },
        newLayout: newLayout,
        containerId: containerId,
      });
      this.completeOnboardingStep(SUB_STEPS.CREATE_FILTER);
      trackEvent(EVENTS.ADDED_DASHBOARD_ELEMENT, {
        dashboard_template_id: currentDashboardTemplateId,
        element_type: elemType,
      });
    }
  };

  deleteDashboardElement = (elementId: string, elementType: DASHBOARD_ELEMENT_TYPES) => {
    const { deleteDashboardElementV2 } = this.props;

    deleteDashboardElementV2({ elementId, elementType });

    trackEvent(EVENTS.DELETED_DASHBOARD_ELEMENT, {
      element_id: elementId,
      element_type: elementType,
    });
  };

  completeOnboardingStep = (subStepName: string) => {
    const { onboardingSteps, completeOnboardingSubStep } = this.props;

    if (shouldCompleteOnboardingStep(onboardingSteps || {}, STEPS.CREATE_DASHBOARD, subStepName))
      completeOnboardingSubStep({ postData: { sub_step_name: subStepName } });
  };

  fetchDataPanelDataWrapper = (
    data: { postData: FetchDataPanelBody },
    onSuccess?: (data: FetchDataPanelData) => void,
  ) => {
    const { fetchDataPanel } = this.props;

    const currentDashboardTemplate = this.getCurrentDashboardTemplate();
    if (!currentDashboardTemplate) return;

    return fetchDataPanel(
      {
        postData: {
          ...data.postData,
          timezone: getTimezone(currentDashboardTemplate.default_timezone),
          resource_id: currentDashboardTemplate.id,
        },
      },
      onSuccess,
    );
  };

  fetchDataPanelRowCountWrapper = (data: { postData: FetchDataPanelRowCountBody }) => {
    const { fetchDataPanelRowCount } = this.props;

    const currentDashboardTemplate = this.getCurrentDashboardTemplate();
    if (!currentDashboardTemplate) return;

    return fetchDataPanelRowCount({
      postData: {
        ...data.postData,
        timezone: getTimezone(currentDashboardTemplate.default_timezone),
        resource_id: currentDashboardTemplate.id,
      },
    });
  };

  fetchSecondaryDataWrapper = (data: { postData: FetchSecondaryDataBody }) => {
    const { fetchSecondaryData } = this.props;

    const currentDashboardTemplate = this.getCurrentDashboardTemplate();
    if (!currentDashboardTemplate) return;

    return fetchSecondaryData({
      postData: {
        ...data.postData,
        timezone: getTimezone(currentDashboardTemplate.default_timezone),
        resource_id: currentDashboardTemplate.id,
      },
    });
  };

  fetchDatasetPreviewWrapper = (
    data: { postData: FetchDashboardDatasetPreviewBody },
    onSuccess?: (data: FetchDashboardDatasetPreviewData) => void,
    onError?: (errorMsg: ErrorResponse) => void,
  ) => {
    const { fetchDashboardDatasetPreview } = this.props;

    const currentDashboardTemplate = this.getCurrentDashboardTemplate();
    if (!currentDashboardTemplate) return;

    return fetchDashboardDatasetPreview(
      {
        postData: {
          ...data.postData,
          timezone: getTimezone(currentDashboardTemplate.default_timezone),
          resource_id: currentDashboardTemplate.id,
        },
      },
      onSuccess,
      onError,
    );
  };

  downloadDataPanelSpreadsheetWrapper = (data: { postData: DownloadDataPanelSpreadsheetBody }) => {
    const { downloadDataPanelSpreadsheet, selectedUserGroup } = this.props;

    const currentDashboardTemplate = this.getCurrentDashboardTemplate();
    if (!currentDashboardTemplate || !selectedUserGroup) return;

    return downloadDataPanelSpreadsheet({
      postData: {
        ...data.postData,
        timezone: getTimezone(currentDashboardTemplate.default_timezone),
        resource_id: currentDashboardTemplate.id,
        customer_id: selectedUserGroup.id,
      },
    });
  };

  downloadDataPanelPdfWrapper = (data: { postData: DownloadDataPanelBody }) => {
    const { downloadDataPanelPdf, selectedUserGroup } = this.props;

    const currentDashboardTemplate = this.getCurrentDashboardTemplate();
    if (!currentDashboardTemplate || !selectedUserGroup) return;

    return downloadDataPanelPdf({
      postData: {
        ...data.postData,
        timezone: getTimezone(currentDashboardTemplate.default_timezone),
        resource_id: currentDashboardTemplate.id,
        user_group_id: selectedUserGroup.id,
      },
    });
  };

  downloadDashboardImageWrapper = (data: { postData: DownloadDashboardBody }) => {
    const { downloadDashboardImage, selectedUserGroup } = this.props;

    const currentDashboardTemplate = this.getCurrentDashboardTemplate();
    if (!currentDashboardTemplate || !selectedUserGroup) return;

    return downloadDashboardImage({
      postData: {
        ...data.postData,
        timezone: getTimezone(currentDashboardTemplate.default_timezone),
        resource_id: currentDashboardTemplate.id,
        user_group_id: selectedUserGroup.id,
      },
    });
  };

  downloadDashboardPdfWrapper = (data: { postData: DownloadDashboardBody }) => {
    const { downloadDashboardPdf, selectedUserGroup } = this.props;

    const currentDashboardTemplate = this.getCurrentDashboardTemplate();
    if (!currentDashboardTemplate || !selectedUserGroup) return;

    return downloadDashboardPdf({
      postData: {
        ...data.postData,
        timezone: getTimezone(currentDashboardTemplate.default_timezone),
        resource_id: currentDashboardTemplate.id,
        user_group_id: selectedUserGroup.id,
      },
    });
  };

  bulkEnqueueJobsWrapper = (
    args: BulkEnqueueFnArgs,
    onSuccess?: (jobs: Record<string, Jobs>) => void,
    onError?: (errorMsg: ErrorResponse) => void,
  ) => {
    const { bulkEnqueueJobs } = this.props;

    const currentDashboardTemplate = this.getCurrentDashboardTemplate();
    if (!currentDashboardTemplate) return;

    Object.values(args.jobs).forEach((jobDefinition) => {
      jobDefinition.job_args['timezone'] = getTimezone(currentDashboardTemplate.default_timezone);
      jobDefinition.job_args['resource_id'] = currentDashboardTemplate.id;
    });

    return bulkEnqueueJobs({ ...args }, onSuccess, onError);
  };

  getCurrentDashboardTemplate = () => {
    const { currentDashboardTemplateId, dashboardTemplateList } = this.props;

    const currentDashboardTemplate =
      currentDashboardTemplateId !== undefined && dashboardTemplateList
        ? dashboardTemplateList.find((dash) => dash.id === currentDashboardTemplateId)
        : undefined;

    return currentDashboardTemplate;
  };
}

const mapStateToProps = (state: ReduxState) => ({
  dashboardTemplateLoading: createLoadingSelector(
    [ACTION.FETCH_DASHBOARD_TEMPLATE, ACTION.LIST_TEAM_DATA_SOURCES],
    false,
  )(state),
  switchEditModeLoading: createLoadingSelector(
    [ACTION.CREATE_NEW_DASHBOARD_VERSION, ACTION.PUBLISH_NEW_DASHBOARD_VERSION],
    false,
  )(state),
  customersLoading: RD.isLoading(state.customers.groups),
  currentDashboardTemplateId: state.dashboard.currentDashboardTemplateId,
  dashboardTemplateList: state.dashboard.dashboardTemplateList,
  currentUser: state.currentUser,
  emailCadenceList: state.emailCadence.emailCadenceList,
  dashboardLoadingError: state.dashboard.error,
  dashboardConfig: state.dashboardEditConfig.config,
  dashboardVersionInfo: state.dashboardEditConfig.versionInfo,
  allDashboardVersions: state.dashboardVersions.versions,
  parentSchemas: state.parentSchemas.usedParentSchemas,
  parentSchemaLoading: createLoadingSelector([ACTION.FETCH_USED_PARENT_SCHEMAS], false)(state),
  schemaTablesMap: state.parentSchemas.schemaTablesMap,
  schemaMapLoading: createLoadingSelector([ACTION.FETCH_ALL_SCHEMA_TABLES_FOR_TEAM], false)(state),
  teamData: state.teamData.data,
  onboardingSteps: state.onboarding.onboardingSteps,
  selectedUserGroup: getSelectedCustomer(state.customers),
});

const mapDispatchToProps = {
  fetchDashboardTemplate,
  fetchDataPanel,
  updateDashboardTemplateLayout,
  updateDashboardPdfLayout,
  updateDashboardEmailLayout,
  updateDashboardMobileLayout,
  fetchDataPanelRowCount,
  fetchSecondaryData,
  downloadDataPanelSpreadsheet,
  updateElementConfig,
  fetchDashboardDatasetPreview,
  fetchShareId,
  listTeamDataSources,
  createDashboardTemplateDataPanelV2,
  deleteDashboardElementV2,
  createNewDashboardVersion,
  switchCurrentlyEditingDashboardVersion,
  createDashboardElement,
  fetchDashboardVersions,
  clearDashboardConfigReducer,
  saveDashboardElementUpdates,
  fetchAllSchemaTables,
  fetchUsedParentSchemas,
  fetchDashboardTemplateList,
  fetchUserTeam,
  completeOnboardingSubStep,
  downloadDataPanelPdf,
  downloadDashboardImage,
  downloadDashboardPdf,
  bulkEnqueueJobs,
  updateDrilldownDataPanel,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withWidth(DashboardPage))),
);
