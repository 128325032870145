import { configureStore } from '@reduxjs/toolkit';
import sessionExpiredMiddleware from './sessionExpiredMiddleware';

import rootReducer from 'reducers/rootReducer';
import { getEnvironment } from 'utils/environmentUtils';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    exploAssets: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    HubSpotConversations: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Calendly: any;
  }
}

export default () => {
  return configureStore<typeof rootReducer>({
    reducer: rootReducer,
    // @ts-ignore
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sessionExpiredMiddleware),
    preloadedState: {},
    devTools: getEnvironment() === 'development',
  });
};
