import { useState, FC } from 'react';
import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CustomPicker } from 'react-color';
import tinycolor from 'tinycolor2';
import { Popover, Icon } from '@blueprintjs/core';

import { Hue, Saturation, EditableInput } from 'react-color/lib/components/common';

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    boxShadow: '4px 4px 15px rgba(0, 0, 0, 0.15)',
  },
  root: {
    width: 175,
    borderRadius: 4,
    overflow: 'hidden',
  },
  saturationBlock: {
    height: 110,
    position: 'relative',
  },
  hueBlock: {
    height: 8,
    borderRadius: 8,
    overflow: 'hidden',
    position: 'relative',
  },
  lowerContainer: {
    padding: theme.spacing(2),
  },
  paletteContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    rowGap: '12px',
    columnGap: '12px',
    marginTop: theme.spacing(2),
  },
  colorPaletteBox: {
    width: 22,
    height: 22,
    borderRadius: 4,
    transform: 'scale(1)',

    '&:hover': {
      transform: 'scale(1.2)',
      cursor: 'pointer',
    },
  },
  editableInputContainer: {
    marginTop: theme.spacing(2),

    '& input': {
      border: `1px solid ${theme.palette.ds.grey400}`,
      borderRadius: 4,
      width: '100%',
      padding: 3,
    },
  },
  customHuePointer: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    transform: 'translate(-9px, -1px)',
    border: `1px solid white`,
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.37)',
  },
  customSaturationPointer: {
    width: 13,
    height: 13,
    borderRadius: '50%',
    transform: 'translate(-9px, -1px)',
    border: `1px solid white`,
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.37)',
  },
  fillWidth: {
    width: '100%',
  },
}));

export type Props = {
  /**
   * optional class to wrap entire component
   */
  className?: string;
  /**
   * selected hex code
   */
  color: string;
  /**
   * color palette to show as pre-selected options
   */
  colorPalette: string[];
  /**
   * when the user selects a new value
   */
  fillWidth?: boolean;
  isCanvas?: boolean;
  onColorChange: (hex: string) => void;
};

const ColorPicker: FC<Props> = ({
  className,
  color,
  onColorChange,
  colorPalette,
  children,
  fillWidth,
  isCanvas,
}) => {
  const classes = useStyles();
  const [newColor, setNewColor] = useState(color);
  const [hsl, setHsl] = useState(tinycolor(color).toHsl());
  const [hsv, setHsv] = useState(tinycolor(color).toHsv());

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const update = (color: any) => {
    const parsedColor = tinycolor(color);
    setHsl(parsedColor.toHsl());
    setHsv(parsedColor.toHsv());
    setNewColor(parsedColor.toHexString());
  };

  return (
    <Popover
      minimal
      onClose={() => onColorChange(newColor)}
      popoverClassName={classes.popover}
      targetClassName={cx(className, { [classes.fillWidth]: fillWidth })}>
      {children}
      <div className={classes.root}>
        {!isCanvas ? (
          <div className={classes.saturationBlock}>
            <Saturation
              //@ts-ignore
              hsl={hsl}
              hsv={hsv}
              onChange={update}
              pointer={CustomSaturationPointer}
            />
          </div>
        ) : null}
        <div className={classes.lowerContainer}>
          {!isCanvas ? (
            <div className={classes.hueBlock}>
              <Hue
                direction="horizontal"
                // @ts-ignore
                hsl={hsl}
                onChange={update}
                pointer={CustomHuePointer}
              />
            </div>
          ) : null}
          <div className={classes.paletteContainer}>
            {colorPalette.map((paletteColor, index) => (
              <div
                className={classes.colorPaletteBox}
                key={`color-palette-${index}`}
                onClick={() => update(paletteColor)}
                style={{ backgroundColor: paletteColor }}></div>
            ))}
          </div>
          <div className={classes.editableInputContainer}>
            <EditableInput onChange={update} value={newColor} />
          </div>
        </div>
      </div>
    </Popover>
  );
};

const CustomHuePointer = () => {
  const classes = useStyles();
  return <div className={classes.customHuePointer} />;
};

const CustomSaturationPointer = () => {
  const classes = useStyles();
  return <div className={classes.customSaturationPointer} />;
};

const buttonStyles = makeStyles((theme: Theme) => ({
  buttonRoot: {
    width: '100%',
    minWidth: 24,
    borderRadius: 4,
    height: 24,
    position: 'relative',

    '&:hover': {
      cursor: 'pointer',

      '& > $cancelBtn': {
        visibility: 'visible',
      },
    },
  },
  brightColorBg: {
    border: `1px solid ${theme.palette.ds.grey400}`,
  },
  cancelBtn: {
    position: 'absolute',
    top: -5,
    right: -4,
    width: 16,
    height: 16,
    borderRadius: 8,
    backgroundColor: theme.palette.ds.lightBlue,

    visibility: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover': {
      backgroundColor: theme.palette.ds.hovered.lightBlue,
    },
    '&:active': {
      backgroundColor: theme.palette.ds.pressed.lightBlue,
    },
  },
  cancelIcon: {
    color: theme.palette.ds.blue,
  },
}));

type ButtonProps = {
  /**
   * optional class to wrap entire component
   */
  className?: string;
  /**
   * optional class to put on the button
   */
  btnClassName?: string;
  /**
   * selected hex code
   */
  color: string;
  /**
   * color palette to show as pre-selected options
   */
  colorPalette: string[];
  /**
   * when the user selects a new value
   */
  fillWidth?: boolean;
  isCanvas?: boolean;
  onColorChange: (hex: string) => void;
  onCancel?: () => void;
};

const ColorPickerButton = (props: ButtonProps) => {
  const classes = buttonStyles();
  const colorObj = tinycolor(props.color);
  return (
    <ColorPicker
      className={props.className}
      color={props.color}
      colorPalette={props.colorPalette}
      fillWidth={props.fillWidth}
      isCanvas={props.isCanvas}
      onColorChange={props.onColorChange}>
      <div
        className={cx(classes.buttonRoot, props.btnClassName, {
          [classes.brightColorBg]: colorObj.getBrightness() > 200,
        })}
        style={{ backgroundColor: props.color }}>
        {props.onCancel && (
          <div
            className={classes.cancelBtn}
            onClick={(e) => {
              e.stopPropagation();
              props.onCancel?.();
            }}>
            <Icon className={classes.cancelIcon} icon="cross" iconSize={10} />
          </div>
        )}
      </div>
    </ColorPicker>
  );
};

export default CustomPicker(ColorPickerButton);
