import { FC } from 'react';
import produce from 'immer';
import { makeStyles } from '@material-ui/core/styles';

import {
  SortableList,
  SortableListItem,
  SortableListItemDragHandle,
} from 'components/SortableList/SortableList';
import { sprinkles } from 'components/ds';
import Checkbox from 'components/checkbox';

import { UserTransformedSchema } from 'constants/types';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';

const useStyles = makeStyles(() => ({
  input: {
    '& .bp3-input': {
      height: 24,
    },
  },
}));

type Props = {
  schema: UserTransformedSchema;
  updateSchema: (newSchema: UserTransformedSchema) => void;
};

export const TransformationSettingsMenu: FC<Props> = ({ schema, updateSchema }) => {
  const classes = useStyles();

  return (
    <div className={sprinkles({ padding: 'sp1', overflowY: 'auto' })} style={{ maxHeight: 350 }}>
      <SortableList
        getIdFromElem={(col) => col.name}
        onListUpdated={updateSchema}
        sortableItems={schema}>
        {schema.map((col, index) => (
          <SortableListItem key={col.name} sortId={col.name}>
            <TransformationRow
              classes={classes}
              index={index}
              key={`${index}-${col}`}
              schema={schema}
              updateSchema={updateSchema}
            />
          </SortableListItem>
        ))}
      </SortableList>
    </div>
  );
};

type TransformationRowProps = {
  classes: ReturnType<typeof useStyles>;
  index: number;
  schema: UserTransformedSchema;
  updateSchema: (newSchema: UserTransformedSchema) => void;
};

const TransformationRow: FC<TransformationRowProps> = ({
  classes,
  index,
  schema,
  updateSchema,
}) => {
  const column = schema[index];

  return (
    <div
      className={sprinkles({
        padding: 'sp.5',
        flexItems: 'alignCenter',
        flexDirection: 'row',
      })}>
      <SortableListItemDragHandle className={sprinkles({ color: 'contentTertiary' })} />
      <Checkbox
        className={sprinkles({ marginX: 'sp.5' })}
        isChecked={column.isVisible}
        onChange={() => {
          const newSchema = produce(schema, (draft) => {
            draft[index].isVisible = !draft[index].isVisible;
          });

          updateSchema(newSchema);
        }}
      />
      <InputWithBlurSave
        hideRightIconInteractions
        className={classes.input}
        initialValue={column.friendly_name}
        onNewValueSubmitted={(newColumnName) => {
          const newSchema = produce(schema, (draft) => {
            draft[index].friendly_name = newColumnName;
          });

          updateSchema(newSchema);
        }}
      />
    </div>
  );
};
