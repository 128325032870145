import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';

import { Switch, sprinkles } from 'components/ds';
import { DashboardVariable, SwitchElementConfig, DashboardVariableMap } from 'types/dashboardTypes';
import { resolveTooltipVariables } from 'utils/variableUtils';
import InputLabel from 'shared/InputLabel';
import { InfoIcon } from 'components/InfoIcon';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { AnalyticsEventTracker } from 'utils/analyticsUtils';
import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      textAlign: 'center',
      height: 32,
      marginTop: 20,
    },
    statusLabel: {
      marginBottom: theme.spacing(0),
      paddingLeft: theme.spacing(1),
      textAlign: 'center',
    },
  }),
);

type Props = {
  analyticsEventTracker?: AnalyticsEventTracker;
  config: SwitchElementConfig;
  disabled?: boolean;
  onNewValueSelect: (newvalue: DashboardVariable) => void;
  value: DashboardVariable;
  variables: DashboardVariableMap;
};

export default function DashboardSwitchElement({
  analyticsEventTracker,
  config,
  disabled,
  onNewValueSelect,
  value,
  variables,
}: Props) {
  const classes = useStyles();

  const isVarOn =
    value === config.onStatusLabel || value === config.onStatusValue || value === 'true';

  return (
    <div className={classes.root}>
      {/* TODO: revisit this when the design system updates are in for info icon */}
      <div
        className={sprinkles({
          display: 'flex',
          justifyContent: 'space-between',
        })}>
        <div className={sprinkles({ display: 'flex', flexDirection: 'row', marginRight: 'sp1' })}>
          <div
            className={cx(
              sprinkles({ color: 'gray12', body: 'b3' }),
              GLOBAL_STYLE_CLASSNAMES.text.body.toggleSwitch,
            )}>
            {config.showSwitchLabel ? config.label : undefined}
          </div>
          {config.showTooltip ? (
            <InfoIcon infoTooltipText={resolveTooltipVariables(config, variables)} />
          ) : undefined}
        </div>
        <Switch
          disabled={disabled}
          onChange={() => {
            const newValue = getOppositeSwitchValue(config, isVarOn);
            onNewValueSelect(newValue);
            analyticsEventTracker?.(REPORTED_ANALYTIC_ACTION_TYPES.DROPDOWN_SELECTED);
          }}
          switchOn={value === undefined ? false : isVarOn}
        />
      </div>
      {config.showStatusLabel && (
        <InputLabel
          ignoreCustomStyles
          className={cx(classes.statusLabel)}
          overrideTextClass={GLOBAL_STYLE_CLASSNAMES.text.body.toggleSwitch}
          text={isVarOn ? config.onStatusLabel || '' : config.offStatusLabel || ''}
        />
      )}
    </div>
  );
}

const getOppositeSwitchValue = (config: SwitchElementConfig, isVarOn: boolean) => {
  if (isVarOn) {
    return config.offStatusValue || 'false';
  } else {
    return config.onStatusValue || 'true';
  }
};
