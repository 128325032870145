import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core';
import cx from 'classnames';
import { cloneDeep, isEqual } from 'utils/standard';

import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';
import Modal from 'components/core/Modal';
import Button from 'shared/Button';
import ChartEditor from './ChartEditor';
import { APP_PORTAL_ID } from 'components/ds';

import {
  CanvasConfigurability,
  CanvasConfigureType,
  CanvasViewType,
  DEFAULT_CANVAS_CONFIGURABILITY,
  updateCanvasConfigurability,
} from 'actions/canvasActions';
import { EndUserDataPanel } from 'actions/architectCustomerDashboardConfigActions';
import { OPERATION_TYPES } from 'constants/types';
import { EMPTY_VISUALIZATION_INSTRUCTIONS } from 'constants/dashboardConstants';
import { showErrorContactSupportToast } from 'shared/sharedToasts';

const useStyles = makeStyles((theme: Theme) => ({
  modal: ({ hasPreview }: Record<'hasPreview', boolean>) => ({ width: hasPreview ? 886 : 552 }),
  modalContainer: {
    padding: `0px ${theme.spacing(5)}px`,
  },
  modalBody: {
    display: 'flex',
    height: 456,
  },
  modalFooter: {
    marginTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  configure: {
    backgroundColor: theme.palette.ds.grey100,
    border: `1px solid ${theme.palette.ds.grey300}`,
    borderRadius: 4,
    flex: 1,
    padding: theme.spacing(3),
  },
  configureTitle: {
    lineHeight: '18px',
    fontSize: 14,
    color: theme.palette.ds.grey900,
    marginBottom: theme.spacing(2),
    fontWeight: 500,
  },
  configureInfoText: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
    fontSize: 12,
    lineHeight: '15px',
    color: theme.palette.ds.grey900,
    marginBottom: theme.spacing(6),
  },
  configureOptions: {
    display: 'flex',
  },
  configureOption: {
    flex: 1,
    height: 80,
    backgroundColor: theme.palette.ds.white,
    border: `1px solid ${theme.palette.ds.grey400}`,
    borderRadius: 4,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginRight: theme.spacing(2),

    '&:last-child': {
      marginRight: 0,
    },

    '&.selected': {
      backgroundColor: theme.palette.ds.lightBlue,
      border: `1px solid ${theme.palette.ds.blue}`,
    },

    '&:not(.selected):hover': {
      border: `1px solid ${theme.palette.ds.blue}`,
    },
  },
  optionTitle: {
    lineHeight: '18px',
    fontWeight: 500,
    fontSize: 14,
    color: theme.palette.ds.black,
    marginBottom: theme.spacing(1),
  },
  optionHelp: {
    fontSize: 12,
    lineHeight: '15px',
    textAlign: 'center',
    color: theme.palette.ds.grey900,
  },

  preview: {
    width: 310,
    marginLeft: theme.spacing(6),
    border: `1px solid ${theme.palette.ds.grey400}`,
    borderRadius: 4,
    overflowY: 'auto',
  },
  configurationContainer: { width: '100%', marginBottom: theme.spacing(4) },
  emailToggle: {
    width: '100%',
  },
}));

const dummyPanel: EndUserDataPanel = {
  id: '',
  provided_id: '',
  dataset_id: '',
  template_id: null,
  visualize_op: EMPTY_VISUALIZATION_INSTRUCTIONS(
    OPERATION_TYPES.VISUALIZE_LINE_CHART_V2,
    'Preview Line Chart',
    '',
  ),
};

type Props = {
  canvasId: number;
  configurability: CanvasConfigurability | null;

  closeModal: () => void;
};

export default function ConfigurabilityModal({
  canvasId,
  configurability,
  closeModal,
}: Props): JSX.Element {
  const dispatch = useDispatch();

  const [currConfigurability, setCurrConfigurability] = useState(
    configurability ? cloneDeep(configurability) : DEFAULT_CANVAS_CONFIGURABILITY,
  );

  const updateConfigurability = (configurabilityUpdates: Partial<CanvasConfigurability>) => {
    setCurrConfigurability({ ...currConfigurability, ...configurabilityUpdates });
  };

  const classes = useStyles({
    hasPreview: currConfigurability.type !== CanvasConfigureType.STATIC,
  });

  const viewConfigureType = (
    title: string,
    helpText: string,
    configureType: CanvasConfigureType,
  ) => {
    const isSelected = currConfigurability.type === configureType;
    return (
      <div
        className={cx(classes.configureOption, { selected: isSelected })}
        key={configureType}
        onClick={() => {
          if (!isSelected) updateConfigurability({ type: configureType });
        }}>
        <div className={classes.optionTitle}>{title}</div>
        <div className={classes.optionHelp}>{helpText}</div>
      </div>
    );
  };

  return (
    <Modal
      modalOpen
      usePortal
      className={classes.modal}
      onClose={() => closeModal()}
      portalContainerId={APP_PORTAL_ID}
      title="Configurability">
      <div className={classes.modalContainer}>
        <div className={classes.modalBody}>
          <div className={classes.configure}>
            <div className={classes.configureTitle}>Blueprint type</div>
            <div className={classes.configureOptions}>
              {viewConfigureType(
                'Editable',
                'Users can create and edit their own charts.',
                CanvasConfigureType.EDITABLE,
              )}
              {viewConfigureType(
                'Styling Only',
                'Users can change the styling of charts.',
                CanvasConfigureType.STYLING,
              )}
              {viewConfigureType(
                'Static',
                'Users can only select templates.',
                CanvasConfigureType.STATIC,
              )}
            </div>
            <div className={classes.configureInfoText}>
              This is how your customers will be able to interact with their blueprints.
            </div>
            <div className={classes.configureTitle}>End User Dashboard View Type</div>
            <ToggleButtonGroup fillWidth className={classes.configurationContainer}>
              <ToggleButton
                active={currConfigurability.viewType !== CanvasViewType.SINGLE}
                onClick={() => updateConfigurability({ viewType: CanvasViewType.MULTI })}
                text="Multiple Dashboards"
              />
              <ToggleButton
                active={currConfigurability.viewType === CanvasViewType.SINGLE}
                onClick={() => updateConfigurability({ viewType: CanvasViewType.SINGLE })}
                text="Single Dashboard"
              />
            </ToggleButtonGroup>
            {currConfigurability.viewType === CanvasViewType.SINGLE ? (
              <>
                <div className={classes.configureTitle}>
                  Allow Customers to Reset Dashboard to Base
                </div>
                <ToggleButtonGroup fillWidth className={classes.configurationContainer}>
                  <ToggleButton
                    active={!currConfigurability.enableResetDashboardChanges}
                    onClick={() => updateConfigurability({ enableResetDashboardChanges: false })}
                    text="No"
                  />
                  <ToggleButton
                    active={currConfigurability.enableResetDashboardChanges}
                    onClick={() => updateConfigurability({ enableResetDashboardChanges: true })}
                    text="Yes"
                  />
                </ToggleButtonGroup>
              </>
            ) : null}

            <div className={classes.configureTitle}>Allow Customers to Schedule Emails</div>
            <ToggleButtonGroup fillWidth className={classes.emailToggle}>
              <ToggleButton
                active={!currConfigurability.allowEmails}
                onClick={() => updateConfigurability({ allowEmails: false })}
                text="No"
              />
              <ToggleButton
                active={currConfigurability.allowEmails}
                onClick={() => updateConfigurability({ allowEmails: true })}
                text="Yes"
              />
            </ToggleButtonGroup>
          </div>
          {currConfigurability.type === CanvasConfigureType.STATIC ? null : (
            <div className={classes.preview}>
              <ChartEditor
                isArchitectCustomerDashboard
                isPreview
                configurability={currConfigurability.type}
                datasets={{}}
                fetchPanelData={() => console.error('This prop should not be called')}
                isNew={false}
                panel={dummyPanel}
              />
            </div>
          )}
        </div>
        <div className={classes.modalFooter}>
          <Button onClick={closeModal} style={{ marginRight: 16 }} text="Cancel" type="secondary" />
          <Button
            disabled={isEqual(currConfigurability, configurability)}
            onClick={() =>
              dispatch(
                updateCanvasConfigurability(
                  { postData: { canvas_id: canvasId, configurability: currConfigurability } },
                  () => closeModal(),
                  () => showErrorContactSupportToast(),
                ),
              )
            }
            text="Save"
            type="primary"
          />
        </div>
      </div>
    </Modal>
  );
}
