import { createReducer, createSelector } from '@reduxjs/toolkit';
import { Canvas } from 'actions/canvasActions';
import * as actions from 'actions/canvasActions';
import * as RD from 'remotedata';
import { fetchCanvasVersionsSuccess } from 'actions/canvasVersionActions';

interface CanvasReducerState {
  currentCanvasId: number | null;
  canvases: RD.ResponseData<Canvas[]>;
}

const canvasReducerInitialState: CanvasReducerState = {
  currentCanvasId: null,
  canvases: RD.Idle(),
};

export const getCurrentCanvas = createSelector(
  (state: CanvasReducerState) => state.currentCanvasId,
  (state: CanvasReducerState) => state.canvases,

  (currentCanvasId, canvases) => {
    if (currentCanvasId === null || !RD.isSuccess(canvases)) return null;

    return canvases.data.find((canvas) => canvas.id === currentCanvasId) ?? null;
  },
);

const updateCanvases = (
  state: CanvasReducerState,
  canvasId: number | string | undefined | null,
  updateFunc: (canvas: Canvas) => void,
): void => {
  if (!RD.isSuccess(state.canvases)) return;

  const canvas = state.canvases.data.find((elem) => elem.id === canvasId);

  if (canvas) updateFunc(canvas);
};

export default createReducer(canvasReducerInitialState, (builder) =>
  builder
    .addCase(actions.fetchCanvasesRequest, (state) => {
      state.canvases = RD.Loading();
    })
    .addCase(actions.fetchCanvasesError, (state) => {
      state.canvases = RD.Error('Error Loading Canvases');
    })
    .addCase(actions.fetchCanvasesSuccess, (state, { payload }) => {
      state.canvases = RD.Success(payload.canvas_list);
    })
    .addCase(actions.createCanvasSuccess, (state, { payload }) => {
      if (!RD.isSuccess(state.canvases)) return;

      state.canvases.data.push(payload.canvas);
      state.currentCanvasId = payload.canvas.id;
    })
    .addCase(actions.cloneCanvasSuccess, (state, { payload }) => {
      if (!RD.isSuccess(state.canvases)) return;

      state.canvases.data.push(payload.new_canvas);
      state.currentCanvasId = payload.new_canvas.id;
    })
    .addCase(actions.deleteCanvasSuccess, (state, { payload }) => {
      if (!RD.isSuccess(state.canvases)) return;
      state.canvases.data = state.canvases.data.filter((canvas) => canvas.id !== payload.id);
    })
    .addCase(actions.renameCanvasSuccess, (state, { payload }) => {
      updateCanvases(state, payload.id, (canvas) => {
        canvas.name = payload.name;
      });
    })
    .addCase(fetchCanvasVersionsSuccess, (state, { payload }) => {
      if (typeof payload.id === 'number') state.currentCanvasId = payload.id;
    })
    .addCase(actions.updateCanvasConfigurabilitySuccess, (state, { payload }) => {
      updateCanvases(state, state.currentCanvasId, (canvas) => {
        canvas.configurability = payload.configurability;
      });
    })
    .addDefaultCase((state) => state),
);
