import { FC } from 'react';

import { Icon, Intent, Tag } from 'components/ds';
import * as styles from './index.css';

import { CanvasDataset } from 'actions/canvasConfigActions';
import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';

type Props = {
  dataset: CanvasDataset | ReportBuilderDataset;
  isSelected: boolean;

  onClick: () => void;
};

export const DatasetItem: FC<Props> = ({ dataset, isSelected, onClick }) => {
  return (
    <div className={isSelected ? styles.selectedDataset : styles.dataset} onClick={onClick}>
      <div className={styles.datasetInfo}>
        <div className={styles.datasetName}>{dataset.name}</div>
        {'isHiddenFromUsers' in dataset && dataset.isHiddenFromUsers ? (
          <Tag inverted className={styles.tag} intent={isSelected ? Intent.ACTIVE : Intent.NONE}>
            Hidden
          </Tag>
        ) : null}
        {dataset.queryDraft ? (
          <Tag
            className={styles.tag}
            intent={isSelected ? Intent.ACTIVE : Intent.NONE}
            inverted={isSelected}>
            Draft
          </Tag>
        ) : null}
      </div>
      <div className={styles.datasetArrowWrapper}>
        {isSelected ? (
          <Icon className={styles.datasetArrow} name="chevron-right" size="md" />
        ) : null}
      </div>
    </div>
  );
};
