import { FC } from 'react';
import cx from 'classnames';
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import { Icon } from '@blueprintjs/core';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
}));

type StepConfig = {
  name: string;
};

export type Props = {
  /**
   * optional class to wrap entire component
   */
  className?: string;
  /**
   * ordered list of the steps
   */
  steps: StepConfig[];
  /**
   * index of the current step that is being viewed. All steps before this will be compelted
   */
  currentStepIndex: number;
};

const StepByStep: FC<Props> = ({ className, steps, currentStepIndex }) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.root, className)}>
      {steps.map((step: StepConfig, index: number) => (
        <Step
          includeStem={index !== steps.length - 1}
          isCompleted={index < currentStepIndex}
          isCurrStep={index === currentStepIndex}
          key={`step-by-step-${step.name}-index`}
          name={step.name}
        />
      ))}
    </div>
  );
};

const useStepStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  stepContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  stepName: {
    fontSize: 12,
    color: theme.palette.ds.grey800,

    '&.highlighted': {
      color: theme.palette.ds.black,
    },
  },
  stepTick: {
    borderRadius: 12,
    padding: 3,
    backgroundColor: theme.palette.ds.grey400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(2),

    '&.current': {
      backgroundColor: theme.palette.ds.green,
      opacity: 0.4,
    },
    '&.completed': {
      backgroundColor: theme.palette.ds.green,
    },
  },
  stem: {
    width: 2,
    height: 40,
    marginRight: 8,
    backgroundColor: theme.palette.ds.grey400,
    '&.completed': {
      backgroundColor: theme.palette.ds.green,
    },
  },
}));

type StepProps = {
  /**
   * name of the step to be displayed
   */
  name: string;
  /**
   * puts a stem out the bottom of the step tick
   */
  includeStem?: boolean;
  /**
   * if true,the step is highlighted and green
   */
  isCompleted?: boolean;
  /**
   * if true, the step is highlighted
   */
  isCurrStep?: boolean;
};

const Step: FC<StepProps> = ({ name, isCompleted, isCurrStep, includeStem }) => {
  const classes = useStepStyles();
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <div className={classes.stepContainer}>
        <div className={cx(classes.stepName, { highlighted: isCurrStep || isCompleted })}>
          {name}
        </div>
        <div className={cx(classes.stepTick, { completed: isCompleted, current: isCurrStep })}>
          <Icon color={theme.palette.ds.white} icon="tick" iconSize={12} />
        </div>
      </div>
      {includeStem && <div className={cx(classes.stem, { completed: isCompleted })}></div>}
    </div>
  );
};

export default StepByStep;
