import { useEffect, useState } from 'react';
import { map } from 'utils/standard';
import { ReduxState } from 'reducers/rootReducer';
import { connect, useDispatch } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { FormGroup, Checkbox, Intent } from '@blueprintjs/core';
import InputGroup from 'explo-ds/forms/marketing/inputGroup';

import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import { saveAnalyticsConfig } from 'actions/teamActions';
import { isValidHttpUrl } from 'utils/analyticsUtils';
import { createDebouncedFn } from 'utils/general';
import { showCustomToast, showSuccessToast } from 'shared/sharedToasts';

const useStyles = makeStyles((theme: Theme) => ({
  subsection: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: theme.spacing(3),
  },
  subtitle: {
    color: theme.palette.ds.grey700,
    marginBottom: theme.spacing(3),
  },
  checkbox: {
    '& input:checked ~ .bp3-control-indicator': {
      backgroundColor: `${theme.palette.ds.blue} !important`,
    },
  },
}));

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>;

const actionTypes: string[] = Object.values(REPORTED_ANALYTIC_ACTION_TYPES);

const debounceFn = createDebouncedFn(2000);

function SettingsAnalyticsSection(props: Props) {
  const { team } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [analyticsReportingUrl, setAnalyticsReportingUrl] = useState(team?.analytics_reporting_url);
  const [analyticsReportingActions, setAnalyticsReportingActions] = useState(
    new Set(map(team?.analytics_reporting_actions || [], 'name')),
  );
  const [analyticsReportingToken, setAnalyticsReportingToken] = useState(
    team?.analytics_reporting_token,
  );

  useEffect(() => {
    setAnalyticsReportingUrl(team?.analytics_reporting_url);
    setAnalyticsReportingActions(new Set(map(team?.analytics_reporting_actions || [], 'name')));
    setAnalyticsReportingToken(team?.analytics_reporting_token);
  }, [team]);

  const onConfigUpdated = (
    actions: Set<string>,
    url: string | null | undefined,
    token: string | null | undefined,
  ) => {
    debounceFn(() => {
      if (url && !isValidHttpUrl(url)) {
        showCustomToast('The input URL must be a valid URL.', {
          icon: 'tick-circle',
          intent: Intent.DANGER,
        });
        return;
      }
      dispatch(
        saveAnalyticsConfig(
          {
            postData: {
              analytics_reporting_actions: Array.from(actions),
              analytics_reporting_url: url,
              analytics_reporting_token: token,
            },
          },
          () => showSuccessToast('Analytics settings saved successfully'),
        ),
      );
    });
  };

  return (
    <div>
      <div className={classes.subsection}>Analytics</div>
      <div className={classes.subtitle}>
        {"Send analytics about your customer's dashboard usage to your database."}
      </div>
      <FormGroup label="URL">
        <InputGroup
          onInputChange={(value) => {
            setAnalyticsReportingUrl(value);
            onConfigUpdated(analyticsReportingActions, value, analyticsReportingToken);
          }}
          type="text"
          value={analyticsReportingUrl || ''}
        />
      </FormGroup>
      <FormGroup label="Authorization Token">
        <InputGroup
          onInputChange={(value) => {
            setAnalyticsReportingToken(value);
            onConfigUpdated(analyticsReportingActions, analyticsReportingUrl, value);
          }}
          type="text"
          value={analyticsReportingToken || ''}
        />
      </FormGroup>
      <FormGroup>
        {actionTypes.map((action) => (
          <Checkbox
            checked={analyticsReportingActions.has(action)}
            className={classes.checkbox}
            key={action}
            label={action}
            onChange={() => {
              const newState = new Set(analyticsReportingActions);
              if (analyticsReportingActions.has(action)) {
                newState.delete(action);
              } else {
                newState.add(action);
              }
              setAnalyticsReportingActions(newState);
              onConfigUpdated(newState, analyticsReportingUrl, analyticsReportingToken);
            }}
          />
        ))}
      </FormGroup>
    </div>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  team: state.teamData.data,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsAnalyticsSection);
