import { Icon, sprinkles } from 'components/ds';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { FC } from 'react';

const subHeaderClass = sprinkles({
  flexItems: 'alignCenter',
  paddingX: 'sp3',
  borderTop: 1,
  borderColor: 'outline',
  justifyContent: 'space-between',
});

type Props = { title: string; fieldCount?: number; sideText?: string; errorText?: string };

export const DataPanelSubHeader: FC<Props> = ({ title, fieldCount, sideText, errorText }) => {
  const renderSideText = (text?: string) => {
    if (!text) return null;
    return (
      <EmbedText body="b2" color="contentTertiary">
        {text}
      </EmbedText>
    );
  };

  const renderText = () => {
    if (errorText) {
      return (
        <div className={sprinkles({ flexItems: 'alignCenter' })}>
          <Icon
            className={sprinkles({ color: 'error', marginRight: 'sp1' })}
            name="circle-exclamation"
          />
          <EmbedText body="b2">{errorText}</EmbedText>
        </div>
      );
    }

    return fieldCount !== undefined
      ? renderSideText(
          fieldCount > 0
            ? `${fieldCount} field${fieldCount > 1 ? 's' : ''}`
            : sideText ?? 'Drag field here',
        )
      : renderSideText(sideText);
  };

  return (
    <div className={subHeaderClass} style={{ height: 48 }}>
      <EmbedText heading="h4">{title}</EmbedText>
      {renderText()}
    </div>
  );
};
