import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Icon } from '@blueprintjs/core';
import { sprinkles } from 'components/ds';

import Button from 'shared/Button';
import FilterRow from './FilterRow';
import { DatasetSchema, DatasetColumn } from 'types/datasets';

import { FilterClause, FilterValueType } from 'constants/types';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { FilterOperator } from 'types/filterOperations';

const useStyles = makeStyles((theme: Theme) => ({
  addFilterButton: {
    boxShadow: 'none !important',
    height: 32,

    '& .bp3-spinner .bp3-spinner-track': {
      stroke: theme.palette.ds.grey100,
    },
  },
  addFilterText: {
    fontSize: 12,
    fontWeight: 500,
  },
  noFilterText: {
    fontSize: 12,
    color: theme.palette.ds.black,
  },
}));

type Props = {
  filterConfigs: FilterClause[];
  onColumnSelect: (column: DatasetColumn, idx: number) => void;
  onOperatorSelect: (operator: FilterOperator, idx: number) => void;
  onFilterValueUpdate: (value: FilterValueType, idx: number) => void;
  addFilter: () => void;
  deleteFilter: (idx: number) => void;
  schema: DatasetSchema;
};

export default function FilterDropdownMenu({
  filterConfigs,
  onColumnSelect,
  onOperatorSelect,
  onFilterValueUpdate,
  addFilter,
  deleteFilter,
  schema,
}: Props) {
  const classes = useStyles();

  const singleFilterRow = filterConfigs.length === 1;
  const firstFilterEmpty = singleFilterRow ? !filterConfigs[0].filterColumn?.name : undefined;

  return (
    <div
      className={cx(
        sprinkles({ padding: 'sp1.5' }),
        GLOBAL_STYLE_CLASSNAMES.container.shadow.dropShadow,
        GLOBAL_STYLE_CLASSNAMES.container.outline.border,
        GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.default.borderRadius,
      )}>
      {filterConfigs.length === 0 ? (
        <div className={classes.noFilterText}>No filters applied</div>
      ) : (
        filterConfigs.map((currentFilterConfig, idx) => (
          <FilterRow
            adHocFilterSettings
            baseSchema={schema}
            deleteDisabled={singleFilterRow && firstFilterEmpty}
            filterValue={currentFilterConfig.filterValue}
            key={idx}
            onColumnSelect={(column) => onColumnSelect(column, idx)}
            onDelete={() => {
              deleteFilter(idx);
              if (singleFilterRow && !firstFilterEmpty) addFilter();
            }}
            onFilterValueUpdate={(value) => onFilterValueUpdate(value, idx)}
            onOperatorSelect={(operator) => onOperatorSelect(operator, idx)}
            selectedColumn={currentFilterConfig.filterColumn}
            selectedOperator={currentFilterConfig.filterOperation?.id}
          />
        ))
      )}
      <Button
        minimal
        className={classes.addFilterButton}
        icon={
          <Icon
            className={GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.color}
            icon="plus"
            iconSize={10}
          />
        }
        onClick={addFilter}
        text={
          <div
            className={cx(
              classes.addFilterText,
              GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.color,
            )}>
            Add filter
          </div>
        }
      />
    </div>
  );
}
