import { NAV_ITEMS } from './constants';
import { getEnvironment } from 'utils/environmentUtils';
import { doesUserHavePermission } from 'utils/permissionUtils';
import { PERMISSIONED_ACTIONS, PERMISSIONED_ENTITIES } from 'constants/roleConstants';
import { User } from 'actions/userActions';
import { Team } from 'actions/teamActions';
import { isArchitectEnabledForPlan, isReportBuilderEnabled } from 'utils/paymentPlanUtils';

export const getOrderedSetupItems = (currentUser: User, teamData?: Team) => {
  const orderedSetupItems = [];

  if (
    doesUserHavePermission(
      currentUser.permissions[PERMISSIONED_ENTITIES.DATA_SOURCE],
      PERMISSIONED_ACTIONS.READ,
    )
  )
    orderedSetupItems.push(NAV_ITEMS.DATA);

  if (
    doesUserHavePermission(
      currentUser.permissions[PERMISSIONED_ENTITIES.CUSTOMER],
      PERMISSIONED_ACTIONS.READ,
    ) ||
    doesUserHavePermission(
      currentUser.permissions[PERMISSIONED_ENTITIES.END_USER_GROUP],
      PERMISSIONED_ACTIONS.READ,
    )
  )
    orderedSetupItems.push(NAV_ITEMS.CUSTOMERS);

  if (
    doesUserHavePermission(
      currentUser.permissions[PERMISSIONED_ENTITIES.TEAM],
      PERMISSIONED_ACTIONS.UPDATE_CUSTOM_STYLES,
    )
  )
    orderedSetupItems.push(NAV_ITEMS.STYLES);

  if (
    currentUser.team?.feature_flags.show_analytics_tab &&
    (teamData?.internal_analytics_user_group_token || getEnvironment() === 'development')
  )
    orderedSetupItems.push(NAV_ITEMS.ANALYTICS);

  return orderedSetupItems;
};

export const getOrderedProductItems = (currentUser: User) => {
  const orderedProductItems = [];

  if (
    doesUserHavePermission(
      currentUser.permissions[PERMISSIONED_ENTITIES.DASHBOARD],
      PERMISSIONED_ACTIONS.READ,
    )
  )
    orderedProductItems.push(NAV_ITEMS.DASHBOARDS);

  const showCanvasPage = isArchitectEnabledForPlan(currentUser);
  if (
    showCanvasPage &&
    doesUserHavePermission(
      currentUser.permissions[PERMISSIONED_ENTITIES.ARCHITECT],
      PERMISSIONED_ACTIONS.READ,
    )
  )
    orderedProductItems.push(NAV_ITEMS.ARCHITECT);

  if (
    isReportBuilderEnabled(currentUser) &&
    doesUserHavePermission(
      currentUser.permissions[PERMISSIONED_ENTITIES.REPORT_BUILDER],
      PERMISSIONED_ACTIONS.READ,
    )
  )
    orderedProductItems.push(NAV_ITEMS.REPORT_BUILDER);

  if (currentUser.is_superuser) orderedProductItems.push(NAV_ITEMS.SUPERUSER);
  return orderedProductItems;
};

export const getUserInitials = (currentUser: User) => {
  const firstInitial = currentUser.first_name
    ? currentUser.first_name.charAt(0).toUpperCase()
    : 'A';
  const secondInitial = currentUser.last_name.charAt(0).toUpperCase();

  return `${firstInitial}${secondInitial}`;
};
