import { createAsyncThunk } from '@reduxjs/toolkit';
import { createErrorAction, createSuccessAction } from 'actions/actionUtils';
import { JobDefinition } from 'actions/jobQueueActions';
import { ACTION } from 'actions/types';
import { Jobs } from 'components/JobQueue/types';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import {
  ViewRequestParams,
  getCurrentView,
  getViewRequestParams,
  getExportColInfo,
} from 'utils/customerReportUtils';
import { makeThunkJobRequest } from 'utils/thunkUtils';

type ExportCustomerReportBody = {
  report_id: number;
  embed_id: string;
  version_number: number;
  dataset_id: string;
  column_names: string[];
  columns_to_select: string[] | null;
} & ViewRequestParams;

export const exportCustomerReportSuccess = createSuccessAction<{ export_url: string }>(
  ACTION.EXPORT_CUSTOMER_REPORT,
);
export const exportCustomerReportError = createErrorAction(ACTION.EXPORT_CUSTOMER_REPORT);

export const exportReport = createAsyncThunk<
  Record<string, Jobs>,
  undefined,
  { state: ReportBuilderReduxState }
>(ACTION.EXPORT_CUSTOMER_REPORT, async (_, { getState }) => {
  const { embeddedReportBuilder, reportEditing } = getState();
  const { requestInfo, reportBuilderVersion } = embeddedReportBuilder;
  const { currentConfig, currentView, selectedReportId } = reportEditing;

  let job: JobDefinition | null = null;
  const view = getCurrentView(currentConfig?.views, currentView);
  const dataset =
    reportBuilderVersion && currentConfig?.dataInfo
      ? reportBuilderVersion.config.datasets[currentConfig.dataInfo.datasetId]
      : undefined;

  if (view && selectedReportId && dataset) {
    const jobArgs: ExportCustomerReportBody = {
      embed_id: requestInfo.embedId,
      version_number: requestInfo.versionNumber,
      dataset_id: dataset.id,
      report_id: selectedReportId,
      ...getViewRequestParams(view),
      ...getExportColInfo(dataset, view),
    };
    job = {
      job_type: ACTION.EXPORT_CUSTOMER_REPORT,
      job_args: jobArgs,
    };
  }

  return makeThunkJobRequest(job, requestInfo.customerToken);
});
