import { MouseEvent as ReactMouseEvent } from 'react';
import { Checkbox } from '@blueprintjs/core';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';

import { Tag, Popover, sprinkles, IconButton } from 'components/ds';
import Button from 'shared/Button';
import TagEditPopover from './TagEditPopover';

import { ACTION } from 'actions/types';
import { EnvironmentTag } from 'actions/teamActions';
import { createLoadingSelector } from 'reducers/api/selectors';
import { createEnvironmentTag, updateEnvironmentTag } from 'actions/environmentTagActions';
import { ResourcePageType } from 'types/exploResource';
import { isVersionTagged } from 'utils/exploResourceUtils';

type Props = {
  className?: string;
  pageType: ResourcePageType;
  tags: EnvironmentTag[];
  resourceId: number;
  versionId: number;
};

export default function PublishToDropdown({
  className,
  pageType,
  tags,
  versionId,
  resourceId,
}: Props) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const createTagLoading = useSelector((state: ReduxState) =>
    createLoadingSelector([ACTION.CREATE_ENVIRONMENT_TAG], false)(state),
  );

  return (
    <div className={className} style={{ width: 180 }}>
      {tags.map((tag) => (
        <div
          className={sprinkles({
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: 4,
            paddingLeft: 'sp1',
            marginBottom: 'sp.5',
          })}
          key={`tag-${tag.id}`}>
          <div className={sprinkles({ flexItems: 'center' })}>
            <Checkbox
              checked={isVersionTagged(pageType, resourceId, versionId, tag)}
              onClick={() => {
                let data;
                if (pageType === ResourcePageType.ARCHITECT) {
                  data = { canvas_id: resourceId };
                } else if (pageType === ResourcePageType.EXPLORE) {
                  data = { dashboard_id: resourceId };
                } else {
                  data = { report_builder_id: resourceId };
                }

                dispatch(
                  updateEnvironmentTag({
                    postData: { tag_id: tag.id, version_id: versionId, ...data },
                  }),
                );
              }}
              style={{ margin: 0 }} // blueprint override
            />
            <Tag
              backgroundColor={tag.color_hex}
              className={sprinkles({ display: 'block', truncateText: 'ellipsis' })}
              // hack to make yellow palette readable until we swap out these override colors
              inverted={tag.color_hex !== theme.palette.envTagColors.yellow}>
              {tag.name}
            </Tag>
          </div>
          <div
            onClick={(e: ReactMouseEvent<HTMLElement, MouseEvent>) => {
              e.preventDefault();
              e.stopPropagation();
            }}>
            <Popover align="end" trigger={<IconButton name="ellipsis-vertical" />} width="small">
              <TagEditPopover
                color={tag.color_hex}
                name={tag.name}
                updateColor={(newColor) =>
                  dispatch(
                    updateEnvironmentTag({ postData: { tag_id: tag.id, color_hex: newColor } }),
                  )
                }
                updateName={(newName) =>
                  dispatch(updateEnvironmentTag({ postData: { tag_id: tag.id, name: newName } }))
                }
              />
            </Popover>
          </div>
        </div>
      ))}
      <Button
        minimal
        className={sprinkles({ width: 'fill' })}
        disabled={createTagLoading}
        icon="plus"
        loading={createTagLoading}
        onClick={() => !createTagLoading && dispatch(createEnvironmentTag())}
        text="Create new"
      />
    </div>
  );
}
