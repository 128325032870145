import { DashboardElement, DASHBOARD_ELEMENT_TYPES, SelectElemConfig } from 'types/dashboardTypes';
import { INPUT_TYPE } from 'constants/types';

export const areFilterValuesComplete = (element: DashboardElement): boolean => {
  switch (element.element_type) {
    case DASHBOARD_ELEMENT_TYPES.TOGGLE:
    case DASHBOARD_ELEMENT_TYPES.DROPDOWN:
    case DASHBOARD_ELEMENT_TYPES.MULTISELECT: {
      const config = element.config as SelectElemConfig;
      if (config.valuesConfig.valuesSource === INPUT_TYPE.QUERY) {
        return getSelectFilterDatasetId(config) !== null;
      } else {
        return !!config.valuesConfig.manualValues;
      }
    }
  }
  return true;
};

export const getSelectFilterDatasetId = ({ valuesConfig }: SelectElemConfig): string | null => {
  if (
    valuesConfig.valuesSource === INPUT_TYPE.QUERY &&
    valuesConfig.queryTable !== undefined &&
    valuesConfig.queryValueColumn !== undefined
  )
    return valuesConfig.queryTable.id;
  return null;
};
