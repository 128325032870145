import cx from 'classnames';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { sprinkles } from 'components/ds';
import { useDispatch } from 'react-redux';

import OperatorDropdown from 'pages/dashboardPage/DashboardDatasetView/Header/OperatorDropdown';
import Button from 'shared/Button';
import FilterConfigMenu from './FilterConfigMenu';
import { ColumnOptionsPopover } from '../vizConfigs/droppable/ColumnOptionsPopover';
import { sharedStyles } from '../vizConfigs/droppable/sharedDroppedStyles';

import { DatasetSchema } from 'types/datasets';
import { FilterClause } from 'constants/types';
import { DashboardElement } from 'types/dashboardTypes';
import { DashboardParam } from 'types/dashboardVersionConfig';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { filterDptsWithDrilldowns } from 'utils/drilldownUtils';
import { FILTER_OPS_NO_VALUE } from 'types/filterOperations';
import {
  deleteFilterClause,
  selectFilterColumn,
  selectFilterOperator,
} from 'actions/dataPanelConfigActions';

const useStyles = makeStyles((theme: Theme) => ({
  operatorDropdown: {
    '& .bp3-button': {
      width: '100%',
      backgroundColor: `${theme.palette.ds.hovered.lightBlue} !important`,
      borderRadius: 3,
      border: 'none !important',
      padding: `0px !important`,
      color: theme.palette.ds.hovered.blue,
      '&:disabled': {
        backgroundColor: `${theme.palette.ds.grey400} !important`,
        color: theme.palette.ds.grey800,
      },
    },
    '& .bp3-icon': {
      color: theme.palette.ds.blue,
      padding: `0px 8px !important`,
    },
    '& .bp3-button-text': {
      fontWeight: 500,
      marginLeft: theme.spacing(2),
      width: '100%',
    },
  },
  columnsPopoverTargetContainer: {
    backgroundColor: theme.palette.ds.hovered.lightBlue,
    marginRight: theme.spacing(1),
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
}));

type Props = {
  clause: FilterClause;
  clauseIdx: number;
  dashboardElements?: DashboardElement[];
  dashboardParams?: Record<string, DashboardParam>;
  dataPanels?: DataPanelTemplate[];
  schema: DatasetSchema;
};

export default function DroppedFilterColumn({
  clause,
  clauseIdx,
  dashboardElements,
  dashboardParams,
  dataPanels,
  schema,
}: Props) {
  const classes = useStyles();
  const sharedClasses = sharedStyles();
  const dispatch = useDispatch();

  const dptsWithDrilldown = dataPanels && filterDptsWithDrilldowns(dataPanels);

  const showFilterConfigMenu =
    !clause.filterOperation || !FILTER_OPS_NO_VALUE.has(clause.filterOperation.id);
  return (
    <div
      className={sprinkles({
        backgroundColor: 'lightBlue',
        padding: 'sp.5',
        paddingBottom: showFilterConfigMenu ? undefined : 'sp0',
        borderRadius: 3,
        marginBottom: 'sp.5',
        display: 'flex',
        alignItems: 'center',
      })}>
      <div className={sprinkles({ flexItems: 'column', flex: 1, overflow: 'hidden' })}>
        <div className={sprinkles({ display: 'flex', flex: 1 })}>
          <div className={classes.columnsPopoverTargetContainer}>
            <div className={sharedClasses.name}> {clause.filterColumn?.name}</div>
            <ColumnOptionsPopover
              onColChange={(column) => dispatch(selectFilterColumn({ index: clauseIdx, column }))}
              rightDivider={false}
              schema={schema}
            />
          </div>
          <OperatorDropdown
            className={classes.operatorDropdown}
            onOperatorSelect={(operator) =>
              dispatch(selectFilterOperator({ index: clauseIdx, operator }))
            }
            selectedColumn={clause.filterColumn}
            selectedOperator={clause.filterOperation?.id}
          />
        </div>
        <div className={sprinkles({ marginTop: 'sp.5' })}>
          {showFilterConfigMenu ? (
            <FilterConfigMenu
              tall
              clauseIdx={clauseIdx}
              dashboardElements={dashboardElements}
              dashboardParams={dashboardParams}
              dataPanels={dptsWithDrilldown}
              filterValue={clause.filterValue}
              filterValueSource={clause.filterValueSource}
              filterValueVariableId={clause.filterValueVariableId}
              filterValueVariableProperty={clause.filterValueVariableProperty}
              operatorDropdownClass={classes.operatorDropdown}
              selectedColumn={clause.filterColumn}
              selectedOperator={clause.filterOperation?.id}
            />
          ) : null}
        </div>
      </div>
      <Button
        compact
        minimal
        className={cx(sharedClasses.iconBtn, sprinkles({ marginLeft: 'sp.5' }))}
        icon="cross"
        onClick={() => dispatch(deleteFilterClause(clauseIdx))}
      />
    </div>
  );
}
