import { FC } from 'react';

import * as styles from '../styles.css';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import { Switch } from 'components/ds';
import { TextInputElemConfig } from 'types/dashboardTypes';
import { getUpdateConfigFunc } from '../utils';

type Props = {
  config: TextInputElemConfig;

  updateInputConfig: (config: TextInputElemConfig) => void;
};

export const TextInputAdjustDisplayConfig: FC<Props> = ({ config, updateInputConfig }) => {
  const updateConfig = getUpdateConfigFunc(config, updateInputConfig);

  return (
    <>
      <InputWithBlurSave
        containerClassName={styles.configInput}
        initialValue={config.label}
        label="Label"
        onNewValueSubmitted={(newValue) => updateConfig((draft) => (draft.label = newValue))}
      />
      <InputWithBlurSave
        containerClassName={styles.configInput}
        initialValue={config.placeholderText}
        label="Placeholder Text"
        onNewValueSubmitted={(newValue) =>
          updateConfig((draft) => (draft.placeholderText = newValue))
        }
      />
      <Switch
        useCustomStyles
        className={styles.configInput}
        label="Show Search Icon"
        onChange={() => updateConfig((draft) => (draft.showSearchIcon = !draft.showSearchIcon))}
        switchOn={config.showSearchIcon}
      />
    </>
  );
};
