import { IToastProps } from '@blueprintjs/core';

import { DatasetColumn } from 'types/datasets';
import {
  AggedChartColumnInfo,
  Aggregation,
  AggregationType,
  CategoryChartColumnInfo,
  ConfigurableColumnInfo,
} from 'constants/types';
import { AGGREGATIONS_TYPES, DATE, DATE_TYPES, DATETIME, TIMESTAMP } from 'constants/dataConstants';
import { DASHBOARD_ELEMENT_TYPES, DashboardElement } from 'types/dashboardTypes';
import {
  DATE_PART_AGGS,
  DATE_PIVOT_AGGS,
  DATE_TOGGLE_GROUP_OPTION,
  DATETIME_PART_AGGS,
  DATETIME_PIVOT_AGGS,
  GroupOption,
  PeriodRangeTypes,
  PIVOT_AGG_TYPES,
} from 'types/dateRangeTypes';
import { getNextAggType, getDefaultAgg, getAggOptions } from 'utils/aggUtils';

export const resolveCategoryColDropped = (
  newCol: DatasetColumn,
  config?: CategoryChartColumnInfo,
) => {
  const monthBucket = PIVOT_AGG_TYPES.DATE_MONTH;
  const bucket = DATE_TYPES.has(newCol.type)
    ? { id: monthBucket.id, name: monthBucket.name }
    : undefined;
  return { ...config, column: newCol, bucket };
};

export const defaultPeriodType = () => {
  return PeriodRangeTypes.LAST_4_WEEKS;
};

/**
 * This function is a helper to dropping a new agg column. It checks to make sure we are able to drop a new agg column by first checking for duplicates,
 * if there are no aggs available for it to take on, it will try the next available one. It returns undefined in the error case, and the new column in the
 * success case.
 */
export const getNewAggCol = (
  newCol: DatasetColumn,
  onError?: (toastProps: IToastProps) => void,
  columns: AggedChartColumnInfo[] = [],
  yAxisFormatId?: string,
  isCollapsibleListAgg?: boolean,
): AggedChartColumnInfo | undefined => {
  let newAgg: AggregationType = getDefaultAgg(newCol.type);
  const currentAggTypes = columns.reduce((acc, aggCol) => {
    if (aggCol.column.name === newCol.name) acc.push(aggCol.agg);
    return acc;
  }, [] as AggregationType[]);

  // Check if the column has already been included, if it has find first new way to aggregate
  if (currentAggTypes.length > 0) {
    const firstNewAgg = getNextAggType(newCol, currentAggTypes, isCollapsibleListAgg);
    if (!firstNewAgg) {
      onError?.({
        message: `The selected column is already present for all possible aggregations. Duplicates are not allowed.`,
        icon: 'warning-sign',
        timeout: 5000,
      });

      return undefined;
    } else {
      newAgg = firstNewAgg;
    }
  }

  return {
    column: newCol,
    agg: newAgg,
    yAxisFormatId: yAxisFormatId,
  };
};

export const resolveAggColDropped = (
  newCol: DatasetColumn,
  onError?: (toastProps: IToastProps) => void,
  columns?: AggedChartColumnInfo[],
  maxAggs?: number,
  yAxisFormatId?: string,
  isCollapsibleListAgg?: boolean,
) => {
  columns = columns || [];
  if (maxAggs) columns = columns.splice(0, maxAggs);

  const newAggCol = getNewAggCol(newCol, onError, columns, yAxisFormatId, isCollapsibleListAgg);
  if (newAggCol === undefined) return columns;
  if (columns.length === maxAggs) columns[maxAggs - 1] = newAggCol;
  else columns.push(newAggCol);

  return columns;
};

export const getColDisplayText = (col: ConfigurableColumnInfo) => {
  if (col.bucket) {
    return `${col.bucket.name.toUpperCase()}(${col.column.name || ''})`;
  } else if (col.agg) {
    if (col.agg.id === Aggregation.FORMULA && col.agg.formula) {
      return col.agg.formula;
    }
    return `${AGGREGATIONS_TYPES[col.agg.id].name.toUpperCase()}(${col.column.name || ''})`;
  }
  return col.column.name;
};

export const getGroupOptions = (
  col: ConfigurableColumnInfo,
  dashboardElements?: DashboardElement[],
  includeFirst?: boolean,
  isCollapsibleListAgg = false,
): GroupOption[] => {
  const columnType = col.column.type || '';
  if (isCollapsibleListAgg) return [{ options: getAggOptions(columnType, true) }];

  if (col.bucket) {
    // Known Edge Case: With a DATE type, a date group switch can filter by HOUR when it shouldn't be able to
    const dateGroupElements = filterForValidDateGroupElements(dashboardElements);
    const dateGroupUserInputs = dateGroupElements.length > 0 ? [DATE_TOGGLE_GROUP_OPTION] : [];

    // DATE types should not show time filters
    if (columnType === DATE) {
      return [
        { title: 'Dates', options: DATE_PIVOT_AGGS },
        {
          title: 'Date Parts',
          options: DATE_PART_AGGS,
        },
        ...dateGroupUserInputs,
      ];
    }

    // DATETIME and TIMESTAMP should show time filters
    if ([TIMESTAMP, DATETIME].includes(columnType)) {
      return [
        { title: 'Dates and Times', options: DATETIME_PIVOT_AGGS },
        {
          title: 'Date Parts',
          options: DATETIME_PART_AGGS,
        },
        ...dateGroupUserInputs,
      ];
    }
  } else if (col.agg) return [{ options: getAggOptions(columnType, false, includeFirst) }];

  return [];
};

export const filterForValidDateGroupElements = (dashboardElements?: DashboardElement[]) => {
  if (!dashboardElements) return [];
  return dashboardElements.filter(
    (elem) => elem.element_type === DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH,
  );
};
