import produce from 'immer';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import { Switch } from 'components/ds';
import DateFormatDescriptiveText from 'pages/dashboardPage/DataPanelConfigV2/FormatConfigTab/DateFormatDescriptiveText';
import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';

import { PieChartFormat, V2TwoDimensionChartInstructions } from 'constants/types';
import { BOOLEAN, DATE_TYPES } from 'constants/dataConstants';
import { V2_NUMBER_FORMATS } from 'constants/dataConstants';

type Props = {
  configInputClass: string;
  instructions: V2TwoDimensionChartInstructions;

  updateInstructions: (instructions: V2TwoDimensionChartInstructions) => void;
};

export default function SharedPieChartConfigs({
  configInputClass,
  instructions,
  updateInstructions,
}: Props) {
  const { chartSpecificFormat, categoryColumn } = instructions;
  const { pieChart } = chartSpecificFormat ?? {};
  const hideChartValues = pieChart?.hideChartValues ?? false;
  const useColorForLabel = pieChart?.useColorForLabel ?? false;
  const selectedFormat = pieChart?.valuesFormat || V2_NUMBER_FORMATS.PERCENT;

  const updatePieChartFormat = (pieChartUpdates: PieChartFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.chartSpecificFormat = {
        ...draft.chartSpecificFormat,
        pieChart: { ...draft.chartSpecificFormat?.pieChart, ...pieChartUpdates },
      };
    });
    updateInstructions(newInstructions);
  };

  return (
    <>
      <Switch
        useCustomStyles
        className={configInputClass}
        label="Show Value Labels"
        onChange={() => updatePieChartFormat({ hideChartValues: !hideChartValues })}
        switchOn={!hideChartValues}
      />
      {!hideChartValues && (
        <>
          <ToggleButtonGroup fillWidth className={configInputClass} label="Value Format">
            {[V2_NUMBER_FORMATS.NUMBER, V2_NUMBER_FORMATS.PERCENT].map((numberFormat) => (
              <ToggleButton
                active={numberFormat.id === selectedFormat.id}
                key={numberFormat.id}
                onClick={() => updatePieChartFormat({ valuesFormat: numberFormat })}
                text={numberFormat.abbreviation}
              />
            ))}
          </ToggleButtonGroup>

          <InputWithBlurSave
            containerClassName={configInputClass}
            initialValue={String(pieChart?.pctDecimalPlaces ?? 1)}
            label="Label Decimal Places"
            onNewValueSubmitted={(newValue) => {
              const intValue = parseInt(newValue);
              updatePieChartFormat({ pctDecimalPlaces: intValue >= 0 ? intValue : 1 });
            }}
          />
        </>
      )}
      <Switch
        useCustomStyles
        className={configInputClass}
        label="Show Category Names"
        onChange={() => updatePieChartFormat({ useColorForLabel: !useColorForLabel })}
        switchOn={useColorForLabel}
      />
      <InputWithBlurSave
        containerClassName={configInputClass}
        initialValue={String(pieChart?.maxCategories ?? '')}
        label="Max Categories"
        onNewValueSubmitted={(newValue) => {
          const intValue = parseInt(newValue);
          updatePieChartFormat({ maxCategories: intValue > 0 ? intValue : undefined });
        }}
      />
      {DATE_TYPES.has(categoryColumn?.column.type || '') ? (
        <InputWithBlurSave
          containerClassName={configInputClass}
          descriptiveText={<DateFormatDescriptiveText />}
          initialValue={instructions.xAxisFormat?.dateFormat}
          label="Category Date Format"
          onNewValueSubmitted={(newValue) => {
            const newInstructions = produce(instructions, (draft) => {
              draft.xAxisFormat = { ...draft.xAxisFormat, dateFormat: newValue };
            });
            updateInstructions(newInstructions);
          }}
        />
      ) : null}
      {categoryColumn?.column.type === BOOLEAN ? (
        <>
          <InputWithBlurSave
            containerClassName={configInputClass}
            initialValue={instructions.chartSpecificFormat?.pieChart?.trueLabel || 'true'}
            label="True Label"
            onNewValueSubmitted={(newValue) => {
              updatePieChartFormat({ trueLabel: newValue });
            }}
          />
          <InputWithBlurSave
            containerClassName={configInputClass}
            initialValue={instructions.chartSpecificFormat?.pieChart?.falseLabel || 'false'}
            label="False Label"
            onNewValueSubmitted={(newValue) => {
              updatePieChartFormat({ falseLabel: newValue });
            }}
          />
        </>
      ) : null}
    </>
  );
}
