import produce from 'immer';
import { useDispatch } from 'react-redux';

import { Switch } from 'components/ds';

import { updateGeneralFormatOptions } from 'actions/dataPanelConfigActions';
import { TableCSVFormat, VisualizeOperationGeneralFormatOptions } from 'constants/types';
import SettingHeader from '../../DataConfigTab/SettingHeader';
import { sharedStyles } from './styles';

type Props = {
  generalFormatOptions: VisualizeOperationGeneralFormatOptions;
};

export default function TableCSVConfig({ generalFormatOptions }: Props) {
  const sharedClasses = sharedStyles();
  const dispatch = useDispatch();

  const setCSVConfig = (update: (draft: TableCSVFormat) => void) => {
    const newOptions = produce(generalFormatOptions, (draft) => {
      if (!draft.export) draft.export = {};
      if (!draft.export.csvFormat) draft.export.csvFormat = {};
      update(draft.export.csvFormat);
    });
    dispatch(updateGeneralFormatOptions(newOptions));
  };

  const csvFormat = generalFormatOptions.export?.csvFormat;
  return (
    <>
      <SettingHeader name="CSV Download" />
      <div className={sharedClasses.root}>
        <Switch
          useCustomStyles
          className={sharedClasses.configInput}
          label="Tab Delimited"
          onChange={() =>
            setCSVConfig((draft) => {
              draft.tsvEnabled = !draft.tsvEnabled;
            })
          }
          switchOn={csvFormat?.tsvEnabled}
        />
      </div>
    </>
  );
}
