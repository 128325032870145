import { FC } from 'react';
import produce from 'immer';
import { useDispatch } from 'react-redux';
import { sprinkles, Icon, Switch } from 'components/ds';

import SettingHeader from '../../DataConfigTab/SettingHeader';

import { updateGeneralFormatOptions } from 'actions/dataPanelConfigActions';
import { Dataset } from 'actions/datasetActions';
import { VisualizeOperationGeneralFormatOptions } from 'constants/types';
import { selectDatasetForDrilldownFormatting } from 'utils/customEventUtils';

type Props = {
  generalFormatOptions: VisualizeOperationGeneralFormatOptions;
  dataset: Dataset;
  onCloseConfigClicked: () => void;
};

export const RawDataDrilldownConfig: FC<Props> = ({
  generalFormatOptions,
  dataset,
  onCloseConfigClicked,
}) => {
  const dispatch = useDispatch();

  return (
    <>
      <SettingHeader
        name="Expose Underlying Data"
        rightContent={
          <Switch
            useCustomStyles
            onChange={() => {
              const newFormat = produce(generalFormatOptions, (draft) => {
                draft.enableRawDataDrilldown = !draft.enableRawDataDrilldown;
              });
              dispatch(updateGeneralFormatOptions(newFormat));
            }}
            switchOn={generalFormatOptions?.enableRawDataDrilldown}
          />
        }
      />
      <div
        className={sprinkles({ padding: 'sp1.5', cursor: 'pointer' })}
        onClick={() => {
          onCloseConfigClicked();
          selectDatasetForDrilldownFormatting(dataset.id);
        }}>
        <div
          className={sprinkles({
            backgroundColor: 'activeSubdued',
            borderRadius: 8,
            display: 'flex',
            alignItems: 'center',
            paddingY: 'sp1.5',
            paddingLeft: 'sp2',
            color: 'activeBold',
            gap: 'sp1',
          })}>
          <Icon name="table" />
          {dataset.table_name}
        </div>
      </div>
    </>
  );
};
