import { PureComponent } from 'react';
import '@explo-tech/react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import DashboardTextElement from './dashboardTextElement';
import { DashboardDropdownElement } from './dashboardDropdownElement';
import { DashboardMultiSelectElement } from './dashboardMultiSelectElement';
import { DashboardDatepickerElement } from './dashboardDatepickerElement';
import { DashboardExportElement } from './dashboardExportElement';
import DashboardToggleElement from './dashboardToggleElement';
import DashboardDateRangePickerElement from './DashboardDateRangePickerElement';
import DashboardImageElement from './DashboardImageElement';
import DashboardDateGroupSwitchElement from './DashboardDateGroupSwitchElement';
import DashboardTimePeriodDropdownElement from './DashboardTimePeriodDropdownElement';
import DashboardSwitchElement from './dashboardSwitchElement';
import DashboardApplyFilterElement from './DashboardApplyFilterElement';
import DashboardTextInputElement from './dashboardTextInputElement';
import { DashboardIframeElement } from './DashboardIframeElement';
import { ArchitectCustomerDashboardEditor } from 'pages/architectCustomerDashboardPage/architectCustomerDashboardEditor';
import { sprinkles } from 'components/ds';

import {
  DashboardElement,
  TextDashboardElemConfig,
  SelectElemConfig,
  DatepickerElemConfig,
  ExportElemConfig,
  DashboardVariable,
  ContainerElemConfig,
  DASHBOARD_ELEMENT_TYPES,
  DashboardVariableMap,
  ImageElemConfig,
  DateGroupToggleConfig,
  VIEW_MODE,
  TimePeriodDropdownElemConfig,
  SwitchElementConfig,
  ApplyFilterElemConfig,
  TextInputElemConfig,
  DateRangePickerElemConfig,
  IframeElemConfig,
} from 'types/dashboardTypes';
import DashboardContainerElement from './DashboardContainerElement';
import { PassedProps as ElementGridLayoutProps } from 'components/DashboardLayout/ElementGridLayout';
import { isElemDisabledByDependency } from 'utils/dashboardUtils';
import { getSelectFilterDatasetId } from 'utils/filterUtils';
import { AnalyticsEventTracker } from 'utils/analyticsUtils';
import { OnNewValueSelectFunc } from 'types/functionPropTypes';

type PassedProps = {
  analyticsEventTracker?: AnalyticsEventTracker;
  blockedElementIds: string[];
  dashboardElement: DashboardElement;
  dashboardElementsById: Record<string, DashboardElement>;
  dashboardLayoutProps: ElementGridLayoutProps;
  elementStartsOnRightSide: boolean;
  editableDashboard?: boolean;
  variables: DashboardVariableMap;
  value?: DashboardVariable;
  applyFilters: (elementIds: string[]) => void;
  onNewValueSelect: OnNewValueSelectFunc;
  disableInputs?: boolean;
  isDragging?: boolean;
  isArchitectCustomerDashboard?: boolean;
  isMobileView?: boolean;
  isResizing: boolean;
  isSelected: boolean;
  timezone: string;
  viewMode: VIEW_MODE;
  portalId?: string;
};

type Props = PassedProps;

class DashboardElementView extends PureComponent<Props> {
  render() {
    return (
      <div className={sprinkles({ parentContainer: 'fill' })}>
        {this.renderElement()}
        {this.renderArchitectCustomerDashboardEditor()}
      </div>
    );
  }

  renderArchitectCustomerDashboardEditor = () => {
    const { isSelected, isArchitectCustomerDashboard, isResizing, isDragging } = this.props;
    if (!isSelected || isResizing || isDragging || !isArchitectCustomerDashboard) return null;

    return <ArchitectCustomerDashboardEditor />;
  };

  renderElement = () => {
    const {
      analyticsEventTracker,
      blockedElementIds,
      dashboardLayoutProps,
      dashboardElement,
      dashboardElementsById,
      elementStartsOnRightSide,
      value,
      onNewValueSelect,
      disableInputs,
      variables,
      isResizing,
      isMobileView,
      applyFilters,
      viewMode,
      portalId,
      timezone,
    } = this.props;
    const {
      datasets,
      fetchShareData,
      downloadDashboardImage,
      downloadDashboardPdf,
      shouldUseJobQueue,
      supportEmail,
      editableDashboard,
    } = dashboardLayoutProps;

    const elemDisabled = isElemDisabledByDependency(
      dashboardElement.config,
      variables,
      dashboardElementsById,
    );
    const areInputsDisabled = disableInputs || elemDisabled;

    switch (dashboardElement.element_type) {
      case DASHBOARD_ELEMENT_TYPES.TEXT:
        return (
          <DashboardTextElement
            config={dashboardElement.config as TextDashboardElemConfig}
            datasets={datasets}
            variables={variables}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.SPACER:
        return <div className={editableDashboard ? editableSpacerClass : undefined} />;
      case DASHBOARD_ELEMENT_TYPES.DROPDOWN:
        return (
          <DashboardDropdownElement
            analyticsEventTracker={analyticsEventTracker}
            config={dashboardElement.config as SelectElemConfig}
            datasets={datasets}
            disabled={areInputsDisabled}
            onNewValueSelect={onNewValueSelect}
            openElementToLeft={elementStartsOnRightSide}
            value={value}
            variables={variables}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN:
        return (
          <DashboardTimePeriodDropdownElement
            analyticsEventTracker={analyticsEventTracker}
            config={dashboardElement.config as TimePeriodDropdownElemConfig}
            disabled={areInputsDisabled}
            onNewValueSelect={onNewValueSelect}
            openElementToLeft={elementStartsOnRightSide}
            value={value as number}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.MULTISELECT:
        return (
          <DashboardMultiSelectElement
            analyticsEventTracker={analyticsEventTracker}
            config={dashboardElement.config as SelectElemConfig}
            datasets={datasets}
            disabled={areInputsDisabled}
            onNewValueSelect={onNewValueSelect}
            openElementToLeft={elementStartsOnRightSide}
            value={value}
            variables={variables}
          />
        );

      case DASHBOARD_ELEMENT_TYPES.DATEPICKER:
        return (
          <DashboardDatepickerElement
            analyticsEventTracker={analyticsEventTracker}
            config={dashboardElement.config as DatepickerElemConfig}
            disabled={areInputsDisabled}
            onNewValueSelect={onNewValueSelect}
            openElementToLeft={elementStartsOnRightSide}
            portalId={portalId}
            timezone={timezone}
            value={value}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER:
        return (
          <DashboardDateRangePickerElement
            analyticsEventTracker={analyticsEventTracker}
            config={dashboardElement.config as DateRangePickerElemConfig}
            disabled={areInputsDisabled}
            onNewValueSelect={onNewValueSelect}
            openElementToLeft={elementStartsOnRightSide}
            portalId={portalId}
            timezone={timezone}
            value={value}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.EXPORT:
        return (
          <DashboardExportElement
            analyticsEventTracker={analyticsEventTracker}
            config={dashboardElement.config as ExportElemConfig}
            downloadDashboardImage={downloadDashboardImage}
            downloadDashboardPdf={downloadDashboardPdf}
            exportVars={variables}
            fetchShareData={fetchShareData}
            isSharedView={viewMode === VIEW_MODE.SHARE}
            shouldUseJobQueue={shouldUseJobQueue}
            supportEmail={supportEmail}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.IMAGE:
        return (
          <DashboardImageElement
            config={dashboardElement.config as ImageElemConfig}
            variables={variables}
          />
        );

      case DASHBOARD_ELEMENT_TYPES.TOGGLE: {
        const config = dashboardElement.config as SelectElemConfig;
        let loading = false;
        const filterDatasetId = getSelectFilterDatasetId(config);
        if (filterDatasetId) {
          const dataset = datasets[filterDatasetId];
          if (dataset) loading = dataset._loading || !dataset._rows;
        }
        return (
          <DashboardToggleElement
            analyticsEventTracker={analyticsEventTracker}
            config={config}
            datasets={datasets}
            disabled={areInputsDisabled}
            loading={loading}
            onNewValueSelect={onNewValueSelect}
            value={value}
            variables={variables}
          />
        );
      }
      case DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH:
        return (
          <DashboardDateGroupSwitchElement
            analyticsEventTracker={analyticsEventTracker}
            config={dashboardElement.config as DateGroupToggleConfig}
            disabled={areInputsDisabled}
            onNewValueSelect={onNewValueSelect}
            openElementToLeft={elementStartsOnRightSide}
            value={value}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.SWITCH:
        return (
          <DashboardSwitchElement
            analyticsEventTracker={analyticsEventTracker}
            config={dashboardElement.config as SwitchElementConfig}
            disabled={areInputsDisabled}
            onNewValueSelect={onNewValueSelect}
            value={value}
            variables={variables}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.CONTAINER:
        return (
          <DashboardContainerElement
            config={dashboardElement.config as ContainerElemConfig}
            dashboardLayoutProps={dashboardLayoutProps}
            id={dashboardElement.id}
            isMobileView={isMobileView}
            isResizing={isResizing}
            viewMode={viewMode}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.APPLY_FILTER_BUTTON:
        return (
          <DashboardApplyFilterElement
            applyFilters={applyFilters}
            blockedElementIds={blockedElementIds}
            config={dashboardElement.config as ApplyFilterElemConfig}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.TEXT_INPUT:
        return (
          <DashboardTextInputElement
            config={dashboardElement.config as TextInputElemConfig}
            disabled={areInputsDisabled}
            onNewValueSelect={onNewValueSelect}
            value={value}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.IFRAME:
        return (
          <DashboardIframeElement
            config={dashboardElement.config as IframeElemConfig}
            variables={variables}
          />
        );
    }
  };
}

export default DashboardElementView;

const editableSpacerClass = sprinkles({
  height: 'fill',
  border: 2,
  borderColor: 'outline',
  borderRadius: 4,
});
