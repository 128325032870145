import { FC } from 'react';
import produce from 'immer';
import { useDispatch } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ColumnSortingConfig from '../../ColumnSortingConfig';
import { CollapsibleListItemConfig } from '../CollapsibleListItemConfig';
import { uniqueId } from 'utils/standard';

import { Switch } from 'components/ds';
import { SortableList, SortableListItem } from 'components/SortableList/SortableList';
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { DatasetSchema, DatasetColumn } from 'types/datasets';
import {
  OPERATION_TYPES,
  VisualizeTableInstructions,
  VisualizeCollapsibleListInstructions,
  SortOrder,
  DefaultSortColumn,
} from 'constants/types';
import { sortSchemaByOrderedColumnNames } from 'utils/general';

const useStyles = makeStyles((theme: Theme) => ({
  configInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
}));

type Props = {
  instructions: VisualizeCollapsibleListInstructions;
  schema: DatasetSchema;
};

export const CollapsibleListColumnsConfig: FC<Props> = ({ instructions, schema }) => {
  const dispatch = useDispatch();
  const classes = useStyles({ isExpanded: false });

  const numCategories = instructions?.rowColumns?.length || 0;
  const numValues = instructions?.categories?.length || 0;
  const categoryCols = schema.slice(0, numCategories);
  const aggCols = schema.slice(numCategories + numValues);
  const sortOptions = aggCols.map((col) => ({ id: col.name, name: col.friendly_name || col.name }));

  const orderedSchema = sortSchemaByOrderedColumnNames(aggCols, instructions?.orderedColumnNames);

  const updateInstructions = (instructions: VisualizeCollapsibleListInstructions) => {
    dispatch(updateVisualizeOperation(instructions, OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST));
  };

  const updateDefaultSort = (column: DatasetColumn, sortColumn: DefaultSortColumn) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.sortColumns = {
        ...draft.sortColumns,
        [column.name]: {
          ...draft.sortColumns?.[column.name],
          ...sortColumn,
        },
      };
    });

    updateInstructions(newInstructions);
  };

  const getSelectedCol = (column: DatasetColumn, sortOptions: { id: string; name: string }[]) => {
    const selectedCol = instructions.sortColumns?.[column.name]?.column;
    return selectedCol ? sortOptions.find((col) => col.id === selectedCol) : undefined;
  };

  return (
    <>
      <Switch
        useCustomStyles
        className={classes.configInput}
        disabled={false}
        label="Sorting"
        onChange={() => {
          const newInstructions = produce(instructions, (draft) => {
            draft.isSortingDisabled = !draft.isSortingDisabled;
          });

          updateInstructions(newInstructions);
        }}
        switchOn={!instructions.isSortingDisabled}
      />
      {!instructions.isSortingDisabled &&
        categoryCols.map((col) => (
          <ColumnSortingConfig
            key={uniqueId(col.name)}
            label={col.name}
            order={instructions.sortColumns?.[col.name]?.order ?? SortOrder.ASC}
            selectedItem={getSelectedCol(col, sortOptions)}
            sortOptions={sortOptions}
            updateDefaultSort={(sortColumn) => updateDefaultSort(col, sortColumn)}
          />
        ))}
      <SortableList
        getIdFromElem={(col) => col.name}
        onListUpdated={(newList) =>
          updateInstructions({
            ...instructions,
            orderedColumnNames: newList.map((col) => col.name),
          })
        }
        sortableItems={orderedSchema}>
        {orderedSchema.map((col) => (
          <SortableListItem key={col.name} sortId={col.name}>
            <CollapsibleListItemConfig
              column={col}
              instructions={instructions as VisualizeTableInstructions}
              key={uniqueId(col.name)}
              schema={schema}
              updateInstructions={updateInstructions}
            />
          </SortableListItem>
        ))}
      </SortableList>
    </>
  );
};
