import { useContext, FC } from 'react';
import cx from 'classnames';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Tooltip, Icon, Intent, IconName } from '@blueprintjs/core';

import DashboardLayoutContext from 'components/DashboardLayout/DashboardLayoutContext';

const useStyles = makeStyles((theme: Theme) => ({
  tooltipWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
  },
  popover: {
    maxWidth: 300,
  },
  infoIcon: {
    '& > span': {
      display: 'flex',
    },
  },
}));

type Props = {
  color?: string;
  infoTooltipText?: string | JSX.Element;
  className?: string;
  icon?: IconName;
};

export const InfoIcon: FC<Props> = ({ color, className, infoTooltipText, icon }) => {
  const classes = useStyles();
  const context = useContext(DashboardLayoutContext);
  const theme = useTheme();

  return (
    <Tooltip
      className={cx(classes.tooltipWrapper, className)}
      content={infoTooltipText}
      intent={Intent.NONE}
      interactionKind="hover"
      popoverClassName={classes.popover}
      portalContainer={document.getElementById(context.dashboardLayoutTagId) ?? undefined}
      targetClassName={classes.infoIcon}>
      <Icon color={color ?? theme.palette.ds.grey500} icon={icon ?? 'info-sign'} iconSize={14} />
    </Tooltip>
  );
};
