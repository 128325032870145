import { makeStyles, Theme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import FullPageContentContainer from 'shared/FullPageContentContainer';
import ConnectStepByStep from '../ConnectStepByStep';
import ConnectFAQs from '../ConnectFAQs';
import PanelButton from 'shared/PanelButton';

import { ConnectDataSourceStep } from '../constants';
import { DBConnectionConfig } from '../types';
import { ReduxState } from 'reducers/rootReducer';
import { dataSourceByType } from 'constants/dataSourceConstants';

const useStyles = makeStyles((theme: Theme) => ({
  body: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    rowGap: `${theme.spacing(3)}px`,
    columnGap: theme.spacing(3),
  },
}));

type Props = {
  config: DBConnectionConfig;
  updateConfig: (newconfig: DBConnectionConfig) => void;
  onNextClicked: () => void;
  onBackClicked: () => void;
};

const SelectDatabase = ({ config, updateConfig, onNextClicked, onBackClicked }: Props) => {
  const classes = useStyles();

  const supportedDataSources = useSelector((state: ReduxState) => state.supportedDataSources);

  return (
    <FullPageContentContainer
      backBtnConfig={{
        onClick: onBackClicked,
      }}
      bodyContent={
        <div className={classes.body}>
          {supportedDataSources.dataSources?.map((dataSource) => {
            return (
              <PanelButton
                disabled={config.selectedDataSourceIsLocked}
                imgUrl={dataSourceByType[dataSource.type].datasourceIconImg}
                key={`select-db-${dataSource.name}`}
                onClick={() => {
                  updateConfig({
                    ...config,
                    selectedDataSource: dataSource,
                  });
                }}
                selected={config.selectedDataSource?.name === dataSource.name}
                text={dataSource.name}
              />
            );
          })}
        </div>
      }
      headerTitle="Select a database"
      leftSideBarBottomContent={<ConnectFAQs />}
      leftSideBarTopContent={<ConnectStepByStep currentStep={ConnectDataSourceStep.SELECT_DB} />}
      primaryActionConfig={{
        text: 'Next',
        disabled: !config.selectedDataSource,
        onClick: onNextClicked,
      }}
    />
  );
};

export default SelectDatabase;
