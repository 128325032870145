import { FC } from 'react';
import cx from 'classnames';

import { Link } from 'react-router-dom';
import * as styles from './index.css';

type Props = {
  draftTabs?: Set<string>;
  errorTabs?: Set<string>;
  selectedTab: string;
  tabs: string[];
  toUrl: string;
};

export const ViewModeTabs: FC<Props> = ({ tabs, selectedTab, toUrl, draftTabs, errorTabs }) => {
  return (
    <div className={styles.tabOptions}>
      {tabs.map((tab, index) => {
        return (
          <Link
            className={cx(styles.tabOption, { [styles.selectedTab]: tab === selectedTab })}
            key={tab}
            to={`${toUrl}/${tab}`}>
            <span className={styles.tabNumber}>{index + 1}</span> {tab}
            {errorTabs?.has(tab) ? (
              <div className={cx(styles.tabNotification, styles.tabError)} />
            ) : draftTabs?.has(tab) ? (
              <div className={cx(styles.tabNotification, styles.tabDraft)} />
            ) : null}
          </Link>
        );
      })}
    </div>
  );
};
