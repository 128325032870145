import { GlobalStyleConfig, GlobalStyleTextConfig } from './types';

export const INTERACTION_STATE_OPACITY = 0.1;
export const ACTION_COLOR_OUTLINE_OPACITY = 0.3;
export const DROP_SHADOW_OPACITY = 0.25;
export const BACKGROUND_COLOR_OFFSET = 0.02;
export const BACKGROUND_COLOR_DEEPER_OFFSET = 0.05;

export const TEXT_SIZE_OFFSET_MAP: Record<keyof GlobalStyleTextConfig['overrides'], number> = {
  h1: 8,
  h2: 6,
  h3: 2,
  kpiTitle: 6,
  kpiValue: 22,
  tableColumnHeader: 2,
  body: 0,
  smallHeading: -2,
  smallBody: -2,
};

export const DEFAULT_OUTLINE_COLOR = '#eeeeee';

export const DEFAULT_GLOBAL_STYLE_CONFIG: GlobalStyleConfig = {
  base: {
    numColumns: 12,
    actionColor: { default: '#664df1' },
    backgroundColor: '#fdfdfd',
    spacing: { default: 30 },
  },
  container: {
    fill: '#ffffff',
    shadow: { size: 1, color: '#777777', enabled: true },
    outline: { color: DEFAULT_OUTLINE_COLOR, weight: 1, enabled: true },
    padding: { default: 25 },
    lineWidth: { default: 2 },
    cornerRadius: { default: 8, inputFields: 4 },
  },
  text: {
    textSize: 14,
    overrides: {
      smallBody: { color: '#777777' },
    },
    primaryFont: 'Manrope',
    primaryColor: '#0a0a0a',
    secondaryFont: 'Manrope',
    secondaryColor: '#777777',
  },
  visualizations: {
    gradientPalette: { hue1: '#ffffff', hue2: '#2196f3' },
    divergingPalette: { hue1: '#30cebb', hue2: '#FFFFFF', hue3: '#2196f3' },
    categoricalPalette: {
      hue1: '#664df1',
      hue2: '#f13663',
      hue3: '#ffc107',
      hue4: '#30cebb',
      hue5: '#f89368',
      hue6: '#2196f3',
    },
  },
};

export const GLOBAL_STYLE_CLASSNAMES = {
  base: {
    backgroundColor: {
      backgroundColor: 'explo-base-backgroundColor_backgroundColor',
      datePickerHeaderBackgroundColor: 'explo-base-backgroundColor_datePickerHeaderBackgroundColor',
    },
    actionColor: {
      default: {
        backgroundColor: 'explo-base-actionColor-default_backgroundColor',
        color: 'explo-base-actionColor-default_color',
        dropdownInputMenuItem: 'explo-base-actionColor-default_dropdownInputMenuItem',
        dropdownInputBorder: 'explo-base-actionColor-default_dropdownInputBorder',
        dropdownFilterInputOutline: 'explo-base-actionColor-default_dropdownFilterInputOutline',
        multiSelectInputOutlineActive: 'explo-base-actionColor-default_multiSelectInputOutline',
        multiSelectInputTag: 'explo-base-actionColor-default_multiSelectInputTag',
        datePickerInputBorderActive: 'explo-base-actionColor-default_datePickerInputBorder',
        buttonBorderActive: 'explo-base-actionColor-default_buttonBorderActive',
        datePickerDaySelected: 'explo-base-actionColor-default_datePickerDaySelected',
      },
      buttonColor: {
        backgroundColor: 'explo-base-actionColor-buttonColor_backgroundColor',
        color: 'explo-base-actionColor-buttonColor_color',
        buttonBackgroundColor: 'explo-base-actionColor-buttonColor_buttonBackgroundColor',
        buttonBackgroundColorImportant:
          'explo-base-actionColor-buttonColor_buttonBackgroundColorImportant',
        buttonColor: 'explo-base-actionColor-buttonColor_buttonColor',
        shareButtonHoverColor: 'explo-base-actionColor-buttonColor_shareButtonHoverColor',
        toggleBackgroundColor: 'explo-base-actionColor-buttonColor_toggleBackgroundColor',
        toggleSwitchInteraction: 'explo-base-actionColor-buttonColor_toggleSwitchInteraction',
      },
      interactionStates: {
        dropdownInputBorderHover:
          'explo-base-actionColor-interactionStates_dropdownInputBorderHover',
        datePickerInputBorderHover:
          'explo-base-actionColor-interactionStates_datePickerInputBorderHover',
        multiSelectInputBorderHover:
          'explo-base-actionColor-interactionStates_multiSelectInputBorderHover',
        textInputBorderHover: 'explo-base-actionColor-interactionStates_textInputBorderHover',
        buttonBorderHover: 'explo-base-actionColor-default_buttonBorderHover',
      },
    },
  },
  container: {
    fill: {
      backgroundColor: 'explo-container-fill_backgroundColor',
      offsetBackgroundColor: 'explo-container-fill_offsetBackgroundColor',
      deeperOffsetBackgroundColor: 'explo-container-fill_deeperOffsetBackgroundColor',
      datePickerMonthContainerFill: 'explo-container-fill_datePickerMonthContainerFill',
      datePickerTimeContainerFill: 'explo-container-fill_datePickerTimeContainerFill',
      buttonBackgroundColor: 'explo-container-fill_buttonBackgroundColor',
    },
    outline: {
      boxShadow: 'explo-container-outline_boxShadow',
      border: 'explo-container-outline_border',
      exportModalBorder: 'explo-container-outline_exportModalBorder',
      dropdownInputBorder: 'explo-container-outline_dropdownInputBorder',
      popoverBorder: 'explo-container-outline_popoverBorder',
      multiSelectInputBorder: 'explo-container-outline_multiSelectInputBorder',
      dropdownFilterInputBorder: 'explo-container-outline_dropdownFilterInputBorder',
      buttonBorder: 'explo-container-outline_buttonBorder',
    },
    shadow: {
      dropShadow: 'explo-container-shadow_dropShadow',
      multiSelectInputShadow: 'explo-container-outline_multiSelectInputShadow',
      buttonShadow: 'explo-container-outline_buttonShadow',
    },
    padding: {
      default: {
        padding: 'explo-container-padding_padding',
      },
    },
    cornerRadius: {
      default: {
        borderRadius: 'explo-container-cornerRadius-default_borderRadius',
        borderRadiusTop: 'explo-container-cornerRadius-default_borderRadiusTop',
        borderRadiusBottom: 'explo-container-cornerRadius-default_borderRadiusBottom',
        exportModalCornerRadius: 'explo-container-cornerRadius-default_exportModalCornerRadius',
      },
      buttons: {
        buttonCornerRadius: 'explo-container-cornerRadius-default_buttonCornerRadius',
      },
      inputFields: {
        defaultBorderRadius: 'explo-container-cornerRadius-inputFields_defaultBorderRadius',
        textElementInputBorderRadius: 'explo-container-cornerRadius-default_inputFieldBorderRadius',
        multiselectBorderRadius: 'explo-container-cornerRadius-default_multiselectBorderRadius',
      },
    },
  },
  text: {
    primaryColor: {
      color: 'explo-text-primaryColor_color',
    },
    secondaryColor: {
      color: 'explo-text-secondaryColor_color',
      datePickerNavigationPreviousColor:
        'explo-text-secondaryColor_datePickerNavigationPreviousColor',
      datePickerNavigationNextColor: 'explo-text-secondaryColor_datePickerNavigationNextColor',
      datePickerMonthDropdownIconColor:
        'explo-text-secondaryColor_datePickerMonthDropdownIconColor',
      datePickerYearDropdownIconColor: 'explo-text-secondaryColor_datePickerYearDropdownIconColor',
    },
    h1: {
      base: 'explo-text-h1',
    },
    h2: {
      base: 'explo-text-h2',
    },
    h3: {
      base: 'explo-text-h3',
    },
    kpiTitle: {
      base: 'explo-text-kpiTitle',
    },
    kpiValue: {
      base: 'explo-text-kpiValue',
    },
    tableColumnHeader: {
      base: 'explo-text-tableColumnHeader',
    },
    body: {
      primary: 'explo-text-body_primary',
      primaryFont: 'explo-text-body_primaryFont',
      secondary: 'explo-text-body_secondary',
      button: {
        primaryFont: 'explo-text-body-button_primaryFont',
        secondaryColor: 'explo-text-body-button_secondaryColor',
      },
      dropdownMenuItem: 'explo-text-body_dropdownMenuItem',
      dropdownFilterInput: 'explo-text-body_dropdownFilterInput',
      toggleSwitch: 'explo-text-body_toggleSwitch',
    },
    smallBody: {
      secondary: 'explo-text-smallBody_secondary',
      datePickerBody: 'explo-text-smallBody_datePickerBody',
    },
    smallHeading: {
      secondary: 'explo-text-smallHeading_secondary',
      chartAxisLabels: 'explo-text-smallHeading_chartAxisLabels',
      datePickerHeader: 'explo-text-smallHeading_datePickerHeader',
    },
  },
  ds: {
    radioGroup: 'explo-ds-radioGroup',
    switch: 'explo-ds-switch',
    switchLabel: 'explo-ds-switchLabel',
  },
};
