import Cookies from 'js-cookie';
import * as Redux from 'redux';
import { ActionCreatorWithPayload, createAction } from '@reduxjs/toolkit';
import axios, { Method, AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';

import { reportError } from 'analytics/datadog';
import { ErrorResponse } from 'actions/responseTypes';

export function createRequestAction<T>(action: string) {
  return createAction<T>(`${action}_REQUEST`);
}

export function createErrorAction<T>(action: string) {
  return createAction<T>(`${action}_ERROR`);
}

export function createSuccessAction<T>(action: string) {
  return createAction<T>(`${action}_SUCCESS`);
}

export interface ActionFnArgs {
  id?: string | number | null;
  headerTeamApiToken?: string;
  customerToken?: string;
  errorData?: ErrorResponse;
}

type ActionFn<T> = (
  args?: ActionFnArgs,
  onSuccess?: (data: T) => void,
  onError?: (errorMsg: ErrorResponse) => void,
) => void;

interface ActionFnArgsWithPost<T> extends ActionFnArgs {
  postData: T;
}

export type ActionFnWithArgs<K, T = {}> = (
  args: ActionFnArgsWithPost<K>,
  onSuccess?: (data: T) => void,
  onError?: (errorMsg: ErrorResponse) => void,
) => void;

export function defineEmbedAction<T>(
  actionName: string,
  urlBeforeId: string,
  urlAfterId: string,
  requestType: Method,
) {
  return defineAPIAction<T>(actionName, urlBeforeId, urlAfterId, requestType, true);
}

export function defineAPIAction<T>(
  actionName: string,
  urlBeforeId: string,
  urlAfterId: string,
  requestType: Method,
  forEmbed = false,
) {
  const requestAction = createRequestAction(actionName);
  const successAction = createSuccessAction<ActionFnArgs & T>(actionName);
  const errorAction = createErrorAction<ActionFnArgs & T & { errorData?: ErrorResponse }>(
    actionName,
  );

  const actionFn: ActionFn<T> = (
    args: ActionFnArgs = {},
    onSuccess?: (data: T) => void,
    onError?: (errorMsg: ErrorResponse) => void,
  ) => {
    return runAction<{}, T>(
      urlBeforeId,
      urlAfterId,
      requestType,
      requestAction,
      successAction,
      errorAction,
      args,
      forEmbed,
      onSuccess,
      onError,
    );
  };

  return { actionFn, requestAction, successAction, errorAction };
}

export function defineEmbedPostAction<K, T>(
  actionName: string,
  urlBeforeId: string,
  urlAfterId: string,
  requestType: Method,
) {
  return defineAPIPostAction<K, T>(actionName, urlBeforeId, urlAfterId, requestType, true);
}

export function defineAPIPostAction<K, T>(
  actionName: string,
  urlBeforeId: string,
  urlAfterId: string,
  requestType: Method,
  forEmbed = false,
) {
  const requestAction = createRequestAction<ActionFnArgsWithPost<K>>(actionName);
  const successAction = createSuccessAction<ActionFnArgsWithPost<K> & T>(actionName);
  const errorAction = createErrorAction<
    // eventually want to remove T
    ActionFnArgsWithPost<K> & T & { errorData?: ErrorResponse } & { error_msg?: string }
  >(actionName);

  const actionFn: ActionFnWithArgs<K, T> = (
    args: ActionFnArgsWithPost<K>,
    onSuccess?: (data: T) => void,
    onError?: (errorMsg: ErrorResponse) => void,
  ) => {
    return runAction<K, T>(
      urlBeforeId,
      urlAfterId,
      requestType,
      requestAction,
      successAction,
      errorAction,
      args,
      forEmbed,
      onSuccess,
      onError,
    );
  };

  return { actionFn, requestAction, successAction, errorAction };
}

function runAction<K, T>(
  urlBeforeId: string,
  urlAfterId: string,
  requestType: Method,
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  requestAction: ActionCreatorWithPayload<any>,
  successAction: ActionCreatorWithPayload<any>,
  errorAction: ActionCreatorWithPayload<any>,
  args: ActionFnArgsWithPost<K> | ActionFnArgs,
  forEmbed: boolean,
  onSuccess?: (data: T) => void,
  onError?: (errorMsg: ErrorResponse) => void,
) {
  let url = args.id ? `${urlBeforeId}/${args.id}/` : `${urlBeforeId}/`;
  if (urlAfterId && urlAfterId !== '') {
    url += `${urlAfterId}/`;
  }
  return (dispatch: Redux.Dispatch) => {
    dispatch(requestAction(args));
    const requestConfig = createApiRequestConfig(
      url,
      requestType,
      'postData' in args ? args.postData : undefined,
      forEmbed,
      args.customerToken,
      args.headerTeamApiToken,
    );
    return axios(requestConfig)
      .then((response: AxiosResponse) => {
        const { data } = response;

        if ((data.success !== 0 || data.status === 'OK') && !isErrorHttpStatus(response.status)) {
          // Note: data.status is for django-rest-passwordreset.
          dispatch(successAction({ ...args, ...data }));
          onSuccess?.(data);
        } else {
          dispatch(errorAction({ ...args, ...data }));
          onError?.(data);
        }
      })
      .catch((error: AxiosError) => {
        console.error(error.response);
        dispatch(
          errorAction({
            ...args,
            errorData: error.response && {
              ...error.response.data,
              status: error.response.status,
            },
          }),
        );
        onError?.(error.response && { ...error.response.data, status: error.response.status });
      });
  };
}

export function defineAuthAction<K, T>(
  actionName: string,
  urlBeforeId: string,
  urlAfterId: string,
  requestType: Method,
) {
  const requestAction = createRequestAction<ActionFnArgsWithPost<K>>(actionName);
  const successAction = createSuccessAction<ActionFnArgsWithPost<K> & T>(actionName);
  const errorAction = createErrorAction<ActionFnArgsWithPost<K> & { errorData?: ErrorResponse }>(
    actionName,
  );

  const actionFn: ActionFnWithArgs<K, T> = (
    args: ActionFnArgsWithPost<K>,
    onSuccess?: (data: T) => void,
    onError?: (errorMsg: ErrorResponse) => void,
  ) => {
    let url = args.id ? `${urlBeforeId}/${args.id}/` : `${urlBeforeId}/`;
    if (urlAfterId && urlAfterId !== '') {
      url += `${urlAfterId}/`;
    }
    return (dispatch: Redux.Dispatch) => {
      dispatch(requestAction(args));
      const apiRequest = createApiRequestConfig(
        url,
        requestType,
        args.postData,
        false,
        args.customerToken,
        args.headerTeamApiToken,
      );
      return axios(apiRequest)
        .then((response: AxiosResponse) => {
          const { data } = response;
          if ((data.success !== 0 || data.status === 'OK') && !isErrorHttpStatus(response.status)) {
            // Note: data.status is for django-rest-passwordreset.
            dispatch(successAction({ ...args, ...data }));
            onSuccess?.(data);
          } else {
            dispatch(errorAction({ ...args, ...data }));
            onError?.(data);
          }
        })
        .catch((error: AxiosError) => {
          console.error(error.response);
          reportError(error);
          dispatch(
            errorAction({
              ...args,
              errorData: error.response && {
                ...error.response.data,
                status: error.response.status,
              },
            }),
          );
          onError?.(error.response && { ...error.response.data, status: error.response.status });
        });
    };
  };

  return { actionFn, requestAction, successAction, errorAction };
}

export const createApiRequestConfig = (
  url: string,
  requestType: Method,
  postData: unknown | undefined,
  forEmbed: boolean,
  customerToken?: string,
  headerTeamApiToken?: string,
): AxiosRequestConfig => {
  return {
    url: process.env.REACT_APP_API_URL + url,
    method: requestType,
    headers: createHeaders(headerTeamApiToken, customerToken, forEmbed),
    data: postData,
  };
};

export function createHeaders(
  headerTeamApiToken: string | undefined,
  customerToken: string | undefined,
  forEmbed: boolean,
) {
  if (forEmbed) {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Explo-Authorization': headerTeamApiToken ? `Token ${headerTeamApiToken}` : undefined,
      'Customer-Token': customerToken ? customerToken : undefined,
    };
  }

  const authToken = Cookies.get('spheres_auth_token');
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: authToken ? 'Token ' + authToken : '',
    ...(headerTeamApiToken && { 'Explo-Authorization': `Token ${headerTeamApiToken}` }),
  };
}

/**
 * Checks if the status is a 4XX or 5XX
 */
const isErrorHttpStatus = (httpStatus: number) => {
  const statusCodeCategory = Math.floor(httpStatus / 100);
  return statusCodeCategory === 4 || statusCodeCategory === 5;
};
