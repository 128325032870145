import { makeStyles, Theme } from '@material-ui/core/styles';

import FullPageContentContainer from 'shared/FullPageContentContainer';
import ConnectStepByStep from '../ConnectStepByStep';
import ConnectFAQs from '../ConnectFAQs';
import { GettingStartedBody } from './GettingStarted';
import { EnterCredentialsBody } from './EnterCredentials';
import { SecurityConfigurationBody } from './SecurityConfiguration';
import PanelButton from 'shared/PanelButton';

import { ConnectDataSourceStep } from '../constants';
import { DBConnectionConfig } from '../types';
import { Intent } from '@blueprintjs/core';
import { dataSourceByType } from 'constants/dataSourceConstants';
import { ParentSchema, DataSource, Quantification } from 'actions/dataSourceActions';
import { areCredentialsComplete } from '../utils';

const useStyles = makeStyles((theme: Theme) => ({
  body: {
    paddingBottom: 100,
  },
  sectionHeader: {
    fontWeight: 600,
    fontSize: 16,
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(8),

    '&:first-child': {
      marginTop: 0,
    },
  },
}));

type Props = {
  config: DBConnectionConfig;
  updateConfig: (newconfig: DBConnectionConfig) => void;
  onNextClicked: () => void;
  onBackClicked: () => void;
  connectLoading?: boolean;
  testingConnWorked?: boolean;
  testingConnError?: string;
  testingConnLoading?: boolean;
  testingConnNumTables?: number;
  testingConnNumTablesQuantification?: Quantification;
  parentSchemas?: ParentSchema[];
  selectedSchema?: ParentSchema;
  setSelectedSchema: (schema: ParentSchema, isNew?: boolean) => void;
  existingDataSources: DataSource[];
  selectedAccessGroupIds: number[];
  setSelectedAccessGroupIds: (accessGroupIds: number[]) => void;
};

const ReviewConfiguration = ({
  config,
  updateConfig,
  onNextClicked,
  onBackClicked,
  testingConnWorked,
  testingConnError,
  testingConnLoading,
  testingConnNumTables,
  testingConnNumTablesQuantification,
  connectLoading,
  parentSchemas,
  selectedSchema,
  setSelectedSchema,
  existingDataSources,
  selectedAccessGroupIds,
  setSelectedAccessGroupIds,
}: Props) => {
  const classes = useStyles();

  if (!config.selectedDataSource)
    return <div>Error: A data source type must be selected before proceeding</div>;

  const dataSource = config.selectedDataSource;

  const getBottomBannerConfig = () => {
    if (testingConnLoading || testingConnWorked === undefined) return;
    if (testingConnWorked) {
      if (testingConnNumTables && testingConnNumTables > 0)
        return {
          intent: Intent.SUCCESS,
          text: `We’ve successfully connected to your database and found ${
            testingConnNumTablesQuantification === Quantification.AtLeast ? 'at least' : ''
          } ${testingConnNumTables} ${testingConnNumTables > 1 ? 'tables' : 'table'}, you’re ready
          to go!`,
        };
      else
        return {
          intent: Intent.WARNING,
          text: (
            <>
              We successfully connected to your database, but found no tables. If this is expected,
              you’re ready to go! Otherwise, this may be a permission issue with your credentials.
            </>
          ),
        };
    } else
      return {
        intent: Intent.DANGER,
        text: (
          <>
            <b>There was an error testing: </b>
            {testingConnError}
          </>
        ),
      };
  };

  return (
    <FullPageContentContainer
      backBtnConfig={{
        onClick: onBackClicked,
      }}
      bodyContent={
        <div className={classes.body}>
          <div className={classes.sectionHeader}>Getting Started</div>
          <GettingStartedBody
            config={config}
            existingDataSources={existingDataSources}
            parentSchemas={parentSchemas}
            selectedAccessGroupIds={selectedAccessGroupIds}
            selectedSchema={selectedSchema}
            setSelectedAccessGroupIds={setSelectedAccessGroupIds}
            setSelectedSchema={setSelectedSchema}
            updateConfig={updateConfig}
          />
          <div className={classes.sectionHeader}>Database Type</div>
          <PanelButton
            imgUrl={dataSourceByType[dataSource.type].datasourceIconImg}
            onClick={() => {
              return;
            }}
            selected={config.selectedDataSource?.name === dataSource.name}
            text={dataSource.name}
          />
          <div className={classes.sectionHeader}>Credentials</div>
          <EnterCredentialsBody config={config} updateConfig={updateConfig} />
          <div className={classes.sectionHeader}>Security</div>
          <SecurityConfigurationBody config={config} updateConfig={updateConfig} />
        </div>
      }
      bottomBannerConfig={getBottomBannerConfig()}
      headerTitle="Review"
      leftSideBarBottomContent={<ConnectFAQs />}
      leftSideBarTopContent={<ConnectStepByStep currentStep={ConnectDataSourceStep.REVIEW} />}
      primaryActionConfig={{
        text: testingConnWorked ? 'Connect' : 'Test Connection',
        onClick: onNextClicked,
        loading: connectLoading || testingConnLoading,
        disabled:
          !config.name ||
          !config.providedId ||
          !selectedSchema ||
          selectedAccessGroupIds.length === 0 ||
          !!config.providedIdError ||
          !areCredentialsComplete(
            dataSource.configuration_schema.properties,
            config.dataSourceConfig || {},
            false,
          ) ||
          !areCredentialsComplete(
            dataSource.configuration_schema.properties,
            config.dataSourceConfig || {},
            true,
          ),
      }}
    />
  );
};

export default ReviewConfiguration;
