import { createReducer } from '@reduxjs/toolkit';

import { EmbedDashboardTemplate } from 'actions/dashboardTemplateActions';
import { updateDrilldownDataPanel } from 'actions/dataPanelTemplateAction';
import { EmbedTeam, Customer } from 'actions/teamActions';
import {
  embedFetchDashboardDatasetPreviewSuccess,
  embedFetchDashboardError,
  embedFetchDashboardSuccess,
  embedFetchDataPanelRowCountSuccess,
  embedFetchDataPanelRowCountError,
  embedFetchDataPanelError,
  embedFetchDataPanelRequest,
  embedFetchDataPanelSuccess,
  embedFetchSecondaryDataRequest,
  embedFetchSecondaryDataSuccess,
  embedUpdateDPTDataTableColumnWidths,
  embedFetchSecondaryDataError,
} from 'actions/shareActions';
import { DashboardVersion } from 'types/dashboardVersion';
import { DashboardVersionConfig } from 'types/dashboardVersionConfig';
import {
  clearEmptyPanels,
  handleSecondaryDataError,
  handleSecondaryDataRequest,
  handleSecondaryDataSuccess,
} from './utils';
import { EMPTY_FILTER_CONFIG } from 'constants/dataConstants';
import { setDpLoading } from 'actions/dashboardV2Actions';
import { updateAdHocOperationInstructions } from 'actions/dataPanelTemplateAction';
import { DRILLDOWN_DATA_PANEL_ID } from 'reducers/dashboardEditConfigReducer';
import { DataPanelTemplate } from 'types/dataPanelTemplate';

interface EmbedDashboardReducer {
  dashboardTemplate?: EmbedDashboardTemplate;
  dashboardVersion?: DashboardVersion;
  team?: EmbedTeam;
  userGroup?: Customer;
  errorMsg?: string;
}

const initialState: EmbedDashboardReducer = {};

function updateConfiguration(
  state: EmbedDashboardReducer,
  updateFunc: (configuration: DashboardVersionConfig) => void,
): void {
  if (!state.dashboardVersion) return;

  updateFunc(state.dashboardVersion.configuration);
}

export default createReducer(initialState, (builder) => {
  builder
    .addCase(embedFetchDashboardSuccess, (state, { payload }) => {
      state.dashboardTemplate = payload.dashboard_template;
      state.dashboardVersion = payload.dashboard_version;
      state.team = payload.team;
      state.userGroup = payload.customer;

      if (state.dashboardVersion) clearEmptyPanels(state.dashboardVersion.configuration);
    })
    .addCase(embedFetchDashboardError, (state, { payload }) => {
      state.errorMsg = payload.errorData?.detail ?? 'Error loading dashboard';
    })
    .addCase(embedFetchDataPanelRequest, (state, { payload }) => {
      updateConfiguration(state, (configuration) => {
        configuration.data_panels[payload.postData.id]._loading = true;
        if (payload.postData) {
          configuration.data_panels[payload.postData.id]._adHocOperationInstructions = {
            ...configuration.data_panels[payload.postData.id]._adHocOperationInstructions,
            currentPage: payload.postData.page_number || 1,
            sortInfo: payload.postData.sort_info,
            filterInfo: payload.postData.filter_info || { ...EMPTY_FILTER_CONFIG },
          };
        }
      });
    })
    .addCase(embedFetchDataPanelError, (state, { payload }) => {
      updateConfiguration(state, (configuration) => {
        configuration.data_panels[payload.postData.id]._loading = false;
        configuration.data_panels[payload.postData.id]._error = payload.error_msg;
      });
    })
    .addCase(embedFetchDataPanelSuccess, (state, { payload }) => {
      updateConfiguration(state, (configuration) => {
        configuration.data_panels[payload.postData.id] = {
          ...configuration.data_panels[payload.postData.id],
          ...payload.data_panel_template,
        };
        configuration.data_panels[payload.postData.id]._loading = false;
      });
    })
    .addCase(embedFetchSecondaryDataRequest, (state, { payload }) => {
      updateConfiguration(state, (configuration) => {
        const currentDataPanel = configuration.data_panels[payload.postData.id];
        handleSecondaryDataRequest(currentDataPanel);
      });
    })
    .addCase(embedFetchSecondaryDataSuccess, (state, { payload }) => {
      updateConfiguration(state, (configuration) => {
        const currentDataPanel = configuration.data_panels[payload.postData.id];
        handleSecondaryDataSuccess(currentDataPanel, payload.data_panel_template);
      });
    })
    .addCase(embedFetchSecondaryDataError, (state, { payload }) => {
      updateConfiguration(state, (configuration) => {
        const currentDataPanel = configuration.data_panels[payload.postData.id];
        handleSecondaryDataError(currentDataPanel);
      });
    })
    .addCase(embedFetchDataPanelRowCountSuccess, (state, { payload }) => {
      updateConfiguration(state, (configuration) => {
        configuration.data_panels[payload.postData.id]._total_row_count = payload._total_row_count;
      });
    })
    .addCase(embedFetchDataPanelRowCountError, (state, { payload }) => {
      updateConfiguration(state, (configuration) => {
        configuration.data_panels[payload.postData.id]._total_row_count = undefined;
      });
    })
    .addCase(embedFetchDashboardDatasetPreviewSuccess, (state, { payload }) => {
      updateConfiguration(state, (configuration) => {
        const dataset = configuration.datasets[payload.postData.dataset_id];
        if (dataset) {
          dataset.schema = payload.dataset_preview?.schema;
          dataset._rows = payload.dataset_preview?._rows;
          dataset._unsupported_operations = payload.dataset_preview._unsupported_operations;
          dataset._loading = false;
          dataset._error = undefined;
        }
      });
    })
    .addCase(setDpLoading, (state, { payload }) => {
      updateConfiguration(state, (configuration) => {
        payload.ids.forEach((id) => {
          configuration.data_panels[id]._loading = payload.loading;
        });
      });
    })
    .addCase(updateAdHocOperationInstructions, (state, { payload }) => {
      updateConfiguration(state, (configuration) => {
        configuration.data_panels[payload.dataPanelId]._adHocOperationInstructions =
          payload.adHocOperationInstructions;
      });
    })
    .addCase(embedUpdateDPTDataTableColumnWidths, (state, { payload }) => {
      updateConfiguration(state, (configuration) => {
        const editingDPT = configuration.data_panels[payload.dataPanelTemplateId];

        if (editingDPT && editingDPT.visualize_op.instructions) {
          editingDPT.visualize_op.instructions.VISUALIZE_TABLE = payload.visualizeInstructions;
        }
      });
    })
    .addCase(updateDrilldownDataPanel, (state, { payload }) => {
      updateConfiguration(state, (configuration) => {
        payload.dataPanel.id = DRILLDOWN_DATA_PANEL_ID;
        configuration.data_panels[DRILLDOWN_DATA_PANEL_ID] = payload.dataPanel as DataPanelTemplate;
      });
    })
    .addDefaultCase((state) => state);
});
