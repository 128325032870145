import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import { pageView } from 'analytics/exploAnalytics';

import Button from 'shared/Button';
import SettingsLeftNav from './settingsLeftNav';
import SettingsProfileSection from './settingsProfileSection';
import { SettingsMembersSection } from './settingsMembersSection';
import SettingsAccessGroupsSection from './settingsAccessGroupsSection';
import SettingsAnalyticsSection from './settingsAnalyticsSection';
import SettingsDomainWhitelistSection from './settingsDomainWhitelistSection';
import SettingsShareLinkSection from './settingsShareLinkSection';
import SettingsInternationalizationSection from './SettingsInternationalizationSection';
import SettingsAttributesSection from './settingsAttributesSection';
import SettingsCustomFontsSection from './settingsCustomFontsSection';
import SettingsSupportEmailSection from './settingsSupportEmailSection';
import SettingsBillingSection from './SettingsBillingSection';

import { PLAN_TYPES } from 'constants/paymentPlanConstants';
import { ReduxState } from 'reducers/rootReducer';
import { SETTINGS_LEFT_NAV_WIDTH } from './settingsLeftNav';
import {
  SETTINGS_SUB_SECTION,
  SETTINGS_SUB_SECTIONS_PERMISSION_MAP,
  SUB_SECTION_ROUTE_MAP,
} from 'constants/settingsPageConstants';
import { fetchUserTeam } from 'actions/teamActions';
import { fetchWhitelistDomains } from 'actions/whitelistDomainActions';
import {
  addCalendlyScripts,
  removeCalendlyScripts,
  openActivationCalendly,
} from 'utils/calendlyUtils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    width: '100%',
    left: '0%',
    bottom: '100%',
    background: theme.palette.ds.white,
  },
  container: {
    display: 'flex',
    height: '100%',
  },
  bodyContainer: {
    overflowY: 'auto',
    background: theme.palette.ds.white,
    display: 'flex',
    justifyContent: 'center',
    width: `calc(100% - ${SETTINGS_LEFT_NAV_WIDTH}px)`,
  },
  body: {
    maxWidth: 720,
    width: '100%',
    height: 'fit-content',
    padding: theme.spacing(10),
  },
  banner: {
    alignItems: 'center',
    backgroundColor: theme.palette.ds.pressed.blue,
    color: theme.palette.ds.white,
    display: 'flex',
    padding: theme.spacing(2),
    justifyContent: 'center',
    width: '100%',
  },
  bannerText: {
    marginBottom: 0,
    paddingRight: theme.spacing(4),
  },
}));

const SettingsPage = () => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const params = useParams<{ subsectionId: string }>();

  const currentUser = useSelector((state: ReduxState) => state.currentUser);

  useEffect(() => {
    dispatch(fetchUserTeam());
    dispatch(fetchWhitelistDomains());
    pageView('Settings');
  }, [dispatch]);

  useEffect(() => {
    const scripts = addCalendlyScripts();
    return () => removeCalendlyScripts(scripts);
  }, []);

  const renderBody = () => {
    let component = <SettingsProfileSection />;
    let subSection = SETTINGS_SUB_SECTION.PROFILE;

    switch (params.subsectionId) {
      case SUB_SECTION_ROUTE_MAP[SETTINGS_SUB_SECTION.PROFILE]:
        component = <SettingsProfileSection />;
        subSection = SETTINGS_SUB_SECTION.PROFILE;
        break;
      case SUB_SECTION_ROUTE_MAP[SETTINGS_SUB_SECTION.MEMBERS]:
        component = <SettingsMembersSection />;
        subSection = SETTINGS_SUB_SECTION.MEMBERS;
        break;
      case SUB_SECTION_ROUTE_MAP[SETTINGS_SUB_SECTION.API_TOKENS]:
        component = <SettingsAccessGroupsSection />;
        subSection = SETTINGS_SUB_SECTION.API_TOKENS;
        break;
      case SUB_SECTION_ROUTE_MAP[SETTINGS_SUB_SECTION.DASHBOARD_ATTRIBUTES]:
        component = <SettingsAttributesSection />;
        subSection = SETTINGS_SUB_SECTION.DASHBOARD_ATTRIBUTES;
        break;
      case SUB_SECTION_ROUTE_MAP[SETTINGS_SUB_SECTION.CUSTOM_FONTS]:
        component = <SettingsCustomFontsSection />;
        subSection = SETTINGS_SUB_SECTION.CUSTOM_FONTS;
        break;
      case SUB_SECTION_ROUTE_MAP[SETTINGS_SUB_SECTION.ANALYTICS]:
        component = <SettingsAnalyticsSection />;
        subSection = SETTINGS_SUB_SECTION.ANALYTICS;
        break;
      case SUB_SECTION_ROUTE_MAP[SETTINGS_SUB_SECTION.DOMAIN_WHITELISTING]:
        component = <SettingsDomainWhitelistSection />;
        subSection = SETTINGS_SUB_SECTION.DOMAIN_WHITELISTING;
        break;
      case SUB_SECTION_ROUTE_MAP[SETTINGS_SUB_SECTION.INTERNATIONALIZATION]:
        component = <SettingsInternationalizationSection />;
        subSection = SETTINGS_SUB_SECTION.INTERNATIONALIZATION;
        break;
      case SUB_SECTION_ROUTE_MAP[SETTINGS_SUB_SECTION.DOMAIN_SETTINGS]:
        component = <SettingsShareLinkSection />;
        subSection = SETTINGS_SUB_SECTION.DOMAIN_SETTINGS;
        break;
      case SUB_SECTION_ROUTE_MAP[SETTINGS_SUB_SECTION.SUPPORT_EMAIL]:
        component = <SettingsSupportEmailSection />;
        subSection = SETTINGS_SUB_SECTION.SUPPORT_EMAIL;
        break;
      case SUB_SECTION_ROUTE_MAP[SETTINGS_SUB_SECTION.BILLING]:
        component = <SettingsBillingSection />;
        subSection = SETTINGS_SUB_SECTION.BILLING;
        break;
    }

    const permissionFn = SETTINGS_SUB_SECTIONS_PERMISSION_MAP[subSection];

    if (permissionFn && !permissionFn(currentUser.permissions)) return <SettingsProfileSection />;

    return component;
  };

  return (
    <div className={styles.root}>
      {currentUser.team?.payment_plan === PLAN_TYPES.DEACTIVATED ? (
        <div className={styles.banner}>
          <p className={styles.bannerText}>
            Your team&rsquo;s workspace has been deactivated. Please contact sales to continue using
            Explo.
          </p>
          <Button
            icon="chat"
            onClick={() => openActivationCalendly(currentUser)}
            text="Contact Sales"
            type="primary"
          />
        </div>
      ) : null}
      <div className={styles.container}>
        <SettingsLeftNav selectedSubSection={params.subsectionId} />
        <div className={styles.bodyContainer}>
          <div className={styles.body}>{renderBody()}</div>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
