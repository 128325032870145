import { DashboardVariable } from 'types/dashboardTypes';
import { VariableSelectOptions } from 'utils/extraVariableUtils';

export const DELETE_DASHBOARD_VARIABLES_EVENT = 'deleteDashboardVariables';

export type DeleteDashboardVariablesEvent = { names: string[] };

export const deleteVariables = (variableNames: string[]) => {
  if (variableNames.length === 0) return;
  const detail: DeleteDashboardVariablesEvent = { names: variableNames };

  window.dispatchEvent(new CustomEvent(DELETE_DASHBOARD_VARIABLES_EVENT, { detail }));
};

export const DATA_PANEL_UPDATE_EVENT = 'dataPanelUpdated';

export type DataPanelUpdateEvent = {
  dataPanelId: string;
  shouldRecompute: boolean;
  shouldRecomputeSecondaryData?: boolean;
};

export const dpConfigUpdated = (detail: DataPanelUpdateEvent) => {
  if (!detail.shouldRecompute && !detail.shouldRecomputeSecondaryData) return;

  window.dispatchEvent(new CustomEvent(DATA_PANEL_UPDATE_EVENT, { detail }));
};

export const sendVariableUpdatedEvent = (varName: string, newValue: DashboardVariable) => {
  window.dispatchEvent(
    new CustomEvent('sendVariableUpdatedEvent', {
      detail: { varName, newValue },
    }),
  );
};

export const DATA_PANEL_LINK_UPDATE = 'dataPanelLinkUpdate';

export type DataPanelLinkUpdateEvent = {
  dataPanelIds: string[];
  elementName: string;
};

export const dpLinkUpdated = (detail: DataPanelLinkUpdateEvent) => {
  window.dispatchEvent(new CustomEvent(DATA_PANEL_LINK_UPDATE, { detail }));
};

export const UPDATE_VARIABLE_VALUE_EVENT = 'updateVariableValue';

export type UpdateVariableEvent = {
  varName: string;
  newValue: DashboardVariable;
  options?: VariableSelectOptions;
};

export const updateVariableEvent = (detail: UpdateVariableEvent) => {
  window.dispatchEvent(new CustomEvent(UPDATE_VARIABLE_VALUE_EVENT, { detail }));
};

export const SAVE_RESOURCE_CONFIG_EVENT = 'saveResourceConfigEvent';

export const saveResourceConfig = () => {
  setTimeout(() => {
    window.dispatchEvent(new CustomEvent(SAVE_RESOURCE_CONFIG_EVENT));
  }, 200);
};

export const SELECT_DATASET_FOR_DRILLDOWN_FORMATTING = 'selectDatasetForDrilldownFormatting';

export type DrilldownDatasetId = { id: string };

export const selectDatasetForDrilldownFormatting = (id: string) => {
  const detail: DrilldownDatasetId = { id };

  window.dispatchEvent(new CustomEvent(SELECT_DATASET_FOR_DRILLDOWN_FORMATTING, { detail }));
};
