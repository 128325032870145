import { ACTION } from 'actions/types';
import { defineAPIAction, defineAPIPostAction } from 'actions/actionUtils';
import { DashboardTimezones } from 'constants/dashboardConstants';
import { DashboardVersion } from '../types/dashboardVersion';
import { LatestVersionInfo } from 'types/exploResource';

// TYPES
type DashboardCachingConfigOptions = {
  is_cache_enabled?: boolean;
  cache_cron?: string;
};

export interface DashboardTemplate extends EmbedDashboardTemplate, DashboardCachingConfigOptions {
  team_id: number;
  embed_id: string;
  disabled?: boolean;
  share_link_title: string | null;
  dashboard_attributes: { attribute_id: number; value_id: number }[];
  latest_versions: LatestVersionInfo[];
}

export interface EmbedDashboardTemplate {
  id: number;
  name: string;
  disable_filters_while_loading: boolean;
  default_timezone: DashboardTimezones | undefined;
  sync_category_colors: boolean;
}

// REQUESTS

type FetchDashboardTemplateData = {
  dashboard_template_name: string;
  dashboard_template_id: number;
  dashboard_version: DashboardVersion;
};

export const {
  actionFn: fetchDashboardTemplate,
  requestAction: fetchDashboardTemplateRequest,
  successAction: fetchDashboardTemplateSuccess,
  errorAction: fetchDashboardTemplateError,
} = defineAPIAction<FetchDashboardTemplateData>(
  ACTION.FETCH_DASHBOARD_TEMPLATE,
  'dashboards',
  'get_dashboard_template',
  'GET',
);

type CreateDashboardTemplatePost = { name: string };

type CreateDashboardTemplateData = {
  new_dashboard_template: DashboardTemplate;
};

export const { actionFn: createDashboardTemplate, successAction: createDashboardTemplateSuccess } =
  defineAPIPostAction<CreateDashboardTemplatePost, CreateDashboardTemplateData>(
    ACTION.CREATE_DASHBOARD_TEMPLATE,
    'dashboards',
    'create_dashboard_template',
    'POST',
  );

type CloneDashboardTemplateData = {
  new_dashboard_template: DashboardTemplate;
};

export const { actionFn: cloneDashboardTemplate, successAction: cloneDashboardTemplateSuccess } =
  defineAPIAction<CloneDashboardTemplateData>(
    ACTION.CLONE_DASHBOARD_TEMPLATE,
    'dashboards',
    'duplicate',
    'POST',
  );

export const { actionFn: deleteDashboardTemplate, successAction: deleteDashboardTemplateSuccess } =
  defineAPIAction(ACTION.DELETE_DASHBOARD_TEMPLATE, 'dashboards', '', 'DELETE');

type ActivateDisabledDashboardPostData = { disable_dashboard_id: number };

export const {
  actionFn: activateDisabledDashboard,
  successAction: activateDisabledDashboardSuccess,
} = defineAPIPostAction<ActivateDisabledDashboardPostData, {}>(
  ACTION.ACTIVATE_DISABLED_DASHBOARD,
  'dashboards',
  'activate',
  'POST',
);

type FetchDashboardTemplateListData = {
  dashboard_template_list: DashboardTemplate[];
};

export const {
  actionFn: fetchDashboardTemplateList,
  successAction: fetchDashboardTemplateListSuccess,
} = defineAPIAction<FetchDashboardTemplateListData>(
  ACTION.FETCH_DASHBOARD_TEMPLATE_LIST,
  'dashboards',
  'get_dashboard_template_list',
  'GET',
);

type RenameDashboardTemplateData = { name: string };

export const { actionFn: renameDashboardTemplate, successAction: renameDashboardTemplateSuccess } =
  defineAPIPostAction<RenameDashboardTemplateData, RenameDashboardTemplateData>(
    ACTION.RENAME_DASHBOARD_TEMPLATE,
    'dashboards',
    'rename_dashboard_template',
    'POST',
  );

type UpdateDashboardDefaultTimezoneBody = {
  default_timezone: DashboardTimezones;
};

export const {
  actionFn: updateDashboardDefaultTimezone,
  successAction: updateDashboardDefaultTimezoneSuccess,
} = defineAPIPostAction<UpdateDashboardDefaultTimezoneBody, {}>(
  ACTION.UPDATE_DASHBOARD_DEFAULT_TIMEZONE,
  'dashboards',
  'update_default_timezone',
  'POST',
);

type UpdateDashboardDisableFiltersWhileLoadingBody = {
  disable_filters_while_loading: boolean;
};

export const {
  actionFn: updateDashboardDisableFiltersWhileLoading,
  successAction: updateDisableFiltersWhileLoadingSuccess,
} = defineAPIPostAction<UpdateDashboardDisableFiltersWhileLoadingBody, {}>(
  ACTION.UPDATE_DISABLE_FILTERS_WHILE_LOADING,
  'dashboards',
  'update_dashboard_disable_filters_while_loading',
  'POST',
);

type UpdateDashboardSyncCategoryColorsBody = {
  sync_category_colors: boolean;
};

export const {
  actionFn: updateDashboardSyncCategoryColors,
  successAction: updateDashboardSyncCategoryColorsSuccess,
} = defineAPIPostAction<UpdateDashboardSyncCategoryColorsBody, {}>(
  ACTION.SYNC_CATEGORY_COLORS,
  'dashboards',
  'update_sync_category_colors',
  'POST',
);

export const {
  actionFn: updateDashboardCacheConfig,
  successAction: updateDashboardCacheConfigSuccess,
} = defineAPIPostAction<DashboardCachingConfigOptions, {}>(
  ACTION.UPDATE_DASHBOARD_CACHE_CONFIG,
  'dashboards',
  'update_caching_config',
  'POST',
);

// Share Dashboard Actions

type SaveShareLinkTitleBody = {
  share_link_title: string;
};

export const { actionFn: saveShareLinkTitle, requestAction: saveShareLinkTitleRequest } =
  defineAPIPostAction<SaveShareLinkTitleBody, {}>(
    ACTION.SAVE_SHARE_LINK_TITLE,
    'dashboards',
    'save_share_link_title',
    'POST',
  );

type FetchShareIdBody = {
  dashboard_template_id: number;
  user_group_id: number;
  version_number?: number;
  environment_tag_id?: number;
  password?: string;
  username?: string;
  is_strict_viewing_mode: boolean;
};

export type FetchShareIdData = {
  share_id: string;
  share_link_url: string | null;
};

export const {
  actionFn: fetchShareId,
  requestAction: fetchShareIdRequest,
  successAction: fetchShareIdSuccess,
  errorAction: fetchShareIdError,
} = defineAPIPostAction<FetchShareIdBody, FetchShareIdData>(
  ACTION.FETCH_SHARE_ID,
  'dashboards',
  'get_share_id',
  'POST',
);
