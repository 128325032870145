import { FC } from 'react';
import { useDispatch } from 'react-redux';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { Button } from 'components/ds';
import * as styles from './AddFilterButton.css';

import { FilterableColumn } from 'utils/customerReportUtils';
import { getColTypeDisplay } from 'utils/reportBuilderConfigUtils';
import { createFilter } from 'reportBuilderContent/reducers/reportEditingReducer';

type Props = { columns: FilterableColumn[] };

export const AddFilterButton: FC<Props> = ({ columns }) => {
  const dispatch = useDispatch();

  if (columns.length === 0) return null;

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        {/* Props to button causes the filter popover to close immediately after opening */}
        <span>
          <Button icon="plus" type="secondary">
            Filter
          </Button>
        </span>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content align="end" className={styles.columnMenu} sideOffset={8}>
        <div className={styles.menuHeader}>
          <EmbedText heading="h3">Select column to filter</EmbedText>
        </div>
        <div className={styles.columns}>
          {columns.map((col) => {
            return (
              <DropdownMenu.Item
                asChild
                className={styles.column}
                key={col.name}
                onSelect={() =>
                  dispatch(
                    createFilter({
                      column: { name: col.name, type: col.type },
                      isPostFilter: col.isPostFilter,
                    }),
                  )
                }>
                <div>
                  <EmbedText body="b1" className={styles.columnName}>
                    {col.display}
                  </EmbedText>
                  <EmbedText body="b2" color="contentTertiary">
                    {getColTypeDisplay(col.type)}
                  </EmbedText>
                </div>
              </DropdownMenu.Item>
            );
          })}
        </div>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};
