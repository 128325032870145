import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { AlertModal } from 'components/ds';

import { setSelectedBuiltInReport } from 'reportBuilderContent/reducers/embeddedReportBuilderReducer';

import {
  clearSelectedReport,
  closeReportModal,
} from 'reportBuilderContent/reducers/reportEditingReducer';

/**
 * Shown when the user tries to close a report or built in with unsaved changes
 */
export const ConfirmCloseReportModal: FC = () => {
  const dispatch = useDispatch();

  return (
    <AlertModal
      isOpen
      actionButtonProps={{
        text: 'Close without saving',
        onClick: () => {
          // This modal is shared between built ins and reports so we need to close both
          dispatch(clearSelectedReport());
          dispatch(setSelectedBuiltInReport());
        },
      }}
      cancelButtonProps={{ text: 'Keep Editing' }}
      onClose={() => dispatch(closeReportModal())}
      title="Are you sure you would like to close this report without saving?"
    />
  );
};
