export type EmbeddedDashboardType = 'embedded' | 'iframe' | 'shared' | 'portal' | 'email' | 'pdf';

export function shouldUseUrlParams(
  embedType: EmbeddedDashboardType,
  updateUrlParams?: boolean,
): boolean {
  return updateUrlParams || embedType !== 'embedded';
}

export function shouldSendAnalytics(embedType: EmbeddedDashboardType): boolean {
  return !['email', 'pdf'].includes(embedType);
}
