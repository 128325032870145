import { TEXT_ELEM_HORIZ_ALIGNMENTS } from 'types/dashboardTypes';
import { DisplayOptions } from 'constants/types';
import { BOOLEAN, DATE_TYPES, NUMBER_TYPES } from 'constants/dataConstants';
import { DatabaseUnsupportedOperations } from 'types/datasets';
import { Sprinkles } from 'components/ds/sprinkles.css';

type CellAlignment = 'start' | 'center' | 'end';

const cellAlignments: Record<string, CellAlignment> = {
  [TEXT_ELEM_HORIZ_ALIGNMENTS.LEFT]: 'start',
  [TEXT_ELEM_HORIZ_ALIGNMENTS.CENTER]: 'center',
  [TEXT_ELEM_HORIZ_ALIGNMENTS.RIGHT]: 'end',
};

const flexAlignments: Record<string, Sprinkles['justifyContent']> = {
  [TEXT_ELEM_HORIZ_ALIGNMENTS.LEFT]: 'flex-start',
  [TEXT_ELEM_HORIZ_ALIGNMENTS.CENTER]: 'center',
  [TEXT_ELEM_HORIZ_ALIGNMENTS.RIGHT]: 'flex-end',
};

export const getFlexAlignments = (
  displayOptions: DisplayOptions | undefined,
  type: string,
): Sprinkles['justifyContent'] => {
  const alignment = getAlignment(displayOptions, type);
  return flexAlignments[alignment];
};

export const getCellAlignment = (
  displayOptions: DisplayOptions | undefined,
  type: string,
): CellAlignment => {
  const alignment = getAlignment(displayOptions, type);
  return cellAlignments[alignment];
};

const getAlignment = (
  displayOptions: DisplayOptions | undefined,
  type: string,
): TEXT_ELEM_HORIZ_ALIGNMENTS => {
  const alignment = displayOptions?.alignment;
  if (alignment) return alignment;

  if (NUMBER_TYPES.has(type) || DATE_TYPES.has(type)) {
    return TEXT_ELEM_HORIZ_ALIGNMENTS.RIGHT;
  }
  if (type === BOOLEAN) return TEXT_ELEM_HORIZ_ALIGNMENTS.CENTER;
  return TEXT_ELEM_HORIZ_ALIGNMENTS.LEFT;
};

export const isPagingDisabled = (
  unsupportedOperations: DatabaseUnsupportedOperations[] | undefined,
) => (unsupportedOperations ?? []).includes(DatabaseUnsupportedOperations.PAGINATION);
