import { Theme, makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';

import { getChartIcon } from './icons';
import { OPERATION_TYPES } from 'constants/types';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(100% - 56px)',
    minHeight: 500,
  },
  iconRow: {
    display: 'flex',
    marginBottom: theme.spacing(5),
    '& > *': {
      marginRight: theme.spacing(5),
      ' &:last-child': {
        marginRight: 0,
      },
    },
  },
  text: {
    marginTop: theme.spacing(6),
    fontSize: 20,
    lineHeight: '24px',
    fontWeight: 500,
    color: theme.palette.ds.grey800,
    textAlign: 'center',
    width: 280,
  },
}));

export default function EmptyDashboard(): JSX.Element {
  const classes = useStyles();

  return (
    <div className={cx(classes.container, 'explo-dashboard-loaded')}>
      <div className={classes.iconRow}>
        {getChartIcon(OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2, 80)}
        {getChartIcon(OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2, 80)}
      </div>
      <div className={classes.iconRow}>
        {getChartIcon(OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2, 80)}
        {getChartIcon(OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2, 80)}
      </div>
      <div className={classes.text}>Start editing the dashboard to create charts.</div>
    </div>
  );
}
