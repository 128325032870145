import { createAction } from '@reduxjs/toolkit';
import { GlobalStyleConfig } from 'globalStyles/types';
import { defineAPIPostAction, defineAPIAction } from './actionUtils';
import { ACTION } from './types';

export const updateGlobalStyles = createAction<{ newConfig: GlobalStyleConfig }>(
  ACTION.UPDATE_GLOBAL_STYLES,
);

type SaveGlobalStylesData = {
  config_v2: GlobalStyleConfig;
};

export const { actionFn: saveGlobalStyles, successAction: saveGlobalStylesSuccess } =
  defineAPIPostAction<SaveGlobalStylesData, SaveGlobalStylesData>(
    ACTION.SAVE_GLOBAL_STYLES,
    'teams',
    'save_style_config_v2',
    'POST',
  );

export const resetGlobalStyles = createAction(ACTION.RESET_GLOBAL_STYLES);

type FetchGoogleFontsResponse = {
  items: string[];
};

export const { actionFn: fetchGoogleFonts, successAction: fetchGoogleFontsSuccess } =
  defineAPIAction<FetchGoogleFontsResponse>(ACTION.FETCH_GOOGLE_FONTS, 'google_fonts', '', 'GET');

export const selectDashboard = createAction<number | null>(ACTION.SELECT_DASHBOARD);
