import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { saveDraftDatasetQuery } from 'actions/datasetActions';
import {
  BuiltInReportConfig,
  CreateBuiltInReport,
  CreateReportBuilderDataset,
  ReportBuilderConfig,
  ReportBuilderDataset,
  ReportBuilderDatasetData,
  UpdateBuiltInReport,
  UpdateBuiltInReportConfig,
  UpdateReportBuilderColConfig,
  UpdateReportBuilderColFormatting,
  UpdateReportBuilderDataset,
} from 'actions/reportBuilderConfigActions';

import * as versionActions from 'actions/reportBuilderVersionActions';
import * as RD from 'remotedata';
import { VersionInfo } from 'types/exploResource';
import { saveResourceConfig } from 'utils/customEventUtils';
import { getCurrentISOString } from 'utils/dateUtils';
import { titleCase } from 'utils/graphUtils';
import { v4 as uuidv4 } from 'uuid';
import {
  fetchReportBuilderDataset,
  fetchReportBuilderDatasetRowCount,
} from './thunks/reportBuilderEditorThunks';
import { saveReportBuilderDraft } from './thunks/resourceSaveThunks';

interface ReportBuilderEditReducerState {
  config: RD.ResponseData<ReportBuilderConfig>;
  versionInfo: VersionInfo | null;
  versions: versionActions.ReportBuilderVersion[];
  datasetData: Record<string, ReportBuilderDatasetData>;
  selectedBuiltInId: string | undefined;
}

const initialState: ReportBuilderEditReducerState = {
  config: RD.Idle(),
  versionInfo: null,
  versions: [],
  datasetData: {},
  selectedBuiltInId: undefined,
};

const setVersion = (
  state: ReportBuilderEditReducerState,
  newVersion: versionActions.ReportBuilderVersion,
) => {
  state.config = RD.Success(newVersion.config);

  state.versionInfo = {
    is_draft: newVersion.is_draft,
    version_number: newVersion.version_number,
    edit_version_number: newVersion.edit_version_number,
    change_comments: newVersion.change_comments,
  };
};

const updateConfig = (
  state: ReportBuilderEditReducerState,
  updateFunc: (config: ReportBuilderConfig) => void,
  updateReportBuilder = true,
) => {
  if (!RD.isSuccess(state.config)) return;
  updateFunc(state.config.data);
  if (updateReportBuilder) saveResourceConfig();
};

const updateDataset = (
  state: ReportBuilderEditReducerState,
  datasetId: string,
  updateFunc: (dataset: ReportBuilderDataset) => void,
) => {
  updateConfig(state, (config) => {
    const dataset = config.datasets[datasetId];
    if (dataset) updateFunc(dataset);
  });
};

const updateBuiltInReportData = (
  state: ReportBuilderEditReducerState,
  builtInId: string,
  updateFunc: (dataset: BuiltInReportConfig) => void,
) => {
  updateConfig(state, (config) => {
    const report = config.builtInReports?.[builtInId];
    if (!report) return;
    // Need to manually track modified since Built Ins are stored as JSON instead of standalone DB models
    report.modified = getCurrentISOString();
    updateFunc(report);
  });
};

const reportBuilderEditorSlice = createSlice({
  name: 'reportBuilderEditor',
  initialState,
  reducers: {
    createReportBuilderDataset: (state, { payload }: PayloadAction<CreateReportBuilderDataset>) => {
      updateConfig(state, (config) => {
        const newDataset: ReportBuilderDataset = {
          description: '',
          id: payload.id,
          name: payload.name,
          parent_schema_id: payload.parentSchemaId,
          query: '',
          columnConfigs: {},
        };
        config.datasets[payload.id] = newDataset;
      });
    },
    updateReportBuilderDataset: (state, { payload }: PayloadAction<UpdateReportBuilderDataset>) => {
      updateDataset(state, payload.datasetId, (dataset) => {
        if (payload.name !== undefined) dataset.name = payload.name;
        if (payload.description !== undefined) dataset.description = payload.description;
        if (payload.schemaId !== undefined) dataset.parent_schema_id = payload.schemaId;
      });
    },
    updateReportBuilderColConfig: (
      state,
      { payload }: PayloadAction<UpdateReportBuilderColConfig>,
    ) => {
      updateDataset(state, payload.datasetId, (dataset) => {
        dataset.columnConfigs[payload.colName] = payload.config;
      });
    },
    updateReportBuilderColFormatting: (
      state,
      { payload }: PayloadAction<UpdateReportBuilderColFormatting>,
    ) => {
      updateDataset(state, payload.datasetId, (dataset) => {
        const colConfig = dataset.columnConfigs[payload.colName];
        if (!colConfig) return;
        colConfig.displayFormatting = payload.formatting;
      });
    },
    deleteReportBuilderDataset: (state, { payload }: PayloadAction<string>) => {
      updateConfig(state, (config) => {
        if (payload in config.datasets) delete config.datasets[payload];
      });
    },
    createBuiltInReport: (state, { payload }: PayloadAction<CreateBuiltInReport>) => {
      updateConfig(state, (config) => {
        const now = getCurrentISOString();
        const newReport: BuiltInReportConfig = {
          ...payload,
          id: uuidv4(),
          permissions: {
            allCustomers: true,
            customerIds: [],
          },
          // Need to manually track created and modified since Built Ins are stored as JSON instead of standalone DB models
          created: now,
          modified: now,
        };

        if (!config.builtInReports) config.builtInReports = {};
        config.builtInReports[newReport.id] = newReport;
        state.selectedBuiltInId = newReport.id;
      });
    },
    updateBuiltInReportSettings: (state, { payload }: PayloadAction<UpdateBuiltInReport>) => {
      updateBuiltInReportData(state, payload.builtInId, (report) => {
        if (payload.name) report.name = payload.name;
        if (payload.description) report.description = payload.description;
        if (payload.permissions) report.permissions = payload.permissions;
      });
    },
    updateBuiltInReportConfig: (state, { payload }: PayloadAction<UpdateBuiltInReportConfig>) => {
      updateBuiltInReportData(state, payload.builtInId, (report) => {
        report.config = payload.config;
      });
    },
    deleteBuiltInReport: (state, { payload: builtInId }: PayloadAction<string>) => {
      updateConfig(state, (config) => {
        const builtInReports = config.builtInReports;
        if (builtInReports) delete builtInReports[builtInId];
        if (state.selectedBuiltInId === builtInId)
          state.selectedBuiltInId = Object.keys(builtInReports || {})[0];
      });
    },
    selectBuiltInReport: (state, { payload: builtInId }: PayloadAction<string | undefined>) => {
      state.selectedBuiltInId = builtInId;
    },
    clearReportBuilderReducer: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(versionActions.fetchReportBuilderVersionsRequest, (state) => {
        state.config = RD.Loading();
        state.versions = [];
      })
      .addCase(versionActions.fetchReportBuilderVersionsError, (state) => {
        state.config = RD.Error('Error Loading Report Builder');
      })
      .addCase(versionActions.fetchReportBuilderVersionsSuccess, (state, { payload }) => {
        const sortedVersions = [...payload.versions].sort(
          (a, b) => b.version_number - a.version_number,
        );

        const version = sortedVersions[0];
        state.config = RD.Success(version.config);
        state.selectedBuiltInId = Object.keys(state.config.data.builtInReports || {})[0];
        state.versions = sortedVersions;
        state.versionInfo = {
          is_draft: version.is_draft,
          version_number: version.version_number,
          edit_version_number: version.edit_version_number,
          change_comments: version.change_comments,
        };
      })
      .addCase(versionActions.publishNewReportBuilderVersionSuccess, (state, { payload }) => {
        const version = payload.version;

        state.versionInfo = {
          is_draft: version.is_draft,
          version_number: version.version_number,
          edit_version_number: version.edit_version_number,
          change_comments: version.change_comments,
        };

        const publishedVersion = state.versions.find(({ id }) => id === version.id);
        if (publishedVersion) {
          publishedVersion.is_draft = payload.version.is_draft;
          publishedVersion.published_by_id = payload.version.published_by_id;
          publishedVersion.change_comments = payload.version.change_comments;
          publishedVersion.version_saved_at = payload.version.version_saved_at;
        }
      })
      .addCase(versionActions.deleteCurrentReportBuilderDraftSuccess, (state, { payload }) => {
        const index = state.versions.findIndex(
          (version) => version.version_number === payload.version_number,
        );
        if (index >= 0) state.versions.splice(index, 1);

        const newVersion = state.versions[0];
        if (newVersion) setVersion(state, newVersion);
      })
      .addCase(versionActions.revertToReportBuilderVersionSuccess, (state, { payload }) => {
        const newVersion = payload.new_version;
        const index = state.versions.findIndex(
          (version) => version.version_number === newVersion.version_number,
        );

        // Remove an active draft if present, since we are pushing a new draft based on the revert
        if (index >= 0) state.versions.splice(index, 1);

        state.versions.unshift(newVersion);
        setVersion(state, newVersion);
      })
      .addCase(saveDraftDatasetQuery, (state, { payload }) => {
        updateDataset(state, payload.dataset_id, (dataset) => {
          dataset.queryDraft =
            payload.queryDraft === dataset.query ? undefined : payload.queryDraft;
        });
      })
      .addCase(saveReportBuilderDraft.fulfilled, (state, { payload }) => {
        if ('edit_version_number' in payload) {
          if (state.versionInfo) {
            state.versionInfo.edit_version_number = payload.edit_version_number;
          }
          state.versions[0].edit_version_number = payload.edit_version_number;
        } else {
          const newVersion = payload.new_version;

          state.versions.unshift(newVersion);
          setVersion(state, newVersion);
        }
      })
      .addCase(fetchReportBuilderDataset.pending, (state, { meta }) => {
        const currentData = state.datasetData[meta.arg.datasetId];
        state.datasetData[meta.arg.datasetId] = {
          loading: true,
          rowCount: meta.arg.page === undefined ? undefined : currentData?.rowCount,
        };
      })
      .addCase(fetchReportBuilderDataset.fulfilled, (state, { meta, payload }) => {
        const { datasetId, save } = meta.arg;

        state.datasetData[datasetId] = {
          ...state.datasetData[datasetId],
          rows: payload.rows,
          // Don't need friendly_name so removing
          schema: payload.schema.map((col) => ({ name: col.name, type: col.type })),
          unsupportedOperations: payload.unsupported_operations,
          loading: false,
        };

        if (save) {
          updateDataset(state, datasetId, (dataset) => {
            dataset.query = dataset.queryDraft ?? dataset.query;
            dataset.queryDraft = undefined;
            dataset.schema = payload.schema;

            payload.schema.forEach((col) => {
              if (col.name in dataset.columnConfigs) return;
              dataset.columnConfigs[col.name] = {
                name: titleCase(col.name),
                isVisible: true,
              };
            });
          });
        }
      })
      .addCase(fetchReportBuilderDataset.rejected, (state, { meta, error }) => {
        state.datasetData[meta.arg.datasetId].error = error.message ?? 'Error loading rows';
        state.datasetData[meta.arg.datasetId].loading = false;
      })
      .addCase(fetchReportBuilderDatasetRowCount.fulfilled, (state, { meta, payload }) => {
        state.datasetData[meta.arg.datasetId].rowCount = payload.row_count;
      });
  },
});

export const getReportBuilderConfig = createSelector(
  (state: ReportBuilderEditReducerState) => state.config,
  (config) => {
    if (!RD.isSuccess(config)) return;
    return config.data;
  },
);

export const getSelectedBuiltIn = createSelector(
  (state: ReportBuilderEditReducerState) => state.config,
  (state: ReportBuilderEditReducerState) => state.selectedBuiltInId,
  (config, selectedBuiltInId) => {
    if (!RD.isSuccess(config)) return;
    return selectedBuiltInId ? config.data.builtInReports?.[selectedBuiltInId] : undefined;
  },
);

export default reportBuilderEditorSlice.reducer;

export const {
  createReportBuilderDataset,
  updateReportBuilderDataset,
  clearReportBuilderReducer,
  updateReportBuilderColConfig,
  deleteReportBuilderDataset,
  updateReportBuilderColFormatting,
  createBuiltInReport,
  updateBuiltInReportSettings,
  updateBuiltInReportConfig,
  deleteBuiltInReport,
  selectBuiltInReport,
} = reportBuilderEditorSlice.actions;
