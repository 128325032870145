import FormatSection from './FormatSection';
import SharedDataLabelsConfigs from 'components/ChartConfigs/DataLabelsConfigs';

import { sharedStyles } from './sharedStyles';
import { CanvasV2TwoDimensionChartTypes, FormatConfigProps } from './types';
import { V2TwoDimensionChartInstructions } from 'constants/types';

type Props = FormatConfigProps<CanvasV2TwoDimensionChartTypes, V2TwoDimensionChartInstructions>;

export default function DataLabelsSection({
  isArchitectCustomerDashboard,
  instructions,
  operationType,
  updateInstructions,
}: Props): JSX.Element {
  const classes = sharedStyles({ isArchitectCustomerDashboard });

  return (
    <FormatSection isArchitectCustomerDashboard={isArchitectCustomerDashboard} title="Data labels">
      <SharedDataLabelsConfigs
        configInputClass={classes.configInput}
        helpTextClass={classes.helperText}
        instructions={instructions}
        updateInstructions={(newInstructions) =>
          updateInstructions({ instructions: newInstructions, operationType })
        }
      />
    </FormatSection>
  );
}
