import { Intent, Tag, sprinkles } from 'components/ds';

import { QueryDebuggingInformation } from 'actions/responseTypes';

type Props = {
  queryInformation: QueryDebuggingInformation | undefined;
};

const tagClassName = sprinkles({
  marginX: 'sp1.5',
  marginTop: 'sp1.5',
  marginBottom: 'sp0',
  paddingX: 'sp.5',
  paddingY: 'sp1',
});
const containerClassName = sprinkles({ display: 'flex', flexWrap: 'wrap' });

function QueryRuntime({ queryInformation }: Props) {
  if (!queryInformation) return null;

  const createTag = (text: string) =>
    tags.push(
      <Tag className={tagClassName} intent={Intent.ACTIVE} key={text} leftIcon="clock">
        {text}
      </Tag>,
    );

  const tags: JSX.Element[] = [];

  if (queryInformation._query_timing.time_to_connect)
    createTag(`${queryInformation._query_timing.time_to_connect}s connection time`);
  if (queryInformation._query_timing.time_to_ssh)
    createTag(`${queryInformation._query_timing.time_to_ssh}s ssh tunneling time`);
  if (queryInformation._query_timing.time_to_run)
    createTag(`${queryInformation._query_timing.time_to_run}s querying time`);
  if (queryInformation._query_timing.time_to_process)
    createTag(`${queryInformation._query_timing.time_to_process}s processing time`);
  if (queryInformation._query_timing.total_time)
    createTag(`${queryInformation._query_timing.total_time}s total runtime`);

  if (queryInformation._query_timing.cache_hit !== undefined)
    tags.push(
      <Tag className={tagClassName} intent={Intent.NONE} key="cache-hit">
        {queryInformation._query_timing.cache_hit ? 'cache hit' : 'cache miss'}
      </Tag>,
    );

  if (tags.length === 0)
    return (
      <Tag className={tagClassName} intent={Intent.ERROR} leftIcon="circle-exclamation">
        Unable to calculate runtime stats
      </Tag>
    );

  return <div className={containerClassName}>{tags}</div>;
}

export { QueryRuntime };
