import FormatSection from './FormatSection';
import SharedPieChartConfigs from 'components/ChartConfigs/PieChartConfigs';

import { sharedStyles } from './sharedStyles';
import { OPERATION_TYPES, V2TwoDimensionChartInstructions } from 'constants/types';
import { FormatConfigProps } from './types';

type Props = FormatConfigProps<
  OPERATION_TYPES.VISUALIZE_PIE_CHART_V2 | OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2,
  V2TwoDimensionChartInstructions
>;

export default function PieChartSection({
  isArchitectCustomerDashboard,
  instructions,
  updateInstructions,
  operationType,
}: Props): JSX.Element | null {
  const classes = sharedStyles({ isArchitectCustomerDashboard });

  return (
    <FormatSection isArchitectCustomerDashboard={isArchitectCustomerDashboard} title="Pie Chart">
      <SharedPieChartConfigs
        configInputClass={classes.configInput}
        instructions={instructions}
        updateInstructions={(newInstructions) =>
          updateInstructions({ instructions: newInstructions, operationType })
        }
      />
    </FormatSection>
  );
}
