import { createReducer } from '@reduxjs/toolkit';

import {
  updateGlobalStyles,
  resetGlobalStyles,
  saveGlobalStylesSuccess,
  fetchGoogleFontsSuccess,
  selectDashboard,
} from 'actions/styleConfigActions';
import { GlobalStyleConfig } from 'globalStyles/types';

export const EXPLO_DASHBOARD_STORAGE_KEY = 'explo_dashboard';

type StyleConfigReducerState = {
  editing: GlobalStyleConfig | null;
  googleFonts?: string[];
  selectedDashboardId: number | null;
};

const initialState: StyleConfigReducerState = {
  editing: null,
  selectedDashboardId: null,
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(updateGlobalStyles, (state, { payload }) => {
      state.editing = payload.newConfig;
    })
    .addCase(resetGlobalStyles, (state) => {
      state.editing = null;
    })
    .addCase(saveGlobalStylesSuccess, (state) => {
      state.editing = null;
    })
    .addCase(fetchGoogleFontsSuccess, (state, { payload }) => {
      state.googleFonts = payload.items;
    })
    .addCase(selectDashboard, (state, { payload }) => {
      state.selectedDashboardId = payload;

      if (payload) localStorage.setItem(EXPLO_DASHBOARD_STORAGE_KEY, String(payload));
      else localStorage.removeItem(EXPLO_DASHBOARD_STORAGE_KEY);
    })
    .addDefaultCase((state) => state);
});
