import { PureComponent } from 'react';
import Highcharts, { PointOptionsObject, AxisTypeValue } from 'highcharts';
import ReactDOMServer from 'react-dom/server';
import NeedsConfigurationPanel from '../DashboardDatasetView/needsConfigurationPanel';
import { DrilldownChart } from './shared/drilldownChart';

import {
  getColorColNames,
  xAxisFormat,
  formatLegend,
  formatLabel,
  getColorPalette,
  getLabelStyle,
  isTwoDimVizInstructionsReadyToDisplay,
  areRequiredVariablesSetTwoDimViz,
  shouldProcessColAsDate,
  formatValue,
  getColorZones,
} from './utils';
import {
  ColorCategoryTracker,
  LineElasticity,
  V2TwoDimensionChartInstructions,
  VisualizeOperationGeneralFormatOptions,
} from 'constants/types';
import { DATE_TYPES } from 'constants/dataConstants';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { DatasetSchema } from 'types/datasets';
import { getColDisplayText } from '../DataPanelConfigV2/DataConfigTab/vizConfigs/utils';
import { GlobalStyleConfig } from 'globalStyles/types';
import { OpenDrilldownModalType } from 'types/dashboardTypes';
import {
  createYAxisBaseTooltip,
  getMultiYAxisInstructions,
  getSingleYAxisInstructions,
  getValueFormat,
  getYAxisFormatById,
  getYAxisChartIndex,
} from './utils/multiYAxisUtils';
import { formatTwoDimensionalData } from 'dataFormatters/twoDimensionalDataFormatter';
import { getTimezoneAwareUnix } from 'utils/timezoneUtils';
import { insertZeroesForMissingDateData } from 'utils/dateUtils';
import { ResourceDataset } from 'types/exploResource';
import { sortBy } from 'utils/standard';
import { getColorColumn, isSelectedColorDateType } from 'utils/colorColUtils';
import { NONE_CATEGORY_COLOR_VALUE } from 'constants/dashboardConstants';
import { ChartMenuInfo } from 'reducers/dashboardLayoutReducer';

type Props = {
  backgroundColor: string;
  colorCategoryTracker: ColorCategoryTracker;
  loading?: boolean;
  previewData: Record<string, string | number>[];
  instructions?: V2TwoDimensionChartInstructions;
  dataPanelTemplateId: string;
  canUseMultiYAxis?: boolean;
  variables: DashboardVariableMap;
  schema: DatasetSchema;
  selectedColorColName?: string;
  grouped?: boolean;
  horizontal?: boolean;
  area?: boolean;
  normalize?: boolean;
  globalStyleConfig: GlobalStyleConfig;
  datasets: Record<string, ResourceDataset>;
  openDrilldownModal?: OpenDrilldownModalType;
  generalOptions?: VisualizeOperationGeneralFormatOptions;
  setChartMenu: (info: ChartMenuInfo | null) => void;
};

type SeriesOptions = (
  | Highcharts.SeriesLineOptions
  | Highcharts.SeriesSplineOptions
  | Highcharts.SeriesAreaOptions
  | Highcharts.SeriesAreasplineOptions
) & {
  rawColorData?: string | number;
  // Highcharts has this set as possibly undefined so just defining it
  // since we never set it as undefined and makes it cleaner when used
  data: Array<number | [number | string, number | null] | null | PointOptionsObject>;
};

class LineChart extends PureComponent<Props> {
  render() {
    const { generalOptions, instructions, loading, variables, setChartMenu } = this.props;
    const requiredVarNotsSet = !areRequiredVariablesSetTwoDimViz(variables, instructions);
    const instructionsReadyToDisplay = isTwoDimVizInstructionsReadyToDisplay(instructions);

    if (loading || !instructionsReadyToDisplay || requiredVarNotsSet) {
      return (
        <NeedsConfigurationPanel
          fullHeight
          instructionsNeedConfiguration={!instructionsReadyToDisplay}
          loading={loading}
          requiredVarsNotSet={requiredVarNotsSet}
        />
      );
    }

    return (
      <DrilldownChart
        chartOptions={this._spec()}
        closeChartMenu={() => setChartMenu(null)}
        customMenuOptions={
          generalOptions?.customMenu?.enabled ? generalOptions?.customMenu?.menuOptions : undefined
        }
        dataPanelTemplateId={this.props.dataPanelTemplateId}
        instructions={instructions}
        openDrilldownModal={this.props.openDrilldownModal}
        selectedColorColName={this.props.selectedColorColName}
        underlyingDataEnabled={this.getUnderlyingDrilldownEnabled()}
      />
    );
  }

  _spec = (): Highcharts.Options | undefined => {
    const {
      previewData,
      schema,
      instructions,
      area,
      normalize,
      backgroundColor,
      generalOptions,
      globalStyleConfig,
      canUseMultiYAxis,
      variables,
      datasets,
      selectedColorColName,
      setChartMenu,
      dataPanelTemplateId,
    } = this.props;
    if (schema?.length === 0 || !previewData) return;

    // this is a short term fix en lieu of this bug being fixed by vega:
    // Ref: TU/447fn2df
    this.processDatesData();
    const { valueFormatId, decimalPlaces } = getValueFormat(instructions?.yAxisFormats?.[0]);
    const showMarkers =
      !instructions?.chartSpecificFormat?.lineChart?.hideMarkers || previewData.length === 1;
    const lineWidth =
      instructions?.chartSpecificFormat?.lineChart?.lineWidth ||
      globalStyleConfig.container.lineWidth.default;

    const data = this.transformData();
    const stacking = this.getStacking();
    const marker = { enabled: showMarkers };
    const underlyingDrilldownEnabled = this.getUnderlyingDrilldownEnabled();

    const hasClickEvents = underlyingDrilldownEnabled || generalOptions?.customMenu?.enabled;

    return {
      chart: {
        type: this.getChartType(),
        zoomType: 'x',
        backgroundColor,
      },
      series: data,
      title: { text: undefined },
      colors: getColorPalette(globalStyleConfig, instructions?.colorFormat),
      plotOptions: {
        series: {
          zones: getColorZones(instructions?.colorFormat, variables, datasets),
          lineWidth,
          animation: false,
          cursor: hasClickEvents ? 'pointer' : undefined,
          point: {
            events: {
              click: function (e) {
                if (!hasClickEvents) return;

                const subCategory: string | undefined =
                  !!instructions?.colorColumnOptions?.length &&
                  selectedColorColName !== NONE_CATEGORY_COLOR_VALUE
                    ? // @ts-ignore
                      e.point.series.userOptions.rawColorData
                    : undefined;

                setChartMenu({
                  chartId: dataPanelTemplateId,
                  chartX: e.chartX,
                  chartY: e.chartY,
                  category: e.point.category,
                  subCategory,
                });
              },
            },
          },
          dataLabels: {
            enabled: !instructions?.xAxisFormat?.hideTotalValues,

            formatter: function () {
              return formatValue({
                value: this.y || 0,
                decimalPlaces,
                formatId: valueFormatId,
                hasCommas: true,
              });
            },

            style: {
              textOutline: 'none',
              ...getLabelStyle(globalStyleConfig, 'primary'),
            },
          },
        },
        spline: { marker },
        areaspline: { stacking, marker },
        line: { marker },
        area: { stacking, marker },
      },
      yAxis: canUseMultiYAxis
        ? getMultiYAxisInstructions(globalStyleConfig, instructions, variables, datasets)
        : getSingleYAxisInstructions(globalStyleConfig, instructions, variables, datasets),
      xAxis: {
        ...xAxisFormat(globalStyleConfig, instructions?.xAxisFormat),
        type: this.getXAxisType(),
        crosshair: true,
        categories: this.getAxisCategories(),
        labels: {
          formatter: function () {
            return formatLabel(
              this.value,
              instructions?.categoryColumn?.column.type,
              instructions?.categoryColumn?.bucket?.id,
              instructions?.categoryColumn?.bucketSize,
              instructions?.xAxisFormat?.dateFormat,
              instructions?.xAxisFormat?.stringFormat,
            );
          },
          style: getLabelStyle(globalStyleConfig, 'secondary'),
          enabled: !instructions?.xAxisFormat?.hideAxisLabels,
        },
        visible: !instructions?.xAxisFormat?.hideAxisLine,
      },
      legend: {
        ...formatLegend(globalStyleConfig, instructions?.legendFormat),
      },

      tooltip: {
        formatter: function () {
          return ReactDOMServer.renderToStaticMarkup(
            createYAxisBaseTooltip({
              tooltipFormatter: this,
              globalValueFormatId: valueFormatId,
              globalDecimalPlaces: decimalPlaces,
              globalStyleConfig: globalStyleConfig,
              instructions: instructions,
              includePercent: (area && normalize) || instructions?.tooltipFormat?.showPct,
            }),
          );
        },
        padding: 0,
        borderWidth: 0,
        borderRadius: 0,
        backgroundColor: '#FFFFFF00',
        shadow: false,
        useHTML: true,
        outside: true,
        followPointer: true,
      },
    };
  };

  getUnderlyingDrilldownEnabled = () => {
    const { generalOptions, openDrilldownModal } = this.props;
    return !!(generalOptions?.enableRawDataDrilldown && openDrilldownModal);
  };

  getChartType = () => {
    const { area, instructions } = this.props;
    if (instructions?.chartSpecificFormat?.lineChart?.elasticity === LineElasticity.STRAIGHT) {
      return area ? 'area' : 'line';
    } else {
      return area ? 'areaspline' : 'spline';
    }
  };

  getStacking = () => {
    const { normalize } = this.props;
    return normalize ? 'percent' : 'normal';
  };

  getXAxisType = (): AxisTypeValue | undefined => {
    const { instructions } = this.props;

    if (DATE_TYPES.has(instructions?.categoryColumn?.column.type || '')) return 'datetime';
    return 'category';
  };

  getXAxisColName = () => {
    const { schema } = this.props;

    return schema[0].name;
  };

  processDatesData = () => {
    const { instructions, previewData, schema } = this.props;
    const categoryColIsDate = shouldProcessColAsDate(instructions?.categoryColumn);
    const colorColIsDate = isSelectedColorDateType(instructions || {});

    if (!previewData || (!categoryColIsDate && !colorColIsDate) || !schema?.length) return;

    const { xAxisColName, aggColName, colorColName } = getColorColNames(schema);

    if (instructions?.categoryColumn?.column.type) {
      previewData.forEach((row) => {
        if (categoryColIsDate)
          row[xAxisColName] = getTimezoneAwareUnix(row[xAxisColName] as string);
        if (colorColIsDate) row[colorColName] = getTimezoneAwareUnix(row[colorColName] as string);
      });
    }

    if (instructions?.chartSpecificFormat?.timeSeriesDataFormat?.zeroMissingDates) {
      const colNames = { xAxisColName, yAxisColName: aggColName, colorColName };
      insertZeroesForMissingDateData(previewData, instructions.categoryColumn, colNames);
    }
  };

  getAxisCategories = () => {
    const { instructions, previewData } = this.props;
    if (DATE_TYPES.has(instructions?.categoryColumn?.column.type || '')) return;

    const xAxisColName = this.getXAxisColName();
    const categories = new Set(previewData.map((row) => String(row[xAxisColName])));
    return Array.from(categories);
  };

  transformData = (): SeriesOptions[] => {
    const { instructions, schema, selectedColorColName } = this.props;

    if (
      !instructions?.aggColumns ||
      instructions.aggColumns.length === 0 ||
      !schema ||
      schema.length === 0
    )
      return [];

    if (
      instructions.colorColumnOptions?.length &&
      selectedColorColName !== NONE_CATEGORY_COLOR_VALUE
    )
      return this.transformColorData(schema);

    return this.transformAggColsData(schema);
  };

  transformColorData = (schema: DatasetSchema): SeriesOptions[] => {
    const { previewData, instructions, selectedColorColName, colorCategoryTracker, area } =
      this.props;
    const { xAxisColName, colorColName, aggColName } = getColorColNames(schema);
    const isDate = DATE_TYPES.has(instructions?.categoryColumn?.column.type || '');
    const series: Record<string, SeriesOptions> = {};
    const colorColumn = getColorColumn(instructions, selectedColorColName);
    const zeroEmptyValues =
      instructions?.chartSpecificFormat?.timeSeriesDataFormat?.zeroMissingValues;

    previewData.forEach((row) => {
      if (isDate && (row[xAxisColName] === undefined || isNaN(row[xAxisColName] as number))) return;
      const colorCategory = formatLabel(
        row[colorColName],
        colorColumn?.column.type,
        colorColumn?.bucket?.id,
      );

      let aggValue = row[aggColName] as number;
      if (zeroEmptyValues && !aggValue) aggValue = 0;

      const entry = isDate
        ? [row[xAxisColName], aggValue]
        : {
            name: String(row[xAxisColName]),
            y: aggValue,
          };
      if (series[colorCategory]) {
        series[colorCategory].data.push(entry);
      } else {
        series[colorCategory] = {
          type: this.getChartType(),
          name: colorCategory,
          rawColorData: row[colorColName],
          data: [entry],
          color: colorCategoryTracker[colorColName]?.[colorCategory],
        };
      }
    });

    const seriesData = Object.values(series);

    if (isDate && instructions?.chartSpecificFormat?.timeSeriesDataFormat?.hideLatestPeriodData) {
      seriesData.forEach((series) => series.data.pop());
      return seriesData;
    }

    if (area && instructions?.chartSpecificFormat?.areaChart?.reverseGroupOrder) {
      seriesData.reverse();
    }
    return seriesData;
  };

  transformAggColsData = (schema: DatasetSchema): SeriesOptions[] => {
    const { instructions, previewData, canUseMultiYAxis } = this.props;
    const xAxisColName = schema[0].name;
    const aggCols = instructions?.aggColumns || [];
    const aggColNames = schema.map((col) => col.name).slice(1);
    const isDate = DATE_TYPES.has(instructions?.categoryColumn?.column.type || '');
    const series: Record<string, SeriesOptions> = {};
    const zeroEmptyValues =
      instructions?.chartSpecificFormat?.timeSeriesDataFormat?.zeroMissingValues;

    formatTwoDimensionalData(previewData, instructions).forEach((row) => {
      aggColNames.forEach((colName, index) => {
        if (isDate && (row[xAxisColName] === undefined || isNaN(row[xAxisColName] as number)))
          return;
        const aggCol = aggCols[index];
        if (!aggCol) return;

        let aggValue = row[colName] as number;
        if (zeroEmptyValues && !aggValue) aggValue = 0;
        if (typeof aggValue !== 'number') return;

        const entry = isDate
          ? [row[xAxisColName], aggValue]
          : {
              name: String(row[xAxisColName]),
              y: aggValue,
            };
        if (series[colName]) {
          series[colName].data.push(entry);
        } else {
          series[colName] = {
            type: this.getChartType(),
            name: aggCol.column.friendly_name || getColDisplayText(aggCol) || colName,
            data: [entry],
            dataLabels: {
              formatter: function () {
                const { valueFormatId, decimalPlaces } = getValueFormat(
                  getYAxisFormatById(instructions?.yAxisFormats, aggCol.yAxisFormatId) ||
                    instructions?.yAxisFormats?.[0], // fallback to the globally set yAxisFormat
                );
                return formatValue({
                  value: this.y || 0,
                  decimalPlaces,
                  formatId: valueFormatId,
                  hasCommas: true,
                });
              },
            },
            yAxis: getYAxisChartIndex(aggCol.yAxisFormatId, canUseMultiYAxis, instructions),
          };
        }
      });
    });

    // Ensure stable legend sorting, not currently configurable
    return sortBy(Object.values(series), (s) => s.name);
  };
}

export { LineChart };
