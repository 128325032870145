import { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { sortBy } from 'utils/standard';

import Button from 'shared/Button';
import Poller from 'components/JobQueue/Poller';
import { DeleteMenu } from 'components/resource/DeleteMenu';
import { Spinner } from 'components/ds';

import { EmailCadence } from 'types/emailCadence';
import { getEmailCadence, getEmailScheduleText } from './utils';
import { Jobs } from 'components/JobQueue/types';
import { DEFAULT_SUPPORT_EMAIL } from 'constants/emailConstants';

const useStyles = makeStyles((theme: Theme) => ({
  infoText: {
    fontSize: 12,
    lineHeight: '15px',
    color: theme.palette.ds.grey900,
    marginBottom: theme.spacing(6),
  },
  footer: {
    marginTop: theme.spacing(8),
    display: 'flex',
    justifyContent: 'space-between',
  },
  noEmails: {
    height: 40,
    lineHeight: '40px',
    color: theme.palette.ds.grey700,
    fontSize: 14,
    fontWeight: 500,
  },
  emailList: {
    maxHeight: 180,
    overflowY: 'auto',
    margin: `0px -${theme.spacing(3)}px`,
  },
  email: {
    height: 40,
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    '&:hover': {
      backgroundColor: theme.palette.ds.grey100,
    },
  },
  emailSubject: {
    width: 200,
    lineHeight: '17px',
    fontWeight: 500,
    color: theme.palette.ds.black,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginRight: theme.spacing(8),
  },
  emailRecipients: {
    width: 100,
    color: theme.palette.ds.grey800,
    fontSize: 14,
    lineHeight: '17px',
    marginRight: theme.spacing(8),
  },
  menuButton: {
    cursor: 'pointer',
  },
  emailMenu: {
    '& .bp3-menu': {
      minWidth: 102,
      width: 102,
    },
    '& .bp3-menu-item:not(.bp3-intent-danger)': {
      color: theme.palette.ds.grey900,
      '&:hover': {
        backgroundColor: theme.palette.ds.lightBlue,
      },
    },
    '& .bp3-intent-danger': {
      color: theme.palette.ds.red,
      '&:hover': {
        color: theme.palette.ds.red,
        backgroundColor: theme.palette.ds.lightRed,
      },
    },
  },
  deletingEmail: {
    display: 'flex',
    alignItems: 'center',
    height: 30,
  },
  cancelDelete: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    marginRight: theme.spacing(1),
    height: '100%',
    fontSize: 14,
    backgroundColor: theme.palette.ds.grey300,
    color: theme.palette.ds.grey900,
    cursor: 'pointer',
  },
  deleteEmail: {
    height: '100%',
    width: 28,
    backgroundColor: theme.palette.ds.red,
    color: theme.palette.ds.white,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  emailCadence: {
    backgroundColor: theme.palette.ds.lightBlue,
    borderRadius: 12,
    padding: `0px ${theme.spacing(2)}px`,
    color: theme.palette.ds.blue,
    fontSize: 12,
    height: 24,
    lineHeight: '24px',
    marginRight: theme.spacing(2),
  },
  emailInfo: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  emailSchedule: {
    fontSize: 14,
    color: theme.palette.ds.grey800,
  },
}));

type Props = {
  emails: EmailCadence[];
  customerToken: string;
  closeModal: () => void;
  editEmail: (emailId: string) => void;
  deleteEmail: (emailId: string) => void;
  openScheduler: () => void;
  sendTestExport: (emailId: string, onSuccess: (jobs: Record<string, Jobs>) => void) => void;
  loading?: boolean;
};

export function ScheduleManager({
  loading,
  closeModal,
  editEmail,
  deleteEmail,
  emails,
  openScheduler,
  sendTestExport,
  customerToken,
}: Props): JSX.Element {
  const classes = useStyles();

  const [awaitedJobs, setAwaitedJobs] = useState<Record<string, Jobs>>({});
  const [loadingEmailId, setLoadingEmailId] = useState<string | undefined>();

  const hasJob = !!loadingEmailId || Object.keys(awaitedJobs).length > 0;

  const renderMenu = (emailId: string) => {
    return (
      <DeleteMenu
        deleteItem={() => deleteEmail(emailId)}
        disabled={hasJob}
        loading={hasJob && loadingEmailId === emailId}
        menuItems={[
          {
            icon: 'edit',
            name: 'Edit',
            onClick: () => editEmail(emailId),
          },
          {
            disabled: hasJob,
            icon: 'paper-plane',
            name: 'Send Test',
            onClick: () => {
              setLoadingEmailId(emailId);
              sendTestExport(emailId, setAwaitedJobs);
            },
          },
        ]}
      />
    );
  };

  return (
    <>
      <div className={classes.infoText}>
        Emails are sent from {DEFAULT_SUPPORT_EMAIL}. Remember to check your spam folder if you
        don&rsquo;t see it in your inbox. If you&rsquo;d like to configure this email, reach out to
        Explo support.
      </div>
      {loading ? (
        <Spinner fillContainer />
      ) : emails.length === 0 ? (
        <div className={classes.noEmails}>No Emails Scheduled</div>
      ) : (
        <div className={classes.emailList}>
          {sortBy(emails, 'subject').map((email) => {
            const numRecipients = email.recipients.length;
            return (
              <div className={classes.email} key={email.id}>
                <div className={classes.emailSubject}>{email.subject}</div>
                <div className={classes.emailRecipients}>{`${numRecipients} recipient${
                  numRecipients > 1 ? 's' : ''
                }`}</div>
                <div className={classes.emailInfo}>
                  <div className={classes.emailCadence}>{getEmailCadence(email)}</div>
                  <div className={classes.emailSchedule}>{getEmailScheduleText(email)}</div>
                </div>
                {renderMenu(email.id)}
              </div>
            );
          })}
        </div>
      )}
      <div className={classes.footer}>
        <Button icon="plus" onClick={openScheduler} text="Schedule an Email" type="secondary" />
        <Button onClick={closeModal} text="Done" type="primary" />
      </div>
      <Poller
        awaitedJobs={awaitedJobs}
        customerToken={customerToken}
        updateJobResult={(finishedJobIds, onComplete) => {
          if (finishedJobIds.length > 0) {
            setAwaitedJobs({});
            setLoadingEmailId(undefined);
          }
          onComplete();
        }}
      />
    </>
  );
}
