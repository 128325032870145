import { FC, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import ExploLoadingSpinner from 'components/ExploLoadingSpinner';
import { ReportBuilderPreview } from './Preview';
import { DatasetEditor } from './DatasetEditor';
import { sprinkles } from 'components/ds';
import { ReduxState } from 'reducers/rootReducer';

import { ReportBuilderBuiltIns } from 'pages/ReportBuilderEditor/BuiltIns';
import { loadFonts } from 'globalStyles/helpers';
import { GlobalStylesProvider } from 'globalStyles';
import { fetchReportBuilderVersions } from 'actions/reportBuilderVersionActions';
import { ReportBuilderConfig } from 'actions/reportBuilderConfigActions';
import { getCurrentReportBuilder } from 'reducers/reportBuilderReducer';
import { Header } from './Header';
import { isIdle, isSuccess } from 'remotedata';
import { fetchReportBuilders } from 'actions/reportBuilderActions';
import { VIEW_MODE } from './types';
import { clearReportBuilderReducer } from 'reducers/reportBuilderEditReducer';
import { useLoadEditMetadata } from 'utils/hookUtils';
import { ReportBuilderDev } from './ReportBuilderDev/ReportBuilderDev';

// TODO: Handle passing down to preview
type Props = { globalStylesClassName: string };

const containerClass = sprinkles({
  flexItems: 'column',
  height: 'fill',
  position: 'relative',
  overflow: 'hidden',
  widthConstraints: 'minOnly',
});

const ReportBuilderEditor: FC<Props> = () => {
  const dispatch = useDispatch();

  const { reportBuilderId, view } = useParams<{ reportBuilderId: string; view: VIEW_MODE }>();

  const metadataLoading = useLoadEditMetadata();

  const { reportBuilder, reportBuildersNotLoaded, config } = useSelector(
    (state: ReduxState) => ({
      reportBuilder: getCurrentReportBuilder(state.reportBuilder),
      reportBuildersNotLoaded: isIdle(state.reportBuilder.reportBuilders),
      config: state.reportBuilderEdit.config,
    }),
    shallowEqual,
  );

  useEffect(() => {
    return function clearEdit() {
      dispatch(clearReportBuilderReducer());
    };
  }, [dispatch]);

  useEffect(() => {
    const id = parseInt(reportBuilderId);

    if (!isNaN(id)) dispatch(fetchReportBuilderVersions({ id }));
  }, [dispatch, reportBuilderId]);

  useEffect(() => {
    if (reportBuildersNotLoaded) dispatch(fetchReportBuilders());
  }, [dispatch, reportBuildersNotLoaded]);

  const renderPage = (reportBuilderId: number, embedId: string, config: ReportBuilderConfig) => {
    // if dev mode not enabled for the account, default to the dataset view
    const viewToLoad =
      process.env.REACT_APP_ENVIRONMENT !== 'development' && view === VIEW_MODE.DEV
        ? VIEW_MODE.DATASETS
        : view;

    switch (viewToLoad) {
      case VIEW_MODE.DEV:
        return <ReportBuilderDev embedId={embedId} />;
      case VIEW_MODE.SETTINGS:
        return null;
      case VIEW_MODE.BUILT_INS:
        return <ReportBuilderBuiltIns />;
      case VIEW_MODE.PREVIEW:
        return <ReportBuilderPreview />;
      default:
        return <DatasetEditor config={config} reportBuilderId={reportBuilderId} />;
    }
  };

  const renderReportBuilderEditor = () => {
    if (!reportBuilder || !isSuccess(config) || metadataLoading) {
      return <ExploLoadingSpinner />;
    }

    return (
      <div className={containerClass}>
        <Header reportBuilder={reportBuilder} />
        <div className={sprinkles({ display: 'flex', flex: 1, width: 'fill', overflow: 'hidden' })}>
          {renderPage(reportBuilder.id, reportBuilder.embed_id, config.data)}
        </div>
      </div>
    );
  };

  return (
    <div className={sprinkles({ display: 'flex', parentContainer: 'fill', overflowX: 'auto' })}>
      {renderReportBuilderEditor()}
    </div>
  );
};

export const ReportBuilderEditorPage: FC = () => {
  const globalStyleConfig = useSelector(
    (state: ReduxState) => state.dashboardStyles.globalStyleConfig,
  );
  const team = useSelector((state: ReduxState) => state.currentUser?.team);

  useEffect(() => {
    if (team) loadFonts(globalStyleConfig.text, team.id, team.payment_plan);
  }, [globalStyleConfig, team]);

  return (
    <GlobalStylesProvider globalStyleConfig={globalStyleConfig}>
      {(globalStylesClassName) => (
        <ReportBuilderEditor globalStylesClassName={globalStylesClassName} />
      )}
    </GlobalStylesProvider>
  );
};
