import { defineAPIPostAction, defineAPIAction } from 'actions/actionUtils';
import { ACTION } from './types';
import { DataSource, ParentSchema, TableDataset } from 'actions/dataSourceActions';
import { Customer } from 'actions/teamActions';

type FetchDatasetsData = {
  datasets: TableDataset[];
  schema_id: number;
};

export const {
  actionFn: fetchDatasets,
  successAction: fetchDatasetsSuccess,
  errorAction: fetchDatasetsError,
} = defineAPIAction<FetchDatasetsData>(ACTION.FETCH_DATASETS, 'schema', 'tables', 'GET');

type FetchParentSchemasData = { parent_schemas: ParentSchema[] };

export const { actionFn: fetchUsedParentSchemas, successAction: fetchUsedParentSchemasSuccess } =
  defineAPIAction<FetchParentSchemasData>(
    ACTION.FETCH_USED_PARENT_SCHEMAS,
    'schema',
    'get_used_schemas',
    'GET',
  );

export const { actionFn: fetchAllParentSchemas } = defineAPIAction<FetchParentSchemasData>(
  ACTION.FETCH_ALL_PARENT_SCHEMAS,
  'schema',
  'get_all_schemas',
  'GET',
);

type FetchSchemaTablesMappingData = {
  schema_tables: { [schemaId: number]: TableDataset[] };
};

export const { actionFn: fetchAllSchemaTables, successAction: fetchAllSchemaTablesSuccess } =
  defineAPIAction<FetchSchemaTablesMappingData>(
    ACTION.FETCH_ALL_SCHEMA_TABLES_FOR_TEAM,
    'schema',
    'all_tables',
    'GET',
  );

type CreateParentSchemaData = {
  schema: ParentSchema;
};

export const { actionFn: createParentSchema } = defineAPIAction<CreateParentSchemaData>(
  ACTION.CREATE_PARENT_SCHEMA,
  'schema',
  'create_schema',
  'POST',
);

type SyncParentSchemaBody = {
  edited_schemas: ParentSchema[];
};

type SyncParentSchemaData = {
  parent_schemas: ParentSchema[];
  data_sources: DataSource[];
  customers: Customer[];
  _error: string;
};

export const { actionFn: syncParentSchema, successAction: syncParentSchemaSuccess } =
  defineAPIPostAction<SyncParentSchemaBody, SyncParentSchemaData>(
    ACTION.SYNC_PARENT_SCHEMA,
    'schema',
    'sync_schema',
    'POST',
  );
