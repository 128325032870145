import { useMemo, useState } from 'react';
import cx from 'classnames';
import * as styles from './styles.css';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { keyBy } from 'utils/standard';
import { sendVariableUpdatedEvent } from 'utils/customEventUtils';

import DashboardElementView from 'pages/dashboardPage/dashboardElement/dashboardElementView';
import { DahsboardStickyHeaderConfig, DashboardHeaderLocation } from 'types/dashboardVersionConfig';
import Button from 'shared/Button';
import { Icon } from 'components/ds';

import {
  DashboardElement,
  DashboardVariable,
  DashboardVariableMap,
  DASHBOARD_ELEMENT_TYPES,
  DASHBOARD_LAYOUT_CONFIG,
  PAGE_TYPE,
  VIEW_MODE,
} from 'types/dashboardTypes';
import { Layout } from 'react-grid-layout';
import { GlobalStyleConfig } from 'globalStyles/types';
import { AdHocOperationInstructions } from 'types/dataPanelTemplate';
import { UserTransformedSchema } from 'constants/types';
import { Customer } from 'actions/teamActions';
import { DataPanel, ResourceDataset } from 'types/exploResource';
import { SpreadsheetType } from 'reducers/dashboardLayoutReducer';

type Props = {
  applyFilters: (elementIds: string[]) => void;
  blockedVariables: DashboardVariableMap;
  config?: DahsboardStickyHeaderConfig;
  dashboardElements: DashboardElement[];
  dashboardLayout: Layout[];
  dataPanels: DataPanel[];
  datasets: Record<string, ResourceDataset>;
  disableInputsForDashboardLoad: boolean;
  disableInputs?: boolean;
  downloadDashboardImage: (email?: string) => void;
  downloadDashboardPdf: (email?: string) => void;
  editableDashboard: boolean;
  fetchDataPanelData: (dataPanel: DataPanel) => void;
  fetchShareData?: (password?: string, username?: string, isStrictViewingMode?: boolean) => void;
  globalStyleConfig: GlobalStyleConfig;
  hoverElementId?: string;
  isHeaderConfigSelected: boolean;
  isViewOnly: boolean;
  onAdHocOperationInstructionsUpdated: (
    dataPanelId: string,
    adHocOperationInstructions: AdHocOperationInstructions,
    skipRowCount?: boolean,
  ) => void;
  onDashboardItemSelect?: (type: DASHBOARD_ELEMENT_TYPES, id: string) => void;
  onDashboardLayoutStateSelected?: (layout: DASHBOARD_LAYOUT_CONFIG) => void;
  onDrop: (newLayout: Layout[], layoutItem: Layout, event: Event, containerId?: string) => void;
  onDownloadPanelPdf: (
    dataPanel: DataPanel,
    adHocOperationInstructions: AdHocOperationInstructions,
    email: string | undefined,
    userTransformedSchema?: UserTransformedSchema,
    reportName?: string,
  ) => void;
  onDownloadDataPanelSpreadsheet: (
    dataPanel: DataPanel,
    fileFormat: SpreadsheetType,
    email: string | undefined,
    userTransformedSchema?: UserTransformedSchema,
  ) => void;
  pageType: PAGE_TYPE;
  selectedDashboardItemId?: string;
  setVariable: (varName: string, value: DashboardVariable, elementId?: string) => void;
  shouldUseJobQueue?: boolean;
  supportEmail?: string;
  timezone: string;
  userGroup: Customer | undefined;
  variables: DashboardVariableMap;
  viewMode: VIEW_MODE;
};

export const DashboardStickyHeader = (props: Props) => {
  const {
    applyFilters,
    blockedVariables,
    config,
    dashboardElements,
    disableInputs,
    disableInputsForDashboardLoad,
    editableDashboard,
    hoverElementId,
    isHeaderConfigSelected,
    onDashboardItemSelect,
    onDashboardLayoutStateSelected,
    selectedDashboardItemId,
    setVariable,
    timezone,
    variables,
    viewMode,
  } = props;
  const [filtersOpen, setFiltersOpen] = useState(false);

  const elementsById = useMemo(() => keyBy(dashboardElements, 'id'), [dashboardElements]);

  if (!config?.enabled) return null;

  const isBelowHeader = config.filterLocations === DashboardHeaderLocation.BELOW_HEADER;

  // if 'belowHeader' is selected, whether there is a header or not, the filters are left aligned
  // so do 'flex-start'
  const filterRowContainer = cx(
    styles.filterRowContainer,
    isBelowHeader ? styles.filterRowContainerLeftAligned : styles.filterRowContainerRightAligned,
  );

  // If the header is disabled, we don't have to put the header below, but rather just left align it
  const filterRowBelowHeader = config.enabledExpandableFilterRow
    ? filtersOpen
    : isBelowHeader && !config.headerDisabled;

  const renderElem = (elem: DashboardElement, isVerticalStack?: boolean) => (
    <div
      className={cx({
        selected: selectedDashboardItemId === elem.id || hoverElementId === elem.id,
        [styles.hoverElement]: editableDashboard,
        [styles.verticalElemContainer]: isVerticalStack,
      })}
      key={`header-elem-${elem.id}`}
      onClick={(e) => {
        if (editableDashboard && selectedDashboardItemId !== elem.id) {
          onDashboardItemSelect?.(elem.element_type, elem.id);
        }
        e.stopPropagation();
      }}
      style={
        config.enableStretchFilters
          ? { flex: '1 0 auto', minWidth: styles.DASHBOARD_STICKY_HEADER_ELEM_WIDTH }
          : {
              minWidth: styles.DASHBOARD_STICKY_HEADER_ELEM_WIDTH,
              width: styles.DASHBOARD_STICKY_HEADER_ELEM_WIDTH,
            }
      }>
      <div className={styles.elementIdTag}>{elem.name}</div>
      <DashboardElementView
        elementStartsOnRightSide
        applyFilters={applyFilters}
        blockedElementIds={Object.keys(blockedVariables)}
        dashboardElement={elem}
        dashboardElementsById={elementsById}
        dashboardLayoutProps={{
          ...props,
        }}
        disableInputs={(disableInputsForDashboardLoad || disableInputs) ?? false}
        isArchitectCustomerDashboard={false}
        isDragging={false}
        isMobileView={false}
        isResizing={false}
        isSelected={false}
        onNewValueSelect={(newValue) => {
          sendVariableUpdatedEvent(elem.name, newValue);
          setVariable(elem.name, newValue, elem.id);
        }}
        portalId={undefined}
        timezone={timezone}
        value={elem.id in blockedVariables ? blockedVariables[elem.id] : variables[elem.name]}
        variables={variables}
        viewMode={viewMode}
      />
    </div>
  );

  const renderElemsRow = () => {
    if (!config.headerContentOrder) return <></>;

    return config.headerContentOrder.map((elemId) => {
      // in the off chance that an element ID is present in the config.headerContentOrder which has been removed from the dashboard
      // we check that it isn't undefined so that we don't error, as a fallback
      if (!elementsById[elemId]) return null;
      return renderElem(elementsById[elemId]);
    });
  };

  const getNumFiltersSet = () => {
    if (!config.headerContentOrder) return 0;

    return config.headerContentOrder.reduce(
      (total, elemId) => total + (variables[elementsById[elemId].name] === undefined ? 0 : 1),
      0,
    );
  };

  const elemsRow = renderElemsRow();

  const renderHeaderText = () => (
    <div
      className={cx(styles.headerText, {
        [styles.headerTextMobile]: viewMode === VIEW_MODE.MOBILE,
      })}
      style={{ color: config.headerTextColor, fontSize: config.headerTextSize }}>
      {config.headerName || 'Dashboard'}
    </div>
  );

  const numFiltersSet = getNumFiltersSet();
  const numFiltersSetText = numFiltersSet === 0 ? '' : `• ${numFiltersSet}`;

  const filterExpandButton = (
    <div
      className={cx(
        styles.filterRowContainer,
        isBelowHeader
          ? styles.filterRowContainerLeftAligned
          : styles.filterRowContainerRightAligned,
        styles.moreButtonRow,
      )}>
      <Button
        minimal
        className={styles.expandFiltersButton}
        icon={
          <Icon
            className={GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.color}
            name="filter-list"
            size="md"
          />
        }
        onClick={() => setFiltersOpen(!filtersOpen)}
        text={
          <div className={GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.color}>
            {filtersOpen
              ? `Hide Filters ${numFiltersSetText}`
              : `Show Filters ${numFiltersSetText}`}
          </div>
        }
      />
    </div>
  );

  const rightAlignedFilterRow = (
    <div className={filterRowContainer}>{!filterRowBelowHeader ? elemsRow : null}</div>
  );

  return (
    <div
      className={cx(styles.headerContainer, {
        [GLOBAL_STYLE_CLASSNAMES.container.outline.border]: !config.disableBottomBorder,
        [GLOBAL_STYLE_CLASSNAMES.container.shadow.dropShadow]: !config.disableBottomShadow,
        [styles.headerContainerSelected]: isHeaderConfigSelected,
        [styles.editableHeaderContainer]: editableDashboard,
      })}
      onClick={(e) => {
        if (editableDashboard) {
          onDashboardLayoutStateSelected?.(DASHBOARD_LAYOUT_CONFIG.HEADER);
        }
        e.stopPropagation();
      }}
      style={{ backgroundColor: config.backgroundColor || 'white' }}>
      <div
        className={cx(styles.headerMainContainer, {
          [styles.headerMainContainerFiltersBelow]:
            !config.enabledExpandableFilterRow && isBelowHeader && !config.headerDisabled,
        })}>
        {config.headerDisabled ? null : renderHeaderText()}
        {config.enabledExpandableFilterRow ? filterExpandButton : rightAlignedFilterRow}
      </div>
      {filterRowBelowHeader ? (
        <div
          className={cx(styles.filterRowContainer, styles.belowHeaderFilterRow, {
            [styles.filterRowExpanded]: config.enabledExpandableFilterRow,
            [GLOBAL_STYLE_CLASSNAMES.container.outline.border]: config.enabledExpandableFilterRow,
          })}>
          {elemsRow}
        </div>
      ) : null}
    </div>
  );
};
