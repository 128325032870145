import { FC } from 'react';
import { useSelector } from 'react-redux';

import { ConfirmChangeDatasetModal } from 'pages/ReportBuilder/ModalViews/ConfirmChangeDatasetModal';
import { ConfirmCloseReportModal } from 'pages/ReportBuilder/ModalViews/ConfirmCloseReportModal';
import { SaveBuiltInAsReportModal } from 'pages/ReportBuilder/ModalViews/SaveBuiltInAsReportModal';
import { DataSelectionModal } from 'pages/ReportBuilder/ModalViews/DataSelectionModal';
import { DatasetSelectionModal } from 'pages/ReportBuilder/ModalViews/DatasetSelectionModal';
import { EditBuiltInModal } from 'pages/ReportBuilder/ModalViews/EditBuiltInModal';
import { SaveAsModal } from 'pages/ReportBuilder/ModalViews/SaveAsModal';
import { ScheduleExportModal } from 'pages/ReportBuilder/ModalViews/ScheduleExportModal';

import { ReportModal } from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';

interface Props {
  reportName: string;
}

export const ModalViews: FC<Props> = ({ reportName }) => {
  const openModal = useSelector((state: ReportBuilderReduxState) => state.reportEditing.openModal);

  switch (openModal) {
    case ReportModal.SAVE_AS:
      return <SaveAsModal reportName={reportName} />;
    case ReportModal.CONFIRM_CHANGE_DATASET:
      return <ConfirmChangeDatasetModal />;
    case ReportModal.DATASET_SELECTION:
      return <DatasetSelectionModal />;
    case ReportModal.DATA_SELECTION:
      return <DataSelectionModal />;
    case ReportModal.SCHEDULE_EXPORT:
      return <ScheduleExportModal reportName={reportName} />;
    case ReportModal.EDIT_BUILT_IN:
      return <EditBuiltInModal />;
    case ReportModal.SAVE_BUILT_IN_AS_REPORT:
      return <SaveBuiltInAsReportModal />;
    case ReportModal.CONFIRM_CLOSE_REPORT:
      return <ConfirmCloseReportModal />;
    default:
      return null;
  }
};
