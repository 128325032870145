import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { IconName } from '@blueprintjs/icons';

import { InfoIcon } from 'components/InfoIcon';
import { sprinkles } from 'components/ds';

import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

const useStyles = makeStyles((theme: Theme) => ({
  label: (props: Props) => ({
    fontSize: props.overrideTextClass ? undefined : '12px',
    color: theme.palette.ds.grey900,
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    height: 16,

    '&.largeText': {
      fontSize: 14,
    },
  }),
  error: {
    color: `${theme.palette.ds.red} !important`,
    marginTop: theme.spacing(1),
    marginBottom: 0,
  },
}));

export type Props = {
  text: string;
  className?: string;
  error?: boolean;
  fakeLabel?: boolean;
  helpIcon?: IconName;
  helpText?: string | JSX.Element;
  iconColor?: string;
  largeText?: boolean;
  overrideTextClass?: string;
  ignoreCustomStyles?: boolean;
};

export default function InputLabel(props: Props) {
  const {
    error,
    text,
    className,
    fakeLabel,
    helpText,
    iconColor,
    largeText,
    ignoreCustomStyles,
    helpIcon,
    overrideTextClass,
  } = props;

  const classes = useStyles(props);

  return (
    <div
      className={cx(
        classes.label,
        {
          [classes.error]: error,
          [sprinkles({ visibility: 'hidden' })]: fakeLabel,
          largeText: largeText,
          [GLOBAL_STYLE_CLASSNAMES.text.smallBody.secondary]: !ignoreCustomStyles,
        },
        overrideTextClass,
        className,
      )}>
      <div className={sprinkles({ truncateText: 'ellipsis' })}>{fakeLabel ? '-' : text}</div>
      {helpText ? (
        <InfoIcon
          className={sprinkles({ marginLeft: 'sp.5' })}
          color={iconColor}
          icon={helpIcon}
          infoTooltipText={helpText}
        />
      ) : null}
    </div>
  );
}
