import Button from 'shared/Button';
import { useState } from 'react';
import validator from 'validator';
import { ControlGroup, FormGroup, Intent } from '@blueprintjs/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import InputGroup from 'explo-ds/forms/marketing/inputGroup';

import { ReduxState } from 'reducers/rootReducer';
import { inviteTeammates } from 'actions/teamActions';
import { showErrorContactSupportToast, showSuccessToast } from 'shared/sharedToasts';
import { DEFAULT_ROLES } from 'constants/roleConstants';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.ds.lightBlue,
    borderRadius: 4,
    marginBottom: theme.spacing(4),
    padding: theme.spacing(3),
  },
  controlGroup: {
    alignItems: 'flex-end',
  },
  formGroup: {
    flexBasis: '100%',
    marginBottom: 0,

    '& .bp3-label': {
      fontSize: 14,
      fontWeight: 600,
    },
  },
  submitBtn: {
    marginLeft: theme.spacing(2),
  },
}));

export default function InviteBox() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [textFieldVal, setTextFieldVal] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [loading, setLoading] = useState(false);

  const currentUser = useSelector((state: ReduxState) => state.currentUser);

  const handleSubmit = (email: string) => {
    if (!(textFieldVal.trim() && isValid && !loading)) return;

    if (email !== '' && validator.isEmail(email)) {
      if (!currentUser.team) return;
      setLoading(true);
      dispatch(
        inviteTeammates(
          {
            postData: {
              invites: [{ email, role_names: [DEFAULT_ROLES.ADMIN] }],
              team_id: currentUser.team.id,
              inviter: currentUser.first_name,
              resend: false,
            },
          },
          () => {
            showSuccessToast(`Invites sent successfully to ${email}`);
            setTextFieldVal('');
            setLoading(false);
          },
          (response) => {
            showErrorContactSupportToast(response.error_msg);
            setLoading(false);
          },
        ),
      );
    } else {
      setIsValid(false);
    }
  };

  return (
    <div className={classes.container}>
      <ControlGroup className={classes.controlGroup} fill={true}>
        <FormGroup
          className={classes.formGroup}
          helperText={isValid ? '' : 'Invalid Email'}
          intent={isValid ? Intent.NONE : Intent.DANGER}
          label="Invite your teammates"
          labelFor="text-input">
          <InputGroup
            intent={isValid ? Intent.NONE : Intent.DANGER}
            onInputChange={(value) => {
              setIsValid(true);
              setTextFieldVal(value);
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') handleSubmit(textFieldVal);
            }}
            placeholder="name@domain.com"
            value={textFieldVal}
          />
        </FormGroup>
        <Button
          className={classes.submitBtn}
          disabled={!textFieldVal.trim() || !isValid}
          icon="send-message"
          loading={loading}
          onClick={() => handleSubmit(textFieldVal)}
          style={{ flexGrow: 0, marginBottom: !isValid ? 20 : 0 }}
          type="primary"
        />
      </ControlGroup>
    </div>
  );
}
