import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import { FocusStyleManager } from '@blueprintjs/core';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import 'globalthis/auto'; // Polyfill for Radix on browsers without globalThis

import configureStore from './store/store';

import App from './App';
import theme from './theme';
import { baseThemeName, APP_PORTAL_ID } from 'components/ds';
import { initDataDog } from 'analytics/datadog';

initDataDog('app');
FocusStyleManager.onlyShowFocusOnTabs();

const MainApp = () => {
  return (
    <div className={baseThemeName} id={APP_PORTAL_ID} style={{ fontFamily: 'Inter, sans-serif' }}>
      <Provider store={configureStore()}>
        <ThemeProvider theme={theme}>
          <DndProvider backend={HTML5Backend}>
            <App />
          </DndProvider>
        </ThemeProvider>
      </Provider>
    </div>
  );
};

ReactDOM.render(<MainApp />, document.getElementById('root'));
