import { useState } from 'react';
import { Theme, withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import ReactQuill from '@explo-tech/react-quill';

import DropdownSelect from 'shared/DropdownSelect';
import InputGroup from 'explo-ds/forms/marketing/inputGroup';
import Button from 'shared/Button';
import { Icon } from '@blueprintjs/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(0.5),
      width: '66%',
      backgroundColor: theme.palette.ds.white,
      boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.12)',
      borderRadius: theme.spacing(1),

      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    dropdownContainer: {
      flexGrow: 1,
      border: `1px solid ${theme.palette.ds.grey400}`,
      borderTopLeftRadius: theme.spacing(1),
      borderBottomLeftRadius: theme.spacing(1),
    },
    textContainer: {
      flexGrow: 3,
      border: `1px solid ${theme.palette.ds.grey400}`,
      borderTopRightRadius: theme.spacing(1),
      borderBottomRightRadius: theme.spacing(1),
    },
    textInput: {
      border: 'none',
    },
    buttonContainer: {
      marginLeft: theme.spacing(1),
    },
  });

type PassedProps = { quill: ReactQuill | null; closeInput: () => void };

type Props = PassedProps & WithStyles<typeof styles>;

const ImageVariableInputToolbar = ({ classes, quill, closeInput }: Props) => {
  const [inputType, setInputType] = useState({ id: 'URL', name: 'URL' });
  const [inputValue, setInputValue] = useState('');

  return (
    <div className={classes.root}>
      <DropdownSelect
        fillWidth
        ignoreCustomStyles
        minimal
        showIcon
        buttonStyle={{ border: 'none' }}
        containerClassName={classes.dropdownContainer}
        filterable={false}
        noSelectionText="Select input type"
        onChange={(item) => {
          setInputType(item);
        }}
        options={[
          { id: 'URL', name: 'URL' },
          { id: 'Variable', name: 'Variable' },
        ]}
        selectedItem={inputType}
      />
      <InputGroup
        className={classes.textContainer}
        onInputChange={setInputValue}
        placeholder={inputType.name === 'URL' ? 'Link to your image' : 'e.g. end_user.header_image'}
        value={inputValue}
      />
      <Button
        ignoreCustomStyles
        className={classes.buttonContainer}
        icon={<Icon icon={inputValue ? 'tick' : 'cross'} />}
        onClick={() => {
          if (!inputValue) closeInput();

          if (quill) {
            const editor = quill.getEditor();
            editor.insertEmbed(
              editor.getSelection()?.index ?? 0,
              'emailImage',
              inputType.name === 'URL'
                ? { src: inputValue }
                : {
                    src: 'https://explo-email.s3.us-west-1.amazonaws.com/placeholder+image.png',
                    explo_variable: `{{ ${inputValue} }}`,
                  },
            );
          }

          closeInput();
        }}
        type={inputValue ? 'primary' : 'secondary'}
      />
    </div>
  );
};

export default withStyles(styles)(ImageVariableInputToolbar);
