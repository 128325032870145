import { makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import { sprinkles } from 'components/ds';

import { Icon } from '@blueprintjs/core';
import DropdownSelect from 'shared/DropdownSelect';
import Button from 'shared/Button';
import OperatorDropdown from './OperatorDropdown';
import ValueInput from './ValueInput';

import { FilterValueType } from 'constants/types';
import { DatasetSchema, DatasetColumn } from 'types/datasets';
import { FilterOperator } from 'types/filterOperations';
import { createColOptionsWithIcon, createColOptionWithIconFromCol } from 'utils/general';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    '& .bp3-button': {
      borderRadius: 4,
      boxShadow: 'none !important',
      backgroundColor: theme.palette.ds.white,
      color: theme.palette.ds.black,
      height: 32,
      minHeight: 18,
      minWidth: 18,
    },
    '& .bp3-button .bp3-icon, .bp3-button .bp3-icon-standard, .bp3-button .bp3-icon-large': {
      color: `${theme.palette.ds.grey800} !important`,
    },
    '& .bp3-button-text': {
      color: `${theme.palette.ds.grey800}`,
    },
  },
  columnOrOperatorOption: {
    display: 'flex',
    marginRight: theme.spacing(2),
    '& .bp3-button': {
      border: `1px solid ${theme.palette.ds.white}`,
      fontSize: 12,
    },
    '& .bp3-input-group .bp3-input': {
      border: `1px solid ${theme.palette.ds.white}`,
    },
  },
  operatorAndValueContainer: {
    width: 300,
    display: 'flex',
  },
}));

export type Props = {
  baseSchema: DatasetSchema;
  deleteDisabled?: boolean;
  selectedColumn?: DatasetColumn;
  selectedOperator?: FilterOperator;
  filterValue?: FilterValueType;
  onColumnSelect: (column: DatasetColumn) => void;
  onOperatorSelect: (operator: FilterOperator) => void;
  onFilterValueUpdate: (value: FilterValueType) => void;
  onDelete: () => void;
  adHocFilterSettings?: boolean;
};

export default function FilterRow({
  onDelete,
  deleteDisabled,
  baseSchema,
  onColumnSelect,
  ...props
}: Props) {
  const classes = useStyles();
  const theme: Theme = useTheme();

  const selectedColumn = props.selectedColumn;
  const longestColumnLength = Math.max(
    6, //length of 'Column'
    ...baseSchema.map((col) => (col.friendly_name || col.name).length),
  );

  return (
    <div className={classes.container}>
      <Button
        minimal
        className={sprinkles({ marginRight: 'sp.5' })}
        disabled={deleteDisabled}
        icon={<Icon color={theme.palette.ds.grey800} icon="cross" iconSize={12} />}
        onClick={onDelete}
      />
      <span className={classes.columnOrOperatorOption}>
        <DropdownSelect
          fillWidth
          minimal
          showIcon
          buttonStyle={{
            // Adding 5 because of icon
            width: `${Math.min(longestColumnLength + 5, 25)}ch`,
          }}
          containerClassName={sprinkles({ width: 'fill' })}
          filterable={false}
          noSelectionText="Column"
          onChange={(item) => {
            const column = baseSchema.find((col) => col.name === item.id);
            column && onColumnSelect(column);
          }}
          options={createColOptionsWithIcon(baseSchema, true)}
          selectedItem={
            selectedColumn ? createColOptionWithIconFromCol(selectedColumn, true) : undefined
          }
        />
      </span>
      <span className={classes.operatorAndValueContainer}>
        <OperatorDropdown className={classes.columnOrOperatorOption} {...props} />
        <ValueInput {...props} />
      </span>
    </div>
  );
}
