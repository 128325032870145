import { Menu, MenuItem } from '@blueprintjs/core';
import { useHistory } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import {
  SETTINGS_LEFT_MENU_SECTIONS,
  SETTINGS_SUB_SECTIONS_PERMISSION_MAP,
  SUB_SECTION_ROUTE_MAP,
} from 'constants/settingsPageConstants';
import { ReduxState } from 'reducers/rootReducer';

export const SETTINGS_LEFT_NAV_WIDTH = 280;

const useStyles = makeStyles((theme: Theme) => ({
  sidePanel: {
    borderRight: `1px solid ${theme.palette.ds.grey500}`,
    height: '100%',
    width: SETTINGS_LEFT_NAV_WIDTH,
  },
  header: {
    fontSize: 20,
    fontWeight: 600,
    marginLeft: theme.spacing(6),
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(6),
  },
  section: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  sectionName: {
    fontWeight: 600,
    color: theme.palette.ds.grey700,
    padding: '5px 7px', // to match blueprint MenuItem
    marginBottom: theme.spacing(1),
  },
  subsection: {
    fontWeight: 400,
    color: theme.palette.ds.grey700,
    borderRadius: 4,
  },
  selectedSubsection: {
    fontWeight: 400,
    color: theme.palette.ds.blue,
    backgroundColor: theme.palette.ds.lightBlue,
  },
}));

type Props = {
  selectedSubSection?: string;
};

export default function SettingsLeftNav(props: Props) {
  const classes = useStyles();
  const history = useHistory();

  const permissions = useSelector((state: ReduxState) => state.currentUser.permissions);

  return (
    <div className={classes.sidePanel}>
      <div className={classes.header}>Settings</div>
      <div>
        {SETTINGS_LEFT_MENU_SECTIONS.map((section) => {
          const subSections: JSX.Element[] = [];

          section.subsections.forEach((subsection) => {
            const permissionFn = SETTINGS_SUB_SECTIONS_PERMISSION_MAP[subsection];

            if (permissionFn && !permissionFn(permissions)) return;

            subSections.push(
              <div
                className="mdl-button mdl-js-button mdl-button--raised"
                key={`setting-subsection-${subsection}`}>
                <MenuItem
                  className={
                    SUB_SECTION_ROUTE_MAP[subsection] === props.selectedSubSection
                      ? classes.selectedSubsection
                      : classes.subsection
                  }
                  onClick={() => history.push(`/settings/${SUB_SECTION_ROUTE_MAP[subsection]}`)}
                  text={subsection}
                />
              </div>,
            );
          });

          if (subSections.length === 0) return null;

          return (
            <Menu key={section.groupName}>
              <div className={classes.section}>
                <div className={classes.sectionName}>{section.groupName}</div>
                {subSections}
              </div>
            </Menu>
          );
        })}
      </div>
    </div>
  );
}
