import { createReducer } from '@reduxjs/toolkit';

import {
  activateDisabledDashboardSuccess,
  DashboardTemplate,
  createDashboardTemplateSuccess,
  cloneDashboardTemplateSuccess,
  deleteDashboardTemplateSuccess,
  fetchDashboardTemplateSuccess,
  fetchDashboardTemplateError,
  fetchDashboardTemplateListSuccess,
  renameDashboardTemplateSuccess,
  saveShareLinkTitleRequest,
  updateDashboardDefaultTimezoneSuccess,
  updateDisableFiltersWhileLoadingSuccess,
  updateDashboardSyncCategoryColorsSuccess,
  updateDashboardCacheConfigSuccess,
} from 'actions/dashboardTemplateActions';
import { ErrorResponse } from 'actions/responseTypes';
import { assignDashboardValueSuccess } from 'actions/dashboardAttributesActions';

interface DashboardReducerState {
  currentDashboardTemplateId?: number;
  dashboardTemplateList?: DashboardTemplate[];
  error?: ErrorResponse;
}

const dashboardReducerInitialState: DashboardReducerState = {};

const updateDashboardList = (
  state: DashboardReducerState,
  dashboardId: number | string | undefined | null,
  updateFunc: (dashboard: DashboardTemplate) => void,
): void => {
  if (!state.dashboardTemplateList) return;

  const dashboardTemplate = state.dashboardTemplateList.find((elem) => elem.id === dashboardId);

  if (dashboardTemplate) updateFunc(dashboardTemplate);
};

export default createReducer(dashboardReducerInitialState, (builder) => {
  builder
    .addCase(createDashboardTemplateSuccess, (state, { payload }) => {
      if (!state.dashboardTemplateList) return;

      state.currentDashboardTemplateId = payload.new_dashboard_template.id;
      state.dashboardTemplateList.push(payload.new_dashboard_template);
    })
    .addCase(cloneDashboardTemplateSuccess, (state, { payload }) => {
      if (!state.dashboardTemplateList) return;

      state.currentDashboardTemplateId = payload.new_dashboard_template.id;
      state.dashboardTemplateList.push(payload.new_dashboard_template);
    })
    .addCase(deleteDashboardTemplateSuccess, (state, { payload }) => {
      if (!state.dashboardTemplateList) return;

      const deletedIndex = state.dashboardTemplateList.findIndex((elem) => elem.id === payload.id);
      if (deletedIndex >= 0) state.dashboardTemplateList.splice(deletedIndex, 1);
    })
    .addCase(activateDisabledDashboardSuccess, (state, { payload }) => {
      if (!state.dashboardTemplateList) return;

      const activateIndex = state.dashboardTemplateList.findIndex((elem) => elem.id === payload.id);
      if (activateIndex >= 0) state.dashboardTemplateList[activateIndex].disabled = false;

      const deactivateIndex = state.dashboardTemplateList.findIndex(
        (elem) => elem.id === payload.postData.disable_dashboard_id,
      );
      if (deactivateIndex >= 0) state.dashboardTemplateList[deactivateIndex].disabled = true;
    })
    .addCase(fetchDashboardTemplateSuccess, (state, { payload }) => {
      state.currentDashboardTemplateId = payload.dashboard_template_id;
      state.error = undefined;
    })
    .addCase(fetchDashboardTemplateError, (state, { payload }) => {
      state.error = payload.errorData;
    })
    .addCase(fetchDashboardTemplateListSuccess, (state, { payload }) => {
      state.dashboardTemplateList = payload.dashboard_template_list;
    })
    .addCase(renameDashboardTemplateSuccess, (state, { payload }) => {
      updateDashboardList(state, payload.id, (dashboard) => {
        dashboard.name = payload.name;
      });
    })
    .addCase(saveShareLinkTitleRequest, (state, { payload }) => {
      updateDashboardList(state, payload.id, (dashboard) => {
        dashboard.share_link_title = payload.postData.share_link_title;
      });
    })
    .addCase(updateDashboardDefaultTimezoneSuccess, (state, { payload }) => {
      updateDashboardList(state, payload.id, (dashboard) => {
        dashboard.default_timezone = payload.postData.default_timezone;
      });
    })
    .addCase(updateDisableFiltersWhileLoadingSuccess, (state, { payload }) => {
      updateDashboardList(state, payload.id, (dashboard) => {
        dashboard.disable_filters_while_loading = payload.postData.disable_filters_while_loading;
      });
    })
    .addCase(updateDashboardSyncCategoryColorsSuccess, (state, { payload }) => {
      updateDashboardList(state, payload.id, (dashboard) => {
        dashboard.sync_category_colors = payload.postData.sync_category_colors;
      });
    })
    .addCase(updateDashboardCacheConfigSuccess, (state, { payload }) => {
      updateDashboardList(state, payload.id, (dashboard) => {
        if (payload.postData.is_cache_enabled !== undefined)
          dashboard.is_cache_enabled = payload.postData.is_cache_enabled;
        if (payload.postData.cache_cron !== undefined)
          dashboard.cache_cron = payload.postData.cache_cron;
      });
    })
    .addCase(assignDashboardValueSuccess, (state, { payload }) => {
      const { value_id, attribute_id, template_id } = payload.postData;
      updateDashboardList(state, template_id, (template) => {
        const newAttributes = template.dashboard_attributes.filter(
          (elem) => elem.attribute_id !== attribute_id,
        );
        if (value_id !== '') newAttributes.push({ attribute_id, value_id: parseInt(value_id) });
        template.dashboard_attributes = newAttributes;
      });
    })
    .addDefaultCase((state) => state);
});
