import { FC } from 'react';

import FlexBox from 'components/core/FlexBox';
import GoalInputRow from './GoalInputRow';
import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';
import GradientColorPicker from './GradientColorPicker';
import { sprinkles, Switch } from 'components/ds';

import { NumberDisplayOptions, GradientType, GradientPointType } from 'constants/types';
import { DEFAULT_GRADIENT } from 'constants/dataPanelEditorConstants';

/**
 * According to style spec here: https://www.figma.com/file/JnQKSyP0S1iYcvKVBChu8Z/Table-Component?node-id=911%3A4475
 */
const POINT_CONFIGURATION_FLEX = 3.33;

const inputClass = sprinkles({ marginTop: 'sp1' });

type Props = {
  updateNumberOptions: (newFields: NumberDisplayOptions) => void;
  displayOptions: NumberDisplayOptions | undefined;
};

export const GradientConfiguration: FC<Props> = ({ updateNumberOptions, displayOptions }) => {
  const { gradientType, gradient, gradientOptions } = displayOptions ?? {};

  const isGradientEnabled = gradientType && gradientType !== GradientType.NONE;
  const { hue1, hue2, hue3 } = {
    hue1: gradient?.hue1 || DEFAULT_GRADIENT.hue1,
    hue2: gradient?.hue2 || DEFAULT_GRADIENT.hue2,
    hue3: gradient?.hue3 || DEFAULT_GRADIENT.hue3,
  };
  const { maxpoint, midpoint, minpoint } = gradientOptions ?? {};

  const getOnHueChanged = (hue: 'hue1' | 'hue2' | 'hue3') => (newHue: string) => {
    updateNumberOptions({ gradient: { ...gradient, [hue]: newHue } });
  };

  return (
    <>
      <Switch
        label="Gradient"
        onChange={() => {
          updateNumberOptions({
            gradientType: isGradientEnabled ? GradientType.NONE : GradientType.LINEAR,
          });
        }}
        switchOn={isGradientEnabled}
      />
      {isGradientEnabled && (
        <>
          <ToggleButtonGroup fillWidth className={inputClass}>
            <ToggleButton
              active={gradientType === GradientType.LINEAR}
              onClick={() => updateNumberOptions({ gradientType: GradientType.LINEAR })}
              text="Linear"
            />
            <ToggleButton
              active={gradientType === GradientType.DIVERGING}
              onClick={() => updateNumberOptions({ gradientType: GradientType.DIVERGING })}
              text="Diverging"
            />
          </ToggleButtonGroup>
          <FlexBox>
            <GradientColorPicker
              className={inputClass}
              hues={gradientType === GradientType.LINEAR ? [hue1, hue3] : [hue1, hue2, hue3]}
              onHuesChanged={
                gradientType === GradientType.LINEAR
                  ? [getOnHueChanged('hue1'), getOnHueChanged('hue3')]
                  : [getOnHueChanged('hue1'), getOnHueChanged('hue2'), getOnHueChanged('hue3')]
              }
            />
            <div className={sprinkles({ marginLeft: 'sp1' })}>
              <GoalInputRow
                exactValue={minpoint?.number}
                flex={POINT_CONFIGURATION_FLEX}
                type="min"
                updateExactValue={(newValue) =>
                  updateNumberOptions({
                    gradientOptions: {
                      ...gradientOptions,
                      minpoint: { ...minpoint, number: newValue },
                    },
                  })
                }
                updateUsesColumnCalculation={(newValue) =>
                  updateNumberOptions({
                    gradientOptions: {
                      ...gradientOptions,
                      minpoint: {
                        ...minpoint,
                        type: newValue ? GradientPointType.COMPUTED : GradientPointType.NUMBER,
                      },
                    },
                  })
                }
                usesColumnCalculation={minpoint?.type !== GradientPointType.NUMBER}
              />
              {gradientType === GradientType.DIVERGING && (
                <GoalInputRow
                  exactValue={midpoint?.number}
                  flex={POINT_CONFIGURATION_FLEX}
                  type="mid"
                  updateExactValue={(newValue) =>
                    updateNumberOptions({
                      gradientOptions: {
                        ...gradientOptions,
                        midpoint: {
                          ...midpoint,
                          number: newValue,
                        },
                      },
                    })
                  }
                  updateUsesColumnCalculation={(newValue) =>
                    updateNumberOptions({
                      gradientOptions: {
                        ...gradientOptions,
                        midpoint: {
                          ...midpoint,
                          type: newValue ? GradientPointType.COMPUTED : GradientPointType.NUMBER,
                        },
                      },
                    })
                  }
                  usesColumnCalculation={midpoint?.type !== GradientPointType.NUMBER}
                />
              )}
              <GoalInputRow
                exactValue={maxpoint?.number}
                flex={POINT_CONFIGURATION_FLEX}
                type="max"
                updateExactValue={(newValue) =>
                  updateNumberOptions({
                    gradientOptions: {
                      ...gradientOptions,
                      maxpoint: {
                        ...maxpoint,
                        number: newValue,
                      },
                    },
                  })
                }
                updateUsesColumnCalculation={(newValue) =>
                  updateNumberOptions({
                    gradientOptions: {
                      ...gradientOptions,
                      maxpoint: {
                        ...maxpoint,
                        type: newValue ? GradientPointType.COMPUTED : GradientPointType.NUMBER,
                      },
                    },
                  })
                }
                usesColumnCalculation={maxpoint?.type !== GradientPointType.NUMBER}
              />
            </div>
          </FlexBox>
        </>
      )}
    </>
  );
};
