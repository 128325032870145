import { FC, useState } from 'react';

import { ApplyFilterRow } from './ApplyFilterRow';
import { FilterDropdown } from './FilterDropdown';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import * as styles from './styles.css';
import { sprinkles } from 'components/ds';

import { CustomerReportFilter } from 'actions/customerReportActions';
import { FilterableColumn, getFilterDefaultOperation } from 'utils/customerReportUtils';
import {
  FILTER_OPERATORS,
  FilterOperator,
  FILTER_OPERATOR_TYPES_BY_ID,
  FILTER_OPS_NO_VALUE,
  FILTER_OPS_RELATIVE_PICKER,
} from 'types/filterOperations';
import { FilterValueRelativeDateType } from 'constants/types';
import { newOperatorShouldClearSelectedVariable } from 'utils/dashboardUtils';
import {
  DATE_RELATIVE_OPTION,
  DATE_RELATIVE_OPTIONS,
  DATE_RELATIVE_OPTIONS_BY_ID,
} from 'constants/dataPanelEditorConstants';
import { DATE, DATETIME } from 'constants/dataConstants';

type Props = {
  clause?: CustomerReportFilter;
  column: FilterableColumn;
  holdOpen: (hold: boolean) => void;
};

const dateOptions = FILTER_OPERATORS.filter(
  (op) =>
    (op.supported_column_types.has(DATE) || op.supported_column_types.has(DATETIME)) &&
    (FILTER_OPS_RELATIVE_PICKER.has(op.id) || FILTER_OPS_NO_VALUE.has(op.id)),
);

export const DateFilterPopover: FC<Props> = ({ column, clause, holdOpen }) => {
  const [currOperator, setOperator] = useState(
    getFilterDefaultOperation(column.type, clause?.filterOperation.id),
  );
  const [value, setValue] = useState(
    clause?.filterValue as FilterValueRelativeDateType | undefined,
  );

  const operator = FILTER_OPERATOR_TYPES_BY_ID[currOperator];

  const renderContent = () => {
    if (FILTER_OPS_NO_VALUE.has(operator.id)) return null;
    if (FILTER_OPS_RELATIVE_PICKER.has(operator.id)) {
      const relativeValue = (value as FilterValueRelativeDateType | undefined) ?? {};
      const selectedRelative = relativeValue.relativeTimeType
        ? DATE_RELATIVE_OPTIONS_BY_ID[relativeValue.relativeTimeType.id]
        : undefined;

      return (
        <div className={styles.relativeContent}>
          <div className={sprinkles({ flex: 1 })}>
            <EmbedText body="b3">Number</EmbedText>
            <input
              className={styles.valueInput}
              onChange={(e) => {
                const newValue = e.target.value;
                if (newValue === '') return setValue({ ...relativeValue, number: undefined });

                const newVal = Number(newValue);
                if (isNaN(newVal)) return;
                setValue({ ...relativeValue, number: newVal });
              }}
              placeholder="Number"
              value={relativeValue.number === undefined ? '' : relativeValue.number}
            />
          </div>
          <div className={sprinkles({ flex: 1 })}>
            <EmbedText body="b3">Time Type</EmbedText>
            <FilterDropdown
              items={DATE_RELATIVE_OPTIONS}
              onOpenChange={holdOpen}
              onSelect={(relativeType) =>
                setValue({
                  ...relativeValue,
                  relativeTimeType: { id: relativeType as DATE_RELATIVE_OPTION },
                })
              }
              placeholder="Select a Time"
              selectedItem={selectedRelative}
            />
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={styles.dateContent}>
      <FilterDropdown
        items={dateOptions}
        onOpenChange={holdOpen}
        onSelect={(id) => {
          const newOperator = id as FilterOperator;
          if (newOperatorShouldClearSelectedVariable(newOperator, currOperator)) {
            setValue(undefined);
          }
          setOperator(id as FilterOperator);
        }}
        selectedItem={{ id: operator.id, name: operator.selectionValue }}
        triggerClassName={sprinkles({ marginX: 'sp2' })}
      />
      {renderContent()}
      <ApplyFilterRow
        className={sprinkles({ paddingX: 'sp2' })}
        clause={clause}
        column={column}
        operator={operator.id}
        value={value}
      />
    </div>
  );
};
