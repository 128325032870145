import { makeStyles, Theme } from '@material-ui/core/styles';
import { useDrag } from 'react-dnd';
import { Icon } from '@blueprintjs/core';

import { SCHEMA_DATA_TYPES_BY_ID } from 'constants/dataConstants';
import { ItemTypes } from 'constants/dragAndDrop';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    borderRadius: 3,

    '&:hover': {
      backgroundColor: theme.palette.ds.lightBlue,
      cursor: 'pointer',
    },
  },
  name: {
    marginLeft: theme.spacing(2),
  },
}));

type Props = {
  name: string;
  type: string;
  disableDrag?: boolean;
  displayName: string;
};

export default function DraggableColumn({ name, type, disableDrag, displayName }: Props) {
  const classes = useStyles();
  const dragData = useDrag({
    item: { type: ItemTypes.CONFIGURATION_COLUMN, data: { name, type } },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    canDrag: !disableDrag,
  });

  return (
    <div className={classes.root} ref={dragData[1]}>
      <Icon icon={SCHEMA_DATA_TYPES_BY_ID[type].icon} />
      {/* setting html here should be fine because it's coming from the column name */}
      <div className={classes.name} dangerouslySetInnerHTML={{ __html: displayName }} />
    </div>
  );
}
