import XRegExp from 'xregexp';
import { cloneDeep } from 'utils/standard';

import {
  CanvasDataset,
  CanvasCustomComponent,
  CanvasFilter,
  canvasFilterOptions,
  CanvasFilterType,
  CanvasTemplate,
  CanvasVersionConfig,
} from 'actions/canvasConfigActions';
import { BASE_CONFIG_BY_DASH_ELEM } from 'constants/dashboardConstants';
import { DatasetSchema } from 'types/datasets';
import { OPERATION_TYPES } from 'constants/types';
import {
  DashboardElement,
  DashboardVariableMap,
  DASHBOARD_ELEMENT_TYPES,
  DropdownValuesConfig,
} from 'types/dashboardTypes';
import { DataPanel, ResourceDataset } from 'types/exploResource';
import { isCanvasDataset } from './exploResourceUtils';
import { getNumOfElem } from './naming';
import { variableRegex } from './dataPanelConfigUtils';
import { getDefaultVariablesFromDashElements } from './variableUtils';
import { removeUnderscoreFields } from './dashboardUtils';
import { getSelectFilterDatasetId } from './filterUtils';

export function getNextElementNumber(
  startStr: string,
  elements: Record<string, { provided_id: string }>,
): number {
  let nextNum = 0;
  Object.values(elements).forEach(({ provided_id }) => {
    const num = getNumOfElem(provided_id, startStr);
    if (num > nextNum) nextNum = num;
  });
  return nextNum + 1;
}

export function createCanvasFilter(filterType: DASHBOARD_ELEMENT_TYPES): CanvasFilterType | null {
  if (!canvasFilterOptions.includes(filterType)) return null;

  // Ideally we move all BASE_CONFIG_BY_DASH_ELEM to a function cleaner
  // like this one. But putting this here for now
  return {
    filter_type: filterType,
    config: BASE_CONFIG_BY_DASH_ELEM[filterType],
  } as CanvasFilterType;
}

export function getCanvasDashboardElements(
  config: CanvasVersionConfig,
  elemsInLayout?: Set<string>,
): DashboardElement[] {
  let filters = Object.values(config.filters);
  let components = Object.values(config.customComponents ?? {});
  if (elemsInLayout) {
    filters = filters.filter((filter) => elemsInLayout.has(filter.id));
    components = components.filter((comp) => elemsInLayout.has(comp.id));
  }
  return canvasFiltersToDashboardElements(filters).concat(
    canvasComponentsToDashboardElements(components),
  );
}

export function canvasFiltersToDashboardElements(filters: CanvasFilter[]): DashboardElement[] {
  const dashboardElements: DashboardElement[] = [];
  filters.forEach((filter) => {
    if (filter.filter_info !== null) {
      dashboardElements.push({
        id: filter.id,
        name: filter.provided_id,
        element_type: filter.filter_info.filter_type,
        config: filter.filter_info.config,
      });
    }
  });
  return dashboardElements;
}

export function canvasComponentsToDashboardElements(
  components: CanvasCustomComponent[],
): DashboardElement[] {
  const dashboardElements: DashboardElement[] = [];
  components.forEach((comp) => {
    if (!comp.info.config.iframeUrl) return;

    dashboardElements.push({
      id: comp.id,
      name: comp.name,
      element_type: comp.info.elementType,
      config: comp.info.config,
    });
  });
  return dashboardElements;
}

export function getFilterReferences(datasets: CanvasDataset[]): Record<string, string[]> {
  const filterReferences: Record<string, string[]> = {};
  datasets.forEach((dataset) => {
    if (!dataset.schema || dataset.schema.length === 0 || dataset.query.trim() === '') return;
    XRegExp.forEach(dataset.query, variableRegex, (match) => {
      let varName = match[2]?.trim();
      if (varName.includes('.')) varName = varName.split('.')[0];

      if (varName in filterReferences) filterReferences[varName].push(dataset.id);
      else filterReferences[varName] = [dataset.id];
    });
  });
  return filterReferences;
}

export const getSchemaForPreviewTable = (
  schema: DatasetSchema | undefined,
  dataset: CanvasDataset,
): DatasetSchema => {
  if (!schema) return [];

  const changedSchema: DatasetSchema = [];
  schema.forEach((col) => {
    const columnOption = dataset.columnOptions[col.name];
    // Show columns not in column option in case query hasn't been saved yet
    if (!columnOption) {
      changedSchema.push({ ...col });
    } else if (columnOption.isVisible) {
      changedSchema.push({ ...col, friendly_name: columnOption.name });
    }
  });
  return changedSchema;
};

export function canvasDataPanelColumnsError(
  { visualize_op }: DataPanel,
  dataset: ResourceDataset,
): string | null {
  if (!isCanvasDataset(dataset)) return null;

  switch (visualize_op.operation_type) {
    case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_LINE_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_AREA_CHART_V2: {
      const { categoryColumn, aggColumns, colorColumnOptions } =
        visualize_op.instructions.V2_TWO_DIMENSION_CHART || {};

      const categoryColError = getColumnUsageError(dataset, categoryColumn?.column.name, true);
      if (categoryColError) return categoryColError;

      const aggColError = getColumnUsageError(dataset, aggColumns?.[0]?.column.name);
      if (aggColError) return aggColError;

      const groupByColError = getColumnUsageError(
        dataset,
        colorColumnOptions?.[0]?.column.name,
        true,
      );
      if (groupByColError) return groupByColError;

      break;
    }
    case OPERATION_TYPES.VISUALIZE_NUMBER_V2: {
      const { aggColumn } = visualize_op.instructions.V2_KPI || {};
      const aggColError = getColumnUsageError(dataset, aggColumn?.column.name);
      if (aggColError) return aggColError;
      break;
    }
    default:
      return null;
  }
  return null;
}

function getColumnUsageError(
  { columnOptions }: CanvasDataset,
  columnName: string | undefined,
  isCategoryCol = false,
): string | null {
  if (columnName === undefined) return null;
  const columnOption = columnOptions[columnName];

  if (!columnOption) return 'A column used for this chart is no longer available.';
  if (!columnOption.isVisible) {
    return `${columnOption.name} column is no longer available`;
  }

  if (isCategoryCol && !columnOption.canBeGroupedBy) {
    return `${columnOption.name} column cannot be grouped by`;
  }

  return null;
}

export function canUseCanvasDataset(dataset: ResourceDataset): boolean {
  if (!isCanvasDataset(dataset)) return true;
  return !dataset.isHiddenFromUsers;
}

export function missingRequiredFilter(
  dataset: ResourceDataset,
  elements: DashboardElement[],
  filters: Record<string, CanvasFilter>,
  variables: DashboardVariableMap,
): string | null {
  if (!isCanvasDataset(dataset)) return null;

  let missingElemError: string | null = null;
  for (const index in dataset.requiredFilters) {
    const filterId: string = dataset.requiredFilters[index];
    const isFilterInDashboard = !!elements.find((element) => element.id === filterId);

    if (isFilterInDashboard && filters[filterId]) {
      if (
        !(filters[filterId].provided_id in variables) ||
        variables[filters[filterId].provided_id] === undefined
      ) {
        missingElemError = `You need to set a value for the required filter ${filters[filterId].name} for dataset ${dataset.name}`;
      }
    } else if (filters[filterId]) {
      missingElemError = `Dataset ${dataset.name} requires a filter. Add ${filters[filterId].name} to view this chart.`;
    }
  }

  return missingElemError;
}

export function getDefaultVariables(filters: CanvasFilter[]): DashboardVariableMap {
  const elements = canvasFiltersToDashboardElements(filters);
  return getDefaultVariablesFromDashElements(elements, 'UTC');
}

export function getFilterQueryInfo({
  filter_info,
}: CanvasFilter): { datasetId: string; valuesConfig: DropdownValuesConfig | null } | null {
  if (!filter_info) return null;

  if (
    filter_info.filter_type === DASHBOARD_ELEMENT_TYPES.DROPDOWN ||
    filter_info.filter_type === DASHBOARD_ELEMENT_TYPES.MULTISELECT ||
    filter_info.filter_type === DASHBOARD_ELEMENT_TYPES.TOGGLE
  ) {
    const valuesConfig = filter_info.config.valuesConfig;
    const datasetId = getSelectFilterDatasetId(filter_info.config);
    if (datasetId) {
      return {
        datasetId,
        valuesConfig: valuesConfig.queryDefaultFirstValue ? valuesConfig : null,
      };
    }
  }
  return null;
}

export function getCanvasTemplateError(
  template: CanvasTemplate,
  datasets: Record<string, CanvasDataset>,
): string | null {
  const dataset = datasets[template.dataset_id];
  if (!dataset) return 'Dataset does not exist';
  if (dataset.isHiddenFromUsers) return 'Dataset is hidden';

  return canvasDataPanelColumnsError(template, dataset);
}

export function canvasFilterHasError(
  filter: CanvasFilter,
  datasets: Record<string, CanvasDataset>,
): boolean {
  const filterQueryInfo = getFilterQueryInfo(filter);
  if (!filterQueryInfo) return false;
  return !datasets[filterQueryInfo.datasetId];
}

export function cleanCanvasConfig(config: CanvasVersionConfig): CanvasVersionConfig {
  const cleanConfig = cloneDeep(config);
  Object.values(cleanConfig.datasets).map(removeUnderscoreFields);
  Object.values(cleanConfig.templates).map(removeUnderscoreFields);

  return cleanConfig;
}

export function isCanvasComponent(
  item: CanvasTemplate | CanvasCustomComponent,
): item is CanvasCustomComponent {
  return 'info' in item;
}
