import { FC } from 'react';
import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Icon } from '@blueprintjs/core';

import { textOverflow } from 'pages/BlueprintPage/sharedStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: `2px solid ${theme.palette.ds.grey400}`,
    backgroundColor: theme.palette.ds.white,
    borderRadius: 4,
    cursor: 'pointer',
    marginBottom: theme.spacing(2),
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between',

    '&:hover': {
      backgroundColor: theme.palette.ds.grey100,
      borderColor: theme.palette.ds.hovered.grey400,
    },

    '&.unselected': {
      backgroundColor: theme.palette.ds.grey200,

      '&:hover': {
        backgroundColor: theme.palette.ds.hovered.grey200,
      },
    },
  },
  itemInfoFlex: {
    flex: 1,
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
  },
  itemIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: theme.spacing(2),
  },
  itemTitle: {
    ...textOverflow(2),
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '20px',
  },
  itemSubTitle: {
    ...textOverflow(2),
    color: theme.palette.ds.grey600,
    fontWeight: 500,
    lineHeight: '15px',
    fontSize: 12,
    marginTop: 2,
  },
  itemStatus: {
    minWidth: 12,
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(2),
      ' &:last-child': {
        marginRight: 0,
      },
    },
  },
  eyeIcon: {
    color: theme.palette.ds.grey800,
  },
}));

type Props = {
  className?: string;
  description?: string;
  icon?: JSX.Element | null;
  isSelected: boolean;
  name: string;
  onClick: () => void;
};

export const DashboardElementVisibilityCard: FC<Props> = ({
  className,
  description,
  icon,
  isSelected,
  name,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.root, className, { unselected: !isSelected })} onClick={onClick}>
      <div className={classes.itemInfoFlex}>
        <div className={classes.itemIcon}>{icon}</div>
        <div className={classes.textContainer}>
          <div className={classes.itemTitle}>{name}</div>
          {description ? <div className={classes.itemSubTitle}>{description}</div> : null}
        </div>
      </div>
      <div className={classes.itemStatus}>
        <Icon
          className={classes.eyeIcon}
          icon={isSelected ? 'eye-open' : 'eye-off'}
          iconSize={16}
        />
      </div>
    </div>
  );
};
