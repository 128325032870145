import { FC } from 'react';
import produce from 'immer';

import { sprinkles } from 'components/ds';
import { Checkbox } from '@blueprintjs/core';
import InfoCard from 'shared/InfoCard';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import { Switch } from 'components/ds';
import SettingHeader from '../DataPanelConfigV2/DataConfigTab/SettingHeader';

import {
  ApplyFilterElemConfig,
  DashboardElement,
  DashboardElementConfig,
} from 'types/dashboardTypes';
import { FILTER_ELEMENTS } from 'constants/dashboardConstants';

const sectionClass = sprinkles({ marginY: 'sp1.5' });
const configInputClass = sprinkles({ marginBottom: 'sp1', paddingX: 'sp1.5' });
const checkboxContainerClass = sprinkles({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
});

type Props = {
  allElements: DashboardElement[];
  config: ApplyFilterElemConfig;
  elementId: string;

  updateConfig: (newConfig: DashboardElementConfig) => void;
};

export const ApplyFilterButtonConfigPanel: FC<Props> = ({
  config,
  elementId,
  allElements,
  updateConfig,
}) => {
  const updateApplyConfig = (update: (config: ApplyFilterElemConfig) => void) => {
    updateConfig(
      produce(config, (draft) => {
        update(draft);
      }),
    );
  };

  return (
    <div className={sprinkles({ marginX: 'spn1.5' })}>
      <SettingHeader name="General" />
      <div className={sectionClass}>
        <InputWithBlurSave
          containerClassName={configInputClass}
          initialValue={config.buttonText || 'Apply Filters'}
          label="Button Text"
          onNewValueSubmitted={(newValue) =>
            updateApplyConfig((draft) => (draft.buttonText = newValue))
          }
        />
        <Switch
          useCustomStyles
          className={configInputClass}
          label="Show refresh icon"
          onChange={() =>
            updateApplyConfig((draft) => (draft.hideRefreshIcon = !draft.hideRefreshIcon))
          }
          switchOn={!config.hideRefreshIcon}
        />
        <Switch
          useCustomStyles
          className={configInputClass}
          label="Show number of filters blocked"
          onChange={() =>
            updateApplyConfig((draft) => (draft.hideNumFiltersIcon = !draft.hideNumFiltersIcon))
          }
          switchOn={!config.hideNumFiltersIcon}
        />
      </div>
      <SettingHeader name="Input Selection" />
      <div className={sectionClass}>
        <InfoCard
          className={sprinkles({ marginBottom: 'sp1.5', marginX: 'sp1.5' })}
          text="Inputs chosen here will not trigger a dashboard refresh when changed. A user will have to click this button to trigger a refresh."
        />
        <div className={configInputClass}>
          {allElements.map((element) => {
            if (element.id === elementId || !FILTER_ELEMENTS.has(element.element_type)) return null;

            return (
              <div className={checkboxContainerClass} key={`filter-btn-element-${element.id}`}>
                <Checkbox
                  checked={config.elementIds && config.elementIds[element.id]}
                  label={element.name}
                  onClick={() =>
                    updateApplyConfig((draft) => {
                      if (!draft.elementIds) draft.elementIds = {};
                      draft.elementIds[element.id] = !draft.elementIds[element.id];
                    })
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
