import { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import validator from 'validator';
import { Classes, Intent, FormGroup } from '@blueprintjs/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import produce from 'immer';
import { v4 as uuidv4 } from 'uuid';

import InputGroup from 'explo-ds/forms/marketing/inputGroup';
import InputLabel from 'shared/InputLabel';
import DropdownSelect from 'shared/DropdownSelect';
import { ReduxState } from 'reducers/rootReducer';
import Modal from 'components/core/Modal';
import Button from 'shared/Button';
import DeleteConfirmButton from 'components/core/DeleteConfirmButton';
import EmailTimeSelection from './emailTimeSelection';
import EmailDaySelection from './emailDaySelection';
import { showSuccessToast, showErrorContactSupportToast } from 'shared/sharedToasts';
import Poller from 'components/JobQueue/Poller';
import { Jobs } from 'components/JobQueue/types';

import {
  createDashboardEmail,
  editDashboardEmail,
  deleteDashboardEmail,
  sendTestEmail,
} from 'actions/emailActions';
import { getFrequency, getHour, getMilitaryTimeHour } from 'utils/emailUtils';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ACTION } from 'actions/types';
import { ExploreEmailCadence } from 'actions/teamActions';
import { EMAIL_FREQUENCY, EmailCadenceTime } from 'constants/types';
import InfoCard from 'shared/InfoCard';
import { isEmailReportingDisabled } from 'utils/paymentPlanUtils';
import { bulkEnqueueJobs } from 'actions/jobQueueActions';
import { DEFAULT_SUPPORT_EMAIL } from 'constants/emailConstants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '600px',
  },
  description: {
    color: theme.palette.ds.grey700,
    fontWeight: 400,
    fontSize: '12px',
  },
  headerMessage: {
    marginTop: theme.spacing(-10),
    paddingBottom: theme.spacing(2),
    color: theme.palette.ds.grey900,
  },
  formContainer: {
    marginBottom: theme.spacing(0),
    paddingTop: theme.spacing(3),
  },
  weekdaySelect: {
    paddingTop: theme.spacing(3),
  },
  timeSelect: {
    display: 'flex',
    paddingTop: theme.spacing(3),
    gap: `${theme.spacing(6)}px`,
    justifyContent: 'space-between',
  },
  daySelect: {},
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  rightButtonContainer: {
    display: 'flex',
  },
  testEmailContainer: {
    marginTop: theme.spacing(8),
    backgroundColor: theme.palette.ds.grey200,
    padding: `${theme.spacing(2.5)}px ${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(
      3,
    )}px`,
    borderRadius: `${theme.spacing(1)}px`,
  },
  testEmailHeader: {
    fontSize: 14,
    fontWeight: 600,
    display: 'flex',
  },
  testEmailDescription: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    color: theme.palette.ds.grey900,
  },
  testEmailContentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
  },
  testEmailInput: {
    flexGrow: 2,
  },
  testEmailButton: {
    backgroundColor: `${theme.palette.ds.grey300} !important`,
  },
  dayContainer: {
    display: 'flex',
    alignItems: 'end',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  dayText: {
    paddingBottom: '5px',
  },
  testEmailQualifier: {
    fontSize: 12,
    color: theme.palette.ds.grey700,
  },
}));

type Props = {
  modalOpen: boolean;
  closeModal: () => void;
  dashboardTemplateId: number;
  emailCadence?: ExploreEmailCadence;
  modalTitle: string;
};

export default function SetupEmailModal({
  modalOpen,
  closeModal,
  modalTitle,
  emailCadence,
  dashboardTemplateId,
}: Props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { teamPaymentPlan, emailSetupLoading, deleteEmailLoading, shouldUseJobQueue, fromEmail } =
    useSelector(
      (state: ReduxState) => ({
        teamPaymentPlan: state.currentUser.team?.payment_plan,
        emailSetupLoading: createLoadingSelector(
          [ACTION.CREATE_DASHBOARD_EMAIL, ACTION.EDIT_DASHBOARD_EMAIL],
          false,
        )(state),
        deleteEmailLoading: createLoadingSelector([ACTION.DELETE_DASHBOARD_EMAIL], false)(state),
        shouldUseJobQueue: !!state.currentUser.team?.feature_flags.use_job_queue,
        fromEmail: state.currentUser.team?.send_emails_as ?? DEFAULT_SUPPORT_EMAIL,
      }),
      shallowEqual,
    );

  const [subject, setSubject] = useState(emailCadence?.subject || '');
  const [frequency, setFrequency] = useState<EMAIL_FREQUENCY | undefined>(
    getFrequency(emailCadence),
  );
  const [dayOfWeek, setDayOfWeek] = useState<number | undefined>(emailCadence?.day_of_week);
  const [time, setTime] = useState<EmailCadenceTime>({
    hour: getHour(emailCadence),
    minute: emailCadence?.minute,
    isPm: (emailCadence?.hour ?? 0) >= 12,
    timezone: emailCadence?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  const [weekOfMonth, setWeekOfMonth] = useState<number | undefined>(
    emailCadence?.week_of_month ? emailCadence?.week_of_month : undefined,
  );
  const [testEmail, setTestEmail] = useState('');
  const [awaitedJobs, setAwaitedJobs] = useState<Record<string, Jobs>>({});
  const [isTestEmailSending, setIsTestEmailSending] = useState(false);

  const validateFrequency = () =>
    frequency === EMAIL_FREQUENCY.DAILY ||
    (frequency === EMAIL_FREQUENCY.MONTHLY &&
      weekOfMonth !== undefined &&
      dayOfWeek !== undefined) ||
    (frequency === EMAIL_FREQUENCY.WEEKLY && dayOfWeek !== undefined);

  const validateTime = () =>
    time && time.hour && time.minute !== undefined && time.isPm !== undefined && time.timezone;

  const isFormReadyToSubmit = () => {
    return (
      validator.isEmail(fromEmail) &&
      !validator.isEmpty(subject ?? '', { ignore_whitespace: true }) &&
      validateTime() &&
      validateFrequency()
    );
  };

  const sendTestEmailWrapper = () => {
    const postData = {
      dashboard_template_id: dashboardTemplateId,
      email: testEmail,
      subject: !validator.isEmpty(subject) ? subject : 'Explo Test Email',
    };

    const onSuccess = () => {
      showSuccessToast(`An email has been sent to ${testEmail}`, 5);
      setIsTestEmailSending(false);
    };

    const onError = (errorMessage?: string) => {
      showErrorContactSupportToast(errorMessage);
      setIsTestEmailSending(false);
    };

    setIsTestEmailSending(true);

    if (!shouldUseJobQueue)
      dispatch(sendTestEmail({ postData }, onSuccess, (response) => onError(response.error_msg)));
    else {
      const jobs = {
        [uuidv4()]: {
          job_type: ACTION.SEND_TEST_EMAIL,
          job_args: postData,
          onSuccess,
          onError,
        },
      };

      dispatch(
        bulkEnqueueJobs({ jobs }, (jobs) => {
          setAwaitedJobs(
            produce(awaitedJobs, (draft) => {
              return {
                ...draft,
                ...jobs,
              };
            }),
          );
        }),
      );
    }
  };

  return (
    <Modal className={classes.root} modalOpen={modalOpen} onClose={closeModal} title={modalTitle}>
      <Poller
        awaitedJobs={awaitedJobs}
        updateJobResult={(finishedJobIds, onComplete) => {
          if (finishedJobIds.length > 0)
            setAwaitedJobs((currentAwaitedJobs) => {
              const newAwaitedJobs = produce(currentAwaitedJobs, (draft) =>
                finishedJobIds.forEach((jobId) => delete draft[jobId]),
              );
              return newAwaitedJobs;
            });

          onComplete();
        }}
      />
      <div className={Classes.DIALOG_BODY}>
        <div className={classes.headerMessage}>
          {'Set up recurring emails to be sent to dashboard users.'}
        </div>
        {isEmailReportingDisabled(teamPaymentPlan) && (
          <InfoCard
            error
            text="Email is not enabled in your plan. You can still send test emails and create email
          reports, but they will be disabled. Please upgrade to start sending email reports!"
          />
        )}
        <FormGroup className={classes.formContainer} labelFor="text-input">
          <InputLabel text={'From Address'} />
          <InputGroup
            disabled
            intent={fromEmail === '' || validator.isEmail(fromEmail) ? Intent.NONE : Intent.DANGER}
            onInputChange={() => console.error('This is not supported')}
            placeholder={DEFAULT_SUPPORT_EMAIL}
            value={fromEmail}
          />
          <div className={classes.description}>
            This is the address the recipient will be receiving the email from. Contact Explo
            support if you would like the email to be sent from your own domain
          </div>
        </FormGroup>
        <FormGroup className={classes.formContainer} labelFor="text-input">
          <InputLabel text={'Email Subject'} />
          <InputGroup onInputChange={setSubject} placeholder="Scheduled Report" value={subject} />
        </FormGroup>
        <div className={classes.formContainer}>
          <InputLabel text={'Frequency'} />
          <DropdownSelect
            fillWidth
            minimal
            showIcon
            filterable={false}
            noSelectionText="Select Frequency"
            onChange={(item) => setFrequency(item.name as EMAIL_FREQUENCY)}
            options={Object.values(EMAIL_FREQUENCY).map((emailFrequency) => ({
              id: emailFrequency,
              name: emailFrequency,
            }))}
            selectedItem={
              frequency
                ? {
                    id: frequency,
                    name: frequency,
                  }
                : undefined
            }
          />
          {(frequency === EMAIL_FREQUENCY.MONTHLY || frequency === EMAIL_FREQUENCY.WEEKLY) && (
            <EmailDaySelection
              dayOfWeek={dayOfWeek}
              frequency={frequency}
              onSetDayOfWeek={(dayOfWeek: number) => setDayOfWeek(dayOfWeek)}
              onSetWeekOfMonth={(weekOfMonth: number) => setWeekOfMonth(weekOfMonth)}
              weekOfMonth={weekOfMonth}
            />
          )}
        </div>
        <EmailTimeSelection
          emailCadenceTime={time}
          onSetTime={(time: EmailCadenceTime) => setTime(time)}
        />
        <div className={classes.testEmailContainer}>
          <div className={classes.testEmailHeader}>Test Email</div>
          <div className={classes.testEmailDescription}>
            The test email will be populated with data from one of your customers.
          </div>
          <div className={classes.testEmailContentContainer}>
            <div>
              <InputLabel text={'Email Address'} />
              <InputGroup
                className={classes.testEmailInput}
                intent={
                  testEmail === '' || validator.isEmail(testEmail) ? Intent.NONE : Intent.DANGER
                }
                onInputChange={setTestEmail}
                placeholder="recipient@your_email.com"
                value={testEmail}
              />
            </div>
            <Button
              className={classes.testEmailButton}
              disabled={!validator.isEmail(testEmail)}
              loading={isTestEmailSending}
              onClick={() => validator.isEmail(testEmail) && sendTestEmailWrapper()}
              text="Send Test"
            />
          </div>
          <div className={classes.testEmailQualifier}>
            Tests usually arrive within a couple of minutes.
          </div>
        </div>
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={classes.actionButtons}>
          <div>
            <DeleteConfirmButton
              disabled={!emailCadence}
              loading={deleteEmailLoading}
              onDelete={() => {
                if (!emailCadence) return;
                dispatch(
                  deleteDashboardEmail(
                    {
                      postData: { dashboard_template_id: dashboardTemplateId },
                    },
                    () => {
                      closeModal();
                    },
                  ),
                );
              }}
            />
          </div>
          <div className={classes.rightButtonContainer}>
            <Button
              disabled={!isFormReadyToSubmit()}
              loading={emailSetupLoading}
              onClick={() => {
                if (!isFormReadyToSubmit()) return;

                const hour = getMilitaryTimeHour(time);
                const postData = {
                  dashboard_template_id: dashboardTemplateId,
                  from_email: fromEmail,
                  subject: subject,
                  hour: hour,
                  minute: time.minute,
                  day_of_week: dayOfWeek,
                  week_of_month: weekOfMonth,
                  timezone: time.timezone,
                };
                emailCadence
                  ? dispatch(
                      editDashboardEmail(
                        { postData },
                        () => {
                          closeModal();
                          showSuccessToast('Email Edited Successfully.');
                        },
                        (response) => showErrorContactSupportToast(response.error_msg),
                      ),
                    )
                  : dispatch(
                      createDashboardEmail(
                        { postData },
                        () => {
                          closeModal();
                          showSuccessToast('Email Configured Successfully.');
                        },
                        (response) => showErrorContactSupportToast(response.error_msg),
                      ),
                    );
              }}
              text="Confirm"
              type="primary"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
