import { makeStyles } from '@material-ui/core/styles';
import Modal from 'components/core/Modal';
import cx from 'classnames';

import { DashboardDatasetView } from '../DashboardDatasetView';
import { DataPanel, ResourceDataset } from 'types/exploResource';
import { UserTransformedSchema } from 'constants/types';
import { PAGE_TYPE } from 'types/dashboardTypes';
import { AdHocOperationInstructions } from 'types/dataPanelTemplate';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { AnalyticsEventTracker } from 'utils/analyticsUtils';
import { SpreadsheetType } from 'reducers/dashboardLayoutReducer';

const useStyles = makeStyles(() => ({
  modalContainer: {
    height: '80vh !important',
    width: '80vw !important',
    paddingBottom: '0px !important',
  },
}));

type Props = {
  datasets: Record<string, ResourceDataset>;
  drilldownDPT: DataPanel;
  globalStylesClassName?: string;
  modalOpen: boolean;
  pageType: PAGE_TYPE;
  portalId: string;

  analyticsEventTracker?: AnalyticsEventTracker;
  closeModal: () => void;
  onAdHocOperationInstructionsUpdated: (
    dataPanelId: string,
    adHocOperationInstructions: AdHocOperationInstructions,
    skipRowCount?: boolean,
  ) => void;
  onDownloadDataPanelPdf: (
    dataPanel: DataPanel,
    adHocOperationInstructions: AdHocOperationInstructions,
    email: string | undefined,
    schema?: UserTransformedSchema,
  ) => void;
  onDownloadDataPanelSpreadsheet: (
    dataPanel: DataPanel,
    fileFormat: SpreadsheetType,
    email: string | undefined,
    schema?: UserTransformedSchema,
  ) => void;
};

export function DrilldownModal({
  analyticsEventTracker,
  closeModal,
  datasets,
  drilldownDPT,
  globalStylesClassName,
  modalOpen,
  onAdHocOperationInstructionsUpdated,
  onDownloadDataPanelSpreadsheet,
  pageType,
  portalId,
}: Props) {
  const classes = useStyles();

  return (
    <Modal
      usePortal
      className={cx(
        classes.modalContainer,
        globalStylesClassName,
        GLOBAL_STYLE_CLASSNAMES.text.body.primaryFont,
      )}
      modalOpen={modalOpen}
      onClose={closeModal}
      portalContainerId={portalId}>
      <DashboardDatasetView
        canDownloadDataPanel
        isDrilldownModal
        isViewOnly
        analyticsEventTracker={analyticsEventTracker}
        dataPanel={drilldownDPT}
        datasets={datasets}
        isInContainer={false}
        isSelected={false}
        loading={
          drilldownDPT._loading === true ||
          drilldownDPT._loading === undefined ||
          !!drilldownDPT._outstandingSecondaryDataRequests
        }
        onAdHocOperationInstructionsUpdated={onAdHocOperationInstructionsUpdated}
        onCloseDrilldownModal={closeModal}
        onDownloadPanelPdf={() => console.warn('Not supported in drilldown view')}
        onDownloadPanelSpreadsheet={(fileFormat, email, userTransformedSchema) => {
          onDownloadDataPanelSpreadsheet(drilldownDPT, fileFormat, email, userTransformedSchema);
        }}
        pageType={pageType}
        variables={{}}
      />
    </Modal>
  );
}
