import { useEffect } from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { NonIdealState } from '@blueprintjs/core';

import { pageView } from 'analytics/exploAnalytics';
import { DEFAULT_SUPPORT_EMAIL } from 'constants/emailConstants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '100vh',
    width: '100%',
    backgroundColor: theme.palette.ds.grey100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  expirationPanel: {
    backgroundColor: theme.palette.ds.white,
    width: '60vw',
    maxWidth: 600,
    border: `1px solid ${theme.palette.ds.grey500}`,
    borderRadius: 4,
    padding: `${theme.spacing(12)}px ${theme.spacing(6)}px`,
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
  },
}));

const TrialExpiredPage = () => {
  const classes = useStyles();

  useEffect(() => {
    pageView('Trial Expired Page');
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.expirationPanel}>
        <NonIdealState
          description={`Thanks for trying Explo! Your trial period has expired. In order to continue using Explo, you will have to move to a paid plan. Please contact us at ${DEFAULT_SUPPORT_EMAIL} to sign up for a plan.`}
          icon="error"
          title="Your trial has expired"
        />
      </div>
    </div>
  );
};

export default TrialExpiredPage;
