import { FC, useEffect, useState } from 'react';

import * as styles from './ValuesConfig.css';
import NavTabs from 'components/core/navTabs';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';
import DropdownSelect from 'shared/DropdownSelect';

import {
  DASHBOARD_ELEMENT_TYPES,
  SELECT_FILTER_TYPE,
  SelectElemConfig,
  MultiSelectSortOption,
} from 'types/dashboardTypes';
import { INPUT_TYPE, INPUT_TYPES, SelectedDropdownInputItem } from 'constants/types';
import { getDatasetName } from 'utils/naming';
import { ASC_SORT_ICON, DEFAULT_SORT_ICON, DESC_SORT_ICON } from 'constants/iconConstants';
import { ResourceDataset } from 'types/exploResource';
import { getUpdateConfigFunc } from '../utils';
import { createColOptionsWithIcon, createColOptionWithIconFromCol } from 'utils/general';

type Props = {
  config: SelectElemConfig;
  datasets: Record<string, ResourceDataset>;
  selectType: SELECT_FILTER_TYPE;

  updateSelectConfig: (config: SelectElemConfig, reRequestRows: boolean) => void;
};

export const SelectValuesConfig: FC<Props> = ({
  config,
  datasets,
  selectType,
  updateSelectConfig,
}) => {
  const [tabId, setTabId] = useState(config.valuesConfig.valuesSource);

  useEffect(() => {
    setTabId(config.valuesConfig.valuesSource);
  }, [config.valuesConfig.valuesSource]);

  const updateConfig = getUpdateConfigFunc(config, updateSelectConfig);

  const isToggle = selectType === DASHBOARD_ELEMENT_TYPES.TOGGLE;

  const renderSideMessage = () => {
    if (!isToggle) return null;
    return <div className={styles.sideText}>A maximum of 5 options will be shown</div>;
  };

  const renderManualConfig = () => {
    const valuesArrStr = config.valuesConfig.manualValues || '';
    const displaysArrStr = config.valuesConfig.manualDisplays || '';

    const sharedProps = { ignoreCustomStyles: true, containerClassName: styles.configInput };

    return (
      <>
        <InputWithBlurSave
          {...sharedProps}
          errorText={parseArrayString(valuesArrStr)}
          initialValue={valuesArrStr}
          label="Values"
          onNewValueSubmitted={(newValue) =>
            updateConfig((draft) => (draft.valuesConfig.manualValues = newValue))
          }
          placeholder={isToggle ? '["day", "week", "month"]' : '[1, 2, 3]'}
        />
        {renderSideMessage()}
        <InputWithBlurSave
          {...sharedProps}
          errorText={parseArrayString(displaysArrStr)}
          initialValue={displaysArrStr}
          label="Displays"
          onNewValueSubmitted={(newValue) =>
            updateConfig((draft) => (draft.valuesConfig.manualDisplays = newValue))
          }
          placeholder={isToggle ? '["Day", "Week", "Month"]' : '["One", "Two", "Three"]'}
        />
      </>
    );
  };

  const renderQueryConfig = () => {
    const datasetOptions = Object.values(datasets).reduce<SelectedDropdownInputItem[]>(
      (acc, dataset) => {
        if (dataset.schema) acc.push({ id: dataset.id, name: getDatasetName(dataset) });
        return acc;
      },
      [],
    );
    const selectedDataset = datasets[config.valuesConfig.queryTable?.id ?? ''];

    const queryValueColName = config.valuesConfig.queryValueColumn?.name;
    const selectedValueColumn = queryValueColName
      ? selectedDataset?.schema?.find((col) => col.name === queryValueColName)
      : undefined;

    const queryDisplayColName = config.valuesConfig.queryDisplayColumn?.name;
    const selectedDisplayColumn = queryDisplayColName
      ? selectedDataset?.schema?.find((col) => col.name === queryDisplayColName)
      : undefined;

    const selectedSort = config.valuesConfig?.querySortOption ?? MultiSelectSortOption.DEFAULT;

    const sharedProps = {
      ignoreCustomStyles: true,
      fillWidth: true,
      minimal: true,
      showCancelBtn: true,
      filterable: false,
      showIcon: true,
      containerClassName: styles.configInput,
    };

    return (
      <>
        <DropdownSelect
          {...sharedProps}
          label="Dataset"
          noSelectionText="Select dataset"
          onCancelClick={() =>
            updateConfig((draft) => {
              draft.valuesConfig.queryValueColumn = undefined;
              draft.valuesConfig.queryDisplayColumn = undefined;
              draft.valuesConfig.queryTable = undefined;
            })
          }
          onChange={(item) =>
            updateConfig((draft) => {
              draft.valuesConfig.queryValueColumn = undefined;
              draft.valuesConfig.queryDisplayColumn = undefined;
              draft.valuesConfig.queryTable = { id: item.id, name: item.name };
            }, true)
          }
          options={datasetOptions}
          selectedItem={
            selectedDataset
              ? { id: selectedDataset.id, name: getDatasetName(selectedDataset) }
              : undefined
          }
        />
        <DropdownSelect
          {...sharedProps}
          disabled={selectedDataset === undefined}
          label="Values"
          noSelectionText="Select column"
          onCancelClick={() =>
            updateConfig((draft) => (draft.valuesConfig.queryValueColumn = undefined))
          }
          onChange={(item) =>
            updateConfig(
              (draft) => (draft.valuesConfig.queryValueColumn = { name: item.name, id: item.id }),
              true,
            )
          }
          options={createColOptionsWithIcon(selectedDataset?.schema || [])}
          selectedItem={
            selectedValueColumn ? createColOptionWithIconFromCol(selectedValueColumn) : undefined
          }
        />
        {renderSideMessage()}
        <DropdownSelect
          {...sharedProps}
          disabled={selectedDataset === undefined}
          label="Displays (optional)"
          noSelectionText="Select column"
          onCancelClick={() =>
            updateConfig((draft) => (draft.valuesConfig.queryDisplayColumn = undefined))
          }
          onChange={(item) =>
            updateConfig((draft) => {
              draft.valuesConfig.queryDisplayColumn = { name: item.name, id: item.id };
            })
          }
          options={createColOptionsWithIcon(selectedDataset?.schema || [])}
          selectedItem={
            selectedDisplayColumn
              ? createColOptionWithIconFromCol(selectedDisplayColumn)
              : undefined
          }
        />
        <ToggleButtonGroup fillWidth className={styles.configInput} label="Sort Values">
          {Object.values(MultiSelectSortOption).map((sortOption) => {
            let icon = DEFAULT_SORT_ICON;
            if (sortOption === MultiSelectSortOption.ASC) icon = ASC_SORT_ICON;
            else if (sortOption === MultiSelectSortOption.DESC) icon = DESC_SORT_ICON;

            return (
              <ToggleButton
                active={sortOption === selectedSort}
                icon={icon}
                key={sortOption}
                onClick={() =>
                  updateConfig((draft) => (draft.valuesConfig.querySortOption = sortOption))
                }
              />
            );
          })}
        </ToggleButtonGroup>
      </>
    );
  };

  return (
    <div className={styles.valuesContainer}>
      <NavTabs
        className={styles.toggleTabContainer}
        onTabSelect={(tabId) => {
          const inputType = tabId as INPUT_TYPE;
          updateConfig((draft) => (draft.valuesConfig.valuesSource = inputType));
          setTabId(inputType);
        }}
        selectedTabId={tabId}
        tabs={[INPUT_TYPES.MANUAL, INPUT_TYPES.QUERY]}
      />
      <div className={styles.section}>
        {tabId === INPUT_TYPES.MANUAL.id ? renderManualConfig() : renderQueryConfig()}
      </div>
    </div>
  );
};

function parseArrayString(arrayString: string): string | undefined {
  let error;
  try {
    if (arrayString) {
      const displaysArr = JSON.parse(arrayString);
      if (!Array.isArray(displaysArr)) error = 'Must be valid javascript array.';
    }
  } catch {
    error = 'Must be valid javascript array.';
  }
  return error;
}
