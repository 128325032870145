import { FC } from 'react';
import { useRouteMatch } from 'react-router-dom';
import parse from 'url-parse';

import { SharedArchitectCustomerDashboard } from './architectCustomerDashboardPage/sharedArchitectCustomerDashboard';

type MatchParams = {
  canvasEmbedId: string;
  customerToken: string;
  dashboardId: string;
  environment?: string;
};

export const SharedArchitectCustomerDashboardPage: FC = () => {
  const { params } = useRouteMatch<MatchParams>();
  const { userId } = parse(window.location.href, true).query;

  return (
    <SharedArchitectCustomerDashboard
      canvasEmbedId={params.canvasEmbedId}
      customerToken={params.customerToken}
      dashboardId={parseInt(params.dashboardId)}
      environment={params.environment}
      userId={userId !== undefined ? (userId.trim() === '' ? undefined : userId) : undefined}
    />
  );
};
