import { FC, useState, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import produce from 'immer';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import { SideSheet, AlertModal, sprinkles } from 'components/ds';
import * as styles from '../styles.css';
import { AccessGroupDropdown } from './AccessGroupDropdown';

import { ParentSchema } from 'actions/dataSourceActions';
import { ReduxState } from 'reducers/rootReducer';
import { cloneDeep, isEmpty } from 'utils/standard';
import {
  getAccessGroupsToUpdate,
  schemasHaveDuplicateNames,
  getAccessGroupDataSourcesForSchema,
} from '../utils';
import { showWarningToast, showErrorToast, showSuccessToast } from 'shared/sharedToasts';
import { syncParentSchema } from 'actions/parentSchemaActions';
import { updateAccessGroups } from 'actions/rolePermissionActions';
import { AccessGroup } from 'actions/teamActions';
import { AccessGroupDataSourcesMap } from 'constants/types';
import { ROUTES } from 'constants/routes';
import * as constants from '../constants';

type Props = {
  schemaName: string;
  schemaId: number;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
};

export const ManageSchemasSideSheet: FC<Props> = ({ schemaId, schemaName, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const [newSchemaName, setNewSchemaName] = useState('');
  const [editorSchema, setEditorSchema] = useState<ParentSchema[]>([]);
  const [accessGroupIdToDefaultDataSourceIdUpdates, setAccessGroupIdToDefaultDataSourceIdUpdates] =
    useState<Record<number, number[]>>({});
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const { dataSources, team, parentSchemas } = useSelector(
    (state: ReduxState) => ({
      parentSchemas: state.parentSchemas.usedParentSchemas,
      dataSources: state.dataSourceList.dataSources,
      team: state.teamData.data,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (parentSchemas) setEditorSchema(cloneDeep(parentSchemas));
  }, [parentSchemas]);

  const accessGroupMap = useMemo(() => {
    const accessGroupInfoInit: AccessGroupDataSourcesMap = {};

    if (!dataSources || !team?.access_groups) return;
    team.access_groups.map(
      (accessGroup) =>
        (accessGroupInfoInit[accessGroup.id] = getAccessGroupDataSourcesForSchema(
          dataSources,
          schemaId,
          accessGroup,
        )),
    );
    return accessGroupInfoInit;
  }, [team, dataSources, schemaId]);

  const renderAccessGroupDropdown = (accessGroup: AccessGroup) => {
    return (
      <AccessGroupDropdown
        accessGroup={accessGroup}
        accessGroupDataSourcesMap={accessGroupMap}
        accessGroupIdToDefaultDataSourceIdUpdates={accessGroupIdToDefaultDataSourceIdUpdates}
        dataSources={dataSources}
        updateAccessGroupDefaultDataSource={(id: number, newDefaults: number[]) =>
          setAccessGroupIdToDefaultDataSourceIdUpdates({
            ...accessGroupIdToDefaultDataSourceIdUpdates,
            [id]: newDefaults,
          })
        }
      />
    );
  };

  const renderManageSchemas = () => {
    return (
      <div className={styles.sideSheetContent}>
        <div className={styles.section}>
          <div className={sprinkles({ display: 'flex', gap: 'sp2' })}>
            <div className={styles.labelAndInput}>
              <p className={styles.label}>Schema Name</p>
              <input
                autoFocus
                className={styles.input}
                onChange={(event) => setNewSchemaName(event.currentTarget.value)}
                placeholder={schemaName}
                value={newSchemaName}
              />
            </div>
            <div className={styles.labelAndInput}>
              <p className={styles.label}>Schema ID</p>
              <input
                disabled
                className={cx(
                  styles.input,
                  sprinkles({ backgroundColor: 'gray3', cursor: 'not-allowed' }),
                )}
                placeholder={String(schemaId)}
              />
            </div>
          </div>
        </div>
        <div className={styles.section}>
          <p className={styles.sectionHeader}>Default Data Sources</p>
          <div className={sprinkles({ color: 'contentSecondary', body: 'b3' })}>
            Define which data sources should be available to a visibility group on the{' '}
            <Link to={ROUTES.SETTINGS_ACCESS_GROUPS}>Settings Page</Link>.
          </div>
          {team?.access_groups?.map(renderAccessGroupDropdown)}
        </div>
      </div>
    );
  };

  const onUpdate = () => {
    const trimmedNewSchemaName = newSchemaName.trim();

    if (trimmedNewSchemaName !== '') {
      const newEditorSchema = produce(editorSchema, (draft) => {
        const indexToUpdate = draft.findIndex((tempSchema) => {
          return schemaName === tempSchema.name;
        });
        if (indexToUpdate !== -1) draft[indexToUpdate].name = trimmedNewSchemaName;
      });

      if (schemasHaveDuplicateNames(newEditorSchema)) {
        showWarningToast(constants.DUPLICATE_SCHEMA_NAME_TEXT, constants.TOAST_TIMEOUT);
        return;
      }
      setEditorSchema(newEditorSchema);
      dispatch(
        syncParentSchema(
          { postData: { edited_schemas: newEditorSchema } },
          () =>
            showSuccessToast(
              `${schemaName} successfully renamed to: ${trimmedNewSchemaName}`,
              constants.TOAST_TIMEOUT,
            ),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (e: any) => showWarningToast(e._error, constants.TOAST_TIMEOUT),
        ),
      );
    }

    const accessGroupsToUpdate = getAccessGroupsToUpdate(accessGroupIdToDefaultDataSourceIdUpdates);

    if (accessGroupsToUpdate) {
      dispatch(
        updateAccessGroups(
          { postData: { access_groups: accessGroupsToUpdate } },
          () =>
            showSuccessToast(`Successfully updated visibility groups.`, constants.TOAST_TIMEOUT),
          () => {
            showErrorToast(constants.VIZ_GROUP_EDITING_ERROR_TEXT, constants.TOAST_TIMEOUT);
          },
        ),
      );
    }
    setNewSchemaName('');
    setAccessGroupIdToDefaultDataSourceIdUpdates({});
    setIsOpen(false);
  };

  const onDiscard = () => {
    setNewSchemaName('');
    setAccessGroupIdToDefaultDataSourceIdUpdates({});
  };

  const hasUpdates = !(
    newSchemaName.trim() === '' && isEmpty(accessGroupIdToDefaultDataSourceIdUpdates)
  );

  const handleCloseAttempt = () => {
    if (hasUpdates) {
      setShowConfirmationModal(true);
    } else {
      setShowConfirmationModal(false);
      setIsOpen(false);
    }
  };

  return (
    <>
      <SideSheet
        className={styles.sidesheet}
        isOpen={isOpen}
        onClickOutside={handleCloseAttempt}
        onCloseClick={handleCloseAttempt}
        primaryButtonProps={{ onClick: onUpdate, disabled: !hasUpdates }}
        secondaryButtonProps={{
          onClick: onDiscard,
          disabled: !hasUpdates,
        }}
        title={schemaName}>
        {renderManageSchemas()}
      </SideSheet>
      <AlertModal
        actionButtonProps={{
          text: 'Discard Changes',
          onClick: () => {
            onDiscard();
            setShowConfirmationModal(false);
            setIsOpen(false);
          },
        }}
        cancelButtonProps={{ text: 'Keep Editing' }}
        isOpen={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        title="Do you want to discard your changes?"
      />
    </>
  );
};
