import { FC } from 'react';
import produce from 'immer';

import { sprinkles } from 'components/ds';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import { IframeElemConfig } from 'types/dashboardTypes';

type Props = {
  config: IframeElemConfig;

  updateConfig: (newConfig: IframeElemConfig) => void;
};

export const IframeElementConfigPanel: FC<Props> = ({ config, updateConfig }) => {
  return (
    <InputWithBlurSave
      containerClassName={sprinkles({ marginTop: 'sp1.5' })}
      helpIcon="code"
      helpText="This field can take in variables"
      initialValue={config.iframeUrl}
      label="Iframe URL"
      onNewValueSubmitted={(newValue) => {
        const newConfig = produce(config, (draft) => {
          draft.iframeUrl = newValue;
        });
        updateConfig(newConfig);
      }}
    />
  );
};
