import produce from 'immer';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { Switch } from 'components/ds';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import DateFormatDescriptiveText from '../DateFormatDescriptiveText';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES, VisualizePivotTableInstructions } from 'constants/types';
import { DATE_TYPES } from 'constants/dataConstants';
import InputLabel from 'shared/InputLabel';
import ColorPickerButton from 'shared/ColorPicker';
import { getCategoricalColors } from 'globalStyles';
import { GlobalStyleConfig } from 'globalStyles/types';

const useStyles = makeStyles((theme: Theme) => ({
  configInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  colorPicker: {
    width: '100%',
  },
}));

type Props = {
  instructions: VisualizePivotTableInstructions;
  globalStyleConfig: GlobalStyleConfig;
};

export const PivotTableRowsConfig: FC<Props> = ({ instructions, globalStyleConfig }) => {
  const classes = useStyles({});
  const dispatch = useDispatch();

  const updatePivotTableInstructions = (
    update: (pivotTableUpdates: VisualizePivotTableInstructions) => void,
  ) => {
    const newInstructions = produce(instructions, update);

    dispatch(updateVisualizeOperation(newInstructions, OPERATION_TYPES.VISUALIZE_PIVOT_TABLE));
  };

  const rowColType = instructions?.rowColumn?.column?.type;

  return (
    <>
      <Switch
        useCustomStyles
        className={classes.configInput}
        label="Display Sum Row"
        onChange={() => {
          updatePivotTableInstructions((draft) => {
            draft.displaySumRow = !draft.displaySumRow;
          });
        }}
        switchOn={instructions.displaySumRow}
      />
      {DATE_TYPES.has(rowColType ?? '') ? (
        <InputWithBlurSave
          containerClassName={classes.configInput}
          descriptiveText={<DateFormatDescriptiveText />}
          initialValue={instructions?.dateFormat}
          label="Date Format"
          onNewValueSubmitted={(newValue: string) => {
            updatePivotTableInstructions((draft) => {
              draft.dateFormat = newValue.trim() === '' ? undefined : newValue;
            });
          }}
        />
      ) : null}
      {instructions.displaySumRow && (
        <>
          <InputWithBlurSave
            containerClassName={classes.configInput}
            initialValue={instructions.sumRowText || 'Totals'}
            label="Sum Row Label"
            onNewValueSubmitted={(newValue: string) => {
              updatePivotTableInstructions((draft) => {
                draft.sumRowText = newValue;
              });
            }}
          />
          <Switch
            useCustomStyles
            className={classes.configInput}
            label="Bold Sum Row"
            onChange={() => {
              updatePivotTableInstructions((draft) => {
                draft.sumRowBold = !draft.sumRowBold;
              });
            }}
            switchOn={instructions.sumRowBold}
          />
          <div className={classes.configInput}>
            <InputLabel text="Sum Row Background Color" />
            <ColorPickerButton
              className={classes.colorPicker}
              color={instructions.sumRowBackgroundColor ?? '#ffffff'}
              colorPalette={getCategoricalColors(globalStyleConfig)}
              onColorChange={(newColor: string) => {
                updatePivotTableInstructions((draft) => {
                  draft.sumRowBackgroundColor = newColor;
                });
              }}
            />
          </div>
        </>
      )}
    </>
  );
};
