import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { sortBy } from 'utils/standard';
import { useHistory } from 'react-router';
import { useQuery } from 'utils/routerUtils';

import { EditorLeftColumn } from '../EditorLeftColumn';
import { AddDatasetButton } from 'components/resource/AddDatasetButton';
import { DatasetItem } from 'components/resource/DatasetItem';
import { DatasetConfig } from './DatasetConfig';
import { DatasetPreviewTable } from 'components/resource/DatasetPreviewTable';
import { sprinkles } from 'components/ds';

import { ReportBuilderConfig } from 'actions/reportBuilderConfigActions';
import { createReportBuilderDataset } from 'reducers/reportBuilderEditReducer';
import { fetchReportBuilderDataset } from 'reducers/thunks/reportBuilderEditorThunks';
import { ColumnHeader } from 'components/resource/ColumnHeader';
import { ReduxState } from 'reducers/rootReducer';
import { getReportBuilderSchema } from 'utils/reportBuilderConfigUtils';

const rootClass = sprinkles({ display: 'flex', flex: 1, overflow: 'hidden', height: 'fill' });

const configMenuClass = sprinkles({
  backgroundColor: 'gray1',
  borderRight: 1,
  borderColor: 'gray7',
  flexItems: 'column',
  height: 'fill',
  overflow: 'hidden',
});

const tableViewClass = sprinkles({
  flex: 1,
  flexItems: 'column',
  overflow: 'hidden',
  backgroundColor: 'gray2',
});

const emptyContainerClass = sprinkles({ flexItems: 'center', height: 'fill' });

type Props = {
  config: ReportBuilderConfig;
  reportBuilderId: number;
};

type SelectedDatasetInfo = { id: string; isNew: boolean };

export const DatasetEditor: FC<Props> = ({ config, reportBuilderId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();

  const [selectedDatasetInfo, setSelectedDatasetInfo] = useState<SelectedDatasetInfo | null>(null);
  const selectedDataset = selectedDatasetInfo ? config.datasets[selectedDatasetInfo.id] : undefined;

  const datasetData = useSelector((state: ReduxState) =>
    selectedDatasetInfo ? state.reportBuilderEdit.datasetData[selectedDatasetInfo.id] : undefined,
  );

  const sortedDatasets = useMemo(
    () => sortBy(Object.values(config.datasets), (dataset) => dataset.name),
    [config.datasets],
  );

  const switchSelectedDataset = useCallback(
    (datasetId: string, isNew?: boolean) => {
      dispatch(fetchReportBuilderDataset({ datasetId, switchedToDataset: true }));

      history.replace(`/report-builder/${reportBuilderId}/datasets?id=${datasetId}`);
      setSelectedDatasetInfo({ id: datasetId, isNew: isNew ?? false });
    },
    [dispatch, history, reportBuilderId],
  );

  useEffect(() => {
    if (selectedDatasetInfo || sortedDatasets.length === 0) return;

    const id = query.get('id');
    const dataset = config.datasets[id ?? ''] ?? sortedDatasets[0];
    switchSelectedDataset(dataset.id);
  }, [query, config.datasets, switchSelectedDataset, selectedDatasetInfo, sortedDatasets]);

  const noDatasetSelectedDiv = () => {
    return (
      <div className={emptyContainerClass} style={{ fontSize: 20 }}>
        Select a Dataset
      </div>
    );
  };

  return (
    <div className={rootClass}>
      <EditorLeftColumn>
        <div className={sprinkles({ width: 'fill', padding: 'sp1' })}>
          <AddDatasetButton
            datasets={sortedDatasets}
            onSubmit={(name, parentSchemaId) => {
              const newId = uuidv4();

              dispatch(createReportBuilderDataset({ id: newId, name, parentSchemaId }));
              switchSelectedDataset(newId, true);
            }}
          />
          {sortedDatasets.map((dataset) => {
            const isSelected = dataset.id === selectedDatasetInfo?.id;
            return (
              <DatasetItem
                dataset={dataset}
                isSelected={isSelected}
                key={dataset.id}
                onClick={() => {
                  if (isSelected) return;
                  switchSelectedDataset(dataset.id);
                }}
              />
            );
          })}
        </div>
      </EditorLeftColumn>
      <div className={configMenuClass} style={{ width: 545, minWidth: 545 }}>
        {selectedDataset ? (
          <DatasetConfig dataset={selectedDataset} isNew={selectedDatasetInfo?.isNew ?? false} />
        ) : (
          noDatasetSelectedDiv()
        )}
      </div>
      <div className={tableViewClass}>
        {selectedDataset ? (
          <>
            <ColumnHeader title="Preview" />
            <DatasetPreviewTable
              currentQuery={selectedDataset.queryDraft ?? selectedDataset.query}
              dataset={selectedDataset}
              error={datasetData?.error}
              handlePageChange={(offset) =>
                dispatch(
                  fetchReportBuilderDataset({
                    datasetId: selectedDataset.id,
                    page: Math.floor(offset / 50) + 1,
                  }),
                )
              }
              isLoading={!!datasetData?.loading}
              rowCount={datasetData?.rowCount}
              rows={datasetData?.rows}
              schema={getReportBuilderSchema(datasetData?.schema, selectedDataset, true)}
              unsupportedOperations={datasetData?.unsupportedOperations}
            />
          </>
        ) : (
          noDatasetSelectedDiv()
        )}
      </div>
    </div>
  );
};
