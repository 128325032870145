import {
  YAxisPlotLinesOptions,
  YAxisPlotBandsOptions,
  YAxisPlotLinesLabelOptions,
  YAxisOptions,
} from 'highcharts';

import { GoalLineFormat } from 'constants/types';
import { replaceTemplatesWithValues } from 'utils/dataPanelConfigUtils';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { ResourceDataset } from 'types/exploResource';

// Soft min and max allow setting values so that goal lines are always shown but can be passed
type GoalLineOptions = Pick<YAxisOptions, 'plotLines' | 'plotBands' | 'softMax' | 'softMin'>;

export const getGoalLines = (
  goalLines: GoalLineFormat[] | undefined,
  variables: DashboardVariableMap,
  datasets: Record<string, ResourceDataset>,
  xAxisGoals = false,
): GoalLineOptions => {
  if (!goalLines?.length) return {};
  const plotLines: YAxisPlotLinesOptions[] = [];
  const plotBands: YAxisPlotBandsOptions[] = [];
  let softMin: number | undefined = undefined;
  let softMax: number | undefined = undefined;

  goalLines.forEach((config) => {
    if (xAxisGoals ? !config.isXAxisGoal : config.isXAxisGoal) return;
    if (config.isGoalBand) {
      const values = getPlotBandValues(config, variables, datasets);
      if (!values) return;
      plotBands.push(getPlotBand(config, values));
      softMin = Math.min(softMin ?? 0, values.startValue);
      softMax = Math.max(softMax ?? 0, values.endValue);
    } else {
      const value = getPlotLineValue(config, variables, datasets);
      if (value === undefined) return;
      plotLines.push(getPlotLine(config, value));
      softMin = Math.min(softMin ?? 0, value);
      softMax = Math.max(softMax ?? 0, value);
    }
  });
  // Adding a bit of wiggle room so that the goal line isn't at
  // the top of the chart and cut off
  if (softMax !== undefined) softMax = softMax * 1.05;

  return { plotBands, plotLines, softMin, softMax };
};

const DefaultColor = '#FF0000';

type PlotBandValues = { startValue: number; endValue: number };

const getPlotBandValues = (
  config: GoalLineFormat,
  variables: DashboardVariableMap,
  datasets: Record<string, ResourceDataset>,
): PlotBandValues | undefined => {
  const startValueString = replaceTemplatesWithValues(
    String(config.goalValue || '100'),
    variables || {},
    datasets,
  );
  const startValue = parseFloat(startValueString);

  const endValueString = replaceTemplatesWithValues(
    String(config.goalValueMax || String(Number.MAX_VALUE)),
    variables || {},
    datasets,
  );
  const endValue = parseFloat(endValueString);

  if (isNaN(startValue) || isNaN(endValue)) return undefined;
  return { startValue, endValue };
};

const getPlotBand = (
  config: GoalLineFormat,
  { startValue, endValue }: PlotBandValues,
): YAxisPlotBandsOptions => {
  return {
    color: config.lineColor || DefaultColor,
    from: startValue,
    to: endValue,
    zIndex: 10,
    label: getLabelConfig(config),
  };
};

const getPlotLineValue = (
  config: GoalLineFormat,
  variables: DashboardVariableMap,
  datasets: Record<string, ResourceDataset>,
): number | undefined => {
  const valueString = replaceTemplatesWithValues(config.goalValue || '100', variables, datasets);

  const valueNum = parseFloat(valueString);
  if (isNaN(valueNum)) return;
  return valueNum;
};

const getPlotLine = (config: GoalLineFormat, value: number): YAxisPlotLinesOptions => {
  return {
    color: config.lineColor || DefaultColor,
    dashStyle: config.lineType,
    value,
    width: config.lineWidth || 1,
    zIndex: 10,
    label: getLabelConfig(config, true),
  };
};

const getLabelConfig = (config: GoalLineFormat, isLine = false): YAxisPlotLinesLabelOptions => {
  return {
    text: config.label || '',
    align: 'right',
    x: -4,
    y: isLine ? -8 : undefined,
    style: { color: config.labelColor || DefaultColor },
  };
};
