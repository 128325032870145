import { FC } from 'react';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';
import GoalInputRow from './GoalInputRow';
import DropdownSelect from 'shared/DropdownSelect';
import { sprinkles, Switch } from 'components/ds';

import { getCellAlignment } from 'components/dataTable/utils';
import { DatasetColumn } from 'types/datasets';
import { NumberDisplayFormat, NumberDisplayOptions, OPERATION_TYPES } from 'constants/types';
import { FLOAT, ORDERED_ALIGNMENTS, TIME_DIFF_FORMATS } from 'constants/dataConstants';
import { getCurrentNumberFormat } from 'utils/formatConfigUtils';

const inputClass = sprinkles({ marginTop: 'sp1' });
const sideBySideInputsClass = sprinkles({
  marginTop: 'sp1',
  flexItems: 'alignCenter',
  justifyContent: 'space-between',
  gap: 'sp1',
});

type Props = {
  updateNumberOptions: (newFields: NumberDisplayOptions) => void;
  column: DatasetColumn;
  displayOptions: NumberDisplayOptions | undefined;
  operationType:
    | OPERATION_TYPES.VISUALIZE_TABLE
    | OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST
    | 'REPORT_BUILDER';
};

export const NumberFormatConfig: FC<Props> = ({
  updateNumberOptions,
  column,
  displayOptions,
  operationType,
}) => {
  const { decimalPlaces, multiplier, hasCommas, goal, useColumnMaxForGoal, timeFormat } =
    displayOptions || {};
  const currentFormat = getCurrentNumberFormat(displayOptions);
  const currentAlignment = getCellAlignment(displayOptions, column.type);

  const onFormatButtonClick = (newFormat: NumberDisplayFormat) => {
    if (newFormat === currentFormat) return;

    updateNumberOptions({ format: newFormat });
  };

  return (
    <>
      <ToggleButtonGroup fillWidth className={inputClass} label="Formatting">
        <ToggleButton
          active={currentFormat === NumberDisplayFormat.NORMAL}
          onClick={() => onFormatButtonClick(NumberDisplayFormat.NORMAL)}
          text="-"
        />
        <ToggleButton
          active={currentFormat === NumberDisplayFormat.CURRENCY}
          onClick={() => onFormatButtonClick(NumberDisplayFormat.CURRENCY)}
          text="$"
        />
        <ToggleButton
          active={currentFormat === NumberDisplayFormat.PERCENT}
          onClick={() => onFormatButtonClick(NumberDisplayFormat.PERCENT)}
          text="%"
        />
        <ToggleButton
          active={currentFormat === NumberDisplayFormat.TIME}
          icon="time"
          onClick={() => onFormatButtonClick(NumberDisplayFormat.TIME)}
        />
      </ToggleButtonGroup>
      {currentFormat !== NumberDisplayFormat.TIME ? (
        <>
          <Switch
            useCustomStyles
            className={inputClass}
            label="Include thousands-separators"
            onChange={() => updateNumberOptions({ hasCommas: !hasCommas })}
            switchOn={hasCommas}
          />
          <div className={sideBySideInputsClass}>
            <InputWithBlurSave
              fillWidth
              initialValue={String(decimalPlaces ? decimalPlaces : column.type === FLOAT ? 2 : 0)}
              label="Decimal places"
              onNewValueSubmitted={(newValue) => {
                const newNum = Number(newValue);
                if (isNaN(newNum)) return;

                updateNumberOptions({ decimalPlaces: newNum });
              }}
            />
            <InputWithBlurSave
              fillWidth
              initialValue={String(multiplier ?? 1)}
              label="Multiplier"
              onNewValueSubmitted={(newValue) => {
                const newNum = Number(newValue);
                if (isNaN(newNum)) return;

                updateNumberOptions({ multiplier: newNum });
              }}
            />
          </div>
        </>
      ) : null}
      {currentFormat === NumberDisplayFormat.TIME && (
        <>
          <DropdownSelect
            btnMinimal
            fillWidth
            minimal
            containerClassName={inputClass}
            filterable={false}
            label="Time Format"
            noSelectionText="Standard"
            onChange={(item) => updateNumberOptions({ timeFormat: item })}
            options={Object.values(TIME_DIFF_FORMATS)}
            selectedItem={timeFormat || TIME_DIFF_FORMATS.STANDARD}
          />
          {displayOptions?.timeFormat?.id === TIME_DIFF_FORMATS.CUSTOM.id && (
            <InputWithBlurSave
              containerClassName={inputClass}
              initialValue={displayOptions?.timeCustomFormat}
              label="Custom Time Format"
              onNewValueSubmitted={(newValue) =>
                updateNumberOptions({ timeCustomFormat: newValue })
              }
              placeholder="DD days HH:mm:ss"
            />
          )}
        </>
      )}

      {currentFormat === NumberDisplayFormat.PERCENT && (
        <GoalInputRow
          disableColumnCalculation={operationType !== OPERATION_TYPES.VISUALIZE_TABLE}
          exactValue={goal}
          label="Denominator"
          type="max"
          updateExactValue={(newValue) => updateNumberOptions({ goal: newValue })}
          updateUsesColumnCalculation={(newValue) =>
            updateNumberOptions({ useColumnMaxForGoal: newValue })
          }
          usesColumnCalculation={useColumnMaxForGoal}
        />
      )}
      {
        // TODO: Implement alignment for collapsible lists
        operationType !== OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST ? (
          <ToggleButtonGroup fillWidth className={inputClass} label="Alignment">
            {ORDERED_ALIGNMENTS.map((alignment) => (
              <ToggleButton
                active={currentAlignment === alignment.id}
                icon={alignment.icon}
                key={`alignment-${alignment.id}-${column.name}`}
                onClick={() => updateNumberOptions({ alignment: alignment.id })}
              />
            ))}
          </ToggleButtonGroup>
        ) : null
      }
    </>
  );
};
