import { useDispatch } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core';

import DropdownSelect from 'shared/DropdownSelect';
import Button from 'shared/Button';
import InputLabel from 'shared/InputLabel';

import { updateApiToken } from 'actions/rolePermissionActions';
import { SelectedDropdownInputItem } from 'constants/types';
import { ApiToken } from 'actions/teamActions';
import { showSuccessToast } from 'shared/sharedToasts';

const useStyles = makeStyles((theme: Theme) => ({
  apiTokenBox: {
    background: theme.palette.ds.white,
    border: '1px solid',
    borderColor: theme.palette.ds.grey400,
    height: '32px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1,

    '&:hover': {
      cursor: 'pointer',
    },
  },
  apiTokenText: {
    paddingLeft: theme.spacing(3),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '90%',
  },
  copy: {
    color: theme.palette.ds.blue,
    fontWeight: 500,
    paddingRight: theme.spacing(3),
  },
  apiTokenRow: {
    display: 'flex',
    gap: `${theme.spacing(2)}px`,
    paddingBottom: theme.spacing(3),
  },
  icon: {
    padding: theme.spacing(2),
    cursor: 'pointer',
  },
  apiTokenSelectContainer: {
    display: 'flex',
    gap: `${theme.spacing(2)}px`,
    flexGrow: 1,
  },
  labeledToken: { width: '70%' },
  alignButtons: { display: 'flex', alignItems: 'flex-end' },
}));

type Props = {
  apiToken: ApiToken;
  setConfirmationModalOpen: () => void;
  setSelectedApiToken: () => void;
  setApiTokenAction: (action: 'refresh' | 'delete') => void;
};

const readOnlyAccessItem = { id: 'readonly', name: 'Read-Only' };
const readWriteAccessItem = { id: 'readwrite', name: 'Read-Write' };
const accessTypeOptions = [readOnlyAccessItem, readWriteAccessItem] as SelectedDropdownInputItem[];

const copyToClipboard = (apiToken: string) => {
  navigator.clipboard.writeText(apiToken);
  showSuccessToast('API Token Copied To Clipboard');
};

export default function SettingsAccessGroupsApiTokenSection(props: Props) {
  const { apiToken, setConfirmationModalOpen, setSelectedApiToken, setApiTokenAction } = props;
  const dispatch = useDispatch();

  const classes = useStyles();
  return (
    <div className={classes.apiTokenRow} key={apiToken.id}>
      <div className={classes.labeledToken}>
        <InputLabel text="API Token" />
        <div className={classes.apiTokenBox} onClick={() => copyToClipboard(apiToken.value)}>
          <div className={classes.apiTokenText}>{apiToken.value}</div>
          <div className={classes.copy}>Copy</div>
        </div>
      </div>
      <DropdownSelect
        fillWidth
        minimal
        filterable={false}
        label="Permissions"
        noSelectionText="Select an access type"
        onChange={(item) =>
          dispatch(
            updateApiToken({
              postData: {
                token_id: apiToken.id,
                is_read_only: item.id === readOnlyAccessItem.id,
              },
            }),
          )
        }
        options={accessTypeOptions}
        selectedItem={apiToken.is_read_only ? readOnlyAccessItem : readWriteAccessItem}
      />
      <div className={classes.alignButtons}>
        <Button
          minimal
          className={classes.icon}
          icon="refresh"
          onClick={() => {
            setConfirmationModalOpen();
            setSelectedApiToken();
            setApiTokenAction('refresh');
          }}
        />
        <Button
          minimal
          className={classes.icon}
          icon="trash"
          onClick={() => {
            setConfirmationModalOpen();
            setSelectedApiToken();
            setApiTokenAction('delete');
          }}
          type="destructive"
        />
      </div>
    </div>
  );
}
