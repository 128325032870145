import { FC } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import cx from 'classnames';

import * as styles from '../styles.css';
import { Icon, IconButton, sprinkles } from 'components/ds';

import * as constants from '../constants';
import { AccessGroup } from 'actions/teamActions';
import { cloneDeep, intersection } from 'utils/standard';
import { AccessGroupDataSourcesMap } from 'constants/types';
import { DataSource } from 'actions/dataSourceActions';

type Props = {
  accessGroup: AccessGroup;
  accessGroupDataSourcesMap?: AccessGroupDataSourcesMap;
  accessGroupIdToDefaultDataSourceIdUpdates?: Record<number, number[]>;
  dataSources?: DataSource[];
  updateAccessGroupDefaultDataSource: (
    accessGroupId: number,
    newDefaultDataSourceIds: number[],
  ) => void;
  isInModal?: boolean;
};

export const AccessGroupDropdown: FC<Props> = ({
  accessGroup,
  accessGroupDataSourcesMap,
  accessGroupIdToDefaultDataSourceIdUpdates,
  dataSources,
  updateAccessGroupDefaultDataSource,
  isInModal,
}) => {
  const accessGroupInfo = accessGroupDataSourcesMap?.[accessGroup.id];
  const availableDataSourceIds = accessGroupInfo?.availableDataSourceIds;
  const defaultDataSourceIdUpdates = accessGroupIdToDefaultDataSourceIdUpdates?.[accessGroup.id];
  const newDefault = intersection(defaultDataSourceIdUpdates, availableDataSourceIds)[0];

  const defaultDataSourceId = newDefault || accessGroupInfo?.defaultDataSourceId;
  const hasAvailableDataSources = !!availableDataSourceIds?.length;

  const getDataSourceName = (id: number | undefined) => {
    if (!id) return constants.DS_SELECTION_TEXT;
    return dataSources?.find((dataSource) => dataSource.id === id)?.name || '';
  };

  return (
    <div className={styles.labelAndInput} key={accessGroup.id}>
      <div className={styles.sideSheetLabelAndIcon}>
        <p className={styles.label}>
          {!isInModal ? accessGroup.name : `[${accessGroup.name}]: Default Data Source`}
        </p>
        {!hasAvailableDataSources ? (
          <IconButton
            className={styles.dropdownExclamationMark}
            name="circle-exclamation-reg"
            tooltipProps={{ text: constants.NO_AVAILABLE_DS_TEXT }}
            type="tertiary"
          />
        ) : !defaultDataSourceId ? (
          <IconButton
            className={styles.dropdownExclamationMark}
            name="circle-exclamation-reg"
            tooltipProps={{ text: constants.NO_DEFAULT_DS_TEXT }}
            type="tertiary"
          />
        ) : null}
      </div>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild disabled={!hasAvailableDataSources}>
          <div
            className={cx(
              styles.input,
              sprinkles({
                justifyContent: 'space-between',
                backgroundColor: hasAvailableDataSources ? 'white' : 'gray3',
                cursor: hasAvailableDataSources ? 'pointer' : 'not-allowed',
              }),
            )}>
            <p className={styles.label}>{getDataSourceName(defaultDataSourceId)}</p>
            {hasAvailableDataSources ? (
              <Icon className={sprinkles({ color: 'gray10' })} name="caret-down" />
            ) : null}
          </div>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content className={styles.accessGroupDropdown}>
          {availableDataSourceIds?.map((dataSourceId) => (
            <DropdownMenu.Item
              className={styles.accessGroupDropdownItem}
              key={dataSourceId}
              onClick={() => {
                let newDefaults = cloneDeep(
                  defaultDataSourceIdUpdates ?? accessGroup.default_data_source_ids,
                );

                if (defaultDataSourceId !== undefined) {
                  // remove old default
                  newDefaults = newDefaults.filter(
                    (dataSourceId) => defaultDataSourceId !== dataSourceId,
                  );
                }

                newDefaults.push(dataSourceId);

                updateAccessGroupDefaultDataSource(accessGroup.id, newDefaults);
              }}
              style={{ width: isInModal ? 556 : 500 }}>
              <div className={sprinkles({ display: 'flex', width: 'fill' })}>
                {getDataSourceName(dataSourceId)}
              </div>
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </div>
  );
};
