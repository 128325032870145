import { sortBy } from 'utils/standard';
import { createReducer } from '@reduxjs/toolkit';

import {
  createDashboardEmailSuccess,
  deleteDashboardEmailSuccess,
  editDashboardEmailSuccess,
  fetchEmailCadenceListSuccess,
  fetchEmailCadenceSuccess,
} from 'actions/emailActions';
import { ExploreEmailCadence } from 'actions/teamActions';

interface EmailReducerState {
  emailCadenceList?: ExploreEmailCadence[];
}

const emailReducerInitialState: EmailReducerState = {};

export default createReducer(emailReducerInitialState, (builder) => {
  builder
    .addCase(fetchEmailCadenceListSuccess, (state, { payload }) => {
      state.emailCadenceList = sortBy(
        payload.email_cadence_list,
        (emailCadence) => emailCadence.id,
      );
    })
    .addCase(fetchEmailCadenceSuccess, (state, { payload }) => {
      state.emailCadenceList = [payload.email_cadence];
    })
    .addCase(createDashboardEmailSuccess, (state, { payload }) => {
      state.emailCadenceList?.push(payload.cadence);
    })
    .addCase(deleteDashboardEmailSuccess, (state, { payload }) => {
      if (!state.emailCadenceList) return;

      state.emailCadenceList = state.emailCadenceList.filter(
        (emailCadence) => emailCadence.dashboard_template_id !== payload.dashboard_template_id,
      );
    })
    .addCase(editDashboardEmailSuccess, (state, { payload }) => {
      const cadence = state.emailCadenceList?.find(
        (emailCadence) =>
          emailCadence.dashboard_template_id === payload.cadence.dashboard_template_id,
      );
      if (cadence) {
        cadence.hour = payload.cadence.hour;
        cadence.minute = payload.cadence.minute;
        cadence.subject = payload.cadence.subject;
        cadence.from_email = payload.cadence.from_email;
        cadence.day_of_week = payload.cadence.day_of_week;
        cadence.week_of_month = payload.cadence.week_of_month;
        cadence.timezone = payload.cadence.timezone;
      }
    })
    .addDefaultCase((state) => state);
});
