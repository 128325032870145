import { EmailBody } from 'types/emailCadence';
import { defineEmbedPostAction } from './actionUtils';
import { ACTION } from './types';

export type ArchitectEmailCadence = {
  id: string;
  architect_customer_dashboard_id: number | null;
  tied_to_canvas: boolean;
} & EmailBody;

type CreateArchitectEmailCadenceBody = {
  canvas_embed_id: string;
  user_id: string | null;
  dashboard_id: number | null;
  email: EmailBody;
};

export const {
  actionFn: createArchitectEmailCadence,
  successAction: createArchitectEmailCadenceSuccess,
} = defineEmbedPostAction<CreateArchitectEmailCadenceBody, { email: ArchitectEmailCadence }>(
  ACTION.CREATE_END_USER_EMAIL,
  'architect_customer_dashboard',
  'create_email',
  'POST',
);

export const {
  actionFn: updateArchitectEmailCadence,
  successAction: updateArchitectEmailCadenceSuccess,
} = defineEmbedPostAction<{ email_id: string; email: EmailBody }, { email: ArchitectEmailCadence }>(
  ACTION.UPDATE_END_USER_EMAIL,
  'architect_customer_dashboard',
  'update_email',
  'POST',
);

export const {
  actionFn: deleteArchitectEmailCadence,
  successAction: deleteArchitectEmailCadenceSuccess,
} = defineEmbedPostAction<{ email_id: string }, { id: string }>(
  ACTION.DELETE_END_USER_EMAIL,
  'architect_customer_dashboard',
  'delete_email',
  'POST',
);
