import { makeStyles, Theme } from '@material-ui/core/styles';
import emptyStateArrow from 'images/app/emptyStateArrow.png';

const useStyles = makeStyles((theme: Theme) => ({
  emptyStateContainer: { marginLeft: 'auto', marginRight: '100px', position: 'relative' },
  emptyStateArrow: {
    float: 'right',
    marginRight: theme.spacing(3),
  },
  emptyStateText: {
    position: 'absolute',
    width: 175,
    fontSize: 14,
    textAlign: 'right',
    color: theme.palette.ds.grey800,
    top: 40,
    right: 100,
  },
}));

type Props = {
  text: string;
};

const EmptyState = ({ text }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.emptyStateContainer}>
      <img
        alt="call out indicating an action to take on an empty page"
        className={classes.emptyStateArrow}
        src={emptyStateArrow}
      />
      <p className={classes.emptyStateText}>{text}</p>
    </div>
  );
};

export default EmptyState;
