import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as styles from './styles.css';
import NavTabs from 'components/core/navTabs';
import { FilterSectionHeader } from './FilterSectionHeader';
import { SelectFilterTypeConfig } from 'components/FilterConfigs/SelectFilter/FilterTypeConfig';
import { SelectValuesConfig } from 'components/FilterConfigs/SelectFilter/ValuesConfig';
import { SelectDefaultValueConfig } from 'components/FilterConfigs/SelectFilter/DefaultValueConfig';
import { SelectAdjustDisplayConfig } from 'components/FilterConfigs/SelectFilter/AdjustDisplayConfig';
import { SelectEditInteractionsConfig } from 'components/FilterConfigs/SelectFilter/EditInteractionsConfig';
import { TextInputValuesConfig } from 'components/FilterConfigs/TextInputFilter/ValuesConfig';
import { TextInputDefaultValuesConfig } from 'components/FilterConfigs/TextInputFilter/DefaultValueConfig';
import { TextInputAdjustDisplayConfig } from 'components/FilterConfigs/TextInputFilter/AdjustDisplayConfig';
import { SwitchValuesConfig } from 'components/FilterConfigs/SwitchFilter/ValuesConfig';
import { SwitchDefaultValueConfig } from 'components/FilterConfigs/SwitchFilter/DefaultValueConfig';
import { SwitchAdjustDisplayConfig } from 'components/FilterConfigs/SwitchFilter/AdjustDisplayConfig';
import { DateGroupValuesConfig } from 'components/FilterConfigs/DateGroupFilter/ValuesConfig';
import { DateGroupDefaultValuesConfig } from 'components/FilterConfigs/DateGroupFilter/DefaultValueConfig';
import { DateGroupGroupLabelsConfig } from 'components/FilterConfigs/DateGroupFilter/GroupLabelsConfig';
import { TimePeriodValuesConfig } from 'components/FilterConfigs/TimePeriodFilter/ValuesConfig';
import { TimePeriodDefaultValuesConfig } from 'components/FilterConfigs/TimePeriodFilter/DefaultValueConfig';
import { TimePeriodEditInteractionsConfig } from 'components/FilterConfigs/TimePeriodFilter/EditInteractionsConfig';
import { ElementDependencyConfig } from './ElementDependencyConfig';
import { DateFilterTypeConfig } from 'components/FilterConfigs/DateFilter/FilterTypeConfig';
import { DateValuesConfig } from 'components/FilterConfigs/DateFilter/ValuesConfig';
import { DateGroupFilterTypeConfig } from 'components/FilterConfigs/DateGroupFilter/DateGroupFilterTypeConfig';
import { DateDefaultValuesConfig } from 'components/FilterConfigs/DateFilter/DefaultValueConfig';
import { DateEditInteractionsConfig } from 'components/FilterConfigs/DateFilter/EditInteractionsConfig';
import { DateAdjustDisplayConfig } from 'components/FilterConfigs/DateFilter/AdjustDisplayConfig';
import { LabelConfig, LabelConfigType } from 'components/FilterConfigs/LabelConfig';
import { OperatorConfig } from 'components/FilterConfigs/OperatorConfig';
import { ChartLinkConfig } from 'components/FilterConfigs/ChartLinkConfig';

import {
  DashboardElement,
  DashboardElementConfig,
  DASHBOARD_ELEMENT_TYPES,
  DateElemConfig,
  DateGroupToggleConfig,
  SelectElemConfig,
  SwitchElementConfig,
  TextInputElemConfig,
  TimePeriodDropdownElemConfig,
} from 'types/dashboardTypes';
import { updateElementConfig } from 'actions/dashboardV2Actions';
import { Dataset } from 'actions/datasetActions';
import { areFilterValuesComplete } from 'utils/filterUtils';
import { ReduxState } from 'reducers/rootReducer';
import { isFilterLinked } from 'utils/filterLinking';
import { FILTER_LINK_ELEMENTS } from 'constants/dashboardConstants';

enum FilterTab {
  SETUP = 'Setup',
  PREFERENCES = 'Preferences',
}

const Tabs = [
  { id: FilterTab.SETUP, name: FilterTab.SETUP },
  { id: FilterTab.PREFERENCES, name: FilterTab.PREFERENCES },
];

type Props = {
  allElements: DashboardElement[];
  datasets: Record<string, Dataset>;
  element: DashboardElement;

  updateConfig: (config: DashboardElementConfig) => void;
};

export const FilterConfigPanel: FC<Props> = ({ allElements, datasets, element, updateConfig }) => {
  const dispatch = useDispatch();

  const [tabId, setTabId] = useState(FilterTab.SETUP);

  const dataPanels = useSelector(
    (state: ReduxState) => state.dashboardEditConfig.config?.data_panels,
  );

  const renderSection = (
    component: JSX.Element | undefined,
    sectionTitle: string,
    isComplete?: boolean,
  ) => {
    if (!component) return null;
    return (
      <>
        <FilterSectionHeader isComplete={isComplete} title={sectionTitle} />
        <div className={styles.filterSectionContainer}>{component}</div>
      </>
    );
  };

  const renderFilterType = () => {
    let filterTypeDiv;
    switch (element.element_type) {
      case DASHBOARD_ELEMENT_TYPES.DROPDOWN:
      case DASHBOARD_ELEMENT_TYPES.MULTISELECT:
      case DASHBOARD_ELEMENT_TYPES.TOGGLE:
        filterTypeDiv = (
          <SelectFilterTypeConfig
            dataPanels={dataPanels}
            elementName={element.name}
            elementType={element.element_type}
            updateType={(newType) =>
              dispatch(updateElementConfig({ elementId: element.id, newElementType: newType }))
            }
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.DATEPICKER:
      case DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER:
        filterTypeDiv = (
          <DateFilterTypeConfig
            config={element.config as DateElemConfig}
            dataPanels={dataPanels}
            dateFilterType={element.element_type}
            elementName={element.name}
            updateDateConfig={updateConfig}
            updateType={(newType) =>
              dispatch(updateElementConfig({ elementId: element.id, newElementType: newType }))
            }
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH:
        filterTypeDiv = (
          <DateGroupFilterTypeConfig
            config={element.config as DateGroupToggleConfig}
            updateConfig={updateConfig}
          />
        );
    }

    return renderSection(filterTypeDiv, 'Filter type', true);
  };

  const renderFilterValues = () => {
    let filterValueDiv;
    switch (element.element_type) {
      case DASHBOARD_ELEMENT_TYPES.DROPDOWN:
      case DASHBOARD_ELEMENT_TYPES.MULTISELECT:
      case DASHBOARD_ELEMENT_TYPES.TOGGLE:
        filterValueDiv = (
          <SelectValuesConfig
            config={element.config as SelectElemConfig}
            datasets={datasets}
            selectType={element.element_type}
            updateSelectConfig={updateConfig}
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.TEXT_INPUT:
        filterValueDiv = (
          <TextInputValuesConfig
            config={element.config as TextInputElemConfig}
            updateInputConfig={updateConfig}
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.SWITCH:
        filterValueDiv = (
          <SwitchValuesConfig
            config={element.config as SwitchElementConfig}
            updateSwitchConfig={updateConfig}
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH:
        filterValueDiv = (
          <DateGroupValuesConfig
            config={element.config as DateGroupToggleConfig}
            updateToggleConfig={updateConfig}
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN:
        filterValueDiv = (
          <TimePeriodValuesConfig
            config={element.config as TimePeriodDropdownElemConfig}
            updateDropdownConfig={updateConfig}
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.DATEPICKER:
      case DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER: {
        const config = element.config as DateElemConfig;
        if (
          element.element_type === DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER &&
          !config.includeRangeDropdown
        )
          break;
        filterValueDiv = (
          <DateValuesConfig
            config={config}
            dateFilterType={element.element_type}
            updateDateConfig={updateConfig}
          />
        );
      }
    }

    return renderSection(filterValueDiv, 'Set the filter values', areFilterValuesComplete(element));
  };

  const renderChartLinking = () => {
    if (!FILTER_LINK_ELEMENTS.has(element.element_type)) return null;

    return (
      <>
        {renderSection(
          <OperatorConfig element={element} key={element.id} updateConfig={updateConfig} />,
          'Select an operator',
          element.config.operator !== undefined,
        )}
        {renderSection(
          <ChartLinkConfig
            config={element.config}
            dataPanels={dataPanels ?? {}}
            datasets={datasets}
            elementName={element.name}
            updateConfig={updateConfig}
          />,
          'Select fields to link charts',
          isFilterLinked(element.config),
        )}
      </>
    );
  };

  const renderDefaultValue = () => {
    let defaultValueDiv;
    switch (element.element_type) {
      case DASHBOARD_ELEMENT_TYPES.DROPDOWN:
      case DASHBOARD_ELEMENT_TYPES.MULTISELECT:
      case DASHBOARD_ELEMENT_TYPES.TOGGLE:
        defaultValueDiv = (
          <SelectDefaultValueConfig
            config={element.config as SelectElemConfig}
            selectType={element.element_type}
            updateSelectConfig={updateConfig}
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.TEXT_INPUT:
        defaultValueDiv = (
          <TextInputDefaultValuesConfig
            config={element.config as TextInputElemConfig}
            updateInputConfig={updateConfig}
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.SWITCH:
        defaultValueDiv = (
          <SwitchDefaultValueConfig
            config={element.config as SwitchElementConfig}
            updateSwitchConfig={updateConfig}
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH:
        defaultValueDiv = (
          <DateGroupDefaultValuesConfig
            config={element.config as DateGroupToggleConfig}
            updateToggleConfig={updateConfig}
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN:
        defaultValueDiv = (
          <TimePeriodDefaultValuesConfig
            config={element.config as TimePeriodDropdownElemConfig}
            updateDropdownConfig={updateConfig}
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.DATEPICKER:
      case DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER:
        defaultValueDiv = (
          <DateDefaultValuesConfig
            config={element.config as DateElemConfig}
            dateFilterType={element.element_type}
            updateDateConfig={updateConfig}
          />
        );
    }
    return renderSection(defaultValueDiv, 'Set the default value');
  };

  const renderAdjustDisplay = () => {
    let adjustDisplayDiv;
    switch (element.element_type) {
      case DASHBOARD_ELEMENT_TYPES.DROPDOWN:
      case DASHBOARD_ELEMENT_TYPES.MULTISELECT:
      case DASHBOARD_ELEMENT_TYPES.TOGGLE:
        adjustDisplayDiv = (
          <SelectAdjustDisplayConfig
            config={element.config as SelectElemConfig}
            selectType={element.element_type}
            updateSelectConfig={updateConfig}
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.TEXT_INPUT:
        adjustDisplayDiv = (
          <TextInputAdjustDisplayConfig
            config={element.config as TextInputElemConfig}
            updateInputConfig={updateConfig}
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.SWITCH:
        adjustDisplayDiv = (
          <SwitchAdjustDisplayConfig
            config={element.config as SwitchElementConfig}
            updateSwitchConfig={updateConfig}
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.DATEPICKER:
      case DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER:
        adjustDisplayDiv = (
          <DateAdjustDisplayConfig
            config={element.config as DateElemConfig}
            updateDateConfig={updateConfig}
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH:
      case DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN:
        adjustDisplayDiv = (
          <LabelConfig config={element.config as LabelConfigType} updateConfig={updateConfig} />
        );
    }
    return renderSection(adjustDisplayDiv, 'Adjust display');
  };

  const renderGroupLabels = () => {
    if (element.element_type === DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH) {
      const labelComponent = (
        <DateGroupGroupLabelsConfig
          config={element.config as DateGroupToggleConfig}
          updateToggleConfig={updateConfig}
        />
      );
      return renderSection(labelComponent, 'Group Labels');
    }
    return null;
  };

  const renderEditInteractions = () => {
    let editInteractionsDiv;
    switch (element.element_type) {
      case DASHBOARD_ELEMENT_TYPES.DROPDOWN:
      case DASHBOARD_ELEMENT_TYPES.MULTISELECT:
      case DASHBOARD_ELEMENT_TYPES.TOGGLE:
        editInteractionsDiv = (
          <SelectEditInteractionsConfig
            config={element.config as SelectElemConfig}
            selectType={element.element_type}
            updateSelectConfig={updateConfig}
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN:
        editInteractionsDiv = (
          <TimePeriodEditInteractionsConfig
            config={element.config as TimePeriodDropdownElemConfig}
            updateDropdownConfig={updateConfig}
          />
        );
        break;
      case DASHBOARD_ELEMENT_TYPES.DATEPICKER:
      case DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER:
        editInteractionsDiv = (
          <DateEditInteractionsConfig
            config={element.config as DateElemConfig}
            dateFilterType={element.element_type}
            updateDateConfig={updateConfig}
          />
        );
    }
    return renderSection(editInteractionsDiv, 'Edit interactions');
  };

  const renderDependencySelection = () => {
    return renderSection(
      <ElementDependencyConfig
        allElements={allElements}
        config={element.config}
        elementId={element.id}
        updateElemConfig={updateConfig}
      />,
      'Dependency Selection',
    );
  };

  const renderSetup = () => (
    <>
      {renderFilterType()}
      {renderFilterValues()}
      {renderChartLinking()}
    </>
  );

  const renderPreferences = () => (
    <>
      {renderDefaultValue()}
      {renderAdjustDisplay()}
      {renderGroupLabels()}
      {renderEditInteractions()}
      {renderDependencySelection()}
    </>
  );

  return (
    <>
      <NavTabs
        className={styles.filterTabContainer}
        onTabSelect={(tabId) => setTabId(tabId as FilterTab)}
        selectedTabId={tabId}
        tabs={Tabs}
      />
      {tabId === FilterTab.SETUP ? renderSetup() : renderPreferences()}
    </>
  );
};
