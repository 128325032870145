import { orderBy, uniqueId } from 'utils/standard';

import { DropdownOption, SelectedDropdownInputItem, INPUT_TYPE } from 'constants/types';
import { DropdownValuesConfig, MultiSelectSortOption } from 'types/dashboardTypes';
import { DatasetRow } from 'types/datasets';

export const constructOptions = (
  valuesConfig: DropdownValuesConfig,
  datasets: Record<string, { _rows?: DatasetRow[] }>,
): SelectedDropdownInputItem[] => {
  if (valuesConfig.valuesSource === INPUT_TYPE.MANUAL) {
    return constructManualOptions(valuesConfig);
  } else if (valuesConfig.valuesSource === INPUT_TYPE.QUERY) {
    return constructQueryOptions(valuesConfig, datasets);
  }

  return [];
};

export const constructManualOptions = (
  valuesConfig: DropdownValuesConfig,
): SelectedDropdownInputItem[] => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let valuesArr: any[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let displaysArr: any[] = [];

  // parse values JSON. if it is not valid or not an array, return no options
  try {
    valuesArr = JSON.parse(valuesConfig.manualValues);
    if (!Array.isArray(valuesArr)) return [];
  } catch {
    return valuesArr;
  }

  // prase displays JSON. if it is not valid or not an array, don't use displays
  try {
    const displaysArrJson = JSON.parse(valuesConfig.manualDisplays);
    if (Array.isArray(displaysArrJson)) displaysArr = displaysArrJson;
  } catch {
    return [];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return valuesArr.map((value: any, index: number) => {
    const option = {
      id: `option-${index}`,
      value: value,
      name: String(value),
    };

    if (displaysArr[index]) option.name = displaysArr[index];

    return option;
  });
};

const constructQueryOptions = (
  valuesConfig: DropdownValuesConfig,
  datasets: Record<string, { _rows?: DatasetRow[] }>,
): SelectedDropdownInputItem[] => {
  const { queryTable, queryValueColumn, queryDisplayColumn, querySortOption } = valuesConfig;
  if (!queryTable || !queryValueColumn) return [];
  const dataset = datasets[queryTable.id];

  if (!dataset || !dataset._rows) return [];

  const { rows } = dataset._rows.reduce(
    (acc, row) => {
      const value = row[queryValueColumn.name];
      const name = (queryDisplayColumn && row[queryDisplayColumn.name]) || value;

      if (!(value in acc.values)) {
        acc.rows.push({
          id: uniqueId('dash_dropdown_elem'),
          value,
          name: String(name),
        });
        acc.values[value] = 1;
      }

      return acc;
    },
    { rows: [] as DropdownOption[], values: {} as Record<string | number, number> },
  );
  if (querySortOption && querySortOption !== MultiSelectSortOption.DEFAULT) {
    return orderBy(rows, 'name', querySortOption === MultiSelectSortOption.ASC ? 'asc' : 'desc');
  }
  return rows;
};
