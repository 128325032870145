import produce from 'immer';

import FormatSection from './FormatSection';
import SharedKPIGeneralConfigs from 'components/ChartConfigs/KPIGeneralConfigs';

import { sharedStyles } from './sharedStyles';
import { FormatConfigProps } from './types';
import { KPIGeneralFormat, OPERATION_TYPES, V2KPIChartInstructions } from 'constants/types';

type Props = FormatConfigProps<OPERATION_TYPES.VISUALIZE_NUMBER_V2, V2KPIChartInstructions>;

export default function KPIGeneralSection({
  isArchitectCustomerDashboard,
  instructions,
  operationType,
  updateInstructions,
}: Props): JSX.Element {
  const classes = sharedStyles({ isArchitectCustomerDashboard });

  const updateFormat = (generalFormat: KPIGeneralFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.generalFormat = { ...draft.generalFormat, ...generalFormat };
    });
    updateInstructions({ instructions: newInstructions, operationType });
  };

  return (
    <FormatSection
      defaultIsOpen
      isArchitectCustomerDashboard={isArchitectCustomerDashboard}
      title="General">
      <SharedKPIGeneralConfigs
        configInputClass={classes.configInput}
        instructions={instructions}
        updateGeneralFormat={updateFormat}
      />
    </FormatSection>
  );
}
