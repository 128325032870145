import { useEffect, useState } from 'react';
import { ReduxState } from 'reducers/rootReducer';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { groupBy } from 'utils/standard';
import * as RD from 'remotedata';
import { Icon, Popover, MenuItem, Position } from '@blueprintjs/core';

import Button from 'shared/Button';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import { AlertModal } from 'components/ds';

import SettingsAccessGroupsSchemaSection from './settingsAccessGroupsSchemaSection';
import SettingsAccessGroupsApiTokenSection from './settingsAccessGroupsApiTokenSection';
import { TextFieldModal } from 'components/modals/textFieldModal';
import InfoCard from 'shared/InfoCard';

import {
  createAccessGroup,
  createApiToken,
  deleteAccessGroup,
  deleteApiToken,
  refreshApiToken,
  updateAccessGroups,
} from 'actions/rolePermissionActions';
import { listTeamDataSources } from 'actions/dataSourceActions';
import { fetchUsedParentSchemas } from 'actions/parentSchemaActions';
import { AccessGroup, ApiToken, fetchUserTeam } from 'actions/teamActions';
import { showErrorToast } from 'shared/sharedToasts';
import { fetchCustomers } from 'actions/customerActions';
import { isCreateDataVisiblityGroupsDisabled } from 'utils/paymentPlanUtils';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  description: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.ds.grey800,
    marginBottom: theme.spacing(6),
  },
  customerInfo: { marginBottom: theme.spacing(2) },
  defaultInfo: { marginBottom: theme.spacing(6) },
  accessGroupNameContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  accessGroupContainer: {
    background: theme.palette.ds.grey200,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    borderRadius: theme.spacing(1),
  },
  accessGroupName: {
    fontSize: 16,
    lineHeight: '19x',
    fontWeight: 500,
  },
  dataSourceAccess: {
    fontSize: 14,
    lineHeight: '17px',
    fontWeight: 600,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  addApiTokenButton: { width: '100%', marginBottom: theme.spacing(2) },
  apiTokens: { paddingTop: theme.spacing(1) },
  learnMoreLink: {
    background: theme.palette.ds.grey200,
    padding: '12px 14px',
    gap: 10,
    color: theme.palette.ds.blue,
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(6),
    fontSize: 12,
    borderRadius: 4,
  },
  learnMoreArrow: {
    color: theme.palette.ds.blue,
    cursor: 'pointer',
  },
  divider: {
    margin: `${theme.spacing(1.5)}px  ${theme.spacing(-3)}px ${theme.spacing(1.5)}px`,
    backgroundColor: theme.palette.ds.grey400,
    height: 1,
  },
  moreButton: { transform: 'rotate(-90deg)' },
  bold: { fontWeight: 700 },
  popoverBackground: {
    width: 209,
    border: `1px solid ${theme.palette.ds.grey400}`,
    borderRadius: 3,
    listStyle: 'none',
  },
  popoverContainer: {
    boxShadow: 'none',
    background: 'none',
    marginLeft: theme.spacing(6),
  },
  portalContainer: {
    '& .bp3-popover .bp3-popover-content': {
      boxShadow: `none`,
      backgroundColor: 'none',
      marginLeft: theme.spacing(6),
    },
  },
  red: { color: theme.palette.ds.red },
  item: {
    '&:hover': {
      backgroundColor: theme.palette.ds.lightBlue,
    },
  },
}));

export default function SettingsAccessGroupsSection() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [createAccessGroupModalOpen, setCreateAccessGroupModalOpen] = useState(false);
  const [hasConfirmedAccessGroupCreation, setHasConfirmedAccessGroupCreation] = useState(false);
  const [apiConfirmationModalOpen, setApiConfirmationModalOpen] = useState(false);
  const [accessGroupIdBeingEdited, setAccessGroupIdBeingEdited] = useState(-1);
  const [deleteAccessGroupConfirmationModalOpen, setDeleteAccessGroupConfirmationModalOpen] =
    useState(false);
  const [selectedApiToken, setSelectedApiToken] = useState(undefined as ApiToken | undefined);
  const [apiTokenAction, setApiTokenAction] = useState(
    undefined as 'delete' | 'refresh' | undefined,
  );
  const [selectedAccessGroup, setSelectedAccessGroup] = useState(
    undefined as AccessGroup | undefined,
  );

  const { team, dataSources, parentSchemas, customers } = useSelector(
    (state: ReduxState) => ({
      team: state.teamData.data,
      dataSources: state.dataSourceList.dataSources,
      parentSchemas: state.parentSchemas.usedParentSchemas,
      customers: state.customers.groups,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (!team) dispatch(fetchUserTeam());
  }, [team, dispatch]);
  useEffect(() => {
    if (!dataSources) dispatch(listTeamDataSources());
  }, [dataSources, dispatch]);
  useEffect(() => {
    if (!parentSchemas) dispatch(fetchUsedParentSchemas());
  }, [parentSchemas, dispatch]);
  useEffect(() => {
    if (RD.isIdle(customers)) dispatch(fetchCustomers());
  }, [dispatch, customers]);

  if (!team) return <></>;

  const dataSourcesBySchemaId = groupBy(
    dataSources ?? [],
    (dataSource) => dataSource.parent_schema_id,
  );

  const accessGroups = team.access_groups;

  const renderApiTokenConfirmationModal = () => {
    if (!apiConfirmationModalOpen || !selectedApiToken) return <></>;

    const closeModal = () => {
      setApiConfirmationModalOpen(false);
      setSelectedApiToken(undefined);
      setApiTokenAction(undefined);
    };

    const onConfirm = () => {
      closeModal();
      apiTokenAction === 'refresh'
        ? dispatch(refreshApiToken({ postData: { token_id: selectedApiToken.id } }))
        : dispatch(deleteApiToken({ postData: { token_id: selectedApiToken.id } }));
    };

    const isTokenRefresh = apiTokenAction === 'refresh';

    return (
      <AlertModal
        actionButtonProps={{
          text: isTokenRefresh ? 'Refresh' : 'Delete',
          onClick: onConfirm,
          type: isTokenRefresh ? 'primary' : 'destructive',
        }}
        isOpen={apiConfirmationModalOpen}
        onClose={closeModal}
        title={`Are you sure you want to ${isTokenRefresh ? 'refresh' : 'delete'} this API token?`}
      />
    );
  };

  const renderDeleteAccessGroupConfirmationModal = () => {
    if (!deleteAccessGroupConfirmationModalOpen || !selectedAccessGroup || !RD.isSuccess(customers))
      return <></>;

    const accessGroupCustomers = customers.data.filter(
      (eug) => eug.access_group_id === selectedAccessGroup.id,
    );

    const [bodyText, confirmButtonText] =
      accessGroupCustomers.length === 0
        ? [undefined, 'Delete']
        : [
            'This visibility group has end user groups associated with it and will cause errors if deleted.',
            'Delete Anyway',
          ];

    const closeModal = () => {
      setDeleteAccessGroupConfirmationModalOpen(false);
      setSelectedAccessGroup(undefined);
    };
    const onConfirm = () => {
      closeModal();
      dispatch(deleteAccessGroup({ postData: { access_group_id: selectedAccessGroup.id } }));
    };
    return (
      <AlertModal
        actionButtonProps={{ text: confirmButtonText, onClick: onConfirm }}
        isOpen={deleteAccessGroupConfirmationModalOpen}
        onClose={closeModal}
        title="Are you sure you want to delete this visibility group?">
        {bodyText}
      </AlertModal>
    );
  };

  const renderCreateAccessGroupModal = () =>
    accessGroups.length > 1 || hasConfirmedAccessGroupCreation ? (
      <TextFieldModal
        buttonName="Create"
        closeModal={() => {
          setHasConfirmedAccessGroupCreation(false);
          setCreateAccessGroupModalOpen(false);
        }}
        modalOpen={createAccessGroupModalOpen}
        modalTitle={'Create a Visibility Group'}
        onSubmit={(name: string) => {
          setHasConfirmedAccessGroupCreation(false);
          dispatch(
            createAccessGroup({ postData: { name: name } }, undefined, (error) => {
              showErrorToast(error.detail, 7);
            }),
          );
        }}
        textFieldPlaceholder="e.g. Developers"
      />
    ) : (
      <AlertModal
        actionButtonProps={{
          text: 'Create Visibility Group',
          onClick: () => setHasConfirmedAccessGroupCreation(true),
          type: 'primary',
        }}
        isOpen={createAccessGroupModalOpen}
        onClose={() => setCreateAccessGroupModalOpen(false)}
        title="Are you sure you want to create another Visibility Group?">
        Managing multiple visibility groups will require you to separately define which customers
        and datasets should be available for each visibility group
      </AlertModal>
    );

  const boldedWord = (word: string) => <span className={classes.bold}>{word}</span>;

  const visibilityGroupHeader = (accessGroup: AccessGroup) => {
    return (
      <div className={classes.accessGroupNameContainer}>
        {accessGroupIdBeingEdited !== accessGroup.id ? (
          <div className={classes.accessGroupName}>{accessGroup.name}</div>
        ) : (
          <InputWithBlurSave
            fillWidth
            className={classes.accessGroupName}
            initialValue={accessGroup.name}
            onNewValueSubmitted={(newValue) => {
              if (newValue.trim() === '') return;
              const new_access_groups = [{ access_group_id: accessGroup.id, name: newValue }];
              dispatch(updateAccessGroups({ postData: { access_groups: new_access_groups } }));
              setAccessGroupIdBeingEdited(-1);
            }}
          />
        )}
        <Popover
          minimal
          usePortal
          content={
            <div className={classes.popoverBackground}>
              <MenuItem
                className={classes.item}
                onClick={() => setAccessGroupIdBeingEdited(accessGroup.id)}
                text="Rename"
              />
              <MenuItem
                className={classes.item}
                disabled={accessGroups.length === 1}
                onClick={() => {
                  setSelectedAccessGroup(accessGroup);
                  setDeleteAccessGroupConfirmationModalOpen(true);
                }}
                text="Delete"
                textClassName={classes.red}
              />
            </div>
          }
          popoverClassName={classes.popoverContainer}
          portalClassName={classes.portalContainer}
          position={Position.RIGHT_TOP}>
          <Button minimal className={classes.moreButton} icon="more" />
        </Popover>
      </div>
    );
  };

  const isDataVisiblityButtonGroupsDisabled = isCreateDataVisiblityGroupsDisabled(
    accessGroups.length,
    team.payment_plan,
  );

  return (
    <div>
      <div className={classes.header}>Data Visibility</div>
      <div className={classes.description}>
        {`This defines who can see which data. It can help you ensure that some of your collaborators
        can contribute to your dashboards without seeing your customer's data.`}
      </div>
      {accessGroups.length === 1 ? (
        <div className={classes.learnMoreLink}>
          <div>Learn more about how Visibility Groups can be used</div>
          <Icon
            className={classes.learnMoreArrow}
            icon="arrow-right"
            onClick={() => window.open('https://docs.explo.co/managing-permissions/access-groups')}
          />
        </div>
      ) : (
        <>
          <InfoCard
            className={classes.customerInfo}
            leftIcon="people"
            text={
              <>
                Reassign customers to a new visibility group from the {boldedWord('Customers')} tab.
              </>
            }
          />
          <InfoCard
            className={classes.defaultInfo}
            leftIcon="database"
            text={<>Assign default data sources on the {boldedWord('Data')} tab.</>}
          />
        </>
      )}
      {accessGroups.map((accessGroup) => {
        return (
          <div className={classes.accessGroupContainer} key={accessGroup.id}>
            {visibilityGroupHeader(accessGroup)}
            <div className={classes.divider} />
            <div className={classes.apiTokens}>
              {accessGroup.api_tokens.map((apiToken) => (
                <SettingsAccessGroupsApiTokenSection
                  apiToken={apiToken}
                  key={apiToken.id}
                  setApiTokenAction={setApiTokenAction}
                  setConfirmationModalOpen={() => setApiConfirmationModalOpen(true)}
                  setSelectedApiToken={() => setSelectedApiToken(apiToken)}
                />
              ))}
            </div>
            <Button
              className={classes.addApiTokenButton}
              icon="plus"
              onClick={() =>
                dispatch(createApiToken({ postData: { access_group_id: accessGroup.id } }))
              }
              text="Add an API Token"
              type="primary"
            />
            <div className={classes.divider} />
            <div className={classes.dataSourceAccess}>Data Source Access</div>
            {(parentSchemas || []).map((schema) => (
              <SettingsAccessGroupsSchemaSection
                accessGroup={accessGroup}
                dataSources={dataSourcesBySchemaId[schema.id]}
                defaultDataSources={accessGroup.default_data_source_ids}
                key={schema.id}
                scheme={schema}
              />
            ))}
          </div>
        );
      })}
      <Button
        disabled={isDataVisiblityButtonGroupsDisabled}
        icon="plus"
        onClick={() => setCreateAccessGroupModalOpen(true)}
        text="Add a Visibility Group"
        tooltipText={
          isDataVisiblityButtonGroupsDisabled
            ? 'Upgrade your plan to add more data visibility groups.'
            : undefined
        }
        type="primary"
      />
      {renderApiTokenConfirmationModal()}
      {renderCreateAccessGroupModal()}
      {renderDeleteAccessGroupConfirmationModal()}
    </div>
  );
}
