import { Component } from 'react';
import { isEqual } from 'utils/standard';
import { MapContainer, Tooltip, GeoJSON } from 'react-leaflet';
import { GeoJsonObject } from 'geojson';
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core/styles';

import { mixColors, numberWithCommas } from 'utils/general';
import { DatasetSchema } from 'types/datasets';
import { ChartColumnInfo, REGION_TYPES } from 'constants/types';
import { REGIONS } from 'constants/dataConstants';
import { GlobalStyleConfig } from 'globalStyles/types';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.ds.white,
      height: 'calc(100% - 63px)',
      borderRadius: 8,

      '& .leaflet-container': {
        width: '100%',
        height: '100%',
        zIndex: 3,
      },
    },
    titleContainer: {
      padding: theme.spacing(6),
      paddingBottom: 0,

      '&.CENTER_ALIGN': {
        textAlign: 'center',
      },
      '&.RIGHT_ALIGN': {
        textAlign: 'right',
      },
    },
    title: {
      color: '#AAAAAA',
      fontWeight: 500,
      fontSize: 16,
    },
  });

type PassedProps = {
  region: string;
  densityColumn: ChartColumnInfo;
  regionColumn: ChartColumnInfo;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  schema: DatasetSchema;
  globalStyleConfig: GlobalStyleConfig;
};

type State = {
  toolTipRegion: string;
  toolTipDensity: number;
  mapContainerKeyCounter: number;
};

type Props = PassedProps & WithStyles<typeof styles>;

class ChoroplethMap extends Component<Props, State> {
  state: State = {
    toolTipRegion: '',
    toolTipDensity: 0,
    mapContainerKeyCounter: 0,
  };

  regionToDensity: { [region: string]: number } = {};
  densityMax: number = Number.MIN_SAFE_INTEGER;
  densityMin: number = Number.MAX_SAFE_INTEGER;

  componentDidMount() {
    this.constructRegionToDensityAndRanges();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      !isEqual(prevProps.densityColumn, this.props.densityColumn) ||
      !isEqual(prevProps.regionColumn, this.props.regionColumn) ||
      !isEqual(prevProps.data, this.props.data)
    ) {
      this.regionToDensity = {};
      this.densityMax = Number.MIN_SAFE_INTEGER;
      this.densityMin = Number.MAX_SAFE_INTEGER;
      this.constructRegionToDensityAndRanges();
    }

    if (prevProps.region !== this.props.region) {
      this.onRegionChange();
    }
  }

  render() {
    const { classes, region } = this.props;
    const { mapContainerKeyCounter } = this.state;
    const regionsMetaData = REGIONS[region];

    return (
      <div className={classes.root}>
        <MapContainer
          center={regionsMetaData.center}
          key={mapContainerKeyCounter}
          zoom={regionsMetaData.zoom}>
          {this.getGeoJsonData()}
        </MapContainer>
      </div>
    );
  }

  onRegionChange = () => {
    const { mapContainerKeyCounter } = this.state;
    this.setState({ mapContainerKeyCounter: mapContainerKeyCounter + 1 });
  };

  getDensityColumnName = () => {
    const { schema } = this.props;
    return schema[1].name;
  };

  constructRegionToDensityAndRanges = () => {
    const { regionColumn, data } = this.props;
    const { mapContainerKeyCounter } = this.state;
    const regionColumnName = regionColumn.name ? regionColumn.name : '';
    const densityColumnName = this.getDensityColumnName();
    const regionToDensityMap: { [region: string]: number } = {};
    for (let i = 0; i < data.length; i++) {
      const row = data[i];
      const densityVal = row[densityColumnName];
      let regionName =
        typeof row[regionColumnName] === 'string'
          ? row[regionColumnName].trim()
          : row[regionColumnName];
      regionName = this.getCorrectRegionName(regionName);
      regionToDensityMap[regionName] = densityVal;

      if (densityVal < this.densityMin) this.densityMin = densityVal;
      if (densityVal > this.densityMax) this.densityMax = densityVal;
    }

    this.regionToDensity = regionToDensityMap;
    this.setState({ mapContainerKeyCounter: mapContainerKeyCounter + 1 });
  };

  getCorrectRegionName = (rawRegionName: string) => {
    const { region } = this.props;
    if (region === REGION_TYPES.WORLD) {
      return rawRegionName in window.exploAssets.worldGeoJsonMapping
        ? window.exploAssets.worldGeoJsonMapping[rawRegionName]
        : rawRegionName;
    } else if (region === REGION_TYPES.UNITED_STATES) {
      return rawRegionName in window.exploAssets.unitedStatesGeoJsonMapping
        ? window.exploAssets.unitedStatesGeoJsonMapping[rawRegionName]
        : rawRegionName;
    } else {
      return rawRegionName;
    }
  };

  getColor = (density: number) => {
    const { globalStyleConfig } = this.props;
    // Prevent this from being 0.
    const densityEighths = Math.max((this.densityMax - this.densityMin) / 8.0, 1);

    const minColor = globalStyleConfig.visualizations.gradientPalette.hue1;
    const maxColor = globalStyleConfig.visualizations.gradientPalette.hue2;

    if (density > this.densityMax - densityEighths * 1) {
      return maxColor;
    } else if (density > this.densityMax - densityEighths * 2) {
      return mixColors(minColor, maxColor, 0.143).rgb().toString();
    } else if (density > this.densityMax - densityEighths * 3) {
      return mixColors(minColor, maxColor, 0.286).rgb().toString();
    } else if (density > this.densityMax - densityEighths * 4) {
      return mixColors(minColor, maxColor, 0.475).rgb().toString();
    } else if (density > this.densityMax - densityEighths * 5) {
      return mixColors(minColor, maxColor, 0.571).rgb().toString();
    } else if (density > this.densityMax - densityEighths * 6) {
      return mixColors(minColor, maxColor, 0.714).rgb().toString();
    } else if (density > this.densityMax - densityEighths * 7) {
      return mixColors(minColor, maxColor, 0.857).rgb().toString();
    } else if (density > 0) {
      return minColor;
    } else {
      return '';
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  styleRegions = (feature: any) => {
    const obj = {
      fillColor:
        feature.properties.name in this.regionToDensity
          ? this.getColor(this.regionToDensity[feature.properties.name])
          : '',
      weight: 2,
      opacity: 1,
      color: 'white',
      dashArray: '3',
      fillOpacity: 0.7,
    };
    return obj;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  highlightFeature = (e: any) => {
    const layer = e.target;
    const highlightedRegion = layer['feature']['properties']['name'];

    layer.setStyle({
      weight: 5,
      color: '#666',
      dashArray: '',
      fillOpacity: 0.7,
    });
    this.setState({
      toolTipRegion: highlightedRegion,
      toolTipDensity: this.regionToDensity[highlightedRegion],
    });
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resetHighlight = (e: any) => {
    const layer = e.target;

    layer.setStyle({
      weight: 2,
      color: 'white',
      dashArray: '3',
      fillOpacity: 0.7,
    });
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onEachFeature = (feature: any, layer: any) => {
    layer.on({
      mouseover: this.highlightFeature,
      mouseout: this.resetHighlight,
    });
  };

  getGeoJsonData = () => {
    const { region } = this.props;
    const { toolTipDensity, toolTipRegion } = this.state;

    let geoJson = null;
    if (region === REGION_TYPES.WORLD) {
      geoJson = window.exploAssets.worldGeoJson;
    } else if (region === REGION_TYPES.UNITED_STATES) {
      geoJson = window.exploAssets.unitedStatesGeoJson;
    } else {
      geoJson = window.exploAssets.worldGeoJson;
    }

    return (
      <GeoJSON
        data={geoJson as GeoJsonObject}
        onEachFeature={this.onEachFeature}
        style={this.styleRegions}>
        <Tooltip sticky>{`${toolTipRegion} (${numberWithCommas(toolTipDensity || 0)})`}</Tooltip>
      </GeoJSON>
    );
  };
}

export default withStyles(styles)(ChoroplethMap);
