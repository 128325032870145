import {
  AGGREGATIONS_LIST,
  COLLAPSIBLE_LIST_AGGREGATION_LIST,
  NON_AGG_TYPE,
  NON_NUMBER_AGGREGATIONS_LIST,
  NUMBER_TYPES,
} from 'constants/dataConstants';
import { AggedChartColumnInfo, Aggregation, AggregationType } from 'constants/types';
import { DatasetColumn } from 'types/datasets';

export const findMatchingAggColIdx = (
  aggregations: AggedChartColumnInfo[] | undefined,
  colName: string | undefined,
  aggOption: { id: string; formula?: string } | undefined,
): number => {
  if (!aggregations) return -1;

  return aggregations.findIndex(({ column, agg }) => {
    if (column.name !== colName) return false;
    // If no agg needed for column and same name then equal
    if (!aggOption) return true;

    // If its the same agg then return true, but if its a formula check the formula
    return (
      agg.id === aggOption.id &&
      (aggOption.id !== Aggregation.FORMULA || aggOption.formula === agg.formula)
    );
  });
};

export const findMatchingAgg = (
  aggregations: AggedChartColumnInfo[] | undefined,
  colName: string | undefined,
  aggType: AggregationType | undefined,
): AggedChartColumnInfo | undefined => {
  const index = findMatchingAggColIdx(aggregations, colName, aggType);
  return aggregations?.[index];
};

export const getDefaultAgg = (type: string): AggregationType => {
  if (NUMBER_TYPES.has(type)) return { id: Aggregation.SUM };
  return { id: Aggregation.COUNT };
};

export const getNextAggType = (
  newCol: DatasetColumn,
  currAggTypes?: AggregationType[],
  isCollapsibleListAgg?: boolean,
) => {
  const defaultAgg = getDefaultAgg(newCol.type);
  if (!currAggTypes || currAggTypes.length === 0) return defaultAgg;

  const currentAggTypesSet = new Set(currAggTypes.map((agg) => agg.id));
  if (!currentAggTypesSet.has(defaultAgg.id)) return defaultAgg;

  const validAggTypes = getAggOptions(newCol.type, isCollapsibleListAgg);

  const validType = validAggTypes.find((aggType) => !currentAggTypesSet.has(aggType.id));
  if (validType) return { id: validType.id };
};

export const getAggOptions = (
  colType: string,
  isCollapsibleListAgg?: boolean,
  includeFirst?: boolean,
): { id: Aggregation; name: string }[] => {
  const isNumber = NUMBER_TYPES.has(colType);
  if (isCollapsibleListAgg) {
    return isNumber ? COLLAPSIBLE_LIST_AGGREGATION_LIST : NON_NUMBER_AGGREGATIONS_LIST;
  }

  const options = isNumber ? [...AGGREGATIONS_LIST] : [...NON_NUMBER_AGGREGATIONS_LIST];
  if (includeFirst) options.push(NON_AGG_TYPE);
  return options;
};
