import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import ElementGridLayout from 'components/DashboardLayout/ElementGridLayout';

import { NUM_MOBILE_COLUMNS } from 'constants/dashboardConstants';
import { withWidth, WithWidthProps } from 'components/HOCs/withWidth';
import { ContainerElemConfig, VIEW_MODE } from 'types/dashboardTypes';
import { PassedProps as ElementGridLayoutProps } from 'components/DashboardLayout/ElementGridLayout';
import { compactLayout, getLayoutFromContainerConfig } from 'utils/layoutUtils';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
}));

type Props = {
  dashboardLayoutProps: ElementGridLayoutProps;
  id: string;
  config: ContainerElemConfig;
  isResizing: boolean;
  isMobileView?: boolean;
  viewMode: VIEW_MODE;
} & WithWidthProps;

function DashboardContainerElement({
  dashboardLayoutProps,
  id,
  config,
  isResizing,
  isMobileView,
  viewMode,
  width,
}: Props) {
  const [savedWidth, setSavedWidth] = useState(width);
  const classes = useStyles();
  /**
   * We don't want to change the width if we are resizing the container so we don't see it jump
   * around when we're finished resizing. We want child elements to stay in place.
   */
  useEffect(() => {
    if (!isResizing) setSavedWidth(width);
  }, [width, isResizing]);
  const { dashboardLayout } = dashboardLayoutProps;

  const layout = getLayoutFromContainerConfig(config, viewMode);

  const layoutElement = dashboardLayout.find((l) => l.i === id);

  return (
    <div className={classes.root}>
      <ElementGridLayout
        {...dashboardLayoutProps}
        columns={isMobileView ? NUM_MOBILE_COLUMNS : layoutElement?.w}
        containerId={id}
        containerLayout={layoutElement}
        dashboardLayout={isMobileView ? compactLayout(layout) : layout}
        width={isResizing ? savedWidth : width}
      />
    </div>
  );
}

export default withWidth(DashboardContainerElement);
