import { FC } from 'react';
import cx from 'classnames';

import { Root, Trigger } from '@radix-ui/react-dropdown-menu';
import { DropdownMenuContent } from 'pages/ReportBuilder/DropdownMenu/DropdownMenuContent';
import { Icon, sprinkles } from 'components/ds';
import * as styles from './FilterDropdown.css';

type Props = {
  items: { id: string; name: string }[];
  selectedItem?: { id: string; name: string };
  placeholder?: string;
  triggerClassName?: string;

  onOpenChange: (open: boolean) => void;
  onSelect: (id: string) => void;
};

export const FilterDropdown: FC<Props> = ({
  items,
  selectedItem,
  placeholder,
  triggerClassName,
  onOpenChange,
  onSelect,
}) => {
  return (
    <Root onOpenChange={onOpenChange}>
      <Trigger asChild>
        <div className={cx(triggerClassName, styles.dropdownButton)}>
          <span
            className={cx(
              styles.selectedName,
              selectedItem ? undefined : sprinkles({ color: 'contentTertiary' }),
            )}>
            {selectedItem?.name ?? placeholder}
          </span>
          <Icon name="caret-down" size="sm" />
        </div>
      </Trigger>
      <DropdownMenuContent items={items} onSelect={onSelect} selectedItemId={selectedItem?.id} />
    </Root>
  );
};
