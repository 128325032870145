import { uniqueId } from 'utils/standard';
import { ThemeProvider, StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import theme from 'theme';

type Props = {
  children: JSX.Element;
};

const generateClassName = createGenerateClassName({
  productionPrefix: uniqueId('explo-embed-tooltip'),
});

export default function TooltipWrapper(props: Props) {
  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </StylesProvider>
  );
}
