import { PureComponent } from 'react';
import { flatten, partition, sortBy, orderBy } from 'utils/standard';
import Highcharts, { PointOptionsObject } from 'highcharts';
import { format } from 'utils/localizationUtils';
import ReactDOMServer from 'react-dom/server';
import NeedsConfigurationPanel from 'pages/dashboardPage/DashboardDatasetView/needsConfigurationPanel';
import { DrilldownChart } from './shared/drilldownChart';

import {
  getColorColNames,
  xAxisFormat,
  formatLegend,
  formatLabel,
  getColorPalette,
  formatValue,
  getLabelStyle,
  isTwoDimVizInstructionsReadyToDisplay,
  areRequiredVariablesSetTwoDimViz,
  shouldProcessColAsDate,
  getColorZones,
} from './utils';
import {
  ColorCategoryTracker,
  GROUPED_STACKED_OPERATION_TYPES,
  LineElasticity,
  OPERATION_TYPES,
  SortAxis,
  SortOption,
  V2TwoDimensionChartInstructions,
  VisualizeOperationGeneralFormatOptions,
} from 'constants/types';
import { DATE_TYPES } from 'constants/dataConstants';
import { PivotAgg } from 'types/dateRangeTypes';
import { DashboardVariableMap, DashboardVariable, DrilldownVariable } from 'types/dashboardTypes';
import { DatasetSchema } from 'types/datasets';
import { getColDisplayText } from 'pages/dashboardPage/DataPanelConfigV2/DataConfigTab/vizConfigs/utils';
import { GlobalStyleConfig } from 'globalStyles/types';
import {
  COMBO_CHART_DATA_FORMATS,
  NONE_CATEGORY_COLOR_VALUE,
  ChartShapeBorderDefaultColor,
  chartShapeBorderDefaultWidth,
} from 'constants/dashboardConstants';
import {
  createYAxisBaseTooltip,
  getMultiYAxisInstructions,
  getSingleYAxisInstructions,
  getValueFormat,
  getYAxisFormatById,
  getYAxisChartIndex,
} from './utils/multiYAxisUtils';
import { formatTwoDimensionalData } from 'dataFormatters/twoDimensionalDataFormatter';
import { getTimezoneAwareUnix } from 'utils/timezoneUtils';
import { getColorTrackerCategoryName } from 'utils/colorCategorySyncUtils';
import { ResourceDataset } from 'types/exploResource';
import { OpenDrilldownModalType } from 'types/dashboardTypes';
import { isSelectedColorDateType, getColorColumn } from 'utils/colorColUtils';
import { ChartMenuInfo } from 'reducers/dashboardLayoutReducer';

const ONE_SECOND = 1000;
const ONE_HOUR = ONE_SECOND * 60 * 60;
const ONE_DAY = ONE_HOUR * 24;
const ONE_WEEK = ONE_DAY * 7;
const ONE_MONTH = ONE_WEEK * 4;
const ONE_YEAR = ONE_MONTH * 12;

type Props = {
  backgroundColor: string;
  loading?: boolean;
  previewData: Record<string, string | number>[];
  instructions?: V2TwoDimensionChartInstructions;
  dataPanelTemplateId: string;
  variables: DashboardVariableMap;
  schema: DatasetSchema;
  selectedColorColName?: string;
  normalize?: boolean;
  grouped?: boolean;
  horizontal?: boolean;
  generalOptions?: VisualizeOperationGeneralFormatOptions;
  globalStyleConfig: GlobalStyleConfig;
  isComboChart?: boolean;
  canUseMultiYAxis?: boolean;
  setVariable?: (value: DashboardVariable) => void;
  openDrilldownModal?: OpenDrilldownModalType;
  colorCategoryTracker?: ColorCategoryTracker;
  dataPanelProvidedId: string;
  datasets: Record<string, ResourceDataset>;
  operationType?: OPERATION_TYPES;
  setChartMenu: (info: ChartMenuInfo | null) => void;
};

type SeriesOptions = (
  | Highcharts.SeriesLineOptions
  | Highcharts.SeriesColumnOptions
  | Highcharts.SeriesSplineOptions
  | Highcharts.SeriesBarOptions
) & {
  rawColorData?: string | number;
  // Highcarts has this set as possibly undefined so just defining it
  // since we never set it as undefined and makes it cleaner when used
  data: Array<number | [number | string, number | null] | null | PointOptionsObject>;
};

class BarChart extends PureComponent<Props> {
  render() {
    const {
      generalOptions,
      instructions,
      loading,
      operationType,
      variables,
      dataPanelTemplateId,
      openDrilldownModal,
      selectedColorColName,
      setChartMenu,
    } = this.props;
    const requiredVarNotsSet = !areRequiredVariablesSetTwoDimViz(variables, instructions);
    const instructionsReadyToDisplay = isTwoDimVizInstructionsReadyToDisplay(
      instructions,
      operationType,
    );

    if (loading || !instructionsReadyToDisplay || requiredVarNotsSet) {
      return (
        <NeedsConfigurationPanel
          fullHeight
          instructionsNeedConfiguration={!instructionsReadyToDisplay}
          loading={loading}
          requiredVarsNotSet={requiredVarNotsSet}
        />
      );
    }

    const drilldownVar = this.getDrilldownVariable();

    const spec = this._spec();

    return (
      <DrilldownChart
        chartOptions={spec}
        closeChartMenu={() => setChartMenu(null)}
        customMenuOptions={
          generalOptions?.customMenu?.enabled ? generalOptions.customMenu.menuOptions : undefined
        }
        dataPanelTemplateId={dataPanelTemplateId}
        drilldownVar={drilldownVar}
        instructions={instructions}
        openDrilldownModal={openDrilldownModal}
        selectedColorColName={selectedColorColName}
        setCategorySelect={
          instructions?.drilldown?.categorySelectEnabled ? this.setCategorySelect : undefined
        }
        underlyingDataEnabled={this.getUnderlyingDrilldownEnabled()}
      />
    );
  }

  setCategorySelect = (category: string, subCategory?: string) => {
    const { setVariable } = this.props;
    if (!setVariable) return;

    const drilldownVar = this.getDrilldownVariable();

    if (category === drilldownVar?.category && subCategory === drilldownVar.color) {
      setVariable({ category: undefined, color: undefined });
    } else setVariable({ category, color: this.isColorColUsed() ? subCategory : undefined });
  };

  _spec = (): Highcharts.Options | undefined => {
    const {
      horizontal,
      previewData,
      schema,
      instructions,
      normalize,
      backgroundColor,
      generalOptions,
      globalStyleConfig,
      canUseMultiYAxis,
      variables,
      datasets,
      setChartMenu,
      dataPanelTemplateId,
    } = this.props;
    if (schema?.length === 0 || !previewData) return;

    // this is a short term fix en lieu of this bug being fixed by vega:
    // Ref: TU/447fn2df
    this.processDatesData();
    const stacking = this.getStacking();
    const { valueFormatId, decimalPlaces } = getValueFormat(instructions?.yAxisFormats?.[0]);
    const showMarkers = !instructions?.chartSpecificFormat?.lineChart?.hideMarkers;
    const lineWidth =
      instructions?.chartSpecificFormat?.lineChart?.lineWidth ||
      globalStyleConfig.container.lineWidth.default;

    const categories = this.getAxisCategories();
    const data = this.filterDataToCategories(this.transformData(), categories);

    const numCategories = categories?.length;
    const widthPerBar = 40;
    const totalScrollableArea = numCategories ? numCategories * widthPerBar : undefined;

    const hasColorCol = this.isColorColUsed();

    const underlyingDataEnabled = this.getUnderlyingDrilldownEnabled();
    const categorySelectEnabled = instructions?.drilldown?.categorySelectEnabled;

    const tickInterval = this.getTickInterval();
    const hasClickEvents =
      underlyingDataEnabled || categorySelectEnabled || generalOptions?.customMenu?.enabled;

    return {
      chart: {
        type: this.getChartType(),
        backgroundColor,
        scrollablePlotArea:
          instructions?.xAxisFormat?.enableScroll && totalScrollableArea
            ? {
                minWidth: !horizontal ? totalScrollableArea : undefined,
                minHeight: horizontal ? totalScrollableArea : undefined,
                opacity: 1,
              }
            : {},
      },
      //@ts-ignore
      series: data,
      title: { text: undefined },
      colors: getColorPalette(globalStyleConfig, instructions?.colorFormat),
      boost: {
        seriesThreshold: 500,
      },
      plotOptions: {
        series: {
          zones: getColorZones(instructions?.colorFormat, variables, datasets),
          animation: false,
          borderColor:
            instructions?.chartSpecificFormat?.barChart?.borderColor ??
            ChartShapeBorderDefaultColor,
          borderWidth:
            instructions?.chartSpecificFormat?.barChart?.borderWidth ??
            chartShapeBorderDefaultWidth,
          cursor: hasClickEvents ? 'pointer' : undefined,
          point: {
            events: {
              click: function (e) {
                if (!hasClickEvents) return;

                const subCategory: string | undefined = hasColorCol
                  ? //@ts-ignore
                    e.point.series.userOptions.rawColorData
                  : undefined;
                const menuInfo: ChartMenuInfo = {
                  chartId: dataPanelTemplateId,
                  chartX: e.chartX,
                  chartY: e.chartY,
                  category: e.point.category,
                  subCategory,
                };

                setChartMenu(menuInfo);
              },
            },
          },
          stacking,
          states: { hover: { borderColor: '#000000' } },
          dataLabels: {
            enabled: instructions?.xAxisFormat?.showBarValues,
            formatter: function () {
              if (stacking === 'percent') return `${format('0.2f')(this.percentage || 0)}%`;
              return formatValue({
                value: this.y || 0,
                decimalPlaces,
                formatId: valueFormatId,
                hasCommas: true,
              });
            },

            style: {
              textOutline: 'none',
              ...getLabelStyle(globalStyleConfig, 'primary'),
            },
          },
          lineWidth,
          //@ts-ignore
          borderRadius: instructions?.xAxisFormat?.barCornerRadius,
          pointRange: tickInterval,
        },
        spline: { marker: { enabled: showMarkers } },
        line: { marker: { enabled: showMarkers } },
      },
      yAxis: canUseMultiYAxis
        ? getMultiYAxisInstructions(globalStyleConfig, instructions, variables, datasets)
        : getSingleYAxisInstructions(globalStyleConfig, instructions, variables, datasets),
      xAxis: {
        ...xAxisFormat(globalStyleConfig, instructions?.xAxisFormat),
        type: this.getXAxisType(),
        categories,
        labels: {
          formatter: function () {
            return formatLabel(
              this.value,
              instructions?.categoryColumn?.column.type,
              instructions?.categoryColumn?.bucket?.id,
              instructions?.categoryColumn?.bucketSize,
              instructions?.xAxisFormat?.dateFormat,
              instructions?.xAxisFormat?.stringFormat,
            );
          },
          style: getLabelStyle(globalStyleConfig, 'secondary'),
          enabled: !instructions?.xAxisFormat?.hideAxisLabels,
        },
        minTickInterval: tickInterval,
        visible: !instructions?.xAxisFormat?.hideAxisLine,
      },
      legend: {
        ...formatLegend(globalStyleConfig, instructions?.legendFormat),
      },
      tooltip: {
        formatter: function () {
          return ReactDOMServer.renderToStaticMarkup(
            createYAxisBaseTooltip({
              tooltipFormatter: this,
              globalValueFormatId: valueFormatId,
              globalDecimalPlaces: decimalPlaces,
              globalStyleConfig: globalStyleConfig,
              instructions: instructions,
              includePercent: normalize || instructions?.tooltipFormat?.showPct,
            }),
          );
        },
        padding: 0,
        borderWidth: 0,
        borderRadius: 0,
        backgroundColor: '#FFFFFF00',
        shadow: false,
        useHTML: true,
        outside: true,
        followPointer: true,
      },
    };
  };

  isColorColUsed = () => {
    return (
      !!this.props.instructions?.colorColumnOptions?.length &&
      this.props.selectedColorColName !== NONE_CATEGORY_COLOR_VALUE
    );
  };

  getChartType = () => {
    const { horizontal } = this.props;
    if (horizontal) return 'bar';
    return 'column';
  };

  getComboChartType = (baseType?: COMBO_CHART_DATA_FORMATS) => {
    const { instructions } = this.props;

    if (baseType === COMBO_CHART_DATA_FORMATS.BAR) return baseType;

    return instructions?.chartSpecificFormat?.lineChart?.elasticity === LineElasticity.STRAIGHT
      ? 'line'
      : 'spline';
  };

  isGroupedStacked = () => {
    const { operationType } = this.props;
    return operationType && GROUPED_STACKED_OPERATION_TYPES.includes(operationType);
  };

  // for grouped stacked bar charts, the grouping column is the first in the schema
  getGroupingColName = () => this.props.schema[0].name;

  // Set a custom tick interval + point range for dates based on the bucket id
  // Highcharts isn't good at automatically calculating these
  getTickInterval = () => {
    const { instructions } = this.props;
    if (!DATE_TYPES.has(instructions?.categoryColumn?.column.type || '')) return;

    const bucketId = instructions?.categoryColumn?.bucket?.id;

    if (bucketId === PivotAgg.DATE_HOUR) {
      return ONE_HOUR;
    } else if (bucketId === PivotAgg.DATE_DAY) {
      return ONE_DAY;
    } else if (bucketId === PivotAgg.DATE_WEEK) {
      return ONE_WEEK;
    } else if (bucketId === PivotAgg.DATE_MONTH) {
      return ONE_MONTH;
    } else if (bucketId === PivotAgg.DATE_YEAR) {
      return ONE_YEAR;
    } else {
      return;
    }
  };

  getXAxisColName = () => {
    const { schema } = this.props;

    const xAxisIndex = this.isGroupedStacked() ? 1 : 0;
    return schema[xAxisIndex].name;
  };

  getXAxisType = () => {
    const { instructions } = this.props;
    if (DATE_TYPES.has(instructions?.categoryColumn?.column.type || '')) return 'datetime';
  };

  getSortingEnabled = () => {
    const { instructions } = this.props;
    return (
      instructions?.xAxisFormat?.sortAxis !== SortAxis.NONE &&
      (instructions?.xAxisFormat?.sortOption === SortOption.AXIS_DESC ||
        instructions?.xAxisFormat?.sortOption === SortOption.AXIS_ASC ||
        instructions?.xAxisFormat?.sortAxis === SortAxis.MANUAL)
    );
  };

  getAxisCategories = () => {
    const { instructions, previewData, schema, operationType } = this.props;
    if (DATE_TYPES.has(instructions?.categoryColumn?.column.type || '')) return;

    const { xAxisColName, aggColNames, sortColNames } = this.getAggColNames(schema);

    if (this.getSortingEnabled()) {
      const valByCategory: Record<string, number> = {};
      previewData.forEach((row) => {
        const category = row[xAxisColName];
        if (!valByCategory[category]) {
          valByCategory[category] = 0;
        }
        if (this.isColumnSortActive() && sortColNames) {
          sortColNames.map((sortCol) => (valByCategory[category] += row[sortCol] as number));
        } else if (this.isColorColUsed()) {
          const { aggColName } = getColorColNames(schema, operationType);
          valByCategory[category] += row[aggColName] as number;
        } else {
          aggColNames.map((aggCol) => (valByCategory[category] += row[aggCol] as number));
        }
      });

      return this.truncateCategoriesToMaxCategories(this.sortCategories(valByCategory));
    } else {
      const categories = new Set(previewData.map((row) => String(row[xAxisColName])));
      return this.truncateCategoriesToMaxCategories(Array.from(categories));
    }
  };

  // column and bar charts sort in opposite directions, with column sorting left to right
  // and column sorting bottom to top. Visually, though we want column to sort top to bottom,
  // so we have to reverse the sorting here to make the behavior the same
  shouldReverseSort = () => this.getChartType() === 'bar';

  shouldTruncateCategories = () => {
    const { instructions } = this.props;

    return !!instructions?.xAxisFormat?.maxCategories;
  };

  sortCategories = (valByCategory: Record<string, number>) => {
    const { instructions } = this.props;
    const sortAxis = instructions?.xAxisFormat?.sortAxis;
    const categoryList = Object.keys(valByCategory);

    if (sortAxis === SortAxis.MANUAL) {
      return orderBy(categoryList, (category) => {
        const index = (instructions?.xAxisFormat?.sortManualCategoriesOrder || []).findIndex(
          (order) => order.category === category,
        );
        return index === -1 ? categoryList.length : index;
      });
    }

    const isAscendingSort = instructions?.xAxisFormat?.sortOption === SortOption.AXIS_ASC;

    let sortDirection: 'desc' | 'asc';
    if (this.shouldReverseSort()) sortDirection = isAscendingSort ? 'desc' : 'asc';
    else sortDirection = isAscendingSort ? 'asc' : 'desc';

    return orderBy(
      categoryList,
      (category) => {
        if (instructions?.xAxisFormat?.sortAxis === SortAxis.CAT_AXIS) return category;
        return valByCategory[category];
      },
      sortDirection,
    );
  };

  truncateCategoriesToMaxCategories = (categories: string[]) => {
    const { instructions } = this.props;

    const maxCategories = instructions?.xAxisFormat?.maxCategories;

    if (!this.shouldTruncateCategories()) return categories;

    // only truncate from the back if we're sorting in reverse
    if (this.shouldReverseSort() && this.getSortingEnabled())
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return categories.slice(categories.length - maxCategories!);
    else return categories.slice(0, maxCategories);
  };

  getStacking = () => {
    if (this.props.normalize) return 'percent';
    if (this.props.grouped) return;
    return 'normal';
  };

  processDatesData = () => {
    const { instructions, previewData, schema, operationType } = this.props;
    const isCategoryColDate = shouldProcessColAsDate(instructions?.categoryColumn);
    const isColorColDate = isSelectedColorDateType(instructions || {});
    let isGroupingColDate = false;

    if (operationType && GROUPED_STACKED_OPERATION_TYPES.includes(operationType)) {
      isGroupingColDate = shouldProcessColAsDate(instructions?.groupingColumn);
    }

    if (
      !previewData ||
      (!isCategoryColDate && !isColorColDate && !isGroupingColDate) ||
      !schema?.length ||
      !instructions?.categoryColumn?.column.type
    )
      return;

    previewData.forEach((row) => {
      if (isCategoryColDate) {
        const xAxisColName = this.getXAxisColName();
        // checking if the data is a number because if it is, that means that it is already
        // in unix time and we have already processed it. If we pass in a unix timestamp to
        // getTimezoneAwareUnix, it returns undefined
        if (typeof row[xAxisColName] !== 'number') {
          row[xAxisColName] = getTimezoneAwareUnix(row[xAxisColName] as string);
        }
      }
      if (isColorColDate) {
        const colorColName = getColorColNames(schema, operationType).colorColName;
        if (typeof row[colorColName] !== 'number') {
          row[colorColName] = getTimezoneAwareUnix(row[colorColName] as string);
        }
      }
      if (isGroupingColDate) {
        const groupingColName = this.getGroupingColName();
        if (typeof row[groupingColName] !== 'number') {
          row[groupingColName] = getTimezoneAwareUnix(row[groupingColName] as string);
        }
      }
    });
  };

  bringLinesToFront = (seriesList: SeriesOptions[]) => {
    return flatten(
      partition(seriesList, (series) => series.type !== 'line' && series.type !== 'spline'),
    );
  };

  transformData = (): SeriesOptions[] => {
    // This is for when there are multiple bars/lines selected
    const { instructions, schema, isComboChart } = this.props;
    const isDate = DATE_TYPES.has(instructions?.categoryColumn?.column.type || '');

    if (
      !instructions?.aggColumns ||
      instructions.aggColumns.length === 0 ||
      !schema ||
      schema.length === 0
    )
      return [];

    let seriesList: SeriesOptions[];
    if (this.isColorColUsed()) {
      seriesList = this.transformColorData(schema);
    } else {
      seriesList = this.transformAggColsData(schema);
    }

    if (isComboChart) seriesList = this.bringLinesToFront(seriesList);

    if (!isDate && this.getSortingEnabled()) {
      const orderedCategory = this.getAxisCategories();
      if (orderedCategory?.length) {
        seriesList.forEach((series) => {
          series.data = sortBy(series.data as PointOptionsObject[], (row) =>
            orderedCategory.indexOf(row.name as string),
          );
        });
      }
    }

    // Ensure stable legend sorting, not currently configurable
    return sortBy(seriesList, (series) => series.name);
  };

  transformColorData = (schema: DatasetSchema): SeriesOptions[] => {
    const { colorCategoryTracker, instructions, previewData, selectedColorColName, operationType } =
      this.props;
    const { xAxisColName, colorColName, aggColName } = getColorColNames(schema, operationType);
    const isDate = DATE_TYPES.has(instructions?.categoryColumn?.column.type || '');
    const categoryOrder = this.getAxisCategories();
    const series: Record<string, SeriesOptions> = {};

    const selectedColorCol = getColorColumn(instructions, selectedColorColName);
    const isGroupedStacked = this.isGroupedStacked();
    const grouping = this.getGroupingColName();
    const drilldownVar = this.getDrilldownVariable();
    const drilldownColor =
      drilldownVar?.color !== undefined ? String(drilldownVar.color) : undefined;

    previewData.forEach((row) => {
      const value = row[xAxisColName];
      if (isDate && value === undefined) return;
      const colorCategory = formatLabel(
        row[colorColName],
        selectedColorCol?.column.type,
        selectedColorCol?.bucket?.id,
      );

      // if isGroupedStacked is false, we don't use groupingCategory
      // but it does have the chance to error on formatLabel
      const groupingCategory = isGroupedStacked
        ? formatLabel(
            row[grouping],
            instructions?.groupingColumn?.column.type,
            instructions?.groupingColumn?.bucket?.id,
          )
        : '';
      const name = isGroupedStacked ? colorCategory + ':' + groupingCategory : colorCategory;
      const stringValue = String(value);
      const entry = isDate
        ? [value, row[aggColName] as number]
        : {
            name: stringValue,
            y: row[aggColName] as number,
            x: categoryOrder?.indexOf(stringValue),
            selected: drilldownVar
              ? drilldownVar?.category === stringValue && drilldownColor === colorCategory
              : false,
          };

      const category = getColorTrackerCategoryName(xAxisColName, colorColName);
      if (series[name] && (isGroupedStacked ? series[name]['stack'] === groupingCategory : true)) {
        series[name].data.push(entry);
      } else {
        series[name] = {
          type: this.getChartType(),
          name: name,
          rawColorData: row[colorColName],
          data: [entry],
          color: colorCategoryTracker?.[category]?.[name],
          stack: isGroupedStacked ? groupingCategory : undefined,
        };
      }
    });

    const seriesData = Object.values(series);

    if (isDate && instructions?.chartSpecificFormat?.timeSeriesDataFormat?.hideLatestPeriodData) {
      seriesData.forEach((series) => series.data.pop());
      return seriesData;
    }

    return seriesData;
  };

  getDrilldownVariable = () => {
    const { variables, instructions, dataPanelProvidedId } = this.props;

    if (instructions?.drilldown?.categorySelectEnabled) {
      return variables[dataPanelProvidedId] as DrilldownVariable | undefined;
    }
  };

  transformAggColsData = (schema: DatasetSchema): SeriesOptions[] => {
    const { previewData, instructions, isComboChart, canUseMultiYAxis } = this.props;
    const { xAxisColName, aggColNames, groupingColName } = this.getAggColNames(schema);
    const isGroupedStacked = this.isGroupedStacked();
    const aggCols = instructions?.aggColumns || [];
    const isDate = DATE_TYPES.has(instructions?.categoryColumn?.column.type || '');

    const series: Record<string, SeriesOptions> = {};
    const selectedCategory = this.getDrilldownVariable()?.category;
    const stacking = this.getStacking();

    formatTwoDimensionalData(previewData, instructions).forEach((row) => {
      const groupingCategory = formatLabel(
        row[groupingColName],
        instructions?.groupingColumn?.column.type,
        instructions?.groupingColumn?.bucket?.id,
      );

      aggColNames.forEach((colName, index) => {
        if (isDate && row[xAxisColName] === undefined) return;
        const aggCol = aggCols[index];
        if (!aggCol) return;

        const y = row[colName];
        if (typeof y !== 'number') return;

        const name = isGroupedStacked ? colName + ':' + groupingCategory : colName;
        const x = isDate ? row[xAxisColName] : String(row[xAxisColName]);

        const entry = isDate
          ? { x: x as number, y, selected: selectedCategory === x }
          : { name: x, y, selected: selectedCategory === x };

        if (series[name]) {
          series[name].data.push(entry);
        } else {
          let extraAdditions = {};
          if (isComboChart && aggCol.column.dataFormat === COMBO_CHART_DATA_FORMATS.DOT) {
            extraAdditions = { lineWidth: 0, marker: { enabled: true } };
          }

          series[name] = {
            type: isComboChart
              ? this.getComboChartType(aggCol.column.dataFormat)
              : this.getChartType(),
            name: isGroupedStacked
              ? name
              : aggCol.column.friendly_name || getColDisplayText(aggCol) || colName,
            data: [entry],
            dataLabels: {
              formatter: function () {
                if (stacking === 'percent') return `${format('0.2f')(this.percentage || 0)}%`;
                const { valueFormatId, decimalPlaces } = getValueFormat(
                  getYAxisFormatById(instructions?.yAxisFormats, aggCol.yAxisFormatId) ||
                    instructions?.yAxisFormats?.[0], // fallback to the globally set yAxisFormat
                );
                return formatValue({
                  value: this.y || 0,
                  decimalPlaces,
                  formatId: valueFormatId,
                  hasCommas: true,
                });
              },
            },
            yAxis: getYAxisChartIndex(aggCol.yAxisFormatId, canUseMultiYAxis, instructions),
            stack: isGroupedStacked ? groupingCategory : undefined,
            ...extraAdditions,
          };
        }
      });
    });
    return Object.values(series);
  };

  isColumnSortActive = () => {
    const { instructions } = this.props;
    const xAxisFormat = instructions?.xAxisFormat;
    return (
      xAxisFormat?.sortAxis === SortAxis.COLUMN && xAxisFormat.sortOption && xAxisFormat.sortColumns
    );
  };

  getAggColNames = (schema: DatasetSchema) => {
    const { instructions } = this.props;

    let xAxisColName, aggColNames, groupingColName, sortColNames;

    if (this.isGroupedStacked()) {
      xAxisColName = schema[1].name;
      aggColNames = schema.map((col) => col.name).slice(2);
      groupingColName = this.getGroupingColName();
    } else {
      xAxisColName = schema[0].name;
      aggColNames = schema.map((col) => col.name).slice(1);
      groupingColName = '';
    }

    const numSortColumns = this.isColumnSortActive()
      ? instructions?.xAxisFormat?.sortColumns?.length || 0
      : 0;

    if (numSortColumns) {
      sortColNames = aggColNames.slice(-numSortColumns);
      aggColNames = aggColNames.slice(0, -numSortColumns);
    }

    return {
      xAxisColName,
      aggColNames,
      groupingColName,
      sortColNames,
    };
  };

  filterDataToCategories = (
    data: SeriesOptions[],
    categories: string[] | undefined,
  ): SeriesOptions[] => {
    if (!categories || !this.shouldTruncateCategories()) return data;

    data.forEach((series: SeriesOptions) => {
      // @ts-ignore
      series.data = series.data.filter((data) => categories.includes(data.name));
    });

    return data;
  };

  getUnderlyingDrilldownEnabled = () => {
    const { generalOptions, openDrilldownModal } = this.props;
    return !!(generalOptions?.enableRawDataDrilldown && openDrilldownModal);
  };
}

export { BarChart };
