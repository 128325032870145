import { useState } from 'react';
import { ReduxState } from 'reducers/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import validator from 'validator';
import { uploadSupportEmail } from 'actions/teamActions';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import { DEFAULT_SUPPORT_EMAIL } from 'constants/emailConstants';

const useStyles = makeStyles((theme: Theme) => ({
  subsection: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  sectionDescription: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.ds.grey800,
    marginBottom: theme.spacing(6),
  },
}));

export default function SettingsSupportEmailSection() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const data = useSelector((state: ReduxState) => state.teamData.data);

  const [newSupportEmail, setNewSupportEmail] = useState<string | undefined>(undefined);

  return (
    <div>
      <div className={classes.subsection}>Support Email</div>
      <div className={classes.sectionDescription}>
        This email will display if a chart download fails. If no email is provided,{' '}
        {DEFAULT_SUPPORT_EMAIL} will be used.
      </div>
      <InputWithBlurSave
        errorText={
          newSupportEmail && !validator.isEmail(newSupportEmail)
            ? 'Email is not formatted correctly'
            : undefined
        }
        initialValue={data?.support_email ?? undefined}
        onNewValueSubmitted={(newString) => {
          dispatch(
            uploadSupportEmail({ postData: { support_email: newString } }, () => {
              setNewSupportEmail(newString);
            }),
          );
        }}
        placeholder={DEFAULT_SUPPORT_EMAIL}
      />
    </div>
  );
}
