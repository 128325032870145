import { Canvas, EmbedCanvas } from 'actions/canvasActions';
import { CanvasDataset, CanvasTemplate, CanvasVersionConfig } from 'actions/canvasConfigActions';
import { DashboardTemplate, EmbedDashboardTemplate } from 'actions/dashboardTemplateActions';
import { Dataset } from 'actions/datasetActions';
import { EndUserDataPanel } from 'actions/architectCustomerDashboardConfigActions';
import { DashboardVersionConfig } from './dashboardVersionConfig';
import { DataPanelTemplate } from './dataPanelTemplate';

export type ExploResource = Canvas | EmbedCanvas | DashboardTemplate | EmbedDashboardTemplate;

export type ResourceDataset = CanvasDataset | Dataset;

export type RequestDataset = Pick<ResourceDataset, 'query' | 'parent_schema_id'>;

export type DataPanel = DataPanelTemplate | CanvasTemplate | EndUserDataPanel;

export interface VersionInfo {
  is_draft: boolean;
  change_comments: string | null;
  version_number: number;
  edit_version_number: number;
}

export interface LatestVersionInfo {
  is_draft: boolean;
  version_number: number;
  modified: string;
  configuration: DashboardVersionConfig | CanvasVersionConfig;
}

export enum ResourcePageType {
  ARCHITECT = 'ARCHITECT',
  REPORT_BUILDER = 'REPORT_BUILDER',
  EXPLORE = 'EXPLORE',
}
