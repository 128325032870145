import VisualizationTypeSelector from './VisualizationTypeSelector';
import TwoDimensionVizConfig from './vizConfigs/TwoDimensionVizConfig';
import KPIVizConfig from './vizConfigs/KPIVizConfig';
import KPITrendConfig from './vizConfigs/KPITrendConfig';
import BoxPlotConfig from './vizConfigs/BoxPlotConfig';
import ScatterPlotConfig from './vizConfigs/ScatterPlotConfig';
import VisualizationConfigTableColumns from './VisualizationConfigTableColumns';
import SettingHeader from './SettingHeader';
import TableConfig from './vizConfigs/TableConfig';
import PivotTableConfig from './vizConfigs/PivotTableConfig';
import CollapsibleListConfig from './vizConfigs/CollapsibleListConfig';
import DroppableFilterColumnSection from './FilterConfigSection/DroppableFilterColumnSection';

import {
  OPERATION_TYPES,
  V2_CHART_VISUALIZATION_OPERATIONS,
  FilterValueSourceType,
} from 'constants/types';
import { DashboardElement } from 'types/dashboardTypes';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { DashboardParam } from 'types/dashboardVersionConfig';
import { Dataset } from 'actions/datasetActions';
import { DatasetSchema } from 'types/datasets';
import { FilterOperator } from 'types/filterOperations';
import { getPossibleLinksForDataPanel } from 'utils/filterLinking';

type Props = {
  schema: DatasetSchema;
  loading?: boolean;
  dashboardElements: DashboardElement[];
  dashboardParams: Record<string, DashboardParam>;
  dataPanels: DataPanelTemplate[];
  sourceTableName?: string;
  datasets: Record<string, Dataset>;
  dataPanel: DataPanelTemplate;
};

export default function DataConfigTab({
  schema,
  loading,
  dashboardElements,
  dashboardParams,
  dataPanel,
  dataPanels,
  datasets,
}: Props) {
  const filterInstructions = dataPanel.filter_op.instructions;
  const visualizationType = dataPanel.visualize_op.operation_type;
  const vizInstructions = dataPanel.visualize_op.instructions;

  const linksForDataPanel = getPossibleLinksForDataPanel(dataPanel, datasets, dashboardElements);

  const hasLinkedDateRangePickerFilter = !!linksForDataPanel.find(
    (link) => link.operator === FilterOperator.DATE_IS_BETWEEN && link.applied,
  );

  const hasDateRangePickerFilter =
    hasLinkedDateRangePickerFilter ||
    !!filterInstructions.filterClauses.find((filter) => {
      return (
        filter.filterValueVariableId !== undefined &&
        filter.filterValueSource === FilterValueSourceType.VARIABLE &&
        filter.filterOperation?.id === FilterOperator.DATE_IS_BETWEEN &&
        filter.filterColumn?.name ===
          vizInstructions.V2_TWO_DIMENSION_CHART?.categoryColumn?.column.name
      );
    });

  const sharedTwoDimConfigProps = {
    chartType: visualizationType,
    instructions: vizInstructions.V2_TWO_DIMENSION_CHART || {},
    loading,
    dashboardElements,
    dataPanelProvidedId: dataPanel.provided_id,
    canSelectSmartGrouping: hasDateRangePickerFilter,
    schema,
  };

  const renderVizConfig = () => {
    switch (visualizationType) {
      case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_LINE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_AREA_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2:
        return (
          <TwoDimensionVizConfig
            {...sharedTwoDimConfigProps}
            enableMultipleColorCols={visualizationType !== OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2}
            maxAggs={vizInstructions.V2_TWO_DIMENSION_CHART?.colorColumnOptions ? 1 : undefined}
            supportColor={visualizationType !== OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2}
          />
        );
      case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2:
        return (
          <TwoDimensionVizConfig
            {...sharedTwoDimConfigProps}
            enableMultipleColorCols
            supportColor
            supportSecondaryGroup
            colorName="Stacks"
            maxAggs={vizInstructions.V2_TWO_DIMENSION_CHART?.colorColumnOptions ? 1 : undefined}
          />
        );
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2:
        return (
          <TwoDimensionVizConfig
            {...sharedTwoDimConfigProps}
            enableMultipleColorCols
            supportColor
            aggName="X-Axis"
            categoryName="Y-Axis"
            maxAggs={vizInstructions.V2_TWO_DIMENSION_CHART?.colorColumnOptions ? 1 : undefined}
          />
        );
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2:
        return (
          <TwoDimensionVizConfig
            {...sharedTwoDimConfigProps}
            enableMultipleColorCols
            supportColor
            supportSecondaryGroup
            aggName="X-Axis"
            categoryName="Y-Axis"
            colorName="Clusters"
            maxAggs={vizInstructions.V2_TWO_DIMENSION_CHART?.colorColumnOptions ? 1 : undefined}
            secondaryGroupName="Stacks"
          />
        );
      case OPERATION_TYPES.VISUALIZE_PIE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2:
        return (
          <TwoDimensionVizConfig
            {...sharedTwoDimConfigProps}
            aggName="Values"
            categoryName="Categories"
            maxAggs={1}
          />
        );
      case OPERATION_TYPES.VISUALIZE_MAP_V2:
        return (
          <TwoDimensionVizConfig
            {...sharedTwoDimConfigProps}
            aggName="Density Column"
            categoryName="Region Column"
            maxAggs={1}
          />
        );
      case OPERATION_TYPES.VISUALIZE_FUNNEL_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2:
        return (
          <TwoDimensionVizConfig
            {...sharedTwoDimConfigProps}
            aggName="Amount"
            categoryName="Stages"
            maxAggs={1}
          />
        );
      case OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2:
        return (
          <TwoDimensionVizConfig
            {...sharedTwoDimConfigProps}
            supportColor
            aggName="Aggregation"
            categoryName="X-Axis"
            colorName="Y-Axis"
            maxAggs={1}
          />
        );
      case OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2:
        return (
          <ScatterPlotConfig
            chartType={visualizationType}
            instructions={vizInstructions.V2_SCATTER_PLOT || {}}
            loading={loading}
            schema={schema}
          />
        );
      case OPERATION_TYPES.VISUALIZE_SPIDER_CHART:
        return (
          <TwoDimensionVizConfig
            {...sharedTwoDimConfigProps}
            supportColor
            aggName="Aggregation"
            categoryName="Categories"
            maxAggs={vizInstructions.V2_TWO_DIMENSION_CHART?.colorColumnOptions ? 1 : undefined}
            schema={schema}
          />
        );
      case OPERATION_TYPES.VISUALIZE_NUMBER_V2:
      case OPERATION_TYPES.VISUALIZE_PROGRESS_V2:
        return (
          <KPIVizConfig
            chartType={visualizationType}
            instructions={vizInstructions.V2_KPI || {}}
            loading={loading}
            schema={schema}
          />
        );
      case OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2:
        return (
          <KPITrendConfig
            chartType={visualizationType}
            dashboardElements={dashboardElements}
            instructions={vizInstructions.V2_KPI_TREND || {}}
            loading={loading}
            schema={schema}
          />
        );

      case OPERATION_TYPES.VISUALIZE_TREND_TABLE:
        return (
          <KPITrendConfig
            chartType={visualizationType}
            dashboardElements={dashboardElements}
            instructions={vizInstructions.V2_TREND_TABLE || {}}
            loading={loading}
            schema={schema}
          />
        );
      case OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2:
        return (
          <BoxPlotConfig
            chartType={visualizationType}
            instructions={vizInstructions.V2_BOX_PLOT || {}}
            loading={loading}
            schema={schema}
          />
        );
      case OPERATION_TYPES.VISUALIZE_TABLE:
      case OPERATION_TYPES.VISUALIZE_REPORT_BUILDER:
        return (
          <TableConfig
            chartType={OPERATION_TYPES.VISUALIZE_TABLE}
            instructions={vizInstructions.VISUALIZE_TABLE || {}}
            loading={loading}
            schema={schema}
          />
        );
      case OPERATION_TYPES.VISUALIZE_PIVOT_TABLE:
      case OPERATION_TYPES.VISUALIZE_PIVOT_REPORT_BUILDER:
        return (
          <PivotTableConfig
            chartType={OPERATION_TYPES.VISUALIZE_PIVOT_TABLE}
            dashboardDatasets={datasets}
            instructions={vizInstructions.VISUALIZE_PIVOT_TABLE || {}}
            loading={loading}
            schema={schema}
          />
        );
      case OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST:
        return (
          <CollapsibleListConfig
            chartType={OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST}
            instructions={vizInstructions.VISUALIZE_COLLAPSIBLE_LIST || {}}
            loading={loading}
            schema={schema}
          />
        );

      default:
        return <div></div>;
    }
  };

  return (
    <>
      {V2_CHART_VISUALIZATION_OPERATIONS.includes(visualizationType as OPERATION_TYPES) && (
        <>
          <SettingHeader noTopBorder name="Chart Type" />
          <VisualizationTypeSelector selectedChartType={visualizationType} />
        </>
      )}
      {renderVizConfig()}
      <SettingHeader name="Filter" />
      <DroppableFilterColumnSection
        clauses={filterInstructions.filterClauses}
        dashboardElements={dashboardElements}
        dashboardParams={dashboardParams}
        dataPanel={dataPanel}
        dataPanels={dataPanels}
        datasets={datasets}
        schema={schema}
      />
      <SettingHeader name="Fields" />
      <VisualizationConfigTableColumns loading={loading} schema={schema} />
    </>
  );
}
