import { FC } from 'react';
import produce from 'immer';

import { Button, ButtonGroup } from '@blueprintjs/core';
import { sprinkles } from 'components/ds';
import TextAreaWithBlurSave from 'pages/dataPanelEditorPage/textAreaWithBlurSave';
import DropdownSelect from 'shared/DropdownSelect';

import { TextDashboardElemConfig, TEXT_ELEM_SIZES } from 'types/dashboardTypes';
import { getQueryTablesReferencedByText } from 'utils/dataPanelConfigUtils';
import { Dataset } from 'actions/datasetActions';
import { TEXT_ELEM_SIZE_CONFIGS } from 'constants/dashboardConstants';
import {
  ALIGNMENT_FORMATS,
  ORDERED_ALIGNMENTS,
  ORDERED_VERT_ALIGNMENTS,
  VERT_ALIGNMENT_FORMATS,
} from 'constants/dataConstants';

type Props = {
  config: TextDashboardElemConfig;
  datasets: Record<string, Dataset>;

  updateConfig: (newConfig: TextDashboardElemConfig) => void;
};

const configInputClass = sprinkles({ marginTop: 'sp1.5', width: 'fill' });

export const TextElementConfigPanel: FC<Props> = ({ config, datasets, updateConfig }) => {
  const updateTextConfig = (update: (config: TextDashboardElemConfig) => void) => {
    updateConfig(
      produce(config, (draft) => {
        update(draft);
      }),
    );
  };

  const horizAlignment = config.alignmentHoriz || ALIGNMENT_FORMATS.LEFT_ALIGN.id;
  const vertAlignment = config.alignmentVertical || VERT_ALIGNMENT_FORMATS.TOP_ALIGN.id;

  return (
    <div>
      <TextAreaWithBlurSave
        containerClassName={configInputClass}
        helpIcon="code"
        helpText="This field can take in variables"
        initialValue={config.text || ''}
        label="Text"
        onNewValueSubmitted={(newValue) =>
          updateTextConfig((draft) => {
            draft.text = newValue;
            draft.queryTables = getQueryTablesReferencedByText(newValue, datasets);
          })
        }
        placeholder=""
      />
      <div className={sprinkles({ body: 'b3', color: 'gray10' })}>We now support markdown!</div>
      <DropdownSelect
        fillWidth
        minimal
        containerClassName={configInputClass}
        filterable={false}
        label="Text Size"
        noSelectionText=""
        onChange={(item) =>
          updateTextConfig((draft) => (draft.textSize = item.id as TEXT_ELEM_SIZES))
        }
        options={[
          TEXT_ELEM_SIZE_CONFIGS[TEXT_ELEM_SIZES.SMALL],
          TEXT_ELEM_SIZE_CONFIGS[TEXT_ELEM_SIZES.MEDIUM],
          TEXT_ELEM_SIZE_CONFIGS[TEXT_ELEM_SIZES.LARGE],
        ]}
        selectedItem={TEXT_ELEM_SIZE_CONFIGS[config.textSize ?? TEXT_ELEM_SIZES.SMALL]}
      />
      <ButtonGroup fill className={configInputClass}>
        {ORDERED_ALIGNMENTS.map((alignment) => (
          <Button
            active={horizAlignment === alignment.id}
            icon={alignment.icon}
            key={`text-elem-config-${alignment.id}`}
            onClick={() => updateTextConfig((draft) => (draft.alignmentHoriz = alignment.id))}
          />
        ))}
      </ButtonGroup>
      <ButtonGroup fill className={configInputClass}>
        {ORDERED_VERT_ALIGNMENTS.map((alignment) => (
          <Button
            active={vertAlignment === alignment.id}
            icon={alignment.icon}
            key={`text-elem-config-${alignment.id}`}
            onClick={() => updateTextConfig((draft) => (draft.alignmentVertical = alignment.id))}
          />
        ))}
      </ButtonGroup>
      <ButtonGroup fill className={configInputClass}>
        <Button
          active={false}
          icon="bold"
          onClick={() => updateTextConfig((draft) => (draft.text = `**${draft.text}**`))}
        />

        <Button
          active={false}
          icon="italic"
          onClick={() => updateTextConfig((draft) => (draft.text = `*${draft.text}*`))}
        />
      </ButtonGroup>
    </div>
  );
};
