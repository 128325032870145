import { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { sprinkles } from 'components/ds';
import { ReportBuilderReportView } from 'pages/ReportBuilder/ReportView';
import { EditorLeftColumn } from '../EditorLeftColumn';

import { createBlankView } from 'pages/ReportBuilder/utils/viewUtils';
import { CustomerReport } from 'actions/customerReportActions';
import { setUpReportBuilderPreview } from 'reducers/thunks/reportBuilderEditorThunks';
import { ReduxState } from 'reducers/rootReducer';
import { clearEmbeddedReportBuilderReducer } from 'reportBuilderContent/reducers/embeddedReportBuilderReducer';

const rootClass = sprinkles({ display: 'flex', flex: 1, overflow: 'hidden', height: 'fill' });
const leftColClass = sprinkles({
  height: 'fill',
  body: 'b2',
  color: 'contentSecondary',
  padding: 'sp2',
});
const previewContainerClass = sprinkles({ flex: 1, flexItems: 'column', backgroundColor: 'white' });
const sandboxBannerClass = sprinkles({
  body: 'b3',
  color: 'red',
  backgroundColor: 'lightRed',
  flexItems: 'center',
});

export const ReportBuilderPreview: FC = () => {
  const dispatch = useDispatch();

  const currentConfig = useSelector((state: ReduxState) => state.reportEditing.currentConfig);

  const previewReport: CustomerReport = useMemo(
    () => ({
      id: 0,
      name: '',
      config: {
        views: [createBlankView()],
      },
      modified: '',
      is_starred: false,
    }),
    [],
  );

  useEffect(() => {
    return () => {
      dispatch(clearEmbeddedReportBuilderReducer());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(setUpReportBuilderPreview(previewReport));
  }, [dispatch, previewReport]);

  return (
    <div className={rootClass}>
      <EditorLeftColumn>
        <div className={leftColClass}>
          This is how your users will create reports. Changes made here won&rsquo;t affect your
          users
        </div>
      </EditorLeftColumn>
      <div className={previewContainerClass}>
        <div className={sandboxBannerClass} style={{ height: 40, minHeight: 40 }}>
          This is a sandbox. Changes made on this page won&rsquo;t be saved.
        </div>
        <div className={sprinkles({ flex: 1, overflow: 'hidden' })}>
          {currentConfig ? (
            <ReportBuilderReportView
              isPreview
              currentConfig={currentConfig}
              report={previewReport}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
