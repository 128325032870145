import cx from 'classnames';
import { uniqueId } from 'utils/standard';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';

import { Icon, Popover, Position, Menu, MenuItem, MenuDivider } from '@blueprintjs/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.ds.grey600,
    border: `1px solid ${theme.palette.ds.white}`, // to account for hover border
  },
  popoverTargetContainer: {
    padding: `${theme.spacing(3)}px ${theme.spacing(5)}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '&:hover': {
      cursor: 'pointer',
    },
  },
  name: {
    fontWeight: 600,
    color: '#182026',
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  noOptionSelected: {
    color: theme.palette.ds.grey600,
    fontWeight: 'unset',
  },
  caretIcon: {
    marginLeft: theme.spacing(3),
  },
  menuItemOption: {
    alignItems: 'center',
  },
}));

type Props = {
  caretColor?: string;
  disableEdits?: boolean;
  onOptionChanged: (option: { id: string; name: string }) => void;
  onRemoveOptionSelected?: () => void;
  options: { id: string; name: string }[];
  placeholder?: string;
  selectedOption?: { id: string; name: string };
};

export default function DropdownSection({
  caretColor,
  disableEdits,
  onOptionChanged,
  onRemoveOptionSelected,
  options,
  placeholder,
  selectedOption,
}: Props) {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div className={classes.root}>
      <Popover
        captureDismiss
        fill
        minimal
        content={
          <Menu>
            {options.map((option) => (
              <MenuItem
                className={classes.menuItemOption}
                disabled={disableEdits}
                icon={
                  selectedOption?.id === option.id && (
                    <Icon
                      color={theme.palette.ds.blue}
                      icon="full-circle" // 6 is the size of the dot icon
                      iconSize={6}
                    />
                  )
                }
                key={uniqueId('col-config-option')}
                onClick={() => !disableEdits && onOptionChanged(option)}
                text={option.name}
              />
            ))}

            {onRemoveOptionSelected && (
              <>
                {options.length > 0 && <MenuDivider />}
                <MenuItem
                  disabled={disableEdits}
                  onClick={() => !disableEdits && onRemoveOptionSelected()}
                  text="Unselect"
                />
              </>
            )}
          </Menu>
        }
        position={Position.BOTTOM_RIGHT}>
        <div className={classes.popoverTargetContainer}>
          <div className={cx(classes.name, { [classes.noOptionSelected]: !selectedOption })}>
            {selectedOption ? selectedOption.name : placeholder || 'Select an option...'}
          </div>
          <Icon
            className={classes.caretIcon}
            color={caretColor || theme.palette.ds.blue}
            icon="caret-down"
          />
        </div>
      </Popover>
    </div>
  );
}
