import { Intent } from 'components/ds';

import { every } from 'utils/standard';
import { DATABASES } from './constants';
import { DataSourcePropertyOption, SupportedDataSource } from 'actions/dataSourceActions';
import { Props as InputWithTagProps } from 'shared/InputWithTag/InputWithTag';
import {
  DataSourceConfiguration,
  DataSourceConfigurationSchema,
  DataSourcePropertyOptions,
} from 'actions/dataSourceActions';

export const areCredentialsComplete = (
  properties: DataSourcePropertyOptions,
  configuration: DataSourceConfiguration,
  isSecurityPage: boolean,
): boolean => {
  return every(
    Object.keys(properties).map((propertyName: string) => {
      const property = properties[propertyName];
      const configValue = configuration[propertyName];

      if (!property) return false;

      if (property.security_page === !isSecurityPage) return true;

      if ((property.depends_on && !configuration[property.depends_on || '']) || property.optional) {
        return true;
      }

      // Dropdowns have sub-properties that need to be recursively checked
      if (property.type === 'dropdown') {
        const dropdownValue = configValue as string;
        if (!property.options || !property.options[dropdownValue]) return false;

        return areCredentialsComplete(
          (property.options ?? {})[dropdownValue],
          configuration,
          isSecurityPage,
        );
      }

      // A checkbox field is configured since if it is not present, it is false
      if (property.type === 'checkbox') {
        return true;
      }

      return (
        configValue !== null &&
        configValue !== undefined &&
        configValue !== '' &&
        (typeof configValue !== 'number' || !isNaN(configValue))
      );
    }),
  );
};

export const getPropertyNames = (
  configurationSchema: DataSourceConfigurationSchema,
  isSecurityPage: boolean,
) =>
  configurationSchema.order.filter(
    (propertyName) =>
      !!configurationSchema.properties[propertyName].security_page === isSecurityPage,
  );

export const getHelpText = (
  dataSource: SupportedDataSource | undefined,
  property: DataSourcePropertyOption,
) => {
  const snowflakeAccountHelpText = (
    <>
      To learn more about Snowflake account identifiers{' '}
      <a
        href="https://docs.snowflake.com/en/user-guide/admin-account-identifier.html#where-are-account-identifiers-used"
        rel="noreferrer"
        style={{ color: 'white' }}
        target="_blank">
        click here
      </a>
    </>
  );

  return dataSource?.name === DATABASES.SNOWFLAKE && property.label === 'Account'
    ? snowflakeAccountHelpText
    : property.help_tooltip;
};

export const getStatusInfo = (
  isEmpty: boolean,
  isDisplayValid: boolean,
  idError?: string,
): InputWithTagProps['statusInfo'] => {
  if (idError)
    return {
      statusIcon: 'cross',
      statusIntent: Intent.ERROR,
      statusText: 'ID not unique',
    };

  return {
    statusIcon: !isEmpty ? (isDisplayValid ? 'check' : 'cross') : undefined,
    statusIntent: !isEmpty ? (isDisplayValid ? Intent.SUCCESS : Intent.ERROR) : Intent.NONE,
    statusText: !isEmpty ? (isDisplayValid ? '' : `Can't be blank`) : undefined,
  };
};
