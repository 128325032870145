import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import { useRouteMatch } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import validator from 'validator';
import Cookies from 'js-cookie';

import InputWithTag from 'shared/InputWithTag';
import Button from 'shared/Button';
import PortalDashboardPage from './portalDashboardPage';
import { GenericLoadingSpinner } from 'components/EmbeddedDashboard';

import {
  customerPortalSignIn,
  customerPortalAuthentication,
  fetchEndUserPortalMetadata,
} from 'actions/authAction';
import { showErrorToast, showSuccessToast } from 'shared/sharedToasts';

type MatchParams = {
  token: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.ds.grey100,
    height: '100vh',
    fontFamily: "'Inter', sans-serif",
    perspective: '1px',
    overflowY: 'auto',
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: `0 ${theme.spacing(7)}px`,
    minHeight: '100%',
  },
  card: {
    backgroundColor: theme.palette.ds.white,
    padding: `${theme.spacing(10)}px ${theme.spacing(16)}px`,
    border: `1px solid ${theme.palette.ds.grey400}`,
    borderRadius: 5,
    position: 'relative',
    width: '400px',
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    marginBottom: theme.spacing(2.5),
    textAlign: 'center',
  },
  subtitle: {
    fontSize: 12,
    color: theme.palette.ds.grey700,
  },
  header: { textAlign: 'center', paddingBottom: theme.spacing(7.5) },
  loginSentHeader: {
    paddingBottom: 0,
  },
  textInput: { paddingBottom: theme.spacing(5) },
  logoContainer: {
    position: 'absolute',
    top: -40,
    left: 'calc(50% - 40px)',
  },
  logo: {
    width: 80,
  },
  logoPadding: {
    paddingTop: theme.spacing(5),
  },
}));

export const PortalBasePage: FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [shareId, setShareId] = useState('');
  const [isLoadingAuth, setIsLoadingAuth] = useState(true);
  const [isLoadingMetadata, setIsLoadingMetadata] = useState(true);
  const [dashboardName, setDashboardName] = useState('');
  const [dashboardLogoUrl, setDashboardLogoUrl] = useState(undefined as string | undefined);
  const [faviconUrl, setFaviconUrl] = useState<string>('');
  const [siteTitle, setSiteTitle] = useState<string>('');
  const [loginText, setLoginText] = useState<string>('');
  const [loginEmailSent, setLoginEmailSent] = useState<boolean>(false);

  let {
    params: { token },
  } = useRouteMatch<MatchParams>();

  const isEmailReroute = !!token;
  token = token ?? Cookies.get('portal_auth');

  useEffect(() => {
    if (token) {
      setIsLoadingAuth(true);
      dispatch(
        customerPortalAuthentication(
          { postData: { token, isEmailReroute } },
          (response) => {
            Cookies.set('portal_auth', token, { expires: 7, sameSite: 'Strict' });
            setShareId(response.share_id);
            setDashboardName(response.dashboard_name);
            setIsLoadingAuth(false);

            window.history.replaceState(null, 'Dashboard', '/portal');
          },
          (e) => {
            showErrorToast(e.error_msg ?? 'Something went wrong. Please try again.');
            Cookies.remove('portal_auth');
            setIsLoadingAuth(false);
            window.history.replaceState(null, 'Dashboard', '/portal');
          },
        ),
      );
    } else {
      setIsLoadingAuth(false);
    }
  }, [dispatch, token, isEmailReroute]);

  useEffect(() => {
    dispatch(
      fetchEndUserPortalMetadata(
        { postData: {} },
        (response) => {
          setDashboardLogoUrl(response.dashboard_logo_url);
          setFaviconUrl(response.favicon_url ?? '');
          setSiteTitle(response.portal_site_title ?? '');
          setLoginText(
            response.portal_login_text ?? 'Contact your administrator for login information',
          );
          setIsLoadingMetadata(false);
        },
        () => {
          console.error('Invalid URL, redirecting...');
          // if there's no metadata, then the portal isn't set up and we should send the user back
          // to whatever origin they started at
          window.location.href = `${window.location.protocol}//${window.location.hostname}`;
        },
      ),
    );
  }, [dispatch]);

  useEffect(() => {
    const faviconElement = document.getElementById('site-favicon') as HTMLAnchorElement;
    const siteTitleElement = document.getElementById('site-title') as HTMLAnchorElement;

    if (faviconElement && faviconUrl)
      faviconElement.href = `https://www.google.com/s2/favicons?domain=${faviconUrl}`;
    if (siteTitleElement && siteTitle) siteTitleElement.innerHTML = `${siteTitle}`;
  }, [faviconUrl, siteTitle]);

  if (isLoadingAuth || isLoadingMetadata) {
    return <GenericLoadingSpinner embedType="shared" />;
  }

  const renderLoginBody = () => {
    if (loginEmailSent) {
      return (
        <div
          className={cx(classes.header, classes.loginSentHeader, {
            [classes.logoPadding]: dashboardLogoUrl,
          })}>
          <div className={classes.title}>Check your email</div>
          <div className={classes.subtitle}>
            A sign-in link has been sent to your inbox at {email}.
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div className={cx(classes.header, { [classes.logoPadding]: dashboardLogoUrl })}>
            <div className={classes.title}>Sign-In</div>
            <div className={classes.subtitle}>{loginText}</div>
          </div>
          <div>
            <InputWithTag
              className={classes.textInput}
              label="Email"
              onChange={setEmail}
              value={email}
            />
            <Button
              fillWidth
              disabled={!validator.isEmail(email)}
              onClick={() =>
                dispatch(
                  customerPortalSignIn(
                    { postData: { email: email } },
                    () => {
                      showSuccessToast(
                        'An email has been sent with a magic link to sign in if an account is set up for that email.',
                      );
                      setLoginEmailSent(true);
                    },
                    (error) => showErrorToast(error.error_msg ?? 'Something went wrong'),
                  ),
                )
              }
              text="Enter"
              type="primary"
            />
          </div>
        </>
      );
    }
  };

  if (!shareId) {
    return (
      <div className={classes.root}>
        <div className={classes.cardContainer}>
          <div className={classes.card}>
            {dashboardLogoUrl && (
              <div className={classes.logoContainer}>
                <img alt="explo icon" className={classes.logo} src={dashboardLogoUrl} />
              </div>
            )}
            {renderLoginBody()}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <PortalDashboardPage
        dashboardLogoUrl={dashboardLogoUrl}
        dashboardName={dashboardName}
        faviconUrl={faviconUrl}
        onSignOut={() => {
          Cookies.remove('portal_auth');
          setShareId('');
        }}
        shareId={shareId}
        siteTitle={siteTitle}></PortalDashboardPage>
    </div>
  );
};
