import { useEffect } from 'react';
import { Icon } from '@blueprintjs/core';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import Button from 'shared/Button';
import CalloutLink from 'shared/CalloutLink';
import InviteBox from 'components/Onboarding/InviteBox';
import OnboardingStep from 'components/Onboarding/OnboardingStep';
import OnboardingModal from 'pages/Onboarding/OnboardingModal';
import { sprinkles } from 'components/ds';

import { OnboardingStepData } from 'actions/userActions';
import { ReduxState } from 'reducers/rootReducer';
import { STEPS, STEP_TITLES, SUB_STEP_TITLES } from 'constants/onboardingConstants';
import { fetchUserTeam } from 'actions/teamActions';
import { pageView } from 'analytics/exploAnalytics';
import { fetchDashboardTemplateList } from 'actions/dashboardTemplateActions';
import { showErrorToast } from 'shared/sharedToasts';
import { ROUTES } from 'constants/routes';
import { addCalendlyScripts, removeCalendlyScripts, openSalesCalendly } from 'utils/calendlyUtils';
import { isOnboardingComplete } from 'utils/onboarding';

const useStyles = makeStyles((theme: Theme) => ({
  stepBodyLeft: {
    width: '60%',
  },
  stepBodyRight: {
    width: '35%',
  },
  stepDescription: {
    color: theme.palette.ds.grey800,
    fontSize: 16,
    fontWeight: 400,
    marginBottom: theme.spacing(8),
  },
  header: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: theme.spacing(2),
    marginTop: 0,
  },
  requirements: {
    paddingLeft: theme.spacing(5),
  },
  requirement: {
    paddingBottom: theme.spacing(2),
  },
  requirementText: {
    paddingLeft: theme.spacing(2),
  },
  subStepItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    listStyle: 'none',
    marginBottom: theme.spacing(2),
  },
  subStepText: {
    paddingLeft: theme.spacing(2),
    marginBottom: 0,
  },
  stepPrimaryCta: {
    marginBottom: theme.spacing(8),
  },
}));

function OnboardingPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();

  const { onboardingSteps, teamData, dashboardTemplateList, currentUser } = useSelector(
    (state: ReduxState) => ({
      onboardingSteps: state.onboarding.onboardingSteps,
      teamData: state.teamData.data,
      dashboardTemplateList: state.dashboard.dashboardTemplateList,
      currentUser: state.currentUser,
    }),
    shallowEqual,
  );

  const stepsArr = Object.values(onboardingSteps ?? {}).sort(
    (step1, step2) => step1.order - step2.order,
  );
  const currentStep = stepsArr.find((step) => !step?.complete);
  const stepExpanded = (slug: string) => slug === currentStep?.slug;

  useEffect(() => {
    if (!teamData) {
      dispatch(fetchUserTeam());
    }
  }, [teamData, dispatch]);

  useEffect(() => {
    if (!dashboardTemplateList && teamData) {
      dispatch(fetchDashboardTemplateList({ id: teamData.id }));
    }
  }, [dashboardTemplateList, teamData, dispatch]);

  useEffect(() => {
    pageView('Onboarding');

    const scripts = addCalendlyScripts();
    return () => removeCalendlyScripts(scripts);
  }, []);

  const renderRequirements = (requirements: string[]) => (
    <>
      <h3 className={classes.header}>What you&rsquo;ll need</h3>
      <ul className={classes.requirements}>
        {requirements.map((requirement) => (
          <li className={classes.requirement} key={requirement}>
            <span className={classes.requirementText}>{requirement}</span>
          </li>
        ))}
      </ul>
    </>
  );

  const renderSubSteps = (subSteps: { slug: string; complete: boolean }[]) => (
    <div>
      <h3 className={classes.header}>Steps</h3>
      <ul style={{ paddingLeft: 0 }}>
        {subSteps.map((subStep) => {
          const subSetText = SUB_STEP_TITLES[subStep.slug];
          if (!subSetText) return null;

          return (
            <li className={classes.subStepItem} key={subStep.slug}>
              <Icon
                color={subStep.complete ? theme.palette.ds.green : theme.palette.ds.grey500}
                icon="tick"
                iconSize={12}
              />
              <p className={classes.subStepText}>{subSetText}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );

  const renderStep = (step: OnboardingStepData) => {
    switch (step.slug) {
      case STEPS.SET_UP_TEAM:
        return (
          <OnboardingStep
            key={step.slug}
            step={step}
            stepExpanded={stepExpanded(step.slug)}
            title={STEP_TITLES[step.slug]}>
            <div className={classes.stepBodyLeft}>
              <InviteBox />

              <p className={classes.stepDescription}>
                You&rsquo;ll be charged based on the # of customer groups, not seats - so invite any
                teammate who could be helpful in setting up your dashboard.
              </p>

              <Link to={ROUTES.BILLING_PAGE}>
                <Button
                  fillWidth={true}
                  icon="properties"
                  style={{ marginBottom: theme.spacing(2) }}
                  text="Select a plan"
                  type="primary"
                />
              </Link>

              <CalloutLink
                onClick={() => openSalesCalendly(currentUser)}
                rightIcon="mobile-phone"
                text="Chat with a member of our team to discover which plan works best for you"
              />
            </div>

            <div className={classes.stepBodyRight}>{renderSubSteps(step.sub_steps)}</div>
          </OnboardingStep>
        );
      case STEPS.CONNECT_DATABASE:
        return (
          <OnboardingStep
            key={step.slug}
            step={step}
            stepExpanded={stepExpanded(step.slug)}
            title={STEP_TITLES[step.slug]}>
            <div className={classes.stepBodyLeft}>
              <Button
                className={classes.stepPrimaryCta}
                fillWidth={true}
                onClick={() => {
                  history.push(ROUTES.DATA_PAGE);
                }}
                rightIcon="arrow-right"
                text="Connect your database"
                type="primary"
              />

              <p className={classes.stepDescription}>
                Once you connect your database you&rsquo;ll be able to create dashboards with your
                customers&rsquo; data. We don&rsquo;t store any of your data, we query your database
                every time a dashboard loads for a customer.
              </p>

              {renderRequirements([
                'Data credentials',
                'Admin access to your data source (i.e. AWS, GCP, Azure)',
              ])}

              <CalloutLink
                text="Review the developer documentation to learn more"
                url="//docs.explo.co/data-sources/connecting-to-your-database-or-data-warehouse"
              />
            </div>

            <div className={classes.stepBodyRight}>{renderSubSteps(step.sub_steps)}</div>
          </OnboardingStep>
        );
      case STEPS.CREATE_EXAMPLE_CUSTOMER:
        return (
          <OnboardingStep
            key={step.slug}
            step={step}
            stepExpanded={stepExpanded(step.slug)}
            title={STEP_TITLES[step.slug]}>
            <div className={classes.stepBodyLeft}>
              <Button
                className={classes.stepPrimaryCta}
                fillWidth={true}
                onClick={() => {
                  history.push(ROUTES.CUSTOMERS_PAGE);
                }}
                rightIcon="arrow-right"
                text="Create a customer"
                type="primary"
              />

              <p className={classes.stepDescription}>
                You can create customers manually in Explo - but it&rsquo;s more efficient to
                connect to our API so your customers are generated automatically. Customers or
                user-groups in Explo are groups of dashboard viewers that are part of the same
                organization and will see the same data. In most cases, this will be one of your
                customers.
              </p>

              {renderSubSteps(step.sub_steps)}

              <CalloutLink
                text="Connect your customers through our API"
                url="//docs.explo.co/api-reference/end-user-api"
              />
            </div>
            <div className={classes.stepBodyRight}>
              <div style={{ position: 'relative', paddingBottom: '49.40530649588289%', height: 0 }}>
                <iframe
                  allowFullScreen
                  frameBorder="0"
                  src="https://www.loom.com/embed/09a0ba453d5b473293002fbeab81f923?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  title="Loom demo"
                />
              </div>
            </div>
          </OnboardingStep>
        );
      case STEPS.CREATE_DASHBOARD:
        return (
          <OnboardingStep
            key={step.slug}
            step={step}
            stepExpanded={stepExpanded(step.slug)}
            title={STEP_TITLES[step.slug]}>
            <div className={classes.stepBodyLeft}>
              <Button
                className={classes.stepPrimaryCta}
                fillWidth={true}
                onClick={() => {
                  history.push(ROUTES.HOME_APP_PAGE);
                }}
                rightIcon="arrow-right"
                text="Take a guided tour"
                type="primary"
              />

              <p className={classes.stepDescription}>
                It&rsquo;s helpful to create an outline of your dashboard with your team before you
                start building.
              </p>

              {renderSubSteps(step.sub_steps)}
            </div>

            <div className={classes.stepBodyRight}>
              <div style={{ position: 'relative', paddingBottom: '49.40530649588289%', height: 0 }}>
                <iframe
                  allowFullScreen
                  frameBorder="0"
                  src="https://www.loom.com/embed/60dba929fca4407b9f028795979b38e3?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  title="Loom demo"
                />
              </div>
            </div>
          </OnboardingStep>
        );
      case STEPS.APPLY_STYLING:
        return (
          <OnboardingStep
            key={step.slug}
            step={step}
            stepExpanded={stepExpanded(step.slug)}
            title={STEP_TITLES[step.slug]}>
            <div className={classes.stepBodyLeft}>
              <Button
                className={classes.stepPrimaryCta}
                fillWidth={true}
                onClick={() => {
                  history.push(ROUTES.GLOBAL_CUSTOM_STYLES_PAGE);
                }}
                rightIcon="arrow-right"
                text="Take a guided tour"
                type="primary"
              />

              <p className={classes.stepDescription}>
                Styling your dashboard is the final step in preparing to add it your product.
              </p>

              {renderRequirements(['Branding materials', 'Mocks of your dashboard style'])}
            </div>

            <div className={classes.stepBodyRight}>{renderSubSteps(step.sub_steps)}</div>
          </OnboardingStep>
        );
      case STEPS.EMBED:
        return (
          <OnboardingStep
            key={step.slug}
            step={step}
            stepExpanded={stepExpanded(step.slug)}
            title={STEP_TITLES[step.slug]}>
            <div className={classes.stepBodyLeft}>
              <Button
                className={classes.stepPrimaryCta}
                fillWidth={true}
                onClick={() => {
                  if (!dashboardTemplateList || dashboardTemplateList.length === 0) {
                    showErrorToast('You need to create a dashboard to start the embed tour.');
                  } else {
                    history.push(ROUTES.HOME_APP_PAGE);
                  }
                }}
                rightIcon="arrow-right"
                text="View the embed snippet"
                type="primary"
              />

              <p className={classes.stepDescription}>
                Version control tags you configured when publishing your dashboard will help ensure
                that only the finalized versions are viewable on your app.
              </p>

              {renderRequirements(['Access to your codebase'])}

              {renderSubSteps(step.sub_steps)}

              <CalloutLink
                text="View documentation for embedding Explo dashboards"
                url="//docs.explo.co/embedding-documentation/whitelisting-your-domain"
              />
            </div>

            <div className={classes.stepBodyRight}></div>
          </OnboardingStep>
        );
    }
  };

  return (
    <div className={sprinkles({ width: 'fill', overflowY: 'scroll' })}>
      {!currentUser.has_seen_welcome_modal ? (
        <OnboardingModal
          message={
            isOnboardingComplete(stepsArr)
              ? undefined
              : teamData?.team_members.length === 1
              ? 'Use the setup checklist to quickly launch your dashboards.'
              : 'Help your team launch by completing the setup checklist.'
          }
        />
      ) : null}
      <div className={sprinkles({ widthConstraints: 'minAndMax', padding: 'sp2' })}>
        {stepsArr.map((step) => (step ? renderStep(step) : null))}
        {isOnboardingComplete(stepsArr) ? (
          <Link to={ROUTES.HOME_APP_PAGE}>
            <Button
              fillWidth={true}
              rightIcon="arrow-right"
              style={{ marginTop: theme.spacing(4) }}
              text="Go to Dashboards"
              type="primary"
            />
          </Link>
        ) : null}
      </div>
    </div>
  );
}

export default OnboardingPage;
