import { FC } from 'react';
import produce from 'immer';

import DropdownSelect from 'shared/DropdownSelect';

import { DateGroupToggleConfig } from 'types/dashboardTypes';
import { TrendGroupingOptions } from 'types/dateRangeTypes';
import { getDateGroupSwitchOptions } from 'utils/dashboardUtils';

type Props = {
  config: DateGroupToggleConfig;

  updateToggleConfig: (config: DateGroupToggleConfig) => void;
};

export const DateGroupDefaultValuesConfig: FC<Props> = ({ config, updateToggleConfig }) => {
  const groupingOptions = getDateGroupSwitchOptions(config);

  const currDefault = config.defaultGroupingOption ?? TrendGroupingOptions.MONTHLY;
  const defaultOption = groupingOptions.find((opt) => opt.id === currDefault);

  return (
    <DropdownSelect
      fillWidth
      ignoreCustomStyles
      minimal
      showIcon
      filterable={false}
      noSelectionText="Select Default Option"
      onChange={(newValue) => {
        const newConfig = produce(config, (draft) => {
          draft.defaultGroupingOption = newValue.id as TrendGroupingOptions;
        });
        updateToggleConfig(newConfig);
      }}
      options={groupingOptions}
      selectedItem={defaultOption}
    />
  );
};
