import { createReducer } from '@reduxjs/toolkit';

import { loginGoogleSuccess, setSignupInfoCollectionStep } from 'actions/authAction';
import { publishNewDashboardVersionSuccess } from 'actions/dashboardV2Actions';
import { syncSourceTablesSuccess } from 'actions/dataSourceActions';
import { addCustomerSuccess } from 'actions/customerActions';
import { completeOnboardingSubStepSuccess, setTestimonialIndex } from 'actions/onboardingActions';
import { inviteTeammatesSuccess } from 'actions/teamActions';
import { OnboardingStepData, logInUserSuccess } from 'actions/userActions';
import { SIGNUP_INFO_NEXT } from 'constants/onboardingConstants';

interface OnboardingReducer {
  onboardingSteps: Record<number, OnboardingStepData> | null;
  signupInfoCollectionStep: SIGNUP_INFO_NEXT | null;
  testimonialIndex: number | null;
}

const onboardingReducerInitialState: OnboardingReducer = {
  onboardingSteps: null,
  signupInfoCollectionStep: null,
  testimonialIndex: null,
};

export default createReducer(onboardingReducerInitialState, (builder) => {
  builder
    //Initializing
    .addCase(logInUserSuccess, (state, { payload }) => {
      if (payload.team?.onboarding_steps) {
        state.onboardingSteps = payload.team.onboarding_steps;
      }
      state.signupInfoCollectionStep = payload.signup_info_collection_step;
    })
    .addCase(loginGoogleSuccess, (state, { payload }) => {
      if (payload.user.team?.onboarding_steps) {
        state.onboardingSteps = payload.user.team.onboarding_steps;
      }
      state.signupInfoCollectionStep = payload.user.signup_info_collection_step;
    })
    //Updating
    .addCase(completeOnboardingSubStepSuccess, (state, { payload }) => {
      state.onboardingSteps = payload.onboarding_steps;
    })
    .addCase(inviteTeammatesSuccess, (state, { payload }) => {
      state.onboardingSteps = payload.onboarding_steps;
    })
    .addCase(publishNewDashboardVersionSuccess, (state, { payload }) => {
      state.onboardingSteps = payload.onboarding_steps;
    })
    .addCase(syncSourceTablesSuccess, (state, { payload }) => {
      state.onboardingSteps = payload.onboarding_steps;
    })
    .addCase(addCustomerSuccess, (state, { payload }) => {
      state.onboardingSteps = payload.onboarding_steps;
    })
    .addCase(setSignupInfoCollectionStep, (state, { payload }) => {
      state.signupInfoCollectionStep = payload;
    })
    .addCase(setTestimonialIndex, (state, { payload }) => {
      state.testimonialIndex = payload;
    })
    .addDefaultCase((state) => state);
});
