import { Layout } from '@explo-tech/react-grid-layout';
import { createAction } from '@reduxjs/toolkit';

import { FilterClause, OPERATION_TYPES } from 'constants/types';
import * as types from 'types/dashboardTypes';
import { DataPanelData, FilterOperation, VisualizeOperation } from 'types/dataPanelTemplate';
import { DatasetData } from './datasetActions';
import { DatasetSchema } from 'types/datasets';
import { ACTION } from './types';

export interface CanvasVersionConfig {
  datasets: Record<string, CanvasDataset>;
  filters: Record<string, CanvasFilter>;
  templates: Record<string, CanvasTemplate>;
  // Custom Components are shown as templates in UI
  customComponents?: Record<string, CanvasCustomComponent>;
  default_layout: Layout[];
}

export interface CanvasCustomComponent {
  id: string;
  name: string;
  description: string;
  info: CanvasCustomComponentType;
}

type CanvasCustomComponentType = {
  elementType: types.DASHBOARD_ELEMENT_TYPES.IFRAME;
  config: types.IframeElemConfig;
};

export const createCanvasComponent = createAction<string>(ACTION.CREATE_CANVAS_COMPONENT);

export const deleteCanvasComponent = createAction<string>(ACTION.DELETE_CANVAS_COMPONENT);

type UpdateCanvasComponent = {
  id: string;
  name?: string;
  description?: string;
  info?: CanvasCustomComponentType;
};

export const updateCanvasComponent = createAction<UpdateCanvasComponent>(
  ACTION.UPDATE_CANVAS_COMPONENT,
);

export interface CanvasTemplate extends DataPanelData {
  id: string;
  provided_id: string;
  dataset_id: string;
  name: string;
  description: string;

  filter_op?: FilterOperation;

  visualize_op: VisualizeOperation;
}

export const createCanvasTemplate = createAction<{ newId: string }>(ACTION.CREATE_CANVAS_TEMPLATE);

type UpdateCanvasTemplate = {
  templateId: string;
  providedId?: string;
  name?: string;
  description?: string;
  datasetId?: string;
  filters?: FilterClause[];
};

export const updateCanvasTemplate = createAction<UpdateCanvasTemplate>(
  ACTION.UPDATE_CANVAS_TEMPLATE,
);

export const updateCanvasTemplateChartType = createAction<{
  templateId: string;
  operationType: OPERATION_TYPES;
  isSubType: boolean;
}>(ACTION.UPDATE_CANVAS_TEMPLATE_CHART_TYPE);

export const updateCanvasTemplateVizOp = createAction<{
  templateId: string;
  vizOp: VisualizeOperation;
}>(ACTION.UPDATE_CANVAS_TEMPLATE_VIZ_OP);

export const deleteCanvasTemplate = createAction<{ templateId: string }>(
  ACTION.DELETE_CANVAS_TEMPLATE,
);

export interface CanvasFilter {
  id: string;
  provided_id: string;
  name: string;
  description: string;

  filter_info: CanvasFilterType | null;
}

export enum CanvasFilterOptions {
  SELECT = 'Dropdown/Toggle',
  DATE = 'Date',
}

export const canvasFilterOptions = [
  types.DASHBOARD_ELEMENT_TYPES.DROPDOWN,
  types.DASHBOARD_ELEMENT_TYPES.MULTISELECT,
  types.DASHBOARD_ELEMENT_TYPES.TOGGLE,
  types.DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER,
  types.DASHBOARD_ELEMENT_TYPES.DATEPICKER,
];

export type CanvasFilterType =
  | { filter_type: types.SELECT_FILTER_TYPE; config: types.SelectElemConfig }
  | { filter_type: types.DATE_FILTER_TYPE; config: types.DateElemConfig };

export const createCanvasFilter = createAction<{ newId: string }>(ACTION.CREATE_CANVAS_FILTER);

export const deleteCanvasFilter = createAction<{ filterId: string }>(ACTION.DELETE_CANVAS_FILTER);

type UpdateCanvas = {
  filterId: string;
  provided_id?: string;
  name?: string;
  description?: string;
};

export const updateCanvasFilter = createAction<UpdateCanvas>(ACTION.UPDATE_CANVAS_FILTER);

export const setCanvasFilterType = createAction<{
  filterId: string;
  filterType: types.DASHBOARD_ELEMENT_TYPES;
}>(ACTION.SET_CANVAS_FILTER_TYPE);

export const updateCanvasFilterConfig = createAction<{
  filterId: string;
  filterInfo: CanvasFilterType;
}>(ACTION.UPDATE_CANVAS_FILTER_CONFIG);

export type CanvasColumnOption = {
  isVisible: boolean;
  canBeGroupedBy: boolean;
  name: string;
  description?: string;
};

// If this is changed make sure to handle updates in BlueprintAPI
export interface CanvasDataset extends DatasetData {
  id: string;
  description: string;
  name: string;
  queryDraft?: string;
  query: string;
  parent_schema_id: number;
  schema?: DatasetSchema;
  columnOptions: Record<string, CanvasColumnOption>;
  requiredFilters: string[];
  isHiddenFromUsers: boolean;

  query_variables?: string[];

  _schema?: DatasetSchema;
  _is_new?: boolean;
  _loading?: boolean;
}

export const createCanvasDataset = createAction<{
  name: string;
  newId: string;
  parentSchemaId: number;
}>(ACTION.CREATE_CANVAS_DATASET);

type UpdateCanvasDataset = {
  datasetId: string;
  name?: string;
  description?: string;
  columnOptions?: Record<string, CanvasColumnOption>;
};

export const updateCanvasDataset = createAction<UpdateCanvasDataset>(ACTION.UPDATE_CANVAS_DATASET);

export const toggleCanvasDatasetVisibility = createAction<string>(
  ACTION.TOGGLE_CANVAS_DATASET_VISIBILITY,
);

export const updateRequiredFilters = createAction<{ filterId: string; datasetId: string }>(
  ACTION.UPDATE_REQUIRED_FILTERS,
);

export const removeElementFromCanvasLayout = createAction<{ elementId: string }>(
  ACTION.REMOVE_ELEMENT_FROM_CANVAS_LAYOUT,
);

export const addElementToCanvasLayout = createAction<{
  filterType?: types.DASHBOARD_ELEMENT_TYPES;
  id: string;
}>(ACTION.ADD_ELEMENT_TO_CANVAS_LAYOUT);

export const updateCanvasDefaultLayout = createAction<Layout[]>(
  ACTION.UPDATE_CANVAS_DEFAULT_LAYOUT,
);
