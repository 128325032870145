import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { FilterOperationInstructions, VisualizeTableInstructions } from 'constants/types';
import FlexBox, { FlexDirection } from 'components/core/FlexBox';
import FlexItem from 'components/core/FlexItem';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { AdHocOperationInstructions, VisualizeOperation } from 'types/dataPanelTemplate';
import { DatasetSchema, DatasetRow, DatabaseUnsupportedOperations } from 'types/datasets';
import DataTableHeader from '../../Header/DataTableHeader';
import StepFooter from '../StepFooter';
import DataTable from '../../DataTable/DataTable';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { replaceTemplatesWithValues } from 'utils/dataPanelConfigUtils';

const useStyles = makeStyles((theme: Theme) => ({
  fillHeight: {
    height: '100%',
  },
  tableContainer: {
    borderTop: `1px solid ${theme.palette.ds.grey200}`,
    minHeight: 0,
  },
}));

type Props = {
  adHocOperationInstructions: AdHocOperationInstructions;
  dataPanelId: string;
  error?: boolean;
  isEmbed: boolean;
  loading?: boolean;
  onAdHocFilterInfoUpdate: (adHocFilterInfo: FilterOperationInstructions) => void;
  onAdHocSortOrPageUpdate: (adHocOperationInstructions: AdHocOperationInstructions) => void;
  onBack: () => void;
  onNext: () => void;
  reportName?: string;
  rows: DatasetRow[];
  schema: DatasetSchema;
  secondaryData: DatasetRow[];
  sourceDataRowCount?: number;
  unsupportedOperations: DatabaseUnsupportedOperations[] | undefined;
  updateDataTableOperation: (visualizeInstructions: VisualizeTableInstructions) => void;
  visualizeOperation: VisualizeOperation;
  variables: DashboardVariableMap;
};

export default function PreviewStep({
  adHocOperationInstructions,
  dataPanelId,
  error,
  isEmbed,
  loading,
  onAdHocFilterInfoUpdate,
  onAdHocSortOrPageUpdate,
  onBack,
  onNext,
  reportName,
  rows,
  schema,
  secondaryData,
  sourceDataRowCount,
  updateDataTableOperation,
  visualizeOperation,
  variables,
  unsupportedOperations,
}: Props) {
  const classes = useStyles();
  if (reportName) reportName = replaceTemplatesWithValues(reportName, variables);

  return (
    <FlexBox
      className={cx(classes.fillHeight, GLOBAL_STYLE_CLASSNAMES.container.fill.backgroundColor)}
      direction={FlexDirection.COLUMN}>
      <FlexItem className={classes.tableContainer}>
        <DataTableHeader
          adHocOperationInstructions={adHocOperationInstructions}
          error={!!error}
          infoTooltipText="This is a preview of how your data will appear"
          isSchemaCustomizationEnabled={false}
          loading={loading}
          onAdHocFilterInfoUpdate={onAdHocFilterInfoUpdate}
          schema={schema}
          title={reportName ?? 'Preview'}
        />
        <DataTable
          adHocOperationInstructions={adHocOperationInstructions}
          canDownloadDataPanel={false}
          dataPanelId={dataPanelId}
          generalOptions={visualizeOperation.generalFormatOptions}
          instructions={visualizeOperation.instructions.VISUALIZE_TABLE}
          isEmbed={isEmbed}
          onAdHocSortOrPageUpdate={onAdHocSortOrPageUpdate}
          previewData={rows}
          schema={schema}
          secondaryData={secondaryData}
          sourceDataRowCount={sourceDataRowCount}
          unsupportedOperations={unsupportedOperations}
          updateDataTableOperation={updateDataTableOperation}
        />
      </FlexItem>
      <StepFooter onBack={onBack} onNext={onNext} text="Export" />
    </FlexBox>
  );
}
