import { createReducer } from '@reduxjs/toolkit';
import {
  updateConversationStarted,
  updateIsWidgetOpen,
  updateWidgetType,
} from 'actions/chatWidgetActions';
import { WIDGET_TYPES } from 'constants/hubspotConstants';

interface ChatWidgetReducerState {
  isOpen?: boolean;
  widgetType?: WIDGET_TYPES;
  conversationStarted: boolean;
}

export default createReducer<ChatWidgetReducerState>({ conversationStarted: false }, (builder) => {
  builder
    .addCase(updateIsWidgetOpen, (state, { payload }) => {
      state.isOpen = payload.isWidgetOpen;
    })
    .addCase(updateWidgetType, (state, { payload }) => {
      state.widgetType = payload.widgetType;
      state.isOpen = true;
      state.conversationStarted = false;
    })
    .addCase(updateConversationStarted, (state, { payload }) => {
      state.conversationStarted = payload.conversationStarted;
    })
    .addDefaultCase((state) => state);
});
