import { FC, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { makeStyles, Theme } from '@material-ui/core/styles';
import copy from 'copy-to-clipboard';
import parse from 'url-parse';
import { Switch, Icon, sprinkles } from 'components/ds';

import * as styles from './exportStyles.css';

import cx from 'classnames';
import 'react-resizable/css/styles.css';
import { isLoading, isSuccess, isIdle } from 'remotedata';

import InputGroup from 'explo-ds/forms/marketing/inputGroup';
import Button from 'shared/Button';
import InputLabel from 'shared/InputLabel';
import { WebShareProps } from 'components/ExportOptions/ExportOptions';

import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { JoinedStates } from 'reducers/rootReducer';
import { getUrlSanitizedDashboardVars } from 'utils/dashboardUtils';

export const DEFAULT_POPOVER_TITLE = 'Share to web';
export const DEFAULT_POPOVER_SUBTITLE_NO_PASS = 'Anyone with the link can view';
export const DEFAULT_POPOVER_SUBTITLE_PASS = 'Only people with the password can view';

const useStyles = makeStyles((theme: Theme) => ({
  linkButtons: {
    '&.bp3-button': {
      height: 30,
      marginRight: theme.spacing(1),
    },
  },
  passwordRequiredLabel: {
    color: `${theme.palette.ds.grey900} !important`,
  },
}));

type Props = {
  webShareProps: WebShareProps;
  onBackButtonClick: () => void;
};

export const WebShareOption: FC<Props> = ({ webShareProps, onBackButtonClick }: Props) => {
  const { config, fetchShareData, isSharedView, exportVars } = webShareProps;
  const classes = useStyles({ disableShareLink: config?.disableShareLink });

  const shareData = useSelector((state: JoinedStates) => state.dashboardLayout.shareData);

  const [isStrictViewingMode, setIsStrictViewingMode] = useState(false);
  const [passwordHasBeenSet, setPasswordHasBeenSet] = useState(false);
  const [inputUsername, setInputUsername] = useState('');
  const [inputPassword, setInputPassword] = useState('');

  const showPasswordElements = !isSharedView && config?.passwordEnabled;

  const fetchShare = useCallback(() => {
    if (!fetchShareData || isSharedView) return;
    fetchShareData(inputPassword, inputUsername, isStrictViewingMode);
  }, [fetchShareData, isSharedView, inputPassword, inputUsername, isStrictViewingMode]);

  useEffect(() => {
    if (!showPasswordElements && isIdle(shareData)) fetchShare();
  }, [showPasswordElements, shareData, fetchShare]);

  const showPasswordInput = showPasswordElements && !passwordHasBeenSet;

  const getShareUrl = () => {
    if (isSharedView) return window.location.href;
    if (!isSuccess(shareData)) return 'Generating...';

    const domain = shareData.data.shareLinkUrl || process.env.REACT_APP_URL;
    return parse(`${domain}share/${shareData.data.id}`)
      .set('query', getUrlSanitizedDashboardVars(exportVars ?? {}))
      .toString();
  };

  const shareUrl = getShareUrl();
  const showShareUrl = !showPasswordInput;
  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <Icon className={styles.backButton} name="arrow-left" onClick={onBackButtonClick} />
        {config?.popoverTitle || DEFAULT_POPOVER_TITLE}
      </div>
      <div
        className={cx(sprinkles({ body: 'b3' }), GLOBAL_STYLE_CLASSNAMES.text.smallBody.secondary)}>
        {config?.popoverSubtitle ||
          (config?.passwordEnabled
            ? DEFAULT_POPOVER_SUBTITLE_PASS
            : DEFAULT_POPOVER_SUBTITLE_NO_PASS)}
      </div>
      {showShareUrl ? (
        <div className={sprinkles({ flexItems: 'alignCenter' })}>
          <InputGroup fill readOnly className={styles.linkInput} value={shareUrl} />

          <Button
            className={cx(
              classes.linkButtons,
              GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.buttonBackgroundColor,
              GLOBAL_STYLE_CLASSNAMES.text.body.button.primaryFont,
            )}
            onClick={() => copy(shareUrl)}
            text="Copy"
            type="primary"
          />
          {showPasswordElements ? (
            <Button
              className={cx(
                classes.linkButtons,
                GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.buttonBackgroundColor,
                GLOBAL_STYLE_CLASSNAMES.text.body.button.primaryFont,
              )}
              icon="refresh"
              onClick={() => {
                setPasswordHasBeenSet(false);
                setInputPassword('');
              }}
              type="primary"
            />
          ) : null}
        </div>
      ) : null}
      {showPasswordInput && config?.usernameEnabled ? (
        <div>
          <InputLabel className={classes.passwordRequiredLabel} text="Username (Required)" />
          <div className={sprinkles({ flexItems: 'alignCenter' })}>
            <InputGroup
              fill
              className={styles.linkInput}
              onInputChange={setInputUsername}
              placeholder="Enter a username..."
              value={inputUsername}
            />
          </div>
        </div>
      ) : null}

      {showPasswordInput ? (
        <div>
          <InputLabel className={classes.passwordRequiredLabel} text="Password (Required)" />
          <div className={sprinkles({ flexItems: 'alignCenter' })}>
            <InputGroup
              fill
              className={styles.linkInput}
              onInputChange={setInputPassword}
              placeholder="Enter a password..."
              type="password"
              value={inputPassword}
            />
            <Button
              className={cx(
                classes.linkButtons,
                GLOBAL_STYLE_CLASSNAMES.base.actionColor.buttonColor.buttonBackgroundColor,
                GLOBAL_STYLE_CLASSNAMES.text.body.button.primaryFont,
              )}
              disabled={!inputPassword || (config?.usernameEnabled && !inputUsername)}
              icon="lock"
              loading={isLoading(shareData)}
              onClick={() => {
                fetchShare();
                setPasswordHasBeenSet(true);
              }}
              text="Set"
              type="primary"
            />
          </div>
        </div>
      ) : null}
      {!isSharedView ? (
        <Switch
          labelOnRight
          useCustomStyles
          className={styles.flexTogether}
          label="Allow viewers to interact with the filters"
          onChange={() => {
            // fire off the call before setting state as setState is
            // async so we want to immediately use it's eventual value
            fetchShare();
            setIsStrictViewingMode(!isStrictViewingMode);
          }}
          switchOn={!isStrictViewingMode}
        />
      ) : null}
    </div>
  );
};
