import { makeStyles, Theme } from '@material-ui/core/styles';
import { Icon, Popover, Position } from '@blueprintjs/core';

import ChartSelectPopover from './ChartSelectPopover';

import { VIZ_TO_NAME, OP_TYPE_TO_BP3_ICON } from 'constants/dataConstants';
import { OPERATION_TYPES } from 'constants/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  chartTypeBtn: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3),

    '&:hover': {
      cursor: 'pointer',
    },
  },
  chattTypeInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  chartTypeIcon: {
    marginRight: theme.spacing(2),
    width: 30,
  },
  text: {},
}));

type Props = {
  selectedChartType: string;
};

export default function VisualizationTypeSelector({ selectedChartType }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Popover
        captureDismiss
        fill
        minimal
        content={<ChartSelectPopover selectedChartType={selectedChartType} />}
        position={Position.BOTTOM_LEFT}>
        <div className={classes.chartTypeBtn}>
          <div className={classes.chattTypeInfo}>
            <div className={classes.chartTypeIcon}>
              <Icon
                icon={OP_TYPE_TO_BP3_ICON[selectedChartType as OPERATION_TYPES]}
                iconSize={20}
              />
            </div>
            <div className={classes.text}>{VIZ_TO_NAME[selectedChartType]}</div>
          </div>
          <Icon icon="expand-all" />
        </div>
      </Popover>
    </div>
  );
}
