import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { ErrorResponse } from 'actions/responseTypes';
import { saveShareMetadata, saveSendEmailsAs } from 'actions/teamActions';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import { ReduxState } from 'reducers/rootReducer';
import InputLabel from 'shared/InputLabel';
import { showErrorContactSupportToast } from 'shared/sharedToasts';
import validator from 'validator';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: `0 ${theme.spacing(8)}px 125px ${theme.spacing(8)}px`,
  },
  header: {
    fontSize: 20,
    fontWeight: 600,
  },
  subtitle: {
    color: theme.palette.ds.grey700,
    marginBottom: theme.spacing(8),
  },
  saveButton: {
    '&.bp3-button': {
      height: 30,
      margin: 0,
    },
  },
  input: {
    paddingBottom: theme.spacing(4),
  },
}));

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

function SettingsShareLinkSection(props: Props) {
  const {
    faviconUrl,
    saveShareMetadata,
    dashboardLogoUrl,
    externalEmail,
    saveSendEmailsAs,
    siteTitle,
    portalLoginText,
  } = props;

  const classes = useStyles();
  const [faviconUrlInternal, setFaviconUrlInternal] = useState(faviconUrl || '');
  const [dashboardLogoUrlInternal, setDashboardLogoUrlInternal] = useState(dashboardLogoUrl || '');
  const [siteTitleInternal, setSiteTitleInternal] = useState(siteTitle || '');
  const [externalEmailInternal, setExternalEmailInternal] = useState(externalEmail || '');
  const [portalLoginTextInternal, setPortalLoginTextInternal] = useState(portalLoginText || '');

  useEffect(() => {
    // Fixes race condition where these haven't loaded yet but we set them
    if (faviconUrl) setFaviconUrlInternal(faviconUrl);
    if (dashboardLogoUrl) setDashboardLogoUrlInternal(dashboardLogoUrl);
    if (siteTitle) setSiteTitleInternal(siteTitle);
    if (externalEmail) setExternalEmailInternal(externalEmail);
    if (portalLoginText) setPortalLoginTextInternal(portalLoginText);
  }, [faviconUrl, dashboardLogoUrl, externalEmail, siteTitle, portalLoginText]);

  const validateInput = (value: string | undefined) => validator.isURL(value ?? '') || value === '';

  return (
    <div className={classes.root}>
      <div className={classes.header}>Share Links</div>
      <div className={classes.subtitle}>
        {'Configure globally applied customizations to your share links and share link pages.'}
      </div>
      <InputLabel
        helpText="This should be your site's domain. We'll automatically grab the favicon from there and show it on your share link pages!"
        text="Page Favicon URL"
      />
      <InputWithBlurSave
        containerClassName={classes.input}
        errorText={validateInput(faviconUrlInternal) ? undefined : 'Invalid URL'}
        initialValue={faviconUrlInternal}
        onNewValueSubmitted={(newValue: string) => {
          setFaviconUrlInternal(newValue);

          if (validateInput(newValue) && newValue !== faviconUrlInternal) {
            saveShareMetadata(
              { postData: { favicon_url: newValue } },
              undefined,
              (response: ErrorResponse) => {
                showErrorContactSupportToast(response.detail);
              },
            );
          }
        }}
        placeholder="www.yourdomain.com"
      />
      <InputLabel
        helpText="This will show up in the top left of your dashboards accessed from an Customer Portal and on your portal's login screen"
        text="Portal Logo"
      />
      <InputWithBlurSave
        containerClassName={classes.input}
        errorText={validateInput(dashboardLogoUrlInternal) ? undefined : 'Invalid URL'}
        initialValue={dashboardLogoUrlInternal}
        onNewValueSubmitted={(newValue: string) => {
          setDashboardLogoUrlInternal(newValue);

          if (validateInput(newValue) && newValue !== dashboardLogoUrlInternal) {
            saveShareMetadata(
              { postData: { dashboard_logo_url: newValue } },
              undefined,
              (response: ErrorResponse) => {
                showErrorContactSupportToast(response.detail);
              },
            );
          }
        }}
        placeholder="www.yourdomain.com"
      />
      <InputLabel
        helpText="This will be the site title of your Customer Portal. Note that this is overriden if you've set a site title for your portal's dashboard"
        text="Portal Site Title"
      />
      <InputWithBlurSave
        containerClassName={classes.input}
        initialValue={siteTitleInternal}
        onNewValueSubmitted={(newValue: string) => {
          setSiteTitleInternal(newValue);

          if (newValue !== siteTitleInternal) {
            saveShareMetadata(
              { postData: { portal_site_title: newValue } },
              undefined,
              (response: ErrorResponse) => {
                showErrorContactSupportToast(response.detail);
              },
            );
          }
        }}
        placeholder="Your Dashboard Portal"
      />
      <InputLabel
        helpText="This will be the login text of your Customer Portal. The default if this section is left blank is 'Contact your administrator for login information.'"
        text="Portal Login Text"
      />
      <InputWithBlurSave
        containerClassName={classes.input}
        initialValue={portalLoginTextInternal}
        onNewValueSubmitted={(newValue: string) => {
          setPortalLoginTextInternal(newValue);

          if (newValue !== portalLoginTextInternal) {
            saveShareMetadata(
              { postData: { portal_login_text: newValue } },
              undefined,
              (response: ErrorResponse) => {
                showErrorContactSupportToast(response.detail);
              },
            );
          }
        }}
        placeholder="Contact your administrator for login information"
      />
      <InputLabel
        helpText="This will be the email that sends portal authentication links to your customers. Reach out to Explo support to set this up."
        text="External Email"
      />
      <InputWithBlurSave
        disabled
        errorText={
          validator.isEmail(externalEmailInternal ?? '') || externalEmailInternal === ''
            ? undefined
            : 'Invalid Email'
        }
        initialValue={externalEmailInternal}
        onNewValueSubmitted={(newValue: string) => {
          setExternalEmailInternal(newValue);

          if (
            (validator.isEmail(newValue ?? '') || newValue === '') &&
            newValue !== externalEmailInternal
          ) {
            saveSendEmailsAs(
              { postData: { send_emails_as: newValue } },
              undefined,
              (response: ErrorResponse) => {
                showErrorContactSupportToast(response.detail);
              },
            );
          }
        }}
        placeholder="your_email@domain.com"
      />
    </div>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  faviconUrl: state.teamData.data?.favicon_url,
  dashboardLogoUrl: state.teamData.data?.dashboard_logo_url,
  externalEmail: state.teamData.data?.send_emails_as,
  siteTitle: state.teamData.data?.portal_site_title,
  portalLoginText: state.teamData.data?.portal_login_text,
});

const mapDispatchToProps = { saveShareMetadata, saveSendEmailsAs };

export default connect(mapStateToProps, mapDispatchToProps)(SettingsShareLinkSection);
