export enum DEFAULT_ROLES {
  ADMIN = 'Admin',
  PUBLISHER = 'Publisher',
  EDITOR = 'Editor',
  VIEWER = 'Viewer',
}
export const DEFAULT_ROLE_OPTIONS = Object.values(DEFAULT_ROLES).map((role) => {
  return { name: role, id: role };
});

// this will have to be kept in sync with role_permissions.actions
export enum PERMISSIONED_ACTIONS {
  READ = 'READ',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  VIEW_EMBED_SNIPPET = 'VIEW_EMBED_SNIPPET',
  PUBLISH = 'PUBLISH',
  UPDATE_ADMIN_SETTING = 'UPDATE_ADMIN_SETTING',
  UPDATE_DEVELOPER_SETTING = 'UPDATE_DEVELOPER_SETTING',
  UPDATE_BILLING = 'UPDATE_BILLING',
  UPDATE_CUSTOM_STYLES = 'UPDATE_CUSTOM_STYLES',
}

export enum PERMISSIONED_ENTITIES {
  DATA_SOURCE = 'datasource',
  DASHBOARD = 'dashboardtemplate',
  END_USER_GROUP = 'endusergroup',
  CUSTOMER = 'customer',
  TEAM = 'team',
  ROLE = 'role',
  ARCHITECT = 'canvas',
  EMAIL = 'emailcadence',
  PORTAL = 'portal',
  REPORT_BUILDER = 'reportbuilder',
}
