import produce from 'immer';
import { Switch } from 'components/ds';

import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import FormatSection from './FormatSection';

import { sharedStyles } from './sharedStyles';
import {
  OPERATION_TYPES,
  V2GeneralInstructions,
  V2KPIChartInstructions,
  V2TwoDimensionChartInstructions,
  VisualizeOperationGeneralFormatOptions,
  VisualizeTableInstructions,
} from 'constants/types';
import {
  GeneralFormatOptionsOperationType,
  UpdateInstructionsPayload,
} from 'actions/updateInstructionsActions';

type Props = {
  isArchitectCustomerDashboard: boolean;
  instructions:
    | V2TwoDimensionChartInstructions
    | V2KPIChartInstructions
    | VisualizeTableInstructions;
  operationType: OPERATION_TYPES;
  options: VisualizeOperationGeneralFormatOptions;
  updateInstructions: (payload: UpdateInstructionsPayload) => void;
};

export default function TitleSection({
  isArchitectCustomerDashboard,
  instructions,
  operationType,
  updateInstructions,
  options,
}: Props): JSX.Element {
  const classes = sharedStyles({ isArchitectCustomerDashboard });

  const updateGeneralInstructions = (updates: V2GeneralInstructions) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.generalFormat = { ...draft.generalFormat, ...updates };
    });

    updateInstructions({
      instructions: newInstructions,
      operationType,
    } as UpdateInstructionsPayload);
  };

  return (
    <FormatSection isArchitectCustomerDashboard={isArchitectCustomerDashboard} title="Title">
      {isArchitectCustomerDashboard ? (
        <InputWithBlurSave
          fillWidth
          containerClassName={classes.configInput}
          initialValue={options.headerConfig?.title}
          label="Title"
          onNewValueSubmitted={(newTitle) => {
            const newInstructions = produce(options, (draft) => {
              draft.headerConfig = {
                ...draft.headerConfig,
                title: newTitle,
              };
            });

            updateInstructions({
              instructions: newInstructions,
              operationType: GeneralFormatOptionsOperationType,
            });
          }}
          placeholder="Title"
        />
      ) : null}
      <Switch
        className={classes.configInput}
        label="Descriptive Tooltip"
        onChange={() =>
          updateGeneralInstructions({ showTooltip: !instructions.generalFormat?.showTooltip })
        }
        switchOn={instructions.generalFormat?.showTooltip}
        useCustomStyles={isArchitectCustomerDashboard}
      />
      {instructions.generalFormat?.showTooltip ? (
        <InputWithBlurSave
          containerClassName={classes.configInput}
          ignoreCustomStyles={!isArchitectCustomerDashboard}
          initialValue={instructions.generalFormat?.tooltipText || ''}
          onNewValueSubmitted={(newValue) => updateGeneralInstructions({ tooltipText: newValue })}
          placeholder=""
        />
      ) : null}
    </FormatSection>
  );
}
