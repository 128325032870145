import { FC, MouseEvent as ReactMouseEvent, forwardRef, Ref } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faAngleLeft,
  faAngleRight,
  faArrowDownRight,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowDown,
  faArrowRightToLine,
  faArrowsRotate,
  faArrowUpRight,
  faArrowUpRightAndArrowDownLeftFromCenter,
  faArrowDownToBracket,
  faBars,
  faCircle,
  faChartLine,
  faCaretDown,
  faCaretRight,
  faCheck,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faCircleExclamation,
  faClock,
  faClone,
  faDatabase,
  faUser,
  faEdit,
  faEllipsisVertical,
  faEmptySet,
  faEnvelope,
  faFile,
  faFileExport,
  faGear,
  faGlobe,
  faMessages,
  faMoneyBill1Wave,
  faPaintRoller,
  faEye,
  faEyeSlash,
  faFilter,
  faFilterList,
  faGrid2,
  faGripDotsVertical,
  faHandPointer,
  faHorizontalRule,
  faList,
  faMinus,
  faPaperPlane,
  faPencil,
  faPlay,
  faPlus,
  faRectangleTerminal,
  faRightToBracket,
  faSearch,
  faSparkles,
  faUsers,
  faUserSlash,
  faStar,
  faTable,
  faTrash,
  faTrashUndo,
  faUserGroup,
  faWrench,
  faXmark,
  faXmarkLarge,
  faArrowUpFromLine,
  faArrowUpWideShort,
  faArrowDownWideShort,
} from '@fortawesome/pro-solid-svg-icons';
import {
  exSidebarArchitect,
  exSidebarArchitectFilled,
  exSidebarDashboards,
  exSidebarDashboardsFilled,
  exSidebarReport,
  exSidebarReportFilled,
  exBlank,
} from './custom';
import {
  faChartLine as faChartLineReg,
  faCircleExclamation as faCircleExclamationReg,
  faCircleQuestion as faCircleQuestionReg,
  faMessages as faMessagesReg,
  faDatabase as faDatabaseReg,
  faFile as faFileReg,
  faFilter as regularFilter,
  faPaintRoller as faPaintRollerReg,
  faStar as regularStar,
  faUsers as faRegUsers,
  faMoneyBill1Wave as faMoneyBill1WaveReg,
  faEnvelope as faEnvelopeReg,
} from '@fortawesome/pro-regular-svg-icons';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';

import * as styles from './index.css';

// Import and add any new FA icons here
const nameMapping = {
  'angle-left': faAngleLeft,
  'angle-right': faAngleRight,
  'arrow-left': faArrowLeft,
  'arrow-right': faArrowRight,
  'arrow-up': faArrowUp,
  'arrow-down': faArrowDown,
  'arrow-down-to-bracket': faArrowDownToBracket,
  'arrow-right-to-line': faArrowRightToLine,
  'arrows-rotate': faArrowsRotate,
  'arrow-up-from-line': faArrowUpFromLine,
  'arrow-up-right': faArrowUpRight,
  'arrow-down-right': faArrowDownRight,
  'arrow-up-wide-short': faArrowUpWideShort,
  'arrow-down-wide-short': faArrowDownWideShort,
  bars: faBars,
  blank: exBlank, // TODO: Remove when no longer using BP
  'chart-line': faChartLine,
  'chart-line-reg': faChartLineReg,
  check: faCheck,
  'chevron-up': faChevronUp,
  'caret-down': faCaretDown,
  'caret-right': faCaretRight,
  circle: faCircle,
  'chevron-down': faChevronDown,
  'chevron-right': faChevronRight,
  'circle-exclamation': faCircleExclamation,
  'circle-exclamation-reg': faCircleExclamationReg,
  'circle-question-reg': faCircleQuestionReg,
  clock: faClock,
  clone: faClone,
  cross: faXmark,
  'ellipsis-vertical': faEllipsisVertical,
  'empty-set': faEmptySet,
  'eye-closed': faEyeSlash,
  'eye-open': faEye,
  gear: faGear,
  grid: faGrid2,
  globe: faGlobe,
  'grip-dots': faGripDotsVertical,
  'hand-pointer': faHandPointer,
  'large-cross': faXmarkLarge,
  'regular-filter': regularFilter,
  'regular-star': regularStar,
  'user-group': faUserGroup,
  'vertical-grip': faGripDotsVertical,
  database: faDatabase,
  'database-reg': faDatabaseReg,
  envelope: faEnvelope,
  'envelope-reg': faEnvelopeReg,
  edit: faEdit,
  file: faFile,
  'file-export': faFileExport,
  'file-reg': faFileReg,
  'horizontal-rule': faHorizontalRule,
  list: faList,
  messages: faMessages,
  'messages-reg': faMessagesReg,
  money: faMoneyBill1Wave,
  'money-reg': faMoneyBill1WaveReg,
  'paint-roller': faPaintRoller,
  'paint-roller-reg': faPaintRollerReg,
  'paper-plane': faPaperPlane,
  'rectangle-terminal': faRectangleTerminal,
  'right-arrow-from-bracket': faRightToBracket,
  search: faSearch,
  sparkles: faSparkles,
  spinner: faSpinnerThird,
  'user-slash': faUserSlash,
  users: faUsers,
  user: faUser,
  'users-reg': faRegUsers,
  expand: faArrowUpRightAndArrowDownLeftFromCenter,
  filter: faFilter,
  'filter-list': faFilterList,
  minus: faMinus,
  pencil: faPencil,
  play: faPlay,
  plus: faPlus,
  star: faStar,
  table: faTable,
  tick: faCheck, // TODO: Remove when no longer using BP
  trash: faTrash,
  trashUndo: faTrashUndo,
  wrench: faWrench,
};

const customIconNameMapping = {
  'sidebar-architect': exSidebarArchitect,
  'sidebar-architect-filled': exSidebarArchitectFilled,
  'sidebar-dashboards': exSidebarDashboards,
  'sidebar-dashboards-filled': exSidebarDashboardsFilled,
  'sidebar-report': exSidebarReport,
  'sidebar-report-filled': exSidebarReportFilled,
};

export type IconName = keyof typeof nameMapping | keyof typeof customIconNameMapping;
export type IconSize = 'sm' | 'md' | 'lg' | 'xl';

export type Props = {
  /**
   * Optional class name for style overrides
   */
  className?: string;
  /**
   * Optional test ID for selecting component in tests
   */
  'data-testid'?: string;
  /**
   * Name of the Font Awesome icon
   */
  name: IconName;
  /**
   * React ref to pass for interacting with DOM instance
   */
  parentRef?: Ref<SVGSVGElement>;
  /**
   * Size of the icon relative to font size
   */
  size?: IconSize;
  /**
   * Animate icon with a spin animation
   */
  spin?: boolean;
  /**
   * Callback to be fired when the icon is clicked
   */
  onClick?: (event: ReactMouseEvent<SVGSVGElement, MouseEvent>) => void;
};

const allIcons = { ...nameMapping, ...customIconNameMapping };

export const Icon: FC<Props> = forwardRef<SVGSVGElement, Props>(
  ({ className, 'data-testid': testId, name, parentRef, size, onClick, spin, ...props }, ref) => {
    return (
      <FontAwesomeIcon
        {...props}
        className={cx(styles.base({ size }), className)}
        data-testid={testId}
        fillRule={name in customIconNameMapping ? 'evenodd' : undefined}
        icon={allIcons[name]}
        onClick={onClick}
        ref={parentRef ?? ref}
        spin={spin}
      />
    );
  },
);

Icon.displayName = 'Icon';
