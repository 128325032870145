import { defineAPIAction, defineAPIPostAction } from 'actions/actionUtils';
import { Customer } from 'actions/teamActions';
import { UpdateOnboardingStepsResponse } from './onboardingActions';
import { ACTION } from './types';

// Move Customer Type in here

type FetchCustomersData = {
  customers: Customer[];
};

export const {
  actionFn: fetchCustomers,
  requestAction: fetchCustomersRequest,
  errorAction: fetchCustomersError,
  successAction: fetchCustomersSuccess,
} = defineAPIAction<FetchCustomersData>(ACTION.FETCH_CUSTOMERS, 'customers', '', 'GET');

type AddCustomerBody = {
  name: string;
  id: string;
  mapping: Record<string, string>;
  access_group_id: number;
  is_demo_group: boolean;
  properties: Record<string, string>;
};

type AddCustomerData = {
  customer: Customer;
} & UpdateOnboardingStepsResponse;

export const { actionFn: addCustomer, successAction: addCustomerSuccess } = defineAPIPostAction<
  AddCustomerBody,
  AddCustomerData
>(ACTION.CREATE_CUSTOMER, 'customers', '', 'POST');

export const { actionFn: deleteCustomer, successAction: deleteCustomersSuccess } = defineAPIAction(
  ACTION.DELETE_CUSTOMER,
  'customers',
  '',
  'DELETE',
);

type EditCustomerBody = {
  name: string;
  provided_id: string;
  mapping: Record<string, string>;
  access_group_id: number;
  is_demo_group: boolean;
  properties: Record<string, string>;
};

type EditCustomerData = {
  user_group: Customer;
};

export const { actionFn: editCustomer, successAction: editCustomersSuccess } = defineAPIPostAction<
  EditCustomerBody,
  EditCustomerData
>(ACTION.EDIT_CUSTOMER, 'customers', 'update_user_group', 'POST');
