import { useDispatch, useSelector } from 'react-redux';
import produce from 'immer';
import { useTheme } from '@material-ui/core';
import { AppToaster } from 'toaster';
import { Divider, Position, Tooltip } from '@blueprintjs/core';

import SettingHeader from '../SettingHeader';
import DroppableColumnSection from './droppable/DroppableColumnSection';
import { Switch } from 'components/ds';
import DropdownSection from './droppable/DropdownSection';
import Button from 'shared/Button';

import { Aggregation, OPERATION_TYPES, VisualizePivotTableInstructions } from 'constants/types';
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { resolveCategoryColDropped, resolveAggColDropped } from './utils';
import { Dataset } from 'actions/datasetActions';
import { DatasetSchema } from 'types/datasets';
import { ReduxState } from 'reducers/rootReducer';

type Props = {
  instructions: VisualizePivotTableInstructions;
  chartType: OPERATION_TYPES;
  loading?: boolean;
  dashboardDatasets: Record<string, Dataset>;
  schema: DatasetSchema;
};

export default function PivotTableConfig({
  instructions,
  chartType,
  loading,
  dashboardDatasets,
  schema,
}: Props) {
  const dispatch = useDispatch();
  const theme = useTheme();

  const showCustomFormula = useSelector(
    (state: ReduxState) =>
      !!state.currentUser.team?.feature_flags.pivot_table_custom_formula_enabled,
  );

  const savedDatasets = Object.values(dashboardDatasets)
    .filter((dataset) => !!dataset.schema)
    .map((dataset) => {
      return {
        name: dataset.table_name,
        id: dataset.id,
      };
    });
  const selectedDataset = instructions.joinedColumns?.joinTable
    ? dashboardDatasets[instructions.joinedColumns.joinTable.id]
    : undefined;

  const joinedColumnName = instructions.joinedColumns?.joinColumn?.name;
  const selectedJoinColumn = joinedColumnName
    ? selectedDataset?.schema?.find((col) => col.name === joinedColumnName)
    : undefined;

  return (
    <div>
      <SettingHeader name="Columns" />
      <DroppableColumnSection
        required
        columns={instructions.colColumn ? [instructions.colColumn] : []}
        disableEdits={loading}
        maxCols={1}
        onColAdded={(col) => {
          const newInstructions = produce(instructions, (draft) => {
            draft.colColumn = { column: col }; // colCol doesn't use resolveCategoryColDropped because it doesn't support bucketing
          });
          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        onColOptionChanged={(option) => {
          const newInstructions = produce(instructions, (draft) => {
            if (draft.colColumn) draft.colColumn.bucket = option;
          });
          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        onRemoveCol={() => {
          const newInstructions = produce(instructions, (draft) => {
            draft.colColumn = undefined;
          });
          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        schema={schema}
      />

      <SettingHeader name="Rows" />
      <DroppableColumnSection
        required
        columns={instructions.rowColumn ? [instructions.rowColumn] : []}
        disableEdits={loading}
        maxCols={1}
        onColAdded={(col) => {
          const newInstructions = produce(instructions, (draft) => {
            draft.rowColumn = resolveCategoryColDropped(col, draft.rowColumn);
          });
          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        onColOptionChanged={(option) => {
          const newInstructions = produce(instructions, (draft) => {
            if (draft.rowColumn) draft.rowColumn.bucket = option;
          });
          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        onRemoveCol={() => {
          const newInstructions = produce(instructions, (draft) => {
            draft.rowColumn = undefined;
          });
          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        schema={schema}
      />

      {!showCustomFormula ? (
        <SettingHeader name="Pivot Value" />
      ) : (
        <SettingHeader
          name="Pivot Value"
          rightContent={
            <Tooltip
              usePortal
              content="Click to add a custom formula aggregation."
              position={Position.BOTTOM}>
              <Button
                compact
                minimal
                icon="function"
                onClick={() => {
                  const newInstructions = produce(instructions, (draft) => {
                    draft.aggregation = resolveAggColDropped(
                      { name: 'custom_formula', type: 'string' },
                      (toastInfo) => AppToaster.show(toastInfo),
                      draft.aggregation ? [draft.aggregation] : [],
                      1,
                    )[0];

                    draft.aggregation.agg = { id: Aggregation.FORMULA, formula: '' };
                  });
                  dispatch(updateVisualizeOperation(newInstructions, chartType));
                }}
              />
            </Tooltip>
          }
        />
      )}
      <DroppableColumnSection
        includeFirst
        required
        columns={instructions.aggregation ? [instructions.aggregation] : []}
        disableEdits={loading}
        maxCols={1}
        onColAdded={(col) => {
          const newInstructions = produce(instructions, (draft) => {
            draft.aggregation = resolveAggColDropped(
              col,
              (toastInfo) => AppToaster.show(toastInfo),
              draft.aggregation ? [draft.aggregation] : [],
              1,
            )[0];
          });
          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        onColOptionChanged={(option, name, aggType) => {
          const newInstructions = produce(instructions, (draft) => {
            const col = draft.aggregation;
            if (!col) return;
            if (col.column.name === name && (!aggType || col.agg.id === aggType.id)) {
              col.agg = { id: option.id as Aggregation, formula: option.formula };
            }
          });
          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        onRemoveCol={() => {
          const newInstructions = produce(instructions, (draft) => {
            draft.aggregation = undefined;
          });
          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        schema={schema}
      />
      <SettingHeader
        name="Join with another dataset"
        rightContent={
          <Switch
            useCustomStyles
            onChange={(newValue: boolean) => {
              const newInstructions = produce(instructions, (draft) => {
                if (newValue) {
                  draft.joinedColumns = {};
                } else {
                  draft.joinedColumns = undefined;
                }
              });

              dispatch(updateVisualizeOperation(newInstructions, chartType));
            }}
            switchOn={!!instructions.joinedColumns}
          />
        }
      />
      {instructions.joinedColumns !== undefined && (
        <>
          <DropdownSection
            caretColor={theme.palette.ds.grey900}
            disableEdits={loading}
            onOptionChanged={(option) => {
              const newInstructions = produce(instructions, (draft) => {
                draft.joinedColumns = {
                  ...draft.joinedColumns,
                  joinTable: option,
                };
              });

              dispatch(updateVisualizeOperation(newInstructions, chartType));
            }}
            options={savedDatasets}
            placeholder="Select a dataset"
            selectedOption={
              selectedDataset
                ? { name: selectedDataset.table_name, id: selectedDataset.id }
                : undefined
            }
          />
          <Divider />
          <DropdownSection
            caretColor={theme.palette.ds.grey900}
            disableEdits={loading}
            onOptionChanged={(option) => {
              const newInstructions = produce(instructions, (draft) => {
                const joinColumn = selectedDataset?.schema?.find((col) => col.name === option.name);
                draft.joinedColumns = {
                  ...draft.joinedColumns,
                  joinColumn: joinColumn,
                };
              });
              dispatch(updateVisualizeOperation(newInstructions, chartType));
            }}
            options={
              selectedDataset && selectedDataset.schema
                ? selectedDataset.schema.map((col) => ({
                    name: col.name,
                    id: col.name,
                  }))
                : []
            }
            placeholder="Select a column"
            selectedOption={
              selectedJoinColumn && {
                id: selectedJoinColumn.name,
                name: selectedJoinColumn.name,
              }
            }
          />
        </>
      )}
    </div>
  );
}
