import { FC, useMemo } from 'react';
import { DateTime } from 'luxon';

import { sprinkles } from 'components/ds';
import * as styles from '../styles.css';
import CustomCheckbox from 'components/checkbox';
import DropdownSelect from 'shared/DropdownSelect';
import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';
import DatePickerInput from 'shared/DatePickerInput';
import {
  SortableListItemDragHandle,
  SortableList,
  SortableListItem,
} from 'components/SortableList/SortableList';

import { DASHBOARD_ELEMENT_TYPES, DateElemConfig, DATE_FILTER_TYPE } from 'types/dashboardTypes';
import {
  DATE_RANGE_TYPES,
  DEFAULT_DATE_RANGES,
  DEFAULT_DATE_RANGES_DISPLAY_OVERWRITES,
  RELATIVE_DATE_RANGES,
} from 'types/dateRangeTypes';
import { dateTimeFromISOString } from 'utils/dateUtils';
import { getUpdateConfigFunc } from '../utils';
import { sortBy } from 'utils/standard';

type Props = {
  config: DateElemConfig;
  dateFilterType: DATE_FILTER_TYPE;

  updateDateConfig: (config: DateElemConfig) => void;
};

export const DateValuesConfig: FC<Props> = ({ config, dateFilterType, updateDateConfig }) => {
  const updateConfig = getUpdateConfigFunc(config, updateDateConfig);

  const orderedRanges = useMemo(() => {
    if (dateFilterType !== DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER) return [];
    const numDefaultRanges = Object.keys(DEFAULT_DATE_RANGES).length;

    return sortBy(DEFAULT_DATE_RANGES, (range) => {
      const index = config.defaultRangesOrder?.indexOf(range);

      if (index === undefined) return undefined;
      if (index === -1) return numDefaultRanges;
      return index;
    });
  }, [dateFilterType, config]);

  if (dateFilterType === DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER) {
    return (
      <SortableList
        getIdFromElem={(item) => item}
        onListUpdated={(newList) =>
          updateConfig((draft) => {
            draft.defaultRangesOrder = newList;
          })
        }
        sortableItems={orderedRanges}>
        {orderedRanges.map((dateOption) => {
          return (
            <SortableListItem key={dateOption} sortId={dateOption}>
              <div className={styles.sortableRangeOption}>
                <SortableListItemDragHandle
                  className={sprinkles({ color: 'contentSecondary', marginRight: 'sp1' })}
                />
                <CustomCheckbox
                  small
                  isChecked={!config.hiddenDefaultRanges?.includes(dateOption)}
                  onChange={() => {
                    updateConfig((draft) => {
                      if (!draft.hiddenDefaultRanges) draft.hiddenDefaultRanges = [];

                      if (draft.hiddenDefaultRanges.includes(dateOption)) {
                        draft.hiddenDefaultRanges = draft.hiddenDefaultRanges.filter(
                          (opt) => opt !== dateOption,
                        );
                      } else {
                        draft.hiddenDefaultRanges.push(dateOption);
                      }
                    });
                  }}
                />
                <div className={sprinkles({ marginLeft: 'sp1' })}>
                  {DEFAULT_DATE_RANGES_DISPLAY_OVERWRITES[dateOption] ?? dateOption}
                </div>
              </div>
            </SortableListItem>
          );
        })}
      </SortableList>
    );
  }

  return (
    <>
      <ToggleButtonGroup fillWidth className={styles.configInput} label="Selectable Range">
        <ToggleButton
          active={config.dateRangeType === DATE_RANGE_TYPES.EXACT}
          onClick={() => updateConfig((draft) => (draft.dateRangeType = DATE_RANGE_TYPES.EXACT))}
          text="Exact Range"
        />
        <ToggleButton
          active={config.dateRangeType !== DATE_RANGE_TYPES.EXACT}
          onClick={() => updateConfig((draft) => (draft.dateRangeType = DATE_RANGE_TYPES.RELATIVE))}
          text="Relative Range"
        />
      </ToggleButtonGroup>
      {config.dateRangeType === DATE_RANGE_TYPES.EXACT ? (
        <>
          <DatePickerInput
            showCancelBtn
            className={styles.configInput}
            labelProps={{ text: 'Start Date' }}
            onCancelClick={() => updateConfig((draft) => (draft.minValue = undefined))}
            onNewValueSelect={(newValue) =>
              updateConfig((draft) => (draft.minValue = (newValue as DateTime) || undefined))
            }
            selectedValue={
              config.minValue
                ? typeof config.minValue === 'string'
                  ? dateTimeFromISOString(config.minValue)
                  : config.minValue
                : undefined
            }
            showTimeSelect={!config.hideTimeSelect}
          />
          <DatePickerInput
            showCancelBtn
            className={styles.configInput}
            labelProps={{ text: 'End Date' }}
            onCancelClick={() => updateConfig((draft) => (draft.maxValue = undefined))}
            onNewValueSelect={(newValue) =>
              updateConfig((draft) => (draft.maxValue = (newValue as DateTime) || undefined))
            }
            selectedValue={
              config.maxValue
                ? typeof config.maxValue === 'string'
                  ? dateTimeFromISOString(config.maxValue)
                  : config.maxValue
                : undefined
            }
            showTimeSelect={!config.hideTimeSelect}
          />
        </>
      ) : (
        <DropdownSelect
          fillWidth
          ignoreCustomStyles
          minimal
          showCancelBtn
          filterable={false}
          noSelectionText="Select Relative range"
          onCancelClick={() => updateConfig((draft) => (draft.relativeDateRange = undefined))}
          onChange={(newValue) =>
            updateConfig(
              (draft) => (draft.relativeDateRange = newValue.name as RELATIVE_DATE_RANGES),
            )
          }
          options={Object.values(RELATIVE_DATE_RANGES).map((date) => ({
            id: date,
            name: date,
          }))}
          selectedItem={
            config.relativeDateRange
              ? { name: config.relativeDateRange, id: config.relativeDateRange }
              : undefined
          }
        />
      )}
    </>
  );
};
