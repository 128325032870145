import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { sprinkles } from 'components/ds';

import SettingHeader from '../../DataConfigTab/SettingHeader';
import { Switch } from 'components/ds';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';

import { updateGeneralFormatOptions } from 'actions/dataPanelConfigActions';
import {
  CustomMenuConfig as CustomMenuConfigType,
  CustomMenuOptionType,
  VisualizeOperationGeneralFormatOptions,
} from 'constants/types';

type Props = {
  generalFormatOptions: VisualizeOperationGeneralFormatOptions;
};

export const CustomMenuConfig: FC<Props> = ({ generalFormatOptions }) => {
  const dispatch = useDispatch();

  const firstOption = generalFormatOptions.customMenu?.menuOptions?.[0] ?? {
    name: '',
    type: CustomMenuOptionType.CUSTOM_JS_EVENT,
    customJSEventName: '',
  };

  const updateCustomMenuConfig = (newConfig: CustomMenuConfigType) => {
    dispatch(
      updateGeneralFormatOptions({
        ...generalFormatOptions,
        customMenu: {
          ...generalFormatOptions.customMenu,
          ...newConfig,
        },
      }),
    );
  };

  return (
    <>
      <SettingHeader
        name="Custom Menu"
        rightContent={
          <Switch
            useCustomStyles
            onChange={() =>
              updateCustomMenuConfig({ enabled: !generalFormatOptions.customMenu?.enabled })
            }
            switchOn={generalFormatOptions?.customMenu?.enabled}
          />
        }
      />
      {/* We are only supporting a single custom menu option for now, so this is hardcoding to edit the first option */}
      {generalFormatOptions?.customMenu?.enabled ? (
        <div className={sprinkles({ padding: 'sp1.5' })}>
          <InputWithBlurSave
            initialValue={firstOption.name}
            label="Menu Option Name"
            onNewValueSubmitted={(newValue) =>
              updateCustomMenuConfig({ menuOptions: [{ ...firstOption, name: newValue }] })
            }
          />
          <InputWithBlurSave
            containerClassName={sprinkles({ marginTop: 'sp1' })}
            initialValue={firstOption.customJSEventName}
            label="Custom JS Event Name"
            onNewValueSubmitted={(newValue) =>
              updateCustomMenuConfig({
                menuOptions: [{ ...firstOption, customJSEventName: newValue }],
              })
            }
          />
        </div>
      ) : null}
    </>
  );
};
