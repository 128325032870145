import { FC } from 'react';
import cx from 'classnames';
import * as RadixRadioGroup from '@radix-ui/react-radio-group';

import * as styles from './index.css';
import { sprinkles } from '../sprinkles.css';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

export type Props = {
  /**
   * Optional class to wrap entire component
   */
  className?: string;
  /**
   * Value to be checked
   */
  defaultValue?: string;
  /**
   * Prevents interacting with radios when true
   */
  disabled?: boolean;
  /**
   * Name of group sent when submitted with <form>
   */
  name?: string;
  /**
   * Event handler called when value changes
   */
  onValueChange: (value: string) => void;
  /**
   * Optional class to wrap entire component
   */
  orientation?: 'horizontal' | 'vertical';
  /**
   * DEPRECATED - Support v2 custom styles
   */
  useCustomStyles?: boolean;
  /**
   * Controlled value of the item to check
   */
  value?: string;
  /**
   * Array of values to render radio items
   */
  values: string[];
  /**
   * Transform the value before displaying it to the suer
   */
  renderValue?: (value: string) => string;
};

const RadioGroup: FC<Props> = ({
  className,
  defaultValue,
  disabled,
  name,
  onValueChange,
  orientation = 'vertical',
  useCustomStyles,
  values,
  value,
  renderValue,
}) => (
  <RadixRadioGroup.Root
    className={cx(
      styles.root,
      sprinkles({ flexDirection: orientation === 'vertical' ? 'column' : 'row' }),
      { [GLOBAL_STYLE_CLASSNAMES.ds.radioGroup]: useCustomStyles },
      className,
    )}
    defaultValue={defaultValue}
    disabled={disabled}
    name={name}
    onValueChange={onValueChange}
    orientation={orientation}
    value={value}>
    {values.map((value, i) => (
      <div
        className={sprinkles({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        })}
        key={value}>
        <RadixRadioGroup.Item className={styles.item} id={`radio${i}`} value={value}>
          <RadixRadioGroup.Indicator className={styles.indicator} />
        </RadixRadioGroup.Item>
        <label className={styles.label} htmlFor={`radio${i}`}>
          {renderValue ? renderValue(value) : value}
        </label>
      </div>
    ))}
  </RadixRadioGroup.Root>
);

export { RadioGroup };
