import { makeStyles, Theme } from '@material-ui/core/styles';

export const sharedStyles = makeStyles((theme: Theme) => ({
  configInput: ({ isArchitectCustomerDashboard }: { isArchitectCustomerDashboard: boolean }) => ({
    marginBottom: theme.spacing(isArchitectCustomerDashboard ? 2 : 3),
  }),
  helperText: ({ isArchitectCustomerDashboard }: { isArchitectCustomerDashboard: boolean }) => ({
    marginBottom: theme.spacing(isArchitectCustomerDashboard ? 2 : 4),
    fontSize: isArchitectCustomerDashboard ? 12 : 14,
    lineHeight: `${isArchitectCustomerDashboard ? 15 : 17}px`,
  }),
}));
