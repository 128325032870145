import cx from 'classnames';
import { FC } from 'react';

import {
  EmbeddedDropdownMenu,
  DropdownOption,
} from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownMenu';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { IconButton, Tag } from 'components/ds';
import { Props as TagProps } from 'components/ds/Tag';

import { getTimezoneAwareDate } from 'utils/timezoneUtils';

import * as styles from './BaseListItem.css';

interface Props {
  name: string;
  description?: string;
  isStarred: boolean;
  onClickStar: () => void;
  modified: string; // Datetime when it was modified
  onClick: () => void;
  tags?: TagProps[];
  menuOptions: DropdownOption[];
  menuDisabled?: boolean;
  menuLoading?: boolean;
}

export const BaseListItem: FC<Props> = ({
  name,
  description,
  onClick,
  onClickStar,
  isStarred,
  modified,
  tags,
  menuOptions,
  menuDisabled,
  menuLoading,
}) => {
  return (
    <div className={styles.baseListItem} onClick={(e) => !e.defaultPrevented && onClick()}>
      <div className={styles.nameContainer}>
        <IconButton
          className={cx({ [styles.activeStarIcon]: isStarred })}
          name={isStarred ? 'star' : 'regular-star'}
          onClick={(e) => {
            e.preventDefault();
            onClickStar();
          }}
        />
        <EmbedText className={styles.text} color="contentPrimary" heading="h4">
          {name}
        </EmbedText>
      </div>
      <EmbedText body="b2" className={styles.description} color="contentTertiary">
        {description}
      </EmbedText>
      <div className={styles.actionContainer}>
        <EmbedText
          body="b3"
          className={styles.text}
          color="contentTertiary">{`Last edited ${getTimezoneAwareDate(
          modified,
        ).toRelative()}`}</EmbedText>
        {tags?.map((tagProps) => (
          <Tag key={tagProps.children} {...tagProps} />
        ))}
        <div onClick={(e) => e.preventDefault()}>
          <EmbeddedDropdownMenu
            disabled={menuDisabled || menuLoading || !menuOptions.length}
            menuOptions={menuOptions}>
            <IconButton
              className={styles.dropdownIcon}
              disabled={menuDisabled || !menuOptions.length}
              loading={menuLoading}
              name="ellipsis-vertical"
            />
          </EmbeddedDropdownMenu>
        </div>
      </div>
    </div>
  );
};
