import { cloneDeep } from 'utils/standard';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';

import ColorPickerButton from 'shared/ColorPicker';
import { Switch } from 'components/ds';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import InputLabel from 'shared/InputLabel';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { KPITrendDisplayFormat, OPERATION_TYPES, V2KPITrendInstructions } from 'constants/types';
import { ReduxState } from 'reducers/rootReducer';
import { getCategoricalColors } from 'globalStyles';
import { sharedStyles } from './styles';

const useStyles = makeStyles((theme: Theme) => ({
  colorPaletteDisplay: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  colorPicker: {
    width: '100%',
  },
}));

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2KPITrendInstructions;
};

export default function KPITrendTrendConfig({ visualizationType, instructions }: Props) {
  const classes = useStyles();
  const sharedClasses = sharedStyles();
  const dispatch = useDispatch();
  const globalStyleConfig = useSelector(
    (state: ReduxState) => state.dashboardStyles.globalStyleConfig,
  );

  const displayFormat = instructions.displayFormat;
  const defaultDecimalPlaces = displayFormat?.showAbsoluteChange ? 0 : 1;

  const updateDisplayFormat = (updates: KPITrendDisplayFormat): void => {
    const newInstructions = cloneDeep(instructions);
    newInstructions.displayFormat = {
      ...newInstructions.displayFormat,
      ...updates,
    };
    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  // Some options won't work with the Charts version of the KPI Trend panel so hide them when the trend lines are being shown.
  // TODO: Migrate the chart w/ trend lines enabled to use these options
  // https://linear.app/explo/issue/PD-934/migrate-kpi-trend-w-lines-enabled-to-use-trend-change-options
  return (
    <div className={sharedClasses.root}>
      <Switch
        className={sharedClasses.configInput}
        label="Hide Trend Lines"
        onChange={() => {
          const newInstructions = cloneDeep(instructions);
          dispatch(
            updateVisualizeOperation(
              { ...newInstructions, hideTrendLines: !newInstructions.hideTrendLines },
              visualizationType,
            ),
          );
        }}
        switchOn={instructions?.hideTrendLines}
      />
      {instructions?.hideTrendLines && (
        <>
          <InputWithBlurSave
            containerClassName={sharedClasses.configInput}
            initialValue={String(displayFormat?.trendDecimalPlaces ?? defaultDecimalPlaces)}
            label="Trend Decimal Places"
            onNewValueSubmitted={(newValue) => {
              const intValue = parseInt(newValue);
              updateDisplayFormat({ trendDecimalPlaces: intValue > 0 ? intValue : 0 });
            }}
          />
          <Switch
            className={sharedClasses.configInput}
            label="Show Absolute Trend Change"
            onChange={() =>
              updateDisplayFormat({ showAbsoluteChange: !displayFormat?.showAbsoluteChange })
            }
            switchOn={displayFormat?.showAbsoluteChange}
          />
          <Switch
            className={sharedClasses.configInput}
            label="Show Trend Change Period Label"
            onChange={() =>
              updateDisplayFormat({
                showTrendChangePeriodLabel: !displayFormat?.showTrendChangePeriodLabel,
              })
            }
            switchOn={displayFormat?.showTrendChangePeriodLabel}
          />
          <Switch
            className={sharedClasses.configInput}
            label="Use Trend Tag Format"
            onChange={() => updateDisplayFormat({ useTrendTag: !displayFormat?.useTrendTag })}
            switchOn={displayFormat?.useTrendTag}
          />
        </>
      )}
      {!instructions?.hideTrendLines && (
        <>
          <div className={sharedClasses.configInput}>
            <InputLabel text="Main Trend Color" />
            <ColorPickerButton
              className={classes.colorPicker}
              color={displayFormat?.periodColor || getCategoricalColors(globalStyleConfig)[0]}
              colorPalette={getCategoricalColors(globalStyleConfig)}
              onColorChange={(newColor) => updateDisplayFormat({ periodColor: newColor })}
            />
          </div>
          <div className={sharedClasses.configInput}>
            <InputLabel text="Comparison Trend Color" />
            <ColorPickerButton
              className={classes.colorPicker}
              color={displayFormat?.comparisonColor || '#757575'}
              colorPalette={getCategoricalColors(globalStyleConfig)}
              onColorChange={(newColor) => updateDisplayFormat({ comparisonColor: newColor })}
            />
          </div>
        </>
      )}
      <Switch
        className={sharedClasses.configInput}
        label="Reverse Trend Label Colors"
        onChange={() =>
          updateDisplayFormat({ trendColorsReversed: !displayFormat?.trendColorsReversed })
        }
        switchOn={displayFormat?.trendColorsReversed}
      />
    </div>
  );
}
