import { FC, useEffect, useState } from 'react';
import cx from 'classnames';

import { Tag, sprinkles, Icon, Switch } from 'components/ds';

const flexContainerClass = sprinkles({ display: 'flex', alignItems: 'center', height: 'fill' });
const marginRightClass = sprinkles({ marginRight: 'sp1' });
const sectionTextClass = sprinkles({ body: 'section' });

type Props = {
  children: JSX.Element | null;
  defaultIsOpen?: boolean;
  isArchitectCustomerDashboard?: boolean;
  title: string;

  toggleOn?: boolean;
  onToggle?: () => void;
  tagText?: string;
};

export const ConfigSection: FC<Props> = ({
  children,
  defaultIsOpen = false,
  isArchitectCustomerDashboard,
  title,
  toggleOn,
  onToggle,
  tagText,
}) => {
  const hasToggle = onToggle !== undefined;
  const hasToggleOff = hasToggle && !toggleOn;

  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  useEffect(() => {
    setIsOpen(defaultIsOpen || (hasToggle && !!toggleOn));
  }, [defaultIsOpen, hasToggle, toggleOn]);

  const renderToggle = (className?: string) => {
    if (!onToggle) return null;

    return <Switch className={className} onChange={() => onToggle()} switchOn={toggleOn} />;
  };

  const renderHeader = () => {
    const caretColorClass = sprinkles({ color: hasToggleOff ? 'gray8' : undefined });
    if (isArchitectCustomerDashboard) {
      return (
        <>
          <div className={flexContainerClass}>
            <div>{renderToggle(marginRightClass)}</div>
            <div className={sectionTextClass}>{title}</div>
          </div>
          <Icon
            className={caretColorClass}
            name={isOpen ? 'caret-down' : 'caret-right'}
            size="sm"
          />
        </>
      );
    }
    return (
      <>
        <div className={flexContainerClass}>
          <Icon
            className={cx(marginRightClass, caretColorClass)}
            name={isOpen ? 'caret-down' : 'caret-right'}
            size="sm"
          />
          <div className={sectionTextClass}>{title}</div>
        </div>
        <div>{renderToggle()}</div>
        {!isOpen && tagText ? <Tag>{tagText}</Tag> : null}
      </>
    );
  };

  return (
    <div>
      <div
        className={cx(
          sprinkles({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: hasToggleOff ? undefined : 'pointer',
          }),
          sprinkles(
            isArchitectCustomerDashboard
              ? { paddingX: 'sp1', backgroundColor: 'gray2' }
              : { padding: 'sp1.5' },
          ),
        )}
        onClick={() => {
          if (hasToggleOff) return;
          setIsOpen(!isOpen);
        }}
        style={{ height: isArchitectCustomerDashboard ? 28 : undefined }}>
        {renderHeader()}
      </div>
      {isOpen && (onToggle === undefined || toggleOn) ? children : null}
    </div>
  );
};
