import { defineAPIPostAction } from './actionUtils';
import { ACTION } from './types';

export type SignupQuestionResponse = { question: string; response: string; isValid: boolean };
export type HubspotField = {
  name: string;
  value: string | boolean;
};

type AddUserSignupCollectionInfoData = {
  user_id: number;
  user_question_answer_list: SignupQuestionResponse[];
};

export const { actionFn: addUserSignupCollectionInfo } = defineAPIPostAction<
  AddUserSignupCollectionInfoData,
  {}
>(ACTION.ADD_USER_SIGNUP_COLLECTION_INFO, 'signup_info', 'record_user_question_answer', 'POST');
