import produce from 'immer';
import { useDispatch } from 'react-redux';

import SharedKPIGeneralConfigs from 'components/ChartConfigs/KPIGeneralConfigs';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { KPIGeneralFormat, OPERATION_TYPES, V2KPIChartInstructions } from 'constants/types';
import { sharedStyles } from './styles';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2KPIChartInstructions;
};

export default function KPIGeneralConfig({ visualizationType, instructions }: Props) {
  const classes = sharedStyles();
  const dispatch = useDispatch();

  const updateFormat = (generalFormat: KPIGeneralFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.generalFormat = { ...draft.generalFormat, ...generalFormat };
    });
    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  return (
    <div className={classes.root}>
      <SharedKPIGeneralConfigs
        configInputClass={classes.configInput}
        instructions={instructions}
        updateGeneralFormat={updateFormat}
      />
    </div>
  );
}
