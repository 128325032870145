import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep, sortBy } from 'utils/standard';
import { Layout } from '@explo-tech/react-grid-layout';
import * as RD from 'remotedata';

import {
  CanvasDataset,
  CanvasVersionConfig,
  CanvasFilter,
  CanvasTemplate,
} from 'actions/canvasConfigActions';
import * as canvasActions from 'actions/canvasActions';
import * as configActions from 'actions/canvasConfigActions';
import * as versionActions from 'actions/canvasVersionActions';
import * as datasetActions from 'actions/datasetActions';
import * as dptActions from 'actions/dataPanelTemplateAction';
import { VersionInfo } from 'types/exploResource';
import { removeUnderscoreFields } from 'utils/dashboardUtils';
import { createCanvasFilter, getNextElementNumber } from 'utils/canvasConfigUtils';
import { OPERATION_TYPES } from 'constants/types';
import {
  defaultDataPanelDimensions,
  DRAGGING_ITEM_CONFIG_BY_TYPE,
  EMPTY_FILTER_OP_STATE,
  EMPTY_VISUALIZATION_INSTRUCTIONS,
} from 'constants/dashboardConstants';
import {
  handleSecondaryDataError,
  handleSecondaryDataRequest,
  handleSecondaryDataSuccess,
} from './utils';
import { ArchitectCustomerDashboard } from 'actions/architectCustomerDashboardActions';
import { DataPanel } from 'types/exploResource';
import { updateCanvasTemplateInstructions } from 'actions/updateInstructionsActions';
import { updateInstructions } from 'utils/dataPanelInstructionUtils';
import { titleCase } from 'utils/graphUtils';
import { DEFAULT_GROUPING_TYPES } from 'constants/dataConstants';
import { DASHBOARD_ELEMENT_TYPES } from 'types/dashboardTypes';
import { getLayoutHeightInRows } from 'utils/layoutResolverUtil';
import { CanvasVersion } from 'actions/canvasVersionActions';
import { saveResourceConfig } from 'utils/customEventUtils';
import { saveArchitectDraft } from './thunks/resourceSaveThunks';

interface CanvasEditReducerState {
  config: RD.ResponseData<CanvasVersionConfig>;
  versionInfo: VersionInfo | null;
  architectCustomerDashboards: RD.ResponseData<ArchitectCustomerDashboard[]>;
  viewingArchitectCustomerDashboard: ArchitectCustomerDashboard | null;
}

const initialState: CanvasEditReducerState = {
  config: RD.Idle(),
  versionInfo: null,
  architectCustomerDashboards: RD.Idle(),
  viewingArchitectCustomerDashboard: null,
};

function updateConfig(
  state: CanvasEditReducerState,
  updateFunc: (config: CanvasVersionConfig) => void,
  updateCanvas = true,
): void {
  if (!RD.isSuccess(state.config)) return;
  updateFunc(state.config.data);
  if (updateCanvas) saveResourceConfig();
}

function updateDataset(
  state: CanvasEditReducerState,
  datasetId: string,
  updateFunc: (dataset: CanvasDataset) => void,
): void {
  updateConfig(
    state,
    (config) => {
      const dataset = config.datasets[datasetId];
      if (dataset) updateFunc(dataset);
    },
    false,
  );
}

function updateDatasetAndSave(
  state: CanvasEditReducerState,
  datasetId: string,
  updateFunc: (dataset: CanvasDataset) => void,
): void {
  updateDataset(state, datasetId, updateFunc);
  saveResourceConfig();
}

function updateFilter(
  state: CanvasEditReducerState,
  filterId: string,
  updateFunc: (filter: CanvasFilter) => void,
): void {
  updateConfig(state, (config) => {
    const filter = config.filters[filterId];
    if (filter) updateFunc(filter);
  });
}

function updateTemplate(
  state: CanvasEditReducerState,
  templateId: string,
  updateFunc: (template: CanvasTemplate) => void,
): void {
  updateConfig(state, (config) => {
    const template = config.templates[templateId];
    if (template) updateFunc(template);
  });
}

function updateCustomComponent(
  state: CanvasEditReducerState,
  compId: string,
  updateFunc: (element: configActions.CanvasCustomComponent) => void,
): void {
  updateConfig(state, (config) => {
    const element = config.customComponents?.[compId];
    if (element) updateFunc(element);
  });
}

function updateDataPanelData(
  state: CanvasEditReducerState,
  dpId: string,
  updateFunc: (dataPanel: CanvasTemplate | DataPanel) => void,
) {
  if (state.viewingArchitectCustomerDashboard !== null) {
    const dataPanel = state.viewingArchitectCustomerDashboard.configuration.data_panels[dpId];
    if (dataPanel) updateFunc(dataPanel);
  } else {
    updateConfig(
      state,
      (config) => {
        const template = config.templates[dpId];
        if (template) updateFunc(template);
      },
      false,
    );
  }
}

function receiveNewVersion(state: CanvasEditReducerState, version: CanvasVersion) {
  if (version.version_number === state.versionInfo?.version_number) return;
  state.config = RD.Success(version.configuration);
  state.versionInfo = {
    is_draft: version.is_draft,
    version_number: version.version_number,
    edit_version_number: version.edit_version_number,
    change_comments: version.change_comments,
  };
}

const canvasEditSlice = createSlice({
  name: 'canvasEdit',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Version Actions
      .addCase(versionActions.fetchCanvasVersionsRequest, (state) => {
        state.config = RD.Loading();
      })
      .addCase(versionActions.fetchCanvasVersionsError, (state) => {
        state.config = RD.Error('Error Loading Canvas');
      })
      .addCase(versionActions.fetchCanvasVersionsSuccess, (state, { payload }) => {
        const sortedVersions = [...payload.versions].sort(
          (a, b) => b.version_number - a.version_number,
        );

        const version = sortedVersions[0];
        receiveNewVersion(state, version);
      })
      .addCase(versionActions.createNewCanvasVersionSuccess, (state, { payload }) => {
        receiveNewVersion(state, payload.canvas_version);
      })
      .addCase(versionActions.switchCurrentlyEditingCanvasVersion, (state, { payload }) => {
        receiveNewVersion(state, payload.canvasVersion);
      })
      .addCase(versionActions.revertToCanvasVersionSuccess, (state, { payload }) => {
        receiveNewVersion(state, payload.canvas_version);
      })
      .addCase(canvasActions.clearCanvasEdit, () => {
        return initialState;
      })
      .addCase(saveArchitectDraft.fulfilled, (state, { payload }) => {
        if ('canvas_version' in payload) {
          receiveNewVersion(state, payload.canvas_version);
        } else if (state.versionInfo) {
          state.versionInfo.edit_version_number = payload.edit_version_number;
        }
      })
      // End User Dashboards Actions
      .addCase(canvasActions.fetchAllDashboardsForCanvasRequest, (state) => {
        state.architectCustomerDashboards = RD.Loading();
      })
      .addCase(canvasActions.fetchAllDashboardsForCanvasError, (state) => {
        state.architectCustomerDashboards = RD.Error('Error Loading Dashboards');
      })
      .addCase(canvasActions.fetchAllDashboardsForCanvasSuccess, (state, { payload }) => {
        state.architectCustomerDashboards = RD.Success(payload.dashboards);
      })
      .addCase(canvasActions.selectArchitectCustomerDashboardToView, (state, { payload }) => {
        if (state.viewingArchitectCustomerDashboard?.id === payload) return;
        if (payload !== null && RD.isSuccess(state.architectCustomerDashboards)) {
          const architectCustomerDashboard = state.architectCustomerDashboards.data.find(
            (dash) => dash.id === payload,
          );
          if (architectCustomerDashboard)
            state.viewingArchitectCustomerDashboard = cloneDeep(architectCustomerDashboard);
        } else {
          state.viewingArchitectCustomerDashboard = null;
        }
      })
      .addCase(canvasActions.clearViewingArchitectCustomerDashboard, (state) => {
        state.viewingArchitectCustomerDashboard = null;
        if (RD.isSuccess(state.config)) {
          Object.values(state.config.data.datasets).map(removeUnderscoreFields);
          Object.values(state.config.data.templates).map(removeUnderscoreFields);
        }
      })
      // Config Actions
      .addCase(configActions.createCanvasDataset, (state, { payload }) => {
        updateConfig(state, (config) => {
          const newDataset: CanvasDataset = {
            description: '',
            id: payload.newId,
            name: payload.name,
            parent_schema_id: payload.parentSchemaId,
            query: '',
            columnOptions: {},
            requiredFilters: [],
            isHiddenFromUsers: false,

            _is_new: true,
          };
          config.datasets[newDataset.id] = newDataset;
        });
      })
      .addCase(configActions.updateCanvasDataset, (state, { payload }) => {
        updateDatasetAndSave(state, payload.datasetId, (dataset) => {
          if (payload.name !== undefined) dataset.name = payload.name;
          if (payload.description !== undefined) dataset.description = payload.description;
          if (payload.columnOptions !== undefined) dataset.columnOptions = payload.columnOptions;
        });
      })
      .addCase(configActions.toggleCanvasDatasetVisibility, (state, { payload }) => {
        updateDatasetAndSave(state, payload, (dataset) => {
          dataset.isHiddenFromUsers = !dataset.isHiddenFromUsers;
        });
      })
      .addCase(configActions.updateRequiredFilters, (state, { payload }) => {
        updateConfig(state, (config) => {
          const dataset = config.datasets[payload.datasetId];
          const filterIdx = dataset.requiredFilters.findIndex(
            (filterId) => filterId === payload.filterId,
          );
          if (filterIdx < 0) {
            dataset.requiredFilters.push(payload.filterId);
          } else {
            dataset.requiredFilters.splice(filterIdx, 1);
          }
        });
      })
      .addCase(configActions.createCanvasFilter, (state, { payload }) => {
        updateConfig(state, (config) => {
          const nextNum = getNextElementNumber('filter_', config.filters);
          const newFilter: CanvasFilter = {
            id: payload.newId,
            provided_id: `filter_${nextNum}`,
            name: `Filter ${nextNum}`,
            description: '',
            filter_info: null,
          };
          config.filters[newFilter.id] = newFilter;
        });
      })
      .addCase(configActions.deleteCanvasFilter, (state, { payload }) => {
        updateConfig(state, (config) => {
          delete config.filters[payload.filterId];
        });
      })
      .addCase(configActions.updateCanvasFilter, (state, { payload }) => {
        updateFilter(state, payload.filterId, (filter) => {
          if (payload.provided_id !== undefined) filter.provided_id = payload.provided_id;
          if (payload.name !== undefined) filter.name = payload.name;
          if (payload.description !== undefined) filter.description = payload.description;
        });
      })
      .addCase(configActions.setCanvasFilterType, (state, { payload }) => {
        updateFilter(state, payload.filterId, (filter) => {
          filter.filter_info = createCanvasFilter(payload.filterType);
        });
      })
      .addCase(configActions.updateCanvasFilterConfig, (state, { payload }) => {
        updateFilter(state, payload.filterId, (filter) => {
          filter.filter_info = payload.filterInfo;
        });
      })
      .addCase(configActions.createCanvasComponent, (state, { payload }) => {
        updateConfig(state, (config) => {
          if (!config.customComponents) config.customComponents = {};

          const newElement: configActions.CanvasCustomComponent = {
            id: payload,
            name: `Custom ${Object.keys(config.customComponents).length + 1}`,
            description: '',
            info: { elementType: DASHBOARD_ELEMENT_TYPES.IFRAME, config: {} },
          };

          config.customComponents[payload] = newElement;
        });
      })
      .addCase(configActions.updateCanvasComponent, (state, { payload }) => {
        updateCustomComponent(state, payload.id, (element) => {
          if (payload.name) element.name = payload.name;
          if (payload.description !== undefined) element.description = payload.description;
          if (payload.info) element.info = payload.info;
        });
      })
      .addCase(configActions.deleteCanvasComponent, (state, { payload }) => {
        updateConfig(state, (config) => {
          delete config.customComponents?.[payload];
        });
      })
      .addCase(configActions.createCanvasTemplate, (state, { payload }) => {
        updateConfig(state, (config) => {
          const defaultDataset = sortBy(
            Object.values(config.datasets).filter((dataset) => !dataset.isHiddenFromUsers),
            'name',
          )[0];
          if (!defaultDataset) return;

          const nextNum = getNextElementNumber('template_', config.templates);
          const defaultOpType = OPERATION_TYPES.VISUALIZE_TABLE;
          const name = `Template ${nextNum}`;
          const providedId = `template_${nextNum}`;
          const newTemplate: CanvasTemplate = {
            id: payload.newId,
            provided_id: providedId,
            dataset_id: defaultDataset.id,
            name,
            description: '',
            visualize_op: EMPTY_VISUALIZATION_INSTRUCTIONS(defaultOpType, name, providedId),
          };
          config.templates[newTemplate.id] = newTemplate;
        });
      })
      .addCase(configActions.updateCanvasTemplate, (state, { payload }) => {
        updateTemplate(state, payload.templateId, (template) => {
          if (payload.providedId !== undefined) template.provided_id = payload.providedId;
          if (payload.name !== undefined) {
            if (!template.visualize_op.generalFormatOptions) {
              template.visualize_op.generalFormatOptions = {};
            }
            template.visualize_op.generalFormatOptions.headerConfig = {
              ...template.visualize_op.generalFormatOptions.headerConfig,
              title: payload.name,
            };
            template.name = payload.name;
          }
          if (payload.description !== undefined) template.description = payload.description;
          if (payload.filters) {
            if (!template.filter_op) template.filter_op = EMPTY_FILTER_OP_STATE();
            template.filter_op.instructions.filterClauses = payload.filters;
          }
          if (payload.datasetId !== undefined) {
            template.dataset_id = payload.datasetId;
            template.visualize_op = {
              ...EMPTY_VISUALIZATION_INSTRUCTIONS(
                template.visualize_op.operation_type,
                '',
                payload.providedId || '',
              ),
              generalFormatOptions: template.visualize_op.generalFormatOptions,
            };
          }
        });
      })
      .addCase(configActions.deleteCanvasTemplate, (state, { payload }) => {
        updateConfig(state, (config) => {
          delete config.templates[payload.templateId];
        });
      })
      .addCase(configActions.updateCanvasTemplateChartType, (state, { payload }) => {
        updateTemplate(state, payload.templateId, (template) => {
          if (payload.isSubType) {
            template.visualize_op.operation_type = payload.operationType;
          } else {
            const newVizOp = EMPTY_VISUALIZATION_INSTRUCTIONS(
              payload.operationType,
              '',
              template.provided_id,
            );
            template.visualize_op = {
              ...newVizOp,
              generalFormatOptions: template.visualize_op.generalFormatOptions,
            };
          }
        });
      })
      .addCase(configActions.updateCanvasTemplateVizOp, (state, { payload }) => {
        updateTemplate(state, payload.templateId, (template) => {
          template.visualize_op = payload.vizOp;
        });
      })
      .addCase(updateCanvasTemplateInstructions, (state, { payload }) => {
        updateTemplate(state, payload.templateId, (template) => {
          updateInstructions(template, payload.update);
        });
      })
      .addCase(configActions.removeElementFromCanvasLayout, (state, { payload }) => {
        updateConfig(state, (config) => {
          config.default_layout = config.default_layout.filter(
            (elem) => elem.i !== payload.elementId,
          );
        });
      })
      .addCase(configActions.addElementToCanvasLayout, (state, { payload }) => {
        updateConfig(state, (config) => {
          const y = getLayoutHeightInRows(config.default_layout);
          const { w, h } = payload.filterType
            ? DRAGGING_ITEM_CONFIG_BY_TYPE[payload.filterType]
            : defaultDataPanelDimensions;

          const newLayoutElem: Layout = { y, x: 0, w, h, i: payload.id };

          config.default_layout.push(newLayoutElem);
        });
      })
      .addCase(configActions.updateCanvasDefaultLayout, (state, { payload }) => {
        updateConfig(state, (config) => (config.default_layout = payload));
      })
      // Data Request Actions
      .addCase(datasetActions.fetchEditorDatasetPreviewRequest, (state, { payload }) => {
        updateDataset(state, payload.postData.dataset_id, (dataset) => {
          dataset._schema = undefined;
          dataset._rows = undefined;
          dataset._total_row_count = undefined;
          dataset._error = undefined;
          dataset._loading = true;
        });
      })
      .addCase(datasetActions.fetchEditorDatasetPreviewSuccess, (state, { payload }) => {
        updateDataset(state, payload.postData.dataset_id, (dataset) => {
          dataset._schema = payload.dataset_preview.schema;
          dataset._rows = payload.dataset_preview._rows;
          dataset._unsupported_operations = payload.dataset_preview._unsupported_operations;
          dataset._error = undefined;
          dataset._loading = false;
        });
      })
      .addCase(datasetActions.fetchEditorDatasetPreviewError, (state, { payload }) => {
        updateDataset(state, payload.postData.dataset_id, (dataset) => {
          dataset._loading = false;
          dataset._error = payload.error_msg ?? 'Internal Error';
        });
      })
      .addCase(datasetActions.fetchEditorDatasetRowCountSuccess, (state, { payload }) => {
        updateDataset(state, payload.postData.dataset_id, (dataset) => {
          dataset._total_row_count = payload._total_row_count;
        });
      })
      .addCase(datasetActions.fetchDashboardDatasetPreviewSuccess, (state, { payload }) => {
        updateDataset(state, payload.postData.dataset_id, (dataset) => {
          dataset._schema = payload.dataset_preview.schema;
          dataset._rows = payload.dataset_preview._rows;
          dataset._unsupported_operations = payload.dataset_preview._unsupported_operations;
          dataset._loading = false;
          dataset._error = undefined;
        });
      })
      .addCase(datasetActions.deleteDataset, (state, { payload }) => {
        updateConfig(state, (config) => {
          delete config.datasets[payload.datasetId];
        });
      })
      .addCase(datasetActions.editDatasetName, (state, { payload }) => {
        updateDatasetAndSave(state, payload.datasetId, (dataset) => {
          dataset.name = payload.name;
        });
      })
      .addCase(datasetActions.saveDraftDatasetQuery, (state, { payload }) => {
        updateDatasetAndSave(state, payload.dataset_id, (dataset) => {
          dataset.queryDraft =
            payload.queryDraft === dataset.query ? undefined : payload.queryDraft;
        });
      })
      .addCase(datasetActions.updateCanvasDatasetSchema, (state, { payload }) => {
        updateDatasetAndSave(state, payload.datasetId, (dataset) => {
          dataset.parent_schema_id = payload.newParentSchemaId;
        });
      })
      .addCase(datasetActions.saveDatasetQuery, (state, { payload }) => {
        updateDatasetAndSave(state, payload.dataset_id, (dataset) => {
          dataset.query = payload.query;
          dataset.queryDraft = undefined;
          dataset.schema = payload.schema;

          payload.schema.forEach((col) => {
            const colOption = dataset.columnOptions[col.name];
            if (!colOption) {
              dataset.columnOptions[col.name] = {
                isVisible: true,
                canBeGroupedBy: DEFAULT_GROUPING_TYPES.has(col.type),
                name: titleCase(col.name),
              };
            }
          });
        });
      })
      .addCase(dptActions.fetchDataPanelRequest, (state, { payload }) => {
        updateDataPanelData(state, payload.postData.id, (template) => {
          template._loading = true;
        });
      })
      .addCase(dptActions.fetchDataPanelSuccess, (state, { payload }) => {
        updateDataPanelData(state, payload.postData.id, (template) => {
          template._loading = false;
          template._rows = payload.data_panel_template._rows;
          template._schema = payload.data_panel_template._schema;
          template._error = payload.data_panel_template._error;
          template._source_type = payload.data_panel_template._source_type;
          template._unsupported_operations = payload.data_panel_template._unsupported_operations;
        });
      })
      .addCase(dptActions.fetchDataPanelError, (state, { payload }) => {
        updateDataPanelData(state, payload.postData.id, (template) => {
          template._loading = false;
          template._error = payload.error_msg ?? 'There was an error fetching the results';
        });
      })
      .addCase(dptActions.fetchDataPanelRowCountSuccess, (state, { payload }) => {
        updateDataPanelData(state, payload.postData.id, (template) => {
          template._total_row_count = payload._total_row_count;
        });
      })
      .addCase(dptActions.fetchSecondaryDataRequest, (state, { payload }) => {
        updateDataPanelData(state, payload.postData.id, (template) =>
          handleSecondaryDataRequest(template),
        );
      })
      .addCase(dptActions.fetchSecondaryDataSuccess, (state, { payload }) => {
        updateDataPanelData(state, payload.postData.id, (template) =>
          handleSecondaryDataSuccess(template, payload.data_panel_template),
        );
      })
      .addCase(dptActions.fetchSecondaryDataError, (state, { payload }) => {
        updateDataPanelData(state, payload.postData.id, (template) =>
          handleSecondaryDataError(template),
        );
      })
      .addCase(dptActions.updateAdHocOperationInstructions, (state, { payload }) => {
        updateDataPanelData(state, payload.dataPanelId, (template) => {
          template._adHocOperationInstructions = payload.adHocOperationInstructions;
        });
      });
  },
});

export const canvasEditReducer = canvasEditSlice.reducer;
