import { Component, KeyboardEvent } from 'react';
import { withStyles, WithStyles } from '@material-ui/styles';

import { IconName, Intent, TextArea } from '@blueprintjs/core';
import InputLabel from 'shared/InputLabel';

const styles = () => ({
  textArea: {
    width: '100%',
    font: 'inherit',
  },
});

type PassedProps = {
  ignoreCustomStyles?: boolean;
  initialValue?: string;
  onNewValueSubmitted: (newValue: string) => void;
  errorText?: string;
  placeholder?: string;
  containerClassName?: string;
  label?: string;
  helpText?: string;
  helpIcon?: IconName;
};

type Props = WithStyles<typeof styles> & PassedProps;

type State = {
  value?: string;
};

class TextAreaWithBlurSave extends Component<Props, State> {
  inputRef: HTMLTextAreaElement | null = null;

  state: State = {
    value: this.props.initialValue,
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.initialValue !== this.props.initialValue) {
      this.setState({ value: this.props.initialValue });
      this.inputRef?.blur();
    }
  }

  render = () => {
    const { value } = this.state;
    const {
      containerClassName,
      initialValue,
      errorText,
      placeholder,
      onNewValueSubmitted,
      classes,
      label,
      ignoreCustomStyles,
      helpIcon,
      helpText,
    } = this.props;

    return (
      <div className={containerClassName}>
        {label ? (
          <InputLabel
            helpIcon={helpIcon}
            helpText={helpText}
            ignoreCustomStyles={ignoreCustomStyles}
            text={label}
          />
        ) : null}
        <TextArea
          className={classes.textArea}
          inputRef={(ref) => (this.inputRef = ref)}
          intent={errorText ? Intent.DANGER : Intent.NONE}
          onBlur={() => {
            this.setState({ value: initialValue });
            onNewValueSubmitted(value || '');
          }}
          onChange={(e) => this.setState({ value: e.target.value })}
          onKeyPress={this.onKeyPress}
          placeholder={placeholder}
          value={value}
        />
      </div>
    );
  };

  onKeyPress = (event?: KeyboardEvent<HTMLTextAreaElement>) => {
    const { onNewValueSubmitted } = this.props;
    const { value } = this.state;
    const code = event?.keyCode || event?.which;
    if (code === 13 && value) {
      onNewValueSubmitted(value || '');
    }
  };
}

export default withStyles(styles)(TextAreaWithBlurSave);
