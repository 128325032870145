import { FC } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import InputGroup from 'explo-ds/forms/marketing/inputGroup';
import InputLabel from 'shared/InputLabel';
import { Intent, Tag, sprinkles } from 'components/ds';
import { IconName } from 'components/ds/Icon';

const useStyles = makeStyles(() => ({
  root: {
    '& .bp3-input-action': {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      marginRight: 5,
    },
  },
  inputClass: {
    '& input[type="number"]': {
      '-webkit-appearance': 'textfield',
      '-moz-appearance': 'textfield',
      appearance: 'textfield',
    },
    '& input::-webkit-outer-spin-button,input::-webkit-inner-spin-button ': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  tag: {
    padding: 6,
  },
}));

export type StatusInfoType = {
  /**
   * Intent of the status tag - controls color
   */
  statusIntent?: Intent;
  /**
   * Icon to optionally show on the tag
   */
  statusIcon?: IconName;
  /**
   * If provided, an informational tooltip will show up next to the label
   */
  statusText?: string;
};

export type Props = {
  /**
   * optional class to wrap entire component
   */
  className?: string;
  /**
   * optional label shown above the input in the top left
   */
  label?: string;
  /**
   * Flag to disable input from user input
   */
  disabled?: boolean;
  /**
   * Text to show when no input has been made
   */
  placeholder?: string;
  /**
   * Type of the input
   */
  type?: string;
  /**
   * Text to show when no input has been made. Required since this is a controlled component
   */
  value: string;
  /**
   * Function that runs on change events
   */
  onChange: (value: string) => void;
  /**
   * If provided, an informational tooltip will show up next to the label
   */
  helpText?: string | JSX.Element;
  /**
   * Information on the status tag to show
   */
  statusInfo?: StatusInfoType;
  hideLabel?: boolean;
};

const InputWithTag: FC<Props> = ({
  className,
  label,
  disabled,
  placeholder,
  type,
  value,
  onChange,
  statusInfo,
  helpText,
  hideLabel,
  ...props
}) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.root, className)}>
      {!hideLabel ? <InputLabel helpText={helpText} text={label || ''} /> : null}
      <InputGroup
        {...props}
        className={classes.inputClass}
        disabled={disabled}
        onInputChange={onChange}
        placeholder={placeholder}
        rightElement={
          statusInfo && (
            <Tag
              className={cx(sprinkles({ marginLeft: 'sp1' }), {
                [classes.tag]: !statusInfo.statusText, // Overwrite padding for new tag styles
              })}
              intent={statusInfo.statusIntent}
              leftIcon={statusInfo.statusIcon}>
              {statusInfo.statusText}
            </Tag>
          )
        }
        type={type}
        value={value}
      />
    </div>
  );
};

export default InputWithTag;
