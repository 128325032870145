import { sprinkles } from 'components/ds';
import { FC } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { Icon } from '@blueprintjs/core';

import { DataPanel, ResourceDataset } from 'types/exploResource';
import { DashboardLinks } from 'utils/filterLinking';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { getDataPanelDatasetId } from 'utils/exploResourceUtils';
import { getDatasetName } from 'utils/naming';
import { setLinkedElementHover } from 'actions/cssLinkActions';
import { ReduxState } from 'reducers/rootReducer';
import { toggleFilterLink } from 'actions/dashboardV2Actions';

type Props = {
  dashboardLinks: DashboardLinks;
  dataPanel: DataPanel;
  datasets: Record<string, ResourceDataset>;
  elementId: string;
};

export const DataPanelLinkOverlay: FC<Props> = ({
  dataPanel,
  dashboardLinks,
  datasets,
  elementId,
}) => {
  const dispatch = useDispatch();

  const datasetId = getDataPanelDatasetId(dataPanel);

  const datasetLinks = dashboardLinks[datasetId];
  const dataset = datasets[datasetId];

  const isLinked = datasetLinks?.has(dataPanel.id) ?? false;

  const hoveredDPId = useSelector((state: ReduxState) => state.cssLinks.hoverElementId);

  let backgroundColor = 'rgba(238, 238, 238, 0.6)';
  if (datasetLinks === undefined) backgroundColor = 'rgba(158, 158, 158, 0.6)';
  else if (isLinked) backgroundColor = 'rgba(0, 105, 237, 0.2)';

  return (
    <div
      className={cx(
        sprinkles({
          parentContainer: 'fillAbsolute',
          flexItems: 'center',
          padding: 'sp1',
          border: hoveredDPId === dataPanel.id ? 1 : undefined,
          borderColor: hoveredDPId === dataPanel.id ? 'blue11' : undefined,
        }),
        GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.default.borderRadius,
      )}
      onMouseEnter={() => dispatch(setLinkedElementHover(dataPanel.id))}
      onMouseLeave={() => dispatch(setLinkedElementHover(undefined))}
      style={{ backgroundColor }}>
      <div
        className={sprinkles({
          flexItems: 'center',
          paddingX: 'sp2',
          backgroundColor: isLinked ? 'blue11' : 'gray11',
          color: 'white',
          borderRadius: 8,
          overflow: 'hidden',
          cursor: datasetLinks ? 'pointer' : undefined,
        })}
        onClick={(e) => {
          if (datasetLinks !== undefined) {
            dispatch(toggleFilterLink({ elementId, dataPanelId: dataPanel.id }));
          }
          e.stopPropagation();
        }}
        style={{ height: 48 }}>
        <Icon icon={isLinked ? 'tick' : 'cross'} size={16} />
        <div className={sprinkles({ marginLeft: 'sp2', heading: 'h4', truncateText: 'ellipsis' })}>
          {isLinked ? (
            'Linked to filter'
          ) : datasetLinks === undefined ? (
            <>
              Select a field for{' '}
              <span className={sprinkles({ fontStyle: 'italic' })}>{getDatasetName(dataset)}</span>
            </>
          ) : (
            'Not Linked'
          )}
        </div>
      </div>
    </div>
  );
};
