import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Icon as BPIcon } from '@blueprintjs/core';
import { Icon, Button, sprinkles } from 'components/ds';
import { IconName } from 'components/ds/Icon';
import validator from 'validator';
import cx from 'classnames';
import { isLoading } from 'remotedata';

import InputGroup from 'explo-ds/forms/marketing/inputGroup';
import { DownloadStatus } from 'components/ExportOptions/DownloadStatus';
import { WebShareOption } from 'components/ExportOptions/WebShareOption';
import { ExportOptionItem } from 'components/ExportOptions/ExportOptionItem';
import { RadioGroup } from 'components/ds/RadioGroup';

import * as styles from './exportStyles.css';

import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import {
  DashboardVariableMap,
  ExportElemConfig,
  OpenDrilldownModalType,
} from 'types/dashboardTypes';
import { DownloadInfo, ExportType } from 'reducers/dashboardLayoutReducer';
import { clearDownloads } from 'actions/dashboardLayoutActions';
import { SpreadsheetType } from 'reducers/dashboardLayoutReducer';
import { WindowState } from 'utils/exportUtils';

enum SHARE_METHODS {
  WEB = 'Share to Web',
  DOWNLOAD = 'Download',
  EMAIL = 'Send to email',
}

type ShareMethodType = {
  icon: IconName;
  shareMethod: SHARE_METHODS;
  shouldRender: boolean;
};

type ExportInfo = {
  currentView: SHARE_METHODS | undefined;
  type: ExportType | undefined;
};

export type WebShareProps = {
  config: ExportElemConfig;
  exportVars: DashboardVariableMap;
  fetchShareData?: (password?: string, username?: string, isStrictViewingMode?: boolean) => void;
  isSharedView?: boolean;
};

export type DownloadFunc = (email?: string | undefined) => void;
export type DownloadSpreadsheetFunc = (
  fileFormat: SpreadsheetType,
  email?: string | undefined,
) => void;

type Props = {
  windowState: WindowState;
  downloadInfo: DownloadInfo | undefined;
  dataPanelId?: string;
  disableDataPanelPdfDownload?: boolean;
  disableDownloadExport?: boolean;
  enableEmailExport?: boolean;
  downloadDashboardImage?: (email?: string) => void;
  downloadDashboardPdf?: (email?: string) => void;
  shouldUseJobQueue?: boolean;
  supportEmail?: string;
  onDownloadPanelSpreadsheet?: DownloadSpreadsheetFunc;
  onDownloadPanelPdf?: DownloadFunc;
  webShareProps?: WebShareProps;
  openDrilldownModal?: OpenDrilldownModalType;
};

const ExportOptions: FC<Props> = ({
  dataPanelId,
  disableDataPanelPdfDownload,
  disableDownloadExport,
  enableEmailExport,
  downloadDashboardImage,
  downloadDashboardPdf,
  shouldUseJobQueue,
  supportEmail,
  onDownloadPanelSpreadsheet,
  onDownloadPanelPdf,
  downloadInfo,
  webShareProps,
  windowState,
  openDrilldownModal,
}: Props) => {
  const dispatch = useDispatch();

  const [inputEmail, setInputEmail] = useState('');
  const [exportInfo, setExportInfo] = useState<ExportInfo>({
    currentView:
      downloadInfo?.type === 'email'
        ? SHARE_METHODS.EMAIL
        : downloadInfo?.type === 'url'
        ? SHARE_METHODS.DOWNLOAD
        : undefined,
    type: downloadInfo?.exportType,
  });

  const isDownloadLoading = isLoading(downloadInfo?.status);
  const isShareComponent = webShareProps !== undefined;
  const canEmail = !!shouldUseJobQueue && (isShareComponent || !!enableEmailExport);
  const canDownload = isShareComponent
    ? !webShareProps?.config?.disablePdfDownload || !webShareProps?.config?.disableImageDownload
    : !disableDownloadExport;

  const shareMethods: ShareMethodType[] = [
    {
      icon: 'globe',
      shareMethod: SHARE_METHODS.WEB,
      shouldRender: isShareComponent && !webShareProps?.config?.disableShareLink,
    },
    {
      icon: 'arrow-down-to-bracket',
      shareMethod: SHARE_METHODS.DOWNLOAD,
      shouldRender: canDownload,
    },
    {
      icon: 'envelope',
      shareMethod: SHARE_METHODS.EMAIL,
      shouldRender: canEmail,
    },
  ];
  const availableShareMethods = shareMethods.filter((method) => method.shouldRender);

  const getExportFunc = (): DownloadFunc | undefined => {
    switch (exportInfo.type) {
      case 'pdf':
        return isShareComponent ? downloadDashboardPdf : onDownloadPanelPdf;
      case 'image':
        return downloadDashboardImage;
      case 'csv':
        return (email) => onDownloadPanelSpreadsheet?.('csv', email);
      case 'xlsx':
        return (email) => onDownloadPanelSpreadsheet?.('xlsx', email);
    }
  };

  const renderOptions = () => {
    const exportOptions: ExportType[] = [];
    if (isShareComponent) {
      if (!webShareProps.config?.disablePdfDownload) exportOptions.push('pdf');
      if (!webShareProps.config?.disableImageDownload) exportOptions.push('image');
    } else {
      if (!disableDataPanelPdfDownload) exportOptions.push('pdf');
      exportOptions.push('csv');
      exportOptions.push('xlsx');
    }
    const defaultType = exportOptions.length > 0 ? exportOptions[0] : undefined;

    if (exportInfo.type === undefined) {
      setExportInfo({
        currentView: exportInfo.currentView,
        type: defaultType,
      });
    }

    return (
      <RadioGroup
        defaultValue={defaultType}
        disabled={isDownloadLoading}
        onValueChange={(newType) => setExportInfo({ ...exportInfo, type: newType as ExportType })}
        renderValue={(value) => value.toLocaleUpperCase()}
        value={exportInfo?.type}
        values={exportOptions}
      />
    );
  };

  const downloadHeader = () => (
    <div className={styles.title}>
      {isDownloadLoading ? null : (
        <Icon
          className={styles.backButton}
          name="arrow-left"
          onClick={() => {
            dispatch(clearDownloads(dataPanelId));
            setExportInfo({ currentView: undefined, type: undefined });
          }}
        />
      )}
      {exportInfo.currentView}
    </div>
  );

  const renderShareBody = () => {
    if (exportInfo.currentView === SHARE_METHODS.WEB && isShareComponent) {
      return (
        <WebShareOption
          onBackButtonClick={() => setExportInfo({ currentView: undefined, type: undefined })}
          webShareProps={webShareProps}
        />
      );
    } else if (
      exportInfo.currentView === SHARE_METHODS.DOWNLOAD ||
      exportInfo.currentView === SHARE_METHODS.EMAIL
    ) {
      const shouldEmail = exportInfo.currentView === SHARE_METHODS.EMAIL;

      const downloadFunc = getExportFunc();
      return (
        <div className={styles.root}>
          {downloadHeader()}
          {renderOptions()}
          {shouldEmail && (
            <InputGroup
              fill
              className={sprinkles({ marginRight: 'sp.5' })}
              leftElement={<BPIcon icon="envelope" />}
              onInputChange={setInputEmail}
              placeholder="Enter an email..."
              value={inputEmail}
            />
          )}
          <Button
            fillWidth
            disabled={
              isDownloadLoading ||
              downloadFunc === undefined ||
              (shouldEmail && !validator.isEmail(inputEmail))
            }
            onClick={() => downloadFunc?.(shouldEmail ? inputEmail : undefined)}>
            {shouldEmail ? 'Send email' : 'Download'}
          </Button>
          <DownloadStatus
            downloadInfo={downloadInfo}
            supportEmail={supportEmail}
            windowState={windowState}
          />
        </div>
      );
    } else {
      return (
        <div className={styles.menuContent}>
          {openDrilldownModal != null && dataPanelId && (
            <ExportOptionItem
              icon="table"
              onClick={() => openDrilldownModal(dataPanelId)}
              option="Expose Underlying Data"
            />
          )}
          {availableShareMethods.map(({ icon, shareMethod }) => (
            <ExportOptionItem
              rightArrow
              icon={icon}
              key={shareMethod}
              onClick={() => setExportInfo({ currentView: shareMethod, type: undefined })}
              option={shareMethod}
            />
          ))}
        </div>
      );
    }
  };

  return (
    <div
      className={cx(
        GLOBAL_STYLE_CLASSNAMES.container.fill.backgroundColor,
        GLOBAL_STYLE_CLASSNAMES.container.outline.border,
        GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.default.borderRadius,
        GLOBAL_STYLE_CLASSNAMES.container.shadow.dropShadow,
      )}>
      {renderShareBody()}
    </div>
  );
};

export default ExportOptions;
