import { PureComponent } from 'react';
import Highcharts from 'highcharts';
import ReactDOMServer from 'react-dom/server';

import HighCharts from './highCharts';
import NeedsConfigurationPanel from 'pages/dashboardPage/DashboardDatasetView/needsConfigurationPanel';

import {
  getColorColNames,
  xAxisFormat,
  formatLegend,
  formatLabel,
  getColorPalette,
  formatValue,
  getLabelStyle,
  isTwoDimVizInstructionsReadyToDisplay,
  areRequiredVariablesSetTwoDimViz,
  shouldProcessColAsDate,
} from './utils';
import {
  ColorCategoryTracker,
  SpiderChartShape,
  V2TwoDimensionChartInstructions,
} from 'constants/types';
import { DATE_TYPES } from 'constants/dataConstants';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { DatasetSchema } from 'types/datasets';
import { getColDisplayText } from 'pages/dashboardPage/DataPanelConfigV2/DataConfigTab/vizConfigs/utils';
import { GlobalStyleConfig } from 'globalStyles/types';
import {
  createYAxisBaseTooltip,
  getSingleYAxisInstructions,
  getValueFormat,
} from './utils/multiYAxisUtils';
import { formatTwoDimensionalData } from 'dataFormatters/twoDimensionalDataFormatter';
import { getTimezoneAwareUnix } from 'utils/timezoneUtils';
import { ResourceDataset } from 'types/exploResource';
import { isSelectedColorDateType, getColorColumn } from 'utils/colorColUtils';

declare global {
  interface PointOptionsObject {
    custom: Record<string, boolean | number | string>;
  }
}

type Props = {
  backgroundColor: string;
  colorCategoryTracker: ColorCategoryTracker;
  loading?: boolean;
  previewData: Record<string, string | number>[];
  instructions?: V2TwoDimensionChartInstructions;
  dataPanelTemplateId: string;
  variables: DashboardVariableMap;
  schema: DatasetSchema;
  selectedColorColName?: string;
  normalize?: boolean;
  globalStyleConfig: GlobalStyleConfig;
  datasets: Record<string, ResourceDataset>;
};

type State = {};

class SpiderChart extends PureComponent<Props, State> {
  getChartId = () => {
    return `pivotChartContainer${this.props.dataPanelTemplateId}`;
  };

  render() {
    const { instructions, loading, variables } = this.props;
    const requiredVarNotsSet = !areRequiredVariablesSetTwoDimViz(variables, instructions);
    const instructionsReadyToDisplay = isTwoDimVizInstructionsReadyToDisplay(instructions);

    if (loading || !instructionsReadyToDisplay || requiredVarNotsSet) {
      return (
        <NeedsConfigurationPanel
          fullHeight
          instructionsNeedConfiguration={!instructionsReadyToDisplay}
          loading={loading}
          requiredVarsNotSet={requiredVarNotsSet}
        />
      );
    }

    return <HighCharts chartOptions={this._spec()} />;
  }

  _spec = (): Highcharts.Options | undefined => {
    const {
      previewData,
      schema,
      instructions,
      normalize,
      backgroundColor,
      globalStyleConfig,
      variables,
      datasets,
    } = this.props;
    if (schema?.length === 0 || !previewData) return;

    // this is a short term fix en lieu of this bug being fixed by vega:
    // Ref: TU/447fn2df
    this.processDatesData();
    const { valueFormatId, decimalPlaces } = getValueFormat(instructions?.yAxisFormats?.[0]);
    const showMarkers = !instructions?.chartSpecificFormat?.lineChart?.hideMarkers;
    const lineWidth =
      instructions?.chartSpecificFormat?.spiderChart?.lineWidth ||
      globalStyleConfig.container.lineWidth.default;
    const data = this.filterToMaxCategories(this.transformData());

    return {
      chart: {
        type: 'line',
        polar: true,
        backgroundColor,
      },
      //@ts-ignore
      series: data,
      title: {
        text: undefined,
      },
      colors: getColorPalette(globalStyleConfig, instructions?.colorFormat),
      plotOptions: {
        series: {
          animation: false,
          states: {
            hover: {
              borderColor: '#000000',
            },
          },
          dataLabels: {
            enabled: !instructions?.xAxisFormat?.hideTotalValues,

            formatter: function () {
              return formatValue({
                value: this.y || 0,
                decimalPlaces,
                formatId: valueFormatId,
                hasCommas: true,
              });
            },

            style: {
              textOutline: 'none',
              ...getLabelStyle(globalStyleConfig, 'primary'),
            },
          },
          lineWidth: lineWidth,
          //@ts-ignore
          borderRadius: instructions?.xAxisFormat?.barCornerRadius,
        },
        spline: {
          marker: {
            enabled: showMarkers,
          },
        },
        line: {
          marker: {
            enabled: showMarkers,
          },
        },
      },
      yAxis: {
        ...getSingleYAxisInstructions(globalStyleConfig, instructions, variables, datasets),
        gridLineInterpolation:
          instructions?.chartSpecificFormat?.spiderChart?.spiderChartShape ===
          SpiderChartShape.POLYGON
            ? 'polygon'
            : 'circle',
      },
      xAxis: {
        ...xAxisFormat(globalStyleConfig, instructions?.xAxisFormat),
        type: this.getXAxisType(),
        categories: this.getAxisCategories(),
        labels: {
          formatter: function () {
            return formatLabel(
              this.value,
              instructions?.categoryColumn?.column.type,
              instructions?.categoryColumn?.bucket?.id,
              instructions?.categoryColumn?.bucketSize,
              instructions?.xAxisFormat?.dateFormat,
              instructions?.xAxisFormat?.stringFormat,
            );
          },
          style: getLabelStyle(globalStyleConfig, 'secondary'),
          enabled: !instructions?.xAxisFormat?.hideAxisLabels,
        },
        visible: !instructions?.xAxisFormat?.hideAxisLine,
        lineWidth: 0, // override the xAxisFormat for spider chart
      },
      legend: {
        ...formatLegend(globalStyleConfig, instructions?.legendFormat),
      },
      tooltip: {
        formatter: function () {
          return ReactDOMServer.renderToStaticMarkup(
            createYAxisBaseTooltip({
              tooltipFormatter: this,
              globalValueFormatId: valueFormatId,
              globalDecimalPlaces: decimalPlaces,
              globalStyleConfig: globalStyleConfig,
              instructions: instructions,
              includePercent: normalize || instructions?.tooltipFormat?.showPct,
            }),
          );
        },
        padding: 0,
        borderWidth: 0,
        borderRadius: 0,
        backgroundColor: '#FFFFFF00',
        shadow: false,
        useHTML: true,
        outside: true,
        followPointer: true,
      },
    };
  };

  getXAxisColName = () => {
    const { schema } = this.props;

    return schema[0].name;
  };

  getXAxisType = () => {
    const { instructions } = this.props;
    if (DATE_TYPES.has(instructions?.categoryColumn?.column.type || '')) return 'datetime';
  };

  getAxisCategories = () => {
    const { instructions, previewData } = this.props;
    if (DATE_TYPES.has(instructions?.categoryColumn?.column.type || '')) return;
    const xAxisColName = this.getXAxisColName();

    const categories = new Set(previewData.map((row) => String(row[xAxisColName])));
    return Array.from(categories);
  };

  processDatesData = () => {
    const { instructions, previewData, schema } = this.props;
    const categoryColIsDate = shouldProcessColAsDate(instructions?.categoryColumn);
    const colorColIsDate = isSelectedColorDateType(instructions || {});

    if (!previewData || (!categoryColIsDate && !colorColIsDate) || !schema || schema.length === 0)
      return;

    const xAxisColName = this.getXAxisColName();
    const colorColName = getColorColNames(schema).colorColName;

    if (!instructions?.categoryColumn?.column.type) return;

    previewData.forEach((row) => {
      if (categoryColIsDate) row[xAxisColName] = getTimezoneAwareUnix(row[xAxisColName] as string);
      if (colorColIsDate) row[colorColName] = getTimezoneAwareUnix(row[colorColName] as string);
    });
  };

  transformData = () => {
    // This is for when there are multiple bars/lines selected
    const { instructions, schema } = this.props;

    if (
      !instructions?.aggColumns ||
      instructions.aggColumns.length === 0 ||
      !schema ||
      schema.length === 0
    )
      return [];

    let seriesList;
    if (instructions.colorColumnOptions?.length) {
      seriesList = this.transformColorData(schema);
    } else {
      seriesList = this.transformAggColsData(schema);
    }

    return seriesList;
  };

  transformColorData = (schema: DatasetSchema) => {
    const { instructions, previewData, selectedColorColName, colorCategoryTracker } = this.props;
    const { xAxisColName, colorColName, aggColName } = getColorColNames(schema);
    const isDate = DATE_TYPES.has(instructions?.categoryColumn?.column.type || '');
    const categoryOrder = this.getAxisCategories();
    const series: Record<
      string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { name: string; rawColorData: number | string; type: string; data: any; color?: string }
    > = {};

    const selectedColorCol = getColorColumn(instructions, selectedColorColName);

    previewData.forEach((row) => {
      if (isDate && row[xAxisColName] === undefined) return;
      const colorCategory = formatLabel(
        row[colorColName],
        selectedColorCol?.column.type,
        selectedColorCol?.bucket?.id,
      );
      const entry = isDate
        ? [row[xAxisColName], row[aggColName] as number]
        : {
            name: String(row[xAxisColName]),
            y: row[aggColName] as number,
            x: categoryOrder?.indexOf(String(row[xAxisColName])),
          };
      if (series[colorCategory]) {
        series[colorCategory].data.push(entry);
      } else {
        series[colorCategory] = {
          type: 'line',
          name: colorCategory,
          rawColorData: row[colorColName],
          data: [entry],
          color: colorCategoryTracker[colorColName]?.[colorCategory],
        };
      }
    });

    const seriesData = Object.values(series);

    if (isDate && instructions?.chartSpecificFormat?.timeSeriesDataFormat?.hideLatestPeriodData) {
      seriesData.forEach((series) => series.data.pop());
      return seriesData;
    }

    return seriesData;
  };

  transformAggColsData = (schema: DatasetSchema) => {
    const { previewData, instructions } = this.props;
    const xAxisColName = schema[0].name;
    const aggCols = instructions?.aggColumns || [];
    const aggColNames = schema.map((col) => col.name).slice(1);
    const isDate = DATE_TYPES.has(instructions?.categoryColumn?.column.type || '');

    const series: Record<
      string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { name: string; type: string; data: any; color?: string; yAxis?: number }
    > = {};

    formatTwoDimensionalData(previewData, instructions).forEach((row) => {
      aggColNames.forEach((colName, index) => {
        if (isDate && row[xAxisColName] === undefined) return;
        const aggCol = aggCols[index];

        const entry = isDate
          ? {
              x: row[xAxisColName],
              y: row[colName] as number,
            }
          : {
              name: String(row[xAxisColName]),
              y: row[colName] as number,
            };

        if (series[colName]) {
          series[colName].data.push(entry);
        } else {
          series[colName] = {
            type: 'line',
            name: aggCol.column.friendly_name || getColDisplayText(aggCol) || colName,
            data: [entry],
          };
        }
      });
    });
    return Object.values(series);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterToMaxCategories = (data: any) => {
    const { instructions } = this.props;

    // PD-1183: max categories isn't going to be supported for charts broken down by color for now
    if (instructions?.colorColumnOptions?.length) return data;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data.forEach((series: any) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      series.data = series.data.filter((_: any, index: any) => {
        return instructions?.xAxisFormat?.maxCategories
          ? index < instructions?.xAxisFormat?.maxCategories
          : true;
      });
    });

    return data;
  };
}

export default SpiderChart;
