import { FC } from 'react';
import { DateTime } from 'luxon';

import * as styles from '../styles.css';
import DropdownSelect from 'shared/DropdownSelect';
import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';
import DatePickerInput from 'shared/DatePickerInput';

import { DASHBOARD_ELEMENT_TYPES, DateElemConfig, DATE_FILTER_TYPE } from 'types/dashboardTypes';
import {
  DEFAULT_DATE_RANGES,
  DEFAULT_DATE_RANGES_DISPLAY_OVERWRITES,
  DEFAULT_DATE_TYPES,
  RELATIVE_DATE_OPTIONS,
} from 'types/dateRangeTypes';
import { SelectedDropdownInputItem } from 'constants/types';
import { dateTimeFromISOString } from 'utils/dateUtils';
import { getUpdateConfigFunc } from '../utils';

type Props = {
  config: DateElemConfig;
  dateFilterType: DATE_FILTER_TYPE;

  updateDateConfig: (config: DateElemConfig) => void;
};

export const DateDefaultValuesConfig: FC<Props> = ({
  config,
  dateFilterType,
  updateDateConfig,
}) => {
  const updateConfig = getUpdateConfigFunc(config, updateDateConfig);

  if (dateFilterType === DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER) {
    const options: SelectedDropdownInputItem[] = [];
    Object.values(DEFAULT_DATE_RANGES).forEach((range) => {
      if (!config.hiddenDefaultRanges?.includes(range))
        options.push({ id: range, name: DEFAULT_DATE_RANGES_DISPLAY_OVERWRITES[range] ?? range });
    });

    return (
      <DropdownSelect
        fillWidth
        ignoreCustomStyles
        minimal
        showCancelBtn
        filterable={false}
        noSelectionText="Select a range"
        onCancelClick={() => updateConfig((draft) => (draft.defaultDateRange = undefined))}
        onChange={(newValue) => {
          updateConfig((draft) => (draft.defaultDateRange = newValue.id as DEFAULT_DATE_RANGES));
        }}
        options={options}
        selectedItem={
          config.defaultDateRange
            ? {
                name:
                  DEFAULT_DATE_RANGES_DISPLAY_OVERWRITES[config.defaultDateRange] ??
                  config.defaultDateRange,
                id: config.defaultDateRange,
              }
            : undefined
        }
      />
    );
  }
  return (
    <>
      <ToggleButtonGroup fillWidth className={styles.configInput}>
        <ToggleButton
          active={config.defaultType === DEFAULT_DATE_TYPES.EXACT}
          onClick={() => updateConfig((draft) => (draft.defaultType = DEFAULT_DATE_TYPES.EXACT))}
          text="Exact Date"
        />
        <ToggleButton
          active={config.defaultType !== DEFAULT_DATE_TYPES.EXACT}
          onClick={() => updateConfig((draft) => (draft.defaultType = DEFAULT_DATE_TYPES.RELATIVE))}
          text="Relative Date"
        />
      </ToggleButtonGroup>
      {config.defaultType === DEFAULT_DATE_TYPES.EXACT ? (
        <DatePickerInput
          showCancelBtn
          className={styles.configInput}
          onCancelClick={() => updateConfig((draft) => (draft.defaultValue = undefined))}
          onNewValueSelect={(newValue) =>
            updateConfig((draft) => (draft.defaultValue = (newValue as DateTime) || undefined))
          }
          placeholder="Select a date"
          selectedValue={
            config.defaultValue
              ? typeof config.defaultValue === 'string'
                ? dateTimeFromISOString(config.defaultValue)
                : config.defaultValue
              : undefined
          }
          showTimeSelect={!config.hideTimeSelect}
        />
      ) : (
        <DropdownSelect
          fillWidth
          ignoreCustomStyles
          minimal
          showCancelBtn
          filterable={false}
          noSelectionText="Select Relative Date"
          onCancelClick={() => updateConfig((draft) => (draft.relativeDefaultValue = undefined))}
          onChange={(newValue) =>
            updateConfig(
              (draft) => (draft.relativeDefaultValue = newValue.name as RELATIVE_DATE_OPTIONS),
            )
          }
          options={Object.values(RELATIVE_DATE_OPTIONS).map((date) => ({
            id: date,
            name: date,
          }))}
          selectedItem={
            config.relativeDefaultValue
              ? { name: config.relativeDefaultValue, id: config.relativeDefaultValue }
              : undefined
          }
        />
      )}
    </>
  );
};
