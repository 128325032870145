import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { HeaderElemOptionSimple } from './PageHeaderConfig/HeaderElemOptionSimple';
import SettingHeader from 'pages/dashboardPage/DataPanelConfigV2/DataConfigTab/SettingHeader';

import { sprinkles } from 'components/ds';
import { DashboardElement, DASHBOARD_ELEMENT_TYPES } from 'types/dashboardTypes';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { filterForContainerElements } from 'utils/dashboardUtils';
import { DASHBOARD_ELEMENT_TYPE_TO_NAME, ELEM_TYPE_TO_ICON } from 'constants/dashboardConstants';
import { OP_TYPE_TO_BP3_ICON, VIZ_TO_NAME } from 'constants/dataConstants';
import { updateElementContainerLocation } from 'actions/dashboardV2Actions';

type Props = {
  containerId: string;
  dataPanels: DataPanelTemplate[];
  elements: DashboardElement[];
};

export const ContainerElementConfigPanel: FC<Props> = ({ containerId, dataPanels, elements }) => {
  const dispatch = useDispatch();
  const dataPanelsPartitioned = filterForContainerElements(dataPanels, containerId);
  const elementsPartitioned = filterForContainerElements(
    elements.filter((elem) => elem.element_type !== DASHBOARD_ELEMENT_TYPES.CONTAINER),
    containerId,
    true,
  );

  const updateElementContainerLocationFn = useCallback(
    (elementId: string, addToContainer: boolean, isDataPanel: boolean) => {
      dispatch(
        updateElementContainerLocation({
          elementId,
          containerId: containerId,
          removeElem: !addToContainer,
          isDataPanel,
        }),
      );
    },
    [containerId, dispatch],
  );

  return (
    <div className={sprinkles({ marginX: 'spn1.5' })}>
      <SettingHeader name="Included in Container" />
      <div className={sprinkles({ padding: 'sp1.5', color: 'gray12' })}>
        {dataPanelsPartitioned.elemsInContainer.map((element) => (
          <HeaderElemOptionSimple
            actionIcon="minus"
            elemId={element.id}
            icon={OP_TYPE_TO_BP3_ICON[element.visualize_op.operation_type]}
            key={`filter-header-${element.id}`}
            name={element.provided_id}
            onActionClicked={() => updateElementContainerLocationFn(element.id, false, true)}
            subName={VIZ_TO_NAME[element.visualize_op.operation_type]}
          />
        ))}
        {elementsPartitioned.elemsInContainer.map((element) => (
          <HeaderElemOptionSimple
            actionIcon="minus"
            elemId={element.id}
            icon={ELEM_TYPE_TO_ICON[element.element_type]}
            key={`filter-header-${element.id}`}
            name={element.name}
            onActionClicked={() => updateElementContainerLocationFn(element.id, false, false)}
            subName={DASHBOARD_ELEMENT_TYPE_TO_NAME[element.element_type]}
          />
        ))}
      </div>
      <SettingHeader name="Excluded from Container" />
      <div className={sprinkles({ padding: 'sp1.5', color: 'gray12' })}>
        {dataPanelsPartitioned.elemsNotInContainer.map((element) => (
          <HeaderElemOptionSimple
            actionIcon="plus"
            elemId={element.id}
            icon={OP_TYPE_TO_BP3_ICON[element.visualize_op.operation_type]}
            key={`filter-header-${element.id}`}
            name={element.provided_id}
            onActionClicked={() => updateElementContainerLocationFn(element.id, true, true)}
            subName={VIZ_TO_NAME[element.visualize_op.operation_type]}
          />
        ))}
        {elementsPartitioned.elemsNotInContainer.map((element) => (
          <HeaderElemOptionSimple
            actionIcon="plus"
            elemId={element.id}
            icon={ELEM_TYPE_TO_ICON[element.element_type]}
            key={`filter-header-${element.id}`}
            name={element.name}
            onActionClicked={() => updateElementContainerLocationFn(element.id, true, false)}
            subName={DASHBOARD_ELEMENT_TYPE_TO_NAME[element.element_type]}
          />
        ))}
      </div>
    </div>
  );
};
