import { useEffect, useState } from 'react';
import { isIdle, isSuccess } from 'remotedata';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { fetchCustomers } from 'actions/customerActions';
import { fetchAllSchemaTables, fetchUsedParentSchemas } from 'actions/parentSchemaActions';
import { ACTION } from 'actions/types';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ReduxState } from 'reducers/rootReducer';

// This custom hook loads all the basic data needed for editing experience,
// and returns the loading state of the metadata
export function useLoadEditMetadata() {
  const dispatch = useDispatch();
  const { parentSchemas, schemaTablesMap, customers, schemaInfoLoading } = useSelector(
    (state: ReduxState) => ({
      parentSchemas: state.parentSchemas.usedParentSchemas,
      schemaTablesMap: state.parentSchemas.schemaTablesMap,
      customers: state.customers.groups,
      schemaInfoLoading: createLoadingSelector(
        [ACTION.FETCH_USED_PARENT_SCHEMAS, ACTION.FETCH_ALL_SCHEMA_TABLES_FOR_TEAM],
        true,
      )(state),
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (!parentSchemas) dispatch(fetchUsedParentSchemas());
  }, [dispatch, parentSchemas]);

  useEffect(() => {
    if (Object.keys(schemaTablesMap).length === 0) dispatch(fetchAllSchemaTables());
  }, [dispatch, schemaTablesMap]);

  useEffect(() => {
    if (isIdle(customers)) dispatch(fetchCustomers());
  }, [dispatch, customers]);

  return schemaInfoLoading || !isSuccess(customers);
}

/**
 * A simple React hook for differentiating single and double clicks on the same component.
 *
 * @param {number} [latency=300] The amount of time (in milliseconds) to wait before differentiating a single from a double click
 * @param {function} onSingleClick A callback function for single click events
 * @param {function} onDoubleClick A callback function for double click events
 */
export const useDoubleClick = ({
  latency = 300,
  onSingleClick,
  onDoubleClick,
}: {
  latency?: number;
  onSingleClick: () => void;
  onDoubleClick: () => void;
}) => {
  const [click, setClick] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (click === 1) onSingleClick();
      setClick(0);
    }, latency);

    if (click === 2) onDoubleClick();

    return () => {
      clearTimeout(timeout);
    };
  }, [click, latency, onSingleClick, onDoubleClick]);

  return { triggerClick: () => setClick((prev) => prev + 1) };
};
