import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as Dialog from '@radix-ui/react-dialog';
import * as styles from './styles.css';

import { closeReportModal } from 'reportBuilderContent/reducers/reportEditingReducer';

type Props = { closeOnClick?: boolean };

export const BaseModal: FC<Props> = ({ children, closeOnClick }) => {
  const dispatch = useDispatch();

  const [portalContainer, setPortalContainer] = useState<HTMLDivElement | null>(null);

  const closeModal = () => dispatch(closeReportModal());

  return (
    <>
      <Dialog.Root open>
        <Dialog.Portal container={portalContainer}>
          <Dialog.Overlay className={styles.dialogOverlay} />
          <Dialog.Content
            className={styles.dialogContent}
            onEscapeKeyDown={closeModal}
            onPointerDownOutside={() => closeOnClick && closeModal()}>
            {children}
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
      {/* Apply margin 0 because in some settings margin is applied to the child the modal is in */}
      <div ref={setPortalContainer} style={{ zIndex: 2, margin: 0 }} />
    </>
  );
};
