import { makeStyles, Theme } from '@material-ui/core/styles';
import { COMPUTER } from 'constants/iconConstants';
import ExploLogo from 'images/branding/text_logo.svg';

const LOGO_HEIGHT = 24;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.ds.white,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '32px 24px',
  },
  exploLogo: {
    width: '100%',
    height: LOGO_HEIGHT,
  },
  computer: {
    width: '100%',
    marginBottom: 16,
  },
  exploLogoImg: { height: LOGO_HEIGHT },
  infoContainer: {
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  message: {
    fontWeight: 500,
    fontSize: 20,
    color: theme.palette.ds.grey900,
  },
}));

export default function RedirectToDesktop() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>
        <div className={classes.exploLogo}>
          <img alt="explo logo" className={classes.exploLogoImg} src={ExploLogo} />
        </div>
        <div className={classes.infoContainer}>
          <div className={classes.computer}>{COMPUTER}</div>
          <div className={classes.message}>
            Please login into a desktop computer to continue building with Explo!
          </div>
        </div>
      </div>
    </div>
  );
}
