import { useDispatch } from 'react-redux';

import SharedYAxisConfigs from 'components/ChartConfigs/YAxisConfigs';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import {
  OPERATION_TYPES,
  V2BoxPlotInstructions,
  V2ScatterPlotInstructions,
  V2TwoDimensionChartInstructions,
  YAxisFormat,
} from 'constants/types';
import {
  getYAxisFormat,
  updateYAxisFormat,
} from 'pages/dashboardPage/charts/utils/multiYAxisUtils';
import { sharedStyles } from './styles';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2TwoDimensionChartInstructions | V2BoxPlotInstructions | V2ScatterPlotInstructions;
  yAxisFormatId?: string;
};

export default function YAxisConfig({ visualizationType, instructions, yAxisFormatId }: Props) {
  const classes = sharedStyles();
  const dispatch = useDispatch();

  const yAxisFormat = getYAxisFormat(yAxisFormatId, instructions, visualizationType);

  const updateAxisFormat = (yAxisUpdates: YAxisFormat) => {
    const newYAxisFormat = { ...yAxisFormat, ...yAxisUpdates };
    dispatch(
      updateVisualizeOperation(
        updateYAxisFormat(newYAxisFormat, instructions, visualizationType),
        visualizationType,
      ),
    );
  };

  return (
    <div className={classes.root}>
      <SharedYAxisConfigs
        configInputClass={classes.configInput}
        updateAxisFormat={updateAxisFormat}
        yAxisFormat={yAxisFormat}
      />
    </div>
  );
}
