import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { ReportSaveStatus } from 'pages/ReportBuilder/ReportSaveStatus';
import { ReportName } from 'pages/ReportBuilder/ReportName';
import { ViewTabs } from 'pages/ReportBuilder/ReportView/Tabs/ViewTabs';
import { Button } from 'components/ds';

import { CustomerReportConfig } from 'actions/customerReportActions';
import { BuiltInReportConfig } from 'actions/reportBuilderConfigActions';
import { updateBuiltInReportSettings } from 'reducers/reportBuilderEditReducer';
import { openDataModal } from 'reportBuilderContent/reducers/reportEditingReducer';

import * as styles from './BuiltInReportView.css';

type Props = {
  builtInConfig?: BuiltInReportConfig;
  currentConfig: CustomerReportConfig;
  isSaved: boolean;
};

export const BuiltInReportView: FC<Props> = ({ builtInConfig, currentConfig, isSaved }) => {
  const dispatch = useDispatch();

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={styles.header}>
          <div className={styles.headerText}>
            <ReportName
              onSubmit={(value) => {
                builtInConfig &&
                  dispatch(
                    updateBuiltInReportSettings({ builtInId: builtInConfig.id, name: value }),
                  );
              }}
              value={builtInConfig?.name || 'Untitled Built In'}
            />
            <ReportSaveStatus isSaved={isSaved} />
          </div>
          <div className={styles.headerButtons}>
            <Button icon="pencil" onClick={() => dispatch(openDataModal())} type="secondary">
              Edit Data
            </Button>
          </div>
        </div>
      </div>

      <ViewTabs
        dataInfo={currentConfig.dataInfo}
        savedViews={builtInConfig?.config.views || []}
        views={currentConfig.views || []}
      />
    </div>
  );
};
