import WorksmithSteve from 'images/marketing/testimonials/worksmith_steve.jpeg';
import ZuddlVedha from 'images/marketing/testimonials/zuddl_vedha.jpeg';
import MedmeNick from 'images/marketing/testimonials/medme_nick.jpeg';
import GoldcastPalash from 'images/marketing/testimonials/goldcast_palash.jpeg';
import TogetherNathan from 'images/marketing/testimonials/together_nathan.jpeg';

import { Intent } from 'components/ds';
import { StatusInfoType } from 'shared/InputWithTag/InputWithTag';

export const ONBOARDING_PAGE_TAB_ID = 'Onboarding';

export type Testimonial = {
  companyName: string;
  personName: string;
  personTitle: string;
  personImage: string;
  quote: string;
};

export enum TESTIMONIAL_COMPANIES {
  WORKSMITH = 'Worksmith',
  ZUDDL = 'Zuddl',
  MEDME_HEALTH = 'MedMe Health',
  TOGETHER = 'Together',
  GOLDCAST = 'Goldcast',
}

export const TESTIMONIALS: Record<TESTIMONIAL_COMPANIES, Testimonial> = {
  [TESTIMONIAL_COMPANIES.WORKSMITH]: {
    companyName: 'Worksmith',
    personName: 'Steve Hehl',
    personTitle: 'VP of Engineering',
    personImage: WorksmithSteve,
    quote:
      'Explo has allowed us to provide best-in-class reporting to our clients in a fraction of the time that it would have taken us otherwise. The tool is easy to use and the team is extremely helpful and responsive.',
  },
  [TESTIMONIAL_COMPANIES.ZUDDL]: {
    companyName: 'Zuddl',
    personName: 'Vedha Sayyaparaju',
    personTitle: 'CTO',
    personImage: ZuddlVedha,
    quote:
      'Using Explo has been awesome! We’re able to launch new dashboards to customers with ease and not a lot of engineering hours. The turnaround time is great. I’ve had feature requests implemented in minutes :)',
  },
  [TESTIMONIAL_COMPANIES.MEDME_HEALTH]: {
    companyName: 'MedMe Health',
    personName: 'Nick Hui',
    personTitle: 'CPO and Founder',
    personImage: MedmeNick,
    quote:
      'Explo has allowed us to funnel valuable dev resources into other business critical initiatives while giving us the peace-of-mind that our customer facing dashboards are taken care of. The Explo team has always been available to lend us a hand getting set up and has been indispensable in making sure their platform serves our nuanced needs.',
  },
  [TESTIMONIAL_COMPANIES.GOLDCAST]: {
    companyName: 'Goldcast',
    personName: 'Palash Soni',
    personTitle: 'CEO',
    personImage: GoldcastPalash,
    quote:
      'Explo enabled us to save months of engineering effort and quickly build a beautiful dashboard. Embedding was simple and Explo makes it easy to silo data between customers and create custom dashboards for specific customers.',
  },
  [TESTIMONIAL_COMPANIES.TOGETHER]: {
    companyName: 'Together',
    personName: 'Nathan Goldstein',
    personTitle: 'Head of Product',
    personImage: TogetherNathan,
    quote:
      'Explo solves embedded analytics in a smart way. We’ve tried other vendors and they are either too much work for engineering, or swing too far the opposite way and don’t easily allow any engineers to create specific queries that our customers want. The combination of the dashboard editor and SQL is perfect for developing quickly and to customer requirements',
  },
};

export enum INPUT_STATUS {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export const INPUT_STATUS_TO_STATUS_TAG_INFO: Record<INPUT_STATUS, StatusInfoType> = {
  [INPUT_STATUS.ERROR]: {
    statusIcon: 'cross',
    statusIntent: Intent.ERROR,
  },
  [INPUT_STATUS.SUCCESS]: {
    statusIcon: 'check',
    statusIntent: Intent.SUCCESS,
  },
};

export enum SIGNUP_INFO_NEXT {
  USER = 'USER',
  // unused
  TEAM = 'TEAM',
  DONE = 'DONE',
}

// user questions
export const QUESTION_ROLE = 'Role';
export const QUESTION_PHONE_NUMBER = 'Contact Number (Optional)';
export const QUESTION_SQL_FAMILIARITY = "What's your familiarity with SQL?";
// team questions, deprecated but being kept around for now
export const QUESTION_COMPANY_NAME = 'Company name';
export const QUESTION_COMPANY_SIZE = '# of employees';
export const QUESTION_COMPANY_INDUSTRY = 'Industry';
// data questions
export const QUESTION_COMPANY_DATA_STATE = 'How would you describe the state of your data?';
export const QUESTION_COMPANY_DASHBOARD_TIMELINE = 'When do you want to launch dashboards?';

export enum SQL_FAMILIARITIES {
  NONE = 'I’ve never written SQL before',
  SOME = 'I have some familiarity with SQL',
  VERY = 'I write SQL often',
}

export enum ROLES {
  PRODUCT = 'Product',
  ENGINEER = 'Engineer',
  DESIGN = 'Design',
  DATA_AND_ANALYTICS = 'Data and Analytics',
  IT = 'IT',
  SALES_MARKETING = 'Sales and Marketing',
  FOUNDER_MANAGEMENT = 'Founder or Management',
  OTHER = 'Other',
}

// unused
export enum COMPANY_SIZE {
  ONE_TO_TEN = '1-10',
  ELEVEN_TO_TWENTY_FIVE = '11-25',
  TWENTY_SIX_TO_ONE_HUNDRED = '26-100',
  ONE_HUNDRED_TO_FIVE_HUNDRED = '101-500',
  FIVE_HUNDRED_PLUS = '500+',
}

// unused
export enum COMPANY_INDUSTRY {
  B2B_SAAS = 'B2B SaaS',
  CONSUMER_TECH = 'Consumer Tech',
  HEALTHCARE = 'Healthcare',
  RETAIL = 'Retail',
  CONSULTING = 'Consulting',
  TRANSPORTATION_LOGISTICS = 'Transportation and Logistics',
  MANUFACTURING = 'Manufacturing',
  INDUSTRIALS = 'Industrials',
  REAL_ESTATE_CONSTRUCTION = 'Real Estate and Construction',
  FINANCE = 'Finance',
  GOVERNMENT = 'Government',
  OTHER = 'Other',
}

// unused
export enum COMPANY_DATA_STATE {
  SETTING_UP_DB = 'We’re setting up a database',
  DATA_NEEDS_WORK = 'We have a database, but our data needs some work',
  GREAT_CONDITION = 'Our database and data are in great condition',
  NOT_SURE = 'We’re not sure about the state of the data',
}

// unused
export enum COMPANY_DASHBOARD_LAUNCH_TIMELINE {
  THIS_WEEK = 'This week',
  THIS_MONTH = 'This month',
  THIS_QUARTER = 'This quarter',
  NEXT_QUARTER = 'Next quarter',
  UNKNOWN = 'Unknown',
}

export const STEPS = {
  CONNECT_DATABASE: 'connect-database',
  CREATE_EXAMPLE_CUSTOMER: 'create-example-customer',
  CREATE_DASHBOARD: 'create-dashboard',
  APPLY_STYLING: 'apply-styling',
  EMBED: 'embed',
  SET_UP_TEAM: 'set-up-team',
};

export const STEP_TITLES = {
  [STEPS.CONNECT_DATABASE]: 'Connect a database',
  [STEPS.CREATE_EXAMPLE_CUSTOMER]: 'Create a customer',
  [STEPS.CREATE_DASHBOARD]: 'Create a dashboard',
  [STEPS.APPLY_STYLING]: 'Apply styling',
  [STEPS.EMBED]: 'Embed',
  [STEPS.SET_UP_TEAM]: 'Set up your team',
};

export const SUB_STEPS = {
  INVITE_TEAMMATES: 'invite-teammates',
  ACTIVATE_PLAN: 'activate-plan',
  CREATE_SCHEMA: 'create-schema',
  ENTER_CREDENTIALS: 'enter-credentials',
  SYNC_TABLES: 'sync-tables',
  CREATE_CUSTOMER: 'create-customer',
  ASSIGN_ACCESS_GROUP: 'assign-access-group',
  CREATE_DATASET: 'create-dataset',
  CREATE_CHART: 'create-chart',
  CREATE_FILTER: 'create-filter',
  PUBLISH_VERSION: 'publish-version',
  STLYE_BACKGROUND: 'style-background',
  STYLE_CARDS: 'style-cards',
  STYLE_TEXT: 'style-text',
  SET_COLOR_PALETTE: 'set-color-palettes',
  WHITELIST_DOMAIN: 'whitelist-domain',
  EMBED_COMPONENT: 'embed-component',
  SET_UP_API_INTEGRATION: 'set-up-api-integration',
};

export const SUB_STEP_TITLES: Record<string, string> = {
  [SUB_STEPS.INVITE_TEAMMATES]: 'Invite teammates',
  [SUB_STEPS.ACTIVATE_PLAN]: 'Activate your plan',
  [SUB_STEPS.CREATE_SCHEMA]: 'Create a schema',
  [SUB_STEPS.ENTER_CREDENTIALS]: 'Enter credentials',
  [SUB_STEPS.SYNC_TABLES]: 'Sync tables',
  [SUB_STEPS.CREATE_CUSTOMER]: 'Manually create a customer',
  [SUB_STEPS.ASSIGN_ACCESS_GROUP]: 'Assign them to a data visibility group',
  [SUB_STEPS.CREATE_DATASET]: 'Create a dataset',
  [SUB_STEPS.CREATE_CHART]: 'Create a chart',
  [SUB_STEPS.CREATE_FILTER]: 'Create a filter',
  [SUB_STEPS.PUBLISH_VERSION]: 'Publish a version',
  [SUB_STEPS.STLYE_BACKGROUND]: 'Style background',
  [SUB_STEPS.STYLE_CARDS]: 'Style cards',
  [SUB_STEPS.STYLE_TEXT]: 'Style text',
  [SUB_STEPS.SET_COLOR_PALETTE]: 'Set color palettes',
  [SUB_STEPS.WHITELIST_DOMAIN]: 'Whitelist our domain',
  [SUB_STEPS.EMBED_COMPONENT]: 'Embed the web component',
  [SUB_STEPS.SET_UP_API_INTEGRATION]: 'Set up an API integration for user groups',
};
