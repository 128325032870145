import { Component, ContextType } from 'react';
import cx from 'classnames';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core/styles';
import '@explo-tech/react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import {
  DashboardVariableMap,
  TextDashboardElemConfig,
  TEXT_ELEM_SIZES,
} from 'types/dashboardTypes';
import { replaceTemplatesWithValues } from 'utils/dataPanelConfigUtils';

import Markdown from 'components/markdown';
import { TEXT_ELEM_SIZE_CONFIGS } from 'constants/dashboardConstants';
import { GlobalStylesContext, GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { ResourceDataset } from 'types/exploResource';

const styles = (theme: Theme) =>
  createStyles({
    emptyText: {
      fontStyle: 'italic',
      fontColor: theme.palette.ds.grey800,
    },
    textOverflow: {
      width: '100%',
      overflowWrap: 'break-word',
    },
    textAlignHorizontal: {
      display: 'flex',
      '&.LEFT_ALIGN': {
        justifyContent: 'flex-start',
      },
      '&.CENTER_ALIGN': {
        justifyContent: 'center',
      },
      '&.RIGHT_ALIGN': {
        justifyContent: 'flex-end',
      },
    },
    textAlignVertical: {
      height: '100%',
      '&.TOP': {
        alignItems: 'flex-start',
      },
      '&.CENTER': {
        alignItems: 'center',
      },
      '&.BOTTOM': {
        alignItems: 'flex-end',
        position: 'absolute',
        bottom: '-10px',
      },
    },
  });

type PassedProps = {
  config: TextDashboardElemConfig;
  variables: DashboardVariableMap;
  datasets: Record<string, ResourceDataset>;
};

type Props = PassedProps & WithStyles<typeof styles>;

const TEXT_SIZE_TO_CLASSNAME: Record<string, string> = {
  small: GLOBAL_STYLE_CLASSNAMES.text.body.primary,
  medium: GLOBAL_STYLE_CLASSNAMES.text.h2.base,
  large: GLOBAL_STYLE_CLASSNAMES.text.h1.base,
};

class DashboardTextElement extends Component<Props> {
  static contextType = GlobalStylesContext;
  context!: ContextType<typeof GlobalStylesContext>;

  render() {
    const { classes, config } = this.props;
    const textSizeConfig = config.textSize
      ? TEXT_ELEM_SIZE_CONFIGS[config.textSize] || TEXT_ELEM_SIZE_CONFIGS[TEXT_ELEM_SIZES.SMALL]
      : TEXT_ELEM_SIZE_CONFIGS[TEXT_ELEM_SIZES.SMALL];

    return (
      <div
        className={cx(
          classes.textAlignHorizontal,
          classes.textAlignVertical,
          config.alignmentHoriz,
          config.alignmentVertical,
          classes.textOverflow,
          TEXT_SIZE_TO_CLASSNAME[textSizeConfig.fontSize],
        )}>
        {this.renderText()}
      </div>
    );
  }

  renderText = () => {
    const { config, variables, datasets } = this.props;

    if (config.text === undefined || config.text === '') {
      return this.renderEmptyText();
    }
    return <Markdown markdownText={replaceTemplatesWithValues(config.text, variables, datasets)} />;
  };

  renderEmptyText = () => {
    const { classes, config } = this.props;

    return (
      <div className={classes.emptyText} style={{ fontSize: config.textSize || 14 }}>
        No text currently configured.
      </div>
    );
  };
}

export default withStyles(styles)(DashboardTextElement);
