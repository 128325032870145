import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core';
import cx from 'classnames';
import { sortBy } from 'utils/standard';

import { ArchitectCustomerDashboardModal } from './modal';
import InputGroup from 'explo-ds/forms/marketing/inputGroup';
import DropdownSelect from 'shared/DropdownSelect';
import Button from 'shared/Button';
import { sprinkles } from 'components/ds/sprinkles.css';

import { CanvasDataset, CanvasCustomComponent, CanvasTemplate } from 'actions/canvasConfigActions';
import { copyEUDTemplate } from 'actions/architectCustomerDashboardConfigActions';
import { getChartIcon, CustomComponentIcon } from 'pages/BlueprintPage/icons';
import { isDataPanelReadyToCompute } from 'utils/dataPanelConfigUtils';
import { isCanvasComponent } from 'utils/canvasConfigUtils';
import { AnalyticsEventTracker } from 'utils/analyticsUtils';
import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    width: 700,
  },
  templateFilterBar: {
    height: 50,
    marginBottom: theme.spacing(6),
    display: 'flex',
  },
  filter: {
    flex: 1,
    marginRight: 10,
    '&:last-child': {
      marginRight: 0,
    },
  },
  filterTitle: {
    fontSize: 12,
    lineHeight: '15px',
    color: theme.palette.ds.grey900,
  },
  templateList: {
    height: 300,
    overflowY: 'auto',
  },
  template: {
    minHeight: 64,
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.ds.grey300}`,
    borderRadius: 4,
    display: 'flex',
    marginBottom: 10,
    '&:hover': {
      backgroundColor: theme.palette.ds.lightBlue,
      border: `1px solid ${theme.palette.ds.blue}`,
    },
    '&:hover .template-copy-button': {
      display: 'block',
    },
  },
  templateIcon: {
    marginRight: theme.spacing(2),
  },
  templateInfo: {
    flex: 1,
  },
  templateTitle: {
    color: theme.palette.ds.black,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '20px',
    marginBottom: 2,
  },
  templateDataset: {
    color: theme.palette.ds.grey600,
    fontSize: 12,
    lineHeight: '15px',
  },
  templateDescription: {
    fontSize: 14,
    color: theme.palette.ds.grey800,
    lineHeight: '17px',
  },
  templateEmpty: {
    fontSize: 16,
    color: theme.palette.ds.grey800,
    lineHeight: '17px',
    textAlign: 'center',
  },
  copyButtonWrapper: {
    marginLeft: theme.spacing(3),
    width: 90,
    alignItems: 'center',
    display: 'flex',
  },
  copyTemplateButton: {
    display: 'none',
  },
}));

type Props = {
  closeModal: () => void;

  customComponents: Record<string, CanvasCustomComponent>;
  datasets: Record<string, CanvasDataset>;
  portalContainerId: string;
  templates: Record<string, CanvasTemplate>;
  analyticsEventTracker?: AnalyticsEventTracker;
};

export function TemplateModal({
  closeModal,
  datasets,
  templates,
  portalContainerId,
  customComponents,
  analyticsEventTracker,
}: Props): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [filterStr, setFilterStr] = useState('');
  const [selectedDatasetId, setSelectedDatasetId] = useState<string | null>(null);

  const filterElements = (): (CanvasCustomComponent | CanvasTemplate)[] => {
    const loweredFilterStr = filterStr.trim().toLocaleLowerCase();
    const filterByTitle = loweredFilterStr !== '';
    const filterByDataset = selectedDatasetId !== null;

    const filteredComponents = Object.values(customComponents).filter((comp) => {
      if (filterByTitle && !comp.name.toLowerCase().includes(loweredFilterStr)) return false;
      if (filterByDataset) return false;
      return !!comp.info.config.iframeUrl;
    });

    const filteredTemplates = Object.values(templates).filter((template) => {
      if (filterByTitle && !template.name.toLowerCase().includes(loweredFilterStr)) return false;
      if (filterByDataset && template.dataset_id !== selectedDatasetId) return false;
      if (!isDataPanelReadyToCompute(template, datasets)) return false;
      return true;
    });

    return sortBy([...filteredTemplates, ...filteredComponents], 'name');
  };

  const renderFilterBar = () => {
    const datasetOptions = Object.values(datasets).map((dataset) => ({
      id: dataset.id,
      name: dataset.name,
    }));
    const selectedDataset = selectedDatasetId
      ? datasetOptions.find((ds) => ds.id === selectedDatasetId)
      : undefined;
    return (
      <div className={classes.templateFilterBar}>
        <div className={classes.filter}>
          <div className={classes.filterTitle}>Search</div>
          <InputGroup
            onInputChange={setFilterStr}
            placeholder={'Search by Title'}
            value={filterStr}
          />
        </div>
        <div className={classes.filter}>
          <div className={classes.filterTitle}>Datasets</div>
          <div>
            <DropdownSelect
              fillWidth
              ignoreCustomStyles
              showCancelBtn
              filterable={false}
              noSelectionText={'All Datasets'}
              onCancelClick={() => setSelectedDatasetId(null)}
              onChange={(item) => setSelectedDatasetId(item.id)}
              options={datasetOptions}
              selectedItem={selectedDataset}
            />
          </div>
        </div>
        {/* TODO: Implement by chart type. Should charts be grouped? */}
      </div>
    );
  };

  const renderTemplates = () => {
    const filteredElements = filterElements();

    if (filterStr.length !== 0 && filteredElements.length === 0) {
      return (
        <div className={classes.templateEmpty}>
          There are no templates matching &ldquo;{filterStr}&rdquo;.
        </div>
      );
    }

    return filteredElements.map((elem) => {
      const isTemplate = !isCanvasComponent(elem);

      return (
        <div className={classes.template} key={elem.id}>
          <div className={classes.templateIcon}>
            {isTemplate ? getChartIcon(elem.visualize_op.operation_type) : CustomComponentIcon()}
          </div>
          <div className={classes.templateInfo}>
            <div className={classes.templateTitle}>{elem.name}</div>
            {isTemplate ? (
              <div className={classes.templateDataset}>{datasets[elem.dataset_id].name}</div>
            ) : null}
            {elem.description.trim() !== '' ? (
              <div
                className={cx(
                  classes.templateDescription,
                  sprinkles({ marginTop: isTemplate ? 'sp1' : 'sp0' }),
                )}>
                {elem.description}
              </div>
            ) : null}
          </div>
          <div className={classes.copyButtonWrapper}>
            <Button
              className={cx(classes.copyTemplateButton, 'template-copy-button')}
              icon="duplicate"
              onClick={() => {
                dispatch(copyEUDTemplate({ templateId: elem.id, isCustomComponent: !isTemplate }));
                analyticsEventTracker?.(REPORTED_ANALYTIC_ACTION_TYPES.TEMPLATE_COPIED, {
                  template_name: elem.name,
                });
                closeModal();
              }}
              text="Select"
              type="primary"
            />
          </div>
        </div>
      );
    });
  };

  return (
    <ArchitectCustomerDashboardModal
      className={classes.modal}
      onClose={() => closeModal()}
      portalContainerId={portalContainerId}
      title="Browse Templates">
      {renderFilterBar()}
      <div className={classes.templateList}>{renderTemplates()}</div>
    </ArchitectCustomerDashboardModal>
  );
}
