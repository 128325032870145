import { createReducer } from '@reduxjs/toolkit';

import {
  createAttributeValueSuccess,
  createDashboardAttributeSuccess,
  deleteAttributeValueSuccess,
  deleteDashboardAttributeSuccess,
  fetchDashboardAttributesSuccess,
} from 'actions/dashboardAttributesActions';
import { DashboardAttribute } from 'actions/teamActions';

interface DashboardAttributesReducerState {
  dashboard_attributes?: DashboardAttribute[];
}

const dashboardAttributesInitialState: DashboardAttributesReducerState = {};

function updateDashboardAttributes(
  state: DashboardAttributesReducerState,
  func: (arg: DashboardAttribute[]) => DashboardAttribute[],
): void {
  if (state.dashboard_attributes) {
    state.dashboard_attributes = func(state.dashboard_attributes);
  }
}

export default createReducer(dashboardAttributesInitialState, (builder) => {
  builder
    .addCase(fetchDashboardAttributesSuccess, (state, { payload }) => {
      state.dashboard_attributes = payload.dashboard_attributes;
    })
    .addCase(createDashboardAttributeSuccess, (state, { payload }) => {
      updateDashboardAttributes(state, (attributes) => {
        return [...attributes, payload.dashboard_attribute];
      });
    })
    .addCase(deleteDashboardAttributeSuccess, (state, { payload }) => {
      updateDashboardAttributes(state, (attributes) => {
        return attributes.filter((attr) => attr.id !== payload.id);
      });
    })
    .addCase(createAttributeValueSuccess, (state, { payload }) => {
      updateDashboardAttributes(state, (attributes) => {
        return attributes.map((attr) => {
          if (attr.id === payload.value.attribute_id) {
            attr.values.push({
              id: payload.value.id,
              color_hex: payload.value.color_hex,
              value: payload.value.value,
            });
          }
          return attr;
        });
      });
    })
    .addCase(deleteAttributeValueSuccess, (state, { payload }) => {
      updateDashboardAttributes(state, (attributes) => {
        return attributes.map((attr) => {
          if (attr.id === payload.postData.attrId) {
            attr.values = attr.values.filter(({ id }) => id !== payload.postData.id);
          }
          return attr;
        });
      });
    })
    .addDefaultCase((state) => state);
});
