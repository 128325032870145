import { useEffect, useState } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { makeStyles, Theme, useTheme } from '@material-ui/core';
import * as RD from 'remotedata';

import { Icon } from '@blueprintjs/core';
import { Spinner, AlertModal, sprinkles } from 'components/ds';

import { CanvasVersionConfig, toggleCanvasDatasetVisibility } from 'actions/canvasConfigActions';
import { deleteDataset } from 'actions/datasetActions';
import { getDashboardsUsingDataset } from 'actions/canvasActions';
import { DASHBOARD_ELEMENT_TYPES } from 'types/dashboardTypes';
import { getSelectFilterDatasetId } from 'utils/filterUtils';

const useStyles = makeStyles((theme: Theme) => ({
  warningHelpText: {
    fontSize: 14,
    color: theme.palette.ds.grey900,
    lineHeight: '17px',
    marginBottom: theme.spacing(6),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 20,
  },
  bottomMargin: {
    marginBottom: theme.spacing(5),
  },
  warningTitle: {
    fontSize: 16,
    lineHeight: '20px',
    marginLeft: theme.spacing(3),
    color: theme.palette.ds.black,
  },
  warningInfoText: {
    margin: `6px 0px 6px ${theme.spacing(8)}px`,
    fontSize: 12,
    lineHeight: '15px',
    color: theme.palette.ds.grey800,
  },
  warningList: {
    fontSize: 12,
    color: theme.palette.ds.grey800,
    margin: `6px 0px ${theme.spacing(5)}px 0px`,
    paddingLeft: theme.spacing(8),
    listStylePosition: 'inside',

    '& li': {
      lineHeight: '15px',
    },
  },
}));

type Props = {
  canvasId: number;
  config: CanvasVersionConfig;
  datasetId: string;
  isDeletion: boolean;

  closeModal: () => void;
};

export default function DatasetModal({
  canvasId,
  config,
  datasetId,
  isDeletion,
  closeModal,
}: Props): JSX.Element {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();

  const [dashboardCount, setDashboardCount] = useState<RD.ResponseData<number>>(RD.Idle());

  useEffect(() => {
    if (RD.isIdle(dashboardCount)) {
      setDashboardCount(RD.Loading());
      dispatch(
        getDashboardsUsingDataset(
          { postData: { dataset_id: datasetId, canvas_id: canvasId } },
          (data) => setDashboardCount(RD.Success(data.dashboard_count)),
          () => setDashboardCount(RD.Error('Error Loading Dashboard Usage')),
        ),
      );
    }
  }, [dispatch, dashboardCount, datasetId, canvasId]);

  const renderUnaffected = (str: string, includeMargin = true) => {
    return (
      <div className={cx(classes.header, includeMargin ? classes.bottomMargin : undefined)}>
        <Icon color={theme.palette.ds.grey600} icon="tick-circle" iconSize={20} />
        <div className={classes.warningTitle}>{str} will be unaffected</div>
      </div>
    );
  };

  const renderWarningHeader = (count: number, countType: string, preRelyMessage = '') => {
    const isOne = count === 1;
    const relyText = ` ${preRelyMessage}rel${isOne ? 'ies' : 'y'}`;
    return (
      <div className={classes.header}>
        <Icon color={theme.palette.ds.yellow} icon="error" iconSize={20} />
        <div className={classes.warningTitle}>
          {count}
          <b>{` ${countType}${isOne ? '' : 's'}`}</b>
          {relyText} on this dataset
        </div>
      </div>
    );
  };

  const renderWarningList = (names: string[]) => {
    return (
      <ul className={classes.warningList}>
        {names.map((name, idx) => (
          <li key={`${name}-${idx}`}>{name}</li>
        ))}{' '}
      </ul>
    );
  };

  const renderArchitectCustomerDashboardWarning = () => {
    return (
      <RD.RemoteComponent
        Error={() => (
          <div className={cx(classes.header, classes.bottomMargin)}>
            <Icon color={theme.palette.ds.red} icon="error" iconSize={20} />
            <div className={classes.warningTitle}>Error getting end user dashboard usage</div>
          </div>
        )}
        Loading={() => (
          <div className={cx(classes.header, classes.bottomMargin)}>
            <Spinner fillContainer size="lg" />
            <div className={classes.warningTitle}>Loading end user dashboard usage</div>
          </div>
        )}
        Success={(count) => {
          if (count === 0) return renderUnaffected('End user dashboards');

          return (
            <>
              {renderWarningHeader(count, 'end user dashboard')}
              <div className={classes.warningInfoText} style={{ marginBottom: theme.spacing(5) }}>
                Charts that use this dataset will break, and user will be forced to edit or remove
                them.
              </div>
            </>
          );
        }}
        data={dashboardCount}
      />
    );
  };

  const renderFilterWarning = () => {
    if (!isDeletion) return null;

    const filtersUsingDataset: string[] = [];
    Object.values(config.filters).forEach(({ filter_info, name }) => {
      switch (filter_info?.filter_type) {
        case DASHBOARD_ELEMENT_TYPES.DROPDOWN:
        case DASHBOARD_ELEMENT_TYPES.MULTISELECT:
        case DASHBOARD_ELEMENT_TYPES.TOGGLE: {
          const filterDatasetId = getSelectFilterDatasetId(filter_info.config);
          if (filterDatasetId === datasetId) filtersUsingDataset.push(name);
        }
      }
    });

    return filtersUsingDataset.length > 0 ? (
      <>
        {renderWarningHeader(filtersUsingDataset.length, 'filter')}
        <div className={classes.warningInfoText}>
          These filters rely exclusively on this dataset and will become useless.
        </div>
        {renderWarningList(filtersUsingDataset)}
      </>
    ) : (
      renderUnaffected('Filters')
    );
  };

  const renderBody = () => {
    const elementsInLayout = new Set(config.default_layout.map((elem) => elem.i));

    const templatesUsingDataset: string[] = [];
    const templatesUsingDatasetInLayout: string[] = [];
    Object.values(config.templates).forEach((template) => {
      if (template.dataset_id === datasetId) {
        templatesUsingDataset.push(template.name);
        if (elementsInLayout.has(template.id)) templatesUsingDatasetInLayout.push(template.name);
      }
    });

    return (
      <>
        {isDeletion ? (
          <div className={classes.warningHelpText}>
            Once you&rsquo;ve deleted it you won&rsquo;t be able to retrieve it.
          </div>
        ) : null}
        {renderArchitectCustomerDashboardWarning()}
        {templatesUsingDataset.length > 0 ? (
          <>
            {renderWarningHeader(templatesUsingDataset.length, 'template')}
            <div className={classes.warningInfoText}>
              You&rsquo;ll be prompted to update the templates, otherwise they&rsquo;ll be
              unavailable to users.
            </div>
            {renderWarningList(templatesUsingDataset)}
          </>
        ) : (
          renderUnaffected('Templates')
        )}
        {renderFilterWarning()}
        {templatesUsingDatasetInLayout.length > 0
          ? renderWarningHeader(templatesUsingDatasetInLayout.length, 'chart', 'in the layout ')
          : renderUnaffected('Layout', false)}
      </>
    );
  };

  const onAction = () => {
    if (isDeletion) {
      dispatch(deleteDataset({ datasetId }));
      return;
    }
    closeModal();
    dispatch(toggleCanvasDatasetVisibility(datasetId));
  };

  return (
    <AlertModal
      isOpen
      actionButtonProps={{
        disabled: !RD.isSuccess(dashboardCount),
        onClick: onAction,
        text: isDeletion ? 'Delete Dataset' : 'Hide Dataset',
      }}
      onClose={closeModal}
      title={`Are you sure you want to ${isDeletion ? 'delete' : 'hide'} this dataset?`}>
      <div className={sprinkles({ paddingX: 'sp3' })}>{renderBody()}</div>
    </AlertModal>
  );
}
