import { FC } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { Spinner, sprinkles, Icon } from 'components/ds';
import { InfoIcon } from 'components/InfoIcon';

import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

const useStyles = makeStyles(() => ({
  titleText: {
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

type Props = {
  panelName: string;
  drilldownDatasetName?: string;
  loading?: boolean;
  infoTooltipText?: string;
};

export const PanelName: FC<Props> = ({
  panelName,
  drilldownDatasetName,
  loading,
  infoTooltipText,
}) => {
  const classes = useStyles();

  const renderInfoIconAndLoadingSpinner = () => {
    return (
      <>
        {infoTooltipText !== undefined && <InfoIcon infoTooltipText={infoTooltipText} />}
        {loading && <Spinner className={sprinkles({ marginLeft: 'sp3' })} size="md" />}
      </>
    );
  };

  if (drilldownDatasetName) {
    return (
      <div className={sprinkles({ display: 'flex', alignItems: 'center' })}>
        {panelName ? (
          <>
            <div className={sprinkles({ body: 'b1' })}> {panelName}</div>
            <Icon
              className={sprinkles({ color: 'contentTertiary', margin: 'sp1' })}
              name="chevron-right"
              size="sm"
            />
          </>
        ) : undefined}
        <div className={sprinkles({ heading: 'h3' })}> {drilldownDatasetName}</div>
        {renderInfoIconAndLoadingSpinner()}
      </div>
    );
  }

  return (
    <div className={cx(classes.titleText, GLOBAL_STYLE_CLASSNAMES.text.h2.base)}>
      {panelName}
      {renderInfoIconAndLoadingSpinner()}
    </div>
  );
};
