import { FunctionComponent, HTMLAttributes } from 'react';
import { Icon } from '@blueprintjs/core';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';

import FlexBox, { VerticalAlignment } from 'components/core/FlexBox';

const useStyles = makeStyles((theme: Theme) => ({
  header: ({ isExpanded }: Pick<Props, 'isExpanded'>) => ({
    fontSize: 12,
    color: theme.palette.ds.grey800,
    cursor: 'pointer',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: isExpanded ? theme.palette.ds.grey300 : undefined,
  }),
  chevronIcon: {
    margin: theme.spacing(1),
  },
  menuBody: {
    padding: `${theme.spacing(4)}px ${theme.spacing(4)}px`,
    backgroundColor: theme.palette.ds.grey100,
    borderRadius: theme.shape.borderRadius,
  },
}));

type Props = HTMLAttributes<HTMLDivElement> & {
  title: string;
  setIsExpanded: (expanded: boolean) => void;
  isExpanded?: boolean;
};

const CollapsibleMenu: FunctionComponent<Props> = ({
  children,
  title,
  isExpanded,
  setIsExpanded,
  ...otherProps
}) => {
  const theme = useTheme();
  const classes = useStyles({ isExpanded });

  return (
    <div {...otherProps}>
      <FlexBox
        className={classes.header}
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
        verticalAlignment={VerticalAlignment.CENTER}>
        <Icon
          className={classes.chevronIcon}
          color={theme.palette.ds.grey800}
          icon={isExpanded ? 'chevron-down' : 'chevron-right'}
        />
        {title}
      </FlexBox>
      {isExpanded && <div className={classes.menuBody}>{children}</div>}
    </div>
  );
};

export default CollapsibleMenu;
