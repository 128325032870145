import { GlobalStyleConfig } from 'globalStyles/types';
import { createSuccessAction, defineAPIPostAction } from 'actions/actionUtils';
import { ACTION } from 'actions/types';
import { PLAN_TYPES, TRIAL_STATUS } from 'constants/paymentPlanConstants';
import { SIGNUP_INFO_NEXT } from 'constants/onboardingConstants';
import { FeatureFlags } from 'featureGates/featureGates';
import { Role } from './rolePermissionActions';

export const logInUserSuccess = createSuccessAction<FetchProfileData>(ACTION.LOGIN_USER);

export type UserPermissions = Record<string, string[]>;

export interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  has_team: boolean;
  team: UserTeam | null;
  logged_in: boolean;
  is_superuser: boolean;

  email_verified: boolean;
  is_sso_account: boolean;
  has_seen_welcome_modal: boolean;
  can_create_team: boolean;
  permissions: UserPermissions;
}

export interface FetchProfileData {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  has_team: boolean;
  team: UserTeam | null;
  signup_info_collection_step: SIGNUP_INFO_NEXT | null;
  email_verified: boolean;
  is_sso_account: boolean;
  is_superuser: boolean;
  can_create_team: boolean | undefined;
  profile_data: {
    has_seen_welcome_modal: boolean;
  } | null;
  roles: Role[];
}

interface UserTeam {
  id: number;
  team_name: string;
  invite_code: string;
  style_config_v2: GlobalStyleConfig | null;
  default_currency_code: string;
  default_locale_code: string;
  use_browser_locale: boolean;
  onboarding_steps: Record<number, OnboardingStepData>;
  payment_plan: PLAN_TYPES;
  trial_status: TRIAL_STATUS;
  trial_end_date: string | null;
  feature_flags: FeatureFlags;
  roles: Role[];
  send_emails_as: string;
}

export interface TeamMember {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  // for now we only technically support one role per user, but leaving this
  //  as a list so that this doesn't have to be an api change in the future
  role_names: string[];
}

export interface OnboardingStepData {
  slug: string;
  complete: boolean;
  sub_steps: { slug: string; complete: boolean }[];
  order: number;
}

// Requests

type UpdateUserNameBody = {
  first_name: string;
  last_name: string;
  id: number;
};

type UpdateUserNameData = {
  first_name: string;
  last_name: string;
};

export const { actionFn: updateUserName, successAction: updateUserNameSuccess } =
  defineAPIPostAction<UpdateUserNameBody, UpdateUserNameData>(
    ACTION.CHANGE_USER_PROFILE_NAME,
    'settings',
    'update_user_name',
    'POST',
  );

export const {
  actionFn: updateHasSeenWelcomeModal,
  successAction: updateHasSeenWelcomeModalSuccess,
} = defineAPIPostAction<{}, {}>(
  ACTION.CHANGE_HAS_SEEN_WELCOME_MODAL,
  'settings',
  'change_has_seen_welcome_modal',
  'POST',
);
