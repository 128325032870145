import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useSelector, shallowEqual } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';

import { Popover, Icon } from 'components/ds';
import ResourceConfigurationMenu from 'shared/ExploResource/ResourceConfigurationMenu';
import { SIDE_PANE_WIDTH, SIDE_PANE_HEADER_HEIGHT } from 'components/SidePane';

import { DashboardTemplate } from 'actions/dashboardTemplateActions';
import { DashboardAttribute, ExploreEmailCadence } from 'actions/teamActions';
import { Canvas } from 'actions/canvasActions';
import { canUserViewResourceConfigurationMenu } from 'utils/permissionUtils';
import { ResourcePageType } from 'types/exploResource';
import { getPermissionEntity } from 'utils/exploResourceUtils';
import { ReportBuilder } from 'actions/reportBuilderActions';
import { isCreateResourceDisabled } from 'utils/paymentPlanUtils';
import { APP_PORTAL_ID } from 'components/ds';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: SIDE_PANE_HEADER_HEIGHT,
    width: SIDE_PANE_WIDTH,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRight: `1px solid ${theme.palette.ds.grey400}`,
    padding: theme.spacing(3),
    backgroundColor: theme.palette.ds.grey100,
  },
  interactive: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.ds.hovered.grey100,
    },
    '&:active': {
      backgroundColor: theme.palette.ds.pressed.grey100,
    },
  },
  canvasInfo: {
    width: 350,
    borderRight: `1px solid ${theme.palette.ds.grey400}`,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    fontSize: 16,
    fontWeight: 600,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  dashboardActions: {
    boxShadow: 'none',
  },
}));

type Props = {
  className?: string;
  createResourceDisabled?: boolean;
  dashboardAttributes?: DashboardAttribute[];
  resource: DashboardTemplate | Canvas | ReportBuilder;
  emailCadence?: ExploreEmailCadence;
  pageType: ResourcePageType;
  showDeveloperSettings?: boolean;

  openConfigurabilityModal?: () => void;
};

export default function EditResourceBannerDropdown({
  className,
  resource,
  emailCadence,
  dashboardAttributes,
  pageType,
  showDeveloperSettings,
  openConfigurabilityModal,
}: Props) {
  const classes = useStyles();

  const { currentUser, resources } = useSelector(
    (state: ReduxState) => ({
      currentUser: state.currentUser,
      resources: state.dashboard.dashboardTemplateList,
    }),
    shallowEqual,
  );

  const createResourceDisabled = isCreateResourceDisabled(
    pageType === ResourcePageType.EXPLORE,
    resources?.length || 0,
    currentUser.team?.payment_plan,
  );

  const resourcePermissionEntity = getPermissionEntity(pageType);

  if (!canUserViewResourceConfigurationMenu(currentUser, resourcePermissionEntity))
    return (
      <div className={cx(classes.root, className)}>
        <div className={classes.title}>{resource.name}</div>
      </div>
    );

  return (
    <Popover
      align="end"
      portalContainerId={APP_PORTAL_ID}
      trigger={
        <div className={cx(classes.root, classes.interactive, className)}>
          <div className={classes.title}>{resource.name}</div>
          <Icon name="caret-down" />
        </div>
      }>
      <ResourceConfigurationMenu
        createResourceDisabled={createResourceDisabled}
        dashboardAttributes={dashboardAttributes}
        emailCadence={emailCadence}
        onDelete={() => (window.location.href = '/home')}
        openConfigurabilityModal={openConfigurabilityModal}
        pageType={pageType}
        resource={resource}
        resourcePermissionEntity={resourcePermissionEntity}
        showDeveloperSettings={!!showDeveloperSettings}
      />
    </Popover>
  );
}
