import { cloneDeep } from 'utils/standard';
import { useDispatch } from 'react-redux';

import { Switch } from 'components/ds';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';

import { updateGeneralFormatOptions } from 'actions/dataPanelConfigActions';
import {
  NoDataConfig as noDataConfig,
  VisualizeOperationGeneralFormatOptions,
} from 'constants/types';
import { sharedStyles } from './styles';

type Props = {
  generalFormatOptions: VisualizeOperationGeneralFormatOptions;
  isKpiChart: boolean;
};

export default function NoDataConfig({ generalFormatOptions, isKpiChart }: Props) {
  const classes = sharedStyles();
  const dispatch = useDispatch();

  const noDataState = generalFormatOptions.noDataState;

  const updateNoDataState = (noDataUpdates: noDataConfig) => {
    const newFormat = cloneDeep(generalFormatOptions);
    newFormat.noDataState = {
      ...newFormat?.noDataState,
      ...noDataUpdates,
    };

    dispatch(updateGeneralFormatOptions(newFormat));
  };

  return (
    <div className={classes.root}>
      {/* for KPIs don't show the hide icon switch */}
      {!isKpiChart && (
        <Switch
          useCustomStyles
          className={classes.configInput}
          label="Hide Chart Icon"
          onChange={() => updateNoDataState({ hideChartIcon: !noDataState?.hideChartIcon })}
          switchOn={noDataState?.hideChartIcon}
        />
      )}
      {isKpiChart && (
        <Switch
          useCustomStyles
          className={classes.configInput}
          label="Zero Is No Data"
          onChange={() => updateNoDataState({ isZeroNoData: !noDataState?.isZeroNoData })}
          switchOn={generalFormatOptions?.noDataState?.isZeroNoData}
        />
      )}
      <InputWithBlurSave
        containerClassName={classes.configInput}
        initialValue={generalFormatOptions.noDataState?.noDataText || 'No data'}
        label="No Data Text"
        onNewValueSubmitted={(newValue: string) => updateNoDataState({ noDataText: newValue })}
      />
      <InputWithBlurSave
        containerClassName={classes.configInput}
        initialValue={String(generalFormatOptions.noDataState?.noDataFontSize || 36)}
        label="Text Size"
        onNewValueSubmitted={(newValue: string) => {
          const intValue = parseInt(newValue);
          if (intValue === 0 || intValue) {
            updateNoDataState({ noDataFontSize: intValue });
          }
        }}
      />
    </div>
  );
}
