import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { embedFetchShareData } from 'actions/shareActions';
import EmbeddedChart from 'components/EmbeddedChart/EmbeddedChart';
import { GenericLoadingSpinner } from 'components/EmbeddedDashboard';

type MatchParams = {
  shareId: string;
  dataPanelId: string;
};

export default function SharedChartPage() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [customerToken, setCustomerToken] = useState<string>('');
  const [dashboardEmbedId, setDashboardEmbedId] = useState<string>('');
  const [versionNumber, setVersionNumber] = useState<number>();
  const dispatch = useDispatch();
  const {
    params: { shareId, dataPanelId },
  } = useRouteMatch<MatchParams>();

  useEffect(() => {
    dispatch(
      embedFetchShareData({ id: shareId, customerToken }, (shareData) => {
        setCustomerToken(shareData.customer_token);
        setDashboardEmbedId(shareData.dashboard_embed_id);
        setVersionNumber(shareData.version_number);
        setIsLoading(false);
      }),
    );
  }, [dispatch, shareId, customerToken]);

  if (isLoading) {
    return <GenericLoadingSpinner embedType="shared" />;
  }

  return (
    <EmbeddedChart
      customerToken={customerToken}
      dashboardEmbedId={dashboardEmbedId}
      dataPanelId={dataPanelId}
      versionNumber={versionNumber}
    />
  );
}
