import { defineAPIAction, defineAPIPostAction } from './actionUtils';
import { ACTION } from './types';

export type AdminTeam = {
  id: string;
  team_name: string;
};

export type AdminAction = {
  id: string;
  params: { id: string; type: string; options: string[] }[];
  requires_team: boolean;
};

export type AdminUser = {
  first_name: string;
  last_name: string;
  email: string;
};

type GetSuperuserActionData = {
  actions: AdminAction[];
};

export const { actionFn: getSuperuserActions, successAction: getSuperuserActionSuccess } =
  defineAPIAction<GetSuperuserActionData>(
    ACTION.GET_SUPERUSER_ACTIONS,
    'superuser',
    'actions',
    'GET',
  );

type GetSuperuserTeamData = {
  teams: AdminTeam[];
};

export const { actionFn: getSuperuserTeams, successAction: getSuperuserTeamsSuccess } =
  defineAPIAction<GetSuperuserTeamData>(ACTION.GET_SUPERUSER_TEAMS, 'superuser', 'teams', 'GET');

type PostSuperuserActionBody = {
  team_id: string;
  action_id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: any;
};

export const { actionFn: postSuperuserAction } = defineAPIPostAction<
  PostSuperuserActionBody,
  PostSuperuserActionBody
>(ACTION.POST_SUPERUSER_ACTION, 'superuser', 'action', 'POST');

type GetSuperuserUsersData = {
  team_id: string;
};

type GetSuperuserUsersReponse = {
  team_id: string;
  users: AdminUser[];
};

export const { actionFn: getTeamUsers, successAction: getTeamUsersSuccess } = defineAPIPostAction<
  GetSuperuserUsersData,
  GetSuperuserUsersReponse
>(ACTION.GET_SUPERUSER_USERS, 'superuser', 'users', 'POST');
