import { makeStyles, Theme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import produce from 'immer';

import SettingHeader from '../SettingHeader';
import KPIAggregation from '../KPIAggregation';
import { TrendTableAggregations } from './TrendTableAggregations';
import DropdownSection from './droppable/DropdownSection';
import ValueInput from 'pages/dashboardPage/DashboardDatasetView/Header/ValueInput';
import DropdownSelect from 'shared/DropdownSelect';
import InputWithTag from 'shared/InputWithTag';
import DroppableColumnSection from './droppable/DroppableColumnSection';

import {
  FilterValueDateType,
  FilterValueType,
  KPIPeriodColumnInfo,
  OPERATION_TYPES,
  V2KPITrendInstructions,
  V2TrendTableInstructions,
} from 'constants/types';
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { DatasetSchema } from 'types/datasets';
import { defaultPeriodType } from './utils';
import { DATETIME, TIMESTAMP, DATE } from 'constants/dataConstants';
import {
  DashboardElement,
  DASHBOARD_ELEMENT_TYPES,
  DateRangePickerElemConfig,
  TimePeriodDropdownElemConfig,
} from 'types/dashboardTypes';
import {
  PeriodRangeTypes,
  PeriodComparisonRangeTypes,
  PERIOD_COMPARISON_RANGE_TYPES,
  TrendGroupingOptions,
  TREND_GROUPING_OPTIONS,
} from 'types/dateRangeTypes';
import { FilterOperator } from 'types/filterOperations';

const useStyles = makeStyles((theme: Theme) => ({
  customRangeDateSelectors: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  configInput: { padding: theme.spacing(3), paddingTop: 0 },
}));

type Props = {
  instructions: V2KPITrendInstructions | V2TrendTableInstructions;
  chartType: OPERATION_TYPES;
  loading?: boolean;
  dashboardElements?: DashboardElement[];
  schema: DatasetSchema;
};

export default function KPITrendConfig({
  instructions,
  chartType,
  loading,
  dashboardElements,
  schema,
}: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const canPeriodRangeUseOffset = (range?: PeriodRangeTypes) => {
    return (
      range !== undefined &&
      range !== PeriodRangeTypes.CUSTOM_RANGE &&
      range !== PeriodRangeTypes.DATE_RANGE_INPUT &&
      range !== PeriodRangeTypes.TIME_PERIOD_DROPDOWN
    );
  };

  const updatePeriodCol = (
    updateFunc: (curr: KPIPeriodColumnInfo) => KPIPeriodColumnInfo | undefined,
  ) => {
    const newInstructions = produce(instructions, (draft) => {
      if (draft.periodColumn) {
        draft.periodColumn = updateFunc(draft.periodColumn);
      }
    });
    dispatch(updateVisualizeOperation(newInstructions, chartType));
  };

  return (
    <div>
      {chartType === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2 ? (
        <KPIAggregation
          chartType={chartType}
          instructions={instructions as V2KPITrendInstructions}
          loading={loading}
          schema={schema}
        />
      ) : (
        <TrendTableAggregations
          chartType={chartType}
          instructions={instructions as V2TrendTableInstructions}
          loading={loading}
          schema={schema}
        />
      )}
      <SettingHeader name="Period Range" />
      <DroppableColumnSection
        isKpiTrendColumn
        required
        allowedTypes={[DATE, DATETIME, TIMESTAMP]}
        columns={instructions.periodColumn ? [{ column: instructions.periodColumn.column }] : []}
        disableEdits={loading}
        maxCols={1}
        onColAdded={(col) => {
          const newInstructions = produce(instructions, (draft) => {
            draft.periodColumn = { column: col, periodRange: defaultPeriodType() };
          });
          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        onColOptionChanged={(option) => {
          updatePeriodCol((periodColumn) => {
            let updated = { ...periodColumn, periodRange: option.id as PeriodRangeTypes };
            if (
              option.id === PeriodRangeTypes.DATE_RANGE_INPUT ||
              option.id === PeriodRangeTypes.TIME_PERIOD_DROPDOWN
            ) {
              updated = { ...updated, customEndDate: undefined, customStartDate: undefined };
            }

            return updated;
          });
        }}
        onRemoveCol={() => updatePeriodCol(() => undefined)}
        periodRange={instructions.periodColumn?.periodRange}
        schema={schema}
      />

      {instructions.periodColumn?.periodRange === PeriodRangeTypes.CUSTOM_RANGE && (
        <div className={classes.customRangeDateSelectors}>
          <ValueInput
            preventFutureDates
            tall
            filterValue={{
              startDate: instructions.periodColumn?.customStartDate,
              endDate: instructions.periodColumn?.customEndDate,
            }}
            onFilterValueUpdate={(newValue: FilterValueType) => {
              const newRange = newValue as FilterValueDateType;

              updatePeriodCol((periodCol) => {
                return {
                  ...periodCol,
                  customStartDate: newRange.startDate,
                  // Backend expects y-m-d formatting
                  customEndDate: newRange.endDate,
                };
              });
            }}
            selectedColumn={{
              name: instructions.periodColumn?.column.name || '',
              type: instructions.periodColumn?.column.type || '',
            }}
            selectedOperator={FilterOperator.DATE_IS_BETWEEN}
          />
        </div>
      )}
      {instructions.periodColumn?.periodRange === PeriodRangeTypes.DATE_RANGE_INPUT && (
        <div className={classes.customRangeDateSelectors}>
          <DropdownSelect
            fillWidth
            minimal
            showIcon
            filterable={false}
            noSelectionText="Select an Input"
            onChange={(newValue) =>
              updatePeriodCol((periodCol) => ({ ...periodCol, rangeElemId: newValue.id }))
            }
            options={filterForValidElementsForDateRangeInput(dashboardElements).map((elem) => ({
              id: elem.name,
              name: elem.name,
            }))}
            selectedItem={
              instructions.periodColumn.rangeElemId
                ? {
                    id: instructions.periodColumn.rangeElemId,
                    name: instructions.periodColumn.rangeElemId,
                  }
                : undefined
            }
          />
        </div>
      )}
      {instructions.periodColumn?.periodRange === PeriodRangeTypes.TIME_PERIOD_DROPDOWN && (
        <div className={classes.customRangeDateSelectors}>
          <DropdownSelect
            fillWidth
            minimal
            showIcon
            filterable={false}
            noSelectionText="Select an Input"
            onChange={(newValue) =>
              updatePeriodCol((periodCol) => ({ ...periodCol, timePeriodElemId: newValue.id }))
            }
            options={filterForValidElementsForTimePeriodDropdown(dashboardElements).map((elem) => ({
              id: elem.name,
              name: elem.name,
            }))}
            selectedItem={
              instructions.periodColumn.timePeriodElemId
                ? {
                    id: instructions.periodColumn.timePeriodElemId,
                    name: instructions.periodColumn.timePeriodElemId,
                  }
                : undefined
            }
          />
        </div>
      )}
      {canPeriodRangeUseOffset(instructions.periodColumn?.periodRange) && (
        <InputWithTag
          className={classes.configInput}
          helpText="This sets the period range's offset e.g. if the period range is 'Last 4 Weeks' and the offset is 2, 
          the period range will be between 2 days ago and 4 weeks ago from 2 days ago"
          label="Offset (Days Ago)"
          onChange={(value) =>
            updatePeriodCol((periodCol) => ({
              ...periodCol,
              trendDateOffset: parseInt(value),
            }))
          }
          placeholder="0"
          value={
            instructions.periodColumn?.trendDateOffset
              ? instructions.periodColumn.trendDateOffset?.toString()
              : '0'
          }
        />
      )}
      <SettingHeader name="Comparison Range" />
      <DropdownSection
        disableEdits={loading}
        onOptionChanged={(option) => {
          const newInstructions = produce(instructions, (draft) => {
            draft.periodComparisonRange = option.id as PeriodComparisonRangeTypes;
          });
          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        options={Object.values(PERIOD_COMPARISON_RANGE_TYPES)}
        selectedOption={
          PERIOD_COMPARISON_RANGE_TYPES[
            instructions.periodComparisonRange || PeriodComparisonRangeTypes.PREVIOUS_PERIOD
          ]
        }
      />
      <SettingHeader name="Grouping" />
      <DropdownSection
        disableEdits={loading}
        onOptionChanged={(option) => {
          const newInstructions = produce(instructions, (draft) => {
            draft.trendGrouping = option.id as TrendGroupingOptions;
          });
          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        options={Object.values(TREND_GROUPING_OPTIONS)}
        selectedOption={
          TREND_GROUPING_OPTIONS[instructions.trendGrouping || TrendGroupingOptions.WEEKLY]
        }
      />
    </div>
  );
}

const filterForValidElementsForDateRangeInput = (dashboardElements?: DashboardElement[]) => {
  if (!dashboardElements) return [];
  return dashboardElements.filter((elem) => {
    if (elem.element_type !== DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER) return false;
    const config = elem.config as DateRangePickerElemConfig;

    return !!config.defaultDateRange;
  });
};

const filterForValidElementsForTimePeriodDropdown = (dashboardElements?: DashboardElement[]) => {
  if (!dashboardElements) return [];
  return dashboardElements.filter((elem) => {
    if (elem.element_type !== DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN) return false;
    const config = elem.config as TimePeriodDropdownElemConfig;

    return !!config.defaultValue && !!config.values && config.values.length > 0;
  });
};
