import produce from 'immer';
import { useDispatch } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { Switch, sprinkles } from 'components/ds';
import { InfoIcon } from 'components/InfoIcon';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import {
  OPERATION_TYPES,
  BaseTableInstructions,
  VisualizeTableInstructions,
} from 'constants/types';
import { MAX_ROWS_TO_PREVIEW } from 'constants/dataConstants';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import SettingHeader from '../../DataConfigTab/SettingHeader';
import { TABLE_ROW_HEIGHT } from 'styles/useBaseDataTableStyles';
import { sharedStyles } from './styles';
import cx from 'classnames';

const MIN_ROW_HEIGHT = 30;

const useStyles = makeStyles((theme: Theme) => ({
  subHeader: {
    padding: `0px ${theme.spacing(3)}px`,
    color: theme.palette.ds.grey900,
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 600,
    marginBottom: theme.spacing(3),
  },
  subSection: {
    marginBottom: theme.spacing(5),
  },
}));

type Props = {
  instructions: BaseTableInstructions;
  visualizationType: OPERATION_TYPES;
};

export default function TableStylingConfig({ instructions, visualizationType }: Props) {
  const classes = useStyles();
  const sharedClasses = sharedStyles();
  const dispatch = useDispatch();

  return (
    <>
      <SettingHeader name="Styling" />
      <div className={sharedClasses.root}>
        <div className={classes.subHeader}>Columns</div>
        <div className={classes.subSection}>
          <Switch
            useCustomStyles
            className={sharedClasses.configInput}
            label="Column lines"
            onChange={() => {
              const newInstructions = produce(instructions, (draft) => {
                draft.isColumnLinesEnabled = !draft.isColumnLinesEnabled;
              });

              dispatch(updateVisualizeOperation(newInstructions, visualizationType));
            }}
            switchOn={instructions.isColumnLinesEnabled}
          />
          <Switch
            useCustomStyles
            className={sharedClasses.configInput}
            label="Bold column headers"
            onChange={() => {
              const newInstructions = produce(instructions, (draft) => {
                draft.isColumnHeadersBolded = !draft.isColumnHeadersBolded;
              });

              dispatch(updateVisualizeOperation(newInstructions, visualizationType));
            }}
            switchOn={instructions.isColumnHeadersBolded}
          />
          <Switch
            useCustomStyles
            className={sharedClasses.configInput}
            label="Bold first column"
            onChange={() => {
              const newInstructions = produce(instructions, (draft) => {
                draft.isFirstColumnBolded = !draft.isFirstColumnBolded;
              });

              dispatch(updateVisualizeOperation(newInstructions, visualizationType));
            }}
            switchOn={instructions.isFirstColumnBolded}
          />
          <Switch
            useCustomStyles
            className={sharedClasses.configInput}
            label="Freeze first column"
            onChange={() => {
              const newInstructions = produce(instructions, (draft) => {
                draft.isFirstColumnFrozen = !draft.isFirstColumnFrozen;
              });

              dispatch(updateVisualizeOperation(newInstructions, visualizationType));
            }}
            switchOn={instructions.isFirstColumnFrozen}
          />
        </div>

        <div className={classes.subHeader}>Rows</div>
        <div className={classes.subSection}>
          <Switch
            useCustomStyles
            className={sharedClasses.configInput}
            label="Row lines"
            onChange={() => {
              const newInstructions = produce(instructions, (draft) => {
                draft.isRowLinesDisabled = !draft.isRowLinesDisabled;
              });

              dispatch(updateVisualizeOperation(newInstructions, visualizationType));
            }}
            switchOn={!instructions.isRowLinesDisabled}
          />

          <InputWithBlurSave
            containerClassName={sharedClasses.configInput}
            helpText="The minimum row height is 30. If cell text is wrapped, this will only impact the header row."
            initialValue={String(instructions.rowHeight || TABLE_ROW_HEIGHT)}
            label="Row Height"
            onNewValueSubmitted={(newHeightString: string) => {
              const newHeight = parseInt(newHeightString);

              if (newHeight < MIN_ROW_HEIGHT || isNaN(newHeight)) return;

              const newInstructions = produce(instructions, (draft) => {
                draft.rowHeight = newHeight;
              });

              dispatch(updateVisualizeOperation(newInstructions, visualizationType));
            }}
            placeholder="Enter a row height"
          />
          {visualizationType === OPERATION_TYPES.VISUALIZE_TABLE ||
          visualizationType === OPERATION_TYPES.VISUALIZE_REPORT_BUILDER ? (
            <InputWithBlurSave
              containerClassName={sharedClasses.configInput}
              helpText="This controls how many rows are loaded each time a user pages through the table."
              initialValue={String(instructions.rowsPerPage || MAX_ROWS_TO_PREVIEW)}
              label="Rows Per Page"
              onNewValueSubmitted={(newRowsPerPageString: string) => {
                const newRowsPerPage = parseInt(newRowsPerPageString);

                if (newRowsPerPage < 0 || newRowsPerPage > 500 || isNaN(newRowsPerPage)) return;

                const newInstructions = produce(instructions, (draft) => {
                  draft.rowsPerPage = newRowsPerPage;
                });

                dispatch(updateVisualizeOperation(newInstructions, visualizationType));
              }}
              placeholder="Enter a number of rows"
            />
          ) : null}

          {/* TODO: revisit this when the design system updates are in for info icon */}
          <div
            className={cx(
              sprinkles({
                display: 'flex',
                justifyContent: 'space-between',
              }),
              sharedClasses.configInput,
            )}>
            <div className={sprinkles({ display: 'flex', flexDirection: 'row' })}>
              <div className={sprinkles({ color: 'gray12', body: 'b3' })}>
                Group rows visually by first column
              </div>
              <InfoIcon infoTooltipText="This will disable sorting and column editing for your table." />
            </div>
            <Switch
              useCustomStyles
              onChange={() => {
                const newInstructions = produce(instructions, (draft) => {
                  draft.shouldVisuallyGroupByFirstColumn = !draft.shouldVisuallyGroupByFirstColumn;

                  if (draft.shouldVisuallyGroupByFirstColumn) {
                    draft.isColumnSortingDisabled = true;
                    if (visualizationType === OPERATION_TYPES.VISUALIZE_TABLE)
                      (draft as VisualizeTableInstructions).isSchemaCustomizationEnabled = false;
                  }
                });

                dispatch(updateVisualizeOperation(newInstructions, visualizationType));
              }}
              switchOn={instructions.shouldVisuallyGroupByFirstColumn}
            />
          </div>
        </div>

        <div className={classes.subHeader}>Cells</div>
        <div className={classes.subSection}>
          <Switch
            useCustomStyles
            className={sharedClasses.configInput}
            label="Wrap text"
            onChange={() => {
              const newInstructions = produce(instructions, (draft) => {
                draft.shouldTruncateText = !draft.shouldTruncateText;
              });

              dispatch(updateVisualizeOperation(newInstructions, visualizationType));
            }}
            switchOn={!instructions.shouldTruncateText}
          />
        </div>
      </div>
    </>
  );
}
