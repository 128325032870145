import { createReducer } from '@reduxjs/toolkit';
import { fetchSupportedDataSourcesSuccess, SupportedDataSource } from 'actions/dataSourceActions';

interface SupportedDataSourcesReducerState {
  dataSources?: SupportedDataSource[];
}

const supportedDataSourceInitialState: SupportedDataSourcesReducerState = {};

export default createReducer(supportedDataSourceInitialState, (builder) => {
  builder
    .addCase(fetchSupportedDataSourcesSuccess, (state, { payload }) => {
      state.dataSources = payload.supported_sources;
    })
    .addDefaultCase((state) => state);
});
