import { useState } from 'react';
import cx from 'classnames';
import Color from 'color';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';

import ColorPickerModal from 'pages/dataPanelEditorPage/colorPicker';
import Button from 'shared/Button';

export type Props = {
  color?: string;
  alpha?: number;
  onClose: (color: string) => void;
  fill?: boolean;
  buttonClassName?: string;
  className?: string;
  colors?: string[];
  label?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  fill: {
    '& .bp3-popover-target': {
      width: '100%',
    },
    '& .bp3-popover': {
      width: '100%',
    },
  },
  button: {
    border: `1px solid ${theme.palette.ds.grey400}`,
    height: '30px',
  },
}));

export default function ColorPicker({
  color,
  onClose,
  fill,
  alpha,
  buttonClassName,
  className,
  colors = [],
  label,
}: Props) {
  const [selectedColor, setSelectedColor] = useState(color ?? '');
  const colorObject = new Color(color);
  const classes = useStyles();
  const theme = useTheme();

  if (!color) {
    return <Button disabled fillWidth={fill} text="—" type="primary" />;
  }

  const colorText = colorObject.hex();
  const alphaText = alpha === undefined ? '' : ` ${alpha * 100}%`;
  const buttonText = label === undefined ? `${colorText}${alphaText}` : label;
  return (
    <ColorPickerModal
      className={cx({ [classes.fill]: fill }, className)}
      colors={colors}
      onClose={() => {
        onClose(selectedColor);
      }}
      onUpdate={(c) => {
        setSelectedColor(c);
      }}
      selectedColor={selectedColor}>
      <Button
        fillWidth
        className={cx(classes.button, buttonClassName)}
        style={{
          backgroundColor: colorObject.hex(),
          color: colorObject.isDark() ? theme.palette.ds.white : theme.palette.ds.black,
        }}
        text={buttonText}
        type="primary"
      />
    </ColorPickerModal>
  );
}
