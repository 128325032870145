import { makeStyles } from '@material-ui/styles';

import InputLabel from 'shared/InputLabel';
import theme from 'theme';

const useStyles = makeStyles(() => ({
  descriptiveTextLink: {
    fontWeight: 600,
    '&:hover': {
      color: theme.palette.ds.blue,
    },
  },
}));

export default function DateFormatDescriptiveText() {
  const classes = useStyles();
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <InputLabel text="Learn more about date formats" />
      <a
        href="https://moment.github.io/luxon/#/formatting?id=table-of-tokens"
        rel="noopener noreferrer"
        style={{ paddingLeft: theme.spacing(1) }}
        target="_blank">
        <InputLabel className={classes.descriptiveTextLink} text="here" />
      </a>
    </div>
  );
}
