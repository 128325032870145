import { useDispatch } from 'react-redux';

import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';
import DropdownSelect from 'shared/DropdownSelect';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { OPERATION_TYPES, V2TwoDimensionChartInstructions, YAxisFormat } from 'constants/types';
import YAxisConfig from './YAxisConfig';
import {
  updateYAxisFormat,
  getAggColDisplayName,
  getYAxisFormat,
} from 'pages/dashboardPage/charts/utils/multiYAxisUtils';
import equal from 'fast-deep-equal/es6/react';
import { sharedStyles } from './styles';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2TwoDimensionChartInstructions;
  yAxisFormatId?: string;
};

/**
 * This component is used to display and set the YAxisOptions for charts that can format multiple Y-Axes
 * It has some extra options that don't make sense on a single Y Axis config such as matching the visualization color
 */
export default function MultiYAxisConfig({
  visualizationType,
  instructions,
  yAxisFormatId,
}: Props) {
  const classes = sharedStyles();
  const dispatch = useDispatch();

  const yAxisFormat = getYAxisFormat(yAxisFormatId, instructions);

  const getColorSelectedItem = () => {
    const mappedAggColumn = (instructions.aggColumns || []).find((aggColumn) =>
      equal(aggColumn, yAxisFormat?.colorFromAggColumn),
    );
    if (!mappedAggColumn) return undefined;
    return {
      id: `${mappedAggColumn.agg.id}-${mappedAggColumn.column.name}`,
      name: getAggColDisplayName(mappedAggColumn),
    };
  };

  const updateYAxis = (updates: YAxisFormat) => {
    const newYAxisFormat = { ...yAxisFormat, ...updates };
    dispatch(
      updateVisualizeOperation(updateYAxisFormat(newYAxisFormat, instructions), visualizationType),
    );
  };

  return (
    <div className={classes.root}>
      <YAxisConfig
        instructions={instructions}
        visualizationType={visualizationType}
        yAxisFormatId={yAxisFormatId}
      />
      <DropdownSelect
        btnMinimal
        fillWidth
        minimal
        showCancelBtn
        containerClassName={classes.configInput}
        filterable={false}
        label="Match Axis Color"
        noSelectionText="Select Axis Color"
        onCancelClick={() => updateYAxis({ colorFromAggColumn: undefined })}
        onChange={(newItem) => {
          const aggColumn = (instructions.aggColumns || []).find((aggColumn) => {
            // newItem.id will always be "aggType-aggColumn"
            const idParts = newItem.id.split('-');
            return idParts[0] === aggColumn.agg.id && idParts[1] === aggColumn.column.name;
          });
          if (!aggColumn) return;
          updateYAxis({ colorFromAggColumn: aggColumn });
        }}
        options={
          instructions.aggColumns?.map((aggColumn) => {
            return {
              id: `${aggColumn.agg.id}-${aggColumn.column.name}`,
              name: getAggColDisplayName(aggColumn),
            };
          }) || []
        }
        selectedItem={getColorSelectedItem()}
      />
      <ToggleButtonGroup fillWidth className={classes.configInput} label="Axis placements">
        <ToggleButton
          active={!yAxisFormat?.oppositeAligned}
          key="left"
          onClick={() => updateYAxis({ oppositeAligned: false })}
          text="Left"
        />
        <ToggleButton
          active={yAxisFormat?.oppositeAligned}
          key="right"
          onClick={() => updateYAxis({ oppositeAligned: true })}
          text="Right"
        />
      </ToggleButtonGroup>
    </div>
  );
}
