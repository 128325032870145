import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import { embedFetchShareData } from 'actions/shareActions';
import { VIEW_MODE } from 'types/dashboardTypes';

import EmbeddedDashboard, { GenericLoadingSpinner } from 'components/EmbeddedDashboard';
import PortalHeader from './portalHeader';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100vh',
  },
}));

type Props = {
  dashboardLogoUrl?: string;
  dashboardName: string;
  faviconUrl: string;
  onSignOut: () => void;
  shareId: string;
  siteTitle: string;
};

export default function PortalDashboardPage({
  dashboardLogoUrl,
  dashboardName,
  faviconUrl,
  onSignOut,
  shareId,
  siteTitle,
}: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isStrictViewingMode, setIsStrictViewingMode] = useState<boolean>(false);
  const [customerToken, setCustomerToken] = useState<string>('');
  const [dashboardEmbedId, setDashboardEmbedId] = useState<string>('');
  const [versionNumber, setVersionNumber] = useState<number>();
  const [dashboardSiteTitle, setDashboardSiteTitle] = useState<string>(siteTitle);

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      embedFetchShareData({ id: shareId, customerToken }, (shareData) => {
        setCustomerToken(shareData.customer_token);
        setDashboardEmbedId(shareData.dashboard_embed_id);
        setVersionNumber(shareData.version_number);
        setIsLoading(false);
        setIsStrictViewingMode(shareData.is_strict_viewing_mode);
        setDashboardSiteTitle(shareData.site_title ?? siteTitle);
      }),
    );
  }, [dispatch, shareId, customerToken, siteTitle]);

  useEffect(() => {
    const faviconElement = document.getElementById('site-favicon') as HTMLAnchorElement;
    const siteTitleElement = document.getElementById('site-title') as HTMLAnchorElement;

    if (faviconElement && faviconUrl !== '')
      faviconElement.href = `https://www.google.com/s2/favicons?domain=${faviconUrl}`;
    if (siteTitleElement && dashboardSiteTitle !== '')
      siteTitleElement.innerHTML = `${dashboardSiteTitle}`;
  }, [faviconUrl, dashboardSiteTitle]);

  if (isLoading) {
    return <GenericLoadingSpinner embedType="shared" />;
  }

  return (
    <div className={classes.root}>
      <PortalHeader
        dashboardName={dashboardName}
        iconUrl={dashboardLogoUrl}
        onSignOut={onSignOut}></PortalHeader>
      <EmbeddedDashboard
        customerToken={customerToken}
        dashboardEmbedId={dashboardEmbedId}
        embedType="portal"
        // Keeping the old way to set strict mode through the URL for backwards compatability
        isStrict={isStrictViewingMode}
        versionNumber={versionNumber}
        viewMode={VIEW_MODE.SHARE}
      />
    </div>
  );
}
