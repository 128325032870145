import { FC } from 'react';

import { Icon } from '@blueprintjs/core';

import * as styles from './styles.css';

type Props = { title: string; isComplete?: boolean };

export const FilterSectionHeader: FC<Props> = ({ title, isComplete }) => {
  return (
    <div className={styles.filterSectionHeader}>
      <div className={styles.sectionHeaderText}>{title}</div>
      {isComplete !== undefined ? (
        <Icon
          className={isComplete ? styles.completeCheck : styles.incompleteCheck}
          icon="tick-circle"
          size={16}
        />
      ) : null}
    </div>
  );
};
