import { FC, useMemo } from 'react';
import { useDroppable } from '@dnd-kit/core';
import { useDispatch } from 'react-redux';

import { DataPanelSubHeader } from './SubHeader';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import {
  SortableListItemDragHandle,
  SortableList,
  SortableListItem,
} from 'components/SortableList/SortableList';
import { IconButton, sprinkles } from 'components/ds';
import {
  EmbeddedDropdownButton,
  EmbeddedDropdownMenu,
} from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownMenu';
import * as styles from './index.css';

import { AGGS_SECTION_ID } from './constants';
import { CustomerReportAgg } from 'actions/customerReportActions';
import { getAggUniqueId, getReportAggOptions } from 'utils/customerReportUtils';
import { updateAggThunk } from 'reportBuilderContent/thunks';
import { AGGREGATIONS_TYPES } from 'constants/dataConstants';
import { Aggregation } from 'constants/types';
import { orderAggs } from 'reportBuilderContent/reducers/reportEditingReducer';

const highlightClass = sprinkles({
  parentContainer: 'fillAbsolute',
  border: 2,
  borderColor: 'active',
});

type Props = {
  colNameToDisplay: Record<string, string | undefined>;
  aggregations: CustomerReportAgg[];
};

export const AggregationsSection: FC<Props> = ({ aggregations, colNameToDisplay }) => {
  const dispatch = useDispatch();

  const { setNodeRef, isOver } = useDroppable({
    id: AGGS_SECTION_ID,
    data: { section: AGGS_SECTION_ID },
  });

  const aggsByCol = useMemo(() => {
    const byCol: Record<string, Set<Aggregation>> = {};
    aggregations.forEach(({ column, agg }) => {
      if (column.name in byCol) byCol[column.name].add(agg.id);
      else byCol[column.name] = new Set([agg.id]);
    });
    return byCol;
  }, [aggregations]);

  return (
    <div
      className={sprinkles({
        position: 'relative',
        paddingBottom: aggregations.length > 0 ? 'sp2' : 'sp0',
      })}
      ref={setNodeRef}>
      <DataPanelSubHeader fieldCount={aggregations.length} title="Values" />
      <SortableList
        getIdFromElem={getAggUniqueId}
        onListUpdated={(orderedAggs) => dispatch(orderAggs(orderedAggs))}
        sortableItems={aggregations}>
        {aggregations.map((agg, idx) => {
          const id = getAggUniqueId(agg);
          const selectedAgg = AGGREGATIONS_TYPES[agg.agg.id];
          const aggOptions = getReportAggOptions(agg, aggsByCol);

          return (
            <SortableListItem key={`${agg.column.name}-${idx}`} sortId={id}>
              <div className={styles.groupedDraggableCol}>
                <SortableListItemDragHandle className={sprinkles({ color: 'contentTertiary' })} />
                <EmbedText body="b1" className={styles.columnName}>
                  {colNameToDisplay[agg.column.name] ?? agg.column.name}
                </EmbedText>

                <EmbeddedDropdownMenu
                  menuOptions={aggOptions}
                  onClick={(newAgg) =>
                    dispatch(updateAggThunk(id, { ...agg, agg: { id: newAgg as Aggregation } }))
                  }>
                  <EmbeddedDropdownButton selectedName={selectedAgg.name} />
                </EmbeddedDropdownMenu>
                <IconButton
                  className={styles.eyeIcon}
                  name="cross"
                  onClick={() => dispatch(updateAggThunk(id))}
                />
              </div>
            </SortableListItem>
          );
        })}
      </SortableList>
      {isOver ? <div className={highlightClass} /> : null}
    </div>
  );
};
