import produce from 'immer';

import FormatSection from './FormatSection';
import { SharedColorConfigs } from 'components/ChartConfigs/ColorConfigs';

import { sharedStyles } from './sharedStyles';
import { ColorFormat, V2TwoDimensionChartInstructions } from 'constants/types';
import { CanvasV2TwoDimensionChartTypes, FormatConfigProps } from './types';
import { useSelector } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';

type Props = FormatConfigProps<CanvasV2TwoDimensionChartTypes, V2TwoDimensionChartInstructions>;

export default function ColorSection({
  isArchitectCustomerDashboard,
  instructions,
  updateInstructions,
  operationType,
}: Props): JSX.Element | null {
  const classes = sharedStyles({ isArchitectCustomerDashboard });

  const globalStyleConfig = useSelector(
    (state: ReduxState) => state.dashboardStyles.globalStyleConfig,
  );

  const updateColorFormat = (colorUpdates: ColorFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.colorFormat = { ...draft.colorFormat, ...colorUpdates };
    });
    updateInstructions({ instructions: newInstructions, operationType });
  };

  return (
    <FormatSection isArchitectCustomerDashboard={isArchitectCustomerDashboard} title="Colors">
      <SharedColorConfigs
        isCanvas
        configInputClass={classes.configInput}
        globalStyleConfig={globalStyleConfig}
        instructions={instructions}
        updateColorFormat={updateColorFormat}
        usePortal={isArchitectCustomerDashboard}
      />
    </FormatSection>
  );
}
