import { useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import parse from 'url-parse';
import { useHistory } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FormGroup } from '@blueprintjs/core';
import { ReduxState } from 'reducers/rootReducer';
import cx from 'classnames';

import OnboardingFlowPage from 'components/Onboarding/OnboardingFlowPage';
import InputWithTag from 'shared/InputWithTag';
import PasswordInput from 'components/PasswordInput';
import InfoCard from 'shared/InfoCard';
import Button from 'shared/Button';

import { fetchSignupEmail, googleOAuthVerification, registerUser } from 'actions/authAction';
import { createLoadingSelector } from 'reducers/api/selectors';
import {
  googleOAuthVerificationOnScriptFailure,
  onRegistrationSubmit,
} from 'utils/landingPageUtils';
import { ACTION } from 'actions/types';
import { pageView } from 'analytics/exploAnalytics';

import {
  googleOAuthVerificationOnSuccess,
  googleOAuthVerificationOnFailure,
} from 'utils/landingPageUtils';
import { ROUTES } from 'constants/routes';
import { enableSSO } from 'featureGates/featureGates';
import { validatePassword } from 'utils/passwordUtils';

const useStyles = makeStyles((theme: Theme) => ({
  signupInput: {
    width: '100%',
    marginBottom: theme.spacing(4),
  },
  signupInputLast: {
    marginBottom: 0,
  },
  signInButton: {
    width: '100%',
    height: '40px !important',
    marginBottom: theme.spacing(3),
  },
  forgotPassword: {
    fontSize: 12,
    color: theme.palette.ds.grey900,
    textDecoration: 'underline',
  },
  forgotPasswordContainer: {
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(2),
  },
  googleSignupInput: {
    width: '100%',
    marginTop: theme.spacing(0.5),
    justifyContent: 'center',
    // we have to override google's default styling on the login button
    height: '40px !important',
    boxShadow: 'none !important',
    borderRadius: '4px !important',
    border: `1px solid ${theme.palette.ds.grey400} !important`,
    overflow: 'hidden',
  },
  formContainer: {
    width: '100%',
  },
  errorCard: {
    height: 'fit-content',
    marginBottom: theme.spacing(6),
  },
  termsOfService: {
    fontSize: 12,
  },
  termsLink: {
    color: theme.palette.ds.blue,
    textDecoration: 'none',
  },
  sideBySideInputs: {
    display: 'flex',
    alignItems: 'center',
  },
  leftSideBySide: {
    marginRight: theme.spacing(4),
  },
}));

const SignUpPage = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [signupEmail, setSignupEmail] = useState('');
  const [teamName, setTeamName] = useState('');
  const [inviteCode, setInviteCode] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [disableSSOForIcognito, setDisableSSOForIncognito] = useState(false);

  const { registrationLoading, ssoLoginLoading } = useSelector(
    (state: ReduxState) => ({
      registrationLoading: createLoadingSelector([ACTION.REGISTER_USER], false)(state),
      ssoLoginLoading: createLoadingSelector([ACTION.GOOGLE_OAUTH_VERIFICATION], false)(state),
    }),
    shallowEqual,
  );

  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const urlQueryParams = parse(window.location.href, true).query;
  useEffect(() => {
    if (urlQueryParams.invite_hash) {
      dispatch(
        fetchSignupEmail(
          { postData: { invite_hash: urlQueryParams.invite_hash } },
          (response) => {
            if (response.invite_accepted) {
              history.push(`/login?invite_hash=${urlQueryParams.invite_hash}`);
            }
            if (!response.invite_code) {
              setErrorMsg(
                'The invite code is invalid. Please make sure you copied the invite link correctly or request a new invitation.',
              );
            } else {
              setSignupEmail(response.email);
              setInviteCode(response.invite_code);
              setTeamName(response.team_name);
            }
          },
          () =>
            setErrorMsg(
              'The invite code is invalid. Please make sure you copied the invite link correctly or request a new invitation.',
            ),
        ),
      );
    }
  }, [urlQueryParams.invite_hash, history, dispatch]);

  useEffect(() => {
    pageView('Signup');

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        onRegistrationSubmit(
          password,
          password2,
          signupEmail,
          firstName,
          lastName,
          registerUser,
          dispatch,
          setErrorMsg,
        );
        return;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  const formIncomplete = !password || !password2 || !signupEmail || !firstName || !lastName;

  const passwordError = password && password2 ? validatePassword(password, password2) : '';

  return (
    <OnboardingFlowPage
      helpLinks={[
        { name: 'Sign In', to: ROUTES.LOGIN },
        { name: 'Need Support?', url: 'https://docs.explo.co/' },
      ]}
      rightContentTitle={
        teamName ? (
          <>
            Join <b>{teamName}</b> on Explo
          </>
        ) : (
          'Get started with Explo'
        )
      }
      rightPanelContent={
        <div className={classes.formContainer}>
          <div className={classes.sideBySideInputs}>
            <InputWithTag
              className={cx(classes.signupInput, classes.leftSideBySide)}
              data-testid="sign-up-first-name"
              label="First name"
              onChange={setFirstName}
              placeholder=""
              value={firstName}
            />

            <InputWithTag
              className={classes.signupInput}
              data-testid="sign-up-last-name"
              label="Last name"
              onChange={setLastName}
              placeholder=""
              value={lastName}
            />
          </div>

          <InputWithTag
            className={classes.signupInput}
            data-testid="sign-up-email"
            disabled={!!inviteCode}
            label="Email address"
            onChange={setSignupEmail}
            placeholder=""
            value={signupEmail}
          />

          <PasswordInput
            className={classes.signupInput}
            data-testid="sign-up-password"
            label="Password"
            onChange={setPassword}
            value={password}
          />
          <PasswordInput
            className={classes.signupInput}
            data-testid="sign-up-confirm-password"
            label="Confirm password"
            onChange={setPassword2}
            value={password2}
          />
          {errorMsg || passwordError ? (
            <InfoCard error className={classes.errorCard} text={errorMsg || passwordError} />
          ) : null}
          <Button
            className={classes.signInButton}
            data-testid="sign-up-submit"
            disabled={formIncomplete || passwordError !== ''}
            loading={registrationLoading || ssoLoginLoading}
            onClick={() =>
              !formIncomplete &&
              onRegistrationSubmit(
                password,
                password2,
                signupEmail,
                firstName,
                lastName,
                registerUser,
                dispatch,
                setErrorMsg,
              )
            }
            text="Sign Up"
            type="primary"
          />
          {enableSSO && (
            <div>
              <FormGroup>
                <GoogleLogin
                  accessType="offline"
                  buttonText="Sign up with Google"
                  className={classes.googleSignupInput}
                  clientId={`${process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}`}
                  disabled={registrationLoading || ssoLoginLoading || disableSSOForIcognito}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onFailure={(response: any) => googleOAuthVerificationOnFailure(response?.details)}
                  onScriptLoadFailure={() => {
                    setDisableSSOForIncognito(true);
                    googleOAuthVerificationOnScriptFailure();
                  }}
                  onSuccess={(response) => {
                    dispatch(
                      googleOAuthVerification(
                        {
                          postData: {
                            authorization_code: response.code,
                            invite_hash: urlQueryParams.invite_hash,
                          },
                        },
                        (response) =>
                          googleOAuthVerificationOnSuccess(
                            response.token,
                            response.is_first_time_sso_login_for_existing_account,
                          ),
                        (response) => googleOAuthVerificationOnFailure(response?.error_msg),
                      ),
                    );
                  }}
                  redirectUri={`${process.env.REACT_APP_GOOGLE_OAUTH_REDIRECT_URI}`}
                  responseType="code"
                />
              </FormGroup>
            </div>
          )}

          <div className={classes.termsOfService}>
            By creating an account, you agree to our{' '}
            <a
              className={classes.termsLink}
              href="//explo.co/legal/terms-of-service"
              rel="noopener noreferrer"
              target="_blank">
              terms{' '}
            </a>
            and{' '}
            <a
              className={classes.termsLink}
              href="//explo.co/legal/privacy-policy"
              rel="noopener noreferrer"
              target="_blank">
              privacy policy
            </a>
            .
          </div>
        </div>
      }
    />
  );
};

export default SignUpPage;
