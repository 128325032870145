import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { NonIdealState, Icon, IconName, Intent } from '@blueprintjs/core';

import { Spinner } from 'components/ds';

import { HEADER_HEIGHT } from 'pages/dashboardPage/DashboardDatasetView/DashboardDatasetView';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';

const useStyles = makeStyles((theme: Theme) => ({
  calloutContainer: {
    height: `calc(100% - ${HEADER_HEIGHT}px)`,
    padding: theme.spacing(2),

    '&.fullHeight': {
      height: '100%',
    },
  },
  callout: {
    height: '100%',
    overflow: 'auto',
  },
  cogIcon: {
    display: 'block',
  },
}));

type Props = {
  className?: string;
  loading?: boolean;
  instructionsNeedConfiguration?: boolean;
  fullHeight?: boolean;
  requiredVarsNotSet?: boolean;
  datasetDoesNotExist?: boolean;
  columnErrorText?: string;
  missingFilterError?: string;
};

export default function NeedsConfigurationPanel({
  className,
  fullHeight,
  instructionsNeedConfiguration,
  loading,
  requiredVarsNotSet,
  datasetDoesNotExist,
  columnErrorText,
  missingFilterError,
}: Props) {
  const classes = useStyles();

  let icon: IconName | JSX.Element;
  let title: string;
  let description: string | undefined = undefined;

  if (instructionsNeedConfiguration) {
    icon = <Icon className={classes.cogIcon} icon="cog" iconSize={30} />;
    title = 'Configure the chart to get started';
  } else if (requiredVarsNotSet) {
    icon = 'multi-select';
    title = 'A dropdown or datepicker is required to load this chart. Please select an option.';
  } else if (loading) {
    icon = <Spinner fillContainer size="lg" />;
    title = '';
  } else if (datasetDoesNotExist) {
    icon = <Icon className={classes.cogIcon} icon="issue" iconSize={30} />;
    title = 'The underlying data is no longer available.';
    description = 'Update dataset to view chart';
  } else if (columnErrorText) {
    icon = <Icon className={classes.cogIcon} icon="issue" iconSize={30} />;
    title = columnErrorText;
    description = 'Update column to view chart';
  } else if (missingFilterError) {
    icon = <Icon className={classes.cogIcon} icon="issue" iconSize={30} />;
    title = missingFilterError;
    description = missingFilterError.includes('set a value')
      ? 'Set a value for the filter'
      : 'Add filter to dashboard';
  } else {
    icon = <Icon icon="error" iconSize={50} intent={Intent.DANGER} />;
    title = 'Something went wrong. Please refresh the page and try again';
  }

  return (
    <div
      className={cx(
        classes.calloutContainer,
        GLOBAL_STYLE_CLASSNAMES.container.fill.backgroundColor,
        className,
        { fullHeight },
      )}>
      <NonIdealState
        className={classes.callout}
        description={description}
        icon={icon}
        title={title}
      />
    </div>
  );
}
