import { FC } from 'react';
import { useSelector } from 'react-redux';

import DropdownSelect from 'shared/DropdownSelect';

import { ReduxState } from 'reducers/rootReducer';

type Props = {
  className?: string;
  selectedDatasetId?: string;
  selectedDatasetSchemaId?: number;
  onSelectSchema: (schema: { id: number; name: string }) => void;
};

export const SchemaSelect: FC<Props> = ({
  className,
  selectedDatasetId,
  selectedDatasetSchemaId,
  onSelectSchema,
}) => {
  const parentSchemas =
    useSelector((state: ReduxState) => state.parentSchemas.usedParentSchemas) ?? [];

  const schemaOptions = parentSchemas.map((schema) => ({
    id: String(schema.id),
    name: schema.name,
  }));

  const handleUpdate = (schema: { id: string; name: string }) => {
    if (selectedDatasetId) onSelectSchema({ id: parseInt(schema.id), name: schema.name });
  };

  return (
    <div className={className}>
      <DropdownSelect
        fillWidth
        ignoreCustomStyles
        minimal
        usePortal
        filterable={false}
        noSelectionText="Select a schema"
        onChange={handleUpdate}
        options={schemaOptions}
        selectedItem={schemaOptions.find(
          (schemaOption) => schemaOption.id === String(selectedDatasetSchemaId),
        )}
      />
    </div>
  );
};
