import { useEffect, useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { sortBy } from 'utils/standard';
import cx from 'classnames';

import Button from 'shared/Button';
import DropdownSelect from 'shared/DropdownSelect';
import ChartConfig from './ChartConfig';
import FormatConfig from './FormatConfig';
import PanelFilters from './PanelFilters';
import { ConfigSection } from 'components/resource/ConfigSection';

import {
  CanvasDataset,
  CanvasTemplate,
  updateCanvasTemplate,
  updateCanvasTemplateChartType,
} from 'actions/canvasConfigActions';
import { getChartIcon } from '../icons';
import { FilterClause, OPERATION_TYPES, SelectedDropdownInputItem } from 'constants/types';
import { VisualizeOperation } from 'types/dataPanelTemplate';
import {
  deleteEUDItem,
  EndUserDataPanel,
  updateEUDataPanelChartType,
  updateEUDataPanelDataset,
} from 'actions/architectCustomerDashboardConfigActions';
import { BAR_CHART_SET, getSubChartTypeOptions, PIE_CHART_SET } from '../constants';
import { CanvasConfigureType } from 'actions/canvasActions';

type StyleProps = { isArchitectCustomerDashboard: boolean };

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    flex: 1,
  },
  sectionHeader: {
    height: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.ds.grey200,
    padding: '0px 10px',
    cursor: 'pointer',
  },
  sectionHeaderTitle: {
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.ds.grey900,
  },

  dataSection: ({ isArchitectCustomerDashboard }: StyleProps) => ({
    padding: isArchitectCustomerDashboard ? theme.spacing(2) : '0px 12px',
  }),

  chartTypeSection: ({ isArchitectCustomerDashboard }: StyleProps) => ({
    padding: isArchitectCustomerDashboard ? theme.spacing(2) : '0px 12px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 10,
  }),
  formatSection: ({ isArchitectCustomerDashboard }: StyleProps) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: isArchitectCustomerDashboard ? undefined : '0px 16px',
  }),
  chartType: {
    height: 40,
    width: 40,
    cursor: 'pointer',
    borderRadius: 4,
    backgroundColor: theme.palette.ds.white,
    outline: `1px solid ${theme.palette.ds.grey400}`,
  },
  selectedChartType: {
    outline: `1px solid ${theme.palette.ds.blue}`,
  },

  tabOptions: {
    display: 'flex',
    height: 40,
    minHeight: 40,
    width: '100%',
  },
  configTab: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.ds.grey800,
    flex: 1,

    '&.selectedTab': {
      backgroundColor: theme.palette.ds.lightBlue,
      color: theme.palette.ds.blue,
      borderBottom: `1px solid ${theme.palette.ds.blue}`,
      marginBottom: '-1px',
      zIndex: 2,
    },
  },
  formattingSubText: {
    lineHeight: '17px',
    fontSize: 14,
    marginBottom: theme.spacing(4),
    color: theme.palette.ds.grey900,
  },
  deleteWrapper: {
    height: 49,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.ds.grey400}`,
  },
  chartSubType: ({ isArchitectCustomerDashboard }: StyleProps) => ({
    margin: isArchitectCustomerDashboard ? '2px 8px 8px 8px' : '10px 12px 0px 12px',
  }),
}));

type Props = {
  configurability: CanvasConfigureType;
  datasets: Record<string, CanvasDataset>;
  isArchitectCustomerDashboard: boolean;
  isNew: boolean;
  panel: CanvasTemplate | EndUserDataPanel;
  isPreview?: boolean;

  fetchPanelData: (updates: { newViz?: VisualizeOperation; newFilters?: FilterClause[] }) => void;
};

enum SECTIONS {
  DATA = 'Data',
  CHART_TYPE = 'Chart type',
  CONFIGURATION = 'Configuration',
  FILTERING = 'Filters',
  FORMATTING = 'Formatting',
}
const sections = [
  SECTIONS.DATA,
  SECTIONS.CHART_TYPE,
  SECTIONS.CONFIGURATION,
  SECTIONS.FILTERING,
  SECTIONS.FORMATTING,
];

enum CONFIG_TAB {
  DATA = 'Data',
  STYLING = 'Styling',
}

export default function ChartEditor({
  configurability,
  datasets,
  panel,
  fetchPanelData,
  isArchitectCustomerDashboard,
  isNew,
  isPreview,
}: Props): JSX.Element {
  const classes = useStyles({ isArchitectCustomerDashboard });
  const dispatch = useDispatch();

  // Used for End User Dashboard Side
  const [openTab, setOpenTab] = useState(
    configurability === CanvasConfigureType.EDITABLE ? CONFIG_TAB.DATA : CONFIG_TAB.STYLING,
  );

  useEffect(() => {
    if (configurability === CanvasConfigureType.STYLING) setOpenTab(CONFIG_TAB.STYLING);
    else setOpenTab(CONFIG_TAB.DATA);
  }, [configurability]);

  const operationType = panel.visualize_op.operation_type;

  const viewChartType = (chartType: OPERATION_TYPES) => {
    const isSelected =
      chartType === operationType ||
      (chartType === OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2 &&
        BAR_CHART_SET.has(operationType)) ||
      (chartType === OPERATION_TYPES.VISUALIZE_PIE_CHART_V2 && PIE_CHART_SET.has(operationType));
    return (
      <div
        className={cx(classes.chartType, {
          [classes.selectedChartType]: isSelected,
        })}
        key={`chart-type-${chartType}`}
        onClick={() => {
          if (isSelected || isPreview) return;
          dispatch(
            isArchitectCustomerDashboard
              ? updateEUDataPanelChartType({ operationType: chartType, isSubType: false })
              : updateCanvasTemplateChartType({
                  templateId: panel.id,
                  operationType: chartType,
                  isSubType: false,
                }),
          );
          if (chartType === OPERATION_TYPES.VISUALIZE_TABLE) {
            fetchPanelData({ newViz: { ...panel.visualize_op, operation_type: chartType } });
          }
        }}>
        {getChartIcon(chartType)}
      </div>
    );
  };

  const viewSubChartTypes = () => {
    const options = getSubChartTypeOptions(operationType);
    if (options.length === 0) return null;

    return (
      <DropdownSelect
        btnMinimal
        fillWidth
        ignoreCustomStyles
        minimal
        showIcon
        containerClassName={classes.chartSubType}
        filterable={false}
        noSelectionText=""
        onChange={(item) => {
          if (isPreview) return;
          const operationType = item.id as OPERATION_TYPES;
          dispatch(
            isArchitectCustomerDashboard
              ? updateEUDataPanelChartType({ operationType, isSubType: true })
              : updateCanvasTemplateChartType({
                  templateId: panel.id,
                  operationType,
                  isSubType: true,
                }),
          );
        }}
        options={options}
        selectedItem={options.find((opt) => opt.id === operationType)}
      />
    );
  };

  const renderSectionOptions = (section: SECTIONS) => {
    switch (section) {
      case SECTIONS.DATA: {
        let datasetOptions: SelectedDropdownInputItem[] = [];
        Object.values(datasets).forEach((dataset) => {
          if (dataset.isHiddenFromUsers) return;
          datasetOptions.push({ id: dataset.id, name: dataset.name });
        });
        datasetOptions = sortBy(datasetOptions, 'name');

        return (
          <div className={classes.dataSection}>
            <DropdownSelect
              btnMinimal
              fillWidth
              ignoreCustomStyles
              minimal
              filterable={false}
              noSelectionText="Select a Dataset"
              onChange={(item) => {
                if (isPreview || item.id === panel.dataset_id) return;
                dispatch(
                  isArchitectCustomerDashboard
                    ? updateEUDataPanelDataset(item.id)
                    : updateCanvasTemplate({ templateId: panel.id, datasetId: item.id }),
                );
              }}
              options={datasetOptions}
              selectedItem={datasetOptions.find((opt) => opt.id === panel.dataset_id)}
            />
          </div>
        );
      }
      case SECTIONS.CHART_TYPE:
        return (
          <>
            <div className={classes.chartTypeSection}>
              {[
                OPERATION_TYPES.VISUALIZE_TABLE,
                OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2,
                OPERATION_TYPES.VISUALIZE_LINE_CHART_V2,
                OPERATION_TYPES.VISUALIZE_AREA_CHART_V2,
                OPERATION_TYPES.VISUALIZE_NUMBER_V2,
                OPERATION_TYPES.VISUALIZE_PIE_CHART_V2,
                OPERATION_TYPES.VISUALIZE_FUNNEL_V2,
              ].map(viewChartType)}
            </div>
            {viewSubChartTypes()}
          </>
        );
      case SECTIONS.FORMATTING:
        return (
          <div className={classes.formatSection}>
            {!isArchitectCustomerDashboard ? (
              <div className={classes.formattingSubText}>
                This is how the user will initially see the template.
              </div>
            ) : null}
            <FormatConfig
              isArchitectCustomerDashboard={isArchitectCustomerDashboard}
              panel={panel}
            />
          </div>
        );
      case SECTIONS.FILTERING: {
        const dataset = datasets[panel.dataset_id];
        if (!dataset || !dataset.schema) return null;

        return (
          <PanelFilters
            dataset={dataset}
            fetchPanelData={(newFilters) => fetchPanelData({ newFilters })}
            filters={panel.filter_op?.instructions.filterClauses ?? []}
            isArchitectCustomerDashboard={isArchitectCustomerDashboard}
            panelId={panel.id}
          />
        );
      }
      case SECTIONS.CONFIGURATION: {
        const dataset = datasets[panel.dataset_id];
        if (!dataset || !dataset.schema) return null;

        return (
          <ChartConfig
            dataset={dataset}
            fetchPanelData={(newViz) => fetchPanelData({ newViz })}
            isArchitectCustomerDashboard={isArchitectCustomerDashboard}
            panelId={panel.id}
            visualizeOperation={panel.visualize_op}
          />
        );
      }
    }
  };

  const renderTabOptions = () => {
    if (configurability === CanvasConfigureType.STYLING) return null;
    return (
      <div className={classes.tabOptions}>
        {[CONFIG_TAB.DATA, CONFIG_TAB.STYLING].map((tab) => {
          return (
            <div
              className={cx(classes.configTab, { selectedTab: openTab === tab })}
              key={`tab-${tab}`}
              onClick={() => setOpenTab(tab)}>
              {tab}
            </div>
          );
        })}
      </div>
    );
  };

  const renderDeleteDataPanel = () => {
    return (
      <div className={classes.deleteWrapper}>
        <Button
          fillWidth
          icon="trash"
          onClick={() => {
            if (!isPreview) dispatch(deleteEUDItem());
          }}
          text="Remove"
        />
      </div>
    );
  };

  return (
    <>
      {isArchitectCustomerDashboard ? renderTabOptions() : null}
      <div
        className={classes.root}
        style={{ overflowY: isArchitectCustomerDashboard ? 'auto' : 'visible' }}>
        {sections.map((section) => {
          if (isArchitectCustomerDashboard) {
            if (
              (openTab === CONFIG_TAB.STYLING && section !== SECTIONS.FORMATTING) ||
              (openTab === CONFIG_TAB.DATA && section === SECTIONS.FORMATTING)
            )
              return null;
            if (openTab === CONFIG_TAB.STYLING && section === SECTIONS.FORMATTING) {
              return <div key="section-formatting">{renderSectionOptions(section)}</div>;
            }
          }

          const { title, defaultIsOpen } = getTitleAndDefaultOpen(
            section,
            isArchitectCustomerDashboard,
            isNew,
            panel.visualize_op,
            !datasets[panel.dataset_id],
          );

          return (
            <ConfigSection
              defaultIsOpen={defaultIsOpen}
              isArchitectCustomerDashboard={isArchitectCustomerDashboard}
              key={`section-${section}-${panel.id}`}
              title={title}>
              {renderSectionOptions(section)}
            </ConfigSection>
          );
        })}
      </div>
      {isArchitectCustomerDashboard ? renderDeleteDataPanel() : null}
    </>
  );
}

function getTitleAndDefaultOpen(
  section: SECTIONS,
  isArchitectCustomerDashboard: boolean,
  isNew: boolean,
  vizOp: VisualizeOperation,
  hasNoDataset: boolean,
): { title: string; defaultIsOpen: boolean } {
  let title: string = section;
  let defaultIsOpen = hasNoDataset ? false : isNew;

  switch (section) {
    case SECTIONS.DATA:
      if (!isArchitectCustomerDashboard) title = 'Select a dataset';
      if (hasNoDataset) defaultIsOpen = true;
      break;
    case SECTIONS.CHART_TYPE:
      if (!isArchitectCustomerDashboard) title = 'Select a chart type';
      break;
    case SECTIONS.CONFIGURATION:
      defaultIsOpen = !hasNoDataset && !isNew;
      if (!isArchitectCustomerDashboard) {
        switch (vizOp.operation_type) {
          case OPERATION_TYPES.VISUALIZE_TABLE:
            title = 'Select Columns';
            break;

          default:
            title = 'Configuration';
        }
      }
      break;
    case SECTIONS.FILTERING:
      if (!isArchitectCustomerDashboard) title = 'Add filters';
      defaultIsOpen = false;
      break;
    case SECTIONS.FORMATTING:
      if (!isArchitectCustomerDashboard) title = 'Adjust formatting';
      defaultIsOpen = false;
  }
  return { title, defaultIsOpen };
}
