import { keyBy } from 'utils/standard';
import PostgresIcon from './images/postgresql-icon.svg';
import MySQLIcon from './images/mysql-icon.svg';
import SnowflakeIcon from './images/snowflake-icon.svg';
import RedshiftIcon from './images/redshift-icon.svg';
import BigqueryIcon from './images/bigquery-icon.svg';
import MongoDB from './images/mongo-icon.svg';
import SqlServerIcon from './images/sqlserver-icon.svg';
import RocksetIcon from './images/rockset.png';
import AzureIcon from './images/azure.png';
import ClickhouseIcon from './images/clickhouse.png';
import DatabricksIcon from './images/databricks.png';
import AthenaIcon from './images/athena.png';

type IntegrationsData = {
  source_type: string;
  datasourceIconImg: string;
  credentialsLink?: string;
};

export const DefaultIcon = PostgresIcon;

export const integrationsInfo: { [id: string]: IntegrationsData } = {
  postgres: {
    source_type: 'postgres',
    datasourceIconImg: PostgresIcon,
    credentialsLink:
      'https://docs.explo.co/data-sources/connecting-to-your-database-or-data-warehouse#connecting-to-different-data-sources',
  },
  mysql: {
    source_type: 'mysql',
    datasourceIconImg: MySQLIcon,
    credentialsLink:
      'https://docs.explo.co/data-sources/connecting-to-your-database-or-data-warehouse#connecting-to-different-data-sources',
  },
  snowflake: {
    source_type: 'snowflake',
    datasourceIconImg: SnowflakeIcon,
    credentialsLink:
      'https://docs.explo.co/data-sources/connecting-to-your-database-or-data-warehouse#connecting-to-different-data-sources',
  },
  redshift: {
    source_type: 'redshift',
    datasourceIconImg: RedshiftIcon,
    credentialsLink:
      'https://docs.explo.co/data-sources/connecting-to-your-database-or-data-warehouse#finding-redshift-credentials',
  },
  bigquery: {
    source_type: 'big_query',
    datasourceIconImg: BigqueryIcon,
    credentialsLink:
      'https://docs.explo.co/data-sources/connecting-to-your-database-or-data-warehouse#finding-big-query-credentials',
  },
  mongodb: {
    source_type: 'mongodb',
    datasourceIconImg: MongoDB,
  },
  microsoft_sql_server: {
    source_type: 'microsoft_sql_server',
    datasourceIconImg: SqlServerIcon,
    credentialsLink:
      'https://docs.explo.co/data-sources/connecting-to-your-database-or-data-warehouse#connecting-to-different-data-sources',
  },
  rockset: {
    source_type: 'rockset',
    datasourceIconImg: RocksetIcon,
  },
  azure: {
    source_type: 'azure_sql_db',
    datasourceIconImg: AzureIcon,
    credentialsLink:
      'https://docs.explo.co/data-sources/connecting-to-your-database-or-data-warehouse#connecting-to-different-data-sources',
  },
  clickhouse: {
    source_type: 'clickhouse',
    datasourceIconImg: ClickhouseIcon,
    credentialsLink:
      'https://docs.explo.co/data-sources/connecting-to-your-database-or-data-warehouse#connecting-to-different-data-sources',
  },
  databricks: {
    source_type: 'databricks',
    datasourceIconImg: DatabricksIcon,
    credentialsLink:
      'https://docs.explo.co/data-sources/connecting-to-your-database-or-data-warehouse#connecting-to-different-data-sources',
  },
  athena: {
    source_type: 'athena',
    datasourceIconImg: AthenaIcon,
    credentialsLink:
      'https://docs.explo.co/data-sources/connecting-to-your-database-or-data-warehouse#connecting-to-different-data-sources',
  },
};

export const dataSourceByType = keyBy(Object.values(integrationsInfo), 'source_type');
