import { FC, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import { isSuccess } from 'remotedata';

import { EditorLeftColumn } from '../EditorLeftColumn';
import { sprinkles } from 'components/ds';

import { ReportBuilderWrapper } from 'pages/ReportBuilder';

const rootClass = sprinkles({ display: 'flex', flex: 1, overflow: 'hidden', height: 'fill' });

type Props = {
  embedId: string;
};

export const ReportBuilderDev: FC<Props> = ({ embedId }) => {
  const { selectedGroupId, customerGroups } = useSelector(
    (state: ReduxState) => ({
      selectedGroupId: state.customers.selectedGroupId,
      customerGroups: state.customers.groups,
    }),
    shallowEqual,
  );

  const customerToken = useMemo(() => {
    if (!isSuccess(customerGroups)) return;
    return customerGroups.data.find((group) => group.id === selectedGroupId)?.token;
  }, [selectedGroupId, customerGroups]);

  if (!customerToken) return <div>No token found or no selected customer</div>;

  return (
    <div className={rootClass}>
      <EditorLeftColumn />
      <ReportBuilderWrapper
        customerToken={customerToken}
        environment="production"
        reportBuilderEmbedId={embedId}
      />
    </div>
  );
};
