import { FC, forwardRef } from 'react';

import { Button } from 'components/ds';

export type Props = {
  /**
   * Text to display for link
   */
  children?: string;
  /**
   * Optional class name for style overrides
   */
  className?: string;
  /**
   * Optional test ID for selecting component in tests
   */
  'data-testid'?: string;
  /**
   * Whether the link should fill the width of its container
   */
  fillWidth?: boolean;
  /**
   * URL to link to
   */
  to: string;
  /**
   * Determines style of the link
   */
  type?: 'primary' | 'secondary';
};

export const Link: FC<Props> = forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>(
  ({ className, children, 'data-testid': testId, fillWidth, to, type, ...props }, ref) => {
    return (
      <Button
        {...props}
        linkIcon
        className={className}
        data-testid={testId}
        fillWidth={fillWidth}
        parentRef={ref}
        to={to}
        type={type === 'primary' ? 'secondary' : 'tertiary'}>
        {children}
      </Button>
    );
  },
);

Link.displayName = 'Link';
