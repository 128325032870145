import produce from 'immer';
import { useDispatch } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';

import EnrichColumnConfiguration from 'pages/dashboardPage/DataPanelConfigV2/FormatConfigTab/formatSections/TableColumnsConfig/EnrichColumnConfiguration';
import { Divider } from '@blueprintjs/core';
import { BooleanFormatConfig } from 'components/ColumnFormatConfigs/BooleanFormatConfig';
import { DateFormatConfig } from 'components/ColumnFormatConfigs/DateFormatConfig';
import { NumberFormatConfig } from 'components/ColumnFormatConfigs/NumberFormatConfig';
import { GradientConfiguration } from 'components/ColumnFormatConfigs/NumberFormatConfig/GradientConfiguration';
import { ProgressBarConfiguration } from 'components/ColumnFormatConfigs/NumberFormatConfig/ProgressBarConfiguration';
import { StringFormatConfig } from 'components/ColumnFormatConfigs/StringFormatConfig';
import { SortableList, SortableListItem } from 'components/SortableList/SortableList';
import { SortableExpandableCard } from 'components/ChartConfigs/SortableExpandableCard';
import InputWithBlurSave from 'pages/dataPanelEditorPage/inputWithBlurSave';
import DropdownSelect from 'shared/DropdownSelect';
import ColumnSortingConfig from '../../ColumnSortingConfig';
import { Switch } from 'components/ds';

import { Dataset } from 'actions/datasetActions';
import { isJoinConfigReady } from 'utils/variableUtils';
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { DatasetSchema, DatasetColumn, DatasetRow } from 'types/datasets';
import { BOOLEAN, DATE_TYPES, NUMBER_TYPES, STRING, STRING_FORMATS } from 'constants/dataConstants';
import {
  BooleanDisplayOptions,
  DateDisplayOptions,
  DefaultSortColumn,
  DisplayOptions,
  NumberDisplayOptions,
  OPERATION_TYPES,
  SchemaChange,
  SortOrder,
  StringDisplayOptions,
  VisualizePivotTableInstructions,
  VisualizeTableInstructions,
} from 'constants/types';
import { sortSchemaByOrderedColumnNames } from 'utils/general';

const useStyles = makeStyles((theme: Theme) => ({
  columnConfigRoot: {
    margin: theme.spacing(3),
  },
  configInput: {
    padding: `0px ${theme.spacing(3)}px`,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  section: {
    padding: theme.spacing(3),
  },
  divider: {
    margin: 0,
  },
  subHeader: {
    padding: `0px ${theme.spacing(3)}px`,
    color: theme.palette.ds.grey900,
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 600,
    marginBottom: theme.spacing(3),
  },
  subSection: {
    marginBottom: theme.spacing(5),
  },
  tooltip: {
    padding: `0px ${theme.spacing(3)}px`,
    marginBottom: -theme.spacing(2),
  },
}));

type Props = {
  instructions: VisualizeTableInstructions | VisualizePivotTableInstructions;
  originalSchema: DatasetSchema;
  schema: DatasetSchema;
  dashboardDatasets: Record<string, Dataset>;
  dataPanelData: DatasetRow[];
  visualizationType: OPERATION_TYPES;
};

/**
 * Configs for all of the columns for a data table
 */
export default function TableColumnsConfig({
  instructions,
  originalSchema,
  schema,
  dashboardDatasets,
  dataPanelData,
  visualizationType,
}: Props) {
  const classes = useStyles({});
  const dispatch = useDispatch();

  const orderedColumnNames = instructions.orderedColumnNames;
  const orderedSchema = sortSchemaByOrderedColumnNames(schema, orderedColumnNames);

  // this is a table only operation so it doesn't have to include pivot tables
  const updateInstructions = (
    instructions: VisualizeTableInstructions | VisualizePivotTableInstructions,
  ) => {
    dispatch(
      updateVisualizeOperation(
        instructions,
        visualizationType === OPERATION_TYPES.VISUALIZE_TABLE ||
          visualizationType === OPERATION_TYPES.VISUALIZE_REPORT_BUILDER
          ? OPERATION_TYPES.VISUALIZE_TABLE
          : OPERATION_TYPES.VISUALIZE_PIVOT_TABLE,
      ),
    );
  };

  const updateDefaultSort = (sortColumn: DefaultSortColumn) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.defaultSortedColumn = {
        ...draft.defaultSortedColumn,
        ...sortColumn,
      };
    });

    updateInstructions(newInstructions);
  };

  const updateFirstColumnTitle = (title: string) => {
    const newInstructions = produce(instructions as VisualizePivotTableInstructions, (draft) => {
      draft.firstColumnTitle = title;
    });

    updateInstructions(newInstructions);
  };

  const viewDefaultSortColumn = () => {
    const options = schema.map((col) => ({ id: col.name, name: col.friendly_name || col.name }));

    const order = instructions.defaultSortedColumn?.order ?? SortOrder.ASC;
    const selectedCol = instructions.defaultSortedColumn?.column;

    return (
      <ColumnSortingConfig
        label="Initial Column Sort"
        order={order}
        selectedItem={selectedCol ? options.find((col) => col.id === selectedCol) : undefined}
        sortOptions={options}
        updateDefaultSort={updateDefaultSort}
      />
    );
  };

  const renderPivotColumnsConfig = () => {
    const pivotInstructions = instructions as VisualizePivotTableInstructions;
    const firstColumnTitle = pivotInstructions?.firstColumnTitle;
    const originalFirstColumnTitle = pivotInstructions?.rowColumn?.column.name;

    const selectedStringFormat = pivotInstructions?.stringFormat?.format || STRING_FORMATS.DEFAULT;

    const updateStringFormat = (stringFormatUpdates?: {
      format?: STRING_FORMATS;
      replaceUnderscores?: boolean;
    }) => {
      const newInstructions = produce(pivotInstructions, (draft) => {
        draft.stringFormat = {
          ...pivotInstructions?.stringFormat,
          ...stringFormatUpdates,
        };
      });

      dispatch(updateVisualizeOperation(newInstructions, visualizationType));
    };
    return (
      <>
        <div className={classes.subHeader}>Column Names</div>
        <div className={classes.subSection}>
          <DropdownSelect
            btnMinimal
            fillWidth
            minimal
            containerClassName={classes.configInput}
            filterable={false}
            label="String Format"
            noSelectionText="Select a format"
            onChange={(item) => {
              updateStringFormat({
                format: item.id as STRING_FORMATS,
              });
            }}
            options={Object.values(STRING_FORMATS).map((formatOption) => ({
              id: formatOption,
              name: formatOption,
            }))}
            selectedItem={{ id: selectedStringFormat, name: selectedStringFormat }}
          />
          <Switch
            useCustomStyles
            className={classes.configInput}
            label="Remove Underscores"
            onChange={() => {
              updateStringFormat({
                replaceUnderscores: !pivotInstructions?.stringFormat?.replaceUnderscores,
              });
            }}
            switchOn={pivotInstructions?.stringFormat?.replaceUnderscores}
          />
          <InputWithBlurSave
            containerClassName={classes.configInput}
            initialValue={firstColumnTitle ?? originalFirstColumnTitle}
            label="First Column Name"
            onNewValueSubmitted={(newColName) => updateFirstColumnTitle(newColName)}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <div className={classes.subSection}>
        <Switch
          useCustomStyles
          className={classes.configInput}
          disabled={instructions.shouldVisuallyGroupByFirstColumn}
          label="Sorting"
          onChange={() => {
            const newInstructions = produce(instructions, (draft) => {
              draft.isColumnSortingDisabled = !draft.isColumnSortingDisabled;
              if (draft.isColumnSortingDisabled) {
                draft.defaultSortedColumn = undefined;
              }
            });

            updateInstructions(newInstructions);
          }}
          switchOn={!instructions.isColumnSortingDisabled}
        />
        {!instructions.isColumnSortingDisabled && viewDefaultSortColumn()}
        <SortableList
          getIdFromElem={(col) => col.name}
          onListUpdated={(newList) =>
            updateInstructions({
              ...instructions,
              orderedColumnNames: newList.map((col) => col.name),
            })
          }
          sortableItems={orderedSchema}>
          {orderedSchema.map((col) => (
            <SortableListItem key={col.name} sortId={col.name}>
              <TableColumnConfig
                column={col}
                dashboardDatasets={dashboardDatasets}
                dataPanelData={dataPanelData}
                instructions={instructions}
                originalSchema={originalSchema}
                updateInstructions={updateInstructions}
              />
            </SortableListItem>
          ))}
        </SortableList>
      </div>
      {visualizationType === OPERATION_TYPES.VISUALIZE_PIVOT_TABLE
        ? renderPivotColumnsConfig()
        : null}
    </>
  );
}

type TableColumnConfigProps = {
  column: DatasetColumn;
  instructions: VisualizeTableInstructions | VisualizePivotTableInstructions;
  updateInstructions: (
    instructions: VisualizeTableInstructions | VisualizePivotTableInstructions,
  ) => void;
  dashboardDatasets: Record<string, Dataset>;
  dataPanelData: DatasetRow[];
  originalSchema: DatasetSchema;
};

function TableColumnConfig({
  column,
  instructions,
  updateInstructions,
  dataPanelData,
  dashboardDatasets,
  originalSchema,
}: TableColumnConfigProps) {
  const classes = useStyles();
  const columnConfig = instructions.schemaDisplayOptions?.[column.name];

  const schemaChangeIndex =
    instructions.changeSchemaList?.findIndex((schemaChange) => schemaChange.col === column.name) ??
    -1;
  const schemaChange =
    schemaChangeIndex >= 0 ? instructions.changeSchemaList?.[schemaChangeIndex] : undefined;

  const columnDisplayName = column.friendly_name || column.name;

  const addFieldsToDisplayOptions = (newFields: DisplayOptions) => {
    const newInstructions = produce(instructions, (draft) => {
      if (!draft.schemaDisplayOptions) draft.schemaDisplayOptions = {};
      draft.schemaDisplayOptions[column.name] = {
        ...draft.schemaDisplayOptions[column.name],
        ...newFields,
      };
    });

    updateInstructions(newInstructions);
  };

  let columnToDisplay = column;
  if (columnConfig && isJoinConfigReady(columnConfig) && columnConfig.joinDisplayColumn) {
    columnToDisplay = columnConfig.joinDisplayColumn.column;
  }

  const updateChangeSchemaList = (updates: Partial<SchemaChange>) => {
    const newInstructions = produce(instructions, (draft) => {
      if (!draft.changeSchemaList) draft.changeSchemaList = [];

      if (schemaChangeIndex !== -1) {
        draft.changeSchemaList[schemaChangeIndex] = {
          ...draft.changeSchemaList[schemaChangeIndex],
          ...updates,
        };
      } else {
        draft.changeSchemaList.push({
          col: column.name,
          newColName: column.friendly_name,
          keepCol: true,
          hideCol: false,
          ...updates,
        });
      }
    });

    updateInstructions(newInstructions);
  };

  return (
    <SortableExpandableCard name={columnDisplayName}>
      <>
        <div className={classes.section}>
          <InputWithBlurSave
            initialValue={columnDisplayName}
            label={`Column title (${column.name})`}
            onNewValueSubmitted={(newColName) => {
              if (newColName.trim() === '') newColName = column.name;
              updateChangeSchemaList({ newColName });
            }}
          />
          <EnrichColumnConfiguration
            column={column}
            dashboardDatasets={dashboardDatasets}
            instructions={instructions}
            updateInstructions={updateInstructions}
          />
        </div>
        <Switch
          useCustomStyles
          className={classes.tooltip}
          label="Tooltip"
          onChange={() => updateChangeSchemaList({ showTooltip: !schemaChange?.showTooltip })}
          switchOn={schemaChange?.showTooltip}
        />
        {schemaChange?.showTooltip ? (
          <div className={classes.section}>
            <InputWithBlurSave
              initialValue={schemaChange?.tooltipText}
              label="Tooltip Text"
              onNewValueSubmitted={(newText) => updateChangeSchemaList({ tooltipText: newText })}
            />
          </div>
        ) : null}
        {(!columnConfig?.joinOn || isJoinConfigReady(columnConfig)) && (
          <>
            <div className={classes.section}>
              {columnToDisplay.type === BOOLEAN && (
                <BooleanFormatConfig
                  column={column}
                  displayOptions={columnConfig as BooleanDisplayOptions}
                  updateBooleanOptions={addFieldsToDisplayOptions}
                />
              )}
              {columnToDisplay.type === STRING && (
                <StringFormatConfig
                  column={column}
                  dataPanelData={dataPanelData}
                  displayOptions={columnConfig as StringDisplayOptions}
                  originalSchema={originalSchema}
                  updateStringOptions={addFieldsToDisplayOptions}
                />
              )}
              {DATE_TYPES.has(columnToDisplay.type) && (
                <DateFormatConfig
                  column={column}
                  displayOptions={columnConfig as DateDisplayOptions}
                  operationType={OPERATION_TYPES.VISUALIZE_TABLE}
                  updateDateOptions={addFieldsToDisplayOptions}
                />
              )}
              {NUMBER_TYPES.has(columnToDisplay.type) && (
                <NumberFormatConfig
                  column={column}
                  displayOptions={columnConfig as NumberDisplayOptions}
                  operationType={OPERATION_TYPES.VISUALIZE_TABLE}
                  updateNumberOptions={addFieldsToDisplayOptions}
                />
              )}
            </div>
            {NUMBER_TYPES.has(columnToDisplay.type) && (
              <>
                <Divider className={classes.divider} />
                <div className={classes.section}>
                  <ProgressBarConfiguration
                    displayOptions={columnConfig as NumberDisplayOptions}
                    operationType={OPERATION_TYPES.VISUALIZE_TABLE}
                    originalSchema={originalSchema}
                    updateNumberOptions={addFieldsToDisplayOptions}
                  />
                </div>
                <Divider className={classes.divider} />
                <div className={classes.section}>
                  <GradientConfiguration
                    displayOptions={columnConfig as NumberDisplayOptions}
                    updateNumberOptions={addFieldsToDisplayOptions}
                  />
                </div>
              </>
            )}
          </>
        )}
      </>
    </SortableExpandableCard>
  );
}
