import { defineAPIAction, defineAPIPostAction } from 'actions/actionUtils';
import { EndUser } from 'actions/teamActions';
import { ACTION } from './types';

type FetchEndUserListData = {
  end_users: EndUser[];
};

export const { actionFn: fetchEndUserList, successAction: fetchEndUserListSuccess } =
  defineAPIAction<FetchEndUserListData>(ACTION.FETCH_END_USER_LIST, 'end_users', '', 'GET');

export const { actionFn: deleteEndUser, successAction: deleteEndUserSuccess } = defineAPIAction(
  ACTION.DELETE_END_USER,
  'end_users',
  '',
  'DELETE',
);

type EditEndUserBody = {
  email: string;
  permissioned_dashboard_id: number | undefined;
};

export const { actionFn: editEndUser, successAction: editEndUserSuccess } = defineAPIPostAction<
  EditEndUserBody,
  { end_user: EndUser }
>(ACTION.EDIT_END_USER, 'end_users', 'update_end_user', 'POST');

type CreateEndUserBody = {
  email: string;
  permissioned_dashboard_id: number | undefined;
  //TODO JORDAN: can this be undefined??
  customer_id: number | undefined;
};

type CreateEndUserData = { new_end_user: EndUser };

export const { actionFn: createEndUser, successAction: createEndUserSuccess } = defineAPIPostAction<
  CreateEndUserBody,
  CreateEndUserData
>(ACTION.CREATE_END_USER, 'end_users', '', 'POST');
