import produce from 'immer';

import FormatSection from './FormatSection';
import SharedLegendConfigs from 'components/ChartConfigs/LegendConfigs';

import { sharedStyles } from './sharedStyles';
import { LegendFormat, V2TwoDimensionChartInstructions } from 'constants/types';
import { CanvasV2TwoDimensionChartTypes, FormatConfigProps } from './types';

type Props = FormatConfigProps<CanvasV2TwoDimensionChartTypes, V2TwoDimensionChartInstructions>;

export default function LegendSection({
  isArchitectCustomerDashboard,
  instructions,
  updateInstructions,
  operationType,
}: Props): JSX.Element | null {
  const classes = sharedStyles({ isArchitectCustomerDashboard });

  const legendFormat = instructions.legendFormat;

  const updateLegendFormat = (legendUpdates: LegendFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.legendFormat = {
        ...draft.legendFormat,
        ...legendUpdates,
      };
    });
    updateInstructions({ instructions: newInstructions, operationType });
  };

  return (
    <FormatSection
      isArchitectCustomerDashboard={isArchitectCustomerDashboard}
      onToggle={() => updateLegendFormat({ hideLegend: !legendFormat?.hideLegend })}
      title="Legend"
      toggleOn={!legendFormat?.hideLegend}>
      <SharedLegendConfigs
        isCanvas
        configInputClass={classes.configInput}
        instructions={instructions}
        operationType={operationType}
        updateLegendFormat={updateLegendFormat}
      />
    </FormatSection>
  );
}
