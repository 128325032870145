import cx from 'classnames';
import { keyBy } from 'utils/standard';
import { useMemo } from 'react';
import produce from 'immer';
import { useDispatch } from 'react-redux';

import DropdownSelect from 'shared/DropdownSelect';
import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';
import { Button, sprinkles } from 'components/ds';

import {
  Aggregation,
  GROUPED_STACKED_OPERATION_TYPES,
  OPERATION_TYPES,
  SelectedDropdownInputItem,
  SortAxis,
  SortOption,
  V2BoxPlotInstructions,
  V2ScatterPlotInstructions,
  V2TwoDimensionChartInstructions,
  XAxisFormat,
} from 'constants/types';
import { ASC_SORT_ICON, DEFAULT_SORT_ICON, DESC_SORT_ICON } from 'constants/iconConstants';
import { IconName } from '@blueprintjs/icons';
import { DatasetSchema, DatasetRow } from 'types/datasets';
import {
  getGroupOptions,
  resolveAggColDropped,
} from 'pages/dashboardPage/DataPanelConfigV2/DataConfigTab/vizConfigs/utils';
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import {
  sharedStyles,
  configInputClass,
} from 'pages/dashboardPage/DataPanelConfigV2/FormatConfigTab/formatSections/styles';
import { SortableCategoriesConfig } from './SortableCategoriesConfig';

type Instructions =
  | V2TwoDimensionChartInstructions
  | V2BoxPlotInstructions
  | V2ScatterPlotInstructions;

type Props = {
  chartSchema: DatasetSchema;
  instructions: Instructions;
  dataPanelData: DatasetRow[];
  datasetSchema?: DatasetSchema;
  isHorizontal?: boolean;
  visualizationType: OPERATION_TYPES;
};

export const SortConfigs = ({
  chartSchema,
  instructions,
  dataPanelData,
  datasetSchema,
  isHorizontal,
  visualizationType,
}: Props) => {
  const dispatch = useDispatch();
  const classes = sharedStyles();

  const { xAxisFormat } = instructions;
  const datasetSchemaByName = useMemo(() => keyBy(datasetSchema, 'name'), [datasetSchema]);
  const selectedSort = xAxisFormat?.sortOption;

  const selectedSortAxis = xAxisFormat?.sortAxis || SortAxis.NONE;
  const selectedSortColumn = xAxisFormat?.sortColumns?.[0];
  const aggGroupOptions: SelectedDropdownInputItem[] = useMemo(() => {
    const sortGroupOptions = selectedSortColumn ? getGroupOptions(selectedSortColumn) : [];
    return (
      sortGroupOptions[0]?.options.map((opt) => ({
        id: opt.id,
        name: opt.name,
      })) ?? []
    );
  }, [selectedSortColumn]);

  const sortableCategories = useMemo(() => {
    const xAxisColIndex = GROUPED_STACKED_OPERATION_TYPES.includes(visualizationType) ? 1 : 0;

    if (!datasetSchema || !chartSchema[xAxisColIndex]) return new Set<string>();
    const xAxisColName = chartSchema[xAxisColIndex].name;

    const categories = new Set<string>();
    dataPanelData.forEach((row) => categories.add(String(row[xAxisColName])));

    return categories;
  }, [dataPanelData, datasetSchema, visualizationType, chartSchema]);

  const updateXAxisFormat = (xAxisUpdates: XAxisFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.xAxisFormat = {
        ...draft.xAxisFormat,
        ...xAxisUpdates,
      };
    });

    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  const sortAxisOptions = useMemo(
    () =>
      Object.values(SortAxis).map((sortAxis) => {
        let text = 'Default';
        if (sortAxis === SortAxis.AGG_AXIS) text = isHorizontal ? 'X-Axis' : 'Y-Axis';
        else if (sortAxis === SortAxis.CAT_AXIS) text = isHorizontal ? 'Y-Axis' : 'X-Axis';
        else if (sortAxis === SortAxis.COLUMN) text = 'Column';
        else if (sortAxis === SortAxis.MANUAL) text = 'Manual';

        return {
          id: sortAxis,
          name: text,
        };
      }),
    [isHorizontal],
  );

  return (
    <div className={classes.root}>
      <DropdownSelect
        btnMinimal
        fillWidth
        ignoreCustomStyles
        minimal
        containerClassName={cx(sprinkles({ width: 'fill' }), configInputClass)}
        filterable={false}
        label="Sort Type"
        noSelectionText="Select an Option"
        onChange={(item) => updateXAxisFormat({ sortAxis: item.id as SortAxis })}
        options={sortAxisOptions}
        selectedItem={sortAxisOptions.find((option) => option.id === selectedSortAxis)}
      />

      {selectedSortAxis === SortAxis.COLUMN ? (
        <div
          className={sprinkles({
            flexItems: 'alignCenter',
            paddingX: 'sp1.5',
            width: 'fill',
            marginBottom: 'sp1',
          })}>
          <DropdownSelect
            btnMinimal
            fillWidth
            ignoreCustomStyles
            minimal
            usePortal
            containerClassName={sprinkles({ width: 'fill' })}
            filterable={false}
            label="Sort Column"
            noSelectionText="Select a column"
            onChange={(item) => {
              const col = datasetSchemaByName[item.id];
              updateXAxisFormat({ sortColumns: resolveAggColDropped(col) });
            }}
            options={
              datasetSchema?.map((col) => ({
                id: col.name,
                name: col.name,
              })) ?? []
            }
            selectedItem={
              selectedSortColumn
                ? {
                    id: selectedSortColumn.column.name || '',
                    name: selectedSortColumn.column.name || '',
                  }
                : undefined
            }
          />
          <DropdownSelect
            btnMinimal
            fillWidth
            ignoreCustomStyles
            minimal
            usePortal
            containerClassName={sprinkles({ marginLeft: 'sp1' })}
            filterable={false}
            label="Aggregation"
            noSelectionText="Select an Aggregation"
            onChange={(item) => {
              if (!selectedSortColumn || selectedSortColumn.agg.id === item.id) return;
              updateXAxisFormat({
                sortColumns: [{ ...selectedSortColumn, agg: { id: item.id as Aggregation } }],
              });
            }}
            options={aggGroupOptions}
            selectedItem={aggGroupOptions.find((opt) => opt.id === selectedSortColumn?.agg.id)}
          />
        </div>
      ) : null}
      {selectedSortAxis === SortAxis.MANUAL ? (
        <div
          className={sprinkles({
            width: 'fill',
            marginBottom: 'sp1',
          })}>
          <div className={sprinkles({ paddingX: 'sp1.5', width: 'fill' })}>
            <Button
              fillWidth
              icon="plus"
              onClick={() =>
                updateXAxisFormat({
                  sortManualCategoriesOrder: [
                    {
                      category: 'New Stage',
                      displayName: 'New Stage',
                    },
                    ...(instructions.xAxisFormat?.sortManualCategoriesOrder || []),
                  ],
                })
              }
              type="secondary">
              Add Stage
            </Button>
          </div>
          <SortableCategoriesConfig
            orderConfig={instructions.xAxisFormat?.sortManualCategoriesOrder}
            updateOrder={(newOrder) => updateXAxisFormat({ sortManualCategoriesOrder: newOrder })}
            visibleCategories={sortableCategories}
          />
        </div>
      ) : null}
      {selectedSortAxis !== SortAxis.NONE && selectedSortAxis !== SortAxis.MANUAL ? (
        <ToggleButtonGroup fillWidth className={configInputClass} label="Sort Direction">
          {Object.values(SortOption).map((sortOption) => {
            let icon: IconName | JSX.Element = DEFAULT_SORT_ICON;
            const isNumericalSort =
              selectedSortAxis === SortAxis.AGG_AXIS || selectedSortAxis === SortAxis.COLUMN;
            if (sortOption === SortOption.AXIS_ASC) {
              icon = isNumericalSort ? ASC_SORT_ICON : 'sort-alphabetical';
            } else if (sortOption === SortOption.AXIS_DESC) {
              icon = isNumericalSort ? DESC_SORT_ICON : 'sort-alphabetical-desc';
            }
            // we don't want to display the default sort option here
            else return null;

            return (
              <ToggleButton
                active={sortOption === selectedSort}
                icon={icon}
                key={sortOption}
                onClick={() => updateXAxisFormat({ sortOption })}
              />
            );
          })}
        </ToggleButtonGroup>
      ) : null}
    </div>
  );
};
