import FormatSection from './FormatSection';
import SharedYAxisConfigs from 'components/ChartConfigs/YAxisConfigs';

import { sharedStyles } from './sharedStyles';
import { CanvasV2TwoDimensionChartTypes, FormatConfigProps } from './types';
import { V2TwoDimensionChartInstructions, YAxisFormat } from 'constants/types';
import {
  getYAxisFormat,
  updateYAxisFormat,
} from 'pages/dashboardPage/charts/utils/multiYAxisUtils';

type Props = FormatConfigProps<CanvasV2TwoDimensionChartTypes, V2TwoDimensionChartInstructions> & {
  isHorizontal?: boolean;
};

export default function YAxisSection({
  isArchitectCustomerDashboard,
  isHorizontal,
  instructions,
  operationType,
  updateInstructions,
}: Props): JSX.Element {
  const classes = sharedStyles({ isArchitectCustomerDashboard });

  const yAxisFormat = getYAxisFormat(undefined, instructions, operationType);

  const updateXAxisFormat = (yAxisUpdates: YAxisFormat) => {
    const newYAxisFormat = { ...yAxisFormat, ...yAxisUpdates };

    updateInstructions({
      instructions: updateYAxisFormat(
        newYAxisFormat,
        instructions,
        operationType,
      ) as V2TwoDimensionChartInstructions,
      operationType,
    });
  };

  return (
    <FormatSection
      isArchitectCustomerDashboard={isArchitectCustomerDashboard}
      title={isHorizontal ? 'X-Axis' : 'Y-Axis'}>
      <SharedYAxisConfigs
        isCanvas
        configInputClass={classes.configInput}
        updateAxisFormat={updateXAxisFormat}
        yAxisFormat={yAxisFormat}
      />
    </FormatSection>
  );
}
