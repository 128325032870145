import { useState, FC } from 'react';
import cx from 'classnames';

import { Button, IconButton, sprinkles } from 'components/ds';
import * as styles from './styles.css';

type Props = {
  onDelete: () => void;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  tooltipText?: string;
  fillWidth?: boolean;
};

export const DeleteConfirmationButton: FC<Props> = ({
  onDelete,
  className,
  loading,
  disabled,
  tooltipText,
  fillWidth,
}) => {
  const [isDeleteToggled, setIsDeleteToggled] = useState<boolean>(false);

  return (
    <div className={cx(sprinkles({ flexItems: 'center' }), className)} style={{ minWidth: 72 }}>
      {isDeleteToggled && !loading ? (
        <div className={sprinkles({ gap: 'sp1', flexItems: 'center', width: 'fill' })}>
          <IconButton
            className={fillWidth ? styles.iconButton : undefined}
            name="cross"
            onClick={() => setIsDeleteToggled(false)}
            type="secondary"
          />
          <IconButton
            className={fillWidth ? styles.iconButton : undefined}
            name="trash"
            onClick={onDelete}
            type="destructive"
          />
        </div>
      ) : (
        <Button
          disabled={disabled}
          fillWidth={fillWidth}
          loading={loading}
          onClick={() => setIsDeleteToggled(true)}
          tooltipProps={tooltipText ? { text: tooltipText } : undefined}
          type="destructive">
          Delete
        </Button>
      )}
    </div>
  );
};
