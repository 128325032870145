import { useDispatch } from 'react-redux';
import produce from 'immer';

import SettingHeader from '../SettingHeader';
import KPIAggregation from '../KPIAggregation';
import DroppableColumnSection from './droppable/DroppableColumnSection';

import { OPERATION_TYPES, V2KPIChartInstructions } from 'constants/types';
import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { DatasetSchema } from 'types/datasets';
import { DATE_TYPES } from 'constants/dataConstants';
import { PIVOT_AGG_TYPES } from 'types/dateRangeTypes';

type Props = {
  instructions: V2KPIChartInstructions;
  chartType: OPERATION_TYPES;
  loading?: boolean;
  schema: DatasetSchema;
};

export default function KPIVizConfig({ instructions, chartType, loading, schema }: Props) {
  const dispatch = useDispatch();

  return (
    <div>
      <KPIAggregation
        chartType={chartType}
        instructions={instructions}
        loading={loading}
        schema={schema}
      />
      {instructions.trendColumn && (
        <>
          <SettingHeader name="Trend" />
          <DroppableColumnSection
            required
            columns={instructions.trendColumn ? [instructions.trendColumn] : []}
            maxCols={1}
            onColAdded={(col) => {
              if (DATE_TYPES.has(col.type)) {
                const newInstructions = produce(instructions, (draft) => {
                  const agg = PIVOT_AGG_TYPES.DATE_MONTH;
                  draft.trendColumn = { column: col, bucket: { id: agg.id, name: agg.name } };
                });
                dispatch(updateVisualizeOperation(newInstructions, chartType));
              }
            }}
            onColOptionChanged={(option) => {
              const newInstructions = produce(instructions, (draft) => {
                if (draft.trendColumn) draft.trendColumn.bucket = option;
              });
              dispatch(updateVisualizeOperation(newInstructions, chartType));
            }}
            onRemoveCol={() => {
              const newInstructions = produce(instructions, (draft) => {
                draft.trendColumn = undefined;
              });
              dispatch(updateVisualizeOperation(newInstructions, chartType));
            }}
            schema={schema}
          />
        </>
      )}
    </div>
  );
}
