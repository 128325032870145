import { useEffect, useRef } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Button from 'shared/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteCustomFont,
  fetchCustomFonts,
  uploadCustomFontFile,
} from 'actions/customFontsActions';
import { ReduxState } from 'reducers/rootReducer';
import { isCustomFontsDisabledForPlan } from 'utils/paymentPlanUtils';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(4),
  },
  title: {
    fontWeight: 600,
    fontSize: 20,
  },
  noFonts: {
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 400,
    fontSize: 16,
  },
  customFont: {
    height: 40,
    padding: `0px 12px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: '#EBF3FE',
    },
  },
  fontName: {
    fontSize: 16,
    fontWeight: 400,
  },
  removeFont: {
    fontWeight: 500,
    fontSize: 14,
    color: '#0069ED',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export default function SettingsCustomFontsSection() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const inputFile = useRef<HTMLInputElement | null>(null);

  const fonts = useSelector((state: ReduxState) => state.customFonts.customFonts);
  const paymentPlan = useSelector((state: ReduxState) => state.teamData.data?.payment_plan);

  useEffect(() => {
    if (!fonts) dispatch(fetchCustomFonts());
  }, [fonts, dispatch]);

  const handleFile = (files: FileList | null) => {
    if (!files || files.length === 0) return;

    const fileReader = new FileReader();
    fileReader.addEventListener('load', () => {
      dispatch(
        uploadCustomFontFile({ postData: { file: fileReader.result, fileName: files[0].name } }),
      );
    });

    fileReader.readAsDataURL(files[0]);
  };

  const deleteFont = (font: string) => {
    dispatch(deleteCustomFont({ postData: { fileName: font } }));
  };

  const renderCustomFonts = (fonts: string[]) => {
    if (fonts.length === 0) {
      return <div className={classes.noFonts}>You haven&rsquo;t uploaded any custom fonts.</div>;
    }
    return fonts.map((font) => {
      return (
        <div className={classes.customFont} key={font}>
          <div className={classes.fontName}>{font}</div>
          <div className={classes.removeFont} onClick={() => deleteFont(font)}>
            Remove
          </div>
        </div>
      );
    });
  };

  if (!fonts) return <></>;
  const disableCustomFonts = isCustomFontsDisabledForPlan(paymentPlan);

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.title}>Custom Fonts</div>
        <Button
          disabled={disableCustomFonts}
          onClick={() => inputFile.current?.click()}
          text="Upload a Font"
          tooltipText={disableCustomFonts ? 'Upgrade your plan to enable custom fonts.' : undefined}
          type="primary"
        />
      </div>
      {renderCustomFonts(fonts)}
      <input
        accept=".otf,.woff,.ttf,.woff2"
        id="file"
        onChange={(event) => handleFile(event.target.files)}
        ref={inputFile}
        style={{ display: 'none' }}
        type="file"
      />
    </div>
  );
}
