import { FC } from 'react';
import { map } from 'utils/standard';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Checkbox } from '@blueprintjs/core';
import cx from 'classnames';

import FullPageContentContainer from 'shared/FullPageContentContainer';
import InputWithTag from 'shared/InputWithTag/InputWithTag';
import DropdownSelect from 'shared/DropdownSelect';
import ConnectStepByStep from '../ConnectStepByStep';
import ConnectFAQs from '../ConnectFAQs';
import { sprinkles } from 'components/ds';

import { ConnectDataSourceStep } from '../constants';
import { DBConnectionConfig } from '../types';
import { DataSource, ParentSchema } from 'actions/dataSourceActions';
import { AccessGroup } from 'actions/teamActions';
import { getStatusInfo } from '../utils';

const useStyles = makeStyles((theme: Theme) => ({
  inputRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(4),
  },
  leftInput: {
    width: '100%',
    marginRight: theme.spacing(3),
  },
  rightInput: {
    width: '100%',
  },
  checkbox: {
    margin: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px ${theme.spacing(1)}px !important`,
  },
  dataSourceRow: {
    display: 'flex',
    fontSize: 14,
    color: theme.palette.ds.grey800,
    '&:hover': {
      backgroundColor: theme.palette.ds.lightBlue,
    },
  },
  accessGroupHeader: {
    color: theme.palette.ds.grey900,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '19px',
    marginBottom: theme.spacing(4),
  },
  description: {
    color: theme.palette.ds.grey800,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '17px',
    marginBottom: theme.spacing(4),
  },
}));

type Props = {
  config: DBConnectionConfig;
  updateConfig: (newconfig: DBConnectionConfig) => void;
  onNextClicked: () => void;
  parentSchemas?: ParentSchema[];
  selectedSchema?: ParentSchema;
  setSelectedSchema?: (schema: ParentSchema, isNew?: boolean) => void;
  existingDataSources: DataSource[];
  accessGroups?: AccessGroup[];
  selectedAccessGroupIds: number[];
  setSelectedAccessGroupIds: (accessGroupIds: number[]) => void;
  placeholderName?: string;
  placeholderProvidedId?: string;
  headerClassName?: string;
  isEditing?: boolean;
};

const GettingStarted = ({
  config,
  updateConfig,
  onNextClicked,
  parentSchemas,
  selectedSchema,
  setSelectedSchema,
  existingDataSources,
  accessGroups,
  selectedAccessGroupIds,
  setSelectedAccessGroupIds,
  placeholderName,
  placeholderProvidedId,
  headerClassName,
  isEditing,
}: Props) => {
  return (
    <FullPageContentContainer
      bodyContent={
        <GettingStartedBody
          accessGroups={accessGroups}
          config={config}
          existingDataSources={existingDataSources}
          headerClassName={headerClassName}
          isEditing={isEditing}
          parentSchemas={parentSchemas}
          placeholderName={placeholderName}
          placeholderProvidedId={placeholderProvidedId}
          selectedAccessGroupIds={selectedAccessGroupIds}
          selectedSchema={selectedSchema}
          setSelectedAccessGroupIds={setSelectedAccessGroupIds}
          setSelectedSchema={setSelectedSchema}
          updateConfig={updateConfig}
        />
      }
      headerSubtitle="Choose how you'd like to reference your database in Explo. You can change this later on"
      headerTitle="Getting started"
      leftSideBarBottomContent={<ConnectFAQs />}
      leftSideBarTopContent={
        <ConnectStepByStep currentStep={ConnectDataSourceStep.GETTING_STARTED} />
      }
      primaryActionConfig={{
        text: 'Next',
        disabled:
          !config.name ||
          !config.providedId ||
          !selectedSchema ||
          !!config.providedIdError ||
          selectedAccessGroupIds.length === 0,
        onClick: onNextClicked,
      }}
    />
  );
};

type BodyProps = Omit<Props, 'onNextClicked'>;

export const GettingStartedBody: FC<BodyProps> = ({
  config,
  updateConfig,
  parentSchemas,
  selectedSchema,
  setSelectedSchema,
  existingDataSources,
  accessGroups,
  selectedAccessGroupIds,
  setSelectedAccessGroupIds,
  placeholderName,
  placeholderProvidedId,
  headerClassName,
  isEditing,
}) => {
  const classes = useStyles();

  const existingDSProvidedIds = new Set(map(existingDataSources, 'provided_id'));

  const displayName = config.name === undefined ? placeholderName ?? '' : config.name;

  const displayProvidedId =
    config.providedId === undefined ? placeholderProvidedId ?? '' : config.providedId;

  return (
    <div>
      <div className={classes.inputRow}>
        <InputWithTag
          className={classes.leftInput}
          data-testid="connect-datasource-database-nickname"
          helpText="This is how we’ll refer to your database in Explo."
          label="Nickname"
          onChange={(newName) => {
            if (!isEditing) {
              const oldGeneratedId = generateProvidedId(config.name ?? '');
              const providedId = config.providedId ?? '';
              const newProvidedId =
                oldGeneratedId === providedId ? generateProvidedId(newName) : providedId;

              updateConfig({
                ...config,
                name: newName,
                providedId: newProvidedId,
                providedIdError:
                  existingDSProvidedIds.has(newProvidedId) &&
                  placeholderProvidedId !== newProvidedId
                    ? 'An existing data source has this ID'
                    : undefined,
              });
            } else {
              updateConfig({ ...config, name: newName });
            }
          }}
          placeholder={!isEditing ? 'Explo DB' : ''}
          statusInfo={getStatusInfo(
            config.name === undefined && placeholderName === undefined,
            displayName.length > 0,
          )}
          value={displayName}
        />
        <InputWithTag
          className={classes.rightInput}
          helpText="This is how you’ll reference the database when setting up user groups."
          label="ID"
          onChange={(value) => {
            updateConfig({
              ...config,
              providedId: value,
              providedIdError:
                existingDSProvidedIds.has(value) && placeholderProvidedId !== value
                  ? 'An existing data source has this provided ID'
                  : undefined,
            });
          }}
          placeholder={!isEditing ? 'explo-db' : ''}
          statusInfo={getStatusInfo(
            config.providedId === undefined &&
              (placeholderProvidedId === undefined || placeholderProvidedId === null),
            displayProvidedId.length > 0,
            config.providedIdError,
          )}
          value={displayProvidedId}
        />
      </div>
      {setSelectedSchema && parentSchemas?.length ? (
        <div className={classes.inputRow}>
          <DropdownSelect
            fillWidth
            minimal
            usePortal
            containerClassName={classes.leftInput}
            createItemPlaceholderText="Enter new schema name"
            createItemText="Create a new schema"
            dataTestid="connect-datasource-dropdown-select-schema"
            filterable={false}
            label="Schema"
            labelHelpText="All data sources in a schema should have the same underlying database tables"
            noSelectionText="Select a schema"
            onChange={(item) => {
              const itemId = parseInt(item.id);
              const selectedSchema = parentSchemas?.find((schema) => schema.id === itemId);
              if (selectedSchema) setSelectedSchema(selectedSchema);
            }}
            onCreateItem={(newSchemaName) => {
              if (newSchemaName) {
                setSelectedSchema({ name: newSchemaName, id: -1, team_id: -1 }, true);
              }
            }}
            options={parentSchemas.map((schema) => ({
              id: schema.id.toString(),
              name: schema.name,
            }))}
            selectedItem={
              selectedSchema && {
                id: selectedSchema.id.toString(),
                name: selectedSchema.name,
              }
            }
          />
        </div>
      ) : null}
      {accessGroups && accessGroups.length > 1 ? (
        <>
          <div
            className={
              isEditing
                ? cx(sprinkles({ marginBottom: 'sp3', marginTop: 'sp7' }), headerClassName)
                : classes.accessGroupHeader
            }>
            Set Data Visibility
          </div>
          <div className={classes.description}>
            {selectedSchema?.id === -1
              ? "Since this is a new schema, this database will become the default data source for any visibility groups it's part of. Defaults can be changed in the manage schemas modal in the data sources page."
              : 'This data source will become part of the selected visibility groups.'}
          </div>
          {accessGroups.map((accessGroup) => {
            const isChecked = selectedAccessGroupIds.includes(accessGroup.id);
            return (
              <div className={classes.dataSourceRow} key={accessGroup.id}>
                <Checkbox
                  checked={isChecked}
                  className={classes.checkbox}
                  label={accessGroup.name}
                  onChange={() => {
                    const newAccessGroupIds = isChecked
                      ? selectedAccessGroupIds.filter((id) => id !== accessGroup.id)
                      : [...selectedAccessGroupIds, accessGroup.id];
                    setSelectedAccessGroupIds(newAccessGroupIds);
                  }}
                />
              </div>
            );
          })}
        </>
      ) : null}
    </div>
  );
};

const generateProvidedId = (name: string) => name.toLowerCase().replace(/(\s+)/g, '-');

export default GettingStarted;
