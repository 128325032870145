import { FC } from 'react';

import { ConfigSection } from '../ConfigSection';
import * as sharedStyles from '../index.css';

type Props = {
  children: (JSX.Element | null)[];
  sectionText: string;
};

export const DatasetColumnFormat: FC<Props> = ({ sectionText, children }) => {
  return (
    <ConfigSection title="Adjust formatting">
      <div className={sharedStyles.configSection}>
        <p className={sharedStyles.sectionText}>{sectionText}</p>
        {children}
      </div>
    </ConfigSection>
  );
};
export { DatasetColumnFormatItem } from './item';
