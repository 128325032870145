import { makeStyles, Theme } from '@material-ui/core';

import DropdownSelect from 'shared/DropdownSelect';
import ToggleButtonGroup, { ToggleButton } from 'shared/ToggleButtonGroup';

import { EMAIL_FREQUENCY } from 'constants/types';

const useStyles = makeStyles((theme: Theme) => ({
  dayContainer: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'space-between',
    gap: `${theme.spacing(2)}px`,
    paddingTop: `${theme.spacing(2)}px`,
  },
  daySelect: {
    flex: '1 1 0',
  },
  weekdayToggle: {
    // overrides the default button padding to fix the height
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
}));

type Props = {
  frequency?: EMAIL_FREQUENCY;
  dayOfWeek?: number;
  weekOfMonth?: number;
  onSetDayOfWeek: (dayOfWeek: number) => void;
  onSetWeekOfMonth: (weekOfMonth: number) => void;
};

const WEEKS_OF_MONTH = ['First', 'Second', 'Third', 'Fourth', 'Last'];
const DAYS_OF_WEEK = ['Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'];

export default function EmailDaySelection({
  frequency,
  dayOfWeek,
  weekOfMonth,
  onSetDayOfWeek,
  onSetWeekOfMonth,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <div className={classes.dayContainer}>
        {frequency === EMAIL_FREQUENCY.MONTHLY && (
          <div className={classes.daySelect}>
            <DropdownSelect
              fillWidth
              minimal
              showIcon
              filterable={false}
              noSelectionText="First"
              onChange={(item) => onSetWeekOfMonth(Number(item.id))}
              options={WEEKS_OF_MONTH.map((week) => ({
                id: String(WEEKS_OF_MONTH.indexOf(week)),
                name: week,
              }))}
              selectedItem={
                weekOfMonth !== undefined
                  ? {
                      id: String(weekOfMonth),
                      name: WEEKS_OF_MONTH[weekOfMonth],
                    }
                  : undefined
              }
            />
          </div>
        )}
        <ToggleButtonGroup fillWidth className={classes.daySelect}>
          {DAYS_OF_WEEK.map((weekday, index) => {
            return (
              <ToggleButton
                active={DAYS_OF_WEEK[dayOfWeek ?? -1] === weekday}
                className={classes.weekdayToggle}
                key={weekday}
                onClick={() => {
                  onSetDayOfWeek(index);
                }}
                text={weekday}
              />
            );
          })}
        </ToggleButtonGroup>
      </div>
    </>
  );
}
