import cx from 'classnames';
import { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { IconName } from '@blueprintjs/core';
import { sharedStyles } from './sharedDroppedStyles';
import { IconButton, sprinkles } from 'components/ds';

import Button from 'shared/Button';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 32,
    border: `1px solid ${theme.palette.ds.lightBlue}`,
  },
  excludedName: {
    fontWeight: 600,
    color: '#182026',
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  excludedIconBtn: {
    // Must be important to override white background when popover options open
    backgroundColor: `${theme.palette.ds.grey200} !important`,
    color: theme.palette.ds.black,
    '&.bp3-button': {
      width: 24,
      height: 24,
    },
    '&.bp3-button,.bp3-icon': {
      color: theme.palette.ds.black,
    },
  },
  rightDivider: {
    borderRadius: `0px !important`,
    borderRight: `1px solid ${theme.palette.ds.pressed.lightBlue}`,
  },
}));

type Props = {
  columnName?: string;
  icon: IconName;
  onIconClick?: () => void;
  isHidden?: boolean; // If true or false, show eye icon and lets users toggle visibility
  onToggleHide?: () => void;
};

export const DroppedColumn: FC<Props> = ({
  columnName,
  icon,
  isHidden,
  onIconClick,
  onToggleHide,
}) => {
  const classes = useStyles();
  const sharedClasses = sharedStyles();
  const isExcludedColumn = icon === 'plus';
  return (
    <div
      className={cx(
        classes.root,
        sprinkles({
          backgroundColor: isExcludedColumn ? 'gray2' : 'lightBlue',
          paddingLeft: isExcludedColumn ? 'sp1' : undefined,
          paddingRight: 'sp.5',
          borderRadius: 3,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 'sp.5',
        }),
      )}>
      <div className={isExcludedColumn ? classes.excludedName : sharedClasses.name}>
        {columnName}
      </div>
      {onToggleHide && (
        <IconButton
          className={sharedClasses.iconBtn}
          name={isHidden ? 'eye-closed' : 'eye-open'}
          onClick={onToggleHide}
        />
      )}
      <Button
        compact
        minimal
        className={isExcludedColumn ? classes.excludedIconBtn : sharedClasses.iconBtn}
        icon={icon}
        onClick={() => onIconClick?.()}
      />
    </div>
  );
};
