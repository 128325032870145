import { useDispatch } from 'react-redux';

import TitleSection from './TitleSection';
import DataLabelsSection from './DataLabelsSection';
import LegendSection from './LegendSection';
import XAxisSection from './XAxisSection';
import YAxisSection from './YAxisSection';
import LineChartSection from './LineChartSection';
import AreaChartSection from './AreaChartSection';
import KPIGeneralSection from './KPIGeneralSection';
import KPIValueSection from './KPIValueSection';
import ColorSection from './ColorSection';
import PieChartSection from './PieChartSection';
import { FunnelChartSection } from './FunnelChartSection';

import { OPERATION_TYPES } from 'constants/types';
import { EndUserDataPanel } from 'actions/architectCustomerDashboardConfigActions';
import { CanvasTemplate } from 'actions/canvasConfigActions';
import {
  updateEUDataPanelInstructions,
  UpdateInstructionsPayload,
  updateCanvasTemplateInstructions,
} from 'actions/updateInstructionsActions';

type Props = {
  isArchitectCustomerDashboard: boolean;
  panel: CanvasTemplate | EndUserDataPanel;
};

export default function FormatConfig({ isArchitectCustomerDashboard, panel }: Props): JSX.Element {
  const dispatch = useDispatch();
  const opType = panel.visualize_op.operation_type;
  const instructions = panel.visualize_op.instructions;

  const updateInstructions = (payload: UpdateInstructionsPayload) => {
    dispatch(
      isArchitectCustomerDashboard
        ? updateEUDataPanelInstructions(payload)
        : updateCanvasTemplateInstructions({ update: payload, templateId: panel.id }),
    );
  };

  const sharedProps = { isArchitectCustomerDashboard, updateInstructions };

  const renderTitleSection = () => {
    const titleProps = {
      ...sharedProps,
      options: panel.visualize_op.generalFormatOptions ?? {},
      operationType: opType,
    };
    switch (opType) {
      case OPERATION_TYPES.VISUALIZE_TABLE:
        return <TitleSection {...titleProps} instructions={instructions.VISUALIZE_TABLE} />;
      case OPERATION_TYPES.VISUALIZE_NUMBER_V2:
        return <TitleSection {...titleProps} instructions={instructions.V2_KPI ?? {}} />;
      default:
        return (
          <TitleSection {...titleProps} instructions={instructions.V2_TWO_DIMENSION_CHART ?? {}} />
        );
    }
  };

  const renderDataLabelSection = () => {
    switch (opType) {
      case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_LINE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_AREA_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_PIE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2:
        return (
          <DataLabelsSection
            {...sharedProps}
            instructions={instructions.V2_TWO_DIMENSION_CHART ?? {}}
            operationType={opType}
          />
        );

      default:
        return null;
    }
  };

  const renderLegendSection = () => {
    switch (opType) {
      case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_LINE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_AREA_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_PIE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2:
        return (
          <LegendSection
            {...sharedProps}
            instructions={instructions.V2_TWO_DIMENSION_CHART ?? {}}
            operationType={opType}
          />
        );
      default:
        return null;
    }
  };

  const renderXAxisSection = () => {
    switch (opType) {
      case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_LINE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_AREA_CHART_V2:
        return (
          <XAxisSection
            {...sharedProps}
            instructions={instructions.V2_TWO_DIMENSION_CHART ?? {}}
            operationType={opType}
          />
        );
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2:
        return (
          <YAxisSection
            {...sharedProps}
            isHorizontal
            instructions={instructions.V2_TWO_DIMENSION_CHART ?? {}}
            operationType={opType}
          />
        );
      default:
        return null;
    }
  };

  const renderYAxisSection = () => {
    switch (opType) {
      case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_LINE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_AREA_CHART_V2:
        return (
          <YAxisSection
            {...sharedProps}
            instructions={instructions.V2_TWO_DIMENSION_CHART ?? {}}
            operationType={opType}
          />
        );
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2:
        return (
          <XAxisSection
            {...sharedProps}
            isHorizontal
            instructions={instructions.V2_TWO_DIMENSION_CHART ?? {}}
            operationType={opType}
          />
        );

      default:
        return null;
    }
  };

  const renderChartSpecificSection = () => {
    switch (opType) {
      case OPERATION_TYPES.VISUALIZE_LINE_CHART_V2:
        return (
          <LineChartSection
            {...sharedProps}
            instructions={instructions.V2_TWO_DIMENSION_CHART ?? {}}
            operationType={opType}
          />
        );
      case OPERATION_TYPES.VISUALIZE_AREA_CHART_V2:
        return (
          <AreaChartSection
            {...sharedProps}
            instructions={instructions.V2_TWO_DIMENSION_CHART ?? {}}
            operationType={opType}
          />
        );
      case OPERATION_TYPES.VISUALIZE_PIE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2:
        return (
          <PieChartSection
            {...sharedProps}
            instructions={instructions.V2_TWO_DIMENSION_CHART ?? {}}
            operationType={opType}
          />
        );
      case OPERATION_TYPES.VISUALIZE_FUNNEL_V2:
        return (
          <FunnelChartSection
            {...sharedProps}
            instructions={instructions.V2_TWO_DIMENSION_CHART ?? {}}
            operationType={opType}
          />
        );
      case OPERATION_TYPES.VISUALIZE_NUMBER_V2: {
        const kpiSharedProps = {
          ...sharedProps,
          instructions: instructions.V2_KPI ?? {},
          operationType: opType,
        };
        return (
          <>
            <KPIGeneralSection {...kpiSharedProps} />
            <KPIValueSection {...kpiSharedProps} />
          </>
        );
      }
      default:
        return null;
    }
  };

  const renderColorSection = () => {
    switch (opType) {
      case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2:
      case OPERATION_TYPES.VISUALIZE_LINE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_AREA_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_PIE_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2:
      case OPERATION_TYPES.VISUALIZE_FUNNEL_V2:
        return (
          <ColorSection
            {...sharedProps}
            instructions={instructions.V2_TWO_DIMENSION_CHART ?? {}}
            operationType={opType}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {renderTitleSection()}
      {renderDataLabelSection()}
      {renderXAxisSection()}
      {renderYAxisSection()}
      {renderChartSpecificSection()}
      {renderLegendSection()}
      {renderColorSection()}
    </>
  );
}
