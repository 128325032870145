import { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

import SidePaneHeader from 'components/SidePaneHeader';

export const SIDE_PANE_WIDTH = 350;
export const WIDE_SIDE_PANE_WIDTH = 400;
export const SIDE_PANE_HEADER_HEIGHT = 64;

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: Props) => ({
    width: props.wide ? WIDE_SIDE_PANE_WIDTH : SIDE_PANE_WIDTH,
    height: `100%`,
    backgroundColor: theme.palette.ds.white,
    borderRight: `1px solid ${theme.palette.ds.grey400}`,
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  }),
  header: (props: Props) => ({
    width: props.wide ? WIDE_SIDE_PANE_WIDTH : undefined,
    minHeight: SIDE_PANE_HEADER_HEIGHT,
  }),
}));

type Props = {
  backButtonPath?: string;
  title?: string;
  wide?: boolean;
};

const SidePane: FC<Props> = (props) => {
  const { backButtonPath, children, title } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      {title && (
        <SidePaneHeader backButtonPath={backButtonPath} className={classes.header} title={title} />
      )}
      {children}
    </div>
  );
};

export default SidePane;
