import { useEffect, useState } from 'react';
import { cloneDeep } from 'utils/standard';
import { useDispatch } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core';

import { Checkbox, Icon } from '@blueprintjs/core';
import { Intent, Tag } from 'components/ds';
import DropdownSelect from 'shared/DropdownSelect/DropdownSelect';

import { SelectedDropdownInputItem } from 'constants/types';
import { ParentSchema, DataSource } from 'actions/dataSourceActions';
import { AccessGroup } from 'actions/teamActions';
import { updateAccessGroups } from 'actions/rolePermissionActions';
import { showErrorToast } from 'shared/sharedToasts';

const useStyles = makeStyles((theme: Theme) => ({
  dataSourceRow: {
    display: 'flex',
    fontSize: 14,
    alignItems: 'center',
    gap: 8,
    '&:hover': {
      backgroundColor: theme.palette.ds.lightBlue,
    },
  },
  schemaContainer: {
    paddingBottom: theme.spacing(4),
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  schemaNameContainer: {
    paddingBottom: theme.spacing(2),
    fontWeight: 600,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  defaultDataSourceSelect: {
    paddingBottom: theme.spacing(2),
  },
  checkbox: {
    margin: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px ${theme.spacing(1)}px !important`,
  },
  icon: {
    paddingLeft: theme.spacing(1),
  },
  dataSourceTypeText: { color: theme.palette.ds.grey700 },
}));

type Props = {
  accessGroup: AccessGroup;
  scheme: ParentSchema;
  dataSources: DataSource[];
  defaultDataSources: number[];
};

function SettingsAccessGroupsSchemaSection(props: Props) {
  const { scheme, dataSources, defaultDataSources, accessGroup } = props;
  const [isExpanded, setIsExpanded] = useState(true);
  const [accessGroupDataSourceIds, setAccessGroupDataSourceIds] = useState(
    accessGroup.data_source_ids,
  );
  const [defaultDataSourceIds, setDefaultDataSourceIds] = useState(defaultDataSources);

  useEffect(
    () => setAccessGroupDataSourceIds(accessGroup.data_source_ids),
    [accessGroup.data_source_ids],
  );
  useEffect(() => setDefaultDataSourceIds(defaultDataSources), [defaultDataSources]);

  const dispatch = useDispatch();
  const classes = useStyles();

  const dataSourceOptions = (dataSources || []).map((dataSource) => {
    return { id: dataSource.id.toString(), name: dataSource.name } as SelectedDropdownInputItem;
  });

  const defaultDataSource = dataSourceOptions.find((dataSource) =>
    defaultDataSourceIds.includes(parseInt(dataSource.id)),
  );

  const updateDefaultDataSource = (newDefaultId: string) => {
    const newDefaults = cloneDeep(defaultDataSourceIds);
    if (defaultDataSource?.id !== undefined) {
      // remove old default
      newDefaults.splice(defaultDataSourceIds.indexOf(parseInt(defaultDataSource.id)), 1);
    }

    newDefaults.push(parseInt(newDefaultId));

    dispatch(
      updateAccessGroups(
        {
          postData: {
            access_groups: [
              {
                access_group_id: accessGroup.id,
                default_data_source_ids: newDefaults,
              },
            ],
          },
        },
        undefined,
        () => {
          showErrorToast('Something went wrong editing your access group. Please try again', 5);
          setDefaultDataSourceIds(defaultDataSourceIds);
        },
      ),
    );

    // optimistically display that the save worked, we revert if the save fails
    setDefaultDataSourceIds(newDefaults);
  };

  return (
    <div className={classes.schemaContainer} key={scheme.id}>
      <div className={classes.schemaNameContainer} onClick={() => setIsExpanded(!isExpanded)}>
        {scheme.name}
        <Icon className={classes.icon} icon={isExpanded ? 'caret-down' : 'caret-right'} />
      </div>
      {isExpanded && (
        <div>
          <DropdownSelect
            fillWidth
            minimal
            containerClassName={classes.defaultDataSourceSelect}
            filterable={false}
            label="Default Datasource"
            labelHelpIconColor="#EED202"
            labelHelpText={
              defaultDataSource
                ? undefined
                : 'This access group does not have a default datasource selected. If no default is selected, this may result in errors loading data.'
            }
            noSelectionText={'Select a datasource'}
            onChange={(item: SelectedDropdownInputItem) => updateDefaultDataSource(item.id)}
            options={dataSourceOptions}
            selectedItem={defaultDataSource ?? undefined}
          />
          {(dataSources ?? []).map((dataSource) => {
            const isDefault = defaultDataSource
              ? dataSource.id === parseInt(defaultDataSource.id)
              : false;

            const isChecked = accessGroupDataSourceIds.includes(dataSource.id);

            return (
              <div className={classes.dataSourceRow} key={dataSource.id}>
                <Checkbox
                  checked={isDefault || isChecked}
                  className={classes.checkbox}
                  disabled={isDefault}
                  label={dataSource.name}
                  onChange={() => {
                    const newDataSources = cloneDeep(accessGroupDataSourceIds);

                    if (isChecked) {
                      const oldIndex = newDataSources.indexOf(dataSource.id);

                      if (oldIndex !== undefined) newDataSources.splice(oldIndex, 1);
                    } else {
                      newDataSources.push(dataSource.id);
                    }

                    dispatch(
                      updateAccessGroups(
                        {
                          postData: {
                            access_groups: [
                              {
                                access_group_id: accessGroup.id,
                                data_source_ids: newDataSources,
                              },
                            ],
                          },
                        },
                        undefined,
                        () => {
                          showErrorToast(
                            'Something went wrong editing your visibility group. Please try again',
                            5,
                          );
                          setAccessGroupDataSourceIds(accessGroupDataSourceIds);
                        },
                      ),
                    );

                    // optimistically display that the save worked, we revert if the save fails
                    setAccessGroupDataSourceIds(newDataSources);
                  }}
                />
                <div className={classes.dataSourceTypeText}>{dataSource.source_type}</div>
                {isDefault ? <Tag intent={Intent.ACTIVE}>Default</Tag> : null}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default SettingsAccessGroupsSchemaSection;
